/**
 * Copyright 2024 Design Barn Inc.
 */

import type { GroupShape } from '@lottiefiles/lottie-js';
import { TrackMatteType } from '@lottiefiles/toolkit-js';
import type { AVLayer, ShapeLayer } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';

import { Minus, Plus } from '~/assets/icons';
import { Checkbox } from '~/components/Elements/Checkbox';
import { Select } from '~/components/Elements/Select';
import { Tooltip } from '~/components/Elements/Tooltip';
import { useMatte } from '~/hooks/useMatte';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

interface Props {}

export const MatteProperty: React.FC<Props> = () => {
  const [selectedNodesInfo, getNodeByIdOnly] = useCreatorStore(
    (state) => [state.ui.selectedNodesInfo, state.toolkit.getNodeByIdOnly],
    shallow,
  );

  const nodes = selectedNodesInfo.map((node) => getNodeByIdOnly(node.nodeId) as AVLayer | GroupShape);

  const layer = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId as string) as ShapeLayer;
  const [matteType, setMatteType] = useState(layer.trackMatteType);

  const { matteOptions, onAddTrackMatte, onMatteChange, selectedMatte } = useMatte(layer.nodeId);

  const isMatted = Boolean(layer.trackMatteType);
  const isMatte = layer.state.properties.td as boolean;

  const onRemoveTrackMatte = (): void => {
    if (nodes.length > 0) {
      emitter.emit(EmitterEvent.TIMELINE_LAYER_MATTE_UPDATE, {
        id: nodes[0]?.id,
        matteType: TrackMatteType.NO_TRACK_MATTE,
      });
      emitter.emit(EmitterEvent.CANVAS_REDRAW);
    }
  };

  const handleOnChangeInvert = (): void => {
    const firstNode = nodes[0] as AVLayer | null;

    if (!firstNode) return;

    const newMatteType =
      layer.trackMatteType === TrackMatteType.ALPHA_INVERTED ? TrackMatteType.ALPHA : TrackMatteType.ALPHA_INVERTED;

    firstNode.setTrackMatteType(newMatteType);
    setMatteType(newMatteType);
    emitter.emit(EmitterEvent.CANVAS_REDRAW);
  };

  if (!isMatted) {
    return (
      <div className="flex justify-between px-4 pb-3 pr-3 pt-4">
        <div className="text-xs font-bold text-gray-300">Matte</div>
        <div className="flex">
          <Tooltip
            content={
              isMatte ? (
                <>
                  You cannot add a matte to this layer <br />
                  as it is already used as a matte layer.
                </>
              ) : (
                ''
              )
            }
            disabled={!isMatte}
            placement="bottom-end"
          >
            <div className="pointer-events-auto mx-2 " onClick={onAddTrackMatte}>
              <Plus
                className={clsx(`h-4 w-4 ${isMatte ? 'stroke-gray-500' : 'stroke-gray-300'}`, {
                  'hover:rounded': !isMatte,
                  'hover:bg-gray-700': !isMatte,
                  'hover:stroke-white': !isMatte,
                  'cursor-pointer': !isMatte,
                })}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <>
      {selectedMatte && (
        <div className="px-4 pb-3 pr-3 pt-4">
          <div className="flex justify-between">
            <div className="text-xs font-bold text-gray-300">Matte</div>
            <div className="flex">
              <div className="pointer-events-auto mx-2 cursor-pointer" onClick={onRemoveTrackMatte}>
                <Minus className=" h-4 w-4 stroke-gray-300 hover:rounded hover:bg-gray-700 hover:stroke-white" />
              </div>
            </div>
          </div>

          <div className="mt-2 ">
            <div className="pt-1">
              <div className="pb-2 text-xs text-gray-400">Matte Layer</div>
              <div className="flex">
                <div className=" w-[130px]">
                  <Select
                    styleClass={{ button: 'border-0 h-[22px]' }}
                    options={matteOptions}
                    selected={selectedMatte}
                    onChange={onMatteChange}
                  />
                </div>
                <div className="pl-2 pt-1 text-xs">
                  <Checkbox isChecked={matteType === TrackMatteType.ALPHA_INVERTED} onChange={handleOnChangeInvert}>
                    Invert
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
