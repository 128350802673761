/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Mesh, OrthographicCamera, Scene, WebGLRenderer } from 'three';
import { Vector3, Group, MeshStandardMaterial } from 'three';

import { getMouseCoord } from './mouse';

import type { CMesh } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import SelectionBox from '~/lib/threejs/Selection/SelectionBox';
import { SelectionHelper } from '~/lib/threejs/Selection/SelectionHelper';
import type { TransformControls } from '~/lib/threejs/TransformControls';

export default class DragSelector {
  public allSelected: Mesh[] = [];

  public allSelectedPathPoints: number[] = [];

  public container: HTMLCanvasElement;

  public disable = false;

  public readonly helper: SelectionHelper;

  public mousedown = false;

  public pathPointSelection = false;

  public scene: Scene;

  public secondaryDragSelector = false;

  public selectedGroup: Group = new Group();

  public readonly selectionBox: SelectionBox;

  public transformControl: TransformControls;

  public constructor(
    camera: OrthographicCamera,
    scene: Scene,
    renderer: WebGLRenderer,
    container: HTMLCanvasElement,
    transformControl: TransformControls,
  ) {
    this.container = container;

    this.scene = scene;
    this.transformControl = transformControl;
    this.selectionBox = new SelectionBox(camera, scene);
    scene.add(this.selectedGroup);

    this.helper = new SelectionHelper(renderer, 'selectBox');
    this.helper.setVisibility(true);
    this.addEventListeners();
  }

  public addEventListeners(): void {
    const group = new Group();

    this.scene.add(group);

    window.addEventListener('pointermove', (event: MouseEvent) => {
      // only left mouse button click event should be fired
      if (event.buttons !== 1 || this.disable || !this.mousedown) {
        this.helper.setVisibility(false);

        return;
      }
      const mouse = getMouseCoord(event, this.container);

      this.selectionBox.endPoint.set(mouse.x, mouse.y, 0.5);
      if (this.pathPointSelection) {
        return;
      }

      if (this.helper.isDown && (!this.transformControl.visible || this.transformControl.hotkeys.shiftDown)) {
        this.allSelected = this.selectionBox.select();

        this.secondaryDragSelector = true;
      }
    });
    window.addEventListener('pointerup', (event: MouseEvent) => {
      if (this.disable || !this.mousedown || (this.transformControl.visible && !this.secondaryDragSelector)) {
        return;
      }
      this.mousedown = false;

      const mouse = getMouseCoord(event, this.container);

      this.selectionBox.endPoint.set(mouse.x, mouse.y, 0.5);
      if (this.pathPointSelection) {
        this.allSelectedPathPoints = this.selectionBox.selectPathPoints();
        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
          event: EmitterEvent.CANVAS_SELECT_PATH_POINTS,
          data: {
            pathPoints: this.allSelectedPathPoints,
          },
        });
      } else {
        this.allSelected = this.selectionBox.select();
        this.allSelected.sort((prev, curr) => {
          const prevPos = new Vector3();
          const currPos = new Vector3();

          prev.getWorldPosition(prevPos);
          curr.getWorldPosition(currPos);

          return prevPos.z - currPos.z;
        });
        if (this.allSelected.length > 0) {
          const layerObjectIds = [
            ...new Set(this.allSelected.map((selected) => (selected as CMesh).toolkitId).filter(Boolean)),
          ];

          if (layerObjectIds.length > 0) {
            emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
              event: EmitterEvent.CANVAS_SELECT_OBJECTS,
              data: layerObjectIds,
            });
          }
        }
      }

      this.selectionBox.clearBoundingBoxes();
      this.pathPointSelection = false;
    });
  }

  public onPointerDown(event: MouseEvent, pathPointSelection?: boolean): void {
    // only left mouse button click event should be fired
    if (event.buttons !== 1 || this.disable) {
      this.helper.setVisibility(false);

      return;
    }
    this.mousedown = true;
    if (this.transformControl.visible && !this.transformControl.hotkeys.shiftDown) {
      this.helper.setVisibility(false);

      return;
    }
    this.pathPointSelection = Boolean(pathPointSelection);
    this.helper.setVisibility(true);
    const mouse = getMouseCoord(event, this.container);

    this.selectionBox.startPoint.set(mouse.x, mouse.y, 0.5);
    for (const item of this.selectionBox.collection) {
      if (item.material instanceof MeshStandardMaterial) item.material.emissive.set(0xaaaaaa);
    }
  }
}
