/**
 * Copyright 2024 Design Barn Inc.
 */

import { Vector2, Vector3 } from 'three';

import { unProject } from './3d/utils/three';
import { getOriginOffset } from './util';
import type { Viewport } from './viewport';

const topLeftCorner = new Vector2(-1, +1);
const bottomRightCorner = new Vector2(1, -1);

export class CanvasContextHelper {
  public bottomRightCorner = new Vector3();

  public topLeftCorner = new Vector3();

  public zoomWithPixelRatio: number = 1;

  private readonly _viewport: Viewport;

  public constructor(viewport: Viewport) {
    this._viewport = viewport;
  }

  public get viewportWidth(): number {
    return this.bottomRightCorner.x - this.topLeftCorner.x;
  }

  public get viewportHeight(): number {
    return this.bottomRightCorner.y - this.topLeftCorner.y;
  }

  public clearCanvas(ctx: CanvasRenderingContext2D): void {
    ctx.clearRect(
      this.topLeftCorner.x * this._viewport.pixelRatio,
      this.topLeftCorner.y * this._viewport.pixelRatio,
      this.viewportWidth * this._viewport.pixelRatio,
      this.viewportHeight * this._viewport.pixelRatio,
    );

    ctx.resetTransform();
  }

  public projectCanvasToScene(ctx: CanvasRenderingContext2D): void {
    this.zoomWithPixelRatio = this._viewport.camera.zoom * this._viewport.pixelRatio;

    this.topLeftCorner = unProject(topLeftCorner, this._viewport.camera).round();
    this.bottomRightCorner = unProject(bottomRightCorner, this._viewport.camera).round();

    const originOffset = getOriginOffset(this._viewport.camera, this._viewport.pixelRatio);

    ctx.translate(originOffset.x, originOffset.y);
  }

  public resize(element: HTMLCanvasElement, width: number, height: number): void {
    element.width = width * this._viewport.pixelRatio;
    element.style.width = `${width}px`;
    element.height = height * this._viewport.pixelRatio;
    element.style.height = `${height}px`;
  }

  public updateCanvasReferences(): void {
    this.zoomWithPixelRatio = this._viewport.camera.zoom * this._viewport.pixelRatio;
    this.topLeftCorner = unProject(topLeftCorner, this._viewport.camera).round();
    this.bottomRightCorner = unProject(bottomRightCorner, this._viewport.camera).round();
  }
}
