/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useContext } from 'react';

import { SaveContext } from '../SaveContext';

import { CreateDirectory } from './create';

import { WorkspaceProjectFolder, WorkspaceProjectSelectedFolder } from '~/assets/icons';
import type { IWorkspaceFolder } from '~/store/projectSlice';
import { DirectoryType } from '~/store/projectSlice';

interface ProjectFolderProps {
  folder: IWorkspaceFolder;
  index: number;
}

export const ProjectFolder: React.FC<ProjectFolderProps> = ({ folder, index }: ProjectFolderProps) => {
  const { selected, setSelected } = useContext(SaveContext);

  const { id } = selected;

  const isFolderSelected = Boolean(id === folder.id);

  const isCreateNew = 'tempId' in folder;

  return (
    <>
      {isCreateNew && (
        <CreateDirectory index={index} type={folder.type} tempId={folder.id} parentId={folder.projectId} />
      )}
      {!isCreateNew && (
        <div
          className={`${
            index === 0 ? 'mt-1' : 'mt-2'
          } ml-[42px] flex cursor-pointer items-center rounded p-1 hover:bg-gray-700 ${
            isFolderSelected ? 'bg-gray-700 text-white' : ''
          }`}
          onClick={() =>
            setSelected({ id: folder.id, type: DirectoryType.Folder, projectId: folder.projectId, title: folder.name })
          }
        >
          {React.cloneElement(isFolderSelected ? <WorkspaceProjectSelectedFolder /> : <WorkspaceProjectFolder />, {
            className: `stroke-current ${
              isFolderSelected ? 'text-white' : 'text-gray-500'
            } hover:text-white cursor-pointer h-3 w-3`,
          })}
          <span className={`ml-1 text-caption ${isFolderSelected ? 'text-white' : 'text-gray-400'}`}>
            {folder.name}
          </span>
        </div>
      )}
    </>
  );
};
