/**
 * Copyright 2024 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/toolkit-js';
import React, { useCallback, useMemo } from 'react';

import { AnchorVisibilityToggle } from '~/components/Elements/Button/AnchorVisibilityToggle';
import { Interactive } from '~/components/Elements/Button/Interactive';
import { ColorInput } from '~/components/Elements/ColorInput/ColorInput';
import { BackgroundColor } from '~/data/constant';
// eslint-disable-next-line no-restricted-imports
import { Background } from '~/features/canvas/3d/threeFactory/background';
import { useCreatorStore } from '~/store';

export const TemplateSizeColor: React.FC = () => {
  const [canvasColor, canvasOpacity, setCanvasBackground] = useCreatorStore((state) => [
    state.canvas.background.color,
    state.canvas.background.opacity,
    state.canvas.setBackground,
  ]);

  const isBackgroundVisible = canvasOpacity > 0;

  const colorMessage = useMemo(() => {
    return isBackgroundVisible ? canvasColor : BackgroundColor.Transparent;
  }, [isBackgroundVisible, canvasColor]);

  const handleOnChangeColor = useCallback(
    (newColor: string) => {
      setCanvasBackground({ color: newColor, opacity: 100 });
    },
    [setCanvasBackground],
  );

  const handleBackgroundToggle = useCallback(() => {
    if (isBackgroundVisible) {
      setCanvasBackground({ color: canvasColor, opacity: 0 });
    } else {
      setCanvasBackground({ color: canvasColor, opacity: 100 });
    }
  }, [canvasColor, isBackgroundVisible, setCanvasBackground]);

  return (
    <div className="mt-2 inline-flex w-[182px]">
      <div className="grow">
        <ColorInput
          shapeType={ShapeType.FILL}
          styleClass="!w-[182px]"
          color={canvasColor}
          opacity={canvasOpacity}
          onChangeColor={handleOnChangeColor}
          onChangeOpacity={() => {
            // no opacity toggle
          }}
          colorStyleClass={`!h-full ${isBackgroundVisible ? '' : '!bg-white'}`}
          inputStyleClass="!w-full"
          message={colorMessage}
          showTransparent={!isBackgroundVisible}
          showOpacityControl={false}
          enableColorModeChange={false}
          isChannelAnimated={false}
        />
      </div>
      <Interactive className="ml-3">
        <AnchorVisibilityToggle
          type={Background.Canvas}
          anchorVisible={isBackgroundVisible}
          onClick={handleBackgroundToggle}
        />
      </Interactive>
    </div>
  );
};
