/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { cmdOrCtrl } from '../../../menu/constant';

import { TimeControl } from './TimeControl';

import {
  TimelinePlay,
  TimelineSkipToStart,
  TimelineLoop,
  TimelineNoLoop,
  TimelinePause,
  TimelineMoveForward,
  Preview,
  ArrowIcon,
} from '~/assets/icons';
import { ShortcutKey } from '~/components/Elements/ShortcutKey';
import { TooltipWithShortcut, Tooltip } from '~/components/Elements/Tooltip';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

const controlTooltipProps = {
  offsetOptions: { mainAxis: 18 },
  placement: 'top',
} as const;

interface ControlContainerProps {
  children: React.ReactNode;
  disabled?: boolean;
}

const ControlContainer: React.FC<ControlContainerProps> = ({ children, disabled }) => {
  return (
    <div
      className={clsx('group relative flex h-6 w-6 items-center justify-center rounded-[4px]', {
        'hover:bg-gray-700 cursor-pointer': !disabled,
      })}
    >
      {children}
    </div>
  );
};

const Play: React.FC = () => {
  const [playing] = useCreatorStore((state) => [state.timeline.playing], shallow);

  const handlePlay = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_PLAY);
  }, []);

  const handlePause = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_PAUSE);
  }, []);

  useEffect(() => {
    const pauseIfHidden = (): void => {
      if (document.hidden) {
        handlePause();
      }
    };

    document.addEventListener('visibilitychange', pauseIfHidden);

    return () => {
      document.removeEventListener('visibilitychange', pauseIfHidden);
    };
  }, [handlePause]);

  return (
    <ControlContainer>
      <TooltipWithShortcut content={playing ? 'Pause' : 'Play'} shortcut="space" {...controlTooltipProps}>
        <div>
          {playing ? (
            <TimelinePause className="h-4 w-4" onClick={handlePause} />
          ) : (
            <TimelinePlay className="h-4 w-4" onClick={handlePlay} />
          )}
        </div>
      </TooltipWithShortcut>
    </ControlContainer>
  );
};

const SkipToStart: React.FC = () => {
  const currentFrame = useCreatorStore((state) => state.toolkit.currentFrame);

  const handleClick = useCallback(() => {
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_FIRST_FRAME);
  }, []);

  return (
    <ControlContainer disabled={currentFrame === 0}>
      <Tooltip
        content={
          <div className="flex items-center">
            Go to start
            <ShortcutKey className="ml-2">fn</ShortcutKey>
            <ShortcutKey className="ml-1 px-0.5 align-text-bottom">
              <ArrowIcon className="w-3 -rotate-90" />
            </ShortcutKey>
            <span className="mx-2 align-bottom text-[10px] text-[#808E9A]">or</span>
            <ShortcutKey>home</ShortcutKey>
          </div>
        }
        {...controlTooltipProps}
      >
        <div>
          <TimelineSkipToStart
            className={`h-4 w-4 fill-gray-400 stroke-gray-400 ${
              currentFrame > 0 ? 'group-hover:fill-white group-hover:stroke-white' : ''
            }`}
            onClick={handleClick}
          />
        </div>
      </Tooltip>
    </ControlContainer>
  );
};

const MoveBackward: React.FC = () => {
  const [currentFrame] = useCreatorStore((state) => [state.toolkit.currentFrame], shallow);

  const handleClick = useCallback(() => {
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_PREVIOUS_FRAME);
  }, []);

  return (
    <ControlContainer disabled={currentFrame === 0}>
      <Tooltip
        content={
          <div className="flex items-center">
            Go back 1 frame
            <ShortcutKey className="ml-2">fn</ShortcutKey>
            <ShortcutKey className="ml-1 px-0.5 align-text-bottom">
              <ArrowIcon className="w-3" />
            </ShortcutKey>
            <span className="mx-2 align-bottom text-[10px] text-[#808E9A]">or</span>
            <ShortcutKey>page up</ShortcutKey>
          </div>
        }
        {...controlTooltipProps}
      >
        <div>
          <TimelineMoveForward
            className={`h-4 w-4 rotate-180 fill-gray-400 stroke-gray-400 ${
              currentFrame > 0 ? 'group-hover:fill-white group-hover:stroke-white' : ''
            }`}
            onClick={handleClick}
          />
        </div>
      </Tooltip>
    </ControlContainer>
  );
};

const MoveForward: React.FC = () => {
  const [currentFrame, duration, currentFrameRate, currentPrecompJson, precompFrameRate, precompDuration] =
    useCreatorStore(
      (state) => [
        state.toolkit.currentFrame,
        (state.toolkit.json?.timeline.duration as number) || 0,
        state.toolkit.json?.timeline.properties.fr as number,

        state.toolkit.selectedPrecompositionJson,
        state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
        state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      ],
      shallow,
    );

  const handleClick = useCallback(() => {
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_NEXT_FRAME);
  }, []);

  return (
    <ControlContainer disabled={currentFrame === 0}>
      <Tooltip
        content={
          <div className="flex items-center">
            Go forward 1 frame
            <ShortcutKey className="ml-2">fn</ShortcutKey>
            <ShortcutKey className="ml-1 px-0.5 align-text-bottom">
              <ArrowIcon className="w-3 rotate-180" />
            </ShortcutKey>
            <span className="mx-2 align-bottom text-[10px] text-[#808E9A]">or</span>
            <ShortcutKey>page down</ShortcutKey>
          </div>
        }
        {...controlTooltipProps}
      >
        <div>
          <TimelineMoveForward
            className={`h-4 w-4 fill-gray-400 stroke-gray-400 ${
              currentFrame <
              (currentPrecompJson ? (precompFrameRate as number) * precompDuration : currentFrameRate * duration)
                ? 'group-hover:fill-white group-hover:stroke-white'
                : ' '
            }`}
            onClick={handleClick}
          />
        </div>
      </Tooltip>
    </ControlContainer>
  );
};

const SkipToLast: React.FC = () => {
  const [currentFrame, duration, currentFrameRate, currentPrecompJson, precompFrameRate, precompDuration] =
    useCreatorStore(
      (state) => [
        state.toolkit.currentFrame,
        (state.toolkit.json?.timeline.duration as number) || 0,
        state.toolkit.json?.timeline.properties.fr as number,

        state.toolkit.selectedPrecompositionJson,
        state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
        state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      ],
      shallow,
    );

  const handleClick = useCallback(() => {
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_LAST_FRAME);
  }, []);

  return (
    <ControlContainer
      disabled={
        currentFrame ===
        (currentPrecompJson ? (precompFrameRate as number) * precompDuration : currentFrameRate * duration)
      }
    >
      <Tooltip
        content={
          <div className="flex items-center">
            Go to end
            <ShortcutKey className="ml-2">fn</ShortcutKey>
            <ShortcutKey className="ml-1 px-0.5 align-text-bottom">
              <ArrowIcon className="w-3 rotate-90" />
            </ShortcutKey>
            <span className="mx-2 align-bottom text-[10px] text-[#808E9A]">or</span>
            <ShortcutKey>end</ShortcutKey>
          </div>
        }
        {...controlTooltipProps}
      >
        <div>
          <TimelineSkipToStart
            className={`h-4 w-4 rotate-180 fill-gray-400 stroke-gray-400 ${
              currentFrame <
              (currentPrecompJson ? (precompFrameRate as number) * precompDuration : currentFrameRate * duration)
                ? 'group-hover:fill-white group-hover:stroke-white'
                : ''
            }`}
            onClick={handleClick}
          />
        </div>
      </Tooltip>
    </ControlContainer>
  );
};

const Loop: React.FC = () => {
  const [looping, setLooping] = useCreatorStore(
    (state) => [state.timeline.looping, state.timeline.setLooping],
    shallow,
  );

  const handleToggle = useCallback(() => {
    setLooping(!looping);
  }, [looping, setLooping]);

  return (
    <ControlContainer>
      <Tooltip content="Loop" {...controlTooltipProps}>
        <div>
          {looping ? (
            <TimelineLoop className=" h-4 w-4 fill-teal-200" onClick={handleToggle} />
          ) : (
            <TimelineNoLoop className=" h-4 w-4 fill-gray-400 group-hover:fill-white" onClick={handleToggle} />
          )}
        </div>
      </Tooltip>
    </ControlContainer>
  );
};

const Control: React.FC = () => {
  return (
    <div className="flex w-[120x] space-x-1">
      <SkipToStart />
      <MoveBackward />
      <Play />
      <MoveForward />
      <SkipToLast />
      <Loop />
    </div>
  );
};

const PreviewLottie: React.FC = () => {
  const [testAnimationOpen, setTestAnimationOpen] = useCreatorStore(
    (state) => [state.ui.testAnimationOpen, state.ui.setTestAnimationOpen],
    shallow,
  );

  const handleOnClickPreview = useCallback(() => {
    setTestAnimationOpen(!testAnimationOpen);
  }, [testAnimationOpen, setTestAnimationOpen]);

  return (
    <div className="ml-4 ">
      <ControlContainer>
        <TooltipWithShortcut
          content="Preview in player"
          shortcut={`${cmdOrCtrl} P`}
          placement="top"
          offsetOptions={{ mainAxis: 20 }}
        >
          <button
            className={clsx('group flex cursor-pointer items-center justify-center rounded hover:bg-gray-700')}
            onClick={handleOnClickPreview}
          >
            <Preview />
          </button>
        </TooltipWithShortcut>
      </ControlContainer>
    </div>
  );
};

const LeftContainer: React.FC = () => {
  return (
    <div className="w-[70px]">
      <TimeControl />
    </div>
  );
};

export const PlayerControl: React.FC = () => {
  return (
    <div
      className={`absolute -top-9 left-1/2 z-player-control h-8 -translate-x-1/2 rounded-xl bg-gray-800/90 px-4 py-1`}
    >
      <div className="flex items-center justify-between">
        <LeftContainer />
        <Control />
        <PreviewLottie />
      </div>
    </div>
  );
};
