/**
 * Copyright 2023 Design Barn Inc.
 */

export const LAYER_TYPES = ['SOLID', 'SHAPE', 'PRECOMPOSITION', 'IMAGE', 'TEXT', 'GROUP'];
export enum KeyboardDirection {
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
}
