/**
 * Copyright 2023 Design Barn Inc.
 */

import type {
  AnimatedProperty,
  AVLayer,
  DagNode,
  GradientFillShape,
  PathShape,
  Shape,
  TrimShape,
} from '@lottiefiles/toolkit-js';
import { DagNodeType, GradientFillType, StarType, PropertyType, ShapeType } from '@lottiefiles/toolkit-js';
import { intersection } from 'lodash-es';

import { getCurrentShape } from '../../../lib/toolkit/shape';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType, checkHasActiveKeyFrame, getCurrentTransform, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { formatNumber } from '~/utils';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const addAnimatedValue = useCreatorStore.getState().toolkit.addAnimatedValue;
const setStaticValue = useCreatorStore.getState().toolkit.setStaticValue;
const removeAnimated = useCreatorStore.getState().toolkit.removeAnimated;

export interface AnimatedPanelCategory {
  animationKeys?: string[];
  label?: string;
  name?: string;
  type?: string | null;
}

export interface AnimatedPanelProperty {
  category: AnimatedPanelCategory[];
  isAnimated: boolean;
  label: string;
  selected: boolean;
  type: string;
  updateKeyframe?: () => void;
}

export const animatedNameLabels = {
  [PropertyType.SHAPE]: { name: 'shape', label: 'Shape', type: PropertyType.SHAPE },
  [ShapeType.FILL]: { name: 'fill', label: 'Fill', type: ShapeType.FILL },
  [ShapeType.STROKE]: { name: 'stroke', label: 'Stroke', type: ShapeType.STROKE },
  [PropertyType.PATH]: { name: 'path', label: 'Path', type: ShapeType.PATH },
  [ShapeType.TRIM]: { name: 'trim', label: 'Trim Path', type: ShapeType.TRIM },
  [ShapeType.GRADIENT_FILL]: { name: 'gradientFill', label: 'gradient fill', type: ShapeType.GRADIENT_FILL },
  [PropertyType.GRADIENT]: { name: 'gradient', label: 'Gradient', type: ShapeType.GRADIENT_FILL },
};

export const animatedCategoryUIMapping = {
  [ShapeType.STROKE]: [PropertyType.STROKE_COLOR, PropertyType.STROKE_WIDTH],
  [ShapeType.FILL]: [PropertyType.FILL_COLOR, PropertyType.OPACITY],
  [PropertyType.PATH]: [],
  [ShapeType.TRIM]: [PropertyType.TRIM_START, PropertyType.TRIM_END, PropertyType.TRIM_OFFSET],
  [ShapeType.GRADIENT_FILL]: [
    PropertyType.GRADIENT,
    PropertyType.OPACITY,
    PropertyType.GRADIENT_START,
    PropertyType.GRADIENT_END,
    PropertyType.HIGHLIGHT_LENGTH,
    PropertyType.HIGHLIGHT_ANGLE,
  ],
};

export const hasMapping = (node: Shape): boolean => {
  return Object.entries(animatedCategoryUIMapping).some(([categoryMappingType]) => node.type === categoryMappingType);
};

export const animatedPanelProperties: AnimatedPanelProperty[] = [
  {
    type: PropertyType.POSITION,
    label: 'Position',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.SIZE,
    label: 'Size',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.SCALE,
    label: 'Scale',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.OPACITY,
    label: 'Opacity',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.ROTATION,
    label: 'Rotation',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.FILL_COLOR,
    label: 'Color',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.FILL]],
  },
  {
    type: ShapeType.PATH,
    label: 'Edit Path',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.PATH]],
  },
  {
    type: PropertyType.STROKE_WIDTH,
    label: 'Stroke Width',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.STROKE]],
  },
  {
    type: PropertyType.STROKE_COLOR,
    label: 'Color',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.STROKE]],
  },
  {
    type: PropertyType.ROUNDNESS,
    label: 'Roundness',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.NUMBER_OF_POINTS,
    label: 'Points',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.INNER_RADIUS,
    label: 'Inner radius',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.OUTER_RADIUS,
    label: 'Outer radius',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.INNER_ROUNDNESS,
    label: 'Inner roundness',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.OUTER_ROUNDNESS,
    label: 'Outer roundness',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[PropertyType.SHAPE]],
  },
  {
    type: PropertyType.TRIM_START,
    label: 'Trim start',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.TRIM]],
  },
  {
    type: PropertyType.TRIM_END,
    label: 'Trim end',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.TRIM]],
  },
  {
    type: PropertyType.TRIM_OFFSET,
    label: 'Trim offset',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.TRIM]],
  },
  {
    type: PropertyType.GRADIENT,
    label: 'Color',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.GRADIENT_FILL]],
  },
  {
    type: PropertyType.GRADIENT_START,
    label: 'Start',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.GRADIENT_FILL]],
  },
  {
    type: PropertyType.GRADIENT_END,
    label: 'End',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.GRADIENT_FILL]],
  },
  {
    type: PropertyType.HIGHLIGHT_LENGTH,
    label: 'Highlight length',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.GRADIENT_FILL]],
  },
  {
    type: PropertyType.HIGHLIGHT_ANGLE,
    label: 'Highlight angle',
    selected: false,
    isAnimated: false,
    category: [animatedNameLabels[ShapeType.GRADIENT_FILL]],
  },
];

export const animatedPanelCategories: AnimatedPanelCategory[] = [
  {
    type: PropertyType.SHAPE,
    name: 'shape',
    label: 'Shape',
  },
  {
    type: ShapeType.FILL,
    name: 'fill',
    label: 'Fill',
  },
  {
    type: PropertyType.PATH,
    name: 'path',
    label: 'Path',
  },
  {
    type: ShapeType.STROKE,
    name: 'stroke',
    label: 'Stroke',
  },
  {
    type: ShapeType.TRIM,
    name: 'trim',
    label: 'Trim path',
  },
  {
    type: ShapeType.GRADIENT_FILL,
    name: 'gradientFill',
    label: 'Gradient fill',
  },
];

// Localized transform values for a node and a property
export interface CurrentTransform {
  [key: string]: unknown;
  currentKeyframe: string;

  // Returns if the transform CHANNEL is animated.
  isAnimated: boolean;
}

export const localUIUpdate = (node: DagNode, type: string): CurrentTransform => {
  const currentShape = getCurrentShape(node);

  const isShapeNodeSelected = [ShapeType.RECTANGLE, ShapeType.STAR, ShapeType.ELLIPSE].includes(node.type);
  let data = {};
  let currentKeyframe = '';
  // Add information about the CHANNEL being animated
  const isAnimated = node.state?.animatedProperties[type as PropertyType].isAnimated;

  if (isShapeNodeSelected) {
    if (type === PropertyType.ROUNDNESS) {
      data = {
        roundness: currentShape.rect.roundness,
      };
      currentKeyframe = currentShape.rect.roundnessCurrentKeyFrame;
    } else if (type === PropertyType.OUTER_RADIUS) {
      data = {
        outerRadius: currentShape.polystar.outerRadius,
      };
      currentKeyframe = currentShape.polystar.outerRadiusCurrentKeyFrame;
    } else if (type === PropertyType.NUMBER_OF_POINTS) {
      data = {
        numPoints: currentShape.polystar.points,
      };
      currentKeyframe = currentShape.polystar.pointsCurrentKeyFrame;
    } else if (type === PropertyType.INNER_RADIUS) {
      data = {
        innerRadius: currentShape.polystar.innerRadius,
      };
      currentKeyframe = currentShape.polystar.innerRadiusCurrentKeyFrame;
    } else if (type === PropertyType.INNER_ROUNDNESS) {
      data = {
        innerRoundness: currentShape.polystar.innerRoundness,
      };
      currentKeyframe = currentShape.polystar.innerRoundnessCurrentKeyFrame;
    } else if (type === PropertyType.OUTER_ROUNDNESS) {
      data = {
        outerRoundness: currentShape.polystar.outerRoundness,
      };
      currentKeyframe = currentShape.polystar.outerRoundnessCurrentKeyFrame;
    } else if (type === PropertyType.SIZE) {
      const size = currentShape.size;

      data = {
        size,
      };
      currentKeyframe = currentShape.sizeCurrentKeyFrame;
    }
  }

  const currentTransform = getCurrentTransform(node);

  if ([ShapeType.FILL, ShapeType.STROKE, ShapeType.PATH].includes(node.type) || node.nodeType === DagNodeType.MASK) {
    if (type === PropertyType.OPACITY && node.type === ShapeType.FILL) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();
      const opacityAnimatedProperties = node.state?.animatedProperties[type];

      data = {
        opacity: opacityAnimatedProperties.value.pct || 100,
      };
      currentKeyframe = hasActiveKeyFrame(opacityAnimatedProperties);
    } else if ([PropertyType.STROKE_COLOR, PropertyType.FILL_COLOR].includes(type as string)) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();
      const colorAnimatedProperties = node.state?.animatedProperties[type];

      data = {
        color: colorAnimatedProperties.value,
      };
      currentKeyframe = hasActiveKeyFrame(colorAnimatedProperties);
    } else if (type === PropertyType.STROKE_WIDTH) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();

      const strokeWidthAnimatedProperties = node.state?.animatedProperties.sw;

      data = {
        strokeWidth: strokeWidthAnimatedProperties.value.value,
      };
      currentKeyframe = hasActiveKeyFrame(strokeWidthAnimatedProperties);
    } else if (type === ShapeType.PATH) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();

      const pathAnimatedProperties = node.state?.animatedProperties.sh;

      data = {
        path: (node as unknown as PathShape).shape.value,
      };
      currentKeyframe = hasActiveKeyFrame(pathAnimatedProperties);
    }
  } else if (type === PropertyType.POSITION) {
    // Shapes and other nodes have to be handled separately
    if (isShapeNodeSelected) {
      data = {
        x: formatNumber(currentShape.position[0]),
        y: formatNumber(currentShape.position[1]),
      };
      currentKeyframe = currentShape.positionCurrentKeyFrame;
    } else {
      data = {
        x: formatNumber(currentTransform.position[0]),
        y: formatNumber(currentTransform.position[1]),
      };
      currentKeyframe = currentTransform.positionCurrentKeyframe;
    }
  } else if (type === PropertyType.OPACITY && node.type !== ShapeType.FILL && node.type !== ShapeType.GRADIENT_FILL) {
    data = {
      opacity: currentTransform.opacity || 100,
    };
    currentKeyframe = currentTransform.opacityCurrentKeyframe;
  } else if (type === PropertyType.SCALE) {
    data = {
      scaleX: currentTransform.scale[0] || 1,
      scaleY: currentTransform.scale[1] || 1,
    };
    currentKeyframe = currentTransform.scaleCurrentKeyframe;
  } else if (type === PropertyType.ROTATION) {
    // Stars can rotate too
    if (isShapeNodeSelected) {
      data = {
        rotation: currentShape.polystar.rotation,
      };
      currentKeyframe = currentShape.polystar.rotationCurrentKeyFrame;
    } else {
      data = {
        rotation: currentTransform.rotation || 0,
      };
      currentKeyframe = currentTransform.rotationCurrentKeyframe;
    }
  } else if ([PropertyType.TRIM_END, PropertyType.TRIM_OFFSET, PropertyType.TRIM_START].includes(type)) {
    const hasActiveKeyFrame = checkHasActiveKeyFrame();

    const trimAnimatedProperties = node.state?.animatedProperties[type];

    data = {
      trimStart: (node as unknown as TrimShape).trimStart.value,
    };
    if (type === PropertyType.TRIM_END) {
      data = {
        trimEnd: (node as unknown as TrimShape).trimEnd.value,
      };
    } else if (type === PropertyType.TRIM_OFFSET)
      data = {
        trimOffset: (node as unknown as TrimShape).trimOffset.value,
      };
    currentKeyframe = hasActiveKeyFrame(trimAnimatedProperties);
  } else if (node.type === ShapeType.GRADIENT_FILL) {
    if (type === PropertyType.GRADIENT_START) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();
      const gradientStartAnimatedProperties = node.state?.animatedProperties[type];

      data = {
        x: (node as unknown as GradientFillShape).startPoint.value.x,
        y: (node as unknown as GradientFillShape).startPoint.value.y,
      };
      currentKeyframe = hasActiveKeyFrame(gradientStartAnimatedProperties);
    } else if (type === PropertyType.GRADIENT_END) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();
      const gradientEndAnimatedProperties = node.state?.animatedProperties[type];

      data = {
        x: (node as unknown as GradientFillShape).endPoint.value.x,
        y: (node as unknown as GradientFillShape).endPoint.value.y,
      };
      currentKeyframe = hasActiveKeyFrame(gradientEndAnimatedProperties);
    } else if (type === PropertyType.HIGHLIGHT_LENGTH) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();
      const gradientHighlightLengthAnimatedProperties = node.state?.animatedProperties[type];

      data = {
        highlightLength: (node as unknown as GradientFillShape).highlightLength.value.value,
      };
      currentKeyframe = hasActiveKeyFrame(gradientHighlightLengthAnimatedProperties);
    } else if (type === PropertyType.HIGHLIGHT_ANGLE) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();
      const gradientHighlightAngleAnimatedProperties = node.state?.animatedProperties[type];

      data = {
        highlightAngle: (node as unknown as GradientFillShape).highlightAngle.value.value,
      };
      currentKeyframe = hasActiveKeyFrame(gradientHighlightAngleAnimatedProperties);
    } else if (type === PropertyType.GRADIENT) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();
      const gradientAnimatedProperties = node.state?.animatedProperties[type];

      data = {
        gradientColorStops: (node as unknown as GradientFillShape).gradient.value.colorStops,
      };
      currentKeyframe = hasActiveKeyFrame(gradientAnimatedProperties);
    } else if (type === PropertyType.OPACITY) {
      const hasActiveKeyFrame = checkHasActiveKeyFrame();
      const opacityAnimatedProperties = node.state?.animatedProperties[type];

      data = {
        opacity: (node as unknown as GradientFillShape).opacity.value.value,
      };
      currentKeyframe = hasActiveKeyFrame(opacityAnimatedProperties);
    }
  }

  const finalTransform = {
    currentKeyframe,
    isAnimated,
    [type]: data,
  };

  return finalTransform as CurrentTransform;
};

export const getFilteredAnimationCategories = (node: Shape): AnimatedPanelCategory[] => {
  const layer = node.toJSON();

  let filteredAnimatedPanelCategories: AnimatedPanelCategory[] = [];
  const animationKeys = Object.keys(layer.animatedProperties) as string[];

  if (hasMapping(node)) {
    const mapIndex = animatedPanelCategories.findIndex((panelCat) => panelCat.type === node.type);

    if (mapIndex > -1) {
      filteredAnimatedPanelCategories = [
        {
          ...animatedPanelCategories[mapIndex],
          animationKeys: animatedCategoryUIMapping[node.type],
        },
      ];
    }
  } else {
    filteredAnimatedPanelCategories = animatedPanelCategories
      .map((categoryProperty) => {
        const catAnimationKeys = animatedPanelProperties.filter((element) => {
          const categoriesNames = element.category.map((elemCat) => elemCat.name);

          return categoriesNames.includes(categoryProperty.name);
        });

        if (catAnimationKeys.length > 0) {
          const catAnimationTypes = catAnimationKeys.map((animKey) => animKey.type);

          return {
            ...categoryProperty,
            animationKeys: catAnimationTypes as string[],
          };
        }

        return {
          ...categoryProperty,
        };
      })
      .filter((animatedCategoryProperty) => {
        return intersection(animatedCategoryProperty.animationKeys, animationKeys).length > 0;
      });
  }

  return filteredAnimatedPanelCategories;
};

export const updateKeyframe = (type: string): void => {
  // TODO: handle multiselection for animate all button
  const layerId = useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string;
  const node = getNodeByIdOnly(layerId) as DagNode;

  const currentTransform = localUIUpdate(node, type) as CurrentTransform;

  // If the transform channel is animated, nuke it
  const propertyNodeId = (node as AVLayer).state.animatedProperties[type as PropertyType].id;
  const track = (getNodeByIdOnly(propertyNodeId) as AnimatedProperty).track;

  if (track.isAnimated) {
    track.clear();
  }

  removeAnimated(layerId, type);

  if (currentTransform.isAnimated) {
    if (type === PropertyType.POSITION) {
      const { x, y } = currentTransform[type];

      setStaticValue(AnimatedType.POSITION, [x, y], layerId);
    } else if (type === PropertyType.SCALE) {
      const { scaleX, scaleY } = currentTransform[type];

      setStaticValue(AnimatedType.SCALE, [scaleX, scaleY], layerId);
    } else if (type === PropertyType.OPACITY) {
      const { opacity } = currentTransform[type];

      setStaticValue(AnimatedType.OPACITY, [opacity], layerId);
    } else if (type === PropertyType.ROTATION) {
      const { rotation } = currentTransform[type];

      setStaticValue(AnimatedType.ROTATION, [rotation], layerId);
    } else if (type === PropertyType.FILL_COLOR) {
      const { b, g, r } = currentTransform[type].color;

      setStaticValue(AnimatedType.FILL_COLOR, [r, g, b], layerId);
    } else if (type === ShapeType.PATH) {
      // PATH
      const { path } = currentTransform[type];

      setStaticValue(AnimatedType.PATH, [path], layerId);
    } else if (type === PropertyType.STROKE_COLOR) {
      const { b, g, r } = currentTransform[type].color;

      setStaticValue(AnimatedType.STROKE_COLOR, [r, g, b], layerId);
    } else if (type === PropertyType.STROKE_WIDTH) {
      const { strokeWidth } = currentTransform[type];

      setStaticValue(AnimatedType.STROKE_WIDTH, [strokeWidth], layerId);
    } else if (type === PropertyType.ROUNDNESS) {
      const { roundness } = currentTransform[type];

      setStaticValue(AnimatedType.ROUNDNESS, [roundness], layerId);
    } else if (type === PropertyType.OUTER_RADIUS) {
      const { outerRadius } = currentTransform[type];

      setStaticValue(AnimatedType.OUTER_RADIUS, [outerRadius], layerId);
    } else if (type === PropertyType.NUMBER_OF_POINTS) {
      const { numPoints } = currentTransform[type];

      setStaticValue(AnimatedType.POINTS, [numPoints], layerId);
    } else if (type === PropertyType.INNER_RADIUS) {
      const { innerRadius } = currentTransform[type];

      setStaticValue(AnimatedType.INNER_RADIUS, [innerRadius], layerId);
    } else if (type === PropertyType.INNER_ROUNDNESS) {
      const { innerRoundness } = currentTransform[type];

      setStaticValue(AnimatedType.INNER_ROUNDNESS, [innerRoundness], layerId);
    } else if (type === PropertyType.OUTER_ROUNDNESS) {
      const { outerRoundness } = currentTransform[type];

      setStaticValue(AnimatedType.OUTER_ROUNDNESS, [outerRoundness], layerId);
    } else if (type === PropertyType.SIZE) {
      const { size } = currentTransform[type];

      setStaticValue(AnimatedType.SIZE, size, layerId);
    } else if (type === PropertyType.GRADIENT) {
      const { gradient } = currentTransform[type];

      setStaticValue(AnimatedType.GRADIENT, gradient, layerId);
    } else if (type === PropertyType.GRADIENT_START) {
      const { x, y } = currentTransform[type];

      setStaticValue(AnimatedType.GRADIENT_START, [x, y], layerId);
    } else if (type === PropertyType.GRADIENT_END) {
      const { x, y } = currentTransform[type];

      setStaticValue(AnimatedType.GRADIENT_END, [x, y], layerId);
    } else if (type === PropertyType.HIGHLIGHT_LENGTH) {
      const { highlightLength } = currentTransform[type];

      setStaticValue(AnimatedType.HIGHLIGHT_LENGTH, [highlightLength], layerId);
    } else if (type === PropertyType.HIGHLIGHT_ANGLE) {
      const { highlightAngle } = currentTransform[type];

      setStaticValue(AnimatedType.HIGHLIGHT_ANGLE, [highlightAngle], layerId);
    }
  } else if (type === PropertyType.POSITION) {
    const { x, y } = currentTransform[type];

    addAnimatedValue(AnimatedType.POSITION, [x, y], layerId);
  } else if (type === PropertyType.SCALE) {
    const { scaleX, scaleY } = currentTransform[type];

    addAnimatedValue(AnimatedType.SCALE, [scaleX, scaleY], layerId);
  } else if (type === PropertyType.OPACITY) {
    const { opacity } = currentTransform[type];

    addAnimatedValue(AnimatedType.OPACITY, [opacity], layerId);
  } else if (type === PropertyType.ROTATION) {
    const { rotation } = currentTransform[type];

    addAnimatedValue(AnimatedType.ROTATION, [rotation], layerId);
  } else if (type === PropertyType.FILL_COLOR) {
    const { b, g, r } = currentTransform[type].color;

    addAnimatedValue(AnimatedType.FILL_COLOR, [r, g, b], layerId);
  } else if (type === ShapeType.PATH) {
    const { path } = currentTransform[type];

    addAnimatedValue(AnimatedType.PATH, [path], layerId);
  } else if (type === PropertyType.STROKE_COLOR) {
    const { b, g, r } = currentTransform[type].color;

    addAnimatedValue(AnimatedType.STROKE_COLOR, [r, g, b], layerId);
  } else if (type === PropertyType.STROKE_WIDTH) {
    const { strokeWidth } = currentTransform[type];

    addAnimatedValue(AnimatedType.STROKE_WIDTH, [strokeWidth], layerId);
  } else if (type === PropertyType.ROUNDNESS) {
    const { roundness } = currentTransform[type];

    addAnimatedValue(AnimatedType.ROUNDNESS, [roundness], layerId);
  } else if (type === PropertyType.OUTER_RADIUS) {
    const { outerRadius } = currentTransform[type];

    addAnimatedValue(AnimatedType.OUTER_RADIUS, [outerRadius], layerId);
  } else if (type === PropertyType.NUMBER_OF_POINTS) {
    const { numPoints } = currentTransform[type];

    addAnimatedValue(AnimatedType.POINTS, [numPoints], layerId);
  } else if (type === PropertyType.INNER_RADIUS) {
    const { innerRadius } = currentTransform[type];

    addAnimatedValue(AnimatedType.INNER_RADIUS, [innerRadius], layerId);
  } else if (type === PropertyType.INNER_ROUNDNESS) {
    const { innerRoundness } = currentTransform[type];

    addAnimatedValue(AnimatedType.INNER_ROUNDNESS, [innerRoundness], layerId);
  } else if (type === PropertyType.OUTER_ROUNDNESS) {
    const { outerRoundness } = currentTransform[type];

    addAnimatedValue(AnimatedType.OUTER_ROUNDNESS, [outerRoundness], layerId);
  } else if (type === PropertyType.SIZE) {
    const { size } = currentTransform[type];

    addAnimatedValue(AnimatedType.SIZE, size, layerId);
  } else if (type === PropertyType.TRIM_START) {
    const { trimStart } = currentTransform[type];

    addAnimatedValue(AnimatedType.TRIM_START, trimStart, layerId);
  } else if (type === PropertyType.TRIM_END) {
    const { trimEnd } = currentTransform[type];

    addAnimatedValue(AnimatedType.TRIM_END, trimEnd, layerId);
  } else if (type === PropertyType.TRIM_OFFSET) {
    const { trimOffset } = currentTransform[type];

    addAnimatedValue(AnimatedType.TRIM_OFFSET, trimOffset, layerId);
  } else if (type === PropertyType.GRADIENT) {
    const { gradientColorStops } = currentTransform[type];

    addAnimatedValue(AnimatedType.GRADIENT, gradientColorStops, layerId);
  } else if (type === PropertyType.GRADIENT_START) {
    const { x, y } = currentTransform[type];

    addAnimatedValue(AnimatedType.GRADIENT_START, [x, y], layerId);
  } else if (type === PropertyType.GRADIENT_END) {
    const { x, y } = currentTransform[type];

    addAnimatedValue(AnimatedType.GRADIENT_END, [x, y], layerId);
  } else if (type === PropertyType.HIGHLIGHT_LENGTH) {
    const { highlightLength } = currentTransform[type];

    addAnimatedValue(AnimatedType.HIGHLIGHT_LENGTH, [highlightLength], layerId);
  } else if (type === PropertyType.HIGHLIGHT_ANGLE) {
    const { highlightAngle } = currentTransform[type];

    addAnimatedValue(AnimatedType.HIGHLIGHT_ANGLE, [highlightAngle], layerId);
  }

  emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);
};

export const getFilteredAnimationProperties = (node: Shape): AnimatedPanelProperty[] => {
  let filteredAnimationProperties: AnimatedPanelProperty[] = [];
  const filteredAnimationCategories = getFilteredAnimationCategories(node) as AnimatedPanelCategory[];

  const layer = node.toJSON();

  let animationKeys = Object.keys(layer.animatedProperties) as string[];

  if (layer.type === ShapeType.STAR && (layer.properties.sy as StarType) === StarType.Polygon) {
    animationKeys = animationKeys.filter(
      (key) => ![PropertyType.INNER_RADIUS, PropertyType.INNER_ROUNDNESS].includes(key as PropertyType),
    );
  }

  filteredAnimationCategories.forEach((animatedPropertyCategory: AnimatedPanelCategory) => {
    if (hasMapping(node)) {
      const animatedKeys = animatedPropertyCategory.animationKeys as string[];

      filteredAnimationProperties = animatedKeys.reduce(
        (animatedProperties: AnimatedPanelProperty[], animatedKey: string): AnimatedPanelProperty[] => {
          const animatedIndex = animatedPanelProperties.findIndex(
            (animatedProperty) => animatedProperty.type === animatedKey,
          ) as number;

          if (animatedIndex > -1) {
            animatedProperties.push(animatedPanelProperties[animatedIndex] as AnimatedPanelProperty);
          }

          return animatedProperties;
        },
        [],
      );

      if (layer.type === ShapeType.GRADIENT_FILL && layer.properties.t === GradientFillType.LINEAR) {
        filteredAnimationProperties = filteredAnimationProperties.filter(
          (key) => ![PropertyType.HIGHLIGHT_ANGLE, PropertyType.HIGHLIGHT_LENGTH].includes(key.type as PropertyType),
        );
      }
    } else {
      filteredAnimationProperties = animatedPanelProperties.filter((element) => {
        const categoriesNames = element.category.map((elemCat) => elemCat.name);

        return categoriesNames.includes(animatedPropertyCategory.name) && animationKeys.includes(element.type);
      });
    }
  });

  if (filteredAnimationProperties.length > 0) {
    for (const prop of filteredAnimationProperties) {
      prop.updateKeyframe = () => {
        updateKeyframe(prop.type);
        localUIUpdate(node, prop.type);
      };
      prop.isAnimated = Boolean(layer.animatedProperties[prop.type as PropertyType].isAnimated);
    }
  }

  return filteredAnimationProperties as AnimatedPanelProperty[];
};

export const toggleAnimateAll = (): void => {
  // TODO: handle multiselection for animate all button
  const nodeId = useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string;

  if (!nodeId) return;

  const node = getNodeByIdOnly(nodeId) as Shape;
  const animatedProps = getFilteredAnimationProperties(node) as AnimatedPanelProperty[];

  const allAnimated = animatedProps.every((prop) => prop.isAnimated);
  const allNotAnimated = animatedProps.every((prop) => !prop.isAnimated);

  stateHistory.beginAction();

  if (allAnimated || allNotAnimated) {
    for (const prop of animatedProps) {
      (prop.updateKeyframe as () => void)();
    }
  } else {
    // if only some props are animated, animate the other props
    for (const prop of animatedProps) {
      if (!prop.isAnimated) (prop.updateKeyframe as () => void)();
    }
  }

  stateHistory.endAction();
};
