/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { getCurrentKeyFrame, getIsAtKeyFrame } from './helpers';

import { PolygonRadius, StarOuterRadius } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { MIN_POLYSTAR_RADIUS, MAX_POLYSTAR_RADIUS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface PolyStarOuterRadiusProps {
  isStar?: boolean;
  outerRadius: number;
  outerRadiusIsAnimated: boolean;
}

export const PolystarOuterRadiusField: React.FC<PolyStarOuterRadiusProps> = ({
  isStar = false,
  outerRadius,
  outerRadiusIsAnimated,
}) => {
  const labelIcon = useMemo(
    () => (isStar ? <StarOuterRadius className="h-4 w-4" /> : <PolygonRadius className="h-4 w-4" />),
    [isStar],
  );

  const [animated, currentShapeNode, setOuterRadius, setPolystarVariableIsAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentShapeNode?.state?.animatedProperties?.or,
      state.toolkit.currentShapeNode,
      state.toolkit.setPolystarOuterRadius,
      state.toolkit.setPolystarVariableIsAnimated,
    ],
    shallow,
  );

  const isAtKeyFrame = getIsAtKeyFrame(animated);
  const outerRadiusCurrentKeyFrame = getCurrentKeyFrame(animated, currentShapeNode);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setOuterRadius(result.value);
      emitter.emit(EmitterEvent.POLYSTAR_OUTER_RADIUS_UPDATED);
    },
    [setOuterRadius],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (outerRadiusCurrentKeyFrame === '') {
      setPolystarVariableIsAnimated('outerRadius', true);
      setOuterRadius(outerRadius);
    } else {
      removeKeyFrame(outerRadiusCurrentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_OUTER_RADIUS_UPDATED);
    stateHistory.endAction();
  }, [setOuterRadius, setPolystarVariableIsAnimated, outerRadiusCurrentKeyFrame, outerRadius]);

  return (
    <div className="w-full">
      <NumberInput
        name="outer-radius"
        label={labelIcon}
        value={outerRadius}
        onChange={handleOnChange}
        min={MIN_POLYSTAR_RADIUS}
        max={MAX_POLYSTAR_RADIUS}
        showKeyframe
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={isAtKeyFrame}
        isChannelAnimated={outerRadiusIsAnimated}
      />
    </div>
  );
};
