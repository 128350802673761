/**
 * Copyright 2022 Design Barn Inc.
 */

import type { DagNode, ColorJSON, PercentageJSON, AnimatedPropertiesJSON } from '@lottiefiles/toolkit-js';
import { GradientFillType, ShapeType, GroupShape, FillShape } from '@lottiefiles/toolkit-js';
import { cloneDeep } from 'lodash-es';

import type { AppFill } from '~/lib/toolkit';
import { createGetCurrentKeyframe } from '~/lib/toolkit';

export interface CurrentFillShape {
  animatedProperties: AnimatedPropertiesJSON | null;
  b: number;
  bm: number;
  colorCurrentKeyframe: string;
  colorIsAnimated: boolean;
  fillRule: number;
  g: number;
  id: string | null;
  opacity: number;
  opacityCurrentKeyframe: string;
  opacityIsAnimated: boolean;
  r: number;
  type: GradientFillType;
}

export const getFillShape = (node: DagNode | null): FillShape | null => {
  if (node instanceof GroupShape) {
    const shape = node.shapes.find((sh) => sh.type === ShapeType.FILL);

    if (shape instanceof FillShape) {
      return shape;
    }
  } else if (node instanceof FillShape) {
    return node;
  }

  return null;
};

export const getFillShapes = (node: DagNode | null): FillShape[] | null => {
  if (node instanceof GroupShape) {
    const shapes = node.shapes.filter((sh) => sh.type === ShapeType.FILL) as FillShape[];

    if (shapes.length > 0 && shapes[0] instanceof FillShape) {
      return shapes;
    }
  } else if (node instanceof FillShape) {
    return [node];
  }

  return null;
};

export const defaultCurrentFillShape: CurrentFillShape = {
  r: 0,
  g: 0,
  b: 0,
  colorCurrentKeyframe: '',
  colorIsAnimated: false,
  opacityCurrentKeyframe: '',
  opacityIsAnimated: false,
  opacity: 100,
  fillRule: 1,
  id: null,
  bm: 0,
  animatedProperties: null,
  type: GradientFillType.NONE,
};

export const getCurrentFillShape = (node: FillShape | null): CurrentFillShape => {
  const currentFillShape: CurrentFillShape = { ...defaultCurrentFillShape };

  if (node instanceof FillShape) {
    const currentFrame = node.scene.timeline.currentFrame;
    const getKeyFrame = createGetCurrentKeyframe(currentFrame);
    const fillState = node.state;
    const color = fillState.animatedProperties.cl;
    const opacity = fillState.animatedProperties.o;

    currentFillShape.r = (color.value as ColorJSON).r;
    currentFillShape.g = (color.value as ColorJSON).g;
    currentFillShape.b = (color.value as ColorJSON).b;
    currentFillShape.id = fillState.id as string;
    currentFillShape.colorCurrentKeyframe = getKeyFrame(fillState.animatedProperties.cl);
    currentFillShape.colorIsAnimated = color.isAnimated;
    currentFillShape.bm = fillState.properties.bm as number;

    // Note: color's alpha is NOT SUPPORTED on Lottie Player. Use opacity instead.
    currentFillShape.opacityCurrentKeyframe = getKeyFrame(fillState.animatedProperties.o);
    currentFillShape.opacityIsAnimated = fillState.animatedProperties.o.isAnimated;
    currentFillShape.fillRule = fillState.properties.flr as number;
    currentFillShape.animatedProperties = cloneDeep(fillState.animatedProperties as AnimatedPropertiesJSON);
    currentFillShape.opacity = (opacity.value as PercentageJSON).pct;
  }

  return currentFillShape;
};

export const getCurrentFillShapes = (node: DagNode | null): AppFill => {
  const fillShapes = getFillShapes(node);

  let currentFillShapes = {};

  if (fillShapes) {
    currentFillShapes = fillShapes
      .map((fs: FillShape) => getCurrentFillShape(fs))
      .reduce((fShapes: AppFill, obj: CurrentFillShape) => {
        if (obj.id) fShapes[obj.id] = { ...obj };

        return fShapes;
      }, {});
  }

  return currentFillShapes;
};
