/**
 * Copyright 2024 Design Barn Inc.
 */

import { SIDEBAR_PLUGINS } from './plugins';

export enum UTMMedium {
  Creator = 'creator',
}

export enum UTMSource {
  HeaderButton = 'header-button',
  HeaderFileLocation = 'header-file-location',
}

export enum EventType {
  AddedKeyframe = 'added-keyframe',
  AddedSegment = 'added-segment',
  ClickedFileLocation = 'clicked-file-location',
  DownloadedFile = 'downloaded-file',
  Export = 'export',
  ExportWithSegment = 'export-with-segment',
  OnboardingPopoverAction = 'onboarding-popover-action',
  OnboardingPopoverShown = 'onboarding-popover-shown',
  OpenedPlugin = 'opened-plugin',
  SelectedFileMenu = 'selected-file-menu',
  StartSession = 'start-session',
  UploadedAsset = 'uploaded-asset',
}

export enum EventAction {
  CreateNew = 'create new file',
  DownloadFile = 'download file',
  GoToDashboard = 'go to dashboard',
  SaveDotCreator = 'save dotcreator',
  SaveWorkspace = 'export to workspace',
}

export enum AssetSource {
  BasicShapes = 'basic-shapes',
  FileUrl = 'file-url',
  GetStartedJSON = 'get-started-JSON',
  GetStartedStatic = 'get-started-static',
  GetStartedUpload = 'get-started-upload',
  IconScout = 'iconscout',
  Local = 'local',
  LottieLibrary = 'lottie-library',
}

export enum SegmentSource {
  SegmentPopover = 'segment-popover',
  WorkArea = 'work-area',
}

export enum SessionSource {
  Creator = 'creator',
}

export enum KeyframeSource {
  PropertyPanel = 'property-panel',
  Timeline = 'timeline',
}

export enum FileType {
  BasicShapes = 'basic-shapes',
  DotLottie = 'dotlottie',
  JSON = 'json',
  SVG = 'svg',
}

export enum ClickSource {
  Creator = 'creator',
  Header = 'header',
  PropertyPanel = 'property-panel',
  Timeline = 'timeline',
}

export enum EasingControl {
  Both = 'both',
  In = 'in',
  Out = 'out',
}

export enum EasingType {
  Linear = 'Linear',
  Mixed = 'Mixed',
  Smooth = 'Smooth',
}

export type EasingControlOption = EasingControl.In | EasingControl.Out;
export type EasingTypeOption = EasingType.Linear | EasingType.Smooth | EasingType.Mixed;

export enum PluginType {
  AIPromptToVector = 'ai-prompt-to-vector',
  AnimationPresets = 'animation-presets',
  Duplicator = 'duplicator',
  KineticTypography = 'kinetic-typography',
  MotionCopilot = 'motion-copilot',
}

export const pluginIdToTypeMap: Record<string, PluginType> = {
  [`plugin-${SIDEBAR_PLUGINS.promptVector.uuid}`]: PluginType.AIPromptToVector,
  [`plugin-${SIDEBAR_PLUGINS.duplicator.uuid}`]: PluginType.Duplicator,
  [`plugin-${SIDEBAR_PLUGINS.kineticTypography.uuid}`]: PluginType.KineticTypography,
  [`plugin-${SIDEBAR_PLUGINS.motionCopilot.uuid}`]: PluginType.MotionCopilot,
};

export enum OnboardingPopoverAction {
  Dismiss = 'dismiss',
  Done = 'done',
  Next = 'next',
  Skip = 'skip',
  Start = 'start',
}

export enum OnboardingPopoverSource {
  GlobalModal = 'global-modal',
  Sidebar = 'sidebar',
}
