/**
 * Copyright 2023 Design Barn Inc.
 */

import { Vector2, Vector3 } from 'three';

import { Box3 } from '../Box3';

import type { CMesh, CObject3D } from '~/features/canvas';
import { canvasMap } from '~/lib/canvas';
import { AlignPivotDirection } from '~/store/uiSlice';

export class AlignmentPivotHelper {
  public object: CObject3D | null = null;

  public objects: Array<CObject3D | CMesh> = [];

  public getAlignPivotPosition(direction: AlignPivotDirection, isMultipleObjects: boolean = false): Vector3 | null {
    if (!isMultipleObjects && this.object === null) return null;
    if (isMultipleObjects && this.objects.length === 0) return null;

    let objectBoundingBox = null;

    if (isMultipleObjects) {
      objectBoundingBox = new Box3().setFromObjects(this.objects, true);
    } else {
      objectBoundingBox = new Box3().setFromObject(this.object as CObject3D, true);
    }

    const { bottomLeft, bottomRight, middleBottom, middleLeft, middleRight, middleTop, topLeft, topRight } =
      this.getPoints(objectBoundingBox);

    const positions = {
      [AlignPivotDirection.Left]: middleLeft,
      [AlignPivotDirection.Right]: middleRight,
      [AlignPivotDirection.Center]: objectBoundingBox.center,
      [AlignPivotDirection.Top]: middleTop,
      [AlignPivotDirection.TopLeft]: topLeft,
      [AlignPivotDirection.TopRight]: topRight,
      [AlignPivotDirection.Bottom]: middleBottom,
      [AlignPivotDirection.BottomLeft]: bottomLeft,
      [AlignPivotDirection.BottomRight]: bottomRight,
    };

    return positions[direction];
  }

  public getMiddleEdge(beginEdge: Vector3, endEdge: Vector3): Vector3 {
    const middlePoint = new Vector3();

    middlePoint.x = (beginEdge.x + endEdge.x) / 2;
    middlePoint.y = (beginEdge.y + endEdge.y) / 2;
    middlePoint.z = (beginEdge.z + endEdge.z) / 2;

    return middlePoint;
  }

  public getPoints(objectBoundingBox: Box3): {
    bottomLeft: Vector3;
    bottomRight: Vector3;
    middleBottom: Vector3;
    middleLeft: Vector3;
    middleRight: Vector3;
    middleTop: Vector3;
    topLeft: Vector3;
    topRight: Vector3;
  } {
    const topLeft = new Vector3(objectBoundingBox.min.x, objectBoundingBox.min.y, objectBoundingBox.min.z);

    const topRight = new Vector3(
      objectBoundingBox.maxXminY.x,
      objectBoundingBox.maxXminY.y,
      objectBoundingBox.maxXminY.z,
    );
    const bottomLeft = new Vector3(
      objectBoundingBox.minXmaxY.x,
      objectBoundingBox.minXmaxY.y,
      objectBoundingBox.minXmaxY.z,
    );
    const bottomRight = new Vector3(objectBoundingBox.max.x, objectBoundingBox.max.y, objectBoundingBox.max.z);

    const middleTop = this.getMiddleEdge(topLeft, topRight);
    const middleLeft = this.getMiddleEdge(topLeft, bottomLeft);
    const middleRight = this.getMiddleEdge(topRight, bottomRight);
    const middleBottom = this.getMiddleEdge(bottomLeft, bottomRight);

    return {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      middleTop,
      middleLeft,
      middleRight,
      middleBottom,
    };
  }
}

export const getBoundingBox = (nodeIds: string[]): Box3 | null => {
  const objects = nodeIds.map((nodeId) => canvasMap.get(nodeId));

  if (objects.length === 0) return null;

  const objectBoundingBox =
    nodeIds.length === 1
      ? new Box3().setFromObject(objects[0] as CObject3D, true)
      : new Box3().setFromObjects(objects as CObject3D[], true);

  return objectBoundingBox;
};

export const getAlignPivotDirection = (nodeIds: string[], pivotPosition: Vector2): AlignPivotDirection | null => {
  const objectBoundingBox = getBoundingBox(nodeIds);

  if (!objectBoundingBox) return null;

  const topLeft = new Vector2(objectBoundingBox.min.x, objectBoundingBox.min.y);
  const topRight = new Vector2(objectBoundingBox.maxXminY.x, objectBoundingBox.maxXminY.y);
  const bottomLeft = new Vector2(objectBoundingBox.minXmaxY.x, objectBoundingBox.minXmaxY.y);
  const bottomRight = new Vector2(objectBoundingBox.max.x, objectBoundingBox.max.y);

  const middleTop = new Vector2((topLeft.x + topRight.x) / 2, (topLeft.y + topRight.y) / 2);
  const middleLeft = new Vector2((topLeft.x + bottomLeft.x) / 2, (topLeft.y + bottomLeft.y) / 2);
  const middleRight = new Vector2((topRight.x + bottomRight.x) / 2, (topRight.y + bottomRight.y) / 2);
  const middleBottom = new Vector2((bottomLeft.x + bottomRight.x) / 2, (bottomLeft.y + bottomRight.y) / 2);

  const positions = [
    { pos: topLeft, direction: AlignPivotDirection.TopLeft },
    { pos: topRight, direction: AlignPivotDirection.TopRight },
    { pos: bottomLeft, direction: AlignPivotDirection.BottomLeft },
    { pos: bottomRight, direction: AlignPivotDirection.BottomRight },
    { pos: middleTop, direction: AlignPivotDirection.Top },
    { pos: middleLeft, direction: AlignPivotDirection.Left },
    { pos: middleRight, direction: AlignPivotDirection.Right },
    { pos: middleBottom, direction: AlignPivotDirection.Bottom },
    { pos: objectBoundingBox.center, direction: AlignPivotDirection.Center },
  ];

  const closestPosition = positions.reduce((closest, current) =>
    pivotPosition.distanceTo(current.pos) < pivotPosition.distanceTo(closest.pos) ? current : closest,
  );

  return pivotPosition.distanceTo(closestPosition.pos) <= 1 ? closestPosition.direction : null;
};
