/**
 * Copyright 2022 Design Barn Inc.
 */

import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { TimelineSidebarPlus, TimelineSidebarSegment, TimelineSidebarSegmentActive } from '~/assets/icons';
import { Tooltip, TooltipWithShortcut } from '~/components/Elements/Tooltip';
import { GB_CONSTANT } from '~/config/growthbook';
import { altOrOpt } from '~/features/menu';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

export const TimelineSidebar: React.FC = () => {
  const [isSegmentsDialogOpen, setIsSegmentsDialogOpen] = useCreatorStore(
    (state) => [state.timeline.isSegmentsDialogOpen, state.timeline.setIsSegmentsDialogOpen],
    shallow,
  );

  const handleSegmentsDialogOpen = useCallback(() => {
    setIsSegmentsDialogOpen(true);
  }, [setIsSegmentsDialogOpen]);

  return (
    <div id="timeline-sidebar" className="mt-1 flex w-10 flex-col items-center gap-2">
      <IfFeatureEnabled feature={GB_CONSTANT.SHOW_ADD_NEW_SCENE}>
        <Tooltip content="Add nested scene" placement="right">
          <div className="flex h-5 w-5 items-center justify-center">
            <TimelineSidebarPlus
              className="h-4 w-4 cursor-pointer stroke-gray-300 hover:rounded hover:bg-[#283137] hover:stroke-white"
              onClick={() => {
                emitter.emit(EmitterEvent.TIMELINE_PRECOMP_ADD_SCENE);
              }}
            />
          </div>
        </Tooltip>
      </IfFeatureEnabled>
      <TooltipWithShortcut content="Segments" placement="right" shortcut={`${altOrOpt} S`}>
        <div className="flex h-5 w-5 items-center justify-center">
          {isSegmentsDialogOpen && <TimelineSidebarSegmentActive />}
          {!isSegmentsDialogOpen && (
            <TimelineSidebarSegment
              className="h-4 w-4 cursor-pointer stroke-gray-300 hover:rounded hover:bg-[#283137] hover:stroke-white"
              onClick={handleSegmentsDialogOpen}
            />
          )}
        </div>
      </TooltipWithShortcut>
      <div className="flex h-5 w-5 items-center justify-center">
        {/* <TimelineSidebarStar className="h-4 w-4 cursor-not-allowed stroke-current text-gray-600" /> */}
      </div>
    </div>
  );
};

export default TimelineSidebar;
