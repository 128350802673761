/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { Reset } from '~/assets/icons';

interface Props {
  onReset: () => void;
  playerVersion: string;
}

export const TestAnimationSubHeader: React.FC<Props> = ({ onReset, playerVersion }) => {
  return (
    <div className="flex h-[52px] items-center justify-between p-3">
      <div className="pl-3 text-xs">dotLottie-player</div>
      <div className="flex items-center">
        <input
          className="mr-[11.5px] h-6 w-[54px] rounded bg-gray-700 text-center text-caption font-bold"
          value={playerVersion}
          disabled={true}
        />
        <button className="rounded p-1 align-middle hover:bg-gray-700">
          <Reset className="h-4 w-4 cursor-pointer stroke-current text-gray-400 " onClick={onReset} />
        </button>
      </div>
    </div>
  );
};
