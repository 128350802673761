/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable no-param-reassign */
import hsvToRgb from './hsvToRgb';

export default function changePicker(
  x: number,
  y: number,
  height: number,
  width: number,
  hue: number,
): {
  alpha: number;
  blue: number;
  green: number;
  red: number;
  saturation: number;
  value: number;
} {
  if (x > width) x = width;
  if (y > height) y = height;
  if (x < 0) x = 0;
  if (y < 0) y = 0;
  const value = (100 - (y * 100) / height) | 0;
  const saturation = ((x * 100) / width) | 0;

  return {
    ...hsvToRgb(hue, saturation, value),
    saturation,
    value,
  };
}
