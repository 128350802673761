/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { getCurrentKeyFrame, getIsAtKeyFrame } from './helpers';

import { AspectRatioToggle } from '~/components/Elements/Button/AspectRatioToggle';
import { Interactive } from '~/components/Elements/Button/Interactive';
import { DualNumberInput } from '~/components/Elements/Input';
import type { NumberInputOption } from '~/components/Elements/Input';
import type { NumberInputRatioResult } from '~/components/Elements/Input/useDualNumberInputRatio';
import { useDualNumberInputRatio } from '~/components/Elements/Input/useDualNumberInputRatio';
import { MAX_SHAPE_HEIGHT, MAX_SHAPE_WIDTH, MIN_SHAPE_HEIGHT, MIN_SHAPE_WIDTH } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { Scalar2D } from '~/lib/toolkit';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const sizeOption: { h: NumberInputOption; w: NumberInputOption } = {
  w: {
    name: 'width',
    label: 'W',
    precision: 0,
    min: MIN_SHAPE_WIDTH,
    max: MAX_SHAPE_WIDTH,
  },
  h: {
    name: 'height',
    label: 'H',
    precision: 0,
    min: MIN_SHAPE_HEIGHT,
    max: MAX_SHAPE_HEIGHT,
  },
};

interface ShapeSizeFieldProps {
  size: Scalar2D;
  sizeIsAnimated: boolean;
}

export const ShapeSizeField: React.FC<ShapeSizeFieldProps> = ({ size, sizeIsAnimated = false }) => {
  const [
    animated,
    currentShapeNode,
    setShapeSize,
    setShapeSizeIsAnimated,
    sizeRatioLocked,
    selectedNodesInfo,
    getNodeByIdOnly,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentShapeNode?.state?.animatedProperties?.sz,
      state.toolkit.currentShapeNode,
      state.toolkit.setShapeSize,
      state.toolkit.setShapeSizeIsAnimated,
      state.ui.sizeRatioLocked,
      state.ui.selectedNodesInfo,
      state.toolkit.getNodeByIdOnly,
    ],
    shallow,
  );

  const isAtKeyFrame = getIsAtKeyFrame(animated);
  const sizeCurrentKeyFrame = getCurrentKeyFrame(animated, currentShapeNode);

  const [width, height] = size;
  const handleOnChangeResult = useCallback(
    (result: NumberInputRatioResult) => {
      stateHistory.beginAction();
      if (result.type === 'both') {
        const value = result.value as number[];

        if (value[0] && value[1]) setShapeSize(value[0], value[1]);
      } else if (result.type === sizeOption.w.name) {
        setShapeSize(result.value as number, height);
      } else {
        setShapeSize(width, result.value as number);
      }
      emitter.emit(EmitterEvent.SHAPE_SIZE_UPDATED);
    },
    [width, height, setShapeSize],
  );

  const { handleInputChange, handleOnClickAspectRatio, isLocked } = useDualNumberInputRatio({
    left: width,
    leftName: sizeOption.w.name,
    right: height,
    onChange: handleOnChangeResult,
    ratioLocked: sizeRatioLocked,
  });

  const handleAspectRatioUpdate = useCallback(() => {
    handleOnClickAspectRatio();
    // TODO: handle multi selection
    const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId as string);

    if (node) {
      emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
        event: EmitterEvent.TOOLKIT_NODE_SIZE_UPDATED,
        data: { node, sizeRatioLock: !sizeRatioLocked },
      });
    }
  }, [handleOnClickAspectRatio, getNodeByIdOnly, selectedNodesInfo, sizeRatioLocked]);

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (sizeCurrentKeyFrame === '') {
      setShapeSizeIsAnimated(true);
      setShapeSize(width, height);
    } else {
      removeKeyFrame(sizeCurrentKeyFrame);
    }
    emitter.emit(EmitterEvent.SHAPE_SIZE_UPDATED);
    stateHistory.endAction();
  }, [setShapeSize, setShapeSizeIsAnimated, sizeCurrentKeyFrame, width, height]);

  return (
    <div className="mt-2 flex w-full">
      <DualNumberInput
        onChange={handleInputChange}
        onKeyframeClick={handleKeyframeClick}
        left={width}
        right={height}
        leftOption={sizeOption.w}
        rightOption={sizeOption.h}
        hasKeyframe={isAtKeyFrame}
        showKeyframe
        leftMin={sizeOption.w.min || MIN_SHAPE_WIDTH}
        leftMax={sizeOption.w.max || MAX_SHAPE_WIDTH}
        rightMin={sizeOption.h.min || MIN_SHAPE_HEIGHT}
        rightMax={sizeOption.h.max || MAX_SHAPE_HEIGHT}
        isChannelAnimated={sizeIsAnimated}
      />
      <Interactive>
        <AspectRatioToggle isLocked={Boolean(isLocked)} onClick={handleAspectRatioUpdate} />
      </Interactive>
    </div>
  );
};
