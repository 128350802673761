/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useRef, useEffect } from 'react';

import { Cross } from '~/assets/icons';

export interface TextInputProps {
  autoSelect?: boolean;
  icon?: React.ReactNode;
  inputClass?: string;
  onChange: (text: string) => void;
  placeholder?: string;
  showRemove?: boolean;
  value: string;
}

export const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const { autoSelect, icon, inputClass } = props;
  const [isFocus, setIsFocus] = React.useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // Focus on the input when the component mounts
    if (autoSelect) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [autoSelect]);

  return (
    <div className="relative dark:text-white">
      {props.icon}
      <input
        ref={inputRef}
        className={`w-full rounded-md border bg-gray-600 py-[2px] pr-7 ${
          icon ? 'pl-8' : ''
        } border-transparent text-xs focus:border-lf-teal focus:ring-0 ${inputClass}`}
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={(ev) => props.onChange(ev.target.value)}
      />
      {props.showRemove && props.value.length > 0 && isFocus && (
        <Cross
          className="absolute right-0 top-[1px] my-1 mr-1 h-5 w-6 cursor-pointer fill-current dark:text-white"
          onMouseDown={(ev) => ev.preventDefault()}
          onClick={() => props.onChange('')}
          data-testid="cross-icon"
        ></Cross>
      )}
    </div>
  );
};
