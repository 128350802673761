/**
 * Copyright 2024 Design Barn Inc.
 */

import clsx from 'clsx';
import React from 'react';
import type { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

export const ShortcutKey: FC<Props> = ({ children, className }) => (
  <span
    className={clsx(
      'inline-block h-4 items-center justify-between rounded bg-[#4C5863] px-1 text-xs text-white shadow-card',
      className,
    )}
  >
    {children}
  </span>
);
