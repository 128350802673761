/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useState, useCallback } from 'react';

import { Checkbox } from '~/components/Elements/Checkbox';
import { LocalStorageKey } from '~/store/constant';

const ContainerStyle = {
  width: '100%',
  height: '100%',
};

export const SidebarSettings = (): JSX.Element => {
  const AutoOpenOnSideBarKey = LocalStorageKey.AutoOpenOnSideBar;
  const autoOpenOnSideBar = window.localStorage.getItem(LocalStorageKey.AutoOpenOnSideBar);
  const initialState = autoOpenOnSideBar === null || autoOpenOnSideBar === 'true';

  const [openPanel, setOpenPanel] = useState(initialState);

  const handleAutoOpenSideBar = useCallback(() => {
    const updated = !openPanel;

    setOpenPanel(updated);
    window.localStorage.setItem(AutoOpenOnSideBarKey, `${updated}`);
  }, [openPanel, setOpenPanel, AutoOpenOnSideBarKey]);

  return (
    <div style={ContainerStyle}>
      <Checkbox labelStyle="w-[210px]" childrenStyle="!pl-1" isChecked={openPanel} onChange={handleAutoOpenSideBar}>
        <div className="text-[10px] text-white">Open this panel when Creator is launched</div>
      </Checkbox>
    </div>
  );
};

export default SidebarSettings;
