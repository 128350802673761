/**
 * Copyright 2022 Design Barn Inc.
 */

import { Vector } from '@lottiefiles/toolkit-js';
import type { PrecompositionLayer } from '@lottiefiles/toolkit-js';
import React from 'react';

import { useCreatorStore } from '~/store';
import type { CanvasCoord } from '~/store/canvasSlice';

export interface IDragToCanvas {
  component?: React.ReactNode | null;
  coord?: CanvasCoord | null;
  dragging?: boolean;
  execute?: () => Promise<void> | null;
  executed?: boolean;
  fromStatic?: boolean;
  height?: number | null;
  width?: number | null;
}

const setDragToCanvas = useCreatorStore.getState().ui.setDragToCanvas;
const setCanvasCoord = useCreatorStore.getState().canvas.setCoord;

export const resetDragToCanvas = (): void => {
  setDragToCanvas({
    coord: null,
    dragging: false,
    executed: false,
    component: null,
    fromStatic: false,
  } as IDragToCanvas);

  // position relative to canvas only
  setCanvasCoord(null);
};

export const getDragPosFromDragToCanvas = (): Record<string, number | boolean | null> => {
  const coord = useCreatorStore.getState().canvas.coord;
  const { fromStatic } = useCreatorStore.getState().ui.dragToCanvas as IDragToCanvas;

  return { coord, fromStatic } as Record<string, number | boolean | null>;
};

export const updatePrecompDragToCanvasPosition = (layer: PrecompositionLayer): boolean => {
  const isPositionAnimated = layer.state.animatedProperties.p.isAnimated;

  const { coord } = getDragPosFromDragToCanvas() as IDragToCanvas;

  if (isPositionAnimated === false && coord) {
    const mousePosX = coord.x;
    const mousePosY = coord.y;

    layer.setPosition(new Vector(Math.round(mousePosX) as number, Math.round(mousePosY) as number));

    resetDragToCanvas();

    return true;
  }

  return false;
};

export const DragToCanvasContainer: React.FC = (): React.ReactElement => {
  const { component, dragging, height, width } = useCreatorStore((state) => state.ui.dragToCanvas);
  const coord = useCreatorStore((state) => state.ui.dragToCanvas.coord);

  // This container overlay entire windows, event listeners on cursor won't work from what's below
  return (
    <>
      {component && dragging && coord && (
        <div
          className="absolute z-drag-to-canvas-container h-full w-full opacity-50"
          style={{
            left: coord.x as number,
            top: coord.y as number,
          }}
        >
          {React.cloneElement(component as React.ReactElement, { style: { height, width } })}
        </div>
      )}
    </>
  );
};
