/**
 * Copyright 2023 Design Barn Inc.
 */

import type { LayerJSON } from '@lottiefiles/toolkit-js';
import React, { forwardRef } from 'react';

import { CreatorLayerType } from '../../helper';
import type { CreatorAnimatedTypeItem, CreatorLayerTypeItem } from '../../helper';
import TimelineItemAnimated from '../../TimelineItem/TimelineItemAnimated';
import TimelineItemLayer from '../../TimelineItem/TimelineItemLayer';

import { FolderTreeItemWrapper } from './FolderTreeItemWrapper';
import type { TreeItemData } from './TreeItemData';
import type { TreeItemComponentProps } from './types';

import { useCreatorStore } from '~/store';
import { GlobalCursorType } from '~/store/uiSlice';

export const FolderTreeItem = forwardRef<HTMLDivElement, TreeItemComponentProps<TreeItemData>>((props, ref) => {
  const { layer } = props;

  const cursorClassName =
    useCreatorStore((state) => state.timeline.timelineContext.cursorClassName) || GlobalCursorType.POINTER;

  return (
    <FolderTreeItemWrapper {...props} ref={ref}>
      {(layer.creatorType === CreatorLayerType.SHAPE || layer.creatorType === CreatorLayerType.GROUP) && (
        <>
          <TimelineItemLayer
            layer={(layer as CreatorLayerTypeItem).layer as LayerJSON}
            cursorClassName={cursorClassName}
          />
        </>
      )}
      {layer.creatorType === CreatorLayerType.MASK && (
        <>
          <TimelineItemLayer
            layer={(layer as CreatorLayerTypeItem).layer as LayerJSON}
            cursorClassName={cursorClassName}
            isMask={true}
          />
        </>
      )}
      {layer.creatorType === CreatorLayerType.ANIMATED_PROPERTY && (
        <TimelineItemAnimated
          animatedId={(layer as CreatorAnimatedTypeItem).id}
          layer={(layer as CreatorAnimatedTypeItem).layer as LayerJSON}
          type={(layer as CreatorAnimatedTypeItem).type}
          cursorClassName={cursorClassName}
        />
      )}
    </FolderTreeItemWrapper>
  );
});
