/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import type { PluginUIType } from '~/plugins';

export interface PluginUIProps {
  host: string;
  id: string;
  isDevPlugin: boolean;
  onClose: () => void;
  onReload?: () => void;
  options: {
    height: number;
    width: number;
  };
  src: string;
  title: string;
  uiType: PluginUIType;
  withIframe: (iframe: HTMLIFrameElement | null) => void;
}

export const PluginUI: React.FC<PluginUIProps> = (props) => {
  const maxWidth = props.options.width === 0 ? 340 : props.options.width;
  const maxHeight = props.options.height === 0 ? 680 : props.options.height;

  return (
    <iframe
      src={props.host}
      sandbox="allow-scripts allow-forms allow-popups allow-same-origin"
      ref={(iframe) => props.withIframe(iframe)}
      id={`plugin-${props.id}`}
      style={{
        minWidth: `${maxWidth}px`,
        minHeight: `${maxHeight}px`,
        maxWidth: `${maxWidth}px`,
        maxHeight: `${maxHeight}px`,
      }}
    ></iframe>
  );
};
