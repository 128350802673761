/**
 * Copyright 2022 Design Barn Inc.
 */

import { useFeature, IfFeatureEnabled } from '@growthbook/growthbook-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { TimelineRangeSlider } from './TimelineRangeSlider';
import { TimelineScrubber } from './TimelineScrubber';
import { TimelineTimeScale } from './TimelineTimeScale';
import { TimelineWorkArea } from './TimelineWorkArea/TimelineWorkArea';

import { GB_CONSTANT } from '~/config/growthbook';
import { useCreatorStore } from '~/store';

export const TimelineControl: React.FC = () => {
  const showZoombar = useFeature(GB_CONSTANT.SHOW_TIMELINE_ZOOMBAR).on;
  const setKeyframeScrubberWidth = useCreatorStore.getState().timeline.setKeyframeScrubberWidth;

  const widthRef = useRef<number | null>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  const onResize = useCallback((width: number | undefined, _: number | undefined) => {
    widthRef.current = width ?? 1;
    setContainerWidth(widthRef.current);
  }, []);

  const { ref } = useResizeDetector({
    onResize,
  });

  useEffect(() => {
    if (containerWidth !== null) {
      setKeyframeScrubberWidth(containerWidth);
    }
  }, [containerWidth, setKeyframeScrubberWidth]);

  return (
    <div ref={ref} className={`${showZoombar === false ? 'mt-1' : ''}`}>
      <IfFeatureEnabled feature={GB_CONSTANT.SHOW_TIMELINE_ZOOMBAR}>
        <TimelineRangeSlider />
      </IfFeatureEnabled>

      <TimelineWorkArea />
      <TimelineScrubber />
      <TimelineTimeScale />
    </div>
  );
};
