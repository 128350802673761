/**
 * Copyright 2024 Design Barn Inc.
 */

import { clamp } from 'lodash-es';

import { hsvToRgb } from './index';

export default function getHue(
  offsetX: number,
  width: number,
  saturation: number,
  value: number,
): {
  blue: number;
  green: number;
  hue: number;
  red: number;
} {
  let hue = ((360 * offsetX) / width) | 0;

  hue = clamp(hue, 0, 360);

  return {
    ...hsvToRgb(hue, saturation, value),
    hue,
  };
}
