/**
 * Copyright 2022 Design Barn Inc.
 */

import type { SceneJSON } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import type { MouseEventHandler } from 'react';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { shallow } from 'zustand/shallow';

import { TimelineTopbarChevronDown, CompTimelineTab, Close, ChevronRight, ChevronLeft } from '~/assets/icons';
import { TitleInput } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { SceneNameType } from '~/data/constant';
import { ContextMenuWrapper, MENU_HEIGHT, MENU_WIDTH } from '~/features/menu';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { resetLayerUI } from '~/lib/layer';
import { getActiveScene, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { CANVAS_DEFAULT_TAB_ID, CANVAS_ANCHOR_GRID_PREFIX } from '~/store/constant';
import { truncateString } from '~/utils';

const TimelineTab: React.FC<{
  isActive?: boolean;
  isMainScene?: boolean;
  name: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onCloseClick?: (e: React.MouseEvent<HTMLElement>) => void;
}> = ({ isActive = false, isMainScene = false, name, onClick, onCloseClick }) => {
  const [hoverState, setHoverState] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [dropdownCoords, setDropdownCoords] = useState<Record<'x' | 'y', number> | null>(null);

  const onDropdownOpen: MouseEventHandler<HTMLElement> = useCallback(
    (evt) => {
      // Set the current scene to active
      if (!isActive) onClick?.(evt);

      setDropdownCoords({
        x:
          evt.clientX + MENU_WIDTH.TimelineTopbarMenu < window.innerWidth
            ? evt.clientX
            : evt.clientX - MENU_WIDTH.TimelineTopbarMenu,
        y:
          evt.clientY + MENU_HEIGHT.TimelineTopbarMenu < window.innerHeight
            ? 28
            : -1 * MENU_HEIGHT.TimelineTopbarMenu - 8,
      });
    },
    [isActive, onClick],
  );

  const onEditClose = useCallback(
    (newName: string) => {
      const trimmedName = newName.trim();

      if (trimmedName.length > 0 && trimmedName !== name) {
        const scene = getActiveScene(toolkit);

        scene?.setName(trimmedName);

        emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
        emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
      }

      setIsEditing(false);
    },
    [name],
  );

  return (
    <>
      <div
        className={`relative flex h-[26px] shrink-0 items-center self-end rounded-t-lg ${
          isActive ? 'bg-gray-700' : 'cursor-pointer'
        } pl-4`}
        onClick={onClick}
        onMouseOver={() => setHoverState(true)}
        onMouseOut={() => setHoverState(false)}
      >
        <div className="flex items-center ">
          <div className="flex items-center">
            <CompTimelineTab className="mr-1 h-4 w-4 text-gray-400" />
            {isEditing ? (
              <TitleInput containerClassName="after:max-w-[300px] pr-4" initialValue={name} onClose={onEditClose} />
            ) : (
              <span
                className="ml-1 mr-6 text-caption font-normal text-white hover:text-[#BFC8D1]"
                onAuxClickCapture={onDropdownOpen}
              >
                {truncateString(name as string, 28)}
              </span>
            )}
          </div>
          {name !== 'current' && hoverState && !isEditing && (
            <div onClick={onCloseClick} className="z-10 ">
              {!isMainScene && (
                <Close className=" absolute right-[7px] top-[7px] z-10  h-3 w-3 cursor-pointer text-gray-400" />
              )}
            </div>
          )}
        </div>
        {isActive && (
          <div>
            <svg
              className="absolute -left-4 bottom-0 h-4 w-4"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16 16H0C11.9 16 15.625 5.33333 16 0V16Z" fill="#2B343B" />
            </svg>
            <svg
              className="absolute -right-4 bottom-0 h-4 w-4"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 16V0C0 11.9 10.6667 15.625 16 16H0Z" fill="#2B343B" />
            </svg>
          </div>
        )}
      </div>
      {dropdownCoords ? (
        <ContextMenuWrapper
          coord={dropdownCoords}
          onClose={() => setDropdownCoords(null)}
          dropdownItems={[
            {
              type: 'Rename',
              label: 'Rename',
              callback: () => setIsEditing(true),
            },
          ]}
        />
      ) : null}
    </>
  );
};

const TimelineCollapseButton = React.forwardRef<HTMLDivElement>((_, ref) => {
  const [visible, setVisible] = useCreatorStore(
    (state) => [state.timeline.visible, state.timeline.setVisible],
    shallow,
  );

  const handleOnToggle = useCallback((): void => {
    setVisible(!visible);
  }, [visible, setVisible]);

  return (
    <div ref={ref} className="h-full">
      <Tooltip content={visible ? 'Minimize' : 'Expand'} placement={visible ? 'bottom-start' : 'top-start'}>
        <button className="z-tooltip h-full" onClick={handleOnToggle}>
          <TimelineTopbarChevronDown
            className={clsx('mx-3 h-4 w-4 cursor-pointer', {
              'rotate-180': !visible,
            })}
          />
        </button>
      </Tooltip>
    </div>
  );
});

const TimelineTabSwitcher = React.forwardRef<
  HTMLDivElement,
  {
    isMoveLeftDisabled: boolean;
    isMoveRightDisabled: boolean;
    onMoveLeft: () => void;
    onMoveRight: () => void;
  }
>(({ isMoveLeftDisabled, isMoveRightDisabled, onMoveLeft, onMoveRight }, ref) => {
  return (
    <div
      ref={ref}
      className="absolute right-0 top-0 z-10 flex min-h-[28px] items-center rounded-tr-2xl bg-gray-800 pr-3"
    >
      <div className="min-h-[28px] w-[1px] bg-gray-700" />
      <div className="pl-2">
        <ChevronLeft
          className={`h-4 w-4 stroke-gray-300 hover:rounded ${isMoveLeftDisabled ? 'text-gray-400' : 'cursor-pointer hover:bg-gray-700 hover:stroke-white'}`}
          onClick={onMoveLeft}
        />
      </div>
      <div>
        <ChevronRight
          className={`h-4 w-4 stroke-gray-300 hover:rounded ${isMoveRightDisabled ? 'text-gray-400' : 'cursor-pointer hover:bg-gray-700 hover:stroke-white'}`}
          onClick={onMoveRight}
        />
      </div>
    </div>
  );
});

const TimelineTopbar: React.FC = () => {
  const [
    json,
    selectedPrecompositionId,
    setSelectedPrecompositionId,
    removeSelectedNodes,
    setCurrentFrame,
    selectedPrecompositionJson,
    setTabState,
    hiddenTabs,
    setAnchorPointsActive,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.json,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.setSelectedPrecompositionId,
      state.ui.removeSelectedNodes,
      state.toolkit.setCurrentFrame,
      state.toolkit.selectedPrecompositionJson,
      state.timeline.setTabState,
      state.timeline.hiddenTabs,
      state.ui.setAnchorPointsActive,
    ],
    shallow,
  );

  const tabSwitcherRef = useRef<HTMLDivElement>(null);
  const collapseRef = useRef<HTMLDivElement>(null);
  const [tabsShift, setTabsShift] = useState(0);
  const [isTabsOverflow, setIsTabsOverflow] = useState(false);
  const [isMoveLeftDisabled, setIsMoveLeftDisabled] = useState(false);

  const onResizeTabs = useCallback(
    (width?: number) => {
      const totalWidth = (width || 0) + (collapseRef.current?.offsetWidth || 0);

      setIsTabsOverflow(totalWidth >= window.innerWidth);
      setIsMoveLeftDisabled(totalWidth < window.innerWidth - (tabSwitcherRef.current?.offsetWidth || 0));
    },
    [setIsTabsOverflow],
  );

  const { ref } = useResizeDetector({
    onResize: onResizeTabs,
  });

  const shiftTabsLeft = useCallback(() => {
    if (isMoveLeftDisabled) {
      return;
    }
    setTabsShift(tabsShift - 1);
  }, [tabsShift, setTabsShift, isMoveLeftDisabled]);

  const shiftTabsRight = useCallback(() => {
    if (tabsShift === 0) {
      return;
    }
    setTabsShift(tabsShift + 1);
  }, [tabsShift, setTabsShift]);

  const updateAnchorPointers = useCallback(() => {
    const anchorPointsActive = useCreatorStore.getState().ui.anchorPointsActive;
    let sceneId = null;

    if (selectedPrecompositionId) {
      sceneId = selectedPrecompositionId;
    }
    const _anchorPointsActive = Object.keys(anchorPointsActive);
    const emptyAnchor = _anchorPointsActive.length === 0;

    if (
      emptyAnchor ||
      !_anchorPointsActive.includes(`${CANVAS_ANCHOR_GRID_PREFIX}${sceneId || CANVAS_DEFAULT_TAB_ID}`)
    ) {
      setAnchorPointsActive(
        `${CANVAS_ANCHOR_GRID_PREFIX}${sceneId || CANVAS_DEFAULT_TAB_ID}`,
        emptyAnchor ? null : anchorPointsActive,
      );
    }
  }, [selectedPrecompositionId, setAnchorPointsActive]);

  useEffect(() => updateAnchorPointers(), [selectedPrecompositionId, updateAnchorPointers]);

  // Gets the total frame count of the precomp
  const getPrecompFrameCount = (assetId: string): number | null => {
    const assetNode = useCreatorStore.getState().toolkit.getNodeByIdOnly(assetId);

    if (!assetNode) return null;

    const precompJSON = assetNode.state as SceneJSON;

    return (precompJSON.timeline.properties.fr as number) * precompJSON.timeline.duration;
  };

  return (
    <div className="flex min-h-[28px] items-center">
      <TimelineCollapseButton ref={collapseRef} />
      <div ref={ref} className="flex min-h-[28px] items-center">
        {tabsShift === 0 && (
          <TimelineTab
            name={(json?.properties.nm as string) || SceneNameType.MainScene}
            isActive={selectedPrecompositionId === null}
            onClick={() => {
              const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

              setSelectedPrecompositionId(null);
              const mainSceneTotalFrames = json
                ? (json.timeline.properties.fr as number) * (json.timeline.duration as number)
                : null;

              if (mainSceneTotalFrames !== null && currentFrame > mainSceneTotalFrames) {
                setCurrentFrame(mainSceneTotalFrames);
              }
              removeSelectedNodes();

              emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
              resetLayerUI();
              emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
            }}
            isMainScene
          />
        )}

        {json &&
          json.assets
            .filter(
              (asset) =>
                asset.type === 'PRECOMPOSITION' &&
                !hiddenTabs.includes((asset.properties.nm || asset.properties.ln) as string),
            )
            .map(
              (asset, index) =>
                index + tabsShift >= -1 && (
                  <TimelineTab
                    key={asset.id}
                    name={(asset.properties.nm || asset.properties.ln) as string}
                    isActive={
                      (selectedPrecompositionJson &&
                        selectedPrecompositionJson.properties.ln === asset.properties.ln) as boolean
                    }
                    onClick={() => {
                      if (asset.id !== selectedPrecompositionId) {
                        const currentFrame = useCreatorStore.getState().toolkit.currentFrame;
                        const precompFrameCount = getPrecompFrameCount(asset.id);

                        setSelectedPrecompositionId(asset.id);
                        if (precompFrameCount !== null && currentFrame > precompFrameCount) {
                          setCurrentFrame(precompFrameCount);
                        }
                        removeSelectedNodes();

                        emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
                        resetLayerUI();
                        emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
                      }
                    }}
                    onCloseClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      setTabState((asset.properties.nm as string) || (asset.properties.ln as string), false);
                      if (selectedPrecompositionId === asset.id || selectedPrecompositionId === asset.properties.ln) {
                        setSelectedPrecompositionId(null);

                        const mainSceneTotalFrames =
                          (json.timeline.properties.fr as number) * (json.timeline.duration as number);

                        const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

                        if (currentFrame > mainSceneTotalFrames) {
                          setCurrentFrame(mainSceneTotalFrames);
                        }
                        removeSelectedNodes();

                        emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
                        resetLayerUI();
                        emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
                        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
                          event: EmitterEvent.CANVAS_ADJUST,
                          data: { size: json.properties.sz },
                        });
                      }
                    }}
                  />
                ),
            )}
      </div>
      {(tabsShift !== 0 || isTabsOverflow) && (
        <TimelineTabSwitcher
          ref={tabSwitcherRef}
          isMoveLeftDisabled={isMoveLeftDisabled}
          isMoveRightDisabled={tabsShift === 0}
          onMoveLeft={shiftTabsLeft}
          onMoveRight={shiftTabsRight}
        />
      )}
    </div>
  );
};

export default TimelineTopbar;
