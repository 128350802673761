/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable no-undefined */
import type { GradientFillType } from '@lottiefiles/toolkit-js';
import { Color, Scalar } from '@lottiefiles/toolkit-js';
import { clamp, throttle } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type { ColorOptional } from '..';
import { MouseAction } from '..';
import { Area } from '../Area';
import { GradientPoints } from '../Area/GradientPoints';
import type { GradientPointType } from '../Area/GradientPoints/GradientPoint';
import DefaultColorPanel from '../DefaultColorPanel';

import { getRightValue, generateGradientStyle } from '@/ColorPicker/helpers';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNewColorFromOffset } from '~/lib/toolkit';

export interface GradientData {
  addedPoint?: GradientPointType;
  degree: number;
  deletedIndex?: number;
  points: GradientPointType[];
  style: string;
  type: GradientFillType;
}

interface Props {
  degree: number;
  onChange: (param: GradientData) => void;
  onEndChange?: (param: GradientData) => void;
  onStartChange?: (param: GradientData) => void;
  points: GradientPointType[];
  type: GradientFillType;
}

export const Gradient: React.FC<Props> = ({ degree, onChange, onEndChange, onStartChange, points, type }) => {
  const [activePointIndex, setActivePointIndex] = useState(0);
  const [gradientPoints, setGradientPoints] = useState(points);
  const [activePoint, setActivePoint] = useState(points[activePointIndex]);
  const [colorRed, setColorRed] = useState(activePoint?.red ?? 0);
  const [colorGreen, setColorGreen] = useState(activePoint?.green ?? 0);
  const [colorBlue, setColorBlue] = useState(activePoint?.blue ?? 0);
  const [colorAlpha, setColorAlpha] = useState(activePoint?.alpha ?? 0);

  const updateCurrentColor = useCallback(() => {
    setColorRed(activePoint?.red ?? 0);
    setColorGreen(activePoint?.green ?? 0);
    setColorBlue(activePoint?.blue ?? 0);
    setColorAlpha(activePoint?.alpha ?? 0);
  }, [activePoint]);

  useEffect(() => {
    setGradientPoints(points);
    setActivePoint(points[activePointIndex]);
    updateCurrentColor();
  }, [activePointIndex, points, updateCurrentColor]);

  useEffect(() => {
    updateCurrentColor();
  }, [activePoint, updateCurrentColor]);

  const actions = useMemo(
    () => ({
      onChange,
      onStartChange,
      onEndChange,
    }),
    [onChange, onEndChange, onStartChange],
  );

  const removePoint = useCallback(
    (index = activePointIndex) => {
      if (gradientPoints.length <= 1) {
        return;
      }

      const localGradientPoints = gradientPoints.slice();

      localGradientPoints.splice(index, 1);

      setGradientPoints(localGradientPoints);

      if (index > 0) {
        setActivePointIndex(index - 1);
      }

      actions.onStartChange({
        points: localGradientPoints,
        type,
        degree,
        style: generateGradientStyle(localGradientPoints, type, degree),
        deletedIndex: index,
      });
    },
    [activePointIndex, gradientPoints, actions, type, degree],
  );

  const keyUpHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        removePoint(activePointIndex);
      }
    },
    [activePointIndex, removePoint],
  );

  useEffect(() => {
    document.addEventListener('keyup', keyUpHandler);

    return () => {
      document.removeEventListener('keyup', keyUpHandler);
    };
  });

  const changeActivePointIndex = useCallback((index: number) => {
    setActivePointIndex(index);
  }, []);

  const updateColor = throttle(
    ({ alpha, blue, green, red }: ColorOptional, actionName: MouseAction = MouseAction.onChange) => {
      let redValue = colorRed;
      let greenValue = colorGreen;
      let blueValue = colorBlue;
      let alphaValue = colorAlpha;

      if (red !== undefined) {
        redValue = getRightValue(red, colorRed);
      }
      if (green !== undefined) {
        greenValue = getRightValue(green, colorGreen);
      }
      if (blue !== undefined) {
        blueValue = getRightValue(blue, colorBlue);
      }
      if (alpha !== undefined && !Number.isNaN(alpha)) {
        alphaValue = getRightValue(alpha, colorAlpha);
      }

      const localGradientPoints = gradientPoints.slice();

      localGradientPoints[activePointIndex] = {
        ...localGradientPoints[activePointIndex],
        red: redValue,
        green: greenValue,
        blue: blueValue,
        alpha: alphaValue,
      } as GradientPointType;

      setGradientPoints(localGradientPoints);

      const action = actions[actionName];

      action({
        points: localGradientPoints,
        type,
        degree,
        style: generateGradientStyle(localGradientPoints, type, degree),
      });
    },
    50,
  );

  const updateGradientLeft = useCallback(
    (left: number, index: number, actionName = MouseAction.onChange) => {
      const localGradientPoints = gradientPoints.slice();
      const localGradientPoint = localGradientPoints[index];

      if (!localGradientPoint) return;
      localGradientPoint.left = clamp(left, 0, 1);

      setGradientPoints(localGradientPoints);

      const action = actions[actionName];

      action({
        points: localGradientPoints,
        type,
        degree,
        style: generateGradientStyle(localGradientPoints, type, degree),
      });
    },
    [gradientPoints, actions, type, degree],
  );

  const addPoint = useCallback(
    (left: number) => {
      const localGradientPoints = gradientPoints.slice();

      const colorstops = localGradientPoints.map((point) => ({
        color: new Color(point.red, point.green, point.blue, point.alpha),
        stop: new Scalar(point.left),
      }));

      const newColor = getNewColorFromOffset(colorstops, left);

      localGradientPoints.push({
        red: newColor.red,
        green: newColor.green,
        blue: newColor.blue,
        alpha: newColor.alpha,
        left,
      });

      localGradientPoints.sort((first, second) => first.left - second.left);

      setGradientPoints(localGradientPoints);
      setActivePointIndex(localGradientPoints.findIndex((point) => point.left === left));

      actions.onStartChange({
        points: localGradientPoints,
        type,
        degree,
        style: generateGradientStyle(localGradientPoints, type, degree),
        addedPoint: localGradientPoints[activePointIndex] as GradientPointType,
      });
    },
    [gradientPoints, actions, type, degree, activePointIndex],
  );

  const getActivePointFromCanvas = useCallback(
    (data: { index: number }) => {
      changeActivePointIndex(data.index);
    },
    [changeActivePointIndex],
  );

  useEffect(() => {
    emitter.on(EmitterEvent.CANVAS_UPDATED_ACTIVE_GRADIENT_COLOR_STOP, getActivePointFromCanvas);

    return () => {
      emitter.off(EmitterEvent.CANVAS_UPDATED_ACTIVE_GRADIENT_COLOR_STOP, getActivePointFromCanvas);
    };
  }, [changeActivePointIndex, getActivePointFromCanvas, points]);

  return (
    <>
      <div className="picker-area">
        <GradientPoints
          points={gradientPoints}
          activePointIndex={activePointIndex}
          changeActivePointIndex={changeActivePointIndex}
          updateGradientLeft={updateGradientLeft}
          addPoint={addPoint}
        />
        <Area
          red={colorRed}
          green={colorGreen}
          blue={colorBlue}
          alpha={colorAlpha}
          updateRgb={updateColor}
          isGradient
          type={type}
          degree={degree}
          points={gradientPoints}
        />
      </div>
      <DefaultColorPanel setColor={updateColor} gradientPoints={gradientPoints} />
    </>
  );
};

export default Gradient;
