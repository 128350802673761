/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { Close, Info } from '~/assets/icons';
import { WORKFLOW_DASHBOARD } from '~/config';
import { useCreatorStore } from '~/store';

export const FetchFailedFileNotFoundWarning: React.FC = () => {
  const [isAuthorized, fileNotFound, fileId, remixId, setFetchExistingFile] = useCreatorStore(
    (state) => [
      state.user.isAuthorized,
      state.project.fetchExistingFile.fileNotFound,
      state.project.fetchExistingFile.fileId,
      state.project.fetchExistingFile.remixId,
      state.project.setFetchExistingFile,
    ],
    shallow,
  );

  const onGoToDashboardClickHandler = useCallback(() => {
    window.open(`${WORKFLOW_DASHBOARD}`, '_blank');
  }, []);

  const onCloseClickHandler = useCallback(() => {
    setFetchExistingFile({ fileNotFound: false });
  }, [setFetchExistingFile]);

  if (!fileNotFound) {
    return null;
  }

  return (
    <div
      className={`fixed left-1/2 ${isAuthorized ? 'top-[56px]' : 'top-[120px]'} z-[2] flex -translate-x-1/2 items-center gap-3 rounded-lg bg-[#333C45] p-3 text-[14px] text-[#ffffff]`}
    >
      <Info className="h-6 w-6 shrink-0" />
      {fileId && (
        <>
          <span>
            Couldn’t open file as it might have been deleted. Try looking for it on your{' '}
            <span className="font-bold">Recently Deleted</span> page.
          </span>
          <button
            className="h-[32px] shrink-0 rounded-lg border border-white px-3 font-bold text-white"
            onClick={onGoToDashboardClickHandler}
          >
            Go to Dashboard
          </button>
        </>
      )}

      {remixId && (
        <>
          <span>Couldn’t find file. It might have been deleted, or the link might be disabled or invalid.</span>
        </>
      )}
      <button>
        <Close
          className="h-6 w-6 shrink-0 cursor-pointer fill-current text-white hover:opacity-50"
          onClick={onCloseClickHandler}
        />
      </button>
    </div>
  );
};
