/**
 * Copyright 2023 Design Barn Inc.
 */

// Plugin UUIDs that are used in the app. These will be eventually fetched from a DB
export const SIDEBAR_PLUGINS = {
  iconscout: { uuid: 'e54b2eef-df61-4758-9d81-c075abf07c59', name: 'IconScout - Free design assets' },
  duplicator: { uuid: 'f7a71bdb-57dc-4e25-9050-daece65a6fd8', name: 'Duplicator' },
  promptVector: { uuid: '684a6639-a435-4be2-a40b-e58d16efe874', name: 'AI Prompt to Vector' },
  kineticTypography: { uuid: 'b82da044-b93e-4fd2-82b3-42adf9dee531', name: 'Kinetic Typography' },
  motionCopilot: { uuid: '9def8fab-e09d-4a09-92c8-6178e2466af4', name: 'Motion Copilot' },
};
