/**
 * Copyright 2023 Design Barn Inc.
 */

import { mapValues } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

// eslint-disable-next-line no-restricted-imports
import { NineGridSelector, getDefaultAnchorPoint } from '~/features/property-panel/components/field/9GridSelector';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getAlignPivotDirection } from '~/lib/threejs/TransformControls/AlignmentPivotHelper';
import { getPivotPosition } from '~/lib/toolkit/pivot';
import { useCreatorStore } from '~/store';
import { DefaultPivotAlignment } from '~/store/constant';

interface TransformAnchorPointerProps {
  className?: string;
  disabled: boolean;
}

export const TransformAnchorPointer: React.FC<TransformAnchorPointerProps> = ({ disabled }) => {
  const [selectedNodesInfo, anchorPointsActives, anchorPointByCursor] = useCreatorStore(
    (state) => [state.ui.selectedNodesInfo, state.ui.anchorPointsActive, state.ui.anchorPointByCursor],
    shallow,
  );

  const nodeId = selectedNodesInfo[0]?.nodeId as string;
  const multiSelect = selectedNodesInfo.length > 1;

  const defaultPivotAlignment = getDefaultAnchorPoint(anchorPointByCursor);

  const [tempAnchorPoints, setTempAnchorPoints] = useState({ [nodeId || '']: defaultPivotAlignment });

  const setAnchorPointsActive = useCreatorStore.getState().ui.setAnchorPointsActive;
  const setAnchorPointByCursor = useCreatorStore.getState().ui.setAnchorPointByCursor;

  const refreshAnchorPoints = useCallback(() => {
    setTimeout(() => {
      setAnchorPointByCursor(false);

      const pivot = getPivotPosition(selectedNodesInfo.map((info) => info.nodeId));

      if (!pivot) {
        setAnchorPointsActive(nodeId as string, null, true);

        return;
      }

      const direction = getAlignPivotDirection(
        selectedNodesInfo.map((info) => info.nodeId),
        pivot,
      );

      if (!direction) {
        setTempAnchorPoints({
          [nodeId]: {
            ...mapValues(DefaultPivotAlignment, () => false),
          },
        });
        setAnchorPointsActive(nodeId as string, null, true);

        return;
      }

      if (multiSelect) {
        setTempAnchorPoints({
          [nodeId]: {
            ...mapValues(DefaultPivotAlignment, () => false),
            [direction]: true,
          },
        });

        return;
      }

      setAnchorPointsActive(nodeId as string, {
        [direction]: true,
      });
    });
  }, [multiSelect, nodeId, selectedNodesInfo, setAnchorPointByCursor, setAnchorPointsActive]);

  useEffect(() => {
    // refresh the anchor point on the grid when the component mounts
    // and if the anchor point has been moved by the cursor
    refreshAnchorPoints();
  }, [nodeId, refreshAnchorPoints, selectedNodesInfo.length, anchorPointByCursor]);

  useEffect(() => {
    // refresh the anchor point on the grid on undo/redo
    emitter.on(EmitterEvent.UI_UNDO, refreshAnchorPoints);
    emitter.on(EmitterEvent.UI_REDO, refreshAnchorPoints);

    return () => {
      emitter.off(EmitterEvent.UI_UNDO, refreshAnchorPoints);
      emitter.off(EmitterEvent.UI_REDO, refreshAnchorPoints);
    };
  }, [refreshAnchorPoints]);

  const onAnchorClick = useCallback(
    (type: string) => {
      if (nodeId) {
        if (multiSelect) {
          setTempAnchorPoints({
            [nodeId]: {
              ...mapValues(DefaultPivotAlignment, () => false),
              [type]: true,
            },
          });
        } else {
          setAnchorPointsActive(nodeId as string, {
            [type]: true,
          });
        }
        setAnchorPointByCursor(false);
        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
          event: EmitterEvent.CANVAS_TRANSFORMCONTROL_PIVOT_UPDATED,
          data: { type },
        });
      }
    },
    [nodeId, setAnchorPointByCursor, setAnchorPointsActive, setTempAnchorPoints, multiSelect],
  );

  return (
    <>
      <NineGridSelector
        multiSelect={multiSelect}
        tempAnchorPoints={tempAnchorPoints}
        nodeId={nodeId as string}
        disabled={disabled}
        activePoints={anchorPointsActives}
        onClick={onAnchorClick}
      />
    </>
  );
};
