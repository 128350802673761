/**
 * Copyright 2022 Design Barn Inc.
 */

import type { CubicBezierJSON, DagNode, PathShape, Keyframe, CubicBezierShape } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType, removeKeyFrame } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

export const PathEditProperty: React.FC = () => {
  const [selectedNodesInfo, setPropertyPanel, currentFrame, getNodeByIdOnly, setAnimatedValue] = useCreatorStore(
    (state) => [
      state.ui.selectedNodesInfo,
      state.ui.setPropertyPanel,
      state.toolkit.currentFrame,
      state.toolkit.getNodeByIdOnly,
      state.toolkit.setAnimatedValue,
    ],
    shallow,
  );
  const [currentKeyFrame, setCurrentKeyFrame] = useState('');
  const [currentShapeNode, setCurrentShapeNode] = useState(null as null | DagNode);
  const [isClosed, setIsClosed] = useState(false);

  const updateCurrentKeyFrame = useCallback(() => {
    setCurrentKeyFrame('');
    if (currentShapeNode) {
      const currentShape = currentShapeNode as PathShape;

      currentShape.shape.keyFrames.forEach((eachKeyFrame: Keyframe<CubicBezierShape, CubicBezierJSON>) => {
        if (eachKeyFrame.frame === currentFrame && !eachKeyFrame.isStatic) {
          setCurrentKeyFrame(eachKeyFrame.frameId as string);
        }
      });
    }
  }, [currentFrame, setCurrentKeyFrame, currentShapeNode]);

  useEffect(() => {
    updateCurrentKeyFrame();
  }, [updateCurrentKeyFrame]);

  useEffect(() => {
    // TODO: handle multi selection
    const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId as string);

    setCurrentShapeNode(node);

    if (node) {
      const shape = (node as PathShape).shape.value as CubicBezierShape;

      setIsClosed(shape.isClosed);
    }
  }, [selectedNodesInfo, getNodeByIdOnly]);

  const onDoneClick = useCallback(() => {
    setPropertyPanel(PropertyPanelType.Path);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_TRIM_START_UPDATED);
  }, [setPropertyPanel]);

  const onToggleOpenPathClick = useCallback(() => {
    const shape = (currentShapeNode as PathShape).shape.value as CubicBezierShape;

    shape.setIsClosed(!isClosed);
    setAnimatedValue(AnimatedType.PATH, [shape], currentShapeNode?.nodeId);

    setIsClosed((isClosedCurrent) => !isClosedCurrent);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED);
  }, [currentShapeNode, isClosed, setAnimatedValue]);

  const handleKeyframeClick = useCallback(() => {
    if (currentKeyFrame) {
      if ((currentShapeNode as PathShape).shape.keyFrames.length === 1) {
        (currentShapeNode as PathShape).shape.setIsAnimated(false);
      }
      removeKeyFrame(currentKeyFrame);
    } else {
      if (!(currentShapeNode as PathShape).shape.isAnimated) {
        (currentShapeNode as PathShape).shape.setIsAnimated(true);
      }

      (currentShapeNode as PathShape).shape.setValue((currentShapeNode as PathShape).shape.value);
    }
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED);
    updateCurrentKeyFrame();
  }, [currentShapeNode, updateCurrentKeyFrame, currentKeyFrame]);

  const isChannelAnimated = React.useMemo(() => {
    return Boolean((currentShapeNode as PathShape | null)?.shape.isAnimated);
  }, [currentShapeNode]);

  return (
    <>
      <div className="mb-2 w-[87px] rounded-r">
        <div className="relative flex items-center">
          <label
            className="group  flex h-[24px] w-full items-center rounded border border-transparent bg-gray-700 p-1 px-2 text-xs font-normal "
            data-testid="path-edit-container"
          >
            Path
          </label>
          <div className="absolute right-[-9px] top-[2px]">
            <KeyframeButton
              hasKeyframe={Boolean(currentKeyFrame)}
              onClick={handleKeyframeClick}
              isChannelAnimated={isChannelAnimated}
            />
          </div>
        </div>
      </div>
      <div
        onClick={onToggleOpenPathClick}
        className="mb-[8px] flex cursor-pointer justify-center rounded border border-gray-600 py-1 font-semibold hover:border-gray-400"
      >
        <div className="py-[2px] text-xs">{isClosed ? 'Open path' : 'Close path'}</div>
      </div>
      <div
        onClick={onDoneClick}
        className="flex cursor-pointer justify-center rounded border border-gray-600 bg-white py-1 font-semibold text-gray-700 hover:border-gray-400 hover:bg-gray-100"
      >
        <div className="py-[2px] text-xs">Done Editing</div>
      </div>
    </>
  );
};
