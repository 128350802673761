/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  AVLayer,
  CubicBezierJSON,
  DrawableBezierView,
  GroupShape,
  Scene,
  ShapeJSON,
  PathShape,
  MaskModeType,
  ShapeLayer,
  GroupBezierView,
  Shape,
} from '@lottiefiles/toolkit-js';
import { ShapeType, DagNodeType, CubicBezierShape, Vector } from '@lottiefiles/toolkit-js';
import type { Intersection, Mesh, MeshBasicMaterial, Object3D } from 'three';
import { Plane, Raycaster, Vector3 } from 'three';

import type { DrawableBezierShape } from '../../features/canvas/3d/threeFactory';
import { isPath, onMaskUpdate, updateBezierPlaneSize, getMaskModeIndex } from '../../features/canvas/3d/threeFactory';

import penPointerVertexImg from './pentool-icons/pen-adjust-vertex.svg';
import penPointerCloseImg from './pentool-icons/pen-pointer-close.svg';
import penPointerImg from './pentool-icons/pen-pointer.svg';

import { ToolType } from '~/data/constant';
import type { BezierMesh, CMesh, PathPoint, Viewport } from '~/features/canvas';
import {
  getBezierUniforms,
  updateBezierShape,
  BLUE_COLOR,
  CObject3D,
  createBezierLine,
  createBezierPoint,
  createControlPoint,
  createPathLine,
  findMeshByID,
  getMouseCoord,
  getVertexType,
  isFirstAndLastInSamePosition,
  UserDataMap,
  VertexType,
  WHITE_COLOR,
} from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { customEventTypes } from '~/lib/history';
import { SnapHelper } from '~/lib/threejs/TransformControls/SnapHelper';
import type { ShapeOption } from '~/lib/toolkit';
import { createShape, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { PropertyPanelType, ShapeTypes } from '~/store/constant';

const { setCurrentTool, setPathPointVertexTypes, setSelectedPathPointIndexes, zoomPercentage } =
  useCreatorStore.getState().ui;

export const collectPathNodes = (layer: ShapeLayer): Shape[] => {
  const pathNodes: Shape[] = [];

  layer.shapes.forEach((sh) => {
    if (isPath(sh)) pathNodes.push(sh);
    if (sh.type === ShapeType.GROUP) {
      pathNodes.push(...collectPathNodes(sh as unknown as ShapeLayer));
    }
  });

  return pathNodes;
};

export const extrudeSettings = { depth: 1, bevelEnabled: false };
const raycaster = new Raycaster();

enum BezierControl {
  In = 'in',
  Out = 'out',
}

interface NextPoint {
  bones: Object3D | null;
  vertex: Mesh | null;
}

class PathControls {
  public _closedPath = false;

  public _viewport: Viewport;

  public absoluteInOutPoints: PathPoint[] = [];

  public bezierControlPointsGroup = new CObject3D();

  public bezierLinesGroup = new CObject3D();

  public boneGroup = new CObject3D();

  public controlPointsGroup = new CObject3D();

  public draggingElement: Mesh | null | Object3D = null;

  public draggingElementInitialPosition: Vector3 | null = null;

  public draggingNextPoint: NextPoint | null = null;

  public group = new CObject3D();

  public hovering = false;

  public pathPoints: PathPoint[] = [];

  public penEnabled = false;

  public scale = 100 / zoomPercentage;

  public selectedMesh: BezierMesh | null = null;

  public selectedNode: PathShape | null = null;

  public shiftDown = false;

  public snapHelper = new SnapHelper();

  private readonly _domElement: HTMLCanvasElement = document as unknown as HTMLCanvasElement;

  private _selectedPointIndexes: number[] = [];

  public constructor(domElement: HTMLCanvasElement, viewport: Viewport) {
    this._domElement = domElement;
    this._viewport = viewport;

    this._domElement.addEventListener('pointerdown', this.pointerDownListener.bind(this), false);
    this._domElement.addEventListener('pointermove', this.pointerMoveListener.bind(this), false);
    this._domElement.addEventListener('pointerup', this.pointerUpListener.bind(this), false);

    document.body.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.shiftKey) {
        this.shiftDown = true;
        this.snapHelper.setShiftDown(true);
      }
    });
    document.body.addEventListener('keyup', (event: KeyboardEvent) => {
      if (!event.shiftKey) {
        this.shiftDown = false;
        this.snapHelper.setShiftDown(false);
        this.snapHelper.translationSnapEnd();
      }

      if (['backspace', 'del', 'delete'].includes(event.key.toLowerCase())) {
        const sortedIndexesFromBiggest = this._selectedPointIndexes.sort((aIndex, bIndex) => bIndex - aIndex);

        const MIN_ALLOWED_VERTEX_AFTER_DELETION = 3;

        if (this.pathPoints.length - sortedIndexesFromBiggest.length >= MIN_ALLOWED_VERTEX_AFTER_DELETION) {
          sortedIndexesFromBiggest.forEach((index: number) => {
            this.pathPoints.splice(index, 1);
          });

          this._selectedPointIndexes = [];
          setSelectedPathPointIndexes([]);

          this.updateToolkitPath();
          this.redrawPathControls();
        }
      }
    });

    useCreatorStore.subscribe(
      (state) => state.ui.zoomPercentage,
      (percentage) => this.onCameraZoomChange(percentage),
    );
    this._viewport.scene.add(this.group);
    this.group.add(this.boneGroup);
    this.group.add(this.controlPointsGroup);
    this.group.add(this.bezierControlPointsGroup);
    this.group.add(this.bezierLinesGroup);
    this.group.add(this.snapHelper.root);
  }

  public createNewPathShape(): void {
    if (this.pathPoints.length < 2) {
      return;
    }

    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const scene = toolkit.scenes[sceneIndex];
    const outPoint = useCreatorStore.getState().toolkit.json?.timeline.properties.op as number;

    if (scene && this.penEnabled && this.selectedMesh === null) {
      const pathOption: ShapeOption = {
        type: ShapeTypes.Path,
        stroke: [0, 0, 0, 1],
        startFrame: 0,
        endFrame: outPoint,
        name: 'PATH Shape',
        rotation: 0,
        position: [0, 0],
        fill: [199, 199, 199, 1],
        path: { pathPoints: this.pathPoints, isClosed: this._closedPath },
      };

      stateHistory.beginAction();
      createShape(scene as Scene, pathOption);

      this.penEnabled = false;

      emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
      stateHistory.endAction();
      setCurrentTool(ToolType.Move);
    }
  }

  public disablePenControls(resetCurrentTool = false): void {
    if (!this.penEnabled) return;

    if (this.selectedMesh === null) {
      this.createNewPathShape();
      if (resetCurrentTool) setCurrentTool(ToolType.Move);
    }

    this._viewport.updateContainerCursor();

    this.penEnabled = false;
    this.pathPoints = [];
    this.absoluteInOutPoints = [];
    this._selectedPointIndexes = [];
    this.selectedNode = null;

    if (this.selectedMesh?.outline) this.selectedMesh.outline.visible = false;
    this.selectedMesh = null;
    this.removePenMeshes();
    stateHistory.removeHandler(customEventTypes.addedPathPoint);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    stateHistory?.removeEvents?.((event) => event.type === customEventTypes.addedPathPoint);
    setSelectedPathPointIndexes([]);
    setPathPointVertexTypes([]);
  }

  public drawBezierLines(): void {
    this.bezierLinesGroup.children = this.bezierControlPointsGroup.children.map((point) => {
      const controlPoint = this.controlPointsGroup.children[point.userData['nodePoint']] as Mesh;

      return createBezierLine(controlPoint.position, point.position);
    });
  }

  public drawBezierPoint(index: number, type: string, point: Vector3): void {
    const bezierPoint = createBezierPoint();

    bezierPoint.scale.set(this.scale, this.scale, 1);
    bezierPoint.position.copy(point);
    bezierPoint.userData['nodePoint'] = index;
    bezierPoint.userData['type'] = type;

    this.bezierControlPointsGroup.add(bezierPoint);
  }

  public drawBezierPoints(): void {
    this.bezierControlPointsGroup.children = [];
    this._selectedPointIndexes.forEach((index: number) => {
      const vertexType = useCreatorStore.getState().ui.pathPointVertexTypes[index];

      if (vertexType === VertexType.SHARP) return;

      const inOutPoint = this.absoluteInOutPoints[index] as PathPoint;

      if (typeof inOutPoint === 'undefined') return;

      const InPoint = new Vector3(inOutPoint.in.x, inOutPoint.in.y, -20);

      this.drawBezierPoint(index, BezierControl.In, InPoint);

      const OutPoint = new Vector3(inOutPoint.out.x, inOutPoint.out.y, -35);

      this.drawBezierPoint(index, BezierControl.Out, OutPoint);
    });
  }

  public drawControlPoint(vertex: Vector | Vector3): void {
    const controlPoint = createControlPoint();

    const point = new Vector3(vertex.x, vertex.y, -45);

    controlPoint.scale.set(this.scale, this.scale, 1);
    controlPoint.position.copy(point);
    controlPoint.userData['nodePoint'] = this.controlPointsGroup.children.length;

    if (this._selectedPointIndexes.includes(controlPoint.userData['nodePoint'])) {
      ((controlPoint as CMesh).material as MeshBasicMaterial).color.setHex(BLUE_COLOR);
    }

    this.controlPointsGroup.add(controlPoint);
  }

  public drawMaskShape(): void {
    if (!this.selectedNode) return;
    const maskMode = getMaskModeIndex(this.selectedNode.state.properties.mo as MaskModeType);

    if (this.selectedMesh?.outline) {
      const maskUniforms = getBezierUniforms(this.selectedNode.shape.value, true);
      const outlineMaterial = (this.selectedMesh.outline as BezierMesh).material;

      outlineMaterial.uniforms['uBezierCount'] = { value: maskUniforms.uBezierCount };
      outlineMaterial.uniforms['uBezierPoints'] = { value: maskUniforms.uBezierPoints };
      outlineMaterial.needsUpdate = true;
      updateBezierPlaneSize(this.selectedMesh.outline.geometry, outlineMaterial.uniforms);
    }

    (this.selectedNode.parent as ShapeLayer).shapes.forEach((sh) => {
      if (isPath(sh)) {
        onMaskUpdate(this.selectedNode, this.selectedNode?.parent as GroupShape, maskMode);
      }
      if (sh.type === ShapeType.GROUP) {
        onMaskUpdate(this.selectedNode, sh as GroupShape, maskMode);
      }
    });
  }

  public drawPathPoint(event: MouseEvent): void {
    const mouseCords = getMouseCoord(event, this._domElement);
    const planeNormal = new Vector3();
    const plane = new Plane();

    planeNormal.copy(this._viewport.camera.position).normalize();
    plane.setFromNormalAndCoplanarPoint(planeNormal, this._viewport.scene.position);
    raycaster.setFromCamera(mouseCords, this._viewport.camera);

    this._viewport.camera.updateProjectionMatrix();

    const intersects = raycaster.intersectObjects(this.controlPointsGroup.children, false);

    if (intersects.length > 0) {
      if (intersects[0]?.object.userData['nodePoint'] === 0 && this.selectedNode === null) {
        this._closedPath = true;
        this._selectedPointIndexes = [0];

        return;
      }

      if (this.selectedNode !== null) {
        if ((intersects[0] as Intersection).object.type === 'Line') {
          this.draggingElement = (intersects[0] as Intersection).object.parent;
        } else {
          this.draggingElement = (intersects[0] as Intersection).object;
        }

        if (this._selectedPointIndexes.length > 0 && !this.shiftDown) {
          this._selectedPointIndexes.forEach((index: number) =>
            ((this.controlPointsGroup.children[index] as CMesh).material as MeshBasicMaterial).color.setHex(
              WHITE_COLOR,
            ),
          );
          this._selectedPointIndexes = [];
        }
        this._selectedPointIndexes = [...this._selectedPointIndexes, this.draggingElement?.userData['nodePoint']];

        setSelectedPathPointIndexes([...this._selectedPointIndexes]);

        ((this.draggingElement as CMesh).material as MeshBasicMaterial).color.setHex(BLUE_COLOR);

        if (this.shiftDown) {
          event.stopImmediatePropagation();
        }

        this.drawBezierPoints();
        this.drawBezierLines();

        return;
      }
    } else {
      setSelectedPathPointIndexes([]);
    }

    const bezierIntesects = raycaster.intersectObjects(this.bezierControlPointsGroup.children, false);

    if (bezierIntesects.length > 0) {
      if ((bezierIntesects[0] as Intersection).object.type === 'Line') {
        this.draggingElement = (bezierIntesects[0] as Intersection).object.parent;
      } else {
        this.draggingElement = (bezierIntesects[0] as Intersection).object;
      }
    }

    if (this.selectedNode === null) {
      const point = new Vector3();

      raycaster.ray.intersectPlane(plane, point);

      const pathPoint = {
        vertex: new Vector(point.x, point.y),
        in: new Vector(0, 0),
        out: new Vector(0, 0),
      };

      this.pathPoints.push(pathPoint);

      this.absoluteInOutPoints.push({
        vertex: new Vector(point.x, point.y),
        in: new Vector(point.x, point.y),
        out: new Vector(point.x, point.y),
      });

      this.drawControlPoint(pathPoint.vertex);
      this.drawPathShape();

      this._selectedPointIndexes = [this._closedPath ? 0 : this.pathPoints.length - 1];

      stateHistory.add({
        data: {
          controlPoint: this.controlPointsGroup.children[this.controlPointsGroup.children.length - 1] as Mesh,
          pathPoint,
        },
        target: this._viewport.scene,
        type: customEventTypes.addedPathPoint,
      });
    }
  }

  public drawPathShape(): void {
    if (this.selectedMesh && this.selectedNode && this.selectedNode.parent) {
      if (this.selectedNode.nodeType === DagNodeType.MASK) {
        this.drawMaskShape();

        return;
      }
      const bezierGroup = (this.selectedNode.parent as DrawableBezierShape).toBezier() as unknown as
        | DrawableBezierView[]
        | GroupBezierView[];

      if (this.selectedNode.nodeType !== DagNodeType.SHAPE) return;
      for (const bezierView of bezierGroup) {
        if (bezierView.isGroup) {
          for (const bVew of bezierView.bezierViews) {
            for (const bezier of (bVew as DrawableBezierView).beziers) {
              updateBezierShape(bezier, this.selectedMesh as BezierMesh);
            }
          }
        } else {
          for (const bezier of bezierView.beziers) {
            updateBezierShape(bezier, this.selectedMesh as BezierMesh);
          }
        }
      }
    } else {
      this.boneGroup.children = [];
      if (this.draggingNextPoint?.bones) {
        this.group.remove(this.draggingNextPoint.bones);
      }
      const pathPoints = [...this.pathPoints];

      if (this._closedPath) {
        pathPoints.push(this.pathPoints[0] as PathPoint);
      }
      pathPoints.forEach((eachPoint, index) => {
        if (index === 0) return;
        const bone = createPathLine([pathPoints[index - 1] as PathPoint, eachPoint], this.scale);

        this.boneGroup.add(bone);
      });
    }
  }

  public enablePenControls(): void {
    this._closedPath = false;
    this.penEnabled = true;

    stateHistory.addHandler(customEventTypes.addedPathPoint, {
      undo: (event) => {
        this.pathPoints = this.pathPoints.slice(0, this.pathPoints.length - 1);
        this.drawPathShape();
        this.controlPointsGroup.remove((event.data as Record<string, unknown>)['controlPoint'] as Mesh);
        if (this.draggingNextPoint) {
          this._updateDraggingNextPointBones(this.draggingNextPoint.vertex?.position as Vector3);
        }
      },
      redo: (event) => {
        const data = event.data as Record<string, unknown>;

        this.pathPoints.push(data['pathPoint'] as PathPoint);
        this.drawPathShape();
        this.controlPointsGroup.add(data['controlPoint'] as Mesh);
        if (this.draggingNextPoint) {
          this._updateDraggingNextPointBones(this.draggingNextPoint.vertex?.position as Vector3);
        }
      },
    });
  }

  public nudgeSelectedPathPoints(offset: Vector): void {
    this._selectedPointIndexes.forEach((index) => {
      const pathPoint = this.pathPoints[index];

      if (pathPoint) pathPoint.vertex.add(offset);
    });

    this.updateToolkitPath();

    emitter.emit(EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED);
  }

  public onCameraZoomChange(zoom: number): void {
    // keep the control point size always the same on the screen
    this.scale = 100 / zoom;

    this.controlPointsGroup.children.forEach((point) => point.scale.set(this.scale, this.scale, 1));
    this.bezierControlPointsGroup.children.forEach((point) => point.scale.set(this.scale, this.scale, 1));
  }

  public pointerDownListener(event: MouseEvent): void {
    if (!this.penEnabled || event.button === 2 || this._viewport.movePlayHeadTool.isMovingPlayHead) {
      return;
    }
    if (this.selectedMesh) {
      stateHistory.beginAction();
    }

    this.drawPathPoint(event);
    this.draggingElementInitialPosition = this.draggingElement?.position.clone() ?? null;
  }

  public pointerMoveListener(event: MouseEvent): void {
    this.hovering = false;
    if (!this.penEnabled || this._viewport.movePlayHeadTool.isMovingPlayHead) {
      return;
    }
    if (!this.selectedNode) {
      this._viewport.updateContainerCursor(`url(${penPointerImg}) 1 1, pointer`, ToolType.Pen);
    }

    const mouseCords = getMouseCoord(event, this._domElement);
    const planeNormal = new Vector3();
    const plane = new Plane();
    const point = new Vector3();

    planeNormal.copy(this._viewport.camera.position).normalize();
    plane.setFromNormalAndCoplanarPoint(planeNormal, this._viewport.scene.position);
    raycaster.setFromCamera(mouseCords, this._viewport.camera);
    raycaster.ray.intersectPlane(plane, point);
    point.setZ(-45);

    // dragging
    if (event.buttons === 1) {
      this._viewport.updateContainerCursor(`url(${penPointerVertexImg}) 2 3, pointer`, ToolType.Pen);

      if (this.draggingElement !== null) {
        if (this.draggingElementInitialPosition && this.shiftDown) {
          const offset = point.clone().sub(this.draggingElementInitialPosition);
          const snappedOffset = this.snapHelper.translationSnap(offset, this.draggingElementInitialPosition, point);

          point.copy(snappedOffset).add(this.draggingElementInitialPosition);
          this.snapHelper.updateLine(1, point);
        }

        this.draggingElement.position.copy(point);

        const pointIndex = this.draggingElement.userData['nodePoint'];
        const pointType = this.draggingElement.userData['type'] as BezierControl | undefined;

        if (pointType && this.selectedNode) {
          const parentNode = this.selectedNode.parent as AVLayer | GroupShape;

          const relativeVertex = parentNode.getPositionRelativeTo((this.pathPoints[pointIndex] as PathPoint).vertex);
          const relativeTangents = parentNode
            .getPositionRelativeTo(new Vector(point.x, point.y))
            .subtractToClone(relativeVertex);

          const vertexType = useCreatorStore.getState().ui.pathPointVertexTypes[pointIndex];
          const oppositePointType = pointType === BezierControl.In ? BezierControl.Out : BezierControl.In;

          if (vertexType === VertexType.MIRRORED) {
            (this.pathPoints[pointIndex] as PathPoint)[oppositePointType] = new Vector(
              relativeTangents.x * -1,
              relativeTangents.y * -1,
            );
          } else if (vertexType === VertexType.CURVE) {
            const oppositeVector = (this.pathPoints[pointIndex] as PathPoint)[oppositePointType];
            const oppositeDistance: number = Math.sqrt((-oppositeVector.x) ** 2 + (-oppositeVector.y) ** 2);
            const distance = Math.sqrt((-relativeTangents.x) ** 2 + (-relativeTangents.y) ** 2);
            const diffDistance = oppositeDistance - distance;
            const oppositeX = (relativeTangents.x + (diffDistance * relativeTangents.x) / distance) * -1;
            const oppositeY = (relativeTangents.y + (diffDistance * relativeTangents.y) / distance) * -1;

            (this.pathPoints[pointIndex] as PathPoint)[oppositePointType] = new Vector(oppositeX, oppositeY);
          }
          (this.pathPoints[pointIndex] as PathPoint)[pointType] = relativeTangents;
        } else {
          (this.pathPoints[pointIndex] as PathPoint).vertex = new Vector(point.x, point.y);
        }
        this.updateAbsolutePoints();
        this.drawBezierPoints();
        this.drawBezierLines();
        this.updateToolkitPath();
        this.drawPathShape();
      }

      // during the creation of a new path shape show the bezier lines
      if (!this.selectedNode) {
        const pointIndex = this._closedPath ? 0 : this.pathPoints.length - 1;

        const relativeTangents = new Vector(point.x, point.y).subtractToClone(
          (this.pathPoints[pointIndex] as PathPoint).vertex,
        );

        const isStart = pointIndex === 0 && !this._closedPath;
        const isEnd = pointIndex === 0 && this._closedPath;
        const isMiddle = !isStart && !isEnd;

        if (isStart || isMiddle) {
          (this.pathPoints[pointIndex] as PathPoint).out = relativeTangents;
          (this.absoluteInOutPoints[pointIndex] as PathPoint).out = new Vector(point.x, point.y);
        }

        if (isEnd || isMiddle) {
          (this.pathPoints[pointIndex] as PathPoint).in = new Vector(relativeTangents.x * -1, relativeTangents.y * -1);
          (this.absoluteInOutPoints[pointIndex] as PathPoint).in = (
            this.pathPoints[pointIndex] as PathPoint
          ).vertex.subtractToClone(relativeTangents);
        }

        this.drawBezierPoints();
        this.drawBezierLines();
        this.drawPathShape();
      }
    } else if (this.selectedNode) {
      const intersects = raycaster.intersectObjects(
        [...this.controlPointsGroup.children, ...this.bezierControlPointsGroup.children],
        false,
      );

      if (intersects.length > 0) {
        this._viewport.updateContainerCursor(`url(${penPointerVertexImg}) 2 3, auto`, ToolType.Pen);
      } else {
        this._viewport.updateContainerCursor();
      }
      this.hovering = intersects.length > 0;
    } else {
      const intersects = raycaster.intersectObjects(this.controlPointsGroup.children, false);

      if (intersects.length > 0) {
        if (intersects[0]?.object.userData['nodePoint'] === 0) {
          this._viewport.updateContainerCursor(`url(${penPointerCloseImg}) 1 1, pointer`, ToolType.Pen);
        }
      }

      if (this.draggingNextPoint) {
        this.draggingNextPoint.vertex?.position.copy(point);
        this._updateDraggingNextPointBones(point);
      } else {
        const controlPoint = createControlPoint();

        controlPoint.scale.set(this.scale, this.scale, 1);
        point.setZ(-45);
        controlPoint.position.copy(point);
        controlPoint.userData['nodePoint'] = this.controlPointsGroup.children.length;

        this.draggingNextPoint = {
          vertex: controlPoint,
          bones: null,
        };
        this.group.add(controlPoint);
      }
    }
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  public pointerUpListener(): void {
    this.draggingElement = null;
    this.snapHelper.translationSnapEnd();

    if (this.selectedNode && useCreatorStore.getState().ui.selectedPathPointIndexes.length > 0) {
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED);
    }

    if (!this.selectedMesh) {
      if (this._closedPath) {
        this.createNewPathShape();
      }

      this._selectedPointIndexes = [];
      this.drawBezierPoints();
      this.drawBezierLines();
    }

    if (!this.penEnabled) return;
    if (this.selectedMesh) {
      stateHistory.endAction();
    }
  }

  public redrawPathControls(): void {
    if (this.penEnabled || this.boneGroup.children.length > 0) {
      this.removePenMeshes();
      if (this.selectedNode) {
        this.setPathShape(this.selectedNode);
        this.drawBezierPoints();
        this.drawBezierLines();
      }
      emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
    }
  }

  public removePenMeshes(): void {
    this.pathPoints = [];

    this.controlPointsGroup.children = [];
    this.bezierControlPointsGroup.children = [];
    this.boneGroup.children = [];
    if (this.draggingNextPoint?.vertex) {
      this.group.remove(this.draggingNextPoint.vertex);
    }
    if (this.draggingNextPoint?.bones) {
      this.group.remove(this.draggingNextPoint.bones);
    }
    this.draggingNextPoint = null;
    this.bezierLinesGroup.children = [];
  }

  public selectPathPoints(pathPointIndexes: number[]): void {
    this._selectedPointIndexes = pathPointIndexes;
    setSelectedPathPointIndexes(pathPointIndexes);

    this.controlPointsGroup.children.forEach((child) =>
      ((child as CMesh).material as MeshBasicMaterial).color.setHex(WHITE_COLOR),
    );
    const controlPoints = this.controlPointsGroup.children.filter((controlPoint) =>
      pathPointIndexes.includes(controlPoint.userData['nodePoint']),
    );

    controlPoints.forEach((controlPoint) => {
      ((controlPoint as CMesh).material as MeshBasicMaterial).color.setHex(BLUE_COLOR);
    });

    this.drawBezierPoints();
    this.drawBezierLines();
  }

  public setPathPoints(node: PathShape, drawControlPoint = false): void {
    if (!this.penEnabled) return;
    const pathValues = node.state.animatedProperties.sh.value as CubicBezierJSON | null;

    if (!pathValues) return;
    const pathPoints = pathValues.points as PathPoint[];
    const isClosed = pathValues.closed;

    this.selectedMesh = findMeshByID(this._viewport.objectContainer, node.nodeId) as unknown as BezierMesh | null;
    if (!this.selectedMesh) return;
    this.selectedNode = node;

    this.pathPoints = [];
    this.absoluteInOutPoints = [];

    const pathPointVertexTypes: VertexType[] = [...useCreatorStore.getState().ui.pathPointVertexTypes];

    const { inTangents, outTangents, vertices } = node.absoluteCoordinates;

    vertices.forEach((vertex: Vector | Vector3, index: number) => {
      if (drawControlPoint) {
        this.drawControlPoint(vertex);
      }
      this.pathPoints.push({
        vertex: new Vector(vertex.x, vertex.y),
        in: (pathPoints[index] as PathPoint).in,
        out: (pathPoints[index] as PathPoint).out,
      });

      this.absoluteInOutPoints.push({
        vertex: new Vector(vertex.x, vertex.y),
        in: new Vector(inTangents[index]?.x, inTangents[index]?.y),
        out: new Vector(outTangents[index]?.x, outTangents[index]?.y),
      });

      if (!pathPointVertexTypes[index]) {
        pathPointVertexTypes[index] = getVertexType(
          (this.absoluteInOutPoints[index] as PathPoint).in,
          (this.absoluteInOutPoints[index] as PathPoint).vertex,
          (this.absoluteInOutPoints[index] as PathPoint).out,
        );
      }
    });

    if (isClosed && !node.getData(UserDataMap.FirstAndLastResolved) && !node.shape.isAnimated) {
      const firstPoint = this.pathPoints[0];
      const lastPoint = this.pathPoints[pathPoints.length - 1];

      if (firstPoint && lastPoint && isFirstAndLastInSamePosition(firstPoint.vertex, lastPoint.vertex)) {
        firstPoint.in = lastPoint.in;

        this.pathPoints.pop();
        this.absoluteInOutPoints.pop();
        this.controlPointsGroup.children.pop();
        node.setData(UserDataMap.FirstAndLastResolved, true);
        this.updateToolkitPath();
      }
    }

    if (this.selectedMesh.outline) {
      this.selectedMesh.outline.visible = true;
    }
    setPathPointVertexTypes(pathPointVertexTypes);
  }

  public setPathShape(node: PathShape): void {
    this.setPathPoints(node, true);
    this.drawPathShape();
    this.group.visible = true;
    this.bezierControlPointsGroup.visible = true;
    this.bezierLinesGroup.visible = true;
  }

  public updateAbsolutePoints(): void {
    if (!this.selectedNode) return;
    const coords = this.selectedNode.absoluteCoordinates;

    this.absoluteInOutPoints = coords.vertices.map((vertex: Vector, index: number) => ({
      vertex: new Vector(vertex.x, vertex.y),
      in: new Vector(coords.inTangents[index]?.x, coords.inTangents[index]?.y),
      out: new Vector(coords.outTangents[index]?.x, coords.outTangents[index]?.y),
    }));
  }

  /**
   *  Draw the path lines or control points if a path shape or parent group shape is selected
   */
  public updatePathShapeControls(node: PathShape): void {
    const currentPropertyPanel = useCreatorStore.getState().ui.currentPropertyPanel;
    const currentTool = useCreatorStore.getState().ui.currentTool;

    if (currentTool !== ToolType.Pen) this.disablePenControls();
    if (
      (node.nodeType === DagNodeType.SHAPE || node.nodeType === DagNodeType.MASK) &&
      (currentPropertyPanel === PropertyPanelType.EditPath || currentPropertyPanel === PropertyPanelType.Mask)
    ) {
      this.enablePenControls();
      this.setPathShape(node);
    }
  }

  public updateToolkitPath(): void {
    if (!this.selectedNode || this.pathPoints.length === 0) return;
    if (this.selectedNode.nodeType !== DagNodeType.SHAPE && this.selectedNode.nodeType !== DagNodeType.MASK) return;
    const parentNode = this.selectedNode.parent as AVLayer | GroupShape;

    const vertexPoints = this.pathPoints.map((pathPoint) => parentNode.getPositionRelativeTo(pathPoint.vertex));
    const inPoints = this.pathPoints.map((pathPoint) => new Vector(pathPoint.in.x, pathPoint.in.y));
    const outPoints = this.pathPoints.map((pathPoint) => new Vector(pathPoint.out.x, pathPoint.out.y));
    const { closed } = (this.selectedNode.state as ShapeJSON).animatedProperties.sh.value as CubicBezierJSON;

    const cubicBezierPath = new CubicBezierShape()
      .setVertices(vertexPoints)
      .setInTangents(inPoints)
      .setOutTangents(outPoints)
      .setIsClosed(closed);

    (this.selectedNode as PathShape).shape.setValue(cubicBezierPath);
  }

  private _updateDraggingNextPointBones(point: Vector3): void {
    if (this.draggingNextPoint && this.pathPoints.length > 0) {
      if (this.draggingNextPoint.bones) {
        this.group.remove(this.draggingNextPoint.bones);
      }

      const lastPoint = this.pathPoints[this.pathPoints.length - 1];

      const pathPoint = {
        vertex: new Vector(point.x, point.y),
        in: new Vector(0, 0),
        out: new Vector(0, 0),
      };

      this.draggingNextPoint.bones = createPathLine([lastPoint as PathPoint, pathPoint], this.scale, BLUE_COLOR, 1);
      this.group.add(this.draggingNextPoint.bones);
    }
  }
}

export { PathControls };
