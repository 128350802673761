/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import { shallow } from 'zustand/shallow';

import { PolystarOuterRadiusField } from './field/PolystarOuterRadiusField';
import { PolystarOuterRoundnessField } from './field/PolystarOuterRoundnessField';
import { PolystarPointsField } from './field/PolystarPointsField';
import { PolystarRotationField } from './field/PolystarRotationField';
import { ShapePositionField } from './field/ShapePositionField';
import { ShapeConvertToPathButton } from './ShapeConvertToPathButton';

import { getCurrentShape } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const PolygonProperty: React.FC = () => {
  const [, selectedNodesInfo, getNodeByIdOnly] = useCreatorStore(
    (state) => [state.toolkit.currentFrame, state.ui.selectedNodesInfo, state.toolkit.getNodeByIdOnly],
    shallow,
  );

  const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId ?? '');
  const currentShape = getCurrentShape(node);
  const { outerRadius, outerRoundness, points, rotation } = currentShape.polystar;

  const { position } = currentShape;

  return (
    <div>
      <ShapePositionField position={position} positionIsAnimated={currentShape.positionIsAnimated} />

      <div className="mt-2">
        <PolystarRotationField rotation={rotation} rotationIsAnimated={currentShape.polystar.rotationIsAnimated} />
      </div>

      <div className="mr-[38px] mt-2 flex space-x-2">
        <PolystarPointsField points={points} pointsIsAnimated={currentShape.polystar.pointsIsAnimated} />
        <PolystarOuterRadiusField
          outerRadius={outerRadius}
          outerRadiusIsAnimated={currentShape.polystar.outerRadiusIsAnimated}
        />
      </div>

      <div className="mr-[132px] mt-2 flex">
        <PolystarOuterRoundnessField
          outerRoundness={outerRoundness}
          outerRoundnessIsAnimated={currentShape.polystar.outerRoundnessIsAnimated}
        />
      </div>
      <ShapeConvertToPathButton />
    </div>
  );
};
