/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

import { LAYER_PANEL_DEFAULT_WIDTH } from './constant';
import { TimelineControl } from './TimelineKeyframePanel/TimelineControl';

import { TimelineSourceLayer } from '~/assets/icons';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

export enum TimelineLayerLabelType {
  LAYERS = 'Layers',
  SOURCE = 'Source',
}

export const TimelineHeader: React.FC = () => {
  const showPrecompSource = useCreatorStore((state) => state.timeline.showPrecompSource);

  return (
    <div className="flex">
      <div
        className="mt-1 h-[17px] shrink-0 items-center text-caption font-medium text-gray-300"
        style={{ width: LAYER_PANEL_DEFAULT_WIDTH }}
      >
        <div
          className="flex cursor-pointer"
          onClick={() => {
            emitter.emit(EmitterEvent.TIMELINE_TOGGLE_PRECOMP_SOURCE_NAME);
          }}
        >
          <div>{showPrecompSource ? TimelineLayerLabelType.SOURCE : TimelineLayerLabelType.LAYERS}</div>
          <TimelineSourceLayer className="ml-1 mt-1 h-3.5 w-3.5 "></TimelineSourceLayer>
        </div>
      </div>
      <div id="timelinecontrol-container" className="relative mr-2 h-[20px] w-full bg-gray-700">
        <TimelineControl />
      </div>
    </div>
  );
};

export default TimelineHeader;
