/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useEffect, useRef } from 'react';

import KeyframeButton from '../Button/KeyframeButton';
import { Tooltip } from '../Tooltip';

import { NumberInput } from './NumberInput';
import type { NumberInputProps } from './NumberInput';
import type { NumberResult } from './types';

const leftStyle = {
  label: 'p-1 h-[22px] w-full text-xs font-normal rounded-l',
};

const rightStyle = {
  label: 'p-1 pr-[10px] h-[22px] w-full text-xs font-normal rounded-l',
};

export type NumberInputOption = Omit<NumberInputProps, 'showKeyframe' | 'onKeyframeClick' | 'onChange' | 'value'>;

interface DualNumberInputProps {
  hasKeyframe?: boolean;
  isChannelAnimated: boolean;
  keyframeShortcut?: string;
  left: number;
  leftMax?: number;
  leftMessage?: string | null;
  leftMin?: number;
  leftOption: NumberInputOption;
  onChange: (result: NumberResult) => void;
  onKeyframeClick?: () => void;
  right: number;
  rightMax?: number;
  rightMessage?: string | null;
  rightMin?: number;
  rightOption: NumberInputOption;
  showKeyframe?: boolean;
  testID1?: string;
  testID2?: string;
  tooltip?: {
    left: string;
    right: string;
  };
}

export const DualNumberInput: React.FC<DualNumberInputProps> = ({
  hasKeyframe = false,
  isChannelAnimated = false,
  keyframeShortcut = '',
  left,
  leftMax = null,
  leftMessage,
  leftMin = null,
  leftOption,
  onChange,
  onKeyframeClick,
  right,
  rightMax = null,
  rightMessage,
  rightMin = null,
  rightOption,
  showKeyframe = false,
  testID1,
  testID2,
  tooltip,
}) => {
  const onKeyframeClickHandler = useRef(onKeyframeClick);

  // store handler
  useEffect(() => {
    onKeyframeClickHandler.current = onKeyframeClick;
  }, [onKeyframeClick]);

  const handleOnKeyframeClick = useCallback(() => {
    if (onKeyframeClickHandler.current) {
      onKeyframeClickHandler.current();
    }
  }, []);

  return (
    <div className="relative flex items-center">
      <div className="number-input-label flex w-[182px] rounded border border-transparent bg-gray-700 focus-within:border-teal-500">
        <Tooltip content={tooltip?.left} disabled={!tooltip?.left}>
          <div>
            <NumberInput
              styleClass={leftStyle}
              {...leftOption}
              value={left}
              min={leftMin}
              max={leftMax}
              onChange={onChange}
              testID={testID1 ?? 'number-input1'}
              message={leftMessage}
            />
          </div>
        </Tooltip>
        <div className="my-[-1px] border border-gray-800"></div>
        <Tooltip content={tooltip?.right} disabled={!tooltip?.right}>
          <div>
            <NumberInput
              styleClass={rightStyle}
              {...rightOption}
              value={right}
              min={rightMin}
              max={rightMax}
              onChange={onChange}
              testID={testID2 ?? 'number-input2'}
              message={rightMessage}
            />
          </div>
        </Tooltip>
      </div>
      {showKeyframe && (
        <div className="absolute right-[-9px] top-[2px]">
          <KeyframeButton
            onClick={handleOnKeyframeClick}
            hasKeyframe={hasKeyframe}
            withTooltip
            isChannelAnimated={isChannelAnimated}
            keyframeShortcut={keyframeShortcut}
          />
        </div>
      )}
    </div>
  );
};
