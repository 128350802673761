/**
 * Copyright 2023 Design Barn Inc.
 */

import type { SceneJSON } from '@lottiefiles/toolkit-js';
import React, { useCallback, useRef, useEffect, useState, type MouseEventHandler } from 'react';
import Draggable from 'react-draggable';
import type { DraggableEvent } from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { TIMELINE_BAR_BEGIN_OFFSET_PX } from '../constant';

import { useTimelineUtils } from './hooks';

import { ContextMenuWrapper, MENU_HEIGHT, MENU_WIDTH } from '~/features/menu';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { GlobalCursorType } from '~/store/uiSlice';

interface Props {}

export const TimelineTimeScale: React.FC<Props> = () => {
  const setGlobalCursor = useCreatorStore.getState().ui.setGlobalCursor;

  const ttsDraggableRef = useRef(null);
  const ttsDraggableNodeRef = useRef(null);

  const [dragWorkAreaFrameStart, setDragWorkAreaFrameStart] = useState<number | null>(null);
  const [dragWorkAreaFrameEnd, setDragWorkAreaFrameEnd] = useState<number | null>(null);
  const [dragStartFrame, setDragStartFrame] = useState<number | null>(null);

  const [
    start,
    fps,
    duration,
    setCurrentFrame,
    precomFps,
    precomDuration,
    selectedPrecompositionId,
    setSelectedPrecompositionJson,
    getNodeByIdOnly,
    width,
    setIsScrubbing,
    workAreaFrameStart,
    workAreaFrameEnd,
    setWorkAreaFrameStart,
    setWorkAreaFrameEnd,
    isWorkAreaScrubbing,
    setIsWorkAreaScrubbing,
  ] = useCreatorStore(
    (state) => [
      (state.toolkit.json?.timeline.properties.ip as number) || 0,
      (state.toolkit.json?.timeline.properties.fr as number) || 30,
      (state.toolkit.json?.timeline.duration as number) || 0,
      state.toolkit.setCurrentFrame,
      (state.toolkit.selectedPrecompositionJson?.timeline.properties.fr as number) || 20,
      (state.toolkit.selectedPrecompositionJson?.timeline.duration as number) || 0,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.setSelectedPrecompositionJson,
      state.toolkit.getNodeByIdOnly,
      state.timeline.keyframeScrubberWidth,
      state.timeline.setIsScrubbing,
      state.timeline.workAreaFrameStart,
      state.timeline.workAreaFrameEnd,
      state.timeline.setWorkAreaFrameStart,
      state.timeline.setWorkAreaFrameEnd,
      state.timeline.isWorkAreaScrubbing,
      state.timeline.setIsWorkAreaScrubbing,
    ],
    shallow,
  );

  const { finalWidth, getXPosFromFrame, totalFrames } = useTimelineUtils(width);

  const ref = useRef<HTMLDivElement>(null);

  const getClickedFrame = useCallback(
    (evt: DraggableEvent): number => {
      const timescale = ref.current;

      if (timescale) {
        const bounds = timescale.getBoundingClientRect();

        const x = (evt as MouseEvent).clientX - bounds.left;

        if (x < 0) {
          return 0;
        }

        const widthRatio = x / finalWidth;

        let cf = Math.round(widthRatio * totalFrames);

        if (cf >= Math.round(totalFrames)) {
          cf = Math.round(totalFrames - 1);
        }

        return cf;
      }

      return 0;
    },
    [finalWidth, totalFrames],
  );

  const executeClick = useCallback(
    (evt: DraggableEvent) => {
      const cf = getClickedFrame(evt);

      if (selectedPrecompositionId) {
        const assetNode = getNodeByIdOnly(selectedPrecompositionId);

        if (assetNode) {
          stateHistory.offTheRecord(() => {
            assetNode.timeline.setCurrentFrame(cf);
          });

          setSelectedPrecompositionJson(assetNode.state as SceneJSON);
        }
      }
      setCurrentFrame(cf);

      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
    },
    [getClickedFrame, selectedPrecompositionId, setCurrentFrame, getNodeByIdOnly, setSelectedPrecompositionJson],
  );

  const [blockWidth, setBlockWidth] = useState(100);
  const [dropdownCoords, setDropdownCoords] = useState<Record<'x' | 'y', number> | null>(null);

  useEffect(() => {
    setBlockWidth(
      (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX) /
        (selectedPrecompositionId && precomDuration ? precomDuration : duration),
    );
  }, [width, duration, selectedPrecompositionId, precomDuration]);

  const moveWorkArea = useCallback(
    (evt: DraggableEvent) => {
      if (dragStartFrame !== null && dragWorkAreaFrameStart !== null && dragWorkAreaFrameEnd !== null) {
        const cf = getClickedFrame(evt);
        const diff = cf - dragStartFrame;

        const newStart = dragWorkAreaFrameStart + diff;
        const newEnd = dragWorkAreaFrameEnd + diff;

        if (newStart < 0 || newEnd > totalFrames) {
          return;
        }

        setWorkAreaFrameStart(newStart);
        setWorkAreaFrameEnd(newEnd);
      }
    },
    [
      dragStartFrame,
      getClickedFrame,
      dragWorkAreaFrameStart,
      dragWorkAreaFrameEnd,
      totalFrames,
      setWorkAreaFrameStart,
      setWorkAreaFrameEnd,
    ],
  );

  const handleOnDrag = (event: DraggableEvent): void => {
    event.preventDefault();

    if (event.altKey) {
      moveWorkArea(event);
    } else {
      executeClick(event);
    }
  };

  const handleOnStart = (event: DraggableEvent): void => {
    if (event.altKey) {
      setIsWorkAreaScrubbing(true);
      setDragWorkAreaFrameStart(workAreaFrameStart);
      setDragWorkAreaFrameEnd(workAreaFrameEnd);
      setDragStartFrame(getClickedFrame(event));

      return;
    }

    event.preventDefault();
    executeClick(event);
    setIsScrubbing(true);
    setGlobalCursor(GlobalCursorType.GRABBING);
  };

  const handleOnStop = (event: DraggableEvent): void => {
    setIsWorkAreaScrubbing(false);

    if (event.altKey) {
      setDragWorkAreaFrameStart(null);
      setDragWorkAreaFrameEnd(null);

      return;
    }

    setIsScrubbing(false);
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED,
    });
    setGlobalCursor(GlobalCursorType.DEFAULT);
  };

  const onDropdownOpen: MouseEventHandler<HTMLElement> = useCallback((evt) => {
    setDropdownCoords({
      x:
        evt.clientX + MENU_WIDTH.TimelineTopbarMenu < window.innerWidth
          ? evt.clientX
          : evt.clientX - MENU_WIDTH.TimelineTopbarMenu,
      y:
        evt.clientY + MENU_HEIGHT.TimelineTopbarMenu < window.innerHeight
          ? 28
          : -1 * MENU_HEIGHT.TimelineTopbarMenu - 8,
    });
  }, []);

  const handleSetWorkAreaAsSegment = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_SET_WORKAREA_AS_SEGMENT);
  }, []);

  const handleTrimSceneToWorkArea = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_TRIM_SCENE_TO_WORKAREA);
  }, []);

  const handleResetWorkArea = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_RESET_WORKAREA);
  }, []);

  const workAreaFrameStartPos = getXPosFromFrame(workAreaFrameStart);
  const workAreaFrameEndPos = getXPosFromFrame(workAreaFrameEnd);

  // The reason why use margin-left instead of padding left for timescale make calculation easier
  // by avoiding using constant like TIMELINE_OFFSET_LEFT_X in calculation
  return (
    <div className="flex h-4 w-full rounded bg-gray-800">
      <div ref={ref} className={'relative mx-[15px] flex h-full w-full flex-nowrap'}>
        <Draggable
          ref={ttsDraggableRef}
          axis="none"
          onStart={handleOnStart}
          onDrag={handleOnDrag}
          onStop={handleOnStop}
        >
          <div ref={ttsDraggableNodeRef} id="timeline-scale" className="relative flex h-full w-full flex-nowrap">
            <div
              style={{ left: `${workAreaFrameStartPos}px`, width: `${workAreaFrameEndPos - workAreaFrameStartPos}px` }}
              className={`absolute top-0 h-full rounded-sm hover:bg-white ${isWorkAreaScrubbing ? 'bg-white' : ''}`}
              onAuxClickCapture={onDropdownOpen}
            />
            {duration &&
              [...Array(Math.ceil((selectedPrecompositionId ? precomDuration : duration) + 1))].map((_value, key) => {
                return (
                  <div key={key}>
                    <div
                      className="pointer-events-none absolute top-[2px] text-[8px] text-gray-300"
                      style={{ left: `${blockWidth * key - 3}px` }}
                    >
                      {start / (selectedPrecompositionId ? precomFps : fps) + key}s
                      <div className="absolute  text-[8px]"></div>
                    </div>
                    {[...Array(4)].map((_subValue, subKey) => (
                      <div
                        key={`${subKey}`}
                        className="pointer-events-none absolute text-[10px]"
                        style={{ left: `${blockWidth * key + (blockWidth / 5) * (subKey + 1)}px` }}
                      >
                        <div className="absolute left-[0px] mt-[0.55em] h-[5px] w-[1px] bg-gray-600 "></div>
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
        </Draggable>
      </div>
      {dropdownCoords ? (
        <ContextMenuWrapper
          coord={dropdownCoords}
          onClose={() => setDropdownCoords(null)}
          dropdownItems={[
            {
              type: 'SetWorkAreaAsSegment',
              label: 'Set work area as segment',
              callback: handleSetWorkAreaAsSegment,
              disabled: selectedPrecompositionId !== null,
            },
            {
              type: 'TrimSceneToWorkArea',
              label: 'Trim scene to work area',
              callback: handleTrimSceneToWorkArea,
            },
            {
              type: 'ResetWorkArea',
              label: 'Reset work area',
              callback: handleResetWorkArea,
            },
          ]}
        />
      ) : null}
    </div>
  );
};
