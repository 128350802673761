/**
 * Copyright 2021 Design Barn Inc.
 */

import React, { useCallback } from 'react';

import {
  AlignBottom,
  AlignCenter,
  AlignLeft,
  AlignMiddle,
  AlignRight,
  AlignTop,
  DistributeHorizontalSpacing,
  DistributeVerticalSpacing,
} from '../icons/AlignIcons';

import { Tooltip } from '~/components/Elements/Tooltip';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AlignDirection, DistributionDirection } from '~/lib/threejs/TransformControls/types';
import { useCreatorStore } from '~/store';

interface AlignFullWidthProps {
  enabled: boolean;
}

const AlignFullWidth: React.FC<AlignFullWidthProps> = ({ enabled }) => {
  const selectedNodesInfo = useCreatorStore((state) => state.ui.selectedNodesInfo);
  const distributionEnabled = selectedNodesInfo.length >= 3;

  const handleOnClick = useCallback(
    (direction: AlignDirection) => {
      if (!enabled) {
        return;
      }

      const alignment = `ALIGNMENT_${direction.toUpperCase()}`;

      emitter.emit(EmitterEvent[alignment as keyof typeof EmitterEvent]);
    },
    [enabled],
  );

  const handleOnDistributionClick = useCallback(
    (direction: DistributionDirection) => {
      if (!enabled || !distributionEnabled) {
        return;
      }

      const alignment = `ALIGNMENT_DISTRIBUTION_${direction.toUpperCase()}`;

      emitter.emit(EmitterEvent[alignment as keyof typeof EmitterEvent]);
    },
    [enabled, distributionEnabled],
  );

  const getButtonStyle = (isEnabled: boolean): string =>
    `flex h-6 w-6 rounded p-1 ${
      isEnabled
        ? 'fill-gray-400 stroke-gray-400 hover:cursor-pointer hover:bg-gray-700 hover:fill-white hover:stroke-white'
        : 'fill-gray-600 stroke-gray-600'
    }`;

  const alignButtonStyle = getButtonStyle(enabled);

  const buttonStyleDistribution = getButtonStyle(enabled && distributionEnabled);

  return (
    <div>
      <div className="flex justify-evenly py-2">
        <Tooltip content="Align left" placement="bottom-start">
          <div onClick={() => handleOnClick(AlignDirection.Left)}>
            <AlignLeft className={`${alignButtonStyle}`} />
          </div>
        </Tooltip>
        <Tooltip content="Align horizontal centers" placement="bottom-start">
          <div onClick={() => handleOnClick(AlignDirection.Center)}>
            <AlignCenter className={`${alignButtonStyle}`} />
          </div>
        </Tooltip>
        <Tooltip content="Align right" placement="bottom-start">
          <div onClick={() => handleOnClick(AlignDirection.Right)}>
            <AlignRight className={`${alignButtonStyle}`} />
          </div>
        </Tooltip>
        <Tooltip content="Align top" placement="bottom-start">
          <div onClick={() => handleOnClick(AlignDirection.Top)}>
            <AlignTop className={`${alignButtonStyle}`} />
          </div>
        </Tooltip>
        <Tooltip content="Align vertical centers" placement="bottom-end">
          <div onClick={() => handleOnClick(AlignDirection.Middle)}>
            <AlignMiddle className={`${alignButtonStyle}`} />
          </div>
        </Tooltip>
        <Tooltip content="Align bottom" placement="bottom-end">
          <div onClick={() => handleOnClick(AlignDirection.Bottom)}>
            <AlignBottom className={`${alignButtonStyle}`} />
          </div>
        </Tooltip>
        <Tooltip content="Distribute horizontal spacing" placement="bottom-end">
          <div onClick={() => handleOnDistributionClick(DistributionDirection.Horizontal)}>
            <DistributeHorizontalSpacing className={`${buttonStyleDistribution}`} />
          </div>
        </Tooltip>
        <Tooltip content="Distribute vertical spacing" placement="bottom-end">
          <div onClick={() => handleOnDistributionClick(DistributionDirection.Vertical)}>
            <DistributeVerticalSpacing className={`${buttonStyleDistribution}`} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export const AlignPropertyFullWidth: React.FC<AlignFullWidthProps> = ({ enabled }) => {
  return (
    <>
      <AlignFullWidth enabled={enabled} />
    </>
  );
};
