/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useEffect, useCallback, useRef, useState } from 'react';

import type { ColorOptional } from '@/ColorPicker/components/ColorPicker/ColorPickerPopover';
import { MouseAction } from '@/ColorPicker/components/ColorPicker/ColorPickerPopover';
import { changePicker, getRgbByHue } from '@/ColorPicker/helpers';
import { useMouseEvents } from '@/ColorPicker/hooks';

interface Props {
  blue: number;
  green: number;
  hue: number;
  red: number;
  saturation: number;
  updateRgb: (value: ColorOptional, name: MouseAction) => void;
  value: number;
}

export const Picking: React.FC<Props> = ({ blue, green, hue, red, saturation, updateRgb, value }) => {
  const pickingAreaRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (pickingAreaRef.current) {
      setWidth(pickingAreaRef.current.clientWidth);
      setHeight(pickingAreaRef.current.clientHeight);
    }
  }, [pickingAreaRef, setWidth, setHeight]);

  useEffect(() => {
    const { blue: _blue, green: _green, red: _red } = getRgbByHue(hue);

    if (pickingAreaRef.current) {
      pickingAreaRef.current.style.backgroundColor = `rgb(${_red}, ${_green}, ${_blue})`;
    }
  }, [hue]);

  // generate offsetLeft by saturation
  const offsetLeft = (((saturation * width) / 100) | 0) - 6;

  // generate offsetTop by value
  const offsetTop = ((height - (value * height) / 100) | 0) - 6;

  const getPointerStyle = {
    backgroundColor: `rgb(${red}, ${green}, ${blue})`,
    left: `${offsetLeft}px`,
    top: `${offsetTop}px`,
  };

  const mouseDownHandler = useCallback(
    (event: MouseEvent) => {
      const elementX = (event.currentTarget as HTMLDivElement).getBoundingClientRect().x;
      const elementY = (event.currentTarget as HTMLDivElement).getBoundingClientRect().y;
      const startX = event.pageX;
      const startY = event.pageY;
      const positionX = startX - elementX;
      const positionY = startY - elementY;

      const color = changePicker(positionX, positionY, height, width, hue);

      // eslint-disable-next-line no-undefined
      color.alpha = undefined;
      updateRgb(color, MouseAction.onStartChange);

      return {
        startX,
        startY,
        positionX,
        positionY,
      };
    },
    [height, width, hue, updateRgb],
  );

  const changeObjectPositions = useCallback(
    (
      event: MouseEvent,
      {
        positionX,
        positionY,
        startX,
        startY,
      }: { positionX: number; positionY: number; startX: number; startY: number },
    ) => {
      const moveX = event.pageX - startX;
      const moveY = event.pageY - startY;

      const _positionX = positionX + moveX;
      const _positionY = positionY + moveY;

      const color = changePicker(_positionX, _positionY, height, width, hue);

      return {
        positions: {
          positionX: _positionX,
          positionY: _positionY,
          startX: event.pageX,
          startY: event.pageY,
        },
        color: {
          ...color,
          // eslint-disable-next-line no-undefined
          alpha: undefined,
        },
      };
    },
    [height, hue, width],
  );

  const mouseMoveHandler = useCallback(
    (
      event: MouseEvent,
      {
        positionX,
        positionY,
        startX,
        startY,
      }: { positionX: number; positionY: number; startX: number; startY: number },
    ) => {
      const { color, positions } = changeObjectPositions(event, {
        startX,
        startY,
        positionX,
        positionY,
      });

      updateRgb(color, MouseAction.onChange);

      return positions;
    },
    [updateRgb, changeObjectPositions],
  );

  const mouseUpHandler = useCallback(
    (
      event: MouseEvent,
      {
        positionX,
        positionY,
        startX,
        startY,
      }: { positionX: number; positionY: number; startX: number; startY: number },
    ) => {
      const { color, positions } = changeObjectPositions(event, {
        startX,
        startY,
        positionX,
        positionY,
      });

      updateRgb(color, MouseAction.onEndChange);

      return positions;
    },
    [updateRgb, changeObjectPositions],
  );

  const mouseEvents = useMouseEvents(mouseDownHandler, mouseMoveHandler, mouseUpHandler);

  const onMouseDown = (event: MouseEvent): void => {
    mouseEvents(event);
  };

  return (
    <div className="picking-area" ref={pickingAreaRef} onMouseDown={onMouseDown}>
      <div className="picking-area-overlay1">
        <div className="picking-area-overlay2">
          <div className="picker-pointer" style={getPointerStyle} />
        </div>
      </div>
    </div>
  );
};

export default Picking;
