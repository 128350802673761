/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable padding-line-between-statements */
import type { DagNode, Shape } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useEffect, useState, useCallback } from 'react';

import type { AnimatedPanelCategory, AnimatedPanelProperty, CurrentTransform } from './helpers';
import {
  localUIUpdate,
  updateKeyframe,
  getFilteredAnimationCategories,
  getFilteredAnimationProperties,
} from './helpers';

import { AnimateAllButton, AnimateChannelIcon, AnimateChannelIconHover } from '~/assets/icons';
import { TooltipWithShortcut } from '~/components/Elements/Tooltip';
import { AnimatedProperty } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

import './AnimationTimeline.css';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

const getTooltipShortcut = (animatedProperty: AnimatedPanelProperty): string => {
  switch (animatedProperty.type) {
    case AnimatedProperty.Position:
    case AnimatedProperty.Scale:
    case AnimatedProperty.Rotation:
      return `${animatedProperty.label[0]?.toUpperCase()}`;
    case AnimatedProperty.Opacity:
      return 'T';
    default:
      return '';
  }
};

interface AnimatedPropertyFromModalProp {
  animatedKey: number;
  animatedProperty: AnimatedPanelProperty;
  isLast: boolean;
  layerId: string;
  setAnimatedProps: React.Dispatch<React.SetStateAction<AnimatedPanelProperty[]>>;
}

const AnimatedPropertyFromModal: React.FC<AnimatedPropertyFromModalProp> = ({
  animatedKey,
  animatedProperty,
  isLast,
  layerId,
  setAnimatedProps,
}: AnimatedPropertyFromModalProp) => {
  const type = animatedProperty.type;

  const node = getNodeByIdOnly(layerId) as DagNode;
  const [currentTransform, setCurrentTransform] = useState<CurrentTransform>(() => localUIUpdate(node, type));

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    updateKeyframe(type);
    stateHistory.endAction();
    setCurrentTransform(localUIUpdate(node, type));
  }, [node, type]);

  // update UI on load
  useEffect(() => {
    setCurrentTransform(localUIUpdate(node, type));
  }, [node, type]);

  // update UI if 'animate all' has been triggered
  useEffect(() => {
    emitter.on(EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES, () => {
      setCurrentTransform(localUIUpdate(node, type));
    });

    return () => {
      emitter.off(EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES, () => {
        setCurrentTransform(localUIUpdate(node, type));
      });
    };
  }, [node, type]);

  // update the 'isAnimated' state, so that parent component can
  // set the 'allAnimated' button status based on children's isAnimated state
  useEffect(() => {
    setAnimatedProps((previousState) => {
      const newState = previousState.map((prop) => {
        if (type === prop.type) {
          return {
            ...prop,
            currentTransform,
            isAnimated: currentTransform.isAnimated,
          };
        }

        return prop;
      });

      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransform]);

  const isFirst = Boolean(animatedKey === 0);
  const isAnimated = currentTransform.isAnimated;
  const shortcut = getTooltipShortcut(animatedProperty);

  return (
    <div
      className={clsx('group z-[999999999] flex cursor-pointer bg-gray-700 px-2 py-[4px]', {
        'rounded-t-lg': isFirst,
        'border-b-[1px] border-[#333C45]': !isLast,
        'rounded-b-lg': isLast,
      })}
      onClick={handleKeyframeClick}
      key={animatedKey}
    >
      <TooltipWithShortcut
        content={`Toggle ${animatedProperty.label.toLowerCase()} animation`}
        shortcut={shortcut}
        placement="bottom-start"
      >
        <div className="flex h-[24px] w-[24px] items-center justify-center justify-items-center rounded hover:bg-gray-600">
          <AnimateChannelIcon
            className={clsx('h-[16px] w-[16px]', {
              'text-gray-500 group-hover:hidden': !isAnimated,
              'text-teal-200 group-hover:text-teal-200 block': isAnimated,
            })}
          />
          <AnimateChannelIconHover
            className={clsx('hidden h-[16px] w-[16px]', {
              'group-hover:block': !isAnimated,
            })}
          />
        </div>
      </TooltipWithShortcut>

      <div
        className={`ml-2 flex w-full items-center justify-between text-xs  ${isAnimated ? 'text-white' : 'text-gray-400'}`}
      >
        <span className="align-sub">{animatedProperty.label}</span>
        <span>{shortcut}</span>
      </div>
    </div>
  );
};

export const AnimatedProperties: React.FC = () => {
  const layerId = useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId;
  const [allAnimated, setAllAnimated] = useState(false);
  const node = getNodeByIdOnly(layerId as string) as Shape;

  const [animatedProps, setAnimatedProps] = useState(() => getFilteredAnimationProperties(node));
  const animatedCategories = getFilteredAnimationCategories(node);

  useEffect(() => {
    if (animatedProps.length > 0) {
      setAllAnimated(animatedProps.every((prop) => prop.isAnimated));
    }
  }, [animatedProps]);

  if (!layerId) return null;

  return (
    <div className="overflow-auto px-4 py-3 text-white">
      {animatedCategories.length > 0 &&
        animatedCategories.map((animatedPropertyCategory: AnimatedPanelCategory, key: number) => {
          return (
            <div key={key}>
              <div className={`flex justify-between ${key > 0 ? 'pt-2' : ''}`}>
                <div className="mb-1 overflow-visible text-xs text-gray-300">{animatedPropertyCategory.label}</div>
                <TooltipWithShortcut content={allAnimated ? 'Clear all keyframes' : 'Animate all'} shortcut="K">
                  <div>
                    <AnimateAllButton
                      allPropsAnimated={allAnimated}
                      onClick={() => {
                        emitter.emit(EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES);
                      }}
                    />
                  </div>
                </TooltipWithShortcut>
              </div>
              <div className="rounded-lg">
                {animatedProps.length > 0 &&
                  animatedProps.map((animatedProperty: AnimatedPanelProperty, animKey: number) => {
                    const isLast = Boolean(animKey === animatedProps.length - 1);

                    return (
                      <AnimatedPropertyFromModal
                        key={animKey}
                        layerId={layerId}
                        animatedKey={animKey}
                        animatedProperty={animatedProperty}
                        isLast={isLast}
                        setAnimatedProps={setAnimatedProps}
                      />
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};
