/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useEffect, useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { FetchUser } from '~/lib/api/auth';
import { useUserAPI } from '~/providers/api/auth';
import { useCreatorStore } from '~/store';
import type { UserInfo } from '~/store/userSlice';

export interface UserDataProps {
  token: string;
}

const UserData: React.FC<UserDataProps> = ({ token }: UserDataProps) => {
  const [setUserInfo, setForbiddenUser] = useCreatorStore(
    (state) => [state.user.setInfo, state.user.setForbid],
    shallow,
  );
  // const { getUserInfo } = useUserAPI(token);

  // TODO: Enable whitelist API after io is up
  const { getUserInfo, isUserWhitelist } = useUserAPI(token);
  const userHasAccess = useCallback(async (): Promise<boolean> => {
    const hasAccess = (await isUserWhitelist()) as boolean;

    return Promise.resolve(hasAccess);
  }, [isUserWhitelist]);

  const getInfo = useCallback(async () => {
    const result = (await getUserInfo()) as UserInfo | null;

    if (result) {
      const { avatarUrl, country, email, id, name, userSegments } = result;

      setUserInfo({ id, email, name, avatarUrl, country, userSegments } as UserInfo);

      // TODO: Enable whitelist API after io is up
      const allowAccess = await userHasAccess();

      if (allowAccess) {
        setForbiddenUser(false);
      } else {
        setForbiddenUser(true);
      }

    }
  }, [setForbiddenUser, setUserInfo, getUserInfo, userHasAccess]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return null;
};

export const AuthListener: React.FC = () => {
  const [userToken, setUserToken, setIsAuthorized, setIsUserFetched] = useCreatorStore(
    (state) => [state.user.token, state.user.setToken, state.user.setIsAuthorized, state.user.setIsUserFetched],
    shallow,
  );

  const checkAuth = useCallback(async (): Promise<void> => {
    if (!userToken) {
      try {
        const authResp = await FetchUser();

        if (authResp.status === 200) {
          const authToken = authResp.data.token;

          setUserToken(authToken);
          setIsAuthorized(true);
        } else if (authResp.status === 401) {
          setIsAuthorized(false);
        }
      } catch (ex) {
        // console.log("error: ", ex)
      }
      setIsUserFetched(true);
    }
  }, [setIsAuthorized, setIsUserFetched, setUserToken, userToken]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (userToken) {
    return <UserData token={userToken} />;
  } else return null;
};
