/**
 * Copyright 2023 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/toolkit-js';
import { colord } from 'colord';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { AppearancePropertyOptions } from './AppearancePropertyOptions';

import { StrokeWidth } from '~/assets/icons';
import { ColorInput } from '~/components/Elements/ColorInput/ColorInput';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { MAX_STROKE_WIDTH, MIN_STROKE_WIDTH } from '~/data/range';
import type { CurrentStrokeShape } from '~/lib/toolkit';
import { AnimatedType, toggleAppearanceKeyframe, updateAppearance, updateColor } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface StrokeProps {
  ids: string[];
}

export const StrokeProperty: React.FC<StrokeProps> = ({ ids }) => {
  const [setAnimatedValue, getShape, currentFrame] = useCreatorStore(
    (state) => [state.toolkit.setAnimatedValue, state.toolkit.getShape, state.toolkit.currentFrame],
    shallow,
  );

  const shapes = useMemo(
    () => ids.map((id) => getShape(ShapeType.STROKE, id) as CurrentStrokeShape),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ids, getShape, currentFrame],
  );

  const hasMixedColor = useMemo(() => {
    const keys = new Set(shapes.map((shape) => `${shape.r}_${shape.g}_${shape.b}_${shape.o}`));

    return keys.size > 1;
  }, [shapes]);

  const hasMixedWidth = useMemo(() => {
    const keys = new Set(shapes.map((shape) => shape.width));

    return keys.size > 1;
  }, [shapes]);

  const color = useMemo(() => {
    if (!hasMixedColor && shapes[0]) {
      const { b, g, o: opacity, r } = shapes[0];

      return colord({ r, g, b, alpha: opacity / 100 })
        .toHex()
        .replace('#', '');
    }

    return 'ffffff';
  }, [shapes, hasMixedColor]);

  const opacity = useMemo(() => {
    if (shapes[0]) {
      return shapes[0].o;
    }

    return 100;
  }, [shapes]);

  const width = useMemo(() => {
    if (shapes[0]) {
      return shapes[0].width;
    }

    return 10;
  }, [shapes]);

  const hasColorKeyFrame = useMemo(() => {
    return shapes.every((shape) => Boolean(shape.colorCurrentKeyframe));
  }, [shapes]);

  const hasWidthKeyFrame = useMemo(() => {
    return shapes.every((shape) => Boolean(shape.widthCurrentKeyframe));
  }, [shapes]);

  const colorIsAnimated = useMemo(() => {
    return shapes.every((shape) => Boolean(shape.colorIsAnimated));
  }, [shapes]);

  const widthIsAnimated = useMemo(() => {
    return shapes.every((shape) => Boolean(shape.widthIsAnimated));
  }, [shapes]);

  const handleKeyframeClick = useCallback(
    (type: AnimatedType): void => {
      toggleAppearanceKeyframe(type, shapes);
    },
    [shapes],
  );

  const handleOnChangeWidth = useCallback(
    (result: NumberResult) => {
      updateAppearance(setAnimatedValue, shapes, AnimatedType.STROKE_WIDTH, result);
    },
    [setAnimatedValue, shapes],
  );

  const handleOnChangeColor = useCallback(
    (newColor: string) => {
      updateColor(setAnimatedValue, ids, AnimatedType.STROKE_COLOR, newColor);
    },
    [setAnimatedValue, ids],
  );

  const handleOnChangeOpacity = useCallback(
    (result: NumberResult) => {
      updateAppearance(setAnimatedValue, shapes, AnimatedType.STROKE_OPACITY, result);
    },
    [setAnimatedValue, shapes],
  );

  const [minValue, maxValue] = useMemo(() => {
    if (shapes.length > 0 && hasMixedWidth) {
      const strokeWidths = shapes.map((shape) => shape.width);

      const smallestStroke = Math.min(...strokeWidths);
      const biggestStroke = Math.max(...strokeWidths);

      const min = -biggestStroke;
      const max = MAX_STROKE_WIDTH + (MAX_STROKE_WIDTH - smallestStroke);

      return [min, max];
    }

    return [MIN_STROKE_WIDTH, MAX_STROKE_WIDTH];
  }, [shapes, hasMixedWidth]);

  return (
    <div className="mt-2 flex">
      <Tooltip content="Stroke color">
        <div className="w-1/2">
          <ColorInput
            shapeType={ShapeType.STROKE}
            styleClass="w-[86px]"
            color={color}
            opacity={opacity || 0}
            onChangeColor={handleOnChangeColor}
            onChangeOpacity={(value: number) =>
              handleOnChangeOpacity({
                name: 'opacity',
                trueValue: value,
                value,
              })
            }
            showKeyframe
            hasKeyframe={hasColorKeyFrame}
            onKeyframeClick={() => handleKeyframeClick(AnimatedType.STROKE_COLOR)}
            message={hasMixedColor ? 'Mixed' : null}
            mixedColors={hasMixedColor}
            isChannelAnimated={colorIsAnimated}
            enableColorModeChange={false}
            selectedIds={ids}
          />
        </div>
      </Tooltip>
      <div className="my-[-8px] border border-gray-800"></div>
      <div className="w-[86px]">
        <Tooltip content="Stroke width">
          <div>
            <NumberInput
              name="stroke"
              label={<StrokeWidth className="h-1.5 w-2.5" />}
              value={width}
              onChange={handleOnChangeWidth}
              min={minValue}
              max={maxValue}
              showKeyframe
              hasKeyframe={hasWidthKeyFrame}
              onKeyframeClick={() => handleKeyframeClick(AnimatedType.STROKE_WIDTH)}
              message={hasMixedWidth ? 'Mixed' : null}
              isChannelAnimated={widthIsAnimated}
            />
          </div>
        </Tooltip>
      </div>
      <AppearancePropertyOptions ids={ids} disabled={hasMixedColor} type={ShapeType.STROKE} />
    </div>
  );
};
