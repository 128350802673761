/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { getCurrentKeyFrame, getIsAtKeyFrame } from './helpers';

import { Rotation, RotationDegrees } from '~/assets/icons';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { PeriodicInput } from '~/components/Elements/Input/PeriodicInput';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface PolystarRotationFieldProps {
  rotation: number;
  rotationIsAnimated: boolean;
}

export const PolystarRotationField: React.FC<PolystarRotationFieldProps> = ({ rotation, rotationIsAnimated }) => {
  const [animated, currentShapeNode, setRotation, setPolystarVariableIsAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentShapeNode?.state?.animatedProperties?.r,
      state.toolkit.currentShapeNode,
      state.toolkit.setPolystarRotation,
      state.toolkit.setPolystarVariableIsAnimated,
    ],
    shallow,
  );

  const isAtKeyFrame = getIsAtKeyFrame(animated);
  const rotationCurrentKeyFrame = getCurrentKeyFrame(animated, currentShapeNode);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setRotation(result.value);

      if (result.name === 'rotation') {
        setRotation(Math.trunc(rotation / 360) * 360 + result.value);
      } else if (result.name === 'numberOfRotations') {
        setRotation(result.value * 360 + (rotation % 360));
      }
      emitter.emit(EmitterEvent.POLYSTAR_ROTATION_UPDATED);
    },
    [setRotation, rotation],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (rotationCurrentKeyFrame === '') {
      setPolystarVariableIsAnimated('rotation', true);
      setRotation(rotation);
    } else {
      removeKeyFrame(rotationCurrentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_ROTATION_UPDATED);
    stateHistory.endAction();
  }, [setRotation, setPolystarVariableIsAnimated, rotationCurrentKeyFrame, rotation]);

  return (
    <div className="mr-6 flex">
      <div className="relative flex items-center">
        <div className="number-input-label flex w-[182px] rounded border border-transparent bg-gray-700 focus-within:border-teal-500">
          <NumberInput
            value={Math.trunc(rotation / 360)}
            name="numberOfRotations"
            label={<Rotation className="h-4 w-4" />}
            onChange={handleOnChange}
            styleClass={{
              label: 'p-1 h-[22px] w-full text-xs font-normal rounded-l',
            }}
          />

          <div className="my-[-1px] border border-gray-800"></div>
          <PeriodicInput
            value={rotation}
            name="rotation"
            label={<RotationDegrees className="h-2 w-2" />}
            append="°"
            period={360}
            allowNegative
            onChange={handleOnChange}
            styleClass={{
              label: 'p-1 pr-[10px] h-[22px] w-full text-xs font-normal rounded-l',
            }}
          />
        </div>
        <div className="absolute right-[-9px] top-[2px]">
          <KeyframeButton
            onClick={handleKeyframeClick}
            hasKeyframe={isAtKeyFrame}
            isChannelAnimated={rotationIsAnimated}
            withTooltip
          />
        </div>
      </div>
    </div>
  );
};
