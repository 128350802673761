/**
 * Copyright 2021 Design Barn Inc.
 */

import { AVLayer, GroupShape, ShapeType } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { AlignPropertyFullWidth } from './AlignProperty';
import { GradientFillProperty } from './GradientFillProperty';
import { GradientProperty } from './GradientProperty';
import { LayerProperty } from './LayerProperty';
import { FillProperty } from './ShapeFillProperty';
import { StrokeProperty } from './StrokeProperty';
import { TransformProperty } from './TransformProperty';
import { PropertyAccordionControl, PropertyAccordion } from './ui/PropertyAccordion';

import { Divider } from '~/components/Elements/Divider';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { AppearanceOption } from '~/lib/toolkit/appearance';
import { AppearanceOptions, addAppearanceMultiselect } from '~/lib/toolkit/appearance';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

export enum CombinedShapeType {
  FILL_OR_GRADIENT_FILL = 'fl_gf',
  STROKE_OR_GRADIENT_STROKE = 'st_gs',
}

interface AppearanceListProps {
  appearanceLists: Array<[string, string[]]>;
}

const AppearanceList: React.FC<AppearanceListProps> = ({ appearanceLists }) => {
  return (
    <>
      {appearanceLists.map(([type, ids]) => {
        return (
          <span key={type}>
            {type === CombinedShapeType.FILL_OR_GRADIENT_FILL && <FillProperty ids={ids} showOpacity={true} />}
            {type === ShapeType.STROKE && <StrokeProperty ids={ids} />}
          </span>
        );
      })}
    </>
  );
};

export const AppearanceProperty: React.FC = () => {
  const [appearanceList, selectedNodesInfo, getNodeByIdOnly] = useCreatorStore(
    (state) => [state.ui.appearanceList, state.ui.selectedNodesInfo, state.toolkit.getNodeByIdOnly],
    shallow,
  );

  const nodes = selectedNodesInfo
    .map((nodeId) => getNodeByIdOnly(nodeId.nodeId) as AVLayer | GroupShape)
    .filter((node) => node instanceof AVLayer || node instanceof GroupShape);

  const isDisabledByType: Partial<Record<ShapeType, boolean>> = {
    [ShapeType.FILL]: nodes.every(
      (node) => node.colors.fillProps.length > 0 || node.colors.gradientFillProps.length > 0,
    ),
    [ShapeType.STROKE]: nodes.every(
      (node) => node.colors.strokeProps.length > 0 || node.colors.gradientStrokeProps.length > 0,
    ),
  };

  const ids = Object.keys(appearanceList);

  const appearanceListByType = ids.reduce((acc: Record<string, string[]>, id) => {
    const type = appearanceList[id] as string;

    let appearanceType = type;

    if (type === ShapeType.FILL || type === ShapeType.GRADIENT_FILL) {
      appearanceType = CombinedShapeType.FILL_OR_GRADIENT_FILL;
    }

    acc[appearanceType] = acc[appearanceType] || [];
    acc[appearanceType]?.push(id);

    return acc;
  }, {});

  const onChangeAdd = useCallback(
    (value: number | string): void => {
      addAppearanceMultiselect(selectedNodesInfo, value as ShapeType, appearanceListByType[value] || []);

      emitter.emit(EmitterEvent.APPEARANCE_CREATED);
    },
    [selectedNodesInfo, appearanceListByType],
  );

  const appearanceLists = Object.entries(appearanceListByType);

  const appearanceOptions: AppearanceOption[] = [];

  AppearanceOptions.forEach((option) => {
    appearanceOptions.push({
      ...option,
      isDisabled: Boolean(isDisabledByType[option.type]),
    });
  });

  return (
    <PropertyAccordion
      title="Appearance"
      withDisclosure={false}
      disableAccordionButton={true}
      header={
        <PropertyAccordionControl
          currentCount={appearanceLists.length}
          onChangeAdd={onChangeAdd}
          showAddRemove
          showAddOnly={true}
          options={appearanceOptions as unknown as Array<Record<string, unknown>>}
        />
      }
    >
      <AppearanceList appearanceLists={appearanceLists} />
    </PropertyAccordion>
  );
};

export const SingleAppearanceProperty: React.FC = () => {
  const [appearanceList, property] = useCreatorStore(
    (state) => [state.ui.appearanceList, state.ui.currentPropertyPanel],
    shallow,
  );

  const appearanceIds = Object.keys(appearanceList);

  if (property === PropertyPanelType.Stroke) {
    return (
      <>
        {/* <Divider /> */}
        <AlignPropertyFullWidth enabled={true} />
        <TransformProperty property={property}>
          <StrokeProperty ids={appearanceIds} />
        </TransformProperty>
        <Divider />
        <LayerProperty type={PropertyPanelType.Stroke} />
        {/* <Divider />
        <DashProperty />
        <Divider />
        <SettingsProperty /> */}
      </>
    );
  }

  if (property === PropertyPanelType.Fill) {
    return (
      <>
        <AlignPropertyFullWidth enabled={true} />
        <TransformProperty property={property}>
          <FillProperty ids={appearanceIds} showOpacity={false} shapeType={ShapeType.FILL} />
        </TransformProperty>
        <Divider />
        <LayerProperty type={PropertyPanelType.Fill} />
        {/* <Divider />
        <SettingsProperty /> */}
      </>
    );
  }

  if (property === PropertyPanelType.GradientFill) {
    return (
      <>
        <AlignPropertyFullWidth enabled={true} />
        <TransformProperty property={'Fill' as PropertyPanelType}>
          <GradientFillProperty ids={appearanceIds} />
        </TransformProperty>
        <Divider />
        <GradientProperty />
        {/* <Divider /> */}
        {/* <SettingsProperty /> */}
      </>
    );
  }

  return <></>;
};
