/**
 * Copyright 2024 Design Barn Inc.
 */

export const getValueFromTexture = `
vec4 getValueFromTexture(sampler2D arrayTexture, vec2 dimension, vec2 coord) {
  vec2 uv = (coord + 0.5) / dimension;
  vec4 color = texture2D(arrayTexture, uv);
  return color;
}
`;
export const isPointInBoundingBox = `
// Function to check if a point is within a bounding box
bool isPointInBoundingBox(vec2 p, vec2 min, vec2 max) {
  return p.x >= min.x && p.x <= max.x && p.y >= min.y && p.y <= max.y;
}
`;

export const solveCubic = `
//But instead of his cancellation fix i'm using a newton iteration
int solve_cubic(vec3 coeffs, inout vec3 r) {
  float a = coeffs[2];
  float b = coeffs[1];
  float c = coeffs[0];

  float p = b - a * a / 3.0;
  float q = a * (2.0 * a * a - 9.0 * b) / 27.0 + c;
  float p3 = p * p * p;
  float d = q * q + 4.0 * p3 / 27.0;
  float offset = -a / 3.0;
  if (d >= 0.0) {
    // Single solution
    float z = sqrt(d);
    float u = (-q + z) / 2.0;
    float v = (-q - z) / 2.0;
    u = sign(u) * pow(abs(u), 1.0 / 3.0);
    v = sign(v) * pow(abs(v), 1.0 / 3.0);
    r[0] = offset + u + v;

    //Single newton iteration to account for cancellation
    float f = ((r[0] + a) * r[0] + b) * r[0] + c;
    float f1 = (3.0 * r[0] + 2.0 * a) * r[0] + b;

    r[0] -= f / f1;

    return 1;
  }
  float u = sqrt(-p / 3.0);
  float v = acos(-sqrt(-27.0 / p3) * q / 2.0) / 3.0;
  float m = cos(v),
    n = sin(v) * 1.732050808;

  //Single newton iteration to account for cancellation
  //(once for every root)
  r[0] = offset + u * (m + m);
  r[1] = offset - u * (n + m);
  r[2] = offset + u * (n - m);

  vec3 f = ((r + a) * r + b) * r + c;
  vec3 f1 = (3.0 * r + 2.0 * a) * r + b;

  r -= f / f1;

  return 3;
}
`;

export const solveQuadric = `
//From Trisomie21
int solve_quadric(vec2 coeffs, inout vec2 roots) {
  // normal form: x^2 + px + q = 0
  float p = coeffs[1] / 2.0;
  float q = coeffs[0];

  float D = p * p - q;

  if (D < 0.0) {
    return 0;
  }

  roots[0] = -sqrt(D) - p;
  roots[1] = sqrt(D) - p;

  return 2;
}

`;

export const cubicDerivative = `
// First derivative of a cubic Bezier. The tangent of the curve.
vec2 cubicDerivative(vec2 a, vec2 b, vec2 c, vec2 d, float t) {
  float omt = 1.0 - t;
  vec2 aD = 3.0 * (b - a);
  vec2 bD = 3.0 * (c - b);
  vec2 cD = 3.0 * (d - c);
  return aD * omt * omt + bD * 2.0 * omt * t + cD * t * t;
}
`;

export const cubicBezierIntTest = `
${solveCubic}
${solveQuadric}
${cubicDerivative}
//From Trisomie21(shadertoy) cubic bezier interior test using non zero rule
vec2 cubic_bezier_int_test_none_zero(vec3 p0, vec3 p1, vec3 p2, vec3 p3, vec2 uv) {
  float cu = -p0.y + 3.0 * p1.y - 3.0 * p2.y + p3.y;
  float qu = 3.0 * p0.y - 6.0 * p1.y + 3.0 * p2.y;
  float li = -3.0 * p0.y + 3.0 * p1.y;
  float co = p0.y - uv.y;
  float up = 0.0;
  float down = 0.0;
  vec3 roots = vec3(1e38);

  int n_roots;
  if (abs(cu) < 0.004) {
    n_roots = solve_quadric(vec2(co / qu, li / qu), roots.xy);
  } else {
    n_roots = solve_cubic(vec3(co / cu, li / cu, qu / cu), roots);
  }

  for (int i = 0; i < n_roots; i++) {
    if (roots[i] >= 0.0 && roots[i] <= 1.0) {
      float x_pos = -p0.x + 3.0 * p1.x - 3.0 * p2.x + p3.x;
      x_pos = x_pos * roots[i] + 3.0 * p0.x - 6.0 * p1.x + 3.0 * p2.x;
      x_pos = x_pos * roots[i] + -3.0 * p0.x + 3.0 * p1.x;
      x_pos = x_pos * roots[i] + p0.x;

      if (x_pos > uv.x) {
        vec2 tangent = normalize(cubicDerivative(p0.xy, p1.xy, p2.xy, p3.xy, roots[i]));
        if (tangent.y >= 0.0) up += 1.0;
        else if (tangent.y < 0.0) down += 1.0;
      }
    }
  }

  return vec2(up, down);
}

// cubic bezier interior test using even off rule
int cubic_bezier_int_test_even_odd(vec3 p0, vec3 p1, vec3 p2, vec3 p3, vec2 uv) {
  float cu = -p0.y + 3.0 * p1.y - 3.0 * p2.y + p3.y;
  float qu = 3.0 * p0.y - 6.0 * p1.y + 3.0 * p2.y;
  float li = -3.0 * p0.y + 3.0 * p1.y;
  float co = p0.y - uv.y;
  vec3 roots = vec3(1e38);
  int n_roots;

  int n_ints = 0;

  if (uv.x < min(min(p0.x, p1.x), min(p2.x, p3.x))) {
    if (uv.y >= min(p0.y, p3.y) && uv.y <= max(p0.y, p3.y)) {
      n_ints++;
    }
  } else {
    if (abs(cu) < 0.004) {
      n_roots = solve_quadric(vec2(co / qu, li / qu), roots.xy);
    } else {
      n_roots = solve_cubic(vec3(co / cu, li / cu, qu / cu), roots);
    }

    for (int i = 0; i < n_roots; i++) {
      if (roots[i] >= 0.0 && roots[i] < 1.0) {
        float x_pos = -p0.x + 3.0 * p1.x - 3.0 * p2.x + p3.x;
        x_pos = x_pos * roots[i] + 3.0 * p0.x - 6.0 * p1.x + 3.0 * p2.x;
        x_pos = x_pos * roots[i] + -3.0 * p0.x + 3.0 * p1.x;
        x_pos = x_pos * roots[i] + p0.x;

        if (x_pos > uv.x) {
          n_ints++;
        }
      }
    }
  }

  return n_ints;
}
`;

const b3Prime = `
// Cubic bezier partial derivative with respect to t
vec2 B3Prime(float t, vec2 P0, vec2 P1, vec2 P2, vec2 P3)
{
    float m1 = 1.-t;
    return 3.*(m1*m1*(P1-P0)+2.*m1*t*(P2-P1)+t*t*(P3-P2));
}`;

const d3Prime = `
${b3Prime}
// This is the "easy" representation of the quintic that has to be solved
// for the sdf.
float D3Prime(vec2 x, float  t, vec2 P0, vec2 P1, vec2 P2, vec2 P3)
{
    return dot(x-B3(t,P0,P1,P2,P3), B3Prime(t,P0,P1,P2,P3));
}`;

const quadraticZeros = `
// Determine zeros of a*x^2+b*x+c
vec2 quadratic_zeros(float a, float b, float cc) {
  if (a == 0.0) return -cc / b * c.xx;
  float d = b * b - 4.0 * a * cc;
  if (d < 0.0) return vec2(1e4);
  return (c.xz * sqrt(d) - b) / (2.0 * a);
}
`;

const cubicZeros = `
${quadraticZeros}
const float pi = acos(-1.0);
// Determine zeros of a*x^3+b*x^2+c*x+d
vec3 cubic_zeros(float a, float b, float cc, float d) {
  if (a == 0.0) return quadratic_zeros(b, cc, d).xyy;

  // Depress
  vec3 ai = vec3(b, cc, d) / a;

  //discriminant and helpers
  float tau = ai.x / 3.0,
    p = ai.y - tau * ai.x,
    q = -tau * (tau * tau + p) + ai.z,
    dis = q * q / 4.0 + p * p * p / 27.0;

  //triple real root
  if (dis > 0.0) {
    vec2 ki = -0.5 * q * c.xx + sqrt(dis) * c.xz,
      ui = sign(ki) * pow(abs(ki), c.xx / 3.0);
    return vec3(ui.x + ui.y - tau);
  }

  //three distinct real roots
  float fac = sqrt(-4.0 / 3.0 * p),
    arg = acos(-0.5 * q * sqrt(-27.0 / p / p / p)) / 3.0;
  return c.zxz * fac * cos(arg * c.xxx + c * pi / 3.0) - tau;
}

`;

const quarticZeros = `
const vec3 c = vec3(1.,0.,-1.);
${cubicZeros}
// Determine zeros of a*x^4+b*x^3+c*x^2+d*x+e
vec4 quartic_zeros(float a, float b, float cc, float d, float e) {
  if (a == 0.0) return cubic_zeros(b, cc, d, e).xyzz;

  // Depress
  float _b = b / a,
    _c = cc / a,
    _d = d / a,
    _e = e / a;

  // Helpers
  float p = (8.0 * _c - 3.0 * _b * _b) / 8.0,
    q = (_b * _b * _b - 4.0 * _b * _c + 8.0 * _d) / 8.0,
    r = (-3.0 * _b * _b * _b * _b + 256.0 * _e - 64.0 * _b * _d + 16.0 * _b * _b * _c) / 256.0;

  // Determine available resolvent zeros
  vec3 res = cubic_zeros(8.0, 8.0 * p, 2.0 * p * p - 8.0 * r, -q * q);

  // Find nonzero resolvent zero
  float m = res.x;
  if (m == 0.0) m = res.y;
  if (m == 0.0) m = res.z;

  // Apply newton iteration to fix numerical artifacts;
  // Credit goes to NinjaKoala / epoqe :)
  for (int i = 0; i < 2; i++) {
    float a_2 = p + m;
    float a_1 = p * p / 4.0 - r + m * a_2;
    float b_2 = a_2 + m;

    float f = -q * q / 8.0 + m * a_1;
    float f1 = a_1 + m * b_2;

    m -= f / f1; // Newton iteration step
  }

  // Apply Ferrari method
  return vec4(
    quadratic_zeros(1.0, sqrt(2.0 * m), p / 2.0 + m - q / (2.0 * sqrt(2.0 * m))),
    quadratic_zeros(1.0, -sqrt(2.0 * m), p / 2.0 + m + q / (2.0 * sqrt(2.0 * m)))
  ) -
  _b / 4.0;
}

`;

const b3 = `
// Cubic bezier curve
vec2 B3(float t, vec2 P0, vec2 P1, vec2 P2, vec2 P3) {
  float m1 = 1.0 - t;
  return m1 * m1 * m1 * P0 + 3.0 * m1 * t * (m1 * P1 + t * P2) + t * t * t * P3;
}
`;

// referenced from https://www.shadertoy.com/view/flsGzH
export const cubicBezierDis = `
${b3}
${d3Prime}
${quarticZeros}
// minimum distance to a cubic spline with the following strategy:
float cubic_bezier_dis(vec2 x, vec2 p0, vec2 p1, vec2 p2, vec2 p3) {
  // Use relative coordinates to eliminate all terms containing p0.
  x -= p0;
  p1 -= p0;
  p2 -= p0;
  p3 -= p0;
  p0 = c.yy;

  // Use interval approximation to determine a numerical solution for the quintic.
  // TODO: find something better, I really would like an analytical approach
  float tmin = -0.5,
    tmax = 1.5,
    tnew,
    dmin = D3Prime(x, tmin, p0, p1, p2, p3),
    dmax = D3Prime(x, tmax, p0, p1, p2, p3),
    dnew;

  for (int i = 0; i < 20; ++i) {
    tnew = mix(tmin, tmax, 0.5);
    dnew = D3Prime(x, tnew, p0, p1, p2, p3);

    if (dnew > 0.0) {
      tmin = tnew;
      dmin = dnew;
    } else {
      tmax = tnew;
      dmax = dnew;
    }
  }

  // Determine coefficients of quintic equation.
  vec2 pa = p2 - p1;
  float a5 = -dot(p3, p3) + 3.0 * dot(pa, 2.0 * p3 - 3.0 * pa),
    a4 = 5.0 * dot(p1 - pa, p3) + 15.0 * dot(pa, pa - p1),
    a3 = -6.0 * dot(p2, p2) + 4.0 * dot(p1, 9.0 * pa - p3),
    a2 = dot(p3 - 3.0 * pa, x) + 9.0 * dot(p1, p1 - pa),
    a1 = 2.0 * dot(pa - p1, x) - 3.0 * dot(p1, p1),
    a0 = dot(p1, x);

  // Polynomial division of numerical solution.
  float _a = a5,
    _b = a4 + _a * tmin,
    _c = a3 + _b * tmin,
    _d = a2 + _c * tmin,
    _e = a1 + _d * tmin;

  vec4 t = clamp(quartic_zeros(_a, _b, _c, _d, _e), 0.0, 1.0);
  tmin = clamp(tmin, 0.0, 1.0);

  return min(
    length(x - B3(tmin, p0, p1, p2, p3)),
    min(
      min(length(x - B3(t.x, p0, p1, p2, p3)), length(x - B3(t.y, p0, p1, p2, p3))),
      min(length(x - B3(t.z, p0, p1, p2, p3)), length(x - B3(t.w, p0, p1, p2, p3)))
    )
  );
}
`;
