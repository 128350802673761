/**
 * Copyright 2024 Design Barn Inc.
 */

import type {
  PrecompositionLayer,
  Scene,
  ShapeLayerJSON,
  SizeJSON,
  ShapeLayer,
  PrecompositionLayerJSON,
  PrecompositionAsset,
} from '@lottiefiles/toolkit-js';
import { Vector, Size } from '@lottiefiles/toolkit-js';
import type { SvgImportOptions } from '@lottiefiles/toolkit-plugin-svg';

// eslint-disable-next-line no-restricted-imports
import type { IDragToCanvas } from '~/features/canvas/components/DragToConvasContainer';
// eslint-disable-next-line no-restricted-imports
import {
  getDragPosFromDragToCanvas,
  updatePrecompDragToCanvasPosition,
} from '~/features/canvas/components/DragToConvasContainer';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { checkTimelineOnImport } from '~/lib/function/import';
import { resetLayerUI } from '~/lib/layer';
import {
  toolkit,
  importSVG,
  renameNestedScenes,
  stateHistory,
  getActiveScene,
  getSceneSize,
  setPrecompReferenceLayersSize,
  lottiePlugin,
  dotLottiePlugin,
} from '~/lib/toolkit';
import { getSelectedDrawOrder } from '~/lib/toolkit/helper';
import { useCreatorStore } from '~/store';

const setSelectedIdsAfterCreated = useCreatorStore.getState().ui.setSelectedIdsAfterCreated;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

export const reAdjustPrecomScale = async (
  scene: Scene | PrecompositionAsset,
  precomLayer: PrecompositionLayer | ShapeLayer,
): Promise<void> => {
  if (precomLayer.scale.isAnimated) {
    return;
  }

  const precomWidth = (precomLayer.state.properties.sz as SizeJSON).w;
  const precomHeight = (precomLayer.state.properties.sz as SizeJSON).h;

  const size = getSceneSize(scene);
  const width = size.width;
  const height = size.height;

  let widthScale = Math.ceil((width * 100) / precomWidth);
  let heightScale = Math.ceil((height * 100) / precomHeight);

  if (precomWidth > width) {
    heightScale = Math.ceil((precomLayer.transform.scale.valueAtKeyFrame(0).y * widthScale) / 100);
    precomLayer.setScale(new Vector(widthScale, heightScale));
  } else if (precomHeight > height) {
    widthScale = Math.ceil((precomLayer.transform.scale.valueAtKeyFrame(0).x * heightScale) / 100);
    precomLayer.setScale(new Vector(widthScale, heightScale));
  }
};

const adjustPrecompAssetSize = (precompLayer: PrecompositionLayer): void => {
  const { h: precompHeight, w: precompWidth } = precompLayer.state.properties.sz as SizeJSON;

  const precompAssetId = precompLayer.precomposition?.nodeId as string;
  const precompAsset = getNodeByIdOnly(precompAssetId) as PrecompositionAsset;

  precompAsset.setData('canvasCustom', false);

  precompAsset.setData('canvasWidth', precompWidth);
  precompAsset.setData('canvasHeight', precompHeight);

  precompAsset.setData('canvasMinWidth', 0);
  precompAsset.setData('canvasMinHeight', 0);

  setPrecompReferenceLayersSize(precompAsset, precompWidth, precompHeight);
};

export const processImportedLotties = async (
  precomLayer: PrecompositionLayer,
  scene: Scene | PrecompositionAsset,
  mainScene: Scene,
  defaultDrawOrder?: number,
): Promise<{ parentLayer: PrecompositionLayer | null }> => {
  const precompLayerDrawOrder = precomLayer.drawOrder;

  reAdjustPrecomScale(scene, precomLayer as PrecompositionLayer);
  const newDrawOrder = defaultDrawOrder || (getSelectedDrawOrder() as number);
  const precompDrawOrder = Math.max(newDrawOrder - 1, 0);

  let parentLayer = null;

  if (precomLayer.state.composition.allLayers.length === 1) {
    const firstLayer = precomLayer.state.composition.layers[0];

    if (firstLayer?.type === 'PRECOMPOSITION') {
      parentLayer = scene.createPrecompositionLayer();
      parentLayer.fromJSON(firstLayer as PrecompositionLayerJSON);
    } else {
      const parentLayerMainScene = mainScene.createShapeLayer();

      parentLayerMainScene.fromJSON(firstLayer as ShapeLayerJSON);

      if (mainScene === scene) {
        parentLayer = parentLayerMainScene;
      } else {
        parentLayer = parentLayerMainScene.clone(scene) as ShapeLayer;
        parentLayerMainScene.removeFromGraph();
      }
    }

    parentLayer.setDrawOrder(precompLayerDrawOrder);

    if (!parentLayer.scale.isAnimated) {
      parentLayer.setScale(
        new Vector(
          parentLayer.scale.value.x * (precomLayer.scale.value.x / 100),
          parentLayer.scale.value.y * (precomLayer.scale.value.y / 100),
        ),
      );
    }

    if (!parentLayer.position.isAnimated) {
      parentLayer.setPosition(
        new Vector(
          parentLayer.position.value.x * (precomLayer.scale.value.x / 100),
          parentLayer.position.value.y * (precomLayer.scale.value.y / 100),
        ),
      );
    }

    const assetID = precomLayer.state.composition.id;
    const assetNode = useCreatorStore.getState().toolkit.getNodeByIdOnly(assetID);

    assetNode?.removeFromGraph();
    precomLayer.exciseLayer();

    parentLayer.moveForward(
      precompLayerDrawOrder - (precompLayerDrawOrder === newDrawOrder ? precompDrawOrder : newDrawOrder),
    );

    setSelectedIdsAfterCreated([parentLayer.nodeId]);
  } else {
    precomLayer.setName(precomLayer.name.replace(/^Precomp Layer/u, ''));
    renameNestedScenes();

    precomLayer.moveForward(
      precompLayerDrawOrder - (precompLayerDrawOrder === newDrawOrder ? precompDrawOrder : newDrawOrder),
    );

    setSelectedIdsAfterCreated([precomLayer.nodeId]);
    adjustPrecompAssetSize(precomLayer);
  }

  emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
  emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });

  return { parentLayer } as { parentLayer: PrecompositionLayer | null };
};

export const uploadJSON = async (json: unknown): Promise<void> => {
  const scene: Scene | PrecompositionAsset | null = getActiveScene(toolkit);

  if (!scene) return;

  stateHistory.beginAction();
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const mainScene = toolkit.scenes[sceneIndex] as Scene;

  const options = {
    animation: json,
    enableNodeIds: true,
    standardizeTimeline: true,
    recalculateStartEnd: true,
  };

  const importedPrecomLayer = await mainScene.importAsPrecompositionLayer(lottiePlugin.id, {
    ...options,
    onImport: (importedScene: Scene) => {
      // import markers only to main scene
      if (mainScene === scene) {
        importedScene.markers.forEach((marker) => {
          marker.setParent(mainScene);
        });
      }
    },
  });

  let precomLayer = importedPrecomLayer;

  checkTimelineOnImport(precomLayer);

  if (mainScene !== scene) {
    precomLayer = importedPrecomLayer.clone(scene) as PrecompositionLayer;
    importedPrecomLayer.removeFromGraph();
  }

  renameNestedScenes();

  // process precompLayer and get the latest canvas positions and etc
  const { parentLayer } = await processImportedLotties(precomLayer, scene, mainScene);

  const fromDragged = updatePrecompDragToCanvasPosition(parentLayer ?? precomLayer);

  // Update drag position after emitted, to prevent position override
  if (fromDragged) {
    // re-update the precompLayer canvas position again
    emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
  }

  stateHistory.endAction();
};

export const uploadDotLottie = async (dotLottie: unknown): Promise<void> => {
  const scene: Scene | PrecompositionAsset | null = getActiveScene(toolkit);

  if (!scene) return;

  stateHistory.beginAction();

  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const mainScene = toolkit.scenes[sceneIndex] as Scene;

  const options = {
    dotlottie: dotLottie,
    enableNodeIds: true,
    standardizeTimeline: true,
    recalculateStartEnd: true,
  };

  const importedPrecomLayer = await mainScene.importAsPrecompositionLayer(dotLottiePlugin.id, {
    ...options,
    onImport: (importedScene: Scene) => {
      // import markers only to main scene
      if (mainScene === scene) {
        importedScene.markers.forEach((marker) => {
          marker.setParent(mainScene);
        });
      }
    },
  });

  let precomLayer = importedPrecomLayer;

  checkTimelineOnImport(precomLayer);

  if (mainScene !== scene) {
    precomLayer = importedPrecomLayer.clone(scene) as PrecompositionLayer;
    importedPrecomLayer.removeFromGraph();
  }

  renameNestedScenes();
  await processImportedLotties(precomLayer, scene, mainScene);

  stateHistory.endAction();
};

export const uploadSVG = async (svgString: string): Promise<void> => {
  const scene: Scene | PrecompositionAsset | null = getActiveScene(toolkit);

  if (!scene) return;

  stateHistory.beginAction();

  const size = getSceneSize(scene);
  const width = size.width;
  const height = size.height;

  const [frameRate, endFrame] = [scene.timeline.frameRate, scene.timeline.endFrame];
  const options: SvgImportOptions = {
    svgString,
    importOptions: {
      // Note (May 2024)
      // - CSS gradients are not supported yet
      // - Might have inaccuracies when converting SVGs using objectBoundingBox
      useExperimentalGradientSupport: true,
      parsingOptions: {
        dpi: 72,
        maxFrames: endFrame as number,
        minFrames: endFrame as number,
        frameRate: frameRate as number,
        screenSize: new Size(width / 2, height / 2),
        removeRedundantRoot: true,
      },
      sceneOptions: {
        author: '',
        description: '',
        keywords: [],
        name: 'Shape Layer - SVG',
      },
      defaultNames: true,
      reduceLayers: true,
    },
  };

  const svgScene = await importSVG(toolkit, options);
  const sceneSize = svgScene.size;
  const svgLayers = svgScene.layers.reverse();

  const { coord } = getDragPosFromDragToCanvas() as IDragToCanvas;

  const mousePosX = coord ? coord.x : width / 2;
  const mousePosY = coord ? coord.y : height / 2;

  svgLayers.forEach((svgLayer, index) => {
    const newDrawOrder = getSelectedDrawOrder() as number;

    // set draw order initially after creating shape layer
    const parentLayer = scene.createShapeLayer().setDrawOrder(newDrawOrder).setName(svgScene.name);

    parentLayer.fromJSON(svgLayer.state as ShapeLayerJSON);

    parentLayer.setDrawOrder(newDrawOrder - index > 0 ? newDrawOrder - index : 0);

    const mouseY = mousePosY as number;
    const mouseX = mousePosX as number;

    parentLayer.setPosition(new Vector(mouseX - sceneSize.width / 2, mouseY - sceneSize.height / 2));

    const svgId = parentLayer.nodeId;

    setSelectedIdsAfterCreated([svgId]);
  });

  toolkit.removeScene(svgScene);

  // Reset layer map
  resetLayerUI();
  useCreatorStore.getState().timeline.clearTabState();

  emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
  stateHistory.endAction();
};
