/**
 * Copyright 2024 Design Barn Inc.
 */

import type { BaseAssetJSON, CompositionJSON, PrecompositionLayerJSON } from '@lottiefiles/toolkit-js';
import { LayerType } from '@lottiefiles/toolkit-js';

export type CompositionJson = CompositionJSON & BaseAssetJSON;
// Given a serialized precomp layer from toolkit, allows to recusively iterate over all the assets
export function forEachAsset(
  json: PrecompositionLayerJSON,
  iterator: (asset: CompositionJson, precomp: PrecompositionLayerJSON) => void,
): void {
  const precomp = json;

  const composition = precomp.composition as CompositionJson;

  const precompositionLayers: PrecompositionLayerJSON[] = composition.layers.filter((layer): boolean => {
    return layer.type === LayerType.PRECOMPOSITION;
  }) as PrecompositionLayerJSON[];

  precompositionLayers.forEach((precompositionLayer) => {
    forEachAsset(precompositionLayer, iterator);
  });

  iterator(composition, precomp);
}

export function uniqueName(startName: string, searchCandidates: string[]): string {
  const nameMatch = /_(\d+)$/u.exec(startName);
  let name;
  let i;

  if (nameMatch) {
    name = startName;
    i = parseInt(nameMatch[0] as string, 10);
  } else {
    name = `${startName}_1`;
    i = 1;
  }

  for (; ; i += 1) {
    const potentialNewName = name.replace(/_(\d+)$/u, `_${i.toString()}`);

    if (!searchCandidates.includes(potentialNewName)) {
      return potentialNewName;
    }
  }
}
