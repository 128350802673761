/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { Tooltip } from '../Tooltip';

import { AnchorVisible, InvisibleEye } from '~/assets/icons';
// eslint-disable-next-line no-restricted-imports
import { Background } from '~/features/canvas/3d/threeFactory/background';

interface AnchorVisibilityToggleProps {
  anchorVisible?: boolean;
  onClick?: () => void;
  type?: string;
}

export const AnchorVisibilityToggle: React.FC<AnchorVisibilityToggleProps> = ({ anchorVisible, onClick, type }) => {
  const color = anchorVisible ? 'text-white' : 'text-gray-500';

  return (
    <Tooltip content="Show/hide anchor point" placement="bottom-end" disabled={Boolean(type)}>
      <button onClick={onClick} className="flex h-6 w-6 items-center justify-center" id="anchor-toggle" tabIndex={-1}>
        {!type && <AnchorVisible className={`h-4 w-4 text-white ${color}`} />}
        {type === Background.Canvas && (
          <>
            {anchorVisible ? (
              <AnchorVisible className={`h-4 w-4 ${color}`} />
            ) : (
              <InvisibleEye className={`h-4 w-4 fill-white ${color}`} />
            )}
          </>
        )}
      </button>
    </Tooltip>
  );
};
