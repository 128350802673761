/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

export const GUIDES_TOOLTIP_HEIGHT = 26;

export const GuidesTooltip: React.FC = () => (
  <div
    id="guides-tooltip"
    style={{ height: GUIDES_TOOLTIP_HEIGHT }}
    className="pointer-events-none absolute hidden -translate-x-1/2 rounded bg-[#D92600] px-2 py-1 text-xs text-white"
  />
);
