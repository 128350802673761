/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { ArrowRight } from '~/assets/icons';
import { COMMUNITY_URL } from '~/data/urls';

export const CommunityPanel = (): JSX.Element => {
  return (
    <div className="flex flex-col  rounded-md bg-gray-600 py-2 px-4 text-xs text-white">
      <p>Want to join other creators, discuss Lottie, and attend events?</p>
      <a className=" text-teal-300" href={COMMUNITY_URL} target="_blank" rel="noreferrer">
        <b>Join the Lottie Community </b>
        <ArrowRight className="inline-block h-2 w-3" />
      </a>
    </div>
  );
};

export default CommunityPanel;
