/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { TransformAnchorPointer } from './TransformAnchorPointer';

import { AnchorVisibilityToggle } from '~/components/Elements/Button/AnchorVisibilityToggle';
import { Interactive } from '~/components/Elements/Button/Interactive';
import { ToolType } from '~/data/constant';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

export const TransFormAnchorField: React.FC = () => {
  const [pivotVisible, setPivotVisibility, currentTool, selectedNodesInfo, getNodeByIdOnly] = useCreatorStore(
    (state) => [
      state.ui.pivotVisible,
      state.ui.setPivotVisibility,
      state.ui.currentTool,
      state.ui.selectedNodesInfo,
      state.toolkit.getNodeByIdOnly,
    ],
    shallow,
  );

  // Anchor visibility is temporarily overridden when the Anchor tool is selected
  // Restore the previous state when the Anchor tool is deselected
  const previousPivotVisibility = useRef<boolean | null>(null);

  useEffect(() => {
    if (currentTool !== ToolType.Anchor) {
      previousPivotVisibility.current = pivotVisible;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pivotVisible]);

  useEffect(() => {
    if (currentTool === ToolType.Anchor) {
      setPivotVisibility(true);

      return;
    }

    if (previousPivotVisibility.current !== pivotVisible) {
      setPivotVisibility(previousPivotVisibility.current as boolean);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTool, setPivotVisibility]);

  const handleOnClickAnchorVisible = useCallback(() => {
    if (currentTool === ToolType.Anchor) return;

    setPivotVisibility(!pivotVisible);

    if (selectedNodesInfo.length === 1) {
      const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId as string);

      emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
        event: EmitterEvent.TOOLKIT_ANCHOR_VISIBILITY_UPDATED,
        data: {
          node,
          pivotVisible: !pivotVisible,
        },
      });
    }
  }, [currentTool, getNodeByIdOnly, pivotVisible, selectedNodesInfo, setPivotVisibility]);

  return (
    <div className="mt-4 flex flex-row justify-between">
      <div className="relative inline-flex">
        <div className="text-xs text-gray-300">Anchor point</div>
      </div>
      <TransformAnchorPointer />
      <Interactive className="ml-0">
        <AnchorVisibilityToggle anchorVisible={pivotVisible} onClick={handleOnClickAnchorVisible} />
      </Interactive>
    </div>
  );
};
