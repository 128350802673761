/**
 * Copyright 2022 Design Barn Inc.
 */

import type { DagNode } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../Plugin';

import { getNodeById } from './utils';
import type { ObjectMethods } from './vmInterface/wrapper';
import { getObjectMethods } from './vmInterface/wrapper';

import { emitter, EmitterEvent } from '~/lib/emitter';

const methodNames = {
  addEventListener: 'addEventListener',
  afterChildAdded: 'afterChildAdded',
  getAllChildren: 'allChildren',
  childrenByInstanceOf: 'childrenByInstanceOf',
  childrenByNodeData: 'childrenByNodeData',
  clearData: 'clearData',
  clone: 'clone',
  findChild: 'findChild',
  getData: 'getData',
  events: 'events',
  getNodeById: 'getNodeById',
  parent: 'parent',
  getReachableDispatcher: 'getReachableDispatcher',
  scene: 'scene',
  timeline: 'timeline',
  timelineContainer: 'timelineContainer',
  hasData: 'hasData',
  hasEventDispatcher: 'hasEventDispatcher',
  onChildAdded: 'onChildAdded',
  onRemove: 'onRemove',
  removeData: 'removeData',
  removeEventListener: 'removeEventListener',
  removeFromGraph: 'removeFromGraph',
  resetState: 'resetState',
  // TODO: implement in plugins v2
  // setData: 'setData',
  setEventDispatcher: 'setEventDispatcher',
};

// TODO: remove modifiers after editing 'getObjectMethods' function to
// accept additionalMethods without first passing in modifiers
const nodeModifiers = {
  removeFromGraph: () => {
    emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
  },
};

function addChild(node: DagNode, vmChild: DagNode): DagNode {
  const child = getNodeById(vmChild.nodeId);

  node.addChild(child);
  emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);

  return node;
}

function hasChild(node: DagNode, vmChild: DagNode): boolean {
  const child = getNodeById(vmChild.nodeId);

  return node.hasChild(child);
}

function setParent(node: DagNode, vmParent: DagNode): DagNode {
  node.setParent(getNodeById(vmParent.nodeId));

  emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);

  return node;
}

function removeChild(node: DagNode, vmChild: DagNode): DagNode {
  const child = getNodeById(vmChild.nodeId);

  node.removeChild(child);
  emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);

  return node;
}

const additionalMethods = {
  addChild,
  hasChild,
  setParent,
  removeChild,
};

export function getNodeMethods(plugin: Plugin, node: DagNode): ObjectMethods {
  return getObjectMethods(plugin, methodNames, node, nodeModifiers, additionalMethods);
}
