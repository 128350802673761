/**
 * Copyright 2024 Design Barn Inc.
 */

import type { CubicBezierShape, DagNode, GroupShape, LayerJSON, PathShape, Shape } from '@lottiefiles/toolkit-js';
import { DagNodeType, ShapeType, MaskModeType } from '@lottiefiles/toolkit-js';

import type { BezierMesh } from '../..';
import { getBezierUniforms, updateBezierShape, updateCompoundBezierShape } from '../..';

import { canvasMap } from '~/lib/canvas';
import { useCreatorStore } from '~/store';

export interface MaskProperties {
  id: string;
  maskMode: MaskModeType;
  maskPath: CubicBezierShape;
  opacity: number;
}

export const getMaskModeIndex = (maskMode: MaskModeType): number => {
  switch (maskMode) {
    case MaskModeType.None:
      return 0;

    case MaskModeType.Add:
      return 1;

    case MaskModeType.Subtract:
      return 2;

    case MaskModeType.Intersect:
      return 3;

    case MaskModeType.Lighten:
      return 4;

    case MaskModeType.Darken:
      return 5;

    case MaskModeType.Difference:
      return 6;

    default:
      return 0;
  }
};

export const isPath = (sh: Shape): boolean =>
  sh.type === ShapeType.PATH ||
  sh.type === ShapeType.RECTANGLE ||
  sh.type === ShapeType.ELLIPSE ||
  sh.type === ShapeType.STAR;

export const onMaskUpdate = (maskNode: PathShape | null, maskedShape: GroupShape, maskMode: number): void => {
  const isCompound = maskedShape.shapes.filter(isPath).length > 1;

  const pathNode = maskedShape.shapes.find(isPath) as Shape;
  const pathMesh = canvasMap.get(pathNode.nodeId);

  if (!pathMesh || !maskNode) return;
  const bezierUniforms = getBezierUniforms(maskNode.shape.value, true);
  const maskUniforms = {
    uMaskBezierCount: bezierUniforms.uBezierCount,
    uMaskBezierPoints: bezierUniforms.uBezierPoints,
    uMaskMode: maskMode,
  };

  if (isCompound) updateCompoundBezierShape([], pathMesh as BezierMesh, maskUniforms);
  else updateBezierShape(null, pathMesh as BezierMesh, maskUniforms);
};

export const getMaskAvailable = (): boolean => {
  const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
  const nodeId = selectedNodesInfo[0]?.nodeId;

  if (!nodeId) return false;

  const node = getNodeByIdOnly(nodeId);

  if (!node) return false;
  if (node.nodeType === DagNodeType.SHAPE) return true;
  if ('shapes' in node && (node.shapes as Shape[]).filter(isPath).length === 1) return true;

  return false;
};

export const hasMasks = (node: DagNode | LayerJSON | undefined): boolean => {
  if (node && 'masks' in node && (node as LayerJSON).masks.length > 0) return true;

  return false;
};
