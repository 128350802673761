/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/member-ordering */

import type { Scene, Object3D } from 'three';
import { PerspectiveCamera, Frustum, Vector3, OrthographicCamera, Line, Points } from 'three';

import type { Box3Helper } from '../Box3Helper';
import { boundingBox } from '../TransformControls/constant';

import { RaycasterLayers, CMesh, clearThree, getParentLayer } from '~/features/canvas';

/**
 * This is a class to check whether objects are in a selection area in 3D space
 */

const _frustum = new Frustum();

const _tmpPoint = new Vector3();

const _vecNear = new Vector3();
const _vecTopLeft = new Vector3();
const _vecTopRight = new Vector3();
const _vecDownRight = new Vector3();
const _vecDownLeft = new Vector3();

const _vecFarTopLeft = new Vector3();
const _vecFarTopRight = new Vector3();
const _vecFarDownRight = new Vector3();
const _vecFarDownLeft = new Vector3();

const _vectemp1 = new Vector3();
const _vectemp2 = new Vector3();
const _vectemp3 = new Vector3();

const EPSILON = 0.01;

export default class SelectionBox {
  public camera: OrthographicCamera;

  public collection: CMesh[] = [];

  public boundingBoxes: Map<string, Box3Helper> = new Map();

  public deep: number = Number.MAX_VALUE;

  public endPoint: Vector3 = new Vector3();

  public pathPointCollection: number[] = [];

  public scene: Scene;

  public startPoint: Vector3 = new Vector3();

  public constructor(camera: OrthographicCamera, scene: Scene, deep = Number.MAX_VALUE) {
    this.camera = camera;
    this.scene = scene;
    this.deep = deep;
  }

  public select(startPoint?: Vector3, endPoint?: Vector3): CMesh[] {
    this.startPoint = startPoint || this.startPoint;
    this.endPoint = endPoint || this.endPoint;
    this.collection = [];

    this.updateFrustum(this.startPoint, this.endPoint);
    this.searchChildInFrustum(_frustum, this.scene);

    return this.collection;
  }

  public selectPathPoints(startPoint?: Vector3, endPoint?: Vector3): number[] {
    this.startPoint = startPoint || this.startPoint;
    this.endPoint = endPoint || this.endPoint;

    this.updateFrustum(this.startPoint, this.endPoint);
    this.searchPathPointsInFrustum(_frustum, this.scene);

    return this.pathPointCollection;
  }

  public updateFrustum(startPoint?: Vector3, endPoint?: Vector3): void {
    const sp = startPoint || this.startPoint;
    const ep = endPoint || this.endPoint;

    // Avoid invalid frustum

    if (sp.x === ep.x) {
      ep.x += Number.EPSILON;
    }

    if (sp.y === ep.y) {
      ep.y += Number.EPSILON;
    }

    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();

    if (this.camera instanceof PerspectiveCamera) {
      _tmpPoint.copy(sp);
      _tmpPoint.x = Math.min(sp.x, ep.x);
      _tmpPoint.y = Math.max(sp.y, ep.y);
      ep.x = Math.max(sp.x, ep.x);
      ep.y = Math.min(sp.y, ep.y);

      _vecNear.setFromMatrixPosition(this.camera.matrixWorld);
      _vecTopLeft.copy(_tmpPoint);
      _vecTopRight.set(ep.x, _tmpPoint.y, 0);
      _vecDownRight.copy(ep);
      _vecDownLeft.set(_tmpPoint.x, ep.y, 0);

      _vecTopLeft.unproject(this.camera);
      _vecTopRight.unproject(this.camera);
      _vecDownRight.unproject(this.camera);
      _vecDownLeft.unproject(this.camera);

      _vectemp1.copy(_vecTopLeft).sub(_vecNear);
      _vectemp2.copy(_vecTopRight).sub(_vecNear);
      _vectemp3.copy(_vecDownRight).sub(_vecNear);
      _vectemp1.normalize();
      _vectemp2.normalize();
      _vectemp3.normalize();

      _vectemp1.multiplyScalar(this.deep);
      _vectemp2.multiplyScalar(this.deep);
      _vectemp3.multiplyScalar(this.deep);
      _vectemp1.add(_vecNear);
      _vectemp2.add(_vecNear);
      _vectemp3.add(_vecNear);

      const planes = _frustum.planes;

      planes[0]?.setFromCoplanarPoints(_vecNear, _vecTopLeft, _vecTopRight);
      planes[1]?.setFromCoplanarPoints(_vecNear, _vecTopRight, _vecDownRight);
      planes[2]?.setFromCoplanarPoints(_vecDownRight, _vecDownLeft, _vecNear);
      planes[3]?.setFromCoplanarPoints(_vecDownLeft, _vecTopLeft, _vecNear);
      planes[4]?.setFromCoplanarPoints(_vecTopRight, _vecDownRight, _vecDownLeft);
      planes[5]?.setFromCoplanarPoints(_vectemp3, _vectemp2, _vectemp1);
      planes[5]?.normal.multiplyScalar(-1);
    } else if (this.camera instanceof OrthographicCamera) {
      let left = Math.min(sp.x, ep.x);
      let top = Math.max(sp.y, ep.y);
      let right = Math.max(sp.x, ep.x);
      let down = Math.min(sp.y, ep.y);

      if (sp.x.toFixed(2) === ep.x.toFixed(2)) left -= EPSILON;
      if (sp.y.toFixed(2) === ep.y.toFixed(2)) top += EPSILON;
      if (sp.x.toFixed(2) === ep.x.toFixed(2)) right += EPSILON;
      if (sp.y.toFixed(2) === ep.y.toFixed(2)) down -= EPSILON;
      _vecTopLeft.set(left, top, -1);
      _vecTopRight.set(right, top, -1);
      _vecDownRight.set(right, down, -1);
      _vecDownLeft.set(left, down, -1);

      _vecFarTopLeft.set(left, top, 1);
      _vecFarTopRight.set(right, top, 1);
      _vecFarDownRight.set(right, down, 1);
      _vecFarDownLeft.set(left, down, 1);

      _vecTopLeft.unproject(this.camera);
      _vecTopRight.unproject(this.camera);
      _vecDownRight.unproject(this.camera);
      _vecDownLeft.unproject(this.camera);

      _vecFarTopLeft.unproject(this.camera);
      _vecFarTopRight.unproject(this.camera);
      _vecFarDownRight.unproject(this.camera);
      _vecFarDownLeft.unproject(this.camera);

      const planes = _frustum.planes;

      planes[0]?.setFromCoplanarPoints(_vecTopLeft, _vecFarTopLeft, _vecFarTopRight);
      planes[1]?.setFromCoplanarPoints(_vecTopRight, _vecFarTopRight, _vecFarDownRight);
      planes[2]?.setFromCoplanarPoints(_vecFarDownRight, _vecFarDownLeft, _vecDownLeft);
      planes[3]?.setFromCoplanarPoints(_vecFarDownLeft, _vecFarTopLeft, _vecTopLeft);
      planes[4]?.setFromCoplanarPoints(_vecTopRight, _vecDownRight, _vecDownLeft);
      planes[5]?.setFromCoplanarPoints(_vecFarDownRight, _vecFarTopRight, _vecFarTopLeft);
      planes[5]?.normal.multiplyScalar(-1);
    } else {
      // eslint-disable-next-line no-console
      console.error('THREE.SelectionBox: Unsupported camera type.');
    }
  }

  public searchChildInFrustum(frustum: Frustum, object: Object3D): void {
    if (object instanceof CMesh || object instanceof Line || object instanceof Points) {
      if (object.layers.isEnabled(RaycasterLayers.CMesh)) {
        const parentLayer = getParentLayer(object as CMesh, RaycasterLayers.CLayer);

        if (!parentLayer || !parentLayer.visible || parentLayer.userData['isLocked']) return;

        const positionAttributes = object.geometry.attributes.position;

        for (let i = 0; i < positionAttributes.count; i += 3) {
          const vertex = new Vector3();

          vertex.fromBufferAttribute(positionAttributes, i);
          vertex.applyMatrix4(object.matrixWorld);

          if (frustum.containsPoint(vertex)) {
            this.collection.push(parentLayer as CMesh);
            this.addBoundingBox(parentLayer as CMesh);

            break;
          }
        }

        if (
          this.boundingBoxes.has(parentLayer.toolkitId as string) &&
          !this.collection.includes(parentLayer as CMesh)
        ) {
          this.removeBoundingBox(parentLayer as CMesh);
        }
      }
    }

    if (object.children.length > 0) {
      object.children.forEach((child) => this.searchChildInFrustum(frustum, child));
    }
  }

  public searchPathPointsInFrustum(frustum: Frustum, object: Object3D): void {
    this.pathPointCollection = [];
    object.traverse((child) => {
      // eslint-disable-next-line no-undefined
      if (child.userData['nodePoint'] === undefined) return;
      if (frustum.containsPoint(child.position)) {
        const pathIndex = child.userData['nodePoint'] as number;

        if (!this.pathPointCollection.includes(pathIndex)) this.pathPointCollection.push(pathIndex);
      }
    });
  }

  public addBoundingBox(object: CMesh): void {
    if (this.boundingBoxes.has(object.toolkitId)) {
      return;
    }

    const bbox = boundingBox();

    this.scene.add(bbox);
    bbox.box.setFromObject(object, true);
    bbox.update();

    this.boundingBoxes.set(object.toolkitId, bbox);
  }

  public removeBoundingBox(object: CMesh): void {
    if (!this.boundingBoxes.has(object.toolkitId)) {
      return;
    }

    const bbox = this.boundingBoxes.get(object.toolkitId);

    if (bbox) {
      clearThree(bbox);
      this.boundingBoxes.delete(object.toolkitId);
    }
  }

  public clearBoundingBoxes(): void {
    if (this.boundingBoxes.size === 0) {
      return;
    }

    this.collection.forEach((object) => this.removeBoundingBox(object));
  }
}
