/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  AnimatedAngleProperty,
  AnimatedColorProperty,
  AnimatedGradientProperty,
  AnimatedPercentageProperty,
  AnimatedPositionProperty,
  AnimatedScalarProperty,
  AnimatedVectorProperty,
  EllipseShape,
  FillShape,
  GradientFillShape,
  GroupShape,
  Layer,
  PathShape,
  PrecompositionAsset,
  PrecompositionLayer,
  RectangleShape,
  RoundedCornersShape,
  Scene,
  Shape,
  StarShape,
  Timeline,
  TrimShape,
} from '@lottiefiles/toolkit-js';
import { ShapeType } from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../Plugin';

import { createdAnimatedPropertyObj } from './animated-properties';
import { createLayerObject } from './layer';
import { createShapeLayerObject } from './layer/shape-layer';
import { createPrecompAssetObj } from './precomp/precomposition-asset';
import { createPrecompLayerObj } from './precomp/precomposition-layer';
import type { Property } from './properties';
import { createPropertyObj } from './properties';
import { createSceneObject } from './scene';
import { createEllipseShapeObject } from './shape/ellipse-shape';
import { createFillShapeObject } from './shape/fill-shape';
import { createGradientFillShapeObject } from './shape/gradient-fill-shape';
import { createGroupShapeObj } from './shape/group-shape';
import { createPathShapeObject } from './shape/path-shape';
import { createRectangleShapeObject } from './shape/rectangle-shape';
import { createRoundedCornersShapeObject } from './shape/rounded-corners-shape';
import { createStarShapeObject } from './shape/star-shape';
import { createTrimShapeObject } from './shape/trim-shape';
import { createTimelineObj } from './time/timeline';
import { marshal } from './vmInterface/marshal';
import { newQuickjsObject } from './vmInterface/marshal/object';

import { useCreatorStore } from '~/store';

// takes a Creator node and extracts info to return to quickjs
// TODO: handle other types of nodes (property, animated property)
export function createNodeObject(plugin: Plugin, node: object): object {
  const nodeType = 'nodeType' in node ? node.nodeType : null;
  let nodeObj;

  switch (nodeType) {
    case 'AnimatedProperty':
      nodeObj = createdAnimatedPropertyObj(
        plugin,
        node as
          | AnimatedPositionProperty
          | AnimatedPercentageProperty
          | AnimatedAngleProperty
          | AnimatedScalarProperty
          | AnimatedColorProperty
          | AnimatedVectorProperty
          | AnimatedGradientProperty,
      );
      break;

    case 'Asset':
      if ((node as PrecompositionAsset).compositionType === 'PRECOMPOSITION') {
        nodeObj = createPrecompAssetObj(plugin, node as PrecompositionAsset);
      }
      break;

    //  TODO: createCharacterObject
    // case 'Character':
    //   break;

    //  TODO: createEffectObject
    // case 'Effect':
    //   break;

    //  TODO: createEffectValueObject
    // case 'EffectValue':
    //   break;

    case 'Layer':
      if ((node as Layer).type === 'SHAPE') {
        nodeObj = createShapeLayerObject(plugin, node as Layer);
      } else if ((node as Layer).type === 'PRECOMPOSITION') {
        nodeObj = createPrecompLayerObj(plugin, node as PrecompositionLayer);
      } else {
        nodeObj = createLayerObject(plugin, node as Layer);
      }

      break;

    //  TODO: createMarkerObject
    // case 'Marker':
    //   break;

    //  TODO: createMaskObject
    // case 'Mask':
    //   break;

    //  TODO: createProjectObject
    // case 'Project':
    //   break;

    case 'Property':
      nodeObj = createPropertyObj(plugin, node as Property);
      break;

    case 'Scene':
      nodeObj = createSceneObject(plugin, node as Scene);
      break;

    case 'Shape':
      if ((node as GroupShape).type === ShapeType.GROUP) {
        nodeObj = createGroupShapeObj(plugin, node as GroupShape);
      } else if ((node as FillShape).type === ShapeType.FILL) {
        nodeObj = createFillShapeObject(plugin, node as FillShape);
      } else if ((node as GradientFillShape).type === ShapeType.GRADIENT_FILL) {
        nodeObj = createGradientFillShapeObject(plugin, node as GradientFillShape);
      } else if ((node as EllipseShape).type === ShapeType.ELLIPSE) {
        nodeObj = createEllipseShapeObject(plugin, node as EllipseShape);
      } else if ((node as RectangleShape).type === ShapeType.RECTANGLE) {
        nodeObj = createRectangleShapeObject(plugin, node as RectangleShape);
      } else if ((node as StarShape).type === ShapeType.STAR) {
        nodeObj = createStarShapeObject(plugin, node as StarShape);
      } else if ((node as PathShape).type === ShapeType.PATH) {
        nodeObj = createPathShapeObject(plugin, node as PathShape);
      } else if ((node as TrimShape).type === ShapeType.TRIM) {
        nodeObj = createTrimShapeObject(plugin, node as TrimShape);
      } else if ((node as RoundedCornersShape).type === ShapeType.ROUNDED_CORNERS) {
        nodeObj = createRoundedCornersShapeObject(plugin, node as RoundedCornersShape);
      }
      break;

    //  TODO: createStrokeDashObject
    // case 'StrokeDash':
    //   break;

    //  TODO: createTextAnimatorObject
    // case 'TextAnimator':
    //   break;

    case 'Timeline':
      nodeObj = createTimelineObj(plugin, node as Timeline);
      break;

    // placeholder to handle other types of nodes
    default:
      nodeObj = {
        ...(node.nodeId && { nodeId: node.nodeId }),
        ...(node.nodeType && { nodeType: node.nodeType }),
        ...(node.type && { type: node.type }),
      };

      break;
  }

  return nodeObj as object;
}

// TODO: remove function when getSelectedNodes has been adopted
export function getSelectedNode(plugin: Plugin): void {
  const getSelectedNodeHandle = plugin.vm.newFunction('getSelectedNode', () => {
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

    const nodeId = useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string;
    const node = getNodeByIdOnly(nodeId) as Layer | Shape;

    const nodeObject = createNodeObject(plugin, node);
    const vmNodeObject = newQuickjsObject(plugin, nodeObject, true);

    return vmNodeObject;
  });

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getSelectedNode', {
    value: getSelectedNodeHandle,
    configurable: false,
  });
  getSelectedNodeHandle.dispose();
}

export function getSelectedNodes(plugin: Plugin): void {
  const getSelectedNodeHandle = plugin.scope.manage(
    plugin.vm.newFunction('getSelectedNodes', () => {
      const nodeIds = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);

      if (nodeIds.length === 0) {
        return plugin.vm.newArray();
      }

      const nodeArray = [];

      for (const nodeId of nodeIds) {
        const node = useCreatorStore.getState().toolkit.getNodeByIdOnly(nodeId) as Layer | Shape;
        const nodeObject = createNodeObject(plugin, node);

        nodeArray.push(nodeObject);
      }

      const vmNodeArray = marshal(plugin, nodeArray);

      return vmNodeArray;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getSelectedNodes', {
    value: getSelectedNodeHandle,
    configurable: false,
  });
}

export function getNodeById(plugin: Plugin): void {
  const getNodeByIdHandle = plugin.vm.newFunction('getNodeById', (vmNodeId: QuickJSHandle): QuickJSHandle => {
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

    const nodeId = plugin.vm.getString(vmNodeId);
    const node = getNodeByIdOnly(nodeId) as Layer | Shape;

    const nodeObject = createNodeObject(plugin, node);
    const vmNodeObject = newQuickjsObject(plugin, nodeObject, true);

    return vmNodeObject;
  });

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getNodeById', {
    value: getNodeByIdHandle,
    configurable: false,
  });
  getNodeByIdHandle.dispose();
}
