/**
 * Copyright 2022 Design Barn Inc.
 */

import { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { WORKFLOW_DASHBOARD } from '~/config';
import { saveToCreator } from '~/lib/function/menu';
import { useWorkspaceAPI } from '~/providers/api/workspace';
import { useCreatorStore } from '~/store';

interface SaveToDraftProp {
  createNew?: boolean;
}

export const SaveToDraft: React.FC<SaveToDraftProp> = ({ createNew = false }: SaveToDraftProp) => {
  const [token, setAlert, setSelectedDirectory, setProjectInfo, setAutoSaveStart] = useCreatorStore(
    (state) => [
      state.user.token,
      state.ui.setAlert,
      state.project.setSelectedDirectory,
      state.project.setInfo,
      state.project.setAutoSaveStart,
    ],
    shallow,
  );

  const { getDraftProject } = useWorkspaceAPI(token);

  const saveToFirstDraft = useCallback(
    async (draftProjectId: string): Promise<void> => {
      const doneSaved = await saveToCreator({ saveWithoutPopup: true });

      if (doneSaved) {
        // Start auto save
        setAutoSaveStart(true);

        setAlert({
          text: 'This file has been saved to Drafts.',
          alertColor: '#20272C',
          timeout: 10000,
          handleText: 'View in Drafts',
          handle: async (): Promise<void> => {
            window.open(`${WORKFLOW_DASHBOARD}/project/${draftProjectId}`, '_blank');
          },
        });
      }
    },
    [setAlert, setAutoSaveStart],
  );

  const getProjectId = useCallback(async () => {
    // NOTE: Get draft project id, handle if none
    const draftProject = await getDraftProject();
    const draftId = draftProject?.id;

    if (draftId) {
      const saveDraftCount = useCreatorStore.getState().project.info.saveDraftCount;

      setProjectInfo({
        isDraft: true,
        saveDraftCount: Number(saveDraftCount) + 1,
        ...(createNew
          ? {
              saveDraftCount: 1,
              fileId: null,
              fileSubscribeKey: null,
              fileVersionId: null,
            }
          : {}),
      });

      setSelectedDirectory({ id: draftId, title: draftProject.title });
      saveToFirstDraft(draftId);
    }
  }, [createNew, getDraftProject, setSelectedDirectory, saveToFirstDraft, setProjectInfo]);

  useEffect(() => {
    if (token) {
      getProjectId();
    }
  }, [getProjectId, token]);

  return null;
};
