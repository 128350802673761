/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Asset, PrecompositionAsset, Size } from '@lottiefiles/toolkit-js';
import { v4 as uuidv4 } from 'uuid';

import { setPrecompSize, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const getNextNestedSceneName = (): string => {
  const countAssets = useCreatorStore.getState().toolkit.json?.assets.length || 0;

  return `Nested Scene ${countAssets + 1}`;
};

export const setNestedSceneSize = (asset: PrecompositionAsset, size: Size): void => {
  asset.setData('canvasMinWidth', 0);
  asset.setData('canvasMinHeight', 0);
  setPrecompSize(asset, [size.width, size.height]);
};

export const renameNestedScenes = (): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  const addedIds: Record<string, number> = {};

  toolkit.scenes[sceneIndex]?.assets.forEach((eachAsset: Asset) => {
    if (eachAsset.type === 'PRECOMPOSITION') {
      const asset = eachAsset as PrecompositionAsset;
      const checkIfUniqueID = asset.data.get('unique_id');

      const assetName = asset.name;
      const regex = /_(\d+)$/u;
      const match = regex.exec(assetName);

      let name = assetName;
      let valueAfterUnderscore = null;

      if (match?.[1]) {
        name = assetName.replace(regex, '');
        valueAfterUnderscore = parseInt(match[1], 10);
      }

      if (typeof addedIds[name] === 'undefined') {
        addedIds[name] = valueAfterUnderscore || 0;
      } else if (valueAfterUnderscore) {
        addedIds[name] = Math.max(valueAfterUnderscore, addedIds[name] as number);
      } else {
        addedIds[name] += 1;
        asset.setName(`${name}_${addedIds[name]}`);
      }

      if (checkIfUniqueID) {
        return;
      }

      let id = asset.id;

      id = `${id}_${uuidv4()}`;
      asset.setId(id);

      asset.setData('unique_id', true);
    }
  });
};
