/**
 * Copyright 2021 Design Barn Inc.
 */

/* eslint-disable no-secrets/no-secrets */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useState } from 'react';

import {
  Community,
  HelpCenter,
  Keyboard,
  LottieCompatibility,
  QuestionMark,
  QuestionMarkActive,
  ReportBug,
  RequestFeature,
  VideoTutorials,
  WhatsNew,
} from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { CHANGELOG_URL, COMMUNITY_URL, SUPPORTED_FEATURES_URL } from '~/data/urls';
import { KeyboardShortcutsModal } from '~/features/keyboard-shortcuts';
import { useUsersnapApi } from '~/providers/UsersnapProvider';
import { useCreatorStore } from '~/store';

const Links = {
  videoTutorials: 'https://lottiefiles.com/courses/intro-lottie-creator/getting-lottie-creator-basics-down',
  keyShortcut: 'https://lottiefiles.notion.site/Keyboard-shortcuts-e370a87aa1ce4c249701c9ff41f9ba28',
  helpCenter: 'https://lottiefiles.notion.site/Lottie-Creator-ca8f1d3fd8a54fdea379a2dd3a381d0b',
  reportBug: 'reportBug',
  requestFeature: 'https://feedback.lottiefiles.com/creator',
  contactUs: 'https://lottiefiles.zendesk.com/hc/en-us/requests/new',
  community: COMMUNITY_URL,
};

const iconStyle = (active: boolean): string => `ml-1 mr-2.5 h-4 w-4 ${active ? 'text-white' : 'text-gray-500'}`;

export const Help: React.FC = () => {
  const isAuthorized = useCreatorStore((state) => state.user.isAuthorized);

  // Handle UserSnap
  const snapAPI = useUsersnapApi();

  const handleReportBug = useCallback(async () => {
    if (snapAPI) {
      (snapAPI as unknown).open();
    }
  }, [snapAPI]);

  // Handle link clicked
  const handleLinkClicked = useCallback((link: string): void => {
    window.open(link, '_blank');
  }, []);

  const [keyboardShortcutsModalOpen, setIsKeyboardShortcutsModalOpen] = useState(false);
  const showKeyboardShortcuts = useCallback(() => {
    setIsKeyboardShortcutsModalOpen(true);
  }, []);
  const onKeyboardShortcutModalClose = useCallback(() => {
    setIsKeyboardShortcutsModalOpen(false);
  }, []);

  const DropDownList = [
    {
      title: "What's new",
      icon: <WhatsNew />,
      link: CHANGELOG_URL,
      onClick: (link: string) => handleLinkClicked(link),
    },
    {
      title: 'Video tutorials',
      icon: <VideoTutorials />,
      link: Links.videoTutorials,
      onClick: (link: string) => handleLinkClicked(link),
    },
    {
      title: 'Help Center',
      icon: <HelpCenter />,
      link: Links.helpCenter,
      onClick: (link: string) => handleLinkClicked(link),
    },

    {
      title: 'Lottie compatibility',
      icon: <LottieCompatibility />,
      link: SUPPORTED_FEATURES_URL,
      onClick: (link: string) => handleLinkClicked(link),
    },

    {
      title: 'Keyboard shortcuts',
      icon: <Keyboard />,
      link: Links.keyShortcut,
      onClick: (_link: string) => showKeyboardShortcuts(),
    },

    {
      title: 'Report a bug',
      icon: <ReportBug />,
      link: Links.reportBug,
      onClick: handleReportBug,
      isHidden: !isAuthorized,
    },
    {
      title: 'Request feature',
      icon: <RequestFeature />,
      link: Links.requestFeature,
      onClick: (link: string) => handleLinkClicked(link),
    },
    {
      title: 'Community',
      icon: <Community />,
      link: Links.community,
      onClick: (link: string) => handleLinkClicked(link),
    },
  ];

  return (
    <>
      <KeyboardShortcutsModal onClose={onKeyboardShortcutModalClose} isOpen={keyboardShortcutsModalOpen} />
      <Menu as="div" className="relative inline-block w-[24px]">
        {({ open }) => (
          <>
            <Tooltip content="Help" placement={isAuthorized ? 'bottom' : 'bottom-end'}>
              <Menu.Button className="group flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded hover:bg-gray-700">
                {React.cloneElement(open ? <QuestionMarkActive /> : <QuestionMark />, {
                  className:
                    'h-[16px] w-[16px] cursor-pointer fill-current text-gray-400 hover:bg-gray-700 group-hover:text-white',
                })}
              </Menu.Button>
            </Tooltip>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-dropdown mt-2 w-40 origin-top-right divide-y rounded-md bg-gray-700 p-2 shadow-dropdown ring-black focus:outline-none">
                {DropDownList.filter((item) => !item.isHidden).map((dropdown) => {
                  return (
                    <Menu.Item key={dropdown.title}>
                      {({ active }) => (
                        <button
                          className={
                            'group mt-1 flex h-6 w-full items-center rounded-md border-gray-700 p-0.5 text-xs leading-normal text-white hover:border-gray-600 hover:bg-gray-600'
                          }
                          onClick={() => dropdown.onClick(dropdown.link)}
                        >
                          {React.cloneElement(dropdown.icon, {
                            className: iconStyle(active),
                          })}
                          {dropdown.title}
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};
