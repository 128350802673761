/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ScalarJSON, StarShape } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { AnimatedOuterRoundness as AnimatedOuterRoundnessIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input/NumberInput';
import { MAX_POLYSTAR_ROUNDNESS, MIN_POLYSTAR_ROUNDNESS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setPolystarOuterRoundness } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedOuterRoundness: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const animatedValue = animatedProp.value as ScalarJSON | null;
  const outerRoundness = animatedValue?.value ?? 0;

  const getNodeByIdOnly = useCreatorStore((state) => state.toolkit.getNodeByIdOnly);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeByIdOnly(animatedProp.id);

      if (node?.parent) {
        setPolystarOuterRoundness(node.parent as StarShape, result.value);
        emitter.emit(EmitterEvent.POLYSTAR_OUTER_ROUNDNESS_UPDATED);
      }
    },
    [getNodeByIdOnly, animatedProp],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="innerRoundness"
        onChange={handleOnChange}
        value={outerRoundness}
        label={<AnimatedOuterRoundnessIcon className="h-3 w-3" />}
        styleClass={styleClass}
        min={MIN_POLYSTAR_ROUNDNESS}
        max={MAX_POLYSTAR_ROUNDNESS}
        append="%"
      />
    </div>
  );
};
