/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Toolkit } from '@lottiefiles/toolkit-js';
import { Scene, PrecompositionAsset } from '@lottiefiles/toolkit-js';
import type { DotLottieExportResult } from '@lottiefiles/toolkit-plugin-dotlottie';
import { saveAs } from 'file-saver';

import { getSceneSize } from './scene';
import { dotLottiePlugin, lottiePlugin } from './toolkit';

import { DEFAULT_FILE_NAME, ExportType } from '~/data/constant';
import saveToWorkspaceService from '~/lib/saveToWorkspace';
import { useCreatorStore } from '~/store';

export const exportPrecompositionToolkitJson = async (
  toolkit: Toolkit,
  precomposition: PrecompositionAsset,
  pluginName: string = lottiePlugin.id,
): Promise<unknown> => {
  if (pluginName === lottiePlugin.id) {
    return toolkit.export(lottiePlugin.id, {
      precomposition,
      size: getSceneSize(precomposition),
    });
  }

  if (pluginName === dotLottiePlugin.id) {
    const scene = precomposition.toScene();

    scene.setSize(getSceneSize(precomposition));

    return toolkit.export(dotLottiePlugin.id, {
      scene,
    });
  }

  return null;
};

export const exportToolkitJson = async (
  toolkit: Toolkit,
  scene: Scene,
  pluginName: string = lottiePlugin.id,
): Promise<unknown> => {
  const result = await toolkit.export(pluginName, {
    scene,
    filePath: 'LottieFiles.lottie',
  });

  return result;
};

export const exportToolkit = async (toolkit: Toolkit, scene: Scene, type = ExportType.JSON): Promise<unknown> => {
  const lottieJSON = await exportToolkitJson(toolkit, scene);

  const projectName = useCreatorStore.getState().project.info.name;

  const lottieName = `${(scene instanceof PrecompositionAsset ? scene.name : projectName) || DEFAULT_FILE_NAME}`;

  if (type === ExportType.JSON && lottieJSON) {
    const filename = `${lottieName}.json`;
    const blob = new Blob([JSON.stringify(lottieJSON)], { type: 'text/plain;charset=utf-8' });

    saveAs(blob, filename);
  }

  if (type === ExportType.DotLottie) {
    const { dotLottieInstance } = (await exportToolkitJson(
      toolkit,
      scene,
      dotLottiePlugin.id,
    )) as DotLottieExportResult;

    const filename = `${lottieName}.lottie`;

    await dotLottieInstance.download(filename);
  }

  return lottieJSON;
};

export const exportToWorkspace = async (
  toolkit: Toolkit,
  scene: Scene | PrecompositionAsset | null,
): Promise<unknown> => {
  if (!scene) return Promise.resolve(null);

  const { buffer: dotLottieBuffer } = (
    scene instanceof Scene
      ? await exportToolkitJson(toolkit, scene, dotLottiePlugin.id)
      : await exportPrecompositionToolkitJson(toolkit, scene, dotLottiePlugin.id)
  ) as DotLottieExportResult;

  const lottieSavedUrl = null;

  const projectName = useCreatorStore.getState().project.info.name;

  const filename = `${(scene instanceof PrecompositionAsset ? scene.name : projectName) || DEFAULT_FILE_NAME}.lottie`;

  // Hotfixing for now!! We shouldnt be using axios to make GQL calls, but for that to work we need to rewrite this using hooks
  try {
    // 1. Post to get presigned url
    const response = await saveToWorkspaceService({
      data: {
        // eslint-disable-next-line no-secrets/no-secrets
        operationName: 'workflowTempFileUploadRequestCreate',
        // eslint-disable-next-line no-secrets/no-secrets
        query: `mutation workflowTempFileUploadRequestCreate{
          workflowTempFileUploadRequestCreate(filename: "${filename}") {
            preSignedUploadURL
            publicReadURL
          }
        }`,
        variables: {},
      },
    });

    // 2. Get presigned url and read url from response and post to presigned url
    // Adding the eslint ignore for now. We need to move this function out and avoid using axios to make GQL calls
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (response.data && response.data.data && response.data.data.workflowTempFileUploadRequestCreate) {
      const preSignedUploadURL = response.data.data.workflowTempFileUploadRequestCreate.preSignedUploadURL;
      const publicReadURL = response.data.data.workflowTempFileUploadRequestCreate.publicReadURL;

      await saveToWorkspaceService({
        url: preSignedUploadURL,
        data: dotLottieBuffer,
        method: 'PUT',
      });

      // 3. resolve read url
      return publicReadURL;
    }
  } catch (ex) {
    return null;
  }

  return lottieSavedUrl;
};
