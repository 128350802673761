/**
 * Copyright 2023 Design Barn Inc.
 */

import type {
  AnimatedAngleProperty,
  AnimatedPercentageProperty,
  AnimatedPositionProperty,
  AnimatedScalarProperty,
  AnimatedVectorProperty,
  AnimatedColorProperty,
  CubicBezierShape,
  EllipseShape,
  FillShape,
  GroupShape,
  Layer,
  PathShape,
  RectangleShape,
  RoundedCornersShape,
  StarShape,
  GradientFillShape,
  Scene,
  PrecompositionAsset,
  Timeline,
  TrimShape,
  PrecompositionLayer,
  Track,
  DagNode,
  Keyframe,
  GradientColor,
  Color,
  Scalar,
  Size,
  Angle,
  Percentage,
  Rect,
  HoldInterpolator,
  LinearInterpolator,
  Vector,
  BezierInterpolator,
  Value,
  Interpolator,
  AnimatedGradientProperty,
} from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../../Plugin';

import { marshal } from './marshal';
import { handleOutgoingValueTypes } from './marshal/class';
import { stripReturnValues } from './marshal/strip-return-values';
import { unmarshal } from './unmarshal';

import { emitter, EmitterEvent } from '~/lib/emitter';

// storing the method names as a key-value pair reference
// as shim may modify method name (e.g. "getName" in shim vs "name" in toolkit)
// uses the format [shimName: "toolkitName"]
interface MethodNames {
  [key: string]: string;
}

export interface ObjectMethods {
  [key: string]: (...args: any[]) => object | number | string | boolean | void;
}

export interface Modifiers {
  [key: string]: (
    result: object[] | object | number | string | boolean | void,
    ...args: any[]
  ) => object[] | object | number | string | boolean | void;
}

export function vmInterface<T extends DagNode | Value | Keyframe | Track | Interpolator>(
  plugin: Plugin,
  node: T,
  toolkitMethod: (node: DagNode, ...args: any[]) => string | number | boolean | object,
  ...vmArgs: QuickJSHandle[]
): QuickJSHandle | void {
  const args = unmarshal(plugin, vmArgs);

  // pass arguments to toolkit method and get return values
  const returnValues = args ? toolkitMethod(node as DagNode, ...args) : toolkitMethod(node as DagNode);

  let vmReturnValues;

  if (returnValues || returnValues === 0 || typeof returnValues === 'boolean') {
    // appends a 'valueType' property to values like Vector, Angle, etc
    // so the sandbox can recognize them
    const updatedReturnValues = handleOutgoingValueTypes(plugin, returnValues);

    // create stripped out objects for quickjs
    const strippedReturnValues = stripReturnValues(plugin, updatedReturnValues as object | number | string | boolean);

    // translate return arguments to quickjs values
    vmReturnValues = marshal(plugin, strippedReturnValues) as QuickJSHandle | void;
  }

  return vmReturnValues;
}

function applyModifier(
  methodName: string,
  result: string | number | boolean | void | object,
  args: unknown[],
  modifiers?: Modifiers,
): string | number | boolean | void | object {
  // calls modifier functions after original method is executed
  // to modify outcomes or fire events

  if (modifiers && methodName in modifiers) {
    const modifierFunction = modifiers[methodName];
    const updatedResult = modifierFunction && modifierFunction(result, args);

    return updatedResult;
  }

  return result;
}

function executeMethod(node: DagNode, methodName: string, args: QuickJSHandle[]): object | string | number | boolean {
  const handle = node[methodName as keyof typeof node];
  const result = typeof handle === 'function' ? handle.apply(node, args) : handle;

  return result as string | number | boolean | object;
}

function createMethod(methodName: string, modifiers?: Modifiers) {
  return (node: DagNode, ...args: QuickJSHandle[]) => {
    let result = executeMethod(node, methodName, args);

    if (modifiers) {
      const modiferResult = applyModifier(methodName, result, args, modifiers);

      if (modiferResult) result = modiferResult;
    }

    return result;
  };
}

export function registerObjectMethods<T extends DagNode | Value | Keyframe | Track | Interpolator>(
  plugin: Plugin,
  sourceObject: T,
  targetObject: object,
  objectMethodsArray: Array<(plugin: Plugin, object: T) => ObjectMethods>,
): void {
  objectMethodsArray.forEach((objectMethods) => {
    Object.defineProperties(targetObject, Object.getOwnPropertyDescriptors(objectMethods(plugin, sourceObject)));
  });
}

export function getObjectMethods(
  plugin: Plugin,
  methodNames: MethodNames,
  node:
    | DagNode
    | Layer
    | GroupShape
    | Vector
    | Color
    | GradientColor
    | Percentage
    | Rect
    | Scalar
    | Size
    | Angle
    | CubicBezierShape
    | EllipseShape
    | PathShape
    | RectangleShape
    | RoundedCornersShape
    | StarShape
    | AnimatedPositionProperty
    | AnimatedPercentageProperty
    | AnimatedAngleProperty
    | AnimatedScalarProperty
    | AnimatedVectorProperty
    | AnimatedColorProperty
    | AnimatedGradientProperty
    | FillShape
    | GradientFillShape
    | Scene
    | PrecompositionAsset
    | Timeline
    | TrimShape
    | PrecompositionLayer
    | Keyframe
    | Track
    | HoldInterpolator
    | LinearInterpolator
    | BezierInterpolator,
  modifiers?: Modifiers,
  additionalMethods?: ObjectMethods,
): ObjectMethods {
  const wrappedMethods = {} as ObjectMethods;

  for (const method of Object.keys(methodNames)) {
    const toolkitMethod = methodNames[method] as string;

    // Wrapping in try/catch to prevent errors being thrown on accessor methods,
    // which halts the return of the object. Eg. if a precompositionAsset has no scene,
    // an error will be thrown while creating the wrapper for the scene accessor.
    try {
      if (typeof node[method as keyof typeof node] === 'function') {
        wrappedMethods[method] = (...args) => {
          const result = vmInterface(plugin, node, createMethod(toolkitMethod, modifiers), ...args);

          emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);

          return result;
        };
      } else {
        Object.defineProperty(wrappedMethods, method, {
          get: (...args) => vmInterface(plugin, node, createMethod(toolkitMethod, modifiers), ...args),
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  if (additionalMethods) {
    for (const method of Object.keys(additionalMethods)) {
      wrappedMethods[method] = (...args) => vmInterface(plugin, node, additionalMethods[method], ...args);
    }
  }

  return wrappedMethods;
}
