/**
 * Copyright 2024 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/lottie-js';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import { EyeOpen, HorizontalGridIcon, InvisibleEye, Minus, Plus, VerticalGridIcon } from '~/assets/icons';
import { ColorInput } from '~/components/Elements/ColorInput/ColorInput';
import { hexToRgb, rgbToHex } from '~/components/Elements/ColorPicker/helpers';
import { DualNumberInput } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

const setGrid = useCreatorStore.getState().canvas.setGrid;

export const Grid: FC = () => {
  const grid = useCreatorStore((state) => state.canvas.grid);

  const toggleGrid = useCallback(() => {
    emitter.emit(EmitterEvent.CANVAS_TOGGLE_GRID);
  }, []);

  const toggleGridVisibility = useCallback(() => {
    emitter.emit(EmitterEvent.CANVAS_TOGGLE_GRID_VISIBILITY);
  }, []);

  const handleChange: Parameters<typeof DualNumberInput>['0']['onChange'] = useCallback((result) => {
    if (result.name === 'horizontal') {
      setGrid({ rows: result.value });
      emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);

      return;
    }

    if (result.name === 'vertical') {
      setGrid({ columns: result.value });
      emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);
    }
  }, []);

  const handleColorChange: Parameters<typeof ColorInput>['0']['onChangeColor'] = useCallback(
    (newColor) => {
      const { blue: b, green: g, red: r } = hexToRgb(newColor);

      setGrid({ color: { r, g, b, a: grid.color.a } });
      emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);
    },
    [grid.color.a],
  );

  const handleOpacityChange = useCallback(
    (opacity: number) => {
      if (grid.color.a === opacity / 100) return;
      setGrid({ color: { ...grid.color, a: opacity / 100 } });
      emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);
    },
    [grid.color],
  );

  return (
    <div className="pb-3 pl-4 pr-3 pt-4">
      <div className="flex items-center justify-between text-xs font-bold text-gray-300">
        <p>Grid</p>
        <button onClick={toggleGrid}>
          {grid.enabled ? (
            <Minus className=" h-6 w-6 stroke-gray-300 p-1 hover:rounded hover:bg-gray-700 hover:stroke-white" />
          ) : (
            <Plus className=" h-6 w-6 stroke-gray-300 p-1 hover:rounded hover:bg-gray-700 hover:stroke-white" />
          )}
        </button>
      </div>
      {grid.enabled ? (
        <>
          <div className="mt-3 flex justify-between">
            <DualNumberInput
              onChange={handleChange}
              left={grid.rows}
              right={grid.columns}
              leftOption={{ name: 'horizontal', label: <HorizontalGridIcon />, min: 2, max: 100 }}
              rightOption={{ name: 'vertical', label: <VerticalGridIcon />, min: 2, max: 100 }}
              leftMin={2}
              leftMax={100}
              rightMin={2}
              rightMax={100}
              isChannelAnimated={false}
            />
            <button onClick={toggleGridVisibility}>
              {grid.visible ? (
                <EyeOpen className="h-6 w-6 fill-white p-1" />
              ) : (
                <InvisibleEye className="h-6 w-6 fill-white p-1" />
              )}
            </button>
          </div>
          <p className="my-2 text-xs text-gray-300">Color</p>
          <ColorInput
            shapeType={ShapeType.FILL}
            styleClass="!w-[86px]"
            color={rgbToHex(grid.color.r, grid.color.g, grid.color.b)}
            opacity={grid.color.a * 100}
            onChangeColor={handleColorChange}
            onChangeOpacity={handleOpacityChange}
            colorStyleClass={`!h-full`}
            inputStyleClass="!w-full"
            enableColorModeChange={false}
            isChannelAnimated={false}
            showDocumentColors={false}
          />
        </>
      ) : null}
    </div>
  );
};
