/**
 * Copyright 2024 Design Barn Inc.
 */

import { Dialog } from '@headlessui/react';
import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from '~/components/Elements/Modal';

export const WarningModal: React.FC = () => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const handleResize = (): void => {
      setShowWarning(!window.matchMedia('(min-width: 1024px)').matches);
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dismissWarning = useCallback((): void => {
    setShowWarning(false);
  }, []);

  return (
    <Modal isOpen={showWarning}>
      <div className="fixed inset-0 overflow-y-auto px-4" id="warning-modal">
        <div className="flex min-h-full items-center justify-center  text-center">
          <Dialog.Panel className=" flex max-w-[550px] flex-col items-center overflow-hidden bg-transparent align-middle transition-all">
            {/* Display this section on screens < md (768px) */}
            <Dialog.Title as="h3" className="relative mb-4 px-5 text-xl font-bold text-white md:hidden">
              Works best on desktop
            </Dialog.Title>
            <div className="mb-4 text-base text-white md:hidden">
              Switch to a desktop with a viewing width of at least 1024px to enjoy a better experience.
            </div>
            {/* Display this section on screens < lg (1024px), but not on mobile */}
            <Dialog.Title as="h3" className="relative mb-4 hidden px-5 text-xl font-bold text-white md:block">
              Expand your window
            </Dialog.Title>
            <div className="mb-4 hidden text-base text-white md:block ">
              Expand your window to a viewing width of at least 1024px to enjoy a better experience.
            </div>
            {/* Display on screens < lg (1024px), but not on mobile */}
            <button
              className=" hidden rounded-lg bg-[#00C1A2] px-4 py-1 text-base font-bold text-white md:block"
              onClick={dismissWarning}
            >
              OK
            </button>
          </Dialog.Panel>
        </div>
      </div>
    </Modal>
  );
};
