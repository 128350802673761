/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';
import { shallow } from 'zustand/shallow';

import { EmptyCanvasMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapper } from './ContextMenuWrapper';

import { MenuType } from '~/data/constant';
import { useCreatorStore } from '~/store';

interface EmptyCanvasMenuProps {
  coord: Coordinate;
  onClose: () => void;
}

export const EmptyCanvasMenu: React.FC<EmptyCanvasMenuProps> = ({ coord, onClose }) => {
  const [timelineVisible, setTimelineVisible, isUIMinimized, setIsUIMinimized] = useCreatorStore(
    (state) => [state.timeline.visible, state.timeline.setVisible, state.ui.isUIMinimized, state.ui.setIsUIMinimized],
    shallow,
  );

  const toggleTimelineMenu = EmptyCanvasMenuList.find((item) => item.type === MenuType.ToggleTimeline);

  if (toggleTimelineMenu) toggleTimelineMenu.callback = () => setTimelineVisible(!timelineVisible);

  const toggleUIMenu = EmptyCanvasMenuList.find((item) => item.type === MenuType.ToggleUI);

  if (toggleUIMenu) toggleUIMenu.callback = () => setIsUIMinimized(!isUIMinimized);

  return <ContextMenuWrapper coord={coord} onClose={onClose} dropdownItems={EmptyCanvasMenuList}></ContextMenuWrapper>;
};
