/**
 * Copyright 2022 Design Barn Inc.
 */

import type { VectorJSON, CubicBezierShape, Vector } from '@lottiefiles/toolkit-js';
import type { PixelFormat, TextureDataType } from 'three';
import { Matrix4, FloatType, DataTexture, Shape, RGFormat, RedFormat, Color } from 'three';

import { BLUE_COLOR, RaycasterLayers } from '../../constant';
import type { BezierMesh } from '../../types/object';
import { createBezierFill, createBezierStroke, DefaultBezierUniforms } from '../threeFactory';

export interface PathPoint {
  in: Vector;
  out: Vector;
  vertex: Vector;
}

/*
  DataTexture is used to pass dynamic size of arrays in the form of a texture to the fragment shaders since we can't directly pass dynamic array.
*/
export interface BezierUniforms {
  uBezierCount: number;
  uBezierPoints: DataTexture;
  uFillRule?: number;
  uIsClosed?: boolean;
}

export interface MaskUniforms {
  uMaskBezierCount?: number;
  uMaskBezierPoints?: DataTexture;
  uMaskMode?: number;
  uMaskOpacity?: number;
}

export interface MatteUniforms {
  uMatteBezierCount?: number;
  uMatteBezierPoints?: DataTexture;
  uMatteMode?: number;
  uMatteTransform?: Matrix4;
}

export interface CompoundBezierUniforms {
  uBezierCount: number;
  uBezierPoints: DataTexture;
  uBezierSegmentCounts: DataTexture;
  uFillRule: number;
  uIsClosed: boolean;
  uTotalSegments: number;
}

export interface MaskProps {
  maskMode?: number;
  maskOpacity?: number;
  matteMode?: number;
  offsetMatrix?: Matrix4;
}

const createDataTexture = (
  attr: Float32Array,
  width: number,
  height: number,
  format: PixelFormat = RGFormat,
  type: TextureDataType = FloatType,
): DataTexture => {
  const texture = new DataTexture(attr, width, height, format, type);

  texture.needsUpdate = true;

  return texture;
};

export const getBezierUniforms = (bezier: CubicBezierShape, isFill: boolean): BezierUniforms => {
  const { isClosed, points } = bezier;

  const newPoints = points as Vector[][];
  const pointsCount = newPoints.length;

  const textureWidth = pointsCount * 2;
  const textureHeight = 4;

  // Initialize attributes
  const attr = new Float32Array(textureWidth * textureHeight);

  const setBezierPoints = (index: number, point: Vector[], nextPoint: Vector[]): void => {
    const out = point[2];
    const nextVertice = nextPoint[1];
    const nextIn = nextPoint[0];

    if (!out || !nextVertice || !nextIn || !point[1]) return;

    const textureIndex = index * 2;

    attr[textureIndex] = point[1].x;
    attr[textureIndex + 1] = point[1].y;

    attr[textureWidth + textureIndex] = point[1].x + out.x;
    attr[textureWidth + textureIndex + 1] = point[1].y + out.y;

    attr[textureWidth * 2 + textureIndex] = nextVertice.x + nextIn.x;
    attr[textureWidth * 2 + textureIndex + 1] = nextVertice.y + nextIn.y;

    attr[textureWidth * 3 + textureIndex] = nextVertice.x;
    attr[textureWidth * 3 + textureIndex + 1] = nextVertice.y;
  };

  newPoints.forEach((point: Vector[], index: number) => {
    if (index === pointsCount - 1) {
      if (isClosed || isFill) {
        setBezierPoints(index, point, newPoints[0] as Vector[]);
      }
    } else {
      setBezierPoints(index, point, newPoints[index + 1] as Vector[]);
    }
  });

  return {
    uBezierPoints: createDataTexture(attr, pointsCount, 4),
    uIsClosed: isClosed,
    uBezierCount: pointsCount,
    uFillRule: 2,
  };
};

export const getCompoundBezierUniforms = (beziers: CubicBezierShape[], isFill: boolean): CompoundBezierUniforms => {
  const bezierSegmentCounts = new Float32Array(beziers.length);
  const bezierTotalCount = beziers.reduce((prev, curr) => prev + curr.points.length, 0);

  const textureWidth = bezierTotalCount * 2;
  const textureHeight = 4;

  const attr = new Float32Array(textureWidth * textureHeight);

  let totalIndex = 0;
  let totalSegments = 0;

  const setBezierPoints = (index: number, point: Vector[], nextPoint: Vector[]): void => {
    const out = point[2];
    const nextVertice = nextPoint[1];
    const nextIn = nextPoint[0];

    if (!out || !nextVertice || !nextIn || !point[1]) return;

    const textureIndex = totalIndex + index * 2;

    attr[textureIndex] = point[1].x;
    attr[textureIndex + 1] = point[1].y;

    attr[textureWidth + textureIndex] = point[1].x + out.x;
    attr[textureWidth + textureIndex + 1] = point[1].y + out.y;

    attr[textureWidth * 2 + textureIndex] = nextVertice.x + nextIn.x;
    attr[textureWidth * 2 + textureIndex + 1] = nextVertice.y + nextIn.y;

    attr[textureWidth * 3 + textureIndex] = nextVertice.x;
    attr[textureWidth * 3 + textureIndex + 1] = nextVertice.y;
  };

  beziers.forEach((bezier, bezierIndex) => {
    const { isClosed, points } = bezier;
    const pointsCount = points.length;

    points.forEach((point, index) => {
      if (index === pointsCount - 1) {
        if (isClosed || isFill) {
          setBezierPoints(index, point, points[0] as Vector[]);
        }
      } else {
        setBezierPoints(index, point, points[index + 1] as Vector[]);
      }
    });

    const bezierCount = pointsCount;

    totalIndex += pointsCount * 2;
    bezierSegmentCounts[bezierIndex] = bezierCount;
    totalSegments += bezierCount;
  });

  const bezierSegmentCountsTexture = new DataTexture(bezierSegmentCounts, beziers.length, 1, RedFormat, FloatType);

  bezierSegmentCountsTexture.needsUpdate = true;

  return {
    uBezierPoints: createDataTexture(attr, bezierTotalCount, 4),
    uBezierCount: beziers.length,
    uBezierSegmentCounts: bezierSegmentCountsTexture,
    uTotalSegments: totalSegments,
    uFillRule: 2,
    uIsClosed: true,
  };
};

export const getCompoundBezierShape = (
  { uBezierCount, uBezierPoints, uBezierSegmentCounts, uTotalSegments }: CompoundBezierUniforms,
  isClosed: boolean,
  isGradient: boolean,
  maskUniforms?: MaskUniforms | null,
  matteUniforms?: MatteUniforms | null,
): BezierMesh => {
  const pathMesh = createBezierFill(
    {
      ...DefaultBezierUniforms(),
      uBezierCount: { value: uBezierCount },
      uBezierSegmentCounts: { value: uBezierSegmentCounts },
      uTotalSegments: { value: uTotalSegments },
      uBezierPoints: { value: uBezierPoints },
      uIsClosed: { value: isClosed },

      uMaskBezierCount: { value: maskUniforms?.uMaskBezierCount ?? 0 },
      uMaskBezierPoints: { value: maskUniforms?.uMaskBezierPoints ?? new DataTexture() },
      uMaskMode: { value: maskUniforms?.uMaskMode ?? 0 },
      uMaskOpacity: { value: maskUniforms?.uMaskOpacity ?? 1 },

      uMatteBezierCount: { value: matteUniforms?.uMatteBezierCount ?? 0.0 },
      uMatteBezierPoints: { value: matteUniforms?.uMatteBezierPoints ?? new DataTexture() },
      uMatteMode: { value: matteUniforms?.uMatteMode ?? 0 },
      uMatteTransform: { value: matteUniforms?.uMatteTransform ?? new Matrix4() },
    },
    true,
    isGradient,
  );

  pathMesh.name = 'bezier';

  pathMesh.layers.enable(RaycasterLayers.CMesh);

  return pathMesh as unknown as BezierMesh;
};

export const getBezierShape = (
  { uBezierCount, uBezierPoints }: BezierUniforms,
  isClosed: boolean,
  isGradient: boolean,
  maskUniforms?: MaskUniforms | null,
  matteUniforms?: MatteUniforms | null,
): BezierMesh => {
  const pathMesh = createBezierFill(
    {
      ...DefaultBezierUniforms(),
      uBezierCount: { value: uBezierCount },
      uBezierPoints: { value: uBezierPoints },
      uIsClosed: { value: isClosed },

      uMaskBezierCount: { value: maskUniforms?.uMaskBezierCount ?? 0 },
      uMaskBezierPoints: { value: maskUniforms?.uMaskBezierPoints ?? new DataTexture() },
      uMaskMode: { value: maskUniforms?.uMaskMode ?? 0 },
      uMaskOpacity: { value: maskUniforms?.uMaskOpacity ?? 1 },

      uMatteBezierCount: { value: matteUniforms?.uMatteBezierCount ?? 0 },
      uMatteBezierPoints: { value: matteUniforms?.uMatteBezierPoints ?? new DataTexture() },
      uMatteMode: { value: matteUniforms?.uMatteMode ?? 0 },
      uMatteTransform: { value: matteUniforms?.uMatteTransform ?? new Matrix4() },
    },
    false,
    isGradient,
  );

  pathMesh.name = 'bezier';

  pathMesh.layers.enable(RaycasterLayers.CMesh);

  return pathMesh as unknown as BezierMesh;
};

export const getCompoundBezierStroke = (
  { uBezierCount, uBezierPoints, uBezierSegmentCounts, uTotalSegments }: CompoundBezierUniforms,
  strokeWidth: number,
  isGradient: boolean,
  maskUniforms?: MaskUniforms | null,
  matteUniforms?: MatteUniforms | null,
): BezierMesh => {
  const pathMesh = createBezierStroke(strokeWidth, true, isGradient, {
    ...DefaultBezierUniforms(),
    uBezierCount: { value: uBezierCount },
    uBezierSegmentCounts: { value: uBezierSegmentCounts },
    uTotalSegments: { value: uTotalSegments },
    uBezierPoints: { value: uBezierPoints },
    uStrokeWidth: { value: strokeWidth },

    uMaskBezierCount: { value: maskUniforms ? maskUniforms.uMaskBezierCount : 0 },
    uMaskBezierPoints: { value: maskUniforms ? maskUniforms.uMaskBezierPoints : new DataTexture() },
    uMaskMode: { value: maskUniforms?.uMaskMode ?? 0 },
    uMaskOpacity: { value: maskUniforms?.uMaskOpacity ?? 1 },

    uMatteBezierCount: { value: matteUniforms?.uMatteBezierCount ?? 0 },
    uMatteBezierPoints: { value: matteUniforms?.uMatteBezierPoints ?? new DataTexture() },
    uMatteMode: { value: matteUniforms?.uMatteMode ?? 0 },
    uMatteTransform: { value: matteUniforms?.uMatteTransform ?? new Matrix4() },
  });

  return pathMesh;
};

export const getBezierStroke = (
  { uBezierCount, uBezierPoints, uIsClosed }: BezierUniforms,
  strokeWidth: number,
  isGradient: boolean,
  maskUniforms?: MaskUniforms | null,
  matteUniforms?: MatteUniforms | null,
): BezierMesh => {
  const strokeUniforms = {
    ...DefaultBezierUniforms(),
    uBezierCount: { value: uBezierCount },
    uBezierPoints: { value: uBezierPoints },
    // eslint-disable-next-line no-undefined
    ...(uIsClosed !== undefined && { uIsClosed: { value: uIsClosed } }),
    uStrokeWidth: { value: strokeWidth },

    uMaskBezierCount: { value: maskUniforms ? maskUniforms.uMaskBezierCount : 0 },
    uMaskBezierPoints: { value: maskUniforms ? maskUniforms.uMaskBezierPoints : new DataTexture() },
    uMaskMode: { value: maskUniforms?.uMaskMode ?? 0 },
    uMaskOpacity: { value: maskUniforms?.uMaskOpacity ?? 1 },

    uMatteBezierCount: { value: matteUniforms?.uMatteBezierCount ?? 0 },
    uMatteBezierPoints: { value: matteUniforms?.uMatteBezierPoints ?? new DataTexture() },
    uMatteMode: { value: matteUniforms?.uMatteMode ?? 0 },
    uMatteTransform: { value: matteUniforms?.uMatteTransform ?? new Matrix4() },
  };

  const pathMesh = createBezierStroke(strokeWidth, false, isGradient, strokeUniforms);

  return pathMesh;
};

export const addOutline = (
  bezierFill: BezierMesh,
  strokeUniforms: BezierUniforms | CompoundBezierUniforms,
  isCompoundBezier: boolean,
  isGradientStroke: boolean,
): void => {
  const strokeMesh = isCompoundBezier
    ? getCompoundBezierStroke(strokeUniforms as CompoundBezierUniforms, 1, isGradientStroke)
    : getBezierStroke(strokeUniforms as BezierUniforms, 1, isGradientStroke, null);

  strokeMesh.material.uniforms['uColor'] = { value: new Color(BLUE_COLOR) };
  strokeMesh.material.uniforms['uOpacity'] = { value: 1 };

  strokeMesh.name = 'outline';
  strokeMesh.visible = false;
  bezierFill.outline = strokeMesh;

  bezierFill.add(strokeMesh);
};

export const drawBezier = (points: PathPoint[], closed: boolean): Shape => {
  const numberOfVertices = points.length;
  const tempShape = new Shape();

  if (points.length > 0 && points[0]) {
    tempShape.moveTo(points[0].vertex.x, points[0].vertex.y);
    let i = 1;

    while (i < numberOfVertices) {
      const currentPoint = points[i];
      const prevPoint = points[i - 1];

      if (!currentPoint || !prevPoint) continue;

      const prevPathV = prevPoint.vertex;
      const prevPathOut = prevPoint.out;

      const currentPathV = currentPoint.vertex;
      const currentPathIn = currentPoint.in;

      tempShape.bezierCurveTo(
        prevPathV.x + prevPathOut.x,
        prevPathV.y + prevPathOut.y,
        currentPathV.x + currentPathIn.x,
        currentPathV.y + currentPathIn.y,
        currentPathV.x,
        currentPathV.y,
      );
      i += 1;
    }

    const lastPoint = points[numberOfVertices - 1];

    if (closed && lastPoint) {
      tempShape.bezierCurveTo(
        lastPoint.vertex.x + lastPoint.out.x,
        lastPoint.vertex.y + lastPoint.out.y,
        points[0].vertex.x + points[0].in.x,
        points[0].vertex.y + points[0].in.y,
        points[0].vertex.x,
        points[0].vertex.y,
      );
    }
  }

  return tempShape;
};

export enum VertexType {
  CURVE = 'curve',
  DISCONNECTED = 'disconnected',
  MIRRORED = 'mirrored',
  SHARP = 'sharp',
}

const roundVertexPosition = (position: number, placeValue: number = 1000): number => {
  return Math.round(position * placeValue) / placeValue;
};

export const arePointsCollinear = (inPoint: VectorJSON, centerPoint: VectorJSON, outPoint: VectorJSON): boolean => {
  // Calculate vectors AB and BC
  const vectorAB: VectorJSON = { x: centerPoint.x - inPoint.x, y: centerPoint.y - inPoint.y };
  const vectorBC: VectorJSON = { x: outPoint.x - centerPoint.x, y: outPoint.y - centerPoint.y };

  // Check if vectors AB and BC are parallel
  return roundVertexPosition(vectorAB.x * vectorBC.y) === roundVertexPosition(vectorAB.y * vectorBC.x);
};

export const areDistancesEqual = (inPoint: VectorJSON, centerPoint: VectorJSON, outPoint: VectorJSON): boolean => {
  // Calculate magnitudes of vectors AB and BC
  const distanceAB: number = Math.sqrt((centerPoint.x - inPoint.x) ** 2 + (centerPoint.y - inPoint.y) ** 2);
  const distanceBC: number = Math.sqrt((outPoint.x - centerPoint.x) ** 2 + (outPoint.y - centerPoint.y) ** 2);

  // Check if distances are equal
  return roundVertexPosition(distanceAB) === roundVertexPosition(distanceBC);
};

export const isVertexesAtSamePosition = (vertex1: VectorJSON, vertex2: VectorJSON): boolean => {
  return (
    roundVertexPosition(vertex1.x) === roundVertexPosition(vertex2.x) &&
    roundVertexPosition(vertex1.y) === roundVertexPosition(vertex2.y)
  );
};

export const isFirstAndLastInSamePosition = (vertex1: VectorJSON, vertex2: VectorJSON): boolean => {
  return (
    roundVertexPosition(vertex1.x, 10) === roundVertexPosition(vertex2.x, 10) &&
    roundVertexPosition(vertex1.y, 10) === roundVertexPosition(vertex2.y, 10)
  );
};

export const getVertexType = (inPoint: VectorJSON, centerPoint: VectorJSON, outPoint: VectorJSON): VertexType => {
  if (isVertexesAtSamePosition(inPoint, outPoint)) {
    return VertexType.SHARP;
  }

  if (arePointsCollinear(inPoint, centerPoint, outPoint)) {
    if (areDistancesEqual(inPoint, centerPoint, outPoint)) {
      return VertexType.MIRRORED;
    }

    return VertexType.CURVE;
  }

  return VertexType.DISCONNECTED;
};
