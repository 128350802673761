/**
 * Copyright 2021 Design Barn Inc.
 */

import clsx from 'clsx';
import { throttle } from 'lodash-es';
import type { KeyboardEventHandler } from 'react';
import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';

import KeyframeButton from '../Button/KeyframeButton';

import { defaultStyle } from './NumberInput';
import type { NumberResult } from './types';

import './input.css';
import { EmitterEvent } from '~/lib/emitter';
import { redoHistory, undoHistory } from '~/lib/history';
import { stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { GlobalCursorType } from '~/store/uiSlice';
import { formatNumber } from '~/utils';

export interface PeriodicInputStyle {
  input?: string;
  keyframe?: string;
  label?: string;
  span?: string;
}

export interface PeriodicInputProps {
  allowNegative: boolean;
  append?: string;
  hasKeyframe?: boolean;
  label?: string | React.ReactNode | null;
  max?: number | null;
  message?: string | null;
  min?: number | null;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange: (result: NumberResult) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyframeClick?: () => void;
  period: number;
  precision?: number;
  showKeyframe?: boolean;
  step?: number;
  styleClass?: PeriodicInputStyle;
  value: number;
}

export const PeriodicInput: React.FC<PeriodicInputProps> = ({
  append = '',
  hasKeyframe = false,
  label = null,
  message,
  name,
  onChange,
  onKeyframeClick,
  period,
  precision = 4,
  showKeyframe = false,
  step = 1,
  styleClass,
  value,
}) => {
  const setHideTransformControls = useCreatorStore.getState().canvas.setHideTransformControls;
  const formatValue = useCallback(
    (val: number) => {
      let num = val;

      if (period !== 0) {
        num %= period;
      }

      // regular expression for removing the trailing zeros
      const formatted = Number.isInteger(num) ? num : num.toFixed(precision).replace(/\.?0+$/u, '');

      return `${formatted}`;
    },
    [period, precision],
  );

  const [input, setInput] = React.useState<string>(formatValue(value));
  // NOTE(miljau): had to throttle it because the updates were too fast
  // updates will be throttled to 1 per 50ms
  const throttledOnChange = useRef(throttle(onChange, 50));

  useEffect(() => {
    setInput(formatValue(value));
  }, [value, setInput, formatValue]);

  const [focused, setFocused] = useState(false);
  const [pointerDown, setPointerDown] = useState(false);
  const [dragStartValue, setDragStartValue] = useState<null | number>(null);
  const [wasDragged, setWasDragged] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [arrowKeyPressedEvent, setArrowKeyPressedEvent] = useState(false);

  const inputRef = React.createRef<HTMLInputElement>();
  const style = useMemo(() => ({ ...defaultStyle, ...styleClass }), [styleClass]);

  useEffect(() => {
    if (wasDragged) {
      setHideTransformControls(true);
      document.addEventListener('pointerup', () => setHideTransformControls(false), { once: true });
    }
  }, [setHideTransformControls, wasDragged]);

  const handleKeyframeClick = useCallback((): void => {
    if (onKeyframeClick) {
      onKeyframeClick();
    }
  }, [onKeyframeClick]);

  const propagateValue = useCallback(
    (newValue: number, wasArrowKeyPressed: boolean = false) => {
      if (newValue === value) {
        return;
      }
      setInput(formatValue(newValue));
      setWasDragged(dragStartValue !== null);

      // Assumed that the newValue is a valid number
      throttledOnChange.current({
        name,
        value: newValue,
        trueValue: 0,
        arrowKeyPressed: wasArrowKeyPressed,
        dragged: dragStartValue !== null,
        change: formatNumber(newValue - value, precision),
      });
    },

    [value, formatValue, dragStartValue, name, precision],
  );

  const handleOnBlurInput = useCallback(
    (_event: React.FocusEvent<HTMLInputElement>) => {
      if (wasDragged) {
        setWasDragged(false);

        return;
      }

      const newValue = parseFloat(input);
      const isNewInputValid = !Number.isNaN(newValue) && Number.isFinite(newValue);

      if (!isNewInputValid) {
        // reset input to actual value
        setInput(formatValue(value));
        setFocused(false);

        return;
      }

      setInput(formatValue(newValue));

      if (focused) {
        if (period === 0) {
          propagateValue(newValue);
        } else propagateValue(Math.trunc(value / period) * period + newValue);
        setFocused(false);
      }

      if (!arrowKeyPressedEvent) {
        stateHistory.endAction();
      }

      setShowMessage(true);
    },

    [wasDragged, input, focused, arrowKeyPressedEvent, formatValue, value, propagateValue, period],
  );

  const handleOnChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      stateHistory.beginAction();

      setShowMessage(false);

      const newInput = event.target.value;

      if (focused) {
        setInput(newInput);
      }
    },
    [focused, setInput],
  );

  const handleArrowKeyPressEnd = useCallback(() => {
    setHideTransformControls(false);
    setArrowKeyPressedEvent(false);
    stateHistory.endAction();
  }, [setHideTransformControls]);

  const handleArrowKeyPress = useCallback(
    (repeat: boolean) => {
      setArrowKeyPressedEvent(true);
      setHideTransformControls(true);

      if (!repeat) {
        stateHistory.beginAction();
        document.addEventListener('keyup', handleArrowKeyPressEnd, { once: true });
      }
    },
    [handleArrowKeyPressEnd, setHideTransformControls],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { ctrlKey, key, metaKey, repeat, shiftKey } = event;

      setWasDragged(false);

      if (key === 'ArrowUp') {
        event.preventDefault();
        handleArrowKeyPress(repeat);
        propagateValue(value + step, true);
      } else if (key === 'ArrowDown') {
        event.preventDefault();
        handleArrowKeyPress(repeat);
        propagateValue(value - step, true);
      } else if (key === 'Enter') {
        // Automatically trigger onBlur
        inputRef.current?.blur();
      } else if (key === 'z' && (metaKey || ctrlKey) && !shiftKey) {
        undoHistory(EmitterEvent.UI_UNDO);
      } else if (key === 'z' && (metaKey || ctrlKey) && shiftKey) {
        redoHistory(EmitterEvent.UI_REDO);
      } else if (key === 'y' && ctrlKey && !shiftKey) {
        redoHistory(EmitterEvent.UI_REDO);
      }
    },
    [handleArrowKeyPress, propagateValue, value, step, inputRef],
  );

  const onPointerMove = useCallback(
    (event: React.PointerEvent<HTMLSpanElement>) => {
      if (dragStartValue !== null) {
        // truncating the offsetX because of the high precision
        const offset = Math.trunc(event.nativeEvent.offsetX);
        const absOffset = Math.abs(offset);
        const newOffset = Math.ceil(absOffset * (offset < 0 ? -1 : 1));
        const newValue = dragStartValue + newOffset;

        propagateValue(newValue);
        setInput(formatValue(newValue));
      }
    },
    [dragStartValue, propagateValue, setInput, formatValue],
  );

  const onPointerDown = useCallback(
    (event: React.PointerEvent<HTMLSpanElement>) => {
      (event.target as HTMLSpanElement).setPointerCapture(event.pointerId);
      setDragStartValue(value);
      setPointerDown(true);
      stateHistory.beginAction();
    },
    [setPointerDown, setDragStartValue, value],
  );

  const onPointerUp = useCallback(
    (event: React.PointerEvent<HTMLSpanElement>) => {
      (event.target as HTMLSpanElement).releasePointerCapture(event.pointerId);
      setDragStartValue(null);
      setPointerDown(false);
      stateHistory.endAction();
    },
    [setPointerDown],
  );

  const onLostPointerCapture = useCallback(
    (_event: React.PointerEvent<HTMLSpanElement>) => {
      // Will be called if the tab is switched while dragging
      setPointerDown(false);
    },
    [setPointerDown],
  );

  const displayValue = useMemo(() => {
    if (focused) {
      return input;
    }

    return `${formatValue(value)}${append}`;
  }, [append, focused, formatValue, value, input]);

  return (
    <div className="relative flex ">
      <label
        className={clsx('group flex items-center', style.label)}
        data-testid="number-input-container"
        onClick={() => {
          inputRef.current?.select();
        }}
      >
        {label && (
          <span
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            // eslint-disable-next-line no-undefined
            onPointerMove={pointerDown ? onPointerMove : undefined}
            onLostPointerCapture={onLostPointerCapture}
            className={GlobalCursorType.RESIZE}
          >
            <span className={style.span} data-testid="number-input-label">
              {label}
            </span>
          </span>
        )}

        <input
          autoComplete="off"
          ref={inputRef}
          name={name}
          className={clsx('w-full', style.input)}
          value={showMessage ? message ?? displayValue : displayValue}
          onBlur={handleOnBlurInput}
          onChange={handleOnChangeInput}
          onFocus={() => setFocused(true)}
          data-testid="number-input"
          onKeyDown={onKeyDown}
        />
      </label>
      {showKeyframe && (
        <div className="absolute right-[-9px] top-[2px]">
          <KeyframeButton hasKeyframe={hasKeyframe} onClick={handleKeyframeClick} />
        </div>
      )}
    </div>
  );
};
