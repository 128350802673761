/**
 * Copyright 2024 Design Barn Inc.
 */

import { throttle } from 'lodash-es';
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';

const SELECTION_BOX_BORDER_COLOR = '#3AA0FF';
const SELECTION_BOX_BACKGROUND_COLOR = '#3AA0FF33';
const EDGE_MARGIN = -10;
const SCROLL_SPEED = 8;

interface SelectionBoxProps {
  style: React.CSSProperties;
  visible: boolean;
}

export const SelectionBoxContainer: React.FC<SelectionBoxProps> = ({ style, visible }) => {
  if (!visible) return null;

  return (
    <div
      style={{
        ...style,
        borderColor: SELECTION_BOX_BORDER_COLOR,
        backgroundColor: SELECTION_BOX_BACKGROUND_COLOR,
      }}
      className="pointer-events-none absolute z-timeline-marquee border-[1px]"
    />
  );
};

export interface UseSelectionBoxProps {
  /** The container ref that constrains the selection area. */
  containerRef: React.RefObject<HTMLElement>;
  enableScrollX?: boolean;
  enableScrollY?: boolean;
  ignoreClasses?: string[];
  /** Max x value for the selection box (relative to the container). */
  maxX?: number | null;
  /** Max y value for the selection box (relative to the container). */
  maxY?: number | null;
  /** Min x value for the selection box (relative to the container). */
  minX?: number | null;
  /** Min y value for the selection box (relative to the container). */
  minY?: number | null;
  onSelectionEnd?: () => void;
  onSelectionStart?: () => void;
  selectItems: (startPos: { x: number; y: number }, endPos: { x: number; y: number }) => void;
}

export interface UseSelectionBoxReturn {
  SelectionBox: () => React.ReactElement;
  handleMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
  isSelecting: boolean;
}

export const useSelectionBox = ({
  containerRef,
  selectItems,
  minX = null,
  maxX = null,
  minY = null,
  maxY = null,
  onSelectionStart,
  onSelectionEnd,
  enableScrollX = true,
  enableScrollY = true,
  ignoreClasses = [],
}: UseSelectionBoxProps): UseSelectionBoxReturn => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionBoxStyle, setSelectionBoxStyle] = useState<React.CSSProperties>({});
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [currentScroll, setCurrentScroll] = useState({ top: 0, left: 0 });

  const currentSelectionStart = useRef(onSelectionStart);
  const currentSelectionEnd = useRef(onSelectionEnd);
  const startPosition = useRef({ x: 0, y: 0 });
  const containerRect = useRef<DOMRect>();
  const initialScroll = useRef({ top: 0, left: 0 });
  const containerPadding = useRef({ top: 0, left: 0, bottom: 0, right: 0 });
  const bounds = useRef({
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  });

  const updateSelectionBox = useCallback(
    (start: { x: number; y: number }, end: { x: number; y: number }): void => {
      if (!containerRect.current || !containerRef.current) return;

      const left = start.x - containerRect.current.left;
      const top = start.y - containerRect.current.top;
      const width = end.x - start.x;
      const height = end.y - start.y;

      setSelectionBoxStyle({
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
      });
    },
    [containerRect, containerRef],
  );

  const selectItemsThrottled = useMemo(() => throttle(selectItems, 150), [selectItems]);

  const handleItemSelection = useCallback(
    (topLeft: { x: number; y: number }, bottomRight: { x: number; y: number }) => {
      selectItemsThrottled(
        {
          x: topLeft.x - currentScroll.left,
          y: topLeft.y - currentScroll.top,
        },
        {
          x: bottomRight.x - currentScroll.left,
          y: bottomRight.y - currentScroll.top,
        },
      );
    },
    [currentScroll.left, currentScroll.top, selectItemsThrottled],
  );

  const hasIgnoredClass = useCallback(
    (element: HTMLElement): boolean => {
      return ignoreClasses.some((className) => element.classList.contains(className));
    },
    [ignoreClasses],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (event.button !== 0 || hasIgnoredClass(event.target as HTMLElement) || isSelecting || !containerRef.current) {
        return;
      }

      // Note: Resetting the style on mouse up seems to persist the previous style,
      // so resetting it here to ensure the previous style is not applied.
      setSelectionBoxStyle({});

      const rect = containerRef.current.getBoundingClientRect() as DOMRect;
      const { scrollLeft, scrollTop } = containerRef.current;

      containerRect.current = rect;

      const currentBounds = {
        minX: minX ? minX + rect.left : rect.left,
        maxX: maxX ? maxX + rect.left : rect.right,
        minY: minY ? minY + rect.top : rect.top,
        maxY: maxY ? maxY + rect.top : rect.bottom,
      };

      if (
        event.clientX < currentBounds.minX - scrollLeft ||
        event.clientX > currentBounds.maxX + scrollLeft ||
        event.clientY < currentBounds.minY - scrollTop ||
        event.clientY > currentBounds.maxY + scrollTop
      )
        return;

      setIsSelecting(true);
      startPosition.current = { x: event.clientX, y: event.clientY };
      setMousePosition({ x: event.clientX, y: event.clientY });
      initialScroll.current = { top: scrollTop, left: scrollLeft };
      setCurrentScroll({ top: scrollTop, left: scrollLeft });
      bounds.current = currentBounds;

      const computedStyle = window.getComputedStyle(containerRef.current);

      containerPadding.current = {
        top: parseInt(computedStyle.paddingTop, 10),
        left: parseInt(computedStyle.paddingLeft, 10),
        bottom: parseInt(computedStyle.paddingBottom, 10),
        right: parseInt(computedStyle.paddingRight, 10),
      };
    },
    [hasIgnoredClass, isSelecting, containerRef, minX, maxX, minY, maxY, startPosition],
  );

  const getSelectionCorners = useCallback(() => {
    // constrain the endPosition within the bounds
    const x = Math.max(bounds.current.minX, Math.min(mousePosition.x, bounds.current.maxX));
    const y = Math.max(bounds.current.minY, Math.min(mousePosition.y, bounds.current.maxY));

    const startPos = {
      x: startPosition.current.x + initialScroll.current.left,
      y: startPosition.current.y + initialScroll.current.top,
    };
    const endPos = { x: x + currentScroll.left, y: y + currentScroll.top };

    // set the start and end positions to the top left and bottom right
    // to account for the different directions the box will be drawn in
    const topLeft = {
      x: Math.min(startPos.x, endPos.x),
      y: Math.min(startPos.y, endPos.y),
    };
    const bottomRight = {
      x: Math.max(startPos.x, endPos.x),
      y: Math.max(startPos.y, endPos.y),
    };

    return { topLeft, bottomRight };
  }, [mousePosition.x, mousePosition.y, currentScroll.left, currentScroll.top]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (event.button !== 0 || !isSelecting || !containerRef.current) return;

      setMousePosition({ x: event.clientX, y: event.clientY });

      const { bottomRight, topLeft } = getSelectionCorners();

      setCurrentScroll({ top: containerRef.current.scrollTop, left: containerRef.current.scrollLeft });
      updateSelectionBox(topLeft, bottomRight);
      handleItemSelection(topLeft, bottomRight);

      if (currentSelectionStart.current) {
        currentSelectionStart.current();
      }
    },
    [isSelecting, containerRef, getSelectionCorners, updateSelectionBox, handleItemSelection],
  );

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      if (event.button !== 0 || !isSelecting) return;

      if (currentSelectionEnd.current) {
        currentSelectionEnd.current();
      }

      setIsSelecting(false);
    },
    [isSelecting, currentSelectionEnd],
  );

  const handleScroll = useCallback(
    (mousePosX: number, mousePosY: number) => {
      if (!containerRef.current) return;

      const { bottom, left, right, top } = containerRef.current.getBoundingClientRect();

      let scrollX = 0;
      let scrollY = 0;

      if (enableScrollY) {
        if (mousePosY < top + containerPadding.current.top + EDGE_MARGIN) {
          scrollY = -SCROLL_SPEED;
        } else if (mousePosY > bottom - containerPadding.current.bottom + EDGE_MARGIN) {
          scrollY = SCROLL_SPEED;
        }
      }

      if (enableScrollX) {
        if (mousePosX < left + containerPadding.current.left + EDGE_MARGIN) {
          scrollX = -SCROLL_SPEED;
        } else if (mousePosX > right - containerPadding.current.right + EDGE_MARGIN) {
          scrollX = SCROLL_SPEED;
        }
      }

      if (scrollX === 0 && scrollY === 0) return;

      containerRef.current.scrollBy(scrollX, scrollY);
      setCurrentScroll({ top: containerRef.current.scrollTop, left: containerRef.current.scrollLeft });

      const { bottomRight, topLeft } = getSelectionCorners();

      updateSelectionBox(topLeft, bottomRight);
      handleItemSelection(topLeft, bottomRight);
    },
    [containerRef, enableScrollX, enableScrollY, getSelectionCorners, handleItemSelection, updateSelectionBox],
  );

  useEffect(() => {
    if (isSelecting) {
      handleScroll(mousePosition.x, mousePosition.y);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, currentScroll.left, currentScroll.top]);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const SelectionBox = (): React.ReactElement => (
    <SelectionBoxContainer visible={isSelecting} style={selectionBoxStyle} />
  );

  return {
    handleMouseDown,
    isSelecting,
    SelectionBox,
  };
};
