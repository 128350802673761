/**
 * Copyright 2022 Design Barn Inc.
 */

import { AlignPivotDirection } from '../uiSlice';

export enum ShapeTypes {
  Ellipse = 'Ellipse',
  Fill = 'Fill',
  Line = 'Line',
  None = 'None',
  Path = 'Path',
  Polygon = 'Polygon',
  Rectangle = 'Rectangle',
  Star = 'Star',
  Stroke = 'Stroke',
  Transform = 'Transform',
  TrimPath = 'TrimPath',
}

export enum PropertyPanelType {
  Composition = 'Composition',
  EditPath = 'Edit Path',
  Ellipse = 'Ellipse',
  EllipsePath = 'EllipsePath',
  Fill = 'Fill',
  GradientFill = 'GradientFill',
  Group = 'Group',
  Line = 'Line',
  Mask = 'Mask',
  MultiObjects = 'Multiple Objects',
  Path = 'Path',
  Polygon = 'Polygon',
  PolygonPath = 'PolygonPath',
  Precomposition = 'Precomposition',
  Rectangle = 'Rectangle',
  RectanglePath = 'RectanglePath',
  ShapeLayer = 'ShapeLayer',
  Star = 'Star',
  StarPath = 'StarPath',
  Stroke = 'Stroke',
  Trim = 'Trim',
}

export const ShapePathMap = {
  [PropertyPanelType.RectanglePath]: PropertyPanelType.Rectangle,
  [PropertyPanelType.EllipsePath]: PropertyPanelType.Ellipse,
  [PropertyPanelType.PolygonPath]: PropertyPanelType.Polygon,
  [PropertyPanelType.StarPath]: PropertyPanelType.Star,
  [PropertyPanelType.Path]: PropertyPanelType.Path,
  [PropertyPanelType.EditPath]: PropertyPanelType.EditPath,
};

export enum LayerType {
  Ellipse = 'el',
  Fill = 'fl',
  Group = 'gr',
  Path = 'sh',
  Rectangle = 'rc',
  Star = 'sr',
  Stroke = 'st',
}

// LayerType and ShapeType arent available for export when using
// --isolatedModules soo this had to be untyped
export const RENAMABLE_LAYER_TYPES: string[] = [
  // precomps
  'PRECOMPOSITION',
  // shape layer
  'SHAPE',
  // ellipse
  LayerType.Ellipse,
  // group
  LayerType.Group,
  // path
  LayerType.Path,
  // rectangle
  LayerType.Rectangle,
  // star
  LayerType.Star,
];

const { Bottom, BottomLeft, BottomRight, Center, Left, Right, Top, TopLeft, TopRight } = AlignPivotDirection;

export const DefaultPivotAlignment = {
  [Bottom]: false,
  [BottomLeft]: false,
  [BottomRight]: false,
  [Center]: true,
  [Left]: false,
  [Right]: false,
  [Top]: false,
  [TopLeft]: false,
  [TopRight]: false,
};
