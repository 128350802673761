/**
 * Copyright 2023 Design Barn Inc.
 */

import { canIRelottie, isFeatureSupported } from '@lottiefiles/can-i-relottie';
import type { PlayerName, PlayerVersion } from '@lottiefiles/can-i-relottie';
import LottieHash, { FEATURE_FLAGS } from '@lottiefiles/lottie-hash';

import { useCreatorStore } from '~/store';

// Reference Player: https://github.com/LottieFiles/can-i-relottie/blob/main/data/players.json
export const CURRENT_PLAYER = 'lottie-creator';
export const CURRENT_PLAYER_VERSION = '1.14.0';

export const checkCompatibleFeatures = (
  json?: unknown,
  currentPlayer: PlayerName = CURRENT_PLAYER,
  currentVersion: PlayerVersion = CURRENT_PLAYER_VERSION,
): string[] => {
  // Throwing an error here wouldnt help, given that creator has no way to catch it.
  // Will add back in when we have a non-obstructive way of error handling
  // if (typeof json !== 'string') throw new Error('JSON must be a string');

  // TODO: Get this from the package.json, when creator has more updates to make to can-i-relottie.
  // Also.. This might be deprecated eventually in favour of lottiehash. To be determined.
  const creatorHash = useCreatorStore.getState().ui.creatorHash;

  if (!creatorHash) return [];
  const lh = LottieHash.fromJSON(JSON.stringify(json));
  const targetHashBigInt = BigInt(creatorHash);
  const differenceHash = LottieHash.diff(targetHashBigInt, lh.toBigint());

  const unsupportedFeatures: string[] = [];

  FEATURE_FLAGS.forEach((flag, feature) => {
    const hasFlag = LottieHash.hasFlag(differenceHash, BigInt(flag));

    if (!hasFlag) return;

    const isSupported = isFeatureSupported(feature, canIRelottie, currentPlayer, currentVersion);

    if (isSupported) return;

    const featureInfo = canIRelottie.features[feature];

    // Only display if feature is in CanILottie
    if (featureInfo?.canilottie) {
      let featureName = feature.split('-').join(' ');

      featureName = `${featureName.charAt(0).toUpperCase()}${featureName.slice(1)}`;

      // Note: Using the featureInfo from canIRelottie is not enough, the naming from canILottie is simply not clear enough
      // (e.g layer-transform, transform-repeater, shape-transform will all be displayed as "Transform")
      // This was enough when we were only relying on can-i-lottie data
      // But now that we're relying on can-i-relottie data (which is more accurate and has more data),
      // There is more information we can display to the end user, but the documentation needs to be updated to reflect this
      // For now, we'll just display the feature name as is
      unsupportedFeatures.push(featureName);
    }
  });

  // Note from Aidos: better to return Set<string> or FeaturesInfo[] or Record<AnyTitle, FeatureInfo>
  return unsupportedFeatures.sort();
};
