/**
 * Copyright 2024 Design Barn Inc.
 */

import type { MouseEventHandler } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { ColorOptional } from '@/ColorPicker/components/ColorPicker';
import { MouseAction } from '@/ColorPicker/components/ColorPicker';
import { getAlpha } from '@/ColorPicker/helpers';
import { useMouseEvents } from '@/ColorPicker/hooks';

interface Props {
  alpha: number;
  blue: number;
  green: number;
  red: number;
  updateRgb: (color: ColorOptional, name: MouseAction) => void;
}

export const Alpha: React.FC<Props> = ({ alpha, blue, green, red, updateRgb }) => {
  const alphaMaskRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (alphaMaskRef.current) {
      setWidth((alphaMaskRef.current as HTMLDivElement).clientWidth);
    }
  }, [setWidth]);

  const mouseDownHandler = useCallback(
    (event: MouseEvent) => {
      const elementX = (event.currentTarget as HTMLDivElement).getBoundingClientRect().x;
      const startX = event.pageX;
      const positionX = startX - elementX - 6;

      updateRgb({ alpha: getAlpha(positionX, width) }, MouseAction.onStartChange);

      return {
        startX,
        positionX,
      };
    },
    [width, updateRgb],
  );

  const changeObjectPositions = useCallback(
    (event: MouseEvent, { positionX, startX }: { alpha?: number; positionX: number; startX: number }) => {
      const moveX = event.pageX - startX;

      const _positionX = positionX + moveX;

      const _alpha = getAlpha(positionX, width);

      return {
        positions: {
          positionX: _positionX,
          startX: event.pageX,
        },
        alpha: _alpha,
      };
    },
    [width],
  );

  const mouseMoveHandler = useCallback(
    (event: MouseEvent, { positionX, startX }: { positionX: number; startX: number }) => {
      const { alpha: _alpha, positions } = changeObjectPositions(event, { startX, positionX });

      updateRgb({ alpha: _alpha }, MouseAction.onChange);

      return positions;
    },
    [changeObjectPositions, updateRgb],
  );

  const mouseUpHandler = useCallback(
    (event: MouseEvent, { positionX, startX }: { positionX: number; startX: number }) => {
      const { alpha: _alpha, positions } = changeObjectPositions(event, { startX, positionX });

      updateRgb({ alpha: _alpha }, MouseAction.onEndChange);

      return positions;
    },
    [changeObjectPositions, updateRgb],
  );

  const mouseEvents = useMouseEvents(mouseDownHandler, mouseMoveHandler, mouseUpHandler);

  const onMouseDown = ((event: MouseEvent): void => {
    mouseEvents(event);
  }) as unknown as MouseEventHandler<HTMLDivElement>;

  const style = {
    background: `linear-gradient(to right, rgba(0, 0, 0, 0), rgb(${red}, ${green}, ${blue}))`,
  };

  const offsetLeft = ((alpha * width) | 0) - 6;

  const pointerStyle = {
    left: `${offsetLeft}px`,
  };

  return (
    <div onMouseDown={onMouseDown} className="alpha">
      <div className="gradient" style={style} />
      <div className="alpha-area">
        <div className="alpha-mask" ref={alphaMaskRef}>
          <div className="picker-pointer" style={pointerStyle} />
        </div>
      </div>
    </div>
  );
};
