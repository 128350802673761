/**
 * Copyright 2024 Design Barn Inc.
 */

import type { DagNode, ShapeLayer, Shape } from '@lottiefiles/toolkit-js';

import { useCreatorStore } from '~/store';
import { LayerType } from '~/store/constant';

interface ShapeIndexes {
  id?: string;
  index: number;
  node: DagNode | null;
  type?: string;
}

export const reorderShapes = (parentNode: DagNode): void => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  const sceneLayersShapes = parentNode.state?.shapes;

  if (sceneLayersShapes.length) {
    // For toolkit & lottie player to render correctly,
    // Fill & Stroke must move to the last Shape indexes
    // Correct order: 'rc', 'sr', 'el', 'fl', 'st'
    // Wrong order: 'rc', 'fl' 'sr', 'st, 'el'
    const moveToLastIndexIds: string[] = [];
    const moveToFirstIndexIds: string[] = [];

    const updatedShapeIndexes: ShapeIndexes[] = [];

    sceneLayersShapes.forEach((item: ShapeIndexes) => {
      if ([LayerType.Fill, LayerType.Stroke].includes(item.type as string)) {
        moveToLastIndexIds.push(item.id as string);
      } else {
        moveToFirstIndexIds.push(item.id as string);
      }
    });

    if (moveToLastIndexIds.length > 0) {
      const startIndex = sceneLayersShapes.length - moveToLastIndexIds.length - 1;

      moveToFirstIndexIds.forEach((id, index) => {
        const itemNode = getNodeByIdOnly(id);

        updatedShapeIndexes.push({ index, node: itemNode });
      });

      moveToLastIndexIds.forEach((id, index) => {
        const itemNode = getNodeByIdOnly(id);

        updatedShapeIndexes.push({ index: startIndex + index + 1, node: itemNode });
      });

      updatedShapeIndexes.forEach((item) => {
        (parentNode as ShapeLayer).setShapeIndex(item.node as Shape, item.index);
      });
    }
  }
};
