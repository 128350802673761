/**
 * Copyright 2023 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import React from 'react';

import { NestedSceneLayerMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapperLayers } from './ContextMenuWrapperLayers';

import { GB_CONSTANT } from '~/config/growthbook';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  eventArg?: unknown;
  isOpen: boolean;
  onClose: () => void;
}

export const NestedSceneLayerMenu: React.FC<ShapeLayerMenuProps> = ({ coord, eventArg, isOpen, onClose }) => {
  const showBreakSceneMenuItem = useFeatureIsOn(GB_CONSTANT.SHOW_CREATE_BREAK_SCENE);

  const keyFrameMenu = NestedSceneLayerMenuList.find((menu) => menu.type === 'ShowAllKeyframes');

  // Temporarily disable precomp keyframe
  if (keyFrameMenu) keyFrameMenu.disabled = true;

  if (!showBreakSceneMenuItem) {
    const breakMenu = NestedSceneLayerMenuList.find((item) => item.type === 'BreakScene');

    if (breakMenu) {
      breakMenu.isHidden = true;
    }
  }

  return (
    <ContextMenuWrapperLayers
      coord={coord}
      isOpen={isOpen}
      onClose={onClose}
      eventArg={eventArg}
      dropdownItems={NestedSceneLayerMenuList}
    ></ContextMenuWrapperLayers>
  );
};
