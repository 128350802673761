/**
 * Copyright 2024 Design Barn Inc.
 */

export const PROPERTY_PANEL_KEYFRAME_WIDTH = 12;

export enum AnchorPointGridColors {
  ACTIVE = 'stroke-[#0FCCCE]',
  INACTIVE = 'stroke-[#606F7B]',
}
