/**
 * Copyright 2024 Design Barn Inc.
 */

import type { SceneJSON } from '@lottiefiles/toolkit-js';
import { round } from 'lodash-es';

import { toolkit } from '../toolkit';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

let requestId: number;

interface CurrentScene {
  fps: number;
  time: number;
}
interface PlaybackParameters {
  currentFrame: number;
  currentScene: CurrentScene;
  duration: number;
  endFrame: number;
  fps: number;
  looping: boolean;
  op: number;
  startFrame: number;
}

export const getPlaybackParameters = (): PlaybackParameters => {
  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

  let fps = useCreatorStore.getState().toolkit.json?.timeline.properties.fr as number;
  let op = useCreatorStore.getState().toolkit.json?.timeline.properties.op as number;
  let duration = useCreatorStore.getState().toolkit.json?.timeline.duration as number;
  let currentFrame = useCreatorStore.getState().toolkit.currentFrame;
  const looping = useCreatorStore.getState().timeline.looping;

  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const currentFrameRate = toolkit.scenes[sceneIndex]?.timeline.frameRate ?? 30;
  const currentTime = toolkit.scenes[sceneIndex]?.timeline.currentTime ?? 0;
  const currentScene = {
    fps: currentFrameRate,
    time: currentTime,
  };

  if (selectedPrecompositionId) {
    const assetNode = toolkit.getNodeById(selectedPrecompositionId);
    const json = assetNode?.state as SceneJSON;

    currentFrame = (json.timeline.properties.cf as number) || 0;

    fps = (json.timeline.properties.fr as number) || 30;
    op = (json.timeline.properties.op as number) || op;
    duration = (json.timeline.duration as number) || duration;
  }

  const workAreaFrameStart = useCreatorStore.getState().timeline.workAreaFrameStart || 0;
  const workAreaFrameEnd = useCreatorStore.getState().timeline.workAreaFrameEnd || 0;

  let startFrame = 0;
  let endFrame = op;

  if (currentFrame >= workAreaFrameStart && currentFrame <= workAreaFrameEnd) {
    if (workAreaFrameEnd < op) {
      endFrame = workAreaFrameEnd;
    }
    if (workAreaFrameStart > 0) {
      startFrame = workAreaFrameStart;
    }
  }

  return { currentFrame, fps, endFrame, startFrame, op, duration, looping, currentScene };
};

export const pause = (): void => {
  const setPlaying = useCreatorStore.getState().timeline.setPlaying;
  const setDisableInteractions = useCreatorStore.getState().canvas.setDisableInteractions;

  setDisableInteractions(false);
  setPlaying(false);
  cancelAnimationFrame(requestId);
};

const animateWithDurationAndFPS = (
  currentFrame: number,
  durationInSeconds: number,
  startFrame: number,
  endFrame: number,
  fps: number,
  looping: boolean = false,
): void => {
  const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;

  // Calculate the duration of each frame in milliseconds
  const frameDuration = 1000 / fps;
  // Convert total duration to milliseconds
  const totalDuration = durationInSeconds * 1000;

  let startTimestamp: number | null = null;
  let lastFrameTimestamp: number | null = null;
  let frameCount = currentFrame;

  const animate = (timestamp: number): void => {
    const currentPlaybackParams = getPlaybackParameters();

    // Reset the animation if the playback parameters have changed
    if (
      currentPlaybackParams.looping !== looping ||
      currentPlaybackParams.fps !== fps ||
      currentPlaybackParams.duration !== durationInSeconds
    ) {
      cancelAnimationFrame(requestId);
      animateWithDurationAndFPS(
        currentPlaybackParams.currentFrame,
        currentPlaybackParams.duration,
        startFrame,
        endFrame,
        currentPlaybackParams.fps,
        currentPlaybackParams.looping,
      );

      return;
    }

    if (!startTimestamp) startTimestamp = timestamp;
    if (!lastFrameTimestamp) lastFrameTimestamp = timestamp;

    const elapsedSinceLastFrame = timestamp - lastFrameTimestamp;
    const framesToAdvance = Math.floor(elapsedSinceLastFrame / frameDuration);

    if (framesToAdvance > 0) {
      if (looping) {
        frameCount = (frameCount + framesToAdvance) % endFrame;
        if (frameCount < startFrame) {
          frameCount += startFrame;
        }
      } else {
        frameCount = Math.min(frameCount + framesToAdvance, endFrame - 1);
      }

      emitter.emit(EmitterEvent.CANVAS_DISABLE_PENCONTROL, { skipUpdate: true });
      setCurrentFrame(Math.round(frameCount));
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, { skipUpdate: true });

      lastFrameTimestamp = timestamp - (elapsedSinceLastFrame % frameDuration);
    }

    const elapsed = timestamp - startTimestamp;

    if (!looping && frameCount === endFrame - 1) {
      setCurrentFrame(endFrame - 1);
      pause();
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, { skipUpdate: true });
    } else if (elapsed < totalDuration) {
      requestId = requestAnimationFrame(animate);
    } else if (looping) {
      startTimestamp = timestamp;
      animate(timestamp);
    }
  };

  requestId = requestAnimationFrame(animate);
};

export const play = (): void => {
  const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;
  const setPlaying = useCreatorStore.getState().timeline.setPlaying;
  const setDisableInteractions = useCreatorStore.getState().canvas.setDisableInteractions;

  const { currentFrame, duration, endFrame, fps, looping, startFrame } = getPlaybackParameters();

  let _currentFrame = currentFrame;

  if (round(currentFrame, 3) === round(endFrame - 1, 3)) {
    _currentFrame = startFrame;
    setCurrentFrame(_currentFrame);
    emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, { skipUpdate: true });
  }

  setDisableInteractions(true);
  setPlaying(true);
  animateWithDurationAndFPS(_currentFrame, duration, startFrame, endFrame, fps, looping);
};
