/**
 * Copyright 2024 Design Barn Inc.
 */

import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { useCallback, Fragment } from 'react';
import { shallow } from 'zustand/shallow';

import { ChevronDown, ConvertVertex, Pen, PenActive } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { GB_CONSTANT } from '~/config/growthbook';
import { ToolType } from '~/data/constant';
import { useCreatorStore } from '~/store';

const toolLabels = {
  [ToolType.Pen]: 'Pen',
  [ToolType.ConvertVertex]: 'Convert Vertex',
};

const DropDownList = [
  {
    type: ToolType.Pen,
    activeIcon: <Pen />,
    inactiveIcon: <Pen />,
    label: 'Pen Tool',
    shortcut: 'G',
  },
  {
    type: ToolType.ConvertVertex,
    activeIcon: <ConvertVertex />,
    inactiveIcon: <ConvertVertex />,
    label: 'Convert Vertex',
    isDisabled: true,
  },
];

const toolIcon = {
  [ToolType.Pen]: {
    activeIcon: <PenActive />,
    inactiveIcon: <Pen />,
  },
  [ToolType.ConvertVertex]: {
    activeIcon: <ConvertVertex />,
    inactiveIcon: <ConvertVertex />,
  },
};

export const PenMenu: React.FC = () => {
  const [currentTool, setCurrentTool, lastSelectedPenTool, setLastSelectedPenTool] = useCreatorStore(
    (state) => [
      state.ui.currentTool,
      state.ui.setCurrentTool,
      state.ui.lastSelectedPenTool,
      state.ui.setLastSelectedPenTool,
    ],
    shallow,
  );

  const handleOnClick: (type: ToolType) => void = useCallback(
    (type) => {
      setCurrentTool(type);
      setLastSelectedPenTool(type as Parameters<typeof setLastSelectedPenTool>[0]);
    },
    [setCurrentTool, setLastSelectedPenTool],
  );

  return (
    <>
      <IfFeatureEnabled feature={GB_CONSTANT.SHOW_PEN_TOOL}>
        <Menu as="div" className="relative mt-1 inline-block">
          {({ open }) => (
            <>
              <div className="group flex h-[24px] cursor-pointer items-center justify-center rounded pl-0.5 hover:bg-gray-700 ">
                <Tooltip offsetOptions={{ mainAxis: 14 }} content={toolLabels[lastSelectedPenTool]}>
                  <div className="flex" onClick={() => handleOnClick(lastSelectedPenTool)}>
                    {React.cloneElement(
                      toolIcon[lastSelectedPenTool][
                        lastSelectedPenTool === currentTool ? 'activeIcon' : 'inactiveIcon'
                      ],
                      {
                        className: clsx(
                          'h-[16px] w-[16px] text-gray-400 group-hover:bg-gray-700',
                          open || lastSelectedPenTool === currentTool
                            ? 'fill-[#00C1A2] text-[#00C1A2] group-hover:text-[#00C1A2]'
                            : 'fill-current text-gray-400 group-hover:text-white',
                        ),
                      },
                    )}
                    <Tooltip offsetOptions={{ mainAxis: 14 }} content="Pen Tools" isNested>
                      <Menu.Button>
                        <ChevronDown className="h-3 w-4 cursor-pointer text-gray-500 transition-transform hover:translate-y-px group-hover:text-white" />
                      </Menu.Button>
                    </Tooltip>
                  </div>
                </Tooltip>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-dropdown mt-1 w-[192px] cursor-pointer rounded-lg border border-gray-600 bg-gray-700 py-2 shadow-dropdown focus:outline-none">
                  {DropDownList.map((dropdown, index) => (
                    <div key={dropdown.type}>
                      <Menu.Item>
                        <button
                          disabled={dropdown.isDisabled as boolean}
                          className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                            index === DropDownList.length - 1 && 'rounded-b'
                          } ${dropdown.isDisabled ? 'text-gray-500' : 'text-white'} px-2 text-xs`}
                          onClick={() => handleOnClick(dropdown.type)}
                        >
                          <div
                            className={`flex w-full justify-between rounded-lg p-2 ${
                              dropdown.isDisabled ? 'text-gray-500' : 'text-white hover:bg-gray-600'
                            }`}
                          >
                            <div className="flex">
                              {React.cloneElement(
                                currentTool === dropdown.type ? dropdown.activeIcon : dropdown.inactiveIcon,
                                {
                                  className: 'h-[16px] w-[16px] fill-current text-gray-400 mr-2',
                                },
                              )}
                              {dropdown.label}
                            </div>
                            {dropdown.shortcut && <div className="mr-2 text-gray-400">{dropdown.shortcut}</div>}
                          </div>
                        </button>
                      </Menu.Item>
                    </div>
                  ))}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </IfFeatureEnabled>
    </>
  );
};
