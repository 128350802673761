/**
 * Copyright 2024 Design Barn Inc.
 */

import { EventType, pluginIdToTypeMap } from '~/data/eventStore';
import { useCreatorStore } from '~/store';
import { fireEvent } from '~/utils';

export const openFloatingPlugin = (pluginId: string): void => {
  const setFloatingPluginId = useCreatorStore.getState().ui.setFloatingPluginId;

  setFloatingPluginId(pluginId);

  const pluginType = pluginIdToTypeMap[`plugin-${pluginId}`];

  if (pluginType) {
    fireEvent({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.OpenedPlugin,
      parameters: {
        type: pluginType,
      },
    });
  }
};
