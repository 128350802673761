/**
 * Copyright 2024 Design Barn Inc.
 */

import { Dialog, Transition, Tab } from '@headlessui/react';
import { ShapeType } from '@lottiefiles/lottie-js';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import type { FC } from 'react';
import React, { useEffect, useRef, useState, useCallback, Fragment } from 'react';

import { Close, Minus, Plus } from '~/assets/icons';
import { ColorInput } from '~/components/Elements/ColorInput/ColorInput';
import { hexToRgb, rgbToHex } from '~/components/Elements/ColorPicker/helpers';
import type { NumberInputProps } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input';
import { Modal } from '~/components/Elements/Modal';
import { MAX_HEIGHT } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';
import type { Guides } from '~/store/canvasSlice';
import { GuideType } from '~/store/canvasSlice';

const setGuide = useCreatorStore.getState().canvas.setGuide;
const setGuideColor = useCreatorStore.getState().canvas.setGuideColor;
const removeGuide = useCreatorStore.getState().canvas.removeGuide;

const GuideRow: FC<{ id: string; index: number; selectedId: string; type: GuideType; value: number }> = ({
  id,
  index,
  selectedId,
  type,
  value,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedId === id) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      ref.current?.querySelector('input')?.focus();
    }
  }, [selectedId, id]);

  const onRemove = useCallback(() => {
    removeGuide({ id, type });
    emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);
  }, [id, type]);

  const onChange: NumberInputProps['onChange'] = (result) => {
    setGuide({ id, type, value: result.value });
    emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);
  };

  return (
    <div className="flex items-center gap-4 text-xs" ref={ref}>
      <p className="w-16">Guide {index + 1}</p>
      <NumberInput name="guide" value={value} onChange={onChange} min={-1 * MAX_HEIGHT} max={MAX_HEIGHT} />
      <button onClick={onRemove}>
        <Minus className="h-6 w-6 stroke-[#A1ADB7] p-1" />
      </button>
    </div>
  );
};

const NoGuides: FC = () => <p className="text-xs text-[#A1ADB7]">No guides added</p>;

const options = [
  {
    value: GuideType.Horizontal,
    label: 'Horizontal',
  },
  {
    value: GuideType.Vertical,
    label: 'Vertical',
  },
];

const onModalClose = (): void => {
  const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;

  setGlobalModal(null);
};

export const EditGuidesModal: FC<{ selectedGuide: NonNullable<Guides['selectedGuide']> }> = ({ selectedGuide }) => {
  const guides = useCreatorStore((state) => state.canvas.guides);

  const horizontalGuides = Object.entries(guides.horizontal);
  const verticalGuides = Object.entries(guides.vertical);

  const [selectedIndex, setSelectedIndex] = useState(
    options.findIndex((option) => option.value === selectedGuide.type) || 0,
  );

  const onTabChange = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  const onAddNewGuide = useCallback(() => {
    const option = options[selectedIndex];

    if (option) {
      const existingGuides = new Set(Object.values(guides[option.value]));

      let newGuide = 100;

      while (existingGuides.has(newGuide)) {
        newGuide += 50;
      }

      setGuide({ id: nanoid(), type: option.value, value: newGuide });
      emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);
    }
  }, [guides, selectedIndex]);

  const handleColorChange: Parameters<typeof ColorInput>['0']['onChangeColor'] = useCallback((newColor) => {
    const { blue: b, green: g, red: r } = hexToRgb(newColor);

    setGuideColor({ r, g, b });
    emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);
  }, []);

  return (
    <Modal isOpen={true} onClose={onModalClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <Dialog.Panel className="h-[380px] w-[248px] rounded-xl bg-[#20272C] text-white shadow-sm">
              <div className="flex h-full flex-col">
                <Dialog.Title className="relative flex items-center justify-between border-b border-solid border-[#283137] px-4 py-3 text-left text-sm font-bold ">
                  <span>Edit guides</span>
                  <Close
                    onClick={onModalClose}
                    className="h-6 w-6 cursor-pointer fill-current p-1 text-[#A1ADB7] hover:opacity-50"
                  />
                </Dialog.Title>
                <div className="flex items-center gap-4 p-4  pb-0 text-xs">
                  <p className="min-w-[50px]">Color</p>
                  <ColorInput
                    shapeType={ShapeType.FILL}
                    styleClass="!w-[86px]"
                    color={rgbToHex(guides.color.r, guides.color.g, guides.color.b)}
                    opacity={100}
                    onChangeColor={handleColorChange}
                    onChangeOpacity={() => {
                      //
                    }}
                    colorStyleClass={`!h-full`}
                    inputStyleClass="!w-full"
                    enableColorModeChange={false}
                    isChannelAnimated={false}
                    showDocumentColors={false}
                    showOpacityControl={false}
                    showEyeDropper={false}
                    noFloatingPortal
                  />
                </div>
                <div className="flex h-full min-h-0 flex-1 flex-col p-4 pb-0">
                  <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
                    <Tab.List className="flex gap-4 border-b border-solid border-[#283137] text-xs">
                      {options.map(({ label }, index) => (
                        <Tab
                          key={index}
                          className={clsx(
                            'relative pb-2.5 outline-none',
                            index === selectedIndex
                              ? 'text-white before:absolute before:-bottom-px before:w-full before:border-b-2 before:border-solid before:border-teal-200'
                              : 'text-[#808E9A]',
                          )}
                        >
                          {label}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels className="flex min-h-0 flex-1 flex-col">
                      <Tab.Panel className="flex min-h-0 flex-1 flex-col gap-2 overflow-y-auto py-4">
                        {horizontalGuides.length ? (
                          <>
                            {horizontalGuides.map(([key, val], index) => (
                              <GuideRow
                                key={key}
                                type={GuideType.Horizontal}
                                id={key}
                                value={val}
                                index={index}
                                selectedId={selectedGuide.id}
                              />
                            ))}
                          </>
                        ) : (
                          <NoGuides />
                        )}
                      </Tab.Panel>
                      <Tab.Panel className="flex min-h-0 flex-1 flex-col gap-2 overflow-y-auto py-4">
                        {verticalGuides.length ? (
                          <>
                            {verticalGuides.map(([key, val], index) => (
                              <GuideRow
                                key={key}
                                type={GuideType.Vertical}
                                id={key}
                                value={val}
                                index={index}
                                selectedId={selectedGuide.id}
                              />
                            ))}
                          </>
                        ) : (
                          <NoGuides />
                        )}
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
                <div className="flex justify-between border-t border-solid border-t-[#283137] px-4 py-3">
                  <button className="flex items-center px-2 py-0.5 text-xs" onClick={onAddNewGuide}>
                    <Plus className="h-4 w-4 stroke-gray-300" />
                    <span className="pl-2">Add new guide</span>
                  </button>
                  <button className="rounded bg-teal-400 px-2 py-0.5 text-xs text-white" onClick={onModalClose}>
                    Done
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Modal>
  );
};
