/**
 * Copyright 2023 Design Barn Inc.
 */

import React, { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { SingleKeyFrameMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapper } from './ContextMenuWrapper';

import { EasingType } from '~/data/eventStore';
import { useCreatorStore } from '~/store';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  onClose: () => void;
  overflow?: boolean;
}

export const SingleKeyFrameMenu: React.FC<ShapeLayerMenuProps> = ({ coord, onClose, overflow = false }) => {
  const toggleLinearItems = (disable: boolean): void => {
    SingleKeyFrameMenuList[0].children[0].disabled = disable;
    SingleKeyFrameMenuList[0].children[1].disabled = disable;
    SingleKeyFrameMenuList[0].children[2].disabled = disable;
  };

  const toggleSmoothItems = (disable: boolean): void => {
    SingleKeyFrameMenuList[1].children[0].disabled = disable;
    SingleKeyFrameMenuList[1].children[1].disabled = disable;
    SingleKeyFrameMenuList[1].children[2].disabled = disable;
  };

  const [inTangent, outTangent, selectedKeyFramesEasing, selectedKeyframeIndex] = useCreatorStore(
    (state) => [
      state.timeline.selectedKeyFramesEasing[0]?.inTangent,
      state.timeline.selectedKeyFramesEasing[0]?.outTangent,
      state.timeline.selectedKeyFramesEasing,
      state.timeline.selectedKeyframeIndex as number,
    ],
    shallow,
  );

  const [changeKey, setChangeKey] = useState('');

  SingleKeyFrameMenuList[0].isHidden = selectedKeyFramesEasing.length === 0;
  SingleKeyFrameMenuList[1].isHidden = selectedKeyFramesEasing.length === 0;

  if (selectedKeyFramesEasing.length > 0) {
    SingleKeyFrameMenuList[0].children[0].isHidden = selectedKeyframeIndex === 0;
    SingleKeyFrameMenuList[0].children[2].isHidden = selectedKeyframeIndex === 0;

    SingleKeyFrameMenuList[1].children[0].isHidden = selectedKeyframeIndex === 0;
    SingleKeyFrameMenuList[1].children[2].isHidden = selectedKeyframeIndex === 0;
  }

  useEffect(() => {
    if (SingleKeyFrameMenuList[0] && SingleKeyFrameMenuList[1]) {
      toggleLinearItems(false);
      toggleSmoothItems(false);

      if (inTangent === EasingType.Linear && outTangent === EasingType.Linear) {
        SingleKeyFrameMenuList[0].disabled = true;
        SingleKeyFrameMenuList[1].disabled = false;
        toggleLinearItems(true);
      } else if (inTangent === EasingType.Smooth && outTangent === EasingType.Smooth) {
        SingleKeyFrameMenuList[0].disabled = false;
        SingleKeyFrameMenuList[1].disabled = true;

        toggleSmoothItems(true);
      } else {
        SingleKeyFrameMenuList[0].disabled = selectedKeyframeIndex === 0 && outTangent === EasingType.Linear;
        SingleKeyFrameMenuList[1].disabled = selectedKeyframeIndex === 0 && outTangent === EasingType.Smooth;

        if (inTangent === EasingType.Linear && outTangent === EasingType.Smooth) {
          SingleKeyFrameMenuList[0].children[0].disabled = true;
          SingleKeyFrameMenuList[1].children[1].disabled = true;
        } else if (inTangent === null) {
          SingleKeyFrameMenuList[0].children[1].disabled = outTangent === EasingType.Linear;
          SingleKeyFrameMenuList[1].children[1].disabled = outTangent === EasingType.Smooth;
        } else {
          SingleKeyFrameMenuList[0].children[1].disabled = true;
          SingleKeyFrameMenuList[1].children[0].disabled = true;
        }
      }

      setChangeKey(`${inTangent}_${outTangent}`);
    }
  }, [changeKey, inTangent, outTangent, setChangeKey, selectedKeyframeIndex]);

  return (
    <ContextMenuWrapper
      overflow={overflow}
      key={changeKey}
      coord={coord}
      onClose={onClose}
      dropdownItems={SingleKeyFrameMenuList}
    ></ContextMenuWrapper>
  );
};
