/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

export const useURLQuery = (): URLSearchParams => {
  // Only called once on page reload, do not listening on path change after loaded.
  const search = window.location.search;

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
