/**
 * Copyright 2023 Design Barn Inc.
 */

import type { AVLayer } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import { round } from 'lodash-es';
import React, { useCallback, useEffect } from 'react';
import RangeSlider from 'react-range-slider-input';
import { shallow } from 'zustand/shallow';

import { handleLayerRightClick } from '../../TimelineLayerPanel/DraggableWrapper/customDnd';
import { useTimelineUtils } from '../hooks';

import type { LayerUI } from '~/features/timeline';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { toolkit, setKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

import './timeline-track.css';

interface Props {
  layerId: string;
  layerUI: LayerUI;
}

export const TimelineTrack: React.FC<Props> = ({ layerId, layerUI }) => {
  const [ctrlDown, getNodeByIdOnly, highlightedLayerIds, keyframeScrubberWidth] = useCreatorStore(
    (state) => [
      state.timeline.singleSelectionModifier,
      state.toolkit.getNodeByIdOnly,
      state.timeline.highlightedLayerIds,
      state.timeline.keyframeScrubberWidth,
    ],
    shallow,
  );
  const [thumbClicked, setThumbClicked] = React.useState(false);

  const node = getNodeByIdOnly(layerId) as AVLayer;
  const roundedNodeStartFrame = round(node.startFrame);
  const roundedNodeEndFrame = round(node.endFrame);
  const nodeOffset = node.timelineOffset - roundedNodeStartFrame;

  const { totalFrames } = useTimelineUtils(keyframeScrubberWidth);

  const handleKeyframeUpdate = useCallback(
    (offsetFrame: number, isDraggingLeft: boolean) => {
      if (layerUI.frameIds.length > 0) {
        // Looping though the frameIds calls setKeyframe on each frame in sequence according
        // to their positions on the timeline - left to right, top to bottom.
        // But if the track is moved to the right, we don't want the first keyframe
        // to overlap with any other keyframes on the right that haven't moved yet, throwing an error.
        // So we reverse the order of the frameIds and move the last keyframe first.
        const frameIds = isDraggingLeft ? layerUI.frameIds : layerUI.frameIds.slice().reverse();

        frameIds.forEach((layerFrameId: string) => {
          const frameObj = toolkit.getKeyframeById(layerFrameId);

          if (!frameObj) return;

          const currentFrame = frameObj.frame;
          const newFrame = isDraggingLeft ? currentFrame - offsetFrame : currentFrame + offsetFrame;

          setKeyFrame(layerFrameId, newFrame as number);
        });
      }
    },
    [layerUI.frameIds],
  );

  const handleOnInput = useCallback(
    (input: [number, number]) => {
      const [inputStartFrame, inputEndFrame] = input;

      const isDragging = inputStartFrame !== roundedNodeStartFrame && inputEndFrame !== roundedNodeEndFrame;

      const isTrimming =
        (inputStartFrame !== roundedNodeStartFrame && inputEndFrame === roundedNodeEndFrame) ||
        (inputStartFrame === roundedNodeStartFrame && inputEndFrame !== roundedNodeEndFrame);

      // Only allow trimming if thumb is clicked or ctrl/cmd is pressed
      if (isTrimming && !thumbClicked && !ctrlDown) return;

      if (isTrimming) {
        // For ctrl + click to trim, the range slider executes it before handleStateHistoryStart,
        // so adding a stateHistory.beginAction here to make sure it is batched
        if (ctrlDown) stateHistory.beginAction();

        if (roundedNodeStartFrame !== inputStartFrame) {
          node.setStartFrame(inputStartFrame);
          node.setTimelineOffset(inputStartFrame + nodeOffset);
          emitter.emit(EmitterEvent.TIMELINE_BAR_START_TIME_UPDATED);
        }

        if (roundedNodeEndFrame !== inputEndFrame) {
          node.setEndFrame(inputEndFrame);
          emitter.emit(EmitterEvent.TIMELINE_BAR_END_TIME_UPDATED);
        }
      }

      if (isDragging) {
        const isDraggingLeft = inputStartFrame < roundedNodeStartFrame;
        const offset = Math.abs(roundedNodeStartFrame - inputStartFrame);

        node.setStartAndEndFrame(inputStartFrame, inputEndFrame);
        node.setTimelineOffset(inputStartFrame + nodeOffset);

        handleKeyframeUpdate(offset, isDraggingLeft);
        emitter.emit(EmitterEvent.TIMELINE_BAR_DRAGGING_UPDATED);
      }
    },
    [roundedNodeStartFrame, roundedNodeEndFrame, thumbClicked, ctrlDown, node, nodeOffset, handleKeyframeUpdate],
  );

  // Change cursor style to pointer when ctrl/cmd is pressed
  useEffect(() => {
    const rangeSlider = document.getElementsByClassName(layerId)[0] as HTMLElement;

    rangeSlider.classList.toggle('ctrlDown', ctrlDown);
  }, [ctrlDown, layerId]);

  const handleOnMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (event.button === 2) {
        handleLayerRightClick(event, layerId);

        return;
      }

      // Setting the state based on the css class instead of triggering from onThumbDragStart
      // because clicking the range slider also triggers onThumbDragStart
      const target = event.target as HTMLElement;
      const wasThumbClicked =
        target.classList.contains('range-slider__thumb') ||
        (target.parentElement?.classList.contains('range-slider__thumb') as boolean);

      setThumbClicked(wasThumbClicked);
    },
    [layerId],
  );

  const handleOnMouseUp = useCallback(() => {
    setThumbClicked(false);
  }, []);

  const handleStateHistoryStart = useCallback(() => {
    if (ctrlDown) return;

    const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

    addToSelectedNodes([layerId], true);

    stateHistory.beginAction();
  }, [ctrlDown, layerId]);

  const handleStateHistoryEnd = useCallback(() => {
    stateHistory.endAction();
    emitter.emit(EmitterEvent.CANVAS_REDRAW);
  }, []);

  const highlight = highlightedLayerIds.includes(layerId);

  return (
    <div onMouseDown={handleOnMouseDown} onMouseUp={handleOnMouseUp}>
      <RangeSlider
        id="timeline-track"
        className={clsx(layerId, { selected: highlight })}
        onInput={handleOnInput}
        value={[roundedNodeStartFrame, roundedNodeEndFrame]}
        onThumbDragStart={handleStateHistoryStart}
        onRangeDragStart={handleStateHistoryStart}
        onThumbDragEnd={handleStateHistoryEnd}
        onRangeDragEnd={handleStateHistoryEnd}
        min={0}
        max={totalFrames}
        step={1}
      />
    </div>
  );
};

export const TimelineEmptyTrack: React.FC = () => {
  return <div className="h-6 bg-gray-800"></div>;
};
