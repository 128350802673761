/**
 * Copyright 2024 Design Barn Inc.
 */

import { Vector2 } from 'three';

import { TIMELINE_BAR_BEGIN_OFFSET_PX } from './components/Timeline/constant';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { getPlaybackParameters } from '~/lib/eventHandler/playback';
import { useCreatorStore } from '~/store';

export class MovePlayHeadTool {
  public isMovingPlayHead = false;

  private _startFrame: number | null = null;

  private _startPosition: Vector2 | null = null;

  public constructor(container: HTMLCanvasElement) {
    container.addEventListener('pointerdown', (event: MouseEvent) => {
      if (this.isMovingPlayHead) {
        const { currentFrame } = getPlaybackParameters();

        this._startFrame = currentFrame;
        this._startPosition = new Vector2(event.clientX, event.clientY);
      }
    });

    document.body.addEventListener('pointerup', () => {
      if (this._startPosition) {
        this._startPosition = null;
        this._startFrame = null;
      }
    });

    document.body.addEventListener('pointermove', (event: MouseEvent) => {
      if (this.isMovingPlayHead && this._startPosition && this._startFrame !== null) {
        const currentMousePosition = new Vector2(event.clientX, event.clientY);
        const diff = new Vector2().subVectors(currentMousePosition, this._startPosition);

        const { duration, fps } = getPlaybackParameters();
        const totalFrames = duration * fps;
        const width = useCreatorStore.getState().timeline.keyframeScrubberWidth;

        const xPosCalculated = (this._startFrame / totalFrames) * (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX);

        let xPos = xPosCalculated + diff.x;

        if (xPos < 0) xPos = 0;
        if (xPos > width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX) xPos = width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX;

        const widthRatio = Math.min(1, Math.abs(xPos / (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX)));
        const newFrame = Math.round(widthRatio * (totalFrames - 1));

        useCreatorStore.getState().toolkit.setCurrentFrame(newFrame);
        emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, { skipUpdate: true });
      }
    });

    document.body.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.code === 'KeyD') {
        this.isMovingPlayHead = true;
      }
    });

    document.body.addEventListener('keyup', () => {
      if (this.isMovingPlayHead) {
        this.isMovingPlayHead = false;
        this._startPosition = null;
        this._startFrame = null;
      }
    });
  }
}
