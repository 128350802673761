/**
 * Copyright 2023 Design Barn Inc.
 */

import { graphql } from 'msw';

export const handlers = [
  // Handles a "Login" mutation
  graphql.query('userHasCreatorAccess', (_, res, ctx) => {
    return res(
      ctx.data({
        userHasCreatorAccess: true,
      }),
    );
  }),

  graphql.query('workspaceDraftProject', (_, res, ctx) => {
    return res(ctx.errors([{ extensions: { code: 'NOT_FOUND' } }]));
  }),

  graphql.query('hasAccessToAWorkspace', (_, res, ctx) => {
    return res(
      ctx.data({
        hasAccessToAWorkspace: true,
      }),
    );
  }),

  graphql.query('getWorkspaces', (_, res, ctx) => {
    return res(
      ctx.data({
        workspaces: [],
      }),
    );
  }),

  // To test the error state in save modal
  // graphql.query('getWorkspaceProjects', (_, res, ctx) => {
  //   return res(ctx.errors([]));
  // }),
  // graphql.query('foldersByProjectId', (_, res, ctx) => {
  //   return res(ctx.errors([]));
  // }),
  //
  //
  // To test the error alerts during save flow
  // graphql.mutation('FileUploadCreateRequest', (_, res, ctx) => {
  //   return res(ctx.errors([]));
  // }),
  // graphql.mutation('FileCreate', (_, res, ctx) => {
  //   return res(ctx.errors([]));
  // }),
  // graphql.query('file', (_, res, ctx) => {
  //   return res(ctx.errors([]));
  // }),
  // graphql.mutation('FileVersionCreate', (_, res, ctx) => {
  //   return res(ctx.errors([]));
  // }),
];
