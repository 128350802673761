/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  DagNode,
  PercentageJSON,
  AnimatedPropertiesJSON,
  ColorStopJSON,
  GradientJSON,
  VectorJSON,
  AngleJSON,
  ShapeLayer,
  ColorStop,
  ScalarJSON,
} from '@lottiefiles/toolkit-js';
import {
  FillShape,
  Color,
  Scalar,
  GradientColor,
  Vector,
  AVLayer,
  ShapeType,
  GroupShape,
  GradientFillShape,
  GradientFillType,
} from '@lottiefiles/toolkit-js';
import { cloneDeep } from 'lodash-es';
import { Vector2, Box3, MathUtils, Matrix4, Vector3, DataTexture, RGBAFormat, FloatType, RedFormat } from 'three';

import { getLighterOrDarkerColor } from '../../components/Elements/ColorPicker/helpers/lightnessUtils';
import { canvasMap } from '../canvas';

import { ColorMode } from '~/components/Elements/ColorPicker/components/ColorPicker';
import { getLightness, hexToRgb } from '~/components/Elements/ColorPicker/helpers';
import type { CObject3D } from '~/features/canvas';
import type { AppGFill } from '~/lib/toolkit';
import { getPathToRoot, AnimatedType, createGetCurrentKeyframe, updateColor } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const GradientPoint = {
  StartX: 'StartX',
  StartY: 'StartY',
  EndX: 'EndX',
  EndY: 'EndY',
  HighlightLength: 'HighlightLength',
  HighlightAngle: 'HighlightAngle',
};

export const GradientFillMapping = {
  None: GradientFillType.NONE,
  Linear: GradientFillType.LINEAR,
  Radial: GradientFillType.RADIAL,
  Angular: GradientFillType.ANGULAR,
  Reflected: GradientFillType.REFLECTED,
  Diamond: GradientFillType.DIAMOND,
};

export const GradientFillTypes = Object.keys(GradientFillMapping);

export interface CurrentGFillShape {
  alpha: number;
  animatedProperties: AnimatedPropertiesJSON | null;
  bm: number;
  colorCurrentKeyframe: string;
  colorIsAnimated: boolean;
  cssColor: string;
  fillRule: number;
  g: ColorStopJSON[];
  geX: number;
  geY: number;
  gsX: number;
  gsY: number;
  ha: number;
  hl: number;
  id: string | null;
  opacity: number;
  opacityCurrentKeyframe: string;
  opacityIsAnimated: boolean;
  type: GradientFillType;
}

export const getGradientFillShape = (node: DagNode | null): GradientFillShape | null => {
  if (node instanceof GroupShape) {
    const shape = node.shapes.find((sh) => sh.type === ShapeType.GRADIENT_FILL);

    if (shape instanceof GradientFillShape) {
      return shape;
    }
  } else if (node instanceof GradientFillShape) {
    return node;
  }

  return null;
};

export const getGradientFillShapes = (node: DagNode | null): GradientFillShape[] | null => {
  if (node instanceof GroupShape || node instanceof AVLayer) {
    const shapes = (node as GroupShape).shapes.filter(
      (sh) => sh.type === ShapeType.GRADIENT_FILL,
    ) as GradientFillShape[];

    if (shapes.length > 0 && shapes[0] instanceof GradientFillShape) {
      return shapes;
    }
  } else if (node instanceof GradientFillShape) {
    return [node];
  }

  return null;
};

export const defaultCurrentGFillShape: CurrentGFillShape = {
  cssColor: 'linear-gradient(180deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)',
  colorCurrentKeyframe: '',
  colorIsAnimated: false,
  alpha: 1,
  opacity: 100,
  opacityCurrentKeyframe: '',
  opacityIsAnimated: false,
  fillRule: 1,
  id: null,
  bm: 0,
  animatedProperties: null,
  g: [],
  geX: 0,
  geY: 0,
  gsX: 0,
  gsY: 0,
  ha: 90,
  hl: 0,
  type: GradientFillType.LINEAR,
};

export const convertToGradientCss = (colors: ColorStopJSON[], type: GradientFillType, lineAngle: number): string => {
  if (colors.length > 0) {
    let gradientStr = '';

    colors.forEach((color: ColorStopJSON, colorIndex: number) => {
      const isLast = colorIndex === colors.length - 1;
      const rgbaL = `rgba(${color.r},${color.g},${color.b},${color.a}) ${color.offset * 100}%`;

      gradientStr = `${gradientStr}${rgbaL}`;
      if (!isLast) gradientStr = `${gradientStr},`;
    });

    const gradientType =
      type === GradientFillType.LINEAR ? `linear-gradient(${lineAngle}deg,` : 'radial-gradient(circle,';

    return `${gradientType} ${gradientStr})`;
  }

  return 'black';
};

const getCssGradientAngle = (currentGFillShape: CurrentGFillShape): number => {
  const lineAngle = MathUtils.radToDeg(
    Math.atan2(currentGFillShape.geY - currentGFillShape.gsY, currentGFillShape.geX - currentGFillShape.gsX),
  );

  // - add 90 because css angles are calculated from the mid-top
  // - make sure the angle is positive
  // - make sure the angle is between 0 and 360
  return (lineAngle + 90 + 360) % 360;
};

export const getCurrentGFillShape = (node: GradientFillShape | null): CurrentGFillShape => {
  const currentGFillShape: CurrentGFillShape = { ...defaultCurrentGFillShape };

  if (node instanceof GradientFillShape) {
    const currentFrame = node.scene.timeline.currentFrame;
    const getKeyFrame = createGetCurrentKeyframe(currentFrame);
    const gFillState = node.state;

    currentGFillShape.type = gFillState.properties.t as GradientFillType;

    currentGFillShape.id = gFillState.id as string;

    currentGFillShape.bm = gFillState.properties.bm as number;
    currentGFillShape.opacity = (gFillState.animatedProperties.o.value as PercentageJSON).pct;
    currentGFillShape.opacityCurrentKeyframe = getKeyFrame(gFillState.animatedProperties.o);
    currentGFillShape.fillRule = gFillState.properties.flr as number;
    currentGFillShape.animatedProperties = cloneDeep(gFillState.animatedProperties);

    // Color
    const colors = (gFillState.animatedProperties.g.value as GradientJSON).colors;

    currentGFillShape.g = colors;
    currentGFillShape.colorCurrentKeyframe = getKeyFrame(gFillState.animatedProperties.g);

    // End point
    currentGFillShape.geX = (gFillState.animatedProperties.ge.value as VectorJSON).x;
    currentGFillShape.geY = (gFillState.animatedProperties.ge.value as VectorJSON).y;

    // Start point
    currentGFillShape.gsX = (gFillState.animatedProperties.gs.value as VectorJSON).x;
    currentGFillShape.gsY = (gFillState.animatedProperties.gs.value as VectorJSON).y;

    // Highlight Angle, relative to the direction from gs to ge
    const angle = (gFillState.animatedProperties.ha.value as AngleJSON).deg;

    currentGFillShape.ha = angle;

    // Highlight Length, as a percentage between gs and ge
    currentGFillShape.hl = (gFillState.animatedProperties.hl.value as ScalarJSON).value as number;

    currentGFillShape.cssColor = convertToGradientCss(
      colors,
      gFillState.properties.t as GradientFillType,
      getCssGradientAngle(currentGFillShape),
    );
  }

  return currentGFillShape;
};

export const getCurrentGFillShapes = (node: DagNode | null): AppGFill => {
  const fillShapes = getGradientFillShapes(node);

  let currentGradientFill = {};

  if (fillShapes && fillShapes.length > 0) {
    currentGradientFill = fillShapes
      .map((fs: GradientFillShape) => getCurrentGFillShape(fs))
      .reduce((fShapes: AppGFill, obj: CurrentGFillShape) => {
        if (obj.id) fShapes[obj.id] = { ...obj };

        return fShapes;
      }, {});
  }

  return currentGradientFill;
};

const getUnprojectedStartAndEndPoint = (
  currentFillShape: GradientFillShape | FillShape,
  projectedStart: Vector2,
  projectedEnd: Vector2,
): {
  unprojectedEnd: Vector3;
  unprojectedStart: Vector3;
} => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  const pathToRoot = getPathToRoot(currentFillShape.nodeId).slice(1);
  const matrices: Matrix4[] = [];

  pathToRoot.forEach((id: string) => {
    const node = getNodeByIdOnly(id);

    if (node && node.transform) {
      const matrix = new Matrix4().fromArray(node.transform.matrix.matrix);

      matrices.push(matrix);
    }
  });

  const unprojectedEnd = new Vector3(projectedStart.x, projectedStart.y, 0);
  const unprojectedStart = new Vector3(projectedEnd.x, projectedEnd.y, 0);

  matrices
    .slice()
    .reverse()
    .forEach((matrix) => {
      unprojectedStart.applyMatrix4(matrix.clone().invert());
      unprojectedEnd.applyMatrix4(matrix.clone().invert());
    });

  return {
    unprojectedEnd,
    unprojectedStart,
  };
};

const getProjectedStartAndEndPoint = (
  currentFillShape: GradientFillShape | FillShape,
): {
  projectedEnd: Vector2;
  projectedStart: Vector2;
} => {
  const shapeObject = canvasMap.get(currentFillShape.nodeId as string) as CObject3D;

  const boundingBox = new Box3().setFromObject(shapeObject, true);
  const isHorizontallyBigger =
    Math.abs(boundingBox.max.x - boundingBox.min.x) > Math.abs(boundingBox.max.y - boundingBox.min.y) ||
    Math.abs(boundingBox.max.x - boundingBox.min.x) === Math.abs(boundingBox.max.y - boundingBox.min.y);

  if (isHorizontallyBigger) {
    return {
      projectedStart: new Vector2(boundingBox.max.x, (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y),
      projectedEnd: new Vector2(boundingBox.min.x, (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y),
    };
  }

  return {
    projectedStart: new Vector2((boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x, boundingBox.max.y),
    projectedEnd: new Vector2((boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x, boundingBox.min.y),
  };
};

const getStartAndEndPoint = (
  currentFillShape: GradientFillShape | FillShape,
): {
  end: Vector3;
  start: Vector3;
} => {
  const { projectedEnd, projectedStart } = getProjectedStartAndEndPoint(currentFillShape);
  const { unprojectedEnd, unprojectedStart } = getUnprojectedStartAndEndPoint(
    currentFillShape,
    projectedStart,
    projectedEnd,
  );

  return {
    end: unprojectedEnd,
    start: unprojectedStart,
  };
};

export const switchSolidAndGradient = (
  currentFillShape: GradientFillShape | FillShape,
  from: ColorMode,
  to: ColorMode,
  color: string | null,
  colorStops: ColorStop[] | null,
  updateNodeSelection = true,
): GradientFillShape | FillShape | null => {
  const setAnimatedValue = useCreatorStore.getState().toolkit.setAnimatedValue;
  const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

  const parent = currentFillShape.parent as ShapeLayer | GroupShape | null;

  if (!parent) return null;

  // from linear and radial to solid
  if (to === ColorMode.Solid) {
    if (currentFillShape.type === ShapeType.FILL) {
      updateColor(setAnimatedValue, [currentFillShape.nodeId], AnimatedType.FILL_COLOR, `#${color}`);

      return currentFillShape;
    }
    const solidFillShape = new FillShape(parent);

    solidFillShape.fromJSON((currentFillShape as GradientFillShape).toJSON());
    if (color) {
      // if one of the multi-selected shape is a solid color, it should be the target solid color of all selected gradients.
      const { blue, green, red } = hexToRgb(color);

      solidFillShape.setColor(new Color(red, green, blue));
    } else {
      const solidColor = (currentFillShape as GradientFillShape).gradient.state.value?.colors[0] ?? {
        r: 255,
        g: 0,
        b: 0,
        a: 1,
        offset: 0,
      };

      solidFillShape.setColor(new Color(solidColor.r, solidColor.g, solidColor.b));
    }

    currentFillShape.removeFromGraph();
    parent.addShape(solidFillShape);
    if (updateNodeSelection) addToSelectedNodes([parent.nodeId], true);

    return solidFillShape;
  }

  // from solid to linear or radial
  if (from === ColorMode.Solid || currentFillShape.type === ShapeType.FILL) {
    const gShape = new GradientFillShape(parent);

    gShape.fromJSON((currentFillShape as FillShape).toJSON());

    let updatedColorStops = colorStops ?? [];

    if (color) {
      const { blue, green, red } = hexToRgb(color);
      const lightness = getLightness(red, green, blue);
      const endColor = getLighterOrDarkerColor(red, green, blue, lightness >= 0.5 ? 20 : -20);

      updatedColorStops = [
        {
          color: new Color(red, green, blue, 1),
          stop: new Scalar(0),
        },
        {
          color: new Color(endColor.red, endColor.green, endColor.blue, 1),
          stop: new Scalar(1),
        },
      ];
    }

    const { end, start } = getStartAndEndPoint(currentFillShape);

    gShape.setGradient(new GradientColor(updatedColorStops));
    gShape.setStartPoint(to === ColorMode.Radial ? new Vector(0, 0) : new Vector(start.x, start.y));
    gShape.setEndPoint(new Vector(end.x, end.y));
    gShape.setGradientType(to === ColorMode.Radial ? GradientFillType.RADIAL : GradientFillType.LINEAR);

    currentFillShape.removeFromGraph();
    parent.addShape(gShape);
    if (updateNodeSelection) addToSelectedNodes([parent.nodeId], true);

    return gShape;
  }

  // switch between linear and radial
  if (!(currentFillShape instanceof GradientFillShape)) {
    return null;
  }

  const { end, start } = getStartAndEndPoint(currentFillShape);

  currentFillShape.setGradientType(to === ColorMode.Radial ? GradientFillType.RADIAL : GradientFillType.LINEAR);
  currentFillShape.setStartPoint(to === ColorMode.Radial ? new Vector(0, 0) : new Vector(start.x, start.y));
  currentFillShape.setEndPoint(new Vector(end.x, end.y));
  if (updateNodeSelection) addToSelectedNodes([parent.nodeId], true);

  return currentFillShape;
};

export const getColorStopDataTexture = (
  colorStops: ColorStopJSON[],
): { colorStopsTexture: DataTexture; offsetsTexture: DataTexture } => {
  const width = colorStops.length;

  const colorStopsData = new Uint8Array(width * 4);
  const offsetsData = new Float32Array(width);

  colorStops.forEach((stop, i) => {
    colorStopsData[i * 4] = stop.r;
    colorStopsData[i * 4 + 1] = stop.g;
    colorStopsData[i * 4 + 2] = stop.b;
    colorStopsData[i * 4 + 3] = Math.round(stop.a * 255);
    offsetsData[i] = stop.offset;
  });

  const colorStopsTexture = new DataTexture(colorStopsData, colorStops.length, 1, RGBAFormat);
  const offsetsTexture = new DataTexture(offsetsData, colorStops.length, 1, RedFormat, FloatType);

  colorStopsTexture.needsUpdate = true;
  offsetsTexture.needsUpdate = true;

  return { colorStopsTexture, offsetsTexture };
};
