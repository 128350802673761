/**
 * Copyright 2022 Design Barn Inc.
 */

export enum RaycasterLayers {
  Inactive = 1,
  CMesh = 2,
  CObject3D = 3,
  CLayer = 4,
  PreComposition = 5,
  Allpass = 6,
}

export const DOUBLE_CLICK_DELAY = 200;

export enum UserDataMap {
  CurrentFrame = 'currentFrame',
  FirstAndLastResolved = 'firstAndLastResolved',
  MultiPivot = 'multiPivot',
  MultiPivotOffset = 'multiPivotOffset',
  PivotCentered = 'pivotCentered',
  PivotVisible = 'pivotVisible',
  ScaleRatioLock = 'scaleRatioLock',
  SizeRatioLock = 'sizeRatioLock',
  TrimOffsetReversed = 'trimOffsetReversed',
}

export const CANVAS_ROUND_PRECISION = 4;

export const BLUE_COLOR = 0x00b6fe;
export const BLACK_COLOR = 0x000000;
export const WHITE_COLOR = 0xffffff;

export const BLUE_COLOR_RGB = 'rgb(0, 182, 254)';

export const enum ElementTags {
  IFRAME = 'IFRAME',
  INPUT = 'INPUT',
}
