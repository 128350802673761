/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable id-length */
import { GradientFillType, GradientStrokeType } from '@lottiefiles/lottie-js';
import type {
  AnimatedPropertiesJSON,
  PropertiesJSON,
  ColorJSON,
  PercentageJSON,
  GradientJSON,
  VectorJSON,
  AngleJSON,
  ScalarJSON,
  SizeJSON,
  AnimatedPropertyJSON,
  AnimatedMultiDPropertyJSON,
} from '@lottiefiles/toolkit-js';
import { Color } from 'three';

import type { BezierUniforms, CompoundBezierUniforms, MaskUniforms } from '../3d/shapes/path';
import { getCompoundBezierStroke, getBezierStroke } from '../3d/shapes/path';
import type { RectangleProperty } from '../3d/shapes/rectangle';
import { getRectangle } from '../3d/shapes/rectangle';
import type { TransformProperty } from '../3d/shapes/transform';
import { getTransform } from '../3d/shapes/transform';
import type { BezierMesh, CObject3D } from '../types';

import { getColorStopDataTexture } from '~/lib/toolkit';

export const parseFill = (
  path: BezierMesh,
  animatedProperties: AnimatedPropertiesJSON,
  accumulatedOpacity: number,
): void => {
  const { cl, o } = animatedProperties;
  const { b, g, r } = cl.value as ColorJSON;

  // TODO this is 2(even_odd rule) by default. Users can change it to none_zero rule through UI
  // const fillRule = properties.flr as number;
  const fillRule = 2;

  path.material.uniforms['uColor'] = {
    value: new Color(`rgb(${r.toFixed(0)},${g.toFixed(0)},${b.toFixed(0)})`),
  };
  path.material.userData['isFillOrGradientColor'] = true;

  path.material.transparent = true;
  const opacity = (o.value as PercentageJSON).pct / 100;

  path.material.uniforms['uOpacity'] = {
    value: opacity * accumulatedOpacity,
  };
  path.material.uniforms['uFillRule'] = { value: fillRule };
  path.opacity = opacity;
};

export const parseGradientFill = (
  path: BezierMesh,
  properties: AnimatedPropertiesJSON,
  accumulatedOpacity: number,
  gradientType: GradientFillType,
): void => {
  const { g, ge, gs, ha, hl, o: opacity } = properties;

  const colorStops = (g.value as GradientJSON).colors;
  const { colorStopsTexture, offsetsTexture } = getColorStopDataTexture(colorStops);

  path.material.uniforms['uIsRadial'] = { value: gradientType === GradientFillType.RADIAL || false };
  path.material.uniforms['uColorStopsCount'] = { value: colorStops.length };
  path.material.uniforms['uColorStops'] = { value: colorStopsTexture };
  path.material.uniforms['uColorStopOffsets'] = { value: offsetsTexture };
  path.material.uniforms['uStart'] = { value: gs.value as VectorJSON };
  path.material.uniforms['uEnd'] = { value: ge.value as VectorJSON };
  path.material.uniforms['uHighlightAngle'] = { value: (ha.value as AngleJSON).deg };
  path.material.uniforms['uHighlightLength'] = { value: (hl.value as ScalarJSON).value };
  path.material.uniforms['uOpacity'] = {
    value: (((opacity.value ?? opacity.staticValue) as PercentageJSON).pct * accumulatedOpacity) / 100,
  };

  path.material.userData['isFillOrGradientColor'] = true;

  path.material.transparent = true;
};

export const parseSolidLayer = (
  parent: CObject3D,
  properties: PropertiesJSON,
  animatedProperties: AnimatedPropertiesJSON,
  precompId: string | undefined,
): void => {
  const setting: RectangleProperty = {
    width: { value: (properties.sz as SizeJSON).w },
    height: { value: (properties.sz as SizeJSON).h },
    positionX: { value: (animatedProperties.p.value as VectorJSON).x },
    positionY: { value: (animatedProperties.p.value as VectorJSON).y },
    radius: { value: 0 },
    color: properties.slc as unknown as ColorJSON,
    drawOrder: properties.do as number,
  };
  const mesh = getRectangle(setting);

  mesh.precompId = precompId;

  parent.add(mesh);
};

export const parseStroke = (
  properties: AnimatedPropertiesJSON,
  bezierUniforms: BezierUniforms | CompoundBezierUniforms,
  maskUniforms: MaskUniforms | null | undefined,
  isCompoundBezier: boolean,
  accumulatedOpacity: number = 1,
): BezierMesh => {
  const { pct } = properties.o.value as PercentageJSON;
  const opacity = (accumulatedOpacity * pct) / 100;
  const strokeWidth = (properties.sw.value ?? properties.sw.staticValue) as ScalarJSON;

  const strokeMesh = isCompoundBezier
    ? getCompoundBezierStroke(bezierUniforms as CompoundBezierUniforms, strokeWidth.value, false, maskUniforms)
    : getBezierStroke(bezierUniforms as BezierUniforms, strokeWidth.value, false, maskUniforms);
  const { b, g, r } = (properties.sc.value ?? properties.sc.staticValue) as ColorJSON;

  strokeMesh.material.uniforms['uColor'] = {
    value: new Color(`rgb(${r.toFixed(0)},${g.toFixed(0)},${b.toFixed(0)})`),
  };
  strokeMesh.material.uniforms['uOpacity'] = { value: opacity };

  return strokeMesh;
};

// TODO: mask on gradient stroke
export const parseGradientStroke = (
  properties: AnimatedPropertiesJSON,
  bezierUniforms: BezierUniforms | CompoundBezierUniforms,
  isCompoundBezier: boolean,
  gradientType: GradientStrokeType,
  accumulatedOpacity: number = 1,
): BezierMesh => {
  const { pct } = properties.o.value as PercentageJSON;
  const opacity = (accumulatedOpacity * pct) / 100;
  const strokeWidth = (properties.sw.value ?? properties.sw.staticValue) as ScalarJSON;

  const strokeMesh = isCompoundBezier
    ? getCompoundBezierStroke(bezierUniforms as CompoundBezierUniforms, strokeWidth.value, true)
    : getBezierStroke(bezierUniforms as BezierUniforms, strokeWidth.value, true, null);
  const { g, ge, gs, ha, hl } = properties;

  const colorStops = (g.value as GradientJSON).colors;
  const { colorStopsTexture, offsetsTexture } = getColorStopDataTexture(colorStops);

  strokeMesh.material.uniforms['uOpacity'] = { value: opacity };
  strokeMesh.material.uniforms['uIsRadial'] = { value: gradientType === GradientStrokeType.RADIAL || false };
  strokeMesh.material.uniforms['uColorStopsCount'] = { value: colorStops.length };
  strokeMesh.material.uniforms['uColorStops'] = { value: colorStopsTexture };
  strokeMesh.material.uniforms['uColorStopOffsets'] = { value: offsetsTexture };
  strokeMesh.material.uniforms['uStart'] = { value: gs.value as VectorJSON };
  strokeMesh.material.uniforms['uEnd'] = { value: ge.value as VectorJSON };
  strokeMesh.material.uniforms['uHighlightAngle'] = { value: (ha.value as AngleJSON).deg };
  strokeMesh.material.uniforms['uHighlightLength'] = { value: (hl.value as ScalarJSON).value };

  return strokeMesh;
};

const parseVector = (vector: AnimatedPropertyJSON): VectorJSON => {
  // split vector
  if (vector.isSplit) {
    const { x, y } = (vector as AnimatedMultiDPropertyJSON).components;

    if (x?.value && y?.value) {
      return {
        x: x.value.value,
        y: y.value.value,
      };
    }
  }

  return vector.value as VectorJSON;
};

export const parseGroupProperties = (group: CObject3D, groupProperties: AnimatedPropertiesJSON): void => {
  const { a, o, p, r, s, sa, sk } = groupProperties;

  const anchorVal = parseVector(a);
  const posVal = p.value ? parseVector(p) : { x: 250, y: 250 };
  const scaleVal = s.value ? parseVector(s) : { x: 100, y: 100 };
  const rotVal = r.value ? (r.value as AngleJSON) : { deg: 0 };
  const opacityVal = o.value ? (o.value as PercentageJSON) : { pct: 100 };

  const settings: TransformProperty = {
    anchorX: anchorVal.x,
    anchorY: anchorVal.y,
    positionX: posVal.x,
    positionY: posVal.y,
    scaleX: scaleVal.x / 100,
    scaleY: scaleVal.y / 100,
    rotation: rotVal.deg,
    opacity: opacityVal.pct / 100,
    skew: (sa.value as AngleJSON).deg,
    skewAngle: (sk.value as AngleJSON).deg,
  };

  getTransform(group, settings);
};
