/**
 * Copyright 2024 Design Barn Inc.
 */

import { nanoid } from 'nanoid';
import type { CSSProperties } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { useCreatorStore } from '~/store';

export const useFloatingWindow = (): {
  windowId: string;
  windowProps: { onMouseDownCapture: () => void; style: CSSProperties };
} => {
  const windowId = useRef(nanoid());

  const [floatingWindows, addToFloatingWindows, removeFromFloatingWindows, _setAsTopWindow] = useCreatorStore(
    (state) => [
      state.ui.floatingWindows,
      state.ui.addToFloatingWindows,
      state.ui.removeFromFloatingWindows,
      state.ui.setAsTopWindow,
    ],
    shallow,
  );

  useEffect(() => {
    addToFloatingWindows(windowId.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      removeFromFloatingWindows(windowId.current);
    };
  }, [addToFloatingWindows, removeFromFloatingWindows]);

  const zIndex = useMemo(
    // assuming 100 possible floating windows
    () => 100 - (floatingWindows.length - 1 - floatingWindows.indexOf(windowId.current)),
    [floatingWindows],
  );

  const setAsTopWindow = useCallback(() => {
    _setAsTopWindow(windowId.current);
  }, [_setAsTopWindow]);

  return { windowId: windowId.current, windowProps: { onMouseDownCapture: setAsTopWindow, style: { zIndex } } };
};
