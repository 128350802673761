/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable id-length */
import type {
  AssetJSON,
  CubicBezierShape,
  DrawableBezierView,
  GradientFillType,
  GradientStrokeType,
  GroupBezierView,
  GroupShapeJSON,
  ImageLayer,
  LayerJSON,
  MaskModeType,
  PathShape,
  PercentageJSON,
  PrecompositionLayerJSON,
  Scene,
  SceneJSON,
  ShapeJSON,
  ShapeLayer,
  ShapeLayerJSON,
  SizeJSON,
  TextLayerJSON,
} from '@lottiefiles/toolkit-js';
import { LayerType, ShapeType } from '@lottiefiles/toolkit-js';
import { BoxGeometry, Matrix4, Mesh, MeshBasicMaterial, TextureLoader } from 'three';

import type { BezierUniforms, CompoundBezierUniforms, MaskUniforms } from '../3d/shapes/path';
import {
  addOutline,
  getBezierShape,
  getBezierUniforms,
  getCompoundBezierShape,
  getCompoundBezierUniforms,
} from '../3d/shapes/path';
import { collectBeziers, DefaultMatteProperty, getMaskModeIndex, hasMasks, matteUpdate } from '../3d/threeFactory';
import type { MatteProperties, MaskProperties } from '../3d/threeFactory';
import { RaycasterLayers } from '../constant';
import type { BezierMesh } from '../types';
import { CObject3D } from '../types';

import { attachToolkitListener } from './observer';
import {
  parseFill,
  parseGradientFill,
  parseGradientStroke,
  parseGroupProperties,
  parseSolidLayer,
  parseStroke,
} from './parseHelpers';

import {
  canvasMap,
  initMaskMap,
  initModifierMap,
  initPrecompLayerMap,
  matteMap,
  setCanvasMap,
  setModifierMap,
  setPrecompLayerMap,
  setRenderRangeMap,
} from '~/lib/canvas';
import { layerMap } from '~/lib/layer';
import { getAssetByReferenceId, getToolkitState, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

export const isShapeLayerJSON = (json: LayerJSON): json is ShapeLayerJSON => 'shapes' in json;
const isTextLayerJSON = (json: LayerJSON): json is TextLayerJSON => 'textAnimators' in json;

export const assertGradientFillShapeJSON = (json: ShapeJSON): json is GroupShapeJSON =>
  json.type === ShapeType.GRADIENT_FILL;
export const isPrecompositionLayerJSON = (json: LayerJSON): json is LayerJSON => json.type === 'PRECOMPOSITION';
const isImageLayerJSON = (json: LayerJSON): json is LayerJSON => json.type === 'IMAGE';
const isSolidLayerJSON = (json: LayerJSON): json is LayerJSON => json.type === 'SOLID';

interface ParseLayersProps {
  accumulatedOpacity: number;
  isFocused: boolean;
  layers: LayerJSON[];
  maskProperty?: MaskProperties[] | null | undefined;
  matteProperty?: MatteProperties;
  parent: CObject3D;
  parentPrecomLayer?: PrecompositionLayerJSON | undefined;
  parentsPrecompDrawOrder?: number[];
  parentsPrecompLayersLength?: number[];
}

interface ParseBeziersProps {
  accumulatedOpacity: number;
  bezierView: GroupBezierView;
  drawOrder?: number;
  isFocused: boolean;
  maskProperty?: MaskProperties[] | null | undefined;
  matteProperty?: MatteProperties | undefined;
  parent: CObject3D;
  parentsPrecompDrawOrder?: number[];
  parentsPrecompLayersLength?: number[];
  precompId?: string | undefined;
}

interface ParseBezierShapesProps {
  accumulatedOpacity: number;
  bezierViews: DrawableBezierView[];
  dOrder?: number;
  isFocused: boolean;
  maskProperty?: MaskProperties[] | null | undefined;
  matteProperty?: MatteProperties | undefined;
  parent: CObject3D;
  parentsPrecompDrawOrder?: number[];
  parentsPrecompLayersLength?: number[];
  precompId?: string | undefined;
}

interface ParseShapeLayerProps {
  accumulatedOpacity: number;
  isFocused: boolean;
  layer: ShapeLayerJSON;
  maskProperty?: MaskProperties[] | null | undefined;
  matteProperty?: MatteProperties;
  parent: CObject3D;
  parentPrecomLayer: PrecompositionLayerJSON | undefined;
  parentsPrecompDrawOrder?: number[];
  parentsPrecompLayersLength?: number[];
}

interface ParsePrecompLayersProps {
  accumulatedOpacity: number;
  isFocused: boolean;
  layer: PrecompositionLayerJSON;
  maskProperty?: MaskProperties[] | null | undefined;
  matteProperty?: MatteProperties | undefined;
  parent: CObject3D;
  parentsPrecompDrawOrder?: number[];
  parentsPrecompLayersLength?: number[];
}

export class Parser {
  private readonly _cachedAssets = new Map();

  private readonly _container: CObject3D;

  private _frame: number;

  private _scene: SceneJSON | undefined;

  public constructor(container: CObject3D) {
    this._container = container;
    this._frame = 0;
  }

  public createGroup(layer: LayerJSON, parentPrecomLayer: PrecompositionLayerJSON | undefined): CObject3D {
    const group = new CObject3D();

    const ip = layer.properties.ip as number;
    const op = layer.properties.op as number;
    const tmo = (layer.properties.tmo as number) || 0;

    group.toolkitId = layer.id;
    group.precompId = parentPrecomLayer?.id;
    group.layers.enable(RaycasterLayers.CLayer);

    const layerUI = layerMap.get(layer.id);
    const isHidden = layerUI?.isHidden || Boolean(layer.data?.['isMatteHidden']) || false;

    group.userData['isLocked'] = layerUI?.isLocked ?? false;

    group.layerType = layer.type;

    const precompOffset = (parentPrecomLayer?.properties.tmo ?? 0) as number;
    const parentPrecompId = parentPrecomLayer?.id;

    group.visible = !isHidden && this._frame >= ip + precompOffset && this._frame < op + precompOffset;
    setRenderRangeMap(layer.id, [ip, op, tmo, parentPrecompId]);
    setCanvasMap(group.toolkitId, group);

    if (parentPrecompId) {
      setRenderRangeMap(`${parentPrecompId}_${layer.id}`, [ip, op, tmo, parentPrecompId]);
      setCanvasMap(`${parentPrecompId}_${group.toolkitId}`, group);
    }

    attachToolkitListener(group.toolkitId);
    parseGroupProperties(group, layer.animatedProperties);

    return group;
  }

  public getMaskUniforms(maskProperty: MaskProperties[] | null | undefined): MaskUniforms | null {
    // TODO handle multi masks
    if (maskProperty && maskProperty.length > 0 && maskProperty[0]) {
      const bezierUniforms = getBezierUniforms(maskProperty[0].maskPath, true);

      return {
        uMaskBezierCount: bezierUniforms.uBezierCount,
        uMaskBezierPoints: bezierUniforms.uBezierPoints,
        uMaskMode: getMaskModeIndex(maskProperty[0].maskMode),
        uMaskOpacity: maskProperty[0].opacity / 100,
      };
    }

    return null;
  }

  public initMaps(): void {
    initModifierMap();
    initPrecompLayerMap();
    initMaskMap();
  }

  public parseBezierShapes({
    parent,
    isFocused,
    bezierViews,
    accumulatedOpacity,
    dOrder,
    precompId,
    maskProperty,
    matteProperty = DefaultMatteProperty,
    parentsPrecompDrawOrder = [],
    parentsPrecompLayersLength = [],
  }: ParseBezierShapesProps): void {
    if (bezierViews.length === 0) return;

    /**
     * Given:
     * parentsPrecompDrawOrder = [0, 2]
     * parentsPrecompLayersLength = [6, 12]
     * drawOrder = 5
     *
     * --firstPrecomp
     *                --
     *                --
     *                -- secondPrecomp
     *                --                  --
     *                --                  --
     *                --                  --
     *                                    --
     *                                    --
     *                                    -- Shape  --- drawOrder = 5 (canvas drawOrder = 0.205)
     *                                    --
     *                                    --
     *                                    --
     *                                    --
     *                                    --
     *
     * Steps:
     * 1. rootPosition = 0 (first element of parentsPrecompDrawOrder)
     * 2. strParts = ['2', '05']
     *    - '2': The second element (2) padded to the length of 6.toString().length (1)
     *    - '05': The drawOrder (5) padded to the length of 12.toString().length (2)
     * 3. result = '0.205' (combining rootPosition and strParts)
     * 4. parseFloat('0.205') returns 0.205
     *
     * Calculates a unique floating-point draw order based on the hierarchical positions of nodes.
     */
    const getPrecompDrawOrder = (drawOrder: number): number => {
      const rootPosition = parentsPrecompDrawOrder[0];
      const strParts = [...parentsPrecompDrawOrder.slice(1), drawOrder].map((num: number, index: number) => {
        const len = parentsPrecompLayersLength[index] ?? 1000;

        return String(num).padStart(len.toString().length, '0');
      });
      const result = `${rootPosition}.${strParts.join('')}`;

      return parseFloat(result);
    };

    const focusedNodeIds = useCreatorStore.getState().ui.focusedNodeIds;

    const drawOrder = bezierViews[0]?.shape.state.properties.do as number;
    const isCompoundBezier = bezierViews.filter((bezierView) => bezierView.modifiers.length === 0).length > 1;
    const isGradientFill = Boolean(
      bezierViews.find((bezierView) => bezierView.styles.find((style) => style.state.type === ShapeType.GRADIENT_FILL)),
    );
    const isGradientStroke = Boolean(
      bezierViews.find((bezierView) =>
        bezierView.styles.find((style) => style.state.type === ShapeType.GRADIENT_STROKE),
      ),
    );

    for (const bezierView of bezierViews) {
      for (const bezier of bezierView.beziers) {
        const fillUniforms = getBezierUniforms(bezier, true);
        const maskUniforms = this.getMaskUniforms(maskProperty);

        parent.updateMatrix();
        const offsetMatrix =
          parent.layerType === LayerType.GROUP ? new Matrix4().copy(parent.matrix).invert() : new Matrix4();
        const bezierFill = getBezierShape(fillUniforms, bezier.isClosed, isGradientFill, maskUniforms, {
          uMatteTransform: offsetMatrix,
        });

        if (!isCompoundBezier) this.setMatteMap(matteProperty, [bezier], bezierFill);

        let focused = true;

        if (focusedNodeIds.length > 0) {
          focused = isFocused || focusedNodeIds.includes(bezierView.shape.nodeId);
        }
        bezierFill.visible = !isCompoundBezier && !bezierView.shape.isHidden && focused;
        bezierFill.toolkitId = bezierView.shape.nodeId;
        bezierFill.drawOrder = dOrder ?? drawOrder;

        const strokeUniforms = getBezierUniforms(bezier, false);

        addOutline(bezierFill, strokeUniforms, false, isGradientStroke);

        attachToolkitListener(bezierFill.toolkitId);

        if (precompId && parentsPrecompDrawOrder.length) {
          bezierFill.drawOrder = getPrecompDrawOrder(bezierFill.drawOrder);
        }
        bezierFill.position.z = bezierFill.drawOrder;
        bezierFill.opacity = 1;
        if (!isCompoundBezier)
          setCanvasMap(precompId ? `${precompId}_${bezierFill.toolkitId}` : bezierFill.toolkitId, bezierFill);

        if (bezierView.modifiers.length > 0) {
          const modifier = bezierView.modifiers[0];

          if (modifier?.type === ShapeType.TRIM) {
            attachToolkitListener(modifier.nodeId);
            setCanvasMap(precompId ? `${precompId}_${modifier.nodeId}` : modifier.nodeId, bezierFill);

            setModifierMap(modifier.nodeId, bezierFill);
            if (precompId) setModifierMap(`${precompId}_${modifier.nodeId}`, bezierFill);
          }
        }

        this.parseBezierStyles(
          bezierView,
          fillUniforms,
          bezierFill,
          accumulatedOpacity,
          false,
          maskUniforms,
          matteProperty.trackMatteParentId,
        );
        parent.add(bezierFill);
      }
    }

    if (isCompoundBezier) {
      const beziers = collectBeziers(bezierViews);
      const fillUniforms = getCompoundBezierUniforms(beziers, true);
      const maskUniforms = this.getMaskUniforms(maskProperty);

      parent.updateMatrix();
      const offsetMatrix =
        parent.layerType === LayerType.GROUP ? new Matrix4().copy(parent.matrix).invert() : new Matrix4();

      const bezierFill = getCompoundBezierShape(fillUniforms, true, isGradientFill, maskUniforms, {
        uMatteTransform: offsetMatrix,
      });

      bezierFill.toolkitId = bezierViews[0]?.shape.nodeId ?? '';
      bezierFill.drawOrder = dOrder ?? drawOrder;

      let focused = true;

      if (focusedNodeIds.length > 0 && bezierViews[0]) {
        focused = isFocused || focusedNodeIds.includes(bezierViews[0].shape.nodeId);
      }
      bezierFill.visible = !bezierViews[0]?.shape.isHidden && focused;

      addOutline(bezierFill, fillUniforms, true, isGradientStroke);
      if (precompId && parentsPrecompDrawOrder.length) {
        bezierFill.drawOrder = getPrecompDrawOrder(bezierFill.drawOrder);
      }
      bezierFill.position.z = bezierFill.drawOrder;
      bezierFill.material.opacity = 0;
      bezierFill.opacity = 1;
      setCanvasMap(bezierFill.toolkitId, bezierFill);
      this.setMatteMap(matteProperty, beziers, bezierFill);
      bezierViews.forEach((bezierView) => {
        this.parseBezierStyles(
          bezierView,
          fillUniforms,
          bezierFill,
          accumulatedOpacity,
          true,
          maskUniforms,
          matteProperty.trackMatteParentId,
        );
        const nodeId = bezierView.shape.nodeId;
        const currentCanvasMap = canvasMap.get(nodeId);

        if (currentCanvasMap) {
          // update parent after drag different across
          currentCanvasMap.parent = parent;
          setCanvasMap(nodeId, currentCanvasMap);
        }
      });
      parent.add(bezierFill);
    }
  }

  public parseBezierStyles(
    bezierView: DrawableBezierView,
    bezierUniforms: BezierUniforms | CompoundBezierUniforms,
    mesh: BezierMesh,
    opacity: number,
    isCompoundBezier: boolean,
    maskUniforms?: MaskUniforms | null,
    matteParentId?: string | null,
  ): void {
    const bezierStyles = bezierView.styles;

    for (const [styleIndex, style] of bezierStyles.entries()) {
      if (style.state.type === ShapeType.STROKE) {
        const strokeLine = parseStroke(
          style.state.animatedProperties,
          bezierUniforms,
          maskUniforms,
          isCompoundBezier,
          opacity,
        );

        strokeLine.toolkitId = style.nodeId;
        strokeLine.name = 'bezierStroke';
        mesh.add(strokeLine);
        if (bezierView.styles.length > 1 && styleIndex === bezierView.styles.length - 1) {
          strokeLine.position.z = 0.05;
        }
        attachToolkitListener(style.nodeId);
        setCanvasMap(style.nodeId, strokeLine);
        if (matteParentId && matteMap.get(matteParentId)) {
          const matteData = matteMap.get(matteParentId);

          matteData?.mattedItems.at(-1)?.mesh.add(strokeLine);
        }
      } else if (style.state.type === ShapeType.FILL) {
        parseFill(mesh, style.state.animatedProperties, opacity);
        attachToolkitListener(style.nodeId);
        setCanvasMap(style.nodeId, mesh);
      } else if (style.state.type === ShapeType.GRADIENT_FILL) {
        const gradientType = style.state.properties.t as GradientFillType;

        parseGradientFill(mesh, style.state.animatedProperties, opacity, gradientType);
        attachToolkitListener(style.nodeId);
        setCanvasMap(style.nodeId, mesh);
      } else if (style.state.type === ShapeType.GRADIENT_STROKE) {
        const gradientType = style.state.properties.t as GradientStrokeType;

        const strokeLine = parseGradientStroke(
          style.state.animatedProperties,
          bezierUniforms,
          isCompoundBezier,
          gradientType,
          opacity,
        );

        strokeLine.toolkitId = style.nodeId;
        strokeLine.name = 'bezierStroke';
        if (bezierView.styles.length > 1 && styleIndex === bezierView.styles.length - 1) {
          strokeLine.position.z = mesh.drawOrder + 1;
        }
        mesh.add(strokeLine);
        attachToolkitListener(style.state.id);
        setCanvasMap(style.state.id, strokeLine);
      }
    }
  }

  public parseBeziers({
    parent,
    isFocused,
    bezierView,
    accumulatedOpacity,
    drawOrder,
    precompId,
    maskProperty,
    matteProperty = DefaultMatteProperty,
    parentsPrecompDrawOrder = [],
    parentsPrecompLayersLength = [],
  }: ParseBeziersProps): void {
    const focusedNodeIds = useCreatorStore.getState().ui.focusedNodeIds;

    const group = new CObject3D();

    group.layers.enable(RaycasterLayers.CObject3D);
    group.toolkitId = bezierView.shape.nodeId;
    group.drawOrder = drawOrder ?? (bezierView.shape.state.properties.do as number);
    group.layerType = LayerType.GROUP;
    group.visible = !bezierView.shape.isHidden;
    group.userData['isLocked'] = bezierView.shape.data.get('isLocked');
    setCanvasMap(precompId ? `${precompId}_${group.toolkitId}` : group.toolkitId, group);

    attachToolkitListener(group.toolkitId);
    parseGroupProperties(group, bezierView.shape.state.animatedProperties);

    const shapes = [];

    for (const subBezierView of bezierView.bezierViews.reverse()) {
      if (subBezierView.isGroup) {
        this.parseBeziers({
          parent: group,
          isFocused: isFocused || focusedNodeIds.includes(bezierView.shape.nodeId),
          bezierView: subBezierView,
          accumulatedOpacity: accumulatedOpacity * group.opacity,
          drawOrder: drawOrder ?? (bezierView.shape.state.properties.do as number),
          precompId,
          maskProperty,
          matteProperty,
          parentsPrecompDrawOrder,
          parentsPrecompLayersLength,
        });
      } else {
        shapes.push(subBezierView);
      }
    }

    this.parseBezierShapes({
      parent: group,
      isFocused: isFocused || focusedNodeIds.includes(bezierView.shape.nodeId),
      bezierViews: shapes,
      accumulatedOpacity: accumulatedOpacity * group.opacity,
      dOrder: drawOrder ?? ((bezierView.shape.state as GroupShapeJSON).properties.do as number),
      precompId,
      maskProperty,
      matteProperty,
      parentsPrecompDrawOrder,
      parentsPrecompLayersLength,
    });
    parent.add(group);
  }

  public parseImageLayer(layer: ImageLayer, parent: CObject3D): void {
    if (!this._scene) return;
    const imageAsset = (this._scene.assets as AssetJSON[] | null)?.find(
      (asset) => asset.properties.ln === (layer as ImageLayer).referenceId,
    );

    if (imageAsset) {
      if (this._cachedAssets.get(layer.referenceId)?.sceneId === this._scene.id) {
        parent.add(this._cachedAssets.get(layer.referenceId).mesh);
      } else {
        this._cachedAssets.delete(layer.referenceId);
        const texture = new TextureLoader().load(imageAsset.properties.ur as string);

        const { h, w } = imageAsset.properties.sz as SizeJSON;
        const geometry = new BoxGeometry(w, h, 0);
        const material = new MeshBasicMaterial({ map: texture, transparent: true });
        const mesh = new Mesh(geometry, material);

        mesh.position.setX(mesh.position.x + w / 2);
        mesh.position.setY(mesh.position.y + h / 2);

        mesh.scale.set(-1, -1, 1);
        mesh.updateMatrix();
        if (imageAsset.properties.do) {
          mesh.position.setZ(imageAsset.properties.do as number);
        }
        parent.add(mesh);
        this._cachedAssets.set(layer.referenceId, {
          referenceId: layer.referenceId,
          mesh,
          sceneId: this._scene.id,
        });
      }
    }
  }

  public parseLayers({
    parent,
    layers,
    isFocused,
    accumulatedOpacity,
    parentPrecomLayer,
    maskProperty: parentMaskProperty,
    matteProperty = DefaultMatteProperty,
    parentsPrecompDrawOrder = [],
    parentsPrecompLayersLength = [],
  }: ParseLayersProps): void {
    if (!this._scene) return;
    const focusedNodeIds = useCreatorStore.getState().ui.focusedNodeIds;

    for (const layer of layers) {
      const group = this.createGroup(layer, parentPrecomLayer);

      const isLayerMatte = Boolean(layer.properties.td);

      const newMatteProperty = {
        isMatte: Boolean(matteProperty.isMatte || isLayerMatte),
        matteMode: matteProperty.matteMode || (layer.properties.tt as number | null),
        trackMatteParentId: matteProperty.trackMatteParentId ?? layer.trackMatteParent,
        layerId: matteProperty.layerId || layer.id,
      };
      const isPrecomp = isPrecompositionLayerJSON(layer);

      const maskProperties = this.parseMasks(group, layer);

      if (layer.layers.length > 0) {
        this.parseLayers({
          parent: group,
          layers: layer.layers,
          isFocused: isFocused || focusedNodeIds.includes(layer.id),
          accumulatedOpacity: isPrecomp ? group.opacity * accumulatedOpacity : accumulatedOpacity,
          parentPrecomLayer,
          maskProperty: parentMaskProperty ?? maskProperties,
          matteProperty: newMatteProperty,
          parentsPrecompDrawOrder,
          parentsPrecompLayersLength,
        });
      }

      if (isShapeLayerJSON(layer)) {
        this.parseShapeLayer({
          parent: group,
          layer,
          isFocused,
          accumulatedOpacity,
          parentPrecomLayer,
          maskProperty: parentMaskProperty ?? maskProperties,
          matteProperty: newMatteProperty,
          parentsPrecompDrawOrder,
          parentsPrecompLayersLength,
        });
      } else if (isTextLayerJSON(layer)) {
        this.parseTextLayer(layer as TextLayerJSON);
      } else if (isPrecompositionLayerJSON(layer)) {
        this.parsePrecompLayers({
          parent: group,
          layer: layer as PrecompositionLayerJSON,
          isFocused: isFocused || focusedNodeIds.includes(layer.id),
          accumulatedOpacity: accumulatedOpacity * group.opacity,
          parentsPrecompDrawOrder,
          parentsPrecompLayersLength,
          maskProperty: parentMaskProperty ?? maskProperties,
          matteProperty: newMatteProperty,
        });
      } else if (isImageLayerJSON(layer)) {
        this.parseImageLayer(layer as ImageLayer, group);
      } else if (isSolidLayerJSON(layer)) {
        parseSolidLayer(
          group,
          (layer as LayerJSON).properties,
          (layer as LayerJSON).animatedProperties,
          parentPrecomLayer?.id,
        );
      }
      parent.add(group);
    }
  }

  public parseMasks(parent: CObject3D, layer: LayerJSON): MaskProperties[] | null {
    if (!hasMasks(layer)) return null;

    const maskProperties: MaskProperties[] = [];

    layer.masks.forEach((mask) => {
      const maskNode = getNodeByIdOnly(mask.id);

      if (!maskNode) return;
      const properties = (maskNode.state as ShapeLayerJSON | null)?.properties;

      if (!properties) return;

      maskProperties.push({
        id: mask.id,
        maskMode: properties.mo as MaskModeType,
        maskPath: (maskNode as PathShape).shape.value,
        opacity:
          ((maskNode.state as ShapeLayerJSON | null)?.animatedProperties.o.value as PercentageJSON | null)?.pct ?? 100,
      });

      const maskUniforms = this.getMaskUniforms(maskProperties);

      if (!maskUniforms?.uMaskBezierCount || !maskUniforms.uMaskBezierPoints) return;
      const bezierUniforms = {
        uBezierCount: maskUniforms.uMaskBezierCount,
        uBezierPoints: maskUniforms.uMaskBezierPoints,
      };
      const maskFill = getBezierShape(bezierUniforms, true, false);

      maskFill.toolkitId = mask.id;
      addOutline(maskFill, bezierUniforms, false, false);
      parent.add(maskFill);

      attachToolkitListener(maskFill.toolkitId);

      setCanvasMap(maskFill.toolkitId, maskFill);
    });

    return maskProperties;
  }

  public parsePrecompLayers({
    parent,
    layer,
    isFocused,
    accumulatedOpacity,
    parentsPrecompDrawOrder = [],
    parentsPrecompLayersLength = [],
    matteProperty = DefaultMatteProperty,
  }: ParsePrecompLayersProps): void {
    if (!this._scene) return;
    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const focusedNodeIds = useCreatorStore.getState().ui.focusedNodeIds;

    parent.precompId = layer.id;
    const precompObj = getAssetByReferenceId(toolkit.scenes[sceneIndex] as Scene, layer.referenceId as string);

    if (precompObj) {
      stateHistory.offTheRecord(() => {
        precompObj.timeline.setCurrentFrame(this._frame - ((layer.properties.tmo as number) || 0));
      });
      setPrecompLayerMap(layer.id, {
        precompId: layer.id,
        referenceId: layer.referenceId as string,
        inPoint: layer.properties.ip as number,
        outPoint: layer.properties.op as number,
        timelineOffset: (layer.properties.tmo as number) || 0,
        precompFrameRate: precompObj.timeline.state.properties.fr as number,
        sceneFrameRate: this._scene.timeline.properties.fr as number,
      });
    }

    const precomLayers = layer.composition.layers;

    if (precomLayers.length > 0) {
      const isLayerMatte = Boolean(layer.properties.td);
      const isMatte = Boolean(matteProperty.isMatte || isLayerMatte);
      const newMatteProperty = {
        isMatte,
        matteMode: matteProperty.matteMode || (layer.properties.tt as number | null),
        trackMatteParentId: matteProperty.trackMatteParentId ?? layer.trackMatteParent,
        layerId: isMatte ? layer.id : '',
      };

      this.parseLayers({
        parent,
        layers: precomLayers,
        isFocused: isFocused || focusedNodeIds.includes(layer.id),
        accumulatedOpacity: parent.opacity * accumulatedOpacity,
        parentPrecomLayer: layer,
        matteProperty: newMatteProperty,
        parentsPrecompDrawOrder: [...parentsPrecompDrawOrder, layer.properties.do as number],
        parentsPrecompLayersLength: [...parentsPrecompLayersLength, layer.composition.allLayers.length],
      });
    }
  }

  public parseShapeLayer({
    parent,
    layer,
    isFocused,
    accumulatedOpacity,
    parentPrecomLayer,
    maskProperty,
    matteProperty,
    parentsPrecompDrawOrder = [],
    parentsPrecompLayersLength = [],
  }: ParseShapeLayerProps): void {
    const layerNode = getNodeByIdOnly(layer.id) as ShapeLayer | undefined;

    if (!layerNode) return;
    const focusedNodeIds = useCreatorStore.getState().ui.focusedNodeIds;
    const beziers = layerNode.toBezier();
    const bezierViews = [];

    const numberOfShapesAndLayers = layer.shapes.length + layer.layers.length;
    const layerDrawOrder = layer.properties.do as number;

    for (const [index, bezierView] of beziers.reverse().entries()) {
      if (bezierView.isGroup) {
        const bezierDrawOrder = parentPrecomLayer ? layerDrawOrder : layerDrawOrder - index / numberOfShapesAndLayers;

        this.parseBeziers({
          parent,
          isFocused: isFocused || focusedNodeIds.includes(layer.id),
          bezierView,
          accumulatedOpacity: parent.opacity * accumulatedOpacity,
          drawOrder: bezierDrawOrder,
          precompId: parentPrecomLayer?.id,
          maskProperty,
          matteProperty,
          parentsPrecompDrawOrder,
          parentsPrecompLayersLength,
        });
      } else {
        bezierViews.push(bezierView);
      }
    }
    this.parseBezierShapes({
      parent,
      isFocused: isFocused || focusedNodeIds.includes(layer.id),
      bezierViews,
      accumulatedOpacity: parent.opacity,
      dOrder: layerDrawOrder,
      precompId: parentPrecomLayer?.id,
      maskProperty,
      matteProperty,
      parentsPrecompDrawOrder,
      parentsPrecompLayersLength,
    });
  }

  public parseTextLayer(layer: TextLayerJSON): void {
    // eslint-disable-next-line no-console
    console.log('layer', layer);
  }

  public parseToolkit(): void {
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
    const setFocusedNodeIds = useCreatorStore.getState().ui.setFocusedNodeIds;

    // get toolkit state json
    this._scene = getToolkitState(toolkit);
    if (selectedPrecompositionId) {
      const assetNode = getNodeByIdOnly(selectedPrecompositionId);

      if (assetNode) {
        this._scene = assetNode.state as SceneJSON;
      }
    }
    if (!this._scene) return;
    this._frame = useCreatorStore.getState().toolkit.currentFrame;

    this.initMaps();
    const focusedNodeIds = this._scene.layers
      .filter((layer) => layerMap.get(layer.id)?.isFocused ?? false)
      .map((layer) => layer.id);

    setFocusedNodeIds(focusedNodeIds, true);
    this.parseLayers({ parent: this._container, layers: this._scene.layers, accumulatedOpacity: 1, isFocused: false });
    this._container.updateMatrixWorld();
    matteUpdate(true);
  }

  public setMatteMap(matteProperty: MatteProperties, beziers: CubicBezierShape[], bezierFill: BezierMesh): void {
    if (matteProperty.isMatte) {
      const matteData = matteMap.get(matteProperty.layerId);

      const matteBezier = beziers[0];

      if (!matteBezier) return;

      if (matteData) {
        matteMap.set(matteProperty.layerId, {
          ...matteData,
          mattes: [
            ...matteData.mattes,
            {
              matteBezier,
              matteMesh: bezierFill,
            },
          ],
        });
      } else {
        matteMap.set(matteProperty.layerId, {
          mattes: [
            {
              matteBezier,
              matteMesh: bezierFill,
            },
          ],
          mattedItems: [],
        });
      }
    }
    if (matteProperty.matteMode && matteProperty.trackMatteParentId) {
      const matteData = matteMap.get(matteProperty.trackMatteParentId);

      if (matteData) {
        matteMap.set(matteProperty.trackMatteParentId, {
          ...matteData,
          mattedItems: [
            ...matteData.mattedItems,
            {
              beziers,
              mesh: bezierFill,
              matteMode: matteProperty.matteMode,
            },
          ],
        });
      } else {
        matteMap.set(matteProperty.trackMatteParentId, {
          mattes: [],
          mattedItems: [
            {
              beziers,
              mesh: bezierFill,
              matteMode: matteProperty.matteMode,
            },
          ],
        });
      }
    }
  }
}
