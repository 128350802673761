/**
 * Copyright 2024 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import type { RectangleShape, EllipseShape, StarShape } from '@lottiefiles/toolkit-js';
import { PathShape, CubicBezierShape } from '@lottiefiles/toolkit-js';
import React, { Fragment, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { Close, ThumbnailPath } from '~/assets/icons';
import { Checkbox } from '~/components/Elements/Checkbox';
import { Divider } from '~/components/Elements/Divider';
import { Modal } from '~/components/Elements/Modal';
import { EmitterEvent, emitter } from '~/lib/emitter';
import { stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { LocalStorageKey } from '~/store/constant';

const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

export interface ConvertToPathConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConvertToPathConfirmationModal: React.FC<ConvertToPathConfirmationModalProps> = (
  props: ConvertToPathConfirmationModalProps,
) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = (): void => {
    setIsChecked(!isChecked);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center  text-center">
            <Dialog.Panel className="h-[240px] w-[400px] overflow-hidden rounded-md bg-gray-800 align-middle shadow-sm transition-all">
              <Dialog.Title className=" relative mt-5 mb-4 px-6 text-left text-xl font-bold text-white">
                Convert to path
                <button className="absolute right-4">
                  <Close
                    className="h-6 w-6 cursor-pointer fill-current text-gray-400 hover:opacity-50"
                    onClick={props.onClose}
                  />
                </button>
              </Dialog.Title>
              <Divider />

              <div className=" mt-5 text-left text-xs text-gray-400">
                <div className=" px-6 ">
                  To edit the path of a shape, this shape needs to be converted to a custom path. You will lose
                  shape-specific properties.
                </div>
                <div className="my-5  px-6 ">
                  <Checkbox isChecked={isChecked} onChange={() => handleOnChange()}>
                    Don’t show this message again
                  </Checkbox>
                </div>
                <Divider />
                <div className="flex justify-end px-5  py-3">
                  <div className="h-8 cursor-pointer py-2 px-4 text-white" onClick={props.onClose}>
                    Cancel
                  </div>
                  <div
                    className="h-8 cursor-pointer rounded bg-[#019D91] px-4 py-2 text-white"
                    onClick={() => {
                      if (isChecked) window.localStorage.setItem(LocalStorageKey.ConvertToPathConfirmDismiss, 'true');
                      props.onConfirm();
                      props.onClose();
                    }}
                  >
                    Convert to Path
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Modal>
  );
};

export const ShapeConvertToPathButton: React.FC = () => {
  const [selectedNodesInfo, getNodeByIdOnly] = useCreatorStore(
    (state) => [state.ui.selectedNodesInfo, state.toolkit.getNodeByIdOnly],
    shallow,
  );

  const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId ?? '') as RectangleShape | EllipseShape | StarShape;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const dismissConvertToPath = window.localStorage.getItem(LocalStorageKey.ConvertToPathConfirmDismiss) || false;

  const convertToBezier = (): void => {
    const parentNode = node.parent;

    if (parentNode) {
      stateHistory.beginAction();
      const beziers = node.toBezier();
      const bezierShape = new CubicBezierShape()
        .setVertices(beziers.vertices)
        .setInTangents(beziers.inTangents)
        .setOutTangents(beziers.outTangents)
        .setIsClosed(beziers.isClosed);

      const pathShape = new PathShape(parentNode);

      pathShape.shape.setValue(bezierShape);

      pathShape.setName(node.name);
      pathShape.moveToFront(false);

      node.removeFromGraph();

      stateHistory.endAction();

      emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
      emitter.emit(EmitterEvent.CANVAS_REDRAW);

      addToSelectedNodes([pathShape.nodeId], true);
    }
  };

  const onEditClick = (): void => {
    if (dismissConvertToPath) convertToBezier();
    else setShowConfirmationModal(true);
  };

  return (
    <>
      <ConvertToPathConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => convertToBezier()}
      />
      <div
        onClick={onEditClick}
        className="mt-2 flex cursor-pointer justify-center rounded border border-gray-600 py-1 hover:border-gray-400"
      >
        <ThumbnailPath className="h-5 w-5 " />

        <div className="py-[2px] text-xs">Convert to Path</div>
      </div>
    </>
  );
};
