/**
 * Copyright 2024 Design Barn Inc.
 */

import type { UseFloatingProps } from '@floating-ui/react-dom-interactions';
import { autoPlacement, offset, useFloating } from '@floating-ui/react-dom-interactions';
import React from 'react';
import Draggable from 'react-draggable';

import { FloatingWindow } from '../../Window';

import type { RGBA } from '@/ColorPicker/components/ColorPicker/ColorPickerPopover';
import { ColorPickerPopover } from '@/ColorPicker/components/ColorPicker/ColorPickerPopover';
import { hexToRgb } from '@/ColorPicker/helpers';
import rgbaToHex from '@/ColorPicker/helpers/rgbaToHex';
import { useClickOutside } from '~/hooks';

const options: Partial<UseFloatingProps> = {
  middleware: [autoPlacement(), offset(32)],
};

interface Props {
  color: string;
  onStopColor: (color: string) => void;
}

export const ColorButton: React.FC<Props> = ({ color, onStopColor }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [opacity, setOpacity] = React.useState(1);
  const { floating, reference, refs, x, y } = useFloating(options);

  useClickOutside(refs.floating, () => setOpen(false));

  const { blue, green, red } = hexToRgb(color);
  const solidColor = {
    red,
    green,
    blue,
    alpha: opacity,
  };

  return (
    <>
      <div
        className={'rounded p-1 hover:cursor-pointer hover:bg-gray-700'}
        ref={reference}
        onClick={() => setOpen(!open)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00002 1.33105L8.3322 1.62633C8.98587 2.20737 9.60703 2.71048 10.1873 3.18047C10.2636 3.24226 10.3392 3.30349 10.414 3.36424C11.0529 3.88265 11.6458 4.37225 12.1465 4.8765C13.1677 5.90493 13.8334 7.02455 13.8334 8.6667C13.8334 11.8884 11.2217 14.5 8.00002 14.5C4.77836 14.5 2.16669 11.8884 2.16669 8.6667C2.16669 7.02455 2.83238 5.90493 3.85355 4.8765C4.35423 4.37225 4.94718 3.88265 5.58599 3.36424C5.66086 3.30349 5.73644 3.24227 5.81274 3.18047C6.393 2.71049 7.01416 2.20737 7.66784 1.62633L8.00002 1.33105ZM4.56316 5.5811C3.66767 6.48295 3.16669 7.36333 3.16669 8.6667C3.16669 11.3361 5.33064 13.5 8.00002 13.5C10.6694 13.5 12.8334 11.3361 12.8334 8.6667C12.8334 7.36333 12.3324 6.48295 11.4369 5.5811C10.9792 5.12019 10.4263 4.66208 9.78391 4.14073C9.70984 4.08062 9.63467 4.01974 9.55846 3.95802C9.07546 3.56684 8.55066 3.14181 8.00002 2.66508C7.44939 3.1418 6.92458 3.56684 6.44159 3.95801C6.36537 4.01974 6.2902 4.08062 6.21613 4.14073C5.57369 4.66208 5.02081 5.12019 4.56316 5.5811Z"
            fill="#A1ADB7"
          />
          <path
            d="M4.66669 8.6667C4.66669 10.5076 6.15907 12 8.00002 12C9.84097 12 11.3334 10.5076 11.3334 8.6667H4.66669Z"
            fill="#A1ADB7"
          />
        </svg>
      </div>
      {open && (
        <FloatingWindow>
          {({ windowProps }) => (
            <Draggable nodeRef={refs.floating} handle="#color-picker-header">
              <ColorPickerPopover
                color={solidColor}
                gradient={null}
                onChange={(col: RGBA) => {
                  const op = Math.round((col.alpha ?? 1) * 255);

                  setOpacity(col.alpha ?? 1);
                  onStopColor(rgbaToHex([col.red, col.green, col.blue, op]));
                }}
                onEndChange={(col: RGBA) => {
                  const op = Math.round((col.alpha ?? 1) * 255);

                  setOpacity(col.alpha ?? 1);
                  onStopColor(rgbaToHex([col.red, col.green, col.blue, op]));
                }}
                onClose={() => {
                  setOpen(false);
                }}
                enableColorModeChange={false}
                windowProps={{
                  ...windowProps,
                  style: { top: y ?? '', left: x ?? '', width: 250, ...windowProps.style },
                  nodeRef: floating,
                }}
              />
            </Draggable>
          )}
        </FloatingWindow>
      )}
    </>
  );
};
