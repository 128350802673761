/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { RemixDisabled } from './RemixDisabled';
import { RemixEnabled } from './RemixEnabled';

import RemixLoadingSpinnerAnimation from '~/assets/animations/remix-loading-spinner.json';
import { useProjectAPI } from '~/providers/api/project';
import { useCreatorStore } from '~/store';

export const RemixContent: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [shareCode, setShareCode] = useState<string | null>(null);
  const [setAlert, token] = useCreatorStore((state) => [state.ui.setAlert, state.user.token], shallow);
  const { getFileShareCode } = useProjectAPI(token);

  useEffect(() => {
    const checkRemixEnabled = async (): Promise<void> => {
      setShowLoading(true);
      try {
        const code = await getFileShareCode();

        setShareCode(code);
      } catch {
        setAlert({
          text: 'Something went wrong. Please try again later.',
          alertColor: '#D92600',
        });
      }
      setShowLoading(false);
    };

    checkRemixEnabled();
  }, [getFileShareCode, setAlert]);

  const onDisableHandler = useCallback(() => {
    setShareCode(null);
  }, []);

  const onEnableHandler = useCallback((code: string) => {
    setShareCode(code);
  }, []);

  if (showLoading) {
    return (
      <div className="flex h-[350px] items-center justify-center">
        <div className="h-[80px] w-[80px]">
          <dotlottie-player
            renderer="svg"
            autoplay
            loop
            src={JSON.stringify(RemixLoadingSpinnerAnimation)}
          ></dotlottie-player>
        </div>
      </div>
    );
  }

  if (shareCode) {
    return <RemixEnabled sharedCode={shareCode} onDisabled={onDisableHandler} />;
  }

  return <RemixDisabled onEnabled={onEnableHandler} />;
};
