/**
 * Copyright 2022 Design Barn Inc.
 */

import { isEqual } from 'lodash-es';
import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { getDualMixedValue, toggleKeyframe } from './helpers';

import { ScaleX, ScaleY } from '~/assets/icons';
import { AspectRatioToggle } from '~/components/Elements/Button/AspectRatioToggle';
import { Interactive } from '~/components/Elements/Button/Interactive';
import { DualNumberInput } from '~/components/Elements/Input';
import type { NumberInputOption } from '~/components/Elements/Input';
import type { NumberInputRatioResult } from '~/components/Elements/Input/useDualNumberInputRatio';
import { useDualNumberInputRatio } from '~/components/Elements/Input/useDualNumberInputRatio';
import { MAX_TRANSFORM_SCALE, MIN_TRANSFORM_SCALE } from '~/data/range';
import { UITransformType, updateGroupFromUI } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType } from '~/lib/toolkit';
import { HotKey } from '~/providers/HotkeyListener';
import { useCreatorStore } from '~/store';
import { formatNumber } from '~/utils';

const scaleOption: { x: NumberInputOption; y: NumberInputOption } = {
  x: {
    name: 'scaleX',
    label: <ScaleX className="h-4 w-4" />,
    append: '%',
  },
  y: {
    name: 'scaleY',
    label: <ScaleY className="h-4 w-4" />,
    append: '%',
  },
};

const handleKeyframeClick = (): void => {
  toggleKeyframe(AnimatedType.SCALE);
};

export const TransformScaleField: React.FC = () => {
  const [
    scaleX,
    scaleY,
    currentKeyframe,
    scaleRatioLocked,
    setScaleRatioLocked,
    selectedNodesInfo,
    currentTransforms,
    getNodeByIdOnly,
    scaleAnimated,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentTransform.scale[0],
      state.toolkit.currentTransform.scale[1],
      state.toolkit.currentTransform.scaleCurrentKeyframe,
      state.ui.scaleRatioLocked,
      state.ui.setScaleRatioLocked,
      state.ui.selectedNodesInfo,
      state.toolkit.currentTransforms,
      state.toolkit.getNodeByIdOnly,
      state.toolkit.currentTransform.scaleIsAnimated,
    ],
    shallow,
  );

  const [keyframeActive, setKeyframeActive] = useState(false);
  const [scaleIsAnimated, setScaleIsAnimated] = useState(false);
  const previousTransforms = useRef(currentTransforms);

  const multiSelect = selectedNodesInfo.length > 1;

  const handleOnChangeResult = useCallback((result: NumberInputRatioResult) => {
    updateGroupFromUI(UITransformType.Scale, result);

    emitter.emit(EmitterEvent.ANIMATED_SCALE_UPDATED);
  }, []);

  // NOTE(miljau):
  // The assumption being made here is that scale applies only to
  // GroupShapes and that the TransformScaleField won't apply to
  // any other nodes. In such a case the nodeId being used here
  // should line up with the toolkitId used by the canvas code
  const { handleInputChange, handleOnClickAspectRatio, isLocked } = useDualNumberInputRatio({
    left: scaleX,
    leftName: scaleOption.x.name,
    right: scaleY,
    onChange: handleOnChangeResult,
    ratioLocked: scaleRatioLocked,
  });

  const handleOnClickAspectRatioCurried = useCallback(() => {
    handleOnClickAspectRatio();
    setScaleRatioLocked(!scaleRatioLocked);

    const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId as string);

    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.TOOLKIT_NODE_SCALE_UPDATED,
      // only save scaleRatioLock data if we are not in multiSelect mode
      data: multiSelect
        ? null
        : {
            node,
            scaleRatioLock: !scaleRatioLocked,
          },
    });
  }, [
    getNodeByIdOnly,
    handleOnClickAspectRatio,
    multiSelect,
    scaleRatioLocked,
    selectedNodesInfo,
    setScaleRatioLocked,
  ]);

  const leftValue = formatNumber(scaleX);
  const rightValue = formatNumber(scaleY);

  useEffect(() => {
    if (!isEqual(previousTransforms.current, currentTransforms) || multiSelect) {
      previousTransforms.current = currentTransforms;
      const isActive = currentTransforms.every((transform) => transform.scaleCurrentKeyframe);

      setKeyframeActive(isActive);

      const isAnimated = currentTransforms.every((transform) => transform.scaleIsAnimated);

      setScaleIsAnimated(isAnimated);
    }

    if (!multiSelect) {
      setKeyframeActive(currentKeyframe !== '');
      setScaleIsAnimated(scaleAnimated);
    }
  }, [currentKeyframe, multiSelect, selectedNodesInfo, currentTransforms, scaleAnimated]);

  const mixedValue = useMemo(() => {
    return getDualMixedValue(multiSelect, currentTransforms, AnimatedType.SCALE);
  }, [currentTransforms, multiSelect]);

  return (
    <div className="mt-2 flex w-full">
      <DualNumberInput
        onChange={handleInputChange}
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={keyframeActive}
        left={leftValue}
        right={rightValue}
        leftOption={scaleOption.x}
        rightOption={scaleOption.y}
        showKeyframe
        leftMin={MIN_TRANSFORM_SCALE}
        leftMax={MAX_TRANSFORM_SCALE}
        rightMin={MIN_TRANSFORM_SCALE}
        rightMax={MAX_TRANSFORM_SCALE}
        tooltip={{ left: 'ScaleX', right: 'ScaleY' }}
        leftMessage={mixedValue.left ?? null}
        rightMessage={mixedValue.right ?? null}
        isChannelAnimated={scaleIsAnimated}
        keyframeShortcut={HotKey.TOGGLE_SCALE_KEYFRAME.toUpperCase()}
      />
      <Interactive>
        <AspectRatioToggle isLocked={isLocked} onClick={handleOnClickAspectRatioCurried} />
      </Interactive>
    </div>
  );
};
