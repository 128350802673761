/**
 * Copyright 2023 Design Barn Inc.
 */

import type { AnimatedProperty, VectorJSON } from '@lottiefiles/toolkit-js/';

import { toolkit } from './toolkit';

import { EasingType } from '~/data/eventStore';
import {
  LINEAR_IN_LINEAR_OUT,
  LINEAR_IN_LINEAR_OUT_ALT,
  SMOOTH_IN_LINEAR_OUT,
  LINEAR_IN_SMOOTH_OUT,
} from '~/data/range';
import { useCreatorStore } from '~/store';
import type { KeyframeEasing } from '~/store/timelineSlice';

export const getIsOutLinear = (outFrame: VectorJSON | null, inFrame: VectorJSON | null): boolean | null => {
  return (
    (outFrame?.x === LINEAR_IN_LINEAR_OUT.out.x && outFrame.y === LINEAR_IN_LINEAR_OUT.out.y) ||
    (outFrame?.x === LINEAR_IN_LINEAR_OUT_ALT.out.x && outFrame.y === LINEAR_IN_LINEAR_OUT_ALT.out.y) ||
    (inFrame &&
      outFrame?.x === SMOOTH_IN_LINEAR_OUT.out.x &&
      outFrame.y === SMOOTH_IN_LINEAR_OUT.out.y &&
      inFrame.x === SMOOTH_IN_LINEAR_OUT.in.x &&
      inFrame.y === SMOOTH_IN_LINEAR_OUT.in.y)
  );
};

export const getIsInLinear = (inFrame: VectorJSON | null): boolean | null => {
  const isInLinear =
    (inFrame?.x === LINEAR_IN_LINEAR_OUT.in.x && inFrame.y === LINEAR_IN_LINEAR_OUT.in.y) ||
    (inFrame?.x === LINEAR_IN_LINEAR_OUT_ALT.in.x && inFrame.y === LINEAR_IN_LINEAR_OUT_ALT.in.y) ||
    (inFrame?.x === LINEAR_IN_SMOOTH_OUT.in.x && inFrame.y === LINEAR_IN_SMOOTH_OUT.in.y);

  return isInLinear;
};

export const updateSelectedKeyframeEasings = (): void => {
  const selectedKeyframes = useCreatorStore.getState().timeline.selectedKeyframeIds;
  const setSelectedKeyframeEasing = useCreatorStore.getState().timeline.setSelectedKeyframeEasing;

  if (selectedKeyframes.length === 0) {
    setSelectedKeyframeEasing([]);

    return;
  }

  const frameObjs = selectedKeyframes.map((keyframeId) => toolkit.getKeyframeById(keyframeId as string));

  if (frameObjs.length === 0) return;

  frameObjs.forEach((frameObj, index) => {
    const selectedKeyframeIndex = frameObj?.parentProperty?.keyFrames.findIndex(
      (frame) => frame.frameId === frameObj.frameId,
    ) as number;

    if (selectedKeyframeIndex >= 0) {
      const foundFrame = (frameObj?.parentProperty as AnimatedProperty).state.keyFrames[selectedKeyframeIndex];

      if (foundFrame) {
        const inFrame = foundFrame.inTangent;
        const outFrame = foundFrame.outTangent;

        const isOutLinear = getIsOutLinear(outFrame, inFrame);
        const isInLinear = selectedKeyframeIndex && selectedKeyframeIndex > 0 ? getIsInLinear(inFrame) : null;

        const easing =
          selectedKeyframeIndex > 0
            ? ({
                inTangent: isInLinear ? EasingType.Linear : EasingType.Smooth,
                outTangent: isOutLinear ? EasingType.Linear : EasingType.Smooth,
              } as KeyframeEasing)
            : ({
                inTangent: null,
                outTangent: isOutLinear ? EasingType.Linear : EasingType.Smooth,
              } as KeyframeEasing);

        setSelectedKeyframeEasing([easing], index === 0);

        return;
      }

      setSelectedKeyframeEasing([]);
    }
  });
};
