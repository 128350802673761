/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';

import { Library } from './Library';

const TopSidebar: React.FC = () => {
  return (
    <div>
      <div className="ml-[8px]">
        <Library />
      </div>
    </div>
  );
};

interface SidebarProps {
  isHidden: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ isHidden }) => {
  return (
    <div className={`z-[3] h-full dark:bg-gray-800 ${isHidden ? 'hidden' : ''}`}>
      <div className="relative box-border flex h-full w-[40px] flex-col">
        <TopSidebar />
      </div>
    </div>
  );
};
