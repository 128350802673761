/**
 * Copyright 2024 Design Barn Inc.
 */

import type { LayerJSON, PropertyType } from '@lottiefiles/toolkit-js';
import React from 'react';

import { KeyframeAnimatedRow } from '../TimelineKeyframePanel/TimelineContent/KeyframeAnimated';

interface Props {
  animatedId: string;
  layer: LayerJSON;
  type: string;
}

export const TimelineItemAnimatedKeyframePanel: React.FC<Props> = ({ animatedId, layer, type }) => {
  return (
    <div id={`animated-${animatedId}`} className="bg-gray-800">
      <div className="mt-[1px] mr-2 h-6 ">
        <KeyframeAnimatedRow
          animatedProp={layer.animatedProperties[type as PropertyType]}
          type={type}
          layerId={layer.id}
        />
      </div>
    </div>
  );
};

export default TimelineItemAnimatedKeyframePanel;
