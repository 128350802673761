/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

export const ColorPickerStroke: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        fill="white"
      />
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        fill="url(#paint0_linear_1270_280796)"
      />
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        fill="url(#paint1_linear_1270_280796)"
      />
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        fill="url(#paint2_linear_1270_280796)"
      />
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        fill="url(#paint3_linear_1270_280796)"
      />
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        fill="url(#paint4_linear_1270_280796)"
      />
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        fill="url(#paint5_linear_1270_280796)"
      />
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        fill="url(#paint6_linear_1270_280796)"
      />
      <path
        d="M9.31445 13.75C6.6911 13.75 4.56445 11.6234 4.56445 9C4.56445 6.37665 6.6911 4.25 9.31445 4.25C11.9378 4.25 14.0645 6.37665 14.0645 9C14.0645 11.6234 11.9378 13.75 9.31445 13.75ZM9.31445 0.75C4.7581 0.75 1.06445 4.44365 1.06445 9C1.06445 13.5563 4.7581 17.25 9.31445 17.25C13.8708 17.25 17.5645 13.5563 17.5645 9C17.5645 4.44365 13.8708 0.75 9.31445 0.75Z"
        stroke="#3D4852"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1270_280796"
          x1="8.62891"
          y1="11.5"
          x2="10.6289"
          y2="1.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC3527" stopOpacity="0" />
          <stop offset="1" stopColor="#EC3527" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1270_280796"
          x1="9.62891"
          y1="10.5"
          x2="6.12891"
          y2="1.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5810" stopOpacity="0" />
          <stop offset="1" stopColor="#FF5810" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1270_280796"
          x1="5.62891"
          y1="10"
          x2="17.6289"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C527EC" stopOpacity="0" />
          <stop offset="1" stopColor="#C527EC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1270_280796"
          x1="7.12891"
          y1="8"
          x2="13.1289"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4327EC" stopOpacity="0" />
          <stop offset="1" stopColor="#4327EC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1270_280796"
          x1="7.62891"
          y1="9"
          x2="5.62891"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#10FFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#10FFFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1270_280796"
          x1="10.1289"
          y1="9"
          x2="2.12891"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#10FF45" stopOpacity="0" />
          <stop offset="1" stopColor="#10FF45" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1270_280796"
          x1="11.1289"
          y1="10"
          x2="1.62891"
          y2="1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE710" stopOpacity="0" />
          <stop offset="1" stopColor="#FFE710" />
        </linearGradient>
      </defs>
    </svg>
  );
});

export const ColorPickerFill: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none" {...props}>
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="white" />
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="url(#paint0_linear_1270_280764)" />
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="url(#paint1_linear_1270_280764)" />
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="url(#paint2_linear_1270_280764)" />
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="url(#paint3_linear_1270_280764)" />
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="url(#paint4_linear_1270_280764)" />
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="url(#paint5_linear_1270_280764)" />
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill="url(#paint6_linear_1270_280764)" />
      <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" stroke="#3D4852" strokeWidth="0.5" />
      <defs>
        <linearGradient
          id="paint0_linear_1270_280764"
          x1="8.31445"
          y1="11.5"
          x2="10.3145"
          y2="1.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC3527" stopOpacity="0" />
          <stop offset="1" stopColor="#EC3527" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1270_280764"
          x1="9.31445"
          y1="10.5"
          x2="5.81445"
          y2="1.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5810" stopOpacity="0" />
          <stop offset="1" stopColor="#FF5810" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1270_280764"
          x1="5.31445"
          y1="10"
          x2="17.3145"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C527EC" stopOpacity="0" />
          <stop offset="1" stopColor="#C527EC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1270_280764"
          x1="6.81445"
          y1="8"
          x2="12.8145"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4327EC" stopOpacity="0" />
          <stop offset="1" stopColor="#4327EC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1270_280764"
          x1="7.31445"
          y1="9"
          x2="5.31445"
          y2="14.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#10FFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#10FFFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1270_280764"
          x1="9.81445"
          y1="9"
          x2="1.81445"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#10FF45" stopOpacity="0" />
          <stop offset="1" stopColor="#10FF45" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1270_280764"
          x1="10.8145"
          y1="10"
          x2="1.31445"
          y2="1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE710" stopOpacity="0" />
          <stop offset="1" stopColor="#FFE710" />
        </linearGradient>
      </defs>
    </svg>
  );
});

export const ColorPickerTransparent: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_2428_17478" maskUnits="userSpaceOnUse" x="0.75" y="0.75" width="16" height="16">
        <rect width="16" height="16" rx="8" fill="white" />
      </mask>
      <g className="translate-x-[0.5px] translate-y-[-0.5px] scale-[0.9]" mask="url(#mask0_2428_17478)">
        <rect x="1.50488" y="1.63281" width="1.77305" height="1.76959" fill="#D9E0E6" />
        <rect x="7.11353" y="1.63281" width="1.77305" height="1.76959" fill="#D9E0E6" />
        <rect x="12.7222" y="1.63281" width="1.77305" height="1.76959" fill="#D9E0E6" />
        <rect x="1.50488" y="7.11523" width="1.77305" height="1.76959" fill="#D9E0E6" />
        <rect x="7.11353" y="7.11523" width="1.77305" height="1.76959" fill="#D9E0E6" />
        <rect x="12.7222" y="7.11523" width="1.77305" height="1.76959" fill="#D9E0E6" />
        <rect x="1.50488" y="12.5977" width="1.77305" height="1.76959" fill="#D9E0E6" />
        <rect x="7.11353" y="12.5977" width="1.77305" height="1.76959" fill="#D9E0E6" />
        <rect x="12.7222" y="12.5977" width="1.77305" height="1.76959" fill="#D9E0E6" />
      </g>
    </svg>
  );
});
