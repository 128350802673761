/**
 * Copyright 2024 Design Barn Inc.
 */

import { SphereGeometry, Mesh, MeshBasicMaterial } from 'three';

import { Elements } from '..';

import { materialOptions, RENDER_ORDERS } from '.';
import { BLACK_COLOR, CObject3D, WHITE_COLOR } from '~/features/canvas';

const reusableMaterials = {
  hoverCircle: new MeshBasicMaterial({ color: WHITE_COLOR, ...materialOptions }),
  dropShadow: new MeshBasicMaterial({ color: BLACK_COLOR, opacity: 0.04, ...materialOptions }),
  dropShadowOuter: new MeshBasicMaterial({
    color: BLACK_COLOR,
    opacity: 0.03,
    ...materialOptions,
  }),
};

export const createHoverCircle = (): CObject3D => {
  const hoverCircle = new CObject3D();
  const geometry = new SphereGeometry(3, 10, 10);
  const mesh = new Mesh(geometry, reusableMaterials.hoverCircle);

  const dropShadow = new Mesh(new SphereGeometry(4, 10, 10), reusableMaterials.dropShadow);
  const dropShadowOuter = new Mesh(new SphereGeometry(4.6, 10, 10), reusableMaterials.dropShadowOuter);

  mesh.renderOrder = RENDER_ORDERS.HOVER_CIRCLE;
  hoverCircle.renderOrder = RENDER_ORDERS.HOVER_CIRCLE;

  hoverCircle.add(mesh);
  hoverCircle.add(dropShadow);
  hoverCircle.add(dropShadowOuter);

  hoverCircle.userData['type'] = Elements.HOVER_CIRCLE;
  hoverCircle.visible = false;

  return hoverCircle;
};
