/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable id-length */
/* eslint-disable no-plusplus */

import { nanoid } from 'nanoid';

import { ViewportConfig } from './config';
import type { OverlayCanvas } from './overlayCanvas';
import { getOriginOffset } from './util';
import type { Viewport } from './viewport';

import { useCreatorStore } from '~/store';
import type { GuideType } from '~/store/canvasSlice';

const defaultConfig = {
  gap: 20,
  marks: 5,
  zoom: 1,
} as const;

const configs = [
  {
    gap: 100,
    marks: 5,
    zoom: 0.24,
  },
  {
    gap: 40,
    marks: 5,
    zoom: 0.5,
  },
  defaultConfig,
  {
    gap: 10,
    marks: 5,
    zoom: 2,
  },
  {
    gap: 5,
    marks: 4,
    zoom: 5,
  },
  {
    gap: 2,
    marks: 5,
    zoom: 8,
  },
  {
    gap: 1,
    marks: 5,
    zoom: 25,
  },
  {
    gap: 1,
    marks: 1,
    zoom: 100,
  },
];

interface RulerArgs {
  direction: 'horizontal' | 'vertical';
  element: HTMLCanvasElement;
  overlayCanvas: OverlayCanvas;
  viewport: Viewport;
}

export const directionToCursorMap = {
  vertical: 'ew-resize',
  horizontal: 'ns-resize',
} as const;

export class Ruler {
  private _config: (typeof configs)[number] = defaultConfig;

  private readonly _ctx: CanvasRenderingContext2D;

  private readonly _direction: RulerArgs['direction'];

  private readonly _element: HTMLCanvasElement;

  private readonly _longMarkSize: number;

  private readonly _overlayCanvas: OverlayCanvas;

  private _previousZoom: number | null = null;

  private _scaledGap: number = 1;

  private readonly _shortMarkSize: number;

  private readonly _viewport: Viewport;

  public constructor({ direction, element, overlayCanvas, viewport }: RulerArgs) {
    this._element = element;
    this._direction = direction;
    this._viewport = viewport;
    this._overlayCanvas = overlayCanvas;

    this._longMarkSize = 8 * this._viewport.pixelRatio;
    this._shortMarkSize = 4 * this._viewport.pixelRatio;

    const ctx = this._element.getContext('2d');

    if (!ctx) {
      throw new Error('Failed to get 2d context');
    }

    this._ctx = ctx;

    this._initListeners();
  }

  public render(): void {
    this._ctx.resetTransform();
    this._ctx.clearRect(0, 0, this._element.width, this._element.height);

    if (this._previousZoom !== this._viewport.camera.zoom) {
      this._config = this._getRulerConfig(this._viewport.camera.zoom);
      this._scaledGap = this._config.gap * this._viewport.camera.zoom;
    }

    this._previousZoom = this._viewport.camera.zoom;

    this._ctx.font = `${10 * this._viewport.pixelRatio}px Karla`;
    this._ctx.fillStyle = '#A1ADB7';
    this._ctx.strokeStyle = '#4C5863';
    this._ctx.textAlign = 'center';

    const originOffset = getOriginOffset(this._viewport.camera, 1);

    const enabled = useCreatorStore.getState().canvas.guides.enabled;

    if (this._direction === 'horizontal') {
      this._ctx.beginPath();
      this._renderPositiveAxis(
        originOffset.x,
        this._element.width / this._viewport.pixelRatio,
        this._renderHorizontalRulerPoints.bind(this),
      );
      this._renderNegativeAxis(originOffset.x, 0, this._renderHorizontalRulerPoints.bind(this));

      this._ctx.stroke();

      if (enabled) this._renderVerticalGuideMarks(originOffset.x * this._viewport.pixelRatio);
    }

    if (this._direction === 'vertical') {
      this._ctx.beginPath();
      this._renderPositiveAxis(
        originOffset.y,
        this._element.height / this._viewport.pixelRatio,
        this._renderVerticalRulerPoints.bind(this),
      );
      this._renderNegativeAxis(originOffset.y, 0, this._renderVerticalRulerPoints.bind(this));

      this._ctx.stroke();

      if (enabled) this._renderHorizontalGuideMarks(originOffset.y * this._viewport.pixelRatio);
    }
  }

  public resize(width: number, height: number): void {
    if (this._direction === 'horizontal') {
      this._element.width = width * this._viewport.pixelRatio;
      this._element.height = ViewportConfig.RulerSize * this._viewport.pixelRatio;
      this._element.style.width = `${width}px`;
      this._element.style.height = `${ViewportConfig.RulerSize}px`;
    }

    if (this._direction === 'vertical') {
      this._element.width = ViewportConfig.RulerSize * this._viewport.pixelRatio;
      this._element.height = height * this._viewport.pixelRatio;
      this._element.style.width = `${ViewportConfig.RulerSize}px`;
      this._element.style.height = `${height}px`;
    }

    this.render();
  }

  private _getRulerConfig(scale: number): (typeof configs)[number] {
    let i = 0;

    while (i < configs.length) {
      const config = configs[i];

      if (config && config.zoom <= scale) {
        i++;
      } else {
        break;
      }
    }

    return configs[i] || defaultConfig;
  }

  private _initListeners(): void {
    this._element.addEventListener('pointerdown', this._onPointerDown.bind(this));
  }

  private _isLongMark(j: number, gap: number, marks: number): boolean {
    return j % (gap * marks) === 0;
  }

  private _onPointerDown(ev: PointerEvent): void {
    if (ev.button === 0 && useCreatorStore.getState().canvas.guides.enabled) {
      this._overlayCanvas.setSelectedGuide({
        id: nanoid(),
        type: this._direction as GuideType,
      });
      this._overlayCanvas.isDraggingGuide = true;
    }
  }

  private _renderHorizontalGuideMarks(startPos: number): void {
    const guides = useCreatorStore.getState().canvas.guides;

    const guideMarkColor = `rgba(${guides.color.r}, ${guides.color.g}, ${guides.color.b}, 0.5)`;
    const selectedGuideMarkColor = `rgba(${guides.color.r}, ${guides.color.g}, ${guides.color.b}, 1)`;

    Object.entries(guides.horizontal).forEach(([key, guide]) => {
      const value = guide * this._viewport.canvasHelper.zoomWithPixelRatio;

      this._ctx.lineWidth = 2;
      this._ctx.beginPath();
      this._ctx.moveTo(0, startPos + value);
      this._ctx.lineTo(this._element.width, startPos + value);
      this._ctx.strokeStyle = this._overlayCanvas.selectedGuide?.id === key ? selectedGuideMarkColor : guideMarkColor;
      this._ctx.stroke();
    });
  }

  private _renderHorizontalRulerPoints(i: number, j: number, k: number, gap: number, marks: number): void {
    const isLongMark = this._isLongMark(j, gap, marks);

    // line
    this._ctx.moveTo(
      i * this._viewport.pixelRatio,
      this._element.height - (isLongMark ? this._longMarkSize : this._shortMarkSize),
    );
    this._ctx.lineTo(i * this._viewport.pixelRatio, this._element.height);

    // text
    if (isLongMark) {
      const value = k * gap;

      this._ctx.fillText(
        value.toString(),
        i * this._viewport.pixelRatio,
        this._element.height - 12 * this._viewport.pixelRatio,
      );
    }
  }

  private _renderNegativeAxis(
    startPos: number,
    endPos: number,
    renderFn: typeof this._renderHorizontalRulerPoints | typeof this._renderVerticalRulerPoints,
  ): void {
    for (let i = startPos, j = 0, k = 0; i > endPos; i -= this._scaledGap, j += this._config.gap, k++) {
      renderFn(i, j, k, -1 * this._config.gap, this._config.marks);
    }
  }

  private _renderPositiveAxis(
    startPos: number,
    endPos: number,
    renderFn: typeof this._renderHorizontalRulerPoints | typeof this._renderVerticalRulerPoints,
  ): void {
    for (let i = startPos, j = 0, k = 0; i < endPos; i += this._scaledGap, j += this._config.gap, k++) {
      renderFn(i, j, k, this._config.gap, this._config.marks);
    }
  }

  private _renderVerticalGuideMarks(startPos: number): void {
    const guides = useCreatorStore.getState().canvas.guides;

    const guideMarkColor = `rgba(${guides.color.r}, ${guides.color.g}, ${guides.color.b}, 0.5)`;
    const selectedGuideMarkColor = `rgba(${guides.color.r}, ${guides.color.g}, ${guides.color.b}, 1)`;

    Object.entries(guides.vertical).forEach(([key, guide]) => {
      const value = guide * this._viewport.canvasHelper.zoomWithPixelRatio;

      this._ctx.lineWidth = 2;
      this._ctx.beginPath();
      this._ctx.moveTo(startPos + value, 0);
      this._ctx.lineTo(startPos + value, this._element.height);
      this._ctx.strokeStyle = this._overlayCanvas.selectedGuide?.id === key ? selectedGuideMarkColor : guideMarkColor;
      this._ctx.stroke();
    });
  }

  private _renderVerticalRulerPoints(i: number, j: number, k: number, gap: number, marks: number): void {
    const isLongMark = this._isLongMark(j, gap, marks);

    // line
    this._ctx.moveTo(
      this._element.width - (isLongMark ? this._longMarkSize : this._shortMarkSize),
      i * this._viewport.pixelRatio,
    );
    this._ctx.lineTo(this._element.width, i * this._viewport.pixelRatio);

    // text
    if (isLongMark) {
      const value = k * gap;
      const xPos = this._element.width - 12 * this._viewport.pixelRatio;
      const yPos = i * this._viewport.pixelRatio;

      this._ctx.save();
      this._ctx.translate(xPos, yPos);
      this._ctx.rotate(-Math.PI / 2);
      this._ctx.fillText(value.toString(), 0, 0);
      this._ctx.restore();
    }
  }
}
