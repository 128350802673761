/**
 * Copyright 2024 Design Barn Inc.
 */

import type { RGBA } from '../components/ColorPicker';

function isValidRGBValue(value: unknown): boolean {
  return typeof value === 'number' && Number.isNaN(value) === false && value >= 0 && value <= 255;
}

export default function setRGBA(red: number, green: number, blue: number, alpha?: number): RGBA {
  const color = {
    red: red | 0,
    green: green | 0,
    blue: blue | 0,
    alpha: 0,
  };

  if (isValidRGBValue(red) && isValidRGBValue(green) && isValidRGBValue(blue)) {
    if (isValidRGBValue(alpha) === true) {
      color.alpha = alpha || 0;
    }
  }

  return color;
}
