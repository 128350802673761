/**
 * Copyright 2024 Design Barn Inc.
 */

import { useEffect, useRef, useCallback } from 'react';

const THROTTLE_TIME = 50;

export const useThrottledUseEffect = (handleEffect: () => void): void => {
  const timerRef = useRef<number>();

  // Define a throttled version of the effect callback using useCallback
  const throttledEffect = useCallback(() => {
    // Clear the previous timer, if any
    clearTimeout(timerRef.current);

    // Set a new timer to execute the effect after 500 milliseconds
    timerRef.current = window.setTimeout(() => {
      handleEffect();
    }, THROTTLE_TIME);
  }, [handleEffect]);

  // Apply the throttled effect using useEffect
  useEffect(() => {
    throttledEffect();

    // Clean-up function to clear the timer when the component unmounts
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [throttledEffect]);
};
