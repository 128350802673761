/**
 * Copyright 2024 Design Barn Inc.
 */

import type { ShapeJSON } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { LayerMatteAction } from './LayerMatteAction';
import type { OptionalShapeLayer } from './Layers';

import { EyeOpen, Focus, InvisibleEye, Locked, UnLocked } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import type { LayerUI } from '~/features/timeline';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

interface LayerActionsProps {
  layer: OptionalShapeLayer | ShapeJSON;
  layerUI: LayerUI;
}

export const LayerActions: React.FC<LayerActionsProps> = ({ layer, layerUI }) => {
  const [isHovered, focusedNodeIds] = useCreatorStore(
    (state) => [state.timeline.hoverId === layer.id, state.ui.focusedNodeIds],
    shallow,
  );

  const { isHidden, isLocked, matteMode } = layerUI;
  const isFocused = focusedNodeIds.includes(layer.id);
  const isMatte = layer.properties.td;
  const isMatteHidden = Boolean(layer.data?.['isMatteHidden']);

  const setHidden = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_LAYER_SHOW_HIDE, { id: layer.id });
  }, [layer.id]);

  const setLocked = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_LAYER_LOCK_UNLOCK, { id: layer.id });
  }, [layer]);

  const setFocused = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_LAYER_FOCUS_UNFOCUS, { id: layer.id });
  }, [layer]);

  const isHiddenIconVisible = isHovered || isMatteHidden || (isHidden && !isMatte);
  const isLockedIconVisible = isHovered || isLocked;
  const isFocusedIconVisible = !isHidden && (isHovered || isFocused);
  const isMatteIconVisible = isHovered || matteMode !== 0;

  if (!isHiddenIconVisible && !isLockedIconVisible && !isFocusedIconVisible && !isMatteIconVisible) {
    return null;
  }

  const eyeIcon =
    isHidden || isMatteHidden ? (
      <InvisibleEye className={`fill-gray-500 hover:fill-white`} />
    ) : (
      <EyeOpen className={`fill-gray-500 hover:fill-white`} />
    );
  const lockIcon = isLocked ? (
    <Locked className={`fill-white`} />
  ) : (
    <UnLocked className="fill-gray-500 hover:fill-white" />
  );
  const focusIcon = isFocused ? (
    <Focus className={`fill-white`} />
  ) : (
    <Focus className="fill-gray-500 hover:fill-white" />
  );

  return (
    <div className="flex items-center gap-1">
      <LayerMatteAction layer={layer} layerUI={layerUI} />
      <div className="h-4 w-4">
        {isFocusedIconVisible && (
          <Tooltip content={isFocused ? 'Unfocus' : 'Focus'}>
            <button onClick={setFocused}>{focusIcon}</button>
          </Tooltip>
        )}
      </div>
      <div className="h-4 w-4">
        {isLockedIconVisible && (
          <Tooltip content={isLocked ? 'Unlock' : 'Lock'}>
            <button onClick={setLocked}>{lockIcon}</button>
          </Tooltip>
        )}
      </div>
      <div className="h-4 w-4">
        {isHiddenIconVisible && (
          <Tooltip
            content={
              // eslint-disable-next-line no-nested-ternary
              isMatte ? (
                <>
                  This layer is currently used as a matte.
                  <br /> Unhiding will have no impact on the players.
                </>
              ) : isHidden ? (
                'Show'
              ) : (
                'Hide'
              )
            }
          >
            <button onClick={setHidden}>{eyeIcon}</button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
