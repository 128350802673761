/**
 * Copyright 2024 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback } from 'react';

import { RemixContent } from './remix-link/RemixContent';

import { Close, GlobeIcon, ShareLinkIcon } from '~/assets/icons';
import { Divider } from '~/components/Elements/Divider';
import { Modal } from '~/components/Elements/Modal';
import { useCreatorStore } from '~/store';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

enum ItemType {
  Publish = 'Publish',
  ShareLink = 'ShareLink',
}

export const ShareModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [selectedType, setSelectedType] = React.useState<ItemType>(ItemType.ShareLink);
  const existingTitle = useCreatorStore.getState().project.info.name;

  const onSelectedType = useCallback(
    (type: ItemType) => {
      setSelectedType(type);
    },
    [setSelectedType],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <Dialog.Panel className="w-[680px] overflow-hidden rounded-md bg-gray-800 shadow-sm transition-all">
              <Dialog.Title className="flex h-[45px] justify-between px-4 py-3 text-sm font-bold text-white">
                <div>Share &quot;{existingTitle}&quot;</div>
                <button>
                  <Close
                    className="h-6 w-6 cursor-pointer fill-current text-gray-500 hover:opacity-50"
                    onClick={onClose}
                  />
                </button>
              </Dialog.Title>
              <Divider />
              <div className="grid grid-cols-[230px_1fr]">
                <div className="flex flex-col gap-1 border-r border-[#283137] p-4 text-[14px]">
                  <div
                    className={`flex items-center gap-2 rounded p-2 ${selectedType === ItemType.ShareLink ? 'bg-[#283137] font-bold' : 'cursor-pointer hover:bg-[#283137]'}`}
                    onClick={() => onSelectedType(ItemType.ShareLink)}
                  >
                    <ShareLinkIcon className="h-4 w-4" />
                    <div className="text-white">Share Remix link</div>
                  </div>
                  <div
                    className={`hidden items-center gap-2 rounded p-2 ${selectedType === ItemType.Publish ? 'bg-[#283137] font-bold' : 'cursor-pointer hover:bg-[#283137]'}`}
                    onClick={() => onSelectedType(ItemType.Publish)}
                  >
                    <GlobeIcon className="h-6 w-6" />
                    <div className="text-white">Publish to community</div>
                  </div>
                </div>
                <div className="h-[452px] p-4">
                  {selectedType === ItemType.ShareLink && <RemixContent />}
                  {selectedType === ItemType.Publish && <div></div>}
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Modal>
  );
};
