/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ScalarJSON, StarShape } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { AnimatedInnerRadius as AnimatedInnerRadiusIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input/NumberInput';
import { MAX_POLYSTAR_RADIUS, MIN_POLYSTAR_RADIUS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setPolystarInnerRadius } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedInnerRadius: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const animatedValue = animatedProp.value as ScalarJSON | null;
  const innerRadius = animatedValue?.value ?? 0;

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = useCreatorStore.getState().toolkit.getNodeByIdOnly(animatedProp.id);

      if (node?.parent) {
        setPolystarInnerRadius(node.parent as StarShape, result.value);
        emitter.emit(EmitterEvent.POLYSTAR_INNER_RADIUS_UPDATED);
      }
    },
    [animatedProp],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="innerRadius"
        onChange={handleOnChange}
        value={innerRadius}
        label={<AnimatedInnerRadiusIcon className="h-3 w-3" />}
        styleClass={styleClass}
        min={MIN_POLYSTAR_RADIUS}
        max={MAX_POLYSTAR_RADIUS}
      />
    </div>
  );
};
