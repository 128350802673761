/**
 * Copyright 2024 Design Barn Inc.
 */

export enum LocalStorageKey {
  AutoOpenOnSideBar = 'autoOpenOnSideBar',
  ConvertToPathConfirmDismiss = 'convertToPathConfirmDismiss',
  LastChangelogSeen = 'lastChangelogSeen',
  SeenOnboarding = 'seenOnboarding',
  ShownTooltip = 'shownTooltip',
}
