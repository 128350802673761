/**
 * Copyright 2021 Design Barn Inc.
 */

import React from 'react';

import { CheckedBox, UncheckedBox } from '~/assets/icons';

interface CheckBoxProps {
  children: React.ReactNode;
  childrenStyle?: string;
  dataTestId?: string;
  isChecked: boolean;
  labelStyle?: string;
  onChange: () => void;
}

export const Checkbox: React.FC<CheckBoxProps> = ({
  children,
  childrenStyle = '',
  dataTestId,
  isChecked,
  labelStyle,
  onChange,
}) => {
  return (
    <label className={`flex cursor-pointer select-none items-center ${labelStyle ?? ''}`}>
      {isChecked ? (
        <CheckedBox className="h-4 w-4"></CheckedBox>
      ) : (
        <UncheckedBox className="h-4 w-4 stroke-current"></UncheckedBox>
      )}
      <input
        defaultChecked={isChecked}
        className="hidden"
        type="checkbox"
        onClick={onChange}
        data-testid={dataTestId}
      />
      <span className={`pl-2 ${childrenStyle}`}>{children}</span>
    </label>
  );
};
