/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable tailwindcss/no-custom-classname */

import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';

import { EasingLinear, EasingSmooth } from '~/assets/icons';
import type { EasingControlOption, EasingTypeOption } from '~/data/eventStore';
import { EasingControl, EasingType } from '~/data/eventStore';
import { EmitterEvent, emitter } from '~/lib/emitter';
import { useCreatorStore } from '~/store';
import type { KeyframeEasing } from '~/store/timelineSlice';

interface EasingControlPanel {
  controlType: EasingControlOption;
  easingType: EasingTypeOption;
  onClickAction: (controlType: EasingControlOption, easingType: EasingTypeOption) => void;
}

const EasingControls: FC<EasingControlPanel> = ({
  controlType = EasingControl.In,
  easingType = EasingType.Linear,
  onClickAction,
}) => {
  const linearStyle = easingType === 'Mixed' || easingType !== EasingType.Linear ? 'cursor-pointer' : 'text-teal-200';
  const smoothStyle = easingType === 'Mixed' || easingType === EasingType.Linear ? 'cursor-pointer' : 'text-teal-200';

  return (
    <div className="flex rounded border border-transparent bg-gray-700 focus-within:border-teal-500">
      <div
        className={`flex w-1/2 pl-2 ${linearStyle}`}
        onClick={() => {
          if (easingType === EasingType.Linear) return;

          onClickAction(controlType, EasingType.Linear);
        }}
      >
        <EasingLinear className=" timeline-keyframe-control w-[16px] " />
        <div className="timeline-keyframe-control px-2 py-[2px] ">Linear</div>
      </div>
      <div className="my-[-1px] border border-gray-800"></div>
      <div
        className={`flex  w-1/2 px-2 ${smoothStyle}`}
        onClick={() => {
          if (easingType === 'Smooth') return;
          onClickAction(controlType, 'Smooth');
        }}
      >
        <EasingSmooth className=" timeline-keyframe-control w-[25px] " />
        <div className="timeline-keyframe-control px-2 py-[2px] ">Smooth</div>
      </div>
    </div>
  );
};

export const EasingProperty: React.FC<{ enabled: boolean }> = ({ enabled }) => {
  const [selectedKeyframes, selectedKeyFramesEasing] = useCreatorStore((state) => [
    state.timeline.selectedKeyframeIds,
    state.timeline.selectedKeyFramesEasing,
    state.ui.selectedNodesInfo,
  ]);

  const [easingResult, setEasingResult] = useState<KeyframeEasing>({ inTangent: null, outTangent: null });

  useEffect(() => {
    if (selectedKeyFramesEasing.length > 0) {
      setEasingResult(
        selectedKeyFramesEasing.reduce((result: KeyframeEasing, frame: KeyframeEasing, index: number) => {
          if (index === 0) return frame;

          // Special handling for `inTangent`:
          // - A `null` check is necessary only for `inTangent` to address the first keyframes' behavior, as subsequent keyframes will have both in and out tangents defined.
          // - If `inTangent` is `null`, we disregard this nullity and consider the value from other frames instead.
          return {
            inTangent:
              frame.inTangent === (result.inTangent || frame.inTangent) || frame.inTangent === null
                ? result.inTangent || frame.inTangent
                : EasingType.Mixed,
            outTangent: frame.outTangent === result.outTangent ? result.outTangent : EasingType.Mixed,
          };
        }),
      );
    }
  }, [selectedKeyFramesEasing]);

  const handleEasingAction = useCallback((controlType: EasingControlOption, easingType: EasingTypeOption) => {
    if (controlType === EasingControl.In && easingType === EasingType.Linear) {
      emitter.emit(EmitterEvent.EASING_LINEAR_IN);
    } else if (controlType === EasingControl.In && easingType === EasingType.Smooth) {
      emitter.emit(EmitterEvent.EASING_SMOOTH_IN);
    } else if (controlType === EasingControl.Out && easingType === EasingType.Linear) {
      emitter.emit(EmitterEvent.EASING_LINEAR_OUT);
    } else if (controlType === EasingControl.Out && easingType === EasingType.Smooth) {
      emitter.emit(EmitterEvent.EASING_SMOOTH_OUT);
    }
  }, []);

  return (
    <>
      {enabled && selectedKeyframes.length > 0 && (
        <div className=" pb-3 pl-4 pr-3 pt-4">
          <div className="pointer-events-none relative mb-3 flex w-[199px] items-center text-xs font-semibold text-gray-100">
            Easing
          </div>

          {selectedKeyframes.length === 1 && selectedKeyFramesEasing[0]?.inTangent && (
            <div className="  flex h-[24px] text-xs">
              <div className="mx-1 flex w-[20px] items-center justify-start text-xs text-gray-500">In</div>
              <EasingControls
                controlType={EasingControl.In}
                easingType={selectedKeyFramesEasing[0].inTangent as EasingTypeOption}
                onClickAction={handleEasingAction}
              />
            </div>
          )}

          {selectedKeyframes.length === 1 && selectedKeyFramesEasing[0]?.outTangent && (
            <div className="mt-2 flex text-xs">
              <div className="mx-1 flex  w-[20px] items-center justify-center text-xs text-gray-500">Out</div>
              <EasingControls
                controlType={EasingControl.Out}
                easingType={selectedKeyFramesEasing[0].outTangent as EasingTypeOption}
                onClickAction={handleEasingAction}
              />
            </div>
          )}

          {selectedKeyframes.length > 1 && (
            <>
              <div className="  flex h-[24px] text-xs">
                <div className="mx-1 flex w-[20px] items-center justify-start text-xs text-gray-500">In</div>
                <EasingControls
                  controlType={EasingControl.In}
                  easingType={easingResult.inTangent as EasingTypeOption}
                  onClickAction={handleEasingAction}
                />
              </div>
              <div className="mt-2 flex text-xs">
                <div className="mx-1 flex  w-[20px] items-center justify-center text-xs text-gray-500">Out</div>
                <EasingControls
                  controlType={EasingControl.Out}
                  easingType={easingResult.outTangent as EasingTypeOption}
                  onClickAction={handleEasingAction}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
