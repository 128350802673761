/**
 * Copyright 2022 Design Barn Inc.
 */

// Not normal text, generated text provides bigger size for correct alignment
// Reference: https://fsymbols.com/generators
// CustomText used in tooltip shortcut icon (accept string only)
export enum CustomText {
  MinusSign = '－',
  PlusSign = '＋',
}

export enum ImportType {
  New = 'new',
  RecentFiles = 'recentFiles',
  Scene = 'scene',
  Upload = 'upload',
  Url = 'url',
}

export enum ExportType {
  DotCreator = 'DotCreator',
  DotLottie = '.lottie',
  Gif = '.gif',
  JSON = '.json',
  MP4 = '.mp4',
  SaveToCreator = 'SaveToCreator',
  SaveToWorkspace = 'SaveToWorkspace',
  TelegramSticker = 'Telegram sticker (TGS)',
  ZipArchive = '.zip',
}

export enum SidebarBottomType {
  Plugin = 'Plugin',
  QuickSearch = 'Quick Search',
}

export enum ToolType {
  Anchor = 'Anchor',
  ConvertVertex = 'ConvertVertex',
  Hand = 'Hand',
  Move = 'Move',
  Pen = 'Pen',
  Shape = 'Shape',
  Text = 'Text',
}

export enum LibraryType {
  GettingStarted = 'GettingStarted',
  Iconscout = 'Iconscout',
  Lottie = 'Lottie',
  MyAsset = 'MyAsset',
  Plugin = 'Plugin',
  Upload = 'Upload',
}

export enum SceneNameType {
  MainScene = 'Main Scene',
}

export const MenuType = {
  ...ImportType,
  ...ExportType,
  ...{
    GoToDashboard: 'GoToDashboard',
    GoToDashboardDivider: 'GoToDashboardDivider',
    Edit: 'Edit',
    View: 'View',
    MakeCopy: 'MakeCopy',
    DownloadMenu: 'DownloadMenu',
    DownloadMenuDivider: 'DownloadMenuDivider',
    ToggleTimeline: 'ToggleTimeline',
    ToggleUI: 'ToggleUI',
  },
};

export const EditMenuType = {
  Undo: 'Undo',
  Redo: 'Redo',
  Cut: 'Cut',
  Copy: 'Copy',
  Paste: 'Paste',
  PasteAsNew: 'PasteAsNew',
  Delete: 'Delete',
  Duplicate: 'Duplicate',
  SelectAll: 'SelectAll',
};

export const ViewMenuType = {
  Rulers: 'Rulers',
  Guides: 'Guides',
  Grid: 'Grid',
  Snapping: 'Snapping',
};

export const GuideMenuType = {
  EditGuides: 'EditGuides',
  DeleteGuide: 'DeleteGuide',
};

export const RulersMenuType = {
  HideRulers: 'HideRulers',
};

// Whenever shift is held within the canvas or number inputs in the property
// panel they change within an increment
export const UI_OTHER_SHIFT_INCREMENT = 20;

// Rotation is handled differently
export const UI_ROTATION_SHIFT_INCREMENT = 15;

export const DEFAULT_FILE_NAME = 'Untitled file';

export enum BackgroundColor {
  Transparent = 'transparent',
}
export const NumberInputPrecision = 4;

export const HEADER_HEIGHT = 40;

export enum SceneType {
  MAIN_SCENE,
  ACTIVE_SCENE,
}
