/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React from 'react';

import { Reload } from '~/assets/icons';
import { WindowCloseButton } from '~/components/Elements/Window';
import { getPluginManager } from '~/plugins';
import { useCreatorStore } from '~/store';

export interface PluginUIHeaderProps {
  className: string;
  id: string;
  isDevPlugin: boolean;
  title: string;
}

export const PluginUIHeader: React.FC<PluginUIHeaderProps> = (props) => {
  const removeFloatingPluginId = useCreatorStore((state) => state.ui.removeFloatingPluginId);

  return (
    <div className={clsx('flex h-10 cursor-move items-center justify-between gap-4 pl-4 pr-3', props.className)}>
      <span className="text-xs font-bold">{props.title}</span>
      <div className="flex flex-row gap-1">
        {props.isDevPlugin && (
          <Reload
            className="h-6 w-6 cursor-pointer p-1 hover:opacity-50"
            onClick={() => {
              getPluginManager().reloadDevPlugin();
            }}
          />
        )}
        <WindowCloseButton
          onClick={() => {
            if (props.isDevPlugin) {
              getPluginManager().unloadDevPlugin();
            } else {
              removeFloatingPluginId(props.id);
            }
          }}
        />
      </div>
    </div>
  );
};
