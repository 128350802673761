/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { compact } from 'lodash-es';
import React, { useEffect, useContext } from 'react';

import { SaveContext } from '../SaveContext';

import { Workspace } from './workspace';

import { useCreatorStore } from '~/store';
import type { IWorkspace } from '~/store/projectSlice';
import { DirectoryType } from '~/store/projectSlice';

interface WorkspaceLayersProp {
  workspaces: IWorkspace[];
}

export const WorkspaceLayers: React.FC<WorkspaceLayersProp> = ({ workspaces }: WorkspaceLayersProp) => {
  const { error, setError, setSelected, setUIMap } = useContext(SaveContext);

  useEffect(() => {
    const selectedWorkspace = {
      id: useCreatorStore.getState().project.selectedWorkspace?.id,
      type: DirectoryType.Workspace,
      parentId: null,
    };

    const selectedDirectory = useCreatorStore.getState().project.selectedDirectory;

    setUIMap((draft: any) => {
      const toBeSelectedIds = compact([selectedWorkspace.id, selectedDirectory?.parentId, selectedDirectory?.id]);

      toBeSelectedIds.map((selectedId) => {
        draft[selectedId] = {
          expanded: true,
        };

        return null;
      });
    });
  }, [setSelected, setUIMap]);

  const onRetry = (): void => {
    setSelected({});
    setError(false);
  };

  if (error) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <p className="text-xs text-gray-400">
          Something went wrong while <br />
          loading this workspace
        </p>
        <button className="mt-3 h-6 w-[46px] rounded bg-[#00C1A2] text-xs text-white" onClick={onRetry}>
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="">
      {workspaces.length > 0 &&
        workspaces.map((workspace, workspaceIndex: number) => {
          return <Workspace key={workspaceIndex} index={workspaceIndex} workspace={workspace} />;
        })}
    </div>
  );
};
