/**
 * Copyright 2024 Design Barn Inc.
 */

import { cubicBezierIntTest, getValueFromTexture } from '../bezier/bezier';

export const applyDithering = `
// Reference: https://shader-tutorial.dev/advanced/color-banding-dithering/
vec4 applyDithering(vec4 color) {
  float noise = fract(sin(dot(vCoord.xy, vec2(12.9898, 78.233))) * 43758.5453);
  float noiseGranularity = 3.5 / 255.0;

  return color += mix(-noiseGranularity, noiseGranularity, noise);
}
`;
export const getOffsetFromTexture = `
float getOffsetFromTexture(sampler2D offsetsTexture, vec2 textureDimensions, int index) {
  float x = (float(index) + 0.5) / textureDimensions.x;
  vec4 texel = texture2D(offsetsTexture, vec2(x, 0.5));
  return texel.x;
}
`;
export const getColorFromProjection = `
vec4 getColorFromProjection(float normalizedProjection) {
  vec4 color = vec4(0.0);

  float firstOffset = getOffsetFromTexture(uColorStopOffsets, vec2(uColorStopsCount, 1.0), 0);
  float lastOffset = getOffsetFromTexture(uColorStopOffsets, vec2(uColorStopsCount, 1.0), uColorStopsCount - 1);

  if (normalizedProjection <= firstOffset) {
    color = getValueFromTexture(uColorStops, vec2(uColorStopsCount, 1), vec2(0, 0));
  }

  if (normalizedProjection >= lastOffset) {
    color = getValueFromTexture(uColorStops, vec2(uColorStopsCount, 1), vec2(uColorStopsCount - 1, 0));
  }

  for (int i = 0; i < uColorStopsCount - 1; ++i) {
    float offsetStart = getOffsetFromTexture(uColorStopOffsets, vec2(uColorStopsCount, 1.0), i);
    float offsetEnd = getOffsetFromTexture(uColorStopOffsets, vec2(uColorStopsCount, 1.0), i + 1);

    if (normalizedProjection > offsetStart && normalizedProjection < offsetEnd) {
      float interpolationValue = (normalizedProjection - offsetStart) / (offsetEnd - offsetStart);
      vec4 colorStart = getValueFromTexture(uColorStops, vec2(uColorStopsCount, 1), vec2(i, 0));
      vec4 colorEnd = getValueFromTexture(uColorStops, vec2(uColorStopsCount, 1), vec2(i + 1, 0));
      color = mix(colorStart, colorEnd, interpolationValue);
      break;
    }
  }

  return applyDithering(color);
}
`;
export const gradientColor = `
vec4 getGradientColor() {
  // Calculate gradient direction vector
  vec2 gradientDirection = uEnd - uStart;
  float gradientLength = length(gradientDirection);
  vec2 gradientDirNormalized = normalize(gradientDirection);

  // Calculate the projection of the fragment position onto the gradient direction
  vec2 fragPos = vCoord;
  vec2 startToEnd = uStart - fragPos;
  float projection = dot(-startToEnd, gradientDirNormalized);
  float normalizedProjection = clamp(projection / gradientLength, 0.0, 1.0);

  return getColorFromProjection(normalizedProjection);
}
`;

export const getRadialColor = `
vec4 getRadialColor() {
  float radius = distance(uStart, uEnd);

  // apply highlight angle rotation
  float angleRadians = radians(uHighlightAngle);
  vec2 direction = uEnd - uStart;
  mat2 rotationMat = mat2(cos(-angleRadians), -sin(-angleRadians), sin(-angleRadians), cos(-angleRadians));
  vec2 rotatedDirection = rotationMat * direction;

  // apply highlight length, which moves the focal point
  // reference: https://www.shadertoy.com/view/ldtSzM
  vec2 focal = uStart + rotatedDirection * (uHighlightLength / 100.0);

  vec2 distanceToFocal = focal - vCoord;
  vec2 focalDirection = focal - uStart;

  vec2 normalizedDirection = normalize(vec2(-distanceToFocal.y, distanceToFocal.x));
  float perpendicularDistance = abs(dot(focalDirection, normalizedDirection));
  float projectionLength = abs(dot(focalDirection, distanceToFocal) / length(distanceToFocal));

  float focalToEdge =
    sqrt(radius * radius - perpendicularDistance * perpendicularDistance) +
    sign(dot(distanceToFocal, focalDirection) / distance(distanceToFocal, focalDirection)) * projectionLength;

  float normalizedProjection = min(1.0, abs(length(vCoord.xy - focal) / focalToEdge));

  return getColorFromProjection(normalizedProjection);
}
`;

export const gradientFillFragment = `

varying vec2 vCoord;
uniform sampler2D uBezierPoints;
uniform int uBezierCount;
uniform vec3 uColor;
uniform float uOpacity;

uniform bool uIsRadial;
uniform sampler2D uColorStops;
uniform int uColorStopsCount;
uniform sampler2D uColorStopOffsets;
uniform vec2 uStart;
uniform vec2 uEnd;
uniform float uHighlightAngle;
uniform float uHighlightLength;

${getValueFromTexture}
${getOffsetFromTexture}
${applyDithering}
${getColorFromProjection}
${cubicBezierIntTest}
${gradientColor}
${getRadialColor}

void main() {
  int s0 = 0;
  for (int i = 0; i < uBezierCount; i++) {
    vec3 P0 = getValueFromTexture(uBezierPoints, vec2(uBezierCount, 4.0), vec2(i, 0)).rgb;
    vec3 P1 = getValueFromTexture(uBezierPoints, vec2(uBezierCount, 4.0), vec2(i, 1)).rgb;
    vec3 P2 = getValueFromTexture(uBezierPoints, vec2(uBezierCount, 4.0), vec2(i, 2)).rgb;
    vec3 P3 = getValueFromTexture(uBezierPoints, vec2(uBezierCount, 4.0), vec2(i, 3)).rgb;
    s0 += cubic_bezier_int_test_even_odd(P0, P1, P2, P3, vCoord);
  }

  if (s0 % 2 == 1) {
    if (uIsRadial) {
      vec4 radialColor = getRadialColor();
      gl_FragColor = vec4(radialColor.rgb, radialColor.a * uOpacity);
    } else {
      vec4 gradientColor = getGradientColor();
      gl_FragColor = vec4(gradientColor.rgb, gradientColor.a * uOpacity);
    }
  } else {
    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
  }
}

`;
