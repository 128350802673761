/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useState, memo, useCallback } from 'react';
import type { FC } from 'react';
import ReactDOM from 'react-dom';

import { steps } from './config';
import { Step } from './Step';

import { Close } from '~/assets/icons';
import { IframeWithLoader } from '~/components/Elements/IFrameWithLoader';
import { EventType, OnboardingPopoverAction } from '~/data/eventStore';
import { useCreatorStore } from '~/store';
import { LocalStorageKey } from '~/store/constant';
import { fireEvent } from '~/utils';

const setShowOnboardingNudge = useCreatorStore.getState().ui.setShowOnboardingNudge;
const setShowOnboarding = useCreatorStore.getState().ui.setShowOnboarding;

const Onboarding: FC = memo(() => {
  const [stepIndex, setStepIndex] = useState(0);

  const onClose = useCallback(() => {
    setShowOnboarding(false);
    const seenOnboarding = window.localStorage.getItem(LocalStorageKey.SeenOnboarding);

    if (seenOnboarding === null || seenOnboarding === 'false') {
      setShowOnboardingNudge(true);
      window.localStorage.setItem(LocalStorageKey.SeenOnboarding, 'true');
    }

    fireEvent({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.OnboardingPopoverAction,
      parameters: {
        action: OnboardingPopoverAction.Dismiss,
        'current-step': stepIndex + 1,
      },
    });
  }, [stepIndex]);

  const onDone = useCallback(() => {
    setShowOnboarding(false);
    window.localStorage.setItem(LocalStorageKey.SeenOnboarding, 'true');

    fireEvent({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.OnboardingPopoverAction,
      parameters: {
        action: OnboardingPopoverAction.Done,
        'current-step': stepIndex + 1,
      },
    });
  }, [stepIndex]);

  const onPrevious = useCallback(() => {
    setStepIndex((prev) => {
      fireEvent({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.OnboardingPopoverAction,
        parameters: {
          action: OnboardingPopoverAction.Next,
          'current-step': prev + 1,
        },
      });

      const newIndex = prev - 1;

      steps[newIndex]?.run?.();

      return newIndex;
    });
  }, []);

  const onNext = useCallback(() => {
    setStepIndex((prev) => {
      fireEvent({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.OnboardingPopoverAction,
        parameters: {
          action: OnboardingPopoverAction.Next,
          'current-step': prev + 1,
        },
      });

      const newIndex = prev + 1;

      steps[newIndex]?.run?.();

      return newIndex;
    });
  }, []);

  const { getHighlightAreaBounds, iframeUrl, title, ...rest } = steps[stepIndex] || {};

  const highlightAreaBounds = getHighlightAreaBounds?.();

  if (!highlightAreaBounds || !iframeUrl) {
    return null;
  }

  const isLastStep = steps.length - 1 === stepIndex;

  return ReactDOM.createPortal(
    <Step hightlightAreaBounds={highlightAreaBounds} {...rest}>
      <div className="h-[380px] w-[380px] rounded-xl bg-white p-4 pb-0">
        <div className="mb-2 flex items-center justify-between text-[#20272C]">
          <p className="text-sm font-bold leading-normal">{title}</p>
          <Close className="m-1 h-6 w-6 cursor-pointer fill-current" onClick={onClose} />
        </div>
        <IframeWithLoader src={iframeUrl} containerClassName="h-[272px]" />
        <div className="flex items-center justify-between pt-1 pb-3">
          <p className="text-xs text-[#63727E]">
            {stepIndex + 1} of {steps.length}
          </p>
          <div className="flex gap-2 font-bold">
            {stepIndex > 0 ? (
              <button className="flex h-8 items-center px-3 text-sm text-[#20272C]" onClick={onPrevious}>
                Previous
              </button>
            ) : null}
            <button
              className="flex h-8 items-center rounded-lg bg-[#00C1A2] px-3 text-sm text-white"
              onClick={isLastStep ? onDone : onNext}
            >
              {isLastStep ? 'Done' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </Step>,
    document.body,
  );
});

export const OnboardingWrapper: FC = () => {
  const showOnboarding = useCreatorStore((state) => state.ui.onboarding.showOnboarding);

  if (!showOnboarding) {
    return null;
  }

  return <Onboarding />;
};
