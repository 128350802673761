/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer, PercentageJSON } from '@lottiefiles/toolkit-js';
import { Percentage } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { AnimatedOpacity as AnimatedOpacityIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input/NumberInput';
import { NumberInputPrecision } from '~/data/constant';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setAnimatedOpacity } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedOpacity: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  // const { pct } = animatedProp.value as PercentageJSON;

  // temp fix
  const animatedValue = animatedProp.value as PercentageJSON | null;
  const { pct } = animatedValue ? animatedValue : { pct: 0 };
  const getNodeByIdOnly = useCreatorStore((state) => state.toolkit.getNodeByIdOnly);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeByIdOnly(animatedProp.id) as AVLayer;

      if (node.parent) {
        if (result.name === 'opacity') {
          if (node.parent.nodeType === 'Shape') {
            (node.parent as AVLayer).setOpacity(new Percentage(result.value as number));
          } else {
            setAnimatedOpacity(node.parent as AVLayer, [result.value]);
          }
        }
        emitter.emit(EmitterEvent.ANIMATED_OPACITY_UPDATED);
      }
    },
    [getNodeByIdOnly, animatedProp],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="opacity"
        onChange={handleOnChange}
        value={pct}
        precision={NumberInputPrecision}
        label={<AnimatedOpacityIcon className="h-3 w-3" />}
        styleClass={styleClass}
        append="%"
        min={0}
        max={100}
      />
    </div>
  );
};
