/**
 * Copyright 2024 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Close, WarningIcon } from '~/assets/icons';
import { Divider } from '~/components/Elements/Divider';
import { Modal } from '~/components/Elements/Modal';
import { GB_CONSTANT } from '~/config/growthbook';
import { useUsersnapApi } from '~/providers/UsersnapProvider';

export const UnhandledErrorsListener: React.FC = () => {
  const [showErrorWarning, setShowErrorWarning] = useState(false);
  const usersnapApi = useUsersnapApi();
  const isShowErrorMessageFeatureOn = useFeatureIsOn(GB_CONSTANT.SHOW_ERROR_MESSAGE);

  useEffect(() => {
    const skippErrorSources = ['react-range-slider-input.js'];
    const skipErrorMessages = ["Failed to execute 'getComputedStyle'"];

    window.addEventListener('error', (error) => {
      const isSkip =
        skippErrorSources.some((source) => error.filename.includes(source)) ||
        skipErrorMessages.some((msg) => error.message.includes(msg));

      if (!isSkip) {
        setShowErrorWarning(true);
      }
    });

    window.addEventListener('unhandledrejection', () => {
      setShowErrorWarning(true);
    });
  }, []);

  const onClose = useCallback(() => {
    setShowErrorWarning(false);
  }, [setShowErrorWarning]);

  const handleReload = useCallback((evt: React.MouseEvent): void => {
    evt.preventDefault();
    window.location.reload();
  }, []);

  const handleReportCrash = useCallback((): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (usersnapApi as any).open();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (usersnapApi as any).hideButton();
  }, [usersnapApi]);

  if (showErrorWarning && isShowErrorMessageFeatureOn) {
    return (
      <Modal isOpen={showErrorWarning} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Dialog.Panel className="w-[360px] overflow-hidden rounded-md bg-gray-800 align-middle shadow-sm transition-all">
                <Dialog.Title as="div" className="flex items-center justify-between px-4 py-3 font-bold text-white">
                  <div className="flex items-center gap-4">
                    <WarningIcon className="h-6 w-6" />
                    Something went wrong
                  </div>
                  <button>
                    <Close
                      className="h-6 w-6 cursor-pointer fill-current text-gray-500 hover:opacity-50"
                      onClick={onClose}
                    />
                  </button>
                </Dialog.Title>
                <Divider />
                <div className="p-4 text-left text-[12px] text-[#BFC8D1]">
                  Please refresh the page to open the most recently saved file version. You can also report this issue
                  if you need help.
                </div>
                <Divider />
                <div className="flex items-center justify-end gap-2 px-4 py-3">
                  <button className="rounded bg-[#333C45] px-3 py-1 text-[12px] text-white" onClick={handleReload}>
                    Refresh page
                  </button>
                  <button className="rounded bg-[#019D91] px-3 py-1 text-[12px] text-white" onClick={handleReportCrash}>
                    Report issue
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Modal>
    );
  }

  return null;
};
