/**
 * Copyright 2024 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useEffect } from 'react';

import { IframeWithLoader } from '~/components/Elements/IFrameWithLoader';
import { Modal } from '~/components/Elements/Modal';
import { EventType, OnboardingPopoverAction, OnboardingPopoverSource } from '~/data/eventStore';
import { useCreatorStore } from '~/store';
import { LocalStorageKey } from '~/store/constant';
import { fireEvent } from '~/utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const setShowOnboarding = useCreatorStore.getState().ui.setShowOnboarding;
const setShowOnboardingNudge = useCreatorStore.getState().ui.setShowOnboardingNudge;

export const OnboardingModal: React.FC<Props> = ({ isOpen, onClose }) => {
  useEffect(() => {
    fireEvent({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.OnboardingPopoverShown,
      parameters: {},
    });
  }, []);

  const onSkip = useCallback(() => {
    onClose();
    setShowOnboardingNudge(true);
    window.localStorage.setItem(LocalStorageKey.SeenOnboarding, 'true');

    fireEvent({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.OnboardingPopoverAction,
      parameters: {
        action: OnboardingPopoverAction.Skip,
      },
    });
  }, [onClose]);

  const onStart = useCallback(() => {
    onClose();
    setShowOnboarding(true);

    fireEvent({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.OnboardingPopoverAction,
      parameters: {
        action: OnboardingPopoverAction.Start,
        source: OnboardingPopoverSource.GlobalModal,
      },
    });
  }, [onClose]);

  return (
    <Modal isOpen={isOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center  text-center">
            <Dialog.Panel className="h-[485px] w-[480px] overflow-hidden rounded-md bg-white align-middle shadow-sm transition-all">
              <IframeWithLoader
                src="https://understanding-actions-530813.framer.app/"
                containerClassName="h-[413px] w-full"
              />
              <div className="flex justify-end gap-2 py-5 pr-5">
                <button
                  onClick={onSkip}
                  className="h-8 px-3 py-1 text-sm font-bold leading-normal text-[#20272C] outline-none"
                >
                  Skip
                </button>
                <button
                  onClick={onStart}
                  className="h-8 cursor-pointer rounded-lg bg-[#00C1A2] px-3 py-1 text-sm font-bold leading-normal text-white"
                >
                  Let&apos;s go
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Modal>
  );
};
