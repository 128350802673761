/**
 * Copyright 2022 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/lottie-js';
import type { FillShape, GradientFillShape } from '@lottiefiles/toolkit-js';
import { colord } from 'colord';
import { throttle } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import type { ColorMode } from '../ColorPicker/components/ColorPicker/ColorPicker';
import { ColorPicker } from '../ColorPicker/components/ColorPicker/ColorPicker';
import { isValidHex, parseHex } from '../ColorPicker/helpers';

import { emitter, EmitterEvent } from '~/lib/emitter';
import type { CurrentFillShape, CurrentGFillShape } from '~/lib/toolkit';
import { switchSolidAndGradient, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface ColorInputProps {
  color: string;
  colorPicker?: boolean;
  colorStyleClass?: string;
  disabled?: boolean;
  enableColorModeChange: boolean;
  gradientShape?: Array<CurrentGFillShape | CurrentFillShape> | null;
  gradientType?: string;
  hasKeyframe?: boolean;
  inputStyleClass?: string;
  isChannelAnimated?: boolean;
  keyFrameStyleClass?: string;
  message?: string | null | undefined;
  mixedColors?: boolean;
  noFloatingPortal?: boolean;
  onChangeColor: (color: string) => void;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOpacity: (opacity: number) => void;
  onClick?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyframeClick?: () => void;
  opacity: number;
  overrideStyle?: Record<string, string | number | boolean>;
  selectedIds?: string[];
  shapeType?: string;
  showDocumentColors?: boolean;
  showEyeDropper?: boolean;
  showKeyframe?: boolean;
  showOpacityControl?: boolean;
  showTransparent?: boolean;
  styleClass?: string;
}

export const ColorInput: React.FC<ColorInputProps> = ({
  color,
  colorPicker = true,
  colorStyleClass = '',
  disabled,
  hasKeyframe = false,
  inputStyleClass = '',
  message,
  mixedColors = false,
  onChangeColor,
  onChangeOpacity,
  onClick,
  onKeyDown,
  onKeyframeClick,
  opacity,
  shapeType = '',
  overrideStyle = {},
  showKeyframe = false,
  styleClass = '',
  isChannelAnimated = false,
  gradientType,
  showTransparent = false,
  gradientShape,
  selectedIds,
  enableColorModeChange,
  showDocumentColors = true,
  showOpacityControl = true,
  showEyeDropper = true,
  noFloatingPortal,
}) => {
  const [getNodeByIdOnly] = useCreatorStore((state) => [state.toolkit.getNodeByIdOnly], shallow);

  const [colorString, setColorString] = useState(color);
  const [draggingEvent, setDraggingEvent] = useState(false);
  const [showMessage, setShowMessage] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);
  const prevColorStringRef = useRef(colorString);

  const updateColor = useCallback(
    (colorValue: string): void => {
      onChangeColor(parseHex(colorValue));
      prevColorStringRef.current = colorValue.length < 6 ? colord(`#${colorValue}`).toHex() : colorValue;
    },
    [onChangeColor],
  );

  const updateColorStringThrottled = useMemo(() => {
    return throttle((newColor: string) => {
      if (colorString.replace('#', '').length === 6) setColorString(newColor.replace('#', ''));
      prevColorStringRef.current = newColor.replace('#', '');
    }, 75);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 1. updates colorString if color is set with Color Picker
    // and when the timeline is playing
    // 2. the throttling reduces rerenders when the timeline is playing
    // and the prop panel is open
    updateColorStringThrottled(color);
  }, [color, updateColorStringThrottled]);

  useEffect(() => {
    if (gradientShape && gradientShape.length > 0) {
      return;
    }

    setColorString(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradientShape, selectedIds]);

  const onChangeInput = useCallback(
    (evt: React.FormEvent<HTMLInputElement>): void => {
      stateHistory.beginAction();
      const value = evt.currentTarget.value;

      setColorString(value);
      setShowMessage(false);

      if (isValidHex(parseHex(value))) updateColor(parseHex(value));
    },
    [updateColor],
  );

  const handlePaste = useCallback(
    (evt: React.ClipboardEvent<HTMLInputElement>): void => {
      evt.preventDefault();

      const pasteValue = evt.clipboardData.getData('text').replace('#', '');

      setColorString(pasteValue);
      if (isValidHex(pasteValue)) updateColor(pasteValue);
    },
    [updateColor],
  );

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      inputRef.current?.select();
      if (onClick) onClick();
    },
    [onClick],
  );

  const handleBlur = useCallback((): void => {
    if (isValidHex(parseHex(colorString)) && colorString.replace('#', '').length < 6) {
      const sixDigitHex = colord(parseHex(colorString)).toHex().replace('#', '');

      setColorString(sixDigitHex);
      updateColor(sixDigitHex);
    } else {
      setColorString(prevColorStringRef.current.replace('#', ''));
    }

    setShowMessage(true);
    stateHistory.endAction();
  }, [colorString, updateColor]);

  const handleColorPickerChange = useCallback(
    (rgbValue: string): void => {
      setShowMessage(false);

      if (!draggingEvent) {
        stateHistory.beginAction();
      }
      updateColor(rgbValue);
      setColorString(rgbValue);
      setDraggingEvent(true);
    },
    [updateColor, setDraggingEvent, draggingEvent],
  );

  const handleStopColorPickerChange = useCallback(
    (rgbValue: string): void => {
      updateColor(rgbValue);
      stateHistory.endAction();
      setDraggingEvent(false);
    },
    [updateColor, setDraggingEvent],
  );

  const handleColorModeChange = useCallback(
    (prevColorMode: ColorMode, newColorMode: ColorMode) => {
      stateHistory.beginAction();
      if (!selectedIds || selectedIds.length === 0) return;
      const nodes = selectedIds.map((id) => getNodeByIdOnly(id)).filter((_) => _) as Array<
        FillShape | GradientFillShape
      >;
      const fillNode = nodes.find((node) => node.type === ShapeType.FILL) as FillShape | null;
      const solidColor = fillNode?.color.value.hex6 as string | null;

      nodes.forEach((node) => {
        switchSolidAndGradient(
          node as FillShape | GradientFillShape,
          prevColorMode,
          newColorMode,
          solidColor,
          null,
          nodes.length === 1,
        );
      });
      emitter.emit(EmitterEvent.CANVAS_COLOR_MODE_UPDATED);
      stateHistory.endAction();
    },
    [getNodeByIdOnly, selectedIds],
  );

  const formattedValue = useMemo(() => {
    if ((showMessage && message) || showTransparent) {
      return message;
    }

    if (gradientType) {
      return gradientType;
    }

    return colorString.toUpperCase().slice(0, 6);
  }, [showMessage, message, gradientType, colorString, showTransparent]) as string;

  const colorInputStyle = `
    relative flex items-center rounded border border-transparent bg-gray-700 p-1 text-xs font-normal number-input-label h-[24px] w-[84px] focus-within:border-teal-500 ${styleClass} 
  `;

  return (
    <div className={colorInputStyle}>
      {colorPicker && (
        <ColorPicker
          styleClass={colorStyleClass}
          type={gradientType as string}
          shapeType={shapeType as string}
          color={color}
          opacity={opacity}
          onChangeColor={handleColorPickerChange}
          onStopColor={handleStopColorPickerChange}
          onChangeOpacity={onChangeOpacity}
          showKeyframe={showKeyframe}
          hasKeyframe={hasKeyframe}
          onKeyframeClick={onKeyframeClick as () => void}
          overrideStyle={overrideStyle}
          mixedColors={mixedColors}
          isChannelAnimated={isChannelAnimated}
          message={formattedValue}
          gradientShape={gradientShape}
          onColorModeChange={handleColorModeChange}
          selectedIds={selectedIds ?? []}
          enableColorModeChange={enableColorModeChange}
          showDocumentColors={showDocumentColors}
          noFloatingPortal={noFloatingPortal}
          showOpacityControl={showOpacityControl}
          showEyeDropper={showEyeDropper}
        />
      )}

      <input
        name="color"
        disabled={disabled}
        className={`number-input w-[55px] bg-gray-700 pl-1 text-left text-xs font-normal focus:outline-none ${inputStyleClass}`}
        value={formattedValue}
        onChange={(event) => onChangeInput(event)}
        onKeyDown={onKeyDown}
        onClick={handleOnClick}
        onPaste={handlePaste}
        spellCheck={false}
        onBlur={handleBlur}
      ></input>
    </div>
  );
};
