/**
 * Copyright 2022 Design Barn Inc.
 */

import type { VectorJSON } from '@lottiefiles/toolkit-js';
import React from 'react';

import { Divider } from './AnimatedSharedComponents';
import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { AnimatedPositionX, AnimatedPositionY } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input/NumberInput';
import { NumberInputPrecision } from '~/data/constant';

export const AnimatedAnchorPoint: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const { x, y } = animatedProp.value as VectorJSON;

  return (
    <div className="flex items-center">
      <NumberInput
        name="anchorX"
        value={x}
        precision={NumberInputPrecision}
        label={<AnimatedPositionX className="h-3 w-3" />}
        styleClass={styleClass}
      />
      <Divider />
      <NumberInput
        name="anchorY"
        value={y}
        precision={NumberInputPrecision}
        label={<AnimatedPositionY className="h-3 w-3" />}
        styleClass={styleClass}
      />
    </div>
  );
};
