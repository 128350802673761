/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useEffect, useContext } from 'react';

import { WorkspaceLayers } from './layers';
import { SaveContext } from './SaveContext';

import SpinnerAnimation from '~/assets/animations/spinner.json';
import { useWorkspaceAPI } from '~/providers/api/workspace';
import { useCreatorStore } from '~/store';
import { DirectoryType } from '~/store/projectSlice';

export const SavePanel: React.FC = () => {
  const [workflowWorkspaces, token] = useCreatorStore((state) => [state.project.workflowWorkspaces, state.user.token]);
  const { getDraftProject } = useWorkspaceAPI(token);
  const { setSelected } = useContext(SaveContext);

  const run = useCallback(async () => {
    const draftProject = await getDraftProject();

    if (draftProject) {
      setSelected({ id: draftProject.id, type: DirectoryType.Project, title: draftProject.title as string });
    }
  }, [getDraftProject, setSelected]);

  useEffect(() => {
    if (token && workflowWorkspaces.length > 0) run();
  }, [run, token, workflowWorkspaces]);

  return (
    <div className="h-[295px] overflow-auto px-3 py-4">
      {workflowWorkspaces.length > 0 && (
        <div className="flex h-full w-full flex-col">
          <WorkspaceLayers workspaces={workflowWorkspaces} />
        </div>
      )}

      {workflowWorkspaces.length === 0 && (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <div className="mb-[16px] h-20 w-20">
            <dotlottie-player
              renderer="svg"
              autoplay
              loop
              className="h-20 w-20"
              src={JSON.stringify(SpinnerAnimation)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
