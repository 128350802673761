/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AnimatedPropertyJSON } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React from 'react';

import { AnimationKeyFrames } from './TimelineKeyFrame';

interface AnimatedRowProps {
  animatedProp: AnimatedPropertyJSON;
  layerId: string;
  type: string;
}

export const KeyframeAnimatedRow: React.FC<AnimatedRowProps> = ({ animatedProp, layerId }) => {
  return (
    <div className={clsx('relative flex h-full w-full items-center', {})}>
      <AnimationKeyFrames animatedProp={animatedProp} layerId={layerId} animatedPropId={animatedProp.id} />
    </div>
  );
};
