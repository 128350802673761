/**
 * Copyright 2022 Design Barn Inc.
 */

import { DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader } from 'three';

import pivotLockedIcon from '~/assets/images/pivot_locked.png';
import pivotUnlockedIcon from '~/assets/images/pivot_unlocked.png';
import { CObject3D } from '~/features/canvas';
import { TransformType } from '~/lib/threejs/TransformControls';

const pivotLockedTexture = await new TextureLoader().loadAsync(pivotLockedIcon);
const pivotUnlockedTexture = await new TextureLoader().loadAsync(pivotUnlockedIcon);

export const getPivotPoint = async (pivotLocked: boolean): Promise<CObject3D> => {
  const pivotPoint = new CObject3D();

  const texture = pivotLocked ? pivotLockedTexture : pivotUnlockedTexture;

  texture.needsUpdate = true;

  const geometry = new PlaneGeometry(16, 16);
  const material = new MeshBasicMaterial({
    map: texture,
    side: DoubleSide,
    depthTest: false,
    transparent: true,
    opacity: 1,
  });

  const mesh = new Mesh(geometry, material);

  mesh.userData['mode'] = TransformType.Pivot;

  pivotPoint.visible = false;
  pivotPoint.renderOrder = 1;
  pivotPoint.add(mesh);

  return pivotPoint;
};

export const updatePivotPointTexture = (pivotPoint: CObject3D, pivotLocked: boolean): void => {
  const texture = pivotLocked ? pivotLockedTexture : pivotUnlockedTexture;

  // Note: giving the mesh a name then retrieving by its name seems to affect
  // the position of the layer when dragging the pivot. So we use the first child
  // of the pivotPoint instead.
  const mesh = pivotPoint.children[0] as Mesh;

  (mesh.material as MeshBasicMaterial).map = texture;
};
