/**
 * Copyright 2022 Design Barn Inc.
 */

import type { PrecompositionAsset } from '@lottiefiles/toolkit-js';
import { Scene } from '@lottiefiles/toolkit-js';
import React, { useState, useCallback, useEffect } from 'react';
import Draggable from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { Window, WindowHeader, FloatingWindow } from '../Window';

import type { DotLottiePlayer } from './TestAnimationPlayer';
import { TestAnimationPlayer } from './TestAnimationPlayer';
import { TestAnimationSubHeader } from './TestAnimationSubHeader';

import { exportPrecompositionToolkitJson, exportToolkitJson, getActiveScene, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const useExportLottie = (setLottieJSON: React.Dispatch<unknown>): { exportJSON: () => void } => {
  const exportJSON = useCallback(async (): Promise<void> => {
    const scene: Scene | PrecompositionAsset | null = getActiveScene(toolkit);

    if (!scene) return;

    const lottie =
      scene instanceof Scene
        ? await exportToolkitJson(toolkit, scene)
        : await exportPrecompositionToolkitJson(toolkit, scene);

    setLottieJSON(lottie);
  }, [setLottieJSON]);

  return { exportJSON };
};

export const TestAnimationDialog: React.FC = () => {
  const nodeRef = React.useRef(null);
  const [testAnimationOpen, setTestAnimationOpen] = useCreatorStore(
    (state) => [state.ui.testAnimationOpen, state.ui.setTestAnimationOpen],
    shallow,
  );
  const [lottieJSON, setLottieJSON] = useState<unknown>('');
  const [playerVersion, setPlayerVersion] = useState<string>('');

  const { exportJSON } = useExportLottie(setLottieJSON);

  // Re-export JSON when reset is clicked
  const handleOnReset = useCallback(() => {
    exportJSON();
  }, [exportJSON]);

  useEffect(() => {
    if (testAnimationOpen) {
      exportJSON();
    }
  }, [testAnimationOpen, exportJSON]);

  const getPlayerVersion = useCallback((player: DotLottiePlayer) => {
    setPlayerVersion(player.getVersions().dotLottiePlayerVersion);
  }, []);

  if (!testAnimationOpen) return null;

  return (
    <FloatingWindow>
      {({ windowProps }) => (
        <Draggable nodeRef={nodeRef} handle="#test-animation-header">
          <Window nodeRef={nodeRef} {...windowProps}>
            <WindowHeader
              title="Test animation for web player"
              onClose={() => setTestAnimationOpen(false)}
              id="test-animation-header"
            />
            <TestAnimationSubHeader playerVersion={playerVersion} onReset={handleOnReset} />
            <TestAnimationPlayer
              getPlayerVersion={getPlayerVersion}
              json={lottieJSON as unknown as Record<string, never>}
            />
          </Window>
        </Draggable>
      )}
    </FloatingWindow>
  );
};
