/**
 * Copyright 2021 Design Barn Inc.
 */

import type { PrecompositionAsset, Scene } from '@lottiefiles/toolkit-js';
import React, { useState, useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { PropertyRow } from './ui/PropertyRow';

import { Duration as DurationIcon } from '~/assets/icons';
import { defaultStyle, NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { MAX_FRAME_RATE, MIN_FRAME_RATE, MAX_TIME_SEC, MIN_TIME_SEC } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setDuration, setPrecompDuration, setPrecompFPS, setSceneFPS, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const Duration: React.FC = () => {
  const [sceneIndex, currentFrameRate, duration, currentPrecompJson, precompFrameRate, precompDuration] =
    useCreatorStore(
      (state) => [
        state.toolkit.sceneIndex,
        state.toolkit.json?.timeline.properties.fr as number,
        state.toolkit.json?.timeline.duration as number,
        state.toolkit.selectedPrecompositionJson,
        state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
        state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      ],
      shallow,
    );

  const [frameRate, setFrameRate] = useState(currentFrameRate);

  const [frameRateDisplay, setFrameRateDisplay] = useState(currentPrecompJson ? precompFrameRate : frameRate);

  const [durationDisplay, setDurationDisplay] = useState(currentPrecompJson ? precompDuration : duration);
  const [sceneId, setSceneID] = useState('main');

  const handleOnChangeDuration = useCallback(
    (result: NumberResult) => {
      stateHistory.beginAction();
      const currentScene = toolkit.scenes[sceneIndex] as Scene;
      const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

      if (currentPrecompJson) {
        const precompAsset = getNodeByIdOnly(currentPrecompJson.id) as PrecompositionAsset;

        setPrecompDuration(precompAsset, result.trueValue);
      } else {
        setDuration(currentScene, result.trueValue);
      }

      setDurationDisplay(result.trueValue);
      emitter.emit(EmitterEvent.TIMELINE_DURATION_UPDATED, result);
      stateHistory.endAction();
    },
    [currentPrecompJson, sceneIndex],
  );

  const handleOnChangeFrameRate = useCallback(
    (option: NumberResult) => {
      setFrameRate(option.value);
      const scene = toolkit.scenes[sceneIndex];

      const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;
      const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

      if (!scene) return;

      if (currentPrecompJson) {
        const precompAsset = getNodeByIdOnly(currentPrecompJson.id) as PrecompositionAsset;

        setPrecompFPS(precompAsset, option.value as number, precompDuration as number);
      } else {
        const currentFrame = useCreatorStore.getState().toolkit.currentFrame;
        const seconds = currentFrame / scene.timeline.frameRate;

        const workAreaFrameStart = useCreatorStore.getState().timeline.workAreaFrameStart;
        const workAreaFrameSeconds = workAreaFrameStart / scene.timeline.frameRate;

        useCreatorStore.getState().timeline.setWorkAreaFrameStart(workAreaFrameSeconds * option.value);

        const workAreaFrameEnd = useCreatorStore.getState().timeline.workAreaFrameEnd;
        const workAreaFrameEndSeconds = workAreaFrameEnd / scene.timeline.frameRate;

        useCreatorStore.getState().timeline.setWorkAreaFrameEnd(workAreaFrameEndSeconds * option.value);

        setSceneFPS(scene, option.value as number, duration as number);
        setCurrentFrame(seconds * option.value);
      }

      setFrameRateDisplay(option.value);
      emitter.emit(EmitterEvent.TIMELINE_FPS_UPDATED);
    },
    [currentPrecompJson, duration, precompDuration, sceneIndex],
  );

  useEffect(() => {
    if (currentPrecompJson) {
      setFrameRateDisplay(precompFrameRate as number);

      setDurationDisplay(precompDuration);
      setSceneID(`${currentPrecompJson.id}-${precompDuration}`);
    } else {
      setFrameRateDisplay(currentFrameRate as number);

      setDurationDisplay(duration);
      setSceneID(`main-${duration}`);
    }
  }, [
    currentFrameRate,
    currentPrecompJson,
    duration,
    durationDisplay,
    handleOnChangeDuration,
    handleOnChangeFrameRate,
    precompDuration,
    precompFrameRate,
  ]);

  const handleOnBlur = useCallback(() => {
    setDurationDisplay(currentPrecompJson ? precompDuration : duration);
  }, [currentPrecompJson, duration, precompDuration]);

  return (
    <div className="flex items-center">
      <Tooltip content="minutes:seconds:frames">
        <div className="w-[86px] rounded-l">
          <NumberInput
            key={sceneId}
            name="duration"
            value={durationDisplay}
            min={MIN_TIME_SEC}
            max={MAX_TIME_SEC}
            label={<DurationIcon className="mx-0 h-4 w-4" />}
            onBlur={handleOnBlur}
            precision={2}
            onChange={handleOnChangeDuration}
            styleClass={{
              label: `${defaultStyle.label} rounded-r-none`,
            }}
            timerSetting={{
              fps: precompDuration ? (precompFrameRate as number) : currentFrameRate,
            }}
            testID="timer-input"
          />
        </div>
      </Tooltip>

      <Tooltip content="Frame Rate">
        <div className="ml-0.5 w-[86px]">
          <NumberInput
            value={frameRateDisplay as number}
            name="Framerate"
            label="FPS"
            min={MIN_FRAME_RATE}
            max={MAX_FRAME_RATE}
            onChange={handleOnChangeFrameRate}
            precision={0}
            styleClass={{
              label: `${defaultStyle.label} rounded-l-none`,
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export const TimeProperty: React.FC = () => {
  return (
    <PropertyRow title="Time">
      <Duration />
    </PropertyRow>
  );
};
