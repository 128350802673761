/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Object3D, Scene, Camera, Intersection, Raycaster } from 'three';
import { Vector3, Vector2, Quaternion } from 'three';

import type { BezierMesh } from '../../types/object';
import { CMesh, CObject3D } from '../../types/object';

import type { RaycasterLayers } from '~/features/canvas';
import { canvasMap } from '~/lib/canvas';
import { Box3 } from '~/lib/threejs/Box3';
import type { Pointer } from '~/lib/threejs/TransformControls';

// get the size of the biggest object
export const getMaxSize = (objects: CObject3D[]): number => {
  let maxSize = 0;

  objects.forEach((object) => {
    const boundingBox = new Box3().setFromObject(object);
    const size = new Vector3();

    boundingBox.getSize(size);
    if (size.length() > maxSize) maxSize = size.length();
  });

  return maxSize;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clearThree = (obj: any): void => {
  if (obj.geometry) {
    obj.geometry.dispose();
  }

  if (obj.material) {
    // in case of map, bumpMap, normalMap, envMap ...
    Object.keys(obj.material).forEach((prop) => {
      if (!obj.material[prop]) {
        return;
      }
      if (typeof obj.material[prop].dispose === 'function') {
        obj.material[prop].dispose();
      }
    });
    obj.material.dispose();
  }

  while (obj.children.length > 0) {
    clearThree(obj.children[0]);
  }

  obj.removeFromParent();
};

export const getCompoundBoundingBox = (object: CObject3D): Box3 | null => {
  const { scale } = object;
  const bounds: Vector3[] = [];

  object.traverse((obj3D) => {
    if (obj3D instanceof CMesh) {
      const pos = obj3D.position;
      const geometry = obj3D.geometry;

      geometry.computeBoundingBox();
      if (geometry.boundingBox) {
        const { max, min } = geometry.boundingBox;

        max.add(pos).multiply(scale);
        min.add(pos).multiply(scale);
        bounds.push(max, min);
      }
    }
  });

  if (bounds.length > 0) {
    return new Box3().setFromPoints(bounds);
  }

  return null;
};

export const getSizeCenter = (object: CObject3D): { center: Vector3; size: Vector3 } => {
  const center = new Vector3();
  const size = new Vector3();

  const worldQuaternion = new Quaternion();

  object.getWorldQuaternion(worldQuaternion);
  const iQuaternion = new Quaternion().copy(worldQuaternion).invert();

  object.quaternion.multiply(iQuaternion);
  const box = new Box3().expandByObject(object);

  object.quaternion.multiply(worldQuaternion);

  box.getCenter(center);
  box.getSize(size);

  return { center, size };
};

// get the object bounding box which lies on the object's local axis, not the world axis
export const updateOBB = (object: CObject3D): void => {
  const worldPosition = new Vector3();
  const worldScale = new Vector3();

  object.getWorldPosition(worldPosition);
  object.getWorldScale(worldScale);

  object.updateMatrix();
};

// evaluate if a certain object is a child of other object with any depth level
export const isChild = (parent: CObject3D, child: CObject3D): boolean => {
  while (child.parent && child.parent instanceof CObject3D) {
    if (parent.toolkitId === child.parent.toolkitId) return true;

    return isChild(parent, child.parent);
  }

  return false;
};

// get 3d position from 2D mouse (x,y) coordinate
// vec2 is a normalized vector
export const unProject = (vec2: Vector2, camera: Camera): Vector3 => {
  const vec3 = new Vector3().set(vec2.x, vec2.y, 0);

  vec3.unproject(camera);

  return vec3;
};

export const findMeshByID = (container: Scene | CObject3D, id: string): CMesh | null => {
  let found: CMesh | null = null;

  container.traverse((child: Object3D | CMesh) => {
    if (found || !(child instanceof CMesh)) return;
    if (child.toolkitId === id) found = child;
  });

  return found;
};

// sync the sings of x, y, z of origin vector with vec3
export const signSyncedVector = (origin: Vector3, vec3: Vector3): Vector3 =>
  new Vector3(
    Math.abs(origin.x) * Math.sign(vec3.x),
    Math.abs(origin.y) * Math.sign(vec3.y),
    Math.abs(origin.z) * Math.sign(vec3.z),
  );

// get 2D coordinate on the canvas from the 3D position
export const toScreenPosition = (
  position: Vector3,
  camera: Camera,
  canvasWidth: number,
  canvasHeight: number,
): Vector2 => {
  const vector = new Vector3().copy(position);

  const widthHalf = 0.5 * canvasWidth;
  const heightHalf = 0.5 * canvasHeight;

  vector.project(camera);
  vector.x = vector.x * widthHalf + widthHalf;
  vector.y = -(vector.y * heightHalf) + heightHalf;

  return new Vector2(vector.x, vector.y);
};

// z value is used for draw order. when copy the position, should not copy z value
export const xyCopy = (from: Vector3, to: Vector3): void => {
  to.x = from.x;
  to.y = from.y;
};

export const aggregatedZRotation = (object: CObject3D | CMesh): number => {
  let aggregatedZ = object.rotation.z;

  let parent = object.parent;

  while (parent) {
    aggregatedZ += parent.rotation.z;
    parent = parent.parent;
  }

  return aggregatedZ;
};

export const getParentLayer = (child: CMesh | CObject3D, targetType: RaycasterLayers): CObject3D | CMesh | null => {
  if (child.layers.isEnabled(targetType)) return child;
  let parent = child.parent as CObject3D | null;

  while (parent) {
    if (parent.layers.isEnabled(targetType)) {
      // FIXME(miljau): the old code written was written with the assumption
      // that the lottie would follow a specific hierachy.
      //
      // eg: if the child is a shape, the parent will most likely be the
      // precomp But lottie doesn't enforce this hierachy so breakage occurs
      // when there is a precomp > shape > shape
      //

      if (parent.precompId) {
        const precompObject = canvasMap.get(parent.precompId);

        if (precompObject) {
          return precompObject;
        }
      }

      return parent as CObject3D;
    }
    parent = parent.parent as CObject3D;
  }

  return null;
};

export const intersectObjectsWithRay = (
  objects: CObject3D[],
  raycaster: Raycaster,
  includeInvisible: boolean,
): Intersection | false | undefined => {
  const allIntersections = raycaster.intersectObjects(objects, true);

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < allIntersections.length; i += 1) {
    if (allIntersections[i]?.object.visible || includeInvisible) {
      return allIntersections[i];
    }
  }

  return false;
};

export const intersectObjectWithRay = (
  object: CObject3D | Object3D,
  raycaster: Raycaster,
  includeInvisible: boolean,
): Intersection | false | undefined => {
  const allIntersections = raycaster.intersectObject(object, true);

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < allIntersections.length; i += 1) {
    if (allIntersections[i]?.object.visible || includeInvisible) {
      return allIntersections[i];
    }
  }

  return false;
};

export const getPointer = (event: PointerEvent, domElement: HTMLElement): Pointer => {
  if (domElement.ownerDocument.pointerLockElement) {
    return {
      x: 0,
      y: 0,
      button: event.button,
    };
  } else {
    const rect = domElement.getBoundingClientRect();

    return {
      x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
      y: (-(event.clientY - rect.top) / rect.height) * 2 + 1,
      button: event.button,
    };
  }
};

export const getAccumulatedOpacity = (object: CObject3D | CMesh, upLimit?: CObject3D | BezierMesh): number => {
  const parent = object.parent as CObject3D | null;

  if (!parent) return 1;
  if (upLimit?.toolkitId === parent.toolkitId) {
    return parent.opacity;
  } else if (typeof parent.opacity === 'number') {
    return parent.opacity * getAccumulatedOpacity(parent, upLimit);
  }

  return 1;
};

export const getClosestPointToLine = (point: Vector3, lineStart: Vector3, lineEnd: Vector3): Vector3 => {
  // Compute the line direction vector
  const line = new Vector3().subVectors(lineEnd, lineStart);

  // Compute the vector from point to lineStart
  const ptl = new Vector3().subVectors(point, lineStart);

  // Compute the projection of ptl onto line
  const projectionLength = ptl.dot(line) / line.lengthSq();
  const projectionPoint = lineStart.clone().add(line.clone().multiplyScalar(projectionLength));

  return projectionPoint;
};
