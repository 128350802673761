/**
 * Copyright 2023 Design Barn Inc.
 */

import type {
  AnimatedPropertyJSON,
  GroupShape,
  Shape,
  AVLayer,
  PrecompositionLayer,
  ShapeLayer,
} from '@lottiefiles/toolkit-js';
import { flatten, uniq } from 'lodash-es';

import { NumberInputPrecision } from '~/data/constant';
import { layerMap } from '~/lib/layer';
import { useCreatorStore } from '~/store';

export const getPathToRoot = (id: string): string[] => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
  const node = getNodeByIdOnly(id);

  if (node) {
    if (node.parent) {
      return [node.nodeId, ...getPathToRoot(node.parent.nodeId)];
    } else {
      return [node.nodeId];
    }
  }

  return [];
};

export const createGetCurrentKeyframe = (cf: number): ((animatedProperty: AnimatedPropertyJSON) => string) => {
  const currentFrame = cf;

  return (animatedProperty: AnimatedPropertyJSON): string => {
    return animatedProperty.keyFrames.find((keyframe) => keyframe.frame === currentFrame)?.frameId || '';
  };
};

export const checkHasActiveKeyFrame = (): ((animatedProperty: AnimatedPropertyJSON) => string) => {
  return (animatedProperty: AnimatedPropertyJSON): string => {
    return animatedProperty.keyFrames.length > 0 ? animatedProperty.keyFrames[0]?.frameId || '' : '';
  };
};

export const findParentLayer = (
  selectedNodeId: string,
): Record<string, unknown> | Array<Record<string, unknown>> | null => {
  const selectedMap = layerMap.get(selectedNodeId);

  if (!selectedMap) return null;
  if (selectedMap.parent.length === 0) {
    return { id: selectedNodeId, layer: selectedMap };
  } else {
    return selectedMap.parent
      .map((parentId: string) => findParentLayer(parentId))
      .filter((item) => item !== null) as Array<Record<string, unknown>>;
  }
};

export const getRootLayer = (id: string): Record<string, string | unknown> | null => {
  let rootLayers = findParentLayer(id);

  rootLayers = Array.isArray(rootLayers)
    ? (uniq(flatten(rootLayers)) as Array<Record<string, unknown>>)
    : (rootLayers as Record<string, unknown>);

  if (Array.isArray(rootLayers)) {
    return uniq(rootLayers)[0] as Record<string, unknown>;
  } else return rootLayers;
};

export const getSelectedDrawOrder = (overrideId?: string): number | null => {
  let newDrawOrder = null;

  // TODO: handle multi select
  const selectedNodeId = useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string;
  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

  const selectedId = selectedNodeId || selectedPrecompositionId;

  if (selectedId) {
    let id = null;

    if (selectedPrecompositionId) {
      id = selectedNodeId || selectedPrecompositionId;
    } else if (selectedNodeId) {
      const rootLayer = getRootLayer(selectedNodeId);

      if (rootLayer) {
        id = rootLayer['id'];
      }
    }

    if (overrideId) {
      id = overrideId;
    }

    const updatedRootShape = useCreatorStore.getState().toolkit.getNodeByIdOnly(id as string) as ShapeLayer | null;

    if (updatedRootShape && updatedRootShape.drawOrder) newDrawOrder = updatedRootShape.drawOrder;
    else newDrawOrder = 0;
  } else {
    newDrawOrder = 0;
  }

  return newDrawOrder;
};

export const isEqualHierarchy = (nodeIds: string[]): boolean => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
  const firstNode = getNodeByIdOnly(nodeIds[0] as string) as AVLayer | GroupShape | Shape | PrecompositionLayer;

  return nodeIds.every((id) => {
    const node = getNodeByIdOnly(id) as AVLayer | GroupShape | Shape | PrecompositionLayer;

    if (node.parent && firstNode.parent) {
      return node.parent.nodeType === firstNode.parent.nodeType;
    }

    return node.type === firstNode.type;
  });
};

export const roundArr = (values: number[], precision = NumberInputPrecision): number[] => {
  if (values.length > 0) {
    return values.map((item: number) => Math.round(item * 10 ** precision) / 10 ** precision);
  }

  return values;
};
