/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback, useState } from 'react';
import Draggable from 'react-draggable';

// eslint-disable-next-line no-restricted-imports
import type { IDragToCanvas } from '~/features/canvas/components/DragToConvasContainer';
import { useCreatorStore } from '~/store';
import { GlobalCursorType } from '~/store/uiSlice';

interface DragToCanvasProps {
  addToCanvas: () => void;
  children: React.ReactNode;
  element: React.ReactNode;
  fromStatic?: boolean;
  onHandleDrag?: (dragged: boolean) => void;
  popupOffset?: Record<string, number>;
  popupSize?: Record<string, number>;
}

const DRAG_TO_CANVAS_OFFSET_X = 75;
const DRAG_TO_CANVAS_OFFSET_Y = 75;

const POPUP_WIDTH = 72;
const POPUP_HEIGHT = 72;

export const DragToCanvas: React.FC<DragToCanvasProps> = ({
  addToCanvas,
  children,
  element,
  popupSize = { width: POPUP_WIDTH, height: POPUP_HEIGHT },
  popupOffset = { x: DRAG_TO_CANVAS_OFFSET_X, y: DRAG_TO_CANVAS_OFFSET_Y },
  fromStatic = false,
}) => {
  const [isClick, setIsClick] = useState(false);
  const setGlobalCursor = useCreatorStore((state) => state.ui.setGlobalCursor);
  const setDragToCanvas = useCreatorStore((state) => state.ui.setDragToCanvas);

  const { height, width } = popupSize;
  const offsetX = popupOffset.x;
  const offsetY = popupOffset.y;

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragStop = useCallback(
    (event: MouseEvent) => {
      setIsDragging(false);

      setGlobalCursor(GlobalCursorType.DEFAULT);

      if (isClick) {
        setDragToCanvas({
          executed: true,
          component: null,
          fromStatic,
          dragging: false,
        } as IDragToCanvas);
      } else {
        const canvasContainer = document.getElementById('artboard-canvas');

        if (!canvasContainer) return;

        const containerRect = canvasContainer.getBoundingClientRect();

        let withinCanvas = true;

        const mouseX = event.clientX;

        const mouseY = event.clientY;

        if (mouseX < containerRect.left || mouseX >= containerRect.right) withinCanvas = false;
        if (mouseY < containerRect.top || mouseY >= containerRect.bottom) withinCanvas = false;

        if (withinCanvas) {
          setDragToCanvas({ executed: true, component: null, fromStatic } as IDragToCanvas);
        } else {
          setDragToCanvas({ dragging: false, executed: false, component: null, fromStatic } as IDragToCanvas);
        }
      }
    },
    [setGlobalCursor, isClick, setDragToCanvas, setIsDragging, fromStatic],
  );

  const startDragging = useCallback(
    (component: React.ReactNode) => {
      setIsDragging(true);

      setDragToCanvas({
        executed: false,
        execute: addToCanvas as () => Promise<void>,
        component,
        coord: null,
        width: width as number,
        height: height as number,
      });
    },
    [setDragToCanvas, addToCanvas, width, height],
  );

  const handleDrag = useCallback(
    (evt: MouseEvent) => {
      if (isDragging) {
        setGlobalCursor(GlobalCursorType.GRABBING);

        evt.preventDefault();
        const x = evt.clientX - (offsetX as number);
        const y = evt.clientY - (offsetY as number);

        setDragToCanvas({ dragging: true, coord: { x, y } });
      }
      setIsClick(false);
    },
    [setDragToCanvas, isDragging, offsetX, offsetY, setGlobalCursor],
  );

  return (
    <>
      <Draggable
        axis="none"
        onStart={() => startDragging(element)}
        onStop={handleDragStop}
        onDrag={handleDrag}
        onMouseDown={() => setIsClick(true)}
        bounds="parent"
        cancel=".profile-link, .animation-link"
      >
        {children}
      </Draggable>
    </>
  );
};
