/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DndContext, useSensor, useSensors, MeasuringStrategy, pointerWithin } from '@dnd-kit/core';
import type {
  Active,
  ClientRect,
  DroppableContainer,
  Collision,
  DragEndEvent,
  DragMoveEvent,
  PointerSensorOptions,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ShapeType, LayerType } from '@lottiefiles/toolkit-js';
import type { AVLayer, LayerJSON, Scene } from '@lottiefiles/toolkit-js';
import { inRange, some } from 'lodash-es';
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { getFlattenLayers } from '../../helper';

import { CustomMouseSensor, CustomPointerSensor, CustomTouchSensor } from './customDnd';
import { FolderTreeItem } from './FolderTreeItem';
import { SortableTreeItemLayer } from './SortableTreeItemLayer';
import type { TreeItemData } from './TreeItemData';
import type { FlattenedItem, SensorContext } from './types';
import { flattenTree, getProjection, sortByDrawOrders } from './utilities';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { layerMap } from '~/lib/layer';
import { LAYER_TYPES } from '~/lib/toolkit/constant';
import { useCreatorStore } from '~/store';
import { DragDirection } from '~/store/timelineSlice';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const defaultPointerSensorOptions: PointerSensorOptions = {
  activationConstraint: {
    distance: 10,
  },
};

interface DraggableWrapperProp {
  creatorItems: unknown[];
  layers: LayerJSON[];
}

interface CollisionDetectionProps {
  active: Active;
  collisionRect: ClientRect;
  droppableContainers: DroppableContainer[];
  droppableRects: Map<UniqueIdentifier, ClientRect>;
  pointerCoordinates: { x: number; y: number } | null;
}

const lastExpandedCollisions = {
  expandedCollisions: [],
  projected: null,
  lastOverId: null,
};

export const DraggableWrapper: React.FC<DraggableWrapperProp> = ({ creatorItems, layers }) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const items = layers;

  const [disableSorting, indentationWidth, setLayerConfiguration, dragDirection, getNodeByIdOnly] = useCreatorStore(
    (state) => [
      state.timeline.layerConfiguration.disableSorting,
      state.timeline.layerConfiguration.indentationWidth,
      state.timeline.setLayerConfiguration,
      state.timeline.layerConfiguration.dragDirection,
      state.toolkit.getNodeByIdOnly,
    ],
    shallow,
  );

  const flattenedItems = useMemo(
    () => getFlattenLayers(items, activeId as string), // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeId, items, items.length],
  );

  const projected = getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth ?? 0);

  if (projected !== null) {
    lastExpandedCollisions.projected = projected;
  }

  const sensorContext: SensorContext<TreeItemData> = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const sensors = useSensors(
    useSensor(CustomPointerSensor, defaultPointerSensorOptions),
    useSensor(CustomMouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(CustomTouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 100,
      },
    }),
  );

  const moveLayersOrder = useCallback(
    (up: boolean, toFrontBack: boolean = false) => {
      const selectedNodeIds = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);

      if (!selectedNodeIds.length) return;
      const setLayerDrawOrder = useCreatorStore.getState().toolkit.setLayerDrawOrder;

      // Get the latest layers
      const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

      let updatedLayers = useCreatorStore.getState().toolkit.json?.allLayers || [];

      if (selectedPrecompositionId !== null) {
        const precomNode = getNodeByIdOnly(selectedPrecompositionId);

        updatedLayers = (precomNode as Scene).state.allLayers;
      }

      if (updatedLayers.length === 0) return;

      if (toFrontBack) {
        if (up) {
          setLayerDrawOrder(updatedLayers.length - 1, selectedNodeIds);
        } else {
          setLayerDrawOrder(0, selectedNodeIds);
        }
      } else {
        const index = updatedLayers.findIndex((item) => selectedNodeIds.includes(item.id));
        const isTop = index === 0;
        const isBottom = index === updatedLayers.length - 1;
        const topBottom = isTop || isBottom;

        let increment = 0;

        if ((isTop && up) || (!topBottom && up)) increment += 1;
        else if ((isBottom && !up) || (!topBottom && !up)) increment -= 1;

        setLayerDrawOrder(index + increment, selectedNodeIds);
      }

      emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL);
    },
    [getNodeByIdOnly],
  );

  useEffect(() => {
    emitter.on(EmitterEvent.TIMELINE_LAYER_MOVE_UP, () => {
      moveLayersOrder(false);
    });
    emitter.on(EmitterEvent.TIMELINE_LAYER_MOVE_DOWN, () => {
      moveLayersOrder(true);
    });
    emitter.on(EmitterEvent.TIMELINE_LAYER_MOVE_MOST_UP, () => {
      moveLayersOrder(false, true);
    });
    emitter.on(EmitterEvent.TIMELINE_LAYER_MOVE_MOST_DOWN, () => {
      moveLayersOrder(true, true);
    });
  }, [moveLayersOrder]);

  const resetState = useCallback(() => {
    setLayerConfiguration({ hoverOverId: null, dragDirection: DragDirection.TOP, dragDisabled: false });
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
  }, [setLayerConfiguration]);

  const handleDragStart = useCallback((): void => {
    const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);

    const sortedLayerIds = sortByDrawOrders(selectedNodes);

    setSelectedIds(sortedLayerIds);

    setActiveId(sortedLayerIds[0] as string);
    setOverId(sortedLayerIds[0] as string);
    setLayerConfiguration({ hoverOverId: null });
  }, [setLayerConfiguration]);

  const handleDragOver = useCallback(
    (event: DragMoveEvent): void => {
      const { over } = event;
      const dragOverId = over?.id ?? null;

      if (dragOverId !== null) {
        lastExpandedCollisions.lastOverId = dragOverId;
      }

      setOverId(dragOverId);
    },
    [setOverId],
  );

  const getExpectedIndexAfterDrag = useCallback(
    (
      list: string[],
      selectFromIndex: number,
      dragFromBottomToTop: boolean,
      layerBelowId: string,
      layerAboveId: string,
      overItem: FlattenedItem<TreeItemData>,
      diffParentId: boolean,
    ) => {
      let newIndex = 0;

      const selectFromId = list[selectFromIndex];
      const mostTop = list[0] === overItem.id;

      const mostBottom = list[list.length - 1] === overItem.id;

      if (mostTop && dragDirection === DragDirection.TOP) {
        newIndex = 0;
      } else if (mostBottom && dragDirection === DragDirection.BOTTOM) {
        newIndex = list.length - 1;
      } else if ([layerAboveId, layerBelowId].includes(selectFromId as string)) {
        newIndex = -1;
      } else if (dragFromBottomToTop) {
        newIndex = list.findIndex((itemId) => itemId === layerBelowId);
      } else if (diffParentId) {
        newIndex = list.findIndex((itemId) => itemId === layerBelowId);
      } else {
        newIndex = list.findIndex((itemId) => itemId === layerAboveId);
      }

      return newIndex;
    },
    [dragDirection],
  );

  const handleDragEnd = useCallback(
    ({ over }: DragEndEvent): void => {
      const layerConfig = useCreatorStore.getState().timeline.layerConfiguration;
      const moveToDifferentLevelLayer = useCreatorStore.getState().toolkit.moveToDifferentLevelLayer;
      const setLayerShapeIndex = useCreatorStore.getState().toolkit.setLayerShapeIndex;

      const finalDragDirection = layerConfig.dragDirection;

      if (layerConfig.dragDisabled) {
        resetState();

        return;
      }
      // setExpandedCollisions
      let _projected = projected;
      let _over = over;

      if (_projected === null) {
        _projected = lastExpandedCollisions.projected;
        _over = { id: lastExpandedCollisions.lastOverId };
      }

      if (_projected && _over) {
        const { depth, parentId } = _projected;

        const clonedItems: Array<FlattenedItem<TreeItemData>> = flattenTree(items);

        const overIndex = clonedItems.findIndex(({ id }) => id === _over.id);
        const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);

        if (overIndex === -1 || activeIndex === -1) return;

        const activeTreeItem = clonedItems[activeIndex];
        const overItem = clonedItems[overIndex];

        if (!activeTreeItem || !overItem) return;

        clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

        const updatedOverIndex = overIndex;

        const sortedItems = arrayMove(clonedItems, activeIndex, updatedOverIndex);

        const newActiveItem = sortedItems.find((x) => x.id === activeId)!;

        const isRootLevelDrag = Boolean(LAYER_TYPES.includes(newActiveItem.type));

        const activeLevel = layerMap.get(newActiveItem.id).level;
        const dragOverLevel = layerMap.get(_over.id).level;
        const nestedButSameLevel = activeLevel === dragOverLevel;

        const dragOverlay = document.getElementById('dragOverlayLine');

        if (!dragOverlay && dragDirection !== DragDirection.INSIDE) return;

        let parentLi = null;
        let allLiElements = null;
        let dragLineIndex = 0;

        if (dragOverlay && dragDirection !== DragDirection.INSIDE) {
          parentLi = dragOverlay.closest('li');
          allLiElements = Array.from(document.querySelectorAll('li'));

          if (parentLi) {
            const _dragLineIndex = allLiElements.indexOf(parentLi) as number;

            if (dragDirection === DragDirection.BOTTOM && _dragLineIndex !== allLiElements.length - 1) {
              dragLineIndex = _dragLineIndex + 1;
            } else {
              dragLineIndex = _dragLineIndex;
            }
          }
        }

        const layerAboveId = dragLineIndex > 0 ? allLiElements[dragLineIndex - 1].id : null;
        const layerBelowId = dragLineIndex > 0 ? allLiElements[dragLineIndex].id : null;
        const selectFromIndex = newActiveItem.index;
        const dragFromBottomToTop = activeIndex > overIndex;

        if (activeLevel > 0) {
          if (finalDragDirection === DragDirection.INSIDE) {
            if ([ShapeType.GROUP, LayerType.SHAPE].includes(overItem.type)) {
              const dragInParentId = _over.id;
              const parentChild = layerMap.get(dragInParentId).children;

              moveToDifferentLevelLayer(parentChild.length, selectedIds, dragInParentId);
              emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_DIFFERENT_LEVEL);
            }
          } else {
            let newParentChilds = null;

            const diffParentId = newActiveItem.parent?.id !== overItem.parentId;

            const activeParentId: string =
              nestedButSameLevel && !diffParentId ? (newActiveItem.parent?.id as string) : overItem.parentId;

            if (activeParentId) {
              newParentChilds = layerMap.get(activeParentId as string).children;

              if (newParentChilds.length > 0) {
                newParentChilds = newParentChilds
                  .map((itemId) => ({ id: itemId, ...layerMap.get(itemId) }))
                  .filter((item) => !item.isAppearance)
                  .map((item) => item.id);
              }
            }

            if (allLiElements && newParentChilds && newParentChilds.length > 0) {
              const newIndex = getExpectedIndexAfterDrag(
                newParentChilds,
                selectFromIndex,
                dragFromBottomToTop,
                layerBelowId as string,
                layerAboveId as string,
                overItem,
                diffParentId,
              );

              if (newIndex !== -1) {
                if (nestedButSameLevel && !diffParentId) {
                  setLayerShapeIndex(newIndex, selectedIds, activeParentId);
                  emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL);
                } else {
                  moveToDifferentLevelLayer(newIndex, selectedIds, activeParentId);
                  emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_DIFFERENT_LEVEL);
                }
              }
            }
          }
        } else if (isRootLevelDrag && clonedItems.length > 0) {
          // Filter selectedIds (other than activeId)
          // So they don't interfere with the index

          const filteredRootItems = clonedItems
            .filter((item) => LAYER_TYPES.includes(item.type))
            .map((item) => item.id);

          const newIndex = getExpectedIndexAfterDrag(
            filteredRootItems,
            selectFromIndex,
            dragFromBottomToTop,
            layerBelowId,
            layerAboveId,
            overItem,
            false,
          );

          if (newIndex > -1) {
            const setLayerDrawOrder = useCreatorStore.getState().toolkit.setLayerDrawOrder;

            setLayerDrawOrder(newIndex, selectedIds);
            emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL);
          }
        }
      }
      resetState();
    },
    [resetState, getExpectedIndexAfterDrag, projected, items, dragDirection, activeId, selectedIds],
  );

  const handleDragCancel = useCallback((): void => {
    resetState();
  }, [resetState]);

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const getCurrentParentId = (layerId: string): string => {
    const currentLayer = layerMap.get(layerId);
    let currentParentId = null;

    currentLayer.parent.forEach((parentId) => {
      const parentLayer = layerMap.get(parentId);

      if (parentLayer.level === currentLayer.level - 1) {
        currentParentId = parentId;
      }
    });

    return currentParentId;
  };

  const isLastLayer = (layerId: string): boolean => {
    const currentLayer = layerMap.get(layerId);
    const currentParentId = getCurrentParentId(layerId);

    if (currentParentId) {
      const currentParent = layerMap.get(currentParentId);

      if (!currentParent) return false;

      const parentChild = currentParent.children;

      return parentChild[parentChild.length - 1] === layerId;
    } else {
      const filteredLayerMap = [];

      for (const [id, obj] of layerMap.entries()) {
        if (currentLayer && obj.type !== 'animated' && currentLayer.level === 0 && obj.level === 0) {
          filteredLayerMap.push(id);
        }
      }

      return filteredLayerMap[filteredLayerMap.length - 1] === layerId;
    }
  };

  const customCollisionDetectionAlgorithm = (args: CollisionDetectionProps): Collision[] => {
    const expandedLayerIds = useCreatorStore.getState().timeline.expandedLayerIds;
    const { droppableContainers } = args;

    const expandedCollisions = pointerWithin(args);

    if (expandedCollisions.length !== 0) {
      lastExpandedCollisions.expandedCollisions = [...expandedCollisions] as Collision[];
    }

    if (expandedCollisions.length > 0) {
      const pointerWithinLayerId = expandedCollisions[0]?.id;

      const { bottom, top } = droppableContainers.find((item) => item.id === pointerWithinLayerId)?.rect.current || {
        bottom: 0,
        top: 0,
      };

      // space between layers
      const offset = 3;

      const dragCursorY = Number(args.pointerCoordinates?.y);

      const cursorWithinLayer = inRange(dragCursorY, Number(top) + offset, Number(bottom) - offset);

      const nodeActiveId = args.active.id;

      const activeLayer = getNodeByIdOnly(nodeActiveId as string) as AVLayer;
      const targetLayer = getNodeByIdOnly(pointerWithinLayerId as string) as AVLayer;

      const activeMapLayer = layerMap.get(nodeActiveId as string);
      const targetMapLayer = layerMap.get(pointerWithinLayerId as string);

      let isFirstLayer = targetLayer.parent?.state?.shapes?.findIndex((item) => item.id === pointerWithinLayerId) === 0;
      const rootLayers = targetLayer.parent?.state.allLayers;

      if (rootLayers) {
        isFirstLayer = rootLayers.findIndex((item) => item.id === pointerWithinLayerId) === 0;
      }
      let overlayDragDirection = null;

      const cursorAboveTargetLayer = dragCursorY < top + offset;

      if (isFirstLayer && cursorAboveTargetLayer) {
        overlayDragDirection = DragDirection.TOP;
      } else {
        overlayDragDirection =
          dragCursorY > top + offset && dragCursorY < bottom - offset ? DragDirection.BOTTOM : DragDirection.TOP;
      }

      if (!activeMapLayer || !targetMapLayer) return [];

      const RESTRICT_USER_INTERACTIONS = {
        ROOT_LAYER_DRAG_IN:
          cursorWithinLayer && activeLayer.type === LayerType.SHAPE && targetLayer.type === activeLayer.type,
        NESTED_LAYERS_DRAG_ON_ROOT: !cursorWithinLayer && activeMapLayer.level > 0 && targetMapLayer.level === 0,
        ROOT_LAYER_TO_DRAG_ON_NESTED_LEVEL:
          !cursorWithinLayer && activeLayer.type === LayerType.SHAPE && targetMapLayer.level > 0,
        MAX_LEVEL_DRAG_ON: [ShapeType.GROUP, LayerType.SHAPE].includes(activeLayer.type) && targetMapLayer.level > 1,
        MAX_LEVEL_DRAG_IN:
          cursorWithinLayer &&
          [ShapeType.GROUP, LayerType.SHAPE].includes(activeLayer.type) &&
          targetMapLayer.level >= 1,
      };

      const disabled = some(RESTRICT_USER_INTERACTIONS, (value: boolean) => value === true);

      if (disabled) {
        setLayerConfiguration({
          hoverOverId: pointerWithinLayerId as string | null,
          dragDirection: cursorWithinLayer ? DragDirection.INSIDE : DragDirection.TOP,
          dragDisabled: true,
        });

        return expandedCollisions;
      }

      const lastDroppableContainer = droppableContainers.find((item) => item.id === targetLayer.nodeId);

      const lastContainerBottom = lastDroppableContainer?.rect.current?.bottom;

      const layerDragDirection = DragDirection.BOTTOM;

      // Allow draggable line visible to the end of layer (not expanded)
      let atLastOffsetY = 0;

      if (isLastLayer(pointerWithinLayerId) && !expandedLayerIds.includes(pointerWithinLayerId)) atLastOffsetY = 10;

      if (isLastLayer(targetLayer.nodeId) && Number(dragCursorY) + atLastOffsetY > (lastContainerBottom || 0)) {
        setLayerConfiguration({
          hoverOverId: targetLayer.nodeId as string | null,
          dragDirection: layerDragDirection,
          dragDisabled: false,
        });
        setOverId(targetLayer.nodeId);
      } else {
        if (cursorWithinLayer && [ShapeType.GROUP, LayerType.SHAPE].includes(targetLayer.type)) {
          overlayDragDirection = DragDirection.INSIDE;
        }

        setLayerConfiguration({
          hoverOverId: pointerWithinLayerId as string | null,
          dragDirection: overlayDragDirection,
          dragDisabled: false,
        });
        setOverId(pointerWithinLayerId as string);
      }
    }

    return expandedCollisions;
  };

  return (
    <>
      {
        <DndContext
          collisionDetection={customCollisionDetectionAlgorithm}
          measuring={measuring}
          modifiers={[restrictToVerticalAxis]}
          {...(disableSorting === false
            ? {
                sensors,
                onDragStart: handleDragStart,
                onDragOver: handleDragOver,
                onDragEnd: handleDragEnd,
                onDragCancel: handleDragCancel,
              }
            : {})}
        >
          <SortableContext
            items={creatorItems}
            {...(disableSorting === false
              ? {
                  strategy: verticalListSortingStrategy,
                }
              : {})}
          >
            {creatorItems.map((layer) => {
              return <SortableTreeItemLayer key={layer.id} layer={layer} TreeItemComponent={FolderTreeItem} />;
            })}
          </SortableContext>
        </DndContext>
      }
    </>
  );
};
