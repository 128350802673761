/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer } from '@lottiefiles/toolkit-js';
import { clamp, throttle } from 'lodash-es';
import { Vector3, Quaternion, MathUtils } from 'three';

import { NumberResultName } from '../../../components/Elements/Input/types';
import type { NumberResult } from '../../../components/Elements/Input/types';
import type { NumberInputRatioResult } from '../../../components/Elements/Input/useDualNumberInputRatio';
import { NumberInputRatioResultType } from '../../../components/Elements/Input/useDualNumberInputRatio';
import type { CObject3D } from '../types';

import { CANVAS_ROUND_PRECISION, UserDataMap } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { rotationAxis, TransformType } from '~/lib/threejs/TransformControls';
import type { Scalar2D } from '~/lib/toolkit';
import { AnimatedType, toolkit, stateHistory, roundArr } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import type { AlignPivotDirection } from '~/store/uiSlice';

const setAnimatedValue = useCreatorStore.getState().toolkit.setAnimatedValue;
const setStaticPivot = useCreatorStore.getState().toolkit.setStaticPivot;
const setCurrentTransform = useCreatorStore.getState().toolkit.setCurrentTransform;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

export const getRealPosition = (object: CObject3D, position: Vector3 = object.position): Vector3 => {
  const quat = new Quaternion().setFromAxisAngle(rotationAxis, -object.rotation.z);
  const offset = new Vector3().copy(object.toolkitAnchorPosition);

  offset.multiply(object.scale).applyQuaternion(quat);

  return offset.add(position);
};

export const updateSelectedNode = throttle(
  (object: CObject3D, transformType: TransformType, isNegativeRotation?: boolean, rotationsCount?: number): void => {
    const currentTransform = useCreatorStore.getState().toolkit.currentTransform;

    if (transformType === TransformType.Pivot || transformType === TransformType.Translation) {
      const realPos = getRealPosition(object);

      setCurrentTransform({
        ...currentTransform,
        position: roundArr([realPos.x, realPos.y], CANVAS_ROUND_PRECISION) as Scalar2D,
      });
    } else if (transformType === TransformType.Rotation) {
      const deg = Math.round(MathUtils.radToDeg(object.rotation.z));

      const adjustedDeg = deg < 0 ? 180 + (180 - Math.abs(deg)) : deg;

      const finalDeg = (rotationsCount || 0) * 360 + (isNegativeRotation ? -(360 - adjustedDeg) : adjustedDeg);

      setCurrentTransform({ ...currentTransform, rotation: finalDeg });
    } else {
      setCurrentTransform({
        ...currentTransform,
        scale: roundArr([object.scale.x * 100, object.scale.y * 100], CANVAS_ROUND_PRECISION) as Scalar2D,
      });
    }
  },
  100,
);

export const updateToolkit = (
  objects: CObject3D[],
  transformType: TransformType,
  multiselectPivotOffset?: Vector3 | null,
  isNegativeRotation?: boolean,
  rotationCount?: number,
): void => {
  // updateToolkit is called at the end of the canvas operation.
  // Having updateSelectedNode throttled means there is a possibility
  // that a throttled event gets fired after updateToolkit has been called.
  // this is to prevent such scenarios
  updateSelectedNode.cancel();

  stateHistory.beginAction();

  objects.forEach((object) => {
    object.updateMatrix();
    if (transformType === TransformType.Pivot || transformType === TransformType.Translation) {
      const realPos = getRealPosition(object);

      setAnimatedValue(
        AnimatedType.POSITION,
        roundArr([realPos.x, realPos.y], CANVAS_ROUND_PRECISION),
        object.toolkitId,
      );

      if (multiselectPivotOffset) {
        toolkit.setData(UserDataMap.MultiPivotOffset, multiselectPivotOffset);
        toolkit.removeData(UserDataMap.MultiPivot);
      }

      // TODO: update pivot in canvas
    } else if (transformType === TransformType.Rotation) {
      const deg = Math.round(MathUtils.radToDeg(object.rotation.z));
      // deg goes from 0 to 180.
      // numbers greater than 0 are represented with negative values.
      const adjustedDeg = deg < 0 ? 180 + (180 - Math.abs(deg)) : deg;
      // the normalized value will be from 0-360

      const finalDeg = (rotationCount || 0) * 360 + (isNegativeRotation ? -(360 - adjustedDeg) : adjustedDeg);

      if (multiselectPivotOffset) {
        const realPos = getRealPosition(object);

        setAnimatedValue(
          AnimatedType.POSITION,
          roundArr([realPos.x, realPos.y], CANVAS_ROUND_PRECISION),
          object.toolkitId,
        );
      }

      setAnimatedValue(AnimatedType.ROTATION, [finalDeg], object.toolkitId);
    } else {
      const realPos = getRealPosition(object);

      if (multiselectPivotOffset) {
        setAnimatedValue(
          AnimatedType.POSITION,
          roundArr([realPos.x, realPos.y], CANVAS_ROUND_PRECISION),
          object.toolkitId,
        );
      }

      setAnimatedValue(
        AnimatedType.SCALE,
        roundArr([object.scale.x * 100, object.scale.y * 100], CANVAS_ROUND_PRECISION),
        object.toolkitId,
      );
    }
  });
  emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });

  stateHistory.endAction();
  // update the pathshape bones once transformation is applied
};

export const updatePivot = (object: CObject3D, pivot: Vector3, fromPivotSelector?: AlignPivotDirection): void => {
  setStaticPivot(Math.round(pivot.x), Math.round(pivot.y), object.toolkitId);
  const setAnchorPointsActive = useCreatorStore.getState().ui.setAnchorPointsActive;

  if (fromPivotSelector) {
    setAnchorPointsActive(object.toolkitId as string, {
      [fromPivotSelector]: true,
    });
  }

  emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });
};

export enum UITransformType {
  Opacity = 'Opacity',
  Pivot = 'Pivot',
  Rotation = 'Rotation',
  RotationCount = 'RotationCount',
  Scale = 'Scale',
  Translation = 'Translation',
}

export const updateGroupFromUI = (
  transformType: UITransformType,
  result: NumberResult | NumberInputRatioResult,
  animatedType?: AnimatedType,
): void => {
  const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;

  // Dragging and arrow keys add values to the previous transform
  // Otherwise, the new value overwrites the previous transform
  const addToPreviousTransform = result.dragged || result.arrowKeyPressed;

  switch (transformType) {
    case UITransformType.Translation:
      selectedNodesInfo.forEach((nodeInfo) => {
        const node = getNodeByIdOnly(nodeInfo.nodeId) as AVLayer;

        if ((result as NumberResult).name === NumberResultName.X) {
          const newXValue = addToPreviousTransform ? node.position.value.x + (result.change as number) : result.value;

          setAnimatedValue(
            AnimatedType.POSITION,
            roundArr([newXValue, node.position.value.y] as number[], CANVAS_ROUND_PRECISION),
            nodeInfo.nodeId,
          );
        } else if ((result as NumberResult).name === NumberResultName.Y) {
          const newYValue = addToPreviousTransform ? node.position.value.y + (result.change as number) : result.value;

          setAnimatedValue(
            AnimatedType.POSITION,
            roundArr([node.position.value.x, newYValue] as number[], CANVAS_ROUND_PRECISION),
            nodeInfo.nodeId,
          );
        }
      });
      break;

    case UITransformType.Scale:
      selectedNodesInfo.forEach((nodeInfo) => {
        const node = getNodeByIdOnly(nodeInfo.nodeId) as AVLayer;

        if ((result as NumberInputRatioResult).type === NumberInputRatioResultType.ScaleX) {
          const newXValue = addToPreviousTransform ? node.scale.value.x + (result.change as number) : result.value;

          setAnimatedValue(AnimatedType.SCALE, [newXValue, node.scale.value.y], nodeInfo.nodeId);
        } else if ((result as NumberInputRatioResult).type === NumberInputRatioResultType.ScaleY) {
          const newYValue = addToPreviousTransform ? node.scale.value.y + (result.change as number) : result.value;

          setAnimatedValue(AnimatedType.SCALE, [node.scale.value.x, newYValue], nodeInfo.nodeId);
        } else if ((result as NumberInputRatioResult).type === NumberInputRatioResultType.Both) {
          const newXValue = addToPreviousTransform
            ? node.scale.value.x + (result.change as number)
            : (result.value as [number, number])[0];
          const newYValue = addToPreviousTransform
            ? node.scale.value.y + (result.change as number)
            : (result.value as [number, number])[1];

          setAnimatedValue(AnimatedType.SCALE, [newXValue, newYValue], nodeInfo.nodeId);
        }
      });
      break;

    case UITransformType.Rotation:
      selectedNodesInfo.forEach((nodeInfo) => {
        const node = getNodeByIdOnly(nodeInfo.nodeId) as AVLayer;
        const currentRotation = node.rotation.value.value;

        const newRotation = addToPreviousTransform ? (result.change as number) + currentRotation : result.value;
        const newRotationRemainder = (newRotation as number) % 360;
        const newRotationCount = Math.trunc((newRotation as number) / 360);

        setAnimatedValue(
          AnimatedType.ROTATION,
          roundArr([newRotationCount * 360 + (newRotationRemainder as number)], CANVAS_ROUND_PRECISION),
          nodeInfo.nodeId,
        );
      });
      break;

    case UITransformType.RotationCount:
      selectedNodesInfo.forEach((nodeInfo) => {
        const node = getNodeByIdOnly(nodeInfo.nodeId) as AVLayer;
        const currentRotation = node.rotation.value.value;
        const currentRotationCount = Math.trunc(currentRotation / 360);
        const currentRotationRemainder = currentRotation % 360;

        const newRotation = addToPreviousTransform ? currentRotationCount + (result.change as number) : result.value;

        setAnimatedValue(
          AnimatedType.ROTATION,
          [(newRotation as number) * 360 + (currentRotationRemainder % 360)],
          nodeInfo.nodeId,
        );
      });
      break;

    case UITransformType.Opacity:
      selectedNodesInfo.forEach((nodeInfo) => {
        const node = getNodeByIdOnly(nodeInfo.nodeId) as AVLayer;

        const newOpacity = addToPreviousTransform
          ? clamp(node.opacity.value.value + (result.change as number), 0, 100)
          : result.value;

        setAnimatedValue(animatedType as number, [newOpacity], nodeInfo.nodeId);
      });
      break;

    default:
      break;
  }
};
