/**
 * Copyright 2023 Design Barn Inc.
 */

import React, { useState } from 'react';

import CommunityPanel from './CommunityPanel';
import CreatorFaqFramer from './CreatorFaqFramer';
import CreatorTutorialFramer from './CreatorTutorialFramer';
import SidebarSettings from './SidebarSettings';
import WhatsNewFramer from './WhatsNewFramer';

import GetStartedChart from '~/assets/animations/get-started-chart.json';
import GetStartedHeart from '~/assets/animations/get-started-heart.json';
import GetStartedToggle from '~/assets/animations/get-started-toggle.json';
import { LottieAnimationLightGray } from '~/assets/icons';
import { Bell, Chart, Chat, Cloud, Heart, Toggle } from '~/assets/illustrations/GettingStartedSVGs';
import { Divider } from '~/components/Elements/Divider';
import { DragToCanvas } from '~/components/Elements/DragToCanvas';
import { AssetSource, EventType, FileType } from '~/data/eventStore';
import { uploadJSON, uploadSVG } from '~/features/upload';
import { useCreatorStore } from '~/store';
import type { EventStorePayload } from '~/utils';
import { fireEvent } from '~/utils';

interface LottieAnimation {
  lottie: Record<string, unknown>;
  name: string;
  svg: JSX.Element;
}

const LottieAnimationsList: LottieAnimation[] = [
  {
    name: 'Toggle',
    lottie: GetStartedToggle,
    svg: <Toggle />,
  },
  {
    name: 'Chart',
    lottie: GetStartedChart,
    svg: <Chart />,
  },
  {
    name: 'Heart',
    lottie: GetStartedHeart,
    svg: <Heart />,
  },
];

const SvgList = [
  {
    name: 'Bell',
    svg: <Bell />,
    svgText: `<svg width="151" height="163" viewBox="0 0 151 163" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2911_379" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="2" width="146" height="158">
    <path d="M148.176 2.31641H3.69104V159.845H148.176V2.31641Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_2911_379)">
    <path d="M75.9938 159.826C87.0768 159.826 96.0614 150.842 96.0614 139.759C96.0614 128.676 87.0768 119.691 75.9938 119.691C64.9112 119.691 55.9266 128.676 55.9266 139.759C55.9266 150.842 64.9112 159.826 75.9938 159.826Z" fill="#B18500"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.672 71.7084V100.45C125.672 105.472 128.956 109.728 133.495 111.182H133.878C141.775 111.182 148.176 117.584 148.176 125.48C148.176 133.377 141.775 139.778 133.878 139.778H17.989C10.0925 139.778 3.69104 133.377 3.69104 125.48C3.69104 117.584 10.0925 111.182 17.989 111.182H18.5021C23.0421 109.728 26.329 105.472 26.329 100.45V71.7084C26.329 48.4645 42.2901 28.9569 63.8519 23.5367C62.579 21.3755 61.908 18.9125 61.9101 16.4043C61.9101 8.62508 68.2186 2.31641 75.9983 2.31641C83.7784 2.31641 90.0865 8.62508 90.0865 16.4043C90.0886 18.9125 89.418 21.3755 88.1446 23.5367C109.706 28.9569 125.672 48.4645 125.672 71.7084ZM70.3636 16.4047C70.3636 19.5156 72.888 22.0356 75.9987 22.0356C79.1098 22.0356 81.6338 19.5152 81.6338 16.4047C81.6338 13.2893 79.1098 10.7693 75.9987 10.7693C72.888 10.7693 70.3636 13.2893 70.3636 16.4047Z" fill="#FFBF00"/>
    <path d="M133.878 111.184H17.989C10.0925 111.184 3.69104 117.585 3.69104 125.482C3.69104 133.378 10.0925 139.78 17.989 139.78H133.878C141.775 139.78 148.176 133.378 148.176 125.482C148.176 117.585 141.775 111.184 133.878 111.184Z" fill="#FEDE7E"/>
    </g>
    </svg>
    `,
  },
  {
    name: 'Cloud',
    svg: <Cloud />,
    svgText: `<svg width="400" height="413" viewBox="0 0 400 413" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M325 150C366.421 150 400 116.421 400 75C400 33.5786 366.421 0 325 0C283.579 0 250 33.5786 250 75C250 116.421 283.579 150 325 150Z" fill="#FFC107"/>
    <path d="M325 125.001L322.55 125.026C316.856 96.7459 301.528 71.3173 279.179 53.0767C256.831 34.8362 228.847 24.9133 200 25.0006C175.669 24.9958 151.866 32.0919 131.509 45.4188C111.152 58.7456 95.1275 77.7237 85.4 100.026C62.5721 100.599 40.8703 110.064 24.9184 126.403C8.96649 142.743 0.0253823 164.665 0 187.501C0 235.751 39.25 275.001 87.5 275.001H325C366.35 275.001 400 241.351 400 200.001C400 158.651 366.35 125.001 325 125.001Z" fill="#E3F2FD"/>
    <path d="M310.073 306.772C323.299 312.441 354.962 327.152 363.456 340.711C374.43 358.233 369.103 381.423 351.581 392.398C334.059 403.373 310.869 398.045 299.894 380.523C291.401 366.963 292.006 332.041 292.657 317.68C292.994 309.505 302.57 303.506 310.073 306.772Z" fill="#2196F3"/>
    <path d="M211.786 306.772C225.012 312.441 256.675 327.152 265.168 340.711C276.143 358.233 270.815 381.423 253.294 392.398C235.772 403.373 212.582 398.045 201.607 380.523C193.114 366.963 193.719 332.041 194.37 317.68C194.706 309.505 204.283 303.506 211.786 306.772Z" fill="#2196F3"/>
    <path d="M135.274 280.375C134.123 278.716 132.587 277.36 130.797 276.424C129.008 275.488 127.018 275 124.999 275H99.9987V212.5C100.015 209.561 98.9941 206.71 97.1166 204.449C95.2392 202.187 92.6247 200.659 89.7327 200.134C86.8407 199.609 83.856 200.12 81.3031 201.576C78.7503 203.033 76.7925 205.343 75.7737 208.1L38.2737 308.1C37.5678 309.994 37.3306 312.03 37.5822 314.035C37.8339 316.04 38.567 317.955 39.7189 319.615C40.8708 321.275 42.4074 322.632 44.1975 323.57C45.9875 324.508 47.9779 324.999 49.9987 325H74.9987V387.5C74.9987 390.428 76.0261 393.262 77.9018 395.51C79.7775 397.757 82.3825 399.275 85.2626 399.799C88.1428 400.322 91.1153 399.819 93.6621 398.375C96.2089 396.932 98.1683 394.64 99.1987 391.9L136.699 291.9C137.403 290.006 137.641 287.97 137.393 285.964C137.145 283.958 136.418 282.041 135.274 280.375Z" fill="#FFC107"/>
    </svg>
    
    `,
  },
  {
    name: 'Chat',
    svg: <Chat />,
    svgText: `<svg width="150" height="137" viewBox="0 0 150 137" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2911_369" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="1" width="147" height="134">
    <path d="M146.997 1.94922H0.492676V134.417H146.997V1.94922Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_2911_369)">
    <path d="M69.3525 22.5234C32.7705 22.5234 3.16272 47.0433 3.16272 77.2212C3.16272 92.003 10.2686 105.425 21.8047 115.295L8.99655 133.323C8.99655 133.323 25.0506 134.244 35.7971 124.374C45.6665 129.199 57.1149 131.963 69.3965 131.963C105.979 131.963 135.586 107.487 135.586 77.2648C135.586 47.0429 105.891 22.5234 69.3525 22.5234Z" fill="#2A94F4"/>
    <path d="M126.463 105.073L141.464 126.215C141.464 126.215 122.383 127.312 110.409 115.161C106.768 116.74 102.952 118.056 99.0481 118.977C93.7404 120.249 88.1696 120.951 82.3796 120.951C47.64 120.951 19.4358 96.1686 19.4358 65.5519C19.4358 59.1479 20.664 52.9632 22.9449 47.2168C31.5421 25.6361 54.8775 10.1523 82.336 10.1523C117.076 10.1523 145.28 34.9352 145.28 65.5519C145.324 81.0357 138.13 95.0279 126.463 105.073Z" fill="#CCE9FF"/>
    <path d="M60.3746 70.0699C65.3311 70.0699 69.3668 66.0346 69.3668 61.0781C69.3668 56.1216 65.3311 52.0859 60.3746 52.0859C55.4182 52.0859 51.3828 56.1216 51.3828 61.0781C51.3828 66.0346 55.3742 70.0699 60.3746 70.0699Z" fill="#2A94F4"/>
    <path d="M84.751 70.0699C89.7075 70.0699 93.7431 66.0346 93.7431 61.0781C93.7431 56.1216 89.7075 52.0859 84.751 52.0859C79.7945 52.0859 75.7592 56.1216 75.7592 61.0781C75.7592 66.0346 79.7505 70.0699 84.751 70.0699Z" fill="#2A94F4"/>
    <path d="M109.141 70.0699C114.097 70.0699 118.133 66.0346 118.133 61.0781C118.133 56.1216 114.097 52.0859 109.141 52.0859C104.184 52.0859 100.149 56.1216 100.149 61.0781C100.149 66.0346 104.14 70.0699 109.141 70.0699Z" fill="#2A94F4"/>
    </g>
    </svg>`,
  },
];

const LottieAnimationItem: React.FC<{ animation: LottieAnimation }> = ({ animation }) => {
  const [onMouseOver, setMouseOver] = useState(false);

  const addToCanvas = async (): Promise<void> => {
    await uploadJSON(animation.lottie);
    // Send analytics event
    const eventParams: EventStorePayload = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.UploadedAsset,
      parameters: {
        source: AssetSource.GetStartedJSON,
        'file-type': FileType.JSON,
      },
    };

    fireEvent(eventParams);
  };

  const element = React.cloneElement(animation.svg);

  return (
    <div
      onMouseOver={() => {
        setMouseOver(true);
      }}
      onMouseOut={() => {
        setMouseOver(false);
      }}
      key={animation.name}
      className="relative h-[66px] w-[66px] cursor-pointer rounded  bg-[#1F2429] "
    >
      <DragToCanvas addToCanvas={addToCanvas} element={element} popupSize={{ width: 100, height: 100 }}>
        <div>
          {!onMouseOver && <>{element}</>}

          {onMouseOver && (
            <>
              <dotlottie-player
                renderer="svg"
                autoplay
                loop
                className="h-20 w-20"
                src={JSON.stringify(animation.lottie)}
              />
            </>
          )}

          <div className="absolute bottom-[-4px] left-[-3px] h-[14px] w-[14px] rounded-full bg-[#3D4852] p-[2px]">
            <LottieAnimationLightGray />
          </div>
        </div>
      </DragToCanvas>
    </div>
  );
};

export const GettingStartedSidePanel: React.FC = () => {
  const timelineHeight = useCreatorStore((state) => state.timeline.height);

  return (
    <div
      style={{
        height: `calc(100vh - ${timelineHeight}px)`,
        minWidth: '238px',
      }}
      className="flex flex-col overflow-y-auto border-l border-[#283137]"
    >
      <div className="getting-started-grid mb-16 w-[238px] overflow-x-hidden ">
        <div className="flex px-3 text-white">
          <div className="py-2 text-xs font-semibold">Let&apos;s get started with Lottie Creator</div>
        </div>
        <Divider className="mb-2" />
        <div className="mb-2 flex items-center px-3 text-xs text-gray-300">Insert asset</div>
        <div className="flex justify-between px-3 pb-2">
          {SvgList.map((svg) => {
            const addToCanvas = async (): Promise<void> => {
              await uploadSVG(svg.svgText);
              // Send analytics event
              const eventParams: EventStorePayload = {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_type: EventType.UploadedAsset,
                parameters: {
                  source: AssetSource.GetStartedStatic,
                  'file-type': FileType.SVG,
                },
              };

              fireEvent(eventParams);
            };

            const element = React.cloneElement(svg.svg);

            return (
              <>
                <div key={svg.name} className="relative">
                  <div className="h-[66px] w-[66px] cursor-pointer rounded bg-[#1F2429] px-[14px]">
                    <DragToCanvas addToCanvas={addToCanvas} element={element} fromStatic={true}>
                      {element}
                    </DragToCanvas>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="px-3 pb-4">
          <div className="flex w-full justify-between">
            {LottieAnimationsList.map((animation) => (
              <LottieAnimationItem animation={animation} key={animation.name} />
            ))}
          </div>
          <p className="mt-3.5 text-[10px] tracking-[-0.2px] text-[#808E9A]">
            Or drag and drop anywhere to upload your own (Lottie JSON, dotLottie, or SVG. Up to 20 MB)
          </p>
        </div>
        <Divider />
        <div className="px-3 pt-3">
          <CreatorTutorialFramer />
        </div>
        <Divider />
        <div className="px-3 pt-3">
          <WhatsNewFramer />
        </div>
        <Divider />
        <div className=" px-3 pt-3">
          <CreatorFaqFramer />
        </div>
        <div className="px-3">
          <CommunityPanel />
        </div>
        <div className="py-3">
          <Divider />
        </div>
        <div className="px-3">
          <SidebarSettings />
        </div>
      </div>
    </div>
  );
};
