/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { NineGridSelector } from '~/features/property-panel/components/field/9GridSelector';
import { useCreatorStore } from '~/store';
import { CANVAS_ANCHOR_GRID_PREFIX, CANVAS_DEFAULT_TAB_ID } from '~/store/constant';

export const TemplateAnchor: React.FC = () => {
  const canvasId = useCreatorStore((state) => state.toolkit.selectedPrecompositionId);
  const sceneId = `${CANVAS_ANCHOR_GRID_PREFIX}${canvasId || CANVAS_DEFAULT_TAB_ID}`;
  const anchorPointsActives = useCreatorStore((state) => state.ui.anchorPointsActive);

  const onAnchorClick = useCallback(
    (type: string) => {
      if (sceneId) {
        const setAnchorPointsActive = useCreatorStore.getState().ui.setAnchorPointsActive;

        setAnchorPointsActive(sceneId as string, {
          [type]: true,
        });
      }
    },
    [sceneId],
  );

  return (
    <div className="mt-4 flex flex-row gap-3">
      <div className="relative inline-flex">
        <div className="text-xs text-gray-300">Origin point</div>
      </div>
      <div>
        <NineGridSelector
          multiSelect={false}
          nodeId={sceneId}
          disabled={false}
          activePoints={anchorPointsActives}
          onClick={onAnchorClick}
        />
      </div>
    </div>
  );
};
