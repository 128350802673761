/**
 * Copyright 2022 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import { StarType } from '@lottiefiles/lottie-js';
import clsx from 'clsx';
import React, { useCallback, Fragment } from 'react';
import { shallow } from 'zustand/shallow';

import { Square, Polygon, Circle, Star, ChevronDown } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { ToolType } from '~/data/constant';
import { AssetSource, EventType, FileType } from '~/data/eventStore';
import type { ShapeOption } from '~/lib/toolkit';
import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { ShapeTypes } from '~/store/constant';
import type { EventStorePayload } from '~/utils';
import { isMacOS, fireEvent } from '~/utils';

const shapeLabels = {
  [ShapeTypes.Rectangle]: 'Rectangle',
  [ShapeTypes.Ellipse]: 'Ellipse',
  [ShapeTypes.Polygon]: 'Polygon',
  [ShapeTypes.Star]: 'Star',
};

const DropDownList = [
  {
    type: ShapeTypes.Rectangle,
    activeIcon: <Square />,
    inactiveIcon: <Square />,
    label: shapeLabels[ShapeTypes.Rectangle],
    shortcut: isMacOS ? '⌥ R' : 'ALT R',
  },
  {
    type: ShapeTypes.Ellipse,
    activeIcon: <Circle />,
    inactiveIcon: <Circle />,
    label: shapeLabels[ShapeTypes.Ellipse],
    shortcut: isMacOS ? '⌥ O' : 'ALT O',
  },
  {
    type: ShapeTypes.Polygon,
    activeIcon: <Polygon />,
    inactiveIcon: <Polygon />,
    label: shapeLabels[ShapeTypes.Polygon],
  },
  {
    type: ShapeTypes.Star,
    activeIcon: <Star />,
    inactiveIcon: <Star />,
    label: shapeLabels[ShapeTypes.Star],
  },
];

export const rectangleOption: ShapeOption = {
  type: ShapeTypes.Rectangle,
  fill: [255, 142, 236, 1],
  startFrame: 0,
  endFrame: 150,
  name: 'Rectangle',
  rotation: 0,
  position: [0, 0],
  rectangle: { size: [100, 100], roundness: 0 },
};

export const ellipseOption: ShapeOption = {
  type: ShapeTypes.Ellipse,
  fill: [58, 134, 255, 1],
  startFrame: 0,
  endFrame: 150,
  name: 'Ellipse',
  rotation: 0,
  position: [0, 0],
  ellipse: { size: [100, 100] },
};

export const polygonOption: ShapeOption = {
  type: ShapeTypes.Polygon,
  fill: [156, 115, 248, 1],
  startFrame: 0,
  endFrame: 150,
  name: 'Polygon',
  rotation: 0,
  position: [0, 0],
  polystar: { points: 3, outerRadius: 50, type: StarType.POLYGON },
};

export const starOption: ShapeOption = {
  type: ShapeTypes.Star,
  fill: [255, 191, 0, 1],
  startFrame: 0,
  endFrame: 150,
  name: 'Star',
  rotation: 0,
  position: [0, 0],
  polystar: { points: 5, outerRadius: 50, innerRadius: 30, type: StarType.STAR },
};

export const ShapeMenu: React.FC = () => {
  const [lastSelectedShape, setLastSelectedShape, currentTool, setCurrentTool] = useCreatorStore(
    (state) => [
      state.ui.lastSelectedShape,
      state.ui.setLastSelectedShape,
      state.ui.currentTool,
      state.ui.setCurrentTool,
    ],
    shallow,
  );

  const handleOnClick = useCallback(
    (type: ShapeTypes): void => {
      setCurrentTool(ToolType.Shape);

      // Execute tracking event the minute we identify the type of file

      const state = useCreatorStore.getState();
      const sceneIndex = state.toolkit.sceneIndex;
      const scene = toolkit.scenes[sceneIndex];

      const eventParams: EventStorePayload = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.UploadedAsset,
        parameters: {
          source: AssetSource.BasicShapes,
          'file-type': FileType.BasicShapes,
        },
      };

      fireEvent(eventParams);

      if (!scene) return;

      setLastSelectedShape(type);
    },
    [setCurrentTool, setLastSelectedShape],
  );

  let shapeIcon: React.ReactElement;

  switch (lastSelectedShape) {
    case ShapeTypes.Rectangle:
      shapeIcon = <Square />;
      break;

    case ShapeTypes.Ellipse:
      shapeIcon = <Circle />;
      break;

    case ShapeTypes.Polygon:
      shapeIcon = <Polygon />;
      break;

    case ShapeTypes.Star:
      shapeIcon = <Star />;
      break;

    default:
      break;
  }

  return (
    <>
      <Menu as="div" className="relative mt-1 inline-block">
        {({ open }) => (
          <>
            <div className="group flex h-[24px] cursor-pointer items-center justify-center rounded pl-0.5 text-gray-400 hover:bg-gray-700">
              <Tooltip
                content={shapeLabels[lastSelectedShape as keyof typeof shapeLabels]}
                offsetOptions={{ mainAxis: 14 }}
              >
                <div className="flex">
                  <div onClick={() => handleOnClick(lastSelectedShape)}>
                    <div>
                      {React.cloneElement(shapeIcon, {
                        className: clsx(
                          'h-[16px] w-[16px]',
                          open || currentTool === ToolType.Shape
                            ? 'fill-[#00C1A2] text-[#00C1A2]'
                            : 'fill-gray-800 group-hover:bg-gray-700 group-hover:fill-gray-700 group-hover:text-white',
                        ),
                      })}
                    </div>
                  </div>
                  <Tooltip content="Shape Tools" offsetOptions={{ mainAxis: 14 }} isNested>
                    <Menu.Button>
                      {React.cloneElement(<ChevronDown />, {
                        className: clsx(
                          'h-3 w-4 cursor-pointer text-gray-500 transition-transform group-hover:text-white',
                          { 'hover:translate-y-px': !open },
                        ),
                      })}
                    </Menu.Button>
                  </Tooltip>
                </div>
              </Tooltip>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-dropdown mt-1 w-[192px] cursor-pointer rounded-lg border border-gray-600 bg-gray-700 py-2 shadow-dropdown focus:outline-none">
                {DropDownList.map((dropdown, index) => (
                  <div key={dropdown.type}>
                    <Menu.Item>
                      <button
                        className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                          index === DropDownList.length - 1 && 'rounded-b'
                        } px-2 text-xs text-white `}
                        onClick={() => handleOnClick(dropdown.type)}
                      >
                        <div className="flex w-full justify-between rounded-lg p-2 hover:bg-gray-600 ">
                          <div className="flex">
                            {React.cloneElement(dropdown.activeIcon, {
                              className:
                                'h-[16px] w-[16px] fill-current text-gray-400 mr-2 fill-gray-700 group-hover:fill-gray-600',
                            })}
                            {dropdown.label}
                          </div>
                          {dropdown.shortcut && <div className="mr-2 text-gray-400">{dropdown.shortcut}</div>}
                        </div>
                      </button>
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};
