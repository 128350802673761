/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable tailwindcss/no-custom-classname */

import type {
  AnimatedProperty,
  AnimatedPropertyJSON,
  KeyFrameJSON,
  ShapeLayer,
  ValueJSON,
} from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import { throttle } from 'lodash-es';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import type { DraggableEvent, DraggableData } from 'react-draggable';
import Draggable from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { useTimelineUtils } from '../hooks';

import {
  KeyframeIcon,
  KeyframeInLinearOutSmoothIcon,
  KeyframeInSmoothOutLinearIcon,
  KeyframeInSmoothOutSmoothIcon,
} from '~/assets/icons';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setKeyFrame, toolkit, stateHistory, removeKeyFrame } from '~/lib/toolkit';
import { getIsInLinear, getIsOutLinear } from '~/lib/toolkit/easings';
import { useCreatorStore } from '~/store';

interface KeyFrameProps {
  animatedPropId: string;
  keyframe: KeyFrameJSON<ValueJSON>;
  layerId: string;
}

export const KeyFrame: React.FC<KeyFrameProps> = ({ animatedPropId, keyframe, layerId }: KeyFrameProps) => {
  const id = keyframe.frameId || `${keyframe.frame}`;

  const isInLinear = getIsInLinear(keyframe.inTangent);
  const isOutLinear = getIsOutLinear(keyframe.outTangent, keyframe.inTangent);

  const [
    selectedKeyframes,
    setSelectedKeyframes,
    selectedNodesInfo,
    addToSelectedNodes,
    removeSelectedNodes,
    keyframeScrubberWidth,
    setTimelineContext,
    multiselectModifier,
    getNodeByIdOnly,
  ] = useCreatorStore(
    (state) => [
      state.timeline.selectedKeyframeIds,
      state.timeline.setSelectedKeyframeIds,
      state.ui.selectedNodesInfo,
      state.ui.addToSelectedNodes,
      state.ui.removeSelectedNodes,
      state.timeline.keyframeScrubberWidth,
      state.timeline.setTimelineContext,
      state.timeline.multiSelectionModifier,
      state.toolkit.getNodeByIdOnly,
    ],
    shallow,
  );

  const { finalWidth, getFrameFromPx, getFrameLeftPadding, totalFrames } = useTimelineUtils(keyframeScrubberWidth);
  const [leftPositionPx, setLeftPositionPx] = useState(() => getFrameLeftPadding(keyframe.frame));
  const [dragHead, setDragHead] = useState<string | null>(null);

  const isSelected = selectedKeyframes.includes(id);

  const nodeRef = React.createRef<HTMLDivElement>();
  const keyFrameClickableRef = React.createRef<HTMLDivElement>();

  const isDraggingRef = React.useRef(false);

  const animatedProp = toolkit.getNodeById(animatedPropId) as AnimatedProperty | null;
  const parentTrack = animatedProp ? animatedProp.track : null;

  const handleRightClick = useCallback(
    (event): void => {
      if (!selectedKeyframes.includes(id)) {
        setSelectedKeyframes([id]);
      }

      if (!selectedNodesInfo.some((nodeInfo) => nodeInfo.nodeId === layerId)) {
        addToSelectedNodes([layerId], true);
      }

      isDraggingRef.current = false;
      const { height, top, width, x } = event.target.getBoundingClientRect();

      const timelineContainer = document.getElementById('TimelineContainer');

      const timelineTop = timelineContainer?.getBoundingClientRect();

      if (timelineTop) {
        // Calculate the vertical position of the element relative to its parent
        const positionY = top - timelineTop.top;

        // Delay execute to next process tick using setTimeout, after mouse contextClosed called at KeyframeContent.
        setTimeout(() => {
          const marginLeft = 3;
          const marginTop = 20;

          setTimelineContext({
            mousePos: {
              x: Number(x) + Number(width) + marginLeft,
              y: Number(positionY) + Number(height) + marginTop,
            },
            keyframeMenuOpened: true,
          });
        }, 0);
      }
    },
    [addToSelectedNodes, id, layerId, selectedKeyframes, selectedNodesInfo, setSelectedKeyframes, setTimelineContext],
  );

  const toggleKeyframe = useCallback((): void => {
    if (selectedKeyframes.includes(id)) {
      setSelectedKeyframes(selectedKeyframes.filter((keyframeId) => keyframeId !== id));

      return;
    }

    setSelectedKeyframes([...selectedKeyframes, id]);
  }, [id, selectedKeyframes, setSelectedKeyframes]);

  const handleLayerSelection = useCallback(() => {
    if (multiselectModifier) {
      const isLayerSelected = selectedNodesInfo.some((nodeInfo) => nodeInfo.nodeId === layerId);
      const layer = getNodeByIdOnly(layerId) as ShapeLayer;

      const hasSelectedKeyframes = layer.animatedProperties.some((prop) => {
        return prop.keyFrames.some((kf) => selectedKeyframes.includes(kf.frameId as string));
      });

      if (isLayerSelected && !hasSelectedKeyframes) {
        removeSelectedNodes([layerId]);
      } else {
        addToSelectedNodes([layerId], false);
      }

      return;
    }

    if (selectedKeyframes.length > 1) {
      addToSelectedNodes([layerId], false);

      return;
    }

    addToSelectedNodes([layerId], true);
  }, [
    addToSelectedNodes,
    getNodeByIdOnly,
    layerId,
    multiselectModifier,
    removeSelectedNodes,
    selectedKeyframes,
    selectedNodesInfo,
  ]);

  const handleOnMouseDown = useCallback(
    (event): void => {
      handleLayerSelection();

      if (event.type === 'mousedown' && event.button === 2 && event.target) {
        handleRightClick(event);

        return;
      }

      stateHistory.beginAction();

      if (multiselectModifier) {
        toggleKeyframe();
      } else if (selectedKeyframes.length === 0 || !selectedKeyframes.includes(id)) {
        setSelectedKeyframes([id]);
      }

      isDraggingRef.current = false;

      setDragHead(id);

      // Send event to get latest toolkit state
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
    },

    [
      multiselectModifier,
      selectedKeyframes,
      id,
      setDragHead,
      handleRightClick,
      toggleKeyframe,
      setSelectedKeyframes,
      handleLayerSelection,
    ],
  );

  useEffect(() => {
    if (isDraggingRef.current) return;

    setLeftPositionPx(getFrameLeftPadding(keyframe.frame));
  }, [getFrameLeftPadding, keyframe.frame, totalFrames]);

  const updateHeadPositionThrottled = useMemo(() => {
    return throttle(() => {
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);
    }, 30);
  }, []);

  // If there are multiple selected keyframes,
  // calculate the distance between the drag head and each drag child
  // to move them all together when the head is dragged
  const dragChildDistances = useMemo(() => {
    if (selectedKeyframes.length > 1 && dragHead === id) {
      const headKeyframe = toolkit.getKeyframeById(dragHead);

      if (!headKeyframe) return {};

      return selectedKeyframes
        .filter((keyframeId) => keyframeId !== dragHead)
        .reduce((acc, keyframeId) => {
          const currentKeyframe = toolkit.getKeyframeById(keyframeId);

          if (!currentKeyframe) return acc;

          const distance = currentKeyframe.frame - headKeyframe.frame;

          return {
            ...acc,
            [keyframeId]: distance,
          };
        }, {});
    }

    return {};
  }, [dragHead, id, selectedKeyframes]);

  const updateKeyframesPosition = useCallback(
    (event: DraggableEvent, data: DraggableData) => {
      const frame = getFrameFromPx(data.x);
      const finalFrame = Math.min(Math.max(Math.round(frame), 0), totalFrames);

      const existingKeyframe = parentTrack?.keyFrames.find((kf) => kf.frame === finalFrame && kf.frameId !== id);

      if (existingKeyframe && event.type === 'mouseup') {
        removeKeyFrame(id);
      }

      const dragChildrenOutOfBounds = Object.values(dragChildDistances).some((distanceFromHead) => {
        const childNewFrame = finalFrame + (distanceFromHead as number);

        return childNewFrame < 0 || childNewFrame > totalFrames;
      });

      if (dragChildrenOutOfBounds) return;
      if (!existingKeyframe) setKeyFrame(id, finalFrame);
      setLeftPositionPx(data.x);

      Object.entries(dragChildDistances).forEach(([keyframeId, distanceFromHead]) => {
        const currentKeyframe = toolkit.getKeyframeById(keyframeId);
        const childNewFrame = finalFrame + (distanceFromHead as number);
        const existingChildKeyframe = currentKeyframe?.parentTrack?.keyFrames.find((kf) => kf.frame === childNewFrame);

        if (!existingChildKeyframe) setKeyFrame(keyframeId, childNewFrame);
      });

      updateHeadPositionThrottled();
    },
    [dragChildDistances, getFrameFromPx, id, parentTrack?.keyFrames, totalFrames, updateHeadPositionThrottled],
  );

  const handleOnDrag = useCallback(
    (event: DraggableEvent, data: DraggableData): void => {
      if (!isDraggingRef.current) isDraggingRef.current = true;

      updateKeyframesPosition(event, data);
    },
    [updateKeyframesPosition],
  );

  // Workaround to handle data.x and data.lastX is the same
  // https://github.com/react-grid-layout/react-draggable/pull/522#issuecomment-694921225
  const handleOnStop = useCallback(
    (event: DraggableEvent, data: DraggableData): void => {
      if (!isDraggingRef.current) return;

      updateKeyframesPosition(event, data);

      isDraggingRef.current = false;

      if (dragHead === id) {
        stateHistory.endAction();
      }
    },
    [dragHead, id, updateKeyframesPosition],
  );

  useEffect(() => {
    // Update KeyframeClickable region, to mirror keyframeUI
    if (nodeRef.current && keyFrameClickableRef.current) {
      const width = nodeRef.current.offsetWidth;
      const height = nodeRef.current.offsetHeight;
      const x = nodeRef.current.offsetLeft;
      const y = nodeRef.current.offsetTop;
      const transform = window.getComputedStyle(nodeRef.current).transform;

      keyFrameClickableRef.current.style.width = `${width}px`;
      keyFrameClickableRef.current.style.height = `${height}px`;
      keyFrameClickableRef.current.style.left = `${x}px`;
      keyFrameClickableRef.current.style.top = `${y}px`;
      keyFrameClickableRef.current.style.transform = transform;
    }
  }, [keyFrameClickableRef, nodeRef]);

  const keyframeBaseStyle = 'selectable-keyframe timeline-keyframe h-5 w-5 cursor-pointer fill-current stroke-current';

  return (
    <>
      {/* KeyframeClickable (transparent) - zIndex KeyframeClickable > zIndex scrubber - Make it clickable when overlay */}
      <Draggable
        axis="x"
        position={{ x: leftPositionPx, y: 0 }}
        onMouseDown={handleOnMouseDown}
        onDrag={handleOnDrag}
        onStop={handleOnStop}
        grid={[finalWidth / totalFrames, 0]}
        bounds={`#animated-${animatedPropId}`}
      >
        <div ref={keyFrameClickableRef} className="absolute z-keyframe-clickable cursor-pointer"></div>
      </Draggable>

      {/* KeyframeUI always stays behind scrubber - zIndex KeyframeUI < zIndex scrubber */}
      <Draggable
        axis="x"
        position={{ x: leftPositionPx, y: 0 }}
        // The drag bounds is pointed to an element that's wider than the tracks
        // in the timeline. This is because the svg element is actually massive
        // and goes 2px over the bounds of the actual parent of this element.
        // Ultimately, that prevents the keyframe from being dragged to the last
        // element and this will have the drag some breathing room
        bounds={`#animated-${animatedPropId}`}
        grid={[finalWidth / totalFrames, 0]}
      >
        <div ref={nodeRef} className="absolute z-keyframe">
          <div className="timeline-keyframe z-0">
            {isInLinear && isOutLinear && (
              <KeyframeIcon
                data-keyframeid={id}
                data-animatedpropid={animatedPropId}
                className={clsx(keyframeBaseStyle, {
                  'text-teal-500': isSelected,
                  'text-white': !isSelected,
                })}
              />
            )}

            {isInLinear && !isOutLinear && (
              <KeyframeInLinearOutSmoothIcon
                data-keyframeid={id}
                data-animatedpropid={animatedPropId}
                className={clsx(keyframeBaseStyle, 'p-[1.5px]', {
                  'text-teal-500': isSelected,
                  'text-white': !isSelected,
                })}
              />
            )}
            {!isInLinear && isOutLinear && (
              <KeyframeInSmoothOutLinearIcon
                data-keyframeid={id}
                data-animatedpropid={animatedPropId}
                className={clsx(keyframeBaseStyle, 'p-[1.5px]', {
                  'text-teal-500': isSelected,
                  'text-white': !isSelected,
                })}
              />
            )}
            {!isInLinear && !isOutLinear && (
              <KeyframeInSmoothOutSmoothIcon
                data-keyframeid={id}
                data-animatedpropid={animatedPropId}
                className={clsx(keyframeBaseStyle, 'p-[1.5px]', {
                  'text-teal-500': isSelected,
                  'text-white': !isSelected,
                })}
              />
            )}
          </div>
        </div>
      </Draggable>
    </>
  );
};

interface AnimationKeyFramesProps {
  animatedProp: AnimatedPropertyJSON;
  animatedPropId: string;
  layerId: string;
}

export const AnimationKeyFrames: React.FC<AnimationKeyFramesProps> = ({ animatedProp, animatedPropId, layerId }) => {
  return (
    <>
      {animatedProp.keyFrames.map((kf: KeyFrameJSON<ValueJSON>) => {
        return (
          <KeyFrame
            key={kf.frameId}
            keyframe={kf as KeyFrameJSON<ValueJSON>}
            layerId={layerId}
            animatedPropId={animatedPropId}
          />
        );
      })}
    </>
  );
};
