/**
 * Copyright 2024 Design Barn Inc.
 */

import type { LayerJSON } from '@lottiefiles/toolkit-js';
import React from 'react';

import { LAYER_PANEL_DEFAULT_WIDTH } from '../constant';

import TimelineItemAnimatedKeyframePanel from './TimelineItemAnimatedKeyframePanel';
import TimelineItemAnimatedLayerPanel from './TimelineItemAnimatedLayerPanel';

import { layerMap } from '~/lib/layer';

interface Props {
  animatedId: string;
  cursorClassName: string;
  layer: LayerJSON;
  type: string;
}

export const TimelineItemAnimated: React.FC<Props> = ({ animatedId, cursorClassName, layer, type }) => {
  const layerUI = layerMap.get(layer.id);

  if (!layerUI) {
    return null;
  }

  return (
    <div className="flex">
      <div className={`z-[10] shrink-0 ${cursorClassName}`} style={{ width: LAYER_PANEL_DEFAULT_WIDTH }}>
        <TimelineItemAnimatedLayerPanel layer={layer} layerUI={layerUI} type={type} />
      </div>
      <div className="mr-2 w-full" data-no-dnd="true">
        <TimelineItemAnimatedKeyframePanel animatedId={animatedId} layer={layer} type={type} />
      </div>
    </div>
  );
};

export default TimelineItemAnimated;
