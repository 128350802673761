/**
 * Copyright 2022 Design Barn Inc.
 */

import type { PrecompositionAsset } from '@lottiefiles/toolkit-js';
import { Scene } from '@lottiefiles/toolkit-js';

import { WORKFLOW_PREVIEW, WORKFLOW_DASHBOARD } from '~/config';
import { ExportType } from '~/data/constant';
import { ClickSource, EventType, UTMMedium, UTMSource } from '~/data/eventStore';
import { toolkit, exportToolkit, exportToWorkspace, getActiveScene } from '~/lib/toolkit';
import { exportPrecompositionToolkitJson, exportToolkitJson } from '~/lib/toolkit/export';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant } from '~/store/constant/modal';
import { SavingState, NetworkStatus } from '~/store/projectSlice';
import type { EventStorePayload } from '~/utils';
import { fireEvent, utmURLBuilder } from '~/utils';

interface SaveToCreatorProp {
  saveWithoutPopup?: boolean;
}

export const createNewFile = (): void => {
  window.open(window.location.origin, '_blank');
};

export const saveToWorkSpace = async (): Promise<void> => {
  const setLoader = useCreatorStore.getState().ui.setLoader;
  const setAlert = useCreatorStore.getState().ui.setAlert;

  setLoader({
    isLoading: true,
    title: 'Launching your workspace...',
  });

  const savedUrl = await exportToWorkspace(toolkit, getActiveScene(toolkit));

  setLoader({
    isLoading: false,
  });

  if (savedUrl) {
    const markers = useCreatorStore.getState().timeline.markers;

    const eventParams: EventStorePayload = {
      // ignore needed because of the tracking lib naming
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: markers.length ? EventType.ExportWithSegment : EventType.Export,
      parameters: {
        source: ClickSource.Header,
      },
    };

    fireEvent(eventParams);

    const url = utmURLBuilder(`${WORKFLOW_PREVIEW}${savedUrl}`, UTMSource.HeaderButton, UTMMedium.Creator);

    window.open(`${url}`, '_blank');
  } else {
    setAlert({
      text: 'Something went wrong while exporting this animation. How about giving it another try?',
      alertColor: '#D92600',
      timeout: 7000,
      handle: async (): Promise<void> => {
        await saveToWorkSpace();
      },
      handleText: 'Try Again',
    });
  }
};

export const exportJSON = (): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  exportToolkit(toolkit, toolkit.scenes[sceneIndex] as Scene, ExportType.JSON);
};

// saveToCreator function calling from ALT+CMD+S or dropdown menu -> Save as...
export const saveToCreator = async (option?: SaveToCreatorProp): Promise<boolean> => {
  const isAuthorized = useCreatorStore.getState().user.isAuthorized;

  if (!isAuthorized) {
    return Promise.resolve(false);
  }

  // Prevent from calling save multiple times when saving
  const savingState = useCreatorStore.getState().project.info.savingState;

  if (savingState === SavingState.SAVING) return Promise.resolve(false);

  const savingProject = (callback: (selectedState: boolean, previousSelectedState: boolean) => void): (() => void) => {
    return useCreatorStore.subscribe((state) => state.project.info.savingState === SavingState.SAVED, callback);
  };

  const waitForProjectSaved = async (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      const unsub = savingProject((isSaved: boolean) => {
        if (isSaved) {
          unsub();
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  };

  const handleSave = async (): Promise<boolean> => {
    const setProjectInfo = useCreatorStore.getState().project.setInfo;
    const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;
    const setLoader = useCreatorStore.getState().ui.setLoader;

    setProjectInfo({ savingState: SavingState.SAVING });

    const done = await waitForProjectSaved();

    setGlobalModal(null);
    setLoader({
      isLoading: false,
    });

    return done;
  };

  const setAlert = useCreatorStore.getState().ui.setAlert;

  const networkStatus = useCreatorStore.getState().project.network;

  const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;

  const handleDefaultSave = async (): Promise<boolean> => {
    // Handle default save will be "Save As" scenario
    // This means it will always prompt for a new location, so there's no need to check for draft or selectedDirectoryId
    // if (isDraft || !selectedDirectoryId) {
    setGlobalModal(GlobalModalConstant.ProjectSave, { createNew: true });
    // } else return Promise.resolve(handleSave());

    return Promise.resolve(false);
  };

  if (networkStatus === NetworkStatus.OFFLINE) {
    setAlert({
      text: 'Looks like you’re offline. We couldn’t save your work.',
      alertColor: '#D92600',
      timeout: 5000,
      handleText: null,
    });
  } else if (option?.saveWithoutPopup) {
    // Autosave scenario
    return handleSave();
  } else {
    // Save as
    return handleDefaultSave();
  }

  return Promise.resolve(false);
};

export const goToDashboard = (): void => {
  window.open(`${WORKFLOW_DASHBOARD}`, '_blank');
};

export const getLottieJSON = async (): Promise<unknown> => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  const lottieJSON = await exportToolkitJson(toolkit, toolkit.scenes[sceneIndex] as Scene);

  return Promise.resolve(lottieJSON);
};

export const getActiveSceneLottieJSON = async (): Promise<unknown> => {
  const activeScene = getActiveScene(toolkit) as Scene | PrecompositionAsset;

  const lottieJSON =
    activeScene instanceof Scene
      ? await exportToolkitJson(toolkit, activeScene)
      : await exportPrecompositionToolkitJson(toolkit, activeScene);

  return Promise.resolve(lottieJSON);
};

export const exportDotLottie = (): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  exportToolkit(toolkit, toolkit.scenes[sceneIndex] as Scene, ExportType.DotLottie);
};
