/**
 * Copyright 2024 Design Barn Inc.
 */

import { PLUGIN_PROMPT_TO_VECTOR_SUBDOMAIN, PLUGIN_MOTION_COPILOT_SUBDOMAIN } from '~/config';
import { SIDEBAR_PLUGINS } from '~/data/plugins';

export const WHITELISTED_SUBDOMAINS_FOR_USER_TOKEN = [
  PLUGIN_PROMPT_TO_VECTOR_SUBDOMAIN,
  PLUGIN_MOTION_COPILOT_SUBDOMAIN,
];

export const WHILTELISTED_IDS_FOR_USER_TOKEN = [SIDEBAR_PLUGINS.promptVector.uuid, SIDEBAR_PLUGINS.motionCopilot.uuid];

export const WHITELISTED_SUBDOMAINS_FOR_UNSAFE_METHODS = [PLUGIN_MOTION_COPILOT_SUBDOMAIN];

export const WHILTELISTED_IDS_FOR_UNSAFE_METHODS = [SIDEBAR_PLUGINS.motionCopilot.uuid];
