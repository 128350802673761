/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

interface Props {
  speed: number;
  toggleSpeed: () => void;
}

export const SpeedButton: React.FC<Props> = ({ speed, toggleSpeed }: Props) => {
  return (
    <div className={'min-w-[24px] rounded p-1 hover:cursor-pointer hover:bg-gray-700'} onClick={toggleSpeed}>
      <div className="flex items-center justify-center rounded bg-[#808E9A] p-0.5 px-1.5">
        <div className="text-[8px] text-gray-800">{speed}x</div>
      </div>
    </div>
  );
};
