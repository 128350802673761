/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

// eslint-disable-next-line no-restricted-imports
import { cmdOrCtrl, formatShortcut } from '~/features/menu/constant';
import { useCreatorStore } from '~/store';

export const MinimizedUIExitButton: React.FC = () => {
  const [isUIMinimized, setIsUIMinimized] = useCreatorStore(
    (state) => [state.ui.isUIMinimized, state.ui.setIsUIMinimized],
    shallow,
  );

  const onCloseHandler = useCallback(() => {
    setIsUIMinimized(false);
  }, [setIsUIMinimized]);

  if (!isUIMinimized) {
    return null;
  }

  return (
    <div
      className="fixed left-2 top-2 z-[2] flex cursor-pointer items-center gap-2.5 rounded bg-[#20272C] py-1 px-2 text-[10px] text-[#ffffff]"
      onClick={onCloseHandler}
    >
      <div>Show/Hide UI</div>
      <div className="text-[12px] text-[#808E9A]">{formatShortcut(cmdOrCtrl, '.')}</div>
    </div>
  );
};
