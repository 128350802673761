/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StateCreator } from 'zustand';

import type { StoreSlice } from '.';

export interface UserInfo {
  avatarUrl?: string | null;
  country?: string;
  email?: string;
  id?: string;
  name?: string;
  userSegments?: UserSegment[];
}

interface UserSegment {
  title: string;
}

export interface UserSlice {
  user: {
    forbid: boolean;
    info: UserInfo;
    isAuthorized: boolean;
    isUserFetched: boolean;
    setForbid: (forbid: boolean) => void;
    setInfo: (info: UserInfo) => void;
    setIsAuthorized: (isAuthorized: boolean) => void;
    setIsUserFetched: (isUserFetched: boolean) => void;
    setToken: (token: string) => void;
    token: string | null;
  };
}

export const createUserSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  UserSlice
> = (set) => ({
  user: {
    token: null,
    info: {},
    isAuthorized: false,
    isUserFetched: false,
    forbid: false,
    setInfo: (info: UserInfo) => {
      set((draft) => {
        draft.user.info = {
          ...draft.user.info,
          ...info,
        };
      });
    },
    setIsAuthorized: (isAuthorized: boolean) => {
      set((draft) => {
        draft.user.isAuthorized = isAuthorized;
      });
    },
    setIsUserFetched: (isUserFetched: boolean) => {
      set((draft) => {
        draft.user.isUserFetched = isUserFetched;
      });
    },
    setToken: (token: string) => {
      set((draft) => {
        draft.user.token = token;
      });
    },
    setForbid: (forbid: boolean) => {
      set((draft) => {
        draft.user.forbid = forbid;
      });
    },
  },
});
