/**
 * Copyright 2022 Design Barn Inc.
 */

import type { PrecompositionAsset, PrecompositionLayer, Scene } from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../../Plugin';
import { getNodeMethods } from '../node';
import { createPrecompAssetObj } from '../precomp/precomposition-asset';
import { createPrecompLayerObj } from '../precomp/precomposition-layer';
import { getCurrentScene } from '../utils';
import { newQuickjsObject } from '../vmInterface/marshal/object';
import { getTranslatedObject } from '../vmInterface/unmarshal/object';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods, registerObjectMethods } from '../vmInterface/wrapper';

import { getComposableMethods } from './composable';
import { getHasTimelineMethods } from './has-timeline';
import { getSizableMethods } from './sizable';
import { getAnimationType } from './utils';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { resizePrecompLayer } from '~/lib/function/import';
import { dotLottiePlugin, lottiePlugin, renameNestedScenes, stateHistory, svgPlugin } from '~/lib/toolkit';
import { verifySanitizeURL } from '~/utils';

const methodNames = {
  createPrecompositionAsset: 'createPrecompositionAsset',
  crop: 'crop',
  bounds: 'bounds',
  resize: 'resize',
  assets: 'assets',
  hasSlots: 'hasSlots',
  slots: 'slots',
  createSlot: 'createSlot',
  getSlotById: 'getSlotById',
};

export interface ImportOptions {
  animation: string;
  standardizeTimeline?: boolean;
}

export enum UrlType {
  JSON = 'url-json',
  LOTTIE = 'url-lottie',
  SVG = 'url-svg',
}

export enum StringType {
  JSON = 'string-json',
  SVG = 'string-svg',
}

async function importAsPrecompositionAsset(
  scene: Scene,
  vmOptions: QuickJSHandle,
  plugin: Plugin,
): Promise<QuickJSHandle> {
  const options = getTranslatedObject(plugin, vmOptions) as ImportOptions;
  const animationType = getAnimationType(options);

  let precompAsset;

  stateHistory.beginAction();

  if (animationType === UrlType.LOTTIE) {
    precompAsset = await scene.importAsPrecompositionAsset(dotLottiePlugin.id, {
      dotlottie: verifySanitizeURL(options.animation),
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  if (animationType === UrlType.JSON || animationType === StringType.JSON) {
    precompAsset = await scene.importAsPrecompositionAsset(lottiePlugin.id, {
      animation: animationType === UrlType.JSON ? verifySanitizeURL(options.animation) : options.animation,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  if (animationType === UrlType.SVG || animationType === StringType.SVG) {
    let svgString = options.animation;

    if (animationType === UrlType.SVG) {
      const response = await fetch(verifySanitizeURL(options.animation));

      svgString = await response.text();
    }

    precompAsset = await scene.importAsPrecompositionAsset(svgPlugin.id, {
      svgString,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);

  stateHistory.endAction();

  const strippedPrecomp = createPrecompAssetObj(plugin, precompAsset as PrecompositionAsset);
  const vmStrippedPrecomp = newQuickjsObject(plugin, strippedPrecomp, true);

  return vmStrippedPrecomp;
}

async function importAsPrecompositionLayer(
  scene: Scene,
  vmOptions: QuickJSHandle,
  plugin: Plugin,
): Promise<QuickJSHandle> {
  const options = getTranslatedObject(plugin, vmOptions) as ImportOptions;
  const animationType = getAnimationType(options);

  let precompLayer;

  stateHistory.beginAction();

  if (animationType === UrlType.LOTTIE) {
    precompLayer = await scene.importAsPrecompositionLayer(dotLottiePlugin.id, {
      dotlottie: verifySanitizeURL(options.animation),
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  if (animationType === UrlType.JSON || animationType === StringType.JSON) {
    precompLayer = await scene.importAsPrecompositionLayer(lottiePlugin.id, {
      animation: animationType === UrlType.JSON ? verifySanitizeURL(options.animation) : options.animation,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  if (animationType === UrlType.SVG || animationType === StringType.SVG) {
    let svgString = options.animation;

    if (animationType === UrlType.SVG) {
      const response = await fetch(verifySanitizeURL(options.animation));

      svgString = await response.text();
    }

    const { fr, op } = scene.state.timeline.properties;
    const [frameRate, endFrame] = [fr, op];

    precompLayer = await scene.importAsPrecompositionLayer(svgPlugin.id, {
      importOptions: {
        // Note (May 2024)
        // - CSS gradients are not supported yet
        // - Might have inaccuracies when converting SVGs using objectBoundingBox
        useExperimentalGradientSupport: true,
        defaultNames: true,
        parsingOptions: {
          dpi: 72,
          maxFrames: endFrame as number,
          minFrames: endFrame as number,
          frameRate: frameRate as number,
        },
      },
      svgString,
      standardizeTimeline: options.standardizeTimeline ?? true,
    });
  }

  resizePrecompLayer(getCurrentScene(), precompLayer as PrecompositionLayer);
  renameNestedScenes();
  emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);

  stateHistory.endAction();

  const strippedPrecomp = createPrecompLayerObj(plugin, precompLayer as PrecompositionLayer);
  const vmStrippedPrecomp = newQuickjsObject(plugin, strippedPrecomp, true);

  return vmStrippedPrecomp;
}

function getSceneMethods(plugin: Plugin, scene: Scene): ObjectMethods {
  return getObjectMethods(plugin, methodNames, scene);
}

export function createSceneObject(plugin: Plugin, scene: Scene): object {
  const sceneObject = {
    nodeId: scene.nodeId,
    nodeType: scene.nodeType,
    importAsPrecompositionLayer: async (args: QuickJSHandle) => {
      return importAsPrecompositionLayer(scene, args, plugin);
    },
    importAsPrecompositionAsset: async (args: QuickJSHandle) => {
      return importAsPrecompositionAsset(scene, args, plugin);
    },
  };

  registerObjectMethods(plugin, scene, sceneObject, [
    getNodeMethods,
    getComposableMethods,
    getHasTimelineMethods,
    getSizableMethods,
    getSceneMethods,
  ]);

  return sceneObject;
}
