/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AnimatedPropertiesJSON, ColorJSON, FillShape, PercentageJSON } from '@lottiefiles/toolkit-js';
import { PropertyType, ShapeType } from '@lottiefiles/toolkit-js';
import { colord } from 'colord';
import React, { useCallback, useMemo } from 'react';

import type { AnimatedInputProp } from './animatedUtil';

import { ColorInput } from '~/components/Elements/ColorInput/ColorInput';
import type { NumberResult } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedColor: React.FC<AnimatedInputProp> = ({ animatedProp, type }) => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
  const { b, g, r } = animatedProp.value as ColorJSON;

  // Note: color's alpha is NOT SUPPORTED on Lottie Player. Use fill opacity instead.
  const node = getNodeByIdOnly(animatedProp.id);

  const opacity =
    ((node?.parent?.state?.animatedProperties as AnimatedPropertiesJSON).o.value as PercentageJSON).pct || 100;

  const colorString = useMemo(
    () =>
      colord({ r, g, b, alpha: opacity / 100 })
        .toHex()
        .replace('#', ''),
    [r, g, b, opacity],
  );

  const setAnimatedValue = useCreatorStore.getState().toolkit.setAnimatedValue;

  const handleOnChangeColor = useCallback(
    (color: string) => {
      const rgba = colord(color).toRgb();

      if (type === PropertyType.FILL_COLOR) {
        setAnimatedValue(AnimatedType.FILL_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a], node?.parent.nodeId);
      } else if (type === PropertyType.STROKE_COLOR) {
        setAnimatedValue(AnimatedType.STROKE_COLOR, [rgba.r, rgba.g, rgba.b, rgba.a], node?.parent.nodeId);
      }
      emitter.emit(EmitterEvent.SHAPE_FILL_UPDATED);
    },
    [node?.parent.nodeId, setAnimatedValue, type],
  );
  const handleOnChangeOpacity = useCallback(
    (result: NumberResult) => {
      const currentOpacity = opacity ? opacity : 0;

      if (currentOpacity !== result.value) {
        if (type === PropertyType.FILL_COLOR) {
          setAnimatedValue(AnimatedType.FILL_OPACITY, [result.value], node?.parent.nodeId);
        } else if (type === PropertyType.STROKE_COLOR) {
          setAnimatedValue(AnimatedType.STROKE_OPACITY, [result.value], node?.parent.nodeId);
        }

        emitter.emit(EmitterEvent.SHAPE_FILL_OPACITY_UPDATED);
      }
    },
    [opacity, type, setAnimatedValue, node?.parent.nodeId],
  );

  return (
    <div className="flex items-center">
      <ColorInput
        overrideStyle={{ left: '350px' }}
        colorStyleClass={'!w-3 !h-3'}
        styleClass="rounded-r-none w-[70px] !h-4 justify-end"
        inputStyleClass="text-[10px] w-[45px] !h-4 bg-transparent"
        color={colorString}
        opacity={opacity}
        onChangeColor={handleOnChangeColor}
        shapeType={type === PropertyType.FILL_COLOR ? ShapeType.FILL : ShapeType.STROKE}
        onChangeOpacity={(value: number) =>
          handleOnChangeOpacity({
            name: 'opacity',
            trueValue: value,
            value,
          })
        }
        enableColorModeChange={type === PropertyType.FILL_COLOR}
        selectedIds={[(node?.parent as FillShape).nodeId]}
      />
    </div>
  );
};
