/**
 * Copyright 2022 Design Barn Inc.
 */

import type { SceneJSON } from '@lottiefiles/toolkit-js';
import type { MutableRefObject } from 'react';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import {
  LAYER_PANEL_DEFAULT_WIDTH,
  TIMELINE_BAR_BEGIN_OFFSET_PX,
  TIMELINE_SCRUBBER_HIGHLIGHT_MIN_WIDTH,
  TIMELINE_SIDEBAR_WIDTH,
} from '../constant';
import { getVisibleKeyFrames } from '../helper';

import { useTimelineUtils } from './hooks';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { GlobalCursorType } from '~/store/uiSlice';

export const getSnappedFrame = (currentFrame: number, totalFrames: number, visibleKeyFrames: number[]): number => {
  let newFrame = currentFrame;
  const snapRange = 0.02 * totalFrames;

  const snapRangeFrames = visibleKeyFrames.filter((frame) => Math.abs(frame - newFrame) < snapRange);

  if (snapRangeFrames.length > 0) {
    newFrame = snapRangeFrames.reduce((prev, curr) =>
      Math.abs(curr - newFrame) < Math.abs(prev - newFrame) ? curr : prev,
    );
  }

  return newFrame;
};

interface Props {}

export const TimelineScrubber: React.FC<Props> = () => {
  const setGlobalCursor = useCreatorStore.getState().ui.setGlobalCursor;

  const scrubberHeadRef = useRef() as MutableRefObject<HTMLDivElement | null>;

  const [
    currentFrame,
    selectedPrecompositionId,
    sceneDuration,
    sceneOp,
    selectedPrecompDuration,
    selectedPrecompOp,
    sceneIndex,
    setCurrentFrame,
    width,
    isScrubbing,
    setIsScrubbing,
    expandedLayerIds,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.currentFrame || 0,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.json?.timeline.duration,
      state.toolkit.json?.timeline.properties.op as number,
      state.toolkit.selectedPrecompositionJson?.timeline.duration,
      state.toolkit.selectedPrecompositionJson?.timeline.properties.op as number,
      state.toolkit.sceneIndex,
      state.toolkit.setCurrentFrame,
      state.timeline.keyframeScrubberWidth,
      state.timeline.isScrubbing,
      state.timeline.setIsScrubbing,
      state.timeline.expandedLayerIds,
    ],
    shallow,
  );

  const [isHover, setIsHover] = useState(false);

  const { finalWidth, timeline, totalFrames } = useTimelineUtils(width);

  useEffect(() => {
    if (selectedPrecompDuration && currentFrame > selectedPrecompOp) {
      setCurrentFrame(selectedPrecompOp);
    } else if (!selectedPrecompDuration && sceneDuration && currentFrame > sceneOp) {
      setCurrentFrame(sceneOp);
    }
    emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, { skipUpdate: true });
  }, [sceneDuration, selectedPrecompOp, selectedPrecompDuration, sceneOp, currentFrame, setCurrentFrame]);

  useEffect(() => {
    const selectedPrecompositionNode = selectedPrecompositionId ? toolkit.getNodeById(selectedPrecompositionId) : null;
    const selectedPrecompositionJson = selectedPrecompositionNode?.state as SceneJSON | undefined;

    const precompCurrentFrame = selectedPrecompositionJson?.timeline.properties.cf as number;

    const xPosCalculated =
      ((selectedPrecompositionId ? precompCurrentFrame : currentFrame) / totalFrames) *
      (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX);

    if (scrubberHeadRef.current) scrubberHeadRef.current.style.transform = `translate(${xPosCalculated}px, 0px)`;
  }, [selectedPrecompositionId, currentFrame, width, sceneIndex, sceneDuration, selectedPrecompDuration, totalFrames]);

  const gotoNextKeyFrame = useCallback(() => {
    const visibleKeyFrames = getVisibleKeyFrames(timeline, expandedLayerIds);
    const filteredFrames = visibleKeyFrames.filter((frame) => frame > currentFrame);

    if (filteredFrames.length === 0) return;

    const newFrame = Math.min(...filteredFrames);

    setCurrentFrame(newFrame);
    emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, { skipUpdate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentFrame]);

  const gotoPreviousKeyFrame = useCallback(() => {
    const visibleKeyFrames = getVisibleKeyFrames(timeline, expandedLayerIds);
    const filteredFrames = visibleKeyFrames.filter((frame) => frame < currentFrame);

    if (filteredFrames.length === 0) return;

    const newFrame = Math.max(...filteredFrames);

    setCurrentFrame(newFrame);
    emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, { skipUpdate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentFrame]);

  useEffect(() => {
    emitter.on(EmitterEvent.PLAYBACK_GOTO_NEXT_KEYFRAME, gotoNextKeyFrame);
    emitter.on(EmitterEvent.PLAYBACK_GOTO_PREVIOUS_KEYFRAME, gotoPreviousKeyFrame);

    return () => {
      emitter.off(EmitterEvent.PLAYBACK_GOTO_NEXT_KEYFRAME, gotoNextKeyFrame);
      emitter.off(EmitterEvent.PLAYBACK_GOTO_PREVIOUS_KEYFRAME, gotoPreviousKeyFrame);
    };
  }, [gotoNextKeyFrame, gotoPreviousKeyFrame]);

  const handleHeaderDrag = useCallback(
    (event: PointerEvent, visibleKeyFrames: number[]): void => {
      let xPos = event.clientX - (TIMELINE_SIDEBAR_WIDTH + LAYER_PANEL_DEFAULT_WIDTH + TIMELINE_BAR_BEGIN_OFFSET_PX);

      if (xPos < 0) xPos = 0;
      if (xPos > width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX) xPos = width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX;

      // Width ratio can't be exceed 1
      const widthRatio = Math.min(1, Math.abs(xPos / (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX)));

      // Compute Current Frame
      let newFrame = Math.round(widthRatio * (totalFrames - 1));

      if (event.shiftKey) {
        // Holding down the shift key while dragging the scrubber will make it snap
        // to **visible** keyframes only. Visible as in visible from the timeline.
        // Keyframes that belong to unexpanded layers will not be considered.
        newFrame = getSnappedFrame(newFrame, totalFrames, visibleKeyFrames);
      }

      const newXPos = ((width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX) * newFrame) / totalFrames;

      if (scrubberHeadRef.current) scrubberHeadRef.current.style.transform = `translate(${newXPos}px, 0px)`;
      setCurrentFrame(newFrame);

      // Send event to get latest toolkit state
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, { skipUpdate: true });
    },
    [setCurrentFrame, totalFrames, width],
  );

  const closeHeaderDrag = (): void => {
    document.onpointerup = null;
    document.onpointermove = null;
    setIsScrubbing(false);
    emitter.emit(EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED, { skipUpdate: true });
  };

  useEffect(() => {
    setGlobalCursor(isScrubbing ? GlobalCursorType.GRABBING : GlobalCursorType.DEFAULT);
  }, [setGlobalCursor, isScrubbing]);

  const frameWidthStyle = useMemo(() => {
    const frameWidth = finalWidth / totalFrames;

    if (frameWidth < TIMELINE_SCRUBBER_HIGHLIGHT_MIN_WIDTH) {
      return {};
    }

    return {
      width: `${finalWidth / totalFrames}px`,
    };
  }, [finalWidth, totalFrames]);

  return (
    <div
      ref={scrubberHeadRef}
      onPointerDown={() => {
        setIsScrubbing(true);
        const visibleKeyFrames = getVisibleKeyFrames(timeline, expandedLayerIds);

        document.onpointermove = (event) => handleHeaderDrag(event, visibleKeyFrames);
        document.onpointerup = closeHeaderDrag;
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      id="playhead-scrubber"
      className={`absolute top-[-8px] z-scrubber mt-[24px] h-[17.36px] w-7 rounded ${
        isScrubbing ? GlobalCursorType.GRABBING : GlobalCursorType.POINTER
      }`}
    >
      <div
        className={`absolute top-0 ml-[7.2px] rounded border-x-[9px] border-t-[8px] border-x-transparent ${isHover ? 'border-t-orange-300' : 'border-t-orange-700'} text-white`}
      ></div>
      <div
        className={`absolute left-[8px] h-[1000px] w-4 ${isHover ? 'bg-orange-300' : 'bg-orange-700'} opacity-0 hover:bg-orange-300`}
      ></div>
      <div className={`absolute left-[15px] h-[1000px] w-0.5 ${isHover ? 'bg-orange-300' : 'bg-orange-700'}`}></div>
      <div
        className="absolute left-[17px] top-[5px] h-[1000px] bg-gray-300 opacity-[20%]"
        style={frameWidthStyle}
      ></div>
    </div>
  );
};
