/**
 * Copyright 2024 Design Barn Inc.
 */

import { BufferGeometry, Matrix4, Mesh, Vector2, Vector3 } from 'three';
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';

import { Elements } from '..';

import { materialOptions, RENDER_ORDERS } from '.';
import { WHITE_COLOR, CObject3D, BLACK_COLOR } from '~/features/canvas';
import type { CurrentGFillShape } from '~/lib/toolkit';
import { getPathToRoot } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

export const GRADIENT_LINE_WIDTH = 2;
export const GRADIENT_LINE_DROP_SHADOW_1_WIDTH = 3.5;
export const GRADIENT_LINE_DROP_SHADOW_2_WIDTH = 4.5;

const gradientMaterialOptions = { ...materialOptions };

const reusableMaterials = {
  gradientLine: new MeshLineMaterial({
    color: WHITE_COLOR,
    lineWidth: GRADIENT_LINE_WIDTH,
    ...gradientMaterialOptions,
  }),
  dropShadow: new MeshLineMaterial({
    color: BLACK_COLOR,
    lineWidth: GRADIENT_LINE_DROP_SHADOW_1_WIDTH,
    opacity: 0.06,
    ...gradientMaterialOptions,
  }),
  dropShadowOuter: new MeshLineMaterial({
    color: BLACK_COLOR,
    lineWidth: GRADIENT_LINE_DROP_SHADOW_2_WIDTH,
    opacity: 0.04,
    ...gradientMaterialOptions,
  }),
};

export const updateGradientLine = (gradientLine: CObject3D, startPoint: Vector2, endPoint: Vector2): void => {
  // note: creating a new geometry because directly updating the points
  // seems to stretch out the line and lower its quality when the line is long
  gradientLine.children.forEach((child) => {
    (child as Mesh).geometry.dispose();
  });

  const geometry = new BufferGeometry().setFromPoints([startPoint, endPoint]);

  gradientLine.children.forEach((child) => {
    (child as Mesh).geometry.setGeometry(geometry);
  });
};

export const createGradientLine = (startPoint: Vector2, endPoint: Vector2): CObject3D => {
  const gradientLine = new CObject3D();
  const meshLine = new MeshLine();
  const dropShadow = new MeshLine();
  const dropShadowOuter = new MeshLine();

  const geometry = new BufferGeometry().setFromPoints([startPoint, endPoint]);

  meshLine.setGeometry(geometry);
  dropShadow.setGeometry(geometry);
  dropShadowOuter.setGeometry(geometry);

  dropShadow.material = reusableMaterials.dropShadow;
  dropShadowOuter.material = reusableMaterials.dropShadowOuter;

  const mesh = new Mesh(meshLine.geometry, reusableMaterials.gradientLine);
  const dropShadowMesh = new Mesh(dropShadow.geometry, dropShadow.material);
  const dropShadowMeshOuter = new Mesh(dropShadowOuter.geometry, dropShadowOuter.material);

  mesh.raycast = MeshLineRaycast;

  mesh.userData['type'] = Elements.GRADIENT_LINE;
  dropShadowMesh.userData['type'] = Elements.GRADIENT_LINE_DROP_SHADOW_1;
  dropShadowMeshOuter.userData['type'] = Elements.GRADIENT_LINE_DROP_SHADOW_2;
  gradientLine.userData['type'] = Elements.GRADIENT_LINE;

  mesh.renderOrder = RENDER_ORDERS.GRADIENT_LINE;
  gradientLine.renderOrder = RENDER_ORDERS.GRADIENT_LINE;

  gradientLine.add(mesh);
  gradientLine.add(dropShadowMesh);
  gradientLine.add(dropShadowMeshOuter);

  return gradientLine;
};

export const getGradientStartAndEnd = (
  shape: CurrentGFillShape,
): { end: Vector2; start: Vector2; transformationMatrices: Matrix4[] } => {
  const nodeId = shape.id as string;
  const lineStart = new Vector3(shape.gsX, shape.gsY, 0);
  const lineEnd = new Vector3(shape.geX, shape.geY, 0);
  const pathToRoot = getPathToRoot(nodeId).slice(1);
  const matrices: Matrix4[] = [];

  pathToRoot.forEach((id: string) => {
    const node = getNodeByIdOnly(id);

    if (node && node.transform) {
      const matrix = new Matrix4().fromArray(node.transform.matrix.matrix);

      lineStart.applyMatrix4(matrix);
      lineEnd.applyMatrix4(matrix);

      matrices.push(matrix);
    }
  });

  return {
    end: new Vector2(lineEnd.x, lineEnd.y),
    start: new Vector2(lineStart.x, lineStart.y),
    transformationMatrices: matrices,
  };
};

export const getUnprojectedGradientLine = (
  start: Vector2,
  end: Vector2,
  transformationMatrices: Matrix4[],
): { end: Vector2; start: Vector2 } => {
  const unprojectedStart = new Vector3(start.x, start.y, 0);
  const unprojectedEnd = new Vector3(end.x, end.y, 0);

  transformationMatrices
    .slice()
    .reverse()
    .forEach((matrix) => {
      unprojectedStart.applyMatrix4(matrix.clone().invert());
      unprojectedEnd.applyMatrix4(matrix.clone().invert());
    });

  return {
    end: new Vector2(unprojectedEnd.x, unprojectedEnd.y),
    start: new Vector2(unprojectedStart.x, unprojectedStart.y),
  };
};
