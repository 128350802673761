/**
 * Copyright 2022 Design Barn Inc.
 */

import { useMemo, useContext } from 'react';
import { pipe, subscribe } from 'wonka';

import {
  FILE_CREATE_MUTATION,
  FILE_VERSION_CREATE_MUTATION,
  FILE_UPLOAD_CREATE_REQUEST_MUTATION,
  FILE_VERSION_QUERY,
  FILE_UPDATE_SUBSCRIPTION,
  FILE_BY_ID_QUERY,
  SHARE_FILE_MUTATION,
  GET_SHARE_CODE_QUERY,
  FILE_BY_SHARED_CODE_QUERY,
  RECENT_FILES_QUERY,
} from './schema';
import { wrapCallbacks } from './shared';

import { BASE_GRAPHQL_WSS_ENDPOINT, BASE_GRAPHQL_ENDPOINT } from '~/config';
import { clientAPIContext } from '~/lib/graphql';
import { useCreatorStore } from '~/store';
import { DirectoryType } from '~/store/projectSlice';

export interface UploadFileRequestProps {
  fields: Record<string, string>;
  key: string;
  url: string;
}

export interface RecentFile {
  backgroundColor: string;
  fileObject: {
    thumbnails: {
      png: {
        medium: {
          url: string;
        };
      };
    };
  };
  id: string;
  name: string;
  updatedAt: string;
}

export interface ExistingFileType {
  backgroundColor: string;
  createdByUserId: string;
  currentVersionId: string;
  fileObject: {
    attributes: {
      contentType: string;
    };
    key: string;
    url: string;
    versionId: string;
  };
  folder?: {
    name: string;
    projectId: string;
  };
  folderId: string;
  id: string;
  name: string;
  project: {
    title: string;
    workspaceId: string;
  };
  projectId: string;
}

interface ProjectAPIProps {
  createFile(): Promise<Record<string, string> | null>;
  createNewVersion(): Promise<Record<string, string> | null>;
  createUploadRequest(): Promise<UploadFileRequestProps | null>;
  getFileById(id: string): Promise<ExistingFileType | null>;
  getFileBySharedCode(sharedCode: string): Promise<ExistingFileType | null>;
  getFileShareCode(): Promise<string>;
  getLatestVersionId(params: Record<string, string>): Promise<string>;
  getRecentFiles(): Promise<RecentFile[]>;
  handleProjectSubscription({ key }: Record<string, string>): Promise<boolean>;
  shareFile(): Promise<Record<string, string> | null>;
  unShareFile(): Promise<void>;
}

export const useProjectAPI = (token: string | null): ProjectAPIProps => {
  const apiClient = useContext(clientAPIContext);

  const context = useMemo(
    () => ({
      clientName: BASE_GRAPHQL_ENDPOINT,
      token,
    }),
    [token],
  );

  const wsContext = useMemo(
    () => ({
      clientName: BASE_GRAPHQL_WSS_ENDPOINT,
      token,
    }),
    [token],
  );

  const getLatestVersionId = async (params: Record<string, string>): Promise<string> => {
    const res = await apiClient.query(FILE_VERSION_QUERY, params, context).toPromise();

    if (res.error) {
      return Promise.reject(res.error);
    }

    const latestVersionId = res.data?.file?.currentVersionId;

    return Promise.resolve(latestVersionId);
  };

  const createUploadRequest = async (): Promise<Record<string, Record<string, string> | string> | null> => {
    const newFileSubscribeKey = useCreatorStore.getState().project.info.fileSubscribeKey;
    const newFileVersionId = useCreatorStore.getState().project.info.fileVersionId;

    const isUpdate = Boolean(newFileSubscribeKey && newFileVersionId);

    const requestParam = isUpdate
      ? {
          input: {
            key: newFileSubscribeKey,
            type: 'update',
          },
        }
      : {};
    const res = await apiClient.mutation(FILE_UPLOAD_CREATE_REQUEST_MUTATION, requestParam, context).toPromise();

    if (res.error) {
      return Promise.reject();
    }

    const uploadFMSData = res.data?.fileUploadRequestCreate;

    return Promise.resolve(uploadFMSData);
  };

  const createFile = async (): Promise<Record<string, string> | null> => {
    const newFileSubscribeKey = useCreatorStore.getState().project.info.fileSubscribeKey;
    const subscriptionVersionId = useCreatorStore.getState().project.info.subscriptionVersionId;
    const projectName = useCreatorStore.getState().project.info.name;
    const directoryId = useCreatorStore.getState().project.selectedDirectory?.id;
    const directoryParentId = useCreatorStore.getState().project.selectedDirectory?.parentId;
    const directoryType = useCreatorStore.getState().project.selectedDirectory?.type;
    const background = useCreatorStore.getState().canvas.background;

    let inputDirectory = {};

    if (directoryType === DirectoryType.Folder) {
      inputDirectory = { projectId: directoryParentId, folderId: directoryId };
    } else {
      // default or project
      inputDirectory = { projectId: directoryId };
    }

    const requestParam = {
      input: {
        name: projectName || `Animation - ${new Date().getTime()}`,
        fileKey: newFileSubscribeKey,
        fileVersionId: subscriptionVersionId,
        backgroundColor: background.color,
        ...inputDirectory,
      },
    };

    const res = await apiClient.mutation(FILE_CREATE_MUTATION, requestParam, context).toPromise();

    if (res.error) {
      return Promise.reject();
    }

    // NOTE: If error here, resolve with error
    const fileCreateData = res.data?.fileCreate;

    return Promise.resolve(fileCreateData);
  };

  const createNewVersion = async (): Promise<Record<string, string> | null> => {
    const newFileSubscribeKey = useCreatorStore.getState().project.info.fileSubscribeKey;
    const subscriptionVersionId = useCreatorStore.getState().project.info.subscriptionVersionId;
    const fileId = useCreatorStore.getState().project.info.fileId;
    const projectName = useCreatorStore.getState().project.info.name;
    const background = useCreatorStore.getState().canvas.background;

    const requestParam = {
      input: {
        fileId,
        fileKey: newFileSubscribeKey,
        fileVersionId: subscriptionVersionId,
        backgroundColor: background.color,
        name: projectName,
      },
    };
    const res = await apiClient.mutation(FILE_VERSION_CREATE_MUTATION, requestParam, context).toPromise();

    if (res.error) {
      return Promise.reject(res.error);
    }

    const fileVersionCreate = res.data?.fileVersionCreate;

    return Promise.resolve(fileVersionCreate);
  };

  const shareFile = async (): Promise<Record<string, string> | null> => {
    const fileId = useCreatorStore.getState().project.info.fileId;

    const requestParam = {
      resourceId: fileId,
      resourceType: 'FILE',
      access: ['viewOnly'],
      allowGuestView: true,
    };
    const res = await apiClient.mutation(SHARE_FILE_MUTATION, requestParam, context).toPromise();

    if (res.error) {
      return Promise.reject();
    }

    const publicShareCreate = res.data?.publicShareCreate;

    return Promise.resolve(publicShareCreate);
  };

  const unShareFile = async (): Promise<void> => {
    const fileId = useCreatorStore.getState().project.info.fileId;

    const requestParam = {
      resourceId: fileId,
      resourceType: 'FILE',
      access: ['noAccess'],
      allowGuestView: false,
    };
    const res = await apiClient.mutation(SHARE_FILE_MUTATION, requestParam, context).toPromise();

    if (res.error) {
      return Promise.reject();
    }

    return Promise.resolve();
  };

  const getFileShareCode = async (): Promise<string | null> => {
    const fileId = useCreatorStore.getState().project.info.fileId;

    const requestParam = {
      resourceId: fileId,
    };
    const res = await apiClient
      .query(GET_SHARE_CODE_QUERY, requestParam, { ...context, requestPolicy: 'network-only' })
      .toPromise();

    if (res.error) {
      return Promise.reject();
    }

    const accessLevels = res.data?.publicShare?.accessLevels || [];
    const shareCode = accessLevels.includes('viewOnly') ? res.data?.publicShare?.shareCode : null;

    return Promise.resolve(shareCode);
  };

  const getFileBySharedCode = async (shareCode: string): Promise<ExistingFileType | null> => {
    const res = await apiClient.query(FILE_BY_SHARED_CODE_QUERY, { shareCode }, context).toPromise();
    const creatorFile = res.data?.publicShareByCode?.resource as ExistingFileType;

    return Promise.resolve(creatorFile);
  };

  const handleProjectSubscription = async ({ key }: Record<string, string>): Promise<boolean> => {
    const requestParam = { key };

    // One-time-off subscribe
    const { unsubscribe } = pipe(
      apiClient.subscription(FILE_UPDATE_SUBSCRIPTION, requestParam, wsContext),
      subscribe((response) => {
        // SaveToWorkflow step 3 - listening for file update change
        if (response.data) {
          const { fileUpdate } = response.data;
          const { versionId } = fileUpdate as Record<string, string>;

          const setInfo = useCreatorStore.getState().project.setInfo;

          setInfo({
            subscriptionVersionId: versionId as string,
          });
        }

        return (unsubscribe as () => void)();
      }),
    ) as Record<string, () => void>;

    const currentSubVersionId = useCreatorStore.getState().project.info.subscriptionVersionId;

    const updatingFile = (callback: (selectedState: boolean, previousSelectedState: boolean) => void): (() => void) => {
      return useCreatorStore.subscribe(
        (state) => state.project.info.subscriptionVersionId !== currentSubVersionId,
        callback,
        {
          fireImmediately: true,
        },
      );
    };

    const waitForSubIdUpdate = async (): Promise<boolean> => {
      return new Promise<boolean>((resolve) => {
        const unsub = updatingFile((isSaved: boolean) => {
          if (isSaved) {
            unsub();

            resolve(true);
          }
        });
      });
    };

    const done = await waitForSubIdUpdate();

    if (unsubscribe) unsubscribe();

    return Promise.resolve(done);
  };

  const getFileById = async (id: string): Promise<ExistingFileType | null> => {
    const res = await apiClient.query(FILE_BY_ID_QUERY, { id }, context).toPromise();
    const creatorFile = res.data?.file;

    return Promise.resolve(creatorFile);
  };

  const getRecentFiles = async (): Promise<RecentFile[]> => {
    const res = await apiClient.query(RECENT_FILES_QUERY, {}, context).toPromise();
    const files = res.data?.filesRecentlyModified;

    return Promise.resolve(files);
  };

  const contextCallbackAPIs = wrapCallbacks(
    {
      getLatestVersionId,
      createUploadRequest,
      createFile,
      createNewVersion,
      getFileById,
      getFileBySharedCode,
      shareFile,
      unShareFile,
      getFileShareCode,
      getRecentFiles,
    },
    [apiClient, context],
  ) as ProjectAPIProps;

  const wsContextCallbackAPIs = wrapCallbacks(
    {
      handleProjectSubscription,
    },
    [apiClient, wsContext],
  ) as ProjectAPIProps;

  return {
    ...contextCallbackAPIs,
    ...wsContextCallbackAPIs,
  };
};
