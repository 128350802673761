/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable no-case-declarations */
/* eslint-disable padding-line-between-statements */
import { ShapeType } from '@lottiefiles/lottie-js';
import type {
  Layer,
  PrecompositionAsset,
  PrecompositionLayer,
  SceneJSON,
  ShapeLayer,
  SizeJSON,
  TrackMatteType,
} from '@lottiefiles/toolkit-js';
import { LayerType, DagNodeType, Vector, DagNode } from '@lottiefiles/toolkit-js';
import { clamp } from 'lodash-es';
import { Vector3 } from 'three';

import { matteUpdate } from '../3d/threeFactory';
import { UserDataMap } from '../constant';
import type { CObject3D } from '../types';
import { PasteMode } from '../viewport/editor';
import type Viewport from '../viewport/viewport';

import { handleFrameUpdateEndDebounced, updateCurrentTransformsThrottled } from './helpers';
import { Parser } from './parser';
import { reAdjustPrecompCanvas } from './precomp';

import type { EasingControlOption, EasingTypeOption } from '~/data/eventStore';
import { EasingControl, EasingType, EventType, SegmentSource } from '~/data/eventStore';
import { LINEAR_IN_LINEAR_OUT, LINEAR_IN_SMOOTH_OUT, SMOOTH_IN_LINEAR_OUT, SMOOTH_IN_SMOOTH_OUT } from '~/data/range';
// eslint-disable-next-line no-restricted-imports
import { resetDragToCanvas, getDragPosFromDragToCanvas } from '~/features/canvas/components/DragToConvasContainer';
// eslint-disable-next-line no-restricted-imports
import { toggleAnimateAll } from '~/features/global-modal/animation-adder/helpers';
import {
  resetWorkArea,
  trimInOutpointTo,
  trimSceneToWorkArea,
  TrimType,
  trimWorkAreaEndToPlayHead,
  trimWorkAreaStartToPlayHead,
  addSegment,
} from '~/features/timeline';
import { canvasMap, refreshScenePropertiesMap, removePrecompLayerMapItem, updateRenderRanges } from '~/lib/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getPlaybackParameters } from '~/lib/eventHandler/playback';
import { resetLayerUI } from '~/lib/layer';
import { AlignDirection, DistributionDirection } from '~/lib/threejs/TransformControls/types';
import type { CurrentGFillShape } from '~/lib/toolkit';
import { getToolkitState, setKeyFrame, stateHistory, toolkit } from '~/lib/toolkit';
import { KeyboardDirection } from '~/lib/toolkit/constant';
import { updateSelectedKeyframeEasings } from '~/lib/toolkit/easings';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';
import type { KeyframeEasing } from '~/store/timelineSlice';
import type { AlignPivotDirection } from '~/store/uiSlice';
import { fireEvent, isNumber } from '~/utils';

const { addToSelectedNodes, setPivotVisibility, setScaleRatioLocked, setSizeRatioLocked } =
  useCreatorStore.getState().ui;
const removeKeyframes = useCreatorStore.getState().timeline.removeKeyframes;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const setCurrentTool = useCreatorStore.getState().ui.setCurrentTool;
const removeSelectedNode = useCreatorStore.getState().toolkit.removeSelectedNode;
const toggleRulers = useCreatorStore.getState().canvas.toggleRulers;
const toggleGuides = useCreatorStore.getState().canvas.toggleGuides;
const toggleGrid = useCreatorStore.getState().canvas.toggleGrid;
const toggleGridVisibility = useCreatorStore.getState().canvas.toggleGridVisibility;

export class ToolkitListener {
  public enable = true;

  public parser: Parser;

  public viewport: Viewport;

  public constructor(viewport: Viewport) {
    this.viewport = viewport;
    this.parser = new Parser(this.viewport.objectContainer);
  }

  public adjustCanvasSize(): void {
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

    if (!selectedPrecompositionId) {
      const json = getToolkitState(toolkit);
      if (!json) return;
      const size = json.properties.sz as SizeJSON;

      this.viewport.adjustCanvas(size);
    }
  }

  public categorizeEvent(
    event: EmitterEvent,
    data: Record<string, string | string[] | number | boolean | DagNode | SizeJSON> | null,
  ): void {
    const json = getToolkitState(toolkit);
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
    const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;

    switch (event) {
      case EmitterEvent.LOADING_ANIMATION:
      case EmitterEvent.LOADED_ANIMATION:
        this.redraw(true);

        if (data?.['id']) {
          this.viewport.selectByID([data['id'] as string]);
        }

        break;
      case EmitterEvent.RESET:
        this.viewport.clearScene();
        break;

      // We need to destroy the all scene objects and parse the whole toolkit json
      case EmitterEvent.TOOLKIT_JSON_IMPORTED:
        // Re-adjust the canvas size for imports and file loads
        emitter.emit(EmitterEvent.SCENE_SIZE_UPDATED);
        refreshScenePropertiesMap();
        this.redraw(false);
        break;

      case EmitterEvent.SHAPE_CREATED:
        this.redraw(true);
        break;
      case EmitterEvent.CANVAS_REDRAW:
        this.redraw(false);
        break;
      case EmitterEvent.CANVAS_RENDER_UPDATE:
        this.viewport.isReRenderingNeeded = true;
        break;
      case EmitterEvent.CANVAS_ZOOM_IN:
        this.viewport.zoomCamera(true);
        break;
      case EmitterEvent.CANVAS_ZOOM_OUT:
        this.viewport.zoomCamera(false);
        break;

      // need to adjust the camera only. No need to update anything else
      case EmitterEvent.CANVAS_ZOOM_TO_FIT:
        let size = json?.properties.sz as SizeJSON | null;
        if (size) {
          const precomp = useCreatorStore.getState().toolkit.selectedPrecompositionJson;

          if (precomp) {
            const { canvasHeight, canvasMinHeight, canvasMinWidth, canvasWidth } = precomp.data as Record<
              string,
              number
            >;
            size = {
              w: Number(canvasWidth) + Number(canvasMinWidth),
              h: Number(canvasHeight) + Number(canvasMinHeight),
            } as SizeJSON;
          }

          this.viewport.adjustCamera(size, { zoomToFit: true });
        }
        break;
      case EmitterEvent.CANVAS_ZOOM_FIT_TO_BOUNDING_BOX:
        if (json) this.viewport.adjustCamera(json.properties.sz as SizeJSON, { zoomToBoundingBox: true });
        break;
      case EmitterEvent.RECTANGLE_CREATED:
        this.viewport.createBasicShapes(ShapeType.RECTANGLE);
        break;
      case EmitterEvent.ELLIPSE_CREATED:
        this.viewport.createBasicShapes(ShapeType.ELLIPSE);
        break;
      // need to update the background size only.
      case EmitterEvent.SCENE_SIZE_UPDATED:
        this.adjustCanvasSize();
        break;
      case EmitterEvent.TIMELINE_TOGGLE_PRECOMP_SOURCE_NAME:
        useCreatorStore.getState().timeline.togglePrecompSource();
        break;
      case EmitterEvent.PRECOMP_SCENE_UPDATE_JSON:
        this.updatePrecompJSON();
        break;
      case EmitterEvent.PRECOMP_SCENE_SIZE_UPDATED:
        this.updatePrecompJSON();
        this.redraw(false, event);
        break;
      case EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED:
        const currentFrame = useCreatorStore.getState().toolkit.currentFrame;
        if (selectedPrecompositionId) {
          const assetNode = getNodeByIdOnly(selectedPrecompositionId);

          if (isNumber(currentFrame) && assetNode) {
            stateHistory.offTheRecord(() => {
              assetNode.timeline.setCurrentFrame(currentFrame);
            });
          }
        }

        updateCurrentTransformsThrottled();
        updateRenderRanges(currentFrame);

        if (!useCreatorStore.getState().canvas.hideTransformControls) {
          useCreatorStore.getState().canvas.setHideTransformControls(true);
        }
        this.viewport.transformControls.showTransformControls(false);
        if (this.viewport.pathControls.penEnabled) {
          this.viewport.pathControls.redrawPathControls();
        }
        // Emit 'TIMELINE_FRAME_UPDATE_ENDED' if 'TIMELINE_CURRENT_FRAME_UPDATED'
        // hasn't been emitted after a certain time
        handleFrameUpdateEndDebounced();
        matteUpdate();
        this.viewport.isReRenderingNeeded = true;
        break;

      // TIMELINE EVENTS: need to compare the previous toolkit state with the current one and update the only changed parts
      case EmitterEvent.TIMELINE_BAR_DRAGGING_UPDATED:
      case EmitterEvent.TIMELINE_BAR_START_TIME_UPDATED:
      case EmitterEvent.TIMELINE_BAR_END_TIME_UPDATED:
      case EmitterEvent.TIMELINE_BAR_RESIZE_LEFT_UPDATED:
      case EmitterEvent.TIMELINE_BAR_RESIZE_RIGHT_UPDATED:
      case EmitterEvent.TIMELINE_DURATION_UPDATED:
        emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
        break;
      case EmitterEvent.TIMELINE_FPS_UPDATED:
        this.redraw(false);
        emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
        break;
      case EmitterEvent.TIMELINE_LAYER_MOVE_SAME_LEVEL:
      case EmitterEvent.TIMELINE_LAYER_MOVE_DIFFERENT_LEVEL:
        this.updatePrecompJSON();
        this.redraw(false);
        break;
      case EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED:
        if (useCreatorStore.getState().timeline.isScrubbing) {
          return;
        }

        // When frame update is ended, continue to show the previous selection
        const updateTransformControls = !this.viewport.pathControls.penEnabled;

        if (updateTransformControls) this.viewport.transformControls.reselectLastObject();
        useCreatorStore.getState().canvas.setHideTransformControls(false, updateTransformControls);

        this.updateCurrentFrame();

        break;
      case EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES:
        toggleAnimateAll();
        this.redraw(false);
        break;
      case EmitterEvent.TIMELINE_TRIM_INPOINT_TO_PLAYHEAD:
        stateHistory.beginAction();
        trimInOutpointTo(TrimType.INPOINT, useCreatorStore.getState().toolkit.currentFrame);
        emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
        stateHistory.endAction();
        break;
      case EmitterEvent.TIMELINE_TRIM_OUTPOINT_TO_PLAYHEAD:
        stateHistory.beginAction();
        trimInOutpointTo(TrimType.OUTPOINT, useCreatorStore.getState().toolkit.currentFrame);
        emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
        stateHistory.endAction();
        break;
      case EmitterEvent.TIMELINE_TRIM_WORKAREA_START_TO_PLAYHEAD:
        trimWorkAreaStartToPlayHead();
        break;
      case EmitterEvent.TIMELINE_TRIM_WORKAREA_END_TO_PLAYHEAD:
        trimWorkAreaEndToPlayHead();
        break;
      case EmitterEvent.TIMELINE_TRIM_SCENE_TO_WORKAREA:
        trimSceneToWorkArea();
        break;
      case EmitterEvent.TIMELINE_RESET_WORKAREA:
        resetWorkArea();
        break;
      case EmitterEvent.TIMELINE_SET_WORKAREA_AS_SEGMENT:
        useCreatorStore.getState().timeline.setIsSegmentsDialogOpen(true);
        addSegment();
        fireEvent({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          event_type: EventType.AddedSegment,
          parameters: {
            source: SegmentSource.WorkArea,
          },
        });
        break;
      case EmitterEvent.CANVAS_COPY:
        this.viewport.editor?.copyObjects();
        break;
      case EmitterEvent.CANVAS_CUT:
        this.viewport.editor?.cutObjects();
        break;
      case EmitterEvent.CANVAS_DUPLICATE:
        this.viewport.editor?.duplicateObjects();
        break;
      case EmitterEvent.TIMELINE_PRECOMP_CREATE_SCENE:
        this.viewport.editor?.createNestedSceneFromSelectedObjects();
        break;
      case EmitterEvent.TIMELINE_PRECOMP_BREAK_SCENE:
        this.viewport.editor?.breakSelectedNestedScenes();
        break;
      case EmitterEvent.TIMELINE_PRECOMP_ADD_SCENE:
        this.viewport.editor?.addNestedScene();
        break;
      case EmitterEvent.TIMELINE_LAYER_FOCUS_UNFOCUS: {
        const nodeId = data?.['id'] as string;
        const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;

        const nodeExists = selectedNodes.some((nodeInfo) => nodeInfo.nodeId === nodeId);

        const ids = nodeExists || !nodeId ? selectedNodes.map((nodeInfo) => nodeInfo.nodeId as string) : [nodeId];
        this.viewport.editor?.focusUnfocusObjects(ids, nodeId);
        break;
      }
      case EmitterEvent.TIMELINE_LAYER_SHOW_HIDE: {
        const nodeId = data?.['id'] as string;
        const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;

        const nodeExists = selectedNodes.some((nodeInfo) => nodeInfo.nodeId === nodeId);

        const ids = nodeExists || !nodeId ? selectedNodes.map((nodeInfo) => nodeInfo.nodeId as string) : [nodeId];
        this.viewport.editor?.showHideObjects(ids, nodeId);
        break;
      }
      case EmitterEvent.TIMELINE_LAYER_MATTE_UPDATE: {
        const nodeId = data?.['id'] as string;
        const matteType = data?.['matteType'] as TrackMatteType;
        const ids = nodeId
          ? [nodeId]
          : useCreatorStore.getState().ui.selectedNodesInfo.map((nodeInfo) => nodeInfo.nodeId as string);
        this.viewport.editor?.updateMatteType(ids, matteType);
        break;
      }
      case EmitterEvent.TIMELINE_LAYER_LOCK_UNLOCK: {
        const nodeId = data?.['id'] as string;
        const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;

        const nodeExists = selectedNodes.some((nodeInfo) => nodeInfo.nodeId === nodeId);

        const ids = nodeExists || !nodeId ? selectedNodes.map((nodeInfo) => nodeInfo.nodeId as string) : [nodeId];
        this.viewport.editor?.lockUnlockObjects(ids, nodeId);
        break;
      }

      case EmitterEvent.CANVAS_COPY_AS_MASK:
        this.viewport.editor?.copyMask();
        break;
      case EmitterEvent.CANVAS_MASK_REMOVE:
        this.viewport.editor?.removeMask();
        emitter.emit(EmitterEvent.CANVAS_REDRAW);

        break;
      case EmitterEvent.CANVAS_MASK_CREATE:
        this.viewport.editor?.createMask();
        emitter.emit(EmitterEvent.CANVAS_REDRAW);

        break;
      case EmitterEvent.CANVAS_PASTE_AS_MASK:
        this.viewport.editor?.pasteMask();
        break;

      case EmitterEvent.CANVAS_PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT:
        this.viewport.editor?.pasteObjects(PasteMode.PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT);
        break;

      case EmitterEvent.CANVAS_PASTE_WITH_CLONED_ASSETS:
        this.viewport.editor?.pasteObjects(PasteMode.PASTE_WITH_CLONED_ASSETS);
        break;

      case EmitterEvent.UI_UNDO:
      case EmitterEvent.UI_REDO:
        this.restorePreviousCurrentframe();
        this.updatePrecompJSON();
        this.refreshSelectedNodes();
        this.redraw(false);

        const selectedObjectsOnCanvas = useCreatorStore
          .getState()
          .ui.selectedNodesInfo.filter((node) => canvasMap.get(node.nodeId)?.parent);
        if (selectedObjectsOnCanvas.length === 0) {
          this.viewport.transformControls.detach();
        } else {
          this.viewport.transformControls.updateGizmo();
        }

        updateSelectedKeyframeEasings();

        if (this.viewport.gradientControls.enabled) {
          this.viewport.gradientControls.redrawGradientControls();
        }

        break;

      case EmitterEvent.EASING_LINEAR_IN:
        this.updateEasing(EasingControl.In, EasingType.Linear);
        break;
      case EmitterEvent.EASING_LINEAR_OUT:
        this.updateEasing(EasingControl.Out, EasingType.Linear);
        break;
      case EmitterEvent.EASING_LINEAR_BOTH:
        this.updateEasing(EasingControl.Both, EasingType.Linear);
        break;
      case EmitterEvent.EASING_SMOOTH_IN:
        this.updateEasing(EasingControl.In, EasingType.Smooth);
        break;
      case EmitterEvent.EASING_SMOOTH_OUT:
        this.updateEasing(EasingControl.Out, EasingType.Smooth);
        break;
      case EmitterEvent.EASING_SMOOTH_BOTH:
        this.updateEasing(EasingControl.Both, EasingType.Smooth);
        break;

      case EmitterEvent.ANIMATED_SHAPE_GRADIENT_POINT_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_GRADIENT_START_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_GRADIENT_END_UPDATED:
      case EmitterEvent.SHAPE_GRADIENT_FILL_COLOR_UPDATED:
      case EmitterEvent.CANVAS_COLOR_MODE_UPDATED:
        this.redraw(false);

        if (event === EmitterEvent.CANVAS_COLOR_MODE_UPDATED) {
          this.updatePrecompJSON();
        }

        if (this.viewport.gradientControls.enabled) {
          this.viewport.gradientControls.redrawGradientControls();
        }

        break;

      // We need to destroy and recreate only selected object for these events
      case EmitterEvent.APPEARANCE_CREATED:
      case EmitterEvent.ANIMATED_ANCHOR_UPDATED:
      case EmitterEvent.ANIMATED_OPACITY_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_FILL_COLOR_ALPHA_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_FILL_COLOR_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_ALPHA_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_STROKE_WIDTH_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_TRIM_END_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_TRIM_OFFSET_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_TRIM_START_UPDATED:
      case EmitterEvent.ANIMATED_SHAPE_STROKE_OPACITY_UPDATED:
      case EmitterEvent.POLYSTAR_INNER_RADIUS_UPDATED:
      case EmitterEvent.POLYSTAR_INNER_ROUNDNESS_UPDATED:
      case EmitterEvent.POLYSTAR_OUTER_RADIUS_UPDATED:
      case EmitterEvent.POLYSTAR_OUTER_ROUNDNESS_UPDATED:
      case EmitterEvent.POLYSTAR_POINTS_UPDATED:
      case EmitterEvent.RECT_ROUNDNESS_UPDATED:
      case EmitterEvent.SHAPE_SIZE_UPDATED:
      case EmitterEvent.SHAPE_FILL_UPDATED:
      case EmitterEvent.SHAPE_FILL_COLOR_UPDATED:
      case EmitterEvent.SHAPE_FILL_OPACITY_UPDATED:
      case EmitterEvent.SHAPE_GRADIENT_UPDATED_FROM_GRADIENT_CONTROLS:
        this.redraw(false);
        break;

      case EmitterEvent.APPEARANCE_DELETED:
        const nodeIds = data?.['nodeIds'] as string[] | undefined;
        if (!nodeIds || nodeIds.length === 0) return;

        toolkit.batch(() => {
          nodeIds.forEach((nodeId) => removeSelectedNode(nodeId));
        });
        this.updatePrecompJSON();
        this.redraw(false);
        emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);

        break;

      // We need to change only transformation of the selected object for these events
      case EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED:
      case EmitterEvent.ANIMATED_POSITION_UPDATED:
      case EmitterEvent.ANIMATED_ROTATION_UPDATED:
      case EmitterEvent.ANIMATED_SCALE_UPDATED:
      case EmitterEvent.POLYSTAR_ROTATION_UPDATED:
      case EmitterEvent.SHAPE_POSITION_UPDATED:
      case EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED:
        this.redraw(false);
        break;
      case EmitterEvent.CANVAS_SELECTION_MOVE_DOWN:
        this.moveCanvasSelection(KeyboardDirection.DOWN, data?.['offsetBy'] as number);
        break;
      case EmitterEvent.CANVAS_SELECTION_MOVE_LEFT:
        this.moveCanvasSelection(KeyboardDirection.LEFT, data?.['offsetBy'] as number);
        break;
      case EmitterEvent.CANVAS_SELECTION_MOVE_RIGHT:
        this.moveCanvasSelection(KeyboardDirection.RIGHT, data?.['offsetBy'] as number);
        break;
      case EmitterEvent.CANVAS_SELECTION_MOVE_UP:
        this.moveCanvasSelection(KeyboardDirection.UP, data?.['offsetBy'] as number);
        break;

      // Handle alignment functions
      case EmitterEvent.ALIGNMENT_LEFT:
        this.viewport.transformControls.align(AlignDirection.Left);
        break;
      case EmitterEvent.ALIGNMENT_RIGHT:
        this.viewport.transformControls.align(AlignDirection.Right);
        break;
      case EmitterEvent.ALIGNMENT_CENTER:
        this.viewport.transformControls.align(AlignDirection.Center);
        break;
      case EmitterEvent.ALIGNMENT_TOP:
        this.viewport.transformControls.align(AlignDirection.Top);
        break;
      case EmitterEvent.ALIGNMENT_MIDDLE:
        this.viewport.transformControls.align(AlignDirection.Middle);
        break;
      case EmitterEvent.ALIGNMENT_BOTTOM:
        this.viewport.transformControls.align(AlignDirection.Bottom);
        break;
      case EmitterEvent.TOOLKIT_ANCHOR_VISIBILITY_UPDATED:
        const anchorVisibleNode = data?.['node'];
        if (anchorVisibleNode instanceof DagNode) {
          const anchorVisible = Boolean(data?.[UserDataMap.PivotVisible]);
          anchorVisibleNode.setData(UserDataMap.PivotVisible, anchorVisible);
          setPivotVisibility(anchorVisible);
        }
        break;
      case EmitterEvent.ALIGNMENT_DISTRIBUTION_VERTICAL:
        this.viewport.transformControls.align(DistributionDirection.Vertical);
        break;
      case EmitterEvent.ALIGNMENT_DISTRIBUTION_HORIZONTAL:
        this.viewport.transformControls.align(DistributionDirection.Horizontal);
        break;

      case EmitterEvent.TOOLKIT_NODE_SCALE_UPDATED:
        const scaleNode = data?.['node'];
        if (scaleNode instanceof DagNode) {
          const scaleRatioLocked = Boolean(data?.[UserDataMap.ScaleRatioLock]);
          scaleNode.setData(UserDataMap.ScaleRatioLock, scaleRatioLocked);
          this.viewport.transformControls.scaleRatioLocked = scaleRatioLocked;
          setScaleRatioLocked(scaleRatioLocked);
        }
        break;
      case EmitterEvent.TOOLKIT_NODE_SIZE_UPDATED:
        const sizeNode = data?.['node'];
        if (sizeNode instanceof DagNode) {
          const sizeRatioLocked = Boolean(data?.[UserDataMap.SizeRatioLock]);
          sizeNode.setData(UserDataMap.SizeRatioLock, sizeRatioLocked);
          setSizeRatioLocked(sizeRatioLocked);
        }
        break;
      case EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT:
        this.viewport.transformControls.lastSelectedObjectIDs = [];
        break;

      case EmitterEvent.CANVAS_OBJECT_FLIP_HORIZONTAL:
      case EmitterEvent.CANVAS_OBJECT_FLIP_VERTICAL:
        const isHorizontalFlip = event === EmitterEvent.CANVAS_OBJECT_FLIP_HORIZONTAL;
        selectedNodesInfo.forEach((nodeInfo) => {
          const potentialNode = toolkit.getNodeById(nodeInfo.nodeId);
          if (!potentialNode) {
            return;
          }
          if (potentialNode.getData('isLocked')) {
            return;
          }

          const isPrecomposition =
            potentialNode.nodeType === DagNodeType.LAYER &&
            (potentialNode as PrecompositionLayer).type === LayerType.PRECOMPOSITION;
          const isShape =
            potentialNode.nodeType === DagNodeType.SHAPE ||
            (potentialNode.nodeType === DagNodeType.LAYER && (potentialNode as Layer).type === LayerType.SHAPE);

          if (isPrecomposition || isShape) {
            const node = potentialNode as ShapeLayer | PrecompositionLayer;
            const scaleValue = node.scale.value;
            const newScaleValue = scaleValue.clone();
            if (isHorizontalFlip) {
              newScaleValue.setX(scaleValue.x * -1);
            } else {
              newScaleValue.setY(scaleValue.y * -1);
            }

            if (node.scale.isAnimated) {
              node.scale.setValueAtKeyFrame(newScaleValue, useCreatorStore.getState().toolkit.currentFrame);
            } else {
              node.setScale(newScaleValue);
            }
          }
        });
        emitter.emit(EmitterEvent.ANIMATED_SCALE_UPDATED);

        break;

      case EmitterEvent.CANVAS_OBJECT_ROTATE_LEFT:
        this.viewport.transformControls.rotateLeft();
        break;
      case EmitterEvent.CANVAS_OBJECT_ROTATE_RIGHT:
        this.viewport.transformControls.rotateRight();
        break;
      case EmitterEvent.CANVAS_ADJUST:
        this.viewport.adjustCanvas(data?.['size'] as SizeJSON);
        this.viewport.adjustCamera(data?.['size'] as SizeJSON);
        break;
      case EmitterEvent.CANVAS_RESIZE:
        this.viewport.resize();
        break;
      case EmitterEvent.TIMELINE_KEYFRAME_MOVE_RIGHT:
        this.moveKeyframes(KeyboardDirection.RIGHT, data?.['offsetBy'] as number);
        break;
      case EmitterEvent.TIMELINE_KEYFRAME_MOVE_LEFT:
        this.moveKeyframes(KeyboardDirection.LEFT, data?.['offsetBy'] as number);
        break;
      case EmitterEvent.UI_DELETE:
        // Can be with currentFrame or without currentFrame
        const keyframes = useCreatorStore.getState().timeline.selectedKeyframeIds;
        const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
        const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;

        if (this.viewport.gradientControls.enabled) {
          this.viewport.gradientControls.removeActiveColorStop();

          return;
        }

        let needRedraw = false;

        const pathsToDelete = selectedNodes.filter((nodeInfo) => nodeInfo.propertyPanel === PropertyPanelType.Path);

        if (keyframes.length === 0 && (this.viewport.selectedIDs.length || pathsToDelete.length > 0)) {
          needRedraw = true;
          this.viewport.transformControls.detach();
          stateHistory.beginAction();

          [...this.viewport.selectedIDs, ...pathsToDelete.map((nodeInfo) => nodeInfo.nodeId)].forEach((id) => {
            this.viewport.editor?.removeObject(id);
            removeSelectedNode(id);
            removePrecompLayerMapItem(id);
          });

          stateHistory.endAction();
        }

        const assetDeleted = selectedNodes.some(
          (eachNode) => eachNode.propertyPanel === PropertyPanelType.Precomposition,
        );
        if (assetDeleted) {
          needRedraw = true;
          const assets = toolkit.scenes[sceneIndex]?.assets || [];

          // Step 1: Remove assets with referencedLayers.length === 0
          assets.forEach((eachAsset) => {
            const precompositionAsset = eachAsset as PrecompositionAsset;

            if (precompositionAsset.referencedLayers.length === 0) {
              eachAsset.removeFromGraph();
            }
          });

          // Step 2: Recheck and remove assets with hasScene === false
          assets.forEach((eachAsset) => {
            const precompositionAsset = eachAsset as PrecompositionAsset;
            if (precompositionAsset.referencedLayers[0]?.hasScene === false) {
              eachAsset.removeFromGraph();
            }
          });
        }

        if (keyframes.length > 0) {
          needRedraw = true;
          removeKeyframes();
          emitter.emit(EmitterEvent.ANIMATED_KEYFRAME_UPDATED);
        } else {
          const appearanceNodes = selectedNodes.filter(
            (eachNode) =>
              eachNode.propertyPanel === PropertyPanelType.Fill ||
              eachNode.propertyPanel === PropertyPanelType.Stroke ||
              eachNode.propertyPanel === PropertyPanelType.Trim ||
              eachNode.propertyPanel === PropertyPanelType.GradientFill,
          );

          if (appearanceNodes.length > 0) {
            needRedraw = true;
            appearanceNodes.forEach((eachNode) => {
              removeSelectedNode(eachNode.nodeId);
            });
          }
          this.updatePrecompJSON();
        }

        toolkit.scenes[sceneIndex]?.refreshDrawOrder(toolkit.scenes[sceneIndex]?.layers || []);

        stateHistory.endAction();

        if (needRedraw) {
          this.redraw(false);
        }

        // emit event so it will fallthrough to the default case to get the latest toolkit state
        emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
        break;
      case EmitterEvent.CANVAS_DESELECT_ALL:
        this.viewport.select([]);
        break;
      case EmitterEvent.CANVAS_SELECT_ALL_LAYERS:
        this.viewport.selectAll();
        this.viewport.dragSelector?.selectionBox.clearBoundingBoxes();
        break;
      case EmitterEvent.CANVAS_SELECT_OBJECTS:
        this.viewport.select(data as unknown as string[]);
        break;
      case EmitterEvent.CANVAS_SELECT_PATH_POINTS:
        const pathPoints = data?.['pathPoints'] as unknown as number[];
        this.viewport.pathControls.selectPathPoints(pathPoints);
        break;
      case EmitterEvent.CANVAS_OBJECT_REMOVED:
        const id = data?.['id'] as string;
        if (id) this.viewport.editor?.removeObject(id);
        else if (this.viewport.selectedIDs.length) {
          this.viewport.selectedIDs.forEach((selectedID) => this.viewport.editor?.removeObject(selectedID));
        }
        break;
      case EmitterEvent.CANVAS_DISABLE_PENCONTROL:
        this.viewport.pathControls.disablePenControls();
        break;
      case EmitterEvent.CANVAS_PENCONTROL_DRAW_CONTINUE:
        this.viewport.pathControls.disablePenControls();

        setCurrentTool(useCreatorStore.getState().ui.lastSelectedPenTool);
        break;
      case EmitterEvent.CANVAS_SHOW_TRANSFORMCONTROL:
        this.viewport.transformControls.showTransformControls(true);
        break;
      case EmitterEvent.CANVAS_HIDE_TRANSFORMCONTROL:
        this.viewport.transformControls.showTransformControls(false);
        break;
      case EmitterEvent.CANVAS_TOGGLE_GRADIENT_CONTROLS:
        const enabled = data?.['enabled'] as boolean;
        const gradientShapes = data?.['gradientShapes'] as unknown as CurrentGFillShape[];

        if (enabled && gradientShapes.length === 1) {
          this.viewport.gradientControls.enable(enabled, gradientShapes);
        }

        if (!enabled) {
          this.viewport.gradientControls.enable(false);
          emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE);
        }
        this.redraw(false);

        break;

      case EmitterEvent.CANVAS_UPDATED_ACTIVE_GRADIENT_COLOR_STOP:
        emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE);

        break;

      case EmitterEvent.MAIN_SCENE_SELECTED:
        emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
        resetLayerUI();
        emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
          event: EmitterEvent.CANVAS_ADJUST,
          data: { size: json?.properties.sz },
        });
        break;

      case EmitterEvent.PLUGIN_CANVAS_UPDATE:
        this.redraw(false);
        break;

      case EmitterEvent.CANVAS_TRANSFORMCONTROL_PIVOT_UPDATED:
        const type = data?.['type'] as AlignPivotDirection;

        this.viewport.transformControls.alignPivot(type);
        break;

      case EmitterEvent.CANVAS_TOGGLE_RULERS:
        toggleRulers();
        this.viewport.overlayCanvas.isReRenderingNeeded = true;
        break;

      case EmitterEvent.CANVAS_TOGGLE_GUIDES:
        toggleGuides();
        this.viewport.overlayCanvas.isReRenderingNeeded = true;
        break;

      case EmitterEvent.CANVAS_TOGGLE_GRID:
        toggleGrid();
        this.viewport.overlayCanvas.isReRenderingNeeded = true;
        break;

      case EmitterEvent.CANVAS_TOGGLE_GRID_VISIBILITY:
        toggleGridVisibility();
        this.viewport.overlayCanvas.isReRenderingNeeded = true;
        break;

      case EmitterEvent.CANVAS_TOGGLE_SNAPPING:
        useCreatorStore.getState().canvas.toggleSnapping();
        break;

      case EmitterEvent.CANVAS_REDRAW_OVERLAY:
        this.viewport.overlayCanvas.isReRenderingNeeded = true;
        break;

      default:
        break;
    }
  }

  public getSize(json: SceneJSON): SizeJSON {
    return json.properties.sz as SizeJSON;
  }

  public moveCanvasSelection(direction: KeyboardDirection, offsetBy: number = 1): void {
    if (useCreatorStore.getState().ui.isTimelineFocused) {
      return;
    }

    const offset = new Vector3();
    switch (true) {
      case direction === KeyboardDirection.UP:
        offset.set(0, -1 * offsetBy, 0);
        break;
      case direction === KeyboardDirection.DOWN:
        offset.set(0, offsetBy, 0);
        break;
      case direction === KeyboardDirection.LEFT:
        offset.set(-1 * offsetBy, 0, 0);
        break;
      case direction === KeyboardDirection.RIGHT:
        offset.set(offsetBy, 0, 0);
        break;
      default:
        break;
    }

    const pathPointsSelected = useCreatorStore.getState().ui.selectedPathPointIndexes.length > 0;

    if (pathPointsSelected) {
      this.viewport.pathControls.nudgeSelectedPathPoints(new Vector(offset.x, offset.y));

      return;
    }

    this.viewport.transformControls.moveSelectedShape(offset);
  }

  public moveKeyframes(direction: KeyboardDirection, offsetBy: number = 1): void {
    const selectedKeyframes = useCreatorStore.getState().timeline.selectedKeyframeIds;

    if (selectedKeyframes.length === 0) return;

    stateHistory.beginAction();

    // Get keyframe objects and sort them in one iteration
    const keyframeObjects = selectedKeyframes
      .map((id) => toolkit.getKeyframeById(id as string))
      .sort((first, second) => {
        if (!first || !second) return -1;

        return first.frame - second.frame;
      });

    // Similar HF as PR #1127, to avoid setting overlapping keyframes when going to the right
    // Keyframes must be drawn in reverse when going to the right
    const keyframes = direction === KeyboardDirection.RIGHT ? keyframeObjects.reverse() : keyframeObjects;

    keyframes.forEach((frameObj) => {
      if (frameObj === null || frameObj.frameId === null) return;
      setKeyFrame(
        frameObj.frameId,
        direction === KeyboardDirection.LEFT ? frameObj.frame - offsetBy : frameObj.frame + offsetBy,
      );
    });

    // Note: Changing this to position so the canvas gets redrawn as it moves
    // Initially was using ANIMATED_KEYFRAME_UPDATED but that doesnt even seem to have a listener, yet is used in other places
    // TODO: check whether those implementations are correct.
    // This naming is unintuitive, implies only position change, but it redraws for all types of keyframe changes
    emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);

    stateHistory.endAction();
  }

  public redraw(shapeCreated: boolean, event?: EmitterEvent): void {
    this.viewport.clearScene();
    this.parser.parseToolkit();

    reAdjustPrecompCanvas(this.viewport.objectContainer.children as CObject3D[], this.viewport.canvasSize);

    const selectedIdsAfterCreated = useCreatorStore.getState().ui.selectedIdsAfterCreated;

    if (shapeCreated && selectedIdsAfterCreated && selectedIdsAfterCreated.length > 0) {
      addToSelectedNodes([...selectedIdsAfterCreated], true);
    } else if (!this.viewport.pathControls.penEnabled) {
      this.viewport.transformControls.reselectLastObject(event === EmitterEvent.PRECOMP_SCENE_SIZE_UPDATED);
    }
    this.viewport.pathControls.redrawPathControls();

    this.viewport.isReRenderingNeeded = true;

    const { fromStatic } = getDragPosFromDragToCanvas();
    if (fromStatic) resetDragToCanvas();
  }

  public refreshSelectedNodes(): void {
    // When an UNDO event is emitted, if the last event included node creation,
    // undoing the creation will remove the node, but it will still be in the selection.
    // This removes the node from the selection if it's no longer found in any scene.

    const selectedNodeIds = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);

    if (selectedNodeIds.length === 0) return;

    const newSelectedNodeIds: string[] = [];

    selectedNodeIds.forEach((id) => {
      toolkit.scenes.forEach((scene) => {
        if (scene.getNodeById(id)) {
          newSelectedNodeIds.push(id);
        }
      });
    });

    addToSelectedNodes(newSelectedNodeIds, true);
  }

  public restorePreviousCurrentframe(): void {
    const previousCurrentFrame = toolkit.getData(UserDataMap.CurrentFrame) || 0;
    const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

    if (previousCurrentFrame === currentFrame) {
      return;
    }

    const { duration, fps } = getPlaybackParameters();
    const totalFrames = duration * fps;

    useCreatorStore.getState().toolkit.setCurrentFrame(clamp(previousCurrentFrame as number, 0, totalFrames - 1));
  }

  public start(): void {
    this.startListener();
  }

  public startListener(): void {
    // We need to listen to TOOLKIT_STATE_UPDATED only so that the render is in sync with currentFrame in timeline
    emitter.on(EmitterEvent.TOOLKIT_STATE_UPDATED, (param) => {
      this.categorizeEvent(param.event, param.data);
    });
  }

  public stop(): void {
    this.enable = false;
  }

  public updateCurrentFrame(): void {
    const previousCurrentFrame = toolkit.getData(UserDataMap.CurrentFrame);
    const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

    if (previousCurrentFrame !== currentFrame) {
      toolkit.setData(UserDataMap.CurrentFrame, currentFrame);
    }
  }

  public updateEasing(controlType: EasingControlOption | EasingControl.Both, easingType: EasingTypeOption): void {
    const selectedKeyframes = useCreatorStore.getState().timeline.selectedKeyframeIds;
    const selectedKeyFramesEasing = useCreatorStore.getState().timeline.selectedKeyFramesEasing;

    if (selectedKeyframes.length > 0 && selectedKeyFramesEasing.length > 0 && selectedKeyFramesEasing[0]) {
      stateHistory.beginAction();

      const frameObjs = selectedKeyframes.map((id) => toolkit.getKeyframeById(id as string));

      frameObjs.forEach((frameObj, index) => {
        if (!frameObj) return;
        const keyFrameIndex = frameObj.parentProperty?.keyFrames.findIndex(
          (frame) => frame.frameId === frameObj.frameId,
        );

        const inTangent = (selectedKeyFramesEasing[index] as KeyframeEasing).inTangent;
        const outTangent = (selectedKeyFramesEasing[index] as KeyframeEasing).outTangent;
        let isLinear = true;

        if (controlType === EasingControl.In && keyFrameIndex === 0) return;

        if (controlType === EasingControl.In) {
          isLinear = outTangent === EasingType.Linear;
        } else if (controlType === EasingControl.Out) {
          isLinear = inTangent ? inTangent === EasingType.Linear : true;
        } else {
          isLinear = true;
        }

        if (easingType === EasingType.Linear && isLinear) {
          if (keyFrameIndex && keyFrameIndex > 0)
            frameObj.setInTangent(new Vector(LINEAR_IN_LINEAR_OUT.in.x, LINEAR_IN_LINEAR_OUT.in.y), true);
          frameObj.setOutTangent(new Vector(LINEAR_IN_LINEAR_OUT.out.x, LINEAR_IN_LINEAR_OUT.out.y), true);
        } else if (
          (easingType === EasingType.Smooth && controlType === EasingControl.In && isLinear) ||
          (easingType === EasingType.Linear && controlType === EasingControl.Out && !isLinear)
        ) {
          if (keyFrameIndex && keyFrameIndex > 0)
            frameObj.setInTangent(new Vector(SMOOTH_IN_LINEAR_OUT.in.x, SMOOTH_IN_LINEAR_OUT.in.y), true);
          frameObj.setOutTangent(new Vector(SMOOTH_IN_LINEAR_OUT.out.x, SMOOTH_IN_LINEAR_OUT.out.y), true);
        } else if (
          (easingType === EasingType.Smooth && controlType === EasingControl.Out && isLinear) ||
          (easingType === EasingType.Linear && controlType === EasingControl.In && !isLinear)
        ) {
          if (keyFrameIndex && keyFrameIndex > 0)
            frameObj.setInTangent(new Vector(LINEAR_IN_SMOOTH_OUT.in.x, LINEAR_IN_SMOOTH_OUT.in.y), true);
          frameObj.setOutTangent(new Vector(LINEAR_IN_SMOOTH_OUT.out.x, LINEAR_IN_SMOOTH_OUT.out.y), true);
        } else {
          if (keyFrameIndex && keyFrameIndex > 0)
            frameObj.setInTangent(new Vector(SMOOTH_IN_SMOOTH_OUT.in.x, SMOOTH_IN_SMOOTH_OUT.in.y), true);
          frameObj.setOutTangent(new Vector(SMOOTH_IN_SMOOTH_OUT.out.x, SMOOTH_IN_SMOOTH_OUT.out.y), true);
        }
      });

      stateHistory.endAction();

      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);

      updateSelectedKeyframeEasings();
    }
  }

  public updatePrecompJSON(): void {
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

    if (!selectedPrecompositionId) return;
    const setSelectedPrecompositionJson = useCreatorStore.getState().toolkit.setSelectedPrecompositionJson;
    const setSelectedPrecompositionId = useCreatorStore.getState().toolkit.setSelectedPrecompositionId;

    const assetNode = getNodeByIdOnly(selectedPrecompositionId);
    if (!assetNode) {
      setSelectedPrecompositionId(null);
      emitter.emit(EmitterEvent.MAIN_SCENE_SELECTED);

      return;
    }

    const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

    if (isNumber(currentFrame)) {
      const fps = assetNode.timeline.frameRate;
      const totalFrames = assetNode.timeline.duration * fps;
      stateHistory.offTheRecord(() => {
        assetNode.timeline.setCurrentFrame(clamp(currentFrame, 0, totalFrames - 1));
      });
    }

    if ((assetNode as DagNode).state) {
      setSelectedPrecompositionJson((assetNode as DagNode).state as SceneJSON);
      emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
    }
  }
}
