/**
 * Copyright 2024 Design Barn Inc.
 */

import { LayerType } from '@lottiefiles/toolkit-js';
import type { GroupBezierView } from '@lottiefiles/toolkit-js';
import { throttle, debounce } from 'lodash-es';

import type { DrawableBezierShape } from '../3d/threeFactory';
import type { BezierMesh } from '../types';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

export const updateCurrentTransformsThrottled = throttle(() => {
  useCreatorStore.getState().toolkit.updateCurrentTransforms();
}, 200);

export const handleFrameUpdateEndDebounced = debounce(() => {
  emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
    event: EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED,
  });
}, 200);

export const isCompoundBezier = (object: BezierMesh): boolean => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  if (!object.parent) {
    return false;
  }

  const parentLayerType = object.parent.layerType;
  const parentToolkitId = object.parent.toolkitId;

  if (![LayerType.GROUP, LayerType.PRECOMPOSITION].includes(parentLayerType)) {
    const parentNode = getNodeByIdOnly(parentToolkitId) as DrawableBezierShape;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const bezierParentNode = parentNode ? parentNode.toBezier() : null;

    if (bezierParentNode && bezierParentNode instanceof Array) {
      let bezierViews: GroupBezierView[] = [];

      for (const [, bezierView] of bezierParentNode.reverse().entries()) {
        if (bezierView && bezierView.bezierViews && bezierView.bezierViews.length > 0) {
          bezierViews = bezierViews.concat(bezierView.bezierViews);
        }
      }


      const unmodifiedBezierViews = bezierViews.filter(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (bezierView) => bezierView && bezierView.modifiers && bezierView.modifiers.length === 0,
      );

      return unmodifiedBezierViews.length > 1;
    }
  }

  return false;
};
