/**
 * Copyright 2021 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import axios from 'axios';
import clsx from 'clsx';
import type { FC } from 'react';
import React, { useRef, Fragment, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { Logout, AvatarEmpty, ChevronDown } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { AUTH_AJAX_ENDPOINT } from '~/config';
import { Logout as LogOut } from '~/lib/api/auth';
import { useCreatorStore } from '~/store';
import { verifySanitizeURL } from '~/utils';

interface CircularAvatarProps {
  avatarReady: boolean;
  avatarUrl: string | null;
  isActive?: boolean;
}

const ProfileNotLoaded: React.FC<void> = () => {
  return (
    <>
      <div className="px-[30px] py-[20.5px]">
        <div className="text-[12px] text-gray-300">Log in to save your work</div>
        <button
          onClick={() => (window.location.href = `${AUTH_AJAX_ENDPOINT}/login?return_to=${window.location.href}`)}
          className="mt-[12px] h-[29px] w-[96px] cursor-pointer rounded-lg bg-teal-300 px-[30.5px] py-[5.5px] text-[12px] leading-[21px] text-white hover:bg-teal-400"
        >
          Log in
        </button>
      </div>
    </>
  );
};

const CircularAvatar: React.FC<CircularAvatarProps> = ({ avatarReady, avatarUrl, isActive }) => {
  return (
    <div className="relative shrink-0">
      {(!avatarUrl || !avatarReady) && <AvatarEmpty className="h-4 w-4 rounded-full shadow-sm" />}
      {avatarUrl && avatarReady && (
        <img
          className={clsx('h-4 w-4 rounded-full shadow-sm', {
            [`border border-solid ${isActive ? 'border-teal-200' : 'border-transparent'}`]:
              typeof isActive === 'boolean',
          })}
          src={`${avatarUrl}`}
          alt="profile avatar"
        />
      )}
    </div>
  );
};

const DropDownList = [
  {
    title: 'Log Out',
    icon: <Logout />,
  },
];

const iconStyle = (active: boolean): string =>
  `ml-1 mr-1 h-4 w-4 inline ${active ? 'stroke-white' : 'stroke-gray-400'}`;

interface EmailWrapperProps {
  children: JSX.Element;
  label: string;
  withTooltip: boolean;
}

const EmailWrapper: FC<EmailWrapperProps> = ({ children, label, withTooltip }) =>
  withTooltip ? (
    <Tooltip content={label} placement="bottom">
      {children}
    </Tooltip>
  ) : (
    children
  );

interface ProfileMenuItemProps {
  avatarReady: boolean;
  avatarUrl: string | null;
}

const ProfileMenuItem: FC<ProfileMenuItemProps> = ({ avatarReady, avatarUrl }) => {
  const user = useCreatorStore((state) => state.user.info);
  const ref = useRef<HTMLParagraphElement>(null);
  const [showTooltipForEmail, setShowTooltipForEmail] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setShowTooltipForEmail(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, []);

  return (
    <Menu.Item key={'profile'}>
      <div className="flex gap-1 p-2">
        <div className="shrink-0 p-1">
          <CircularAvatar avatarUrl={avatarUrl} avatarReady={avatarReady} />
        </div>
        <div className="min-w-0 text-xs">
          <p className="truncate font-bold text-white">{user.name}</p>
          <EmailWrapper withTooltip={showTooltipForEmail} label={user.email || ''}>
            <p ref={ref} className="truncate text-[#A1ADB7]">
              {user.email}
            </p>
          </EmailWrapper>
        </div>
      </div>
    </Menu.Item>
  );
};

export const Profile: React.FC = () => {
  const [avatarReady, setAvatarReady] = useState(false);
  const [user, setUser, isAuthorized] = useCreatorStore(
    (state) => [state.user.info, state.user.setInfo, state.user.isAuthorized],
    shallow,
  );

  const avatarUrl = user.avatarUrl as string | null;

  useEffect(() => {
    const checkImage = async (): Promise<void> => {
      try {
        await axios.get(verifySanitizeURL(avatarUrl as string));
      } catch (ex) {
        setUser({ avatarUrl: null });
      }
      setAvatarReady(true);
    };

    if (avatarUrl) {
      checkImage();
    }
  }, [avatarUrl, setUser]);

  const handleDropdownClick = async (title: string): Promise<void> => {
    if (title === 'Log Out') {
      const response = await LogOut();

      if (response?.status === 204) {
        window.location.href = `${AUTH_AJAX_ENDPOINT}/login?return_to=${window.location.href}`;
      }
    }
  };

  if (!isAuthorized) {
    return null;
  }

  return (
    <>
      <Menu as="div" className="relative flex items-center">
        {({ open }) => (
          <>
            <Tooltip content="Profile">
              <Menu.Button className="group ml-1 mr-2 flex h-[26px] cursor-pointer items-center justify-center rounded pl-2 pr-0.5 hover:bg-gray-700">
                <CircularAvatar avatarUrl={avatarUrl} avatarReady={avatarReady} isActive={open} />
                <ChevronDown className="ml-1 h-3 text-gray-500 group-hover:text-white" />
              </Menu.Button>
            </Tooltip>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 top-5 z-dropdown mt-2 w-[180px] origin-top-right scale-100 divide-y divide-black rounded-md bg-[#20272C] opacity-100 shadow-dropdown ring-black focus:outline-none">
                <div className="text-center">{!avatarReady && ProfileNotLoaded()}</div>

                {avatarReady && (
                  <>
                    <ProfileMenuItem avatarUrl={avatarUrl} avatarReady={avatarReady} />
                    <div className="p-2 pt-1">
                      {DropDownList.map((dropdown) => {
                        return (
                          <Menu.Item key={dropdown.title}>
                            {({ active }) => (
                              <button
                                className="mt-1 h-6 w-full items-center rounded-md border-gray-700 p-0.5 text-left text-xs leading-normal text-white hover:border-gray-600 hover:bg-gray-600"
                                onClick={async () => handleDropdownClick(dropdown.title)}
                              >
                                {React.cloneElement(dropdown.icon, {
                                  className: iconStyle(active),
                                })}
                                <span className="inline">{dropdown.title}</span>
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};
