/**
 * Copyright 2023 Design Barn Inc.
 */

import type { PrecompositionAsset, PrecompositionLayer, Scene, SizeJSON } from '@lottiefiles/toolkit-js';
import { Vector } from '@lottiefiles/toolkit-js';

// eslint-disable-next-line no-restricted-imports
import { updatePrecompDragToCanvasPosition } from '~/features/canvas/components/DragToConvasContainer';
import { emitter, EmitterEvent } from '~/lib/emitter';
import {
  toolkit,
  renameNestedScenes,
  stateHistory,
  getSceneSize,
  getActiveScene,
  dotLottiePlugin,
  lottiePlugin,
} from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant } from '~/store/constant';
import type { AnimationLoader } from '~/store/uiSlice';
import { AnimationLoaderStatus } from '~/store/uiSlice';

export const removeAnimationLoader = (status: AnimationLoaderStatus): void => {
  const setAnimationLoader = useCreatorStore.getState().ui.setAnimationLoader;

  setAnimationLoader({
    status,
  } as AnimationLoader);
};

export const resizePrecompLayer = (scene: Scene | PrecompositionAsset, precompLayer: PrecompositionLayer): void => {
  let precompWidth = (precompLayer.state.properties.sz as SizeJSON).w;
  let precompHeight = (precompLayer.state.properties.sz as SizeJSON).h;

  const size = getSceneSize(scene);
  const width = size.width;
  const height = size.height;

  let widthScale = Math.ceil((width * 100) / precompWidth);
  let heightScale = Math.ceil((height * 100) / precompHeight);

  if (precompWidth > width || precompHeight > height) {
    heightScale = Math.ceil((precompLayer.transform.scale.valueAtKeyFrame(0).y * widthScale) / 100);
    widthScale = Math.ceil((precompLayer.transform.scale.valueAtKeyFrame(0).x * heightScale) / 100);

    const scale = Math.min(widthScale, heightScale);

    precompLayer.setScale(new Vector(scale, scale));

    precompWidth = (precompWidth * widthScale) / 100;
    precompHeight = (precompHeight * heightScale) / 100;
  }

  precompLayer.setPosition(new Vector(width / 2 - precompWidth / 2, height / 2 - precompHeight / 2));
};

const isEmptyScene = (scene: Scene): boolean => {
  return scene.state.layers.length === 1;
};

export const adjustTimelineOnImport = (precomLayer: PrecompositionLayer, useSceneFPS: boolean): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  if (!scene) return;
  const precomTimeline = precomLayer.precomposition?.timeline;

  if (!precomTimeline) return;

  // When importing a Lottie file into an empty project, the project should adjust itself to the FPS and the length of that Lottie
  if (isEmptyScene(scene)) {
    const { frameRate } = precomTimeline;
    const { endTime } = precomLayer;

    scene.timeline.setFrameRate(frameRate);
    scene.timeline.setDuration(endTime);
  } else if (useSceneFPS) {
    precomLayer.precomposition.timeline.setFrameRate(scene.timeline.frameRate);
  } else {
    const { frameRate } = precomTimeline;

    scene.timeline.setFrameRate(frameRate);
  }
  emitter.emit(EmitterEvent.TIMELINE_FPS_UPDATED);
};

export const checkTimelineOnImport = (precomLayer: PrecompositionLayer): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  if (!scene) return;
  const precomTimeline = precomLayer.precomposition?.timeline;

  if (!precomTimeline) return;
  const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;

  if (isEmptyScene(scene)) {
    adjustTimelineOnImport(precomLayer, false);
  } else {
    const sceneFPS = scene.timeline.frameRate;
    const precomFPS = precomTimeline.frameRate;

    if (sceneFPS !== precomFPS) {
      setGlobalModal(GlobalModalConstant.FrameOnImport, {
        precomLayer,
      });
    }
  }
};

export const importToCanvas = async (newDrawOrder?: number): Promise<void> => {
  const scene: Scene | PrecompositionAsset | null = getActiveScene(toolkit);

  if (!scene) return;

  stateHistory.beginAction();
  const animationLoader = useCreatorStore.getState().ui.animationLoader;

  const setSelectedIdsAfterCreated = useCreatorStore.getState().ui.setSelectedIdsAfterCreated;
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const mainScene = toolkit.scenes[sceneIndex] as Scene;

  const lottieUrl = useCreatorStore.getState().ui.animationLoader.url;

  if (!lottieUrl) return;

  const fileType = lottieUrl.split('.').pop();
  let importedPrecomLayer;

  if (fileType === 'lottie') {
    const options = {
      dotlottie: lottieUrl,
      enableNodeIds: true,
      standardizeTimeline: true,
      recalculateStartEnd: true,
    };

    importedPrecomLayer = await mainScene.importAsPrecompositionLayer(dotLottiePlugin.id, options);
  } else {
    const options = {
      animation: lottieUrl,
      enableNodeIds: true,
      standardizeTimeline: true,
      recalculateStartEnd: true,
    };

    importedPrecomLayer = await mainScene.importAsPrecompositionLayer(lottiePlugin.id, options);
  }

  let precomLayer = importedPrecomLayer;

  checkTimelineOnImport(precomLayer);

  if (mainScene !== scene) {
    precomLayer = importedPrecomLayer.clone(scene) as PrecompositionLayer;
    importedPrecomLayer.removeFromGraph();
  }

  const precompLayerDrawOrder = precomLayer.drawOrder;

  resizePrecompLayer(scene, precomLayer);

  // Use the animation title as the precomp layer's name
  renameNestedScenes();

  let precompDrawOrder = 0;

  if (animationLoader.status === AnimationLoaderStatus.Loading) {
    removeAnimationLoader(AnimationLoaderStatus.Loaded);

    precomLayer.setName(animationLoader.name as string);
  }

  if (newDrawOrder) {
    precompDrawOrder = Math.max(newDrawOrder - 1, 0);
    precomLayer.moveForward(
      precompLayerDrawOrder - (precompLayerDrawOrder === newDrawOrder ? precompDrawOrder : newDrawOrder),
    );
  } else {
    precomLayer.setDrawOrder(precompDrawOrder);
  }

  setSelectedIdsAfterCreated([precomLayer.nodeId]);

  emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event: EmitterEvent.LOADED_ANIMATION });

  // Update drag position after emitted, to prevent position override
  const fromDragged = updatePrecompDragToCanvasPosition(precomLayer);

  if (fromDragged) {
    // re-update precompLayer's canvas position again
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event: EmitterEvent.LOADED_ANIMATION });
  }

  stateHistory.endAction();
};
