/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable tailwindcss/no-custom-classname */

import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import type { MenuDropdownProp } from './CreatorMenu';
import { MenuDivider } from './MenuDivider';

import { fireEvent, type EventStorePayload } from '~/utils';

interface SubMenuProp {
  onClose?: () => void;
  overflow?: boolean;
  subItems: MenuDropdownProp[];
}

export const SubMenu: React.FC<SubMenuProp> = ({ onClose, overflow = false, subItems }: SubMenuProp) => {
  const handleClick = (callback: () => void, eventParams?: EventStorePayload): void => {
    if (eventParams) {
      fireEvent(eventParams);
    }
    if (onClose) onClose();

    callback();
  };

  const position = overflow ? 'left-[40%]' : 'right-[-103%]';

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu as="div" className={`absolute ${position} top-[-6px] inline-block w-full`}>
        {() => (
          <>
            <div className="absolute z-dropdown mt-1 max-h-[480px] w-60 cursor-pointer overflow-auto rounded-lg border border-gray-600 bg-gray-800 py-2 shadow-dropdown focus:outline-none">
              {subItems.map((child: MenuDropdownProp, index: number) => (
                <React.Fragment key={child.type}>
                  {!child.isHidden && (
                    <div className="mx-2 mb-1 last:mb-0">
                      <Menu.Item>
                        <button
                          className={`timeline-keyframe-control group relative flex w-full ${index === 0 && 'rounded-t'} ${
                            index === subItems.length - 1 && 'rounded-b'
                          } ${child.isCustomLayout ? '' : 'h-6'} items-center rounded px-2 text-xs ${child.disabled ? 'text-gray-500' : 'text-white hover:bg-gray-600'} `}
                          onClick={() => handleClick(child.callback, child.trackParams)}
                          disabled={Boolean(child.disabled)}
                        >
                          {child.label}
                          <span
                            className={`absolute right-[7px] ${child.disabled ? 'text-gray-500' : 'text-gray-300'}`}
                          >
                            {child.shortcut}
                          </span>
                        </button>
                      </Menu.Item>
                    </div>
                  )}
                  {child.isDivider && !child.isHidden && index !== subItems.length - 1 && <MenuDivider />}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </Menu>
    </Transition>
  );
};
