/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useCallback, useContext, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useDropzone } from 'react-dropzone';

import UploadFile from '../assets/UploadFile';

import { uploadDotLottie, uploadJSON, uploadSVG } from './helper';
import { Insert } from './Insert';
import { UploadContext } from './UploadContext';
import { InvalidFileFormatError, UnknownError } from './UploadError';

import { Info } from '~/assets/icons';
import { AssetSource, EventType, FileType } from '~/data/eventStore';
import { SUPPORTED_FEATURES_URL } from '~/data/urls';
// eslint-disable-next-line no-restricted-imports
import { executeUpload } from '~/features/upload/components/middleware';
import { fireEvent, type EventStorePayload } from '~/utils';

interface Props {}

export const UploadComputer: React.FC<Props> = () => {
  const { onClose, setError, setLoading } = useContext(UploadContext);

  const [isDragEnter, setDragEnter] = useState(false);

  const handleOnDrop = useCallback(
    (acceptedFiles: File[]) => {
      setError(null);
      setDragEnter(false);
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0] as File;
        const fileType = file.name.split('.').pop();
        const reader = new FileReader();
        let assetType = FileType.JSON;

        setLoading(true);
        reader.onload = async (event: ProgressEvent<FileReader>) => {
          if (event.target?.result) {
            let lottieRecord: null | ArrayBuffer | string = null;

            try {
              // Get Lottie record for dotlottie, svg, json

              let json: unknown = null;

              if (fileType === 'lottie') {
                lottieRecord = event.target.result;
                assetType = FileType.DotLottie;
              } else if (fileType === 'svg') {
                lottieRecord = event.target.result as string;
                assetType = FileType.SVG;
              } else {
                json = JSON.parse(event.target.result as string);
              }

              if (fileType === 'lottie') {
                await executeUpload({
                  revertLabel: 'Upload another',
                  openLabel: 'Open anyway',
                  type: 'dotLottie',
                  data: lottieRecord,
                  execute: async () => uploadDotLottie(lottieRecord),
                  revert: () => {
                    const container = document.getElementById('InsertContainer');

                    if (container) {
                      const root = createRoot(container);

                      root.render(<Insert defaultIsOpen={true} />);
                    }
                  },
                });
              } else if (fileType === 'svg') {
                await uploadSVG(lottieRecord as string);
              } else {
                await executeUpload({
                  revertLabel: 'Upload another',
                  openLabel: 'Open anyway',
                  data: json,
                  execute: async () => uploadJSON(json),
                  revert: () => {
                    const container = document.getElementById('InsertContainer');

                    if (container) {
                      const root = createRoot(container);

                      root.render(<Insert defaultIsOpen={true} />);
                    }
                  },
                });
              }

              // Send analytics event
              const eventParams: EventStorePayload = {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                event_type: EventType.UploadedAsset,
                parameters: {
                  source: AssetSource.Local,
                  'file-type': assetType,
                },
              };

              fireEvent(eventParams);

              onClose();
            } catch (err) {
              if (err instanceof Error) {
                // TODO: set better, more specific error messages from upload error result
                // eslint-disable-next-line no-console
                console.error(err);
                setLoading(false);
                setError(new InvalidFileFormatError(err.message));
              }
            }
          }
        };

        reader.onabort = (err) => {
          if (err instanceof Error) {
            setLoading(false);
            setError(new UnknownError(err.message));
          }
        };

        reader.onerror = (err) => {
          if (err instanceof Error) {
            setLoading(false);
            setError(new UnknownError(err.message));
          }
        };

        if (file.type === 'application/json') {
          reader.readAsText(file);
        } else if (fileType === 'svg') {
          reader.readAsText(file);
        } else if (fileType === 'lottie') {
          reader.readAsArrayBuffer(file);
        } else {
          setLoading(false);
          setError(new InvalidFileFormatError(file.type));
        }
      }
    },
    [onClose, setError, setLoading],
  );

  const onDragEnter = useCallback(() => {
    setDragEnter(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDragEnter(false);
  }, []);

  const { getInputProps, getRootProps } = useDropzone({ onDrop: handleOnDrop, onDragEnter, onDragLeave });

  return (
    <div className="p-3">
      <div
        {...getRootProps({
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className: clsx(
            'dropzone mb-4 flex h-full w-full cursor-pointer flex-col items-center rounded border-2 border-dashed border-gray-600',
            isDragEnter ? 'border-teal-200 bg-gray-700' : '',
          ),
        })}
        data-testid="upload-panel"
      >
        <input data-testid="upload-file-modal" {...getInputProps()} />
        <div className="flex h-full w-full flex-col items-center gap-1 p-12">
          <UploadFile className="h-16 w-20" />
          <span className="text-sm font-bold leading-[18px] text-white">{'Drag & drop asset to upload'}</span>
          <span className="text-caption">or</span>
          <button className="rounded-lg bg-teal-300 px-3 py-2 text-sm font-bold leading-[21px] text-white hover:bg-teal-400">
            Browse
          </button>
        </div>
        <span className="mb-3 text-caption">You can upload JSON, dotlottie or SVG asset (up to 20MB)</span>
      </div>
      <div className="banner-info">
        <Info className="h-8 w-8" />
        <p>
          Animations with features not supported by Lottie Creator might not render correctly. View the full list of
          features that Lottie Creator supports{' '}
          <a className="underline" href={SUPPORTED_FEATURES_URL} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
      </div>
    </div>
  );
};
