/**
 * Copyright 2023 Design Barn Inc.
 */

import { clamp } from 'lodash-es';
import {
  Object3D,
  Float32BufferAttribute,
  BufferGeometry,
  Color,
  DoubleSide,
  ExtrudeGeometry,
  Line,
  LineBasicMaterial,
  Mesh,
  MeshBasicMaterial,
  Path,
  PlaneGeometry,
  Shape,
  SphereGeometry,
  Vector3,
  Vector2,
} from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';

import type { PathPoint } from '../shapes/path';
import { drawBezier } from '../shapes/path';

import { BLACK_COLOR, BLUE_COLOR, WHITE_COLOR } from '~/features/canvas';
import { extrudeSettings } from '~/lib/threejs/PathControls';

export const createBezierPoint = (): Mesh => {
  const geometry = new PlaneGeometry(8, 8);
  const material = new MeshBasicMaterial({ color: WHITE_COLOR, side: DoubleSide });

  const bezierPoint = new Mesh(geometry, material);

  const line = new MeshLine();

  line.setGeometry(
    new ExtrudeGeometry(new Shape().moveTo(0, 0).lineTo(0, 8).lineTo(8, 8).lineTo(8, 0).lineTo(0, 0), extrudeSettings),
  );

  const material2 = new LineBasicMaterial({
    color: new Color(BLUE_COLOR),
  });

  const threeMesh = new Line(line.geometry, material2);

  threeMesh.position.setX(-4);
  threeMesh.position.setY(-4);
  bezierPoint.add(threeMesh);

  return bezierPoint;
};

export const createControlPoint = (): Mesh => {
  const lineGeometry = new BufferGeometry().setFromPoints(
    new Path().absarc(0, 0, 5, 0, Math.PI * 2, true).getSpacedPoints(50),
  );
  const mesh = new LineBasicMaterial({ color: BLUE_COLOR });
  const line = new Line(lineGeometry, mesh);

  const geometry = new SphereGeometry(4, 32, 32);
  const material = new MeshBasicMaterial({ color: WHITE_COLOR });

  const controlPoint = new Mesh(geometry, material);

  controlPoint.add(line);

  return controlPoint;
};

export const createBezierLine = (start: Vector3, end: Vector3): Line => {
  const material = new LineBasicMaterial({
    color: BLUE_COLOR,
  });

  const points = [start, end];

  const geometry = new BufferGeometry().setFromPoints(points);

  const line = new Line(geometry, material);

  line.renderOrder = 1;

  return line;
};

export const updateBezierLine = (line: Line, start: Vector3, end: Vector3): void => {
  const newVertices = [start.x, start.y, start.z, end.x, end.y, end.z];

  line.geometry.setAttribute('position', new Float32BufferAttribute(newVertices, 3));
};

const getPathDivisions = (pathPoints: PathPoint[]): number => {
  const start = new Vector2(pathPoints[0]?.vertex.x, pathPoints[0]?.vertex.y);
  const end = new Vector2(pathPoints[pathPoints.length - 1]?.vertex.x, pathPoints[pathPoints.length - 1]?.vertex.y);
  const pathLength = start.distanceTo(end);

  return clamp(Math.floor(pathLength / 10), 15, 50);
};

export const createPathLine = (
  pathPoints: PathPoint[],
  scale: number,
  color = BLACK_COLOR,
  lineWidth = 5,
): Object3D => {
  const pathLine = new Object3D();
  const shape = drawBezier(pathPoints, false);

  const levelOfDetail = getPathDivisions(pathPoints);
  const extractedPoints = shape.extractPoints(levelOfDetail).shape;

  const points = extractedPoints.map((point) => new Vector3(point.x, point.y, -1));
  const geometry = new BufferGeometry().setFromPoints(points);

  const meshPathLine = new MeshLine();

  meshPathLine.setGeometry(geometry);
  const meshPath = new Mesh(
    meshPathLine.geometry,
    new MeshLineMaterial({
      color,
      lineWidth: lineWidth * scale,
    }),
  );

  pathLine.add(meshPath);

  return pathLine;
};
