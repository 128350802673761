/**
 * Copyright 2022 Design Barn Inc.
 */

// import type { Event } from '@lottiefiles/toolkit-js';
import type React from 'react';
import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';

import { SceneNameType } from '~/data/constant';
import { ViewportConfig } from '~/features/canvas';
import { toolkit, createScene, getToolkitState } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const ToolkitListener: React.FC = () => {
  const handleError = useErrorHandler();

  const setJSON = useCreatorStore((state) => state.toolkit.setJSON);
  const setSceneIndex = useCreatorStore((state) => state.toolkit.setSceneIndex);

  // create Scene if not exist
  useEffect(() => {
    if (toolkit.scenes.length === 0) {
      const option = {
        name: SceneNameType.MainScene,
        width: ViewportConfig.Background.w,
        height: ViewportConfig.Background.h,
        frameRate: 30,
        startFrame: 0,
        endFrame: 150,
      };

      createScene(toolkit, option);

      // set scene index to 0
      setSceneIndex(0);

      // set initial json
      const json = structuredClone(getToolkitState(toolkit));

      try {
        setJSON(json);
      } catch (error) {
        handleError(error);
      }
    }
  }, [setJSON, setSceneIndex, handleError]);

  // TODO: subscribe to toolkit listener?
  return null;
};
