/**
 * Copyright 2023 Design Barn Inc.
 */

import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import type { Transform } from '@dnd-kit/utilities';
import { CSS } from '@dnd-kit/utilities';
import type { ShapeJSON } from '@lottiefiles/toolkit-js';
import type { CSSProperties } from 'react';
import React, { useCallback } from 'react';

import { resetTimelineLayerPopup } from '../../helper';

import type { OptionalShapeLayer } from './SortableTreeItemLayer';

interface SortableTreeItemProps {
  TreeItemComponent: unknown;
  attributes: DraggableAttributes;
  disableSorting: boolean;
  id: string;
  isDragging: boolean;
  isSorting: boolean;
  lastLayer?: unknown;
  layer: OptionalShapeLayer | ShapeJSON;
  listeners: SyntheticListenerMap | undefined;
  setDraggableNodeRef: (element: HTMLElement | null) => void;
  setDroppableNodeRef: (element: HTMLElement | null) => void;
  transform: Transform | null;
  transition?: string | null;
}

const SortableTreeItemNotMemoized = ({
  TreeItemComponent,
  attributes,
  disableSorting,
  id,
  isDragging,
  isSorting,
  lastLayer,
  listeners,
  setDraggableNodeRef,
  setDroppableNodeRef,
  transform,
  transition,
  ...props
}: SortableTreeItemProps): unknown => {
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? 0,
  };

  const onClick = useCallback(() => {
    resetTimelineLayerPopup();
  }, []);

  return (
    <>
      <TreeItemComponent
        {...props}
        id={id}
        ref={setDraggableNodeRef}
        wrapperRef={setDroppableNodeRef}
        style={style}
        ghost={isDragging}
        disableInteraction={isSorting}
        handleProps={{
          ...attributes,
          ...listeners,
        }}
        onClick={onClick}
        disableSorting={disableSorting}
      />
    </>
  );
};

export const SortableTreeItem = React.memo(SortableTreeItemNotMemoized) as typeof SortableTreeItemNotMemoized;
