/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene, Layer, DagNode, GroupShape } from '@lottiefiles/toolkit-js';

import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export function getCurrentScene(): Scene {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const currentScene = toolkit.scenes[sceneIndex] as Scene;

  return currentScene;
}

export function getNodeById(nodeId: string): DagNode | GroupShape | Layer {
  const node = useCreatorStore.getState().toolkit.getNodeByIdOnly(nodeId) as DagNode;

  return node;
}
