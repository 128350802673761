/**
 * Copyright 2024 Design Barn Inc.
 */

import { GradientFillType } from '@lottiefiles/lottie-js';
import { merge } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import '@/ColorPicker/colorpicker.css';
import '@/ColorPicker/index.css';

import { ColorTypeTab } from '../ColorPickerTab';
import type { GradientData } from '../Gradient';
import Gradient from '../Gradient';
import { Solid } from '../Solid';

import { ColorMode, ColorOptions } from '@/ColorPicker/components/ColorPicker';
import { hexToRgb } from '@/ColorPicker/helpers';
import type { WindowProps } from '~/components/Elements/Window';
import { WindowCloseButton, WindowHeaderSlot, Window } from '~/components/Elements/Window';

export interface RGBA {
  alpha?: number;
  blue: number;
  green: number;
  red: number;
}
export interface ColorOptional {
  alpha?: number;
  blue?: number;
  green?: number;
  hue?: number;
  red?: number;
  saturation?: number;
  value?: number;
}

export enum MouseAction {
  onChange = 'onChange',
  onEndChange = 'onEndChange',
  onStartChange = 'onStartChange',
}

const PresetColors = ['#6666ff', '#55afe7', '#24a8af', '#ff6699', '#e7b549', '#de524c', '#767fad', '#ffffff'];

interface Props {
  color: RGBA;
  enableColorModeChange: boolean;
  gradient: GradientData | null;
  onChange?: (c: RGBA) => void;
  onChangeGradient?: (g: GradientData) => void;
  onClose: () => void;
  onColorModeChange?: (prevColorMode: ColorMode, newColorMode: ColorMode) => void;
  onEndChange?: (c: RGBA) => void;
  onEndGradientChange?: (g: GradientData) => void;
  onStartChange?: (c: RGBA) => void;
  onStartGradientChange?: (g: GradientData) => void;
  showDocumentColors?: boolean;
  showEyeDropper?: boolean;
  showOpacityControl?: boolean;
  windowProps?: Omit<WindowProps, 'children' | 'title' | 'onClose'>;
}

export const ColorPickerPopover: React.FC<Props> = ({
  color,
  enableColorModeChange,
  gradient,
  onChange,
  onChangeGradient,
  onClose,
  onColorModeChange,
  onEndChange,
  onEndGradientChange,
  onStartChange,
  onStartGradientChange,
  showDocumentColors = true,
  showEyeDropper = true,
  showOpacityControl = true,
  windowProps = {},
  ...restProps
}) => {
  const [colorMode, setColorMode] = useState<ColorMode>(ColorMode.Solid);

  useEffect(() => {
    if (gradient?.type === GradientFillType.LINEAR) {
      setColorMode(ColorMode.Linear);
    } else if (gradient?.type === GradientFillType.RADIAL) setColorMode(ColorMode.Radial);
    else setColorMode(ColorMode.Solid);
  }, [gradient?.type]);

  const onChooseColor = (item: string): void => {
    const { blue: b, green: g, red: r } = hexToRgb(item);

    if (onChange) onChange({ red: r, blue: b, green: g });
  };

  const handleColorModeChange = useCallback(
    (newColorMode: ColorMode) => {
      if (colorMode === newColorMode) return;
      setColorMode(newColorMode);
      if (onColorModeChange) onColorModeChange(colorMode, newColorMode);
    },
    [colorMode, onColorModeChange],
  );

  const isGradient = colorMode === ColorMode.Linear || colorMode === ColorMode.Radial;

  const availableColorModes = useMemo(() => {
    if (enableColorModeChange) return ColorOptions;

    return ColorOptions.filter((colorOption) => colorOption.type === ColorMode.Solid);
  }, [enableColorModeChange]);

  return (
    <Window {...merge({}, windowProps, restProps)}>
      <WindowHeaderSlot>
        <div id="color-picker-header" className="flex cursor-move items-center justify-between py-2 pl-4 pr-3">
          <div className="flex gap-4 text-xs font-bold text-white">
            {availableColorModes.map((colorOption) => (
              <ColorTypeTab
                key={colorOption.type}
                colorMode={colorOption.type}
                onColorModeChange={handleColorModeChange}
                isSelected={colorMode === colorOption.type}
                label={enableColorModeChange ? colorOption.label : 'Color picker'}
              />
            ))}
          </div>
          <WindowCloseButton onClick={onClose} />
        </div>
      </WindowHeaderSlot>
      <div className="ui-color-picker">
        <div className="w-[248px] px-4 pb-5 pt-3">
          {isGradient && gradient && (
            <Gradient
              points={[...gradient.points]}
              type={gradient.type}
              degree={gradient.degree}
              onChange={onChangeGradient}
              onStartChange={onStartGradientChange}
              onEndChange={onEndGradientChange}
            />
          )}
          {colorMode === ColorMode.Solid && (
            <>
              {showDocumentColors && (
                <div className="colorpicker">
                  <div className="mb-[10px] mt-[5px]">
                    <div className="default-color-panel">
                      {PresetColors.map((col) => (
                        <div
                          key={col}
                          className={`default-color-panel_item h-5 w-5 rounded-[10px]`}
                          style={{
                            backgroundColor: col,
                            border: `1px solid rgba(0, 0, 0, 0.2)`,
                          }}
                          onClick={() => onChooseColor(col)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <Solid
                red={color.red}
                green={color.green}
                blue={color.blue}
                alpha={color.alpha ?? 1}
                onChange={onChange}
                onStartChange={onStartChange}
                onEndChange={onEndChange}
                showDocumentColors={showDocumentColors}
                showOpacityControl={showOpacityControl}
                showEyeDropper={showEyeDropper}
              />
            </>
          )}
        </div>
      </div>
    </Window>
  );
};
