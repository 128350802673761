/**
 * Copyright 2024 Design Barn Inc.
 */

import type { OffsetOptions, Placement, SideObject } from '@floating-ui/react-dom-interactions';
import { offset, arrow, useFloating } from '@floating-ui/react-dom-interactions';
import type { FC, ReactNode } from 'react';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import { Close } from '~/assets/icons';

interface Props {
  children: ({ ref }: { ref: ReturnType<typeof useFloating>['reference'] }) => ReactNode;
  content: ReactNode;
  offsetOptions?: OffsetOptions;
  onClose: () => void;
  open: boolean;
  placement?: Placement;
}

const arrowSize = 12;
const halfTooltipSize = arrowSize / 2;

const arrowStyles: Record<Placement, Partial<SideObject>> = {
  'right-start': {
    left: -halfTooltipSize,
  },
  'right-end': {
    left: -halfTooltipSize,
  },
  'left-start': {
    right: -halfTooltipSize,
  },
  'left-end': {
    right: -halfTooltipSize,
  },
  'top-start': {
    bottom: -halfTooltipSize,
  },
  'top-end': {
    bottom: -halfTooltipSize,
  },
  'bottom-start': {
    top: -halfTooltipSize,
  },
  'bottom-end': {
    top: -halfTooltipSize,
  },
  left: {
    right: -halfTooltipSize,
  },
  right: {
    left: -halfTooltipSize,
  },
  top: {
    bottom: -halfTooltipSize,
  },
  bottom: {
    top: -halfTooltipSize,
  },
};

export const PopoverTooltip: FC<Props> = ({
  children,
  content,
  offsetOptions = 16,
  onClose,
  open,
  placement = 'right',
}) => {
  const arrowRef = useRef(null);

  const { floating, middlewareData, reference, x, y } = useFloating({
    open,
    middleware: [offset(offsetOptions), arrow({ element: arrowRef })],
    placement,
  });

  return (
    <>
      {children({ ref: reference })}
      {open &&
        createPortal(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <div ref={floating} className="fixed z-10" style={{ top: y!, left: x! }}>
            <div
              ref={arrowRef}
              className="absolute rotate-45 bg-[#006262]"
              style={{
                width: arrowSize,
                height: arrowSize,
                top: middlewareData.arrow?.y,
                left: middlewareData.arrow?.x,
                ...arrowStyles[placement],
              }}
            />
            <div className="flex gap-2 rounded bg-[#006262] py-1 px-2">
              {content}
              <Close className="m-1 h-4 w-4 cursor-pointer fill-current text-white" onClick={onClose} />
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};
