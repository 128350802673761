/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

export interface SaveContextType {
  error: boolean;
  fileName: string;
  isOpen: boolean;
  onClose: () => void;
  options?: Record<string, string | boolean>;
  rowMap: Record<string, any>;
  selected: Record<string, string | number | boolean>;
  setError: (error: boolean) => void;
  setFileName: (name: string) => void;
  setRowMap: (data: Record<string, any>) => void;
  setSelected: (input: Record<string, string | number | boolean>) => void;
  setUIMap: (data: Record<string, any>) => void;
  uiMap: Record<string, any>;
}

const save: SaveContextType = {
  isOpen: false,
  error: false,
  onClose: () => {},
  setError: (_) => {},
  selected: {},
  setSelected: () => {},
  uiMap: {},
  setUIMap: () => {},
  rowMap: {},
  setRowMap: () => {},
  fileName: '',
  setFileName: () => {},
};

export const SaveContext = React.createContext<SaveContextType>(save);
