/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { MENU_HEIGHT, MENU_WIDTH, MENU_WIDTH_OFFSET, MENU_HEIGHT_OFFSET } from '../../../../menu/constant';

import { SingleKeyFrameMenu, MultiKeyframeMenu } from '~/features/menu';
import { useCreatorStore } from '~/store';

export const TimelineKeyframeMenuContainer: React.FC = () => {
  const [
    selectedKeyframes,
    setSelectedKeyframes,
    timelineContext,
    keyframeMenuOpened,
    setTimelineContext,
    selectedNodeIds,
    removeSelectedNodes,
  ] = useCreatorStore(
    (state) => [
      state.timeline.selectedKeyframeIds,
      state.timeline.setSelectedKeyframeIds,
      state.timeline.timelineContext,
      state.timeline.timelineContext.keyframeMenuOpened || false,
      state.timeline.setTimelineContext,
      state.ui.selectedNodesInfo.map((nodeInfo) => nodeInfo.nodeId),
      state.ui.removeSelectedNodes,
    ],
    shallow,
  );

  const mousePos = timelineContext.mousePos;

  const onCloseMenu = useCallback(() => {
    if (keyframeMenuOpened) {
      setTimelineContext({
        keyframeMenuOpened: false,
        mousePos: { x: 0, y: 0 },
      });
    }
  }, [keyframeMenuOpened, setTimelineContext]);

  // Left as a global listener in case we expliclity need a click in a
  // different area to do a de-selection If a keyframe/node is selected and a
  // user clicks away, deselect all the selected keyframes/nodes
  useEffect(() => {
    const listener = (ev: MouseEvent): void => {
      if (selectedKeyframes.length === 0 && selectedNodeIds.length === 0) {
        return;
      }

      if (
        (ev.target as HTMLElement).classList.contains('timeline-keyframe') ||
        (ev.target as HTMLElement).classList.contains('timeline-keyframe-control') ||
        (ev.target as HTMLElement).classList.contains('z-keyframe-clickable') ||
        (ev.target as HTMLElement).id === 'timeline-scale' ||
        (ev.target as HTMLElement).id === 'edit-path-btn' ||
        (ev.target as HTMLElement).parentElement?.id === 'edit-path-btn' ||
        (ev.target as HTMLElement).id === 'timelinecontrol-container' ||
        (ev.target as HTMLElement).id === 'playhead-scrubber' ||
        (ev.target as HTMLElement).parentElement?.id === 'playhead-scrubber' ||
        // Ignore clicks on the prop panel
        (ev.target as HTMLElement).closest('#property-panel') ||
        (ev.target as HTMLElement).closest('.lil-gui')
      ) {
        return;
      }

      if (selectedKeyframes.length > 0) {
        setSelectedKeyframes([]);
      }
      if (selectedNodeIds.length > 0 && ['TimelineList', 'TimelineBody'].includes((ev.target as HTMLElement).id)) {
        removeSelectedNodes(selectedNodeIds);
      }
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [selectedKeyframes, setSelectedKeyframes, removeSelectedNodes, selectedNodeIds]);

  const timelineHeight = useCreatorStore.getState().timeline.height;
  const timelineFullWidth = useCreatorStore.getState().timeline.fullWidth;

  let yOffset = mousePos.y;
  let xOffset = mousePos.x;
  let xOverflow = false;
  const atBottom = (mousePos.y as number) + MENU_HEIGHT.SingleKeyFrameMenu > timelineHeight;

  if (atBottom) {
    yOffset = timelineHeight - MENU_HEIGHT.SingleKeyFrameMenu;
  }

  if ((mousePos.x as number) + MENU_WIDTH.SingleKeyFrameMenu > timelineFullWidth) {
    xOffset = timelineFullWidth - MENU_WIDTH.SingleKeyFrameMenu - MENU_WIDTH_OFFSET;
    yOffset = atBottom ? yOffset - MENU_HEIGHT_OFFSET : yOffset + MENU_HEIGHT_OFFSET;
    xOverflow = true;
  } else if (
    (mousePos.x as number) + MENU_WIDTH.SingleKeyFrameMenu + MENU_WIDTH.SingleKeyFrameSubMenu >
    timelineFullWidth
  ) {
    xOffset = timelineFullWidth - (timelineFullWidth - mousePos.x);
    xOverflow = true;
  }

  return (
    <div className="absolute">
      {keyframeMenuOpened && (
        <div className="z-dropdown">
          {selectedKeyframes.length === 1 && (
            <SingleKeyFrameMenu onClose={onCloseMenu} coord={{ x: xOffset, y: yOffset }} overflow={xOverflow} />
          )}
          {selectedKeyframes.length > 1 && (
            <MultiKeyframeMenu onClose={onCloseMenu} coord={{ x: xOffset, y: yOffset }} overflow={xOverflow} />
          )}
        </div>
      )}
    </div>
  );
};
