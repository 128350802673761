/**
 * Copyright 2024 Design Barn Inc.
 */

import type { ShapeLayer, GroupShape } from '@lottiefiles/toolkit-js';
import type { Vector3 } from 'three';
import { Vector2 } from 'three';

import { getBoundingBox } from '../threejs/TransformControls/AlignmentPivotHelper';

import { UserDataMap } from '~/features/canvas';
import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const getPivotPosition = (nodeIds: string[]): Vector2 | null => {
  if (nodeIds.length === 0) return null;

  if (nodeIds.length > 1) {
    const savedPivot = toolkit.getData(UserDataMap.MultiPivot) as Vector3 | null;

    if (savedPivot) return new Vector2(savedPivot.x, savedPivot.y);

    const savedPivotOffset = toolkit.getData(UserDataMap.MultiPivotOffset) as Vector3 | null;

    if (!savedPivotOffset) return null;

    const boundingBox = getBoundingBox(nodeIds);

    if (!boundingBox) return null;

    return new Vector2(boundingBox.center.x + savedPivotOffset.x, boundingBox.center.y + savedPivotOffset.y);
  }

  const node = useCreatorStore.getState().toolkit.getNodeByIdOnly(nodeIds[0] as string);

  if (!node || !node.pivot) return null;

  return new Vector2((node as ShapeLayer | GroupShape).pivot.x, (node as ShapeLayer | GroupShape).pivot.y);
};
