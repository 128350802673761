/**
 * Copyright 2022 Design Barn Inc.
 */

export const TIMELINE_BOTTOM_GUTTER = 70;
export const LAYER_PANEL_DEFAULT_WIDTH = 300;
export const TIMELINE_BAR_BEGIN_OFFSET_PX = 15;
export const TIMELINE_SIDEBAR_WIDTH = 40;
export const KEYFRAME_ICON_HALF_WIDTH = 9;
export const TIMELINE_SCRUBBER_DEBOUNCE_MS = 10;
export const TIMELINE_KEYFRAME_DRAG_DEBOUNCE_MS = 20;
export const TIMELINE_SCRUBBER_HIGHLIGHT_MIN_WIDTH = 6;

export const secondsRegex = /^[+-]?\d*\.?\d+$/u;
export const timeRegex = /^(\d{1,3}):(\d{1,3})(?::(\d{1,3}))?$/u;
export const secondFrameRegex = /^(\d+)s (\d+)f$/u;
export const secondStrRegex = /^(\d+)s$/u;
export const frameStrRegex = /^(\d+)f$/u;
