/**
 * Copyright 2023 Design Barn Inc.
 */

import type { QuickJSHandle } from 'quickjs-emscripten';

import { stripReturnValues } from './strip-return-values';

import type { Plugin } from '~/plugins/Plugin';

// TODO: reduce code repetition by calling again from 'marshal' function
// copies the current object or array into a QuickJS equivalent
export function copyIntoVmObject(
  plugin: Plugin,
  data: object,
  objectHandle: QuickJSHandle,
  includeFunctions?: boolean,
): void {
  Object.getOwnPropertyNames(data).forEach((key) => {
    const descriptor = Object.getOwnPropertyDescriptor(data, key) as PropertyDescriptor;
    const { value } = descriptor;

    if (includeFunctions && typeof value === 'function') {
      const functionHandle =
        value.constructor.name === 'AsyncFunction'
          ? plugin.vm.newAsyncifiedFunction(key, value)
          : plugin.vm.newFunction(key, value);

      plugin.vm.setProp(objectHandle, key, functionHandle);

      functionHandle.dispose();
    } else if (descriptor.get) {
      plugin.vm.defineProp(objectHandle, key, {
        get: descriptor.get,
      });
    } else if (typeof value === 'string') {
      const valueHandle = plugin.vm.newString(value);

      plugin.vm.setProp(objectHandle, key, valueHandle);
      valueHandle.dispose();
    } else if (typeof value === 'number') {
      const valueHandle = plugin.vm.newNumber(value);

      plugin.vm.setProp(objectHandle, key, valueHandle);
      valueHandle.dispose();
    } else if (typeof value === 'boolean') {
      const valueHandle = value ? plugin.vm.true : plugin.vm.false;

      plugin.vm.setProp(objectHandle, key, valueHandle);
      valueHandle.dispose();
    } else if (Array.isArray(value)) {
      const arrayHandle = plugin.vm.newArray();
      const push = plugin.vm.getProp(arrayHandle, 'push');

      for (const innerValue of value) {
        if (typeof innerValue === 'string') {
          const vmValue = plugin.vm.newString(innerValue);

          plugin.vm.callFunction(push, arrayHandle, vmValue);
          vmValue.dispose();
        } else if (typeof innerValue === 'number') {
          const vmValue = plugin.vm.newNumber(innerValue);

          plugin.vm.callFunction(push, arrayHandle, vmValue);
          vmValue.dispose();
        } else {
          // calls itself again to handle array and object values
          const strippedValue = stripReturnValues(plugin, value);

          copyIntoVmObject(plugin, strippedValue, arrayHandle, includeFunctions);
        }
      }

      plugin.vm.setProp(objectHandle, key, arrayHandle);
    } else if (typeof value === 'object') {
      const innerObjectHandle = plugin.vm.newObject();

      copyIntoVmObject(plugin, value, innerObjectHandle, includeFunctions);
      plugin.vm.setProp(objectHandle, key, innerObjectHandle);

      innerObjectHandle.dispose();
    }
  });
}

// create an equivalent object for QuickJS
export function newQuickjsObject(plugin: Plugin, object: object, includeFunctions?: boolean): QuickJSHandle {
  const vmObject = plugin.vm.newObject();

  copyIntoVmObject(plugin, object, vmObject, includeFunctions);

  return vmObject;
}
