/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

import TimelineHeader from './TimelineHeader';
import TimelineList from './TimelineList';

export const TimelineMainContent: React.FC = () => {
  return (
    <div className="flex flex-1 flex-col">
      <TimelineHeader />
      <TimelineList />
    </div>
  );
};

export default TimelineMainContent;
