/**
 * Copyright 2021 Design Barn Inc.
 */

import { FloatingDelayGroup } from '@floating-ui/react-dom-interactions';
import { enableMapSet } from 'immer';
import React, { useCallback } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'urql';

import { AuthListener } from './AuthListener';
import { AutoSaveListener } from './AutoSaveListener';
import { BeforeUnloadListener } from './BeforeUnloadListener';
import { EmitterListener } from './EmitterListener';
import { EventStoreProvider } from './EventStoreProvider';
import { GrowthBookProvider } from './GrowthBookProvider';
import { HotkeyListener } from './HotkeyListener';
import { NetworkListener } from './NetworkListener';
import { ProjectListener } from './ProjectListener';
import { ToolkitListener } from './ToolkitListener';
import { ToolkitProvider } from './ToolkitProvider';
import { UsersnapProvider, useUsersnapApi } from './UsersnapProvider';

import ErrorAnimation from '~/assets/animations/something-went-wrong.json';
import LottieLogo from '~/assets/images/lottiefiles_logo_for_dark_bg.png';
import { CREATOR_HOME } from '~/config';
import { client } from '~/lib/graphql';
import { UnhandledErrorsListener } from '~/providers/UnhandledErrorsListener';

enableMapSet();


const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  const usersnapApi = useUsersnapApi();

  const handleReload = useCallback((evt: React.MouseEvent): void => {
    evt.preventDefault();
    window.location.reload();
  }, []);

  const handleReportCrash = useCallback((): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (usersnapApi as any).open();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (usersnapApi as any).hideButton();
  }, [usersnapApi]);

  return (
    <div className="w-full bg-gray-800">
      <div className="flex h-[24px] w-full p-[10px]">
        <img src={LottieLogo} alt="Lottie logo" className="h-[20px]" />
      </div>
      <div className="flex h-[calc(100vh-24px)] w-full flex-col items-center justify-center px-4 text-white">
        <div className="-mt-10 w-[500px] text-center">
          <div className="flex justify-center">
            <dotlottie-player
              autoplay
              loop
              src={JSON.stringify(ErrorAnimation)}
              style={{ height: '140px', marginBottom: '20px' }}
            ></dotlottie-player>
          </div>
          <div className="mt-2 text-xl">Something went wrong somewhere 😔</div>
          <div className="mt-2 text-sm text-gray-100">
            You could refresh this page, or&nbsp;
            <a className="text-lf-teal" href="https://help.lottiefiles.com/hc/en-us/requests/new">
              contact our customer support team.
            </a>
            &nbsp; We&apos;re always eager to help!
          </div>
          {error.message && <div className="mt-2 w-[500px] text-xs text-gray-300">{error.message}</div>}
          <div className="m-4 mt-6 flex justify-center">
            <a
              onClick={handleReportCrash}
              className="mr-4 !h-10 cursor-pointer rounded-xl bg-[#00C1A2] px-4 py-2 text-base text-white"
            >
              Report this crash
            </a>
            <a
              onClick={(evt) => handleReload(evt)}
              className="!h-10 cursor-pointer rounded-xl  border-[1px] border-solid px-4 py-2 text-base text-white"
            >
              Reload this page
            </a>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 h-10 w-full">
          <div className="flex w-full justify-center text-sm">
            <div className="px-4">
              <a href="https://www.notion.so/lottiefiles/How-To-s-ca8f1d3fd8a54fdea379a2dd3a381d0b">FAQ & Support</a>
            </div>
            <div className="px-4">
              <a href="https://help.lottiefiles.com/hc/en-us/requests/new">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CustomErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  const handleReload = useCallback((evt: React.MouseEvent): void => {
    evt.preventDefault();
    window.location.reload();
  }, []);

  return (
    <div className="w-full bg-gray-800">
      <div className="flex h-[24px] w-full p-[10px]">
        <img src={LottieLogo} alt="Lottie logo" className="h-[20px]" />
      </div>
      <div className="flex h-[calc(100vh-24px)] w-full flex-col items-center justify-center px-4 text-white">
        <div className="-mt-10 w-[360px] text-center">
          <div className="flex justify-center">
            <dotlottie-player
              autoplay
              loop
              src={JSON.stringify(ErrorAnimation)}
              style={{ height: '140px', marginBottom: '20px' }}
            ></dotlottie-player>
          </div>
          <div className="mt-2 text-xl">Something went wrong 😔</div>
          <div className="mt-2 text-sm text-gray-100">
            You could refresh this page, or&nbsp;
            <a className="text-lf-teal" href="https://help.lottiefiles.com/hc/en-us/requests/new">
              contact our customer support team.
            </a>
            &nbsp; We&apos;re always eager to help!
          </div>
          {error.message && <div className="mt-2 w-[360px] text-xs text-gray-300">{error.message}</div>}
          <div className="m-4 flex justify-center space-x-4">
            <a
              onClick={(evt) => handleReload(evt)}
              className=" !h-10 cursor-pointer rounded-xl bg-[#00C1A2] px-4 py-2 text-base text-white"
            >
              Reload this page
            </a>
            <a
              className="!h-10 rounded-xl border-[1px] border-solid px-4 py-2 text-base text-white"
              href={`${CREATOR_HOME}`}
              target="_blank"
              rel="noreferrer"
            >
              Create new file
            </a>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 h-10 w-full">
          <div className="flex w-full justify-center text-sm">
            <div className="px-4">
              <a href="https://www.notion.so/lottiefiles/How-To-s-ca8f1d3fd8a54fdea379a2dd3a381d0b">FAQ & Support</a>
            </div>
            <div className="px-4">
              <a href="https://help.lottiefiles.com/hc/en-us/requests/new">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  children?: React.ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  return (
    <GrowthBookProvider>
      <EventStoreProvider>
        <UsersnapProvider>
          <ToolkitProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Provider value={client}>
                <UnhandledErrorsListener />
                <HotkeyListener />
                <ToolkitListener />
                <EmitterListener />
                <AuthListener />
                <ProjectListener />
                <NetworkListener />
                <AutoSaveListener />
                <BeforeUnloadListener />

                <FloatingDelayGroup delay={{}}>{children}</FloatingDelayGroup>
              </Provider>
            </ErrorBoundary>
          </ToolkitProvider>
        </UsersnapProvider>
      </EventStoreProvider>
    </GrowthBookProvider>
  );
};
