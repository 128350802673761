/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable padding-line-between-statements */

import { isEqual } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { getMixedValue, handleOpacityType, toggleKeyframe } from './field/helpers';
import { PropertyAccordion } from './ui/PropertyAccordion';

import { Opacity } from '~/assets/icons';
import { Interactive } from '~/components/Elements/Button/Interactive';
import { VisibilityToggle } from '~/components/Elements/Button/VisibilityToggle';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { UITransformType, updateGroupFromUI } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { layerMap } from '~/lib/layer';
import { AnimatedType } from '~/lib/toolkit';
import { HotKey } from '~/providers/HotkeyListener';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

interface LayerProps {
  type:
    | PropertyPanelType.Group
    | PropertyPanelType.Fill
    | PropertyPanelType.Stroke
    | PropertyPanelType.ShapeLayer
    | PropertyPanelType.Precomposition;
}

const handleKeyframeClick = (): void => {
  toggleKeyframe(AnimatedType.OPACITY);
};

export const LayerProperty: React.FC<LayerProps> = ({ type }) => {
  let animatedType: number | undefined;
  let event: string | undefined;

  switch (type) {
    case PropertyPanelType.Precomposition:
    case PropertyPanelType.ShapeLayer:
    case PropertyPanelType.Group:
      animatedType = AnimatedType.OPACITY;
      event = EmitterEvent.ANIMATED_OPACITY_UPDATED;
      break;

    case PropertyPanelType.Fill:
      animatedType = AnimatedType.FILL_OPACITY;
      event = EmitterEvent.SHAPE_FILL_OPACITY_UPDATED;
      break;

    case PropertyPanelType.Stroke:
      animatedType = AnimatedType.STROKE_OPACITY;
      event = EmitterEvent.ANIMATED_SHAPE_STROKE_OPACITY_UPDATED;
      break;

    default:
      break;
  }

  const [currentTransform, selectedNodesInfo, currentTransforms] = useCreatorStore(
    (state) => [state.toolkit.currentTransform, state.ui.selectedNodesInfo, state.toolkit.currentTransforms],
    shallow,
  );

  const [opacity, opacityCurrentKeyframe, opacityIsAnimated] = handleOpacityType(
    selectedNodesInfo,
    currentTransforms,
    currentTransform,
    type,
  );

  const [keyframeActive, setKeyframeActive] = useState(false);
  const [isOpacityAnimated, setIsOpacityAnimated] = useState(false);
  const previousTransforms = useRef(currentTransforms);

  const multiSelect = selectedNodesInfo.length > 1;

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      updateGroupFromUI(UITransformType.Opacity, result, animatedType);

      emitter.emit(event as string);
    },
    [animatedType, event],
  );

  const handleOnVisibleChange = useCallback(() => {
    emitter.emit(EmitterEvent.TIMELINE_LAYER_SHOW_HIDE);
  }, []);

  useEffect(() => {
    if (!isEqual(previousTransforms.current, currentTransforms) || multiSelect) {
      previousTransforms.current = currentTransforms;
      const isActive = currentTransforms.every((transform) => transform.opacityCurrentKeyframe);

      setKeyframeActive(isActive);
      const isAnimated = currentTransforms.every((transform) => transform.opacityIsAnimated);
      setIsOpacityAnimated(isAnimated);
    }

    if (!multiSelect) {
      setKeyframeActive(opacityCurrentKeyframe !== '');
      setIsOpacityAnimated(opacityIsAnimated);
    }
  }, [opacityCurrentKeyframe, multiSelect, selectedNodesInfo, currentTransforms, opacityIsAnimated]);

  const mixedValue = useMemo(() => {
    return getMixedValue(multiSelect, currentTransforms, AnimatedType.OPACITY);
  }, [currentTransforms, multiSelect]);

  const [minValue, maxValue] = useMemo(() => {
    if (mixedValue) {
      // If there are mixed opacities, we need to adjust the min and max allowable values
      // or the slider won't go beyond the default min and max
      // if one layer has reached the minMax bounds
      const lowestOpacity = Math.min(...currentTransforms.map((transform) => transform.opacity));
      const highestOpacity = Math.max(...currentTransforms.map((transform) => transform.opacity));

      const min = -highestOpacity;
      const max = 100 + (100 - lowestOpacity);

      return [min, max];
    } else {
      return [0, 100];
    }
  }, [currentTransforms, mixedValue]);

  const isVisibilityToggleAvailable =
    type === PropertyPanelType.ShapeLayer || type === PropertyPanelType.Precomposition;

  const isVisible = selectedNodesInfo.some((nodeInfo) => !layerMap.get(nodeInfo.nodeId)?.isHidden);

  return (
    <PropertyAccordion title="Layer" withDisclosure={false} disableAccordionButton={true}>
      <div className="mt-2 flex">
        {/* <TooltipWrapper label={'Blending Mode'}>
          <div className="w-[86px] ml-0.5">
            <Select
              options={BlendModeOptions}
              selected={BlendModeOptions[0] as Option}
              onChange={handleOnChangeBlendMode}
            />
          </div>
        </TooltipWrapper> */}
        <Tooltip content={'Opacity'}>
          <div className="mt-[1px] w-[86px]">
            <NumberInput
              name="layer-opacity"
              label={<Opacity className="h-4 w-4" />}
              value={opacity}
              onChange={handleOnChange}
              onKeyframeClick={handleKeyframeClick}
              hasKeyframe={keyframeActive}
              showKeyframe
              append="%"
              min={minValue}
              max={maxValue}
              message={mixedValue ?? null}
              isChannelAnimated={isOpacityAnimated}
              keyframeShortcut={HotKey.TOGGLE_OPACITY_KEYFRAME.toUpperCase()}
            />
          </div>
        </Tooltip>
        {isVisibilityToggleAvailable && (
          <Interactive>
            <VisibilityToggle visible={isVisible} onClick={handleOnVisibleChange} />
          </Interactive>
        )}
      </div>
    </PropertyAccordion>
  );
};
