/**
 * Copyright 2022 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import React from 'react';
import { shallow } from 'zustand/shallow';

import { CompositionProperty } from '../components/CompositionProperty';
import { Grid } from '../components/Grid';
import { TimeProperty } from '../components/TimeProperty';

import { PropertyPanel } from './PropertyPanel';

import { Divider } from '~/components/Elements/Divider';
import { GB_CONSTANT } from '~/config/growthbook';
import { useCreatorStore } from '~/store';

export const CompositionPanel: React.FC = () => {
  const [currentPrecompJson] = useCreatorStore(
    (state) => [state.toolkit.selectedPrecompositionJson, state.toolkit.selectedPrecompositionJson?.data],
    shallow,
  );

  const showRulersGuidesGrid = useFeatureIsOn(GB_CONSTANT.SHOW_RULERS_GUIDES_GRID);

  return (
    <PropertyPanel key={currentPrecompJson ? currentPrecompJson.id : 'main'}>
      <CompositionProperty />
      <Divider />
      <TimeProperty />
      <Divider />
      {showRulersGuidesGrid ? (
        <>
          <Grid />
          <Divider />
        </>
      ) : null}
      {/* <BackgroundProperty /> */}
    </PropertyPanel>
  );
};
