/**
 * Copyright 2024 Design Barn Inc.
 */

import { cubicBezierDis, cubicBezierIntTest, getValueFromTexture, isPointInBoundingBox } from '../bezier/bezier';

export const compoundStrokeFragment = `

// referenced from https://www.shadertoy.com/view/7ltBzj
varying vec2 vCoord;
uniform sampler2D uBezierPoints;
uniform sampler2D uBezierSegmentCounts;
uniform vec3 uColor;
uniform int uBezierCount;
uniform int uTotalSegments;
uniform float uOpacity;
uniform float uStrokeWidth;
uniform bool uIsClosed;

uniform sampler2D uMaskBezierPoints;
uniform int uMaskBezierCount;
uniform sampler2D uMatteBezierPoints;
uniform int uMatteBezierCount;
uniform float uMaskOpacity;
uniform int uMatteMode;
uniform int uMaskMode;
uniform mat4 uMatteTransform;

${getValueFromTexture}
${cubicBezierIntTest}
${isPointInBoundingBox}
${cubicBezierDis}

void main() {
  int mask0 = 0;
  int matte0 = 0;

  for (int i = 0; i < uMaskBezierCount; i++) {
    vec3 maskP0 = getValueFromTexture(uMaskBezierPoints, vec2(uMaskBezierCount, 4), vec2(i, 0)).rgb;
    vec3 maskP1 = getValueFromTexture(uMaskBezierPoints, vec2(uMaskBezierCount, 4), vec2(i, 1)).rgb;
    vec3 maskP2 = getValueFromTexture(uMaskBezierPoints, vec2(uMaskBezierCount, 4), vec2(i, 2)).rgb;
    vec3 maskP3 = getValueFromTexture(uMaskBezierPoints, vec2(uMaskBezierCount, 4), vec2(i, 3)).rgb;
    mask0 += cubic_bezier_int_test_even_odd(maskP0, maskP1, maskP2, maskP3, vCoord);
  }

  for (int i = 0; i < uMatteBezierCount; i++) {
    vec3 matteP0 = (uMatteTransform * getValueFromTexture(uMatteBezierPoints, vec2(uMatteBezierCount, 4), vec2(i, 0))).rgb;
    vec3 matteP1 = (uMatteTransform * getValueFromTexture(uMatteBezierPoints, vec2(uMatteBezierCount, 4), vec2(i, 1))).rgb;
    vec3 matteP2 = (uMatteTransform * getValueFromTexture(uMatteBezierPoints, vec2(uMatteBezierCount, 4), vec2(i, 2))).rgb;
    vec3 matteP3 = (uMatteTransform * getValueFromTexture(uMatteBezierPoints, vec2(uMatteBezierCount, 4), vec2(i, 3))).rgb;
    matte0 += cubic_bezier_int_test_even_odd(matteP0, matteP1, matteP2, matteP3, vCoord);
  }

  gl_FragColor = vec4(1.0, 1.0, 1.0, 0.0);
  int bezierTotalCount = 0;

  float finalOpacity = uOpacity * uMaskOpacity;

  for (int i = 0; i < uBezierCount; i++) {
    // retrieve the segment count of individual bezier inside a compound bezier.
    // only red channel of the texture has the information about bezier segment count.
    int bezierSegmentCount = int(getValueFromTexture(uBezierSegmentCounts, vec2(uBezierCount, 1), vec2(i, 0)).r);
    if (bezierSegmentCount == 0) break;
    for (int j = 0; j < bezierSegmentCount; j++) {
      vec3 P0 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 0)).rgb;
      vec3 P1 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 1)).rgb;
      vec3 P2 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 2)).rgb;
      vec3 P3 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 3)).rgb;

      float halfWidth = uStrokeWidth * 0.5;
      vec2 minBound = min(min(P0.xy, P3.xy), min(P1.xy, P2.xy)) - vec2(halfWidth);
      vec2 maxBound = max(max(P0.xy, P3.xy), max(P1.xy, P2.xy)) + vec2(halfWidth);

      if (!isPointInBoundingBox(vCoord, minBound, maxBound)) {
        continue;
      }

      float dis = cubic_bezier_dis(vCoord, P0.xy, P1.xy, P2.xy, P3.xy);
      if (dis < halfWidth) {
        if (uMaskBezierCount == 0 && uMatteBezierCount == 0) {
          gl_FragColor = vec4(uColor, finalOpacity);
        } else {
          bool maskApplied = mask0 % 2 == 1 && (uMaskMode == 1 || uMaskMode == 4);
          if (mask0 % 2 == 0 && uMaskMode == 2) maskApplied = true;
          bool matteApplied = matte0 % 2 == 1 && uMatteMode == 1;
          if (matte0 % 2 == 0 && (uMatteMode == 2 || uMatteMode == 4)) matteApplied = true;
          if (matte0 % 2 == 1 && uMatteMode == 3) {
            float luminance = dot(uColor.rgb, vec3(0.2126, 0.7152, 0.0722)); // Calculate luminance
            float minLuminance = 0.0; // Minimum luminance
            float maxLuminance = 1.0; // Maximum luminance
            float finalOpacity = clamp((luminance - minLuminance) / (maxLuminance - minLuminance), 0.0, 1.0); // Map luminance to opacity in range [0, 1]
            matteApplied = true;
          }
          if (uMaskBezierCount > 0 && uMatteBezierCount > 0) {
            if (maskApplied && matteApplied) gl_FragColor = vec4(uColor, finalOpacity);
            else gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
          } else if (uMatteBezierCount > 0) {
            if (matteApplied) gl_FragColor = vec4(uColor, finalOpacity);
            else gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
          } else if (uMaskBezierCount > 0) {
            if (maskApplied) gl_FragColor = vec4(uColor, finalOpacity);
            else gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
          }
        }
        break;
      }
    }
    bezierTotalCount += bezierSegmentCount;
  }
}

`;
