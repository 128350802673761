/**
 * Copyright 2024 Design Barn Inc.
 */

// referenced from https://www.shadertoy.com/view/7ltBzj
import { cubicBezierIntTest, getValueFromTexture } from '../bezier/bezier';

import {
  getOffsetFromTexture,
  applyDithering,
  getColorFromProjection,
  gradientColor,
  getRadialColor,
} from './gradientFillFragment';

export const gradientCompoundFillFragment = `
varying vec2 vCoord;
uniform sampler2D uBezierPoints;
uniform sampler2D uBezierSegmentCounts;
uniform vec3 uColor;
uniform int uBezierCount;
uniform int uTotalSegments;
uniform float uOpacity;

uniform bool uIsRadial;
uniform sampler2D uColorStops;
uniform int uColorStopsCount;
uniform sampler2D uColorStopOffsets;
uniform vec2 uStart;
uniform vec2 uEnd;
uniform float uHighlightAngle;
uniform float uHighlightLength;

${getValueFromTexture}
${getOffsetFromTexture}
${applyDithering}
${getColorFromProjection}
${cubicBezierIntTest}
${gradientColor}
${getRadialColor}
void main() {
  int s0 = 0;
  int bezierTotalCount = 0;
  for (int i = 0; i < uBezierCount; i++) {
    int bezierSegmentCount = int(getValueFromTexture(uBezierSegmentCounts, vec2(uBezierCount, 1), vec2(i, 0)).r);
    if (bezierSegmentCount == 0) break;
    for (int j = 0; j < bezierSegmentCount; j++) {
      vec3 P0 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 0)).rgb;
      vec3 P1 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 1)).rgb;
      vec3 P2 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 2)).rgb;
      vec3 P3 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 3)).rgb;
      s0 += cubic_bezier_int_test_even_odd(P0, P1, P2, P3, vCoord);
    }
    bezierTotalCount += bezierSegmentCount;
  }

  if (s0 % 2 == 1) {
    if (uIsRadial) {
      vec4 radialColor = getRadialColor();
      gl_FragColor = vec4(radialColor.rgb, radialColor.a * uOpacity);
    } else {
      vec4 gradientColor = getGradientColor();
      gl_FragColor = vec4(gradientColor.rgb, gradientColor.a * uOpacity);
    }
  } else {
    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
  }
}

`;
