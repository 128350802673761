/**
 * Copyright 2024 Design Barn Inc.
 */

import type { GradientFillType } from '@lottiefiles/toolkit-js';
import React, { useEffect, useState } from 'react';

import type { GradientPointType } from '../GradientPoints/GradientPoint';

import { generateSolidStyle, generateGradientStyle } from '@/ColorPicker/helpers';

interface Props {
  alpha: number;
  blue: number;
  gradientDegree: number;
  gradientType: GradientFillType;
  green: number;
  isGradient: boolean;
  points: GradientPointType[];
  red: number;
}

export const Preview: React.FC<Props> = ({
  alpha,
  blue,
  gradientDegree,
  gradientType,
  green,
  isGradient,
  points,
  red,
}) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (isGradient) {
      const _style = generateGradientStyle(points, gradientType, gradientDegree);

      setStyle({ background: _style });

      return;
    }

    setStyle({ backgroundColor: generateSolidStyle(red, green, blue, alpha) });
  }, [points, gradientDegree, gradientType, isGradient, red, green, blue, alpha]);

  return (
    <div>
      <div className="transparent-preview-underlay">
        <div className="preview-box" style={style}></div>
      </div>
    </div>
  );
};

export default Preview;
