/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { Tooltip } from '../Tooltip';

import { AspectRatioLock, AspectRatioUnlock } from '~/assets/icons';

interface AspectRatioToggleProps {
  isLocked?: boolean;
  onClick?: () => void;
}

export const AspectRatioToggle: React.FC<AspectRatioToggleProps> = ({ isLocked = false, onClick }) => {
  const icon = isLocked ? (
    <AspectRatioLock className="h-4 w-4" />
  ) : (
    <AspectRatioUnlock className="h-4 w-4 stroke-white" />
  );

  return (
    <Tooltip content="Constrain proportions" placement="bottom-end">
      <button onClick={onClick} className="flex h-6 w-6 items-center justify-center" tabIndex={-1}>
        {icon}
      </button>
    </Tooltip>
  );
};
