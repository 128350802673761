/**
 * Copyright 2024 Design Barn Inc.
 */

export default function rgbToHSv({ blue, green, red }: { blue: number; green: number; red: number }): {
  hue: number;
  saturation: number;
  value: number;
} {
  let redRatio;
  let greenRatio;
  let blueRatio;
  let hue = 0;
  let saturation;

  const rabs = red / 255;
  const gabs = green / 255;
  const babs = blue / 255;
  const value = Math.max(rabs, gabs, babs);
  const diff = value - Math.min(rabs, gabs, babs);
  const diffc = (cc: number): number => (value - cc) / 6 / diff + 1 / 2;

  if (diff === 0) {
    hue = 0;
    saturation = 0;
  } else {
    saturation = diff / value;
    redRatio = diffc(rabs);
    greenRatio = diffc(gabs);
    blueRatio = diffc(babs);

    if (rabs === value) {
      hue = blueRatio - greenRatio;
    } else if (gabs === value) {
      hue = 1 / 3 + redRatio - blueRatio;
    } else if (babs === value) {
      hue = 2 / 3 + greenRatio - redRatio;
    }
    if (hue < 0) {
      hue += 1;
    } else if (hue > 1) {
      hue -= 1;
    }
  }

  return {
    hue: Math.round(hue * 360),
    saturation: Math.round(saturation * 100),
    value: Math.round(value * 100),
  };
}
