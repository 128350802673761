/**
 * Copyright 2023 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { executeUpload } from './middleware';

import Dropzone from '~/assets/icons/dropzone';
import { Modal } from '~/components/Elements/Modal';
import { uploadDotLottie, uploadJSON, uploadSVG } from '~/features/upload';
import { useCreatorStore } from '~/store';

export interface DropModalProps {}

export const DropModal: React.FC<DropModalProps> = () => {
  const [isDropzoneVisible, setIsDropzoneVisible] = useState(false);

  const [isUploadModalOpened, setAlert] = useCreatorStore(
    (state) => [state.ui.isUploadModalOpened, state.ui.setAlert],
    shallow,
  );

  const onDragEnter = useCallback(
    (ev: DragEvent): void => {
      if (!isDropzoneVisible && !isUploadModalOpened) {
        // Check if what the user is dragging is a file. Text can be dragged in too
        if (ev.dataTransfer && ![...ev.dataTransfer.items].some((item) => item.kind === 'file')) {
          return;
        }
        setIsDropzoneVisible(true);
      }
    },
    [isDropzoneVisible, isUploadModalOpened],
  );

  const onDocumentDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
  }, []);

  const onDocumentDrop = useCallback((event: DragEvent) => {
    event.preventDefault();
  }, []);

  React.useEffect(() => {
    document.addEventListener('dragenter', onDragEnter);
    document.addEventListener('dragover', onDocumentDragOver);
    document.addEventListener('drop', onDocumentDrop);

    return () => {
      document.removeEventListener('dragenter', onDragEnter);
      document.removeEventListener('dragover', onDocumentDragOver);
      document.removeEventListener('drop', onDocumentDrop);
    };
  }, [onDragEnter, onDocumentDragOver, onDocumentDrop]);

  const onDragLeave = React.useCallback(
    (ev: React.DragEvent) => {
      // if the current target of the dragleave event is a child of this element, ignore it
      if (ev.currentTarget.contains(ev.relatedTarget as Node)) {
        return;
      }

      setIsDropzoneVisible(false);
    },
    [setIsDropzoneVisible],
  );

  const onDrop = React.useCallback(
    async (ev: React.DragEvent) => {
      setIsDropzoneVisible(false);
      if (ev.dataTransfer.files.length > 0) {
        const file = ev.dataTransfer.files[0] as File;

        let importStartTime: number;

        const showUploadingAlert = (): void => {
          importStartTime = Date.now();
          setAlert({
            text: 'Uploading asset...',
            alertColor: '#20272C',
          });
        };

        const showUploadedAlert = (): void => {
          const timePassed = Date.now() - importStartTime;

          const showAlert = (): void => {
            setAlert({
              text: 'Asset uploaded',
              alertColor: '#00951D',
              timeout: 3000,
            });
          };

          if (timePassed < 1000) {
            setTimeout(showAlert, 1000 - timePassed);
          } else {
            showAlert();
          }
        };

        if (file.size > 20 * 1000 * 1000) {
          setAlert({
            text: 'File cannot be larger than 20 MB.',
            alertColor: '#D92600',
          });

          return;
        }

        const showError = (): void => {
          setAlert({
            text: 'Asset upload failed. How about giving it another go?',
            alertColor: '#D92600',
          });
        };

        try {
          if (file.type === 'image/svg+xml' || /\.svg$/iu.exec(file.name)) {
            showUploadingAlert();
            await uploadSVG(await file.text());
            showUploadedAlert();
          } else if (file.type === 'application/json' || /\.json$/iu.exec(file.name)) {
            const json = JSON.parse(await file.text());

            await executeUpload({
              revertLabel: 'Cancel',
              openLabel: 'Open anyway',
              data: json as unknown as string,
              execute: async () => {
                try {
                  showUploadingAlert();
                  await uploadJSON(json as unknown);
                  showUploadedAlert();
                } catch (_err) {
                  showError();
                }
              },
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              revert: () => {},
              type: 'json',
            });
          } else if (/\.lottie$/iu.exec(file.name)) {
            const buffer = await file.arrayBuffer();

            await executeUpload({
              revertLabel: 'Cancel',
              openLabel: 'Open anyway',
              type: 'dotLottie',
              data: buffer as unknown as string,
              execute: async () => {
                try {
                  showUploadingAlert();
                  uploadDotLottie(buffer);
                  showUploadedAlert();
                } catch (_err) {
                  showError();
                }
              },
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              revert: () => {},
            });
          } else {
            setAlert({
              text: 'File format not supported.',
              alertColor: '#D92600',
            });

            return;
          }
        } catch (_err) {
          showError();
        }
      }
    },
    [setIsDropzoneVisible, setAlert],
  );

  return (
    <Modal isOpen={isDropzoneVisible}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 overflow-y-auto" id="drop-modal" onDragLeave={onDragLeave} onDrop={onDrop}>
          <div className="flex min-h-full items-center justify-center  text-center">
            <Dialog.Panel className="flex max-w-[550px] flex-col items-center overflow-hidden bg-transparent align-middle transition-all">
              <Dropzone className="w-[130px]" />
              <Dialog.Title as="h3" className=" relative mb-6 mt-4 px-5 text-left font-bold text-white ">
                Drag & drop asset to upload
              </Dialog.Title>
              <div className="text-base text-gray-400">You can upload JSON, dotLottie or SVG asset (up to 20MB)</div>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Modal>
  );
};
