/**
 * Copyright 2023 Design Barn Inc.
 */

import type { ImportOptions } from '.';
import { StringType, UrlType } from '.';
import { isValidURL, verifySanitizeURL } from '~/utils';

const isJsonString = (string: string): boolean => {
  try {
    JSON.parse(string);

    return true;
  } catch {
    return false;
  }
};

const isSvgString = (string: string): boolean => {
  try {
    const parser = new DOMParser();

    parser.parseFromString(string, 'image/svg+xml');

    return true;
  } catch {
    return false;
  }
};

export const getAnimationType = (options: ImportOptions): UrlType | StringType | null => {
  if (isValidURL(options.animation)) {
    const sanitizedURL = new URL(verifySanitizeURL(options.animation));

    sanitizedURL.search = '';

    const fileType = sanitizedURL.toString().split('.').pop();

    if (Object.keys(UrlType).includes(fileType?.toUpperCase() as string)) {
      return UrlType[fileType?.toUpperCase() as keyof typeof UrlType];
    }
  }

  if (isJsonString(options.animation)) {
    return StringType.JSON;
  }

  if (isSvgString(options.animation)) {
    return StringType.SVG;
  }

  return null;
};
