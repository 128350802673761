/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable no-param-reassign */

import type { SizeJSON } from '@lottiefiles/toolkit-js';
import { DoubleSide, MeshBasicMaterial, Path, Shape, ShapeGeometry } from 'three';

import { ViewportConfig } from '../../config';
import { CMesh } from '../../types/object';

import { getToolkitState, toolkit } from '~/lib/toolkit';

export const enum Background {
  Canvas = 'background',
}

export const getBackground = async (width?: number, height?: number): Promise<CMesh> => {
  if (!width || !height) {
    const json = getToolkitState(toolkit);
    const size = json?.properties.sz as SizeJSON;

    width = size.w;
    height = size.h;
  }

  // add a background plane
  const { CameraZPosition } = ViewportConfig;

  // any reasonably big number
  const size = 10000;
  const backgroundShape = new Shape()
    .moveTo(size, size)
    .lineTo(-size, size)
    .lineTo(-size, -size)
    .lineTo(size, -size)
    .lineTo(size, size);
  const holePath = new Path()
    .moveTo(width / 2, height / 2)
    .lineTo(-width / 2, height / 2)
    .lineTo(-width / 2, height / 2)
    .lineTo(-width / 2, -height / 2)
    .lineTo(width / 2, -height / 2)
    .lineTo(width / 2, height / 2);

  backgroundShape.holes.push(holePath);
  const backgroundPlane = new CMesh(
    new ShapeGeometry(backgroundShape),
    new MeshBasicMaterial({ color: 0x111417, side: DoubleSide }),
  );

  backgroundPlane.position.z = CameraZPosition + 5;
  backgroundPlane.frustumCulled = false;
  backgroundPlane.position.x = width / 2;
  backgroundPlane.position.y = height / 2;

  backgroundPlane.name = Background.Canvas;

  return backgroundPlane;
};
