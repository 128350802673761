/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable padding-line-between-statements */
import type { AnimatedProperty, AVLayer, PrecompositionLayer, SceneJSON } from '@lottiefiles/toolkit-js';
import type { EventAndListener } from 'eventemitter2';
import { nanoid } from 'nanoid';

import { emitter, EmitterEvent } from '../emitter';

import { pause, play } from './playback';

import { RightSideBarSetting } from '~/components/Layout/Property';
import { SceneNameType } from '~/data/constant';
import { onToggleExpandAllChildren } from '~/features/timeline';
import { undoHistory, redoHistory } from '~/lib/history';
import { layerMap, resetLayerUI } from '~/lib/layer';
import {
  toolkit,
  stateHistory,
  getToolkitState,
  setToolkitCurrentFrame,
  removeAllScenes,
  createScene,
  getAnimatedFunction,
  setKeyFrame,
} from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { RENAMABLE_LAYER_TYPES } from '~/store/constant';
import { DirectoryType, SavingState } from '~/store/projectSlice';
import { AnimationLoaderStatus } from '~/store/uiSlice';

const setJSON = useCreatorStore.getState().toolkit.setJSON;
const setSceneIndex = useCreatorStore.getState().toolkit.setSceneIndex;
const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;
const removeSelectedNodes = useCreatorStore.getState().ui.removeSelectedNodes;
const setProjectInfo = useCreatorStore.getState().project.setInfo;
const setSelectedPrecompositionId = useCreatorStore.getState().toolkit.setSelectedPrecompositionId;
const setTabState = useCreatorStore.getState().timeline.setTabState;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const setRightSideBarPanel = useCreatorStore.getState().ui.setRightSideBarPanel;

const getLastFrame = (): number => {
  const duration = (useCreatorStore.getState().toolkit.json?.timeline.duration as number) || 0;
  const currentFrameRate = (useCreatorStore.getState().toolkit.json?.timeline.properties.fr as number) || 30;
  const currentPrecompJson = useCreatorStore.getState().toolkit.selectedPrecompositionJson;
  const precompFrameRate =
    (useCreatorStore.getState().toolkit.selectedPrecompositionJson?.timeline.properties.fr as number) || 30;
  const precompDuration =
    (useCreatorStore.getState().toolkit.selectedPrecompositionJson?.timeline.duration as number) || 0;

  return currentPrecompJson ? (precompFrameRate as number) * precompDuration : currentFrameRate * duration;
};

const ableToMoveTrack = (offset: number): boolean => {
  const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;
  const toolkitStore = useCreatorStore.getState().toolkit;
  const fps = (toolkitStore.json?.timeline.properties.fr as number) || 20;
  const duration = (toolkitStore.json?.timeline.duration as number) || 0;
  const precomFps = (toolkitStore.selectedPrecompositionJson?.timeline.properties.fr as number) || 20;
  const precomDuration = (toolkitStore.selectedPrecompositionJson?.timeline.duration as number) || 0;
  const selectedPrecompositionId = toolkitStore.selectedPrecompositionId;

  const totalFrames =
    (selectedPrecompositionId && precomFps ? precomFps : fps) *
    (selectedPrecompositionId && precomDuration ? precomDuration : duration);

  return selectedNodes.every((nodeInfo) => {
    const node = getNodeByIdOnly(nodeInfo.nodeId) as AVLayer | undefined;

    if (node) {
      const startFrame = node.startFrame + offset;
      const endFrame = node.endFrame + offset;

      return startFrame >= 0 && endFrame < totalFrames;
    }

    return false;
  });
};

const nudgeTrack = (offset: number, checkMovable: boolean): void => {
  const store = useCreatorStore.getState();
  const selectedNodes = store.ui.selectedNodesInfo;

  const ableToMove = ableToMoveTrack(offset);
  if (!ableToMove && checkMovable) return;

  stateHistory.beginAction();

  selectedNodes.forEach((nodeInfo) => {
    const node = getNodeByIdOnly(nodeInfo.nodeId) as AVLayer | undefined;
    if (node) {
      const nodeOffset = node.timelineOffset - node.startFrame;
      const startFrame = node.startFrame + offset;
      const endFrame = node.endFrame + offset;
      if (offset > 0) {
        node.setEndFrame(endFrame);
        node.setStartFrame(startFrame);
      } else {
        node.setStartFrame(startFrame);
        node.setEndFrame(endFrame);
      }
      node.setTimelineOffset(startFrame + nodeOffset);
    }
    const layerUI = layerMap.get(nodeInfo.nodeId);
    if (layerUI && layerUI.frameIds.length > 0) {
      const frameIds = layerUI.frameIds.slice().reverse();

      frameIds.forEach((layerFrameId: string) => {
        const frameObj = toolkit.getKeyframeById(layerFrameId);

        if (!frameObj) return;

        const currentFrame = frameObj.frame;
        const newFrame = currentFrame + offset;

        setKeyFrame(layerFrameId, newFrame as number);
      });
    }
  });

  stateHistory.endAction();

  emitter.emit(EmitterEvent.TIMELINE_BAR_DRAGGING_UPDATED);
};

export const eventHandler: EventAndListener = (event, arg): void => {
  const fetchExistingFileStatus = useCreatorStore.getState().project.fetchExistingFile.status;

  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
  const animationLoader = useCreatorStore.getState().ui.animationLoader;

  // When animation loading, do not update timeline layers
  if (animationLoader.status === AnimationLoaderStatus.Loading) {
    return;
  }

  switch (event) {
    case EmitterEvent.TOOLKIT_STATE_UPDATED: {
      if (arg.event && (arg.event === EmitterEvent.UI_REDO || arg.event === EmitterEvent.TIMELINE_RENAME_LAYER_END)) {
        const json = structuredClone(getToolkitState(toolkit));
        if (json) setJSON(json);
      }
      // Prevent infinite loop
      break;
    }

    case EmitterEvent.UI_UNDO: {
      undoHistory(event);
      break;
    }

    case EmitterEvent.UI_REDO: {
      redoHistory(event);
      break;
    }

    case EmitterEvent.UI_ANIMATED_DELETE: {
      const type = arg ? arg.type || false : false;
      const id = arg ? arg.id || false : false;

      if (type && id) {
        const animatedFunctionList = Object.values(getAnimatedFunction);
        const animatedIndex = animatedFunctionList.findIndex((animated) => animated.key === type);

        if (animatedIndex > -1) {
          const animated = animatedFunctionList[animatedIndex];

          const node = getNodeByIdOnly(id);

          if (node && animated) {
            (node as AnimatedProperty).setIsAnimated(false);
            emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);
          }
        }
      }
      break;
    }

    case EmitterEvent.TIMELINE_PRECOMP_EDIT_SCENE: {
      let targetNode;

      if (selectedPrecompositionId) {
        targetNode = toolkit.getNodeById(selectedPrecompositionId)?.getNodeById(arg.id);
      } else {
        targetNode = toolkit.getNodeById(arg.id);
      }

      if (!targetNode) {
        return;
      }
      // The event fired from context menus (as well as double clicking the
      // layers) contain the precomp LAYER id what we want is the precomp
      // asset id.
      targetNode = (targetNode as PrecompositionLayer).precomposition;
      if (!targetNode) {
        return;
      }
      setSelectedPrecompositionId(targetNode.nodeId);
      setCurrentFrame(0);
      removeSelectedNodes();

      setTabState(targetNode.name, true);

      emitter.emit(EmitterEvent.CANVAS_NULLIFY_LAST_OBJECT);
      resetLayerUI();
      emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
      break;
    }

    case EmitterEvent.TIMELINE_RENAME_LAYER_START:
      {
        const creatorState = useCreatorStore.getState();
        const selectedNodes = creatorState.ui.selectedNodesInfo;
        let renamableLayerId;

        if (selectedNodes.length === 1) {
          renamableLayerId = creatorState.ui.selectedNodesInfo[0]?.nodeId as string;
        } else {
          renamableLayerId = null;
        }

        if (renamableLayerId) {
          const node = getNodeByIdOnly(renamableLayerId);
          // Decides if the layer type is renamable or not
          const isRenamableLayer = RENAMABLE_LAYER_TYPES.includes((node as AVLayer).type);

          if (!isRenamableLayer) {
            renamableLayerId = null;
          }
        }

        if (renamableLayerId !== creatorState.timeline.renamingLayer) {
          creatorState.timeline.setRenamingLayer(renamableLayerId);
        }
      }
      break;

    case EmitterEvent.TIMELINE_TOGGLE_PLAY: {
      const playing = useCreatorStore.getState().timeline.playing;

      if (playing) {
        pause();
      } else {
        play();
      }

      break;
    }

    case EmitterEvent.PLAYBACK_GOTO_FIRST_FRAME: {
      setCurrentFrame(0);
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      break;
    }

    case EmitterEvent.PLAYBACK_GOTO_LAST_FRAME: {
      setCurrentFrame(getLastFrame() - 1);
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      break;
    }

    case EmitterEvent.PLAYBACK_GOTO_NEXT_FRAME: {
      const lastFrame = getLastFrame();
      const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

      if (lastFrame === currentFrame) return;
      setCurrentFrame(Math.min(currentFrame + 1, lastFrame - 1));
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      break;
    }

    case EmitterEvent.PLAYBACK_GOTO_PREVIOUS_FRAME: {
      const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

      if (currentFrame === 0) return;
      setCurrentFrame(currentFrame - 1);
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      break;
    }

    case EmitterEvent.PLAYBACK_GO_FORWARD_TEN_FRAME: {
      const lastFrame = getLastFrame();
      const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

      if (lastFrame === currentFrame) return;

      const remianingFrames = lastFrame - currentFrame;

      setCurrentFrame(currentFrame + (remianingFrames < 10 ? remianingFrames : 10));
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      break;
    }

    case EmitterEvent.PLAYBACK_GO_BACK_TEN_FRAME: {
      const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

      if (currentFrame === 0) return;

      setCurrentFrame(currentFrame - (currentFrame < 10 ? currentFrame : 10));
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
      break;
    }

    case EmitterEvent.TIMELINE_PLAY: {
      play();
      break;
    }

    case EmitterEvent.TIMELINE_PAUSE: {
      pause();
      break;
    }

    case EmitterEvent.TRACK_MOVE_BACKWARD: {
      nudgeTrack(-1, true);
      break;
    }

    case EmitterEvent.TRACK_MOVE_FORWARD: {
      nudgeTrack(1, true);
      break;
    }

    case EmitterEvent.TRACK_MOVE_BACKWARD_TEN_FRAME: {
      nudgeTrack(-10, true);
      break;
    }

    case EmitterEvent.TRACK_MOVE_FORWARD_TEN_FRAME: {
      nudgeTrack(10, true);
      break;
    }

    case EmitterEvent.TRACK_IN_TO_PLAYHEAD: {
      const store = useCreatorStore.getState();
      const selectedNodeId = store.ui.selectedNodesInfo[0]?.nodeId ?? '';
      const node = getNodeByIdOnly(selectedNodeId) as AVLayer | undefined;
      if (!node) return;
      const currentFrame = useCreatorStore.getState().toolkit.currentFrame as number;
      nudgeTrack(currentFrame - node.startFrame, false);
      break;
    }

    case EmitterEvent.TRACK_OUT_TO_PLAYHEAD: {
      const store = useCreatorStore.getState();
      const selectedNodeId = store.ui.selectedNodesInfo[0]?.nodeId ?? '';
      const node = getNodeByIdOnly(selectedNodeId) as AVLayer | undefined;
      if (!node) return;
      const currentFrame = useCreatorStore.getState().toolkit.currentFrame as number;
      nudgeTrack(currentFrame - node.endFrame, false);
      break;
    }

    case EmitterEvent.LINE_CREATED:
      break;

    case EmitterEvent.RESET:
      if (toolkit.scenes.length > 0) {
        removeAllScenes(toolkit);
      }

      createScene(toolkit, {
        name: SceneNameType.MainScene,
        width: 512,
        height: 512,
        frameRate: 30,
        startFrame: 0,
        endFrame: 150,
      });

      // set scene index to 0
      setSceneIndex(0);

      // set initial json
      setJSON(structuredClone(getToolkitState(toolkit)) as SceneJSON);
      removeSelectedNodes();
      setCurrentFrame(0);
      break;

    case EmitterEvent.TIMELINE_TOGGLE_VISIBLE: {
      const visible = useCreatorStore.getState().timeline.visible;

      const setVisible = useCreatorStore.getState().timeline.setVisible;

      setVisible(!visible);
      break;
    }

    case EmitterEvent.ANIMATION_PRESET_PANEL_OPEN: {
      setRightSideBarPanel(RightSideBarSetting.AnimationPresets);

      break;
    }

    case EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED:
    case EmitterEvent.CANVAS_HIDE_TRANSFORMCONTROL:
    case EmitterEvent.CANVAS_SHOW_TRANSFORMCONTROL:
      emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event, data: arg });
      break;
    case EmitterEvent.TIMELINE_TOGGLE_CHILD_LAYERS_EXPANSION:
      onToggleExpandAllChildren();
      break;

    default: {
      // Skip update state to store
      // TODO: Do we need to set current frame here for every event?
      stateHistory.offTheRecord(() => {
        setToolkitCurrentFrame(toolkit, useCreatorStore.getState().toolkit.currentFrame);
      });

      if (arg && arg.skipUpdate) {
        // Emit TOOLKIT_STATE_UPDATED event so that canvas redraw can be triggered
        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event });

        // offTheRecord
        // if (executableFunctions.length > 0) {
        //   stateHistory.offTheRecord(() => {
        //     toolkit.batch(() => {
        //       // layer.rotation.setStaticValue(new Angle(50));
        //       executableFunctions.map((func: unknown) => func());
        //     });
        //   });
        // }

        return;
      }

      if (
        (event.includes('animated') ||
          event.includes('shape:fill-') ||
          event.includes('appearance:created') ||
          event.includes('timeline:current-frame-updated') ||
          event.includes('timeline:duration-updated') ||
          event === EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED) &&
        selectedPrecompositionId
      ) {
        setSelectedPrecompositionId(selectedPrecompositionId);
      }

      // deep copy to avoid state mutation, toolkit state will be constantly updated
      const json = structuredClone(getToolkitState(toolkit));
      if (!json) return;
      if (arg && arg.commit) {
        // if (executableFunctions.length > 0) {
        //   toolkit.batch(() => {
        //     console.log('batch running...');
        //     executableFunctions.map((func: unknown) => func());
        //   });
        // }
        // const prevJSON = useCreatorStore.getState().toolkit.json;
        // const prevState = { ui: {}, json: prevJSON };
        // const currentState = { ui: {}, json };
        // const patches = compare(prevState, currentState);
        // const inversePatches = compare(currentState, prevState);
        // commitHistory({ patches, inversePatches });
      }

      if (json.allLayers.length && !fetchExistingFileStatus) {
        // TODO: Move to if (arg && arg.commit) condition block in undo/redo implementaiton
        const { madeFirstChange, setMadeFirstChange } = useCreatorStore.getState().project;

        if (!madeFirstChange) {
          // Set madeFirstChange once only
          setMadeFirstChange(true);

          const selectedDirectoryType = useCreatorStore.getState().project.selectedDirectory?.type;
          const isInFolderOrProject = [DirectoryType.Folder, DirectoryType.Project].includes(
            selectedDirectoryType as DirectoryType,
          );

          if (isInFolderOrProject) {
            setProjectInfo({ savingState: SavingState.SAVING });
          }
        }
      }

      setJSON(json);

      // TODO: Move to if (arg && arg.commit) condition block in undo/redo implementaiton
      setProjectInfo({ localChangeId: nanoid() });

      const savingState = useCreatorStore.getState().project.info.savingState;

      // If the project is saved, set savingState to PENDING_SAVE as a new change has been made
      if (savingState === SavingState.SAVED) {
        setProjectInfo({ savingState: SavingState.PENDING_SAVE });
      }

      // Emit TOOLKIT_STATE_UPDATED event so that canvas redraw can be triggered
      emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event, data: arg });
    }
  }
};
