/**
 * Copyright 2024 Design Barn Inc.
 */

import { throttle } from 'lodash-es';

export default function useMouseEvents(
  mouseDownHandler: (eve: MouseEvent) => unknown,
  mouseMoveHandler: (eve: MouseEvent, positions: unknown) => unknown,
  mouseUpHandler?: (eve: MouseEvent, positions: unknown) => unknown,
) {
  return function mouseEventsHandler(event: MouseEvent) {
    let positions = mouseDownHandler(event);

    const onMouseMove = throttle((eve: MouseEvent): void => {
      positions = mouseMoveHandler(eve, positions) || positions;
    }, 50);

    window.addEventListener('mousemove', onMouseMove);

    window.addEventListener(
      'mouseup',
      (eve: MouseEvent): void => {
        window.removeEventListener('mousemove', onMouseMove);

        if (mouseUpHandler) mouseUpHandler(eve, positions);
      },
      { once: true },
    );
  };
}
