/**
 * Copyright 2022 Design Barn Inc.
 */

import type { GradientFillShape } from '@lottiefiles/toolkit-js';
import { GradientFillType } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PropertyRow } from './ui/PropertyRow';

import {
  AnimatedPositionX,
  AnimatedPositionY,
  AnimatedHighlightLengthIcon,
  AnimatedHighlightAngleIcon,
} from '~/assets/icons';
import { NumberInput, defaultStyle, DualNumberInput } from '~/components/Elements/Input';
import type { NumberResult, NumberInputOption } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { MIN_SHAPE_WIDTH, MAX_SHAPE_WIDTH, MIN_X, MAX_X } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import {
  getCurrentGFillShape,
  getGradientFillShape,
  GradientPoint,
  removeKeyFrame,
  setAnimatedHighlightAngle,
  setAnimatedHighlightLength,
} from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const gradientStartOption: { x: NumberInputOption; y: NumberInputOption } = {
  x: {
    name: GradientPoint.StartX,
    label: <AnimatedPositionX className="h-3 w-3" />,
  },
  y: {
    name: GradientPoint.StartY,
    label: <AnimatedPositionY className="h-3 w-3" />,
  },
};

const gradientEndOption: { x: NumberInputOption; y: NumberInputOption } = {
  x: {
    name: GradientPoint.EndX,
    label: <AnimatedPositionX className="h-3 w-3" />,
  },
  y: {
    name: GradientPoint.EndY,
    label: <AnimatedPositionY className="h-3 w-3" />,
  },
};

const GradientStartAndEnd: React.FC = () => {
  const [selectedNodesInfo, getNodeByIdOnly, currentFrame, setGradientPoint] = useCreatorStore(
    (state) => [
      state.ui.selectedNodesInfo,
      state.toolkit.getNodeByIdOnly,
      state.toolkit.currentFrame,
      state.toolkit.setGradientPoint,
    ],
    shallow,
  );

  const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId ?? '') as GradientFillShape | null;

  const handleStartInputChange = useCallback(
    (result: NumberResult) => {
      setGradientPoint(result.name, result.value);
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_POINT_UPDATED);
    },
    [setGradientPoint],
  );

  const handleEndInputChange = useCallback(
    (result: NumberResult) => {
      setGradientPoint(result.name, result.value);
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_END_UPDATED);
    },
    [setGradientPoint],
  );

  if (!node) return null;

  const animatedStartProperties = node.state.animatedProperties.gs;
  const animatedEndProperties = node.state.animatedProperties.ge;

  const currentStartKeyframe = animatedStartProperties.keyFrames.find((kf) => kf.frame === currentFrame)?.frameId ?? '';
  const currentEndKeyframe = animatedEndProperties.keyFrames.find((kf) => kf.frame === currentFrame)?.frameId ?? '';

  const isStartAnimated = node.startPoint.isAnimated;
  const isEndAnimated = node.endPoint.isAnimated;

  const { geX, geY, gsX, gsY } = getCurrentGFillShape(getGradientFillShape(node));

  const handleStartKeyframeClick = (): void => {
    if (currentStartKeyframe === '') {
      node.startPoint.setIsAnimated(true);
      setGradientPoint(GradientPoint.StartX, gsX);
      setGradientPoint(GradientPoint.StartY, gsY);
    } else {
      removeKeyFrame(currentStartKeyframe);
    }

    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_START_UPDATED);
  };

  const handleEndKeyframeClick = (): void => {
    if (currentEndKeyframe === '') {
      node.endPoint.setIsAnimated(true);
      setGradientPoint(GradientPoint.EndX, geX);
      setGradientPoint(GradientPoint.EndY, geY);
    } else {
      removeKeyFrame(currentEndKeyframe);
    }

    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_END_UPDATED);
  };

  return (
    <>
      <div className="text-xs font-normal text-gray-300">Start</div>
      <div className="mt-2 flex w-full">
        <DualNumberInput
          onChange={handleStartInputChange}
          onKeyframeClick={handleStartKeyframeClick}
          hasKeyframe={Boolean(currentStartKeyframe)}
          left={gsX}
          right={gsY}
          leftOption={gradientStartOption.x}
          rightOption={gradientStartOption.y}
          showKeyframe
          leftMin={MIN_X}
          leftMax={MAX_X}
          rightMin={MIN_X}
          rightMax={MAX_X}
          tooltip={{ left: 'Start pointX', right: 'Start pointY' }}
          isChannelAnimated={isStartAnimated}
        />
      </div>

      <div className="mt-2 text-xs font-normal text-gray-300">End</div>
      <div className="mt-2 flex w-full">
        <DualNumberInput
          onChange={handleEndInputChange}
          onKeyframeClick={handleEndKeyframeClick}
          hasKeyframe={Boolean(currentEndKeyframe)}
          left={geX}
          right={geY}
          leftOption={gradientEndOption.x}
          rightOption={gradientEndOption.y}
          showKeyframe
          leftMin={MIN_SHAPE_WIDTH}
          leftMax={MAX_SHAPE_WIDTH}
          rightMin={MIN_SHAPE_WIDTH}
          rightMax={MAX_SHAPE_WIDTH}
          tooltip={{ left: 'End pointX', right: 'End pointY' }}
          isChannelAnimated={isEndAnimated}
        />
      </div>
    </>
  );
};

const HighlightPoint: React.FC = () => {
  const [selectedNodesInfo, getNodeByIdOnly, currentFrame] = useCreatorStore(
    (state) => [
      state.ui.selectedNodesInfo,
      state.toolkit.getNodeByIdOnly,
      state.toolkit.currentFrame,
      state.toolkit.setGradientPoint,
    ],
    shallow,
  );

  const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId ?? '') as GradientFillShape | null;

  const handleLengthChange = useCallback(
    (result: NumberResult) => {
      setAnimatedHighlightLength(node, [result.value]);
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_POINT_UPDATED);
    },
    [node],
  );

  const handleAngleChange = useCallback(
    (result: NumberResult) => {
      setAnimatedHighlightAngle(node, [result.value]);
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_POINT_UPDATED);
    },
    [node],
  );

  if (!node) return null;

  const animatedLengthProperties = node.state.animatedProperties.hl;
  const animatedAngleProperties = node.state.animatedProperties.ha;

  const currentLengthKeyframe =
    animatedLengthProperties.keyFrames.find((kf) => kf.frame === currentFrame)?.frameId ?? '';
  const currentAngleKeyframe = animatedAngleProperties.keyFrames.find((kf) => kf.frame === currentFrame)?.frameId ?? '';

  const isLengthAnimated = node.highlightLength.isAnimated;
  const isAngleAnimated = node.highlightAngle.isAnimated;

  const { ha, hl } = getCurrentGFillShape(getGradientFillShape(node));

  const handleLengthKeyframeClick = (): void => {
    if (currentLengthKeyframe === '') {
      node.highlightLength.setIsAnimated(true);
      setAnimatedHighlightLength(node, [hl]);
    } else {
      removeKeyFrame(currentLengthKeyframe);
    }
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_POINT_UPDATED);
  };

  const handleAngleKeyframeClick = (): void => {
    if (currentAngleKeyframe === '') {
      node.highlightAngle.setIsAnimated(true);
      setAnimatedHighlightAngle(node, [ha]);
    } else {
      removeKeyFrame(currentAngleKeyframe);
    }
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_POINT_UPDATED);
  };

  return (
    <div className="mt-2 w-[182px]">
      <div className="text-xs font-normal text-gray-300">Highlight</div>
      <div className="mt-2 flex">
        <div className="mr-[2px] w-1/2">
          <Tooltip content="Highlight length" placement="bottom">
            <div className="flex">
              <NumberInput
                styleClass={{ label: `${defaultStyle.label} w-[87px]` }}
                name={GradientPoint.HighlightLength}
                value={hl}
                min={-100}
                max={100}
                label={<AnimatedHighlightLengthIcon className="h-3 w-3" />}
                onChange={handleLengthChange}
                onKeyframeClick={handleLengthKeyframeClick}
                hasKeyframe={Boolean(currentLengthKeyframe)}
                isChannelAnimated={isLengthAnimated}
                showKeyframe
              />
            </div>
          </Tooltip>
        </div>
        <div className="my-[-8px] border border-gray-800"></div>
        <div className="w-[87px]">
          <Tooltip content="Highlight angle" placement="bottom">
            <div className="flex">
              <NumberInput
                styleClass={{ label: `${defaultStyle.label}` }}
                name={GradientPoint.HighlightAngle}
                value={ha}
                label={<AnimatedHighlightAngleIcon className="h-3 w-3" />}
                min={-360}
                max={360}
                onChange={handleAngleChange}
                onKeyframeClick={handleAngleKeyframeClick}
                hasKeyframe={Boolean(currentAngleKeyframe)}
                isChannelAnimated={isAngleAnimated}
                append="°"
                showKeyframe
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const GradientProperty: React.FC = () => {
  const selectedNode = useCreatorStore.getState().ui.selectedNodesInfo[0];
  const node = useCreatorStore.getState().toolkit.getNodeByIdOnly(selectedNode?.nodeId as string) as GradientFillShape;
  const isLinear = node.gradientType === GradientFillType.LINEAR;

  return (
    <PropertyRow title={isLinear ? 'Linear gradient' : 'Radial gradient'}>
      <GradientStartAndEnd />
      {!isLinear && <HighlightPoint />}
    </PropertyRow>
  );
};
