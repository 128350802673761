/**
 * Copyright 2024 Design Barn Inc.
 */

import type { Size } from '@lottiefiles/toolkit-js';
import { round, throttle } from 'lodash-es';
import { Vector3 } from 'three';

import { TransformType } from './constant';

import type { Viewport } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getActiveScene, getSceneSize, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const BASE_SNAP_THRESHOLD = 5;
const HIGHLIGHT_THRESHOLD = 0.5;
const HIGHLIGHT_COLOR = '#D92600';
const THROTTLE_VALUE = 50;

enum SnapLineType {
  CANVAS = 'canvas',
  GRID = 'grid',
  GUIDE = 'guide',
}

enum SnapLineOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

interface SnapLine {
  orientation: SnapLineOrientation;
  position: number;
  type: SnapLineType;
}

export interface SnappedLines {
  horizontal: SnapLine[];
  vertical: SnapLine[];
}

interface SnapResult {
  distanceToLine: Vector3;
  snapLineX: SnapLine | null;
  snapLineY: SnapLine | null;
  snapOffsetFromMouse: Vector3;
}

export class SnapManager {
  public enabled = true;

  public isSnapping = false;

  public snapLines: SnappedLines = {
    horizontal: [] as SnapLine[],
    vertical: [] as SnapLine[],
  };

  public snapThreshold: number;

  public snappedLines: SnappedLines = {
    horizontal: [] as SnapLine[],
    vertical: [] as SnapLine[],
  };

  private readonly _ctx: CanvasRenderingContext2D;

  private readonly _element: HTMLCanvasElement;

  private _highlightThreshold: number;

  private _sceneSize = { width: 0, height: 0 };

  private _transformType: TransformType | null = null;

  private readonly _viewport: Viewport;

  public constructor(viewport: Viewport) {
    this._viewport = viewport;

    this._element = document.getElementById('snapped-lines') as HTMLCanvasElement;

    const ctx = this._element.getContext('2d');

    if (!ctx) {
      throw new Error('Failed to get canvas context for snapping');
    }

    this._ctx = ctx;

    this.enabled = useCreatorStore.getState().canvas.snapping.enabled;

    this.snapThreshold = Math.max(
      BASE_SNAP_THRESHOLD * (100 / useCreatorStore.getState().ui.zoomPercentage),
      BASE_SNAP_THRESHOLD,
    );
    this._highlightThreshold = Math.max(
      HIGHLIGHT_THRESHOLD * (100 / useCreatorStore.getState().ui.zoomPercentage),
      HIGHLIGHT_THRESHOLD,
    );

    const scene = getActiveScene(toolkit);

    if (!scene) return;

    const size = getSceneSize(scene) as Size | null;

    if (!size) return;

    this._sceneSize = size;

    this._addEventListeners();
    this.generateSnapLines();

    const ro = new ResizeObserver((entries) => {
      if (entries[0]) {
        this._resize(entries[0].contentRect.width, entries[0].contentRect.height);
      }
    });

    ro.observe(this._element.parentElement as Element);
  }

  public generateCanvasLines(): void {
    this.removeSnapLines(SnapLineType.CANVAS);

    const verticalPositions = [0, this._sceneSize.width / 2, this._sceneSize.width];
    const horizontalPositions = [0, this._sceneSize.height / 2, this._sceneSize.height];

    this.snapLines.vertical.push(
      ...verticalPositions.map((position) => ({
        orientation: SnapLineOrientation.VERTICAL,
        position,
        type: SnapLineType.CANVAS,
      })),
    );

    this.snapLines.horizontal.push(
      ...horizontalPositions.map((position) => ({
        orientation: SnapLineOrientation.HORIZONTAL,
        position,
        type: SnapLineType.CANVAS,
      })),
    );
  }

  public generateGridLines(): void {
    this.removeSnapLines(SnapLineType.GRID);

    const grid = useCreatorStore.getState().canvas.grid;

    if (!grid.enabled || !grid.visible) return;

    const verticalSpacing = this._sceneSize.width / grid.columns;
    const horizontalSpacing = this._sceneSize.height / grid.rows;

    const gridColumnsPositions = Array.from({ length: grid.columns - 1 }, (_, index) => (index + 1) * verticalSpacing);
    const gridRowsPositions = Array.from({ length: grid.rows - 1 }, (_, index) => (index + 1) * horizontalSpacing);

    this.snapLines.vertical.push(
      ...gridColumnsPositions.map((position) => ({
        orientation: SnapLineOrientation.VERTICAL,
        position,
        type: SnapLineType.GRID,
      })),
    );

    this.snapLines.horizontal.push(
      ...gridRowsPositions.map((position) => ({
        orientation: SnapLineOrientation.HORIZONTAL,
        position,
        type: SnapLineType.GRID,
      })),
    );
  }

  public generateGuideLines(): void {
    this.removeSnapLines(SnapLineType.GUIDE);

    const guides = useCreatorStore.getState().canvas.guides;

    if (!guides.enabled) return;

    const horizontalGuides = Object.values(guides.horizontal);
    const verticalGuides = Object.values(guides.vertical);

    this.snapLines.horizontal.push(
      ...horizontalGuides.map((position) => ({
        orientation: SnapLineOrientation.HORIZONTAL,
        position,
        type: SnapLineType.GUIDE,
      })),
    );

    this.snapLines.vertical.push(
      ...verticalGuides.map((position) => ({
        orientation: SnapLineOrientation.VERTICAL,
        position,
        type: SnapLineType.GUIDE,
      })),
    );
  }

  public generateSnapLines(): void {
    this.generateCanvasLines();
    this.generateGridLines();
    this.generateGuideLines();

    this.snapLines.vertical.sort((lineA, lineB) => lineA.position - lineB.position);
    this.snapLines.horizontal.sort((lineA, lineB) => lineA.position - lineB.position);
  }

  public getAllSnappedLines(
    snapPoints: Vector3[],
    horizontalLines: SnapLine[],
    verticalLines: SnapLine[],
    distanceToLine: Vector3,
    mousePosition: Vector3,
  ): void {
    snapPoints.forEach((point) => {
      point.add(distanceToLine);

      horizontalLines.forEach((line) => {
        if (Math.abs(point.y - line.position) <= this._highlightThreshold) {
          if (!this.ignoreSnap(line, point, mousePosition)) {
            this.snappedLines.horizontal.push(line);
          }
        }
      });

      verticalLines.forEach((line) => {
        if (Math.abs(point.x - line.position) <= this._highlightThreshold) {
          if (!this.ignoreSnap(line, point, mousePosition)) {
            this.snappedLines.vertical.push(line);
          }
        }
      });
    });

    this.snappedLines.horizontal = Array.from(new Set(this.snappedLines.horizontal));
    this.snappedLines.vertical = Array.from(new Set(this.snappedLines.vertical));
  }

  public getSnapPoints(): Vector3[] {
    const boundingBox = this._viewport.transformControls.getBoundingBox();
    const { bottomLeft, bottomRight, topLeft, topRight } =
      this._viewport.transformControls.alignmentPivotHelper.getPoints(boundingBox);

    const cornerPoints = [bottomLeft, bottomRight, topLeft, topRight];

    if (this._transformType === TransformType.Translation) {
      return [...cornerPoints, boundingBox.center];
    }

    return cornerPoints;
  }

  public getSnapResult(transformType: TransformType, mousePosition: Vector3): SnapResult {
    if (!this.enabled)
      return { distanceToLine: new Vector3(), snapLineX: null, snapLineY: null, snapOffsetFromMouse: new Vector3() };

    this.isSnapping = true;
    this._transformType = transformType;

    const snapPoints = this.getSnapPoints();
    const distanceToLine = new Vector3();
    const snapOffsetFromMouse = new Vector3();

    const midX = this._sceneSize.width / 2;
    const midY = this._sceneSize.height / 2;

    const quadrants = new Set<number>();

    snapPoints.forEach((point) => {
      if (point.x <= midX && point.y <= midY) quadrants.add(1);
      if (point.x >= midX && point.y <= midY) quadrants.add(2);
      if (point.x <= midX && point.y >= midY) quadrants.add(3);
      if (point.x >= midX && point.y >= midY) quadrants.add(4);
    });

    const filteredHorizontalLines = this.snapLines.horizontal.filter((line) => {
      if (line.position <= midY && (quadrants.has(1) || quadrants.has(2))) return true;
      if (line.position >= midY && (quadrants.has(3) || quadrants.has(4))) return true;

      return false;
    });

    const filteredVerticalLines = this.snapLines.vertical.filter((line) => {
      if (line.position <= midX && (quadrants.has(1) || quadrants.has(3))) return true;
      if (line.position >= midX && (quadrants.has(2) || quadrants.has(4))) return true;

      return false;
    });

    const snapLineX = filteredHorizontalLines.reduce(
      (closest, line) => {
        let minDistance = closest ? Math.abs(closest.position - (snapPoints[0] as Vector3).y) : Infinity;

        for (const point of snapPoints) {
          const distance = Math.abs(line.position - point.y);

          if (distance < this.snapThreshold && distance < minDistance && !this.ignoreSnap(line, point, mousePosition)) {
            minDistance = distance;
            distanceToLine.setY(line.position - point.y);
            snapOffsetFromMouse.setY(line.position - mousePosition.y);

            return line;
          }
        }

        return closest;
      },
      null as SnapLine | null,
    );

    const snapLineY = filteredVerticalLines.reduce(
      (closest, line) => {
        let minDistance = closest ? Math.abs(closest.position - (snapPoints[0] as Vector3).x) : Infinity;

        for (const point of snapPoints) {
          const distance = Math.abs(line.position - point.x);

          if (distance < this.snapThreshold && distance < minDistance && !this.ignoreSnap(line, point, mousePosition)) {
            minDistance = distance;
            distanceToLine.setX(line.position - point.x);
            snapOffsetFromMouse.setX(line.position - mousePosition.x);

            return line;
          }
        }

        return closest;
      },
      null as SnapLine | null,
    );

    if (!snapLineX && !snapLineY) return { distanceToLine, snapLineX: null, snapLineY: null, snapOffsetFromMouse };

    this.getAllSnappedLines(snapPoints, filteredHorizontalLines, filteredVerticalLines, distanceToLine, mousePosition);
    this.highlightSnappedLines();

    return { distanceToLine, snapLineX, snapLineY, snapOffsetFromMouse };
  }

  public handlePointerUp(): void {
    if (!this.isSnapping) return;

    this.isSnapping = false;
    this.snappedLines.horizontal = [];
    this.snappedLines.vertical = [];
    this._transformType = null;
    this.removeHighlightedLines();
  }

  public highlightSnappedLines(): void {
    const startsAndEnds = [...this.snappedLines.horizontal, ...this.snappedLines.vertical].map((line) => {
      const start = new Vector3();
      const end = new Vector3();

      if (line.orientation === SnapLineOrientation.HORIZONTAL) {
        start.set(0, round(line.position, 2), 0);
        end.set(this._sceneSize.width, round(line.position, 2), 0);

        if (line.type === SnapLineType.GUIDE) {
          start.setX(-this._viewport.canvasHelper.viewportWidth);
          end.setX(this._viewport.canvasHelper.viewportWidth);
        }
      } else {
        start.set(round(line.position, 2), 0, 0);
        end.set(round(line.position, 2), this._sceneSize.height, 0);

        if (line.type === SnapLineType.GUIDE) {
          start.setY(-this._viewport.canvasHelper.viewportHeight);
          end.setY(this._viewport.canvasHelper.viewportHeight);
        }
      }

      return [start, end];
    });

    this.renderHighlightedLines(startsAndEnds);
  }

  public ignoreSnap(snapLine: SnapLine, snappedPoint: Vector3, mousePosition: Vector3): boolean {
    const boundingBox = this._viewport.transformControls.getBoundingBox();

    const mouseToSnapLine = new Vector3(
      snapLine.orientation === SnapLineOrientation.VERTICAL ? Math.abs(snapLine.position - mousePosition.x) : 0,
      snapLine.orientation === SnapLineOrientation.HORIZONTAL ? Math.abs(snapLine.position - mousePosition.y) : 0,
    );

    if (this._transformType === TransformType.Scale || this._transformType === TransformType.Drawing) {
      if (mouseToSnapLine.x > this.snapThreshold || mouseToSnapLine.y > this.snapThreshold) {
        return true;
      }
    }

    if (this._transformType === TransformType.Scale) {
      // when scaling, don't snap if the bounding box's centerLine is the same as the snapLine
      // or it will snap to a width/height of 0 and get stuck
      if (boundingBox.center.x === snapLine.position || boundingBox.center.y === snapLine.position) {
        return true;
      }
    }

    const allowedOutOfScene = snapLine.type === SnapLineType.GUIDE;

    if (snapLine.orientation === SnapLineOrientation.HORIZONTAL) {
      return !allowedOutOfScene && (snappedPoint.x > this._sceneSize.width || snappedPoint.x < 0);
    }

    return !allowedOutOfScene && (snappedPoint.y > this._sceneSize.height || snappedPoint.y < 0);
  }

  public removeHighlightedLines(): void {
    this._viewport.canvasHelper.clearCanvas(this._ctx);
    this._viewport.canvasHelper.projectCanvasToScene(this._ctx);
  }

  public removeSnapLines(snapType: SnapLineType): void {
    this.snapLines.horizontal = this.snapLines.horizontal.filter((snapLine) => snapLine.type !== snapType);
    this.snapLines.vertical = this.snapLines.vertical.filter((snapLine) => snapLine.type !== snapType);
  }

  public renderHighlightedLines(startsAndEnds: Vector3[][]): void {
    this.removeHighlightedLines();

    this._ctx.scale(this._viewport.camera.zoom, this._viewport.camera.zoom);

    const pixelRatio = this._viewport.pixelRatio;

    this._ctx.strokeStyle = HIGHLIGHT_COLOR;
    this._ctx.lineWidth = (1.5 / this._viewport.camera.zoom) * pixelRatio;

    startsAndEnds.forEach(([start, end]) => {
      if (!start || !end) return;

      this._ctx.beginPath();
      this._ctx.moveTo(start.x * pixelRatio, start.y * pixelRatio);
      this._ctx.lineTo(end.x * pixelRatio, end.y * pixelRatio);
      this._ctx.stroke();
    });
  }

  public toggleSnapping(): void {
    this.enabled = !this.enabled;

    if (this.enabled) {
      this.generateSnapLines();
    }

    if (!this.enabled) {
      this.snapLines.horizontal = [];
      this.snapLines.vertical = [];
    }
  }

  public updateHighlightedLines(): void {
    if (
      !this.enabled ||
      !this.isSnapping ||
      this._viewport.transformControls.objects.length === 0 ||
      (!this.snappedLines.horizontal.length && !this.snapLines.vertical.length)
    )
      return;

    const snapPoints = this.getSnapPoints();

    this.snappedLines.horizontal = this.snappedLines.horizontal.filter((line) =>
      snapPoints.some((point) => Math.abs(point.y - line.position) <= this._highlightThreshold),
    );

    this.snappedLines.vertical = this.snappedLines.vertical.filter((line) =>
      snapPoints.some((point) => Math.abs(point.x - line.position) <= this._highlightThreshold),
    );

    this.highlightSnappedLines();
  }

  private _addEventListeners(): void {
    window.addEventListener('pointermove', this.updateHighlightedLines.bind(this));
    window.addEventListener('pointerup', this.handlePointerUp.bind(this));

    useCreatorStore.subscribe(
      (state) => state.ui.zoomPercentage,
      (percentage) => {
        this.snapThreshold = Math.max(BASE_SNAP_THRESHOLD * (100 / percentage), BASE_SNAP_THRESHOLD);
        this._highlightThreshold = Math.max(HIGHLIGHT_THRESHOLD * (100 / percentage), HIGHLIGHT_THRESHOLD);
      },
    );

    emitter.on(EmitterEvent.SCENE_SIZE_UPDATED, () => {
      if (!this.enabled) return;

      const scene = getActiveScene(toolkit);

      if (!scene) return;

      const size = getSceneSize(scene) as Size | null;

      if (!size) return;

      this._sceneSize = size;

      this.generateCanvasLines();
      this.generateGridLines();
    });

    emitter.on(EmitterEvent.PRECOMP_SCENE_SIZE_UPDATED, () => {
      if (!this.enabled) return;

      const scene = getActiveScene(toolkit);

      if (!scene) return;

      const size = getSceneSize(scene) as Size | null;

      if (!size) return;

      this._sceneSize = size;

      this.generateCanvasLines();
      this.generateGridLines();
    });

    useCreatorStore.subscribe(
      (state) => state.canvas.snapping.enabled,
      (enabled) => {
        this.enabled = enabled;
      },
    );

    useCreatorStore.subscribe(
      (state) => state.canvas.grid,
      throttle((grid) => {
        if (!this.enabled) return;

        if (grid.enabled && grid.visible) {
          this.generateGridLines();
        } else {
          this.removeSnapLines(SnapLineType.GRID);
        }
      }, THROTTLE_VALUE),
    );

    useCreatorStore.subscribe(
      (state) => state.canvas.guides,
      throttle((guides) => {
        if (!this.enabled) return;

        if (guides.enabled) {
          this.generateGuideLines();
        } else {
          this.removeSnapLines(SnapLineType.GUIDE);
        }
        // avoid too many updates when guides are being dragged
      }, THROTTLE_VALUE),
    );
  }

  private _resize(width: number, height: number): void {
    this._viewport.canvasHelper.resize(this._element, width, height);
    this._viewport.canvasHelper.updateCanvasReferences();
  }
}
