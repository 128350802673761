/**
 * Copyright 2022 Design Barn Inc.
 */

import { useEffect } from 'react';

import { saveToCreator } from '~/lib/function/menu';

export const SaveToExistingProject: React.FC = () => {
  useEffect(() => {
    saveToCreator({ saveWithoutPopup: true });
  }, []);

  return null;
};
