/**
 * Copyright 2023 Design Barn Inc.
 */

import type { RefObject } from 'react';

import { useClickOutside } from '~/hooks';

interface Props {
  onExecute: (evt: MouseEvent | TouchEvent) => void;
  onRef: RefObject<HTMLElement> | null;
}
// To prevent useClickOutside's onExecute called on first comp initialize
export const ClickOutside: React.FC<Props> = (params) => {
  const { onExecute, onRef } = params;

  useClickOutside(onRef, onExecute, null);

  return null;
};
