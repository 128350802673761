/**
 * Copyright 2023 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/lottie-js';
import type { CubicBezierShape, DrawableBezierView, Shape } from '@lottiefiles/toolkit-js';
import { Color, DoubleSide, Float32BufferAttribute, PlaneGeometry, ShaderMaterial, DataTexture } from 'three';
import type { IUniform, BufferGeometry, Matrix4 } from 'three';

import type { BezierMesh } from '../../types';
import { CMesh } from '../../types';
import { compoundFillFragment } from '../shaders/bezier-fill/compoundFillFragment';
import { fillFragment } from '../shaders/bezier-fill/fillFragment';
import { gradientCompoundFillFragment } from '../shaders/bezier-fill/gradientComoundFillFragment';
import { gradientFillFragment } from '../shaders/bezier-fill/gradientFillFragment';
import bezierFillVertexShader from '../shaders/bezier-fill/vertexShader.glsl?raw';
import { compoundStrokeFragment } from '../shaders/bezier-stroke/compoundStrokeFragment';
import { gradientCompoundStrokeFragment } from '../shaders/bezier-stroke/gradientCompoundStrokeFragment';
import { gradientStrokeFragment } from '../shaders/bezier-stroke/gradientStrokeFragment';
import { strokeFragment } from '../shaders/bezier-stroke/strokeFragment';
import strokeVertexShader from '../shaders/bezier-stroke/vertexShader.vert?raw';
import type { MaskUniforms, MatteUniforms } from '../shapes/path';
import { getCompoundBezierUniforms, getBezierUniforms } from '../shapes/path';

const getFragmentShaderType = (shapeType: ShapeType, isCompoundBezier: boolean): string => {
  const shaderTypes = {
    [ShapeType.GRADIENT_FILL]: isCompoundBezier ? gradientCompoundFillFragment : gradientFillFragment,
    [ShapeType.GRADIENT_STROKE]: isCompoundBezier ? gradientCompoundStrokeFragment : gradientStrokeFragment,
    [ShapeType.FILL]: isCompoundBezier ? compoundFillFragment : fillFragment,
    [ShapeType.STROKE]: isCompoundBezier ? compoundStrokeFragment : strokeFragment,
  };

  return shaderTypes[shapeType as keyof typeof shaderTypes];
};

export type DrawableBezierShape = Shape & { toBezier: () => CubicBezierShape };

interface Box {
  max: { x: number; y: number };
  min: { x: number; y: number };
}

export const DefaultBezierUniforms = (): Record<string, IUniform> => {
  return {
    uBezierCount: { value: 0 },
    uBezierPoints: { value: new DataTexture() },
    uStrokeWidth: { value: 2.5 },
    uColor: { value: new Color(0x00ff00) },
    uIsClosed: { value: true },
    uOpacity: { value: 0.0 },
    uMaskOpacity: { value: 1.0 },
  };
};

function getBezierBoundingBox(
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
): Box {
  let minX = Math.min(x0, x3);
  let minY = Math.min(y0, y3);
  let maxX = Math.max(x0, x3);
  let maxY = Math.max(y0, y3);

  // The smaller the step is, the more correct the bounding box would become
  for (let t = 0; t <= 1; t += 0.1) {
    const x = (1 - t) ** 3 * x0 + 3 * (1 - t) ** 2 * t * x1 + 3 * (1 - t) * t ** 2 * x2 + t ** 3 * x3;
    const y = (1 - t) ** 3 * y0 + 3 * (1 - t) ** 2 * t * y1 + 3 * (1 - t) * t ** 2 * y2 + t ** 3 * y3;

    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  }

  return { min: { x: minX, y: minY }, max: { x: maxX, y: maxY } };
}

export const getBeziersBoundingBox = (bezierUniforms: Record<string, IUniform>, closed: boolean): Box => {
  const box = { min: { x: 0, y: 0 }, max: { x: 0, y: 0 } };
  const bezierPoints = bezierUniforms['uBezierPoints']?.value as DataTexture | null;
  const bezierCount = bezierUniforms['uBezierCount']?.value as number | null;

  if (!bezierPoints || !bezierCount) return box;
  const bezierPointsData = bezierPoints.source.data.data;

  // control points(4) * coordinate x, y(2)
  const step = 8;
  // bezier points * coordinate x, y
  const textureWidth = bezierCount * 2;

  for (let i = 0; i < bezierPointsData.length / step; i += 1) {
    if (!closed && i === bezierPointsData.length / step - 1) continue;
    // calculate the bounding box from 4 control points
    // bezierPointsData is a DataTexture with a pixel format of `RGFormat` and each pixel has 2 values(r, g).
    // i: pixel index, x0 = bezierPointsData[i * 2], y0 = bezierPointsData[i * 2 + 1]
    const pointIndex = i * 2;
    const minMax = getBezierBoundingBox(
      bezierPointsData[pointIndex],
      bezierPointsData[pointIndex + 1],
      bezierPointsData[textureWidth + pointIndex],
      bezierPointsData[textureWidth + pointIndex + 1],
      bezierPointsData[textureWidth * 2 + pointIndex],
      bezierPointsData[textureWidth * 2 + pointIndex + 1],
      bezierPointsData[textureWidth * 3 + pointIndex],
      bezierPointsData[textureWidth * 3 + pointIndex + 1],
    );

    if (i === 0) {
      box.min.x = minMax.min.x;
      box.min.y = minMax.min.y;
      box.max.x = minMax.max.x;
      box.max.y = minMax.max.y;
    } else {
      box.min.x = Math.min(box.min.x, minMax.min.x);
      box.min.y = Math.min(box.min.y, minMax.min.y);
      box.max.x = Math.max(box.max.x, minMax.max.x);
      box.max.y = Math.max(box.max.y, minMax.max.y);
    }
  }

  return box;
};

export const updateBezierPlaneSize = (
  planeGeometry: BufferGeometry,
  bezierUniforms: Record<string, IUniform>,
  strokeWidth = 0,
): void => {
  const box = getBeziersBoundingBox(bezierUniforms, Boolean(bezierUniforms['uIsClosed']?.value));

  const newVertices = [];

  const halfStroke = strokeWidth / 2;

  newVertices.push(box.min.x - halfStroke, box.max.y + halfStroke, 0);
  newVertices.push(box.max.x + halfStroke, box.max.y + halfStroke, 0);
  newVertices.push(box.min.x - halfStroke, box.min.y - halfStroke, 0);
  newVertices.push(box.max.x + halfStroke, box.min.y - halfStroke, 0);
  const positionAttr = planeGeometry.getAttribute('position');

  planeGeometry.setAttribute('position', new Float32BufferAttribute(newVertices, 3));
  positionAttr.needsUpdate = true;
};

export const createBezierFill = (
  uniforms: Record<string, IUniform>,
  isCompoundBezier: boolean,
  isGradient: boolean,
): CMesh => {
  const geometry = new PlaneGeometry(1, 1, 1, 1);

  updateBezierPlaneSize(geometry, uniforms, 0);

  const vertexShader = bezierFillVertexShader;
  const fillType = isGradient ? ShapeType.GRADIENT_FILL : ShapeType.FILL;
  const fragmentShader = getFragmentShaderType(fillType, isCompoundBezier);

  const material = new ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms,
    side: DoubleSide,
    transparent: true,
  });
  const mesh = new CMesh(geometry, material);

  return mesh;
};

export const createBezierStroke = (
  strokeWidth: number,
  isCompoundBezier: boolean,
  isGradient: boolean,
  uniforms = DefaultBezierUniforms(),
): BezierMesh => {
  const geometry = new PlaneGeometry(1, 1, 1, 1);

  updateBezierPlaneSize(geometry, uniforms, strokeWidth);

  const strokeType = isGradient ? ShapeType.GRADIENT_STROKE : ShapeType.STROKE;
  const fragmentShader = getFragmentShaderType(strokeType, isCompoundBezier);

  const material = new ShaderMaterial({
    vertexShader: strokeVertexShader,
    fragmentShader,
    uniforms,
    side: DoubleSide,
    transparent: true,
  });
  const mesh = new CMesh(geometry, material);

  mesh.name = 'bezierStroke';

  return mesh as BezierMesh;
};

const applyMaskMatte = (
  object: BezierMesh,
  maskUniforms: MaskUniforms | null | undefined,
  matteUniforms: MatteUniforms | null | undefined,
): void => {
  const material = object.material;

  if (object.name === 'outline') {
    if (maskUniforms?.uMaskBezierCount) material.uniforms['uBezierCount'] = { value: maskUniforms.uMaskBezierCount };
    if (maskUniforms?.uMaskBezierPoints) material.uniforms['uBezierPoints'] = { value: maskUniforms.uMaskBezierPoints };
    material.uniforms['uIsClosed'] = { value: true };
  } else {
    if (maskUniforms?.uMaskBezierCount)
      material.uniforms['uMaskBezierCount'] = { value: maskUniforms.uMaskBezierCount };
    if (maskUniforms?.uMaskBezierPoints)
      material.uniforms['uMaskBezierPoints'] = { value: maskUniforms.uMaskBezierPoints };
    if (maskUniforms?.uMaskMode) material.uniforms['uMaskMode'] = { value: maskUniforms.uMaskMode };
    if (maskUniforms?.uMaskOpacity) material.uniforms['uMaskOpacity'] = { value: maskUniforms.uMaskOpacity };
    if (matteUniforms?.uMatteBezierCount)
      material.uniforms['uMatteBezierCount'] = { value: matteUniforms.uMatteBezierCount };
    if (matteUniforms?.uMatteBezierPoints)
      material.uniforms['uMatteBezierPoints'] = { value: matteUniforms.uMatteBezierPoints };
    if (matteUniforms?.uMatteMode) material.uniforms['uMatteMode'] = { value: matteUniforms.uMatteMode };
    if (matteUniforms?.uMatteTransform) material.uniforms['uMatteTransform'] = { value: matteUniforms.uMatteTransform };
  }

  material.needsUpdate = true;
};

const applyMaskMatteTraverse = (
  object: BezierMesh,
  maskUniforms: MaskUniforms | null | undefined,
  matteUniforms: MatteUniforms | null | undefined,
): void => {
  applyMaskMatte(object, maskUniforms, matteUniforms);
  object.children.forEach((child) => {
    applyMaskMatte(child as BezierMesh, maskUniforms, matteUniforms);
  });
};

export const updateBezierShape = (
  bezier: CubicBezierShape | null,
  object: BezierMesh,
  maskUniforms?: MaskUniforms | null,
  matteUniforms?: MatteUniforms,
): void => {
  const material = object.material;

  applyMaskMatteTraverse(object, maskUniforms, matteUniforms);

  if (!bezier) return;
  const { uBezierCount, uBezierPoints } = getBezierUniforms(bezier, true);

  material.uniforms['uBezierCount'] = { value: uBezierCount };
  material.uniforms['uBezierPoints'] = { value: uBezierPoints };
  material.uniforms['uIsClosed'] = { value: bezier.isClosed };

  material.needsUpdate = true;

  updateBezierPlaneSize(object.geometry, material.uniforms);
  object.children.forEach((child) => {
    if (child.name === 'outline') return;
    const childMaterial = (child as BezierMesh).material;

    childMaterial.uniforms['uBezierCount'] = { value: uBezierCount };
    childMaterial.uniforms['uBezierPoints'] = { value: uBezierPoints };
    childMaterial.uniforms['uIsClosed'] = { value: bezier.isClosed };
    updateBezierPlaneSize(
      (child as unknown as CMesh).geometry,
      childMaterial.uniforms,
      childMaterial.uniforms['uStrokeWidth']?.value ?? 0,
    );
    childMaterial.needsUpdate = true;
  });
};

export const updateCompoundBezierShape = (
  beziers: CubicBezierShape[],
  object: BezierMesh,
  maskUniforms?: MaskUniforms | null,
  matteUniforms?: MatteUniforms,
): void => {
  const material = object.material;

  applyMaskMatteTraverse(object, maskUniforms, matteUniforms);
  material.needsUpdate = true;
  if (beziers.length === 0) return;
  const { uBezierCount, uBezierPoints, uBezierSegmentCounts, uTotalSegments } = getCompoundBezierUniforms(
    beziers,
    true,
  );

  material.uniforms['uBezierCount'] = { value: uBezierCount };
  material.uniforms['uBezierSegmentCounts'] = { value: uBezierSegmentCounts };
  material.uniforms['uBezierPoints'] = { value: uBezierPoints };
  material.uniforms['uTotalSegments'] = { value: uTotalSegments };

  material.needsUpdate = true;
  updateBezierPlaneSize(object.geometry, material.uniforms);
  object.children.forEach((child) => {
    if (child.name === 'outline') return;
    const childMaterial = (child as BezierMesh).material;

    childMaterial.uniforms['uBezierCount'] = { value: uBezierCount };
    childMaterial.uniforms['uBezierSegmentCounts'] = { value: uBezierSegmentCounts };
    childMaterial.uniforms['uBezierPoints'] = { value: uBezierPoints };
    childMaterial.uniforms['uTotalSegments'] = { value: uTotalSegments };
    updateBezierPlaneSize(
      (child as unknown as CMesh).geometry,
      childMaterial.uniforms,
      childMaterial.uniforms['uStrokeWidth']?.value ?? 0,
    );
    childMaterial.needsUpdate = true;
  });
};

export const updateMatteTransformMatrix = (object: BezierMesh, offsetMatrix: Matrix4): void => {
  const material = object.material;

  material.uniforms['uMatteTransform'] = { value: offsetMatrix };
  material.needsUpdate = true;
  updateBezierPlaneSize(object.geometry, material.uniforms);
  object.children.forEach((child) => {
    const childMaterial = (child as BezierMesh).material;

    childMaterial.uniforms['uMatteTransform'] = { value: offsetMatrix };
    updateBezierPlaneSize(
      (child as unknown as CMesh).geometry,
      childMaterial.uniforms,
      childMaterial.uniforms['uStrokeWidth']?.value ?? 0,
    );
    childMaterial.needsUpdate = true;
  });
};

export const collectBeziers = (bezierViews: DrawableBezierView[]): CubicBezierShape[] => {
  const beziers = [];

  for (const bezierView of bezierViews.reverse()) {
    for (const bezier of bezierView.beziers) {
      beziers.push(bezier);
    }
  }

  return beziers;
};
