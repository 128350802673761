/**
 * Copyright 2024 Design Barn Inc.
 */

import { clamp } from 'lodash-es';
import { Vector2, Color, Mesh, ShaderMaterial, SphereGeometry, MathUtils } from 'three';

import { Elements } from '..';

import { materialOptions, RENDER_ORDERS } from '.';
import { BLUE_COLOR_RGB, CObject3D } from '~/features/canvas';
import type { CurrentGFillShape } from '~/lib/toolkit';

const highlightVertexShader = `
  varying vec2 vCoord;

  void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position.xyz, 1.0);

    vCoord = position.xy;
  }
`;

const highlightFragmentShader = `
  uniform vec3 color;
  uniform float alpha;
  varying vec2 vCoord;

  void main() {
    float circleRadius = 6.0;
    float outlineRadius = 7.2;

    float distanceFromCenter = length(vCoord);
    float angle = atan(vCoord.y, vCoord.x);

    float gapCount = 8.0;
    float gapWidth = 0.5;

    if (distanceFromCenter < circleRadius) {
      gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
    } else if (distanceFromCenter < outlineRadius) {
      if (mod(angle, 3.14159265 * 2.0 / gapCount) < gapWidth) {
        gl_FragColor = vec4(color, 1.0);
      } 
    }
  }
`;

export const updateHighlightPosition = (
  highlightPoint: CObject3D,
  start: Vector2,
  end: Vector2,
  highlightAngle: number,
  highlightLength: number,
): void => {
  const angleRadians = MathUtils.degToRad(highlightAngle);
  const direction = end.clone().sub(start);

  const rotatedDirection = new Vector2(
    direction.x * Math.cos(angleRadians) - direction.y * Math.sin(angleRadians),
    direction.x * Math.sin(angleRadians) + direction.y * Math.cos(angleRadians),
  );

  const clampedHighlight = clamp(highlightLength / 100, -1, 1);

  rotatedDirection.multiplyScalar(clampedHighlight);
  const newPosition = start.clone().add(rotatedDirection);

  highlightPoint.position.set(newPosition.x, newPosition.y, 0);
};

export const createHighlightPoint = (shape: CurrentGFillShape, start: Vector2, end: Vector2): CObject3D => {
  const highlightPoint = new CObject3D();

  const shader = new ShaderMaterial({
    uniforms: {
      color: { value: new Color(BLUE_COLOR_RGB) },
    },
    vertexShader: highlightVertexShader,
    fragmentShader: highlightFragmentShader,
    ...materialOptions,
  });

  const mesh = new Mesh(new SphereGeometry(8, 10, 10), shader);

  mesh.renderOrder = RENDER_ORDERS.HIGHLIGHT_POINT;

  highlightPoint.add(mesh);
  highlightPoint.userData['type'] = Elements.HIGHLIGHT_POINT;

  updateHighlightPosition(highlightPoint, start, end, shape.ha, shape.hl);

  return highlightPoint;
};
