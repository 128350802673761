/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import { createPortal } from 'react-dom';

import LoadingAnimation from '~/assets/animations/loading-lf.json';
import { useCreatorStore } from '~/store';

export const FullScreenLoading: React.FC = () => {
  const loader = useCreatorStore((state) => state.ui.loader);
  const { description, isLoading, title } = loader;

  return (
    <>
      {isLoading &&
        createPortal(
          <div
            className="absolute inset-0 z-loading-overlay flex h-screen w-full flex-col items-center justify-center overflow-hidden"
            style={{
              // Prevent from applying opacity to childs
              backgroundColor: 'rgba(17, 19, 23, 0.7)',
            }}
          >
            <div className="mb-[16px] h-20 w-20">
              <dotlottie-player
                renderer="svg"
                autoplay
                loop
                className="h-20 w-20"
                src={JSON.stringify(LoadingAnimation)}
              />
            </div>
            {title && <h2 className="text-center text-[16px] font-semibold text-white">{title}</h2>}
            {description && <p className="w-1/3 text-center text-white">{description}</p>}
          </div>,
          document.body,
        )}
    </>
  );
};
