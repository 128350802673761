/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

interface Props {
  children: React.ReactNode;
  header?: React.ReactNode;
  icon?: React.ReactNode;
  title: string | JSX.Element;
}

export const PropertyRow: React.FC<Props> = ({ children, header, icon, title }) => {
  return (
    <div className="pb-3 pl-4 pr-3 pt-4">
      <div className="mb-3 flex items-center text-xs font-bold text-gray-300">
        {icon}
        {title}
        {header}
      </div>
      {children}
    </div>
  );
};
