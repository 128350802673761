/**
 * Copyright 2024 Design Barn Inc.
 */

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { getEventStoreInstance } from '@lottiefiles/event-store';
import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import semver from 'semver';
import { shallow } from 'zustand/shallow';

import { Divider } from '../Elements/Divider';
import { FloatingWindowsContainer } from '../Elements/Window';

import { Header } from './Header';
import { CreatorLock, AlertContainer, FullScreenLoading, GlobalModal, WarningModal } from './Overlay';
import PreventPinchZoom from './PreventPinchZoom';
import { Property } from './Property';
import { Sidebar, SidebarPanel } from './Sidebar';

import {
  Duplicator,
  DuplicatorActive,
  KineticTypography,
  KineticTypographyActive,
  IconScout,
  IconScoutActive,
  PromptVector,
  PromptVectorActive,
  MotionCopilotActive,
  MotionCopilot,
} from '~/assets/icons';
import { TestAnimationDialog, HiddenThumbnailPlayer } from '~/components/Elements/TestAnimationDialog';
import { FetchFailedFileNotFoundWarning } from '~/components/Layout/Overlay/FetchFailedFileNotFoundWarning';
import { FetchFailedToLoadWarning } from '~/components/Layout/Overlay/FetchFailedToLoadWarning';
import { MinimizedUIExitButton } from '~/components/Layout/Overlay/MinimizedUIExitButton';
import { NotAuthorizedWarning } from '~/components/Layout/Overlay/NotAuthorizedWarning';
import { GB_CONSTANT } from '~/config/growthbook';
import { SIDEBAR_PLUGINS } from '~/data/plugins';
import { Canvas } from '~/features/canvas';
// eslint-disable-next-line no-restricted-imports
import { DragToCanvasContainer } from '~/features/canvas/components/DragToConvasContainer';
import { OnboardingWrapper } from '~/features/onboarding';
import { PluginBrowserDialog } from '~/features/plugin-browser';
import { NewPluginModal } from '~/features/plugin-dev';
import { FloatingPlugins } from '~/features/plugin-ui';
import { TimeLine, HiddenTimelineContainer } from '~/features/timeline';
// eslint-disable-next-line no-restricted-imports
import { TimelineSegmentsDialog } from '~/features/timeline/components/Timeline/TimelineSegments/TimelineSegmentsDialog';
import { DropModal } from '~/features/upload';
import { useWindowSize } from '~/hooks/useWindowSize';
import { getPluginManager, PluginUIType } from '~/plugins';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant, LocalStorageKey } from '~/store/constant';
import './scrollbar.css';

const DebugUI = lazy(async () => {
  const module = await import('~/features/debugging-ui/DebugUI');

  return { default: module.DebugUI };
});

const IS_DEVELOPMENT = import.meta.env.DEV;

// CSS rationale:
// Require to set position:relative on #main-content to bound the draggable element
// https://github.com/react-grid-layout/react-draggable/issues/213#issuecomment-480667868
// Tailwind layout experiment playground: https://play.tailwindcss.com/uV5XsPn3eb
const DefaultCreator: React.FC = () => {
  const [currentUser, isAuthorized, isUIMinimized] = useCreatorStore(
    (state) => [state.user.info, state.user.isAuthorized, state.ui.isUIMinimized],
    shallow,
  );
  const eventStore = getEventStoreInstance();
  const changelogVersion = useFeatureValue(GB_CONSTANT.LATEST_CHANGELOG_VERSION, APP_VERSION);

  // Track and set id on first open, or when user changes
  useEffect(() => {
    if (currentUser.id && currentUser.email) {
      const segments = currentUser.userSegments?.map((segment) => segment.title);

      eventStore.initEventStore({
        userIdentity: {
          email: currentUser.email,
          id: currentUser.id,
          segment: segments,
          otherProperties: {
            utmsource: 'event-store',
          },
        },
      });
    }
  }, [currentUser.email, currentUser.id, currentUser.userSegments, eventStore]);

  useEffect(() => {
    const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;

    const seenOnboarding = window.localStorage.getItem(LocalStorageKey.SeenOnboarding);

    if (seenOnboarding === null || seenOnboarding === 'false') {
      setGlobalModal(GlobalModalConstant.Onboarding);

      return;
    }

    if (!isAuthorized) {
      return;
    }

    let showChangelog = true;
    const lastChangelogSeen = window.localStorage.getItem(LocalStorageKey.LastChangelogSeen);

    try {
      showChangelog = lastChangelogSeen === null || semver.lt(lastChangelogSeen, changelogVersion);
    } catch (error) {
      // console.error('Semver error, something went wrong in the APP_VERSION setting');
      // Default to displaying the changelog anyway (showChangelog is defaulted to true)
    }

    if (showChangelog) {
      setGlobalModal(GlobalModalConstant.LatestReleaseModal);
    }

    window.localStorage.setItem(LocalStorageKey.LastChangelogSeen, changelogVersion);
  }, [isAuthorized, changelogVersion]);

  return (
    <div
      id="creator-root"
      className="flex h-full flex-col"
      onContextMenu={(event: MouseEvent) => {
        event.preventDefault();
      }}
    >
      <DropModal />
      <WarningModal />
      <Header isHidden={isUIMinimized} />
      <Divider />
      <div className="relative flex flex-1 overflow-hidden">
        <Sidebar isHidden={isUIMinimized} />
        <div id="main-content" className="relative flex flex-1 flex-col overflow-hidden">
          <div id="middle-content" className="flex flex-1 overflow-hidden">
            <NotAuthorizedWarning />
            <FetchFailedFileNotFoundWarning />
            <FetchFailedToLoadWarning />
            <AlertContainer />
            <SidebarPanel />
            <Canvas />
            <Property isHidden={isUIMinimized} />
            <DragToCanvasContainer />
          </div>
          <MinimizedUIExitButton />
          <HiddenTimelineContainer />
          <TestAnimationDialog />
          <TimelineSegmentsDialog />
          <PluginBrowserDialog />
          <NewPluginModal />
          <HiddenThumbnailPlayer />
          <FloatingPlugins />
        </div>
        <TimeLine />
      </div>
      <GlobalModal />
      <OnboardingWrapper />
      {IS_DEVELOPMENT && (
        <Suspense fallback={null}>
          <DebugUI />
        </Suspense>
      )}
      <FloatingWindowsContainer />
    </div>
  );
};

const CreatorCursor: React.FC = () => {
  const globalCursor = useCreatorStore((state) => state.ui.globalCursor);

  useEffect(() => {
    document.body.className = `${globalCursor}`;
  }, [globalCursor]);

  return null;
};

const CreatorDocumentHead: React.FC = () => {
  const projectName = useCreatorStore((state) => state.project.info.name);

  return (
    <Helmet>
      <title>{projectName} - Lottie Creator</title>
    </Helmet>
  );
};

const CreatorWindow: React.FC = () => {
  const setWindow = useCreatorStore.getState().ui.setWindow;
  const window = useCreatorStore.getState().ui.window;

  const windowSize = useWindowSize();

  if (windowSize.height !== window.height || windowSize.width !== window.width) {
    setWindow({ ...windowSize });
  }

  return null;
};

export const Creator: React.FC = () => {
  const [forbiddenUser, isUserFetched] = useCreatorStore(
    (state) => [state.user.forbid, state.user.isUserFetched],
    shallow,
  );

  // Note: load plugin manager only when iconscout plugin is enabled
  const iconscoutPluginVisibility = useFeatureIsOn(GB_CONSTANT.SHOW_ICONSCOUT_PLUGIN);

  const duplicatorPluginVisibility = useFeatureIsOn(GB_CONSTANT.SHOW_DUPLICATOR_PLUGIN);

  const promptVectorPluginVisibility = useFeatureIsOn(GB_CONSTANT.SHOW_PROMPT_VECTOR_PLUGIN);

  const kineticTypographyPluginVisibility = useFeatureIsOn(GB_CONSTANT.SHOW_KINETIC_TYPOGRAPHY_PLUGIN);

  const motionCopilotPluginVisibility = useFeatureIsOn(GB_CONSTANT.SHOW_MOTION_COPILOT_PLUGIN);

  useEffect(() => {
    if (!isUserFetched) {
      return;
    }
    if (iconscoutPluginVisibility) {
      // Note: This is IS's plugin id that's hardcoded temporarily.
      // These values will be fetched from an external resource eventually, and moved outside of this file
      getPluginManager()
        .load(SIDEBAR_PLUGINS.iconscout.uuid, {
          hasToolbarShortcut: true,
          uiType: PluginUIType.SidebarUI,
          toolbarIcon: <IconScout />,
          toolbarIconActive: <IconScoutActive />,
          eagerName: SIDEBAR_PLUGINS.iconscout.name,
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
    if (duplicatorPluginVisibility) {
      getPluginManager()
        .load(SIDEBAR_PLUGINS.duplicator.uuid, {
          hasToolbarShortcut: true,
          uiType: PluginUIType.FloatingUI,
          toolbarIcon: <Duplicator />,
          toolbarIconActive: <DuplicatorActive />,
          eagerName: SIDEBAR_PLUGINS.duplicator.name,
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }

    if (promptVectorPluginVisibility) {
      getPluginManager()
        .load(SIDEBAR_PLUGINS.promptVector.uuid, {
          hasToolbarShortcut: true,
          uiType: PluginUIType.SidebarUI,
          toolbarIcon: <PromptVector />,
          toolbarIconActive: <PromptVectorActive />,
          eagerName: SIDEBAR_PLUGINS.promptVector.name,
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }

    if (kineticTypographyPluginVisibility) {
      getPluginManager()
        .load(SIDEBAR_PLUGINS.kineticTypography.uuid, {
          hasToolbarShortcut: true,
          uiType: PluginUIType.FloatingUI,
          toolbarIcon: <KineticTypography />,
          toolbarIconActive: <KineticTypographyActive />,
          eagerName: SIDEBAR_PLUGINS.kineticTypography.name,
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }

    if (motionCopilotPluginVisibility) {
      getPluginManager()
        .load(SIDEBAR_PLUGINS.motionCopilot.uuid, {
          hasToolbarShortcut: true,
          uiType: PluginUIType.FloatingUI,
          toolbarIcon: <MotionCopilot />,
          toolbarIconActive: <MotionCopilotActive />,
          eagerName: SIDEBAR_PLUGINS.motionCopilot.name,
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [
    iconscoutPluginVisibility,
    duplicatorPluginVisibility,
    promptVectorPluginVisibility,
    kineticTypographyPluginVisibility,
    motionCopilotPluginVisibility,
    isUserFetched,
  ]);

  return (
    <>
      <CreatorWindow />
      <PreventPinchZoom>
        <CreatorDocumentHead />
        <CreatorCursor />
        <FullScreenLoading />
        {forbiddenUser && <CreatorLock />}
        {!forbiddenUser && <DefaultCreator />}
      </PreventPinchZoom>
    </>
  );
};
