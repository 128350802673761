/**
 * Copyright 2024 Design Barn Inc.
 */

import clsx from 'clsx';
import type { FC, HTMLAttributes } from 'react';
import React from 'react';

interface DividerProps extends HTMLAttributes<HTMLDivElement> {}

export const Divider: FC<DividerProps> = ({ className = '', ...restProps }) => (
  <div className={clsx('h-px bg-[#283137]', className)} {...restProps} />
);
