/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import { shallow } from 'zustand/shallow';

import { RectangleRoundnessField } from './field/RectangleRoundnessField';
import { ShapePositionField } from './field/ShapePositionField';
import { ShapeSizeField } from './field/ShapeSizeField';
import { ShapeConvertToPathButton } from './ShapeConvertToPathButton';

import { getCurrentShape } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const RectangleProperty: React.FC = () => {
  const [, selectedNodesInfo, getNodeByIdOnly] = useCreatorStore(
    (state) => [state.toolkit.currentFrame, state.ui.selectedNodesInfo, state.toolkit.getNodeByIdOnly],
    shallow,
  );

  const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId ?? '');
  const currentShape = getCurrentShape(node);
  const { roundness } = currentShape.rect;

  const { position, size } = currentShape;

  return (
    <>
      <ShapePositionField position={position} positionIsAnimated={currentShape.positionIsAnimated} />
      <ShapeSizeField size={size} sizeIsAnimated={currentShape.sizeIsAnimated} />

      <div className="mr-[132px] mt-2 flex">
        <RectangleRoundnessField
          size={size}
          roundness={roundness}
          roundnessIsAnimated={currentShape.rect.roundnessIsAnimated}
        />
      </div>

      <ShapeConvertToPathButton />
    </>
  );
};
