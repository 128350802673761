/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/naming-convention */
import { getEventStoreInstance } from '@lottiefiles/event-store';

import { version as clientVersion } from '../../package.json';

import type {
  EventAction,
  AssetSource,
  EventType,
  FileType,
  ClickSource,
  PluginType,
  OnboardingPopoverAction,
  OnboardingPopoverSource,
  SegmentSource,
  SessionSource,
} from '~/data/eventStore';

export interface ClientPayload {
  name: string;
  version: string;
}

export interface EventStorePayload {
  event_type: EventType;
  parameters:
    | EventStoreMenuSelect
    | EventStoreFileDownload
    | EventStoreAssetUpload
    | EventStoreKeyframeAdd
    | EventStoreOpenedPlugin
    | EventStoreOboardingPopoverAction
    | EventStoreSegmentAdd
    | EventStoreSessionStart
    // empty object
    | Record<string, never>;
}

export const GenericPayload: ClientPayload = {
  name: 'creator',
  version: clientVersion,
};

export interface EventStoreMenuSelect {
  action: EventAction;
}

export interface EventStoreFileDownload {
  file?: {
    'animated-properties'?: string;
    'canvas-size'?: string;
    'frame-rate'?: string;
    'playback-duration'?: string;
  };
  type: FileType;
}

export interface EventStoreAssetUpload {
  'file-type': FileType;
  'file-url'?: string;
  'search-tag'?: string;
  slug?: string;
  source: AssetSource;
}

export interface EventStoreKeyframeAdd {
  source: ClickSource;
}

export interface EventStoreSegmentAdd {
  source: SegmentSource;
}

export interface EventStoreSessionStart {
  source: SessionSource;
}

export interface EventStoreOpenedPlugin {
  type: PluginType;
}

export interface EventStoreOboardingPopoverAction {
  action: OnboardingPopoverAction;
  'current-step'?: number;
  source?: OnboardingPopoverSource;
}

export const fireEvent = (eventParams: EventStorePayload): void => {
  const eventStore = getEventStoreInstance();

  eventStore.sendEvents(
    eventParams.event_type,
    // Jitsu Payload
    { client: GenericPayload, ...eventParams },
    // Amplitude Payload
    { name: eventParams.event_type, eventProperties: { ...eventParams.parameters } },
  );
};
