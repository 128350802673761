/**
 * Copyright 2022 Design Barn Inc.
 */

import { LayerType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { ViewportConfig } from '../config';
import { initViewport } from '../main';
import type { Viewport } from '../viewport';

import { GuidesTooltip } from './GuidesTooltip';
import { SceneLabel } from './SceneLabel';

import { EmptyCanvasMenu, ShapeLayerMenuCanvas, NestedSceneCanvasMenu, GuidesMenu, RulersMenu } from '~/features/menu';
// eslint-disable-next-line no-restricted-imports
import { MENU_HEIGHT, MENU_HEIGHT_OFFSET } from '~/features/menu/constant';
import useOpenable from '~/hooks/useOpenable';
import { canvasMap } from '~/lib/canvas';
import { GradientControlsTooltip } from '~/lib/threejs/GradientControls/ToolTips';
import { useCreatorStore } from '~/store';

interface ICanvasContextMenu {
  closeContextMenu(): void;
  coord: { x: number; y: number };
}

const CanvasContextMenu: React.FC<ICanvasContextMenu> = React.memo(
  ({ closeContextMenu, coord }: ICanvasContextMenu) => {
    const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;
    const selectedGuide = useCreatorStore.getState().canvas.guides.selectedGuide;

    const selectedNodeType = canvasMap.get(selectedNodesInfo[0]?.nodeId as string)?.layerType;

    const isEmpty = !selectedNodeType;
    const isLayer = selectedNodeType === 'SHAPE';
    const isNestedLayer = selectedNodeType === LayerType.PRECOMPOSITION;

    const windowHeight = useCreatorStore.getState().ui.window.height as number;

    if (isLayer && Number(coord.y) + MENU_HEIGHT.CanvasShapeLayerMenuCanvas > windowHeight) {
      coord.y = windowHeight - MENU_HEIGHT.CanvasShapeLayerMenuCanvas - MENU_HEIGHT_OFFSET;
    } else if (isEmpty && Number(coord.y) + MENU_HEIGHT.CanvasEmptyCanvasMenu > windowHeight) {
      coord.y = windowHeight - MENU_HEIGHT.CanvasEmptyCanvasMenu - MENU_HEIGHT_OFFSET;
    } else if (isNestedLayer && Number(coord.y) + MENU_HEIGHT.CanvasNestedSceneCanvasMenu > windowHeight) {
      coord.y = windowHeight - MENU_HEIGHT.CanvasNestedSceneCanvasMenu - MENU_HEIGHT_OFFSET;
    }

    if (selectedGuide) {
      return <GuidesMenu onClose={closeContextMenu} coord={coord} selectedGuide={selectedGuide} />;
    }

    return (
      <>
        {isEmpty && <EmptyCanvasMenu onClose={closeContextMenu} coord={coord} />}
        {isLayer && <ShapeLayerMenuCanvas onClose={closeContextMenu} coord={coord} />}
        {isNestedLayer && <NestedSceneCanvasMenu onClose={closeContextMenu} coord={coord} />}
      </>
    );
  },
);

const commonClassNames = 'absolute border-solid border-[#333C45] bg-[#192023]';

export const Canvas: React.FC = () => {
  const [, setViewport] = useState<Viewport>();
  const artboardCanvasRef = useRef<HTMLCanvasElement>(null);
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);
  const horizontalRulerRef = useRef<HTMLCanvasElement>(null);
  const verticalRulerRef = useRef<HTMLCanvasElement>(null);
  const snappedLinesRef = useRef<HTMLCanvasElement>(null);

  const [disableInteractions, rulersEnabled, guidesEnabled] = useCreatorStore(
    (state) => [state.canvas.disableInteractions, state.canvas.rulers.enabled, state.canvas.guides.enabled],
    shallow,
  );

  const { close: closeContextMenu, isOpen: isContextMenuOpen, open: openContextMenu } = useOpenable(false);
  const {
    close: closeRulersContextMenu,
    isOpen: isRulersContextMenuOpen,
    open: openRulersContextMenu,
  } = useOpenable(false);
  const [coord, setCoord] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (
      artboardCanvasRef.current &&
      overlayCanvasRef.current &&
      horizontalRulerRef.current &&
      verticalRulerRef.current &&
      snappedLinesRef.current
    ) {
      const vp = initViewport({
        artboard: artboardCanvasRef.current,
        horizontalRuler: horizontalRulerRef.current,
        overlay: overlayCanvasRef.current,
        verticalRuler: verticalRulerRef.current,
      });

      setViewport(vp);
    }
  }, []);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      event.preventDefault();
      setCoord({ x: event.nativeEvent.clientX, y: event.nativeEvent.clientY });
      openContextMenu();
    },
    [openContextMenu],
  );

  const handleRulersContextMenu = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      event.preventDefault();
      setCoord({ x: event.nativeEvent.clientX, y: event.nativeEvent.clientY });
      openRulersContextMenu();
    },
    [openRulersContextMenu],
  );

  return (
    <div
      id="artboard-container"
      className={clsx('relative flex flex-1 overflow-hidden', { 'pointer-events-none': disableInteractions })}
    >
      <canvas ref={artboardCanvasRef} id="artboard-canvas" className="h-full w-full" />
      <SceneLabel />
      <canvas
        ref={overlayCanvasRef}
        id="overlay-canvas"
        className="absolute top-0 h-full w-full"
        onContextMenu={handleContextMenu}
        onPointerDown={closeContextMenu}
      />
      <canvas ref={snappedLinesRef} id="snapped-lines" className="pointer-events-none absolute top-0 h-full w-full" />
      <canvas
        ref={horizontalRulerRef}
        style={{ height: ViewportConfig.RulerSize }}
        className={clsx(
          commonClassNames,
          'top-0 w-full border-b',
          { hidden: !rulersEnabled },
          { 'cursor-ns-resize': guidesEnabled },
        )}
        onContextMenu={handleRulersContextMenu}
      />
      <canvas
        ref={verticalRulerRef}
        style={{ width: ViewportConfig.RulerSize }}
        className={clsx(
          commonClassNames,
          'left-0 h-full w-6 border-r',
          { hidden: !rulersEnabled },
          { 'cursor-ew-resize': guidesEnabled },
        )}
        onContextMenu={handleRulersContextMenu}
      />
      <div
        className={clsx(commonClassNames, 'top-0 z-[2] border-b border-r', { hidden: !rulersEnabled })}
        style={{ width: ViewportConfig.RulerSize, height: ViewportConfig.RulerSize }}
      />
      {isContextMenuOpen && <CanvasContextMenu closeContextMenu={closeContextMenu} coord={coord} />}
      {isRulersContextMenuOpen && <RulersMenu coord={coord} onClose={closeRulersContextMenu} />}
      <GradientControlsTooltip />
      <GuidesTooltip />
    </div>
  );
};
