/**
 * Copyright 2024 Design Barn Inc.
 */

import { cubicBezierDis, getValueFromTexture } from '../bezier/bezier';

// referenced from https://www.shadertoy.com/view/7ltBzj

export const gradientCompoundStrokeFragment = `
varying vec2 vCoord;
uniform sampler2D uBezierPoints;
uniform sampler2D uBezierSegmentCounts;
uniform vec3 uColor;
uniform int uBezierCount;
uniform int uTotalSegments;
uniform float uOpacity;
uniform float uStrokeWidth;
uniform bool uIsClosed;

uniform bool uIsRadial;
uniform sampler2D uColorStops;
uniform int uColorStopsCount;
uniform sampler2D uColorStopOffsets;
uniform vec2 uStart;
uniform vec2 uEnd;
uniform float uHighlightAngle;
uniform float uHighlightLength;

${getValueFromTexture}
${cubicBezierDis} 
void main() {
  gl_FragColor = vec4(1.0, 1.0, 1.0, 0.0);
  int bezierTotalCount = 0;

  for (int i = 0; i < uBezierCount; i++) {
    // retrieve the segment count of individual bezier inside a compound bezier.
    // only red channel of the texture has the information about bezier segment count.
    int bezierSegmentCount = int(getValueFromTexture(uBezierSegmentCounts, vec2(uBezierCount, 1), vec2(i, 0)).r);
    if (bezierSegmentCount == 0) break;
    for (int j = 0; j < bezierSegmentCount; j++) {
      vec3 P0 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 0)).rgb;
      vec3 P1 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 1)).rgb;
      vec3 P2 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 2)).rgb;
      vec3 P3 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 3)).rgb;

      float dis = cubic_bezier_dis(vCoord, P0.xy, P1.xy, P2.xy, P3.xy);
      if (dis < uStrokeWidth / 2.0) {
        if (uIsRadial) {
          vec4 radialColor = getRadialColor();
          gl_FragColor = vec4(radialColor.rgb, radialColor.a * uOpacity);
        } else {
          vec4 gradientColor = getGradientColor();
          gl_FragColor = vec4(gradientColor.rgb, gradientColor.a * uOpacity);
        }
      }
    }
    bezierTotalCount += bezierSegmentCount;
  }
}

`;
