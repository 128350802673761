/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AnimatedPropertyJSON } from '@lottiefiles/toolkit-js';

export interface AnimatedInputProp {
  animatedProp: AnimatedPropertyJSON;
  id: string;
  parentId?: string | undefined;
  parentType?: string | undefined;
  type: string;
}

export const styleClass = {
  label:
    'number-input-timeline px-0.5 h-4 w-16 rounded border-[0.5px] border-transparent bg-gray-700 focus-within:border-gray-600',
  input: 'number-input bg-gray-700 pl-1 text-left text-[10px] font-normal leading-[10px] focus:outline-none',
  span: 'flex items-center justify-center text-gray-500',
};

export const AnimatedType: Record<string, string> = {
  a: 'Anchor Point',
  p: 'Position',
  s: 'Scale',
  r: 'Rotation',
  o: 'Opacity',
  sk: 'Skew',
  sa: 'Skew Axis',

  // TODO: Temporary hardcode Edit Path
  sh: 'Edit Path',
  cl: 'Fill',
  sc: 'Color',

  // stroke color and opacity
  scO: 'Color',
  sw: 'Stroke Width',
  nt: 'Points',
  ir: 'Inner Radius',
  or: 'Outer Radius',
  is: 'Inner Roundness',
  os: 'Outer Roundness',
  rd: 'Roundness',
  sz: 'Size',

  // trim
  to: 'Trim Offset',
  ts: 'Trim Start',
  te: 'Trim End',

  // gradient
  g: 'Color',
  gs: 'Start',
  ge: 'End',
  hl: 'Highlight length',
  ha: 'Highlight angle',
};
