/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

export const AnchorPointTopLeft: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.576172 6.33398C0.576172 3.02028 3.26246 0.333984 6.57617 0.333984H24.5762V24.334H0.576172V6.33398Z"
        fill="#2B343B"
      />
      <path
        d="M18.5784 6.33398H6.57617L6.57617 18.334"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});

export const AnchorPointTop: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.576172" y="0.333984" width="24" height="24" fill="#2B343B" />
      <path
        d="M18.5762 6.33398L12.5762 6.33398M6.57617 6.33398L12.5762 6.33398M12.5762 6.33398L12.5762 18.3362"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});

export const AnchorPointTopRight: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.576172 0.333984H18.5762C21.8899 0.333984 24.5762 3.02028 24.5762 6.33398V24.334H0.576172V0.333984Z"
        fill="#2B343B"
      />
      <path
        d="M18.5762 18.3362L18.5762 6.33398L6.57617 6.33398"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});

export const AnchorPointLeft: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.576172" y="0.333984" width="24" height="24" fill="#2B343B" />
      <path
        d="M6.57617 6.33398L6.57617 12.334M6.57617 18.334L6.57617 12.334M6.57617 12.334H18.5784"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});

export const AnchorPointCenter: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.576172" y="0.333984" width="24" height="24" fill="#2B343B" />
      <path
        d="M12.5762 6.33398L12.5762 12.3351M12.5762 18.3362L12.5762 12.3351M12.5762 12.3351L18.5762 12.3351M12.5762 12.3351L6.57617 12.3351"
        // stroke="#0FCCCE"
        stroke="current"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});

export const AnchorPointRight: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.576172" y="0.333984" width="24" height="24" fill="#2B343B" />
      <path
        d="M18.5762 6.33398L18.5762 12.3351M18.5762 18.3362L18.5762 12.3351M18.5762 12.3351L6.57617 12.3351"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});

export const AnchorPointBottomLeft: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.576172 18.334C0.576172 21.6477 3.26246 24.334 6.57617 24.334H24.5762V0.333984H0.576172V18.334Z"
        fill="#2B343B"
      />
      <path
        d="M18.5784 18.334H6.57617L6.57617 6.33398"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});

export const AnchorPointBottom: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" transform="matrix(1 0 0 -1 0.576172 24.334)" fill="#2B343B" />
      <path
        d="M18.5762 18.334L12.5762 18.334M6.57617 18.334L12.5762 18.334M12.5762 18.334L12.5762 6.33174"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});

export const AnchorPointBottomRight: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.576172 24.334H18.5762C21.8899 24.334 24.5762 21.6477 24.5762 18.334V0.333984H0.576172V24.334Z"
        fill="#2B343B"
      />
      <path
        d="M18.5762 6.33174L18.5762 18.334L6.57617 18.334"
        stroke="#606F7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      />
    </svg>
  );
});
