/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Quaternion } from 'three';
import { Matrix4, Vector3 } from 'three';

import type { CMesh, CObject3D } from '../../types/object';
import { degToRad } from '../utils/transform';

import { rotationAxis } from '~/lib/threejs/TransformControls';

export interface TransformProperty {
  anchorX: number;
  anchorY: number;
  opacity: number;
  positionX: number;
  positionY: number;
  rotation: number;
  scaleX: number;
  scaleY: number;
  skew: number;
  skewAngle: number;
}

export enum AnimatedProperty {
  Anchor = 'a',
  Color = 'cl',
  Gradient = 'g',
  Opacity = 'o',
  Position = 'p',
  Rotation = 'r',
  Scale = 's',
}
// obj - your object (THREE.Object3D or derived)
// point - the point of rotation (THREE.Vector3)
// axis - the axis of rotation (normalized THREE.Vector3)
// theta - radian value of rotation
// pointIsWorld - boolean indicating the point is in world coordinates (default = false)

export function rotateAboutPoint(
  obj: CObject3D | CMesh,
  point: Vector3,
  axis: Vector3,
  theta: number,
  pointIsWorld: boolean,
  startPosition: Vector3 = obj.position,
  startQuaternion: Quaternion = obj.quaternion,
): void {
  obj.position.copy(startPosition);

  if (pointIsWorld) {
    // compensate for world coordinate
    obj.parent?.localToWorld(obj.position);
  }
  // remove the offset
  obj.position.sub(point);
  // rotate the POSITION
  obj.position.applyAxisAngle(axis, theta);
  // re-add the offset
  obj.position.add(point);

  if (pointIsWorld) {
    // undo world coordinates compensation
    obj.parent?.worldToLocal(obj.position);
  }

  obj.quaternion.copy(startQuaternion);
  // rotate the OBJECT

  obj.rotateOnAxis(axis, theta);
}

export function scaleAboutPoint(
  obj: CObject3D,
  point: Vector3,
  scale: Vector3,
  pointIsWorld: boolean,
  startPosition: Vector3 = obj.position,
): void {
  if (pointIsWorld) {
    // compensate for world coordinate
    obj.parent?.localToWorld(obj.position);
  }
  obj.position.copy(startPosition);
  // remove the offset
  obj.position.sub(point);
  obj.position.multiply(obj.parentScale);
  // rotate the POSITION
  obj.position.multiply(scale).applyAxisAngle(rotationAxis, -obj.rotation.z);

  // re-add the offset
  obj.position.add(point);
  obj.scale.copy(scale);
  if (pointIsWorld) {
    // undo world coordinates compensation
    obj.parent?.worldToLocal(obj.position);
  }
}

export const getTransformMatrix = (from: Matrix4, to: Matrix4): Matrix4 => {
  const offsetMatrix = new Matrix4().multiplyMatrices(from.invert(), to);

  return offsetMatrix;
};

export const getTransform = (object: CObject3D, transformSettings: TransformProperty): void => {
  const {
    anchorX,
    anchorY,
    opacity,
    positionX,
    positionY,
    rotation,
    scaleX,
    scaleY,
    // skew,
    // skewAngle,
  } = transformSettings;

  object.position.x = positionX - anchorX * scaleX;
  object.position.y = positionY - anchorY * scaleY;
  object.toolkitPosition.x = positionX;
  object.toolkitPosition.y = positionY;
  object.toolkitAnchorPosition.x = anchorX;
  object.toolkitAnchorPosition.y = anchorY;
  object.scale.x = scaleX;
  object.scale.y = scaleY;

  rotateAboutPoint(object, new Vector3(positionX, positionY, 0), rotationAxis, -degToRad(rotation), true);
  object.toolkitRotation = rotation;
  object.opacity *= opacity;

  object.updateMatrixWorld();
  // const skewMatrix = new Matrix4();

  /* skew matrix
            | 1    Syx  Szx  Tx |
            |                   |
            | Sxy  1    Szy  Ty |
    SkewM = |                   |
            | Sxz  Syz  1    Tz |
            |                   |
            | 0    0    0    1  |
            |                   |

        we assume Szx = Sxz = Syz = Szy = 0

      Steps to apply Skew + Skew angle.
       - what is skew angle:
         Direction at which skew is applied, in degrees (0 skews along the X axis, 90 along the Y axis)

      1. Transform the coordinate using Affine Transformation
          Sa = Skew Angle

              | cos(Sa) -sin(Sa)  0    0 |
              |                          |
              | sin(Sa) cos(Sa)   0    0 |
       preM = |                          |
              | 0         0       1    0 |
              |                          |
              | 0         0       0    1 |
              |                          |

      2. Apply Skew Matrix
      3. Transform the coordinate back to the origin.

              | cos(Sa) sin(Sa)   0    0 |
              |                          |
              | -sin(Sa) cos(Sa)  0    0 |
      postM = |                          |
              | 0         0       1    0 |
              |                          |
              | 0         0       0    1 |
              |                          |

        Final Matrix = preM * SkewM * postM

    P.S. Unlike Object3D, geometry does not have matrix property and it has only applyMatrix function property.
        Thus we can't just replace the transformation matrix.
        if we have already applied M1, for the next transform we should revert the previous transformation by multiplying inverse(M1) and then apply M2.

        In matrix expression: GeometryMatrix *= inverse(M1) * M2;

    */
  // const Syx = tan(degToRad(skew));
  // const SkewAngle = degToRad(skewAngle);

  // const Sxy = 0;
  // const Szx = 0;
  // const Sxz = 0;
  // const Syz = 0;
  // const Szy = 0;

  // const newAnchor = new Vector3(anchorX, anchorY, 0).applyQuaternion(quat);
  // const Tx = newAnchor.x;
  // const Ty = newAnchor.y;

  // object.toolkitAnchorPosition.x = Tx;
  // object.toolkitAnchorPosition.y = Ty;

  // skewMatrix.set(1, Syx, Szx, -Tx * scaleX, Sxy, 1, Szy, -Ty * scaleY, Sxz, Syz, 1, 0, 0, 0, 0, 1);
  // let finalMatrix = skewMatrix;

  // // affine transform is applied only when skew angle is not 0
  // if (SkewAngle !== 0) {
  //   const preMatrix = new Matrix4();
  //   const postMatrix = new Matrix4();

  //   preMatrix.set(cos(SkewAngle), -sin(SkewAngle), 0, 0, sin(SkewAngle), cos(SkewAngle), 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  //   postMatrix.set(cos(SkewAngle), sin(SkewAngle), 0, 0, -sin(SkewAngle), cos(SkewAngle), 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  //   finalMatrix = new Matrix4().copy(preMatrix).multiply(skewMatrix).multiply(postMatrix);
  // }

  // object.applyMatrix4(finalMatrix);
};
