/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable no-secrets/no-secrets */
import type React from 'react';
import { useEffect, useState } from 'react';
import type { Keys, HotkeyCallback } from 'react-hotkeys-hook';
import { useHotkeys } from 'react-hotkeys-hook';

import { growthbook } from './GrowthBookProvider';

import { RightSideBarSetting } from '~/components/Layout/Property';
import { GB_CONSTANT } from '~/config/growthbook';
import { ToolType } from '~/data/constant';
import { EasingType } from '~/data/eventStore';
import { toggleKeyframe } from '~/features/property-panel';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { saveToWorkSpace, saveToCreator } from '~/lib/function/menu';
import { AnimatedType } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { ShapeTypes, CANVAS_ANCHOR_GRID_PREFIX, CANVAS_DEFAULT_TAB_ID, GlobalModalConstant } from '~/store/constant';
import { AlignPivotDirection } from '~/store/uiSlice';

const setCurrentTool = useCreatorStore.getState().ui.setCurrentTool;
const setLastSelectedShape = useCreatorStore.getState().ui.setLastSelectedShape;
const setTestAnimationOpen = useCreatorStore.getState().ui.setTestAnimationOpen;
const setZoomPercentage = useCreatorStore.getState().ui.setZoomPercentage;
const setSaveFromHotKey = useCreatorStore.getState().ui.setSaveFromHotKey;
const setRightSideBarPanel = useCreatorStore.getState().ui.setRightSideBarPanel;
const setAnchorPointsActive = useCreatorStore.getState().ui.setAnchorPointsActive;
const setAnchorPointByCursor = useCreatorStore.getState().ui.setAnchorPointByCursor;
const setIsUIMinimized = useCreatorStore.getState().ui.setIsUIMinimized;

// Define the hotkey using window format. Use key(hotkey) to determine the correct format
// use pipe (|) to split combos where comma is used.
export enum HotKey {
  ANCHOR_TOOL = 'y',
  BACKTICK = '`',
  BREAK_SCENE = 'mod+alt+c',
  CENTER_ANCHOR_GRID = 'mod+home',
  COPY = 'mod+c',
  CREATE_SCENE = 'mod+shift+c',
  CUT = 'mod+x',
  DELETE = 'backspace, del, delete',
  DESELECT_ALL = 'esc',
  DUPLICATE = 'mod+d',
  EASING_TOGGLE = 'e,f9',
  ESCAPE = 'escape',
  GOTO_FIRST_FRAME = 'home,',
  GOTO_LAST_FRAME = 'end',
  GOTO_NEXT_FRAME = 'pagedown',
  GOTO_NEXT_KEYFRAME = 'l',
  GOTO_PREVIOUS_FRAME = 'pageup',
  GOTO_PREVIOUS_KEYFRAME = 'j',
  GO_BACK_TEN_FRAMES = 'shift+pageup',
  GO_FORWARD_TEN_FRAMES = 'shift+pagedown',
  HAND = 'h',
  IN_POINT_TO_PLAYHEAD = '[',
  KEYFRAME_MOVE_LEFT = 'alt+left',
  KEYFRAME_MOVE_LEFT_TENFRAMES = 'shift+alt+left',
  KEYFRAME_MOVE_RIGHT = 'alt+right',
  KEYFRAME_MOVE_RIGHT_TENFRAMES = 'shift+alt+right',
  LAYER_DOWN = 'cmd+[',
  LAYER_FOCUS_UNFOCUS = 'mod+f',
  LAYER_LOCK_UNLOCK = 'mod+l',
  LAYER_MOST_DOWN = 'mod+[',
  LAYER_MOST_UP = 'mod+]',
  LAYER_SHOW_HIDE = 'mod+shift+h',
  LAYER_UP = 'cmd+]',
  LINE = 'l',
  MINIMIZE_UI = 'mod+.',
  MOVE = 'v',
  NUDGE_DOWN = 'down,shift+down',
  NUDGE_LEFT = 'left,shift+left',
  NUDGE_RIGHT = 'right,shift+right',
  NUDGE_TRACK_BACKWARD = 'alt+pageup',
  NUDGE_TRACK_BACKWARD_TEN_FRAME = 'shift+alt+pageup',
  NUDGE_TRACK_FORWARD = 'alt+pagedown',
  NUDGE_TRACK_FORWARD_TEN_FRAME = 'shift+alt+pagedown',
  NUDGE_UP = 'up,shift+up',
  OPEN_SEGMENTS = 'alt+s',
  OUT_POINT_TO_PLAYHEAD = ']',
  PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT = 'mod+v',
  PASTE_WITH_CLONED_ASSETS = 'alt+mod+v',
  PEN = 'g',
  REDO = 'mod+y,mod+shift+z',
  RENAME = 'enter',
  SAVE_TO_CREATOR = 'alt+mod+s',
  SAVE_TO_WORKFLOW = 'shift+mod+s',
  SELECT_ALL_LAYERS = 'mod+a',
  SELECT_ANIMATION_PRESETS = 'mod+2',
  SELECT_PROPERTY_TAB = 'mod+1',
  SHAPE_TOOL_ELLIPSE = 'alt+o',
  SHAPE_TOOL_RECTANGLE = 'alt+r',
  SHOW_ANIMATED_PROPERTIES = 'shift+k',
  SPACE = 'space',
  TOGGLE_ANIMATE_ALL_PROPERTIES = 'k',
  TOGGLE_GRID = 'alt+shift+quote',
  TOGGLE_GUIDES = 'alt+shift+semicolon',
  TOGGLE_LAYER_EXPANSION = 'u',
  TOGGLE_OPACITY_KEYFRAME = 't',
  TOGGLE_POSITON_KEYFRAME = 'p',
  TOGGLE_PRECOMP_SOURCE_NAME = 'alt+n',
  TOGGLE_PREVIEW = 'mod+p',
  TOGGLE_ROTATION_KEYFRAME = 'r',
  TOGGLE_RULERS = 'alt+shift+r',
  TOGGLE_SCALE_KEYFRAME = 's',
  // `[` and `]` aren't working but bracketleft and bracketright are working
  TRIM_INPOINT_TO_PLAYHEAD = 'alt+bracketleft',
  TRIM_OUTPOINT_TO_PLAYHEAD = 'alt+bracketright',
  TRIM_WORKAREA_END_TO_PLAYHEAD = 'N',
  TRIM_WORKAREA_START_TO_PLAYHEAD = 'B',
  UNDO = 'mod+z',
  ZOOMIN = 'mod+=',

  // ZOOMOUT moved to PreventPinchZoom.tsx
  ZOOMTOFIT = 'mod+0',
  ZOOM_TO_100_PERCENT = 'shift+1',

  ZOOM_TO_BOUNDING_BOX = 'f',
}

const getNudgeParams = (event: KeyboardEvent): { offsetBy?: number } => {
  if (event.shiftKey) {
    return { offsetBy: 10 };
  }

  return {};
};

const setAsTemporaryToolOnHold = (previousTool: ToolType, holdDuration: number = 250): void => {
  const keyDownTime = Date.now();

  const handleKeyUp = (): void => {
    const keyUpTime = Date.now();

    if (keyUpTime - keyDownTime > holdDuration) {
      setCurrentTool(previousTool);
    }
  };

  document.addEventListener('keyup', handleKeyUp, { once: true });
};
const useDetectDoublePress = (
  keys: Keys,
  onSinglePress: HotkeyCallback,
  onDoublePress: HotkeyCallback,
  threshold: number = 350,
): void => {
  const [lastKeyPressTime, setLastKeyPressTime] = useState<number>(0);
  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  useHotkeys(
    keys,
    (...args) => {
      const currentTime: number = Date.now();

      if (currentTime - lastKeyPressTime < threshold && timeoutId !== null) {
        // Double press detected
        clearTimeout(timeoutId);
        setTimeoutId(null);
        onDoublePress(...args);
      } else {
        // Schedule single press if no subsequent press occurs within the threshold
        const newTimeoutId: number = window.setTimeout(() => {
          onSinglePress(...args);
          // Clear the timeout ID once the single press action is executed
          setTimeoutId(null);
        }, threshold);

        setTimeoutId(newTimeoutId);
      }
      setLastKeyPressTime(currentTime);
    },
    { keydown: true, keyup: false },
  );

  // Cleanup timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
};

export const HotkeyListener: React.FC = () => {
  // IMPORTANT: use event emitter or zustand action to trigger action
  // Don't call perform state/props change here to prevent rerendering as this is place at the top of the component tree
  // Changing state will cause rerender of the whole component tree

  useHotkeys(HotKey.UNDO, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.UI_UNDO);
  });
  useHotkeys(HotKey.REDO, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.UI_REDO);
  });
  useHotkeys(HotKey.DELETE, () => emitter.emit(EmitterEvent.UI_DELETE));
  useHotkeys(HotKey.DESELECT_ALL, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_DESELECT_ALL);
  });
  useHotkeys(HotKey.MINIMIZE_UI, () => {
    const showOnboarding = useCreatorStore.getState().ui.onboarding.showOnboarding;
    const globalModal = useCreatorStore.getState().ui.globalModal;

    const isOnboarding = globalModal?.state === GlobalModalConstant.Onboarding || showOnboarding;

    if (!isOnboarding) {
      const isUIMinimized = useCreatorStore.getState().ui.isUIMinimized;

      setIsUIMinimized(!isUIMinimized);
      // send mousedown to close all context menu
      document.dispatchEvent(new MouseEvent('mousedown'));
    }
  });
  useHotkeys(HotKey.HAND, () => setCurrentTool(ToolType.Hand));
  useHotkeys(HotKey.LINE, () => emitter.emit(EmitterEvent.LINE_CREATED));
  useHotkeys(HotKey.MOVE, () => setCurrentTool(ToolType.Move));
  useHotkeys(HotKey.TOGGLE_POSITON_KEYFRAME, () => toggleKeyframe(AnimatedType.POSITION));
  useHotkeys(HotKey.TOGGLE_ROTATION_KEYFRAME, () => toggleKeyframe(AnimatedType.ROTATION));
  useHotkeys(HotKey.TOGGLE_OPACITY_KEYFRAME, () => toggleKeyframe(AnimatedType.OPACITY));
  useHotkeys(HotKey.TOGGLE_SCALE_KEYFRAME, () => toggleKeyframe(AnimatedType.SCALE));
  useHotkeys(HotKey.TOGGLE_PRECOMP_SOURCE_NAME, () => emitter.emit(EmitterEvent.TIMELINE_TOGGLE_PRECOMP_SOURCE_NAME));

  useDetectDoublePress(
    HotKey.ESCAPE,
    () => {
      const currentTool = useCreatorStore.getState().ui.currentTool;

      if (currentTool === ToolType.Pen) {
        emitter.emit(EmitterEvent.CANVAS_PENCONTROL_DRAW_CONTINUE, { skipUpdate: true });
      } else {
        setCurrentTool(ToolType.Move);
      }
    },
    () => {
      emitter.emit(EmitterEvent.CANVAS_DISABLE_PENCONTROL, { skipUpdate: true });
      setCurrentTool(ToolType.Move);
    },
  );

  useHotkeys(HotKey.ZOOMIN, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_ZOOM_IN, { skipUpdate: true });
  });

  useHotkeys(HotKey.ZOOMTOFIT, () => emitter.emit(EmitterEvent.CANVAS_ZOOM_TO_FIT));

  useHotkeys(HotKey.ZOOM_TO_100_PERCENT, () => {
    setZoomPercentage(100);
  });

  useHotkeys(HotKey.ZOOM_TO_BOUNDING_BOX, () => {
    emitter.emit(EmitterEvent.CANVAS_ZOOM_FIT_TO_BOUNDING_BOX);
  });

  useHotkeys(HotKey.SAVE_TO_WORKFLOW, (event: KeyboardEvent) => {
    event.preventDefault();
    saveToWorkSpace();
  });

  useHotkeys(HotKey.SAVE_TO_CREATOR, (event: KeyboardEvent) => {
    event.preventDefault();

    const isDraft = useCreatorStore.getState().project.info.isDraft;

    // project.setMadeFirstChange
    const setMadeFirstChange = useCreatorStore.getState().project.setMadeFirstChange;

    setSaveFromHotKey(true);

    if (!isDraft) {
      setMadeFirstChange(true);
    }
    saveToCreator({ saveWithoutPopup: true });
  });

  useHotkeys(HotKey.SELECT_ALL_LAYERS, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_SELECT_ALL_LAYERS);
  });

  useHotkeys(HotKey.SELECT_PROPERTY_TAB, (event: KeyboardEvent) => {
    event.preventDefault();
    setRightSideBarPanel(RightSideBarSetting.Property);
  });

  useHotkeys(HotKey.SELECT_ANIMATION_PRESETS, (event: KeyboardEvent) => {
    event.preventDefault();
    setRightSideBarPanel(RightSideBarSetting.AnimationPresets);
  });

  useHotkeys(HotKey.SHAPE_TOOL_ELLIPSE, (event: KeyboardEvent) => {
    event.preventDefault();
    setCurrentTool(ToolType.Shape);
    setLastSelectedShape(ShapeTypes.Ellipse);
  });

  useHotkeys(HotKey.SHAPE_TOOL_RECTANGLE, (event: KeyboardEvent) => {
    event.preventDefault();
    setCurrentTool(ToolType.Shape);
    setLastSelectedShape(ShapeTypes.Rectangle);
  });

  useHotkeys(HotKey.SPACE, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_TOGGLE_PLAY);
  });

  useHotkeys(HotKey.GOTO_FIRST_FRAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_FIRST_FRAME);
  });

  useHotkeys(HotKey.GOTO_LAST_FRAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_LAST_FRAME);
  });

  useHotkeys(HotKey.GOTO_NEXT_FRAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_NEXT_FRAME);
  });

  useHotkeys(HotKey.GOTO_PREVIOUS_FRAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_PREVIOUS_FRAME);
  });

  useHotkeys(HotKey.GO_BACK_TEN_FRAMES, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GO_BACK_TEN_FRAME);
  });

  useHotkeys(HotKey.GO_FORWARD_TEN_FRAMES, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GO_FORWARD_TEN_FRAME);
  });
  useHotkeys(HotKey.ANCHOR_TOOL, (event: KeyboardEvent) => {
    event.preventDefault();

    const currentTool = useCreatorStore.getState().ui.currentTool;

    if (currentTool !== ToolType.Anchor) {
      setAsTemporaryToolOnHold(ToolType.Move);
    }

    setCurrentTool(ToolType.Anchor);
  });
  useHotkeys(HotKey.CUT, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_CUT);
  });
  useHotkeys(HotKey.COPY, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_COPY);
  });
  useHotkeys(HotKey.DUPLICATE, (event: KeyboardEvent) => {
    // ctrl + D opens a browser window to edit the bookmarks by default.
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_DUPLICATE);
  });
  useHotkeys(HotKey.BREAK_SCENE, () => {
    emitter.emit(EmitterEvent.TIMELINE_PRECOMP_BREAK_SCENE);
  });
  useHotkeys(HotKey.CREATE_SCENE, () => {
    emitter.emit(EmitterEvent.TIMELINE_PRECOMP_CREATE_SCENE);
  });
  useHotkeys(HotKey.PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT, () => {
    emitter.emit(EmitterEvent.CANVAS_PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT);
  });

  useHotkeys(HotKey.PASTE_WITH_CLONED_ASSETS, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_PASTE_WITH_CLONED_ASSETS);
  });

  useHotkeys(HotKey.PEN, (event: KeyboardEvent) => {
    event.preventDefault();
    setCurrentTool(useCreatorStore.getState().ui.lastSelectedPenTool);
  });

  useHotkeys(HotKey.NUDGE_DOWN, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_SELECTION_MOVE_DOWN, getNudgeParams(event));
  });
  useHotkeys(HotKey.NUDGE_LEFT, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_SELECTION_MOVE_LEFT, getNudgeParams(event));
  });
  useHotkeys(HotKey.NUDGE_RIGHT, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_SELECTION_MOVE_RIGHT, getNudgeParams(event));
  });
  useHotkeys(HotKey.NUDGE_UP, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.CANVAS_SELECTION_MOVE_UP, getNudgeParams(event));
  });
  useHotkeys(HotKey.NUDGE_TRACK_FORWARD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TRACK_MOVE_FORWARD);
  });
  useHotkeys(HotKey.NUDGE_TRACK_BACKWARD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TRACK_MOVE_BACKWARD);
  });
  useHotkeys(HotKey.NUDGE_TRACK_FORWARD_TEN_FRAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TRACK_MOVE_FORWARD_TEN_FRAME);
  });
  useHotkeys(HotKey.NUDGE_TRACK_BACKWARD_TEN_FRAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TRACK_MOVE_BACKWARD_TEN_FRAME);
  });
  useHotkeys(HotKey.LAYER_DOWN, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_DOWN);
  });
  useHotkeys(HotKey.LAYER_FOCUS_UNFOCUS, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_FOCUS_UNFOCUS);
  });
  useHotkeys(HotKey.LAYER_LOCK_UNLOCK, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_LOCK_UNLOCK);
  });
  useHotkeys(HotKey.LAYER_SHOW_HIDE, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_SHOW_HIDE);
  });
  useHotkeys(HotKey.LAYER_UP, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_UP);
  });
  useHotkeys(HotKey.LAYER_MOST_DOWN, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_MOST_DOWN);
  });
  useHotkeys(HotKey.LAYER_MOST_UP, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_MOST_UP);
  });
  useHotkeys(HotKey.SHOW_ANIMATED_PROPERTIES, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_SHOW_ANIMATED_PROPERTIES);
  });
  useDetectDoublePress(
    HotKey.TOGGLE_LAYER_EXPANSION,
    (event: KeyboardEvent) => {
      event.preventDefault();
      emitter.emit(EmitterEvent.TIMELINE_SHOW_ALL_KEYFRAMES);
    },
    (event: KeyboardEvent) => {
      event.preventDefault();
      emitter.emit(EmitterEvent.TIMELINE_TOGGLE_CHILD_LAYERS_EXPANSION);
    },
  );
  useHotkeys(HotKey.TOGGLE_ANIMATE_ALL_PROPERTIES, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES);
  });
  useHotkeys(HotKey.RENAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_RENAME_LAYER_START);
  });

  useHotkeys(HotKey.BACKTICK, (event: KeyboardEvent) => {
    event.preventDefault();

    emitter.emit(EmitterEvent.TIMELINE_TOGGLE_VISIBLE);
  });

  useHotkeys(HotKey.TOGGLE_PREVIEW, (event: KeyboardEvent) => {
    event.preventDefault();
    setTestAnimationOpen(!useCreatorStore.getState().ui.testAnimationOpen);
  });

  useHotkeys(HotKey.TRIM_INPOINT_TO_PLAYHEAD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_TRIM_INPOINT_TO_PLAYHEAD);
  });

  useHotkeys(HotKey.TRIM_OUTPOINT_TO_PLAYHEAD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_TRIM_OUTPOINT_TO_PLAYHEAD);
  });

  useHotkeys(HotKey.TRIM_WORKAREA_START_TO_PLAYHEAD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_TRIM_WORKAREA_START_TO_PLAYHEAD);
  });

  useHotkeys(HotKey.TRIM_WORKAREA_END_TO_PLAYHEAD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_TRIM_WORKAREA_END_TO_PLAYHEAD);
  });

  useHotkeys(HotKey.TRIM_OUTPOINT_TO_PLAYHEAD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_TRIM_OUTPOINT_TO_PLAYHEAD);
  });

  useHotkeys(HotKey.KEYFRAME_MOVE_LEFT_TENFRAMES, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_KEYFRAME_MOVE_LEFT, getNudgeParams(event));
  });

  useHotkeys(HotKey.KEYFRAME_MOVE_RIGHT, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_KEYFRAME_MOVE_RIGHT, getNudgeParams(event));
  });

  useHotkeys(HotKey.KEYFRAME_MOVE_RIGHT_TENFRAMES, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_KEYFRAME_MOVE_RIGHT, getNudgeParams(event));
  });

  useHotkeys(HotKey.KEYFRAME_MOVE_LEFT, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TIMELINE_KEYFRAME_MOVE_LEFT, getNudgeParams(event));
  });

  useHotkeys(HotKey.EASING_TOGGLE, (event: KeyboardEvent) => {
    event.preventDefault();

    const selectedKeyFramesEasing = useCreatorStore.getState().timeline.selectedKeyFramesEasing;

    let overallEasingState = null as EasingType | null;

    selectedKeyFramesEasing.forEach((easing) => {
      if (
        (easing.inTangent === EasingType.Linear || easing.inTangent === null) &&
        easing.outTangent === EasingType.Linear &&
        (overallEasingState === null || overallEasingState === EasingType.Linear)
      ) {
        overallEasingState = EasingType.Linear;
      } else if (
        (easing.inTangent === EasingType.Smooth || easing.inTangent === null) &&
        easing.outTangent === EasingType.Smooth &&
        (overallEasingState === null || overallEasingState === EasingType.Smooth)
      ) {
        overallEasingState = EasingType.Smooth;
      } else {
        overallEasingState = EasingType.Mixed;
      }
    });

    if (overallEasingState === EasingType.Linear) {
      emitter.emit(EmitterEvent.EASING_SMOOTH_BOTH);
    } else if (overallEasingState === EasingType.Smooth) {
      emitter.emit(EmitterEvent.EASING_LINEAR_BOTH);
    } else {
      emitter.emit(EmitterEvent.EASING_SMOOTH_BOTH);
    }
  });

  useHotkeys(HotKey.IN_POINT_TO_PLAYHEAD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TRACK_IN_TO_PLAYHEAD);
  });

  useHotkeys(HotKey.OPEN_SEGMENTS, (event: KeyboardEvent) => {
    event.preventDefault();
    useCreatorStore
      .getState()
      .timeline.setIsSegmentsDialogOpen(!useCreatorStore.getState().timeline.isSegmentsDialogOpen);
  });

  useHotkeys(HotKey.OUT_POINT_TO_PLAYHEAD, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.TRACK_OUT_TO_PLAYHEAD);
  });

  useHotkeys(HotKey.CENTER_ANCHOR_GRID, (event: KeyboardEvent) => {
    event.preventDefault();
    const canvasId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
    const sceneId = `${CANVAS_ANCHOR_GRID_PREFIX}${canvasId || CANVAS_DEFAULT_TAB_ID}`;

    setAnchorPointsActive(sceneId as string, {
      [AlignPivotDirection.Center]: true,
    });
    setAnchorPointByCursor(false);
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.CANVAS_TRANSFORMCONTROL_PIVOT_UPDATED,
      data: { type: AlignPivotDirection.Center },
    });
  });

  useHotkeys(HotKey.TOGGLE_RULERS, (ev: KeyboardEvent) => {
    if (growthbook.isOn(GB_CONSTANT.SHOW_RULERS_GUIDES_GRID)) {
      ev.preventDefault();
      emitter.emit(EmitterEvent.CANVAS_TOGGLE_RULERS);
    }
  });

  useHotkeys(HotKey.TOGGLE_GUIDES, (ev: KeyboardEvent) => {
    if (growthbook.isOn(GB_CONSTANT.SHOW_RULERS_GUIDES_GRID)) {
      ev.preventDefault();
      emitter.emit(EmitterEvent.CANVAS_TOGGLE_GUIDES);
    }
  });

  useHotkeys(HotKey.TOGGLE_GRID, (ev: KeyboardEvent) => {
    if (growthbook.isOn(GB_CONSTANT.SHOW_RULERS_GUIDES_GRID)) {
      ev.preventDefault();
      emitter.emit(EmitterEvent.CANVAS_TOGGLE_GRID_VISIBILITY);
    }
  });

  useHotkeys(HotKey.GOTO_NEXT_KEYFRAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_NEXT_KEYFRAME);
  });

  useHotkeys(HotKey.GOTO_PREVIOUS_KEYFRAME, (event: KeyboardEvent) => {
    event.preventDefault();
    emitter.emit(EmitterEvent.PLAYBACK_GOTO_PREVIOUS_KEYFRAME);
  });

  return null;
};
