/**
 * Copyright 2024 Design Barn Inc.
 */

import type { GroupShape } from '@lottiefiles/lottie-js';
import type {
  AnimatedAngleProperty,
  AnimatedPercentageProperty,
  AVLayer,
  KeyFrameJSON,
  TrimShape,
} from '@lottiefiles/toolkit-js';
import { Percentage, PropertyType, ShapeType } from '@lottiefiles/toolkit-js';
import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { Minus, Plus, EyeOpen, InvisibleEye, ScaleX } from '~/assets/icons';
import { Checkbox } from '~/components/Elements/Checkbox';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input';
import { UserDataMap } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import {
  mapAnimatedProperty,
  removeKeyFrame,
  setAnimatedTrimEnd,
  setAnimatedTrimOffset,
  setAnimatedTrimStart,
} from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface Props {}

export const TrimPathProperty: React.FC<Props> = () => {
  const [selectedNodesInfo, addToSelectedNodes, getNodeByIdOnly, currentFrame, addAnimatedValue, setStaticValue] =
    useCreatorStore(
      (state) => [
        state.ui.selectedNodesInfo,
        state.ui.addToSelectedNodes,
        state.toolkit.getNodeByIdOnly,
        state.toolkit.currentFrame,
        state.toolkit.addAnimatedValue,
        state.toolkit.setStaticValue,
      ],
      shallow,
    );

  const nodes = selectedNodesInfo.map((node) => getNodeByIdOnly(node.nodeId) as AVLayer | GroupShape);

  const [trimNode, setTrimNode] = React.useState<TrimShape | null>(
    nodes[0]?.parent.shapes.find((shape: { type: ShapeType }) => shape.type === ShapeType.TRIM) as TrimShape | null,
  );

  useEffect(() => {
    setTrimNode(
      nodes[0]?.parent.shapes.find((shape: { type: ShapeType }) => shape.type === ShapeType.TRIM) as TrimShape | null,
    );
  }, [nodes]);
  const [visible, setVisible] = React.useState(true);
  const [reversed, setReversed] = React.useState(Boolean(trimNode?.data.get(UserDataMap.TrimOffsetReversed)));

  const hasTrimStartKeyFrame = Boolean(
    trimNode?.trimStart.animatedKeyFrames.some((keyFrame) => keyFrame.frame === currentFrame),
  );
  const hasTrimEndKeyFrame = Boolean(
    trimNode?.trimEnd.animatedKeyFrames.some((keyFrame) => keyFrame.frame === currentFrame),
  );
  const hasTrimOffsetKeyFrame = Boolean(
    trimNode?.trimOffset.animatedKeyFrames.some((keyFrame) => keyFrame.frame === currentFrame),
  );

  if (nodes.length !== 1) return null;

  const onAddTrimNode = (): void => {
    const parent = nodes[0]?.parent;

    if (parent) {
      const tn = parent.createTrimShape() as TrimShape;

      tn.setName('Trim Path');
      tn.trimStart.setStaticValue(new Percentage(0));
      setTrimNode(tn);
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_TRIM_START_UPDATED);
    }
  };

  const onRemoveTrimNode = (): void => {
    trimNode?.removeFromGraph();
    setTrimNode(null);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_TRIM_START_UPDATED);
  };

  if (!trimNode)
    return (
      <div className="flex justify-between px-4 pb-3 pr-3 pt-4">
        <div className="text-xs text-gray-300">Trim path</div>
        <div className="flex">
          <div className="pointer-events-auto mx-2 cursor-pointer" onClick={onAddTrimNode}>
            <Plus className=" h-4 w-4 stroke-gray-300 hover:rounded hover:bg-gray-700 hover:stroke-white" />
          </div>
        </div>
      </div>
    );
  const { trimEnd, trimOffset, trimStart } = trimNode;

  const eyeIcon = visible ? (
    <EyeOpen className="h-4 w-4 fill-white" />
  ) : (
    <InvisibleEye className={`h-4 w-4 fill-white`} />
  );

  const toggleVisibility = (): void => {
    setVisible(!visible);
  };

  const onChangeTrimStart = (result: NumberResult): void => {
    setAnimatedTrimStart(trimNode, [result.value]);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_TRIM_START_UPDATED);
  };

  const onChangeTrimEnd = (result: NumberResult): void => {
    setAnimatedTrimEnd(trimNode, [result.value]);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_TRIM_END_UPDATED);
  };

  const onChangeTrimOffset = (result: NumberResult): void => {
    setAnimatedTrimOffset(trimNode, [reversed ? 360 - result.value : result.value]);
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_TRIM_OFFSET_UPDATED);
  };

  const reverse = (): void => {
    trimNode.setData(UserDataMap.TrimOffsetReversed, !reversed);
    setReversed(!reversed);
  };

  const handleKeyFrameClick = (type: PropertyType): void => {
    const mappedAnimatedProperty = mapAnimatedProperty[type];
    let animatedProp: AnimatedPercentageProperty | AnimatedAngleProperty = trimNode.trimStart;

    if (type === PropertyType.TRIM_END) animatedProp = trimNode.trimEnd;
    else if (type === PropertyType.TRIM_OFFSET) animatedProp = trimNode.trimOffset;

    if (animatedProp.state.isAtKeyFrame) {
      const currentKeyFrame = animatedProp.keyFrames.find((keyFrame) => {
        return keyFrame.frame === currentFrame || keyFrame.frame === Math.round(currentFrame);
      }) as KeyFrameJSON | null;

      if (currentKeyFrame && mappedAnimatedProperty) {
        const currentValue = mappedAnimatedProperty.getAnimatedValue(trimNode);

        removeKeyFrame(currentKeyFrame.frameId as string);
        if (animatedProp.keyFrames.length === 1) {
          setStaticValue(mappedAnimatedProperty.animatedProperty, currentValue, trimNode.nodeId);
        }
      }
    } else if (mappedAnimatedProperty) {
      addAnimatedValue(
        mappedAnimatedProperty.animatedProperty,
        mappedAnimatedProperty.getAnimatedValue(trimNode),
        trimNode.nodeId,
      );
    }
    addToSelectedNodes(
      selectedNodesInfo.map((info) => info.nodeId),
      true,
    );
    emitter.emit(EmitterEvent.ANIMATED_KEYFRAME_UPDATED);
  };

  return (
    <>
      <div className="flex justify-between px-4 pb-3 pr-3 pt-4">
        <div className="text-xs text-gray-300">Trim path</div>
        <div className="flex">
          <button className="mx-2" onClick={toggleVisibility}>
            {eyeIcon}
          </button>
          <div className="pointer-events-auto mx-2 cursor-pointer" onClick={onRemoveTrimNode}>
            <Minus className=" h-4 w-4 stroke-gray-300 hover:rounded hover:bg-gray-700 hover:stroke-white" />
          </div>
        </div>
      </div>
      {visible && (
        <>
          <div className="ml-4 mt-2 flex w-44">
            <div className="mr-2 w-1/2">
              <div className="mb-2 text-xs text-gray-300">Trim start</div>
              <NumberInput
                name={'trim-start'}
                min={0}
                max={100}
                append="%"
                value={trimStart.value.value}
                onChange={onChangeTrimStart}
                onKeyframeClick={() => handleKeyFrameClick(PropertyType.TRIM_START)}
                showKeyframe
                hasKeyframe={hasTrimStartKeyFrame}
                isChannelAnimated={trimStart.isAnimated}
                precision={4}
                label={<ScaleX className="h-3 w-3" />}
              />
            </div>
            <div className="border-gray-800"></div>
            <div className="w-[84px]">
              <div className="mb-2 text-xs text-gray-300">Trim end</div>
              <NumberInput
                name={'trim-end'}
                min={0}
                max={100}
                append="%"
                value={trimEnd.value.value}
                onChange={onChangeTrimEnd}
                onKeyframeClick={() => handleKeyFrameClick(PropertyType.TRIM_END)}
                showKeyframe
                hasKeyframe={hasTrimEndKeyFrame}
                isChannelAnimated={trimEnd.isAnimated}
                precision={4}
                label={<ScaleX className="h-3 w-3" />}
              />
            </div>
          </div>
          <div className="ml-4 mt-2 mb-4 flex w-44">
            <div className="mr-2 w-1/2">
              <div className="mb-2 text-xs text-gray-300">Trim offset</div>
              <NumberInput
                name={'trim-offset'}
                value={trimOffset.value.value}
                onChange={onChangeTrimOffset}
                onKeyframeClick={() => handleKeyFrameClick(PropertyType.TRIM_OFFSET)}
                append="°"
                showKeyframe
                hasKeyframe={hasTrimOffsetKeyFrame}
                isChannelAnimated={trimOffset.isAnimated}
                min={0}
                max={360}
                precision={4}
                label={<ScaleX className="h-3 w-3" />}
              />
            </div>
            <div className="mt-7 ml-1">
              <Checkbox childrenStyle="!pl-1" isChecked={reversed} onChange={reverse}>
                <div className="text-xs text-white">Reverse</div>
              </Checkbox>
            </div>
          </div>
        </>
      )}
    </>
  );
};
