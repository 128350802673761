/**
 * Copyright 2021 Design Barn Inc.
 */

import type { FillShape, GradientFillShape } from '@lottiefiles/toolkit-js';
import { GradientFillType, ShapeType } from '@lottiefiles/toolkit-js';
import { colord } from 'colord';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { AppearancePropertyOptions } from './AppearancePropertyOptions';

import { Opacity } from '~/assets/icons';
import { ColorInput } from '~/components/Elements/ColorInput/ColorInput';
import { NumberInput, defaultStyle } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { NumberInputPrecision } from '~/data/constant';
import type { CurrentGFillShape, CurrentFillShape } from '~/lib/toolkit';
import { AnimatedType, toggleAppearanceKeyframe, updateAppearance, updateColor } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface ShapeFillProps {
  ids: string[];
  showOpacity: boolean;
}

export const FillProperty: React.FC<ShapeFillProps> = ({ ids, showOpacity }) => {
  const [setAnimatedValue, getShape, currentFrame] = useCreatorStore(
    (state) => [state.toolkit.setAnimatedValue, state.toolkit.getShape, state.toolkit.currentFrame],
    shallow,
  );

  const shapes: Array<CurrentFillShape | CurrentGFillShape> = useMemo(() => {
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

    return ids.map((id) => {
      const shapeNode = getNodeByIdOnly(id) as FillShape | GradientFillShape;

      return getShape(shapeNode.type, id) as CurrentFillShape | CurrentGFillShape;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getShape, ids, currentFrame]);

  const shapeType = useMemo(() => {
    if (shapes.find((shape) => (shape as CurrentGFillShape).cssColor)) {
      return ShapeType.GRADIENT_FILL;
    }

    return ShapeType.FILL;
  }, [shapes]);

  const hasMixedColor = useMemo(() => {
    if (shapeType === ShapeType.GRADIENT_FILL) {
      const keys = new Set((shapes as CurrentGFillShape[]).map((shape) => shape.cssColor));

      return keys.size > 1;
    }

    const keys = new Set(
      (shapes as CurrentFillShape[]).map((shape) => `${shape.r}_${shape.g}_${shape.b}_${shape.opacity}`),
    );

    return keys.size > 1;
  }, [shapeType, shapes]);

  const hasMixedOpacity = useMemo(() => {
    const keys = new Set(shapes.map((shape) => shape.opacity));

    return keys.size > 1;
  }, [shapes]);

  const hasColorKeyframe = useMemo(() => {
    return shapes.every((shape) => Boolean(shape.colorCurrentKeyframe));
  }, [shapes]);

  const isColorAnimated = useMemo(() => {
    return shapes.every((shape) => Boolean(shape.colorIsAnimated));
  }, [shapes]);

  const hasOpacityKeyframe = useMemo(() => {
    return shapes.every((shape) => Boolean(shape.opacityCurrentKeyframe));
  }, [shapes]);

  const isOpacityAnimated = useMemo(() => {
    return shapes.every((shape) => Boolean(shape.opacityIsAnimated));
  }, [shapes]);

  const color = useMemo(() => {
    if (!hasMixedColor && shapes[0] && shapeType === ShapeType.FILL) {
      const { b, g, opacity, r } = shapes[0] as CurrentFillShape;

      return colord({ r, g, b, alpha: opacity / 100 })
        .toHex()
        .replace('#', '');
    }

    if (shapeType === ShapeType.GRADIENT_FILL) {
      return (
        (
          shapes.find(
            (sh) => sh.type === GradientFillType.LINEAR || sh.type === GradientFillType.RADIAL,
          ) as CurrentGFillShape | null
        )?.cssColor ?? 'ffffff'
      );
    }

    return 'ffffff';
  }, [hasMixedColor, shapes, shapeType]);

  const opacity = useMemo(() => {
    if (shapes[0]) {
      return shapes[0].opacity;
    }

    return 100;
  }, [shapes]);

  const handleKeyframeClick = useCallback(
    (type: AnimatedType) => {
      toggleAppearanceKeyframe(type, shapes);
    },
    [shapes],
  );

  const handleOnChangeOpacity = useCallback(
    (result: NumberResult) => {
      updateAppearance(setAnimatedValue, shapes, AnimatedType.FILL_OPACITY, result);
    },
    [setAnimatedValue, shapes],
  );

  const handleOnChangeColor = useCallback(
    (newColor: string) => {
      updateColor(setAnimatedValue, ids, AnimatedType.FILL_COLOR, newColor);
    },
    [setAnimatedValue, ids],
  );

  const [minValue, maxValue] = useMemo(() => {
    if (shapes.length && hasMixedOpacity) {
      const opacities = shapes.map((shape) => shape.opacity);

      const lowestOpacity = Math.min(...opacities);
      const highestOpacity = Math.max(...opacities);

      const min = -highestOpacity;
      const max = 100 + (100 - lowestOpacity);

      return [min, max];
    }

    return [0, 100];
  }, [shapes, hasMixedOpacity]);

  const isMixedGradients = useMemo(() => {
    if (shapeType !== ShapeType.GRADIENT_FILL) {
      return false;
    }

    if (shapes.length > 1) {
      const colors = shapes.map((shape) => (shape as CurrentGFillShape).cssColor);

      return new Set(colors).size > 1;
    }

    return false;
  }, [shapeType, shapes]);

  const message = useMemo(() => {
    if (shapeType === ShapeType.FILL) {
      return hasMixedColor ? 'Mixed' : null;
    }

    if (shapes.every((shape) => shape.type === GradientFillType.LINEAR)) {
      return isMixedGradients ? 'Mixed' : 'Linear';
    }

    if (shapes.every((shape) => shape.type === GradientFillType.RADIAL)) {
      return isMixedGradients ? 'Mixed' : 'Radial';
    }

    return 'Mixed';
  }, [hasMixedColor, isMixedGradients, shapeType, shapes]);

  const gradientShapes =
    shapeType === ShapeType.GRADIENT_FILL ? (shapes as Array<CurrentGFillShape | CurrentFillShape>) : null;

  return (
    <div className="mt-2 flex">
      <Tooltip content="Fill color">
        <div className="w-1/2">
          <ColorInput
            shapeType={shapeType}
            styleClass="w-[86px]"
            color={color}
            opacity={opacity}
            onChangeColor={handleOnChangeColor}
            showKeyframe
            hasKeyframe={hasColorKeyframe}
            onKeyframeClick={() =>
              handleKeyframeClick(
                gradientShapes && gradientShapes.length > 0 ? AnimatedType.GRADIENT : AnimatedType.FILL_COLOR,
              )
            }
            onChangeOpacity={(value: number) =>
              handleOnChangeOpacity({
                name: 'opacity',
                trueValue: value,
                value,
              })
            }
            message={message}
            mixedColors={hasMixedColor}
            isChannelAnimated={isColorAnimated}
            gradientShape={gradientShapes}
            selectedIds={ids}
            enableColorModeChange={true}
            disabled={Boolean(gradientShapes)}
          />
        </div>
      </Tooltip>
      {showOpacity && (
        <>
          <div className="my-[-1px] border border-gray-800"></div>
          <Tooltip content="Fill opacity">
            <div className="w-[86px]">
              <NumberInput
                styleClass={{ label: `${defaultStyle.label}` }}
                name="opacity"
                value={opacity as number}
                label={<Opacity className="h-4 w-4" />}
                min={minValue}
                max={maxValue}
                precision={NumberInputPrecision}
                onChange={handleOnChangeOpacity}
                onKeyframeClick={() =>
                  handleKeyframeClick(
                    gradientShapes && gradientShapes.length > 0
                      ? AnimatedType.GRADIENT_OPACITY
                      : AnimatedType.FILL_OPACITY,
                  )
                }
                hasKeyframe={hasOpacityKeyframe}
                append="%"
                showKeyframe
                message={hasMixedOpacity ? 'Mixed' : null}
                isChannelAnimated={isOpacityAnimated}
              />
            </div>
          </Tooltip>
          <AppearancePropertyOptions ids={ids} disabled={hasMixedColor} type={ShapeType.FILL} />
        </>
      )}
    </div>
  );
};
