/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  SceneJSON,
  RectangleShape,
  ShapeLayer,
  StarShape,
  FillShape,
  StrokeShape,
  StrokeDashType,
  BlendModeType,
  FillRuleType,
  Scene,
  ColorStopJSON,
  GradientFillShape,
  DagNode,
  Shape,
  PrecompositionLayer,
  Layer,
} from '@lottiefiles/toolkit-js';
import { ShapeType, GroupShape, AVLayer, LayerType } from '@lottiefiles/toolkit-js';
import { isArray, last, throttle } from 'lodash-es';
import type { StateCreator } from 'zustand';

import { clearSelectionState, getAnimatedNode, getCurrentNode, getCurrentShapeNode } from './storeUtil';

import type { StoreSlice } from '.';
import { getLayerMap } from '~/features/timeline';
import { layerMap, setLayerUI } from '~/lib/layer';
import type {
  ColorValue,
  CurrentTransformProperty,
  AnimatedType,
  CurrentFillShape,
  CurrentStrokeShape,
  CurrentGFillShape,
} from '~/lib/toolkit';
import {
  getAppearanceListFromNodes,
  setAnimatedFillColor,
  setAnimatedFillOpacity,
  setStaticPivot,
  getAnimatedFunction,
  defaultCurrentTransformProperty,
  toolkit,
  getCurrentTransform,
  getCurrentFillShape,
  getFillShape,
  getGradientFillShape,
  setShapePosition,
  setRectangleRoundeness,
  setPolystarRotation,
  setPolystarInnerRadius,
  setPolystarOuterRadius,
  setPolystarInnerRoundness,
  setPolystarOuterRoundness,
  setPolystarPoints,
  setStrokeColor,
  setShapeSize,
  setStrokeLineJoin,
  setStrokeLineCap,
  setStrokeMiter,
  setStrokeOpacity,
  addStrokeDash,
  setStrokeWidth,
  getStrokeShape,
  getCurrentStrokeShape,
  getCurrentGFillShape,
  removeStrokeDash,
  setStrokeDash,
  removeNode,
  setBlendMode,
  setFillRule,
  getAppearanceList,
  updateLayerHighlight,
  setGradient,
  setGradientOpacity,
  setGradientPoint,
  stateHistory,
} from '~/lib/toolkit';
import { PropertyPanelType } from '~/store/constant';
import { reorderShapes } from '~/utils/multi-hierarchy-utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialSceneJSON: any = {
  assets: [],
  layers: [],
  markers: [],
  properties: {
    sz: {
      w: 512,
      h: 512,
    },
  },
  timeline: {
    duration: 5,
    properties: {
      fr: 30,
      ip: 0,
      op: 150,
    },
  },
};

export interface ToolkitSlice {
  toolkit: {
    addAnimatedValue(type: AnimatedType, value: unknown[], id?: string): void;
    addStrokeDash(type: StrokeDashType): void;
    allLayersCount: number;
    currentFrame: number;
    currentShapeNode: DagNode | AVLayer | Shape | null;
    currentTransform: CurrentTransformProperty;
    currentTransforms: CurrentTransformProperty[];
    getCurrentPropertyNode(): StrokeShape | FillShape | GradientFillShape | null;
    getNodeByIdOnly(id: string): DagNode | null;
    getShape(type: ShapeType, id?: string): unknown | null;
    json?: SceneJSON;
    moveToDifferentLevelLayer(newIndex: number, activeIds: string[], parentId: string): void;
    removeAnimated(id: string, type: string): void;
    removeSelectedNode(id?: string): void;
    removeStrokeDash(id: string): void;
    sceneIndex: number;
    selectedPrecompositionId: string | null;
    selectedPrecompositionJson: SceneJSON | null;
    setAnimatedValue(type: AnimatedType, value: unknown[], id?: string): void;
    setBlendMode(value: BlendModeType): void;
    setCurrentFrame(index: number): void;
    setCurrentTransform: (transform: CurrentTransformProperty) => void;
    setFillColor(color: ColorValue): void;
    setFillOpacity(opacity: number): void;
    setFillRule(value: FillRuleType): void;
    setGradientColor(data: ColorStopJSON[]): void;
    setGradientFillOpacity(opacity: number): void;
    setGradientPoint(name: string, value: number): void;
    setJSON(json: SceneJSON): void;
    setLayerDrawOrder(newIndex: number, activeIds: string[]): void;
    setLayerShapeIndex(newIndex: number, activeIds: string[], parentId: string): void;
    setPolystarInnerRadius(radius: number): void;
    setPolystarInnerRoundness(r: number): void;
    setPolystarOuterRadius(radius: number): void;
    setPolystarOuterRoundness(r: number): void;
    setPolystarPoints(points: number): void;
    setPolystarRotation(deg: number): void;
    setPolystarVariableIsAnimated(variableType: string, isAnimated: boolean): void;
    setRectangleRoundness(r: number): void;
    setRectangleRoundnessIsAnimated(isAnimated: boolean): void;
    setSceneIndex(index: number): void;
    setSelectedPrecompositionId: (id: string | null) => void;
    setSelectedPrecompositionJson: (json: SceneJSON | null) => void;
    setShapePosition(x: number, y: number): void;
    setShapePositionIsAnimated(isAnimated: boolean): void;
    setShapeSize(w: number, y: number): void;
    setShapeSizeIsAnimated(isAnimated: boolean): void;
    setStaticPivot(x: number, y: number, id?: string): void;
    setStaticValue(type: AnimatedType, value: unknown[], id?: string): void;
    setStrokeColor(color: ColorValue): void;
    setStrokeDash(id: string, value: number): void;
    setStrokeLineCap(linCap: number): void;
    setStrokeLineJoin(lineJoin: number): void;
    setStrokeMiter(miter: number): void;
    setStrokeOpacity(opacity: number): void;
    setStrokeWidth(width: number): void;
    updateCurrentTransforms(): void;
  };
}

export const createToolkitSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  ToolkitSlice
> = (set, get) => ({
  toolkit: {
    sceneIndex: 0,
    allLayersCount: 0,
    json: initialSceneJSON,
    currentTransform: { ...defaultCurrentTransformProperty },
    currentTransforms: [],
    currentFrame: 0,
    selectedPrecompositionId: null,
    selectedPrecompositionJson: null,
    currentShapeNode: null,
    setSceneIndex: (index: number) => {
      return set((state) => {
        state.toolkit.sceneIndex = index;
      });
    },
    setCurrentFrame: throttle((index: number) => {
      return set((state) => {
        state.toolkit.currentFrame = index;
      });
    }, 30),
    getShape: (type: ShapeType, id?: string): CurrentFillShape | CurrentStrokeShape | CurrentGFillShape | null => {
      const nodeId = id ?? getCurrentNode(get)?.nodeId;

      if (!nodeId) return null;

      const getNodeByIdOnly = get().toolkit.getNodeByIdOnly;

      if (type === ShapeType.FILL) {
        return getCurrentFillShape(getNodeByIdOnly(nodeId) as FillShape);
      }
      if (type === ShapeType.STROKE) {
        return getCurrentStrokeShape(getNodeByIdOnly(nodeId) as StrokeShape);
      }
      if (type === ShapeType.GRADIENT_FILL) {
        return getCurrentGFillShape(getNodeByIdOnly(nodeId) as GradientFillShape);
      }
      if (type === ShapeType.GRADIENT_STROKE) {
        // TODO: add gradient stroke support
      }

      return null;
    },
    getCurrentPropertyNode: (): StrokeShape | FillShape | GradientFillShape | null => {
      const currentProperty = get().ui.currentPropertyPanel;

      let node = null;

      if (currentProperty === PropertyPanelType.Stroke) {
        node = getStrokeShape(getCurrentNode(get) as ShapeLayer);
      } else if (currentProperty === PropertyPanelType.Fill) {
        node = getFillShape(getCurrentNode(get) as ShapeLayer);
      } else if (currentProperty === PropertyPanelType.GradientFill) {
        node = getGradientFillShape(getCurrentNode(get) as ShapeLayer);
      }

      return node;
    },
    updateCurrentTransforms: () => {
      const selectedNodesInfo = get().ui.selectedNodesInfo;

      const currentNode = getCurrentNode(get);

      const currentTransform = getCurrentTransform(currentNode);
      const currentShapeNode = getCurrentShapeNode(get) as ShapeLayer;

      set((state) => {
        state.toolkit.currentTransform = currentTransform;
        state.toolkit.currentShapeNode = currentShapeNode;
      });
      if (selectedNodesInfo.length > 1) {
        const getNodeByIdOnly = get().toolkit.getNodeByIdOnly;

        set((state) => {
          state.toolkit.currentTransforms = selectedNodesInfo.map((nodeInfo) => {
            const node = getNodeByIdOnly(nodeInfo.nodeId);

            return getCurrentTransform(node);
          });
        });
      }
    },
    setJSON: (json: SceneJSON) => {
      const selectedNodesInfo = get().ui.selectedNodesInfo;
      const getNodeByIdOnly = get().toolkit.getNodeByIdOnly;

      let sceneJSON = json;

      if (get().toolkit.selectedPrecompositionId && get().toolkit.selectedPrecompositionJson) {
        sceneJSON = get().toolkit.selectedPrecompositionJson as SceneJSON;
      }

      const newLayerMap = getLayerMap(sceneJSON as SceneJSON);

      if (selectedNodesInfo.length > 1) {
        const nodes = selectedNodesInfo
          .map((nodeInfo) => getNodeByIdOnly(nodeInfo.nodeId))
          .filter(Boolean) as DagNode[];

        const [appearanceList, newAdded] = getAppearanceListFromNodes(nodes, layerMap, newLayerMap);

        set((state) => {
          state.toolkit.allLayersCount = sceneJSON.allLayers.length;
          state.toolkit.json = json;

          setLayerUI(newLayerMap);
          state.ui.appearanceList = appearanceList;

          newAdded.forEach((id) => {
            updateLayerHighlight(state, id as string);
          });
        });
      } else {
        const node = getCurrentNode(get);

        const [appearanceList, newAdded] = getAppearanceList(node, layerMap, newLayerMap);

        set((state) => {
          state.toolkit.allLayersCount = sceneJSON.allLayers.length;
          state.toolkit.json = json;

          setLayerUI(newLayerMap);
          state.ui.appearanceList = appearanceList;

          if (newAdded.length > 0) {
            const id = newAdded[0];

            updateLayerHighlight(state, id as string);
          }
        });
      }

      get().toolkit.updateCurrentTransforms();
    },
    setShapePositionIsAnimated: (isAnimated: boolean) => {
      const node = getCurrentShapeNode(get) as ShapeLayer;

      node.position.setIsAnimated(isAnimated);
      get().ui.addToSelectedNodes([]);
    },
    setShapePosition: (x: number, y: number) => {
      const node = getCurrentShapeNode(get) as ShapeLayer;

      setShapePosition(node, [x, y]);
      get().ui.addToSelectedNodes([]);
    },
    setStaticPivot: (x: number, y: number, id?: string) => {
      const node = id ? get().toolkit.getNodeByIdOnly(id) : getCurrentNode(get);

      if (node instanceof GroupShape || node instanceof AVLayer) setStaticPivot(node, [x, y]);
    },
    setStaticValue: (type: AnimatedType, value: unknown[], id?: string) => {
      const animated = getAnimatedFunction[type];
      let node: FillShape | AVLayer | null = null;

      if (id) {
        // For select specific animation from multiple appearances using id
        node = get().toolkit.getNodeByIdOnly(id) as FillShape | AVLayer | null;
      } else {
        // For select "current/individual" on single
        node = getAnimatedNode(animated.nodeType, get);
      }

      if (!node) {
        return;
      }

      if (animated.hasKeyframes(node)) {
        return;
      } else {
        animated.enableAnimation(node, false);
      }

      animated.setStatic(node, value);
    },
    setShapeSizeIsAnimated: (isAnimated: boolean) => {
      const node = getCurrentShapeNode(get) as RectangleShape;

      node.size.setIsAnimated(isAnimated);
      get().ui.addToSelectedNodes([]);
    },
    setShapeSize: (width: number, height: number) => {
      const node = getCurrentShapeNode(get) as RectangleShape;

      setShapeSize(node, [width, height]);
      get().ui.addToSelectedNodes([]);
    },
    setRectangleRoundnessIsAnimated: (isAnimated: boolean) => {
      const node = getCurrentShapeNode(get) as RectangleShape;

      node.roundness.setIsAnimated(isAnimated);
      get().ui.addToSelectedNodes([]);
    },
    setRectangleRoundness: (r: number) => {
      const node = getCurrentShapeNode(get) as RectangleShape;

      setRectangleRoundeness(node, r);
      get().ui.addToSelectedNodes([]);
    },
    setPolystarRotation: (deg: number) => {
      const node = getCurrentShapeNode(get) as StarShape;

      setPolystarRotation(node, deg);
      get().ui.addToSelectedNodes([]);
    },
    setPolystarInnerRadius: (radius: number) => {
      const node = getCurrentShapeNode(get) as StarShape;

      setPolystarInnerRadius(node, radius);
      get().ui.addToSelectedNodes([]);
    },
    setPolystarOuterRadius: (radius: number) => {
      const node = getCurrentShapeNode(get) as StarShape;

      setPolystarOuterRadius(node, radius);
      get().ui.addToSelectedNodes([]);
    },
    setPolystarInnerRoundness: (r: number) => {
      const node = getCurrentShapeNode(get) as StarShape;

      setPolystarInnerRoundness(node, r);
    },
    setPolystarOuterRoundness: (r: number) => {
      const node = getCurrentShapeNode(get) as StarShape;

      setPolystarOuterRoundness(node, r);
      get().ui.addToSelectedNodes([]);
    },
    setPolystarPoints: (points: number) => {
      const node = getCurrentShapeNode(get) as StarShape;

      setPolystarPoints(node, points);
      get().ui.addToSelectedNodes([]);
    },
    setPolystarVariableIsAnimated: (variableType: string, isAnimated: boolean) => {
      const node = getCurrentShapeNode(get) as StarShape;

      if (variableType === 'points') {
        node.numPoints.setIsAnimated(isAnimated);
      } else if (variableType === 'rotation') {
        node.rotation.setIsAnimated(isAnimated);
      } else if (variableType === 'outerRoundness') {
        node.outerRoundness.setIsAnimated(isAnimated);
      } else if (variableType === 'outerRadius') {
        node.outerRadius.setIsAnimated(isAnimated);
      } else if (variableType === 'innerRoundness') {
        node.innerRoundness.setIsAnimated(isAnimated);
      } else if (variableType === 'innerRadius') {
        node.innerRadius.setIsAnimated(isAnimated);
      }
      get().ui.addToSelectedNodes([]);
    },
    removeAnimated: (id: string, type: string) => {
      const node = get().toolkit.getNodeByIdOnly(id) as Layer | null;

      if (node && node.animatedProperties.length > 0) {
        const animated = node.animatedProperties.filter((anim) => anim.type === type);

        if (isArray(animated) && animated[0]) {
          animated[0].setIsAnimated(false);
        }
      }

      if (!get().ui.isUIMinimized) {
        const setTimelineVisible = get().timeline.setVisible;

        setTimelineVisible(true);
      }
    },
    addAnimatedValue: (type: AnimatedType, value: unknown[], id?: string) => {
      const animated = getAnimatedFunction[type];
      let node = null;

      if (id) {
        // For select specific animation from multiple appearances using id
        node = get().toolkit.getNodeByIdOnly(id);
      } else {
        // For select "current/individual" on single
        node = getAnimatedNode(animated.nodeType, get);
      }

      if (node) {
        animated.enableAnimation(node, true);
        animated.setAnimated(node, value);

        if (!get().ui.isUIMinimized) {
          const setTimelineVisible = get().timeline.setVisible;

          setTimelineVisible(true);
        }
      }
    },
    setAnimatedValue: (type: AnimatedType, value: unknown[], id?: string) => {
      const animated = getAnimatedFunction[type];

      let node: FillShape | AVLayer | null = null;

      if (id) {
        // For select specific animation from multiple appearances using id
        node = get().toolkit.getNodeByIdOnly(id) as FillShape | AVLayer | PrecompositionLayer | null;
      } else {
        // For select "current/individual" on single
        node = getAnimatedNode(animated.nodeType, get);
      }

      if (!node) {
        return;
      }

      if (node.animatableProperties.length > 0) {
        // Compare the set value with the current value, return if it is same
        if (animated.isSame(node, value)) return;

        // Check for animated properties
        const property = node.animatedProperties.find((prop) => prop.type === animated.key);

        if (property && property.isAnimated) {
          if (!get().ui.isUIMinimized) {
            const setTimelineVisible = get().timeline.setVisible;

            setTimelineVisible(true);
          }
          animated.setAnimated(node, value);
        } else {
          animated.setStatic(node, value);
        }
      } else {
        animated.setStatic(node, value);
      }
    },
    setCurrentTransform: (currentTransform: CurrentTransformProperty) => {
      set((state) => {
        state.toolkit.currentTransform = currentTransform;
      });
    },
    setFillColor: (color: ColorValue) => {
      const fl = getFillShape(getCurrentNode(get) as ShapeLayer);

      setAnimatedFillColor(fl, color);
      get().ui.addToSelectedNodes([]);
    },
    setFillOpacity: (opacity: number) => {
      const fl = getFillShape(getCurrentNode(get) as ShapeLayer);

      setAnimatedFillOpacity(fl, [opacity]);
      get().ui.addToSelectedNodes([]);
    },
    setStrokeColor: (color: ColorValue) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      setStrokeColor(st, color);
      get().ui.addToSelectedNodes([]);
    },
    setStrokeWidth: (width: number) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      setStrokeWidth(st, width);
      get().ui.addToSelectedNodes([]);
    },
    setStrokeLineJoin: (lineJoin: number) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      setStrokeLineJoin(st, lineJoin);
      get().ui.addToSelectedNodes([]);
    },
    setStrokeLineCap: (lineCap: number) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      setStrokeLineCap(st, lineCap);
      get().ui.addToSelectedNodes([]);
    },
    setStrokeMiter: (miter: number) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      setStrokeMiter(st, miter);
      get().ui.addToSelectedNodes([]);
    },
    setStrokeOpacity: (opacity: number) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      setStrokeOpacity(st, opacity);
      get().ui.addToSelectedNodes([]);
    },
    addStrokeDash: (name: StrokeDashType) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      addStrokeDash(st, name);
      get().ui.addToSelectedNodes([]);
    },
    removeStrokeDash: (id: string) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      removeStrokeDash(st, id);
      get().ui.addToSelectedNodes([]);
    },
    setStrokeDash: (id: string, value: number) => {
      const st = getStrokeShape(getCurrentNode(get) as ShapeLayer);

      setStrokeDash(st, { id, value });
      get().ui.addToSelectedNodes([]);
    },
    removeSelectedNode: (id?: string) => {
      const sceneIndex = get().toolkit.sceneIndex;

      const scene = toolkit.scenes[sceneIndex];

      if (scene) {
        // TODO: handle multi select
        const selectedId = id ? id : (get().ui.selectedNodesInfo[0]?.nodeId as string);

        removeNode(scene, selectedId);

        const parentId = last(layerMap.get(selectedId)?.parent);

        set((state) => {
          clearSelectionState(state);

          // Select parent layer after deletion
          if (parentId) {
            state.ui.addToSelectedNodes([parentId], true);

            updateLayerHighlight(state, parentId);
          }
        });
      }
    },
    setBlendMode: (value: BlendModeType) => {
      const node = get().toolkit.getCurrentPropertyNode();

      if (node) {
        setBlendMode(node, value);
        get().ui.addToSelectedNodes([]);
      }
    },
    setFillRule: (value: FillRuleType) => {
      const node = get().toolkit.getCurrentPropertyNode() as GradientFillShape | FillShape | null;

      if (node) {
        setFillRule(node, value);
        get().ui.addToSelectedNodes([]);
      }
    },
    setGradientColor: (data: ColorStopJSON[]) => {
      const node = getGradientFillShape(getCurrentNode(get) as ShapeLayer);

      setGradient(node, data);
      get().ui.addToSelectedNodes([]);
    },
    setGradientFillOpacity: (opacity: number) => {
      const gf = getGradientFillShape(getCurrentNode(get) as ShapeLayer);

      setGradientOpacity(gf, opacity);
      get().ui.addToSelectedNodes([]);
    },
    setGradientPoint: (name: string, value: number) => {
      const gf = getGradientFillShape(getCurrentNode(get) as ShapeLayer);

      setGradientPoint(gf, name, value);
      get().ui.addToSelectedNodes([]);
    },
    setSelectedPrecompositionId: (id: string | null) => {
      set((state) => {
        state.toolkit.selectedPrecompositionId = id;
        if (id) {
          const getNodeByIdOnly = get().toolkit.getNodeByIdOnly;

          const assetNode = getNodeByIdOnly(id);

          state.toolkit.selectedPrecompositionJson = structuredClone(assetNode?.state) as SceneJSON;
        } else {
          state.toolkit.selectedPrecompositionJson = null;
        }
      });
    },
    setSelectedPrecompositionJson: (json: SceneJSON | null) => {
      set((state) => {
        state.toolkit.selectedPrecompositionJson = structuredClone(json) as SceneJSON;
      });
    },
    moveToDifferentLevelLayer: (newIndex: number, activeIds: string[], parentId: string) => {
      const getNodeByIdOnly = get().toolkit.getNodeByIdOnly;
      const setLayerShapeIndex = get().toolkit.setLayerShapeIndex;

      stateHistory.beginAction();

      if (activeIds.length > 0) {
        const updatedParentLayer = getNodeByIdOnly(parentId) as ShapeLayer | null;

        if (updatedParentLayer) {
          activeIds.forEach((activeId: string) => {
            const activeNode = getNodeByIdOnly(activeId) as AVLayer;

            activeNode.setParent(updatedParentLayer);
          });
        }
      }

      setLayerShapeIndex(newIndex, activeIds, parentId);

      // after updated, it should reorder the children for correct toolkit/player rendering
      const parentNode = getNodeByIdOnly(parentId);

      if (parentNode) {
        reorderShapes(parentNode);
      }

      stateHistory.endAction();
    },
    setLayerShapeIndex: (newIndex: number, activeIds: string[], parentId: string) => {
      const getNodeByIdOnly = get().toolkit.getNodeByIdOnly;

      const updatedParentLayer = getNodeByIdOnly(parentId) as ShapeLayer | null;

      if (!updatedParentLayer) return;
      stateHistory.beginAction();

      // Sort all layers to the bottom first
      // To batch layers together if there are unselected layers between
      // And avoid counting the layers themselves within the draw order
      const totalShapes = updatedParentLayer.shapes.length as number;

      activeIds.forEach((activeId) => {
        const updatedShape = getNodeByIdOnly(activeId);

        updatedParentLayer.setShapeIndex(updatedShape as GroupShape, totalShapes);
      });

      activeIds.forEach((activeId, index) => {
        const updatedShape = getNodeByIdOnly(activeId);

        updatedParentLayer.setShapeIndex(updatedShape as GroupShape, newIndex + index);
      });

      stateHistory.endAction();
    },
    setLayerDrawOrder: (newIndex: number, activeIds: string[]) => {
      const sceneIndex = get().toolkit.sceneIndex;
      const toolkitScene = toolkit.scenes[sceneIndex] as Scene | null;
      const getNodeByIdOnly = get().toolkit.getNodeByIdOnly;

      if (toolkitScene) {
        stateHistory.beginAction();

        // Sort all layers to the bottom first
        // To batch layers together if there are unselected layers between
        // And avoid counting the layers themselves within the draw order
        const totalLayers = toolkitScene.allLayers.length;

        activeIds.forEach((activeId) => {
          const updatedRootShape = getNodeByIdOnly(activeId) as AVLayer | null;

          if (updatedRootShape) {
            if (updatedRootShape.type !== LayerType.SHAPE && updatedRootShape.type !== LayerType.PRECOMPOSITION) return;
            updatedRootShape.setDrawOrder(totalLayers);
          }
        });

        activeIds.forEach((activeId, index) => {
          const updatedRootShape = getNodeByIdOnly(activeId) as AVLayer | null;

          if (updatedRootShape) {
            if (updatedRootShape.type !== LayerType.SHAPE && updatedRootShape.type !== LayerType.PRECOMPOSITION) return;
            (updatedRootShape as AVLayer).setDrawOrder(newIndex + index);
          }
        });

        stateHistory.endAction();
      }
    },
    getNodeByIdOnly: (id: string): DagNode | null => {
      const node = toolkit.getNodeById(id);

      if (node) {
        return node;
      }

      const sceneIndex = get().toolkit.sceneIndex;
      const scene = toolkit.scenes[sceneIndex];

      if (!scene) {
        return null;
      }

      return scene.getNodeById(id);
    },
  },
});
