/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

export const MenuDivider: React.FC = () => (
  <div
    className="my-2 h-[1px] w-full bg-gray-700"
    style={{
      width: '100%',
    }}
  />
);
