/**
 * Copyright 2021 Design Barn Inc.
 */

import type { ColorStopJSON, GradientFillShape } from '@lottiefiles/toolkit-js';
import { GradientFillType, ShapeType } from '@lottiefiles/toolkit-js';
import GParser from 'gradient-parser';
import type { ColorStop } from 'gradient-parser';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { Opacity } from '~/assets/icons';
import { ColorInput } from '~/components/Elements/ColorInput/ColorInput';
import { NumberInput, defaultStyle } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { CurrentGFillShape } from '~/lib/toolkit';
import { AnimatedType, toggleAppearanceKeyframe, setAnimatedOpacity, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface GradientFillProp {
  ids: string[];
}

export interface GradientData {
  length: number;
  rgba: number[];
}

export const GradientFillProperty: React.FC<GradientFillProp> = ({ ids }) => {
  const [getShape, getNodeByIdOnly, selectedNodesInfo, currentFrame] = useCreatorStore(
    (state) => [
      state.toolkit.getShape,
      state.toolkit.getNodeByIdOnly,
      state.ui.selectedNodesInfo,
      state.toolkit.currentFrame,
    ],
    shallow,
  );

  const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId ?? '') as GradientFillShape | null;

  const shapes = useMemo(
    () => ids.map((id) => getShape(ShapeType.GRADIENT_FILL, id) as CurrentGFillShape),
    [ids, getShape],
  );

  const hasMixedColor = useMemo(() => {
    const keys = new Set(shapes.map((shape) => shape.cssColor));

    return keys.size > 1;
  }, [shapes]);

  const hasMixedOpacity = useMemo(() => {
    const keys = new Set(shapes.map((shape) => shape.opacity));

    return keys.size > 1;
  }, [shapes]);

  const animatedOpacityPropertes = node?.state.animatedProperties.o;
  const animatedColorProperties = node?.state.animatedProperties.g;

  const currentOpacityKeyframe =
    animatedOpacityPropertes?.keyFrames.find((keyframe) => keyframe.frame === currentFrame)?.frameId ?? '';
  const currentColorKeyframe =
    animatedColorProperties?.keyFrames.find((keyframe) => keyframe.frame === currentFrame)?.frameId ?? '';

  const isOpacityAnimated = node?.opacity.isAnimated as boolean;
  const isColorAnimated = node?.gradient.isAnimated as boolean;

  const opacity = node?.opacity.value.value;

  const handleOpacityKeyframeClick = useCallback(() => {
    toggleAppearanceKeyframe(AnimatedType.GRADIENT_OPACITY, shapes);
  }, [shapes]);

  const handleColorKeyframeClick = useCallback(() => {
    toggleAppearanceKeyframe(AnimatedType.GRADIENT, shapes);
  }, [shapes]);

  const handleOnChangeOpacity = useCallback(
    (result: NumberResult) => {
      stateHistory.beginAction();
      setAnimatedOpacity(node, [result.value]);
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_OPACITY_UPDATED);

      stateHistory.endAction();
    },
    [node],
  );

  const handleOnChangeColor = useCallback((inputColor: string): void => {
    const gradientObj = GParser.parse(inputColor);
    const updateParam: ColorStopJSON[] = [];

    if (gradientObj[0] && gradientObj[0].colorStops.length > 0) {
      gradientObj[0].colorStops.forEach((cStop: ColorStop) => {
        const [red, green, blue, alpha] = cStop.value;

        updateParam.push({
          r: Number(red),
          g: Number(green),
          b: Number(blue),
          a: Number(alpha),
          offset: Number(cStop.length?.value),
        });
      });
    }

    useCreatorStore.getState().toolkit.setGradientColor(updateParam);

    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_COLOR_UPDATED);
  }, []);

  const [minValue, maxValue] = useMemo(() => {
    if (shapes.length && hasMixedOpacity) {
      const opacities = shapes.map((shape) => shape.opacity);

      const lowestOpacity = Math.min(...opacities);
      const highestOpacity = Math.max(...opacities);

      const min = -highestOpacity;
      const max = 100 + (100 - lowestOpacity);

      return [min, max];
    }

    return [0, 100];
  }, [shapes, hasMixedOpacity]);

  const message = useMemo(() => {
    if (shapes.length === 1) {
      return shapes[0]?.type === GradientFillType.LINEAR ? 'Linear' : 'Radial';
    }

    if (shapes.every((shape) => shape.type === GradientFillType.LINEAR)) {
      return hasMixedColor ? 'Mixed' : 'Linear';
    }

    if (shapes.every((shape) => shape.type === GradientFillType.RADIAL)) {
      return hasMixedColor ? 'Mixed' : 'Radial';
    }

    return 'Mixed';
  }, [hasMixedColor, shapes]);

  const gradientShape = shapes as CurrentGFillShape[];

  return (
    <div className="mt-2 flex w-[182px]">
      <div className="mt-2 flex">
        <div className="mr-[2px] w-1/2">
          <ColorInput
            gradientType={ShapeType.GRADIENT_FILL}
            shapeType={ShapeType.GRADIENT_FILL}
            styleClass="rounded-r-none w-[87px]"
            color={shapes[0]?.cssColor as string}
            opacity={opacity as number}
            onChangeColor={handleOnChangeColor}
            onChangeOpacity={(result) => handleOnChangeOpacity({ name: 'opacity', value: result, trueValue: result })}
            message={message}
            isChannelAnimated={isColorAnimated}
            gradientShape={gradientShape}
            onKeyframeClick={handleColorKeyframeClick}
            showKeyframe
            hasKeyframe={Boolean(currentColorKeyframe)}
            selectedIds={ids}
            enableColorModeChange={true}
            disabled
          />
        </div>
        <div className="my-[-8px] border border-gray-800"></div>
        <div className="w-[87px]">
          <NumberInput
            styleClass={{ label: `${defaultStyle.label} rounded-l-none` }}
            name="opacity"
            value={opacity as number}
            label={<Opacity className="h-4 w-4" />}
            min={minValue}
            max={maxValue}
            precision={0}
            onChange={handleOnChangeOpacity}
            onKeyframeClick={handleOpacityKeyframeClick}
            hasKeyframe={Boolean(currentOpacityKeyframe)}
            message={hasMixedOpacity ? 'Mixed' : null}
            isChannelAnimated={isOpacityAnimated}
            append="%"
            showKeyframe
          />
        </div>
      </div>
    </div>
  );
};
