/**
 * Copyright 2022 Design Barn Inc.
 */

import type { PrecompositionAsset, Scene, Timeline } from '@lottiefiles/toolkit-js';

const changeFpsButKeepSpeed = (timeline: Timeline, fps: number): void => {
  const currentFrameRate = timeline.frameRate;
  const factor = fps / currentFrameRate;

  if (factor !== 1) {
    timeline.scene.scaleTimeline(factor);
  }
  timeline.setFrameRate(fps);
};

const stretchTimelineToDuration = (timeline: Timeline, newDuration: number): void => {
  const currentDuration = timeline.duration;
  const factor = newDuration / currentDuration;

  if (factor !== 1) {
    timeline.scene.scaleTimeline(factor);
  }
};

export const setDuration = (scene: Scene, duration: number): void => {
  scene.timeline.setDuration(duration);
};

export const setSceneFPS = (scene: Scene, fps: number, duration?: number): void => {
  changeFpsButKeepSpeed(scene.timeline, fps);
  if (duration) {
    stretchTimelineToDuration(scene.timeline, duration);
  }
};

export const setStartFrame = (scene: Scene, startFrame: number): void => {
  scene.timeline.setStartFrame(startFrame);
};

export const setEndFrame = (scene: Scene, endFrame: number): void => {
  scene.timeline.setEndFrame(endFrame);
};

export const setPrecompFPS = (precompAsset: PrecompositionAsset, fps: number, duration?: number): void => {
  precompAsset.timeline.setFrameRate(fps);

  if (duration) {
    precompAsset.timeline.setDuration(duration);
  }
};

export const setPrecompDuration = (precompAsset: PrecompositionAsset, duration: number): void => {
  precompAsset.timeline.setDuration(duration);
};
