/**
 * Copyright 2024 Design Barn Inc.
 */

import type { LayerJSON } from '@lottiefiles/toolkit-js';
import React from 'react';

import { LAYER_PANEL_DEFAULT_WIDTH } from '../constant';
import { TimelineRow } from '../TimelineKeyframePanel/TimelineContent/TimelineItem';
import { LayerRow } from '../TimelineLayerPanel/Layers';

import { layerMap } from '~/lib/layer';

interface TimelineItemProps {
  cursorClassName: string;
  isMask?: boolean | undefined;
  layer: LayerJSON;
}

export const TimelineItemLayer: React.FC<TimelineItemProps> = ({ cursorClassName, isMask, layer }) => {
  const layerUI = layerMap.get(layer.id);

  if (!layerUI) {
    return null;
  }

  return (
    <div className="flex" data-timeline-item-layer={layer.id}>
      <div className={`shrink-0 ${cursorClassName}`} style={{ width: LAYER_PANEL_DEFAULT_WIDTH }}>
        <LayerRow layer={layer} layerUI={layerUI} isMask={isMask} />
      </div>
      <div className="mr-2 w-full" data-no-dnd="true">
        <TimelineRow layer={layer} layerUI={layerUI} />
      </div>
    </div>
  );
};

export default TimelineItemLayer;
