/**
 * Copyright 2024 Design Barn Inc.
 */

import clsx from 'clsx';
import React from 'react';
import type { FC } from 'react';

import { TickIcon } from '~/assets/icons';
import { useCreatorStore } from '~/store';

export const RulersMenuItem: FC = () => {
  const enabled = useCreatorStore((state) => state.canvas.rulers.enabled);

  return (
    <span className="flex">
      <TickIcon className={clsx('mr-2 h-4 w-4', { invisible: !enabled })} />
      Show rulers
    </span>
  );
};

export const GuidesMenuItem: FC = () => {
  const enabled = useCreatorStore((state) => state.canvas.guides.enabled);

  return (
    <span className="flex">
      <TickIcon className={clsx('mr-2 h-4 w-4', { invisible: !enabled })} />
      Show guides
    </span>
  );
};

export const GridMenuItem: FC = () => {
  const visible = useCreatorStore((state) => state.canvas.grid.visible);

  return (
    <span className="flex">
      <TickIcon className={clsx('mr-2 h-4 w-4', { invisible: !visible })} />
      Show grid
    </span>
  );
};

export const SnapMenuItem: FC = () => {
  const enabled = useCreatorStore((state) => state.canvas.snapping.enabled);

  return (
    <span className="flex">
      <TickIcon className={clsx('mr-2 h-4 w-4', { invisible: !enabled })} />
      Snapping guides
    </span>
  );
};
