/**
 * Copyright 2024 Design Barn Inc.
 */

import type { OffsetOptions } from '@floating-ui/react-dom-interactions';
import { useFloating, arrow, autoUpdate, offset } from '@floating-ui/react-dom-interactions';
import type { Placement, SideObject } from '@floating-ui/utils';
import type { FC, ReactNode } from 'react';
import React, { useRef } from 'react';

const arrowSize = 16;
const halfTooltipSize = arrowSize / 2;

const arrowStyles: Record<Placement, Partial<SideObject>> = {
  'right-start': {
    top: 24,
    left: -halfTooltipSize,
  },
  'right-end': {
    top: 24,
    left: -halfTooltipSize,
  },
  'left-start': {
    top: 24,
    right: -halfTooltipSize,
  },
  'left-end': {
    top: 24,
    right: -halfTooltipSize,
  },
  'top-start': {
    bottom: -halfTooltipSize,
  },
  'top-end': {
    bottom: -halfTooltipSize,
  },
  'bottom-start': {
    top: -halfTooltipSize,
  },
  'bottom-end': {
    top: -halfTooltipSize,
  },
  left: {
    right: -halfTooltipSize,
  },
  right: {
    left: -halfTooltipSize,
  },
  top: {
    bottom: -halfTooltipSize,
  },
  bottom: {
    top: -halfTooltipSize,
  },
};

export interface StepOptions {
  offsetOptions?: OffsetOptions;
  placement?: Placement;
}

export type HightlightAreaBounds = Record<'top' | 'left' | 'width' | 'height', number>;

interface Props extends StepOptions {
  children: ReactNode;
  hightlightAreaBounds: HightlightAreaBounds;
}

export const Step: FC<Props> = ({ children, hightlightAreaBounds, offsetOptions = 16, placement = 'right-start' }) => {
  const arrowRef = useRef(null);

  const { floating, middlewareData, reference, x, y } = useFloating({
    open: true,
    middleware: [offset(offsetOptions), arrow({ element: arrowRef })],
    placement,
    whileElementsMounted: autoUpdate,
  });

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <div ref={floating} className="fixed z-20" style={{ top: y!, left: x! }}>
        <div
          ref={arrowRef}
          className="absolute rotate-45 bg-white"
          style={{
            width: arrowSize,
            height: arrowSize,
            top: middlewareData.arrow?.y,
            left: middlewareData.arrow?.x,
            ...arrowStyles[placement],
          }}
        />
        {children}
      </div>
      <div className="fixed inset-0 z-10 bg-black/50 mix-blend-hard-light">
        <div
          ref={reference}
          className="absolute rounded border border-dashed border-white"
          style={{
            backgroundColor: 'gray',
            ...hightlightAreaBounds,
          }}
        />
      </div>
    </>
  );
};
