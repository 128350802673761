/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ShapeJSON } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React from 'react';

import type { OptionalShapeLayer } from '../../TimelineLayerPanel/Layers';

import { TimelineEmptyTrack, TimelineTrack } from './TimelineTrack';

import type { LayerUI } from '~/features/timeline';

interface TimelineRowProps {
  layer: OptionalShapeLayer | ShapeJSON;
  layerUI: LayerUI;
}

const isTrack = (layer: OptionalShapeLayer | ShapeJSON): boolean => {
  // Track contain ip and op properties
  return 'ip' in layer.properties && 'op' in layer.properties;
};

export const TimelineRow: React.FC<TimelineRowProps> = ({ layer, layerUI }) => {
  return (
    <>
      <div className={clsx('mt-[1px] bg-[#22292f] px-[11px]')}>
        {isTrack(layer) ? <TimelineTrack layerId={layer.id} layerUI={layerUI} /> : <TimelineEmptyTrack />}
      </div>
    </>
  );
};
