/**
 * Copyright 2024 Design Barn Inc.
 */

import {
  applyDithering,
  getColorFromProjection,
  getOffsetFromTexture,
  getRadialColor,
  gradientColor,
} from '../bezier-fill/gradientFillFragment';
import { cubicBezierDis, getValueFromTexture, isPointInBoundingBox } from '../bezier/bezier';

export const gradientStrokeFragment = `
// referenced from https://www.shadertoy.com/view/7ltBzj
#define LINEWIDTH (2.0)

varying vec2 vCoord;
uniform sampler2D uBezierPoints;
uniform vec3 uColor;
uniform int uBezierCount;
uniform float uOpacity;
uniform float uStrokeWidth;
uniform bool uIsClosed;

uniform bool uIsRadial;
uniform sampler2D uColorStops;
uniform int uColorStopsCount;
uniform sampler2D uColorStopOffsets;
uniform vec2 uStart;
uniform vec2 uEnd;
uniform float uHighlightAngle;
uniform float uHighlightLength;

${getValueFromTexture}
${getOffsetFromTexture}
${isPointInBoundingBox}
${applyDithering}
${getColorFromProjection}
${cubicBezierDis}
${gradientColor}
${getRadialColor}
void main() {
  gl_FragColor = vec4(1.0, 1.0, 1.0, 0.0);
  for (int i = 0; i < uBezierCount; i++) {
    if (!uIsClosed && i == uBezierCount - 1) continue;
    vec3 P0 = getValueFromTexture(uBezierPoints, vec2(uBezierCount, 4), vec2(i, 0)).rgb;
    vec3 P1 = getValueFromTexture(uBezierPoints, vec2(uBezierCount, 4), vec2(i, 1)).rgb;
    vec3 P2 = getValueFromTexture(uBezierPoints, vec2(uBezierCount, 4), vec2(i, 2)).rgb;
    vec3 P3 = getValueFromTexture(uBezierPoints, vec2(uBezierCount, 4), vec2(i, 3)).rgb;

    float halfWidth = uStrokeWidth * 0.5;
    vec2 minBound = min(min(P0.xy, P3.xy), min(P1.xy, P2.xy)) - vec2(halfWidth);
    vec2 maxBound = max(max(P0.xy, P3.xy), max(P1.xy, P2.xy)) + vec2(halfWidth);

    if (!isPointInBoundingBox(vCoord, minBound, maxBound)) {
      continue;
    }

    float dis = cubic_bezier_dis(vCoord, P0.xy, P1.xy, P2.xy, P3.xy);
    if (dis < halfWidth) {
      if (uIsRadial) {
        vec4 radialColor = getRadialColor();
        gl_FragColor = vec4(radialColor.rgb, radialColor.a * uOpacity);
      } else {
        vec4 gradientColor = getGradientColor();
        gl_FragColor = vec4(gradientColor.rgb, gradientColor.a * uOpacity);
      }
    }
  }
}

`;
