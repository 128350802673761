/**
 * Copyright 2023 Design Barn Inc.
 */

import type { GroupShape, Shape } from '@lottiefiles/lottie-js';
import { ShapeType } from '@lottiefiles/lottie-js';
import type { DagNode } from '@lottiefiles/toolkit-js';
import { PrecompositionLayer, ShapeLayer } from '@lottiefiles/toolkit-js';
import { Scene, Vector3 } from 'three';

import { getRealPosition } from '../../../features/canvas/toolkit';
import { Box3 } from '../Box3';

import type { CMesh, CObject3D } from '~/features/canvas';
import { canvasMap } from '~/lib/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AlignDirection, DistributionDirection } from '~/lib/threejs/TransformControls/types';
import { AnimatedType, toolkit, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;
const setAnimatedValue = useCreatorStore.getState().toolkit.setAnimatedValue;

interface DistributionObject {
  bottom: number;
  boundingBox: Box3;
  left: number;
  object: CObject3D;
  right: number;
  top: number;
}

export class AlignmentHelper {
  public align(direction: AlignDirection | DistributionDirection): void {
    const filteredObjects = this.getFilteredObjects();

    const parentIsScene = this._getParentIsScene(filteredObjects[0]?.parent as CObject3D);
    const parentBoundingBox = this.getParentBoundingBox(filteredObjects as CObject3D[], parentIsScene) as Box3;

    stateHistory.beginAction();

    if (direction === DistributionDirection.Horizontal || direction === DistributionDirection.Vertical) {
      this._distributeObjects(filteredObjects, parentIsScene, parentBoundingBox, direction as DistributionDirection);
    } else {
      filteredObjects.forEach((object) => {
        const alignedPosition = this.getAlignedPosition(
          object,
          parentIsScene,
          parentBoundingBox,
          direction as AlignDirection,
        );

        setAnimatedValue(AnimatedType.POSITION, [alignedPosition.x, alignedPosition.y], object.toolkitId);
      });
    }

    stateHistory.endAction();

    emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });
  }

  public getAlignedPosition(
    object: CObject3D,
    parentIsScene: boolean,
    parentBoundingBox: Box3,
    direction: AlignDirection,
  ): Vector3 {
    const currentLocalPosition = getRealPosition(object as CObject3D);

    const currentWorldPosition = parentIsScene
      ? currentLocalPosition
      : ((object as CObject3D).parent as CObject3D).localToWorld(currentLocalPosition);

    const objectBoundingBox = new Box3().setFromObject(object as CObject3D, true);
    const objectCenter = objectBoundingBox.center;

    const { bottom: bottomEdge, left: leftEdge, right: rightEdge, top: topEdge } = parentBoundingBox.getBoxEdges();

    const objectLeftEdge = Math.min(
      objectBoundingBox.min.x,
      objectBoundingBox.minXmaxY.x,
      objectBoundingBox.max.x,
      objectBoundingBox.maxXminY.x,
    );
    const objectRightEdge = Math.max(
      objectBoundingBox.min.x,
      objectBoundingBox.minXmaxY.x,
      objectBoundingBox.max.x,
      objectBoundingBox.maxXminY.x,
    );
    const objectTopEdge = Math.min(
      objectBoundingBox.min.y,
      objectBoundingBox.maxXminY.y,
      objectBoundingBox.max.y,
      objectBoundingBox.minXmaxY.y,
    );
    const objectBottomEdge = Math.max(
      objectBoundingBox.min.y,
      objectBoundingBox.maxXminY.y,
      objectBoundingBox.max.y,
      objectBoundingBox.minXmaxY.y,
    );

    const positions = {
      [AlignDirection.Left]: new Vector3(
        currentWorldPosition.x - objectLeftEdge + leftEdge,
        currentWorldPosition.y,
        currentWorldPosition.z,
      ),
      [AlignDirection.Right]: new Vector3(
        currentWorldPosition.x + (rightEdge - objectRightEdge),
        currentWorldPosition.y,
        currentWorldPosition.z,
      ),
      [AlignDirection.Center]: new Vector3(
        currentWorldPosition.x + (rightEdge / 2 - objectCenter.x) + leftEdge / 2,
        currentWorldPosition.y,
        currentWorldPosition.z,
      ),
      [AlignDirection.Top]: new Vector3(
        currentWorldPosition.x,
        currentWorldPosition.y - objectTopEdge + topEdge,
        currentWorldPosition.z,
      ),
      [AlignDirection.Middle]: new Vector3(
        currentWorldPosition.x,
        currentWorldPosition.y + (bottomEdge / 2 - objectCenter.y) + topEdge / 2,
        currentWorldPosition.z,
      ),
      [AlignDirection.Bottom]: new Vector3(
        currentWorldPosition.x,
        currentWorldPosition.y + (bottomEdge - objectBottomEdge),
        currentWorldPosition.z,
      ),
    };

    const alignedWorldPosition = positions[direction];
    const alignedLocalPosition = parentIsScene
      ? alignedWorldPosition
      : ((object as CObject3D).parent as CObject3D).worldToLocal(alignedWorldPosition);

    return alignedLocalPosition;
  }

  public getFilteredObjects(): CObject3D[] {
    const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;
    const selectedNodeIds = selectedNodesInfo.map((node) => node.nodeId);

    const isEqualHierarchy = selectedNodesInfo.every((node) => node.nodeType === selectedNodesInfo[0]?.nodeType);

    const filteredIds: string[] = [];

    let updateSelectedNodes = false;

    selectedNodeIds.forEach((id) => {
      const node = getNodeByIdOnly(id) as GroupShape | ShapeLayer | PrecompositionLayer | unknown;

      // If all selected nodes are groups, push them to the filtered objects array
      // to align them within the shape layer parent
      if ((node as Shape).type === ShapeType.GROUP && isEqualHierarchy) {
        filteredIds.push(id);

        return;
      }

      // If there are non-transformable nodes (like path, fill, stroke), get their parent layers
      let currentNode = node;

      while (!(currentNode instanceof ShapeLayer) && !(currentNode instanceof PrecompositionLayer)) {
        currentNode = (currentNode as DagNode).parent;
        updateSelectedNodes = true;
      }

      filteredIds.push(currentNode.nodeId);
    });

    const uniqueFilteredIds = [...new Set(filteredIds)];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (updateSelectedNodes) addToSelectedNodes(uniqueFilteredIds, true);

    const filteredObjects = uniqueFilteredIds.map((id) => canvasMap.get(id)) as [CObject3D | CMesh];

    return filteredObjects as CObject3D[];
  }

  public getParentBoundingBox(objects: CObject3D[], parentIsScene: boolean): Box3 | null {
    // Parent type is the selection box
    if (objects.length > 1) {
      return new Box3().setFromObjects(objects, true);
    }

    // Parent type is the scene
    if (objects.length === 1 && parentIsScene) {
      const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
      const scene = toolkit.scenes[sceneIndex];
      const precomp = getNodeByIdOnly(useCreatorStore.getState().toolkit.selectedPrecompositionId ?? '');

      const sceneWidth = precomp ? (precomp.getData('canvasWidth') as number) : scene?.size.width;
      const sceneHeight = precomp ? (precomp.getData('canvasHeight') as number) : scene?.size.height;

      return new Box3().setFromPoints([
        new Vector3(0, 0, 0),
        new Vector3(sceneWidth, 0, 0),
        new Vector3(sceneWidth, sceneHeight, 0),
        new Vector3(0, sceneHeight, 0),
      ]);
    }

    // Parent type is CObject3D
    if (objects.length === 1) {
      const parent = objects[0]?.parent as CObject3D;

      return new Box3().setFromObject(parent, true);
    }

    return null;
  }

  private readonly _calculateDistributionSpaceBetween = (
    parentHeight: number,
    parentWidth: number,
    objects: DistributionObject[],
    direction: DistributionDirection,
  ): number => {
    let totalObjectsHeight = 0;
    let totalObjectsWidth = 0;

    objects.forEach((object: DistributionObject) => {
      totalObjectsHeight += object.bottom - object.top;
      totalObjectsWidth += object.right - object.left;
    });

    if (direction === DistributionDirection.Vertical) {
      return Math.round(((parentHeight - totalObjectsHeight) / (objects.length - 1)) * 1000) / 1000;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (direction === DistributionDirection.Horizontal) {
      return Math.round(((parentWidth - totalObjectsWidth) / (objects.length - 1)) * 1000) / 1000;
    }

    return 0;
  };

  private _distributeObjects(
    filteredObjects: CObject3D[],
    parentIsScene: boolean,
    parentBoundingBox: Box3,
    direction: DistributionDirection,
  ): void {
    if (filteredObjects.length < 3) {
      return;
    }

    const {
      bottom: parentBottom,
      left: parentLeft,
      right: parentRight,
      top: parentTop,
    } = parentBoundingBox.getBoxEdges();

    const distributionObjects: DistributionObject[] = filteredObjects.map((object) => {
      const objectBoundingBox = new Box3().setFromObject(object as CObject3D, true);

      const { bottom, left, right, top } = objectBoundingBox.getBoxEdges();

      return {
        object,
        bottom,
        left,
        right,
        top,
        boundingBox: objectBoundingBox,
      };
    });

    const sortedObjects = this._getSortedDistributionObjects(distributionObjects, direction);

    const parentHeight = parentBottom - parentTop;
    const parentWidth = parentRight - parentLeft;
    const spaceBetween = this._calculateDistributionSpaceBetween(parentHeight, parentWidth, sortedObjects, direction);

    let shift = 0;

    if (direction === DistributionDirection.Vertical) {
      shift = parentTop;
    }
    if (direction === DistributionDirection.Horizontal) {
      shift = parentLeft;
    }

    sortedObjects.forEach((obj, index) => {
      shift = this._transformDistributionObject(
        shift,
        obj,
        index,
        spaceBetween,
        parentIsScene,
        sortedObjects.length,
        direction,
      );
    });
  }

  private readonly _getParentIsScene = (parent: CObject3D | null): boolean => {
    if (!parent) return false;

    return (parent.parent && parent.parent instanceof Scene) || parent instanceof Scene;
  };

  private readonly _getSortedDistributionObjects = (
    extObjects: DistributionObject[],
    direction: DistributionDirection,
  ): DistributionObject[] => {
    if (direction === DistributionDirection.Vertical) {
      const sortedTopToBottom = [...extObjects].sort((first, second) => first.top - second.top);
      const sortedBottomToTop = [...extObjects].sort((first, second) => second.bottom - first.bottom);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const last = sortedBottomToTop[0]!;

      return [...sortedTopToBottom.filter((obj) => obj.object.id !== last.object.id), last];
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (direction === DistributionDirection.Horizontal) {
      const sortedLeftToRight = [...extObjects].sort((first, second) => first.left - second.left);
      const sortedRightToLeft = [...extObjects].sort((first, second) => second.right - first.right);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const last = sortedRightToLeft[0]!;

      return [...sortedLeftToRight.filter((obj) => obj.object.id !== last.object.id), last];
    }

    return extObjects;
  };

  private readonly _transformDistributionObject = (
    shift: number,
    obj: DistributionObject,
    index: number,
    spaceBetween: number,
    parentIsScene: boolean,
    objectsLength: number,
    direction: DistributionDirection,
  ): number => {
    const currentLocalPosition = getRealPosition(obj.object as CObject3D);

    const currentWorldPosition = parentIsScene
      ? currentLocalPosition
      : ((obj.object as CObject3D).parent as CObject3D).localToWorld(currentLocalPosition);

    if (index > 0 && index < objectsLength - 1) {
      let x = currentWorldPosition.x;
      let y = currentWorldPosition.y;

      if (direction === DistributionDirection.Vertical) {
        y = currentWorldPosition.y - obj.top + shift;
      }
      if (direction === DistributionDirection.Horizontal) {
        x = currentWorldPosition.x - obj.left + shift;
      }

      const newPos = new Vector3(x, y, currentWorldPosition.z);
      const alignedLocalPosition = parentIsScene
        ? newPos
        : ((obj.object as CObject3D).parent as CObject3D).worldToLocal(newPos);

      setAnimatedValue(AnimatedType.POSITION, [alignedLocalPosition.x, alignedLocalPosition.y], obj.object.toolkitId);
    }

    if (direction === DistributionDirection.Vertical) {
      return shift + obj.bottom - obj.top + spaceBetween;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (direction === DistributionDirection.Horizontal) {
      return shift + obj.right - obj.left + spaceBetween;
    }

    return shift;
  };
}
