/**
 * Copyright 2022 Design Barn Inc.
 */

// Import core-js features
import 'core-js/stable/structured-clone';

// import '../wdyr';
import React from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import type { RouteObject } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { hijackEffects } from 'stop-runaway-react-effects';

import App from './App';
import { ErrorBoundary } from './lib/bugsnag';
import { worker } from './mocks/browser';
import { CreateNewFile } from './path';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

// Note: Hardcoding this flag because we cant rely on .dev (otherwise we'll be deploying mocks to dev env)
// We should switch .dev to .local and use this flag then
const useMockServiceWorker = false;

const routes = [
  {
    path: '/new',
    element: <CreateNewFile />,
    hasErrorBoundary: true,
  },
  {
    path: '*',
    element: <App />,
    hasErrorBoundary: true,
  },
].filter(Boolean);

const router = createBrowserRouter(routes as RouteObject[]);

if (process.env['NODE_ENV'] === 'development') {
  hijackEffects({ callCount: 100, timeLimit: 1000 });
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (useMockServiceWorker) {
    worker.start();
  }
}

// TODO: temporary remove StrictMode
root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <RouterProvider router={router} />
  </ErrorBoundary>,
  // <App />,
  // </React.StrictMode>,
);
