/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-non-null-assertion */

import type { PathShape, VectorJSON } from '@lottiefiles/toolkit-js';
import { type CubicBezierJSON, type CubicBezierShape, type DagNode, Vector } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import {
  CurveCorner,
  CurveCornerSelected,
  DisconnectedTangents,
  DisconnectedTangentsSelected,
  MirroredTangent,
  MirroredTangentSelected,
  SharpCorner,
  SharpCornerSelected,
} from '../icons/VertexTypeIcons';

import { isVertexesAtSamePosition, VertexType } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const PathEditVertexTypeProperty: React.FC = () => {
  const [
    selectedPathPointIndexes,
    selectedNodesInfo,
    pathPointVertexTypes,
    setPathPointVertexTypes,
    getNodeByIdOnly,
    setAnimatedValue,
  ] = useCreatorStore(
    (state) => [
      state.ui.selectedPathPointIndexes,
      state.ui.selectedNodesInfo,
      state.ui.pathPointVertexTypes,
      state.ui.setPathPointVertexTypes,
      state.toolkit.getNodeByIdOnly,
      state.toolkit.setAnimatedValue,
    ],
    shallow,
  );

  const [selectedVertexType, setSelectedVertexType] = React.useState<VertexType | null>(null);
  const [currentShapeNode, setCurrentShapeNode] = useState(null as null | DagNode);

  useEffect(() => {
    const node = getNodeByIdOnly(selectedNodesInfo[0]?.nodeId as string);

    setCurrentShapeNode(node);
  }, [selectedNodesInfo, getNodeByIdOnly]);

  useEffect(() => {
    const vertexTypes: VertexType[] = [];

    selectedPathPointIndexes.forEach((index) => {
      const vertexType = pathPointVertexTypes[index] || VertexType.SHARP;

      vertexTypes.push(vertexType);
    });

    const uniqueVertexTypes = [...new Set(vertexTypes)];

    setSelectedVertexType(uniqueVertexTypes.length === 1 ? (uniqueVertexTypes[0] as VertexType) : null);
  }, [selectedPathPointIndexes, pathPointVertexTypes]);

  const calculateMirroredControlPoints = useCallback(
    (first: VectorJSON, second: VectorJSON, third: VectorJSON): { in: Vector; out: Vector } => {
      if (isVertexesAtSamePosition(first, third)) {
        // eslint-disable-next-line no-param-reassign
        third = {
          x: first.x + second.x,
          y: first.y,
        };
      }

      const directionVector = new Vector(third.x - first.x, third.y - first.y);

      // Normalize the direction vector
      const length = Math.sqrt(directionVector.x ** 2 + directionVector.y ** 2);
      const normalizedDirection = new Vector(directionVector.x / length, directionVector.y / length);

      // Calculate the distance for IN-OUT
      const distance = length / 4;

      // Calculate the center of IN-OUT (which is the SECOND vector)
      const center = second;

      // Calculate the control points
      const controlIn = new Vector(
        center.x - normalizedDirection.x * distance,
        center.y - normalizedDirection.y * distance,
      );
      const controlOut = new Vector(
        center.x + normalizedDirection.x * distance,
        center.y + normalizedDirection.y * distance,
      );

      return { in: controlIn, out: controlOut };
    },
    [],
  );

  const handleOnClick = useCallback(
    (newVertexType: VertexType) => {
      if (!currentShapeNode) {
        return;
      }

      const shape = (currentShapeNode as PathShape).shape.value as CubicBezierShape;
      const pathData = (currentShapeNode as PathShape).state.animatedProperties.sh.value as CubicBezierJSON;
      const pathPoints = pathData.points;

      const inPoints = pathPoints.map((pathPoint) => new Vector(pathPoint.in.x, pathPoint.in.y));
      const outPoints = pathPoints.map((pathPoint) => new Vector(pathPoint.out.x, pathPoint.out.y));

      selectedPathPointIndexes.forEach((index) => {
        const currentVertexType = pathPointVertexTypes[index] || VertexType.SHARP;

        if (currentVertexType !== newVertexType) {
          if (newVertexType === VertexType.SHARP) {
            inPoints[index] = new Vector(0, 0);
            outPoints[index] = new Vector(0, 0);
          } else {
            if (currentVertexType === VertexType.SHARP) {
              const previousPoint =
                index > 0 ? pathPoints[index - 1]!.vertex : pathPoints[pathPoints.length - 1]!.vertex;
              const currentPoint = pathPoints[index]!.vertex;
              const nextPoint = index < pathPoints.length - 1 ? pathPoints[index + 1]!.vertex : pathPoints[0]!.vertex;
              const point = calculateMirroredControlPoints(previousPoint, currentPoint, nextPoint);

              inPoints[index] = point.in.subtractToClone(new Vector(currentPoint.x, currentPoint.y));
              outPoints[index] = point.out.subtractToClone(new Vector(currentPoint.x, currentPoint.y));
            }

            if (currentVertexType === VertexType.CURVE && newVertexType === VertexType.MIRRORED) {
              const inVector = inPoints[index] as Vector;

              outPoints[index] = new Vector(inVector.x * -1, inVector.y * -1);
            }

            if (
              currentVertexType === VertexType.DISCONNECTED &&
              [VertexType.MIRRORED, VertexType.CURVE].includes(newVertexType)
            ) {
              const inVector = inPoints[index] as Vector;

              outPoints[index] = new Vector(inVector.x * -1, inVector.y * -1);
            }
          }
        }
      });

      shape.setInTangents(inPoints);
      shape.setOutTangents(outPoints);
      setAnimatedValue(AnimatedType.PATH, [shape], currentShapeNode.nodeId);

      setPathPointVertexTypes(
        pathPointVertexTypes.map((vertexType: VertexType, index: number) =>
          selectedPathPointIndexes.includes(index) ? newVertexType : vertexType,
        ),
      );

      setSelectedVertexType(newVertexType);
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED);
    },
    [
      selectedPathPointIndexes,
      currentShapeNode,
      pathPointVertexTypes,
      setPathPointVertexTypes,
      setAnimatedValue,
      setSelectedVertexType,
      calculateMirroredControlPoints,
    ],
  );

  const buttonStyle = `h-6 w-6 p-1`;
  const buttonStyleNotSelected = `${buttonStyle} hover:cursor-pointer`;

  return (
    <div className="flex">
      <div className="flex gap-1 rounded bg-gray-700 px-1">
        {selectedVertexType === VertexType.SHARP ? (
          <SharpCornerSelected className={`${buttonStyle}`} />
        ) : (
          <div onClick={() => handleOnClick(VertexType.SHARP)}>
            <SharpCorner className={`${buttonStyleNotSelected}`} />
          </div>
        )}
        {selectedVertexType === VertexType.MIRRORED ? (
          <MirroredTangentSelected className={`${buttonStyle}`} />
        ) : (
          <div onClick={() => handleOnClick(VertexType.MIRRORED)}>
            <MirroredTangent className={`${buttonStyleNotSelected}`} />
          </div>
        )}
        {selectedVertexType === VertexType.CURVE ? (
          <CurveCornerSelected className={`${buttonStyle}`} />
        ) : (
          <div onClick={() => handleOnClick(VertexType.CURVE)}>
            <CurveCorner className={`${buttonStyleNotSelected}`} />
          </div>
        )}
        {selectedVertexType === VertexType.DISCONNECTED ? (
          <DisconnectedTangentsSelected className={`${buttonStyle}`} />
        ) : (
          <div onClick={() => handleOnClick(VertexType.DISCONNECTED)}>
            <DisconnectedTangents className={`${buttonStyleNotSelected}`} />
          </div>
        )}
      </div>
    </div>
  );
};
