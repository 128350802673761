/**
 * Copyright 2022 Design Barn Inc.
 */

import { Size } from '@lottiefiles/toolkit-js';
import type { Toolkit, Scene, PrecompositionLayer, SizeJSON } from '@lottiefiles/toolkit-js';
import axios from 'axios';
import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../Plugin';

import { createPrecompLayerObj } from './precomp/precomposition-layer';
import { createSceneObject } from './scene';
import { getCurrentScene } from './utils';
import { newQuickjsObject } from './vmInterface/marshal/object';
import { getTranslatedObject } from './vmInterface/unmarshal/object';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { resizePrecompLayer } from '~/lib/function/import';
import { resetLayerUI } from '~/lib/layer';
import {
  toolkit,
  removeAllScenes,
  renameNestedScenes,
  stateHistory,
  getSelectedDrawOrder,
  lottiePlugin,
  svgPlugin,
} from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface ImportOptions {
  asPrecomposition: boolean;
  url: string;
}

const importLottieAsPrecomp = async (
  // eslint-disable-next-line @typescript-eslint/no-shadow
  toolkit: Toolkit,
  options: Record<string, unknown>,
): Promise<PrecompositionLayer> => {
  if (toolkit.scenes.length === 0) {
    toolkit.createScene();
  }

  const currentScene = getCurrentScene();

  return currentScene.importAsPrecompositionLayer(lottiePlugin.id, options);
};

const importLottie = async (
  // eslint-disable-next-line @typescript-eslint/no-shadow
  toolkit: Toolkit,
  options: Record<string, unknown>,
): Promise<Scene> => {
  return toolkit.import(lottiePlugin.id, options);
};

async function getJsonData(url: string, asPrecomposition: boolean): Promise<PrecompositionLayer | void> {
  let response;
  let lottieJSON;
  let precompObj;

  try {
    response = await axios.get(url);
    lottieJSON = response.data;
  } catch {
    throw new Error(`${url} is an invalid URL.`);
  }

  if (asPrecomposition) {
    precompObj = await importLottieAsPrecomp(toolkit, { animation: lottieJSON });
  } else {
    await importLottie(toolkit, { animation: lottieJSON });
  }

  useCreatorStore.getState().ui.resetSelection();
  resetLayerUI();
  emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);

  return precompObj;
}

export async function insertLottieJson(plugin: Plugin): Promise<void> {
  const insertLottieJsonHandle = plugin.scope.manage(
    plugin.vm.newAsyncifiedFunction(
      'insertLottieJson',
      async (vmOptions: QuickJSHandle): Promise<void | QuickJSHandle> => {
        const { asPrecomposition, url } = getTranslatedObject(plugin, vmOptions) as ImportOptions;

        stateHistory.beginAction();

        if (toolkit.scenes.length > 0 && !asPrecomposition) {
          removeAllScenes(toolkit);
        }

        const precomp = await getJsonData(url, asPrecomposition);

        if (precomp) {
          precomp.setDrawOrder(getSelectedDrawOrder() as number);
          resizePrecompLayer(getCurrentScene(), precomp);
          renameNestedScenes();
        }

        emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);

        const returnObj = asPrecomposition
          ? createPrecompLayerObj(plugin, precomp as PrecompositionLayer)
          : createSceneObject(plugin, precomp as unknown as Scene);

        stateHistory.endAction();

        const vmReturnObj = newQuickjsObject(plugin, returnObj, true);

        // if asPrecomposition is true, returns a handle to the precomposition
        return vmReturnObj;
      },
    ),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'insertLottieJson', {
    value: insertLottieJsonHandle,
    configurable: false,
  });
}

export const importSVG = async (
  options: {
    importOptions?: {
      parsingOptions: { dpi: number; endFrame: number; frameRate: number; screenSize: Size; startFrame: number };
      sceneOptions: { author: string; description: string; keywords: string[]; name: string };
    };
    svgString: string;
  },
  asPrecomposition: boolean,
): Promise<PrecompositionLayer | Scene> => {
  // Override the defaultNames parameters
  const newOptions = { ...options, importOptions: { ...(options.importOptions ?? {}), defaultNames: true } };

  if (asPrecomposition) {
    if (toolkit.scenes.length === 0) {
      removeAllScenes(toolkit);
      toolkit.createScene();
    }
    const currentScene = getCurrentScene();

    return currentScene.importAsPrecompositionLayer(svgPlugin.id, newOptions);
  } else {
    removeAllScenes(toolkit);

    return toolkit.import(svgPlugin.id, newOptions);
  }
};

const getSvgData = async (svgUrl: string, asPrecomposition: boolean): Promise<Scene | PrecompositionLayer> => {
  const currentScene = getCurrentScene();

  const response = await fetch(svgUrl);
  const svgString = await response.text();

  const width = (currentScene.state.properties.sz as SizeJSON).w;
  const height = (currentScene.state.properties.sz as SizeJSON).h;

  const options = {
    svgString,
    importOptions: {
      // Note (May 2024)
      // - CSS gradients are not supported yet
      // - Might have inaccuracies when converting SVGs using objectBoundingBox
      useExperimentalGradientSupport: true,
      defaultNames: true,
      parsingOptions: {
        dpi: 72,
        endFrame: 90,
        frameRate: 25,
        screenSize: new Size(width / 2, height / 2),
        startFrame: 0,
      },
      sceneOptions: {
        author: '',
        description: '',
        keywords: [],
        name: '',
      },
    },
  };

  const precomp = (await importSVG(options, asPrecomposition)) as PrecompositionLayer;

  useCreatorStore.getState().ui.resetSelection();
  resetLayerUI();
  emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);

  return precomp;
};

export async function insertSvg(plugin: Plugin): Promise<void> {
  const insertSvgHandle = plugin.scope.manage(
    plugin.vm.newAsyncifiedFunction('insertSvg', async (vmOptions: QuickJSHandle): Promise<void | QuickJSHandle> => {
      const { asPrecomposition, url } = getTranslatedObject(plugin, vmOptions) as ImportOptions;

      stateHistory.beginAction();

      const precomp = await getSvgData(url, asPrecomposition);

      resizePrecompLayer(getCurrentScene(), precomp as PrecompositionLayer);
      renameNestedScenes();
      emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);

      const returnObj = asPrecomposition
        ? createPrecompLayerObj(plugin, precomp as PrecompositionLayer)
        : createSceneObject(plugin, precomp as Scene);

      stateHistory.endAction();
      const vmReturnObj = newQuickjsObject(plugin, returnObj, true);

      // if asPrecomposition is true, returns a handle to the precomposition
      return vmReturnObj;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'insertSvg', {
    value: insertSvgHandle,
    configurable: false,
  });
}
