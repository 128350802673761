/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { Tooltip } from '../Tooltip';

import { EyeClose, EyeOpen } from '~/assets/icons';

interface VisibilityToggleProps {
  onClick?: () => void;
  visible?: boolean;
}

export const VisibilityToggle: React.FC<VisibilityToggleProps> = ({ onClick, visible }) => {
  const icon = visible ? <EyeOpen className="h-4 w-4 fill-white" /> : <EyeClose className="h-4 w-4" />;

  return (
    <Tooltip content="Show/hide" placement="bottom-end">
      <button onClick={onClick} className="flex h-6 w-6 items-center justify-center">
        {icon}
      </button>
    </Tooltip>
  );
};
