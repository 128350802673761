/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

const GRADIENT_TOOLTIP_COLOR = '#3AA0FF';

export const GradientControlsTooltip: React.FC = () => {
  return (
    <div
      id="gradient-controls-tooltip"
      className="absolute hidden rounded-full py-[2px] px-[4px] align-middle text-[10px] text-white"
      style={{ backgroundColor: GRADIENT_TOOLTIP_COLOR }}
    ></div>
  );
};
