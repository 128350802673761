/**
 * Copyright 2022 Design Barn Inc.
 */

import EventEmitter2 from 'eventemitter2';

export const emitter = new EventEmitter2({
  wildcard: true,
  delimiter: ':',
});

export enum EmitterEvent {
  ALIGNMENT_BOTTOM = 'alignment:bottom',
  ALIGNMENT_CENTER = 'alignment:center',
  ALIGNMENT_DISTRIBUTION_HORIZONTAL = 'alignment:distribution_horizontal',
  ALIGNMENT_DISTRIBUTION_VERTICAL = 'alignment:distribution_vertical',
  ALIGNMENT_LEFT = 'alignment:left',
  ALIGNMENT_MIDDLE = 'alignment:middle',
  ALIGNMENT_RIGHT = 'alignment:right',
  ALIGNMENT_TOP = 'alignment:top',
  ANIMATED_ANCHOR_UPDATED = 'animated:anchor-updated',

  ANIMATED_KEYFRAME_UPDATED = 'animated:key-frame-updated',
  ANIMATED_OPACITY_UPDATED = 'animated:opacity-updated',
  ANIMATED_POSITION_UPDATED = 'animated:position-updated',
  ANIMATED_ROTATION_UPDATED = 'animated:rotation-updated',
  ANIMATED_SCALE_UPDATED = 'animated:scale-updated',
  ANIMATED_SHAPE_BLENDING_MODE_UPDATED = 'animated:shape-blending-mode-updated',
  ANIMATED_SHAPE_COMPOSITION_UPDATED = 'animated:shape-composition-updated',
  ANIMATED_SHAPE_FILL_COLOR_ALPHA_UPDATED = 'animated:fill-color-alpha-updated',
  ANIMATED_SHAPE_FILL_COLOR_UPDATED = 'animated:fill-color-updated',
  ANIMATED_SHAPE_FILL_RULE_UPDATED = 'animated:shape-fill-rule-updated',
  ANIMATED_SHAPE_GRADIENT_COLOR_UPDATED = 'animated:gradient-color-updated',
  ANIMATED_SHAPE_GRADIENT_END_UPDATED = 'animated:gradient-end-updated',
  ANIMATED_SHAPE_GRADIENT_OPACITY_UPDATED = 'animated:gradient-opacity-updated',
  ANIMATED_SHAPE_GRADIENT_POINT_UPDATED = 'animated:gradient-point-updated',
  ANIMATED_SHAPE_GRADIENT_START_UPDATED = 'animated:gradient-start-updated',
  ANIMATED_SHAPE_PATH_UPDATED = 'animated:path-updated',
  ANIMATED_SHAPE_STROKE_COLOR_ALPHA_UPDATED = 'animated:stroke-color-alpha-updated',
  ANIMATED_SHAPE_STROKE_COLOR_UPDATED = 'animated:stroke-color-updated',
  ANIMATED_SHAPE_STROKE_DASH_ADDED = 'animated:stroke-dash-added',
  ANIMATED_SHAPE_STROKE_DASH_REMOVED = 'animated:stroke-dash-removed',
  ANIMATED_SHAPE_STROKE_DASH_UPDATED = 'animated:stroke-dash-updated',
  ANIMATED_SHAPE_STROKE_LINECAP_UPDATED = 'animated:stroke-linecap-updated',
  ANIMATED_SHAPE_STROKE_LINEJOIN_UPDATED = 'animated:stroke-linejoin-updated',
  ANIMATED_SHAPE_STROKE_MITER_UPDATED = 'animated:stroke-miter-updated',
  ANIMATED_SHAPE_STROKE_OPACITY_UPDATED = 'animated:stroke-opacity-updated',
  ANIMATED_SHAPE_STROKE_WIDTH_UPDATED = 'animated:stroke-width-updated',
  ANIMATED_SHAPE_TRIM_END_UPDATED = 'animated:trim-end-updated',
  ANIMATED_SHAPE_TRIM_OFFSET_UPDATED = 'animated:trim-offset-updated',
  ANIMATED_SHAPE_TRIM_START_UPDATED = 'animated:trim-start-updated',
  ANIMATION_PRESET_PANEL_OPEN = 'animation:preset-panel',

  APPEARANCE_CREATED = 'appearance:created',
  APPEARANCE_DELETED = 'appearance:deleted',
  CANVAS_ADJUST = 'canvas:adjust',
  CANVAS_COLOR_MODE_UPDATED = 'canvas:color-mode-updated',
  CANVAS_COPY = 'canvas:copy',
  CANVAS_COPY_AS_MASK = 'canvas:copy-as-mask',
  CANVAS_CUT = 'canvas:cut',
  CANVAS_DESELECT_ALL = 'canvas:deselect-all',
  CANVAS_DISABLE_PENCONTROL = 'canvas:disable-pen-control',
  CANVAS_DUPLICATE = 'canvas:duplicate',
  CANVAS_HIDE_TRANSFORMCONTROL = 'canvas:hide-transformcontrol',
  CANVAS_MASK_CREATE = 'canvas:mask-create',
  CANVAS_MASK_REMOVE = 'canvas:mask-remove',

  CANVAS_NULLIFY_LAST_OBJECT = 'canvas:nullify-last-selected-object',
  CANVAS_OBJECT_FLIP_HORIZONTAL = 'canvas:object-flip-horizontal',
  CANVAS_OBJECT_FLIP_VERTICAL = 'canvas:object-flip-vertical',
  CANVAS_OBJECT_REMOVED = 'canvas:object-removed',
  CANVAS_OBJECT_ROTATE_LEFT = 'canvas:object-rotate-left',
  CANVAS_OBJECT_ROTATE_RIGHT = 'canvas:object-rotate-right',
  CANVAS_PASTE_AS_MASK = 'canvas:paste-as-mask',
  CANVAS_PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT = 'canvas:paste-with-assets-by-ref-if-same-project',
  CANVAS_PASTE_WITH_CLONED_ASSETS = 'canvas:paste-with-cloned-assets',
  CANVAS_PENCONTROL_DRAW_CONTINUE = 'canvas:pen-control-draw-continue',
  CANVAS_REDRAW = 'canvas:redraw',
  CANVAS_REDRAW_OVERLAY = 'canvas:redraw-overlay',
  CANVAS_RENDER_UPDATE = 'canvas:render-update',
  CANVAS_RESIZE = 'canvas:resize',
  CANVAS_SELECTION_MOVE_DOWN = 'canvas:selection-move-down',
  CANVAS_SELECTION_MOVE_LEFT = 'canvas:selection-move-left',
  CANVAS_SELECTION_MOVE_RIGHT = 'canvas:selection-move-right',
  CANVAS_SELECTION_MOVE_UP = 'canvas:move-up',
  CANVAS_SELECT_ALL_LAYERS = 'canvas:select-all-layers',
  CANVAS_SELECT_OBJECTS = 'canvas:select-objects',
  CANVAS_SELECT_PATH_POINTS = 'canvas:select-path-points',
  CANVAS_SHOW_TRANSFORMCONTROL = 'canvas:show-transformcontrol',
  CANVAS_TOGGLE_GRADIENT_CONTROLS = 'canvas:toggle-gradient-controls',
  CANVAS_TOGGLE_GRID = 'canvas:toggle-grid',
  CANVAS_TOGGLE_GRID_VISIBILITY = 'canvas:toggle-grid-visibility',
  CANVAS_TOGGLE_GUIDES = 'canvas:toggle-guides',
  CANVAS_TOGGLE_RULERS = 'canvas:toggle-rulers',
  CANVAS_TOGGLE_SNAPPING = 'canvas:toggle-snapping',
  CANVAS_TRANSFORMCONTROL_PIVOT_UPDATED = 'canvas:transformcontrol-pivot-updated',
  CANVAS_TRANSFORMCONTROL_UPDATED = 'canvas:transformcontrol-updated',
  CANVAS_UPDATED_ACTIVE_GRADIENT_COLOR_STOP = 'canvas:updated-active-gradient-color-stop',
  CANVAS_ZOOM_FIT_TO_BOUNDING_BOX = 'canvas:zoom-to-fit-bounding-box',
  CANVAS_ZOOM_IN = 'canvas:zoom-in',
  CANVAS_ZOOM_OUT = 'canvas:zoom-out',
  CANVAS_ZOOM_TO_FIT = 'canvas:zoom-to-fit',

  EASING_LINEAR_BOTH = 'easing:linear-both',
  EASING_LINEAR_IN = 'easing:linear-in',
  EASING_LINEAR_OUT = 'easing:linear-out',
  EASING_SMOOTH_BOTH = 'easing:smooth-both',
  EASING_SMOOTH_IN = 'easing:smooth-in',
  EASING_SMOOTH_OUT = 'easing:smooth-out',

  ELLIPSE_CREATED = 'ellipse:created',
  LINE_CREATED = 'line:created',
  LOADED_ANIMATION = 'animation:loaded',
  LOADING_ANIMATION = 'animation:loading',

  MAIN_SCENE_SELECTED = 'scene:main-scene-selected',

  PLAYBACK_GOTO_FIRST_FRAME = 'playback:goto-first-frame',
  PLAYBACK_GOTO_LAST_FRAME = 'playback:goxto-last-frame',
  PLAYBACK_GOTO_NEXT_FRAME = 'playback:goto-next-frame',
  PLAYBACK_GOTO_NEXT_KEYFRAME = 'playback:goto-next-keyframe',
  PLAYBACK_GOTO_PREVIOUS_FRAME = 'playback:goto-previous-frame',
  PLAYBACK_GOTO_PREVIOUS_KEYFRAME = 'playback:goto-previous-keyframe',
  PLAYBACK_GO_BACK_TEN_FRAME = 'playback:go-back-ten-frame',
  PLAYBACK_GO_FORWARD_TEN_FRAME = 'playback:go-forward-ten-frame',

  PLUGIN_CANVAS_UPDATE = 'plugin:canvas-update',

  POLYSTAR_INNER_RADIUS_UPDATED = 'polystar:inner-radius-updated',
  POLYSTAR_INNER_ROUNDNESS_UPDATED = 'polystar:inner-roundness-updated',
  POLYSTAR_OUTER_RADIUS_UPDATED = 'polystar:outer-radius-updated',
  POLYSTAR_OUTER_ROUNDNESS_UPDATED = 'polystar:outer-roundness-updated',
  POLYSTAR_POINTS_UPDATED = 'polystar:points-updated',
  POLYSTAR_ROTATION_UPDATED = 'polystar:rotation-updated',
  PRECOMP_SCENE_SIZE_UPDATED = 'precompScene:size-updated',
  PRECOMP_SCENE_UPDATE_JSON = 'precompScene:update-json',
  RECTANGLE_CREATED = 'rect:created',
  RECT_ROUNDNESS_UPDATED = 'rect:roundness-updated',
  RESET = 'reset',
  SCENE_SIZE_UPDATED = 'scene:size-updated',
  SHAPE_CREATED = 'shape:created',
  SHAPE_FILL_COLOR_OPACITY_UPDATED = 'shape:fill-color-opacity-updated',
  SHAPE_FILL_COLOR_UPDATED = 'shape:fill-color-updated',
  SHAPE_FILL_OPACITY_UPDATED = 'shape:fill-opacity-updated',
  SHAPE_FILL_UPDATED = 'shape:fill-updated',
  SHAPE_GRADIENT_FILL_COLOR_UPDATED = 'shape:gradient-fill-color-updated',
  SHAPE_GRADIENT_UPDATED_FROM_GRADIENT_CONTROLS = 'shape:gradient-updated-from-gradient-controls',
  SHAPE_POSITION_UPDATED = 'shape:position-updated',
  SHAPE_SIZE_UPDATED = 'shape:size-updated',
  TIMELINE_BAR_DRAGGING_UPDATED = 'timeline:bar-dragging-updated',
  TIMELINE_BAR_END_TIME_UPDATED = 'timeline:bar-end-time-updated',
  TIMELINE_BAR_RESIZE_LEFT_UPDATED = 'timeline:bar-resize=left-updated',
  TIMELINE_BAR_RESIZE_RIGHT_UPDATED = 'timeline:bar-resize-right-updated',
  TIMELINE_BAR_START_TIME_UPDATED = 'timeline:bar-start-time-updated',
  TIMELINE_CURRENT_FRAME_UPDATED = 'timeline:current-frame-updated',
  TIMELINE_DURATION_UPDATED = 'timeline:duration-updated',
  TIMELINE_FPS_UPDATED = 'timeline:fps-updated',
  TIMELINE_FRAME_UPDATE_ENDED = 'timeline:frame-update-ended',
  TIMELINE_KEYFRAME_MOVE_LEFT = 'timeline:keyframe-move-left',
  TIMELINE_KEYFRAME_MOVE_RIGHT = 'timeline:keyframe-move-right',

  TIMELINE_LAYER_FOCUS_UNFOCUS = 'timeline:layer-focus-unfocus',
  TIMELINE_LAYER_LOCK_UNLOCK = 'timeline:layer-lock-unlock',
  TIMELINE_LAYER_MATTE_UPDATE = 'timeline:layer-matte-update',
  TIMELINE_LAYER_MOVE_DIFFERENT_LEVEL = 'timeline:layer-move-different-level',
  TIMELINE_LAYER_MOVE_DOWN = 'timeline:layer-move-down',
  TIMELINE_LAYER_MOVE_MOST_DOWN = 'timeline:layer-move-most-down',
  TIMELINE_LAYER_MOVE_MOST_UP = 'timeline:layer-move-most-up',
  TIMELINE_LAYER_MOVE_SAME_LEVEL = 'timeline:layer-move-same-level',
  TIMELINE_LAYER_MOVE_UP = 'timeline:layer-move-up',
  TIMELINE_LAYER_SHOW_HIDE = 'timeline:layer-show-hide',
  TIMELINE_PAUSE = 'timeline:pause',
  TIMELINE_PLAY = 'timeline:play',
  TIMELINE_PRECOMP_ADD_SCENE = 'timeline:add-scene',
  TIMELINE_PRECOMP_BREAK_SCENE = 'timeline:break-scene',
  TIMELINE_PRECOMP_CREATE_SCENE = 'timeline:create-scene',
  TIMELINE_PRECOMP_EDIT_SCENE = 'timeline:edit-scene',
  TIMELINE_RENAME_LAYER_END = 'timeline:rename-layer-end',
  TIMELINE_RENAME_LAYER_START = 'timeline:rename-layer-start',
  TIMELINE_RESET_WORKAREA = 'timeline:reset-workarea',
  TIMELINE_SET_WORKAREA_AS_SEGMENT = 'timeline:set-workarea-as-segment',
  TIMELINE_SHOW_ALL_KEYFRAMES = 'timeline:show-all-keyframes',
  TIMELINE_SHOW_ANIMATED_PROPERTIES = 'timeline:show-animated-properties',
  TIMELINE_TOGGLE_ANIMATE_ALL_PROPERTIES = 'timeline:layer-animate-all-properties',
  TIMELINE_TOGGLE_CHILD_LAYERS_EXPANSION = 'timeline:toggle-expand-all-child-layers',
  TIMELINE_TOGGLE_PLAY = 'timeline:toggle-play',
  TIMELINE_TOGGLE_PRECOMP_SOURCE_NAME = 'timeline:toggle-precomp-source-name',
  TIMELINE_TOGGLE_VISIBLE = 'timeline:toggle-visible',
  TIMELINE_TRIM_INPOINT_TO_PLAYHEAD = 'timeline:trim-inpoint-to-playhead',
  TIMELINE_TRIM_OUTPOINT_TO_PLAYHEAD = 'timeline:trim-outpoint-to-playhead',
  TIMELINE_TRIM_SCENE_TO_WORKAREA = 'timeline:trim-scene-to-workarea',
  TIMELINE_TRIM_WORKAREA_END_TO_PLAYHEAD = 'timeline:trim-end-to-playhead',
  TIMELINE_TRIM_WORKAREA_START_TO_PLAYHEAD = 'timeline:trim-start-to-playhead',
  TOOLKIT_ANCHOR_VISIBILITY_UPDATED = 'toolkit:anchor-visibility-updated',
  TOOLKIT_GET_LATEST = 'toolkit:get-latest',
  TOOLKIT_JSON_IMPORTED = 'toolkit:json-imported',
  TOOLKIT_NODE_SCALE_UPDATED = 'toolkit:node-scale-updated',
  TOOLKIT_NODE_SIZE_UPDATED = 'toolkit:node-size-updated',
  TOOLKIT_STATE_HYDRATED = 'toolkit:state-hydrated',
  TOOLKIT_STATE_UPDATED = 'toolkit:state-updated',

  TRACK_IN_TO_PLAYHEAD = 'track:move-in-to-playhead',
  TRACK_MOVE_BACKWARD = 'track:move-backward',
  TRACK_MOVE_BACKWARD_TEN_FRAME = 'track:move-backward-ten-frame',
  TRACK_MOVE_FORWARD = 'track:move-forward',
  TRACK_MOVE_FORWARD_TEN_FRAME = 'track:move-forward-ten-frame',
  TRACK_OUT_TO_PLAYHEAD = 'track:move-out-to-playhead',

  UI_ANIMATED_DELETE = 'ui:animated-delete',
  UI_DELETE = 'ui:delete',
  UI_REDO = 'ui:redo',
  UI_UNDO = 'ui:undo',
}
