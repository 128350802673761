/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { SceneNameType } from '~/data/constant';
import { useCreatorStore } from '~/store';
import { truncateString } from '~/utils';

export const SceneLabel: React.FC = () => {
  const [selectedPrecompositionName, sceneName] = useCreatorStore((state) => [
    state.toolkit.selectedPrecompositionJson?.properties.nm as string,
    state.toolkit.json?.properties.nm as string,
  ]);

  return (
    <div
      id="scene-label"
      className="pointer-events-none absolute translate-y-[-18px] whitespace-nowrap text-[12px] leading-none"
    >
      <span id="scene-size-label" className="overflow-hidden text-gray-400" />
      <span className="overflow-hidden font-bold text-white">
        {truncateString(selectedPrecompositionName || sceneName || SceneNameType.MainScene, 28)}
      </span>
    </div>
  );
};
