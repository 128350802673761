/**
 * Copyright 2024 Design Barn Inc.
 */

import { clamp } from 'lodash-es';
import React, { useCallback } from 'react';

import { MouseAction } from '@/ColorPicker/components/ColorPicker/ColorPickerPopover';
import { useMouseEvents } from '@/ColorPicker/hooks';

export interface GradientPointType {
  alpha: number;
  blue: number;
  green: number;
  left: number;
  red: number;
}

interface Props {
  activePointIndex: number;
  changeActivePointIndex: (index: number) => void;
  hexColor: string;
  index: number;
  point: GradientPointType;
  points: GradientPointType[];
  updateGradientLeft: (left: number, index: number, name: MouseAction) => void;
  width: number;
}

export const GradientPoint: React.FC<Props> = ({
  activePointIndex,
  changeActivePointIndex,
  hexColor,
  index,
  point,
  points,
  updateGradientLeft,
  width,
}) => {
  const activeClassName = activePointIndex === index ? ' active' : '';

  const mouseDownHandler = useCallback(
    (event: MouseEvent) => {
      changeActivePointIndex(index);
      const startX = event.pageX;
      const startY = event.pageY;

      const containerRect = document.getElementById('color-picker')?.getBoundingClientRect() as DOMRect;
      const offsetX = startX - containerRect.left - 24;

      updateGradientLeft(point.left, index, MouseAction.onStartChange);

      return {
        startX,
        startY,
        offsetX,
      };
    },
    [point.left, index, changeActivePointIndex, updateGradientLeft],
  );

  const changeObjectPositions = useCallback(
    (event: MouseEvent, { offsetX, startX }: { offsetX: number; startX: number }) => {
      const moveX = event.pageX - startX;

      const _offsetX = offsetX + moveX;
      // update point percent
      const left = clamp(_offsetX / width, 0, 1);

      return {
        positions: {
          offsetX: _offsetX,
          startX: event.pageX,
        },
        left,
      };
    },
    [width],
  );

  const mouseMoveHandler = useCallback(
    (event: MouseEvent, { offsetX, startX }: { offsetX: number; startX: number }) => {
      const { left, positions: _positions } = changeObjectPositions(event, { startX, offsetX });

      updateGradientLeft(left, index, MouseAction.onChange);

      const sortedPoints = points.slice().sort((first, second) => first.left - second.left);
      const newIndex = sortedPoints.findIndex((currentPoint) => currentPoint.left === left);

      changeActivePointIndex(newIndex);

      return _positions;
    },
    [changeObjectPositions, updateGradientLeft, index, points, changeActivePointIndex],
  );

  const mouseUpHandler = useCallback(() => {
    updateGradientLeft(point.left, index, MouseAction.onEndChange);
  }, [index, point.left, updateGradientLeft]);

  const mouseEvents = useMouseEvents(mouseDownHandler, mouseMoveHandler, mouseUpHandler);

  const onMouseDown = (event: MouseEvent): void => {
    changeActivePointIndex(index);
    mouseEvents(event);
  };

  const onMouseUp = (event: MouseEvent): void => {
    mouseEvents(event);
  };

  const pointerClickHandler = (event: MouseEvent): void => {
    event.stopPropagation();
  };

  const pointStyle = {
    left: `${point.left * width - 6}px`,
    backgroundColor: `#${hexColor}`,
  };

  return (
    <div
      className={`picker-pointer${activeClassName}`}
      onClick={pointerClickHandler}
      style={pointStyle}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    ></div>
  );
};
