/**
 * Copyright 2023 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import { Close } from '~/assets/icons';
import { Modal } from '~/components/Elements/Modal';

export interface KeyboardShortcutsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const KeyboardShortcutsModal: React.FC<KeyboardShortcutsModalProps> = (props: KeyboardShortcutsModalProps) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center  text-center">
            <Dialog.Panel className="h-[328px] w-[610px] overflow-hidden rounded-md bg-gray-800 align-middle shadow-sm transition-all">
              <Dialog.Title as="h3" className=" relative mt-7 mb-4 h-10 px-6 text-left text-xl font-bold text-white">
                Keyboard shortcuts
                <button className="absolute right-4">
                  <Close
                    className="h-6 w-6 cursor-pointer fill-current text-gray-500 hover:opacity-50"
                    onClick={props.onClose}
                  />
                </button>
              </Dialog.Title>
              <iframe className="h-full w-full" src="https://releases.lottiefiles.com/creator-keyboard-shortcuts" />
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Modal>
  );
};
