/**
 * Copyright 2024 Design Barn Inc.
 */

import { MathUtils } from 'three';

import { cursorBaseAngles, TransformType, cursorUrls } from './constant';

import { ToolType } from '~/data/constant';
import type { CObject3D } from '~/features/canvas';
import { useCreatorStore } from '~/store';

export function getCursorImageUrl(pivotDragging: boolean, mode: TransformType): string {
  if (pivotDragging) {
    return cursorUrls.Pivot;
  }

  if (useCreatorStore.getState().ui.currentTool === ToolType.Shape) {
    return cursorUrls.Drawing;
  }

  return cursorUrls[mode];
}

export function getCursorAngle(mode: string, tag: string, intersect?: CObject3D): number {
  if (useCreatorStore.getState().ui.currentTool === ToolType.Shape) {
    return 90;
  }

  if (mode === TransformType.Translation) {
    return 0;
  }

  const angleOnMode = cursorBaseAngles[mode as TransformType];

  if (angleOnMode && intersect) {
    return Math.floor(MathUtils.radToDeg(intersect.rotation.z) + Number(angleOnMode[tag]));
  }

  return 0;
}

export function getCursorOffset(mode: string): { deltaX: number; deltaY: number } {
  if (useCreatorStore.getState().ui.currentTool === ToolType.Shape) {
    return { deltaX: 6, deltaY: 4 };
  }

  if (mode === TransformType.Pivot) {
    return { deltaX: 16, deltaY: 16 };
  }

  return { deltaX: 4, deltaY: 2 };
}
