/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable no-undefined */
import { GradientFillType } from '@lottiefiles/toolkit-js';
import { throttle } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';

import type { ColorOptional, RGBA } from '..';
import { MouseAction } from '..';
import { Area } from '../Area';
import DefaultColorPanel from '../DefaultColorPanel';

import { getRightValue } from '@/ColorPicker/helpers';

interface Props {
  alpha: number;
  blue: number;
  green: number;
  onChange: (c: RGBA) => void;
  onEndChange?: (c: RGBA) => void;
  onStartChange?: ((c: RGBA) => void) | undefined;
  red: number;
  showDocumentColors?: boolean;
  showOpacityControl?: boolean;
}
export const Solid: React.FC<Props> = ({
  alpha,
  blue,
  green,
  onChange,
  onEndChange,
  onStartChange,
  red,
  showDocumentColors,
  showEyeDropper = true,
  showOpacityControl = true,
}) => {
  const [colorRed, setColorRed] = useState(red);
  const [colorGreen, setColorGreen] = useState(green);
  const [colorBlue, setColorBlue] = useState(blue);
  const [colorAlpha, setColorAlpha] = useState(alpha);

  useEffect(() => {
    setColorAlpha(alpha);
    setColorRed(red);
    setColorBlue(blue);
    setColorGreen(green);
  }, [alpha, blue, green, red]);

  const actions = useMemo(
    () => ({
      onChange,
      onStartChange,
      onEndChange,
    }),
    [onChange, onEndChange, onStartChange],
  );

  const updateColor = throttle(
    (
      {
        alpha: _alpha,
        blue: _blue,
        green: _green,
        hue: _hue,
        red: _red,
        saturation: _saturation,
        value: _value,
      }: ColorOptional,
      actionName: MouseAction = MouseAction.onChange,
    ) => {
      let redValue = colorRed;
      let greenValue = colorGreen;
      let blueValue = colorBlue;
      let alphaValue = colorAlpha;

      if (_red !== undefined) {
        redValue = getRightValue(_red, colorRed);

        setColorRed(redValue);
      }
      if (_green !== undefined) {
        greenValue = getRightValue(_green, colorGreen);

        setColorGreen(greenValue);
      }
      if (_blue !== undefined) {
        blueValue = getRightValue(_blue, colorBlue);

        setColorBlue(blueValue);
      }
      if (_alpha !== undefined && !Number.isNaN(_alpha)) {
        alphaValue = getRightValue(_alpha, colorAlpha);

        setColorAlpha(alphaValue);
      }

      const action = actions[actionName];

      if (action)
        action({
          red: redValue,
          green: greenValue,
          blue: blueValue,
          alpha: alphaValue,
        });
    },
    50,
  );

  return (
    <>
      <div className="picker-area">
        <Area
          red={colorRed}
          green={colorGreen}
          blue={colorBlue}
          alpha={colorAlpha}
          updateRgb={updateColor}
          isGradient={false}
          type={GradientFillType.NONE}
          showOpacityControl={showOpacityControl}
          showEyeDropper={showEyeDropper}
        />
      </div>
      {showDocumentColors && <DefaultColorPanel setColor={updateColor} solidColor={{ red, green, blue, alpha }} />}
    </>
  );
};
