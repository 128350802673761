/**
 * Copyright 2022 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { useCallback, Fragment, useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { UploadModal } from './UploadModal';

import { Upload, Link } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { ImportType } from '~/data/constant';
import { useCreatorStore } from '~/store';

const DropDownList = [
  {
    type: ImportType.Upload,
    activeIcon: <Upload />,
    inactiveIcon: <Upload />,
    label: 'Upload asset',
  },
  {
    type: ImportType.Url,
    activeIcon: <Link />,
    inactiveIcon: <Link />,
    label: 'Open animation from URL',
  },
];

interface InsertProps {
  defaultIsOpen?: boolean;
  id?: string;
}

export const Insert: React.FC<InsertProps> = ({ defaultIsOpen = false, id }: InsertProps) => {
  const [importType, setImportType] = useState<ImportType>(ImportType.Upload);
  const [isUploadModalOpened, setIsUploadModalOpened] = useCreatorStore(
    (state) => [state.ui.isUploadModalOpened, state.ui.setIsUploadModalOpened],
    shallow,
  );

  useEffect(() => {
    if (defaultIsOpen) {
      setIsUploadModalOpened(true);
    }
  }, [defaultIsOpen, setIsUploadModalOpened]);

  const handleImport = useCallback(
    (type: ImportType) => {
      setImportType(type);
      setIsUploadModalOpened(true);
    },
    [setIsUploadModalOpened],
  );

  const handleClose = useCallback(() => {
    setIsUploadModalOpened(false);
  }, [setIsUploadModalOpened]);

  return (
    <>
      <Menu as="div" className="relative inline-block" id={id}>
        {({ open }) => (
          <>
            <Tooltip content="Upload asset" placement="right" disabled={open}>
              <Menu.Button
                as="div"
                className="group flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded stroke-gray-500 hover:bg-gray-700 hover:stroke-white"
                data-testid="insert-button"
              >
                <Upload
                  className={clsx(
                    'h-[16px] w-[16px] cursor-pointer text-gray-400',
                    open ? 'text-[#00C1A2]' : 'group-hover:text-white',
                  )}
                />
              </Menu.Button>
            </Tooltip>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute -top-3 left-9 z-dropdown w-[240px] cursor-pointer rounded-lg border border-gray-700 bg-gray-800 p-2 shadow-dropdown focus:outline-none">
                {DropDownList.map((dropdown, index) => (
                  <div key={dropdown.type}>
                    <Menu.Item>
                      <div
                        className={`group flex w-full text-xs text-white ${
                          index === DropDownList.length - 1 ? '' : 'mb-1'
                        }`}
                        onClick={() => handleImport(dropdown.type)}
                      >
                        <div className="flex w-full items-center justify-start rounded-lg px-1 hover:bg-gray-600">
                          {React.cloneElement(dropdown.activeIcon, {
                            className: 'h-6 w-6 py-1 text-gray-400 mr-1',
                          })}
                          {dropdown.label}
                        </div>
                      </div>
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <UploadModal isOpen={isUploadModalOpened} onClose={handleClose} importType={importType} initialError={null} />
    </>
  );
};
