/**
 * Copyright 2024 Design Barn Inc.
 */

import { FloatingPortal } from '@floating-ui/react-dom-interactions';
import clsx from 'clsx';
import type { FC, HTMLAttributes, JSXElementConstructor, ReactNode } from 'react';
import React, { isValidElement } from 'react';

import { Divider } from '../Divider';

import { useFloatingWindow } from './useFloatingWindow';

import { Close } from '~/assets/icons';

interface WindowCloseButtonProps {
  onClick: (() => void) | undefined;
}

export const WindowCloseButton: FC<WindowCloseButtonProps> = ({ onClick }) => (
  <Close className="h-6 w-6 shrink-0 cursor-pointer p-1 hover:opacity-50" onClick={onClick} />
);

interface WindowHeaderProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  onClose: WindowCloseButtonProps['onClick'];
  title?: string | undefined;
}

export const WindowHeader: FC<WindowHeaderProps> = ({ className, onClose, title, ...restProps }) => (
  <>
    <div
      className={clsx('flex h-10 cursor-move items-center justify-between gap-4 pl-4 pr-3', className)}
      {...restProps}
    >
      <span className="text-xs font-bold">{title}</span>
      <WindowCloseButton onClick={onClose} />
    </div>
  </>
);

export const WindowHeaderSlot: FC<{ children: ReactNode }> = ({ children }) => <>{children}</>;

export interface WindowProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  nodeRef?: React.RefObject<HTMLDivElement>;
  onClose?: () => void;
  title?: string;
}

export const Window: FC<WindowProps> = ({ children, className, nodeRef, onClose, style, title, ...restProps }) => {
  const slots: Record<string, ReactNode> = {};

  const content = React.Children.map(children, (child) => {
    if (
      isValidElement(child) &&
      ((child.type as JSXElementConstructor<unknown>).name === WindowHeader.name ||
        (child.type as JSXElementConstructor<unknown>).name === WindowHeaderSlot.name)
    ) {
      slots['header'] = child;

      return null;
    }

    return child;
  });

  return (
    <div
      data-no-dnd
      ref={nodeRef}
      className={clsx('absolute rounded-md border border-solid border-gray-600 bg-[#20272C] text-white', className)}
      style={{
        right: 256,
        top: 16,
        boxShadow: '0px 8px 48px 0px #20272C26, 0px 8px 16px 0px #0000008F, 0px 0px 1px 0px #FFFFFF5C',
        ...style,
      }}
      {...restProps}
    >
      {slots['header']}
      {slots['header'] ? <Divider /> : null}
      <div className="relative">
        {content}
        <div className={clsx('absolute inset-0', { hidden: style?.zIndex === 100 })} />
      </div>
    </div>
  );
};

const floatingWindowsContainerId = 'floating-windows-container';

interface FloatingWindowProps {
  children: (args: ReturnType<typeof useFloatingWindow>) => React.ReactNode;
}

/**
 * Make sure to have a `<FloatingWindowsContainer />` component in the app to open/close multiple floating windows properly.
 */
export const FloatingWindow: FC<FloatingWindowProps> = ({ children }) => {
  const { windowId, windowProps } = useFloatingWindow();

  return (
    <FloatingPortal id={floatingWindowsContainerId}>
      {children({
        windowId,
        windowProps,
      })}
    </FloatingPortal>
  );
};

/**
 * Creates a placeholder for `<FloatingWindow />`s to append to.
 */
export const FloatingWindowsContainer: FC = () => <FloatingPortal id={floatingWindowsContainerId} />;
