/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  GroupShapeJSON,
  Scene as ToolkitScene,
  SizeJSON,
  PrecompositionLayer,
  PathShape,
} from '@lottiefiles/toolkit-js';
import { PrecompositionAsset, ShapeType } from '@lottiefiles/toolkit-js';
import CameraControls from 'camera-controls';
import type { IUniform } from 'three';
import {
  DoubleSide,
  PlaneGeometry,
  ShaderMaterial,
  Vector2,
  WebGLRenderTarget,
  OrthographicCamera,
  Raycaster,
  Scene,
  Vector3,
  WebGLRenderer,
  Color,
} from 'three';
// eslint-disable-next-line import/no-namespace
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass';

import fragmentShader from '../3d/shaders/canvas-background/canvas-bg-fragment-shader.frag?raw';
import vertexShader from '../3d/shaders/canvas-background/canvas-bg-vertex-shader.vert?raw';
import { getBackground } from '../3d/threeFactory/background';
import DragSelector from '../3d/utils/dragSelector';
import { getMouseCoord } from '../3d/utils/mouse';
import { clearThree, getPointer, unProject } from '../3d/utils/three';
import { CanvasContextHelper } from '../canvasContextHelper';
import { ViewportConfig } from '../config';
import { DOUBLE_CLICK_DELAY, UserDataMap, ElementTags, BLACK_COLOR } from '../constant';
import { OverlayCanvas } from '../overlayCanvas';
import { getCursorStyle } from '../styleMapper';
import { ToolkitListener } from '../toolkit/listener';
import { CMesh, CObject3D } from '../types/object';
import { getDevicePixelRatio } from '../util';

import Editor from './editor';

import { ellipseOption, rectangleOption } from '~/components/Layout/Header/ShapeMenu';
import { ToolType } from '~/data/constant';
import { updatePivotPointTexture } from '~/features/canvas';
// eslint-disable-next-line no-restricted-imports
import { Background } from '~/features/canvas/3d/threeFactory/background';
// eslint-disable-next-line no-restricted-imports
import { MovePlayHeadTool } from '~/features/timeline/MovePlayHeadTool';
import { canvasMap, scenePropertiesMap, setScenePropertiesMap } from '~/lib/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { layerMap, resetLayerUI } from '~/lib/layer';
import { Box3 } from '~/lib/threejs/Box3';
import { GradientControls } from '~/lib/threejs/GradientControls';
import { PathControls } from '~/lib/threejs/PathControls';
import { ShapeTool } from '~/lib/threejs/ShapeTool/ShapeTool';
import { TransformControls } from '~/lib/threejs/TransformControls';
import drawCursorImg from '~/lib/threejs/TransformControls/drawCursor.svg';
import type { Scalar2D, ShapeOption } from '~/lib/toolkit';
import { getSceneSize, getActiveScene, createShape, getToolkitState, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { LocalStorageKey, PropertyPanelType } from '~/store/constant';
import { GlobalCursorType, GlobalCursorUpdate } from '~/store/uiSlice';
import { isMacOS } from '~/utils';

CameraControls.install({ THREE });
const clock = new THREE.Clock();

// In the latest THREE.JS versions, this was set to true by default.
// more info: https://threejs.org/docs/#manual/en/introduction/Color-management
THREE.ColorManagement.enabled = false;

const setZoomPercentage = useCreatorStore.getState().ui.setZoomPercentage;
const setHasShownOnetimeTooltip = useCreatorStore.getState().ui.setHasShownOnetimeTooltip;
const setHovered = useCreatorStore.getState().ui.setCanvasHoveredNodeId;
const setPivotVisibility = useCreatorStore.getState().ui.setPivotVisibility;
const setSizeRatioLocked = useCreatorStore.getState().ui.setSizeRatioLocked;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;
const setPropertyPanel = useCreatorStore.getState().ui.setPropertyPanel;
const setCanvasCoord = useCreatorStore.getState().canvas.setCoord;

interface AdjustCameraOption {
  zoomToBoundingBox?: boolean;
  zoomToFit?: boolean;
}

export default class Viewport {
  public camera: OrthographicCamera;

  public cameraControls: CameraControls | null = null;

  public canvasHelper: CanvasContextHelper;

  public canvasResizing = false;

  public canvasSize: SizeJSON | null = null;

  public composer: EffectComposer | null = null;

  public container: HTMLCanvasElement;

  public cursorRightClick: boolean = false;

  public dragSelector: DragSelector | null = null;

  public editor: Editor | null = null;

  public gradientControls: GradientControls;

  public gridBackground: CMesh | null = null;

  public hoveredID: string | null = null;

  public isReRenderingNeeded: boolean = true;

  public movePlayHeadTool: MovePlayHeadTool;

  // any objects added to the scene from toolkit json will be included as a child of this object
  public objectContainer = new CObject3D();

  public overlayCanvas: OverlayCanvas;

  public overlayCanvasElement: HTMLCanvasElement;

  public pathControls: PathControls;

  public pixelRatio = getDevicePixelRatio();

  public raycaster = new Raycaster();

  public renderer: WebGLRenderer | null = null;

  public scene = new Scene();

  public selectedIDs: string[] = [];

  public selectedToolbar: ToolType;

  public shapeTool: ShapeTool;

  public ssaaRenderPass: SSAARenderPass;

  public toolkitListener: ToolkitListener;

  public transformControls: TransformControls;

  private readonly _alphaScene = new Scene();

  public constructor({
    artboard,
    horizontalRuler,
    overlay,
    verticalRuler,
  }: Record<'artboard' | 'overlay' | 'horizontalRuler' | 'verticalRuler', HTMLCanvasElement>) {
    this.container = artboard;
    this.overlayCanvasElement = overlay;

    const width = this.container.clientWidth;
    const height = this.container.clientHeight;

    this.camera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 0.1, 1050);
    this.camera.zoom = ViewportConfig.CameraZoom;
    // this will refine the camera up vector and its position
    // so that the coordinate origin can be placed at the top left of the canvas
    this.camera.up.set(0, -1, 0);
    this.camera.position.set(
      ViewportConfig.Background.w / 2,
      ViewportConfig.Background.h / 2,
      ViewportConfig.CameraZPosition,
    );
    this.camera.updateProjectionMatrix();

    this.scene.add(this.objectContainer);

    this.selectedToolbar = ToolType.Move;
    this.toolkitListener = new ToolkitListener(this);
    this.shapeTool = new ShapeTool(this);
    this.movePlayHeadTool = new MovePlayHeadTool(this.overlayCanvasElement);

    this._initRenderer();
    this._initCameraControls();
    this._initGridBackground();
    this._saveCurrentCameraProperties();

    this.canvasHelper = new CanvasContextHelper(this);

    const json = getToolkitState(toolkit);

    if (json) {
      const size = json.properties.sz as SizeJSON;

      this.adjustCanvas(size);
    }

    this.transformControls = new TransformControls(this);
    this.scene.add(this.transformControls);
    if (this.renderer)
      this.dragSelector = new DragSelector(
        this.camera,
        this.scene,
        this.renderer,
        this.overlayCanvasElement,
        this.transformControls,
      );

    this.editor = new Editor(this.scene, this.objectContainer, this.transformControls);
    this.pathControls = new PathControls(this.overlayCanvasElement, this);
    this.gradientControls = new GradientControls(this.overlayCanvasElement, this);

    this.overlayCanvas = new OverlayCanvas({
      element: overlay,
      horizontalRulerElement: horizontalRuler,
      verticalRulerElement: verticalRuler,
      viewport: this,
    });

    this.ssaaRenderPass = new SSAARenderPass(this.scene, this.camera);
    // range (1, 5)
    this.ssaaRenderPass.sampleLevel = 2;
    this.composer?.addPass(this.ssaaRenderPass);
    this._addEventListeners();
    this._step();

    this.cameraControls?.addEventListener('controlend', this._saveCurrentCameraProperties);
  }

  // depending on the canvas size, we need to adjust the camera zoom value
  // so that the canvas always fit into the screen
  public adjustCamera(size?: SizeJSON | null, options?: AdjustCameraOption): void {
    const zoomToFit = options?.zoomToFit || false;
    const zoomToBoundingBox = options?.zoomToBoundingBox || false;

    const canvas = document.getElementById('artboard-canvas');

    if (!canvas) {
      // eslint-disable-next-line no-console
      console.error('canvas does not exist');

      return;
    }

    let width = null;
    let height = null;
    let centerX = null;
    let centerY = null;

    if (zoomToBoundingBox) {
      // 80%
      const zoomOutFactor = 1 / 0.8;

      if (this.transformControls.objects.length > 0) {
        const transformBox = this.transformControls.getBoundingBox();

        width = transformBox.width * zoomOutFactor;
        height = transformBox.height * zoomOutFactor;

        centerX = transformBox.center.x;
        centerY = transformBox.center.y;
      } else {
        // Zoom to fit everything (visible) in the canvas
        const scene = getActiveScene(toolkit);

        if (!scene) {
          return;
        }
        const allObjectsInThisScene = scene.layers
          .filter((layer) => !layer.isHidden)
          .map((layer) => canvasMap.get(layer.nodeId)) as CObject3D[];

        // Zoom to fit if there is nothing visible in the canvas
        if (allObjectsInThisScene.length === 0) {
          this.adjustCamera(size, { zoomToFit: true });

          return;
        }

        const transformBox = new Box3().setFromObjects(allObjectsInThisScene, true);

        width = transformBox.width * zoomOutFactor;
        height = transformBox.height * zoomOutFactor;

        centerX = transformBox.center.x;
        centerY = transformBox.center.y;
      }
    } else {
      const _size = size || ViewportConfig.Background;

      this.canvasSize = _size;
      width = _size.w;
      height = _size.h;
      centerX = _size.w / 2;
      centerY = _size.h / 2;
    }

    const rulersEnabled = useCreatorStore.getState().canvas.rulers.enabled;

    this.camera.zoom = Math.min(
      (canvas.clientWidth - ViewportConfig.Margin - (rulersEnabled ? ViewportConfig.RulerSize : 0)) / width,
      (canvas.clientHeight -
        ViewportConfig.Margin -
        // 24 to accomodate scene label
        (rulersEnabled ? ViewportConfig.RulerSize + 24 : 0) -
        (zoomToFit ? ViewportConfig.CanvasBottomContent : 0)) /
        height,
    );

    this.camera.updateProjectionMatrix();
    this.cameraControls?.setLookAt(centerX, centerY, ViewportConfig.CameraZPosition, centerX, centerY, 0, true);
    this.camera.updateProjectionMatrix();

    const activeScene = getActiveScene(toolkit);

    if (activeScene) {
      setScenePropertiesMap(activeScene.nodeId, {
        cameraPosition: { x: centerX, y: centerY },
        canvasZoom: this.camera.zoom,
      });
    }

    if (this.gridBackground) {
      this.gridBackground.scale.set(1 / this.camera.zoom, 1 / this.camera.zoom, 1);
    }
    const percentage = (100 * this.camera.zoom) / ViewportConfig.CameraZoom;

    setZoomPercentage(percentage);
  }

  // adjust canvas size depending on the size of the added object
  public async adjustCanvas(size: SizeJSON): Promise<void> {
    this.scene.children
      .filter((child) => child.name === Background.Canvas)
      .forEach((background) => clearThree(background));

    this.canvasSize = size;
    const backgroundPlane = await getBackground(size.w, size.h);

    this.editor?.addObject(backgroundPlane, this.scene);
  }

  public clearScene(): void {
    // this.transformControls.detach();
    while (this.objectContainer.children.length > 0) {
      const child = this.objectContainer.children[0];

      clearThree(child);
    }
    this.isReRenderingNeeded = true;
  }

  public createBasicShapes(shapeType: ShapeType): void {
    const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
    const toolkitScene = toolkit.scenes[sceneIndex];
    const json = getToolkitState(toolkit);

    if (!json) return;
    const outPoint = json.timeline.properties.op as number;
    const currentFrame = useCreatorStore.getState().toolkit.currentFrame;
    const width = (json.properties.sz as SizeJSON).w as number;
    const height = (json.properties.sz as SizeJSON).h as number;

    const position: Scalar2D = [width / 2, height / 2];

    let shapeOption: ShapeOption | undefined;

    if (shapeType === ShapeType.RECTANGLE) shapeOption = rectangleOption;
    else if (shapeType === ShapeType.ELLIPSE) shapeOption = ellipseOption;
    if (!shapeOption || !toolkitScene) return;
    stateHistory.beginAction();
    createShape(toolkitScene, {
      ...shapeOption,
      endFrame: outPoint,
      startFrame: currentFrame,
      position,
    });
    stateHistory.endAction();
    // stateHistory.removeTrackedEvent('addChild');

    emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
  }

  public resize(): void {
    const parent = this.container.parentNode;

    if (!parent) return;

    const width = (parent as Element).clientWidth;
    const height = (parent as Element).clientHeight;

    if (this.camera instanceof OrthographicCamera) {
      this.camera.left = width / -2;
      this.camera.right = width / 2;
      this.camera.top = height / 2;
      this.camera.bottom = height / -2;
    }

    this.camera.updateProjectionMatrix();

    if (this.renderer && this.composer) {
      this.renderer.setSize(width, height);
      this.composer.setSize(width, height);
      this.composer.render();
    }
  }

  public select(selectIDs: string[]): void {
    this.selectedIDs = selectIDs;

    // update the selected node id of the toolkit state when user select the object
    if (selectIDs.length) {
      if (this.dragSelector?.secondaryDragSelector) {
        this.toggleSelection(selectIDs);
        (this.dragSelector as DragSelector).secondaryDragSelector = false;

        return;
      }

      addToSelectedNodes(selectIDs, true);
      this.updateContainerCursor(GlobalCursorUpdate.MOVE);
    }

    if (selectIDs.length === 0 && useCreatorStore.getState().ui.selectedNodesInfo.length !== 0) {
      useCreatorStore.getState().ui.removeSelectedNodes();
    }
  }

  public selectAll(): void {
    const precompId = useCreatorStore.getState().toolkit.selectedPrecompositionId;
    const scene = precompId
      ? (toolkit.getNodeById(precompId) as ToolkitScene)
      : (toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as ToolkitScene);
    const layerIDs = scene.layers.map((layer) => layer.nodeId) as string[];

    addToSelectedNodes(layerIDs, true);
  }

  public selectByID(ids: string[]): void {
    const currentPropertyPanel = useCreatorStore.getState().ui.currentPropertyPanel;
    const currentTool = useCreatorStore.getState().ui.currentTool;

    const selectedObjects = ids.map((id) => canvasMap.get(id)).filter(Boolean);

    if (selectedObjects.length === 0) {
      this.transformControls.detach();
      this.transformControls.lastSelectedObjectIDs = [];

      return;
    }
    const multiSelect = selectedObjects.length > 1;

    if (multiSelect) {
      this.transformControls.attachMultiple(ids, false, true);
      this.selectedIDs = ids;
      setPivotVisibility(true);
    } else {
      const selected = selectedObjects[0] as CObject3D | CMesh;

      const pathSelect =
        currentPropertyPanel === PropertyPanelType.EditPath || currentPropertyPanel === PropertyPanelType.Mask;
      const selectedId = pathSelect ? (ids[0] as string) : selected.toolkitId;
      const node = getNodeByIdOnly(selectedId) as PathShape | null;

      const nonTransformable = selected instanceof CMesh;

      this.selectedIDs = nonTransformable ? [] : [selectedId];
      // Pass fromCanvas = false as indicator that this event is triggered from UI
      // this._objectSelected(selected, nonTransformable ? id : null);
      this.transformControls.detach();
      if (nonTransformable) {
        if (this.pathControls.penEnabled) {
          this.transformControls.hideBoundingBox();
        } else if (!useCreatorStore.getState().canvas.hideTransformControls) {
          this.transformControls.showBoundingBox(selected, true);
        }

        if (node) {
          setSizeRatioLocked(Boolean(node.data.get(UserDataMap.SizeRatioLock)));
          this.pathControls.updatePathShapeControls(node);
        }

        return;
      }
      this.transformControls.attach(selected);

      if (node) {
        if (currentTool !== ToolType.Pen) this.pathControls.disablePenControls();
      }
    }
  }

  public toggleSelection(ids: string[]): void {
    if (ids.length === 0) return;

    ids.forEach((id) => {
      const isSelected = useCreatorStore.getState().ui.selectedNodesInfo.find((node) => node.nodeId === id);

      // if the user has dragged a selection to align it, do not unselect
      if (this.transformControls.objects.length > 1 && isSelected && !this.transformControls.hasDragged) {
        useCreatorStore.getState().ui.removeSelectedNodes([id]);

        return;
      }

      addToSelectedNodes([id], false);
    });
  }

  public updateContainerCursor(cursor?: string, type?: string): void {
    const globalCursor = useCreatorStore.getState().ui.globalCursor;

    if (globalCursor === GlobalCursorType.RESIZE) {
      // when users drag the resize icon input on property panel, towards canvas
      // Update the custom cursor when mouse entered the canvas space.
      this.overlayCanvasElement.style.cursor = getCursorStyle('pointer', GlobalCursorUpdate.RESIZE);
    } else if (this.selectedToolbar === ToolType.Hand) {
      this.overlayCanvasElement.style.cursor = getCursorStyle('pointer', cursor || GlobalCursorUpdate.GRAB);
    } else if (type && (this.selectedToolbar === ToolType.Pen || type === ToolType.Pen)) {
      if (cursor === 'auto') {
        this.overlayCanvasElement.style.cursor = getCursorStyle('pointer');
      } else {
        this.overlayCanvasElement.style.cursor = cursor as string;
      }
    } else if ((type && type === ToolType.Shape) || this.selectedToolbar === ToolType.Shape) {
      this.overlayCanvasElement.style.cursor = `url(${drawCursorImg}) 12 10, pointer`;
    } else if (this.gradientControls.enabled || this.overlayCanvas.selectedGuide) {
      this.overlayCanvasElement.style.cursor = cursor as string;
    } else {
      this.overlayCanvasElement.style.cursor = getCursorStyle('pointer');
    }
  }

  public zoomCamera(zoomIn: boolean): void {
    const zoomPercentage = (100 * this.camera.zoom) / ViewportConfig.CameraZoom;

    if (zoomIn) {
      if (zoomPercentage < ViewportConfig.MaxZoom * 100) setZoomPercentage(zoomPercentage + 10);
    } else if (zoomPercentage > ViewportConfig.MinZoom * 100) setZoomPercentage(zoomPercentage - 10);
  }

  private _addEventListeners(): void {
    let startTime = 0;

    this.overlayCanvasElement.addEventListener('pointerdown', (event: PointerEvent) => {
      if (this.movePlayHeadTool.isMovingPlayHead) {
        return;
      }

      if (this.selectedToolbar === ToolType.Hand) this.updateContainerCursor(GlobalCursorUpdate.GRABBING);
      if (this.selectedToolbar === ToolType.Shape) {
        this.shapeTool.handlePointerDown(event);

        return;
      }

      this.cursorRightClick = event.button === 2;

      // consider left and right button click
      if (
        this.selectedToolbar === ToolType.Hand ||
        this.selectedToolbar === ToolType.Pen ||
        this.selectedToolbar === ToolType.Anchor ||
        this.gradientControls.enabled ||
        this.overlayCanvas.selectedGuide
      ) {
        return;
      }

      if (event.timeStamp - startTime < DOUBLE_CLICK_DELAY) {
        this._doDoubleClickAction(event);

        return;
      }
      startTime = event.timeStamp;
      // ignore clicks while the transformControl is used
      if (this.transformControls.pivotHovered) return;
      if (
        this.transformControls.objects.length > 0 &&
        this.transformControls.axis &&
        this.transformControls.axis !== 'XY'
      )
        return;

      // if the focus was on a plugin, set it back to Creator
      if (document.activeElement?.tagName === ElementTags.IFRAME) {
        document.body.focus();
      }

      if (document.activeElement && document.activeElement.tagName === ElementTags.INPUT) {
        // trigger the updates of any active input fields
        (document.activeElement as HTMLInputElement).blur();

        setTimeout(() => {
          // avoid changing the right property panel (and losing input values)
          // before the input value has time to update
          this._doClickAction(event);
        }, 10);

        return;
      }

      this._doClickAction(event);

      if (this.transformControls.hotkeys.shiftDown && this.hoveredID && !this.pathControls.penEnabled) {
        this.toggleSelection([this.hoveredID]);
      }
    });

    this.overlayCanvasElement.addEventListener('pointermove', (event: MouseEvent) => {
      if (this.movePlayHeadTool.isMovingPlayHead) {
        return;
      }

      if (this.selectedToolbar === ToolType.Shape) {
        this.shapeTool.handlePointerMove(event);

        return;
      }

      if (this.gradientControls.enabled) {
        return;
      }

      if (this.overlayCanvas.selectedGuide) return;

      if (!this.transformControls.dragging || this.cursorRightClick) {
        this._doHoverAction(event);
      }
      if (
        this.transformControls.hotkeys.shiftDown === false &&
        this.transformControls.hotkeys.altDown &&
        this.transformControls.dragging
      ) {
        this.transformControls.duplicateOnDrag();
      }

      if (event.buttons === 1 && this.pathControls.draggingElement) {
        this.dragSelector?.helper.setVisibility(false);
      }
    });

    this.overlayCanvasElement.addEventListener('pointerup', () => {
      if (this.selectedToolbar === ToolType.Hand) this.updateContainerCursor(GlobalCursorUpdate.GRAB);

      this.transformControls.hasDragged = false;
    });

    this.overlayCanvasElement.addEventListener('pointerenter', (event) => {
      if (this.gradientControls.enabled || this.overlayCanvas.selectedGuide) return;

      this.updateContainerCursor();
      const { dragging } = useCreatorStore.getState().ui.dragToCanvas;

      if (dragging) {
        // For dragging animations from left-panel to canvas:
        // On the time of mouse drag release, DragToCanvasContainer's context (window), will switch back to Canvas context.
        // We need to further re-compute into canvas cursor position.
        const mouseCoord = getMouseCoord(event, this.overlayCanvasElement);
        const currentMousePosition = unProject(mouseCoord, this.camera);

        setCanvasCoord({ x: currentMousePosition.x, y: currentMousePosition.y });
      }
    });

    this.overlayCanvasElement.addEventListener('wheel', (event) => {
      if (this.cameraControls) {
        const isZoom = event.ctrlKey || event.metaKey;

        this.cameraControls.mouseButtons.wheel = isZoom ? CameraControls.ACTION.ZOOM : CameraControls.ACTION.TRUCK;
      }

      this._saveCurrentCameraProperties();

      // Disable default browser pinch zoom, use canvas zoom instead
      if (event.ctrlKey) event.preventDefault();
    });

    this.transformControls.addEventListener('dragging-changed', (evt: THREE.Event) => {
      // Only call toolkit when dragging end
      if (this.transformControls.objects.length === 0 || evt.value) return;
      this.transformControls.onDrag();
    });

    document.body.addEventListener('keyup', (event) => {
      if (
        document.activeElement !== document.body &&
        event.key === 'Enter' &&
        this._isSelectedPathNode() &&
        useCreatorStore.getState().ui.currentPropertyPanel === PropertyPanelType.Path
      ) {
        setPropertyPanel(PropertyPanelType.EditPath);
      }
    });

    // Subscribe to Creator store
    useCreatorStore.subscribe(
      (state) => state.ui.currentTool,
      (currentTool) => this._setToolbar(currentTool),
    );
    useCreatorStore.subscribe(
      (state) => state.ui.currentPropertyPanel,
      (currentPropertyPanel) => {
        const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;

        if (selectedNodesInfo.length > 0 && selectedNodesInfo[0]) {
          const node = getNodeByIdOnly(selectedNodesInfo[0].nodeId);

          if (node && currentPropertyPanel !== PropertyPanelType.Mask) {
            this.selectByID([node.nodeId]);
          }
        }
      },
    );
    useCreatorStore.subscribe(
      (state) => state.ui.zoomPercentage,
      (zoomPercentage) => {
        const zoom = (ViewportConfig.CameraZoom * zoomPercentage) / 100;

        this.cameraControls?.zoomTo(zoom);
        this.camera.zoom = zoom;
        this._syncZoomChange();

        const activeScene = getActiveScene(toolkit);

        if (!activeScene) return;

        const savedCameraPosition = scenePropertiesMap.get(activeScene.nodeId)?.cameraPosition;

        setScenePropertiesMap(activeScene.nodeId, {
          cameraPosition: savedCameraPosition || { x: this.camera.position.x, y: this.camera.position.y },
          canvasZoom: zoomPercentage / 100,
        });
      },
    );
    useCreatorStore.subscribe(
      (state) => state.ui.selectedNodesInfo,
      (selectedNodesInfo) => {
        this.selectByID(selectedNodesInfo.map((node) => node.nodeId));
      },
    );
    useCreatorStore.subscribe(
      (state) => state.timeline.hoverId,
      (timelineHoverID) => {
        this._doTimelineHoverAction(timelineHoverID);
      },
    );
    useCreatorStore.subscribe(
      (state) => state.ui.pivotVisible,
      (visible) => {
        if (this.transformControls.objects.length > 0 && this.transformControls.pivotPoint) {
          this.transformControls.pivotPoint.visible = visible;
        }
      },
    );
    useCreatorStore.subscribe(
      (state) => state.ui.currentTool === ToolType.Anchor,
      (anchorToolActive) => {
        this.transformControls.anchorToolActive = anchorToolActive;
        updatePivotPointTexture(this.transformControls.pivotPoint as CObject3D, !anchorToolActive);
      },
    );

    useCreatorStore.subscribe(
      (state) => state.timeline.height,
      () => this._onResizeArtboard(),
    );

    useCreatorStore.subscribe(
      (state) => state.toolkit.selectedPrecompositionId,
      () => this._handleOnChangeScene(),
    );

    useCreatorStore.subscribe(
      (state) => state.toolkit.allLayersCount as number,
      (allLayersLength: number) => {
        if (this.gridBackground) {
          const gridBackgroundZposition = Math.max(allLayersLength * 3, 1000);

          // only re-adjust camera and grid background if layers increase
          if (this.gridBackground.position.z < gridBackgroundZposition) {
            this.camera.far = gridBackgroundZposition + 50;
            this.gridBackground.position.z = gridBackgroundZposition;
            this.camera.updateProjectionMatrix();
          }
        }
      },
    );

    useCreatorStore.subscribe(
      (state) => state.ui.canvasHoveredNodeId,
      (hoverId) => this._onHoverChange(hoverId),
    );

    useCreatorStore.subscribe(
      (state) => state.ui.dragToCanvas.executed === true,
      (dragReleased) => {
        if (dragReleased) {
          const { execute } = useCreatorStore.getState().ui.dragToCanvas;

          if (execute) {
            setTimeout(() => {
              execute();
            }, 100);
          }
        }
      },
    );

    useCreatorStore.subscribe(
      (state) => state.canvas.background,
      (background) => {
        const { color, opacity } = background;

        if (this.gridBackground && this.gridBackground.material instanceof THREE.ShaderMaterial) {
          const material = this.gridBackground.material;

          (material.uniforms['uOverlayColor'] as IUniform).value = new Color(color);
          (material.uniforms['uOverlayOpacity'] as IUniform).value = opacity / 100;

          this.isReRenderingNeeded = true;
        }
      },
    );

    this.toolkitListener.start();

    window.addEventListener('resize', () => {
      this._onResizeArtboard();
    });
  }

  private _doClickAction(event: MouseEvent): void {
    if (this.pathControls.penEnabled) {
      this.dragSelector?.onPointerDown(event, true);

      return;
    }
    if (!this.hoveredID) {
      // If there is no hoveredID and SHIFT is not pressed, deselect all and hide transform controls
      if (!this.transformControls.hotkeys.shiftDown && !this.pathControls.hovering) {
        this.select([]);
        this.transformControls.detach();
      }

      if (!this.pathControls.hovering) {
        // If there is no hoveredID, start drag selection
        if (!this.hoveredID) {
          this.dragSelector?.onPointerDown(event);
        }
        this.pathControls.disablePenControls();
      }

      return;
    }

    // If the hoveredID is already selected, update cursor and object position
    const isSelected = useCreatorStore.getState().ui.selectedNodesInfo.find((node) => node.nodeId === this.hoveredID);

    if (isSelected && this.transformControls.objects.length > 0 && this.transformControls.axis) {
      this.transformControls.pointerHover(event as PointerEvent);
      this.transformControls.pointerDown(getPointer(event as PointerEvent, this.overlayCanvasElement));

      return;
    }

    // If the hoveredID is unselected and the SHIFT key is not pressed, select the hoveredID
    if (!this.transformControls.hotkeys.shiftDown) {
      this.select([this.hoveredID]);
      const object = canvasMap.get(this.hoveredID);

      if (!object) return;

      this.transformControls.objects = [object as CObject3D];
      this.transformControls.pointerHover(event as PointerEvent);
      this.transformControls.pointerDown(getPointer(event as PointerEvent, this.overlayCanvasElement));

      // show tooltip to notify users that double click will show shape's properties in the property panel
      // P.S. used local storage to show the tooltip only once in a lifetime
      if (!localStorage.getItem(LocalStorageKey.ShownTooltip)) {
        localStorage.setItem(LocalStorageKey.ShownTooltip, 'true');
        setHasShownOnetimeTooltip();
      }
    }
  }

  // we select one level below child on a double click
  private _doDoubleClickAction(event: MouseEvent): void {
    let intersect = this._getIntersects(event, true);

    if (!intersect || this.pathControls.penEnabled) {
      this.select([]);
      if (!intersect) this.dragSelector?.onPointerDown(event);

      return;
    }

    if (this._isSelectedPathNode() && useCreatorStore.getState().ui.currentPropertyPanel === PropertyPanelType.Path) {
      setPropertyPanel(PropertyPanelType.EditPath);

      return;
    }

    const currentLayer = layerMap.get(intersect.toolkitId);

    if (currentLayer) {
      // Check for current selection
      const prevID = this.transformControls.lastSelectedObjectIDs[0];

      if (prevID) {
        const prevIndex = currentLayer.parent.indexOf(prevID);

        if (prevIndex > -1) {
          const nextLayerId = currentLayer.parent.find(
            (id, index) => index > prevIndex && !layerMap.get(id)?.isAppearance,
          );

          if (nextLayerId) {
            intersect = canvasMap.get(nextLayerId) as CObject3D;
          }
        }
      }

      setHovered(intersect.toolkitId);
      this.select([intersect.toolkitId]);
      localStorage.setItem(LocalStorageKey.ShownTooltip, 'true');

      return;
    }

    const precompId = this._findTopPrecompId(intersect);

    if (precompId) {
      const precompLayer = getNodeByIdOnly(precompId) as PrecompositionLayer | null;

      if (precompLayer && precompLayer.asset) {
        const setSelectedPrecompositionId = useCreatorStore.getState().toolkit.setSelectedPrecompositionId;
        const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;
        const setTabState = useCreatorStore.getState().timeline.setTabState;

        setTabState(precompLayer.asset.name, true);
        setSelectedPrecompositionId(precompLayer.asset.nodeId);
        setCurrentFrame(0);
        resetLayerUI();
        emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
        this.select([]);
      }
    }
  }

  private _doHoverAction(event: MouseEvent): void {
    const intersect = this._getIntersects(event);

    if (intersect) {
      const layerUI = layerMap.get(intersect.toolkitId);

      if (layerUI) {
        const selectedNodeIds = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);
        const selectedId = layerUI.parent.find((value) => selectedNodeIds.includes(value));

        if (selectedId) {
          setHovered(selectedId);
        } else {
          const id = layerUI.parent.length ? (layerUI.parent[0] as string) : intersect.toolkitId;

          setHovered(id);
        }

        return;
      }

      const precompId = this._findTopPrecompId(intersect);

      if (precompId) {
        setHovered(precompId);

        return;
      }
    }

    this.transformControls.hideBoundingBox();
    setHovered('');
  }

  private _doTimelineHoverAction(id: string | null): void {
    const hoveredObject = canvasMap.get(id ?? '');

    if (!id || !hoveredObject) {
      this.transformControls.hideBoundingBox();

      return;
    }

    this.transformControls.showBoundingBox(hoveredObject);
  }

  private _findTopPrecompId(intersect: CObject3D): string | null {
    let precompId = null;
    let parent = intersect.parent as CObject3D | null;

    while (parent) {
      precompId = parent.precompId || precompId;
      parent = parent.parent as CObject3D | null;
    }

    return precompId;
  }

  private _getAlphaForObject(object: CObject3D, mouse: Vector2): number {
    if (!this.renderer) {
      return 0;
    }

    // construct full hierarchy from top parent to the object to have all transformations

    const items = [object.clone()];
    let parent = object.parent;

    while (parent) {
      if (parent.id !== this.scene.id) {
        const parentObj = parent.clone();

        parentObj.clear();
        items.unshift(parentObj as CObject3D);
      }
      parent = parent.parent;
    }

    const objToAdd = items[0] as CObject3D;

    if (items.length > 1) {
      for (let i = 1; i < items.length; i += 1) {
        (items[i - 1] as CObject3D).add(items[i] as CObject3D);
      }
    }

    this._alphaScene.add(objToAdd);

    const renderTarget = new WebGLRenderTarget(this.container.width, this.container.height);

    this.renderer.setRenderTarget(renderTarget);

    this.renderer.render(this._alphaScene, this.camera);

    // one pixel contains 4 numbers: [r, g, b, a]
    const pixelBuffer = new Uint8Array(4);

    const halfWidth = this.renderer.domElement.width / 2;
    const halfHeight = this.renderer.domElement.height / 2;
    const x = halfWidth + mouse.x * halfWidth;
    const y = halfHeight + mouse.y * halfHeight;

    this.renderer.readRenderTargetPixels(renderTarget, x, y, 1, 1, pixelBuffer);
    this.renderer.setRenderTarget(null);

    this._alphaScene.clear();

    // return alpha value (index 3)
    return pixelBuffer[3] as number;
  }

  private _getIntersects(event: MouseEvent, filterTransparentLayers = false): CObject3D | null {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    const mouse = getMouseCoord(event, this.overlayCanvasElement);

    // unproject mouse vector to 3D
    const vec3 = unProject(mouse, this.camera);

    vec3.z = -500;
    const direction = new Vector3(0, 0, 1);

    this.raycaster.set(vec3, direction);
    const intersects = this.raycaster.intersectObject(this.objectContainer, true);
    const intersectsWithoutTransparent = intersects.filter((intersect) => {
      let isVisible = intersect.object.visible;
      let isLocked = intersect.object.userData['isLocked'];
      let parent = intersect.object.parent;

      while (parent) {
        isVisible = isVisible && parent.visible;
        isLocked = isLocked || parent.userData['isLocked'];
        parent = parent.parent;
      }

      if (!isVisible || isLocked) {
        return false;
      }

      if (intersect.object.name === 'outline') {
        return false;
      }

      return filterTransparentLayers ? this._getAlphaForObject(intersect.object as CObject3D, mouse) > 0 : true;
    });

    const minDistance = Math.min(...intersectsWithoutTransparent.map((obj) => obj.distance));
    const lowerObjects = intersectsWithoutTransparent.filter((obj) => obj.distance === minDistance);
    const nearestIntersect = lowerObjects[lowerObjects.length - 1];

    if (!nearestIntersect) return null;

    return nearestIntersect.object as CObject3D | null;
  }

  private _handleOnChangeScene(): void {
    const activeScene = getActiveScene(toolkit);

    if (!activeScene) return;

    const currentSize = getSceneSize(activeScene)?.toJSON();

    if (!currentSize) return;

    if (!scenePropertiesMap.get(activeScene.nodeId)) {
      this.adjustCanvas(currentSize);
      this.adjustCamera(currentSize);

      this.camera.updateProjectionMatrix();

      setScenePropertiesMap(activeScene.nodeId, {
        cameraPosition: { x: currentSize.w / 2, y: currentSize.h / 2 },
        canvasZoom: this.camera.zoom,
      });

      return;
    }

    const { cameraPosition, canvasZoom } = scenePropertiesMap.get(activeScene.nodeId) ?? {};

    if (cameraPosition) {
      this.camera.position.set(cameraPosition.x, cameraPosition.y, ViewportConfig.CameraZPosition);
      this.cameraControls?.setLookAt(
        cameraPosition.x,
        cameraPosition.y,
        ViewportConfig.CameraZPosition,
        cameraPosition.x,
        cameraPosition.y,
        0,
        true,
      );

      if (activeScene instanceof PrecompositionAsset) {
        this.adjustCanvas(currentSize);
      }

      this.camera.updateProjectionMatrix();
    }

    if (canvasZoom) {
      setZoomPercentage(canvasZoom * 100);
    }
  }

  private _initCameraControls(): void {
    this.cameraControls = new CameraControls(this.camera, this.overlayCanvasElement);
    this._setRotation(false);
    this.cameraControls.mouseButtons.right = CameraControls.ACTION.NONE;
    this.cameraControls.mouseButtons.middle = CameraControls.ACTION.NONE;
    this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.TRUCK;
    this.cameraControls.dollyToCursor = true;
    this.cameraControls.dollySpeed = isMacOS ? 2.5 : 0.5;
    this.cameraControls.truckSpeed = 1;
    this.cameraControls.draggingSmoothTime = 0;
    this.cameraControls.smoothTime = 0;
    this.cameraControls.minZoom = ViewportConfig.MinZoom;
    this.cameraControls.maxZoom = ViewportConfig.MaxZoom;

    this.cameraControls.addEventListener('update', () => {
      setZoomPercentage((100 * this.camera.zoom) / ViewportConfig.CameraZoom);
      this._updateGridBackground();
    });

    this.resize();
    setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
  }

  private _initGridBackground(): void {
    const gridMaterial = new ShaderMaterial({
      uniforms: {
        uZoom: { value: this.camera.zoom },
        uViewportSize: { value: new Vector2(this.container.clientWidth, this.container.clientHeight) },
        uCameraPosition: { value: new Vector2(this.camera.position.x, this.camera.position.y) },
        uPixelRatio: { value: this.pixelRatio },
        uOverlayColor: { value: new Color(useCreatorStore.getState().canvas.background.color) },
        uOverlayOpacity: { value: useCreatorStore.getState().canvas.background.opacity },
        uDotColor: { value: new Color(0.855, 0.886, 0.91) },
        uBackgroundColor: { value: new Color(0.957, 0.965, 0.973) },
        uDotRadius: { value: 1.75 },
        uGridSpacing: { value: 20 },
        uCameraFrustum: { value: new Vector2(Math.abs(this.camera.left), Math.abs(this.camera.top)) },
      },
      vertexShader,
      fragmentShader,
      transparent: true,
      side: DoubleSide,
    });

    this.gridBackground = new CMesh(new PlaneGeometry(4000, 4000), gridMaterial);

    this.gridBackground.position.z = 1000;
    this.gridBackground.position.x = this.camera.position.x;
    this.gridBackground.position.y = this.camera.position.y;
    this.scene.add(this.gridBackground);

    this.adjustCamera();
  }

  private _initRenderer(): void {
    // Enable preserveDrawingBuffer so that Usersnap can capture what drawn on canvas
    this.renderer = new WebGLRenderer({
      antialias: true,
      canvas: this.container,
      preserveDrawingBuffer: true,
    });
    this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
    this.renderer.setClearColor(BLACK_COLOR, 0);
    this.renderer.autoClear = false;
    this.renderer.setPixelRatio(this.pixelRatio);

    this.composer = new EffectComposer(this.renderer);
  }

  private _isSelectedPathNode(): boolean {
    const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;

    if (selectedNodesInfo.length > 0 && selectedNodesInfo[0]) {
      const node = getNodeByIdOnly(selectedNodesInfo[0].nodeId);

      if (node && (node.state as GroupShapeJSON).type === ShapeType.PATH) {
        return true;
      }
    }

    return false;
  }

  private _onHoverChange(hoverId: string): void {
    this.hoveredID = hoverId;
    this.transformControls.onHoverChange(this.pathControls.penEnabled ? '' : hoverId);
  }

  private _onResizeArtboard(): void {
    this.resize();
    this._updateSceneLabel();
    this._updateGridBackground();
  }

  private _saveCurrentCameraProperties(): void {
    const activeScene = getActiveScene(toolkit);

    if (!activeScene) return;

    setScenePropertiesMap(activeScene.nodeId, {
      cameraPosition: { x: this.camera.position.x, y: this.camera.position.y },
      canvasZoom: this.camera.zoom,
    });
  }

  private _setPanning(enable: boolean): void {
    if (!this.cameraControls || !this.dragSelector) return;
    if (this.selectedToolbar !== ToolType.Pen) {
      this.cameraControls.mouseButtons.left = enable ? CameraControls.ACTION.TRUCK : CameraControls.ACTION.NONE;
      this.cameraControls.mouseButtons.right = enable ? CameraControls.ACTION.TRUCK : CameraControls.ACTION.NONE;
    }

    this.dragSelector.disable = enable;
  }

  private _setRotation(enable: boolean): void {
    if (!this.cameraControls) return;
    this.cameraControls.mouseButtons.left = enable ? CameraControls.ACTION.ROTATE : CameraControls.ACTION.NONE;
  }

  private _setToolbar(type: ToolType): void {
    this.selectedToolbar = type;

    let timer: NodeJS.Timeout | undefined;

    this.canvasResizing = true;
    if (!timer)
      timer = setTimeout(() => {
        this.canvasResizing = false;
        clearTimeout(timer);
      }, 500);

    this.transformControls.enabled = !(type === ToolType.Hand || type === ToolType.Pen);
    if (type === ToolType.Hand) {
      this.updateContainerCursor(GlobalCursorUpdate.GRAB);
    } else this.updateContainerCursor(GlobalCursorUpdate.DEFAULT);
    if (type === ToolType.Hand) {
      this.transformControls.detach();
      useCreatorStore.getState().ui.removeSelectedNodes();
      this.selectedIDs = [];
      this._setPanning(true);
    } else if (type === ToolType.Pen) {
      this.transformControls.detach();
      this._setPanning(true);
    } else {
      this._setPanning(false);
    }
    if (type === ToolType.Pen) {
      this.pathControls.disablePenControls();
      this.pathControls.enablePenControls();
    }
    if (type !== ToolType.Pen) {
      this.pathControls.disablePenControls();
    }
  }

  private _step(): void {
    requestAnimationFrame(() => this._step());
    if (!this.renderer || !this.cameraControls || !this.composer) return;
    const delta = clock.getDelta();

    const isCameraReRenderingNeeded = this.cameraControls.update(delta);

    if (isCameraReRenderingNeeded || this.isReRenderingNeeded) {
      this.composer.render();
      this.isReRenderingNeeded = false;
    }

    if (isCameraReRenderingNeeded || this.overlayCanvas.isReRenderingNeeded) {
      this.overlayCanvas.render();
      this.overlayCanvas.isReRenderingNeeded = false;
    }

    if (isCameraReRenderingNeeded) {
      this._updateSceneLabel();
      this.canvasHelper.updateCanvasReferences();
    }

    if (this.canvasResizing) this.resize();
  }

  // sync the anchor point as the object moves or the camera zoom changes
  private _syncZoomChange(): void {
    this.transformControls.pivotPoint?.scale.set(1 / this.camera.zoom, 1 / this.camera.zoom, 1);
    this._updateGridBackground();
  }

  private _updateGridBackground(): void {
    if (!this.gridBackground || !(this.gridBackground.material instanceof THREE.ShaderMaterial)) return;

    const material = this.gridBackground.material as THREE.ShaderMaterial;

    (material.uniforms['uZoom'] as IUniform).value = this.camera.zoom;
    (material.uniforms['uCameraPosition'] as IUniform).value.set(this.camera.position.x, -this.camera.position.y);
    (material.uniforms['uViewportSize'] as IUniform).value.set(this.container.clientWidth, this.container.clientHeight);
    (material.uniforms['uCameraFrustum'] as IUniform).value.set(Math.abs(this.camera.left), Math.abs(this.camera.top));

    this.gridBackground.position.x = this.camera.position.x;
    this.gridBackground.position.y = this.camera.position.y;

    this.gridBackground.scale.set(1 / this.camera.zoom, 1 / this.camera.zoom, 1);
  }

  private _updateSceneLabel(): void {
    const sceneLabel = document.getElementById('scene-label');

    if (sceneLabel) {
      this.camera.updateMatrix();
      sceneLabel.style.top = `${this.camera.top - this.camera.position.y * this.camera.zoom}px`;
      sceneLabel.style.left = `${this.camera.right - this.camera.position.x * this.camera.zoom}px`;
      const sizeLabel = document.getElementById('scene-size-label');

      if (sizeLabel && this.canvasSize) {
        sizeLabel.innerText = `${Math.round(this.canvasSize.w)} x ${Math.round(this.canvasSize.h)} `;
      }
    }
  }
}
