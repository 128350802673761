/**
 * Copyright 2024 Design Barn Inc.
 */

import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import type { FC } from 'react';

import type { RecentFile } from '~/providers/api/project';

interface RecentFileMenuItemProps {
  file: RecentFile;
}

export const RecentFileMenuItem: FC<RecentFileMenuItemProps> = ({ file }) => {
  return (
    <div className="flex gap-2 py-2">
      <div
        className="flex max-h-[60px] w-[80px] shrink-0 justify-center overflow-hidden rounded"
        style={{ backgroundColor: file.backgroundColor || '#ffffff' }}
      >
        <img className="h-[60px]" src={file.fileObject.thumbnails.png.medium.url} alt={file.name} />
      </div>
      <div className="flex w-full flex-col gap-0.5 text-left">
        <div className="text-[12px] text-white">{file.name}</div>
        <div className="text-[10px] text-[#A1ADB7]">
          Edited {formatDistanceToNow(file.updatedAt, { addSuffix: true })}
        </div>
      </div>
    </div>
  );
};
