/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

import { AspectRatioLock, AspectRatioUnlock } from '~/assets/icons';

interface TimelineAspectRatioToggleProps {
  isLocked?: boolean;
  onClick?: () => void;
}

export const TimelineAspectRatioToggle: React.FC<TimelineAspectRatioToggleProps> = ({ isLocked = false, onClick }) => {
  const icon = isLocked ? (
    <>
      <AspectRatioLock className="h-3 w-3" />
    </>
  ) : (
    <AspectRatioUnlock className="h-3 w-3 stroke-[#606F7B]" />
  );

  return (
    <div className="mr-[5px]" onClick={onClick}>
      {icon}
    </div>
  );
};
