/**
 * Copyright 2022 Design Barn Inc.
 */

import { Popover } from '@headlessui/react';
import React from 'react';

import type { CatalogueEntry } from './PluginBrowserDialog';

import { Plugin } from '~/assets/icons';
import { getPluginManager } from '~/plugins/PluginManager';

export interface PluginBrowserEntryProps {
  catalogueItem: CatalogueEntry;
  isDev: boolean;
}

export const PluginBrowserEntry: React.FC<PluginBrowserEntryProps> = (props: PluginBrowserEntryProps) => {
  const item = props.catalogueItem;
  const [isLoading, setIsLoading] = React.useState(false);

  const pluginManager = getPluginManager();

  const onClick = (): void => {
    if (isLoading || pluginManager.isLoaded(item.id)) {
      return;
    }

    setIsLoading(true);

    const loadPlugin = async (): Promise<void> => {
      if (props.isDev) {
        await pluginManager.reloadDevPlugin();
      } else {
        await pluginManager.load(item.id);
      }
      setIsLoading(false);
    };

    // eslint-disable-next-line promise/prefer-await-to-then, node/handle-callback-err
    loadPlugin().catch((_err) => {
      // TODO(miljau): do something with error
    });
  };

  let actionButton: React.ReactElement;

  if (isLoading) {
    actionButton = (
      <>
        <div className="flex h-full items-center justify-center px-[10px]">
          <div className="inline-block h-3 w-3 animate-spin rounded-full border-2 border-teal-300 border-r-transparent"></div>
        </div>
      </>
    );
  } else {
    actionButton = (
      <>
        <Popover>
          <Popover.Button as="div" className="flex h-full cursor-pointer items-center justify-center px-[10px]">
            <span>...</span>
          </Popover.Button>
          <Popover.Panel className="absolute rounded-md border border-gray-600 bg-gray-700 p-1 text-[14px] drop-shadow-md">
            <Popover.Button className="w-full rounded p-3 text-left hover:bg-gray-600">
              {props.isDev ? 'Reload' : 'Run'}
            </Popover.Button>
            <Popover.Button
              className="w-full rounded p-3 text-left text-red-500 hover:bg-gray-600"
              onClick={(ev) => {
                ev.stopPropagation();
                if (props.isDev) {
                  pluginManager.unloadDevPlugin();
                } else {
                  pluginManager.unload(item.id);
                }
              }}
            >
              Remove
            </Popover.Button>
          </Popover.Panel>
        </Popover>
      </>
    );
  }

  return (
    <div className="my-[5px]">
      <div className="mx-[10px] flex h-[60px] max-h-[60px] max-w-full rounded-md hover:bg-gray-700" onClick={onClick}>
        <div className="m-[5px] mr-[10px] flex min-w-[50px] items-center justify-center rounded-md border border-dashed border-gray-600">
          <Plugin className="h-[36px] w-[36px] text-gray-400" />
        </div>

        <div className="my-[4px] max-h-[60px] overflow-hidden">
          <div className="text-[13px] text-gray-100">{item.name}</div>
          <div className="text-[12px] text-gray-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Blandit massa enim nec dui. Quis enim lobortis scelerisque fermentum dui faucibus in.
          </div>
        </div>
        {actionButton}
      </div>
    </div>
  );
};
