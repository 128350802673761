/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { AnchorPointGridColors } from '../../constants';

import {
  AnchorPointTopLeft,
  AnchorPointTop,
  AnchorPointTopRight,
  AnchorPointLeft,
  AnchorPointCenter,
  AnchorPointRight,
  AnchorPointBottomLeft,
  AnchorPointBottom,
  AnchorPointBottomRight,
} from '~/assets/icons';
import { DefaultPivotAlignment } from '~/store/constant';
import { AlignPivotDirection } from '~/store/uiSlice';

export const getDefaultAnchorPoint = (anchorPointByCursor: boolean): Record<string, boolean> => {
  let defaultPivotAlignment: Record<string, boolean> = DefaultPivotAlignment;

  if (anchorPointByCursor) {
    const updatedObject = Object.fromEntries(Object.keys(DefaultPivotAlignment).map((key) => [key, false]));

    defaultPivotAlignment = updatedObject;
  }

  return defaultPivotAlignment;
};

interface AnchorPointProps {
  children: React.ReactNode;
  direction: AlignPivotDirection;
  disabled: boolean;
  isActive: boolean;
  onClick: (direction: AlignPivotDirection) => void;
}

const AnchorPoint: React.FC<AnchorPointProps> = ({ children, direction, disabled, onClick }) => {
  return (
    <div
      onClick={() => !disabled && onClick(direction)}
      className={`flex items-center justify-center ${!disabled && 'hover:cursor-pointer hover:stroke-teal-400'}`}
    >
      {children}
    </div>
  );
};

interface NineGridSelectorProps {
  activePoints: Record<string, Record<string, boolean>>;
  anchorPointByCursor?: boolean;
  disabled: boolean;
  multiSelect: boolean;
  nodeId?: string;
  onClick: (direction: AlignPivotDirection) => void;
  tempAnchorPoints?: Record<string, Record<string, boolean>>;
}

export const NineGridSelector: React.FC<NineGridSelectorProps> = ({
  activePoints,
  anchorPointByCursor,
  disabled,
  multiSelect,
  nodeId,
  onClick,
  tempAnchorPoints,
}) => {
  const hasAnchorPointActive = Object.values(activePoints).length > 0;
  const defaultPivotAlignment = getDefaultAnchorPoint(false);

  let activePoint: Record<string, boolean> | undefined | null = null;

  if (multiSelect && !anchorPointByCursor && tempAnchorPoints) {
    activePoint = nodeId ? tempAnchorPoints[nodeId as string] : defaultPivotAlignment;
  } else if (anchorPointByCursor) {
    activePoint = defaultPivotAlignment;
  } else {
    activePoint = hasAnchorPointActive ? activePoints[nodeId as string] : defaultPivotAlignment;
  }

  const getAnchorPointStyle = (direction: AlignPivotDirection): AnchorPointGridColors => {
    if (activePoint?.[direction] && !disabled) {
      return AnchorPointGridColors.ACTIVE;
    }

    return AnchorPointGridColors.INACTIVE;
  };

  const { Bottom, BottomLeft, BottomRight, Center, Left, Right, Top, TopLeft, TopRight } = AlignPivotDirection;

  const anchorPointIcons = {
    [TopLeft]: <AnchorPointTopLeft className={getAnchorPointStyle(TopLeft)} />,
    [Top]: <AnchorPointTop className={getAnchorPointStyle(Top)} />,
    [TopRight]: <AnchorPointTopRight className={getAnchorPointStyle(TopRight)} />,
    [Left]: <AnchorPointLeft className={getAnchorPointStyle(Left)} />,
    [Center]: <AnchorPointCenter className={getAnchorPointStyle(Center)} />,
    [Right]: <AnchorPointRight className={getAnchorPointStyle(Right)} />,
    [BottomLeft]: <AnchorPointBottomLeft className={getAnchorPointStyle(BottomLeft)} />,
    [Bottom]: <AnchorPointBottom className={getAnchorPointStyle(Bottom)} />,
    [BottomRight]: <AnchorPointBottomRight className={getAnchorPointStyle(BottomRight)} />,
  };

  return (
    <div className="relative inline-flex items-center">
      <div className="grid grid-cols-3">
        {Object.entries(anchorPointIcons).map(([direction, icon]) => {
          return (
            <AnchorPoint
              key={direction}
              direction={direction as AlignPivotDirection}
              disabled={disabled}
              isActive={activePoint?.[direction as AlignPivotDirection] || false}
              onClick={onClick}
            >
              {icon}
            </AnchorPoint>
          );
        })}
      </div>
    </div>
  );
};
