/**
 * Copyright 2024 Design Barn Inc.
 */

import type { Marker } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect } from 'react';
import Draggable from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { useTimelineUtils } from '../TimelineKeyframePanel/hooks';

import { addSegment, selectSegment, updateMarkers } from './helper';
import { TimelineSegmentItem } from './TimelineSegmentItem';

import SegmentsAnimation from '~/assets/animations/segments.json';
import { CompTimelineTab, Info } from '~/assets/icons';
import { Divider } from '~/components/Elements/Divider';
import { WindowCloseButton, FloatingWindow, Window, WindowHeaderSlot } from '~/components/Elements/Window';
import { SceneNameType } from '~/data/constant';
import { EventType, SegmentSource } from '~/data/eventStore';
import { getPlaybackParameters } from '~/lib/eventHandler/playback';
import { stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { fireEvent } from '~/utils';

export const TimelineSegmentsDialog: React.FC = () => {
  const nodeRef = React.useRef(null);

  const [
    sceneName,
    sceneIndex,
    isSegmentsDialogOpen,
    isSegmentHovered,
    setIsSegmentsDialogOpen,
    setIsSegmentHovered,
    width,
    workAreaFrameStart,
    workAreaFrameEnd,
    setWorkAreaFrameStart,
    setWorkAreaFrameEnd,
    markers,
    selectedMarker,
    setSelectedMarker,
    prevWorkAreaFrameStart,
    setPrevWorkAreaFrameStart,
    prevWorkAreaFrameEnd,
    setPrevWorkAreaFrameEnd,
    selectedPrecompositionId,
  ] = useCreatorStore(
    (state) => [
      state.toolkit.json?.properties.nm,
      state.toolkit.sceneIndex,
      state.timeline.isSegmentsDialogOpen,
      state.timeline.isSegmentHovered,
      state.timeline.setIsSegmentsDialogOpen,
      state.timeline.setIsSegmentHovered,
      state.timeline.keyframeScrubberWidth,
      state.timeline.workAreaFrameStart,
      state.timeline.workAreaFrameEnd,
      state.timeline.setWorkAreaFrameStart,
      state.timeline.setWorkAreaFrameEnd,
      state.timeline.markers,
      state.timeline.selectedMarker,
      state.timeline.setSelectedMarker,
      state.timeline.prevWorkAreaFrameStart,
      state.timeline.setPrevWorkAreaFrameStart,
      state.timeline.prevWorkAreaFrameEnd,
      state.timeline.setPrevWorkAreaFrameEnd,
      state.toolkit.selectedPrecompositionId,
    ],
    shallow,
  );

  const { duration, fps } = getPlaybackParameters();

  const { totalFrames } = useTimelineUtils(width);

  useEffect(() => {
    if (isSegmentsDialogOpen) {
      updateMarkers();
    } else {
      setSelectedMarker(null);
    }
  }, [isSegmentsDialogOpen, sceneIndex, setSelectedMarker]);

  const onCloseDialogHandler = useCallback(() => {
    setIsSegmentsDialogOpen(false);
  }, [setIsSegmentsDialogOpen]);

  const onSelectSegmentHandler = useCallback((_marker: Marker) => {
    selectSegment(_marker);
  }, []);

  const onAddSegmentHandler = useCallback(() => {
    addSegment();
    fireEvent({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.AddedSegment,
      parameters: {
        source: SegmentSource.SegmentPopover,
      },
    });
  }, []);

  const onDeleteSegmentHandler = useCallback(
    (deleteMarker: Marker) => {
      deleteMarker.removeFromGraph();
      setIsSegmentHovered(false);
      updateMarkers();
    },
    [setIsSegmentHovered],
  );

  const onUpdateSegmentHandler = useCallback(
    (
      marker: Marker,
      startFrame: number | null,
      endFrame: number | null,
      comment: string,
      isAdjustTimeline: boolean = false,
    ) => {
      if (marker.startFrame === startFrame && marker.endFrame === endFrame && marker.comment === comment) {
        return;
      }

      if (marker.startFrame !== startFrame && startFrame !== null) {
        marker.setStartFrame(startFrame);
      }

      if (marker.endFrame !== endFrame && endFrame !== null) {
        marker.setEndFrame(endFrame);
      }

      if (marker.comment !== comment) {
        marker.setComment(comment);
      }

      if (isAdjustTimeline) {
        setWorkAreaFrameStart(marker.startFrame);
        setWorkAreaFrameEnd(marker.endFrame);
        setPrevWorkAreaFrameStart(marker.startFrame);
        setPrevWorkAreaFrameEnd(marker.endFrame);
      }

      setIsSegmentHovered(false);
      updateMarkers();
    },
    [
      setIsSegmentHovered,
      setWorkAreaFrameStart,
      setWorkAreaFrameEnd,
      setPrevWorkAreaFrameStart,
      setPrevWorkAreaFrameEnd,
    ],
  );

  const onHoverSegmentHandler = useCallback(
    (_marker: Marker, isHover: boolean) => {
      if (selectedMarker?.nodeId === _marker.nodeId) {
        setIsSegmentHovered(false);

        return;
      }

      if (isHover) {
        if (!isSegmentHovered) {
          setPrevWorkAreaFrameStart(workAreaFrameStart);
          setPrevWorkAreaFrameEnd(workAreaFrameEnd);
        }
        setWorkAreaFrameStart(_marker.startFrame);
        setWorkAreaFrameEnd(_marker.endFrame);
        setIsSegmentHovered(true);

        return;
      }

      if (prevWorkAreaFrameStart !== null) {
        setWorkAreaFrameStart(prevWorkAreaFrameStart);
      }
      if (prevWorkAreaFrameEnd !== null) {
        setWorkAreaFrameEnd(prevWorkAreaFrameEnd);
      }
      setIsSegmentHovered(false);
    },
    [
      selectedMarker,
      isSegmentHovered,
      setIsSegmentHovered,
      workAreaFrameStart,
      workAreaFrameEnd,
      prevWorkAreaFrameStart,
      prevWorkAreaFrameEnd,
      setPrevWorkAreaFrameStart,
      setPrevWorkAreaFrameEnd,
      setWorkAreaFrameStart,
      setWorkAreaFrameEnd,
    ],
  );

  const onMatchWorkAreaHandler = useCallback(
    (_marker: Marker) => {
      stateHistory.beginAction();

      onUpdateSegmentHandler(_marker, workAreaFrameStart, workAreaFrameEnd, _marker.comment);

      stateHistory.endAction();
    },
    [workAreaFrameStart, workAreaFrameEnd, onUpdateSegmentHandler],
  );

  const onInfoClickHandler = useCallback(() => {
    window.open(
      'https://lottiefiles.com/blog/working-with-lottie-animations/how-to-add-segments-on-your-animations-in-lottie-creator',
      '_blank',
    );
  }, []);

  const isDisabled = selectedPrecompositionId !== null;
  const sceneNameTitle = sceneName || SceneNameType.MainScene;

  return (
    <>
      {isSegmentsDialogOpen && (
        <FloatingWindow>
          {({ windowProps }) => (
            <Draggable nodeRef={nodeRef} handle="#segments-dialog-header">
              <Window
                nodeRef={nodeRef}
                {...windowProps}
                style={{ ...windowProps.style, width: 250, left: 56, top: 123 }}
              >
                <WindowHeaderSlot>
                  <div
                    id="segments-dialog-header"
                    className="flex h-10 cursor-move items-center justify-between gap-4 pl-4 pr-3"
                  >
                    <div className="w-[200px] truncate text-xs font-bold">
                      <>
                        Segments: <CompTimelineTab className="inline h-3 w-3" /> {sceneNameTitle}
                      </>
                    </div>
                    <div className="flex items-center gap-1">
                      <Info
                        width={16}
                        height={16}
                        className="h-4 w-4 shrink-0 cursor-pointer hover:opacity-50"
                        onClick={onInfoClickHandler}
                      />
                      <WindowCloseButton onClick={onCloseDialogHandler} />
                    </div>
                  </div>
                </WindowHeaderSlot>
                {markers.length === 0 && (
                  <div className="h-[300px]">
                    <div className="h-[184px]">
                      <dotlottie-player
                        renderer="svg"
                        autoplay
                        loop
                        src={JSON.stringify(SegmentsAnimation)}
                      ></dotlottie-player>
                    </div>
                    <div className="pt-[20px] text-center text-[10px] text-[#FFF]">
                      <div className="font-bold">Manage animation easily with segments</div>
                      <div className="mx-auto w-[185px]">
                        Segments let you choose different slices of time in your animation so you can break it down into
                        manageable parts, making it easier to edit and refine.
                      </div>
                    </div>
                  </div>
                )}
                {markers.length > 0 && (
                  <div className="flex h-[300px] flex-col gap-2 overflow-auto px-4 py-3">
                    {isDisabled && (
                      <div className="rounded bg-[#101417] py-2 pl-3 pr-2 text-[10px]">
                        Segments are only available in the <CompTimelineTab className="inline h-3 w-3 align-sub" />{' '}
                        <span className="font-bold">
                          <>{sceneNameTitle}</>
                        </span>{' '}
                        for now. Please switch to the main scene to use this feature.
                      </div>
                    )}
                    {markers.map((marker) => (
                      <TimelineSegmentItem
                        key={marker.nodeId}
                        currentFps={fps}
                        duration={duration}
                        isActive={marker.nodeId === selectedMarker?.nodeId}
                        isDisabled={isDisabled}
                        totalFrames={totalFrames}
                        marker={marker}
                        markers={markers}
                        onSelect={() => onSelectSegmentHandler(marker)}
                        onHover={(isHover: boolean) => onHoverSegmentHandler(marker, isHover)}
                        onDelete={() => onDeleteSegmentHandler(marker)}
                        onUpdate={(startFrame, endFrame, comment, isAdjustTimeline) =>
                          onUpdateSegmentHandler(marker, startFrame, endFrame, comment, isAdjustTimeline)
                        }
                        onMatchWorkArea={() => onMatchWorkAreaHandler(marker)}
                        workAreaFrameEnd={workAreaFrameEnd}
                        workAreaFrameStart={workAreaFrameStart}
                      />
                    ))}
                  </div>
                )}
                <Divider />
                <div className="px-4 py-3">
                  <button
                    className={`h-6 w-full rounded text-xs ${isDisabled ? 'bg-[#333C45] text-[#4C5863]' : 'bg-[#00C1A2] text-white'}`}
                    disabled={isDisabled}
                    onClick={onAddSegmentHandler}
                  >
                    Add segment
                  </button>
                </div>
              </Window>
            </Draggable>
          )}
        </FloatingWindow>
      )}
    </>
  );
};
