/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

interface FrameProps {
  currentFrame: number;
  frameRate: number;
}

export const FrameDisplay: React.FC<FrameProps> = ({ currentFrame, frameRate }: FrameProps) => {
  const totalSeconds = currentFrame / frameRate;
  const seconds = Math.floor(totalSeconds);
  const frame = Math.floor((totalSeconds - seconds) * frameRate);

  return (
    <div className="ml-8 flex items-center gap-1 text-[12px] text-[#BFC8D1]">
      <div>{`${seconds}s`}</div>
      <div>{`${frame}f`}</div>
    </div>
  );
};
