/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { MultiKeyFrameMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapper } from './ContextMenuWrapper';

interface MultiKeyframeMenuProps {
  coord: Coordinate;
  onClose: () => void;
  overflow?: boolean;
}

export const MultiKeyframeMenu: React.FC<MultiKeyframeMenuProps> = ({ coord, onClose, overflow = false }) => {
  const linearOptions = MultiKeyFrameMenuList.find((item) => item.label === 'Linear');
  const smoothOptions = MultiKeyFrameMenuList.find((item) => item.label === 'Smooth');

  if (linearOptions) {
    linearOptions.disabled = false;
    linearOptions.children?.forEach((child) => {
      child.disabled = false;
    });
  }

  if (smoothOptions) {
    smoothOptions.disabled = false;
    smoothOptions.children?.forEach((child) => {
      child.disabled = false;
    });
  }

  return (
    <ContextMenuWrapper
      coord={coord}
      onClose={onClose}
      dropdownItems={MultiKeyFrameMenuList}
      overflow={overflow}
    ></ContextMenuWrapper>
  );
};
