/**
 * Copyright 2024 Design Barn Inc.
 */

import type { LayerJSON, ShapeJSON, PropertyType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React from 'react';
import { shallow } from 'zustand/shallow';

import { AnimatedRow } from '../TimelineLayerPanel/Animated';
import { useTreeLines } from '../TimelineLayerPanel/hooks';
import { computeAnimatedPaddingLeft } from '../TimelineLayerPanel/layer-helper';
import { AnimatedTreeLine } from '../TimelineLayerPanel/TreeLine';

import type { LayerUI } from '~/features/timeline';
import { useCreatorStore } from '~/store';

interface Props {
  layer: LayerJSON;
  layerUI: LayerUI;
  type: string;
}

export const TimelineItemAnimatedLayerPanel: React.FC<Props> = ({ layer, layerUI, type }) => {
  const [, getNodeByIdOnly, highlightedLayerIds] = useCreatorStore(
    (state) => [state.toolkit.currentFrame, state.toolkit.getNodeByIdOnly, state.timeline.highlightedLayerIds],
    shallow,
  );

  const { animated, children, last, level, parent } = layerUI;

  const paddingLeft = computeAnimatedPaddingLeft(level);
  const highlight = highlightedLayerIds.includes(layer.id);
  const hasChildren = Boolean(children.length);
  const hasAnimated = Boolean(animated.length > 0);

  const { animatedTreeLines } = useTreeLines(parent, hasChildren, last, level, hasAnimated);

  const node = getNodeByIdOnly(layer.id)?.state as ShapeJSON;

  return (
    <div
      data-layerid={layer.id}
      data-no-dnd="true"
      key={layer.id}
      className={clsx('relative mt-[1px] h-[24px]', {
        'bg-[#272E34]': highlight,
      })}
      style={{ paddingLeft }}
    >
      <AnimatedRow
        ids={layer.id}
        animatedProps={[node.animatedProperties[type as PropertyType]]}
        types={[type]}
        layerType={layer.type}
        parentId={layer.id}
      />
      <AnimatedTreeLine treeLines={animatedTreeLines} />
    </div>
  );
};

export default TimelineItemAnimatedLayerPanel;
