/**
 * Copyright 2024 Design Barn Inc.
 */

import type { Scene } from '@lottiefiles/toolkit-js';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { LAYER_PANEL_DEFAULT_WIDTH, TIMELINE_BOTTOM_GUTTER } from './constant';
import { TimelineKeyframeMenuContainer } from './TimelineKeyframePanel';
import { DraggableWrapper } from './TimelineLayerPanel/DraggableWrapper';
import { LayerMenuContainer } from './TimelineLayerPanel/Layers';
import { TimeLineNavigation } from './TimelineNavigation';

import type { CreatorLayerItem } from '~/features/timeline';
import { getElementWidth, getCreatorLayers } from '~/features/timeline';
import { useClickOutside } from '~/hooks';
import { useSelectionBox } from '~/hooks/useSelectionBox';
import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const TimelineList: React.FC = () => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const height = useCreatorStore((state) => state.timeline.height);
  let layers = useCreatorStore((state) => state.toolkit.json?.allLayers || []);
  const [
    selectedNodesInfo,
    selectedPrecompositionId,
    getNodeByIdOnly,
    expandedLayerIds,
    timelineVisible,
    setSelectedKeyframeIds,
    addToSelectedNodes,
    selectedKeyframeIds,
  ] = useCreatorStore(
    (state) => [
      state.ui.selectedNodesInfo,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.getNodeByIdOnly,
      state.timeline.expandedLayerIds,
      state.timeline.visible,
      state.timeline.setSelectedKeyframeIds,
      state.ui.addToSelectedNodes,
      state.timeline.selectedKeyframeIds,
    ],
    shallow,
  );
  const [isSelectingKeyframes, setIsSelectingKeyframes] = useState(false);

  if (selectedPrecompositionId) {
    const precomNode = getNodeByIdOnly(selectedPrecompositionId);

    layers = (precomNode as Scene).state.allLayers;
  }

  const creatorFlatItems = useMemo(() => {
    const { items, itemsById } = getCreatorLayers(layers);
    const isAllParentsExpanded = (item: CreatorLayerItem): boolean => {
      if (!item.parentId) {
        return true;
      }
      const parent = itemsById[item.parentId];
      const isParentsExpanded = parent?.parentId ? isAllParentsExpanded(parent) : true;

      return isParentsExpanded && expandedLayerIds.includes(item.parentId);
    };

    return items.filter((item: CreatorLayerItem) => isAllParentsExpanded(item));
  }, [layers, expandedLayerIds]);

  const rowVirtualizer = useVirtualizer({
    count: creatorFlatItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 26,
    getItemKey: (index: number) => creatorFlatItems[index]?.id ?? index,
    overscan: 10,
  });

  useEffect(() => {
    if (
      selectedNodesInfo.length &&
      timelineVisible &&
      // avoid scrolling when selecting or moving keyframes
      !isSelectingKeyframes &&
      selectedKeyframeIds.length === 0
    ) {
      const index = creatorFlatItems.findIndex((item) => item.id === selectedNodesInfo[0]?.nodeId);

      if (index > -1) {
        rowVirtualizer.scrollToIndex(index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineVisible, rowVirtualizer, selectedNodesInfo, creatorFlatItems]);

  const virtualItems = rowVirtualizer.getVirtualItems();
  const items = virtualItems.map((virtualItem) => {
    return {
      ...virtualItem,
      ...creatorFlatItems[virtualItem.index],
    };
  });

  const layerContainerRef = useRef(null);
  const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;
  const [keyframeMenuOpened, layerMenuOpened] = useCreatorStore(
    (state) => [
      state.timeline.timelineContext.keyframeMenuOpened || false,
      state.timeline.timelineContext.layerMenuOpened || false,
    ],
    shallow,
  );

  const handleClickOutside = useCallback(() => {
    if (keyframeMenuOpened || layerMenuOpened) {
      setTimelineContext({
        mousePos: { x: 0, y: 0 },
        layerMenuOpened: false,
        keyframeMenuOpened: false,
      });
    }
  }, [keyframeMenuOpened, layerMenuOpened, setTimelineContext]);

  useClickOutside(layerContainerRef, handleClickOutside, null);

  const selectKeyframes = useCallback(
    (start: { x: number; y: number }, end: { x: number; y: number }): void => {
      const keyframeIds: string[] = [];
      const keyframeElements = document.querySelectorAll('.selectable-keyframe');

      keyframeElements.forEach((kf) => {
        const rect = kf.getBoundingClientRect();
        const centerX = (rect.x + rect.x + rect.width) / 2;
        const centerY = (rect.y + rect.y + rect.height) / 2;

        if (centerX >= start.x && centerX <= end.x && centerY >= start.y && centerY <= end.y) {
          keyframeIds.push(kf.getAttribute('data-keyframeid') || '');
        }
      });

      setSelectedKeyframeIds(keyframeIds);

      // Add parent layers to selection
      addToSelectedNodes(
        keyframeIds.map((id) => toolkit.getKeyframeById(id)?.parentProperty?.parent?.nodeId || ''),
        true,
      );
    },
    [setSelectedKeyframeIds, addToSelectedNodes],
  );

  const { SelectionBox, handleMouseDown } = useSelectionBox({
    containerRef: parentRef,
    selectItems: selectKeyframes,
    minX: getElementWidth('[data-testid="layer-row-container"]'),
    onSelectionStart: () => setIsSelectingKeyframes(true),
    onSelectionEnd: () => setIsSelectingKeyframes(false),
    enableScrollX: false,
    ignoreClasses: ['z-keyframe-clickable', 'range-slider__range', 'range-slider__thumb'],
  });

  return (
    <div
      id="TimelineList"
      className="relative overflow-auto text-[12px] font-normal leading-[15px]"
      ref={parentRef}
      style={{
        height,
        paddingBottom: TIMELINE_BOTTOM_GUTTER,
      }}
      onMouseDown={handleMouseDown}
    >
      <SelectionBox />
      <TimeLineNavigation items={creatorFlatItems} />
      <div
        className="relative w-full"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      ></div>

      <div
        ref={layerContainerRef}
        id="LayerContainer"
        className={clsx('text-[12px] font-normal leading-[15px]', {
          'flex h-full items-center justify-center': items.length === 0,
        })}
      >
        <LayerMenuContainer />
        <TimelineKeyframeMenuContainer />
        {items.length > 0 ? (
          <DraggableWrapper layers={layers} creatorItems={items} />
        ) : (
          <div className="flex w-full">
            <div className="shrink-0 px-[30px] text-center text-gray-300" style={{ width: LAYER_PANEL_DEFAULT_WIDTH }}>
              A new layer will appear here for every object you insert and asset you upload.
            </div>
            <div className="w-full px-[30px] text-center text-gray-300">
              A new timeline will appear here for every object you insert and asset you upload.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimelineList;
