/**
 * Copyright 2024 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { cmdOrCtrl, formatShortcut } from '../../../features/menu/constant';

import { TopBarZoomOut, TopBarZoomIn } from '~/assets/icons';
import { TooltipWithShortcut } from '~/components/Elements/Tooltip';
import { CustomText } from '~/data/constant';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

const zoomOptions = [
  {
    label: '50%',
    value: 50,
  },
  {
    label: '100%',
    value: 100,
    shortcut: '⇧1',
  },
  {
    label: '150%',
    value: 150,
  },
  {
    label: '200%',
    value: 200,
  },
];

const onZoomToFit = (): void => {
  emitter.emit(EmitterEvent.CANVAS_ZOOM_TO_FIT, { skipUpdate: true });
};

const zoomFunctions = [
  {
    label: 'Zoom to fit',
    shortcut: formatShortcut(cmdOrCtrl, '0'),
    onClick: onZoomToFit,
  },
];

export const ZoomControl: React.FC = () => {
  const [zoomPercentage, setZoomPercentage] = useCreatorStore(
    (state) => [state.ui.zoomPercentage, state.ui.setZoomPercentage],
    shallow,
  );

  const increment = useCallback(() => {
    setZoomPercentage(zoomPercentage + 1);
  }, [zoomPercentage, setZoomPercentage]);

  const decrement = useCallback(() => {
    setZoomPercentage(zoomPercentage - 1);
  }, [zoomPercentage, setZoomPercentage]);

  return (
    <>
      <div>
        <Menu as="div" className="relative inline-block h-[25px] align-middle">
          {() => (
            <>
              <TooltipWithShortcut
                content="Zoom out"
                shortcut={`${cmdOrCtrl} ${CustomText.MinusSign}`}
                placement="bottom"
              >
                <Menu.Button
                  onClick={decrement}
                  className={clsx(
                    'group inline-flex h-[24px] w-[24px] items-center justify-center rounded hover:bg-gray-700',
                  )}
                >
                  <TopBarZoomOut className="h-4 w-4 cursor-pointer text-gray-500 transition-transform  group-hover:text-white" />
                </Menu.Button>
              </TooltipWithShortcut>
            </>
          )}
        </Menu>

        <Menu as="div" className="relative inline-block">
          {({ open }) => (
            <>
              <Menu.Button
                className={clsx(
                  'group inline-flex h-[24px] items-center justify-center rounded px-1 hover:bg-gray-700',
                )}
              >
                <div
                  className={clsx(
                    'cursor-pointer pl-1 text-xs leading-[18px] group-hover:text-white',
                    open ? 'text-[#00C1A2]' : 'text-gray-400',
                  )}
                >
                  {zoomPercentage.toFixed(0)}%
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 z-dropdown w-[120px] cursor-pointer rounded-lg border border-gray-600 bg-gray-700 py-2 text-white">
                  {zoomFunctions.map((el) => (
                    <Menu.Item key={el.label}>
                      <div
                        className="mx-2 flex items-center justify-between rounded-lg p-2 text-[12px] leading-[18px] hover:bg-gray-600"
                        onClick={el.onClick}
                      >
                        <div>{el.label}</div>
                        {el.shortcut && <div className="text-gray-500">{el.shortcut}</div>}
                      </div>
                    </Menu.Item>
                  ))}
                  <div className="my-2 w-full border-b border-gray-600"></div>
                  {zoomOptions.map((dropdown) => (
                    <Menu.Item key={dropdown.label}>
                      <div
                        className="mx-2 mb-1 flex items-center justify-between rounded-lg p-2 text-[12px] leading-[18px] hover:bg-gray-600"
                        onClick={() => setZoomPercentage(dropdown.value)}
                      >
                        <div>{dropdown.label}</div>
                        {dropdown.shortcut && <div className="text-gray-500">{dropdown.shortcut}</div>}
                      </div>
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>

        <Menu as="div" className="relative inline-block h-[25px] align-middle">
          {() => (
            <>
              <TooltipWithShortcut
                content="Zoom in"
                placement="bottom"
                shortcut={`${cmdOrCtrl} ${CustomText.PlusSign}`}
              >
                <Menu.Button
                  onClick={increment}
                  className={clsx(
                    'group inline-flex h-[24px] w-[24px] items-center justify-center rounded  hover:bg-gray-700',
                  )}
                >
                  <TopBarZoomIn className="h-4 w-4 cursor-pointer text-gray-500 transition-transform group-hover:text-white" />
                </Menu.Button>
              </TooltipWithShortcut>
            </>
          )}
        </Menu>
      </div>
    </>
  );
};
