/**
 * Copyright 2024 Design Barn Inc.
 */

import type { AngleJSON, AnimatedProperty, GradientFillShape } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { AnimatedHighlightAngleIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input';
import { NumberInputPrecision } from '~/data/constant';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setAnimatedHighlightAngle } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedHighlightAngle: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  const angle = (animatedProp.value as AngleJSON).deg;

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeByIdOnly(animatedProp.id) as AnimatedProperty;

      if (node.parent) {
        setAnimatedHighlightAngle(node.parent as GradientFillShape, [result.value]);
        emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_POINT_UPDATED);
      }
    },
    [getNodeByIdOnly, animatedProp.id],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="highlightAngle"
        onChange={handleOnChange}
        value={angle}
        precision={NumberInputPrecision}
        label={<AnimatedHighlightAngleIcon className="h-3 w-3" />}
        styleClass={styleClass}
        append="°"
        min={-360}
        max={360}
      />
    </div>
  );
};
