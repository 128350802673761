/**
 * Copyright 2023 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import React from 'react';

import { AlignPropertyFullWidth } from '../../property-panel/components/AlignProperty';

import type { MenuDropdownProp } from './CreatorMenu';
import { MenuDivider } from './MenuDivider';
import { MenuInteraction } from './MenuInteraction';
import { SubMenu } from './SubMenu';

import { LottieDropdownExpand } from '~/assets/icons';
import { useClickOutside } from '~/hooks';

export interface Coordinate {
  x: number;
  y: number;
}

export interface ContextMenuWrapperProps {
  coord: Coordinate;
  customClass?: string;
  dropdownItems: MenuDropdownProp[];
  eventArg?: unknown;
  onClose: () => void;
  overflow?: boolean;
}

export const ContextMenuWrapper: React.FC<ContextMenuWrapperProps> = ({
  coord,
  customClass,
  dropdownItems,
  eventArg,
  onClose,
  overflow = false,
}) => {
  const menuRef = React.useRef(null);
  const handleClick = React.useCallback(
    (callbackFn: ((arg?: unknown) => void) | undefined): void => {
      if (callbackFn) callbackFn(eventArg);
      onClose();
    },
    [onClose, eventArg],
  );

  useClickOutside(menuRef, onClose, null);

  return (
    <>
      <Menu as="div">
        <Transition
          show={true}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Menu.Items
            ref={menuRef}
            style={{ left: `${coord.x}px`, top: `${coord.y}px` }}
            className={`fixed z-dropdown mt-1 w-60 cursor-pointer rounded-lg border border-gray-600 bg-gray-800 p-2 focus:outline-none ${customClass}`}
          >
            {dropdownItems.map((dropdown, index) => {
              const { callback, disabled, isAlignment, isDivider, isHidden, type } = dropdown;

              return (
                <div key={`${type}_${index}`} className="mb-1 last:mb-0">
                  <div className={`relative rounded ${!disabled && !isAlignment && 'hover:bg-gray-600'}`}>
                    <Menu.Item>
                      <MenuInteraction>
                        {({ hover }) => (
                          <>
                            {!isHidden && (
                              <>
                                {isAlignment && <AlignPropertyFullWidth enabled={true} />}
                                {!isAlignment && (
                                  <button
                                    className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                                      index === dropdownItems.length - 1 && 'rounded-b'
                                    } h-6 items-center px-2 text-xs ${disabled ? 'text-gray-500' : 'text-white'}`}
                                    onClick={() => handleClick(callback)}
                                    // Required because timeline keyframe has clickaway listeners that listen for the mousedown event
                                    // The click interaction triggers the clickaways and triggers side-effects like de-selecting keyframes
                                    // right as the user selects an option to operate on the keyframe
                                    onMouseDown={(ev) => ev.stopPropagation()}
                                    disabled={Boolean(disabled)}
                                  >
                                    {dropdown.label}
                                    {dropdown.children && dropdown.children.length > 0 && (
                                      <>
                                        <LottieDropdownExpand className="absolute right-[7px] mt-[1.5px] h-4 w-4" />
                                      </>
                                    )}
                                    {!dropdown.children && (
                                      <span
                                        className={`absolute right-[7px] ${disabled ? 'text-gray-500' : 'text-gray-300'}`}
                                      >
                                        {dropdown.shortcut}
                                      </span>
                                    )}
                                  </button>
                                )}
                                {hover && dropdown.children && dropdown.children.length > 0 && (
                                  <>
                                    <SubMenu onClose={onClose} subItems={dropdown.children} overflow={overflow} />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </MenuInteraction>
                    </Menu.Item>
                  </div>
                  {isDivider && !isHidden && index !== dropdownItems.length - 1 && <MenuDivider />}
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
