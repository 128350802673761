/**
 * Copyright 2024 Design Barn Inc.
 */

import type { WebGLRenderer } from 'three';
import { Vector2 } from 'three';

export class SelectionHelper {
  private readonly _element: HTMLElement;

  private _isDown = false;

  private _pointBottomRight = new Vector2();

  private _pointTopLeft = new Vector2();

  private readonly _renderer: WebGLRenderer;

  private _startPoint = new Vector2();

  public constructor(renderer: WebGLRenderer, cssClassName: string) {
    this._element = document.createElement('div');
    this._element.classList.add(cssClassName);
    this._element.style.pointerEvents = 'none';

    this._renderer = renderer;

    document.body.addEventListener('pointerdown', (event: PointerEvent): void => {
      this._isDown = true;
      this.onSelectStart(event);
    });
    document.body.addEventListener('pointermove', (event: PointerEvent): void => {
      if (this._isDown) {
        this.onSelectMove(event);
      }
    });
    document.body.addEventListener('pointerup', (): void => {
      this._isDown = false;
      this.onSelectOver();
    });
  }

  public get isDown(): boolean {
    return this._isDown;
  }

  public onSelectMove(event: PointerEvent): void {
    this._element.style.display = 'block';

    this._pointBottomRight.x = Math.max(this._startPoint.x, event.clientX);
    this._pointBottomRight.y = Math.max(this._startPoint.y, event.clientY);
    this._pointTopLeft.x = Math.min(this._startPoint.x, event.clientX);
    this._pointTopLeft.y = Math.min(this._startPoint.y, event.clientY);

    this._element.style.left = `${this._pointTopLeft.x}px`;
    this._element.style.top = `${this._pointTopLeft.y}px`;
    this._element.style.width = `${this._pointBottomRight.x - this._pointTopLeft.x}px`;
    this._element.style.height = `${this._pointBottomRight.y - this._pointTopLeft.y}px`;
  }

  public onSelectOver(): void {
    if (this._element.parentElement) this._element.parentElement.removeChild(this._element);
  }

  public onSelectStart(event: PointerEvent): void {
    this._element.style.display = 'none';

    this._renderer.domElement.parentElement?.appendChild(this._element);

    this._element.style.left = `${event.clientX}px`;
    this._element.style.top = `${event.clientY}px`;
    this._element.style.width = '0px';
    this._element.style.height = '0px';

    this._startPoint.x = event.clientX;
    this._startPoint.y = event.clientY;
  }

  public setVisibility(visible: boolean): void {
    this._element.style.visibility = visible ? 'visible' : 'hidden';
  }
}
