/**
 * Copyright 2024 Design Barn Inc.
 */

import type { DagNode, AVLayer, LayerJSON, CubicBezierShape } from '@lottiefiles/toolkit-js';

import type { MatteUniforms } from '../shapes/path';
import { getBezierUniforms } from '../shapes/path';
import { getTransformMatrix } from '../shapes/transform';

import { updateBezierShape, updateCompoundBezierShape, updateMatteTransformMatrix } from './bezier';

import { matteMap } from '~/lib/canvas';

export interface MatteProperties {
  isMatte: boolean;
  layerId: string;
  matteMode: number | null;
  trackMatteParentId: string | null | undefined;
}

export const DefaultMatteProperty = {
  id: '',
  trackMatteParentId: null,
  layerId: '',
  isMatte: false,
  matteMode: null,
  hasLayerMatte: false,
};

const matteUpdateNeeded = (node: DagNode | null): boolean => {
  const isTrackMatte = (node as AVLayer | null)?.isTrackMatte;
  const isMatteTarget = ((node?.state as LayerJSON | null)?.properties.tt as number) > 0;

  return isTrackMatte || isMatteTarget;
};

// if init is false, we only need to update the transform matrix of the matte
export const matteUpdate = (init = false): void => {
  for (const matteId of matteMap.keys()) {
    const matteData = matteMap.get(matteId);

    if (!matteData || matteData.mattes.length === 0) return;
    matteData.mattes.forEach((matte) => {
      matte.matteMesh?.updateMatrixWorld();
      matteData.mattedItems.forEach((item) => {
        if (!matte.matteMesh || !matte.matteBezier) return;
        item.mesh.updateMatrixWorld();
        const offsetMatrix = getTransformMatrix(item.mesh.matrixWorld, matte.matteMesh.matrixWorld);

        if (init) {
          // TODO handle the situation when the compound shape becomes a matte. (It is possible for a compound shape to be matted)
          const bezierUniforms = getBezierUniforms(matte.matteBezier, true);

          const matteUniforms: MatteUniforms = {
            uMatteBezierCount: bezierUniforms.uBezierCount,
            uMatteBezierPoints: bezierUniforms.uBezierPoints,
            uMatteMode: item.matteMode as number,
            uMatteTransform: offsetMatrix,
          };

          if (item.beziers.length === 1)
            updateBezierShape(item.beziers[0] as CubicBezierShape, item.mesh, null, matteUniforms);
          else updateCompoundBezierShape(item.beziers, item.mesh, null, matteUniforms);
        } else {
          updateMatteTransformMatrix(item.mesh, offsetMatrix);
        }
      });
    });
  }
};

export const onMatteNodeUpdate = (node: DagNode | null): void => {
  const needed = matteUpdateNeeded(node);

  if (!needed) return;
  matteUpdate();
};
