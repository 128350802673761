/**
 * Copyright 2021 Design Barn Inc.
 */

import type React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { CombinedError } from 'urql';
import { shallow } from 'zustand/shallow';

import { startAutoSave } from './shared';

import { getDotCreator } from '~/lib/dotCreator';
import { saveToCreator } from '~/lib/function/menu';
import { useProjectAPI } from '~/providers/api/project';
import type { UploadFileRequestProps } from '~/providers/api/project';
import { useCreatorStore } from '~/store';
import { DirectoryType, SavingState } from '~/store/projectSlice';

export const setSaveFailedAlert = (error: CombinedError, onRetry?: () => void): void => {
  const setAlert = useCreatorStore.getState().ui.setAlert;
  const setInfo = useCreatorStore.getState().project.setInfo;
  const setLoader = useCreatorStore.getState().ui.setLoader;

  setLoader({
    isLoading: false,
  });

  setInfo({
    savingState: SavingState.FAILED,
  });

  let isFileNotFoundError = false;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (error?.graphQLErrors?.length && error.graphQLErrors[0]) {
    isFileNotFoundError = error.graphQLErrors[0].extensions['code'] === 'NOT_FOUND';
  }

  const text = isFileNotFoundError
    ? 'Couldn’t save the file as it might have been deleted. Please make a copy of it or try looking for it on your Recently Deleted page.'
    : 'Something went wrong while saving this animation. How about giving it another try?';
  const handleText = isFileNotFoundError ? 'Make a copy' : 'Try Again';

  setAlert({
    text,
    alertColor: '#D92600',
    icon: 'error',
    isClosable: true,
    handle: async (): Promise<void> => {
      if (isFileNotFoundError) {
        saveToCreator();
      } else if (onRetry) {
        onRetry();
      }
    },
    handleText,
  });
};

export const setSaveSuccessAlert = (): void => {
  const setAlert = useCreatorStore.getState().ui.setAlert;
  const setInfo = useCreatorStore.getState().project.setInfo;
  const setLoader = useCreatorStore.getState().ui.setLoader;

  setLoader({
    isLoading: false,
  });

  setInfo({
    savingState: SavingState.SAVED,
  });

  setAlert({
    text: 'File saved',
    alertColor: '#00951D',
  });
};

export const SaveFileUpload: React.FC = () => {
  const navigate = useNavigate();
  const setSaveFromHotKey = useCreatorStore.getState().ui.setSaveFromHotKey;
  const saveFromHotKey = useCreatorStore.getState().ui.saveFromHotKey;
  const setInfo = useCreatorStore.getState().project.setInfo;
  const [subscribing, setSubscribing] = useState(false);
  const [token, selectedDirectoryId, newFileSubscribeKey, newFileVersionId] = useCreatorStore(
    (state) => [
      state.user.token,
      state.project.selectedDirectory?.id,
      state.project.info.fileSubscribeKey,
      state.project.info.subscriptionVersionId,
    ],
    shallow,
  );

  const { createFile, createNewVersion, createUploadRequest, getLatestVersionId, handleProjectSubscription } =
    useProjectAPI(token);

  const updateUrl = useCallback(
    (newUrl: string) => {
      navigate(newUrl, { replace: true });
    },
    [navigate],
  );

  const handleFile = useCallback(async (): Promise<void> => {
    const sk = useCreatorStore.getState().project.info.fileSubscribeKey;
    const fvi = useCreatorStore.getState().project.info.fileVersionId;
    const fileId = useCreatorStore.getState().project.info.fileId;

    const isUpdate = Boolean(sk && fvi);

    try {
      if (isUpdate && fileId) {
        const res = await createNewVersion();

        if (res) {
          const latestVersionId = await getLatestVersionId({ id: fileId });

          if (latestVersionId) {
            setInfo({
              savingState: SavingState.SAVED,
              fileVersionId: latestVersionId as string,
            });
          }
        }
      } else if (selectedDirectoryId) {
        // SaveToWorkflow step 4 - call file create method
        const res = await createFile();

        if (res) {
          const { currentVersionId, id } = res;

          if (id && currentVersionId) {
            const oldFileName = useCreatorStore.getState().project.info.oldName;
            const setSelectedDirectory = useCreatorStore.getState().project.setSelectedDirectory;
            const isDraft = useCreatorStore.getState().project.info.isDraft;

            const { host, pathname, protocol } = window.location;

            const newUrl = `${protocol}//${host}${pathname}?fileId=${id}`;

            if (isDraft) {
              setInfo({
                fileVersionId: currentVersionId,
                fileId: id,
                savingState: SavingState.SAVED,
              });

              updateUrl(`?fileId=${id}`);
            } else {
              setSelectedDirectory({ id: '', type: DirectoryType.Workspace });
              setInfo({
                name: oldFileName as string,
                fileVersionId: null,
                fileId: null,
                savingState: SavingState.FAILED,
              });

              const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;
              const setLoader = useCreatorStore.getState().ui.setLoader;

              setGlobalModal(null);
              setLoader({
                isLoading: false,
              });

              window.open(newUrl, '_blank');
            }
          }
        }
      }

      startAutoSave();
      if (saveFromHotKey) {
        setSaveSuccessAlert();
        setSaveFromHotKey(false);
      }
    } catch (err) {
      setSaveFailedAlert(err as CombinedError, () => {
        handleFile();
      });
      if (saveFromHotKey) setSaveFromHotKey(false);
    }
  }, [
    updateUrl,
    getLatestVersionId,
    selectedDirectoryId,
    createFile,
    createNewVersion,
    setInfo,
    saveFromHotKey,
    setSaveFromHotKey,
  ]);

  const runSubscribe = useCallback(async () => {
    if (newFileSubscribeKey && !subscribing) {
      setSubscribing(true);

      const done = await handleProjectSubscription({ key: newFileSubscribeKey });

      if (done) {
        handleFile();
      }
    }
  }, [handleProjectSubscription, newFileSubscribeKey, handleFile, subscribing]);

  useEffect(() => {
    if ((newFileSubscribeKey && newFileVersionId) || (newFileSubscribeKey && !newFileVersionId)) {
      runSubscribe();
    }
  }, [runSubscribe, newFileSubscribeKey, newFileVersionId]);

  const uploadFileToFMS = useCallback(
    async ({ fields, key, url }: UploadFileRequestProps) => {
      try {
        const subscribeKey = useCreatorStore.getState().project.info.fileSubscribeKey;

        if (!subscribeKey) {
          setInfo({
            fileSubscribeKey: key,
          });
        }

        const formData = new FormData();

        const amzKey = subscribeKey ? subscribeKey : key;

        formData.append('x-amz-meta-key', amzKey);
        Object.keys(fields).forEach((fieldKey: string) => {
          formData.append(fieldKey, fields[fieldKey] as string);
        });

        const creatorFile = await getDotCreator();

        const file = new File(
          [new Blob([JSON.stringify(creatorFile)], { type: 'application/json' })],
          `Animation-${Math.floor(Math.random() * 10000000 + 1)}.creator`,
        );

        formData.append('file', file);

        // SaveToWorkflow step 2 - upload .creator to FMS AWS S3
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw Error('Failed to upload file to FMS');
        }
      } catch (err) {
        throw Error(err as string);
      }
    },
    [setInfo],
  );

  const startUpload = useCallback(async (): Promise<void> => {
    try {
      // SaveToWorkflow step 1 - create upload request params for FMS AWS S3 requirements
      const uploadFMSData = await createUploadRequest();

      if (uploadFMSData) {
        await uploadFileToFMS(uploadFMSData);
        setInfo({
          savingState: SavingState.SAVED,
        });
      }
    } catch (err) {
      setSaveFailedAlert(err as CombinedError, () => {
        startUpload();
      });
    }
  }, [createUploadRequest, setInfo, uploadFileToFMS]);

  useEffect(() => {
    startUpload();
  }, [startUpload]);

  return null;
};
