/**
 * Copyright 2021 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import type { SceneJSON } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { Fragment, useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { TimelinePlayerChevronDown } from '~/assets/icons';
import { NumberInput, defaultStyle } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { MAX_TIME_SEC } from '~/data/range';
import { parseFrames } from '~/features/timeline';
// eslint-disable-next-line no-restricted-imports
import { secondFrameRegex, secondsRegex, timeRegex } from '~/features/timeline/components/Timeline/constant';
import { useThrottledUseEffect } from '~/hooks/useThrottledUseEffect';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getPlaybackParameters } from '~/lib/eventHandler/playback';
import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

// TODO: temporary set hidden until we extract out time property
const TimeDropDown: React.FC = () => {
  return (
    <Menu as="div" className="relative inline-block">
      {({ open }) => (
        <>
          <Menu.Button
            className={clsx('group ml-1 hidden h-4 w-4 items-center justify-center rounded hover:bg-gray-700', {
              'bg-gray-700': open,
            })}
          >
            <TimelinePlayerChevronDown
              className={clsx('h-3 w-3 group-hover:stroke-white', {
                'stroke-white': open,
                'stroke-gray-400': !open,
              })}
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-[5px] w-[172px] rounded-lg bg-gray-800 py-2 drop-shadow-player">
              <div>content</div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const pad = (num: number, size: number): string => {
  return `000${num}`.slice(size * -1);
};

interface PlaybackTime {
  frames: string;
  seconds: string;
}

const formatPlaybackTimeCache: Record<string, PlaybackTime> = {};

export const formatPlaybackTime = (input: string | number, fps: number): PlaybackTime => {
  const key = `${input}_${fps}`;

  if (formatPlaybackTimeCache[key]) {
    return formatPlaybackTimeCache[key] as PlaybackTime;
  }

  const totalFrames = parseFloat(input as string) * fps;
  const minutes = Math.floor(parseFloat(input as string) / 60);
  const seconds = (Math.floor(parseFloat(input as string) % 60) + minutes * 60).toString();
  const remainingSeconds = totalFrames % (60 * fps);
  const frames = Math.round(remainingSeconds % fps).toString();

  const result = {
    frames,
    seconds,
  };

  formatPlaybackTimeCache[key] = result;

  return result;
};

const formatTimeCache: Record<string, string | number | undefined> = {};

export const formatTime = (input: string | number, fps: number): string => {
  const key = `${input}_${fps}`;

  if (formatTimeCache[key]) {
    return formatTimeCache[key] as string;
  }

  let formattedTime;

  if (secondsRegex.test(input as string)) {
    // if format is in seconds return formatted time
    const totalFrames = parseFloat(input as string) * fps;
    const minutes = pad(Math.floor(parseFloat(input as string) / 60), 2);
    const seconds = pad(Math.floor(parseFloat(input as string) % 60), 2);
    const remainingSeconds = totalFrames % (60 * fps);
    const frames = pad(Math.round(remainingSeconds % fps), 2);

    formattedTime = `${minutes}:${seconds}:${frames}`;
  } else if (timeRegex.test(input as string)) {
    // if in M:S:F / MM:SS:FF / MMM:SSS:FFF format
    // check if it's valid
    const match = timeRegex.exec(input as string);
    const minutes = (match as RegExpExecArray)[1] as string;
    const seconds = (match as RegExpExecArray)[2] as string;
    const frames = (match as RegExpExecArray)[3] as string;

    const notValid = parseFloat(seconds) > 60 || parseFloat(frames) > fps;

    if (notValid) {
      const secondsFromFrames = parseFloat(frames) / fps;
      const totalSeconds = parseFloat(seconds) + parseFloat(minutes) * 60 + secondsFromFrames;

      formattedTime = formatTime(totalSeconds, fps);
    } else {
      formattedTime = input;
    }
  } else if (secondFrameRegex.test(input as string)) {
    const match = secondFrameRegex.exec(input as string);

    if (match) {
      const seconds = parseInt(match[1] as string, 10);
      const frames = parseInt(match[2] as string, 10);
      const minutes = Math.floor(seconds / 60);
      const secondsFromFrames = frames / fps;
      const totalSeconds = seconds + minutes * 60 + secondsFromFrames;

      formattedTime = formatTime(totalSeconds, fps);
    }
  } else {
    formattedTime = input;
  }

  formatTimeCache[key] = formattedTime;

  return formattedTime as string;
};

const Time: React.FC = () => {
  const setCurrentFrame = useCreatorStore.getState().toolkit.setCurrentFrame;

  const [currentFrame, sceneIndex, selectedPrecompositionId, _currentFrameRate, _precompFrameRate, _precompDuration] =
    useCreatorStore(
      (state) => [
        state.toolkit.currentFrame,
        state.toolkit.sceneIndex,
        state.toolkit.selectedPrecompositionId,
        state.toolkit.json?.timeline.properties.fr as number,
        state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
        state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      ],
      shallow,
    );

  const currentFPS = _precompDuration ? (_precompFrameRate as number) : _currentFrameRate;

  const [timeValue, setTimeValue] = useState(() => {
    const formatDuration = formatPlaybackTime(0, currentFPS);

    return `${formatDuration.seconds}s ${formatDuration.frames}f`;
  });

  const { currentScene, duration } = getPlaybackParameters();

  const handleEffect = useCallback(() => {
    const currentFrameRate = currentScene.fps;
    const currentTime = currentFrame / currentFrameRate;

    let precompFrameRate = 0;
    let precompDuration = 0;
    let precompCurrentTime = 0;

    if (selectedPrecompositionId) {
      const assetNode = toolkit.getNodeById(selectedPrecompositionId);

      if (assetNode) {
        const precompJSON = assetNode.state as SceneJSON;

        precompFrameRate = precompJSON.timeline.properties.fr as number;
        precompDuration = precompJSON.timeline.duration;
        precompCurrentTime = precompJSON.timeline.properties.ct as number;
      }
    }

    const frameRateDisplay = selectedPrecompositionId ? precompFrameRate : currentFrameRate;
    const durationDisplay = selectedPrecompositionId ? precompDuration : duration;

    // if not clamped, decimal points on currentTime adds on extra frames
    let clampedCurrentTime = currentTime > durationDisplay ? durationDisplay : currentTime;

    if (selectedPrecompositionId) {
      clampedCurrentTime = precompCurrentTime > durationDisplay ? durationDisplay : precompCurrentTime;
    }
    const pbTime = formatPlaybackTime(clampedCurrentTime, frameRateDisplay as number);

    setTimeValue(`${pbTime.seconds}s ${pbTime.frames}f`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFrame, sceneIndex, selectedPrecompositionId, currentScene, duration]);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      // set current frame to the input value
      // assume input value is in frames
      const inputValue = event.target.value;

      const frame = parseFrames(inputValue, currentFPS, duration);

      if (frame === null) {
        return;
      }

      setCurrentFrame(frame);

      setTimeValue(inputValue);
      emitter.emit(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED);
    },
    [currentFPS, setCurrentFrame, duration],
  );

  useThrottledUseEffect(handleEffect);

  return (
    <div className="relative">
      <div className="w-[52px]">
        <Tooltip content="Seconds and frames" placement="top" offsetOptions={{ mainAxis: 15 }}>
          <div>
            <NumberInput
              name="duration"
              message={timeValue}
              min={0}
              max={MAX_TIME_SEC}
              precision={2}
              value={Number(timeValue)}
              onBlur={handleBlur}
              styleClass={{
                input: `${defaultStyle.input} time-control-field bg-transparent focus:border focus:border-white border p-px px-1 rounded-md border border-transparent hover:border-[#333C45]`,
                label: `bg-transparent`,
              }}
              timerSetting={{
                fps: _precompDuration ? (_precompFrameRate as number) : _currentFrameRate,
              }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export const TimeControl: React.FC = () => {
  return (
    <div className="flex items-center">
      <Time />
      <TimeDropDown />
    </div>
  );
};
