/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';

/* Define icon since it is only used in this component */
export const AlignLeft: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.668 13.333V2.666" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.335 8.666H4.668v2.667h8.667V8.666ZM10.001 4.666H4.668v2h5.333v-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignCenter: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 13.333V2.666" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.665 8.666H3.332v2.667h9.333V8.666ZM10.665 4.666H5.332v2h5.333v-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignRight: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.332 13.333V2.666" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2.665 8.666h8.667v2.667H2.665V8.666ZM5.999 4.666h5.333v2H5.999v-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignTop: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.165 2.666h10.667" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.832 13.333V4.666H9.165v8.667h2.667ZM7.168 10V4.665h-2v5.333h2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignMiddle: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.835 8H3.168" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.168 3.333v9.333h2.667V3.333H9.168ZM5.168 5.333v5.333h2V5.333h-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const AlignBottom: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.835 13.334H3.168" strokeWidth={0.994} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.168 2.667v8.667h2.667V2.667H9.168ZM5.168 6v5.334h2V6.001h-2Z"
      strokeWidth={0.994}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const DistributeHorizontalSpacing: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.23828 2.16663C2.96214 2.16663 2.73828 2.39048 2.73828 2.66663V13.3333C2.73828 13.6094 2.96214 13.8333 3.23828 13.8333C3.51442 13.8333 3.73828 13.6094 3.73828 13.3333L3.73828 2.66663C3.73828 2.39048 3.51442 2.16663 3.23828 2.16663ZM13.9049 2.16663C13.6288 2.16663 13.4049 2.39048 13.4049 2.66663L13.4049 13.3333C13.4049 13.6094 13.6288 13.8333 13.9049 13.8333C14.1811 13.8333 14.4049 13.6094 14.4049 13.3333L14.4049 2.66663C14.4049 2.39048 14.1811 2.16663 13.9049 2.16663ZM10.2383 4.16663C10.5144 4.16663 10.7383 4.39048 10.7383 4.66663V11.3333C10.7383 11.6094 10.5144 11.8333 10.2383 11.8333H6.90495C6.6288 11.8333 6.40495 11.6094 6.40495 11.3333V4.66663C6.40495 4.39048 6.6288 4.16663 6.90495 4.16663H10.2383Z"
    />
  </svg>
));

export const DistributeVerticalSpacing: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16602 2.66663C2.16602 2.94277 2.38987 3.16663 2.66602 3.16663L13.3327 3.16663C13.6088 3.16663 13.8327 2.94277 13.8327 2.66663C13.8327 2.39048 13.6088 2.16663 13.3327 2.16663L2.66602 2.16663C2.38987 2.16663 2.16602 2.39049 2.16602 2.66663ZM2.16602 13.3333C2.16602 13.6094 2.38987 13.8333 2.66602 13.8333L13.3327 13.8333C13.6088 13.8333 13.8327 13.6094 13.8327 13.3333C13.8327 13.0571 13.6088 12.8333 13.3327 12.8333L2.66602 12.8333C2.38987 12.8333 2.16602 13.0572 2.16602 13.3333ZM4.16602 6.33329C4.16602 6.05715 4.38987 5.83329 4.66602 5.83329H11.3327C11.6088 5.83329 11.8327 6.05715 11.8327 6.33329L11.8327 9.66663C11.8327 9.94277 11.6088 10.1666 11.3327 10.1666H4.66602C4.38987 10.1666 4.16602 9.94277 4.16602 9.66663V6.33329Z"
    />
  </svg>
));
