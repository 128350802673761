/**
 * Copyright 2022 Design Barn Inc.
 */

import { initializeEventStore } from '@lottiefiles/event-store';
import React from 'react';

import { EVENTSTORE_API_KEY, EVENTSTORE_HOST } from '~/config';

interface Props {
  children?: React.ReactNode;
  initParams?: unknown;
}

export const EventStoreProvider: React.FC<Props> = ({ children }) => {
  const params = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tracking_host: EVENTSTORE_HOST,
    key: EVENTSTORE_API_KEY,
  };

  const additionalClients = {
    amplitude: {
      enabled: true,
      amplitudeTrackingOptions: {
        deviceManufacturer: true,
        deviceModel: true,
        ipAddress: true,
        language: true,
        osName: true,
        osVersion: true,
        platform: false,
      },
      defaultTrackingOptions: {
        pageViews: true,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
      },
    },
  };

  initializeEventStore(params, additionalClients);

  // No need to provide the context value as the event store is initialized globally
  return <>{children}</>;
};
