/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';
import './customDragOverlay.css';

import { TimelineSortOrderLine } from '~/assets/icons/timeline';
import { DragDirection } from '~/store/timelineSlice';

interface CustomDragOverlayProps {
  direction: string;
  disabled: boolean;
}

export const CustomDragOverlay: React.FC<CustomDragOverlayProps> = ({
  direction,
  disabled,
}: CustomDragOverlayProps) => {
  return (
    <div
      id="dragOverlayLine"
      className={`absolute w-[300px] ${direction === DragDirection.BOTTOM ? 'bottom-[-3px]' : 'top-0'} z-[100] ${disabled ? 'cursor-not-allowed' : ''}`}
    >
      <TimelineSortOrderLine />
    </div>
  );
};
