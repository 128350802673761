/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { GradientFillType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useState } from 'react';

import type { ColorOptional } from '..';
import { MouseAction } from '..';
import InputRgba from '../InputRgba';

import { Alpha } from './Alpha';
import type { GradientPointType } from './GradientPoints/GradientPoint';
import Hue from './Hue';
import Picking from './Picking';
import Preview from './Preview';

import { hexToRgb, rgbToHex, rgbToHsv } from '@/ColorPicker/helpers';
import { EyeDropper as EyeDropperIcon } from '~/assets/icons';

let eyeDropper: unknown = null;

if (window.EyeDropper) {
  // @ts-ignore
  eyeDropper = new EyeDropper();
}
const abortController = new AbortController();

interface Props {
  alpha: number;
  blue: number;
  degree?: number;
  green: number;
  isGradient: boolean;
  points?: GradientPointType[];
  red: number;
  showEyeDropper?: boolean;
  showOpacityControl?: boolean;
  type: GradientFillType;
  updateRgb: (value: ColorOptional, name: MouseAction) => void;
}

export const Area: React.FC<Props> = ({
  alpha,
  blue,
  degree = 0,
  green,
  isGradient,
  points = [],
  red,
  type,
  updateRgb,
  showOpacityControl = true,
  showEyeDropper = true,
}) => {
  const { hue, saturation, value } = rgbToHsv({ red, green, blue });
  const [eyeDropperSelected, setEyeDropperSelected] = useState(false);

  const handleEyeDropperClick = async (): Promise<void> => {
    if (!window.EyeDropper || !eyeDropper) {
      // eslint-disable-next-line no-console
      console.warn('Your browser does not support the EyeDropper API');

      return;
    }
    setEyeDropperSelected(true);

    // @ts-ignore
    const result = await eyeDropper.open({ signal: abortController.signal });

    setEyeDropperSelected(false);
    const { blue: b, green: g, red: r } = hexToRgb(result.sRGBHex);

    updateRgb({ red: r, green: g, blue: b }, MouseAction.onChange);
  };

  const handleChangeColor = ({ alpha: _alpha, hex }: { alpha: number; hex: string }): void => {
    const { blue: b, green: g, red: r } = hexToRgb(hex);

    updateRgb({ red: r, green: g, blue: b, alpha: _alpha / 100 }, MouseAction.onChange);
  };

  return (
    <>
      <div className="mt-2"></div>
      <Picking
        red={red}
        green={green}
        blue={blue}
        hue={hue}
        saturation={saturation}
        value={value}
        updateRgb={updateRgb}
      />

      <div className="preview">
        {showEyeDropper && (
          <EyeDropperIcon
            className={`${
              eyeDropperSelected ? 'border-2 border-[#00DDB3]' : ''
            } mr-1 h-8 w-8 rounded-lg hover:bg-gray-600`}
            onClick={handleEyeDropperClick}
          />
        )}
        <div
          className={clsx('color-hue-alpha', {
            'no-opacity-control justify-center': !showOpacityControl || !isGradient,
          })}
        >
          <Hue hue={hue} saturation={saturation} value={value} updateRgb={updateRgb} />
          {showOpacityControl && isGradient && (
            <Alpha alpha={alpha} red={red} green={green} blue={blue} updateRgb={updateRgb} />
          )}
        </div>
        <Preview
          red={red}
          green={green}
          blue={blue}
          alpha={alpha}
          points={points}
          gradientDegree={degree}
          gradientType={type}
          isGradient={isGradient}
        />
      </div>
      <InputRgba
        hex={rgbToHex(red || 0, green || 0, blue || 0)}
        alpha={alpha * 100}
        format={'hex'}
        onChange={handleChangeColor}
        showOpacityControl={showOpacityControl}
        showAlpha={false}
        isGradient={isGradient}
      />
      {/* <div className="h-[1px] w-full bg-gray-700" /> */}
      {/* <DefaultColorsPanel setColor={setColor} isGradient={isGradient} /> */}
    </>
  );
};
