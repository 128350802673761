/**
 * Copyright 2021 Design Barn Inc.
 */

import { Listbox, Transition } from '@headlessui/react';
import { filter } from 'lodash-es';
import React, { Fragment } from 'react';

import type { InputSelectOption } from './InputSelect';
import { SelectInput } from './InputSelect';

import { CaretDown } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input/';

export interface Option {
  btnLabel?: string | null;
  editable?: boolean;
  icon?: React.ReactNode | null;
  isDivider?: boolean;
  label: string;
  styleName?: string;
  value: string | number;
}

interface SelectInputProp {
  inputOption: InputSelectOption;
  onInputChange: (result: NumberResult) => void;
}

export interface SelectInputStyle {
  button?: string;
  options?: string;
}

interface SelectProps {
  icon?: React.ReactNode;
  input?: SelectInputProp;
  onChange: (val: Option) => void;
  options: Option[];
  selected: Option;
  styleClass?: SelectInputStyle;
  styleName?: string;
}
export const Select: React.FC<SelectProps> = ({
  icon,
  input = null,
  onChange,
  options,
  selected: selectedOption,
  styleClass,
}) => {
  const filterOptions = filter(options, (opt: Option) => !opt.editable);

  return (
    <Listbox value={selectedOption} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative" style={{ zIndex: open ? 20 : 'auto' }}>
            <div className="relative">
              <Listbox.Button
                className={`relative h-[24px] w-full cursor-default rounded border border-transparent bg-gray-700 px-2 text-left text-xs font-normal hover:border-gray-500 ${styleClass?.button}`}
              >
                <>
                  {icon ? icon : <></>}
                  {!selectedOption.editable && (
                    <>
                      <span className="inline px-0.5">{selectedOption.btnLabel || selectedOption.label}</span>
                    </>
                  )}
                  <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
                    <CaretDown className="h-4 w-4 fill-current stroke-current text-gray-400" />
                  </span>
                </>
              </Listbox.Button>
              {input && selectedOption.editable && (
                <SelectInput
                  onInputChange={input.onInputChange}
                  selected={selectedOption}
                  inputOption={input.inputOption}
                />
              )}
            </div>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute mt-1 max-h-60 w-full overflow-y-auto overflow-x-hidden rounded bg-gray-800 text-xs font-normal drop-shadow-select ${styleClass?.options}`}
              >
                <div className="m-2">
                  {filterOptions.map((option, optionIdx) => (
                    <span key={optionIdx}>
                      {!option.isDivider && (
                        <Listbox.Option
                          className={({ active }) =>
                            `relative cursor-default text-left h-6 rounded-md	 ${active || option.value === selectedOption.value ? 'bg-gray-700' : 'text-white'}`
                          }
                          value={option}
                          style={{
                            marginTop: optionIdx === 0 ? '0px' : '4px',
                          }}
                        >
                          {({ selected }) => {
                            return (
                              <>
                                <span
                                  className={`flex h-6 items-center truncate rounded-md pl-2 ${
                                    selected ? 'bg-gray-700' : ''
                                  }`}
                                >
                                  {option.label}
                                </span>
                              </>
                            );
                          }}
                        </Listbox.Option>
                      )}

                      {option.isDivider && optionIdx !== options.length - 1 && (
                        <>
                          <div
                            className="my-2 h-[1px] w-full bg-gray-700"
                            style={{
                              transform: 'translateX(-9px)',
                              width: '120%',
                            }}
                          ></div>
                        </>
                      )}
                    </span>
                  ))}
                </div>
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
