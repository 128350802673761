/**
 * Copyright 2022 Design Barn Inc.
 */

import { Tab } from '@headlessui/react';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import React, { useEffect } from 'react';
import Draggable from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { SearchBar } from '../search-animation/components/SearchBar';

import { PluginBrowserEntry } from './PluginBrowserEntry';

import LoadingAnimation from '~/assets/animations/loading-lf.json';
import { Divider } from '~/components/Elements/Divider';
import { FloatingWindow, Window, WindowHeader } from '~/components/Elements/Window';
import { useCreatorStore } from '~/store';

const PLUGINS_ARTIFACTS_ENDPOINT = import.meta.env['VITE_PLUGINS_ARTIFACTS_ENDPOINT'];

export interface CatalogueEntry {
  id: string;
  name: string;
}

export const PluginBrowserDialog: React.FC = () => {
  const nodeRef = React.useRef(null);

  const [pluginBrowserOpen, setPluginBrowserOpen, devPlugin, setNewPluginModalOpen, isUIMinimized] = useCreatorStore(
    (state) => [
      state.ui.pluginBrowserOpen,
      state.ui.setPluginBrowserOpen,
      state.plugins.devPlugin,
      state.ui.setNewPluginModalOpen,
      state.ui.isUIMinimized,
    ],
    shallow,
  );

  const [catalogue, setCatalogue] = React.useState<CatalogueEntry[] | null>(null);
  const [filter, setFilter] = React.useState<string>('');

  useEffect(() => {
    const start = Date.now();

    const fetchCatalogue = async (): Promise<void> => {
      const res = await fetch(new URL('/catalogue.json', PLUGINS_ARTIFACTS_ENDPOINT));
      const json = await res.json();
      const body: CatalogueEntry[] = json;

      // Let the loading animation spin for at least one second
      // regardless of how fast the response is.
      const end = Date.now();

      if (end - start < 1000) {
        await new Promise<CatalogueEntry[]>((resolve) => {
          setTimeout(
            () => {
              resolve(body);
            },
            1000 - (end - start),
          );
        });
      }
      setCatalogue(body);
    };

    fetchCatalogue()
      // eslint-disable-next-line promise/prefer-await-to-then, node/handle-callback-err
      .catch((_err) => {
        // TODO(miljau): do something with this error
      });
  }, []);

  const loadingAnimation = (
    <div className="flex h-[432px] w-full items-center justify-center">
      <div className="mb-[16px] h-20 w-20">
        <LottiePlayer renderer="svg" autoplay loop className="h-20 w-20" src={LoadingAnimation} />
      </div>
    </div>
  );
  let filteredCatalogue: CatalogueEntry[];

  if (catalogue === null) {
    filteredCatalogue = [];
  } else if (filter.trim().length === 0) {
    filteredCatalogue = catalogue;
  } else {
    filteredCatalogue = catalogue.filter((item) => item.name.toLowerCase().startsWith(filter.toLowerCase().trim()));
  }

  let devTab;

  if (devPlugin === null) {
    devTab = (
      <>
        <div className="flex grow flex-col items-center justify-center">
          <div className="text-[13px] text-gray-300">You don&apos;t have any plugins</div>
          <div className="py-[10px] text-center text-[13px] text-teal-300">
            <a href="#">Learn more about plugin development</a>
          </div>
          <div className="mt-[15px] flex w-full flex-row justify-center">
            <button
              className="cursor-pointer rounded-md border border-gray-600 py-[3px] pr-[10px] text-sm hover:bg-gray-700"
              onClick={() => {
                setPluginBrowserOpen(false);
                setNewPluginModalOpen(true);
              }}
            >
              <svg
                className="mx-[10px] box-border inline h-[12px] w-[12px]"
                width={12}
                height={12}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.667 6H1.333M6 10.667V1.333" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              New Plugin
            </button>
          </div>
        </div>
        <div className="py-[10px] text-center text-[13px] text-teal-300">
          <a href="#">Download Plugins Template</a>
        </div>
      </>
    );
  } else {
    devTab = (
      <>
        <PluginBrowserEntry
          isDev={true}
          catalogueItem={{ id: '00000000-0000-0000-0000-000000000000', name: devPlugin.name }}
        />
      </>
    );
  }

  return (
    <>
      {pluginBrowserOpen ? (
        <FloatingWindow>
          {({ windowProps }) => (
            <Draggable nodeRef={nodeRef} handle="#test-animation-header">
              <Window nodeRef={nodeRef} {...windowProps} className={isUIMinimized ? 'hidden' : ''}>
                <WindowHeader title="Plugins" onClose={() => setPluginBrowserOpen(false)} id="test-animation-header" />
                <div className="max-h-[680px] w-[340px] bg-gray-800 text-white">
                  {catalogue === null ? (
                    loadingAnimation
                  ) : (
                    <div className="flex min-h-[432px] flex-col">
                      <div className="my-[15px]">
                        <SearchBar isError={false} onSubmit={(query) => setFilter(query)} />
                      </div>
                      <Tab.Group>
                        <Tab.List className="bg-gray-800 px-1">
                          <Tab className="bg-gray-800 px-3 py-2 text-caption focus:outline-none">
                            {({ selected }) => {
                              return (
                                <span className={`${selected ? 'font-bold' : 'text-gray-400'} hover:text-white`}>
                                  Catalogue
                                </span>
                              );
                            }}
                          </Tab>
                          <Tab className="bg-gray-800 px-3 py-2 text-caption focus:outline-none">
                            {({ selected }) => {
                              return (
                                <span className={`${selected ? 'font-bold' : 'text-gray-400'} hover:text-white`}>
                                  Development
                                </span>
                              );
                            }}
                          </Tab>
                        </Tab.List>
                        <Divider />
                        <Tab.Panels className="flex grow flex-col">
                          <Tab.Panel className="flex grow flex-col">
                            <div className="grow overflow-y-auto">
                              {filteredCatalogue.map((item) => {
                                return <PluginBrowserEntry catalogueItem={item} key={item.id} isDev={false} />;
                              })}
                            </div>
                            <div className="h-[1px] w-full bg-gray-700"></div>
                          </Tab.Panel>
                          <Tab.Panel className="flex grow flex-col">{devTab}</Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  )}
                </div>
              </Window>
            </Draggable>
          )}
        </FloatingWindow>
      ) : null}
    </>
  );
};
