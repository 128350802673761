/**
 * Copyright 2023 Design Barn Inc.
 */

// Easing values
export const LINEAR_IN_LINEAR_OUT_ALT = {
  in: { x: 0, y: 0 },
  out: { x: 1, y: 1 },
};

export const LINEAR_IN_LINEAR_OUT = {
  in: { x: 1, y: 1 },
  out: { x: 0, y: 0 },
};

export const LINEAR_IN_SMOOTH_OUT = {
  in: { x: 0.68, y: 0.19 },
  out: { x: 0.55, y: 0.06 },
};

export const SMOOTH_IN_SMOOTH_OUT = {
  in: { x: 0.36, y: 1 },
  out: { x: 0.65, y: 0 },
};

export const SMOOTH_IN_LINEAR_OUT = {
  in: { x: 0.36, y: 1 },
  out: { x: 0.22, y: 0.61 },
};

// Copied from toolkit-js but modified where needed to work with creator's needs
export const MIN_TIME_STRETCH = -100;
export const MAX_TIME_STRETCH = 100;

// 64000 is from toolkit
// These are values for canvas
export const MIN_HEIGHT = 4;
export const MIN_WIDTH = 4;
export const MAX_WIDTH = 10000;
export const MAX_HEIGHT = 10000;

// These are values for shapes
export const MIN_SHAPE_HEIGHT = 0;
export const MIN_SHAPE_WIDTH = 0;
export const MAX_SHAPE_WIDTH = 10000;
export const MAX_SHAPE_HEIGHT = 10000;

// Values for time input
export const MIN_TIME_SEC = 1;
export const MAX_TIME_SEC = 600;

// Set the frame rate between 2 - 100
export const MIN_FRAME_RATE = 2;
export const MAX_FRAME_RATE = 100;

export const MAX_NAME_LENGTH = 128;

// These are magic nrs!! Need to be defined by designer
// Position:
export const MIN_X = -99999;
export const MIN_Y = -99999;
export const MAX_X = 99999;
export const MAX_Y = 99999;

// Polystar:
export const MIN_POLYSTAR_POINTS = 3;
export const MAX_POLYSTAR_POINTS = 60;

export const MIN_POLYSTAR_RADIUS = 1;
export const MAX_POLYSTAR_RADIUS = 999;

export const MIN_POLYSTAR_ROUNDNESS = 0;
export const MAX_POLYSTAR_ROUNDNESS = 100;

export const MIN_POLYSTAR_ROTATION = -360;
export const MAX_POLYSTAR_ROTATION = 360;

// Stroke:
export const MIN_STROKE_WIDTH = 0;
export const MAX_STROKE_WIDTH = 1000;

// Transform:
export const MIN_TRANSFORM_ROTATION = -360;
export const MAX_TRANSFORM_ROTATION = 360;
export const MIN_TRANSFORM_SCALE = -999999.99;
export const MAX_TRANSFORM_SCALE = 999999.99;
