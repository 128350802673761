/**
 * Copyright 2021 Design Barn Inc.
 */

// Temporary disable any check until we implement graphQL typing generation
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { MutableRefObject } from 'react';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useQuery } from 'urql';

import { SearchQuery, FeaturedQuery } from '../api/search';

import { CursorType } from './SearchAnimation';

import LoadingAnimation from '~/assets/animations/loading-lf.json';
import { SearchResultEmpty } from '~/assets/icons/placeholder';
import { DragToCanvas } from '~/components/Elements/DragToCanvas';
import { SEARCH_GRAPHQL_ENDPOINT, ASSET_ENDPOINT } from '~/config';
import { useCreatorStore } from '~/store';
import { AnimationLoaderStatus } from '~/store/uiSlice';

const getEndpoint = (endpoint: string): string => {
  if (endpoint && endpoint.toLowerCase().startsWith('http')) return endpoint;
  else return `${ASSET_ENDPOINT}${endpoint}`;
};

export interface DotLottiePlayerElement extends Element {
  load(record: Record<string, any>): void;
}

interface CaptionProps {
  animationUrl: string;
  avatarUrl: string;
  name: string;
  username: string;
}

const Caption: React.FC<CaptionProps> = ({ animationUrl, avatarUrl, name, username }) => {
  const preventPropagation = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
  }, []);

  return (
    <div className="absolute bottom-0 hidden w-[100px] rounded-md from-black group-hover:block group-hover:bg-gradient-to-t">
      <div className="px-[6px] py-2">
        <div className="mb-1 h-[22px] w-[22px]">
          <a
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="profile-link"
            draggable={false}
            href={`https://lottiefiles.com${username}`}
            target="_blank"
            onClick={preventPropagation}
            rel="noreferrer"
          >
            <img className="rounded-full shadow-sm" src={avatarUrl} height={22} width={22} draggable={false} />
          </a>
        </div>
        <div className="max-h-[80px] text-ellipsis whitespace-normal text-caption line-clamp-1">
          <a
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="animation-link block truncate font-semibold text-white hover:underline"
            draggable={false}
            href={getEndpoint(animationUrl)}
            target="_blank"
            onClick={preventPropagation}
            rel="noreferrer"
          >
            {name}
          </a>
        </div>
      </div>
    </div>
  );
};

interface ItemProps {
  onClick: () => void;
  result: any;
}

const Item: React.FC<ItemProps> = ({ onClick, result }) => {
  const [onMouseOver, setMouseOver] = useState(false);

  const element = React.cloneElement(<img />, {
    src: getEndpoint(result.imageUrl),
    className: 'h-[100px] w-[100px] rounded-[6px]',
  });

  const addToCanvas = async (): Promise<void> => onClick();

  return (
    <DragToCanvas
      addToCanvas={addToCanvas}
      element={element}
      popupSize={{ width: 120, height: 120 }}
      popupOffset={{ x: 100, y: 100 }}
    >
      {result && (
        <div
          onMouseOver={() => {
            setMouseOver(true);
          }}
          onMouseOut={() => {
            setMouseOver(false);
          }}
          className="group relative my-[5px] h-[100px] w-[100px] cursor-pointer even:justify-self-end"
        >
          <div>
            {onMouseOver && (
              <div className="h-[100px] w-[100px] rounded-md bg-white">
                <dotlottie-player
                  renderer="svg"
                  autoplay
                  loop
                  src={getEndpoint(result.lottieUrl)}
                  style={{ height: '100px', width: '100px', borderRadius: '6px' }}
                ></dotlottie-player>
              </div>
            )}

            {!onMouseOver && <div className="h-[100px] w-[100px] rounded-md bg-white">{element}</div>}
          </div>

          {result.createdBy && (
            <Caption
              avatarUrl={getEndpoint(result.createdBy.avatarUrl)}
              animationUrl={getEndpoint(result.url)}
              name={result.name}
              username={result.createdBy.username}
            />
          )}
        </div>
      )}
    </DragToCanvas>
  );
};

const Loading: React.FC = () => {
  const playerRef = useRef(null);
  const player = playerRef.current as Element | null;

  if (player) {
    (player as DotLottiePlayerElement).load(LoadingAnimation as Record<string, any>);
  }

  return (
    <div className="py-2 text-center text-sm text-gray-50" data-testid="loading">
      <div className="absolute top-[calc(30%+80px)] flex w-full justify-center">
        <div className="w-[64px]">
          <dotlottie-player ref={playerRef} renderer="svg" autoplay loop></dotlottie-player>
        </div>
      </div>
    </div>
  );
};

const NoSearchResult: React.FC = () => {
  return (
    <div className="flex flex-col items-center text-gray-50" data-testid="no-search-result">
      <SearchResultEmpty className="mt-20 w-[190px] px-4" />
      <div className="mt-4 text-center text-[10px] font-bold text-gray-300">No results found</div>
      <p className="mt-4 w-[210px] whitespace-normal text-center text-[10px] text-gray-300">
        {`We couldn't find any animations based on your search`}
      </p>
    </div>
  );
};

interface ResultGridProps {
  data: any;
  fetching: boolean;
  items: any;
  loadMore: () => void;
  onSelected: (result: any) => void;
}

const ResultGrid: React.FC<ResultGridProps> = ({ data, fetching, items, loadMore, onSelected }) => {
  const loaderRef = useRef() as MutableRefObject<HTMLDivElement | null>;
  const [animationLoader] = useCreatorStore((state) => [state.ui.animationLoader]);

  useEffect(() => {
    const currentLoaderRef = loaderRef.current;
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];

      if (target && target.isIntersecting) {
        loadMore();
      }
    });

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loadMore]);

  if (data && data.edges.length === 0) return <NoSearchResult />;

  return (
    <>
      <div className="search-result-grid grid auto-rows-min grid-cols-2 px-3" data-testid="search-results">
        {items.map((edge: any, edgeIndex: number) => (
          <Item key={edgeIndex} result={edge.node} onClick={() => onSelected(edge.node)} />
        ))}
      </div>
      {(fetching || animationLoader.status === AnimationLoaderStatus.Loading) && (
        <div className="absolute top-0 h-full w-full">
          <div className=" h-full w-full bg-gray-800 opacity-80"></div>
          <div className=" h-full w-full">
            <Loading />
          </div>
        </div>
      )}
      {data && data.pageInfo && data.pageInfo.hasNextPage && (
        <div ref={loaderRef} className=" flex justify-center px-3 pt-2">
          <div
            className="block cursor-pointer rounded  border border-gray-500 px-2 py-1 text-xs text-gray-200 hover:border-gray-200"
            onClick={loadMore}
          >
            Load More
          </div>
        </div>
      )}
    </>
  );
};

interface SearchResultProps {
  onError?: (msg: string) => void;
  onSelected: (result: any) => void;
  query: {
    cursor: string;
    cursorType: CursorType;
    text: string;
  };
}

interface PageVariables {
  after: string | null;
  before: string | null;
  first: number | null;
  last: number | null;
}

export const SearchResult: React.FC<SearchResultProps> = ({ onError, onSelected, query }) => {
  const ANIMATION_LIMIT = 20;
  const [variables, setVariables] = useState<PageVariables>({
    first: null,
    after: null,
    last: null,
    before: null,
  });

  const [items, setItems] = useState([]);
  const loadingMoreRef = useRef(false) as MutableRefObject<boolean>;

  const collectionRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const updatedVariables = {
      first: query.cursorType === CursorType.NEXT_CURSOR ? ANIMATION_LIMIT : null,
      after: query.cursorType === CursorType.NEXT_CURSOR ? query.cursor : null,
      last: query.cursorType === CursorType.PREV_CURSOR ? ANIMATION_LIMIT : null,
      before: query.cursorType === CursorType.PREV_CURSOR ? query.cursor : null,
    };

    setVariables(updatedVariables);
  }, [query]);

  const lottieHash = useCreatorStore.getState().ui.creatorHash;

  const [result] = useQuery({
    query: query.text ? SearchQuery : FeaturedQuery,
    variables: query.text
      ? { ...variables, query: query.text }
      : {
          ...variables,
          filters: { hash: lottieHash },
        },
    context: useMemo(
      () => ({
        clientName: SEARCH_GRAPHQL_ENDPOINT,
      }),
      [],
    ),
  });

  const { data, error, fetching } = result;

  const animationResult = data && (data?.searchPublicAnimations || data?.featuredPublicAnimations);

  useEffect(() => {
    if (animationResult) {
      if (loadingMoreRef.current) {
        setItems((tempItems) => {
          return tempItems.concat(animationResult.edges);
        });
      } else {
        setItems(animationResult.edges);
        collectionRef.current.scrollTo({ top: 0 });
      }
      loadingMoreRef.current = false;
    }
  }, [animationResult]);

  const loadMore = (): void => {
    loadingMoreRef.current = true;
    setVariables({
      first: ANIMATION_LIMIT,
      after: animationResult.pageInfo.endCursor,
      last: null,
      before: null,
    });
  };

  if (error && onError) {
    onError(error.message);
  }

  const timelineContainer = document.getElementsByClassName('drop-shadow-timeline');

  let timelineHeight = 30;

  if (timelineContainer[0]) {
    timelineHeight = timelineContainer.length > 0 ? timelineContainer[0].clientHeight : 30;
  }

  return (
    <>
      <div>
        <div
          ref={collectionRef}
          style={{
            height: query.text ? `calc(100vh - ${timelineHeight + 140}px)` : `calc(100vh - ${timelineHeight + 170}px)`,
          }}
          className="overflow-auto"
          onContextMenu={(evt) => evt.preventDefault()}
        >
          <ResultGrid
            fetching={fetching}
            data={animationResult}
            items={items}
            onSelected={onSelected}
            loadMore={loadMore}
          />
        </div>
      </div>
    </>
  );
};
