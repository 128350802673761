/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Scene, VectorJSON, AngleJSON, ScalarJSON, ShapeLayer, GroupShape } from '@lottiefiles/toolkit-js';
import { Angle, Vector, Scalar, StarShape } from '@lottiefiles/toolkit-js';

import type { Scalar2D } from './types';

export interface PolystarOption {
  innerRadius?: number;
  innerRadiusCurrentKeyFrame?: string;
  innerRadiusIsAnimated?: boolean;

  innerRoundness?: number;
  innerRoundnessCurrentKeyFrame?: string;
  innerRoundnessIsAnimated?: boolean;

  outerRadius?: number;
  outerRadiusCurrentKeyFrame?: string;
  outerRadiusIsAnimated?: boolean;

  outerRoundness?: number;
  outerRoundnessCurrentKeyFrame?: string;
  outerRoundnessIsAnimated?: boolean;

  points?: number;
  pointsCurrentKeyFrame?: string;
  pointsIsAnimated: boolean;

  position?: Scalar2D;
  positionCurrentKeyFrame?: string;
  positionIsAnimated?: boolean;

  rotation?: number;
  rotationCurrentKeyFrame?: string;
  rotationIsAnimated?: boolean;

  type: number;
}

export const updatePolystar = (scene: Scene, id: string, option: PolystarOption): void => {
  const node = scene.getNodeById(id);

  if (node instanceof StarShape) {
    const { innerRadius, innerRoundness, outerRadius, outerRoundness, points, position, rotation, type } = option;
    const polystar = node as StarShape;

    polystar.setStarType(type);

    if (position) {
      polystar.setPosition(new Vector(position[0], position[1]));
    }

    if (points) {
      polystar.setPoints(new Scalar(points));
    }

    if (rotation) {
      polystar.setRotation(new Angle(rotation));
    }

    if (innerRadius) {
      polystar.setInnerRadius(new Scalar(innerRadius));
    }

    if (outerRadius) {
      polystar.setOuterRadius(new Scalar(outerRadius));
    }

    if (innerRoundness) {
      polystar.setInnerRoundness(new Scalar(innerRoundness));
    }

    if (outerRoundness) {
      polystar.setOuterRoundness(new Scalar(outerRoundness));
    }
  }
};

export const createPolystar = (scene: Scene, parent: ShapeLayer | GroupShape, polystar?: PolystarOption): StarShape => {
  const shape = parent.createStarShape();

  if (polystar) {
    updatePolystar(scene, shape.nodeId, polystar);
  }

  return shape;
};

export const setPolystarRotation = (node: StarShape | null, deg: number): void => {
  node?.setRotation(new Angle(deg));
};

export const setPolystarPoints = (node: StarShape | null, points: number): void => {
  node?.setPoints(new Scalar(points));
};

export const setPolystarInnerRadius = (node: StarShape | null, radius: number): void => {
  node?.setInnerRadius(new Scalar(radius));
};

export const setPolystarOuterRadius = (node: StarShape | null, radius: number): void => {
  node?.setOuterRadius(new Scalar(radius));
};

export const setPolystarInnerRoundness = (node: StarShape | null, r: number): void => {
  node?.setInnerRoundness(new Scalar(r));
};

export const setPolystarOuterRoundness = (node: StarShape | null, r: number): void => {
  node?.setOuterRoundness(new Scalar(r));
};

export type CurrentPolystar = Required<PolystarOption>;

export const getCurrentPolystar = (node: StarShape): CurrentPolystar => {
  const state = node.state.animatedProperties;
  const currentFrame = node.scene.timeline.currentFrame;

  // NOTE(miljau): the assumption here is that <KeyFrame>.frame and currentFrame will line up as integers
  return {
    position: [(state.p.value as VectorJSON).x, (state.p.value as VectorJSON).y],
    positionCurrentKeyFrame:
      node.position.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    positionIsAnimated: node.position.isAnimated,

    rotation: (state.r.value as AngleJSON).deg,
    rotationCurrentKeyFrame:
      node.rotation.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    rotationIsAnimated: node.rotation.isAnimated,

    points: (state.nt.value as ScalarJSON).value,
    pointsCurrentKeyFrame:
      node.numPoints.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    pointsIsAnimated: node.numPoints.isAnimated,

    innerRadius: (state.ir.value as ScalarJSON).value,
    innerRadiusCurrentKeyFrame:
      node.innerRadius.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    innerRadiusIsAnimated: node.innerRadius.isAnimated,

    outerRadius: (state.or.value as ScalarJSON).value,
    outerRadiusCurrentKeyFrame:
      node.outerRadius.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    outerRadiusIsAnimated: node.outerRadius.isAnimated,

    innerRoundness: (state.is.value as ScalarJSON).value,
    innerRoundnessCurrentKeyFrame:
      node.innerRoundness.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    innerRoundnessIsAnimated: node.innerRoundness.isAnimated,

    outerRoundness: (state.os.value as ScalarJSON).value,
    outerRoundnessCurrentKeyFrame:
      node.outerRoundness.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    outerRoundnessIsAnimated: node.outerRoundness.isAnimated,

    type: node.state.properties.sy as number,
  };
};
