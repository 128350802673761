/**
 * Copyright 2024 Design Barn Inc.
 */

import type { RGBA } from '../components/ColorPicker';

import { setRgba } from './index';

const hexRegexp = /(^#{0,1}[\da-f]{6}$)|(^#{0,1}[\da-f]{3}$)|(^#{0,1}[\da-f]{8}$)/iu;

const regexp = /([\da-f])([\da-f])([\da-f])/iu;

export default function hexToRgb(val: string): RGBA {
  let value = val;
  const valid = hexRegexp.test(value);

  if (valid) {
    if (value.startsWith('#')) value = value.slice(1, value.length);

    if (value.length === 3) value = value.replace(regexp, '$1$1$2$2$3$3');

    const red = parseInt(value.substr(0, 2), 16);
    const green = parseInt(value.substr(2, 2), 16);
    const blue = parseInt(value.substr(4, 2), 16);
    const alpha = parseInt(value.substr(6, 2), 16) / 255;

    const color = setRgba(red, green, blue, alpha);

    return color;
  }

  return {
    red: 0,
    blue: 0,
    green: 0,
  };
}
