/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

import TimelineMainContent from './TimelineMainContent';
import TimelineSidebar from './TimelineSidebar';

export const TimelineBody: React.FC = () => {
  return (
    <div id="TimelineBody" className="flex flex-1 bg-gray-700">
      <TimelineSidebar />
      <TimelineMainContent />
    </div>
  );
};

export default TimelineBody;
