/**
 * Copyright 2024 Design Barn Inc.
 */

import { compact, uniq, isNaN, isUndefined, min, some } from 'lodash-es';

import { layerMap, setLayerNameFromId } from '~/lib/layer';
import { getNodeById } from '~/plugins/shim/utils';
import { useCreatorStore } from '~/store';
import type { SelectedNodeInfo } from '~/store/uiSlice';

const containNumber = (text: string): boolean => {
  return /\d+/u.test(text);
};

const isNullOrNaN = (value: string): boolean => isNaN(value) || isUndefined(value) || !containNumber(value);

/**
 * @example
 * findLinkingNumber([1,3,4,5]) // 2
 * findLinkingNumber([1,2,3,4,5]) // 6
 */
const findLinkingNumber = (arr: number[]): number => {
  const input = uniq(arr);

  if (input.length > 0) {
    input.sort((valueA, valueB) => valueA - valueB);

    const minVal = min(input);

    for (let i = input.length - 1; i > 0; i -= 1) {
      if (input[i] - 1 !== input[i - 1] && !isNullOrNaN(minVal) && input[i] > minVal) {
        return (input[i] - 1) as number;
      }
    }
  }

  return Number(input[input.length - 1]) + 1;
};

const removeLastSegment = (input: string, pattern: string): string => {
  // Split the input string by dot delimiter
  const segments = input.split(pattern);

  // Remove the last segment
  segments.pop();

  // Join the remaining segments back together with dots
  return `${segments.join(pattern)}${pattern}`;
};

/**
 * @example
 * getSameness("100_1","100_2") // 100_
 */
const getSameness = (elem1: string, elem2: string | undefined): string => {
  const _elem2 = elem2 ? elem2 : elem1;
  let sameness = '';

  const minLength = min([elem1.length, _elem2.length]);

  for (let i = 0; i < minLength; i += 1) {
    if (elem1[i] !== _elem2[i]) {
      break;
    }
    sameness += elem1[i];
  }

  if (sameness.includes('.')) {
    return removeLastSegment(sameness, '.');
  }

  return sameness;
};

/**
 * @example
 * getSamenessDifference(["100_1","100_2"]) // sameness: "100_", difference "1","2"
 */
const getSamenessDifference = (arr: string[]): Array<Record<string, string>> => {
  const first = arr[0]?.toString();
  const nextElem = arr[1]?.toString();
  let sameness = '';

  if (arr.length === 1) {
    sameness = getSameness(first, null);
  } else {
    sameness = getSameness(first, nextElem);
  }

  return arr.map((element) => {
    return {
      sameness,
      difference: element.slice(sameness.length),
    };
  });
};

const getPostFix = (searchTerm: string, options: string[]): string => {
  const _searchTerm = isNullOrNaN(searchTerm) ? 0 : searchTerm;
  const _options = options.map((item) => (isNullOrNaN(item) ? 0 : item));

  const numberLists = [_searchTerm, ..._options];

  const numberRegex = /^[+-]?\d+$/u;
  const isPureNumberList = numberLists.every((val) => numberRegex.test(val as string));
  const pureNumberTest = numberLists.map((val) => ({ val, test: numberRegex.test(val as string) }));
  const hasMixedPattern = some(pureNumberTest, { test: true }) && some(pureNumberTest, { test: false });
  const isSearchComposite = !numberRegex.test(searchTerm);

  let newNumber = '1';
  const testNumberLists = pureNumberTest
    .filter((item) => (isSearchComposite ? !item.test : item.test))
    .map((item) => item.val);

  if (isPureNumberList) {
    newNumber = findLinkingNumber([...numberLists].map(Number));
  } else if (hasMixedPattern || Boolean(isPureNumberList) === false) {
    const inspectNumberLists = getSamenessDifference(testNumberLists);

    if (inspectNumberLists.length > 0) {
      const sameness = inspectNumberLists[0].sameness;

      newNumber = `${sameness}${findLinkingNumber([...inspectNumberLists.map((val) => val.difference)].map(Number))}`;
    }
  }

  return newNumber as string;
};

const isLastCharacterNumber = (str: string): boolean => {
  return /\d$/u.test(str);
};

/**
 * @example
 * retrievePrefix(["Shape", "Layer"]) // "Shape Layer"
 * retrievePrefix(["Shape", "Layer", "3"]) // "Shape Layer"
 * retrievePrefix(["Shape", "Layer", "Precomp", "200_1"]) // "Shape Layer Precomp"
 */
const retrievePrefix = (list: string[]): [string, boolean] => {
  if (list.length > 0) {
    const lastWord = list[list.length - 1] as string;

    if (containNumber(lastWord) && isLastCharacterNumber(lastWord)) {
      return [[...list.slice(0, -1)].join(' '), true];
    }

    return [list.join(' '), isLastCharacterNumber(lastWord)];
  }

  return [list.join(' '), false];
};

export const getNewName = (newLayerName: string, layerNames: string[]): string => {
  const newLayerNameSplit = newLayerName.split(' ');

  const [tokenize, lastCharNum] = retrievePrefix(newLayerNameSplit);

  const inputFirstTokenize = tokenize;
  const inputLastTokenize = lastCharNum ? newLayerNameSplit[newLayerNameSplit.length - 1] : '';

  const mapLayers = new Map<string, string[]>();

  if (layerNames.length > 0) {
    layerNames.forEach((layer) => {
      const layerSplit = layer.split(' ');
      const [layerToken, layerLastCharNum] = retrievePrefix(layerSplit);

      const tokenizeLayer = layerToken;
      const lastTokenizeLayer = layerLastCharNum ? (layerSplit[layerSplit.length - 1] as string | null) : '';

      if (!mapLayers.has(tokenizeLayer)) {
        mapLayers.set(tokenizeLayer, []);
      }

      mapLayers.get(tokenizeLayer).push(lastTokenizeLayer as string);
    });
  }

  const inputValues = mapLayers.get(inputFirstTokenize);

  if (inputValues) {
    const nextValue = getPostFix(inputLastTokenize as string, inputValues);

    return `${inputFirstTokenize} ${nextValue}`.trim();
  }

  return newLayerName;
};

const getLayerUIByLevel = (level: number): string[] => {
  const ids: string[] = [];

  for (const [key, value] of layerMap) {
    if (value.level === level) {
      ids.push(key);
    }
  }

  return ids;
};

export const renameLayerItem = ({
  newItemIds,
  newItemIndex,
  newNames = [],
  selectedItem,
}: {
  newItemIds: string[];
  newItemIndex: number;
  newNames?: string[];
  selectedItem: SelectedNodeInfo;
}): string | null => {
  const item = selectedItem;
  const toolkitIds = newItemIds;

  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  const itemMap = layerMap.get(item.nodeId);

  let currentLevelIds = [] as string[];

  if (itemMap) {
    const currentLevel = itemMap.level;

    if (itemMap.parent.length > 0) {
      itemMap.parent.forEach((parentId) => {
        const parentItemMap = layerMap.get(parentId);

        currentLevelIds = currentLevelIds.concat(parentItemMap?.children);
      });
    } else if (currentLevel === 0) {
      currentLevelIds = getLayerUIByLevel(currentLevel);
    }
  } else {
    currentLevelIds = getLayerUIByLevel(0);
  }

  if (currentLevelIds.length > 0) {
    const otherLayerNames = uniq(
      compact(
        currentLevelIds.map((levelId: string): string[] | null => {
          const node = getNodeByIdOnly(levelId);

          if (node) {
            return node.name;
          }

          return null;
        }),
      ),
    );

    const selectedNode = getNodeByIdOnly(item.nodeId);

    const newName = getNewName(selectedNode.name?.trim() as string, [
      ...otherLayerNames.map((layerName) => layerName.trim()),
      ...newNames,
    ]);

    setLayerNameFromId(newName, toolkitIds[newItemIndex]);

    return newName;
  }

  return null;
};

export const renameLayers = async (toolkitIds: string[], fromPaste = false): void => {
  const layers = useCreatorStore.getState().toolkit.json?.allLayers || [];

  if (layers.length > 0) {
    const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;

    let targetNodes = [...selectedNodes];

    if (fromPaste) {
      const clipboard = useCreatorStore.getState().ui.clipboard;

      if (clipboard) {
        let targetNodesIds = (
          clipboard.nodes.length > 0 ? clipboard.nodes.map((item) => item.data.id as string) : []
        ) as string[];
        const selectedNodesIds = selectedNodes.map((item) => item.nodeId);

        targetNodesIds = targetNodesIds.filter((pasteNodeId) => !selectedNodesIds.includes(pasteNodeId));

        if (targetNodesIds.length > 0) {
          //  paste the clipboard only
          targetNodes = [...targetNodesIds.map((id) => getNodeById(id))];
        }
      }
    }

    if (targetNodes.length > 0) {
      const newNames = [] as string[];

      targetNodes.forEach((item, itemIndex) => {
        const newName = renameLayerItem({
          selectedItem: item,
          newItemIds: toolkitIds,
          newItemIndex: itemIndex,
          newNames,
        });

        if (newName) {
          newNames.push(newName);
        }
      });
    }
  }
};
