/**
 * Copyright 2022 Design Barn Inc.
 */

export const ViewportConfig = {
  CameraZPosition: -50,
  CameraZoom: 1,
  Background: {
    w: 500,
    h: 500,
  },
  Margin: 50,
  MinZoom: 0.1,
  MaxZoom: 50,
  RedrawThrottleDelay: 0,
  CanvasBottomContent: 65,
  RulerSize: 24,
} as const;
