/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import { SaveContext } from '../SaveContext';

import { CreateDirectory } from './create';
import { ProjectFolder } from './projectFolder';

import {
  WorkspaceProjectFolder,
  WorkspaceProjectDraft,
  LayerChevronCollapse,
  WorkspaceProjectSelectedFolder,
} from '~/assets/icons';
import { DRAFT_FOLDER } from '~/providers/api/constant';
import { useWorkspaceAPI } from '~/providers/api/workspace';
import { useCreatorStore } from '~/store';
import type { IWorkspaceFolder, IWorkspaceProject } from '~/store/projectSlice';
import { DirectoryType } from '~/store/projectSlice';

interface ProjectProp {
  index: number;
  project: IWorkspaceProject;
}

export const Project: React.FC<ProjectProp> = ({ index, project }: ProjectProp) => {
  const { selected, setSelected } = useContext(SaveContext);
  const { token } = useCreatorStore((state) => state.user);

  const { setError } = useContext(SaveContext);

  const { fetchWorkflowFolders } = useWorkspaceAPI(token);
  const [folders, setFolders] = useState<IWorkspaceFolder[]>([]);

  const { id } = selected;

  const isProjectSelected = Boolean(id === project.id);

  const isProjectDrafts = Boolean(project.title === DRAFT_FOLDER);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const selectedWorkspaceFolders = (await fetchWorkflowFolders(id as string)) as IWorkspaceFolder[];

        if (selectedWorkspaceFolders instanceof Error) {
          setError(true);

          return;
        }

        setFolders(selectedWorkspaceFolders);
      } catch (error) {
        // Handle any potential errors
        // console.error('Error fetching folders:', error);
      }
    };

    if (isProjectSelected && !isProjectDrafts) {
      fetchData();
    }
  }, [id, fetchWorkflowFolders, isProjectSelected, isProjectDrafts, setError]);

  const hasFolders = project.folderCount > 0;

  const isCreateNew = 'tempId' in project;

  return (
    <>
      {isCreateNew && (
        <CreateDirectory index={index} type={project.type} tempId={project.id} parentId={project.workspaceId} />
      )}
      {!isCreateNew && (
        <>
          <div
            className={clsx(
              `ml-[18px] flex cursor-pointer items-center rounded p-1 hover:bg-gray-700 ${
                index === 0 ? 'mt-3' : 'mt-2'
              }`,
              {
                'bg-gray-700': isProjectSelected,
              },
            )}
            onClick={() => setSelected({ id: project.id, type: DirectoryType.Project, title: project.title as string })}
          >
            <button
              className={`mr-1 justify-center rounded-layer-thumbnail ${
                hasFolders === false ? 'invisible' : 'visible'
              }`}
            >
              <LayerChevronCollapse
                className={clsx(
                  `h-3 w-3 cursor-pointer stroke-current hover:text-white ${
                    isProjectSelected ? 'text-white' : 'text-gray-500'
                  }`,
                  {
                    'rotate-90': isProjectSelected,
                  },
                )}
              />
            </button>
            <div className="flex items-center">
              {!isProjectDrafts &&
                React.cloneElement(
                  isProjectSelected ? <WorkspaceProjectSelectedFolder /> : <WorkspaceProjectFolder />,
                  {
                    className: `stroke-current ${
                      isProjectSelected ? 'text-white' : 'text-gray-500'
                    } hover:text-white cursor-pointer h-3 w-3`,
                  },
                )}

              {isProjectDrafts &&
                React.cloneElement(<WorkspaceProjectDraft />, {
                  className: `stroke-current ${
                    isProjectSelected ? 'text-white' : 'text-gray-500'
                  } hover:text-white cursor-pointer h-3 w-3`,
                })}
              <span className={`ml-1 text-caption ${isProjectSelected ? 'text-white' : 'text-gray-400'}`}>
                {project.title}
              </span>
            </div>
          </div>
          {folders.length > 0 &&
            folders.map((folder: IWorkspaceFolder, folderIndex: number) => {
              return <ProjectFolder index={folderIndex} key={folderIndex} folder={folder} />;
            })}
        </>
      )}
    </>
  );
};
