/**
 * Copyright 2024 Design Barn Inc.
 */

import type { Marker } from '@lottiefiles/toolkit-js';

import { stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const updateMarkers = (): void => {
  const scene = toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex];

  if (!scene) {
    return;
  }

  useCreatorStore.getState().timeline.setMarkers(scene.markers);
};

export const selectSegment = (_marker: Marker): void => {
  if (useCreatorStore.getState().timeline.selectedMarker?.nodeId === _marker.nodeId) {
    useCreatorStore.getState().timeline.setSelectedMarker(null);

    return;
  }

  useCreatorStore.getState().timeline.setIsSegmentHovered(false);
  useCreatorStore.getState().timeline.setWorkAreaFrameStart(_marker.startFrame);
  useCreatorStore.getState().timeline.setWorkAreaFrameEnd(_marker.endFrame);
  useCreatorStore.getState().timeline.setPrevWorkAreaFrameStart(_marker.startFrame);
  useCreatorStore.getState().timeline.setPrevWorkAreaFrameEnd(_marker.endFrame);
  useCreatorStore.getState().timeline.setSelectedMarker(_marker);
};

export const addSegment = (): void => {
  const scene = toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex];

  if (!scene) {
    return;
  }

  stateHistory.beginAction();

  const marker = scene.createMarker();

  marker.setComment('Segment name');
  marker.setStartFrame(useCreatorStore.getState().timeline.workAreaFrameStart);
  marker.setEndFrame(useCreatorStore.getState().timeline.workAreaFrameEnd);

  stateHistory.endAction();

  updateMarkers();
  selectSegment(marker);

  window.setTimeout(() => {
    const node = document.getElementById(marker.nodeId);

    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    const nameInput = document.getElementById(`segment-name-${marker.nodeId}`) as HTMLInputElement | null;

    if (nameInput) {
      nameInput.select();
    }
  }, 200);
};
