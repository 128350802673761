/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useRef, useLayoutEffect } from 'react';
import Draggable from 'react-draggable';

import { AnimatedProperties } from './animatedProperties';

import { FloatingWindow, Window, WindowHeader } from '~/components/Elements/Window';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  options?: any;
}

export const AnimationAdder: React.FC<Props> = ({ isOpen, onClose, options }) => {
  const { defaultPosition } = options;

  const modalRef = useRef<HTMLDivElement | null>(null);

  const [yOffset, setYOffset] = useState<null | number>(null);

  useLayoutEffect(() => {
    // setTimeout is used cuz we need to wait for the content to mount inside the FloatingPanel's portal
    setTimeout(() => {
      if (modalRef.current && yOffset === null) {
        const modalHeight = modalRef.current.clientHeight + 10;
        const viewportHeight = window.innerHeight;

        if ((defaultPosition.y as number) + modalHeight > viewportHeight) {
          setYOffset(viewportHeight - modalHeight - (defaultPosition.y as number) - 10);
        }
      }
    }, 0);
  }, [yOffset, defaultPosition.y]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 z-10 bg-transparent" onClick={onClose} />
      <FloatingWindow>
        {({ windowProps }) => (
          <Draggable
            defaultPosition={defaultPosition}
            handle=".draggable-handle"
            positionOffset={{ y: yOffset ?? 0, x: 0 }}
          >
            <Window
              nodeRef={modalRef}
              {...windowProps}
              style={{
                zIndex: windowProps.style.zIndex,
                left: 0,

                width: 248,
              }}
            >
              {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
              <WindowHeader title="Animate Properties" onClose={onClose} className="draggable-handle" />
              <AnimatedProperties />
            </Window>
          </Draggable>
        )}
      </FloatingWindow>
    </>
  );
};
