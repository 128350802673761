/**
 * Copyright 2022 Design Barn Inc.
 */

import { isEqual } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { getDualMixedValue, toggleKeyframe } from './helpers';

import { DualNumberInput } from '~/components/Elements/Input';
import type { NumberResult, NumberInputOption } from '~/components/Elements/Input';
import { MIN_X, MAX_X, MIN_Y, MAX_Y } from '~/data/range';
import { UITransformType, updateGroupFromUI } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType } from '~/lib/toolkit';
import { HotKey } from '~/providers/HotkeyListener';
import { useCreatorStore } from '~/store';
import { formatNumber } from '~/utils';

const positionOption: { x: NumberInputOption; y: NumberInputOption } = {
  x: {
    name: 'x',
    label: 'X',
    min: MIN_X,
    max: MAX_X,
  },
  y: {
    name: 'y',
    label: 'Y',
    min: MIN_Y,
    max: MAX_Y,
  },
};

const handleKeyframeClick = (): void => {
  toggleKeyframe(AnimatedType.POSITION);
};

export const TransformPositionField: React.FC = () => {
  const [x, y, currentKeyframe, selectedNodesInfo, currentTransforms, positionAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentTransform.position[0],
      state.toolkit.currentTransform.position[1],
      state.toolkit.currentTransform.positionCurrentKeyframe,
      state.ui.selectedNodesInfo,
      state.toolkit.currentTransforms,
      state.toolkit.currentTransform.positionIsAnimated,
    ],
    shallow,
  );

  const [keyframeActive, setKeyframeActive] = useState(false);
  const [isPositionAnimated, setIsPositionAnimated] = useState(false);
  const previousTransforms = useRef(currentTransforms);

  const multiSelect = selectedNodesInfo.length > 1;

  const handleOnChange = useCallback((result: NumberResult) => {
    updateGroupFromUI(UITransformType.Translation, result);

    emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);
  }, []);

  const leftValue = formatNumber(x);
  const rightValue = formatNumber(y);

  useEffect(() => {
    if (!isEqual(previousTransforms.current, currentTransforms) || multiSelect) {
      previousTransforms.current = currentTransforms;
      const isActive = currentTransforms.every((transform) => transform.positionCurrentKeyframe);

      setKeyframeActive(isActive);

      const isAnimated = currentTransforms.every((transform) => transform.positionIsAnimated);

      setIsPositionAnimated(isAnimated);
    }

    if (!multiSelect) {
      setKeyframeActive(currentKeyframe !== '');
      setIsPositionAnimated(positionAnimated);
    }
  }, [currentKeyframe, multiSelect, selectedNodesInfo, currentTransforms, positionAnimated]);

  const mixedValue = useMemo(() => {
    return getDualMixedValue(multiSelect, currentTransforms, AnimatedType.POSITION);
  }, [currentTransforms, multiSelect]);

  return (
    <>
      <div className="mr-6 flex">
        <DualNumberInput
          showKeyframe
          onChange={handleOnChange}
          onKeyframeClick={handleKeyframeClick}
          hasKeyframe={keyframeActive}
          left={leftValue}
          right={rightValue}
          leftMin={positionOption.x.min || MIN_X}
          leftMax={positionOption.x.max || MAX_X}
          rightMax={positionOption.y.max || MAX_Y}
          rightMin={positionOption.y.min || MIN_Y}
          leftOption={positionOption.x}
          rightOption={positionOption.y}
          tooltip={{ left: 'PositionX', right: 'PositionY' }}
          leftMessage={mixedValue.left ?? null}
          rightMessage={mixedValue.right ?? null}
          isChannelAnimated={isPositionAnimated}
          keyframeShortcut={HotKey.TOGGLE_POSITON_KEYFRAME.toUpperCase()}
        />
      </div>
    </>
  );
};
