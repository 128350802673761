/**
 * Copyright 2021 Design Barn Inc.
 */

import type { UseFloatingProps } from '@floating-ui/react-dom-interactions';
import { autoPlacement, useFloating, offset } from '@floating-ui/react-dom-interactions';
import type { ColorStop, ColorStopJSON } from '@lottiefiles/toolkit-js';
import {
  FillShape,
  GradientFillType,
  Scalar,
  GradientColor,
  ShapeType,
  Color,
  GradientFillShape,
} from '@lottiefiles/toolkit-js';
import type { FC, ReactNode } from 'react';
import React, { useState, useCallback, useEffect, useMemo, useRef, Fragment } from 'react';
import Draggable from 'react-draggable';

import { ColorPickerPopover } from './ColorPickerPopover';
import type { RGBA } from './ColorPickerPopover';
import type { GradientData } from './Gradient';

import { hexToRgb, rgbToHex, parseHex } from '@/ColorPicker/helpers';
import { ColorPickerFill, ColorPickerStroke, ColorPickerTransparent } from '~/assets/icons/color-picker';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { FloatingWindow } from '~/components/Elements/Window';
import { BackgroundColor } from '~/data/constant';
import { useClickOutside } from '~/hooks/useClickOutside';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { CurrentFillShape, CurrentGFillShape } from '~/lib/toolkit';
import { switchSolidAndGradient, stateHistory, equalizeNumColorStops } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export enum ColorMode {
  Linear = 'Linear',
  Radial = 'Radial',
  Solid = 'Solid',
}

export const ColorOptions = [
  {
    label: 'Solid',
    type: ColorMode.Solid,
  },
  {
    label: 'Linear',
    type: ColorMode.Linear,
  },
  {
    label: 'Radial',
    type: ColorMode.Radial,
  },
];

interface ColorPickerProps {
  color: string;
  enableColorModeChange: boolean;
  gradientShape: Array<CurrentGFillShape | CurrentFillShape> | null;
  hasKeyframe?: boolean;
  isChannelAnimated: boolean;
  message?: string;
  mixedColors: boolean;
  noFloatingPortal?: boolean;
  onChangeColor: (color: string) => void;
  onChangeOpacity: (opacity: number) => void;
  onColorModeChange: (prevColorMode: ColorMode, newColorMode: ColorMode) => void;
  onKeyframeClick?: () => void;
  onStopColor: (color: string) => void;
  opacity: number;
  options?: UseFloatingProps;
  overrideStyle?: Record<string, string | number | boolean>;
  selectedIds?: string[];
  shapeType: string;
  showDocumentColors?: boolean;
  showEyeDropper?: boolean;
  showKeyframe?: boolean;
  showOpacityControl?: boolean;
  styleClass?: string;
  type?: string;
}

const defaultOptions: Partial<UseFloatingProps> = {
  middleware: [autoPlacement(), offset(32)],
};

const DefaultGradient: GradientData = {
  points: [
    {
      left: 0,
      red: 0,
      green: 0,
      blue: 0,
      alpha: 1,
    },
    {
      left: 1,
      red: 255,
      green: 0,
      blue: 0,
      alpha: 1,
    },
  ],
  degree: 0,
  type: GradientFillType.LINEAR,
  style: '',
};

const CustomFragment: FC<{
  children: ({ windowProps }: { windowProps: { style: Record<string, unknown> } }) => ReactNode;
}> = ({ children }) => <Fragment>{children({ windowProps: { style: {} } })}</Fragment>;

export const ColorPicker: React.FC<ColorPickerProps> = ({
  color,
  opacity,
  onChangeColor,
  onStopColor,
  onChangeOpacity,
  options = defaultOptions,
  showKeyframe = false,
  hasKeyframe = false,
  mixedColors,
  onKeyframeClick,
  styleClass = '',
  type,
  shapeType,
  overrideStyle,
  isChannelAnimated = false,
  message,
  gradientShape,
  selectedIds,
  onColorModeChange,
  enableColorModeChange,
  showDocumentColors = true,
  showOpacityControl = true,
  showEyeDropper = true,
  noFloatingPortal,
}) => {
  const [open, setOpen] = useState(false);
  const updatedSelectedIdsRef = useRef<string[]>(selectedIds ?? []);

  useEffect(() => {
    updatedSelectedIdsRef.current = selectedIds ?? [];
  }, [selectedIds]);

  useEffect(() => {
    if (open && (shapeType === ShapeType.GRADIENT_FILL || shapeType === ShapeType.GRADIENT_STROKE)) {
      emitter.emit(EmitterEvent.CANVAS_TOGGLE_GRADIENT_CONTROLS, {
        enabled: true,
        gradientShapes: gradientShape as CurrentGFillShape[],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapeType]);

  const { floating, reference, refs, x, y } = useFloating(options);

  const handleKeyframeClick = useCallback((): void => {
    if (onKeyframeClick) {
      onKeyframeClick();
    }
  }, [onKeyframeClick]);

  const topY = type ? 60 : y;

  const style = {
    ...(shapeType === ShapeType.GRADIENT_FILL && { background: color }),
    ...(shapeType === ShapeType.GRADIENT_STROKE && { background: color }),
    ...(shapeType === ShapeType.FILL && { background: parseHex(color) }),
    ...(shapeType === ShapeType.STROKE && {
      background: 'none',
      boxShadow: `inset 0 0 0 3px ${parseHex(color)}, inset 0 0 0 3.5px #3D4852`,
      borderRadius: '50%',
    }),
  };

  const isTransparent = message === BackgroundColor.Transparent;
  const { blue, green, red } = hexToRgb(color);

  const info = useMemo(
    () =>
      ({
        red,
        blue,
        green,
        alpha: opacity / 100,
      }) as Color,
    [blue, green, opacity, red],
  );

  const gradient = useMemo(() => {
    if (gradientShape && gradientShape.length > 0) {
      const grad = DefaultGradient;

      // the selection may include solid fills, so we need to find the first gradient fill
      const shape = gradientShape.find(
        (child) => child.g && (child.g as ColorStopJSON[]).length > 0,
      ) as CurrentGFillShape;

      grad.points = shape.g.map((point) => ({
        red: point.r,
        blue: point.b,
        green: point.g,
        left: point.offset,
        alpha: point.a,
      }));
      grad.degree = shape.ha;
      grad.type = shape.type;

      return grad;
    }

    return null;
  }, [gradientShape]);

  const equalizeStops = useCallback(
    (toolkitNode: GradientFillShape, gradData: GradientData, colorStops: ColorStop[]) => {
      const currentFrame = useCreatorStore.getState().toolkit.currentFrame;
      const currentValue = toolkitNode.gradient.valueAtKeyFrame(currentFrame).colorStops;
      const isAddition = currentValue.length < colorStops.length;
      const newColor = {
        color: new Color(
          gradData.addedPoint?.red,
          gradData.addedPoint?.green,
          gradData.addedPoint?.blue,
          gradData.addedPoint?.alpha,
        ),
        stop: new Scalar(gradData.addedPoint?.left),
      };

      equalizeNumColorStops(toolkitNode, newColor, currentFrame, isAddition, gradData.deletedIndex);
    },
    [],
  );

  const createColorStopsFromGradient = useCallback((gradientData: GradientData): ColorStop[] => {
    const colorStops = gradientData.points.map((point) => ({
      color: new Color(point.red, point.green, point.blue, point.alpha),
      stop: new Scalar(point.left),
    }));

    colorStops.sort((firstStop, secondStop) => firstStop.stop.value - secondStop.stop.value);

    return colorStops;
  }, []);

  const onEndGradientChange = useCallback(() => {
    stateHistory.endAction();
  }, []);

  const onGradientChange = useCallback(
    (gradData: GradientData) => {
      if (gradientShape?.every((shape) => !shape.g) || !gradientShape || gradientShape.length === 0) return;

      const colorStops = createColorStopsFromGradient(gradData);
      const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
      const isMultiselect = updatedSelectedIdsRef.current.length > 1;

      const allNodes = isMultiselect
        ? updatedSelectedIdsRef.current.map((id) => getNodeByIdOnly(id))
        : [getNodeByIdOnly(gradientShape[0]?.id as string)];

      const newColorMode = gradData.type === GradientFillType.LINEAR ? ColorMode.Linear : ColorMode.Radial;

      allNodes.forEach((node) => {
        if (node instanceof GradientFillShape && colorStops.length !== node.gradient.value.colorStops.length) {
          equalizeStops(node, gradData, colorStops);
        }

        if (node instanceof GradientFillShape && node.gradientType === gradData.type) {
          node.setGradient(new GradientColor(colorStops));
        }
        if (node instanceof GradientFillShape && node.gradientType !== gradData.type) {
          const currentColorMode = node.gradientType === GradientFillType.LINEAR ? ColorMode.Linear : ColorMode.Radial;

          switchSolidAndGradient(node, currentColorMode, newColorMode, null, colorStops, !isMultiselect);
          emitter.emit(EmitterEvent.CANVAS_COLOR_MODE_UPDATED);
        }

        if (node instanceof FillShape) {
          const gradientFill = switchSolidAndGradient(
            node,
            ColorMode.Solid,
            newColorMode,
            null,
            colorStops,
            !isMultiselect,
          );

          if (!gradientFill) return;

          const newSelectedIds = updatedSelectedIdsRef.current
            .filter((id) => id !== node.nodeId)
            .concat(gradientFill.nodeId);

          updatedSelectedIdsRef.current = newSelectedIds;
          emitter.emit(EmitterEvent.CANVAS_COLOR_MODE_UPDATED);
        }
      });

      if (gradData.deletedIndex) {
        onEndGradientChange();
      }

      emitter.emit(EmitterEvent.SHAPE_GRADIENT_FILL_COLOR_UPDATED);
    },
    [gradientShape, createColorStopsFromGradient, equalizeStops, onEndGradientChange],
  );

  const onStartGradientChange = useCallback(
    (gradData: GradientData) => {
      stateHistory.beginAction();
      onGradientChange(gradData);
    },
    [onGradientChange],
  );

  const handleColorThumbClick = useCallback(() => {
    if (shapeType === ShapeType.GRADIENT_FILL || shapeType === ShapeType.GRADIENT_STROKE) {
      emitter.emit(EmitterEvent.CANVAS_TOGGLE_GRADIENT_CONTROLS, {
        enabled: !open,
        gradientShapes: gradientShape as CurrentGFillShape[],
      });
    }

    setOpen(!open);
  }, [gradientShape, open, shapeType]);

  const handleOnClose = useCallback(() => {
    if (shapeType === ShapeType.GRADIENT_FILL || shapeType === ShapeType.GRADIENT_STROKE) {
      emitter.emit(EmitterEvent.CANVAS_TOGGLE_GRADIENT_CONTROLS, {
        enabled: false,
      });
    }

    setOpen(false);
  }, [shapeType]);

  useClickOutside(
    refs.floating,
    (event) => {
      const isGradient = shapeType === ShapeType.GRADIENT_FILL || shapeType === ShapeType.GRADIENT_STROKE;
      const isCanvasClicked = document.getElementById('artboard-container')?.contains(event.target as Node);

      if ((isGradient && !isCanvasClicked) || !isGradient) {
        handleOnClose();
      }
    },
    [],
  );

  const Container = noFloatingPortal ? CustomFragment : FloatingWindow;

  return (
    <>
      <div
        id="color-picker-thumbnail"
        ref={reference}
        className={`h-4 w-4 cursor-pointer rounded-lg border-[0.5px] border-solid border-gray-600 shadow-sm ${styleClass}`}
        style={mixedColors ? {} : style}
        onClick={handleColorThumbClick}
      >
        {isTransparent && shapeType === ShapeType.FILL && <ColorPickerTransparent className="h-4 w-3.5" />}

        {!isTransparent && (
          <>
            {shapeType === ShapeType.FILL && mixedColors && <ColorPickerFill className="h-4 w-4" />}
            {shapeType === ShapeType.GRADIENT_FILL && mixedColors && <ColorPickerFill className="h-4 w-4" />}
            {shapeType === ShapeType.STROKE && mixedColors && <ColorPickerStroke className=" h-4 w-4" />}
          </>
        )}
      </div>
      {open && (
        <Container>
          {({ windowProps }) => (
            <Draggable nodeRef={refs.floating} handle="#color-picker-header">
              <ColorPickerPopover
                id="color-picker"
                color={info}
                gradient={gradient ? { ...gradient } : null}
                onChange={(col: RGBA) => {
                  onChangeColor(rgbToHex(col.red, col.green, col.blue));
                  // eslint-disable-next-line no-undefined
                  if (col.alpha !== undefined) onChangeOpacity(col.alpha * 100);
                }}
                onEndChange={(col: RGBA) => {
                  onStopColor(rgbToHex(col.red, col.green, col.blue));
                  // eslint-disable-next-line no-undefined
                  if (col.alpha !== undefined) onChangeOpacity(col.alpha * 100);
                }}
                onChangeGradient={onGradientChange}
                onStartGradientChange={onStartGradientChange}
                onEndGradientChange={onEndGradientChange}
                onClose={handleOnClose}
                onColorModeChange={onColorModeChange}
                enableColorModeChange={enableColorModeChange}
                showDocumentColors={showDocumentColors}
                showOpacityControl={showOpacityControl}
                showEyeDropper={showEyeDropper}
                windowProps={{
                  ...windowProps,
                  style: {
                    top: topY ?? '',
                    left: x ?? '',
                    width: 250,
                    zIndex: 1,
                    ...windowProps.style,
                    ...overrideStyle,
                  },
                  nodeRef: floating,
                }}
              />
            </Draggable>
          )}
        </Container>
      )}
      {showKeyframe && (
        <div className="absolute right-[-9px] top-[2px]">
          <KeyframeButton
            hasKeyframe={hasKeyframe}
            onClick={handleKeyframeClick}
            withTooltip
            isChannelAnimated={isChannelAnimated}
          />
        </div>
      )}
    </>
  );
};
