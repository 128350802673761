/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

export const SharpCorner: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.03275 4.09309L0.479699 8.64615C0.284436 8.84141 0.284436 9.15799 0.479699 9.35325C0.674961 9.54851 0.991543 9.54851 1.18681 9.35325L5.88648 4.65358C5.56389 4.5262 5.27397 4.33402 5.03275 4.09309ZM7.7664 4.63995L12.4797 9.35325C12.675 9.54851 12.9915 9.54851 13.1868 9.35325C13.3821 9.15799 13.3821 8.84141 13.1868 8.64615L8.60991 4.06924C8.37243 4.3128 8.08595 4.50835 7.7664 4.63995Z"
      fill="#4C5863"
    />
    <path
      d="M8.83337 2.33319C8.83337 3.43776 7.93794 4.33319 6.83337 4.33319C5.7288 4.33319 4.83337 3.43776 4.83337 2.33319C4.83337 1.22862 5.7288 0.333191 6.83337 0.333191C7.93794 0.333191 8.83337 1.22862 8.83337 2.33319Z"
      fill="#808E9A"
    />
  </svg>
));

export const SharpCornerSelected: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.03275 4.09306L0.479699 8.64611C0.284436 8.84138 0.284436 9.15796 0.479699 9.35322C0.674961 9.54848 0.991543 9.54848 1.18681 9.35322L5.88648 4.65355C5.56389 4.52616 5.27397 4.33399 5.03275 4.09306ZM7.7664 4.63992L12.4797 9.35322C12.675 9.54848 12.9915 9.54848 13.1868 9.35322C13.3821 9.15796 13.3821 8.84138 13.1868 8.64611L8.60991 4.06921C8.37243 4.31277 8.08595 4.50832 7.7664 4.63992Z"
      fill="#019D91"
    />
    <path
      d="M8.83337 2.33325C8.83337 3.43782 7.93794 4.33325 6.83337 4.33325C5.7288 4.33325 4.83337 3.43782 4.83337 2.33325C4.83337 1.22868 5.7288 0.333252 6.83337 0.333252C7.93794 0.333252 8.83337 1.22868 8.83337 2.33325Z"
      fill="#00C1A2"
    />
  </svg>
));

export const MirroredTangent: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.82017 2.73599C5.81977 2.73617 5.81936 2.73635 5.81896 2.73654C4.95868 3.12341 4.26643 3.69595 3.71819 4.34293C2.54861 5.72317 2.01278 7.46618 1.87049 8.60447C1.83624 8.87848 2.0306 9.12837 2.30461 9.16262C2.57862 9.19688 2.82852 9.00251 2.86277 8.7285C2.9875 7.73067 3.4673 6.18584 4.48112 4.98942C4.93288 4.45629 5.48618 3.99737 6.15741 3.68154C5.98578 3.39815 5.86854 3.07814 5.82017 2.73599ZM10.4617 3.68162C11.1329 3.99745 11.6861 4.45634 12.1378 4.98942C13.1516 6.18584 13.6315 7.73067 13.7562 8.7285C13.7904 9.00251 14.0403 9.19688 14.3143 9.16262C14.5883 9.12837 14.7827 8.87848 14.7485 8.60447C14.6062 7.46618 14.0703 5.72317 12.9008 4.34293C12.3525 3.69595 11.6603 3.12341 10.8 2.73654C10.7997 2.73639 10.7993 2.73624 10.799 2.73609C10.7506 3.07823 10.6334 3.39824 10.4617 3.68162Z"
      fill="#4C5863"
    />
    <circle cx="1.9422" cy="2.67093" r="1.06122" fill="#808E9A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7471 3.00001C10.7973 2.80189 10.8239 2.5944 10.8239 2.38068C10.8239 2.36487 10.8238 2.34909 10.8235 2.33334H14.6666C14.8507 2.33334 15 2.48258 15 2.66668C15 2.85077 14.8507 3.00001 14.6666 3.00001L10.7471 3.00001ZM5.87218 3.00001L1.99996 3.00001C1.81586 3.00001 1.66663 2.85077 1.66663 2.66668C1.66663 2.48258 1.81586 2.33334 1.99996 2.33334H5.79577C5.79547 2.34909 5.79533 2.36487 5.79533 2.38068C5.79533 2.5944 5.82199 2.80189 5.87218 3.00001ZM10.0233 2.33334H10.0233C10.0237 2.34911 10.0239 2.36494 10.0239 2.38081C10.0239 2.49912 10.0119 2.61462 9.9891 2.72618C10.0119 2.61458 10.0239 2.49903 10.0239 2.38068C10.0239 2.36485 10.0237 2.34907 10.0233 2.33334Z"
      fill="#808E9A"
    />
    <circle cx="14.6769" cy="2.67093" r="1.06122" fill="#808E9A" />
    <path
      d="M10.3334 2C10.3334 3.10457 9.43794 4 8.33337 4C7.2288 4 6.33337 3.10457 6.33337 2C6.33337 0.895431 7.2288 0 8.33337 0C9.43794 0 10.3334 0.895431 10.3334 2Z"
      fill="#808E9A"
    />
  </svg>
));

export const MirroredTangentSelected: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.82017 2.73599C5.81977 2.73617 5.81936 2.73635 5.81896 2.73654C4.95868 3.12341 4.26643 3.69595 3.71819 4.34293C2.54861 5.72317 2.01278 7.46618 1.87049 8.60447C1.83624 8.87848 2.0306 9.12837 2.30461 9.16262C2.57862 9.19688 2.82852 9.00251 2.86277 8.7285C2.9875 7.73067 3.4673 6.18584 4.48112 4.98942C4.93288 4.45629 5.48618 3.99737 6.15741 3.68154C5.98578 3.39815 5.86854 3.07814 5.82017 2.73599ZM10.4617 3.68162C11.1329 3.99745 11.6861 4.45634 12.1378 4.98942C13.1516 6.18584 13.6315 7.73067 13.7562 8.7285C13.7904 9.00251 14.0403 9.19688 14.3143 9.16262C14.5883 9.12837 14.7827 8.87848 14.7485 8.60447C14.6062 7.46618 14.0703 5.72317 12.9008 4.34293C12.3525 3.69595 11.6603 3.12341 10.8 2.73654C10.7997 2.73639 10.7993 2.73624 10.799 2.73609C10.7506 3.07823 10.6334 3.39824 10.4617 3.68162Z"
      fill="#019D91"
    />
    <circle cx="1.9422" cy="2.67093" r="1.06122" fill="#00C1A2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7471 3.00001C10.7973 2.80189 10.8239 2.5944 10.8239 2.38068C10.8239 2.36487 10.8238 2.34909 10.8235 2.33334H14.6666C14.8507 2.33334 15 2.48258 15 2.66668C15 2.85077 14.8507 3.00001 14.6666 3.00001L10.7471 3.00001ZM5.87218 3.00001L1.99996 3.00001C1.81586 3.00001 1.66663 2.85077 1.66663 2.66668C1.66663 2.48258 1.81586 2.33334 1.99996 2.33334H5.79577C5.79547 2.34909 5.79533 2.36487 5.79533 2.38068C5.79533 2.5944 5.82199 2.80189 5.87218 3.00001ZM10.0233 2.33334H10.0233C10.0237 2.34911 10.0239 2.36494 10.0239 2.38081C10.0239 2.49912 10.0119 2.61462 9.9891 2.72618C10.0119 2.61458 10.0239 2.49903 10.0239 2.38068C10.0239 2.36485 10.0237 2.34907 10.0233 2.33334Z"
      fill="#00C1A2"
    />
    <circle cx="14.6769" cy="2.67093" r="1.06122" fill="#00C1A2" />
    <path
      d="M10.3334 2C10.3334 3.10457 9.43794 4 8.33337 4C7.2288 4 6.33337 3.10457 6.33337 2C6.33337 0.895431 7.2288 0 8.33337 0C9.43794 0 10.3334 0.895431 10.3334 2Z"
      fill="#00C1A2"
    />
  </svg>
));

export const CurveCorner: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.3349 3.05063C4.31837 3.05607 4.30197 3.06243 4.28575 3.06973C3.42548 3.45659 2.73323 4.02914 2.18499 4.67612C1.0154 6.05636 0.479573 7.79937 0.337288 8.93766C0.303036 9.21167 0.497399 9.46156 0.771409 9.49581C1.04542 9.53006 1.29531 9.3357 1.32957 9.06169C1.45429 8.06386 1.9341 6.51903 2.94792 5.32261C3.41566 4.77062 3.99225 4.29818 4.69589 3.98175C4.71793 3.97184 4.73889 3.96053 4.75873 3.94798C4.56208 3.68399 4.41602 3.38008 4.3349 3.05063ZM8.79397 3.94807C8.81377 3.96059 8.83467 3.97187 8.85665 3.98175C9.5603 4.29818 10.1369 4.77062 10.6046 5.32261C11.6184 6.51903 12.0982 8.06386 12.223 9.06169C12.2572 9.3357 12.5071 9.53006 12.7811 9.49581C13.0551 9.46156 13.2495 9.21167 13.2153 8.93766C13.073 7.79937 12.5371 6.05636 11.3676 4.67612C10.8193 4.02914 10.1271 3.45659 9.26679 3.06973C9.25064 3.06246 9.23431 3.05613 9.21785 3.0507C9.13672 3.38017 8.99064 3.68408 8.79397 3.94807Z"
      fill="#4C5863"
    />
    <circle cx="2.3946" cy="2.39441" r="1.06122" fill="#808E9A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.28 2.68083C9.28704 2.60413 9.29065 2.52643 9.29065 2.44789C9.29065 2.29999 9.27787 2.15506 9.25337 2.01416H12.5334C12.6807 2.01416 12.8001 2.1634 12.8001 2.34749C12.8001 2.53159 12.6807 2.68083 12.5334 2.68083H9.28ZM4.27269 2.68083H2.40009C2.25281 2.68083 2.13342 2.53159 2.13342 2.34749C2.13342 2.1634 2.25281 2.01416 2.40009 2.01416H4.29932C4.27482 2.15506 4.26204 2.29999 4.26204 2.44789C4.26204 2.52643 4.26565 2.60413 4.27269 2.68083ZM5.06713 2.58089C5.06376 2.537 5.06204 2.49265 5.06204 2.44789C5.06204 2.29805 5.08127 2.15269 5.11739 2.01416H5.21104C5.13251 2.18945 5.08256 2.38034 5.06713 2.58089ZM8.48553 2.58131C8.47013 2.38061 8.42017 2.18958 8.34159 2.01416H8.4353C8.47142 2.15269 8.49064 2.29805 8.49064 2.44789C8.49064 2.49279 8.48892 2.53728 8.48553 2.58131Z"
      fill="#808E9A"
    />
    <circle cx="12.7278" cy="2.39441" r="1.06122" fill="#808E9A" />
    <path
      d="M8.80005 2.33319C8.80005 3.43776 7.90462 4.33319 6.80005 4.33319C5.69548 4.33319 4.80005 3.43776 4.80005 2.33319C4.80005 1.22862 5.69548 0.333191 6.80005 0.333191C7.90462 0.333191 8.80005 1.22862 8.80005 2.33319Z"
      fill="#808E9A"
    />
  </svg>
));

export const CurveCornerSelected: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.3349 3.05063C4.31837 3.05607 4.30197 3.06243 4.28575 3.06973C3.42548 3.45659 2.73323 4.02914 2.18499 4.67612C1.0154 6.05636 0.479573 7.79937 0.337288 8.93766C0.303036 9.21167 0.497399 9.46156 0.771409 9.49581C1.04542 9.53006 1.29531 9.3357 1.32957 9.06169C1.45429 8.06386 1.9341 6.51903 2.94792 5.32261C3.41566 4.77062 3.99225 4.29818 4.69589 3.98175C4.71793 3.97184 4.73889 3.96053 4.75873 3.94798C4.56208 3.68399 4.41602 3.38008 4.3349 3.05063ZM8.79397 3.94807C8.81377 3.96059 8.83467 3.97187 8.85665 3.98175C9.5603 4.29818 10.1369 4.77062 10.6046 5.32261C11.6184 6.51903 12.0982 8.06386 12.223 9.06169C12.2572 9.3357 12.5071 9.53006 12.7811 9.49581C13.0551 9.46156 13.2495 9.21167 13.2153 8.93766C13.073 7.79937 12.5371 6.05636 11.3676 4.67612C10.8193 4.02914 10.1271 3.45659 9.26679 3.06973C9.25064 3.06246 9.23431 3.05613 9.21785 3.0507C9.13672 3.38017 8.99064 3.68408 8.79397 3.94807Z"
      fill="#019D91"
    />
    <circle cx="2.3946" cy="2.39441" r="1.06122" fill="#00C1A2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.28 2.68083C9.28704 2.60413 9.29065 2.52643 9.29065 2.44789C9.29065 2.29999 9.27787 2.15506 9.25337 2.01416H12.5334C12.6807 2.01416 12.8001 2.1634 12.8001 2.34749C12.8001 2.53159 12.6807 2.68083 12.5334 2.68083H9.28ZM4.27269 2.68083H2.40009C2.25281 2.68083 2.13342 2.53159 2.13342 2.34749C2.13342 2.1634 2.25281 2.01416 2.40009 2.01416H4.29932C4.27482 2.15506 4.26204 2.29999 4.26204 2.44789C4.26204 2.52643 4.26565 2.60413 4.27269 2.68083ZM5.06713 2.58089C5.06376 2.537 5.06204 2.49265 5.06204 2.44789C5.06204 2.29805 5.08127 2.15269 5.11739 2.01416H5.21104C5.13251 2.18945 5.08256 2.38034 5.06713 2.58089ZM8.48553 2.58131C8.47013 2.38061 8.42017 2.18958 8.34159 2.01416H8.4353C8.47142 2.15269 8.49064 2.29805 8.49064 2.44789C8.49064 2.49279 8.48892 2.53728 8.48553 2.58131Z"
      fill="#00C1A2"
    />
    <circle cx="12.7278" cy="2.39441" r="1.06122" fill="#00C1A2" />
    <path
      d="M8.80005 2.33319C8.80005 3.43776 7.90462 4.33319 6.80005 4.33319C5.69548 4.33319 4.80005 3.43776 4.80005 2.33319C4.80005 1.22862 5.69548 0.333191 6.80005 0.333191C7.90462 0.333191 8.80005 1.22862 8.80005 2.33319Z"
      fill="#00C1A2"
    />
  </svg>
));

export const DisconnectedTangents: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28697 3.06918C4.28656 3.06936 4.28616 3.06955 4.28575 3.06973C3.42548 3.4566 2.73323 4.02914 2.18499 4.67613C1.0154 6.05636 0.479573 7.79937 0.337288 8.93766C0.303036 9.21167 0.497399 9.46156 0.771409 9.49581C1.04542 9.53007 1.29531 9.3357 1.32957 9.06169C1.45429 8.06386 1.9341 6.51903 2.94792 5.32261C3.39968 4.78948 3.95297 4.33056 4.62421 4.01473C4.45258 3.73134 4.33534 3.41133 4.28697 3.06918ZM8.92851 4.01481C9.59967 4.33064 10.1529 4.78953 10.6046 5.32261C11.6184 6.51903 12.0982 8.06386 12.223 9.06169C12.2572 9.3357 12.5071 9.53007 12.7811 9.49581C13.0551 9.46156 13.2495 9.21167 13.2153 8.93766C13.073 7.79937 12.5371 6.05636 11.3676 4.67613C10.8193 4.02914 10.1271 3.4566 9.26679 3.06973C9.26646 3.06958 9.26612 3.06943 9.26579 3.06928C9.21741 3.41143 9.10015 3.73143 8.92851 4.01481Z"
      fill="#4C5863"
    />
    <circle cx="2.19465" cy="2.72773" r="1.06122" fill="#808E9A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28337 2.39014H2.60605C2.52908 2.39014 2.46667 2.53938 2.46667 2.72347C2.46667 2.90756 2.52908 3.0568 2.60605 3.0568L4.28517 3.0568C4.274 2.94477 4.26823 2.83039 4.26823 2.71417C4.26823 2.60437 4.27338 2.49622 4.28337 2.39014Z"
      fill="#808E9A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1109 3.6495L12.8473 5.19177C12.9471 5.23297 13.0849 5.12841 13.1552 4.95824C13.2254 4.78807 13.2015 4.61673 13.1017 4.57554L9.27501 2.99601C9.24953 3.22449 9.19342 3.44372 9.1109 3.6495ZM8.49013 2.67204L6.23513 1.74124C6.13533 1.70005 5.99749 1.8046 5.92725 1.97477C5.85701 2.14494 5.88097 2.31628 5.98077 2.35748L8.37117 3.34416C8.44827 3.14914 8.49063 2.9366 8.49063 2.71416C8.49063 2.70008 8.49046 2.68604 8.49013 2.67204Z"
      fill="#808E9A"
    />
    <circle cx="12.9664" cy="4.90287" r="1.06122" fill="#808E9A" />
    <path
      d="M8.80005 2.33319C8.80005 3.43776 7.90462 4.33319 6.80005 4.33319C5.69548 4.33319 4.80005 3.43776 4.80005 2.33319C4.80005 1.22862 5.69548 0.333191 6.80005 0.333191C7.90462 0.333191 8.80005 1.22862 8.80005 2.33319Z"
      fill="#808E9A"
    />
  </svg>
));

export const DisconnectedTangentsSelected: React.FC<React.SVGProps<SVGSVGElement>> = React.memo((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28691 3.06918C4.2865 3.06936 4.2861 3.06955 4.28569 3.06973C3.42542 3.4566 2.73317 4.02914 2.18493 4.67613C1.01534 6.05636 0.479512 7.79937 0.337227 8.93766C0.302975 9.21167 0.497338 9.46156 0.771348 9.49581C1.04536 9.53007 1.29525 9.3357 1.3295 9.06169C1.45423 8.06386 1.93404 6.51903 2.94786 5.32261C3.39962 4.78948 3.95291 4.33056 4.62415 4.01473C4.45252 3.73134 4.33528 3.41133 4.28691 3.06918ZM8.92845 4.01481C9.59961 4.33064 10.1528 4.78953 10.6046 5.32261C11.6184 6.51903 12.0982 8.06386 12.2229 9.06169C12.2572 9.3357 12.5071 9.53007 12.7811 9.49581C13.0551 9.46156 13.2494 9.21167 13.2152 8.93766C13.0729 7.79937 12.5371 6.05636 11.3675 4.67613C10.8193 4.02914 10.127 3.4566 9.26673 3.06973C9.2664 3.06958 9.26606 3.06943 9.26573 3.06928C9.21735 3.41143 9.10009 3.73143 8.92845 4.01481Z"
      fill="#019D91"
    />
    <circle cx="2.19458" cy="2.72773" r="1.06122" fill="#00C1A2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28337 2.39014H2.60605C2.52908 2.39014 2.46667 2.53938 2.46667 2.72347C2.46667 2.90756 2.52908 3.0568 2.60605 3.0568L4.28517 3.0568C4.274 2.94477 4.26823 2.83039 4.26823 2.71417C4.26823 2.60437 4.27338 2.49622 4.28337 2.39014Z"
      fill="#00C1A2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.11087 3.6495L12.8473 5.19177C12.9471 5.23297 13.0849 5.12841 13.1552 4.95824C13.2254 4.78807 13.2014 4.61673 13.1016 4.57554L9.27498 2.99601C9.2495 3.22449 9.19339 3.44372 9.11087 3.6495ZM8.4901 2.67204L6.2351 1.74124C6.1353 1.70005 5.99746 1.8046 5.92722 1.97477C5.85698 2.14494 5.88094 2.31628 5.98074 2.35748L8.37114 3.34416C8.44824 3.14914 8.4906 2.9366 8.4906 2.71416C8.4906 2.70008 8.49043 2.68604 8.4901 2.67204Z"
      fill="#00C1A2"
    />
    <circle cx="12.9663" cy="4.90287" r="1.06122" fill="#00C1A2" />
    <path
      d="M8.80002 2.33319C8.80002 3.43776 7.90459 4.33319 6.80002 4.33319C5.69545 4.33319 4.80002 3.43776 4.80002 2.33319C4.80002 1.22862 5.69545 0.333191 6.80002 0.333191C7.90459 0.333191 8.80002 1.22862 8.80002 2.33319Z"
      fill="#00C1A2"
    />
  </svg>
));
