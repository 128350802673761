/**
 * Copyright 2024 Design Barn Inc.
 */

import type { MutableRefObject } from 'react';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { LAYER_PANEL_DEFAULT_WIDTH, TIMELINE_BAR_BEGIN_OFFSET_PX, TIMELINE_SIDEBAR_WIDTH } from '../../constant';
import { useTimelineUtils } from '../hooks';
import { getSnappedFrame } from '../TimelineScrubber';

import { formatPlaybackTime, getVisibleKeyFrames } from '~/features/timeline';
import { useEventListener } from '~/hooks';
import { useCreatorStore } from '~/store';
import { GlobalCursorType } from '~/store/uiSlice';

interface Props {
  currentFps: number;
  frame: number;
  isLeftSlider: boolean;
  maxFrame: number;
  minFrame: number;
  onFrameChange: (frame: number) => void;
  onScrubbingEnd: () => void;
  totalFrames: number;
  width: number;
}

export const TimelineWorkAreaSlider: React.FC<Props> = ({
  currentFps,
  frame,
  isLeftSlider,
  maxFrame,
  minFrame,
  onFrameChange,
  onScrubbingEnd,
  totalFrames,
  width,
}) => {
  const scrubberHeadRef = useRef() as MutableRefObject<HTMLDivElement | null>;
  const [isHover, setIsHover] = useState(false);
  const [isSegmentHovered, expandedLayerIds, isWorkAreaScrubbing, setIsWorkAreaScrubbing] = useCreatorStore(
    (state) => [
      state.timeline.isSegmentHovered,
      state.timeline.expandedLayerIds,
      state.timeline.isWorkAreaScrubbing,
      state.timeline.setIsWorkAreaScrubbing,
    ],
    shallow,
  );

  const [isSliderOnTop, setIsSliderOnTop] = useState(false);

  const { getXPosFromFrame, timeline } = useTimelineUtils(width);

  const minPos = getXPosFromFrame(minFrame);
  const maxPos = getXPosFromFrame(maxFrame);

  useEffect(() => {
    if (scrubberHeadRef.current) {
      const pos = getXPosFromFrame(frame);

      scrubberHeadRef.current.style.transform = `translate(${pos}px, 0px)`;
    }
  }, [frame, width, totalFrames, getXPosFromFrame]);

  const handleHeaderDrag = (event: PointerEvent, visibleKeyFrames: number[]): void => {
    let xPos = event.clientX - (TIMELINE_SIDEBAR_WIDTH + LAYER_PANEL_DEFAULT_WIDTH + TIMELINE_BAR_BEGIN_OFFSET_PX);

    if (xPos < minPos) xPos = minPos;
    if (xPos > maxPos) xPos = maxPos;

    // Width ratio can't be exceed 1
    const widthRatio = Math.min(1, Math.abs(xPos / (width - 2 * TIMELINE_BAR_BEGIN_OFFSET_PX)));

    // Compute Current Frame
    let newFrame = Math.round(widthRatio * totalFrames);

    if (event.shiftKey) {
      // Holding down the shift key while dragging the scrubber will make it snap
      // to **visible** keyframes only. Visible as in visible from the timeline.
      // Keyframes that belong to unexpanded layers will not be considered.
      newFrame = getSnappedFrame(newFrame, totalFrames, visibleKeyFrames);
    }

    onFrameChange(newFrame);
  };

  const closeHeaderDrag = (): void => {
    document.onpointerup = null;
    document.onpointermove = null;
    setIsWorkAreaScrubbing(false);
    onScrubbingEnd();
  };

  const shadowBackground = isLeftSlider
    ? 'linear-gradient(270deg, rgba(32, 39, 44, 0.40) 0%, rgba(32, 39, 44, 0.00) 87.78%)'
    : 'linear-gradient(270deg, rgba(32, 39, 44, 0.00) 0%, rgba(32, 39, 44, 0.40) 87.78%)';

  const shadowClass = isLeftSlider
    ? 'absolute left-[-30px] h-[1000px] w-[45px] pointer-events-none'
    : 'absolute left-[17px] h-[1000px] w-[45px] pointer-events-none';

  const arrowClass = isLeftSlider ? 'absolute top-[-11px] left-[9px]' : 'absolute top-[-11px] left-[10px] rotate-180';

  const isActive = isHover || isWorkAreaScrubbing || isSegmentHovered;

  const formatDuration = formatPlaybackTime(frame / currentFps, currentFps);

  const time = `${formatDuration.seconds}s ${formatDuration.frames}f`;

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey) {
        setIsSliderOnTop(true);
      }
    },
    [setIsSliderOnTop],
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (!event.altKey) {
        setIsSliderOnTop(false);
      }
    },
    [setIsSliderOnTop],
  );

  useEventListener('keydown', handleKeyDown);
  useEventListener('keyup', handleKeyUp);

  return (
    <div
      ref={scrubberHeadRef}
      onPointerDown={() => {
        setIsWorkAreaScrubbing(true);
        const visibleKeyFrames = getVisibleKeyFrames(timeline, expandedLayerIds);

        document.onpointermove = (event) => handleHeaderDrag(event, visibleKeyFrames);
        document.onpointerup = closeHeaderDrag;
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={`absolute top-[-8px] ${isSliderOnTop ? 'z-scrubber-workarea' : 'z-scrubber'} mt-[24px] h-[17.36px] w-7 rounded ${GlobalCursorType.RESIZE}`}
    >
      <div
        className={shadowClass}
        style={{
          background: shadowBackground,
        }}
      ></div>
      {isActive && (
        <div className="absolute left-[-2px] top-[-31px] flex h-4 w-[38px] justify-center rounded-[9999px] bg-[#fff] px-[4px] py-[2px] align-middle text-[8px] text-[#000]">
          {time}
        </div>
      )}
      <svg
        className="absolute left-[11px] top-[-11px] h-[14px] w-[10px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 14"
        fill="none"
      >
        <path
          d="M0.5 1C0.5 0.723858 0.723857 0.5 1 0.5H9C9.27614 0.5 9.5 0.723858 9.5 1V10.4338C9.5 10.6094 9.40785 10.7722 9.25725 10.8626L5.25725 13.2626C5.09891 13.3576 4.90109 13.3576 4.74275 13.2626L0.742752 10.8626C0.59215 10.7722 0.5 10.6094 0.5 10.4338V1Z"
          fill={isActive ? 'white' : '#4C5863'}
        />
      </svg>
      <svg
        className={`${arrowClass} h-[12px] w-[13px]`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.73483 8.76517C5.58839 8.61872 5.58839 8.38128 5.73483 8.23484L7.96967 6L5.73484 3.76517C5.58839 3.61872 5.58839 3.38128 5.73484 3.23484C5.88128 3.08839 6.11872 3.08839 6.26517 3.23484L8.76516 5.73484C8.91161 5.88128 8.91161 6.11872 8.76516 6.26517L6.26516 8.76517C6.11872 8.91161 5.88128 8.91161 5.73483 8.76517Z"
          fill={isActive ? '#4C5863' : 'white'}
        />
      </svg>
      <div className="absolute left-[8px] h-[1000px] w-4 opacity-0"></div>
      <div className={`absolute left-[15px] h-[1000px] w-0.5 ${isActive ? 'bg-[#ffffff]' : 'bg-[#4C5863]'}`}></div>
    </div>
  );
};
