/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { AUTH_AJAX_ENDPOINT } from '~/config';
import { EventType, SessionSource } from '~/data/eventStore';
import { useCreatorStore } from '~/store';
import { fireEvent } from '~/utils';

export const NotAuthorizedWarning: React.FC = () => {
  const [isAuthorized, isUserFetched, isUIMinimized] = useCreatorStore(
    (state) => [state.user.isAuthorized, state.user.isUserFetched, state.ui.isUIMinimized],
    shallow,
  );

  const onLoginClickHandler = useCallback(() => {
    fireEvent({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.StartSession,
      parameters: {
        source: SessionSource.Creator,
      },
    });

    window.location.href = `${AUTH_AJAX_ENDPOINT}/login?return_to=${window.location.href}`;
  }, []);

  if (isAuthorized || !isUserFetched) {
    return null;
  }

  return (
    <div
      className={`fixed left-1/2 top-[56px] z-[2] flex -translate-x-1/2 items-center gap-4 rounded-lg bg-[#333C45] p-3 text-[14px] text-[#ffffff] ${isUIMinimized ? 'hidden' : ''}`}
    >
      <span>
        <span className="font-bold">You’re in guest mode.</span> Changes you make won’t be saved.
      </span>
      <button className="h-[32px] w-[112px] rounded-lg bg-[#00C1A2] font-bold text-white" onClick={onLoginClickHandler}>
        Log in to save
      </button>
    </div>
  );
};
