/**
 * Copyright 2024 Design Barn Inc.
 */

import { clamp } from 'lodash-es';

import type { RGBA } from '../components/ColorPicker';

import hsvToRgb from './hsvToRgb';
import rgbToHSv from './rgbToHsv';

export const getLightness = (red: number, green: number, blue: number): number => {
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);

  const lightness = (max + min) / 2;

  return lightness / 255;
};

export const getLighterOrDarkerColor = (red: number, green: number, blue: number, percentage = 20): RGBA => {
  // Convert RGB to HSL
  const { hue, saturation, value } = rgbToHSv({ red, green, blue });

  // Decrease lightness by the given percentage
  const newValue = Math.max(0, value - (value * percentage) / 100);

  // Convert back to RGB
  const rgb = hsvToRgb(hue, saturation, newValue);

  // Clamp the colors
  return {
    red: clamp(rgb.red, 0, 255),
    green: clamp(rgb.green, 0, 255),
    blue: clamp(rgb.blue, 0, 255),
    alpha: rgb.alpha ?? 1,
  };
};
