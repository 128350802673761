/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';

import { ThumbnailPath } from '~/assets/icons';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';

export const PathProperty: React.FC = () => {
  const setPropertyPanel = useCreatorStore((state) => state.ui.setPropertyPanel);
  const setSelectedKeyframes = useCreatorStore((state) => state.timeline.setSelectedKeyframeIds);
  const onEditClick = useCallback(() => {
    setPropertyPanel(PropertyPanelType.EditPath);
    setSelectedKeyframes([]);
  }, [setPropertyPanel, setSelectedKeyframes]);

  return (
    <>
      <div
        id="edit-path-btn"
        onClick={onEditClick}
        className="flex cursor-pointer justify-center rounded border border-gray-600 py-1 hover:border-gray-400"
      >
        <ThumbnailPath className="h-5 w-5 " />

        <div className="py-[2px] text-xs">Edit Path</div>
      </div>
    </>
  );
};
