/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer, DagNode } from '@lottiefiles/toolkit-js';
import { StrokeShape, GroupShape, FillShape } from '@lottiefiles/toolkit-js';
import type { WritableDraft } from 'immer/dist/internal';
import type { StoreApi } from 'zustand';

import { PropertyPanelType } from './constant';

import type { StoreSlice } from '.';
import {
  findShape,
  defaultCurrentTransformProperty,
  NodeType,
  getCurrentTransformNode,
  getFillShape,
  getStrokeShape,
  getGradientFillShape,
} from '~/lib/toolkit';

export const getCurrentNode = (get: StoreApi<StoreSlice>['getState']): DagNode | null => {
  const selectedNodeId = get().ui.selectedNodesInfo[0]?.nodeId as string;

  return get().toolkit.getNodeByIdOnly(selectedNodeId);
};

export const getCurrentShapeNode = (get: StoreApi<StoreSlice>['getState']): DagNode | null => {
  const node = getCurrentNode(get);

  if (node instanceof GroupShape) {
    return findShape(node.shapes) || null;
  }
  if (node instanceof FillShape || node instanceof StrokeShape) {
    return findShape((node.parent as GroupShape).shapes) || null;
  }

  return node;
};

export const clearSelectionState = (draft: WritableDraft<StoreSlice>): void => {
  draft.toolkit.currentTransform = { ...defaultCurrentTransformProperty };
  draft.ui.currentPropertyPanel = PropertyPanelType.Composition;
  draft.ui.selectedNodesInfo = [];
};

export const getAnimatedNode = (
  nodeType: NodeType,
  get: StoreApi<StoreSlice>['getState'],
): AVLayer | FillShape | null => {
  let node = null;

  switch (nodeType) {
    case NodeType.TRANSFORM:
      node = getCurrentTransformNode(getCurrentNode(get)) as AVLayer;
      break;

    case NodeType.FILL:
      node = getFillShape(getCurrentNode(get)) as unknown as FillShape;
      break;

    case NodeType.STROKE:
      node = getStrokeShape(getCurrentNode(get)) as unknown as FillShape;
      break;

    case NodeType.GRADIENT_FILL:
      node = getGradientFillShape(getCurrentNode(get)) as unknown as FillShape;
      break;

    default:
  }

  return node;
};
