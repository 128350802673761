/**
 * Copyright 2024 Design Barn Inc.
 */

import React from 'react';

import type { ColorMode } from './ColorPicker';

interface ColorTypeTabProps {
  colorMode: ColorMode;
  isSelected: boolean;
  label: string;
  onColorModeChange: (colorMode: ColorMode) => void;
}

export const ColorTypeTab: React.FC<ColorTypeTabProps> = ({ colorMode, isSelected, label, onColorModeChange }) => {
  return (
    <div
      onClick={() => onColorModeChange(colorMode)}
      className={`cursor-pointer rounded-lg px-2 py-1 text-xs font-bold ${isSelected ? 'text-white' : 'text-gray-400'}`}
    >
      {label}
    </div>
  );
};
