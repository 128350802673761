/**
 * Copyright 2024 Design Barn Inc.
 */

export const isValidHex = (colorValue: string): boolean => {
  const colorReg = /^([\da-f]{3}){1,2}$/iu;

  return colorReg.test(colorValue.replace('#', ''));
};

export default isValidHex;
