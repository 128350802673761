/**
 * Copyright 2023 Design Barn Inc.
 */

import type { FC } from 'react';
import React from 'react';
import { shallow } from 'zustand/shallow';

import { SparklesIcon } from '~/assets/icons';
import { EventType, OnboardingPopoverAction, OnboardingPopoverSource } from '~/data/eventStore';
import { PopoverTooltip } from '~/features/onboarding';
import { useCreatorStore } from '~/store';
import { fireEvent } from '~/utils';

const ContainerStyle = {
  width: '100%',
};

const IframeStyle = {
  width: '100%',
  height: '170px',
  border: 'none',
};

const closeOnboardingFlowNudge = (): void => {
  useCreatorStore.getState().ui.setShowOnboardingNudge(false);
};

const openOnboardingFlow = (): void => {
  closeOnboardingFlowNudge();
  useCreatorStore.getState().ui.setShowOnboarding(true);

  fireEvent({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_type: EventType.OnboardingPopoverAction,
    parameters: {
      action: OnboardingPopoverAction.Start,
      source: OnboardingPopoverSource.Sidebar,
    },
  });
};

const NudgeContent: FC = () => (
  <p className="w-[212px] text-xs leading-normal text-white">
    You can check out these tips anytime in &apos;Quick tutorials&apos;. Have fun with Lottie Creator!
  </p>
);

export const CreatorTutorialFramer = (): JSX.Element => {
  const [showOnboardingNudge, isUIMinimized] = useCreatorStore(
    (state) => [state.ui.onboarding.showOnboardingNudge, state.ui.isUIMinimized],
    shallow,
  );

  return (
    <div style={ContainerStyle}>
      <iframe title="WhatsNew" style={IframeStyle} src="https://releases.lottiefiles.com/creator-tutorial" />
      <PopoverTooltip
        onClose={closeOnboardingFlowNudge}
        open={showOnboardingNudge && !isUIMinimized}
        content={<NudgeContent />}
      >
        {({ ref }) => (
          <button
            onClick={openOnboardingFlow}
            className="mb-6 flex h-[21px] w-full items-center justify-center gap-2 rounded bg-[#333C45] text-white"
            ref={ref}
          >
            <span>
              <SparklesIcon width={16} height={16} />
            </span>
            <span className="text-[10px] font-bold">View handy tips to start creating</span>
          </button>
        )}
      </PopoverTooltip>
    </div>
  );
};

export default CreatorTutorialFramer;
