/**
 * Copyright 2024 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/lottie-js';
import type { ColorStopJSON, GradientFillShape } from '@lottiefiles/toolkit-js';
import { GradientFillType, Angle, Scalar } from '@lottiefiles/toolkit-js';
import { clamp, round } from 'lodash-es';
import type { Matrix4 } from 'three';
import { MathUtils, Vector3, Raycaster, Vector2 } from 'three';

import { Box3 } from '../Box3';
import { Box3Helper } from '../Box3Helper';

import addNewStopCursor from './cursors/addNewStopCursor.svg';
import { emitGradientFillUpdatedThrottled, RENDER_ORDERS } from './helpers';
import { createColorStopGizmos, updateColorStopPositions } from './helpers/color-stop';
import {
  getGradientStartAndEnd,
  createGradientLine,
  updateGradientLine,
  getUnprojectedGradientLine,
  GRADIENT_LINE_WIDTH,
  GRADIENT_LINE_DROP_SHADOW_1_WIDTH,
  GRADIENT_LINE_DROP_SHADOW_2_WIDTH,
} from './helpers/gradient-line';
import { createHighlightPoint, updateHighlightPosition } from './helpers/highlight-point';
import { createHoverCircle } from './helpers/hover-circle';
import {
  createGradientLineHandles,
  getOffsettedLineStartAndEnd,
  GRADIENT_HANDLE_OFFSET,
  rotateGradientLineHandles,
} from './helpers/start-end-handles';

import { ToolType } from '~/data/constant';
import type { Viewport } from '~/features/canvas';
import { BLUE_COLOR, getCanvasToScreenCoord, clearThree, CObject3D, getMouseCoord, unProject } from '~/features/canvas';
// eslint-disable-next-line no-restricted-imports
import { getCursorStyle } from '~/features/canvas/styleMapper';
import { canvasMap } from '~/lib/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { CurrentGFillShape } from '~/lib/toolkit';
import {
  getNewColorFromOffset,
  GradientPoint,
  setGradientPoint,
  addGradientColor,
  updateGradientColorOffset,
  stateHistory,
} from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { GlobalCursorUpdate } from '~/store/uiSlice';

const zoomPercentage = useCreatorStore.getState().ui.zoomPercentage;
const getShape = useCreatorStore.getState().toolkit.getShape;
const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

const RAYCASTER_LINE_THRESHOLD = 8;

export enum Elements {
  COLOR_STOP = 'COLOR_STOP',
  END_POINT = 'END_POINT',
  GRADIENT_LINE = 'GRADIENT_LINE',
  GRADIENT_LINE_DROP_SHADOW_1 = 'GRADIENT_LINE_DROP_SHADOW_1',
  GRADIENT_LINE_DROP_SHADOW_2 = 'GRADIENT_LINE_DROP_SHADOW_2',
  HIGHLIGHT_POINT = 'HIGHLIGHT_POINT',
  HOVER_CIRCLE = 'HOVER_CIRCLE',
  START_POINT = 'START_POINT',
}

export interface GradientFillInfo extends CurrentGFillShape {
  colorStopGizmos: CObject3D[];
  gradientLine: CObject3D;
  highlightPoint: CObject3D | null;
  hoverCircle: CObject3D;
  projectedGeX: number;
  projectedGeY: number;
  projectedGsX: number;
  projectedGsY: number;
  startAndEndHandles: CObject3D[];
  transformationMatrices: Matrix4[];
}

const raycaster = new Raycaster();

raycaster.params.Line.threshold = RAYCASTER_LINE_THRESHOLD;

// TODO: (May 2024) - initial implementation supporting a single gradient fill.
// In the future we will support multiple gradient fills.
export class GradientControls {
  public activeColorStopIndex: number | null = null;

  public activeColorStopUuid: string | null = null;

  public activeGradientId: string | null = null;

  public allObjects: CObject3D = new CObject3D();

  public currentColorOffset: number = 0;

  public currentDraggableElement: CObject3D | null = null;

  public currentMousePosition: Vector3 = new Vector3();

  public currentZoom: number = 100 / zoomPercentage;

  public draggableElements: CObject3D[] = [];

  public draggingElementType:
    | Elements.COLOR_STOP
    | Elements.END_POINT
    | Elements.HIGHLIGHT_POINT
    | Elements.START_POINT
    | null = null;

  public enabled: boolean = false;

  public gradientShapes: GradientFillInfo[] = [];

  public initialMousePosition: Vector3 = new Vector3();

  public isDragging: boolean = false;

  public selectionBox: Box3Helper = new Box3Helper(new Box3(), BLUE_COLOR);

  public tooltip: HTMLElement;

  public tooltipRafId: number | null = null;

  public type: GradientFillType = GradientFillType.LINEAR;

  public viewport: Viewport;

  private readonly _domElement: HTMLCanvasElement = document as unknown as HTMLCanvasElement;

  public constructor(domElement: HTMLCanvasElement, viewport: Viewport) {
    this._domElement = domElement;
    this.viewport = viewport;

    this.selectionBox.visible = false;
    this.selectionBox.renderOrder = RENDER_ORDERS.SELECTION_BOX;
    this.viewport.scene.add(this.selectionBox);

    this.tooltip = document.getElementById('gradient-controls-tooltip') as HTMLElement;
    this.tooltip.addEventListener('pointerup', this.handlePointerUp.bind(this), false);

    this.setupListeners();
  }

  public addColorStop(): void {
    this.gradientShapes.forEach((shape) => {
      const gradientFillShape = getNodeByIdOnly(shape.id as string) as GradientFillShape;
      const colorAtHoverPoint = getNewColorFromOffset(
        gradientFillShape.gradient.value.colorStops,
        this.currentColorOffset,
      );

      addGradientColor(gradientFillShape, colorAtHoverPoint, this.currentColorOffset);

      this.updateGradientShapesInfo();

      const newStop = this.gradientShapes[0]?.g.find(
        (stop) => stop.offset === this.currentColorOffset,
      ) as ColorStopJSON;
      const index = this.gradientShapes[0]?.g.findIndex((stop) => stop.offset === this.currentColorOffset) as number;

      const gizmo = createColorStopGizmos(
        [newStop],
        new Vector2(shape.projectedGsX, shape.projectedGsY),
        new Vector2(shape.projectedGeX, shape.projectedGeY),
        index,
      )[0] as CObject3D;

      // update the userData index for all the other gizmos
      shape.colorStopGizmos.forEach((colorGizmo, gizmoIndex) => {
        if (gizmoIndex >= index) {
          colorGizmo.userData['index'] = index + 1;
        }
      });

      this.viewport.scene.add(gizmo);
      shape.colorStopGizmos.push(gizmo);
      this.allObjects.add(gizmo);
      this.draggableElements.push(gizmo);

      this.setActiveColorStop(gizmo);
      this.currentDraggableElement = gizmo;
      this.isDragging = true;
      this.draggingElementType = Elements.COLOR_STOP;

      emitGradientFillUpdatedThrottled();
      this.updateCursor();
    });
  }

  public createGradientControls(gradientShapes: CurrentGFillShape[]): void {
    if (this.isEmptyShape(gradientShapes)) {
      this.enable(false);

      return;
    }

    this.gradientShapes = [];

    gradientShapes.forEach((shape) => {
      const { end, start, transformationMatrices } = getGradientStartAndEnd(shape);

      const gradientInfo: GradientFillInfo = {
        ...shape,
        projectedGeX: end.x,
        projectedGeY: end.y,
        projectedGsX: start.x,
        projectedGsY: start.y,
        gradientLine: createGradientLine(start, end),
        startAndEndHandles: createGradientLineHandles(start, end),
        hoverCircle: createHoverCircle(),
        colorStopGizmos: createColorStopGizmos(shape.g, start, end),
        transformationMatrices,
        highlightPoint: null,
      };

      this.gradientShapes.push(gradientInfo);
      this.draggableElements.push(...gradientInfo.colorStopGizmos, ...gradientInfo.startAndEndHandles);

      if (shape.type === GradientFillType.RADIAL) {
        gradientInfo.highlightPoint = createHighlightPoint(shape, start, end);
        this.draggableElements.push(gradientInfo.highlightPoint);
      }

      this.allObjects.add(gradientInfo.gradientLine, gradientInfo.hoverCircle, ...this.draggableElements);
    });

    this.viewport.scene.add(this.allObjects);
    // scale the objects according to the current zoom
    this.onCameraZoomChange(useCreatorStore.getState().ui.zoomPercentage, 1);
  }

  public enable(enabled: boolean, gradientShapes?: CurrentGFillShape[]): void {
    this.enabled = enabled;

    if (enabled && gradientShapes) {
      const selectedLayer = canvasMap.get(gradientShapes[0]?.id as string) as CObject3D;

      this.selectionBox.box.setFromObject(selectedLayer, true);
      this.selectionBox.update();
      this.selectionBox.visible = true;

      this.viewport.transformControls.showTransformControls(false);
      this.viewport.transformControls.visible = false;
      this.viewport.transformControls.enabled = false;

      useCreatorStore.getState().ui.setCurrentTool(ToolType.Move);
      this.createGradientControls(gradientShapes);

      this._domElement.addEventListener('pointerdown', this.handlePointerDown.bind(this), false);
      this._domElement.addEventListener('pointermove', this.handlePointerMove.bind(this), false);
      this._domElement.addEventListener('pointerup', this.handlePointerUp.bind(this), false);
    }

    if (!enabled) {
      this.gradientShapes = [];

      this.selectionBox.visible = false;
      this.viewport.transformControls.visible = true;
      this.viewport.transformControls.enabled = true;

      addToSelectedNodes(useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId));

      this._domElement.removeEventListener('pointerdown', this.handlePointerDown);
      this._domElement.removeEventListener('pointermove', this.handlePointerMove);
      this._domElement.removeEventListener('pointerup', this.handlePointerUp);

      this.showToolTip(null);
      this.removeGradientControls();
    }
  }

  public getIntersects(event: MouseEvent, objectsToIntersect: CObject3D | CObject3D[]): CObject3D[] {
    const mouseCoords = getMouseCoord(event, this._domElement);

    raycaster.setFromCamera(mouseCoords, this.viewport.camera);

    const intersects = Array.isArray(objectsToIntersect)
      ? raycaster.intersectObjects(objectsToIntersect, true)
      : raycaster.intersectObject(objectsToIntersect, true);

    return intersects.map((intersect) => (intersect.object.parent ?? intersect.object) as CObject3D);
  }

  public getMousePosition(event: MouseEvent): Vector3 {
    const mouseCoord = getMouseCoord(event, this._domElement);

    return unProject(mouseCoord, this.viewport.camera);
  }

  public getMousePositionOnLine(shape: GradientFillInfo, keepWithinLineLength: boolean = true): Vector3 {
    const lineStart = new Vector3(shape.projectedGsX, shape.projectedGsY, 0);
    const lineEnd = new Vector3(shape.projectedGeX, shape.projectedGeY, 0);

    const lineDirection = lineEnd.clone().sub(lineStart).normalize();
    const lineStartToMouse = this.currentMousePosition.clone().sub(lineStart);
    const projectionLength = lineStartToMouse.dot(lineDirection);
    const projection = lineDirection.clone().multiplyScalar(projectionLength).add(lineStart);

    if (keepWithinLineLength) {
      const lineLength = lineStart.distanceTo(lineEnd);

      if (projectionLength > lineLength) {
        projection.copy(lineEnd);
      } else if (projectionLength < 0) {
        projection.copy(lineStart);
      }
    }

    return projection;
  }

  public getNewStartAndEndPositions(
    shape: GradientFillInfo,
    startHandle: CObject3D,
    endHandle: CObject3D,
    lineOffset: Vector2,
  ): {
    direction: Vector2;
    newEndHandlePosition: Vector2;
    newGradientEnd: Vector2;
    newGradientStart: Vector2;
    newStartHandlePosition: Vector2;
  } {
    const oldGradientStart = new Vector2(shape.projectedGsX, shape.projectedGsY);
    const oldGradientEnd = new Vector2(shape.projectedGeX, shape.projectedGeY);

    const currentMousePosition = new Vector2(this.currentMousePosition.x, this.currentMousePosition.y);
    const direction = oldGradientEnd.clone().sub(oldGradientStart).normalize();

    let newGradientStart = new Vector2(oldGradientStart.x, oldGradientStart.y);
    let newGradientEnd = new Vector2(oldGradientEnd.x, oldGradientEnd.y);
    let newStartHandlePosition = new Vector2(startHandle.position.x, startHandle.position.y);
    let newEndHandlePosition = new Vector2(endHandle.position.x, endHandle.position.y);

    if (this.draggingElementType === Elements.START_POINT) {
      newGradientStart = currentMousePosition.clone().add(lineOffset.clone().multiply(direction));
      newStartHandlePosition = new Vector2(this.currentMousePosition.x, this.currentMousePosition.y);
      newEndHandlePosition = oldGradientEnd.clone().add(lineOffset.clone().multiply(direction));
    }

    if (this.draggingElementType === Elements.END_POINT) {
      newGradientEnd = currentMousePosition.clone().sub(lineOffset.clone().multiply(direction));
      newStartHandlePosition = oldGradientStart.clone().sub(lineOffset.clone().multiply(direction));
      newEndHandlePosition = new Vector2(this.currentMousePosition.x, this.currentMousePosition.y);
    }

    return {
      newGradientStart,
      newGradientEnd,
      newStartHandlePosition,
      newEndHandlePosition,
      direction,
    };
  }

  public getOffsetAtHoverCircle(): number {
    const shape = this.gradientShapes[0] as GradientFillInfo;

    const lineLength = new Vector2(shape.projectedGeX, shape.projectedGeY).distanceTo(
      new Vector2(shape.projectedGsX, shape.projectedGsY),
    );
    const startToHoverPoint = shape.hoverCircle.position.distanceTo(
      new Vector3(shape.projectedGsX, shape.projectedGsY, -0),
    ) as number;

    return round(startToHoverPoint / lineLength, 3);
  }

  public handleIsDragging(): void {
    if (!this.isDragging || !this.currentDraggableElement || !this.draggingElementType) return;

    this.showToolTip(this.draggingElementType);
    this.updateCursor(this.draggingElementType);

    if (this.draggingElementType === Elements.COLOR_STOP) {
      this.moveColorStop();
    }

    if (this.draggingElementType === Elements.START_POINT || this.draggingElementType === Elements.END_POINT) {
      this.moveStartOrEndPoint();
    }

    if (this.draggingElementType === Elements.HIGHLIGHT_POINT) {
      this.moveHighlightPoint();
    }

    this.showToolTip(this.draggingElementType);
  }

  public handleIsHovering(nearestElement: Elements): void {
    this.showToolTip(nearestElement);

    const shape = this.gradientShapes[0] as GradientFillInfo;

    if (nearestElement === Elements.GRADIENT_LINE || nearestElement === Elements.HOVER_CIRCLE) {
      shape.hoverCircle.visible = true;
      const projection = this.getMousePositionOnLine(shape);

      shape.hoverCircle.position.set(projection.x, projection.y, 0);
      emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });

      return;
    }

    if (shape.hoverCircle.visible) {
      shape.hoverCircle.visible = false;
      emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
    }
  }

  public handlePointerDown(event: MouseEvent): void {
    if (!this.enabled || event.button === 2) {
      return;
    }

    stateHistory.beginAction();

    this.initialMousePosition = this.getMousePosition(event);

    if ((this.gradientShapes[0] as GradientFillInfo).hoverCircle.visible) {
      (this.gradientShapes[0] as GradientFillInfo).hoverCircle.visible = false;

      this.addColorStop();
    }

    const gizmoIntersects = this.getIntersects(event, this.draggableElements);

    if (gizmoIntersects.length > 0) {
      this.isDragging = true;

      const hasHighlightPoint = gizmoIntersects.find(
        (intersect) => intersect.userData['type'] === Elements.HIGHLIGHT_POINT,
      );

      if (hasHighlightPoint) {
        this.handleIsHovering(hasHighlightPoint.userData['type']);
        this.currentDraggableElement = hasHighlightPoint;
        this.draggingElementType = hasHighlightPoint.userData['type'];

        return;
      }

      this.currentDraggableElement = gizmoIntersects[0] as CObject3D;
      this.draggingElementType = this.currentDraggableElement.userData['type'];

      if (this.draggingElementType === Elements.COLOR_STOP) {
        this.setActiveColorStop(this.currentDraggableElement);
      } else {
        this.setActiveColorStop(null);
      }
    }
  }

  public handlePointerMove(event: MouseEvent): void {
    if (!this.enabled) {
      return;
    }

    this.currentMousePosition = this.getMousePosition(event);
    this.currentColorOffset = this.getOffsetAtHoverCircle();

    if (this.isDragging) {
      this.handleIsDragging();

      return;
    }

    const intersects = this.getIntersects(event, this.allObjects);

    if (intersects.length === 0) {
      this.hideTooltip();
      const shape = this.gradientShapes[0] as GradientFillInfo;

      if (shape.hoverCircle.visible) {
        shape.hoverCircle.visible = false;
        emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
      }
    }

    if (intersects.length > 0) {
      const hasHighlightPoint = intersects.find((intersect) => intersect.userData['type'] === Elements.HIGHLIGHT_POINT);

      if (hasHighlightPoint) {
        this.handleIsHovering(hasHighlightPoint.userData['type']);
        this.updateCursor(Elements.HIGHLIGHT_POINT);

        return;
      }

      this.handleIsHovering(intersects[0]?.userData['type']);
    }

    this.updateCursor(intersects[0]?.userData['type']);
  }

  public handlePointerUp(): void {
    stateHistory.endAction();
    this.currentDraggableElement = null;
    this.isDragging = false;
  }

  public hideTooltip(): void {
    this.tooltip.style.display = 'none';
  }

  public isEmptyShape(gradientShapes: CurrentGFillShape[]): boolean {
    return gradientShapes.every((shape) => shape.geX === shape.gsX && shape.geY === shape.gsY && shape.g.length === 0);
  }

  public moveColorStop(): void {
    if (!this.currentDraggableElement) return;

    this.gradientShapes.forEach((shape) => {
      const lineStart = new Vector3(shape.projectedGsX, shape.projectedGsY, 0);
      const lineEnd = new Vector3(shape.projectedGeX, shape.projectedGeY, 0);
      const lineLength = lineStart.distanceTo(lineEnd);

      const projection = this.getMousePositionOnLine(shape);

      this.currentDraggableElement?.position.set(projection.x, projection.y, 0);
      this.currentColorOffset = projection.distanceTo(lineStart) / lineLength;

      const currentIndex = this.currentDraggableElement?.userData['index'];

      updateGradientColorOffset(
        getNodeByIdOnly(this.gradientShapes[0]?.id as string) as GradientFillShape,
        currentIndex,
        this.currentColorOffset,
      );

      shape.colorStopGizmos.sort((first, second) => {
        const posA = new Vector3(first.position.x, first.position.y, 0);
        const posB = new Vector3(second.position.x, second.position.y, 0);

        return posA.distanceTo(lineStart) - posB.distanceTo(lineStart);
      });

      shape.colorStopGizmos.forEach((gizmo, index) => {
        gizmo.userData['index'] = index;
      });
    });

    this.setActiveColorStop(this.currentDraggableElement);
    this.updateGradientShapesInfo();
    emitGradientFillUpdatedThrottled();
  }

  public moveHighlightPoint(): void {
    const shape = this.gradientShapes[0] as GradientFillInfo;
    const gradientFillShape = getNodeByIdOnly(shape.id as string) as GradientFillShape;

    const startPoint = new Vector3(shape.projectedGsX, shape.projectedGsY, 0);
    const endPoint = new Vector3(shape.projectedGeX, shape.projectedGeY, 0);
    const mousePosition = new Vector3(this.currentMousePosition.x, this.currentMousePosition.y, 0);

    const lineDirection = endPoint.clone().sub(startPoint);
    const lineLength = lineDirection.length();

    const highlightDirection = mousePosition.clone().sub(startPoint);
    const highlightLength = clamp((highlightDirection.length() / lineLength) * 100, -100, 100);
    const highlightAngle = MathUtils.radToDeg(
      Math.atan2(highlightDirection.y, highlightDirection.x) - Math.atan2(lineDirection.y, lineDirection.x),
    );

    gradientFillShape.setHighlightAngle(new Angle(highlightAngle));
    gradientFillShape.setHighlightLength(new Scalar(highlightLength));

    updateHighlightPosition(
      shape.highlightPoint as CObject3D,
      new Vector2(startPoint.x, startPoint.y),
      new Vector2(endPoint.x, endPoint.y),
      highlightAngle,
      highlightLength,
    );

    this.updateGradientShapesInfo();
    emitGradientFillUpdatedThrottled();
  }

  public moveStartOrEndPoint(): void {
    const lineOffsetFromHandle = new Vector2(
      GRADIENT_HANDLE_OFFSET * this.currentZoom,
      GRADIENT_HANDLE_OFFSET * this.currentZoom,
    );

    this.gradientShapes.forEach((shape) => {
      const gradientFillShape = getNodeByIdOnly(this.gradientShapes[0]?.id as string) as GradientFillShape;
      const startHandle = shape.startAndEndHandles[0] as CObject3D;
      const endHandle = shape.startAndEndHandles[1] as CObject3D;

      const { direction, newEndHandlePosition, newGradientEnd, newGradientStart, newStartHandlePosition } =
        this.getNewStartAndEndPositions(shape, startHandle, endHandle, lineOffsetFromHandle);

      // update line handle positions
      startHandle.position.set(newStartHandlePosition.x, newStartHandlePosition.y, 0);
      endHandle.position.set(newEndHandlePosition.x, newEndHandlePosition.y, 0);
      rotateGradientLineHandles(shape.startAndEndHandles, direction);

      // update gradient line
      updateGradientLine(this.gradientShapes[0]?.gradientLine as CObject3D, newGradientStart, newGradientEnd);

      // update color stop positions
      shape.colorStopGizmos.forEach((colorStop, index) =>
        updateColorStopPositions(colorStop, newGradientStart, newGradientEnd, (shape.g[index] as ColorStopJSON).offset),
      );

      // update highlight point
      if (shape.type === GradientFillType.RADIAL && shape.highlightPoint) {
        updateHighlightPosition(shape.highlightPoint, newGradientStart, newGradientEnd, shape.ha, shape.hl);
      }

      // apply point position change
      const { end, start } = getUnprojectedGradientLine(newGradientStart, newGradientEnd, shape.transformationMatrices);

      setGradientPoint(gradientFillShape, GradientPoint.StartX, start.x);
      setGradientPoint(gradientFillShape, GradientPoint.StartY, start.y);
      setGradientPoint(gradientFillShape, GradientPoint.EndX, end.x);
      setGradientPoint(gradientFillShape, GradientPoint.EndY, end.y);
    });

    this.updateGradientShapesInfo();

    emitGradientFillUpdatedThrottled();
  }

  public onCameraZoomChange(zoom: number, overridenPreviousZoom?: number): void {
    const previousZoom = overridenPreviousZoom ?? this.currentZoom;

    this.currentZoom = 100 / zoom;

    // avoid overriding the handle intersects when zooming in
    raycaster.params.Line.threshold = RAYCASTER_LINE_THRESHOLD * this.currentZoom;

    this.allObjects.children.forEach((object) => {
      if (object.userData['type'] === Elements.COLOR_STOP) {
        if (object.uuid === this.activeColorStopUuid) {
          object.scale.set(1.6 * this.currentZoom, 1.6 * this.currentZoom, 1);
        } else {
          object.scale.set(Number(this.currentZoom), Number(this.currentZoom), 1);
        }
      }

      if (object.userData['type'] === Elements.GRADIENT_LINE) {
        object.children.forEach((child) => {
          if (child.userData['type'] === Elements.GRADIENT_LINE) {
            child.material.lineWidth = GRADIENT_LINE_WIDTH * this.currentZoom;
          } else if (child.userData['type'] === Elements.GRADIENT_LINE_DROP_SHADOW_1) {
            child.material.lineWidth = GRADIENT_LINE_DROP_SHADOW_1_WIDTH * this.currentZoom;
          } else if (child.userData['type'] === Elements.GRADIENT_LINE_DROP_SHADOW_2) {
            child.material.lineWidth = GRADIENT_LINE_DROP_SHADOW_2_WIDTH * this.currentZoom;
          }
        });
      }

      if (object.userData['type'] === Elements.HOVER_CIRCLE || object.userData['type'] === Elements.HIGHLIGHT_POINT) {
        object.children.forEach((child) => {
          child.scale.set(
            (child.scale.x / previousZoom) * this.currentZoom,
            (child.scale.y / previousZoom) * this.currentZoom,
            1,
          );
        });
      }

      if (object.userData['type'] === Elements.START_POINT || object.userData['type'] === Elements.END_POINT) {
        const shape = this.gradientShapes[0] as GradientFillInfo;
        const offset = GRADIENT_HANDLE_OFFSET * this.currentZoom;

        const { end, start } = getOffsettedLineStartAndEnd(
          new Vector2(shape.projectedGsX, shape.projectedGsY),
          new Vector2(shape.projectedGeX, shape.projectedGeY),
          offset,
        );

        if (object.userData['type'] === Elements.START_POINT) {
          object.position.set(start.x, start.y, 0);
        }

        if (object.userData['type'] === Elements.END_POINT) {
          object.position.set(end.x, end.y, 0);
        }

        object.scale.set(
          (object.scale.x / previousZoom) * this.currentZoom,
          (object.scale.y / previousZoom) * this.currentZoom,
          1,
        );
      }
    });

    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  public redrawGradientControls(): void {
    const previousGradientShapeIds = this.gradientShapes.map((shape) => shape.id);
    const gradientShapes = previousGradientShapeIds.map(
      (id) => getShape(ShapeType.GRADIENT_FILL, id as string) as CurrentGFillShape,
    );

    this.removeGradientControls();
    this.createGradientControls(gradientShapes);
  }

  public removeActiveColorStop(): void {
    // Note: the color picker picks up the 'delete' command and updates the gradient,
    // so we just update the index data
    if (this.activeColorStopUuid === null) return;

    let newIndex = 0;

    this.gradientShapes.forEach((shape) => {
      if (shape.colorStopGizmos.length <= 1) return;

      shape.colorStopGizmos.forEach((gizmo) => {
        if (gizmo.uuid !== this.activeColorStopUuid) {
          gizmo.userData['index'] = newIndex;
          newIndex += 1;
        }
      });
    });

    this.updateGradientShapesInfo();
    this.activeColorStopUuid = null;
    emitGradientFillUpdatedThrottled();
  }

  public removeGradientControls(): void {
    clearThree(this.allObjects);
  }

  public setActiveColorStop(colorStopGizmo: CObject3D | null): void {
    const shape = this.gradientShapes[0] as GradientFillInfo;

    if (!colorStopGizmo) {
      this.activeColorStopUuid = null;
      shape.colorStopGizmos.forEach((gizmo) => {
        gizmo.scale.set(Number(this.currentZoom), Number(this.currentZoom), 1);
      });

      return;
    }

    if (this.activeColorStopUuid === colorStopGizmo.uuid) {
      const activeIndex = shape.colorStopGizmos.findIndex((gizmo) => gizmo.uuid === colorStopGizmo.uuid);

      if (activeIndex !== this.activeColorStopIndex) {
        this.setActiveColorStopIndex(activeIndex);
      }

      return;
    }

    colorStopGizmo.scale.set(1.6 * this.currentZoom, 1.6 * this.currentZoom, 1);

    this.activeColorStopUuid = colorStopGizmo.uuid;

    shape.colorStopGizmos.forEach((gizmo) => {
      if (gizmo.uuid !== colorStopGizmo.uuid) {
        gizmo.scale.set(Number(this.currentZoom), Number(this.currentZoom), 1);
      }
    });

    this.setActiveColorStopIndex(shape.colorStopGizmos.findIndex((gizmo) => gizmo.uuid === colorStopGizmo.uuid));
  }

  public setActiveColorStopIndex(index: number): void {
    this.activeColorStopIndex = index;
    const colorAtIndex = this.gradientShapes[0]?.g[index];

    emitter.emit(EmitterEvent.CANVAS_UPDATED_ACTIVE_GRADIENT_COLOR_STOP, {
      index,
      color: colorAtIndex,
    });
  }

  public setupListeners(): void {
    useCreatorStore.subscribe(
      (state) => state.ui.zoomPercentage,
      (percentage) => this.onCameraZoomChange(percentage),
    );

    useCreatorStore.subscribe(
      (state) => state.ui.currentTool,
      (tool) => {
        if (tool !== ToolType.Move && this.enabled) {
          this.enable(false);
        }
      },
    );

    emitter.on(EmitterEvent.TIMELINE_CURRENT_FRAME_UPDATED, () => {
      this.removeGradientControls();
    });

    emitter.on(EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED, () => {
      if (this.enabled && this.gradientShapes.length > 0) {
        this.redrawGradientControls();
      }
    });

    emitter.on(EmitterEvent.TOOLKIT_STATE_UPDATED, (params) => {
      if (params.event === EmitterEvent.TIMELINE_FRAME_UPDATE_ENDED) {
        if (this.enabled && this.gradientShapes.length > 0) {
          this.redrawGradientControls();
        }
      }
    });

    emitter.on(EmitterEvent.CANVAS_ZOOM_FIT_TO_BOUNDING_BOX, () => {
      this.enable(false);
    });
  }

  public showToolTip(type: Elements | null): void {
    if (!type) {
      this.tooltip.style.display = 'none';

      return;
    }

    if (this.tooltipRafId) {
      cancelAnimationFrame(this.tooltipRafId);
    }

    this.tooltipRafId = requestAnimationFrame(() => {
      this.tooltip.style.display = 'block';

      if (type === Elements.HIGHLIGHT_POINT) {
        const highlightPosition = getCanvasToScreenCoord(
          this._domElement,
          (this.gradientShapes[0]?.highlightPoint as CObject3D).position.clone(),
          this.viewport.camera,
        );

        this.tooltip.style.left = `${highlightPosition.x - 25}px`;
        this.tooltip.style.top = `${highlightPosition.y - 35}px`;
        this.tooltip.textContent = 'Highlight';

        return;
      }

      if (type === Elements.START_POINT || type === Elements.END_POINT) {
        const mousePosition = this.getMousePositionOnLine(this.gradientShapes[0] as GradientFillInfo, false);
        const screenPosition = getCanvasToScreenCoord(this._domElement, mousePosition, this.viewport.camera);
        const isStart = type === Elements.START_POINT;

        this.tooltip.style.left = `${screenPosition.x - (isStart ? 15 : 12)}px`;
        this.tooltip.style.top = `${screenPosition.y - 35}px`;
        this.tooltip.textContent = isStart ? 'Start' : 'End';

        return;
      }

      if (type === Elements.COLOR_STOP || type === Elements.GRADIENT_LINE || type === Elements.HOVER_CIRCLE) {
        const mousePosition = this.getMousePositionOnLine(this.gradientShapes[0] as GradientFillInfo, true);
        const screenPosition = getCanvasToScreenCoord(this._domElement, mousePosition, this.viewport.camera);

        this.tooltip.style.left = `${screenPosition.x - 10}px`;
        this.tooltip.style.top = `${screenPosition.y - 35}px`;
        this.tooltip.textContent = `${Math.round(this.currentColorOffset * 100)}%`;
      }
    });
  }

  public updateCursor(type?: Elements): void {
    if (!type) {
      this.viewport.updateContainerCursor(getCursorStyle('pointer'));

      return;
    }

    if (type === Elements.START_POINT || type === Elements.END_POINT) {
      this.viewport.updateContainerCursor(getCursorStyle('move', GlobalCursorUpdate.MOVE));

      return;
    }

    if (type === Elements.COLOR_STOP || type === Elements.HIGHLIGHT_POINT) {
      this.viewport.updateContainerCursor(getCursorStyle('pointer', GlobalCursorUpdate.INDEX));
    }

    if (type === Elements.GRADIENT_LINE || type === Elements.HOVER_CIRCLE) {
      this.viewport.updateContainerCursor(`url(${addNewStopCursor}), pointer`, ToolType.Pen);
    }
  }

  public updateGradientShapesInfo(): void {
    this.gradientShapes.forEach((shape, index) => {
      const newShape = {
        ...shape,
        ...(getShape(ShapeType.GRADIENT_FILL, shape.id as string) as CurrentGFillShape),
      };

      const { end, start } = getGradientStartAndEnd(newShape);

      newShape.projectedGeX = end.x;
      newShape.projectedGeY = end.y;
      newShape.projectedGsX = start.x;
      newShape.projectedGsY = start.y;

      this.gradientShapes[index] = newShape;
    });
  }
}
