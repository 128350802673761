/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Placement } from '@floating-ui/react-dom-interactions';
import React from 'react';

import { TooltipWithShortcut } from '../Tooltip';

import type { LibraryType } from '~/data/constant';

export interface BarButtonProps {
  activeIcon: React.ReactElement;
  id?: string;
  inactiveIcon: React.ReactElement;
  isSelected?: boolean;
  offset?: { crossAxis: number; mainAxis: number };
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  testID?: string;
  tooltipProps: {
    content?: string;
    disabled?: boolean;
    placement?: Placement;
    shortcut?: string;
  };
  type?: LibraryType;
}

export const BarButton: React.FC<BarButtonProps> = ({
  activeIcon,
  id,
  inactiveIcon,
  isSelected,
  onClick,
  testID,
  tooltipProps,
}) => {
  const { content = '', disabled = false, placement = 'right', shortcut = '' } = tooltipProps;

  return (
    <div className="relative">
      <TooltipWithShortcut content={content} disabled={disabled} placement={placement} shortcut={shortcut}>
        <button
          data-testid={testID}
          className="group flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded hover:bg-gray-700"
          onClick={onClick}
          id={id}
        >
          {React.cloneElement(isSelected ? activeIcon : inactiveIcon, {
            className: 'h-[16px] w-[16px] fill-current text-gray-400 group-hover:text-white group-hover:bg-gray-700',
          })}
        </button>
      </TooltipWithShortcut>
    </div>
  );
};

export const PanelButton: React.FC<BarButtonProps> = (props) => {
  return (
    <div>
      <BarButton {...props} />
    </div>
  );
};
