/**
 * Copyright 2023 Design Barn Inc.
 */

const CursorStyle = `style="fill: #FFF;stroke-width: 1.5px;stroke: #111417;filter: drop-shadow(0px 8px 48px rgba(243, 246, 248, 0.15)) drop-shadow(0px 2px 6px rgba(61, 72, 83, 0.06)) drop-shadow(0px 0px 1px rgba(61, 72, 83, 0.36));"`;

export const CursorPointer = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ${CursorStyle}>
    <path
    d="M11.0338 19.7422L13.779 13.7948L19.6652 11.4456C20.5184 11.1051 20.4996 9.891 19.6363 9.57708L5.84267 4.5612C5.05599 4.27513 4.28699 5.02449 4.5526 5.8183L9.17756 19.6404C9.46488 20.4991 10.6544 20.5643 11.0338 19.7422Z"
    fill="#111417"
    stroke="white"
    strokeLinecap="round"
    strokeLinejoin="round"
    />
</svg>`;

export const CursorIndex = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ${CursorStyle}>
  <path d="M18.082 9.35079V10.0467V9.35079Z" fill="white"/>
  <path d="M20.9955 11.4385V9.39179C20.9944 9.023 20.8405 8.66963 20.5676 8.40886C20.2946 8.14809 19.9248 8.0011 19.5387 8C19.1527 8.0011 18.7829 8.14809 18.5099 8.40886C18.237 8.66963 18.0831 9.023 18.082 9.39179V8.39179C18.0808 8.023 17.927 7.66963 17.654 7.40886C17.3811 7.14809 17.0112 7.0011 16.6252 7C16.2392 7.0011 15.8693 7.14809 15.5963 7.40886C15.3234 7.66963 15.1695 8.023 15.1684 8.39179V7.89179C15.1672 7.523 15.0134 7.16963 14.7404 6.90886C14.4675 6.64809 14.0976 6.5011 13.7116 6.5C13.3256 6.5011 12.9557 6.64809 12.6828 6.90886C12.4098 7.16963 12.256 7.523 12.2548 7.89179L12.2544 6.9502L12.2541 9.35079V4.39179C12.2529 4.023 12.0991 3.66963 11.8261 3.40886C11.5532 3.14809 11.1833 3.0011 10.7973 3C10.4113 3.0011 10.0414 3.14809 9.76848 3.40886C9.49553 3.66963 9.34167 4.023 9.34052 4.39179V12.8303L6.66003 10.3988C6.44383 10.0932 6.1098 9.88173 5.73092 9.81066C5.35204 9.7396 4.95912 9.81469 4.63802 10.0195C4.31823 10.2262 4.09706 10.5454 4.02281 10.9074C3.94856 11.2693 4.06447 11.6217 4.24177 11.9513C7.32302 17.6794 9.8593 20.5156 14.4955 20C17.6371 19.6506 21 17 20.9948 12.2891V9.35079L20.9955 11.4385Z" fill="white"/>
  <path d="M18.082 9.39179C18.0831 9.023 18.237 8.66963 18.5099 8.40886C18.7829 8.14809 19.1527 8.0011 19.5387 8C19.9248 8.0011 20.2946 8.14809 20.5676 8.40886C20.8405 8.66963 20.9944 9.023 20.9955 9.39179V11.4385L20.9948 9.35079V12.2891C21 17 17.6371 19.6506 14.4955 20C9.8593 20.5156 7.32302 17.6794 4.24177 11.9513C4.06447 11.6218 3.94856 11.2693 4.02281 10.9074C4.09706 10.5454 4.31823 10.2262 4.63802 10.0195C4.95912 9.81469 5.35204 9.7396 5.73092 9.81066C6.1098 9.88173 6.44383 10.0932 6.66003 10.3988L9.34052 12.8303V4.39179C9.34167 4.023 9.49553 3.66963 9.76848 3.40886C10.0414 3.14809 10.4113 3.0011 10.7973 3C11.1833 3.0011 11.5532 3.14809 11.8261 3.40886C12.0991 3.66963 12.2529 4.023 12.2541 4.39179M18.082 9.39179V9.35079M18.082 9.39179V8.39179M18.082 9.35079V8.39179M18.082 9.35079V10.0467M18.082 8.39179C18.0808 8.023 17.927 7.66963 17.654 7.40886C17.3811 7.14809 17.0112 7.0011 16.6252 7C16.2392 7.0011 15.8693 7.14809 15.5963 7.40886C15.3234 7.66963 15.1695 8.023 15.1684 8.39179M15.1684 8.39179V9.35079M15.1684 8.39179V7.89179M15.1684 9.35079V7.89179M15.1684 9.35079V10.7426M15.1684 7.89179C15.1672 7.523 15.0134 7.16963 14.7404 6.90886C14.4675 6.64809 14.0976 6.5011 13.7116 6.5C13.3256 6.5011 12.9557 6.64809 12.6828 6.90886C12.4098 7.16963 12.256 7.523 12.2548 7.89179L12.2544 6.9502M12.2541 4.39179V9.35079L12.2544 6.9502M12.2541 4.39179L12.2544 6.9502M12.2548 10.7426L12.2544 6.9502" stroke="#111417" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

export const CursorGrab = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ${CursorStyle}>
      <path d="M18.082 10.3508V11.0467V10.3508Z" fill="white" />
      <path
        d="M20.9955 12.4385V8.39179C20.9944 8.023 20.8405 7.66963 20.5676 7.40886C20.2946 7.14809 19.9248 7.0011 19.5387 7C19.1527 7.0011 18.7829 7.14809 18.5099 7.40886C18.237 7.66963 18.0831 8.023 18.082 8.39179V5.39179C18.0808 5.023 17.927 4.66963 17.654 4.40886C17.3811 4.14809 17.0112 4.0011 16.6252 4C16.2392 4.0011 15.8693 4.14809 15.5963 4.40886C15.3234 4.66963 15.1695 5.023 15.1684 5.39179V4.39179C15.1672 4.023 15.0134 3.66963 14.7404 3.40886C14.4675 3.14809 14.0976 3.0011 13.7116 3C13.3256 3.0011 12.9557 3.14809 12.6828 3.40886C12.4098 3.66963 12.256 4.023 12.2548 4.39179L12.2544 7.9502L12.2541 10.3508V5.39179C12.2529 5.023 12.0991 4.66963 11.8261 4.40886C11.5532 4.14809 11.1833 4.0011 10.7973 4C10.4113 4.0011 10.0414 4.14809 9.76848 4.40886C9.49553 4.66963 9.34167 5.023 9.34052 5.39179V13.8303L6.66003 11.3988C6.44383 11.0932 6.1098 10.8817 5.73092 10.8107C5.35204 10.7396 4.95912 10.8147 4.63802 11.0195C4.31823 11.2262 4.09706 11.5454 4.02281 11.9074C3.94856 12.2693 4.06447 12.6217 4.24177 12.9513C7.32302 18.6794 9.8593 21.5156 14.4955 21C17.6371 20.6506 21 18 20.9948 13.2891V10.3508L20.9955 12.4385Z"
        fill="white"
      />
      <path
        d="M18.082 8.39179C18.0831 8.023 18.237 7.66963 18.5099 7.40886C18.7829 7.14809 19.1527 7.0011 19.5387 7C19.9248 7.0011 20.2946 7.14809 20.5676 7.40886C20.8405 7.66963 20.9944 8.023 20.9955 8.39179V12.4385L20.9948 10.3508V13.2891C21 18 17.6371 20.6506 14.4955 21C9.8593 21.5156 7.32302 18.6794 4.24177 12.9513C4.06447 12.6218 3.94856 12.2693 4.02281 11.9074C4.09706 11.5454 4.31823 11.2262 4.63802 11.0195C4.95912 10.8147 5.35204 10.7396 5.73092 10.8107C6.1098 10.8817 6.44383 11.0932 6.66003 11.3988L9.34052 13.8303V5.39179C9.34167 5.023 9.49553 4.66963 9.76848 4.40886C10.0414 4.14809 10.4113 4.0011 10.7973 4C11.1833 4.0011 11.5532 4.14809 11.8261 4.40886C12.0991 4.66963 12.2529 5.023 12.2541 5.39179M18.082 8.39179V10.3508M18.082 8.39179V5.39179M18.082 10.3508V5.39179M18.082 10.3508V11.0467M18.082 5.39179C18.0808 5.023 17.927 4.66963 17.654 4.40886C17.3811 4.14809 17.0112 4.0011 16.6252 4C16.2392 4.0011 15.8693 4.14809 15.5963 4.40886C15.3234 4.66963 15.1695 5.023 15.1684 5.39179M15.1684 5.39179V10.3508M15.1684 5.39179V4.39179M15.1684 10.3508V4.39179M15.1684 10.3508V11.7426M15.1684 4.39179C15.1672 4.023 15.0134 3.66963 14.7404 3.40886C14.4675 3.14809 14.0976 3.0011 13.7116 3C13.3256 3.0011 12.9557 3.14809 12.6828 3.40886C12.4098 3.66963 12.256 4.023 12.2548 4.39179L12.2544 7.9502M12.2541 5.39179V10.3508L12.2544 7.9502M12.2541 5.39179L12.2544 7.9502M12.2548 11.7426L12.2544 7.9502"
        stroke="#111417"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
</svg>`;

export const CursorGrabbing = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ${CursorStyle}>
    <path d="M18.104 10.0433V10.7392V10.0433Z" fill="white"/>
    <path d="M21.0176 10.2668C21.0056 9.91287 20.8532 9.57587 20.5904 9.32474C20.3174 9.06396 19.9475 8.91698 19.5615 8.91587C19.1755 8.91698 18.8057 9.06396 18.5327 9.32474C18.2598 9.58551 18.1052 9.80668 18.104 10.1755V10.0433V8.28357C18.1029 7.91479 17.949 7.56142 17.6761 7.30065C17.4031 7.03987 17.0332 6.89289 16.6472 6.89178C16.2612 6.89289 15.8914 7.03987 15.6184 7.30065C15.3455 7.56142 15.1916 7.59448 15.1905 7.96326V10.0433L15.1898 7.61948C15.1886 7.25069 15.0348 6.89733 14.7618 6.63655C14.4889 6.37578 14.119 6.22879 13.733 6.22769C13.347 6.22879 12.9771 6.37578 12.7041 6.63655C12.4312 6.89733 12.2773 7.25069 12.2762 7.61948L12.2762 10.0433V7.39179C12.275 7.023 12.1211 6.66963 11.8482 6.40886C11.5752 6.14809 11.2054 6.0011 10.8194 6C10.4334 6.0011 10.0635 6.14809 9.79054 6.40886C9.51759 6.66963 9.36374 7.023 9.36258 7.39179V10.2728C6.98462 9.39178 5.73853 11.5374 6.04608 12.7834C7.04603 18.7834 9.88136 21.2081 14.5175 20.6925C17.6592 20.3431 21.0221 17.6925 21.0169 12.9815L21.0176 10.2668Z" fill="white"/>
    <path d="M18.104 10.0433V10.1755C18.1052 9.80668 18.2598 9.58551 18.5327 9.32474C18.8057 9.06396 19.1755 8.91698 19.5615 8.91587C19.9475 8.91698 20.3174 9.06396 20.5904 9.32474C20.8532 9.57587 21.0056 9.91287 21.0176 10.2668M18.104 10.0433V8.28357C18.1029 7.91479 17.949 7.56142 17.6761 7.30065C17.4031 7.03987 17.0332 6.89289 16.6472 6.89178C16.2612 6.89289 15.8914 7.03987 15.6184 7.30065C15.3455 7.56142 15.1916 7.59448 15.1905 7.96326V10.0433M18.104 10.0433V10.7392M15.1905 10.0433L15.1898 7.61948C15.1886 7.25069 15.0348 6.89733 14.7618 6.63655C14.4889 6.37578 14.119 6.22879 13.733 6.22769C13.347 6.22879 12.9771 6.37578 12.7041 6.63655C12.4312 6.89733 12.2773 7.25069 12.2762 7.61948L12.2762 10.0433V7.39179M15.1905 10.0433V11.4351M12.2762 7.39179C12.275 7.023 12.1211 6.66963 11.8482 6.40886C11.5752 6.14809 11.2054 6.0011 10.8194 6C10.4334 6.0011 10.0635 6.14809 9.79054 6.40886C9.51759 6.66963 9.36374 7.023 9.36258 7.39179V10.2728M12.2762 7.39179L12.2769 11.4351M9.36258 13.5227V10.7392V10.2728M21.0176 10.1755L21.0176 10.2668M9.36258 10.2728C6.98462 9.39178 5.73853 11.5374 6.04608 12.7834C7.04603 18.7834 9.88136 21.2081 14.5175 20.6925C17.6592 20.3431 21.0221 17.6925 21.0169 12.9815L21.0176 10.2668M21.0176 10.2668L21.0176 12.131L21.0183 10.3077C21.0183 10.294 21.018 10.2804 21.0176 10.2668Z" stroke="#111417" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const CursorMove = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ${CursorStyle}>
    <path d="M3.02209 11.2299L3.37167 11.5795L3.02209 11.2299C2.5968 11.6552 2.5968 12.3448 3.02209 12.77L5.22965 14.9776C5.65494 15.4029 6.34447 15.4029 6.76976 14.9776C7.19505 14.5523 7.19505 13.8628 6.76976 13.4375L6.48874 13.1565L10.8431 13.1565L10.8432 17.5111L10.5622 17.2301C10.1369 16.8048 9.44738 16.8048 9.02209 17.2301C8.5968 17.6554 8.5968 18.3449 9.02209 18.7702L11.2296 20.9778C11.6549 21.4031 12.3445 21.4031 12.7698 20.9778L14.9773 18.7702C15.4026 18.3449 15.4026 17.6554 14.9773 17.2301C14.552 16.8048 13.8625 16.8048 13.4372 17.2301L13.1562 17.5111L13.1562 13.1564L17.5107 13.1564L17.2296 13.4375C16.8043 13.8628 16.8043 14.5523 17.2296 14.9776C17.6549 15.4029 18.3445 15.4029 18.7697 14.9776L20.9773 12.77C21.4026 12.3447 21.4026 11.6552 20.9773 11.2299L18.7697 9.02236C18.3445 8.59707 17.6549 8.59707 17.2296 9.02236C16.8043 9.44765 16.8043 10.1372 17.2296 10.5625L17.5106 10.8434L13.1561 10.8434L13.1561 6.4892L13.4372 6.77025C13.8625 7.19554 14.552 7.19554 14.9773 6.77025C15.4026 6.34496 15.4026 5.65542 14.9773 5.23013L12.7697 3.02258C12.3445 2.59729 11.6549 2.59729 11.2296 3.02258L11.5785 3.37142L11.2296 3.02258L9.02208 5.23013C8.59678 5.65542 8.59679 6.34496 9.02208 6.77025C9.44737 7.19554 10.1369 7.19554 10.5622 6.77025L10.8431 6.48929L10.8431 10.8434L6.48877 10.8435L6.76976 10.5625C7.19505 10.1372 7.19505 9.44767 6.76976 9.02238C6.34447 8.59709 5.65494 8.59709 5.22965 9.02238L3.02209 11.2299Z" fill="#111417" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

export const CursorResize = `
<svg width="36" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" ${CursorStyle}>
<path d="M16.847 7.8271C16.4108 7.39097 15.7037 7.39097 15.2676 7.8271C14.8315 8.26323 14.8315 8.97034 15.2676 9.40646L16.4623 10.6012L8.31365 10.6013L9.50784 9.40707C9.94397 8.97094 9.94397 8.26383 9.50784 7.8277C9.07171 7.39157 8.3646 7.39157 7.92847 7.8277L4.8271 10.9291C4.39097 11.3652 4.39097 12.0723 4.8271 12.5084L7.92847 15.6098C8.3646 16.0459 9.07171 16.0459 9.50784 15.6098C9.94397 15.1737 9.94397 14.4666 9.50784 14.0304L8.3122 12.8348L16.4627 12.8348L15.2676 14.0298C14.8315 14.466 14.8315 15.1731 15.2676 15.6092C15.7037 16.0453 16.4108 16.0453 16.847 15.6092L19.9483 12.5078C20.3845 12.0717 20.3845 11.3646 19.9483 10.9285L16.847 7.8271Z" fill="#111417" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
