/**
 * Copyright 2023 Design Barn Inc.
 */

import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import React, { useEffect } from 'react';

import LoadingAnimation from '~/assets/animations/loading-lf.json';
import { PluginFailed } from '~/assets/icons';
import { Divider } from '~/components/Elements/Divider';
import { getPluginManager } from '~/plugins';
import { useCreatorStore } from '~/store';

interface PluginHolderPanelProps {
  currentLibrary: string;
}

export const PluginHolderPanel: React.FC<PluginHolderPanelProps> = ({ currentLibrary }) => {
  const loadedPlugins = useCreatorStore((state) => state.plugins.loadedPlugins);
  const currentPluginId = currentLibrary.substring('plugin-'.length);
  const currentPlugin = loadedPlugins.find((plugin) => plugin.id === currentPluginId);
  const isLoading = currentPlugin?.loading;
  const isFailed = currentPlugin?.failed;

  useEffect(() => {
    if (!isLoading) {
      const plugin = getPluginManager().getPlugin(currentPluginId);

      // NOTE: to guarantee that the sidebar container that the plugin can
      // attach it's ui to is present, we have to dispatch messages on the next
      // tick. Othewise, there appears to be some inconsistency with attaching
      // the dom nodes to the sidebar
      setTimeout(() => {
        plugin?.dispatchMessage({ action: 'showSidebar' });
      }, 0);
    }
  }, [isLoading, currentPluginId]);

  let pluginName = currentPlugin?.name ?? currentPlugin?.options.eagerName ?? 'Plugin';

  // FIXME: This is a quick and dirty workaround since the iconscout plugin's name a suffix
  pluginName = pluginName.replace(/- Free design assets$/u, '');

  return (
    <div
      className="flex h-full w-full flex-col"
      id="sidebar-plugin-panel"
      key={currentLibrary.substring('plugin-'.length)}
    >
      <div className="flex px-3 text-white">
        <div className="py-2 text-xs font-semibold">
          {currentPlugin?.name ?? currentPlugin?.options.eagerName ?? 'Plugin Sidebar'}
        </div>
      </div>
      <Divider className="mb-2" />
      {isLoading && !isFailed && (
        <div className="flex grow flex-col justify-center">
          <LottiePlayer renderer="svg" autoplay loop className="h-20 w-20" src={LoadingAnimation} />
        </div>
      )}
      {isFailed && !isLoading && (
        <div className="flex grow flex-col justify-start">
          <div className="mx-auto w-[124px] pt-10">
            <PluginFailed className="w-[115px]" />
          </div>
          <div className="mx-auto w-[185px] ">
            <div className="mt-4 text-center text-[10px] font-bold text-gray-300">
              Couldn{"'"}t load {pluginName}
            </div>
            <div className="mt-2 text-center text-[10px] text-gray-300">
              Please try again, or contact our{' '}
              <a
                target="_blank"
                href="https://help.lottiefiles.com/hc/en-us/requests/new"
                className="text-teal-500"
                rel="noreferrer"
              >
                customer support team.
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
