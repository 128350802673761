/**
 * Copyright 2023 Design Barn Inc.
 */

import { startsWith } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Color } from 'three';

import { PlayerControls } from './PlayerControls/PlayerControls';
import { RenderVisibility } from './RenderVisibility';

import { WHITE_COLOR } from '~/features/canvas';
import { useCreatorStore } from '~/store';
import type { SizeType } from '~/utils';
import { scaleToFit } from '~/utils';

interface DotLottiePlayerElement {
  current: DotLottiePlayer;
}

export interface DotLottiePlayer {
  getLottie: () => AnimationItem;
  getVersions: () => { dotLottiePlayerVersion: string; lottieWebVersion: string };
  load: (src: Record<string, never>) => void;
  pause: () => void;
  play: () => void;
  seek: (value: number | string) => void;
  setLooping: (value: boolean) => void;
  setSpeed: (value: number) => void;
  stop: () => void;
}

export interface AnimationItem {
  currentFrame: number;
  frameRate: number;
  goToAndStop: (frame: number, value: boolean) => void;
  isLoaded: boolean;
  isPaused: boolean;
  loop: boolean | number;
  name: string;
  onComplete: () => void;
  onEnterFrame: () => void;
  totalFrames: number;
}

interface Props {
  forThumbnail?: boolean;
  getPlayerVersion?: (player: DotLottiePlayer) => void;
  hidden?: boolean;
  json: Record<string, never>;
}

const extractOpacityAlpha = (hex: string): number => {
  const alphaHex = hex.substring(7, 9);
  const alphaDecimal = parseInt(alphaHex, 16);

  return alphaDecimal / 255;
};

const isHexWithOpacity = (hex: string): boolean => {
  return hex.length > 7;
};

const getBgColorAndOpacity = (hexColor: string): [string, number] => {
  const color = startsWith(hexColor, '#') ? hexColor : `#${hexColor}`;
  const isHexOpacity = isHexWithOpacity(color);

  const opacity = isHexOpacity ? extractOpacityAlpha(color) : 1;

  return [color, opacity];
};

export const getScaledSize = (src: SizeType): { h: number; w: number } => {
  // player reference max height: 680 (popover max size) - 226
  // player reference max width: 340 (popover max size) - 2px border
  const result = scaleToFit(src, { width: 338, height: 454 });

  return { h: result.height, w: result.width };
};

export const TestAnimationPlayer: React.FC<Props> = ({
  forThumbnail = false,
  getPlayerVersion,
  hidden = false,
  json,
}) => {
  const canvasBackground = useCreatorStore.getState().canvas.background;
  const canvasColor =
    canvasBackground.opacity === 100
      ? getBgColorAndOpacity(canvasBackground.color)[0]
      : `#${new Color(WHITE_COLOR).getHexString()}`;

  // color picker state
  const [color, setColor] = useState(canvasColor);

  // player state
  const playerRef = useRef<DotLottiePlayerElement>(null);
  const player = playerRef.current as DotLottiePlayer | null;
  const [size, setSize] = useState({ h: 0, w: 0 });
  const [speed, setSpeed] = useState(1);
  const [lottieInstance, setLottieInstance] = useState<AnimationItem | null>(null);
  const [isPlaying, setIsPlaying] = useState(forThumbnail ? false : !hidden);
  const [isLooping, setIsLooping] = useState(true);
  const [currentFrame, setCurrentFrame] = useState<number>(0);
  const [totalFrames, setTotalFrames] = useState<number>(1);
  const [frameRate, setFrameRate] = useState<number>(30);

  const defaultSize = {
    h: 340,
    w: 454,
  };

  const updateColor = useCallback((colorValue: string): void => {
    setColor(colorValue);
  }, []);

  // eslint-disable-next-line id-length
  const { h, w } = json as Record<string, number | undefined>;

  useEffect(() => {
    setSize(getScaledSize({ width: (w as number) || defaultSize.w, height: (h as number) || defaultSize.h }));
  }, [defaultSize.h, defaultSize.w, h, w]);

  // Load the animation
  useEffect(() => {
    if (player) {
      if (!forThumbnail) player.load(json);
      if (getPlayerVersion) getPlayerVersion(player);
      setLottieInstance(player.getLottie());
    }
  }, [forThumbnail, getPlayerVersion, json, player, size.h, size.w]);

  // Update the current frame
  useEffect(() => {
    if (lottieInstance && isPlaying) {
      lottieInstance.onEnterFrame = () => {
        const frame = Math.floor(lottieInstance.currentFrame);

        setCurrentFrame(frame);
      };
    }
  }, [isPlaying, lottieInstance]);

  // Get the animation's total frames and frame rate
  useEffect(() => {
    if (lottieInstance) {
      setTotalFrames(lottieInstance.totalFrames);
      setFrameRate(lottieInstance.frameRate);
    }
  }, [lottieInstance, setTotalFrames, setFrameRate]);

  // Reset player if animation reaches the end and loop is disabled
  useEffect(() => {
    if (lottieInstance && !isLooping) {
      lottieInstance.onComplete = () => {
        setIsPlaying(false);
        player?.stop();
      };
    }
  }, [currentFrame, isLooping, lottieInstance, player, totalFrames]);

  const togglePlayAnimation = useCallback(() => {
    if (isPlaying) {
      setIsPlaying(false);
      (player as DotLottiePlayer).pause();
    } else {
      setIsPlaying(true);
      (player as DotLottiePlayer).play();
    }
  }, [isPlaying, player]);

  const toggleLooping = useCallback(() => {
    if (isLooping) {
      setIsLooping(false);
      (player as DotLottiePlayer).setLooping(false);
    } else {
      setIsLooping(true);
      (player as DotLottiePlayer).setLooping(true);
    }
  }, [isLooping, player]);

  const toggleSpeed = useCallback(() => {
    let newSpeed = speed + 0.5;

    if (newSpeed > 2.5) {
      newSpeed = 0.5;
    }

    setSpeed(newSpeed);
    (player as DotLottiePlayer).setSpeed(newSpeed);
  }, [speed, player]);

  return (
    <div>
      <RenderVisibility hidden={hidden}>
        <dotlottie-player
          ref={playerRef}
          background={color}
          renderer="svg"
          autoplay
          loop
          style={{
            height: size.h,
            width: size.w,
            maxWidth: forThumbnail ? 'none' : 340,
          }}
          {...(hidden && forThumbnail
            ? {
                id: 'thumbnail-player',
                src: JSON.stringify(json),
              }
            : {
                id: 'test-animation-player',
              })}
        />
        {playerRef.current && lottieInstance && (
          <>
            <PlayerControls
              player={player as DotLottiePlayer}
              lottieInstance={lottieInstance}
              isPlaying={isPlaying}
              isLooping={isLooping}
              frameRate={frameRate}
              speed={speed}
              toggleSpeed={toggleSpeed}
              togglePlayAnimation={togglePlayAnimation}
              currentFrame={currentFrame}
              setCurrentFrame={setCurrentFrame}
              totalFrames={totalFrames}
              toggleLooping={toggleLooping}
              color={color}
              onStopColor={updateColor}
            />
          </>
        )}
        <div className="rounded-b-md border-t border-gray-700">
          <div className="flex items-center justify-center rounded-b-md border-gray-700 p-3">
            <span className="text-[12px] text-[#808E9A]">Background color will not be exported.</span>
          </div>
        </div>
      </RenderVisibility>
    </div>
  );
};
