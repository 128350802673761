/**
 * Copyright 2024 Design Barn Inc.
 */

import { Vector } from '@lottiefiles/toolkit-js';
import type { Scene, LayerJSON, AnimatedPropertyJSON, Transform } from '@lottiefiles/toolkit-js';
import { findKey } from 'lodash-es';

import { AnimatedProperty } from './3d/shapes/transform';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { layerMap as LayerMap } from '~/lib/layer';
import { toolkit, AnimatedType } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { CANVAS_DEFAULT_TAB_ID, CANVAS_ANCHOR_GRID_PREFIX } from '~/store/constant';

export enum CanvasAnchorDirection {
  Bottom = 'bottom',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Top = 'top',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
}

// Only applicable when ThreeJS Canvas starting at top-left corner - coordinate (0,0) origin.
// x increasing towards right, y increasing towards bottom
const CanvasOriginPositionShiftFactor = {
  [CanvasAnchorDirection.TopLeft]: { x: 0, y: 0 },
  [CanvasAnchorDirection.Top]: { x: 0.5, y: 0 },
  [CanvasAnchorDirection.TopRight]: { x: 1, y: 0 },
  [CanvasAnchorDirection.Left]: { x: 0, y: 0.5 },
  [CanvasAnchorDirection.Center]: { x: 0.5, y: 0.5 },
  [CanvasAnchorDirection.Right]: { x: 1, y: 0.5 },
  [CanvasAnchorDirection.Bottom]: { x: 0.5, y: 1 },
  [CanvasAnchorDirection.BottomLeft]: { x: 0, y: 1 },
  [CanvasAnchorDirection.BottomRight]: { x: 1, y: 1 },
};

export interface SizeChanged {
  new: {
    height: number;
    width: number;
  };
  old: {
    height: number;
    width: number;
  };
}

export const adjustPositionLayersAfterResize = (size: SizeChanged): void => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
  const setAnimatedValue = useCreatorStore.getState().toolkit.setAnimatedValue;

  const getShiftedPosition = (
    { x, y }: { x: number; y: number },
    direction: CanvasAnchorDirection,
  ): Record<string, number> => {
    const shiftFactor = CanvasOriginPositionShiftFactor[direction];

    const newWidthDiff = size.new.width - size.old.width;
    const newHeightDiff = size.new.height - size.old.height;

    return {
      x: x + newWidthDiff * shiftFactor.x,
      y: y + newHeightDiff * shiftFactor.y,
    };
  };

  const updateNonAnimatedPositionLayer = (layer: LayerJSON, direction: CanvasAnchorDirection): void => {
    const node = getNodeByIdOnly(layer.id) as Transform | null;

    if (!node) return;
    const { x, y } = getShiftedPosition({ x: node.position.value.x, y: node.position.value.y }, direction);

    setAnimatedValue(AnimatedType.POSITION, [x, y] as number[], layer.id);
  };

  // Prevent updates if height & width doesn't change
  if (size.new.width === size.old.width && size.new.height === size.old.height) return;

  let allLayers = useCreatorStore.getState().toolkit.json?.allLayers || [];
  const anchorPointsActives = useCreatorStore.getState().ui.anchorPointsActive;
  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

  const sceneId = `${CANVAS_ANCHOR_GRID_PREFIX}${selectedPrecompositionId || CANVAS_DEFAULT_TAB_ID}`;
  const anchorDirection = findKey(anchorPointsActives[sceneId]) as CanvasAnchorDirection | null;

  if (!anchorDirection) return;

  if (selectedPrecompositionId !== null) {
    const precomNode = getNodeByIdOnly(selectedPrecompositionId);

    allLayers = (precomNode as Scene).state.allLayers;
  }

  if (allLayers.length > 0) {
    allLayers.forEach((layer) => {
      if (layer.id) {
        const layerMap = LayerMap.get(layer.id);

        if (layerMap) {
          if (layerMap.animated.length > 0) {
            const positionIds: string[] = [];

            layerMap.animated.forEach((item) => {
              if ([AnimatedProperty.Position].includes(item.type as AnimatedProperty)) positionIds.push(item.id);
            });

            if (positionIds.length > 0) {
              positionIds.forEach((id) => {
                const posNode = getNodeByIdOnly(id);

                (posNode?.state as AnimatedPropertyJSON | null)?.keyFrames.forEach((keyFrame) => {
                  const kfNode = toolkit.getKeyframeById(keyFrame.frameId as string);

                  if (kfNode && kfNode.value.x !== null && kfNode.value.y !== null) {
                    const kfNodeX = kfNode.value.x;
                    const kfNodeY = kfNode.value.y;

                    const { x, y } = getShiftedPosition({ x: kfNodeX, y: kfNodeY }, anchorDirection);

                    kfNode.setValue(new Vector(x, y));
                  }
                });
              });
            } else {
              updateNonAnimatedPositionLayer(layer, anchorDirection);
            }
          } else {
            updateNonAnimatedPositionLayer(layer, anchorDirection);
          }
        }
      }
    });
  }
  emitter.emit(EmitterEvent.CANVAS_ZOOM_TO_FIT);
  emitter.emit(EmitterEvent.ANIMATED_POSITION_UPDATED);
};
