/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { GuidesMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapper } from './ContextMenuWrapper';

import { GuideMenuType } from '~/data/constant';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';
import type { Guides } from '~/store/canvasSlice';
import { GlobalModalConstant } from '~/store/constant';

interface GuidesMenuProps {
  coord: Coordinate;
  onClose: () => void;
  selectedGuide: NonNullable<Guides['selectedGuide']>;
}

export const GuidesMenu: React.FC<GuidesMenuProps> = ({ coord, onClose, selectedGuide }) => {
  const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;
  const removeGuide = useCreatorStore.getState().canvas.removeGuide;

  GuidesMenuList.forEach((item) => {
    if (item.type === GuideMenuType.EditGuides) {
      item.callback = () => {
        setGlobalModal(GlobalModalConstant.EditGuides, selectedGuide);
      };
    }

    if (item.type === GuideMenuType.DeleteGuide) {
      item.callback = () => {
        removeGuide(selectedGuide);
        emitter.emit(EmitterEvent.CANVAS_REDRAW_OVERLAY);
      };
    }
  });

  return <ContextMenuWrapper coord={coord} onClose={onClose} dropdownItems={GuidesMenuList} />;
};
