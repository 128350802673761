/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StateCreator } from 'zustand';

import type { StoreSlice } from '.';
import type { Viewport, Editor } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { PathControls } from '~/lib/threejs/PathControls';

export interface CanvasCoord {
  x: number;
  y: number;
}

export enum GuideType {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface Guide {
  [id: string]: number;
}

export type Guides = {
  color: Record<'r' | 'g' | 'b', number>;
  enabled: boolean;
  selectedGuide: { id: string; type: GuideType } | null;
} & Record<GuideType, Guide>;

export interface Grid {
  color: Record<'r' | 'g' | 'b' | 'a', number>;
  columns: number;
  enabled: boolean;
  rows: number;
  visible: boolean;
}

export interface CanvasBackground {
  color: string;
  opacity: number;
}

export interface CanvasSlice {
  canvas: {
    background: CanvasBackground;
    coord: CanvasCoord | null;
    disableInteractions: boolean;
    editor: Editor | null;
    grid: Grid;
    guides: Guides;
    hideTransformControls: boolean;
    pathControls: PathControls | null;
    removeGuide({ id, type }: { id: string; type: GuideType }): void;
    rulers: {
      enabled: boolean;
    };
    setBackground: (background: CanvasBackground) => void;
    setCoord: (input: CanvasCoord | null) => void;
    setDisableInteractions: (shouldDisable: boolean) => void;
    setEditor: (editor: Editor | null) => void;
    setGrid(data: Partial<Grid>): void;
    setGuide({ id, type, value }: { id: string; type: GuideType; value: number }): void;
    setGuideColor(color: Guides['color']): void;
    setHideTransformControls: (hide: boolean, emitEvent?: boolean) => void;
    setSelectedGuide(selectedGuide: Guides['selectedGuide']): void;
    setViewport: (viewport: Viewport | null) => void;
    snapping: {
      enabled: boolean;
    };
    toggleGrid(): void;
    toggleGridVisibility(): void;
    toggleGuides(): void;
    toggleRulers(): void;
    toggleSnapping(): void;
    viewport: Viewport | null;
  };
}

const defaultGridState = {
  visible: true,
  enabled: true,
  color: { r: 80, g: 193, b: 84, a: 1 },
  columns: 3,
  rows: 3,
};

export const createCanvasSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  CanvasSlice
> = (set) => ({
  canvas: {
    editor: null,
    hideTransformControls: false,
    pathControls: null,
    viewport: null,
    coord: null,
    setCoord: (input: CanvasCoord | null) => {
      set((state) => {
        if (input) {
          state.canvas.coord = {
            ...state.canvas.coord,
            ...input,
          };
        } else {
          state.canvas.coord = input;
        }
      });
    },
    background: { color: '#FFFFFF', opacity: 0 },
    setBackground: (background: CanvasBackground) => {
      set((state) => {
        state.canvas.background = background;
      });
    },
    setEditor: (editor: Editor | null) => {
      set(() => {
        return {
          editor,
        };
      });
    },
    setHideTransformControls: (hide: boolean, emitEvent = true) => {
      set((state) => {
        state.canvas.hideTransformControls = hide;
      });

      if (!emitEvent) return;

      if (hide) {
        emitter.emit(EmitterEvent.CANVAS_HIDE_TRANSFORMCONTROL);
      } else {
        emitter.emit(EmitterEvent.CANVAS_SHOW_TRANSFORMCONTROL);
      }
    },
    snapping: {
      enabled: true,
    },
    setViewport: (viewport: Viewport | null) => {
      set(() => {
        return {
          viewport,
        };
      });
    },
    disableInteractions: false,
    setDisableInteractions: (shouldDisable: boolean) => {
      set((state) => {
        state.canvas.disableInteractions = shouldDisable;
      });
    },
    guides: {
      enabled: true,
      horizontal: {},
      vertical: {},
      selectedGuide: null,
      color: { r: 217, g: 38, b: 0 },
    },
    rulers: {
      enabled: false,
    },
    grid: { ...defaultGridState, enabled: false, visible: false },
    toggleGuides: () => {
      set((state) => {
        state.canvas.guides.enabled = !state.canvas.guides.enabled;
      });
    },
    setGuide: ({ id, type, value }: { id: string; type: GuideType; value: number }) => {
      set((state) => {
        state.canvas.guides[type][id] = value;
      });
    },
    setGuideColor: (color: Guides['color']) => {
      set((state) => {
        state.canvas.guides.color = color;
      });
    },
    setSelectedGuide: (selectedGuide: Guides['selectedGuide']) => {
      set((state) => {
        state.canvas.guides.selectedGuide = selectedGuide;
      });
    },
    removeGuide: ({ id, type }: { id: string; type: GuideType }) => {
      set((state) => {
        delete state.canvas.guides[type][id];
      });
    },
    toggleRulers: () => {
      set((state) => {
        state.canvas.rulers.enabled = !state.canvas.rulers.enabled;
      });
    },
    toggleGrid: () => {
      set((state) => {
        if (state.canvas.grid.enabled) {
          state.canvas.grid.enabled = false;
        } else {
          state.canvas.grid = { ...defaultGridState };
        }
      });
    },
    toggleGridVisibility: () => {
      set((state) => {
        if (state.canvas.grid.visible) {
          state.canvas.grid.visible = false;
        } else {
          state.canvas.grid.visible = true;
          state.canvas.grid.enabled = true;
        }
      });
    },
    toggleSnapping: () => {
      set((state) => {
        state.canvas.snapping.enabled = !state.canvas.snapping.enabled;
      });
    },
    setGrid: (data: Partial<Grid>) => {
      set((state) => {
        state.canvas.grid = {
          ...state.canvas.grid,
          ...data,
        };
      });
    },
  },
});
