/**
 * Copyright 2024 Design Barn Inc.
 */

import type { ColorStopJSON } from '@lottiefiles/toolkit-js';
import type { Vector2 } from 'three';
import { ShaderMaterial, Color, Mesh, SphereGeometry } from 'three';

import { Elements } from '..';

import { materialOptions, RENDER_ORDERS } from '.';
import { CObject3D } from '~/features/canvas';

const colorStopVertexShader = `
  varying vec2 vCoord;

  void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position.xyz, 1.0);

    vCoord = position.xy;
  }
`;

const colorStopFragmentShader = `
  uniform vec3 color;
  uniform float alpha;
  uniform vec3 outlineColor;
  varying vec2 vCoord;
  uniform vec2 direction;

  void main() {
    float circleRadius = 6.0;
    float innerOutlineRadius = 7.0;
    float outlineRadius = 8.6;
    float shadowRadius = 10.0;

    float distanceFromCenter = length(vCoord);

    if (distanceFromCenter < circleRadius) {
      if (alpha < 1.0) {
        // Calculate the direction of the line
        float angle = atan(direction.y, direction.x);
        // Rotate the coord to follow the line direciton
        mat2 rotationMat = mat2(cos(angle), -sin(angle), sin(angle), cos(angle));
        vec2 rotatedCoord = rotationMat * vCoord;
        // Fade from top to bottom
        float yOffset = circleRadius + 2.0;
        float fadeEffect = mix(0.8, 0.0, (rotatedCoord.y + yOffset) / (2.0 * circleRadius));
        gl_FragColor = vec4(color, fadeEffect);
      } else {
        gl_FragColor = vec4(color, alpha);
      }
    } else if (distanceFromCenter < innerOutlineRadius) {
      gl_FragColor = vec4(0.9, 0.9, 0.9, 1.0);
    } else if (distanceFromCenter < outlineRadius) {
      gl_FragColor = vec4(outlineColor, 1.0);
    } else if (distanceFromCenter < shadowRadius) {
      float shadowAlpha = mix(0.05, 0.0, (distanceFromCenter - outlineRadius) / (shadowRadius - outlineRadius));
      gl_FragColor = vec4(0.0, 0.0, 0.0, shadowAlpha);
    } 
  }
`;

const transparentPatternFragmentShader = `
  varying vec2 vCoord;

  vec3 getCheckeredPattern(in float x, in float y) {
    float checkSize = 0.8; 
    float modResult = mod(floor(checkSize * x) + floor(checkSize * y), 2.0);
    float color = max(sign(modResult), 0.0);
    return vec3(color * 0.5 + 0.6);
  }

  void main() {
    float circleRadius = 6.0;
    float distanceFromCenter = length(vCoord);

    if (distanceFromCenter < circleRadius) {
      vec3 color = getCheckeredPattern(vCoord.x, vCoord.y);
      gl_FragColor = vec4(color, 1.0); 
    } else {
      gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
    }
  }
`;

export const updateColorStopPositions = (
  colorStopGizmo: CObject3D,
  startPoint: Vector2,
  endPoint: Vector2,
  offset: number,
): void => {
  const position = startPoint.clone().lerp(endPoint, offset);
  const direction = endPoint.clone().sub(startPoint).normalize();

  colorStopGizmo.children[1].material.uniforms.direction.value = direction;

  colorStopGizmo.position.set(position.x, position.y, 0);
};

export const createColorStopGizmos = (
  colorStops: ColorStopJSON[],
  start: Vector2,
  end: Vector2,
  overrideIndex?: number,
): CObject3D[] => {
  const colorStopGizmos: CObject3D[] = [];

  colorStops.forEach((colorStop, index) => {
    if (colorStop.offset < 0 || colorStop.offset > 1) {
      return;
    }

    const colorStopGizmo = new CObject3D();

    const innerSphereShader = new ShaderMaterial({
      uniforms: {
        color: { value: new Color(`rgb(${colorStop.r}, ${colorStop.g}, ${colorStop.b})`) },
        alpha: { value: colorStop.a },
        outlineColor: { value: new Color('rgb(255, 255, 255)') },
        direction: { value: end.clone().sub(start).normalize() },
      },
      vertexShader: colorStopVertexShader,
      fragmentShader: colorStopFragmentShader,
      ...materialOptions,
    });

    const innerSphere = new Mesh(new SphereGeometry(10, 10, 10), innerSphereShader);

    const transparentPatternShader = new ShaderMaterial({
      vertexShader: colorStopVertexShader,
      fragmentShader: transparentPatternFragmentShader,
      ...materialOptions,
    });

    const transparentPatternUnderLay = new Mesh(new SphereGeometry(6, 10, 10), transparentPatternShader);

    transparentPatternUnderLay.renderOrder = RENDER_ORDERS.COLOR_STOP_TRANSPARENT_PATTERN;
    innerSphere.renderOrder = RENDER_ORDERS.COLOR_STOP;
    colorStopGizmo.renderOrder = RENDER_ORDERS.COLOR_STOP;

    colorStopGizmo.add(transparentPatternUnderLay);
    colorStopGizmo.add(innerSphere);

    colorStopGizmo.userData = {
      type: Elements.COLOR_STOP,
      index: overrideIndex ? overrideIndex : index,
    };

    updateColorStopPositions(colorStopGizmo, start, end, colorStops[index]?.offset as number);

    colorStopGizmos.push(colorStopGizmo);
  });

  return colorStopGizmos;
};
