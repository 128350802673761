/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable no-plusplus */

import type { Size } from '@lottiefiles/toolkit-js';
import { clamp } from 'lodash-es';

import { getMouseCoord } from './3d/utils/mouse';
import { unProject } from './3d/utils/three';
import type { CanvasContextHelper } from './canvasContextHelper';
import { GUIDES_TOOLTIP_HEIGHT } from './components/GuidesTooltip';
import { ViewportConfig } from './config';
import { directionToCursorMap, Ruler } from './ruler';
import { getCursorStyle } from './styleMapper';
import type { Viewport } from './viewport';

import { ToolType } from '~/data/constant';
import { getActiveScene, getSceneSize, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { GuideType } from '~/store/canvasSlice';
import type { Guides } from '~/store/canvasSlice';

const setGuide = useCreatorStore.getState().canvas.setGuide;

export interface OverlayCanvasArgs {
  element: HTMLCanvasElement;
  horizontalRulerElement: HTMLCanvasElement;
  verticalRulerElement: HTMLCanvasElement;
  viewport: Viewport;
}

export class OverlayCanvas {
  public readonly element: HTMLCanvasElement;

  public isDraggingGuide: boolean = false;

  public isReRenderingNeeded: boolean = false;

  public selectedGuide: Guides['selectedGuide'] = null;

  private readonly _canvasHelper: CanvasContextHelper;

  private readonly _ctx: CanvasRenderingContext2D;

  private readonly _horizontalGuidesReverseMap = new Map();

  private readonly _horizontalRuler: Ruler;

  private _sceneSize = { width: 0, height: 0 };

  private readonly _tooltipElement: HTMLElement;

  private _transformControlsVisibleToRestore = false;

  private readonly _verticalGuidesReverseMap = new Map();

  private readonly _verticalRuler: Ruler;

  private readonly _viewport: Viewport;

  public constructor({ element, horizontalRulerElement, verticalRulerElement, viewport }: OverlayCanvasArgs) {
    this.element = element;
    this._viewport = viewport;

    this._horizontalRuler = new Ruler({
      direction: 'horizontal',
      element: horizontalRulerElement,
      viewport,
      overlayCanvas: this,
    });
    this._verticalRuler = new Ruler({
      direction: 'vertical',
      element: verticalRulerElement,
      viewport,
      overlayCanvas: this,
    });

    const ctx = this.element.getContext('2d');

    if (!ctx) {
      throw new Error('Failed to get 2d context');
    }

    this._ctx = ctx;

    this._canvasHelper = this._viewport.canvasHelper;

    this._tooltipElement = document.getElementById('guides-tooltip') as HTMLElement;

    this._setGuides(useCreatorStore.getState().canvas.guides);
    this._initListeners();
    this.isReRenderingNeeded = true;
  }

  public render(): void {
    this._canvasHelper.clearCanvas(this._ctx);
    this._canvasHelper.projectCanvasToScene(this._ctx);

    const scene = getActiveScene(toolkit);

    if (!scene) return;

    const size = getSceneSize(scene) as Size | null;

    if (!size) return;

    this._sceneSize = {
      width: size.width,
      height: size.height,
    };

    const gridEnabled =
      useCreatorStore.getState().canvas.grid.enabled && useCreatorStore.getState().canvas.grid.visible;
    const guidesEnabled = useCreatorStore.getState().canvas.guides.enabled;
    const rulersEnabled = useCreatorStore.getState().canvas.rulers.enabled;

    if (rulersEnabled) {
      this._horizontalRuler.render();
      this._verticalRuler.render();
    }

    if (gridEnabled) this._renderGrids();
    if (guidesEnabled) this._renderGuides();

    this._ctx.scale(this._viewport.camera.zoom, this._viewport.camera.zoom);
  }

  public setSelectedGuide(selectedGuide: Guides['selectedGuide']): void {
    // previous value same as new one
    if (this.selectedGuide === selectedGuide) return;

    // reset to default
    if (this.selectedGuide) {
      this._viewport.updateContainerCursor(getCursorStyle('pointer'));
      this._viewport.transformControls.visible = this._transformControlsVisibleToRestore;
    }

    useCreatorStore.getState().canvas.setSelectedGuide(selectedGuide);
    this.selectedGuide = selectedGuide;

    if (selectedGuide) {
      this._viewport.updateContainerCursor(directionToCursorMap[selectedGuide.type]);
      this._transformControlsVisibleToRestore = this._viewport.transformControls.visible;
      this._viewport.transformControls.visible = false;
    }

    this.isReRenderingNeeded = true;
  }

  private _initListeners(): void {
    const ro = new ResizeObserver((entries) => {
      if (entries[0]) {
        this._resize(entries[0].contentRect.width, entries[0].contentRect.height);
        this._horizontalRuler.resize(entries[0].contentRect.width, ViewportConfig.RulerSize);
        this._verticalRuler.resize(ViewportConfig.RulerSize, entries[0].contentRect.height);
      }
    });

    ro.observe(this.element.parentElement as Element);

    useCreatorStore.subscribe(
      (state) => state.canvas.guides,
      (guides) => this._setGuides(guides),
    );

    this.element.addEventListener('pointermove', this._onPointerMove.bind(this));
    this.element.addEventListener('pointerdown', this._onPointerDown.bind(this));
    this.element.addEventListener('pointerup', this._onCanvasPointerUp.bind(this));
    document.addEventListener('pointerup', this._onDocumentPointerUp.bind(this));
  }

  private _onCanvasPointerUp(): void {
    if (this.selectedGuide) {
      this.isDraggingGuide = false;
      this.setSelectedGuide(null);
      this._tooltipElement.classList.add('hidden');
    }
  }

  private _onDocumentPointerUp(): void {
    if (this.selectedGuide && this.isDraggingGuide) {
      useCreatorStore.getState().canvas.removeGuide(this.selectedGuide);
      this._onCanvasPointerUp();
      this.isReRenderingNeeded = true;
    }
  }

  private _onPointerDown(ev: PointerEvent): void {
    if (ev.button === 0 && this.selectedGuide) {
      this.isDraggingGuide = true;
    }
  }

  private _onPointerMove(ev: PointerEvent): void {
    if (
      !this.isDraggingGuide &&
      (this._viewport.gradientControls.enabled ||
        this._viewport.pathControls.penEnabled ||
        this._viewport.selectedToolbar !== ToolType.Move ||
        this._viewport.transformControls.dragging)
    )
      return;

    const coords = unProject(getMouseCoord(ev, this.element), this._viewport.camera).round();

    if (this.isDraggingGuide && this.selectedGuide) {
      const value = this.selectedGuide.type === GuideType.Horizontal ? coords.y : coords.x;

      setGuide({
        id: this.selectedGuide.id,
        type: this.selectedGuide.type,
        value,
      });

      const rulersEnabled = useCreatorStore.getState().canvas.rulers.enabled;
      const offsetPadding = (rulersEnabled ? ViewportConfig.RulerSize : 0) + 8;

      this._tooltipElement.classList.remove('hidden');
      this._tooltipElement.textContent = `${value}`;
      this._tooltipElement.style.left = `${clamp(ev.offsetX, offsetPadding + this._tooltipElement.clientWidth / 2, this.element.clientWidth - 8 - this._tooltipElement.clientWidth / 2)}px`;

      this._tooltipElement.style.top = `${clamp(ev.offsetY - GUIDES_TOOLTIP_HEIGHT - 16, offsetPadding, this.element.clientHeight)}px`;

      this.isReRenderingNeeded = true;

      return;
    }
    const padding = 4 / this._viewport.camera.zoom;

    for (let i = Math.round(coords.x - padding), j = Math.round(coords.y - padding); i < coords.x + padding; i++, j++) {
      const hoveredHorizontalGuide = this._horizontalGuidesReverseMap.get(j);

      if (hoveredHorizontalGuide) {
        this.setSelectedGuide({
          type: GuideType.Horizontal,
          id: hoveredHorizontalGuide,
        });

        return;
      }

      const hoveredVerticalGuide = this._verticalGuidesReverseMap.get(i);

      if (hoveredVerticalGuide) {
        this.setSelectedGuide({
          type: GuideType.Vertical,
          id: hoveredVerticalGuide,
        });

        return;
      }
    }

    this.setSelectedGuide(null);
  }

  private _renderGrids(): void {
    const grid = useCreatorStore.getState().canvas.grid;

    this._ctx.strokeStyle = `rgba(${grid.color.r}, ${grid.color.g}, ${grid.color.b}, ${grid.color.a})`;
    this._ctx.lineWidth = 1;

    const gridWidth = (this._sceneSize.width / grid.columns) * this._canvasHelper.zoomWithPixelRatio;
    const gridHeight = (this._sceneSize.height / grid.rows) * this._canvasHelper.zoomWithPixelRatio;

    this._ctx.beginPath();
    for (let i = 1; i < grid.columns; i++) {
      const x = gridWidth * i;

      this._ctx.moveTo(x, 0);
      this._ctx.lineTo(x, this._sceneSize.height * this._canvasHelper.zoomWithPixelRatio);
    }
    for (let i = 1; i < grid.rows; i++) {
      const y = gridHeight * i;

      this._ctx.moveTo(0, y);
      this._ctx.lineTo(this._sceneSize.width * this._canvasHelper.zoomWithPixelRatio, y);
    }
    this._ctx.stroke();
  }

  private _renderGuides(): void {
    const guides = useCreatorStore.getState().canvas.guides;

    const startX = this._canvasHelper.topLeftCorner.x * this._canvasHelper.zoomWithPixelRatio;
    const endX = this._canvasHelper.bottomRightCorner.x * this._canvasHelper.zoomWithPixelRatio;

    const startY = this._canvasHelper.topLeftCorner.y * this._canvasHelper.zoomWithPixelRatio;
    const endY = this._canvasHelper.bottomRightCorner.y * this._canvasHelper.zoomWithPixelRatio;

    this._ctx.lineWidth = 2;

    const guideColor = `rgba(${guides.color.r}, ${guides.color.g}, ${guides.color.b}, 0.5)`;
    const selectedGuideColor = `rgba(${guides.color.r}, ${guides.color.g}, ${guides.color.b}, 1)`;

    for (const [value, key] of this._horizontalGuidesReverseMap.entries()) {
      this._ctx.beginPath();
      this._ctx.moveTo(startX, value * this._canvasHelper.zoomWithPixelRatio);
      this._ctx.lineTo(endX, value * this._canvasHelper.zoomWithPixelRatio);
      this._ctx.strokeStyle = this.selectedGuide?.id === key ? selectedGuideColor : guideColor;
      this._ctx.stroke();
    }

    for (const [value, key] of this._verticalGuidesReverseMap.entries()) {
      this._ctx.beginPath();
      this._ctx.moveTo(value * this._canvasHelper.zoomWithPixelRatio, startY);
      this._ctx.lineTo(value * this._canvasHelper.zoomWithPixelRatio, endY);
      this._ctx.strokeStyle = this.selectedGuide?.id === key ? selectedGuideColor : guideColor;
      this._ctx.stroke();
    }
  }

  private _resize(width: number, height: number): void {
    this._canvasHelper.resize(this.element, width, height);

    this.isReRenderingNeeded = true;
  }

  private _setGuides(guides: Guides): void {
    this._horizontalGuidesReverseMap.clear();
    this._verticalGuidesReverseMap.clear();

    if (guides.enabled) {
      Object.entries(guides.horizontal).forEach(([key, value]) => {
        this._horizontalGuidesReverseMap.set(value, key);
      });
      Object.entries(guides.vertical).forEach(([key, value]) => {
        this._verticalGuidesReverseMap.set(value, key);
      });
    }
  }
}
