/**
 * Copyright 2023 Design Barn Inc.
 */

import React, { useCallback } from 'react';

import type { DotLottiePlayer, AnimationItem } from '../TestAnimationPlayer';

import { FrameDisplay } from './FrameDisplay';
import { LoopButton } from './LoopButton';
import { PlayButton } from './PlayButton';
import { PlayerSeeker } from './PlayerSeeker';
import { SpeedButton } from './SpeedButton';

import { ColorButton } from '~/components/Elements/TestAnimationDialog/PlayerControls/ColorButton';

interface PlayerControlsProps {
  color: string;
  currentFrame: number | null;
  frameRate: number;
  isLooping: boolean;
  isPlaying: boolean;
  lottieInstance: unknown;
  onStopColor: (color: string) => void;
  player: DotLottiePlayer;
  setCurrentFrame: (frame: number) => void;
  speed: number;
  toggleLooping: () => void;
  togglePlayAnimation: () => void;
  toggleSpeed: () => void;
  totalFrames: number | null;
}

export const PlayerControls: React.FC<PlayerControlsProps> = ({
  color,
  currentFrame,
  frameRate,
  isLooping,
  isPlaying,
  lottieInstance,
  onStopColor,
  player,
  setCurrentFrame,
  speed,
  toggleLooping,
  togglePlayAnimation,
  toggleSpeed,
  totalFrames,
}: PlayerControlsProps) => {
  const handleSeekerChange = useCallback(
    (frame: number) => {
      setCurrentFrame(frame);
      if ((lottieInstance as AnimationItem).isPaused) {
        (lottieInstance as AnimationItem).goToAndStop(frame, true);
      } else {
        player.seek(frame as number);
      }
    },
    [lottieInstance, player, setCurrentFrame],
  );

  return (
    <>
      <div className="m-3 mb-0 flex ">
        <PlayButton isPlaying={isPlaying} togglePlayAnimation={togglePlayAnimation} />
        <PlayerSeeker
          max={totalFrames ? totalFrames - 1 : 1}
          value={currentFrame || 0}
          onChange={(frame) => handleSeekerChange(frame as number)}
        />
      </div>
      <div className="m-3 mt-0 flex justify-between">
        <FrameDisplay currentFrame={currentFrame as number} frameRate={frameRate} />
        <div className="flex gap-1">
          <LoopButton toggleLooping={toggleLooping} isLooping={isLooping} />
          <SpeedButton toggleSpeed={toggleSpeed} speed={speed} />
          <ColorButton color={color} onStopColor={onStopColor} />
        </div>
      </div>
    </>
  );
};
