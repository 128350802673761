/**
 * Copyright 2024 Design Barn Inc.
 */

import { clamp } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { ColorOptional } from '@/ColorPicker/components/ColorPicker/ColorPickerPopover';
import { MouseAction } from '@/ColorPicker/components/ColorPicker/ColorPickerPopover';
import { getHue } from '@/ColorPicker/helpers';
import { useMouseEvents } from '@/ColorPicker/hooks';

interface Props {
  hue: number;
  saturation: number;
  updateRgb: (value: ColorOptional, name: MouseAction) => void;
  value: number;
}

export const Hue: React.FC<Props> = ({ hue, saturation, updateRgb, value }) => {
  const hueRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);

  useEffect(() => {
    if (hueRef.current) {
      setWidth(hueRef.current.clientWidth);
    }
  }, [setWidth]);

  // Note: make sure the picker position is updated along the hue board when it's explicitly set with the mouse
  // even when there is no actual change in hue (ie when grays are selected)
  const setHueWithMouse = useCallback(
    (positionX: number) => {
      setOffsetLeft(clamp(positionX - 6, 0, width));
    },
    [width],
  );

  const mouseDownHandler = useCallback(
    (event: MouseEvent) => {
      const elementX = (event.currentTarget as HTMLDivElement).getBoundingClientRect().x;
      const startX = event.pageX;
      const positionX = startX - elementX - 6;

      const color = getHue(positionX, width, saturation, value);

      // eslint-disable-next-line no-undefined
      color.alpha = undefined;
      updateRgb(color, MouseAction.onStartChange);

      setHueWithMouse(positionX);

      return {
        startX,
        positionX,
      };
    },
    [width, saturation, value, updateRgb, setHueWithMouse],
  );

  const changeObjectPositions = useCallback(
    (event: MouseEvent, { positionX, startX }: { positionX: number; startX: number }) => {
      const moveX = event.pageX - startX;

      const _positionX = positionX + moveX;

      // update value and saturation
      const offsetX = clamp(_positionX, 0, width);
      const color = getHue(offsetX, width, saturation, value);

      return {
        positions: {
          positionX: _positionX,
          startX: event.pageX,
        },
        color: {
          ...color,
          // eslint-disable-next-line no-undefined
          alpha: undefined,
        },
      };
    },
    [width, saturation, value],
  );

  const mouseMoveHandler = useCallback(
    (event: MouseEvent, { positionX, startX }: { positionX: number; startX: number }) => {
      const { color, positions } = changeObjectPositions(event, { startX, positionX });

      // eslint-disable-next-line no-undefined
      color.alpha = undefined;
      updateRgb(color, MouseAction.onChange);
      setHueWithMouse(positionX);

      return positions;
    },
    [changeObjectPositions, setHueWithMouse, updateRgb],
  );

  const mouseUpHandler = useCallback(
    (event: MouseEvent, { positionX, startX }: { positionX: number; startX: number }) => {
      const { color, positions } = changeObjectPositions(event, { startX, positionX });

      updateRgb(color, MouseAction.onEndChange);

      return positions;
    },
    [changeObjectPositions, updateRgb],
  );

  const mouseEvents = useMouseEvents(mouseDownHandler, mouseMoveHandler, mouseUpHandler);

  const onMouseDown = (event: MouseEvent): void => {
    mouseEvents(event);
  };

  useEffect(() => {
    setOffsetLeft((((hue * width) / 360) | 0) - 6);
  }, [hue, width]);

  const pointerStyle = {
    left: `${offsetLeft}px`,
  };

  return (
    <div className="hue" onMouseDown={onMouseDown}>
      <div className="hue-area" ref={hueRef}>
        <div className="picker-pointer" style={pointerStyle} />
      </div>
    </div>
  );
};

export default Hue;
