/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { Close, Info } from '~/assets/icons';
import { useCreatorStore } from '~/store';

export const FetchFailedToLoadWarning: React.FC = () => {
  const [failedToLoad, setFetchExistingFile] = useCreatorStore(
    (state) => [state.project.fetchExistingFile.failedToLoad, state.project.setFetchExistingFile],
    shallow,
  );

  const onRetryClickHandler = useCallback(() => {
    setFetchExistingFile({ status: true, failedToLoad: false });
  }, [setFetchExistingFile]);

  const onCloseClickHandler = useCallback(() => {
    setFetchExistingFile({ failedToLoad: false });
  }, [setFetchExistingFile]);

  if (!failedToLoad) {
    return null;
  }

  return (
    <div className="fixed left-1/2 top-[56px] z-[2] flex -translate-x-1/2 items-center gap-3 rounded-lg bg-[#333C45] p-3 text-[14px] text-[#ffffff]">
      <Info className="h-6 w-6 shrink-0" />
      <span>Couldn’t open the file due to a network error. Please reopen it or try again later.</span>
      <button
        className="h-[32px] shrink-0 rounded-lg border border-white px-3 font-bold text-white"
        onClick={onRetryClickHandler}
      >
        Reopen file
      </button>
      <button>
        <Close
          className="h-6 w-6 shrink-0 cursor-pointer fill-current text-white hover:opacity-50"
          onClick={onCloseClickHandler}
        />
      </button>
    </div>
  );
};
