/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  ColorJSON,
  ColorStop,
  DagNode,
  GradientJSON,
  PercentageJSON,
  RGBAColor,
  ScalarJSON,
  Shape,
} from '@lottiefiles/toolkit-js';
import { Scalar, Color, GroupShape, PropertyType, ShapeLayer, ShapeType } from '@lottiefiles/toolkit-js';
import { colord } from 'colord';
import { clamp, difference } from 'lodash-es';

import type { LayerUIMap } from '../layer';

import type { CurrentFillShape, CurrentStrokeShape, CurrentGFillShape } from '.';
import { stateHistory, AnimatedType, addAppearance, removeKeyFrame } from '.';
import type { NumberResult } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';
import type { SelectedNodeInfo } from '~/store/uiSlice';

export type Appearance<T> = Record<string, T>;

export const AppearanceTypes = [ShapeType.FILL, ShapeType.STROKE, ShapeType.GRADIENT_FILL, ShapeType.TRIM];
export const AppearancePropertyPanel = [
  PropertyPanelType.Fill,
  PropertyPanelType.Stroke,
  PropertyPanelType.GradientFill,
];
export const AppearanceTypeMapping: Record<string, string> = {
  [PropertyPanelType.Fill]: ShapeType.FILL,
  [PropertyPanelType.Stroke]: ShapeType.STROKE,
  [PropertyPanelType.GradientFill]: ShapeType.GRADIENT_FILL,
  [PropertyPanelType.Trim]: ShapeType.TRIM,
};

export interface AppearanceOption {
  isDisabled?: boolean;
  label: string;
  type: ShapeType;
}

export const AppearanceOptions: AppearanceOption[] = [
  {
    label: 'Fill',
    type: ShapeType.FILL,
    isDisabled: false,
  },
  {
    label: 'Stroke',
    type: ShapeType.STROKE,
    isDisabled: false,
  },
];

export type AppStroke = Appearance<CurrentStrokeShape>;
export type AppFill = Appearance<CurrentFillShape>;
export type AppGFill = Appearance<CurrentGFillShape>;

export interface CurrentFillShapeProp {
  currentFillShapes: AppFill;
  currentGFillShapes: AppGFill;
  currentStrokeShapes: AppStroke;
}

export const defaultCurrentAppearance: CurrentFillShapeProp = {
  currentFillShapes: {},
  currentStrokeShapes: {},
  currentGFillShapes: {},
};

export const getAppearanceList = (
  node: DagNode | null,
  oldLayerMap: LayerUIMap,
  layerMap?: LayerUIMap,
): [Record<string, string>, string[]] => {
  const updateDescedantLayer = (
    descendants: string[],
    appearances: Record<string, string>,
    newLayerMap: LayerUIMap,
  ): void => {
    descendants.forEach((descendantId: string) => {
      const descedantLayer = newLayerMap.get(descendantId);

      if (descedantLayer && descedantLayer.isAppearance) {
        const { appearanceType } = descedantLayer;

        appearances[descendantId] = appearanceType as string;
      }
    });
  };
  const appearances: Record<string, string> = {};

  const newLayerMap = layerMap ?? oldLayerMap;

  let diff: string[] = [];

  let group = node;
  const type = (group as Shape | null)?.type;

  if (type === ShapeType.RECTANGLE || type === ShapeType.ELLIPSE || type === ShapeType.STAR) {
    group = node?.parent as DagNode;
  }
  if (group) {
    const nodeLayer = newLayerMap.get(group.nodeId);

    if (layerMap && nodeLayer) {
      // Get new added appearance ids
      const oldNodeLayer = oldLayerMap.get(group.nodeId);

      if (oldNodeLayer) {
        diff = difference(nodeLayer.descendant, oldNodeLayer.descendant);
      }
    }

    if (nodeLayer) {
      const { appearanceType } = nodeLayer;

      if (nodeLayer.isAppearance && AppearanceTypes.includes(appearanceType as ShapeType)) {
        appearances[group.nodeId] = appearanceType as string;
      } else if (nodeLayer.descendant.length > 0) {
        updateDescedantLayer(nodeLayer.descendant, appearances, newLayerMap);
      }
    }
  }

  return [{ ...appearances }, diff];
};

export const getAppearanceListFromNodes = (
  nodes: DagNode[],
  oldLayerMap: LayerUIMap,
  layerMap?: LayerUIMap,
): [Record<string, string>, string[]] => {
  let appearanceList = {};
  let newAdded: string[] = [];

  nodes.forEach((node) => {
    const [list, added] = getAppearanceList(node, oldLayerMap, layerMap);

    appearanceList = { ...appearanceList, ...list };
    newAdded = [...newAdded, ...added];
  });

  return [appearanceList, newAdded];
};

const getFirstColorAppearance = (
  type: ShapeType,
  shapes: Array<CurrentFillShape | CurrentStrokeShape>,
): Color | null => {
  if (shapes.length === 0) {
    return null;
  }

  if (type === ShapeType.FILL) {
    const colorValue = (shapes[0] as CurrentFillShape).animatedProperties?.[PropertyType.FILL_COLOR].value as ColorJSON;

    return new Color(colorValue.r, colorValue.g, colorValue.b, colorValue.a);
  }

  if (type === ShapeType.STROKE) {
    const colorValue = (shapes[0] as CurrentStrokeShape).animatedProperties?.[PropertyType.STROKE_COLOR]
      .value as ColorJSON;

    return new Color(colorValue.r, colorValue.g, colorValue.b, colorValue.a);
  }

  return null;
};

export const addAppearanceMultiselect = (
  selectedNodesInfo: SelectedNodeInfo[],
  type: ShapeType,
  ids: string[],
): void => {
  stateHistory.beginAction();

  const shapes = ids
    .map((id) => useCreatorStore.getState().toolkit.getShape(type, id) as CurrentFillShape | CurrentStrokeShape)
    .filter((shape) => shape.id);
  const color = getFirstColorAppearance(type, shapes);
  const props = type === ShapeType.FILL ? 'fillProps' : 'strokeProps';

  selectedNodesInfo.forEach((nodeInfo) => {
    const node = useCreatorStore.getState().toolkit.getNodeByIdOnly(nodeInfo.nodeId);

    if (!(node instanceof ShapeLayer || node instanceof GroupShape)) {
      return;
    }

    // If the node already has the appearance type, update its color
    // If not, add the appearance to the node
    if (node.colors[props].length > 0) {
      node.colors[props].forEach((fill) => {
        if (color instanceof Color) fill.setValue(color);
      });

      return;
    }

    addAppearance(node, type, color);
  });

  stateHistory.endAction();
};

const getEmitterEvent = (type: AnimatedType): EmitterEvent => {
  const animatedTypeToEmitterEventMap = {
    [AnimatedType.FILL_COLOR]: EmitterEvent.SHAPE_FILL_COLOR_UPDATED,
    [AnimatedType.FILL_OPACITY]: EmitterEvent.SHAPE_FILL_OPACITY_UPDATED,
    [AnimatedType.STROKE_COLOR]: EmitterEvent.ANIMATED_SHAPE_STROKE_COLOR_UPDATED,
    [AnimatedType.STROKE_OPACITY]: EmitterEvent.ANIMATED_SHAPE_STROKE_OPACITY_UPDATED,
    [AnimatedType.STROKE_WIDTH]: EmitterEvent.ANIMATED_SHAPE_STROKE_WIDTH_UPDATED,
    [AnimatedType.GRADIENT]: EmitterEvent.ANIMATED_SHAPE_GRADIENT_COLOR_UPDATED,
    [AnimatedType.GRADIENT_OPACITY]: EmitterEvent.ANIMATED_SHAPE_GRADIENT_OPACITY_UPDATED,
  };

  return animatedTypeToEmitterEventMap[type as keyof typeof animatedTypeToEmitterEventMap];
};

export const updateAppearance = (
  setAnimatedValue: (type: AnimatedType, value: number[], id: string) => void,
  shapes: Array<CurrentFillShape | CurrentStrokeShape | CurrentGFillShape>,
  type: AnimatedType,
  result: NumberResult,
): void => {
  if (!result.value && result.value !== 0) return;

  const addToPreviousAppearance = (result.dragged || result.arrowKeyPressed) ?? false;

  if (type === AnimatedType.FILL_OPACITY || type === AnimatedType.STROKE_OPACITY) {
    shapes.forEach((shape) => {
      const currentValue =
        type === AnimatedType.FILL_OPACITY ? (shape as CurrentFillShape).opacity : (shape as CurrentStrokeShape).o;

      if (currentValue === result.value) return;

      const newValue = addToPreviousAppearance ? clamp(currentValue + (result.change as number), 0, 100) : result.value;

      setAnimatedValue(type, [newValue], shape.id as string);
    });
  }

  if (type === AnimatedType.STROKE_WIDTH) {
    shapes.forEach((shape) => {
      const currentValue = (shape as CurrentStrokeShape).width;

      const newValue = addToPreviousAppearance ? Math.max(currentValue + (result.change as number), 0) : result.value;

      setAnimatedValue(type, [newValue], shape.id as string);
    });
  }

  emitter.emit(getEmitterEvent(type));
};

export const updateColor = (
  setAnimatedValue: (type: AnimatedType, value: number[], id: string) => void,
  ids: string[],
  type: AnimatedType,
  hexColor: string,
): void => {
  if (!hexColor) return;

  if (type === AnimatedType.FILL_COLOR || type === AnimatedType.STROKE_COLOR) {
    const rgba = colord(hexColor).toRgb();

    ids.forEach((appearanceId) => {
      setAnimatedValue(type, [rgba.r, rgba.g, rgba.b, rgba.a], appearanceId);
    });
  }

  emitter.emit(getEmitterEvent(type));
};

const formatValue = (type: PropertyType, value: unknown): number[] | ColorStop[] => {
  if (type === PropertyType.FILL_COLOR || type === PropertyType.STROKE_COLOR) {
    const color = value as RGBAColor;

    return [color.r, color.g, color.b, color.a];
  }

  if (type === PropertyType.OPACITY) {
    return [(value as PercentageJSON).pct];
  }

  if (type === PropertyType.STROKE_WIDTH) {
    return [(value as ScalarJSON).value];
  }

  if (type === PropertyType.GRADIENT) {
    return (value as GradientJSON).colors.map((color) => ({
      color: new Color(color.r, color.g, color.b, color.a),
      stop: new Scalar(color.offset),
    }));
  }

  return [];
};

const getKeyframeType = (type: AnimatedType): string => {
  // note: declaring the const outside this function causes an 'AnimatedType is not defined' error
  const animatedTypeToKeyframeMap = {
    [AnimatedType.FILL_COLOR]: 'colorCurrentKeyframe',
    [AnimatedType.FILL_OPACITY]: 'opacityCurrentKeyframe',
    [AnimatedType.STROKE_COLOR]: 'colorCurrentKeyframe',
    [AnimatedType.STROKE_OPACITY]: 'opacityCurrentKeyframe',
    [AnimatedType.STROKE_WIDTH]: 'widthCurrentKeyframe',
    [AnimatedType.GRADIENT]: 'colorCurrentKeyframe',
    [AnimatedType.GRADIENT_OPACITY]: 'opacityCurrentKeyframe',
  };

  return animatedTypeToKeyframeMap[type as keyof typeof animatedTypeToKeyframeMap];
};

const getPropertyType = (type: AnimatedType): PropertyType => {
  const animatedTypeToPropertyTypeMap = {
    [AnimatedType.FILL_COLOR]: PropertyType.FILL_COLOR,
    [AnimatedType.FILL_OPACITY]: PropertyType.OPACITY,
    [AnimatedType.STROKE_COLOR]: PropertyType.STROKE_COLOR,
    [AnimatedType.STROKE_OPACITY]: PropertyType.OPACITY,
    [AnimatedType.STROKE_WIDTH]: PropertyType.STROKE_WIDTH,
    [AnimatedType.GRADIENT]: PropertyType.GRADIENT,
    [AnimatedType.GRADIENT_OPACITY]: PropertyType.OPACITY,
  };

  return animatedTypeToPropertyTypeMap[type as keyof typeof animatedTypeToPropertyTypeMap];
};

export const toggleAppearanceKeyframe = (
  type: AnimatedType,
  shapes: Array<CurrentFillShape | CurrentStrokeShape | CurrentGFillShape>,
): void => {
  stateHistory.beginAction();

  const addAnimatedValue = useCreatorStore.getState().toolkit.addAnimatedValue;

  const keyframeType = getKeyframeType(type);
  const propertyType = getPropertyType(type);

  const allKeyframesActive = shapes.every((shape) => shape[keyframeType]);
  const allKeyframesInactive = shapes.every((shape) => !shape[keyframeType]);
  const addKeyframes = !allKeyframesActive && !allKeyframesInactive;

  if (allKeyframesActive) {
    shapes.forEach((shape) => removeKeyFrame(shape[keyframeType] as string));
  }

  if (allKeyframesInactive) {
    // add keyframe to all layers
    shapes.forEach((shape) => {
      if (!shape[keyframeType] && shape.animatedProperties) {
        addAnimatedValue(
          type,
          formatValue(propertyType, shape.animatedProperties[propertyType].value),
          shape.id as string,
        );
      }
    });
  }

  if (addKeyframes) {
    // add keyframes to layers that don't have them
    shapes.forEach((shape) => {
      if (!(keyframeType in shape) && shape.animatedProperties) {
        addAnimatedValue(
          type,
          formatValue(propertyType, shape.animatedProperties[propertyType].value),
          shape.id as string,
        );
      }
    });
  }

  stateHistory.endAction();

  emitter.emit(getEmitterEvent(type));
};
