/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Placement } from '@floating-ui/react-dom-interactions';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PanelButton } from '../../Elements/Button/BarButton';

import type { ToolType } from '~/data/constant';
import { useCreatorStore } from '~/store';

interface ToolbarItemProps {
  activeIcon: JSX.Element;
  inactiveIcon: JSX.Element;
  tooltipProps: {
    content: string;
    placement: Placement;
    shortcut?: string;
  };
  type: ToolType;
}

export const ToolbarItem: React.FC<ToolbarItemProps> = (props) => {
  const [currentTool, setCurrentTool] = useCreatorStore(
    (state) => [state.ui.currentTool, state.ui.setCurrentTool],
    shallow,
  );

  const handleOnClick: (type: ToolType) => void = useCallback(
    (type) => {
      setCurrentTool(type);
    },
    [setCurrentTool],
  );

  return (
    <PanelButton
      key={props.type}
      activeIcon={props.activeIcon}
      inactiveIcon={props.inactiveIcon}
      tooltipProps={props.tooltipProps}
      isSelected={currentTool === props.type}
      onClick={() => handleOnClick(props.type)}
    />
  );
};
