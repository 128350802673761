/**
 * Copyright 2024 Design Barn Inc.
 */

export enum AlignDirection {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top',
}

export enum DistributionDirection {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
