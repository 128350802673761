/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { GrowthBook, GrowthBookProvider as GBProvider } from '@growthbook/growthbook-react';
import React, { useEffect, useContext, useCallback } from 'react';

import { GROWTHBOOK_ENDPOINT } from '~/config';
import { GB_CONSTANT } from '~/config/growthbook';

export const UsersnapContext = React.createContext(null);

interface Props {
  children?: React.ReactNode;
}

// Create a GrowthBook instance
export const growthbook = new GrowthBook();

export const GrowthBookProvider: React.FC<Props> = ({ children }) => {
  const fetchGrowthbook = useCallback(async () => {
    try {
      const data = await fetch(GROWTHBOOK_ENDPOINT);
      const dataJSON = await data.json();

      growthbook.setFeatures(dataJSON.features);
    } catch (error) {
      // Fallback data in case of error
      // Only features known to be stable should be added here
      const fallbackData = [
        GB_CONSTANT.SHOW_DUPLICATOR_PLUGIN,
        GB_CONSTANT.SHOW_PROMPT_VECTOR_PLUGIN,
        GB_CONSTANT.SHOW_KINETIC_TYPOGRAPHY_PLUGIN,
        GB_CONSTANT.SHOW_MOTION_COPILOT_PLUGIN,
        GB_CONSTANT.SHOW_MOTION_PRESETS_PLUGIN,
        GB_CONSTANT.SHOW_CREATE_BREAK_SCENE,
      ].reduce(
        (acc, key) => {
          acc[key] = { defaultValue: true };

          return acc;
        },
        {} as Parameters<typeof growthbook.setFeatures>[0],
      );

      growthbook.setFeatures(fallbackData);

      // re-throw the error so that we can track the occurences in bugsnag
      throw error;
    }
  }, []);

  useEffect(() => {
    // Load feature definitions from API
    fetchGrowthbook();
  }, [fetchGrowthbook]);

  return <GBProvider growthbook={growthbook}>{children}</GBProvider>;
};

export function useUsersnapApi() {
  return useContext(UsersnapContext);
}
