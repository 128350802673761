/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AnimatedPropertyJSON, CubicBezierShape, DagNodeType, ShapeType } from '@lottiefiles/toolkit-js';
import produce from 'immer';
import { isEqual } from 'lodash-es';
import type { Vector3 } from 'three';
import type { StateCreator } from 'zustand';

import { PropertyPanelType, ShapeTypes, DefaultPivotAlignment } from './constant';
import type { GlobalModalConstant } from './constant/modal';

import type { StoreSlice } from '.';
import type { LibraryType } from '~/data/constant';
import { ToolType } from '~/data/constant';
import type { VertexType } from '~/features/canvas';
// eslint-disable-next-line no-restricted-imports
import type { IDragToCanvas } from '~/features/canvas/components/DragToConvasContainer';
// eslint-disable-next-line no-restricted-imports
import type { CreatorClipboard } from '~/features/canvas/viewport/editor';
import { CURRENT_PLAYER, CURRENT_PLAYER_VERSION } from '~/lib/features-checker';
import { getPlayerHash } from '~/lib/features-checker/hash';
import { layerMap } from '~/lib/layer';
import {
  getCurrentTransform,
  getPropertyPanelType,
  getAppearanceList,
  updateLayerHighlight,
  removeLayerHighlight,
  updatePropertyStates,
} from '~/lib/toolkit';
import type { CurrentTransformProperty } from '~/lib/toolkit';

export interface LoaderProps {
  description?: string | null;
  isLoading: boolean;
  title?: string | null;
}

export interface AlertProps {
  advanced?: {
    alertId: string;
    runOnceEveryPageRefresh?: boolean;
  };
  alertColor?: string | null;
  handle?: (...args: any[]) => unknown;
  handleText?: string | null;
  icon?: 'error' | 'success' | null;
  iconClass?: string | null;
  isClosable?: boolean;
  text: string | null;
  timeout?: number | null;
}

export interface IGlobalModal {
  options?: Record<string, string | boolean>;
  state: GlobalModalConstant | null;
}

export interface TransformInfo {
  opacity?: number;
  pivot?: Vector3 | null;
  pivotOffset?: Vector3;
  position?: Vector3;
  rotation?: number;
  scale?: Vector3;
}

export interface FeatureCheckerProp {
  skip?: boolean;
  unsupportedFeatures?: string[];
}

export enum AnimationLoaderStatus {
  Empty = 'Empty',
  Loaded = 'Loaded',
  Loading = 'Loading',
  Reverted = 'Reverted',
}

export enum GlobalCursorType {
  DEFAULT = 'cursor-default',
  GRAB = 'cursor-grab',
  GRABBING = 'cursor-grabbing',
  HAND = 'cursor-hand',
  MOVE = 'cursor-move',
  NOT_ALLOWED = 'cursor-not-allowed',
  POINTER = 'cursor-pointer',
  RESIZE = 'cursor-ew-resize',
  SCALEY = 'cursor-scaleY',
}

export enum GlobalCursorUpdate {
  DEFAULT = 'default',
  GRAB = 'grab',
  GRABBING = 'grabbing',
  HAND = 'hand',
  INDEX = 'index',
  MOVE = 'move',
  NOT_ALLOWED = 'not-allowed',
  POINTER = 'pointer',
  RESIZE = 'ew-resize',
  SCALEY = 'scaleY',
}

export interface AnimationLoader {
  avatarUrl?: string | null;
  name?: string | null;
  status: AnimationLoaderStatus;
  url: string | null;
  username?: string | null;
}

export interface SelectedNodeInfo {
  nodeId: string;
  nodeTransform: CurrentTransformProperty;
  nodeType: DagNodeType;
  propertyPanel: PropertyPanelType;
}

export enum AlignPivotDirection {
  Bottom = 'bottom',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Top = 'top',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
}

const { Bottom, BottomLeft, BottomRight, Center, Left, Right, Top, TopLeft, TopRight } = AlignPivotDirection;

const moveItemToEnd = <T>(array: T[], item: T): T[] => {
  return array.filter((i) => i !== item).concat(item);
};

export interface AnchorPointsActive {
  [Bottom]: boolean;
  [BottomLeft]: boolean;
  [BottomRight]: boolean;
  [Center]: boolean;
  [Left]: boolean;
  [Right]: boolean;
  [Top]: boolean;
  [TopLeft]: boolean;
  [TopRight]: boolean;
}

export interface WindowProps {
  height?: number;
  width?: number;
}

export interface CopiedMask {
  bezier: CubicBezierShape;
  name: string;
  nodeType: ShapeType;
  shapeAnimated: AnimatedPropertyJSON | null;
}

export interface UiSlice {
  ui: {
    addToFloatingWindows(id: string): void;
    addToSelectedNodes(toolkitIds: string[], replaceCurrentSelection?: boolean, force?: boolean): void;
    alert: AlertProps;
    anchorPointByCursor: boolean;
    anchorPointsActive: Record<string, Record<string, boolean>>;
    animationLoader: AnimationLoader;
    appearanceList: Record<string, string>;
    canvasHoveredNodeId: string;
    clipboard: CreatorClipboard | null;
    copiedMask: CopiedMask | null;
    creatorHash: string;
    currentLibrary: LibraryType | null;
    currentPropertyPanel: PropertyPanelType;
    currentTool: ToolType;
    dragToCanvas: IDragToCanvas;
    featureChecker: FeatureCheckerProp;
    floatingPluginIds: string[];
    floatingWindows: string[];
    focusedNodeIds: string[];
    globalCursor: string;
    globalModal: IGlobalModal | null;
    hasShownOnetimeTooltip: boolean;
    isAddingToolkitListener: boolean;
    isTimelineFocused: boolean;
    isUIMinimized: boolean;
    isUploadModalOpened: boolean;
    lastSelectedPenTool: ToolType.Pen | ToolType.ConvertVertex;
    lastSelectedShape: ShapeTypes;
    loader: LoaderProps;
    newPluginModalOpen: boolean;
    onboarding: {
      showOnboarding: boolean;
      showOnboardingNudge: boolean;
    };
    openHiddenPlayer: boolean;
    pathPointVertexTypes: VertexType[];
    pivotVisible: boolean;
    pluginBrowserOpen: boolean;
    removeFloatingPluginId(id: string): void;
    removeFromFloatingWindows(id: string): void;
    removeSelectedNodes(toolkitIds?: string[]): void;
    resetSelection(): void;
    rightSideBarPanel: string;
    saveFromHotKey: boolean;
    scaleRatioLocked: boolean;
    selectedIdsAfterCreated: string[] | null;
    selectedNodesInfo: SelectedNodeInfo[];

    selectedPathPointIndexes: number[];
    setAlert(data: AlertProps): void;
    setAnchorPointByCursor(status: boolean): void;
    setAnchorPointsActive(toolkitId: string, data: Record<string, boolean> | null, makeItNull?: boolean): void;
    setAnimationLoader(data: AnimationLoader): void;
    setAsTopWindow(id: string): void;
    setCanvasHoveredNodeId(id: string): void;
    setClipboard(data: CreatorClipboard): void;
    setCurrentLibrary(lib: LibraryType | null): void;
    setCurrentTool(tool: ToolType): void;
    setDragToCanvas(info: IDragToCanvas): void;
    setFeatureChecker(data: FeatureCheckerProp): void;
    setFloatingPluginId(id: string): void;
    setFocusedNodeIds: (ids: string[], focus: boolean) => void;
    setGlobalCursor(cursor: string): void;
    setGlobalModal(modalState: GlobalModalConstant | null, options?: unknown): void;
    setHasShownOnetimeTooltip(): void;
    setIsAddingToolkitListener(status: boolean): void;
    setIsTimelineFocused(status: boolean): void;
    setIsUIMinimized(minimized: boolean): void;
    setIsUploadModalOpened(open: boolean): void;
    setLastSelectedPenTool(tool: ToolType.Pen | ToolType.ConvertVertex): void;
    setLastSelectedShape(shapeType: ShapeTypes): void;
    setLoader(info: LoaderProps): void;
    setMaskCopied(masks: CopiedMask | null): void;
    setNewPluginModalOpen(open: boolean): void;
    setOpenHiddenPlayer(open: boolean): void;
    setPathPointVertexTypes(types: VertexType[]): void;
    setPivotVisibility(visible: boolean): void;
    setPluginBrowserOpen(open: boolean): void;
    setPropertyPanel(type: PropertyPanelType): void;
    setRightSideBarPanel(panel: string): void;
    setSaveFromHotKey(status: boolean): void;
    setScaleRatioLocked(isLocked: boolean): void;
    setSelectedIdsAfterCreated(ids: string[]): void;
    setSelectedPathPointIndexes(indexes: number[]): void;
    setShowOnboarding: (value: boolean) => void;
    setShowOnboardingNudge: (value: boolean) => void;

    setSizeRatioLocked(isLocked: boolean): void;
    setTestAnimationOpen(open: boolean): void;
    setWindow(input: WindowProps): void;
    setZoomPercentage(percentage: number): void;
    sizeRatioLocked: boolean;
    testAnimationOpen: boolean;
    window: WindowProps;
    zoomPercentage: number;
  };
}

export const createUiSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  UiSlice
> = (set, get) => ({
  ui: {
    window: { height: window.innerHeight, width: window.innerWidth },
    globalCursor: 'cursor-default',
    globalModal: null,
    alert: {
      text: null,
    },
    lastSelectedShape: ShapeTypes.Rectangle,
    loader: {
      isLoading: false,
    },
    copiedMask: null,
    currentPropertyPanel: PropertyPanelType.Composition,
    currentLibrary: null,
    currentTool: ToolType.Move,
    lastSelectedPenTool: ToolType.Pen,
    canvasHoveredNodeId: '',
    focusedNodeIds: [],
    selectedNodesInfo: [],
    selectedPathPointIndexes: [],
    pathPointVertexTypes: [],
    selectedIdsAfterCreated: null,
    testAnimationOpen: false,
    newPluginModalOpen: false,
    pluginBrowserOpen: false,
    zoomPercentage: 100,
    appearanceList: {},
    hasShownOnetimeTooltip: false,
    scaleRatioLocked: false,
    onboarding: {
      showOnboarding: false,
      showOnboardingNudge: false,
    },
    sizeRatioLocked: false,
    pivotVisible: true,
    openHiddenPlayer: false,
    isAddingToolkitListener: false,
    isTimelineFocused: false,
    isUIMinimized: false,
    isUploadModalOpened: false,
    rightSideBarPanel: 'property',
    dragToCanvas: {
      dragging: false,
      component: null,
      x: null,
      y: null,
      height: null,
      width: null,
    },
    clipboard: null,
    setClipboard: (data: CreatorClipboard) => {
      set((state) => {
        state.ui.clipboard = {
          ...state.ui.clipboard,
          ...data,
        };
      });
    },
    creatorHash:
      getPlayerHash(CURRENT_PLAYER, CURRENT_PLAYER_VERSION).hex ||
      '0xe001a015fff0043fc181f8006701910411060012000302012010480e072600a180f1900fee3fe6fc7fc00000837cc704f9effffffffffffffffffffffffffff',
    setDragToCanvas: (info: IDragToCanvas) => {
      set((state) => {
        state.ui.dragToCanvas = {
          ...state.ui.dragToCanvas,
          ...info,
        };
      });
    },
    setRightSideBarPanel: (panel: string) => {
      set((state) => {
        state.ui.rightSideBarPanel = panel;
      });
    },
    featureChecker: {
      skip: false,
      unsupportedFeatures: [],
    },
    animationLoader: {
      status: AnimationLoaderStatus.Empty,
      url: null,
    },
    saveFromHotKey: false,
    setSaveFromHotKey: (status: boolean) => {
      set((state) => {
        return produce(state, (draft) => {
          draft.ui.saveFromHotKey = status;
        });
      });
    },
    setShowOnboarding: (value: boolean) => {
      set((state) => {
        state.ui.onboarding.showOnboarding = value;
      });
    },
    setShowOnboardingNudge: (value: boolean) => {
      set((state) => {
        state.ui.onboarding.showOnboardingNudge = value;
      });
    },
    floatingPluginIds: [],
    setWindow: (input: WindowProps) => {
      set((state) => {
        return produce(state, (draft) => {
          draft.ui.window = { ...input };
        });
      });
    },
    setGlobalCursor: (cursor: string) => {
      set((state) => {
        return produce(state, (draft) => {
          draft.ui.globalCursor = cursor;
        });
      });
    },
    anchorPointsActive: {},
    anchorPointByCursor: false,
    setAnchorPointByCursor: (status: boolean) => {
      set((state) => {
        state.ui.anchorPointByCursor = status;
      });
    },
    setAnchorPointsActive: (toolkitId: string, data: Record<string, boolean> | null, makeItNull: boolean = false) => {
      set((state) => {
        const anchorPoint = state.ui.anchorPointsActive[toolkitId];

        if (anchorPoint && data) {
          const updatedObject = Object.fromEntries(Object.keys(anchorPoint).map((key) => [key, false]));

          state.ui.anchorPointsActive[toolkitId] = {
            ...updatedObject,
            ...data,
          };
        } else if (makeItNull) {
          const updatedObject = Object.fromEntries(Object.keys(DefaultPivotAlignment).map((key) => [key, false]));

          state.ui.anchorPointsActive[toolkitId] = {
            ...updatedObject,
          };
        } else {
          state.ui.anchorPointsActive[toolkitId] = { ...DefaultPivotAlignment };
        }
      });
    },

    addToSelectedNodes: (toolkitIds: string[], removeCurrentSelection: boolean, force = false) => {
      const uniqueToolkitIds = [...new Set(toolkitIds)];

      if (
        isEqual(
          uniqueToolkitIds,
          get().ui.selectedNodesInfo.map((node) => node.nodeId),
        ) &&
        !force
      ) {
        return;
      }

      // Remove old selection
      // note: calling get().ui.removeSelectedNodes() doesn't seem to update state properly
      if (removeCurrentSelection && get().ui.selectedNodesInfo.length) {
        set((state) => {
          state.ui = produce(state.ui, (draft) => {
            const selectedNodes = draft.selectedNodesInfo;

            selectedNodes.forEach((node) => removeLayerHighlight(state, node.nodeId));

            draft.selectedNodesInfo = [];
          });
        });
      }

      set((state) => {
        return produce(state, (draft) => {
          // Remove existing nodes with the same ids
          draft.ui.selectedNodesInfo = draft.ui.selectedNodesInfo.filter(
            (node) => !uniqueToolkitIds.includes(node.nodeId),
          );

          // For each id, create a SelectedNodeInfo object
          for (const id of uniqueToolkitIds) {
            updateLayerHighlight(draft, id);

            const node = draft.toolkit.getNodeByIdOnly(id);

            const nodeTransform = getCurrentTransform(node);
            const propertyPanel = getPropertyPanelType(id);

            const [appearanceList] = getAppearanceList(node, layerMap);

            const selectedNodeInfo: SelectedNodeInfo = {
              nodeId: id,
              nodeTransform,
              nodeType: node?.nodeType as unknown as DagNodeType,
              propertyPanel,
            };

            draft.ui.selectedNodesInfo.push(selectedNodeInfo);
            draft.ui.appearanceList = appearanceList;
          }

          updatePropertyStates(draft);
        });
      });
    },

    removeSelectedNodes: (toolkitIds?: string[]) => {
      if (get().ui.selectedNodesInfo.length === 0) {
        return;
      }

      set((state) => {
        return produce(state, (draft) => {
          const selectedNodes = draft.ui.selectedNodesInfo;
          const selectedNodeIds = selectedNodes.map((node) => node.nodeId);

          const idsToRemove = toolkitIds ?? selectedNodeIds;

          idsToRemove.forEach((id) => removeLayerHighlight(draft, id));
          draft.ui.selectedNodesInfo = selectedNodes.filter((node) => !idsToRemove.includes(node.nodeId));

          // Update the property panel
          if (draft.ui.selectedNodesInfo.length === 1 || draft.ui.selectedNodesInfo.length === 0) {
            const node = draft.toolkit.getNodeByIdOnly(draft.ui.selectedNodesInfo[0]?.nodeId as string);
            const [appearanceList] = getAppearanceList(node, layerMap);

            draft.ui.appearanceList = appearanceList;
          }

          updatePropertyStates(draft);
        });
      });
    },

    setFeatureChecker: (data: FeatureCheckerProp) => {
      set((state) => {
        state.ui.featureChecker = {
          ...state.ui.featureChecker,
          ...data,
        };
      });
    },
    setAnimationLoader: (data: AnimationLoader) => {
      set((state) => {
        state.ui.animationLoader = {
          ...state.ui.animationLoader,
          ...data,
        };
      });
    },
    setIsAddingToolkitListener: (status: boolean) => {
      set((state) => {
        state.ui.isAddingToolkitListener = status;
      });
    },
    setIsTimelineFocused: (status: boolean) => {
      set((state) => {
        state.ui.isTimelineFocused = status;
      });
    },
    setIsUIMinimized: (minimized: boolean) => {
      set((state) => {
        state.ui.isUIMinimized = minimized;
        state.timeline.visible = !minimized;
      });
    },
    setIsUploadModalOpened: (open: boolean) => {
      set((state) => {
        state.ui.isUploadModalOpened = open;
      });
    },
    setGlobalModal: (modalState: GlobalModalConstant | null, options: Record<string, string | boolean>) => {
      set((state) => {
        state.ui.globalModal = {
          state: modalState,
          options,
        };
      });
    },
    setCurrentLibrary: (lib: LibraryType | null) =>
      set((state) => {
        state.ui.currentLibrary = lib;
      }),
    setLastSelectedShape: (shapeType: ShapeTypes) =>
      set((state) => {
        state.ui.lastSelectedShape = shapeType;
      }),
    setCurrentTool: (tool: ToolType) =>
      set((state) => {
        state.ui.currentTool = tool;
      }),
    setLastSelectedPenTool: (tool) =>
      set((state) => {
        state.ui.lastSelectedPenTool = tool;
      }),
    setZoomPercentage: (percentage: number) =>
      set((state) => {
        state.ui.zoomPercentage = percentage;
      }),
    setPropertyPanel: (type: PropertyPanelType) => {
      set((state) => {
        state.ui.currentPropertyPanel = type;
      });
    },
    setNewPluginModalOpen: (open: boolean) => {
      set((state) => {
        state.ui.newPluginModalOpen = open;
      });
    },
    setPluginBrowserOpen: (open: boolean) => {
      set((state) => {
        state.ui.pluginBrowserOpen = open;
      });
    },
    setTestAnimationOpen: (open: boolean) => {
      set((state) => {
        state.ui.testAnimationOpen = open;
      });
    },
    setCanvasHoveredNodeId: (id: string) => {
      set((state) => {
        state.ui.canvasHoveredNodeId = id;
      });
    },
    setSizeRatioLocked: (isLocked: boolean) => {
      set((state) => {
        state.ui.sizeRatioLocked = isLocked;
      });
    },
    setSelectedIdsAfterCreated: (ids: string[]) => {
      set((state) => {
        state.ui.selectedIdsAfterCreated = [...ids];
      });
    },
    setSelectedPathPointIndexes: (indexes: number[]) => {
      set((state) => {
        state.ui.selectedPathPointIndexes = indexes;
      });
    },
    setPathPointVertexTypes: (types: VertexType[]) => {
      set((state) => {
        state.ui.pathPointVertexTypes = types;
      });
    },
    setScaleRatioLocked: (isLocked: boolean) => {
      set((state) => {
        state.ui.scaleRatioLocked = isLocked;
      });
    },
    setPivotVisibility: (visible: boolean) => {
      set((state) => {
        state.ui.pivotVisible = visible;
      });
    },
    resetSelection: () => {
      set((state) => {
        state.toolkit.currentFrame = 0;
        state.ui.currentPropertyPanel = PropertyPanelType.Composition;
        state.ui.removeSelectedNodes();
      });
    },
    setLoader: (obj: LoaderProps) => {
      set((state) => {
        state.ui.loader = {
          ...{
            isLoading: false,
            title: null,
            description: null,
          },
          ...obj,
        };
      });
    },
    setMaskCopied: (mask: CopiedMask) => {
      set((state) => {
        state.ui.copiedMask = mask;
      });
    },
    setAlert: (obj: AlertProps) => {
      set((state) => {
        state.ui.alert = {
          ...{
            alertColor: null,
            handleText: null,
            text: null,
            timeout: null,
            icon: null,
            iconClass: null,
          },
          ...obj,
        };
      });
    },
    setHasShownOnetimeTooltip: () => {
      set((state) => {
        state.ui.hasShownOnetimeTooltip = true;
      });
    },
    setOpenHiddenPlayer: (open: boolean) => {
      set((state) => {
        state.ui.openHiddenPlayer = open;
      });
    },
    setFloatingPluginId: (id: string) => {
      set((state) => {
        if (state.ui.floatingPluginIds.includes(id)) {
          const element = document.getElementById(`floating-plugin-content-${id}`);

          if (element) {
            const windowId = element.dataset['windowId'];

            if (windowId) {
              state.ui.floatingWindows = moveItemToEnd(state.ui.floatingWindows, windowId);
            }
          }
        } else {
          state.ui.floatingPluginIds.push(id);
        }
      });
    },
    removeFloatingPluginId: (id: string) => {
      set((state) => {
        state.ui.floatingPluginIds = state.ui.floatingPluginIds.filter((pluginId) => pluginId !== id);
      });
    },
    setFocusedNodeIds: (ids: string[], focus: boolean) => {
      set((state) => {
        if (focus) {
          state.ui.focusedNodeIds = [...new Set([...state.ui.focusedNodeIds, ...ids])];
        } else {
          state.ui.focusedNodeIds = [...state.ui.focusedNodeIds.filter((id) => !ids.includes(id))];
        }
      });
    },
    floatingWindows: [],
    addToFloatingWindows: (id: string) => {
      set((state) => {
        state.ui.floatingWindows.push(id);
      });
    },
    removeFromFloatingWindows: (id: string) => {
      set((state) => {
        state.ui.floatingWindows = state.ui.floatingWindows.filter((windowId) => windowId !== id);
      });
    },
    setAsTopWindow: (id: string) => {
      set((state) => {
        state.ui.floatingWindows = moveItemToEnd(state.ui.floatingWindows, id);
      });
    },
  },
});
