/**
 * Copyright 2024 Design Barn Inc.
 */

// reference: https://en.wikipedia.org/wiki/HSL_and_HSV#HSL_to_RGB
export default function getRgbByHue(hue: number): {
  blue: number;
  green: number;
  red: number;
} {
  let chroma = 1;
  const hueSegment = hue / 60;
  let secondLargestComponent = chroma * (1 - Math.abs((hueSegment % 2) - 1));
  const minValue = 0;
  const precision = 255;
  let r = 0;
  let g = 0;
  let b = 0;

  chroma = ((chroma + minValue) * precision) | 0;
  secondLargestComponent = ((secondLargestComponent + minValue) * precision) | 0;

  if (hueSegment >= 0 && hueSegment < 1) {
    r = chroma | 0;
    g = secondLargestComponent | 0;
    b = minValue | 0;
  }
  if (hueSegment >= 1 && hueSegment < 2) {
    r = secondLargestComponent | 0;
    g = chroma | 0;
    b = minValue | 0;
  }
  if (hueSegment >= 2 && hueSegment < 3) {
    r = minValue | 0;
    g = chroma | 0;
    b = secondLargestComponent | 0;
  }
  if (hueSegment >= 3 && hueSegment < 4) {
    r = minValue | 0;
    g = secondLargestComponent | 0;
    b = chroma | 0;
  }
  if (hueSegment >= 4 && hueSegment < 5) {
    r = secondLargestComponent | 0;
    g = minValue | 0;
    b = chroma | 0;
  }
  if (hueSegment >= 5 && hueSegment <= 6) {
    r = chroma | 0;
    g = minValue | 0;
    b = secondLargestComponent | 0;
  }

  return {
    red: r,
    green: g,
    blue: b,
  };
}
