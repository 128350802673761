/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ScalarJSON, Scene, SizeJSON, VectorJSON, ShapeLayer, GroupShape } from '@lottiefiles/toolkit-js';
import { Size, Scalar, Vector, RectangleShape } from '@lottiefiles/toolkit-js';

import type { Scalar2D } from './types';

export interface RectangleOption {
  position?: Scalar2D;
  positionCurrentKeyFrame?: string;
  positionIsAnimated?: boolean;

  roundness?: number;
  roundnessCurrentKeyFrame?: string;
  roundnessIsAnimated: boolean;

  size?: Scalar2D;
  sizeCurrentKeyFrame?: string;
  sizeIsAnimated: boolean;
}

export const updateRectangle = (scene: Scene, id: string, option: RectangleOption): void => {
  const node = scene.getNodeById(id);

  if (node instanceof RectangleShape) {
    const { position, roundness, size } = option;
    const rectangle = node as RectangleShape;

    if (position) {
      rectangle.setPosition(new Vector(position[0], position[1]));
    }

    if (size) {
      rectangle.setSize(new Size(size[0], size[1]));
    }

    if (roundness) {
      rectangle.setRoundness(new Scalar(roundness));
    }
  }
};

export const createRectangle = (
  scene: Scene,
  parent: ShapeLayer | GroupShape,
  rectangle?: RectangleOption,
): RectangleShape => {
  const shape = parent.createRectangleShape();

  if (rectangle) {
    updateRectangle(scene, shape.nodeId, rectangle);
  }

  return shape;
};

export const setRectangleRoundeness = (node: RectangleShape | null, r: number): void => {
  node?.setRoundness(new Scalar(r));
};

export type CurrentRectangle = Required<RectangleOption>;

export const getCurrentRectangle = (node: RectangleShape): CurrentRectangle => {
  const state = node.state.animatedProperties;

  const currentFrame = node.scene.timeline.currentFrame;

  // NOTE(miljau): the assumption here is that <KeyFrame>.frame and currentFrame will line up as integers
  return {
    position: [(state.p.value as VectorJSON).x, (state.p.value as VectorJSON).y],
    positionCurrentKeyFrame:
      node.position.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    positionIsAnimated: node.position.isAnimated,

    roundness: (state.rd.value as ScalarJSON).value,
    roundnessCurrentKeyFrame:
      node.roundness.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    roundnessIsAnimated: node.roundness.isAnimated,

    size: [(state.sz.value as SizeJSON).w, (state.sz.value as SizeJSON).h],
    sizeCurrentKeyFrame: node.size.keyFrames.find((kf) => !kf.isStatic && kf.frame === currentFrame)?.frameId ?? '',
    sizeIsAnimated: node.size.isAnimated,
  };
};
