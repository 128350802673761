/**
 * Copyright 2023 Design Barn Inc.
 */

import {
  CursorPointer,
  CursorGrab,
  CursorGrabbing,
  CursorMove,
  CursorResize,
  CursorIndex,
} from '~/assets/icons/cursor';
import { ToolType } from '~/data/constant';
import { useCreatorStore } from '~/store';
import { GlobalCursorUpdate } from '~/store/uiSlice';

export const getCursorStyle = (fallbackKeyword: string, cursorStyle?: string, hotspotX = 0, hotspotY = 0): string => {
  const updatedHotspot = {
    x: hotspotX,
    y: hotspotY,
  };

  const getDefaultStyle = (): string => {
    if (cursorStyle === GlobalCursorUpdate.GRAB) return CursorGrab;
    if (cursorStyle === GlobalCursorUpdate.GRABBING) return CursorGrabbing;
    if (cursorStyle === GlobalCursorUpdate.MOVE) {
      updatedHotspot.x = 10;
      updatedHotspot.y = 10;

      return CursorMove;
    }
    if (cursorStyle === GlobalCursorUpdate.RESIZE) return CursorResize;
    if (cursorStyle === GlobalCursorUpdate.POINTER) return CursorPointer;
    if (cursorStyle === GlobalCursorUpdate.INDEX) {
      updatedHotspot.x = 10;
      updatedHotspot.y = 3;

      return CursorIndex;
    }

    updatedHotspot.x = 2;
    updatedHotspot.y = 3;

    return CursorPointer;
  };

  const currentTool = useCreatorStore.getState().ui.currentTool;
  const cursorContent = cursorStyle === 'auto' && currentTool === ToolType.Hand ? CursorGrab : getDefaultStyle();
  const imageData = `data:image/svg+xml;base64,${btoa(cursorContent)}`;

  return `url(${imageData}) ${updatedHotspot.x} ${updatedHotspot.y}, ${fallbackKeyword}`;
};
