/**
 * Copyright 2024 Design Barn Inc.
 */

import type { EllipseShape, RectangleShape, Scene, Shape, ShapeLayer, StarShape } from '@lottiefiles/toolkit-js';
import { Angle, Scalar, ShapeType, Size, Vector } from '@lottiefiles/toolkit-js';
import { round } from 'lodash-es';
import type { Object3D } from 'three';
import { Vector3 } from 'three';

import { Box3 } from '../Box3';
import { TransformType } from '../TransformControls';

import { ellipseOption, polygonOption, rectangleOption, starOption } from '~/components/Layout/Header/ShapeMenu';
import { ToolType } from '~/data/constant';
import type { CObject3D, Viewport } from '~/features/canvas';
import { CANVAS_ROUND_PRECISION, getMouseCoord, unProject, UserDataMap } from '~/features/canvas';
import { canvasMap } from '~/lib/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getPlaybackParameters } from '~/lib/eventHandler/playback';
import type { Scalar2D } from '~/lib/toolkit';
import { setAnimatedPosition, createShape, setAnimatedScale, stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { ShapeTypes } from '~/store/constant';

const MINIMUM_MOVEMENT_TO_TRIGGER_DRAWING = 3;
const MINIMUM_SHAPE_SIZE = 10;

// Smaller radius will cause the shape to be cut off within the selection box
const MINIMUM_TRIANGLE_OUTER_RADIUS = 10;
const MINIMUM_STAR_INNER_RADIUS = 15;

enum ShapeToolType {
  CLICK_TO_ADD = 'CLICK_TO_ADD',
  DRAW = 'DRAW',
}

export class ShapeTool {
  public boundingBox: Box3 | null = null;

  public currentMousePosition: Vector3 | null = null;

  public currentShape: Object3D | null = null;

  public currentShapeId: string | null = null;

  public currentToolkitShapeLayer: ShapeLayer | null = null;

  public initialMousePosition: Vector3 | null = null;

  public initialSize: number = 10;

  public isDrawing: boolean = false;

  public pivot: Object3D | null = null;

  public pivotOffsetFactor: { x: number; y: number } = { x: 2, y: 2 };

  public scaleOffsetFactor: { x: number; y: number } = { x: 1, y: 1 };

  public snappedMousePosition: Vector3 | null = null;

  public viewport: Viewport;

  public constructor(viewport: Viewport) {
    this.viewport = viewport;
  }

  public clickToAddShape(): void {
    this.createShape(ShapeToolType.CLICK_TO_ADD);

    emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });
  }

  public createKeydownHandler(): (keyEvent: KeyboardEvent) => void {
    return (keyEvent: KeyboardEvent): void => {
      this.handleScaleRatioLockChange();

      if (!keyEvent.shiftKey) {
        this.handlePointerUp();
      }
    };
  }

  public createKeyupHandler(): () => void {
    return (): void => {
      this.handleScaleRatioLockChange();
    };
  }

  public createShape(shapeCreationType: ShapeToolType): void {
    if (!this.initialMousePosition) return;

    const { currentFrame: startFrame, op: endFrame } = getPlaybackParameters();
    const scene = toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as Scene;
    const position = [this.initialMousePosition.x, this.initialMousePosition.y] as Scalar2D;
    const type = useCreatorStore.getState().ui.lastSelectedShape;
    const isDrawing = shapeCreationType === ShapeToolType.DRAW;

    this.initialSize = useCreatorStore.getState().ui.lastSelectedShape === ShapeTypes.Star ? 15 : 10;

    if (type === ShapeTypes.Rectangle) {
      const { shape } = createShape(scene, { ...rectangleOption, endFrame, startFrame, position });

      if (isDrawing) (shape as RectangleShape).setSize(new Size(10, 10));

      return;
    }

    if (type === ShapeTypes.Ellipse) {
      const { shape } = createShape(scene, { ...ellipseOption, endFrame, startFrame, position });

      if (isDrawing) (shape as EllipseShape).setSize(new Size(10, 10));

      return;
    }

    if (type === ShapeTypes.Polygon) {
      const { shape, shapeLayer } = createShape(scene, { ...polygonOption, endFrame, startFrame, position });

      // Triangles and stars have a different center from the bounding box center
      // So we tell TransformControls that it is already centered, so
      // it doesn't override with the bounding box center
      shapeLayer.setData(UserDataMap.PivotCentered, true);
      if (isDrawing) (shape as StarShape).setOuterRadius(new Scalar(10));

      return;
    }

    if (type === ShapeTypes.Star) {
      const { shape, shapeLayer } = createShape(scene, { ...starOption, endFrame, startFrame, position });

      if (isDrawing) {
        (shape as StarShape).setInnerRadius(new Scalar(15));
        (shape as StarShape).setOuterRadius(new Scalar(25));
      }

      shapeLayer.setData(UserDataMap.PivotCentered, true);
    }
  }

  public findCreatedShape(): RectangleShape | EllipseShape | StarShape {
    return this.currentToolkitShapeLayer?.shapes.find(
      (shape: Shape) =>
        shape.type === ShapeType.RECTANGLE || shape.type === ShapeType.ELLIPSE || shape.type === ShapeType.STAR,
    ) as RectangleShape | EllipseShape | StarShape;
  }

  public getMousePosition(event: MouseEvent): Vector3 {
    const mouseCoord = getMouseCoord(event, this.viewport.overlayCanvasElement);

    return unProject(mouseCoord, this.viewport.camera).round();
  }

  public getPositionAndScaleOffsets(): void {
    if (!this.initialMousePosition) return;

    this.boundingBox = new Box3().setFromObject(this.currentShape as CObject3D, true);

    const boundingBoxSize = new Vector3(
      this.boundingBox.max.x - this.boundingBox.min.x,
      this.boundingBox.max.y - this.boundingBox.min.y,
      1,
    );

    this.scaleOffsetFactor.x = boundingBoxSize.x / this.initialSize;
    this.scaleOffsetFactor.y = boundingBoxSize.y / this.initialSize;

    this.pivotOffsetFactor.x =
      (this.boundingBox.max.x - this.boundingBox.min.x) / (this.initialMousePosition.x - this.boundingBox.min.x);
    this.pivotOffsetFactor.y =
      (this.boundingBox.max.y - this.boundingBox.min.y) / (this.initialMousePosition.y - this.boundingBox.min.y);
  }

  public handleIsDrawing(currentMousePosition: Vector3): void {
    if (!this.initialMousePosition || !this.currentShape) {
      return;
    }

    const diff = new Vector3().copy(currentMousePosition.clone()).sub(this.initialMousePosition.clone());

    if (this.viewport.transformControls.snapManager?.enabled) {
      const { snapOffsetFromMouse } = this.viewport.transformControls.snapManager.getSnapResult(
        TransformType.Drawing,
        currentMousePosition,
      );

      diff.add(snapOffsetFromMouse.clone());
    }

    // Calculate scale as the distance from the initial mouse position to the current mouse position
    const scale = diff.clone().divideScalar(this.initialSize);

    if (useCreatorStore.getState().ui.scaleRatioLocked) {
      const maxAbsScale = Math.max(Math.abs(scale.x), Math.abs(scale.y));

      scale.x = Math.sign(scale.x) * maxAbsScale;
      scale.y = Math.sign(scale.y) * maxAbsScale;

      diff.copy(scale.clone().multiplyScalar(this.initialSize));
    }

    // Offset the position so that it looks like we are dragging from the corner of the shape
    // or it will look like the shape is scaled from the center
    const newPosition = new Vector3();

    newPosition.x = this.initialMousePosition.x + diff.x / this.pivotOffsetFactor.x;
    newPosition.y = this.initialMousePosition.y + diff.y / this.pivotOffsetFactor.y;

    if (useCreatorStore.getState().ui.scaleRatioLocked) {
      const scaleDiff = Math.abs(Math.max(diff.x, diff.y));

      newPosition.x = this.initialMousePosition.x + (diff.x < 0 ? -scaleDiff : scaleDiff) / this.pivotOffsetFactor.x;
      newPosition.y = this.initialMousePosition.y + (diff.y < 0 ? -scaleDiff : scaleDiff) / this.pivotOffsetFactor.y;
    }

    this.currentShape.position.copy(newPosition.clone());
    this.currentShape.scale.set(scale.x / this.scaleOffsetFactor.x, scale.y / this.scaleOffsetFactor.y, 1);
    this.currentToolkitShapeLayer?.setPosition(new Vector(newPosition.x, newPosition.y));

    this.viewport.transformControls.handleShapeDrawing();
  }

  public handlePointerDown(event: MouseEvent): void {
    stateHistory.beginAction();
    this.initialMousePosition = this.getMousePosition(event);

    const handleKeydown = this.createKeydownHandler();
    const handleKeyup = this.createKeyupHandler();

    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyup);

    const onPointerUp = (): void => {
      this.handlePointerUp();
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyup);
    };

    this.viewport.overlayCanvasElement.addEventListener('pointerup', onPointerUp, { once: true });
  }

  public handlePointerMove(event: MouseEvent): void {
    if (!this.initialMousePosition) {
      return;
    }

    this.currentMousePosition = this.getMousePosition(event);
    if (this.isDrawing) {
      this.handleIsDrawing(this.currentMousePosition);
    }

    if (!this.isDrawing && !this.isWithinMovementThreshold(event)) {
      this.startDrawing();
    }
  }

  public handlePointerUp(): void {
    if (!this.initialMousePosition) {
      return;
    }

    if (this.isDrawing) {
      this.stopDrawing();
    } else {
      this.clickToAddShape();
    }

    stateHistory.endAction();
    emitter.emit(EmitterEvent.CANVAS_REDRAW);

    this.currentToolkitShapeLayer = null;
    this.initialMousePosition = null;
    this.currentShapeId = null;
    this.snappedMousePosition = null;
    this.isDrawing = false;
    if (this.viewport.dragSelector) this.viewport.dragSelector.disable = false;

    useCreatorStore.getState().ui.setCurrentTool(ToolType.Move);
  }

  public handleScaleRatioLockChange(): void {
    if (!this.isDrawing || !this.currentMousePosition) return;

    this.handleIsDrawing(this.currentMousePosition);
    this.viewport.transformControls.handleShapeDrawing();
  }

  public isMinimumSize(): boolean {
    if (!this.boundingBox) return false;

    this.boundingBox.setFromObject(this.currentShape as CObject3D, true);

    const boundingBoxSize = new Vector3(
      this.boundingBox.max.x - this.boundingBox.min.x,
      this.boundingBox.max.y - this.boundingBox.min.y,
      1,
    );

    return boundingBoxSize.x > MINIMUM_SHAPE_SIZE || boundingBoxSize.y > MINIMUM_SHAPE_SIZE;
  }

  public isWithinMovementThreshold(event: MouseEvent): boolean {
    if (!this.initialMousePosition) return false;

    const currentMousePosition = this.getMousePosition(event);

    return (this.initialMousePosition.distanceTo(currentMousePosition) as number) < MINIMUM_MOVEMENT_TO_TRIGGER_DRAWING;
  }

  public startDrawing(): void {
    if (!this.initialMousePosition) return;

    if (this.viewport.dragSelector) this.viewport.dragSelector.disable = true;
    this.isDrawing = true;

    this.createShape(ShapeToolType.DRAW);
    emitter.emit(EmitterEvent.SHAPE_CREATED, { commit: true });

    const selectedIdsAfterCreation = useCreatorStore.getState().ui.selectedIdsAfterCreated;

    this.currentShapeId = selectedIdsAfterCreation ? (selectedIdsAfterCreation[0] as string) : null;

    this.currentShape = canvasMap.get(this.currentShapeId as string) as CObject3D;
    this.viewport.transformControls.visible = true;
    this.currentToolkitShapeLayer = useCreatorStore
      .getState()
      .toolkit.getNodeByIdOnly(this.currentShapeId as string) as ShapeLayer;

    this.getPositionAndScaleOffsets();
  }

  public stopDrawing(): void {
    if (!this.isMinimumSize() || !this.currentShape || !this.initialMousePosition) {
      useCreatorStore.getState().ui.removeSelectedNodes();
      stateHistory.rollbackAction();

      return;
    }

    const type = useCreatorStore.getState().ui.lastSelectedShape;
    const currentScale = this.currentShape.scale.multiplyScalar(this.initialSize);

    if (type === ShapeTypes.Rectangle || type === ShapeTypes.Ellipse) {
      const rectangleOrEllipse = this.findCreatedShape() as RectangleShape | EllipseShape;
      const size = new Size(Math.abs(currentScale.x), Math.abs(currentScale.y));

      rectangleOrEllipse.setSize(size);

      setAnimatedPosition(this.currentToolkitShapeLayer, [
        round(rectangleOrEllipse.absolutePosition.x, CANVAS_ROUND_PRECISION),
        round(rectangleOrEllipse.absolutePosition.y, CANVAS_ROUND_PRECISION),
      ]);

      return;
    }

    if (type === ShapeTypes.Polygon) {
      const polygonShape = this.findCreatedShape() as StarShape;

      if (currentScale.y < 0) {
        polygonShape.setRotation(new Angle(180));
      }

      // For polygon and stars, we use radius to change the size
      // But we can't change the X and Y scale with radius, so we need to adjust the scale
      const scaleFactor = Math.abs(currentScale.x) / polygonShape.outerRadius.value.value;
      const minRadiusScaleOffset =
        Math.abs(currentScale.x) < MINIMUM_TRIANGLE_OUTER_RADIUS
          ? Math.abs(currentScale.x) / MINIMUM_TRIANGLE_OUTER_RADIUS
          : 1;
      const adjustedScaleX = (Math.abs(currentScale.x) / (scaleFactor * this.initialSize)) * minRadiusScaleOffset;
      const adjustedScaleY = (Math.abs(currentScale.y) / (scaleFactor * this.initialSize)) * minRadiusScaleOffset;

      polygonShape.setOuterRadius(new Scalar(Math.max(Math.abs(currentScale.x), MINIMUM_TRIANGLE_OUTER_RADIUS)));

      if (adjustedScaleX !== adjustedScaleY || minRadiusScaleOffset > 0) {
        setAnimatedScale(this.currentToolkitShapeLayer, [
          round(adjustedScaleX * 100, CANVAS_ROUND_PRECISION),
          round(adjustedScaleY * 100, CANVAS_ROUND_PRECISION),
        ]);
      }

      setAnimatedPosition(this.currentToolkitShapeLayer, [
        round(polygonShape.absolutePosition.x, CANVAS_ROUND_PRECISION),
        round(polygonShape.absolutePosition.y, CANVAS_ROUND_PRECISION),
      ]);

      return;
    }

    if (type === ShapeTypes.Star) {
      const starShape = this.findCreatedShape() as StarShape;

      if (currentScale.y < 0) {
        starShape.setRotation(new Angle(180));
      }

      const scaleFactor = Math.abs(currentScale.x) / starShape.innerRadius.value.value;
      const minRadiusScaleOffset =
        Math.abs(currentScale.x) < MINIMUM_STAR_INNER_RADIUS ? Math.abs(currentScale.x) / MINIMUM_STAR_INNER_RADIUS : 1;

      const adjustedScaleX = (Math.abs(currentScale.x) / (scaleFactor * this.initialSize)) * minRadiusScaleOffset;
      const adjustedScaleY = (Math.abs(currentScale.y) / (scaleFactor * this.initialSize)) * minRadiusScaleOffset;

      const outerToInnerRadiusRatio = starShape.outerRadius.value.value / starShape.innerRadius.value.value;
      const innerRadius = Math.max(Math.abs(currentScale.x), MINIMUM_STAR_INNER_RADIUS);

      starShape.setInnerRadius(new Scalar(innerRadius));
      starShape.setOuterRadius(new Scalar(innerRadius * outerToInnerRadiusRatio));

      if (adjustedScaleX !== adjustedScaleY || minRadiusScaleOffset > 0) {
        setAnimatedScale(this.currentToolkitShapeLayer, [
          round(adjustedScaleX * 100, CANVAS_ROUND_PRECISION),
          round(adjustedScaleY * 100, CANVAS_ROUND_PRECISION),
        ]);
      }

      setAnimatedPosition(this.currentToolkitShapeLayer, [
        round(starShape.absolutePosition.x, CANVAS_ROUND_PRECISION),
        round(starShape.absolutePosition.y, CANVAS_ROUND_PRECISION),
      ]);
    }
  }
}
