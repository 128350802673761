/**
 * Copyright 2024 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import React, { useCallback, Fragment, useEffect, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { CreatorMenuList } from '../constant';

import { MenuDivider } from './MenuDivider';
import { MenuInteraction } from './MenuInteraction';
import { RecentFileMenuItem } from './RecentFileMenuItem';
import { SubMenu } from './SubMenu';

import { LottieDropdownExpand, LottieDropdownArrow, LottieFilesLight } from '~/assets/icons';
import { GB_CONSTANT } from '~/config/growthbook';
import { EditMenuType, MenuType } from '~/data/constant';
import { useClickOutside } from '~/hooks';
import type { RecentFile } from '~/providers/api/project';
import { useProjectAPI } from '~/providers/api/project';
import { useCreatorStore } from '~/store';
import { fireEvent, type EventStorePayload } from '~/utils';

export interface MenuDropdownProp {
  callback: (arg?: unknown) => void;
  children?: MenuDropdownProp[];
  disabled?: boolean;
  isAlignment?: boolean;
  isCustomLayout?: boolean;
  isDivider?: boolean;
  isHidden?: boolean;
  label: ReactNode;
  shortcut?: string;
  trackParams?: EventStorePayload;
  type: string;
}

export const CreatorMenu: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => setOpen(false));

  const [selectedNodesInfo, isAuthorized, fileId, token] = useCreatorStore(
    (state) => [state.ui.selectedNodesInfo, state.user.isAuthorized, state.project.info.fileId, state.user.token],
    shallow,
  );

  const { getRecentFiles } = useProjectAPI(token);

  const openRecentFile = useCallback(
    (file: RecentFile) => {
      window.open(`?fileId=${file.id}`, fileId ? '_blank' : '_self');
    },
    [fileId],
  );

  useEffect(() => {
    const fetchRecentFiles = async (): Promise<void> => {
      const files = await getRecentFiles();

      if (files.length) {
        const recentFilesItem = CreatorMenuList.find((item) => item.type === MenuType.RecentFiles);

        if (recentFilesItem) {
          recentFilesItem.children = files.map((file) => ({
            type: `${MenuType.RecentFiles}_${file.id}`,
            callback: () => openRecentFile(file),
            label: <RecentFileMenuItem file={file} />,
            isCustomLayout: true,
          }));
          recentFilesItem.isHidden = false;
        }
      }
    };

    if (token) {
      fetchRecentFiles();
    }
  }, [getRecentFiles, token, openRecentFile]);

  useEffect(() => {
    CreatorMenuList.forEach((item) => {
      if (
        [
          MenuType.MakeCopy,
          MenuType.GoToDashboard,
          MenuType.GoToDashboardDivider,
          MenuType.DownloadMenu,
          MenuType.DownloadMenuDivider,
          MenuType.SaveToCreator,
        ].includes(item.type)
      ) {
        item.isHidden = !isAuthorized;
      }
    });
  }, [isAuthorized]);

  useEffect(() => {
    const makeCopyItem = CreatorMenuList.find((item) => item.type === MenuType.MakeCopy);

    if (makeCopyItem) {
      makeCopyItem.disabled = !fileId;
    }
  }, [fileId]);

  const showRulersGuidesGrid = useFeatureIsOn(GB_CONSTANT.SHOW_RULERS_GUIDES_GRID);

  useEffect(() => {
    const viewMenuItem = CreatorMenuList.find((item) => item.type === MenuType.View);

    if (viewMenuItem) {
      viewMenuItem.isHidden = !showRulersGuidesGrid;
    }

    useCreatorStore.setState((state) => {
      state.canvas.snapping.enabled = showRulersGuidesGrid;
    });
  }, [showRulersGuidesGrid]);

  const currentCreatorMenuList = useMemo(() => {
    // when the menu is open, disable few edit menu items if no node is selected
    return isOpen
      ? CreatorMenuList.map((item) => {
          if (item.type === MenuType.Edit && selectedNodesInfo.length === 0) {
            return {
              ...item,
              children: item.children?.map((child) => {
                if (
                  [EditMenuType.Cut, EditMenuType.Copy, EditMenuType.Duplicate, EditMenuType.Delete].includes(
                    child.type,
                  )
                ) {
                  return { ...child, disabled: true };
                }

                return child;
              }),
            };
          }

          return item;
        })
      : CreatorMenuList;
  }, [selectedNodesInfo, isOpen]);

  const handleClick = (callback: () => void, eventParams?: EventStorePayload): void => {
    if (eventParams) {
      fireEvent(eventParams);
    }
    setOpen(false);
    callback();
  };

  return (
    <>
      <Menu as="div" className="relative ml-1" ref={menuRef}>
        <Menu.Button
          data-testid="creator-menu-dropdown"
          onClick={() => setOpen(!isOpen)}
          className="group flex cursor-pointer items-center justify-center rounded hover:bg-gray-700  "
        >
          <LottieFilesLight className="mr-1 h-full w-[24px]" />
          <LottieDropdownArrow
            className={`h-3 w-4 cursor-pointer stroke-current text-gray-500 hover:text-white group-hover:stroke-white ${
              isOpen ? '!text-white' : ''
            }`}
          />
        </Menu.Button>
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-dropdown mt-1 w-[260px] cursor-pointer rounded-lg border border-gray-600 bg-gray-800 py-2 shadow-dropdown focus:outline-none">
            {currentCreatorMenuList.map((dropdown, index) => {
              const { callback, disabled, isDivider, isHidden, label, trackParams } = dropdown;

              if (isHidden) {
                return null;
              }

              return (
                <div
                  key={`${label}_${index}`}
                  className={clsx('relative mb-1 rounded last:mb-0', {
                    'mx-2': !isDivider,
                    'hover:bg-gray-600': !disabled,
                  })}
                >
                  <Menu.Item>
                    <>
                      <MenuInteraction>
                        {({ hover }) => (
                          <>
                            {!isDivider && (
                              <>
                                <button
                                  data-testid={`creator-menu-dropdown-${dropdown.type}`}
                                  className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                                    index === CreatorMenuList.length - 1 && 'rounded-b'
                                  } h-6 items-center px-2 text-xs ${disabled ? 'text-gray-500' : 'text-white'}`}
                                  onClick={() => handleClick(callback, trackParams)}
                                  disabled={Boolean(disabled)}
                                >
                                  {/* {displayLabel(dropdown.label, type)} */}
                                  {dropdown.label}
                                  {dropdown.children && dropdown.children.length > 0 && (
                                    <>
                                      <LottieDropdownExpand className="absolute right-[7px] h-4 w-4" />
                                    </>
                                  )}
                                  {!dropdown.children && (
                                    <span
                                      className={`absolute right-[7px] ${disabled ? 'text-gray-500' : 'text-gray-300'}`}
                                    >
                                      {dropdown.shortcut}
                                      {/* {displayShortcut(dropdown.shortcut as string, type)} */}
                                    </span>
                                  )}
                                </button>
                                {hover && dropdown.children && dropdown.children.length > 0 && (
                                  <>
                                    <SubMenu subItems={dropdown.children} onClose={() => setOpen(false)} />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </MenuInteraction>
                      {isDivider && index !== CreatorMenuList.length - 1 && <MenuDivider />}
                    </>
                  </Menu.Item>
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
