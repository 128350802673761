/**
 * Copyright 2024 Design Barn Inc.
 */

import type { OrthographicCamera } from 'three';

export const getOriginOffset = (
  { position, right, top, zoom }: OrthographicCamera,
  pixelRatio: number,
): Record<'x' | 'y', number> => {
  return {
    x: Number((right - position.x * zoom).toFixed(4)) * pixelRatio,
    y: Number((top - position.y * zoom).toFixed(4)) * pixelRatio,
  };
};

export const getDevicePixelRatio = (): number => Math.min(2, window.devicePixelRatio);
