/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import type { MenuDropdownProp } from './components/CreatorMenu';
import { GridMenuItem, GuidesMenuItem, RulersMenuItem, SnapMenuItem } from './components/ViewMenuItems';

import { EditMenuType, GuideMenuType, MenuType, RulersMenuType, ViewMenuType } from '~/data/constant';
import { EventAction, EventType, FileType } from '~/data/eventStore';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { createNewFile, exportDotLottie, exportJSON, goToDashboard, saveToCreator } from '~/lib/function/menu';
import { isMacOS } from '~/utils';

// These are precalculated heights for the context menu. Update them if any
// items are added/removed from the context menus.

export const MENU_HEIGHT_OFFSET = 20;

export const MENU_HEIGHT = {
  ShapeLayerMenuTimeline: 796 + 22,
  NormalLayerMenu: 327,
  NestedSceneLayerMenu: 770 + 22,
  SingleKeyFrameMenu: 205,
  CanvasEmptyCanvasMenu: 222,
  CanvasShapeLayerMenuCanvas: 856 + 22,
  CanvasNestedSceneCanvasMenu: 833 + 22,
  TimelineTopbarMenu: 52,
};

export const MENU_WIDTH_OFFSET = 20;

export const MENU_WIDTH = {
  SingleKeyFrameMenu: 240,
  SingleKeyFrameSubMenu: 132,
  ShapeLayerMenuTimeline: 240,
  NestedSceneLayerMenu: 240,
  NormalLayerMenu: 240,
  TimelineTopbarMenu: 240,
};

export const cmdOrCtrl = isMacOS ? '⌘' : 'ctrl';
export const altOrOpt = isMacOS ? '⌥' : 'alt';

export const formatShortcut = (...keys: string[]): string => (isMacOS ? keys.join('') : keys.join(' '));

const CutItem: MenuDropdownProp = {
  type: EditMenuType.Cut,
  label: 'Cut',
  shortcut: formatShortcut(cmdOrCtrl, 'X'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_CUT),
};

const CopyAsMaskItem: MenuDropdownProp = {
  type: 'Copy as Mask',
  label: 'Copy as Mask',
  callback: () => emitter.emit(EmitterEvent.CANVAS_COPY_AS_MASK),
};

const CreateMaskItem: MenuDropdownProp = {
  type: 'Create Mask',
  label: 'Create Mask',
  callback: () => emitter.emit(EmitterEvent.CANVAS_MASK_CREATE),
};

const PasteMaskItem: MenuDropdownProp = {
  type: 'Paste Mask',
  label: 'Paste Mask',
  callback: () => emitter.emit(EmitterEvent.CANVAS_PASTE_AS_MASK),
};

const CopyItem: MenuDropdownProp = {
  type: EditMenuType.Copy,
  label: 'Copy',
  shortcut: formatShortcut(cmdOrCtrl, 'C'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_COPY),
};

const PasteItem: MenuDropdownProp = {
  type: EditMenuType.Paste,
  label: 'Paste',
  shortcut: formatShortcut(cmdOrCtrl, 'V'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT),
};

const PasteAsNewItem: MenuDropdownProp = {
  type: EditMenuType.PasteAsNew,
  label: 'Paste as new',
  shortcut: formatShortcut(altOrOpt, cmdOrCtrl, 'V'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_PASTE_WITH_CLONED_ASSETS),
};

const DuplicateItem: MenuDropdownProp = {
  type: EditMenuType.Duplicate,
  label: 'Duplicate',
  shortcut: formatShortcut(cmdOrCtrl, 'D'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_DUPLICATE),
};

const SelectAllItem: MenuDropdownProp = {
  type: EditMenuType.SelectAll,
  label: 'Select All',
  shortcut: formatShortcut(cmdOrCtrl, 'A'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_SELECT_ALL_LAYERS),
};

const ZoomInItem: MenuDropdownProp = {
  type: 'ZoomIn',
  label: 'Zoom in',
  shortcut: formatShortcut(cmdOrCtrl, '+'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_ZOOM_IN),
};

const ZoomOutItem: MenuDropdownProp = {
  type: 'ZoomOut',
  label: 'Zoom out',
  shortcut: formatShortcut(cmdOrCtrl, '-'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_ZOOM_OUT),
};

const ZoomToFitItem: MenuDropdownProp = {
  type: 'ZoomToFit',
  label: 'Zoom to fit',
  shortcut: formatShortcut(cmdOrCtrl, '0'),
  callback: () => emitter.emit(EmitterEvent.CANVAS_ZOOM_TO_FIT),
};

const AnimatedPropertiesItem: MenuDropdownProp = {
  type: 'AnimatedProperties',
  label: 'Animate properties...',
  shortcut: '⇧K',
  callback: () => emitter.emit(EmitterEvent.TIMELINE_SHOW_ANIMATED_PROPERTIES),
};

const AnimationPresetsItem: MenuDropdownProp = {
  type: 'AnimationPresets',
  label: 'Animation presets',
  shortcut: formatShortcut(cmdOrCtrl, '2'),
  callback: () => emitter.emit(EmitterEvent.ANIMATION_PRESET_PANEL_OPEN),
};

const ShowAllKeyframesItem: MenuDropdownProp = {
  type: 'ShowAllKeyframes',
  label: 'Show all keyframes',
  isDivider: true,
  shortcut: 'U',
  callback: () => emitter.emit(EmitterEvent.TIMELINE_SHOW_ALL_KEYFRAMES),
};

const FocusUnfocusItem: MenuDropdownProp = {
  type: 'FocusUnfocus',
  label: 'Focus/Unfocus',
  shortcut: formatShortcut(cmdOrCtrl, 'F'),
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_FOCUS_UNFOCUS),
};

const LockUnlockItem: MenuDropdownProp = {
  type: 'LockUnlock',
  label: 'Lock/Unlock',
  shortcut: formatShortcut(cmdOrCtrl, 'L'),
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_LOCK_UNLOCK),
};

const ShowHideItem: MenuDropdownProp = {
  type: 'ShowHide',
  label: 'Show/Hide',
  shortcut: formatShortcut(cmdOrCtrl, '⇧', 'H'),
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_SHOW_HIDE),
};

const BringToFrontItem: MenuDropdownProp = {
  type: 'BringToFront',
  label: 'Bring forward',
  shortcut: ']',
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_UP),
};

const SendToBackItem: MenuDropdownProp = {
  type: 'SendToBack',
  label: 'Send backward',
  shortcut: '[',
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_DOWN),
};

const BringToMostFrontItem: MenuDropdownProp = {
  type: 'BringToMostFront',
  label: 'Bring to front',
  shortcut: formatShortcut(cmdOrCtrl, ' ]'),
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_MOST_UP),
};

const SendToMostBackItem: MenuDropdownProp = {
  type: 'SendToMostBack',
  label: 'Send to back',
  shortcut: formatShortcut(cmdOrCtrl, ' ['),
  callback: () => emitter.emit(EmitterEvent.TIMELINE_LAYER_MOVE_MOST_DOWN),
};

const AlignmentItem: MenuDropdownProp = {
  isAlignment: true,
  type: 'Alignment',
  label: 'Alignment',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => {},
};

const RotateRightItem: MenuDropdownProp = {
  type: 'RotateRight',
  label: 'Rotate 90° right',
  callback: () => emitter.emit(EmitterEvent.CANVAS_OBJECT_ROTATE_RIGHT),
};

const RotateLeftItem: MenuDropdownProp = {
  type: 'RotateLeft',
  label: 'Rotate 90° left',
  callback: () => emitter.emit(EmitterEvent.CANVAS_OBJECT_ROTATE_LEFT),
};

const FlipHorizontal: MenuDropdownProp = {
  type: 'FlipHorizontal',
  label: 'Flip horizontal',
  callback: () => emitter.emit(EmitterEvent.CANVAS_OBJECT_FLIP_HORIZONTAL),
};

const FlipVertical: MenuDropdownProp = {
  type: 'FlipVertical',
  label: 'Flip vertical',
  callback: () => emitter.emit(EmitterEvent.CANVAS_OBJECT_FLIP_VERTICAL),
};

const DeleteItem: MenuDropdownProp = {
  type: EditMenuType.Delete,
  label: 'Delete',
  shortcut: '⌫',
  callback: (arg) => emitter.emit(EmitterEvent.UI_DELETE, arg),
};

const DeleteAnimatedItem: MenuDropdownProp = {
  type: 'Delete',
  label: 'Delete',
  shortcut: '⌫',
  callback: (arg) => emitter.emit(EmitterEvent.UI_ANIMATED_DELETE, arg),
};

const EditScene: MenuDropdownProp = {
  type: 'EditScene',
  label: 'Edit Scene',
  shortcut: '',
  callback: (arg) => emitter.emit(EmitterEvent.TIMELINE_PRECOMP_EDIT_SCENE, arg),
};

const BreakScene: MenuDropdownProp = {
  type: 'BreakScene',
  label: 'Break Scene',
  shortcut: formatShortcut(cmdOrCtrl, altOrOpt, 'C'),
  callback: (arg) => emitter.emit(EmitterEvent.TIMELINE_PRECOMP_BREAK_SCENE, arg),
};

const CreateScene: MenuDropdownProp = {
  type: 'CreateScene',
  label: 'Create Scene',
  shortcut: formatShortcut(cmdOrCtrl, '⇧', 'C'),
  callback: (arg) => emitter.emit(EmitterEvent.TIMELINE_PRECOMP_CREATE_SCENE, arg),
};

const RenameItem: MenuDropdownProp = {
  type: 'RenameItem',
  label: 'Rename',
  shortcut: '',
  callback: (arg) => emitter.emit(EmitterEvent.TIMELINE_RENAME_LAYER_START, arg),
};
const isDevelopment = import.meta.env.DEV;

const DownloadMenu = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => {},
  type: MenuType.DownloadMenu,
  label: 'Download as',
  children: [
    {
      type: MenuType.JSON,
      callback: exportJSON,
      label: 'Lottie JSON',
      shortcut: formatShortcut(cmdOrCtrl, 'E'),
      trackParams: {
        parameters: { type: FileType.JSON },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.DownloadedFile,
      },
    },
    {
      type: MenuType.DotLottie,
      callback: exportDotLottie,
      label: 'dotLottie',
      trackParams: {
        parameters: { type: FileType.DotLottie },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.DownloadedFile,
      },
    },
  ],
};

const EditMenu = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => {},
  type: MenuType.Edit,
  label: 'Edit',
  children: [
    {
      type: EditMenuType.Undo,
      callback: () => emitter.emit(EmitterEvent.UI_UNDO),
      label: 'Undo',
      shortcut: formatShortcut(cmdOrCtrl, 'Z'),
    },
    {
      type: EditMenuType.Redo,
      callback: () => emitter.emit(EmitterEvent.UI_REDO),
      label: 'Redo',
      shortcut: formatShortcut('⇧', cmdOrCtrl, 'Z'),
      isDivider: true,
    },
    CutItem,
    CopyItem,
    PasteItem,
    PasteAsNewItem,
    DuplicateItem,
    { ...DeleteItem, isDivider: true },
    SelectAllItem,
  ],
};

const ViewMenu = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => {},
  type: MenuType.View,
  label: 'View',
  children: [
    {
      type: ViewMenuType.Rulers,
      callback: () => emitter.emit(EmitterEvent.CANVAS_TOGGLE_RULERS),
      label: <RulersMenuItem />,
      shortcut: formatShortcut(altOrOpt, '⇧', 'R'),
    },
    {
      type: ViewMenuType.Guides,
      callback: () => emitter.emit(EmitterEvent.CANVAS_TOGGLE_GUIDES),
      label: <GuidesMenuItem />,

      shortcut: formatShortcut(altOrOpt, '⇧', ';'),
    },
    {
      type: ViewMenuType.Grid,
      callback: () => emitter.emit(EmitterEvent.CANVAS_TOGGLE_GRID_VISIBILITY),
      label: <GridMenuItem />,
      shortcut: formatShortcut(altOrOpt, '⇧', "'"),
      isDivider: true,
    },
    {
      type: ViewMenuType.Snapping,
      callback: () => emitter.emit(EmitterEvent.CANVAS_TOGGLE_SNAPPING),
      label: <SnapMenuItem />,
    },
  ],
};

const EasingLinearMenu = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => {},
  type: 'easing',
  label: 'Linear',
  disabled: false,
  isHidden: false,
  children: [
    {
      type: 'LinearIn',
      callback: () => emitter.emit(EmitterEvent.EASING_LINEAR_IN),
      label: 'In',
    },
    {
      type: 'LinearOut',
      callback: () => emitter.emit(EmitterEvent.EASING_LINEAR_OUT),
      label: 'Out',
    },
    {
      type: 'LinearBoth',
      shortcut: formatShortcut('E', '/', 'F9'),
      callback: () => emitter.emit(EmitterEvent.EASING_LINEAR_BOTH),
      label: 'Both',
    },
  ],
};

const EasingSmoothMenu = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback: () => {},
  type: 'easing',
  label: 'Smooth',
  disabled: false,
  isHidden: false,
  children: [
    {
      type: 'SmoothIn',
      callback: () => emitter.emit(EmitterEvent.EASING_SMOOTH_IN),
      label: 'In',
    },
    {
      type: 'SmoothOut',
      callback: () => emitter.emit(EmitterEvent.EASING_SMOOTH_OUT),
      label: 'Out',
    },
    {
      type: 'SmoothBoth',
      shortcut: formatShortcut('E', '/', 'F9'),
      callback: () => emitter.emit(EmitterEvent.EASING_SMOOTH_BOTH),
      label: 'Both',
    },
  ],
};

export const CreatorMenuList: MenuDropdownProp[] = [
  {
    type: MenuType.New,
    callback: createNewFile,
    label: 'Create new file',
    trackParams: {
      parameters: { action: EventAction.CreateNew },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.SelectedFileMenu,
    },
  },
  {
    type: MenuType.RecentFiles,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
    label: 'Recent files',
    children: [],
    isHidden: true,
  },
  {
    type: MenuType.MakeCopy,
    callback: saveToCreator,
    label: 'Make a copy',
    // shortcut: '⌘S',
    trackParams: {
      parameters: { action: EventAction.SaveDotCreator },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.SelectedFileMenu,
    },
  },
  {
    type: MenuType.SaveToCreator,
    callback: async () => saveToCreator({ saveWithoutPopup: true }),
    label: 'Save',
    shortcut: formatShortcut(altOrOpt, cmdOrCtrl, 'S'),
  },
  {
    type: '',
    label: '',
    isDivider: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
  },
  ...(isDevelopment
    ? [
        DownloadMenu,
        {
          type: MenuType.DownloadMenuDivider,
          label: '',
          isDivider: true,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          callback: () => {},
        },
      ]
    : []),

  EditMenu,
  ViewMenu,
  {
    type: MenuType.GoToDashboardDivider,
    label: '',
    isDivider: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
  },
  {
    type: MenuType.GoToDashboard,
    callback: goToDashboard,
    label: 'Go to Dashboard',
    trackParams: {
      parameters: { action: EventAction.GoToDashboard },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      event_type: EventType.SelectedFileMenu,
    },
  },
];

export const EmptyCanvasMenuList: MenuDropdownProp[] = [
  {
    ...PasteItem,
    isDivider: true,
    disabled: false,
  },
  {
    type: MenuType.ToggleTimeline,
    label: 'Expand/Collapse timeline',
    shortcut: '`',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
  },
  {
    type: MenuType.ToggleUI,
    label: 'Show/Hide UI',
    isDivider: true,
    shortcut: formatShortcut(cmdOrCtrl, '.'),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
  },
  ZoomInItem,
  ZoomOutItem,
  ZoomToFitItem,
];

export const ShapeLayerMenuList: MenuDropdownProp[] = [
  AnimatedPropertiesItem,
  AnimationPresetsItem,
  {
    ...ShowAllKeyframesItem,
    isDivider: true,
  },
  CopyItem,
  PasteItem,
  {
    ...DuplicateItem,
    isDivider: true,
  },
  CreateMaskItem,
  CopyAsMaskItem,
  {
    ...PasteMaskItem,
    isDivider: true,
  },
  {
    ...CreateScene,
    isDivider: true,
  },
  FocusUnfocusItem,
  LockUnlockItem,
  {
    ...ShowHideItem,
    isDivider: true,
  },
  BringToFrontItem,
  SendToBackItem,
  BringToMostFrontItem,
  {
    ...SendToMostBackItem,
    isDivider: true,
  },
  { ...AlignmentItem, isDivider: true },
  RotateRightItem,
  RotateLeftItem,

  FlipHorizontal,
  { ...FlipVertical, isDivider: true },

  { ...RenameItem, isDivider: true },
  DeleteItem,
];

export const NormalLayerMenuList: MenuDropdownProp[] = [
  AnimatedPropertiesItem,
  AnimationPresetsItem,
  {
    ...ShowAllKeyframesItem,
    isDivider: true,
  },
  CopyItem,
  PasteItem,
  {
    ...DuplicateItem,
    isDivider: true,
  },
  {
    ...CopyAsMaskItem,
    isDivider: true,
  },
  { ...RenameItem, isDivider: true },
  DeleteItem,
];

export const SingleKeyFrameMenuList: MenuDropdownProp[] = [
  EasingLinearMenu,
  EasingSmoothMenu,
  CopyItem,
  {
    ...PasteItem,
    isDivider: true,
  },
  DeleteItem,
];

export const MultiKeyFrameMenuList: MenuDropdownProp[] = [
  EasingLinearMenu,
  EasingSmoothMenu,
  CopyItem,
  {
    ...PasteItem,
    isDivider: true,
  },
  DeleteItem,
];

export const AnimatedPropertyMenuList: MenuDropdownProp[] = [
  {
    ...DeleteAnimatedItem,
    isDivider: true,
  },
];

export const NestedSceneLayerMenuList: MenuDropdownProp[] = [
  {
    ...EditScene,
    isDivider: true,
  },
  AnimatedPropertiesItem,
  AnimationPresetsItem,
  {
    ...ShowAllKeyframesItem,
    isDivider: true,
  },
  CopyItem,
  PasteItem,
  {
    ...DuplicateItem,
    isDivider: true,
  },
  {
    ...BreakScene,
    isDivider: true,
  },
  FocusUnfocusItem,
  LockUnlockItem,
  {
    ...ShowHideItem,
    isDivider: true,
  },
  BringToFrontItem,
  SendToBackItem,
  BringToMostFrontItem,
  SendToMostBackItem,
  RotateRightItem,
  RotateLeftItem,
  FlipHorizontal,
  { ...FlipVertical, isDivider: true },
  { ...RenameItem, isDivider: true },
  DeleteItem,
];

export const GuidesMenuList: MenuDropdownProp[] = [
  {
    type: GuideMenuType.EditGuides,
    label: 'Edit guides',
    callback: () => {
      //
    },
  },
  {
    type: GuideMenuType.DeleteGuide,
    label: 'Delete guide',
    callback: () => {
      //
    },
  },
];

export const RulersMenuList: MenuDropdownProp[] = [
  {
    type: RulersMenuType.HideRulers,
    label: 'Hide rulers',
    callback: () => {
      emitter.emit(EmitterEvent.CANVAS_TOGGLE_RULERS);
    },
  },
];
