/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StateCreator } from 'zustand';

import type { StoreSlice } from '.';
import type { Manifest } from '~/plugins';
import type { PluginOptions } from '~/plugins/Plugin';

export interface PluginInfo {
  failed?: boolean;
  id: string;
  loading?: boolean;
  manifest?: Manifest;
  name: string;
  options: PluginOptions;
}

export interface DevPluginInfo {
  manifest: Manifest;
  name: string;
}

// The plugins contain unserializable data so only very primitive information
// about them are stored in the store
export interface PluginSlice {
  plugins: {
    devPlugin: DevPluginInfo | null;
    loadedPlugins: PluginInfo[];

    setDevPlugin: (plugin: DevPluginInfo | null) => void;
    setLoadedPlugins: (plugins: PluginInfo[]) => void;
  };
}

export const createPluginSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  PluginSlice
> = (set) => ({
  plugins: {
    loadedPlugins: [],
    devPlugin: null,
    setLoadedPlugins(plugins: PluginInfo[]) {
      set((draft) => {
        draft.plugins.loadedPlugins = plugins;
      });
    },

    setDevPlugin(plugin: DevPluginInfo | null) {
      set((draft) => {
        draft.plugins.devPlugin = plugin;
      });
    },
  },
});
