/**
 * Copyright 2024 Design Barn Inc.
 */

import { isFeatureSupported, type PlayerName, type PlayerVersion, canIRelottie } from '@lottiefiles/can-i-relottie';
import LottieHash, { FEATURE_FLAGS } from '@lottiefiles/lottie-hash';

export const getPlayerHash = (playerName: PlayerName, playerVersion: PlayerVersion): { hex: string } => {
  const lh = new LottieHash();

  FEATURE_FLAGS.forEach((flag, feature) => {
    const isSupported = isFeatureSupported(feature, canIRelottie, playerName, playerVersion);

    if (!isSupported) return;

    lh.setFlag(BigInt(flag));
  });

  return {
    hex: lh.toHex('0x'),
  };
};
