/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import type { UniqueIdentifier } from '@dnd-kit/core';
import type {
  AnimatedPropertiesJSON,
  AnimatedPropertyJSON,
  BaseEvent,
  BatchEvent,
  GroupShapeJSON,
  LayerJSON,
  MaskJSON,
  PrecompositionAsset,
  SceneJSON,
  ShapeJSON,
  ShapeLayerJSON,
  Timeline,
  UpdateDataEvent,
} from '@lottiefiles/toolkit-js';
import { LayerType, AVLayer, PropertyType, ShapeType, Scene } from '@lottiefiles/toolkit-js';
import { clamp } from 'lodash-es';

import { flattenTree } from './TimelineLayerPanel/DraggableWrapper/utilities';

import { formatTime } from '~/features/timeline';
// eslint-disable-next-line no-restricted-imports
import {
  frameStrRegex,
  secondFrameRegex,
  secondsRegex,
  secondStrRegex,
  timeRegex,
} from '~/features/timeline/components/Timeline/constant';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getPlaybackParameters } from '~/lib/eventHandler/playback';
import type { LayerUIMap } from '~/lib/layer';
import { layerMap as layerUIMap } from '~/lib/layer';
import { getActiveScene, setDuration, setPrecompDuration, stateHistory, toolkit } from '~/lib/toolkit';
import { AppearanceTypes } from '~/lib/toolkit/appearance';
import { useCreatorStore } from '~/store';
import type { Optional } from '~/types';

// Decides the order of animated properties displayed within the timeline.
const ANIMATED_SORT_ORDER = [
  PropertyType.POSITION,
  PropertyType.SCALE,
  PropertyType.SIZE,
  PropertyType.FILL_COLOR,
  PropertyType.OPACITY,
  PropertyType.ROTATION,
  PropertyType.STROKE_COLOR,
  PropertyType.STROKE_WIDTH,
  PropertyType.ROUNDNESS,
  PropertyType.NUMBER_OF_POINTS,
  PropertyType.INNER_RADIUS,
  PropertyType.OUTER_RADIUS,
  PropertyType.INNER_ROUNDNESS,
  PropertyType.OUTER_ROUNDNESS,
  PropertyType.EXPANSION,
];

export const getAnimatedProperties = (
  animatedProperties: AnimatedPropertiesJSON,
): [Record<string, AnimatedPropertyJSON>, boolean] => {
  let hasAnimated = false;
  const animated = Object.keys(animatedProperties)
    .filter((key) => animatedProperties[key as PropertyType].isAnimated)
    .reduce(
      (obj, key) => {
        hasAnimated = true;
        obj[key] = animatedProperties[key as PropertyType];

        return obj;
      },
      {} as Record<string, AnimatedPropertyJSON>,
    );

  return [animated, hasAnimated];
};

const getKeyFrameIds = (animatedProperties: AnimatedPropertiesJSON): string[] => {
  let kfIds: string[] = [];

  kfIds = Object.keys(animatedProperties)
    .filter((key) => animatedProperties[key as PropertyType].isAnimated)
    .reduce((arr: string[], key) => {
      const animatedPropertiesKey = animatedProperties[key as PropertyType];

      if (animatedPropertiesKey.keyFrames.length > 0) {
        const frameIds = animatedPropertiesKey.keyFrames.map((apk) => apk.frameId);

        if (frameIds.length > 0) {
          arr.push(...(frameIds as string[]));
        }
      }

      return arr;
    }, []);

  return kfIds;
};

export const getNestedAnimatedKeyFrames = (
  keyframesIds: string[],
  layer: ShapeLayerJSON | ShapeJSON | LayerJSON,
): void => {
  const keyframes = getKeyFrameIds(layer.animatedProperties);

  if (keyframes.length > 0) {
    keyframes.map((kf) => keyframesIds.push(kf));
  }
  if ('shapes' in layer && layer.shapes.length > 0) {
    layer.shapes.map((layerShape: ShapeLayerJSON | ShapeJSON | LayerJSON) => {
      return getNestedAnimatedKeyFrames(keyframesIds, layerShape);
    });
  }
};

const updateLayerMap = (
  layer: ShapeLayerJSON | ShapeJSON | LayerJSON,
  layerMap: LayerUIMap,
  level: number,
  descendant: string[],
  children: string[],
  parent: string[],
  last: boolean,
): void => {
  const { animatedProperties } = layer;

  const [animated, hasAnimated] = getAnimatedProperties(animatedProperties);

  // eslint-disable-next-line prefer-const
  let frameIds: string[] = [];

  const isNullLayer = layer.type === 'GROUP' && 'effects' in layer;

  if ((level === 0 && ['SHAPE', 'PRECOMPOSITION'].includes(layer.type)) || isNullLayer) {
    getNestedAnimatedKeyFrames(frameIds, layer);
  }

  const layerUI: LayerUI = {
    type: 'layer',
    animated: Object.keys(animated)
      .map((key) => ({
        type: key,
        id: animated[key]?.id || '',
      }))
      .sort((alpha, beta) => {
        let aOrd = ANIMATED_SORT_ORDER.indexOf(alpha.type as PropertyType);
        let bOrd = ANIMATED_SORT_ORDER.indexOf(beta.type as PropertyType);

        // If a or b wasn't found in the ANIMATED_SORT_ORDER list, push them to the bottom
        if (aOrd === -1) {
          aOrd = ANIMATED_SORT_ORDER.length;
        }

        if (bOrd === -1) {
          bOrd = ANIMATED_SORT_ORDER.length;
        }

        return aOrd - bOrd;
      }),
    isAppearance: AppearanceTypes.includes(layer.type as ShapeType),
    isHidden: layer.properties[PropertyType.IS_HIDDEN] as boolean,
    matteMode: layer.properties.tt as number,
    isLocked: layer.data?.['isLocked'] as boolean,
    isFocused: layer.data?.['isFocused'] as boolean,
    appearanceType: layer.type,
    level,
    descendant,
    frameIds,
    children,
    parent,
    last,
  };

  layerMap.set(layer.id, layerUI);

  // Animated Properties
  if (hasAnimated) {
    const length = Object.keys(animated).length;

    Object.keys(animated).forEach((key, i) => {
      const animatedProp = animated[key];

      if (animatedProp && animatedProp.id) {
        const animId = animatedProp.id;

        const animatedUI: LayerUI = {
          type: 'animated',
          parent: [...parent, layer.id],
          animated: [],
          level,
          children: [],
          descendant: [],
          last: length === i + 1,
          frameIds: [],
        };

        layerMap.set(animId, animatedUI);
      }
    });
  }
};

type OptionalShapeLayer = Optional<ShapeLayerJSON, 'shapes'>;

const traverseShape = (
  layer: OptionalShapeLayer | ShapeJSON | LayerJSON | MaskJSON | null,
  layerMap: LayerUIMap,
  parent: string[],
  level: number,
  isLastChild: boolean,
): string[] => {
  if (!layer) return [];

  const shapeLayer = layer as OptionalShapeLayer;

  // Save currentParent to be store in Layer Map
  const currentParent = [...parent];

  parent.push(layer.id);

  // Get children
  const descendant: string[] = [];
  const children: string[] = [];

  // Store animated property as children as well ??
  const [animated, hasAnimated] = getAnimatedProperties(layer.animatedProperties);

  // Store animated value
  if (hasAnimated) {
    Object.keys(animated).forEach((key) => {
      const animatedProp = animated[key];

      if (animatedProp && animatedProp.id) {
        descendant.push(animatedProp.id);
      }
    });
  }

  // Traverse shape and layers
  if (shapeLayer.shapes) {
    const filteredShapes = shapeLayer.shapes.filter(
      (shape) =>
        (shape.type !== ShapeType.FILL && shape.type !== ShapeType.STROKE) ||
        Object.keys(shape.animatedProperties).some((key) => shape.animatedProperties[key as PropertyType].isAnimated),
    );

    const lastId = filteredShapes[filteredShapes.length - 1]?.id;
    const shapes = shapeLayer.shapes;

    shapes.forEach((_layer) => {
      // Check whether it is the last node. If there is a mask layer, it is always the last layer
      const last = _layer.id === lastId && !layer.masks?.length;
      const newParent = [...parent];
      const descendantPath = traverseShape(_layer, layerMap, newParent, level + 1, last);

      children.push(_layer.id);
      descendant.push(...descendantPath);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (shapeLayer.masks) {
    const masks = shapeLayer.masks;

    const lastId = masks[masks.length - 1]?.id;

    masks.forEach((_layer) => {
      // Check whether it is the last node
      const last = _layer.id === lastId;
      const newParent = [...parent];
      const descendantPath = traverseShape(_layer, layerMap, newParent, level + 1, last);

      children.push(_layer.id);
      descendant.push(...descendantPath);
    });
  }

  updateLayerMap(layer, layerMap, level, [...descendant], [...children], [...currentParent], isLastChild);
  // Insert into children list
  descendant.unshift(layer.id);

  return descendant;
};

export interface AnimatedUI {
  id: string;
  type: string;
}

export interface LayerUI {
  // animated properties info
  animated: AnimatedUI[];

  appearanceType?: LayerType | ShapeType;

  // children of the node
  children: string[];

  // all the descendants from the node
  descendant: string[];

  frameIds: string[];

  // whether the layer is appearance
  isAppearance?: boolean;

  isFocused?: boolean;

  isHidden?: boolean;

  isLocked?: boolean;

  // whether the layer is the last node of the level
  last: boolean;

  // layer level or depth
  level: number;

  matteMode?: number;

  // parent list of the layer
  parent: string[];

  // the layer type
  type: string;
}

export const getLayerMap = (json: SceneJSON): LayerUIMap => {
  const layerMap = new Map<string, LayerUI>();

  json.allLayers.forEach((layer) => {
    if (
      layer.type === 'SHAPE' ||
      layer.type === 'PRECOMPOSITION' ||
      layer.type === 'SOLID' ||
      layer.type === 'IMAGE' ||
      layer.type === 'GROUP'
    ) {
      traverseShape(layer, layerMap, [], 0, true);
    }
  });

  return layerMap;
};

export const getFlattenLayers = (layers: LayerJSON[], activeId: string): LayerJSON[] => {
  const removeLayersChildren = (items: any, ids: string[]): LayerJSON[] => {
    const excludeParentIds = [...ids];

    return items.filter((item: any) => {
      if (item.parentId && excludeParentIds.includes(item.parentId)) {
        if (item.shapes?.length) {
          excludeParentIds.push(item.id);
        }
      }

      return true;
    });
  };

  const flattenedTree = flattenTree(layers as any);

  const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>(
    (acc, { collapsed, id, shapes }) => (collapsed && shapes?.length ? [...acc, id] : acc),
    [],
  );

  const activeChildren = activeId ? [activeId, ...collapsedItems] : collapsedItems;

  return removeLayersChildren(flattenedTree, activeChildren as string[]);
};

export const resetTimelineLayerPopup = (): void => {
  const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;

  setTimelineContext({
    mousePos: { x: 0, y: 0 },
  });
};

export enum TrimType {
  INPOINT = 'inpoint',
  OUTPOINT = 'outpoint',
}

export const trimInOutpointTo = (type: TrimType, destinationFrame: number, allLayers?: boolean): void => {
  let layerIds: string[];

  if (allLayers) {
    const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

    let layers = useCreatorStore.getState().toolkit.json?.allLayers || [];

    if (selectedPrecompositionId !== null) {
      const precomNode = toolkit.getNodeById(selectedPrecompositionId);

      layers = (precomNode as PrecompositionAsset).state.allLayers;
    }

    layerIds = layers.map((layer) => layer.id);
  } else {
    const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;

    layerIds = selectedNodesInfo.map((nodeInfo) => nodeInfo.nodeId);
  }

  layerIds.forEach((nodeId) => {
    const layer = toolkit.getNodeById(nodeId);

    if (!(layer instanceof AVLayer)) return;

    if (type === TrimType.INPOINT) {
      // new inpoint should be less than outpoint
      if (destinationFrame >= layer.endFrame) {
        return;
      }
      layer.setStartFrame(destinationFrame);
    }

    if (type === TrimType.OUTPOINT) {
      // new outpoint should be greater than inpoint
      if (destinationFrame <= layer.startFrame) {
        return;
      }
      layer.setEndFrame(destinationFrame);
    }
  });
};

export const saveWorkAreaStart = (): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  if (scene) {
    scene.setData('workAreaStart', useCreatorStore.getState().timeline.workAreaFrameStart);
  }
};

export const saveWorkAreaEnd = (): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  if (scene) {
    scene.setData('workAreaEnd', useCreatorStore.getState().timeline.workAreaFrameEnd);
  }
};

export const trimWorkAreaStartToPlayHead = (): void => {
  const setWorkAreaFrameStart = useCreatorStore.getState().timeline.setWorkAreaFrameStart;
  const setWorkAreaFrameEnd = useCreatorStore.getState().timeline.setWorkAreaFrameEnd;
  const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

  stateHistory.beginAction();

  let newWorkAreaFrameStart = currentFrame;
  const workAreaFrameStart = useCreatorStore.getState().timeline.workAreaFrameStart;
  const workAreaFrameEnd = useCreatorStore.getState().timeline.workAreaFrameEnd;

  if (newWorkAreaFrameStart > workAreaFrameEnd) {
    const distance = workAreaFrameEnd - workAreaFrameStart;
    let newWorkAreaFrameEnd = newWorkAreaFrameStart + distance;

    const { duration, fps } = getPlaybackParameters();
    const totalFrames = duration * fps;

    if (newWorkAreaFrameEnd > totalFrames) {
      newWorkAreaFrameEnd = totalFrames;
    }

    setWorkAreaFrameEnd(newWorkAreaFrameEnd);
    saveWorkAreaEnd();
  }

  if (workAreaFrameEnd === currentFrame) {
    newWorkAreaFrameStart = currentFrame - 1;
  }

  setWorkAreaFrameStart(newWorkAreaFrameStart);
  saveWorkAreaStart();

  stateHistory.endAction();
};

export const trimWorkAreaEndToPlayHead = (): void => {
  const workAreaFrameStart = useCreatorStore.getState().timeline.workAreaFrameStart;
  const workAreaFrameEnd = useCreatorStore.getState().timeline.workAreaFrameEnd;
  const setWorkAreaFrameStart = useCreatorStore.getState().timeline.setWorkAreaFrameStart;
  const setWorkAreaFrameEnd = useCreatorStore.getState().timeline.setWorkAreaFrameEnd;
  const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

  stateHistory.beginAction();

  let newWorkAreaFrameEnd = currentFrame;

  if (newWorkAreaFrameEnd < workAreaFrameStart) {
    const distance = workAreaFrameEnd - workAreaFrameStart;
    let newWorkAreaFrameStart = newWorkAreaFrameEnd - distance;

    if (newWorkAreaFrameStart < 0) {
      newWorkAreaFrameStart = 0;
    }

    setWorkAreaFrameStart(newWorkAreaFrameStart);
    saveWorkAreaStart();
  }

  if (workAreaFrameStart === currentFrame) {
    newWorkAreaFrameEnd = currentFrame + 1;
  }

  setWorkAreaFrameEnd(newWorkAreaFrameEnd);
  saveWorkAreaEnd();

  stateHistory.endAction();
};

export const trimSceneToWorkArea = (): void => {
  const workAreaFrameStart = useCreatorStore.getState().timeline.workAreaFrameStart;
  const workAreaFrameEnd = useCreatorStore.getState().timeline.workAreaFrameEnd;

  stateHistory.beginAction();

  const durationFrames = workAreaFrameEnd - workAreaFrameStart;

  trimInOutpointTo(TrimType.INPOINT, 0, true);
  trimInOutpointTo(TrimType.OUTPOINT, durationFrames, true);

  const scene = getActiveScene(toolkit);

  if (!scene) {
    return;
  }

  const durationSeconds = durationFrames / scene.timeline.frameRate;

  if (scene instanceof Scene) {
    setDuration(scene, durationSeconds);
  } else {
    setPrecompDuration(scene, durationSeconds);
  }

  useCreatorStore.getState().timeline.setWorkAreaFrameStart(0);
  saveWorkAreaStart();
  useCreatorStore.getState().timeline.setWorkAreaFrameEnd(durationFrames);
  saveWorkAreaEnd();

  emitter.emit(EmitterEvent.TIMELINE_DURATION_UPDATED);

  stateHistory.endAction();
};

export const restoreWorkAreaFromHistory = (
  historyItem: BaseEvent | UpdateDataEvent | BatchEvent,
  isUndo: boolean,
): void => {
  const payloads: Array<Record<string, unknown>> = [];

  const addPayload = (item: UpdateDataEvent): void => {
    const data = isUndo ? item.data.previousData : item.data.currentData;

    payloads.push(data);
  };

  if (historyItem.type === 'updateData') {
    addPayload(historyItem as UpdateDataEvent);
  }
  if (historyItem.type === 'batch') {
    (historyItem as BatchEvent).data.events.forEach((batchEvent: BaseEvent) => {
      if (batchEvent.type === 'updateData') {
        addPayload(batchEvent as UpdateDataEvent);
      }
    });
  }

  payloads.forEach((data) => {
    if (Object.hasOwn(data, 'workAreaStart')) {
      useCreatorStore.getState().timeline.setWorkAreaFrameStart((data['workAreaStart'] as number) || 0);
    }
    if (Object.hasOwn(data, 'workAreaEnd')) {
      const { duration, fps } = getPlaybackParameters();
      const totalFrames = duration * fps;

      useCreatorStore.getState().timeline.setWorkAreaFrameEnd((data['workAreaEnd'] as number) || totalFrames);
    }
  });
};

export const resetWorkArea = (): void => {
  stateHistory.beginAction();

  useCreatorStore.getState().timeline.setWorkAreaFrameStart(0);
  saveWorkAreaStart();

  const { duration, fps } = getPlaybackParameters();
  const totalFrames = duration * fps;

  useCreatorStore.getState().timeline.setWorkAreaFrameEnd(totalFrames);
  saveWorkAreaEnd();

  stateHistory.endAction();
};

// the type of item in the timeline component, as we have a mix of layers and animated properties
export enum CreatorLayerType {
  ANIMATED_PROPERTY = 'ANIMATED_PROPERTY',
  GROUP = 'GROUP',
  MASK = 'MASK',
  SHAPE = 'SHAPE',
}

export type CreatorLayerItem = CreatorLayerTypeItem | CreatorAnimatedTypeItem;

interface CreatorBaseItem {
  creatorType: CreatorLayerType;
  id: string;
  layer: OptionalShapeLayer | GroupShapeJSON | ShapeJSON;
  parentId: string;
}

export interface CreatorAnimatedTypeItem extends CreatorBaseItem {
  type: string;
}

export interface CreatorLayerTypeItem extends CreatorBaseItem {}

const getAnimatedCreatorLayers = (
  shapeLayer: OptionalShapeLayer | ShapeJSON | MaskJSON,
  parentId: string,
): CreatorLayerItem[] => {
  const [animated, hasAnimated] = getAnimatedProperties(shapeLayer.animatedProperties);

  if (hasAnimated) {
    return Object.keys(animated).map((key) => {
      const animatedProperty = animated[key] as AnimatedPropertyJSON;

      return {
        creatorType: CreatorLayerType.ANIMATED_PROPERTY,
        id: animatedProperty.id,
        layer: shapeLayer,
        parentId,
        type: key,
      };
    });
  }

  return [];
};

const processCreatorLayer = (
  creatorLayers: CreatorLayerItem[],
  layer: OptionalShapeLayer | GroupShapeJSON | LayerJSON,
  parentId: string,
): void => {
  const shapeLayer = layer as OptionalShapeLayer;

  const shapes = shapeLayer.shapes || [];

  const layerTypeItem = {
    creatorType: CreatorLayerType.GROUP,
    id: shapeLayer.id,
    layer: shapeLayer,
    parentId,
  } as CreatorLayerTypeItem;

  creatorLayers.push(layerTypeItem);
  const animatedCreatorLayers = getAnimatedCreatorLayers(shapeLayer, shapeLayer.id);

  if (animatedCreatorLayers.length) {
    creatorLayers.push(...animatedCreatorLayers);
  }

  shapes.forEach((shape: ShapeJSON) => {
    if (shape.type === ShapeType.GROUP) {
      processCreatorLayer(creatorLayers, shape as GroupShapeJSON, shapeLayer.id);
    } else {
      const animatedShapeCreatorLayers = getAnimatedCreatorLayers(shape, shapeLayer.id);

      if (
        (shape.type !== ShapeType.FILL &&
          shape.type !== ShapeType.STROKE &&
          shape.type !== ShapeType.GRADIENT_FILL &&
          shape.type !== ShapeType.GRADIENT_STROKE) ||
        animatedShapeCreatorLayers.length
      ) {
        const layerShapeTypeItem = {
          creatorType: CreatorLayerType.SHAPE,
          id: shape.id,
          layer: shape,
          parentId: shapeLayer.id,
        } as CreatorLayerTypeItem;

        creatorLayers.push(layerShapeTypeItem);
        if (animatedShapeCreatorLayers.length) {
          creatorLayers.push(...animatedShapeCreatorLayers);
        }
      }
    }
  });

  if ('masks' in layer) {
    layer.masks.forEach((mask: MaskJSON) => {
      const animatedShapeCreatorLayers = getAnimatedCreatorLayers(mask, shapeLayer.id);

      const layerShapeTypeItem = {
        creatorType: CreatorLayerType.MASK,
        id: mask.id,
        layer: mask,
        parentId: shapeLayer.id,
      } as CreatorLayerTypeItem;

      creatorLayers.push(layerShapeTypeItem);
      if (animatedShapeCreatorLayers.length) {
        creatorLayers.push(...animatedShapeCreatorLayers);
      }
    });
  }
};

export const getCreatorLayers = (
  layers: LayerJSON[],
): { items: CreatorLayerItem[]; itemsById: Record<string, CreatorLayerItem> } => {
  const creatorLayers: CreatorLayerItem[] = [];

  layers.forEach((layer: LayerJSON) => {
    processCreatorLayer(creatorLayers, layer, '');
  });

  const creatorLayersMap: Record<string, CreatorLayerItem> = creatorLayers.reduce(
    (acc, layer) => {
      acc[layer.id] = layer;

      return acc;
    },
    {} as Record<string, CreatorLayerItem>,
  );

  return {
    items: creatorLayers,
    itemsById: creatorLayersMap,
  };
};

const getChildLayersRecursive = (ids: string[]): string[] => {
  return ids.reduce<string[]>((acc, id) => {
    const layer = layerUIMap.get(id);

    if (layer?.children.length) {
      acc.push(id);
      acc.push(...getChildLayersRecursive(layer.children));
    }

    return acc;
  }, []);
};

export const onToggleExpandAllChildren = (): void => {
  const selectedNodesIds = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);
  const expandedLayerIds = useCreatorStore.getState().timeline.expandedLayerIds;
  const setExpandedLayerIds = useCreatorStore.getState().timeline.setExpandedLayerIds;

  const layersToToggle = getChildLayersRecursive(selectedNodesIds);

  const isExpanded = expandedLayerIds.some((id) => layersToToggle.includes(id));

  // If any of the layersToToggle layers are expanded, collapse them all
  setExpandedLayerIds(!isExpanded, layersToToggle);
};

const padZeros = (inputString: string | number, desiredLength = 6): string => {
  const _inputString = inputString.toString();

  const numZeros = Math.max(0, desiredLength - _inputString.length);

  return '0'.repeat(numZeros) + _inputString;
};

const formatNewValue = (inputParam: [number, number, number], currentFPS: number): string => {
  let [inputMinutes, inputSeconds, inputFrames] = inputParam;

  if (inputFrames >= currentFPS) {
    inputSeconds += Math.floor(inputFrames / currentFPS);
    inputFrames %= currentFPS;
  }
  if (inputSeconds >= 60) {
    inputMinutes += Math.floor(inputSeconds / 60);
    inputSeconds %= 60;
  }

  return `${padZeros(inputMinutes, 2)}:${padZeros(inputSeconds, 2)}:${padZeros(inputFrames, 2)}`;
};

const formatFormattedTime = (time: string, fps: number): Record<string, number> => {
  const [minutes, seconds, frames] = time.split(':').map(Number) as [number, number, number];

  const totalSeconds = minutes * 60 + seconds + frames / fps;

  const second = totalSeconds;
  const frame = totalSeconds / fps;

  return { second, frame };
};

export const parseFrames = (inputValue: string, currentFPS: number, duration: number): number | null => {
  let newTime = null;
  let totalFrames = null;

  const inputValuePureNumber = /^-?\d+$/u.test(inputValue);

  const conditionOne = secondsRegex.test(inputValue);
  const conditionTwo = timeRegex.test(inputValue);
  const conditionThree = secondFrameRegex.test(inputValue);
  const conditionFour = secondStrRegex.test(inputValue);
  const conditionFive = frameStrRegex.test(inputValue);

  if (!conditionOne && !conditionTwo && !conditionThree && !conditionFour && !conditionFive) {
    return null;
  }

  if (inputValuePureNumber || conditionFour || conditionFive) {
    // 1234 => 00:12:34
    let inputString = inputValue.toString().padStart(5, '0');

    if (conditionFour) {
      // 2 => 00:02:00
      const secondRegex = secondStrRegex.exec(inputValue);

      if (secondRegex) {
        const inputSecond = parseInt(secondRegex[1] as string, 10);

        inputString = `${inputSecond}00`.toString().padStart(5, '0');
      }
    } else if (conditionFive) {
      // 2f => 00:00:02
      const frameRegex = frameStrRegex.exec(inputValue);

      if (frameRegex) {
        const inputFrame = parseInt(frameRegex[1] as string, 10);

        inputString = `00${inputFrame}`.toString().padStart(5, '0');
      }
    }

    const pzInputString = padZeros(inputString, 6);

    const inputParam = [];
    const indices = [0, 2, 4];

    for (const index of indices) {
      const first = pzInputString[index];
      const second = pzInputString[index + 1];

      if (first && second) {
        inputParam.push(Number(`${first}${second}`));
      }
    }

    if (inputParam.length === 3) {
      const testInputString = formatNewValue(inputParam as [number, number, number], currentFPS);

      const { frame, second } = formatFormattedTime(testInputString, currentFPS);

      newTime = Number(second) + Number(frame) / currentFPS;

      totalFrames = duration * currentFPS;
    }
  } else {
    const hasSemicolon = inputValue.includes(':');

    let newValue = inputValue;

    if (hasSemicolon) {
      const semiColonList = inputValue.split(':');
      const countSemicolon = semiColonList.length - 1;

      if (countSemicolon === 1) {
        semiColonList.unshift('0');
        newValue = formatNewValue(semiColonList.map(Number) as [number, number, number], currentFPS);
      }
    }

    const fTime = formatTime(newValue, currentFPS);

    const { frame, second } = formatFormattedTime(fTime, currentFPS);

    newTime = Number(second) + Number(frame) / currentFPS;

    totalFrames = duration * currentFPS;
  }

  return clamp((newTime || 0) * currentFPS, 0, (totalFrames || 0) - 1);
};

export const getElementWidth = (querySelector: string): number => {
  const rect = document.querySelector(querySelector)?.getBoundingClientRect();

  return rect?.width || 0;
};

export const getVisibleKeyFrames = (timeline: Timeline | undefined, expandedLayerIds: string[]): number[] => {
  let visibleKeyFrames: number[] = [];

  if (!timeline) return [];
  visibleKeyFrames = Array.from(timeline.tracks)
    // filter out tracks that belong to animated properties of unexpanded layers
    .filter((track) => {
      // Skip tracks without keyframes
      if (track.keyFrames.length === 0) {
        return false;
      }

      if (track.property.parent) {
        let layerId = track.property.parent.nodeId;
        let layerUI = layerUIMap.get(layerId);

        if (!layerUI) {
          return false;
        }

        if (layerUI.appearanceType === LayerType.PRECOMPOSITION) {
          return true;
        }
        // If the layerUI object for the node that the animated property
        // is attached to has no children, it means that node isn't
        // collapsible
        if (layerUI.children.length === 0) {
          do {
            const parent = layerUI.parent.at(-1);

            if (!parent) {
              return false;
            }

            if (parent) {
              layerId = parent;
              layerUI = layerUIMap.get(parent);
              if (!layerUI) {
                return false;
              }
            }
          } while (layerUI.children.length === 0);
        }

        return expandedLayerIds.includes(layerId);
      }

      return false;
    })
    // Unroll the keyframes
    .flatMap((track) => track.keyFrames)
    // We're only interested in the frame numbers
    .map((keyFrame) => keyFrame.frame)
    // sort them
    .sort((alpha, beta) => alpha - beta)
    // remove duplicates
    .filter((frame, index, list) => list.indexOf(frame) === index);

  return visibleKeyFrames;
};
