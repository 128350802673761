/**
 * Copyright 2024 Design Barn Inc.
 */

import type { Padding } from '@floating-ui/utils';
import { getPaddingObject } from '@floating-ui/utils';

import type { HightlightAreaBounds, StepOptions } from './Step';

import { SettingTabs } from '~/components/Layout/Property';

interface IStep extends StepOptions {
  getHighlightAreaBounds: () => HightlightAreaBounds | null;
  iframeUrl: string;
  run?: () => void;
  title: string;
}

const getBoundingClientRectBySelector = (
  id: string,
  padding: Padding = {},
): ReturnType<IStep['getHighlightAreaBounds']> => {
  const element = document.querySelector(id);

  if (element) {
    const domRect = element.getBoundingClientRect();

    const paddingObject = getPaddingObject(padding);

    return {
      top: domRect.top - paddingObject.top,
      left: domRect.left - paddingObject.left,
      width: domRect.width + paddingObject.left + paddingObject.right,
      height: domRect.height + paddingObject.top + paddingObject.bottom,
    };
  }

  return null;
};

export const steps: IStep[] = [
  {
    title: 'Add assets',
    iframeUrl: 'https://understanding-actions-530813.framer.app/assets',
    getHighlightAreaBounds: () =>
      getBoundingClientRectBySelector('#add-assets', {
        left: 4,
        right: -2,
        top: 4,
        bottom: 4,
      }),
  },
  {
    title: 'Plugins',
    iframeUrl: 'https://understanding-actions-530813.framer.app/plugin',
    offsetOptions: {
      mainAxis: 16,
      crossAxis: -16,
    },
    getHighlightAreaBounds: () =>
      getBoundingClientRectBySelector('#plugins', {
        left: 4,
        right: -2,
        top: 4,
        bottom: 4,
      }),
  },
  {
    title: 'Toolbar',
    iframeUrl: 'https://understanding-actions-530813.framer.app/toolbar',
    placement: 'bottom',
    offsetOptions: {
      crossAxis: 144,
      mainAxis: 16,
    },
    getHighlightAreaBounds: () => {
      const element = document.querySelector('#toolbar');

      if (element) {
        const firstChildRect = element.firstElementChild?.getBoundingClientRect();
        const lastChildRect = element.lastElementChild?.getBoundingClientRect();

        if (firstChildRect && lastChildRect) {
          return {
            top: firstChildRect.top - 4,
            left: firstChildRect.left - 4,
            width: lastChildRect.left + lastChildRect.width - firstChildRect.left + 8,
            height: firstChildRect.height + 8,
          };
        }
      }

      return null;
    },
  },
  {
    title: 'Property panel',
    iframeUrl: 'https://understanding-actions-530813.framer.app/property-panel',
    placement: 'left-start',
    run: () => {
      document.getElementById(SettingTabs[0].id)?.click();
    },
    getHighlightAreaBounds: () =>
      getBoundingClientRectBySelector('#right-panel', {
        top: 2,
        left: 2,
        right: -4,
        bottom: -16,
      }),
  },
  {
    title: 'Animation presets',
    iframeUrl: 'https://understanding-actions-530813.framer.app/presets',
    placement: 'left-start',
    run: () => {
      document.getElementById(SettingTabs[1].id)?.click();
    },
    getHighlightAreaBounds: () =>
      getBoundingClientRectBySelector('#right-panel', {
        top: 2,
        left: 2,
        right: -4,
        bottom: -16,
      }),
  },
  {
    title: 'Timeline',
    iframeUrl: 'https://understanding-actions-530813.framer.app/timeline',
    placement: 'top',
    getHighlightAreaBounds: () =>
      getBoundingClientRectBySelector('#TimelineContainer', {
        top: 40,
      }),
  },
];
