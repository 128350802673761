/**
 * Copyright 2022 Design Barn Inc.
 */

import type {
  BaseLayerJSON,
  AVLayer,
  DagNodeJSON,
  ShapeJSON,
  ShapeLayerJSON,
  Scene as ToolkitScene,
  PrecompositionAssetJSON,
  AnimatedProperty,
  PrecompositionAsset,
  Keyframe,
  Scalar,
  PrecompositionLayerJSON,
  DagNode,
  AnimatedPropertyJSON,
  PathShape,
} from '@lottiefiles/toolkit-js';
import {
  TrackMatteType,
  ShapeType,
  AssetType,
  LayerType,
  ShapeLayer,
  PrecompositionLayer,
  deserialize,
  Shape,
  DagNodeType,
  MaskModeType,
  EllipseShape,
  Vector,
  Size,
} from '@lottiefiles/toolkit-js';
import { Vector3, type Mesh, type Object3D, type Scene } from 'three';
import { v4 as uuidv4 } from 'uuid';

import { isPath } from '../3d/threeFactory';
import type { CObject3D } from '../types/object';
import { CMesh } from '../types/object';

import type { Coordinate } from '~/features/menu';
import { uploadSVG } from '~/features/upload';
import { canvasMap, removeCanvasMapItem, removeRenderRangeMapItem } from '~/lib/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { renameLayers } from '~/lib/function/auto-layer-rename';
import { layerMap } from '~/lib/layer';
import { Box3 } from '~/lib/threejs/Box3';
import type { TransformControls } from '~/lib/threejs/TransformControls';
import {
  getActiveScene,
  getNextNestedSceneName,
  getSceneSize,
  renameNestedScenes,
  setNestedSceneSize,
  stateHistory,
  toolkit,
} from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { LayerType as LayerTypes } from '~/store/constant';
import { forEachAsset, uniqueName } from '~/utils/copy-paste-utils';

const CLIPBOARD_IDENTIFIER = 'lottiefiles/creator';

const IS_FIREFOX = navigator.userAgent.toLowerCase().includes('firefox');

const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;
const setSelectedIdsAfterCreated = useCreatorStore.getState().ui.setSelectedIdsAfterCreated;

export enum PasteMode {
  PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT,
  PASTE_WITH_CLONED_ASSETS,
}

enum ClipboardPermission {
  Read = 'clipboard-read',
  Write = 'clipboard-write',
}

interface CreatorClipboardNodeEntry {
  data: DagNodeJSON;
  nodeType: DagNodeType;
  parentNodeType: string;
  parentType: string;
}

interface CreatorClipboardKeyframeEntry {
  data: ReturnType<typeof Keyframe.prototype.toJSON>;
  sourceType: NonNullable<typeof Keyframe.prototype.parentProperty>['type'];
}

export interface CreatorClipboard {
  keyframes: CreatorClipboardKeyframeEntry[];
  // This is just an identifier to let creator know that it can assume that the
  // content in the clipboard is pastable
  meta: typeof CLIPBOARD_IDENTIFIER;
  nodes: CreatorClipboardNodeEntry[];
}

export default class Editor {
  private readonly _objectContainer: CObject3D;

  private readonly _scene: Scene;

  private _toolkitScene: ToolkitScene | null = null;

  private readonly _transformControls: TransformControls | null = null;

  public constructor(scene: Scene, objectContainer: CObject3D, transformControls: TransformControls | null) {
    this._scene = scene;
    this._objectContainer = objectContainer;
    this._transformControls = transformControls;

    this._addEventListeners();
  }

  public addNestedScene(): void {
    const scene: ToolkitScene | PrecompositionAsset | null = getActiveScene(toolkit);

    if (!scene) {
      return;
    }

    stateHistory.beginAction();

    const newScene = toolkit.createScene();

    const asset = newScene.toPrecompositionAsset();

    const name = getNextNestedSceneName();

    asset.setName(name);
    asset.timeline.setStartAndEndFrame(scene.timeline.startFrame, scene.timeline.endFrame);
    asset.timeline.setFrameRate(scene.timeline.frameRate);
    asset.timeline.setDuration(scene.timeline.duration);

    const layer = scene.createPrecompositionLayer();

    layer.setName(name);
    layer.setStartAndEndFrame(0, scene.timeline.endFrame);
    layer.setTimelineOffset(0);
    layer.setAsset(asset);

    const selectedNodeInfo = useCreatorStore.getState().ui.selectedNodesInfo;

    let index = 0;

    if (selectedNodeInfo.length > 0 && selectedNodeInfo[0]) {
      const layerUI = layerMap.get(selectedNodeInfo[0].nodeId);

      if (layerUI) {
        const id = layerUI.parent.length ? (layerUI.parent[0] as string) : selectedNodeInfo[0].nodeId;
        const node = useCreatorStore.getState().toolkit.getNodeByIdOnly(id);

        if (node) {
          index = (node as ShapeLayer).drawOrder;
        }
      }
    }
    layer.setDrawOrder(index);

    toolkit.removeScene(newScene);

    const size = getSceneSize(scene);

    setNestedSceneSize(asset, size);

    renameNestedScenes();

    emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
    emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);

    addToSelectedNodes([layer.nodeId], true);

    stateHistory.endAction();
  }

  public addObject(object: CObject3D | CMesh | Object3D | Mesh, parent?: CObject3D | Scene): void {
    if (parent) {
      parent.add(object);
      object.parent = parent;
    } else this._objectContainer.add(object);
  }

  public breakSelectedNestedScenes(): void {
    if (this._toolkitScene) {
      stateHistory.beginAction();

      const selectedNodeInfo = useCreatorStore.getState().ui.selectedNodesInfo;
      const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
      const currentNodes = selectedNodeInfo.map((nodeInfo) => getNodeByIdOnly(nodeInfo.nodeId as string));

      const removedReferenceLayerIds: string[] = [];

      toolkit.batch(() => {
        currentNodes.forEach((currentNode) => {
          if (currentNode instanceof PrecompositionLayer && currentNode.asset) {
            currentNode.asset.layers.forEach((layer, index: number) => {
              const cloned = layer.clone(this._toolkitScene as DagNode);

              (cloned as ShapeLayer).setDrawOrder(currentNode.drawOrder + index);
            });

            removedReferenceLayerIds.push(currentNode.nodeId);
            currentNode.removeFromGraph();

            if (currentNode.asset.referencedLayers.every((layer) => removedReferenceLayerIds.includes(layer.nodeId))) {
              currentNode.asset.removeFromGraph();
            }
          }
        });
      });

      useCreatorStore.getState().ui.removeSelectedNodes();

      emitter.emit(EmitterEvent.TOOLKIT_GET_LATEST);
      emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
      emitter.emit(EmitterEvent.CANVAS_REDRAW);

      stateHistory.endAction();
    }
  }

  public async checkClipboardPermissions(permissionName: ClipboardPermission): Promise<boolean> {
    try {
      const canWrite = await navigator.permissions.query({ name: permissionName as string as PermissionName });

      if (canWrite.state === 'denied') {
        // If the user has explicitly denied access to the clipboard.
        useCreatorStore.getState().ui.setAlert({
          text: 'Clipboard permission missing',
          alertColor: '#D92600',
          icon: 'error',
        });

        return false;
      }
      // In the "prompt" state there is still a chance the user will choose no
      // So this is an assumption

      return true;
    } catch (err) {
      // Something to keep note is a lot of firefox users change the useragent
      // They will have to use the proper useragent for creator
      if (IS_FIREFOX) {
        return true;
      }
      // An unexpected error occured while checking permissions

      return false;
    }
  }

  public async copyMask(): Promise<void> {
    const creatorState = useCreatorStore.getState();
    const selectedNodeInfo = creatorState.ui.selectedNodesInfo;
    const getNodeByIdOnly = creatorState.toolkit.getNodeByIdOnly;
    const setMaskCopied = creatorState.ui.setMaskCopied;

    if (selectedNodeInfo.length === 1) {
      const node = getNodeByIdOnly(selectedNodeInfo[0]?.nodeId as string) as DagNode;

      if (node.nodeType === DagNodeType.SHAPE) {
        setMaskCopied({
          bezier: (node as PathShape).toBezier(),
          nodeType: (node as PathShape).type as ShapeType,
          shapeAnimated:
            (node as PathShape).type === ShapeType.PATH ? (node.state as ShapeJSON).animatedProperties.sh : null,
          name: (node as PathShape).name,
        });
      } else if ('shapes' in node) {
        (node.shapes as Shape[]).forEach((sh) => {
          if (!isPath(sh)) return;
          setMaskCopied({
            bezier: (sh as PathShape).toBezier(),
            nodeType: sh.type as ShapeType,
            shapeAnimated: sh.type === ShapeType.PATH ? { ...(sh.state as ShapeJSON).animatedProperties.sh } : null,
            name: sh.name,
          });
        });
      } else setMaskCopied(null);
    }
  }

  public async copyObjects(): Promise<void> {
    const creatorState = useCreatorStore.getState();
    const selectedKeyframesIds = creatorState.timeline.selectedKeyframeIds;

    if (selectedKeyframesIds.length > 0) {
      type KeyframeType = ReturnType<typeof toolkit.getKeyframeById>;

      const jsonKeyframes = (
        selectedKeyframesIds
          .map<KeyframeType>((id): KeyframeType => {
            return toolkit.getKeyframeById(id);
          })
          .filter((kf) => {
            return kf !== null && kf.parentProperty !== null;
          }) as Array<
          NonNullable<KeyframeType> & { parentProperty: NonNullable<typeof Keyframe.prototype.parentProperty> }
        >
      )
        // sort the keyframes by ascending frame number
        .sort((first, second) => first.frame - second.frame)
        .map((kf) => {
          return {
            data: kf.toJSON(),
            sourceType: kf.parentProperty.type,
          } as CreatorClipboardKeyframeEntry;
        });

      await this._writeToClipboard({
        meta: CLIPBOARD_IDENTIFIER,
        nodes: [],
        keyframes: jsonKeyframes,
      });
    } else {
      let copiedToolkitIDs = [];

      if (this._transformControls?.nonTransformNodeSelected) {
        copiedToolkitIDs = useCreatorStore.getState().ui.selectedNodesInfo.map((item) => item.nodeId);
      } else {
        copiedToolkitIDs = this._transformControls?.lastSelectedObjectIDs as string[];
      }
      if (copiedToolkitIDs.length === 0) {
        return;
      }

      const itemsWithToolkitNode = (
        await Promise.all(
          copiedToolkitIDs.map(async (id) => {
            const node = this._toolkitScene?.getNodeById(id);

            if (!node) {
              return null;
            }

            const parent = node.parent;

            if (!parent) {
              return null;
            }

            return {
              data: node.serialize(null),
              nodeType: node.nodeType,
              toolkitNode: node,
              parentNodeType: parent.nodeType,
              parentType: (parent as AVLayer).type,
            };
          }),
        )
      ).filter((entry) => entry !== null) as Array<CreatorClipboardNodeEntry & { toolkitNode: DagNode }>;

      const isSimilar =
        itemsWithToolkitNode.length === 1 ||
        itemsWithToolkitNode
          .slice(1)
          .every((item) => (item.data as ShapeJSON).type === (itemsWithToolkitNode[0]?.data as ShapeJSON).type);

      let nodes: CreatorClipboardNodeEntry[];

      if (isSimilar) {
        nodes = itemsWithToolkitNode.map(({ toolkitNode: _, ...rest }) => rest);
      } else {
        // If there is any shape layer in the clipboard, the topmost level is a shape, otherwise a group
        const topmostLevel = itemsWithToolkitNode.find((node) => (node.data as ShapeLayerJSON).type === LayerType.SHAPE)
          ? LayerType.SHAPE
          : ShapeType.GROUP;

        nodes = itemsWithToolkitNode
          .map((item) => {
            if (
              (item.data as ShapeJSON).type === topmostLevel ||
              (item.data as PrecompositionAssetJSON).type === AssetType.PRECOMPOSITION
            ) {
              const { toolkitNode: _, ...rest } = item;

              return rest;
            } else {
              // Traverse up until a SHAPE or a gr node has been found
              // If none was found, exclude the node from copy
              let currentNode = item.toolkitNode.parent as AVLayer | undefined;

              if (!currentNode) {
                return null;
              }

              while (currentNode.type !== topmostLevel) {
                if (currentNode.parent) {
                  currentNode = currentNode.parent as AVLayer | undefined;
                }

                return null;
              }

              const parent = currentNode;

              return {
                data: currentNode.serialize(null),
                nodeType: currentNode.nodeType,
                parentNodeType: parent.nodeType,
                parentType: (parent as AVLayer).type,
              };
            }
          })
          .filter((item) => item !== null) as CreatorClipboardNodeEntry[];
      }

      await this._writeToClipboard({
        meta: CLIPBOARD_IDENTIFIER,
        nodes,
        keyframes: [],
      });
    }
  }

  public async createMask(): Promise<void> {
    const creatorState = useCreatorStore.getState();

    const getNodeByIdOnly = creatorState.toolkit.getNodeByIdOnly;
    const selectedNodeInfo = creatorState.ui.selectedNodesInfo;

    if (selectedNodeInfo.length > 0) {
      stateHistory.beginAction();
      const nodeId = selectedNodeInfo[0]?.nodeId as string;
      const node = getNodeByIdOnly(nodeId) as AVLayer;
      const canvasObj = canvasMap.get(nodeId);

      const boundingBox = new Box3().setFromObject(canvasObj as CObject3D, true);

      const boundingBoxSize = new Vector3(
        boundingBox.max.x - boundingBox.min.x,
        boundingBox.max.y - boundingBox.min.y,
        1,
      );

      const absolutePosition = node.absolutePosition;
      const position = node.state.animatedProperties.p.value as Coordinate;
      const circle = EllipseShape.getBezier(
        new Vector(position.x - absolutePosition.x, position.y - absolutePosition.y),
        new Size(boundingBoxSize.x * 0.8, boundingBoxSize.y * 0.8),
      );

      node.createMask().setShape(circle).setMode(MaskModeType.Add).setName('ellipse');

      addToSelectedNodes([nodeId], true, true);
      stateHistory.endAction();
    }
  }

  public createNestedSceneFromSelectedObjects(): void {
    if (this._toolkitScene) {
      const selectedNodeInfo = useCreatorStore.getState().ui.selectedNodesInfo;
      const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

      const currentNodes = selectedNodeInfo
        .map((nodeInfo) => getNodeByIdOnly(nodeInfo.nodeId as string))
        .filter((currentNode) => currentNode instanceof ShapeLayer) as ShapeLayer[];

      currentNodes.sort((node1, node2) => (node1 as ShapeLayer).drawOrder - (node2 as ShapeLayer).drawOrder);

      if (currentNodes.length) {
        stateHistory.beginAction();

        const scene = toolkit.createScene();

        const newNodes = currentNodes.map((currentNode: ShapeLayer) => {
          const newNode = currentNode.clone(scene) as ShapeLayer;

          currentNode.removeFromGraph();

          return newNode;
        });

        // remap the matte parent ids because the node ids are changed when the nodes are cloned
        currentNodes.forEach((matteNode, matteLayerIndex) => {
          if (!matteNode.state.properties.td) return;
          currentNodes.forEach((mattedNode, mattedLayerIndex) => {
            if (mattedNode.trackMatteParent && mattedNode.trackMatteParent.nodeId === matteNode.nodeId) {
              const matteLayer = newNodes[matteLayerIndex];

              if (matteLayer) newNodes[mattedLayerIndex]?.setTrackMatteParent(matteLayer);
            }
          });
        });
        const asset = scene.toPrecompositionAsset();

        asset.timeline.setStartAndEndFrame(
          this._toolkitScene.timeline.startFrame,
          this._toolkitScene.timeline.endFrame,
        );
        asset.timeline.setFrameRate(this._toolkitScene.timeline.frameRate);
        asset.timeline.setDuration(this._toolkitScene.timeline.duration);

        const name = getNextNestedSceneName();

        asset.setName(name);

        const layer = this._toolkitScene.createPrecompositionLayer();

        layer.setName(name);
        layer.setStartAndEndFrame(0, this._toolkitScene.timeline.endFrame);
        layer.setTimelineOffset(0);
        layer.setAsset(asset);

        layer.setDrawOrder((currentNodes[0] as ShapeLayer).drawOrder);

        const size = getSceneSize(this._toolkitScene);

        setNestedSceneSize(asset, size);

        useCreatorStore.getState().ui.setSelectedIdsAfterCreated([layer.nodeId]);
        renameNestedScenes();

        emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
        emitter.emit(EmitterEvent.SHAPE_CREATED);

        toolkit.removeScene(scene);

        stateHistory.endAction();
      }
    }
  }

  public cutObjects(): void {
    if (!this._transformControls) return;

    this.copyObjects();

    emitter.emit(EmitterEvent.UI_DELETE);
  }

  public duplicateObjects(): void {
    if (this._transformControls) {
      const selectedNodeIDs = this._transformControls.lastSelectedObjectIDs as string[];

      if (!selectedNodeIDs.length) return;

      // If the user was in the middle of a drag-related transformation,
      // end it and apply the toolkit update
      const transformType = this._transformControls.objects.length
        ? (this._transformControls.objects[0] as CObject3D).userData['mode']
        : null;

      if (transformType) this._transformControls.onDrag();

      stateHistory.beginAction();

      const clonedNodeIDs: string[] = [];

      toolkit.batch(() => {
        selectedNodeIDs.forEach((nodeID) => {
          const node = this._toolkitScene?.getNodeById(nodeID) as DagNode;
          const clonedNode = node.clone();

          clonedNodeIDs.push(clonedNode.nodeId);

          this.updateDrawOrder(node, clonedNode, selectedNodeIDs.length);
        });
      });

      renameLayers(clonedNodeIDs);
      setSelectedIdsAfterCreated(clonedNodeIDs);

      // Send event to get latest toolkit state, to update canvas + timeline
      // Note: Emits before _transformControls.updateGizmo(), so that transformControl won't disappear after drag
      emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
      emitter.emit(EmitterEvent.SHAPE_CREATED);

      this._transformControls.objects.forEach((obj) => {
        obj.userData['mode'] = transformType;
        obj.userData['isDuplicate'] = true;
      });

      stateHistory.endAction();

      this._transformControls.lastSelectedObjectIDs = clonedNodeIDs;
      this._transformControls.updateGizmo();
    }
  }

  public focusUnfocusObjects(ids: string[], clickedNode?: string): void {
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
    const focusedIds = useCreatorStore.getState().ui.focusedNodeIds;
    const setFocusedNodeIds = useCreatorStore.getState().ui.setFocusedNodeIds;
    const currentNodes = ids.map((id) => getNodeByIdOnly(id)) as DagNode[];

    if (currentNodes.length) {
      const isAllFocused = clickedNode ? focusedIds.includes(clickedNode) : ids.every((id) => focusedIds.includes(id));

      stateHistory.beginAction();
      const shapeLayerNodes = currentNodes.filter(
        (currentNode) => currentNode instanceof ShapeLayer || currentNode instanceof PrecompositionLayer,
      );

      shapeLayerNodes.forEach((currentNode) => {
        currentNode.setData('isFocused', !isAllFocused);
      });

      setFocusedNodeIds(ids, !isAllFocused);
      stateHistory.endAction();

      if (useCreatorStore.getState().toolkit.selectedPrecompositionId) {
        emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
      }
      emitter.emit(EmitterEvent.CANVAS_REDRAW);
    }
  }

  public lockUnlockObjects(ids: string[], clickedNode?: string): void {
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
    const currentNodes = ids.map((id) => getNodeByIdOnly(id)) as DagNode[];

    if (currentNodes.length) {
      const isAllLocked = clickedNode
        ? (getNodeByIdOnly(clickedNode) as DagNode).getData('isLocked')
        : currentNodes.every((currentNode) => currentNode.getData('isLocked'));

      stateHistory.beginAction();
      currentNodes.forEach((currentNode) => {
        currentNode.setData('isLocked', !isAllLocked);
      });
      stateHistory.endAction();

      if (this._transformControls) {
        this._transformControls.detach();
      }

      if (useCreatorStore.getState().toolkit.selectedPrecompositionId) {
        emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
      }
      emitter.emit(EmitterEvent.CANVAS_REDRAW);
    }
  }

  public pasteKeyframes(clipboard: CreatorClipboard): void {
    const selectedNodeInfo = useCreatorStore.getState().ui.selectedNodesInfo;

    if (selectedNodeInfo.length === 0 || clipboard.keyframes.length === 0) {
      return;
    }

    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
    const currentNodes = selectedNodeInfo.map((nodeInfo) => getNodeByIdOnly(nodeInfo.nodeId as string));
    const currentFrame = useCreatorStore.getState().toolkit.currentFrame;

    stateHistory.beginAction();

    currentNodes.forEach((currentNode) => {
      clipboard.keyframes.forEach((keyframe) => {
        currentNode?.children.forEach((destination) => {
          if ((destination as AnimatedProperty).type !== keyframe.sourceType) return;

          if (!(destination as AnimatedProperty).isAnimated) {
            (destination as AnimatedProperty).setIsAnimated(true);
          }

          // the first keyframe is pasted onto currentFrame
          // while the rest are offset by their distance to the first keyframe
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const newFrame = currentFrame + keyframe.data.frame - clipboard.keyframes[0]!.data.frame;

          (destination as AnimatedProperty<Scalar>).addKeyframeFromJSON({ ...keyframe.data, frame: newFrame });
        });
      });
    });

    stateHistory.endAction();

    // Send event to get latest toolkit state, to update canvas + timeline
    emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });
  }

  public async pasteMask(): Promise<void> {
    const creatorState = useCreatorStore.getState();
    const copiedMask = creatorState.ui.copiedMask;
    const getNodeByIdOnly = creatorState.toolkit.getNodeByIdOnly;
    const selectedNodeInfo = creatorState.ui.selectedNodesInfo;

    // TODO paste to multi nodes?
    if (copiedMask && selectedNodeInfo.length > 0) {
      stateHistory.beginAction();

      const bezier = copiedMask.bezier;

      const selectedNode = getNodeByIdOnly(selectedNodeInfo[0]?.nodeId as string) as ShapeLayer;

      const maskNode = selectedNode.createMask().setShape(bezier).setMode(MaskModeType.Add).setName(copiedMask.name);

      if (copiedMask.nodeType === ShapeType.PATH) {
        // keep the animated properties of the mask if the mask is a path shape
        maskNode.setState({
          ...maskNode.state,
          animatedProperties: {
            ...maskNode.state.animatedProperties,
            sh: { ...(copiedMask.shapeAnimated as AnimatedPropertyJSON), id: maskNode.state.animatedProperties.sh.id },
          },
        });
      }

      emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });

      stateHistory.endAction();
    }
  }

  public async pasteObjects(pasteMode: PasteMode): Promise<void> {
    const clipboardText = await this._getClipboardContent();

    if (clipboardText === null) {
      return;
    }

    if (clipboardText.startsWith('<svg')) {
      uploadSVG(clipboardText);

      return;
    }

    const clipboard = await this._fetchClipboardData(clipboardText);

    if (clipboard === null) {
      return;
    }

    if (clipboard.keyframes.length > 0) {
      this.pasteKeyframes(clipboard);
    } else if (clipboard.nodes.length > 0) {
      stateHistory.beginAction();

      // if the nodes have mixed hierarchies,
      // find the topmost hierarchy in the group
      // and make all the nodes match that hierarchy

      const nodesToPaste: CreatorClipboardNodeEntry[] = clipboard.nodes;

      const clonedNodeIDs: string[] = [];

      let selectedNodeIDs = useCreatorStore.getState().ui.selectedNodesInfo.map((item) => item.nodeId);

      if (selectedNodeIDs.length === 0) {
        selectedNodeIDs = this._transformControls?.lastSelectedObjectIDs as string[];
      }

      nodesToPaste.forEach((nodeInClipboard) => {
        // the destination parent depends on its original parent type
        const parentsToAttachTo: DagNode[] = [];

        if (
          nodeInClipboard.parentNodeType === DagNodeType.SCENE ||
          nodeInClipboard.parentNodeType === DagNodeType.ASSET
        ) {
          // 1. if parent is the scene, paste the nodes onto the scene
          if (this._toolkitScene) {
            parentsToAttachTo.push(this._toolkitScene);
          }
        } else {
          // 2. if parent is not the scene
          selectedNodeIDs.forEach((selectedNodeID) => {
            const selectedNode = this._toolkitScene?.getNodeById(selectedNodeID) as AVLayer | Shape;

            // 2.1 paste it on compatible selected layers
            if (
              selectedNode.nodeType === nodeInClipboard.parentNodeType &&
              (selectedNode as Shape).type === (nodeInClipboard.parentType as unknown as Shape['type'])
            ) {
              parentsToAttachTo.push(selectedNode);
              // 2.2 if the node to be duplicated is still selected, paste on its parent
            } else if (selectedNodeID === (nodeInClipboard.data as { id: string }).id) {
              if (this._toolkitScene) {
                const parent = this._toolkitScene.getNodeById((nodeInClipboard.data as { id: string }).id);

                if (parent) {
                  parentsToAttachTo.push(parent);
                }
              }
            } else if (
              [LayerTypes.Group].includes(selectedNode.state.type) &&
              [LayerTypes.Rectangle, LayerTypes.Star, LayerTypes.Ellipse].includes(nodeInClipboard.data?.type as string)
            ) {
              parentsToAttachTo.push(selectedNode);
            }
          });
        }

        // This is a map of found asset ids to their new uuids whenever asset id regeneration is required.
        // It prevents bloating the lottie with multiple assets if parentsToAttachTo are more than 1.
        // The uuid for the assets gets generated once and reused for any subsequent parentsToAttach to
        interface NewAssetInfo {
          newId: string;
          // The name **MIGHT** be overriden to prevent confusion as assets with the same name could exist within the scene
          newName: string | null;
        }
        const assetIdMap = new Map<string, NewAssetInfo>();

        // Boolean indicating whether we are copying into a different project
        // or not. Attaching a project id to the copied json would be the
        // simplest approach here but there can be cases where the user copied
        // something when the project wasn't saved. Not relying on a remote
        // identifier in this case allows users to use copy-paste as an
        // escape-hatch to copy their work onto a new tab in case the first
        // save fails
        let isDifferentProject = true;

        parentsToAttachTo.forEach((parentToAttachTo) => {
          let clonedNode;

          if ((nodeInClipboard.data as PrecompositionLayerJSON).type === LayerType.PRECOMPOSITION) {
            const UUID_V4_REGEXP = /[\da-f]{8}(-[\da-f]{4}){3}-[\da-f]{12}$/iu;

            const precomp = nodeInClipboard.data as PrecompositionLayerJSON;

            if (precomp.referenceId) {
              isDifferentProject = parentToAttachTo.scene.findAssetByReference(precomp.referenceId) === null;
            }

            const shouldRegeneratedAssetIds =
              pasteMode === PasteMode.PASTE_WITH_CLONED_ASSETS ||
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              (pasteMode === PasteMode.PASTE_WITH_ASSETS_BY_REF_IF_SAME_PROJECT && isDifferentProject);

            // Iterate over each asset referenced within the precomposition
            // This does include nested assets too
            forEachAsset(precomp, (loopedAsset, loopedPrecomp) => {
              if (shouldRegeneratedAssetIds) {
                let newAssetInfo: NewAssetInfo;
                let newAssetId: string;

                if (assetIdMap.has(loopedAsset.assetId)) {
                  newAssetInfo = assetIdMap.get(loopedAsset.assetId) as NewAssetInfo;
                  newAssetId = newAssetInfo.newId;
                } else {
                  const uuid = uuidv4();

                  newAssetId = loopedAsset.assetId.replace(UUID_V4_REGEXP, uuid);

                  // In case there were no replacements
                  if (parentToAttachTo.scene.findAssetByReference(newAssetId)) {
                    newAssetId = `${loopedAsset.assetId}_${uuid}`;
                  }

                  let newName: string | null = null;

                  // Setting the asset name to be unique
                  if (loopedAsset.properties.nm) {
                    const name = loopedAsset.properties.nm as string;

                    const existingAssetNames = parentToAttachTo.scene.assets.map((asset) => {
                      return asset.name as string;
                    });

                    if (existingAssetNames.includes(name)) {
                      newName = uniqueName(name, existingAssetNames);
                      loopedAsset.properties.nm = newName;
                    }
                  }

                  assetIdMap.set(loopedAsset.assetId, { newId: newAssetId, newName });
                }

                loopedAsset.assetId = newAssetId;
                loopedAsset.properties.ln = newAssetId;
                loopedPrecomp.referenceId = newAssetId;
              }

              // Create the asset only if it doesn't exist
              // Meaning, nested assets which refer to already created assets wont get duplicated
              // Essentially a deduplication process
              if (!parentToAttachTo.scene.findAssetByReference(loopedAsset.assetId)) {
                deserialize(loopedAsset, DagNodeType.ASSET, parentToAttachTo.scene);
              }
            });

            // If inserting the precomp scene, paste it using new extra scene
            // validate if there is a loop in the precomp
            // impossible to paste A inside C in case: A -> B -> C
            // possible to paste C inside A in case: A -> B -> C

            const newScene = toolkit.createScene();
            let isLoopDetected = false;

            if (precomp.id === parentToAttachTo.nodeId) {
              isLoopDetected = true;
            }

            forEachAsset(precomp, (loopedAsset) => {
              if (loopedAsset.id === parentToAttachTo.nodeId) {
                isLoopDetected = true;
              }

              deserialize(loopedAsset, DagNodeType.ASSET, newScene);
            });

            if (!isLoopDetected) {
              const clonedNodeToNewScene = deserialize(precomp, nodeInClipboard.nodeType, newScene);
              const asset = parentToAttachTo.scene.findAssetByReference(precomp.composition.assetId);

              if (asset && clonedNodeToNewScene) {
                clonedNode = clonedNodeToNewScene.clone(parentToAttachTo) as PrecompositionLayer;

                clonedNode.setAsset(asset as PrecompositionAsset);
              }
            }
            toolkit.removeScene(newScene);
          } else if (parentToAttachTo.type === LayerType.PRECOMPOSITION) {
            // If the parent is a precomp, paste the nodes using new extra scene
            const newScene = toolkit.createScene();

            const node = deserialize(nodeInClipboard.data, nodeInClipboard.nodeType, newScene);

            if (node) {
              clonedNode = node.clone().setParent(parentToAttachTo);
            }
            toolkit.removeScene(newScene);
          } else {
            clonedNode = deserialize(nodeInClipboard.data, nodeInClipboard.nodeType, parentToAttachTo);
          }

          if (clonedNode && (clonedNode as { nodeId?: string }).nodeId) {
            clonedNodeIDs.push((clonedNode as { nodeId: string }).nodeId);
          }
        });
      });

      // attach the transformControl to the newly created object
      if (this._transformControls && clonedNodeIDs.length) {
        this._transformControls.lastSelectedObjectIDs = clonedNodeIDs;
      }

      renameLayers(clonedNodeIDs, true);
      addToSelectedNodes(clonedNodeIDs, true);

      emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });

      stateHistory.endAction();
    }
  }

  public async removeMask(): Promise<void> {
    const creatorState = useCreatorStore.getState();

    const getNodeByIdOnly = creatorState.toolkit.getNodeByIdOnly;
    const selectedNodeInfo = creatorState.ui.selectedNodesInfo;

    if (selectedNodeInfo.length > 0) {
      const node = getNodeByIdOnly(selectedNodeInfo[0]?.nodeId as string) as AVLayer;

      if (selectedNodeInfo[0]?.nodeType === DagNodeType.MASK) {
        addToSelectedNodes([node.parent?.nodeId as string], true);
        node.removeFromGraph();
      } else if (selectedNodeInfo[0]?.nodeType === DagNodeType.SHAPE) {
        (node.parent as AVLayer).masks.forEach((mask) => {
          (node.parent as AVLayer).removeChild(mask);
        });
      } else {
        node.masks.forEach((mask) => {
          node.removeChild(mask);
        });
      }
    }
  }

  public removeObject(objectID: string): void {
    const object = canvasMap.get(objectID);

    if (!object) return;

    removeCanvasMapItem(objectID);
    removeRenderRangeMapItem(objectID);
    object.traverse((child) => {
      if (child instanceof CMesh) {
        child.geometry.dispose();
        child.material.dispose();
      }
    });

    if (object.parent) object.parent.remove(object);
    else this._scene.remove(object);
    // detach from transformControl
    if (this._transformControls) {
      this._transformControls.detach();
      this._transformControls.hideBoundingBox();
    }
  }

  public showHideObjects(ids: string[], clickedNode?: string): void {
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
    const focusedIds = useCreatorStore.getState().ui.focusedNodeIds;
    const setFocusedNodeIds = useCreatorStore.getState().ui.setFocusedNodeIds;
    const currentNodes = ids.map((id) => getNodeByIdOnly(id));

    if (currentNodes.length === 0) return;
    const isAllHidden = clickedNode
      ? (getNodeByIdOnly(clickedNode) as ShapeLayer).isHidden
      : currentNodes.every((currentNode) => (currentNode as ShapeLayer).isHidden);

    stateHistory.beginAction();
    currentNodes.forEach((currentNode) => {
      const node = currentNode as ShapeLayer;

      if (node.state.properties.td) {
        node.setData('isMatteHidden', Boolean(!node.getData('isMatteHidden')));

        return;
      }
      const newIsHidden = !isAllHidden;

      node.setIsHidden(newIsHidden);

      if (newIsHidden && focusedIds.includes(node.nodeId)) {
        setFocusedNodeIds([node.nodeId], false);
      }
    });
    stateHistory.endAction();

    if (useCreatorStore.getState().toolkit.selectedPrecompositionId) {
      emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
    }
    emitter.emit(EmitterEvent.CANVAS_REDRAW);
  }

  public updateDrawOrder(originalNode: DagNode, clonedNode: DagNode, drawOrderOffset: number): void {
    const originalDrawOrder = (originalNode.state as BaseLayerJSON).properties.do;

    if (originalDrawOrder !== null && typeof originalDrawOrder === 'number') {
      (originalNode as ShapeLayer).setDrawOrder(originalDrawOrder + drawOrderOffset);
    } else if (clonedNode instanceof Shape) {
      const setLayerShapeIndex = useCreatorStore.getState().toolkit.setLayerShapeIndex;
      const index = (originalNode.parent as ShapeLayer).shapes.findIndex((node) => node.nodeId === originalNode.nodeId);

      setLayerShapeIndex(index, [clonedNode.nodeId], (clonedNode.parent as AVLayer).nodeId);
    }
  }

  public updateMatteType(ids: string[], matteType: TrackMatteType): void {
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
    const currentNodes = ids
      .map((id) => getNodeByIdOnly(id))
      .filter(
        (currentNode) => currentNode instanceof ShapeLayer || currentNode instanceof PrecompositionLayer,
      ) as DagNode[];

    if (currentNodes.length) {
      stateHistory.beginAction();
      currentNodes.forEach((currentNode) => {
        const node = currentNode as ShapeLayer;

        node.setTrackMatteType(matteType);
        if (matteType === TrackMatteType.NO_TRACK_MATTE) node.setIsTrackMatte(false);

        if (matteType === TrackMatteType.NO_TRACK_MATTE && node.trackMatteParent) {
          node.trackMatteParent.setTrackMatteType(TrackMatteType.NO_TRACK_MATTE).setIsTrackMatte(false);
          node.setTrackMatteParent(null);
        }
      });
      stateHistory.endAction();

      if (useCreatorStore.getState().toolkit.selectedPrecompositionId) {
        emitter.emit(EmitterEvent.PRECOMP_SCENE_UPDATE_JSON);
      }
      emitter.emit(EmitterEvent.CANVAS_REDRAW);
    }
  }

  private _addEventListeners(): void {
    document.addEventListener('paste', (event: ClipboardEvent) => {
      for (const item of event.clipboardData?.items || []) {
        if (item.kind === 'file' && item.type === 'image/svg+xml') {
          const reader = new FileReader();

          reader.onload = (readerEvent) => {
            if (readerEvent.target) {
              const svgContent = readerEvent.target.result;

              uploadSVG(svgContent as string);
            }
          };

          const file = item.getAsFile();

          if (file) {
            reader.readAsText(file);
          }
        }
      }
    });

    // initialize the scene
    const selectedPrecomp = useCreatorStore.getState().toolkit.selectedPrecompositionId;

    this._toolkitScene = selectedPrecomp
      ? (toolkit.getNodeById(selectedPrecomp) as ToolkitScene)
      : (toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as ToolkitScene);

    // if opening a saved file, update the scene after the layer data has loaded
    useCreatorStore.subscribe(
      (state) => state.ui.loader.isLoading,
      (isLoading) => {
        if (!isLoading) {
          this._toolkitScene = toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as ToolkitScene;
        }
      },
    );

    // when switching to a precomp scene, update the scene type
    useCreatorStore.subscribe(
      (state) => state.toolkit.selectedPrecompositionId,
      (selectedPrecompositionId) => {
        if (selectedPrecompositionId) {
          this._toolkitScene = toolkit.getNodeById(selectedPrecompositionId) as ToolkitScene;
        } else {
          this._toolkitScene = toolkit.scenes[useCreatorStore.getState().toolkit.sceneIndex] as ToolkitScene;
        }
      },
    );
  }

  private async _fetchClipboardData(clipboardText: string): Promise<CreatorClipboard | null> {
    let contents;

    try {
      contents = JSON.parse(clipboardText);
    } catch (err) {
      // If the contents aren't json
      return null;
    }

    if (
      Boolean(contents) &&
      typeof contents === 'object' &&
      Object.prototype.hasOwnProperty.call(contents, 'meta') &&
      contents.meta === CLIPBOARD_IDENTIFIER
    ) {
      // Return as clipboard contents
      return {
        nodes: [],
        keyframes: [],
        ...contents,
      };
    }

    // If the contents are json but not what we expect
    useCreatorStore.getState().ui.setAlert({
      text: 'File format not supported',
      alertColor: '#D92600',
      icon: 'error',
    });

    return null;
  }

  private async _getClipboardContent(): Promise<string | null> {
    let clipboardText;

    try {
      if (!(await this.checkClipboardPermissions(ClipboardPermission.Read))) {
        return null;
      }

      clipboardText = await navigator.clipboard.readText();
    } catch {
      // If the read operation failed
      useCreatorStore.getState().ui.setAlert({
        text: 'Failed to read from the clipboard',
        alertColor: '#D92600',
        icon: 'error',
      });

      return null;
    }

    return clipboardText;
  }

  private async _writeToClipboard(data: CreatorClipboard): Promise<void> {
    const setClipboard = useCreatorStore.getState().ui.setClipboard;

    try {
      if (!(await this.checkClipboardPermissions(ClipboardPermission.Write))) {
        return;
      }

      await navigator.clipboard.writeText(JSON.stringify(data));
      setClipboard(data);
    } catch {
      useCreatorStore.getState().ui.setAlert({
        text: 'Failed to copy to the clipboard',
        alertColor: '#D92600',
        icon: 'error',
      });
    }
  }
}
