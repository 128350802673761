/**
 * Copyright 2023 Design Barn Inc.
 */

import { Menu, Transition } from '@headlessui/react';
import React from 'react';

import { AlignPropertyFullWidth } from '../../property-panel/components/AlignProperty';

import type { MenuDropdownProp } from './CreatorMenu';
import { MenuDivider } from './MenuDivider';
import { MenuInteraction } from './MenuInteraction';

import { ClickOutside } from '~/utils/components';

export interface Coordinate {
  x: number;
  y: number;
}

export interface ContextMenuWrapperProps {
  coord: Coordinate;
  customClass?: string;
  dropdownItems: MenuDropdownProp[];
  eventArg?: unknown;
  isOpen: boolean;
  onClose: () => void;
}

export const ContextMenuWrapperLayers: React.FC<ContextMenuWrapperProps> = ({
  coord,
  customClass,
  dropdownItems,
  eventArg,
  isOpen,
  onClose,
}) => {
  const menuRef = React.useRef(null);
  const handleClick = React.useCallback(
    (callbackFn: ((arg?: unknown) => void) | undefined): void => {
      if (callbackFn) callbackFn(eventArg);
      onClose();
    },
    [onClose, eventArg],
  );

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {menuRef.current && <ClickOutside onRef={menuRef} onExecute={onClose} />}

      <Menu as="div">
        <Transition
          show={isOpen}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Menu.Items
            ref={menuRef}
            style={{ left: `${coord.x}px`, top: `${coord.y}px` }}
            className={`fixed z-dropdown mt-1 w-60 cursor-pointer rounded-lg border border-gray-600 bg-gray-800 p-2 focus:outline-none ${customClass}`}
          >
            {dropdownItems.map((dropdown, index) => {
              const { callback, disabled, isAlignment, isDivider, isHidden, type } = dropdown;

              return (
                <div key={`${type}_${index}`} className="mb-1 last:mb-0 ">
                  <div className={`relative rounded ${!disabled && !isAlignment && 'hover:bg-gray-600'}`}>
                    <Menu.Item>
                      <MenuInteraction>
                        {() => (
                          <>
                            {!isHidden && (
                              <>
                                {isAlignment && <AlignPropertyFullWidth enabled={true} />}
                                {!isAlignment && (
                                  <button
                                    className={`group flex w-full ${index === 0 && 'rounded-t'} ${
                                      index === dropdownItems.length - 1 && 'rounded-b'
                                    } h-6 items-center px-2 text-xs ${disabled ? 'text-gray-500' : 'text-white'}`}
                                    onClick={() => handleClick(callback)}
                                    disabled={Boolean(disabled)}
                                  >
                                    {dropdown.label}
                                    {!dropdown.children && (
                                      <span
                                        className={`absolute right-[7px] ${disabled ? 'text-gray-500' : 'text-gray-300'}`}
                                      >
                                        {dropdown.shortcut}
                                      </span>
                                    )}
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </MenuInteraction>
                    </Menu.Item>
                  </div>
                  {isDivider && !isHidden && index !== dropdownItems.length - 1 && <MenuDivider />}
                </div>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
