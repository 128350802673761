/**
 * Copyright 2024 Design Barn Inc.
 */

import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import LoadingAnimation from '~/assets/animations/loading-lf.json';

interface Props {
  containerClassName?: string;
  src: string;
}

export const IframeWithLoader: FC<Props> = ({ containerClassName, src }) => {
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [isIframeMounted, setIsIframeMounted] = useState(false);

  const ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setIsIframeMounted(false);
    setIsIframeLoading(true);
    setTimeout(() => {
      setIsIframeMounted(true);
    }, 0);
  }, [src]);

  useEffect(() => {
    const onLoad = (): void => {
      setIsIframeLoading(false);
    };

    const ele = ref.current;

    ele?.addEventListener('load', onLoad);

    return () => {
      ele?.removeEventListener('load', onLoad);
    };
  }, [isIframeMounted]);

  return (
    <div className={`relative ${containerClassName}`}>
      {isIframeMounted ? <iframe src={src} className="h-full w-full" ref={ref} /> : null}
      {isIframeLoading ? (
        <div className="absolute inset-0 flex items-center justify-center bg-white">
          <LottiePlayer renderer="svg" autoplay loop className="h-20 w-20" src={LoadingAnimation} />
        </div>
      ) : null}
    </div>
  );
};
