/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Placement } from '@floating-ui/react-dom-interactions';
import { IfFeatureEnabled, useFeature } from '@growthbook/growthbook-react';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { PanelButton } from '../../Elements/Button/BarButton';

import { Bolt, BoltActive, LottieAnimation, LottieAnimationActive, Plugin } from '~/assets/icons';
import { GB_CONSTANT } from '~/config/growthbook';
import { LibraryType } from '~/data/constant';
import { EventType, pluginIdToTypeMap } from '~/data/eventStore';
import { SIDEBAR_PLUGINS } from '~/data/plugins';
import { Insert } from '~/features/upload';
import { PluginUIType, openFloatingPlugin } from '~/plugins';
import { useCreatorStore } from '~/store';
import { fireEvent } from '~/utils';

const LibraryList = [
  {
    type: LibraryType.GettingStarted,
    activeIcon: <BoltActive />,
    inactiveIcon: <Bolt />,
    tooltipProps: {
      content: 'Get started',
      placement: 'right' as Placement,
    },
    testID: 'get-started-button',
  },
  {
    type: LibraryType.Upload,
    activeIcon: <Insert id="InsertContainer" />,
    inactiveIcon: <Insert id="InsertContainer" />,
    tooltipProps: {
      disabled: true,
    },
    testID: 'upload-button',
  },
  {
    type: LibraryType.Lottie,
    activeIcon: <LottieAnimationActive />,
    inactiveIcon: <LottieAnimation />,
    tooltipProps: {
      content: 'Discover animations',
      placement: 'right' as Placement,
    },
    testID: 'discover-button',
  },
] as const;

export const Library: React.FC = () => {
  const [currentLibrary, setCurrentLibrary, loadedPlugins, floatingPluginIds, isAuthorized] = useCreatorStore(
    (state) => [
      state.ui.currentLibrary,
      state.ui.setCurrentLibrary,
      state.plugins.loadedPlugins,
      state.ui.floatingPluginIds,
      state.user.isAuthorized,
    ],
    shallow,
  );

  const showGetStarted = useFeature(GB_CONSTANT.SHOW_GET_STARTED).on;

  const pluginBrowserOpen = useCreatorStore((state) => state.ui.pluginBrowserOpen);
  const setPluginBrowserOpen = useCreatorStore.getState().ui.setPluginBrowserOpen;

  const handleOnClick: (type: LibraryType) => void = useCallback(
    (type) => {
      if (!isAuthorized) {
        const loadedPlugin = loadedPlugins.find((plugin) => `plugin-${plugin.id}` === type);

        if (
          loadedPlugin &&
          [SIDEBAR_PLUGINS.promptVector.uuid, SIDEBAR_PLUGINS.motionCopilot.uuid].includes(loadedPlugin.id)
        ) {
          useCreatorStore.getState().ui.setAlert({
            text: 'Ooops, only for logged in users',
            alertColor: '#D92600',
            icon: 'error',
          });

          return;
        }
      }

      if (type === LibraryType.Plugin) {
        setPluginBrowserOpen(!pluginBrowserOpen);

        return;
      }

      const pluginWithFloatingUI = loadedPlugins.find(
        (plugin) => `plugin-${plugin.id}` === type && plugin.options.uiType === PluginUIType.FloatingUI,
      );

      const pluginType = pluginIdToTypeMap[type];

      if (pluginWithFloatingUI) {
        openFloatingPlugin(pluginWithFloatingUI.id);

        return;
      }

      if (currentLibrary === type || type === LibraryType.Upload) {
        // Set to null to collapse side panel
        setCurrentLibrary(null);
      } else {
        setCurrentLibrary(type);

        if (pluginType) {
          fireEvent({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            event_type: EventType.OpenedPlugin,
            parameters: {
              type: pluginType,
            },
          });
        }
      }
    },
    [loadedPlugins, currentLibrary, setPluginBrowserOpen, pluginBrowserOpen, setCurrentLibrary, isAuthorized],
  );

  return (
    <div className="my-2 space-y-3">
      {showGetStarted && (
        <PanelButton
          {...LibraryList[0]}
          isSelected={currentLibrary === LibraryList[0].type}
          onClick={() => handleOnClick(LibraryList[0].type)}
        />
      )}
      <div id="add-assets" className="space-y-3">
        {LibraryList.map(
          (lib) =>
            lib.type !== LibraryType.GettingStarted && (
              <PanelButton
                key={lib.type}
                activeIcon={lib.activeIcon}
                inactiveIcon={lib.inactiveIcon}
                tooltipProps={lib.tooltipProps}
                isSelected={currentLibrary === lib.type}
                onClick={() => handleOnClick(lib.type)}
                testID={lib.testID}
              />
            ),
        )}
      </div>
      {/* Note: this will load ALL plugins, but plugin manager (in Creator.tsx) will only populate this based on growthbook */}
      <div id="plugins" className="space-y-3">
        {loadedPlugins
          .filter((plugin) => plugin.options.hasToolbarShortcut)
          .map((plugin) => {
            return (
              <PanelButton
                key={plugin.id}
                activeIcon={plugin.options.toolbarIconActive || <></>}
                inactiveIcon={plugin.options.toolbarIcon || <></>}
                tooltipProps={{ content: plugin.name, placement: 'right' }}
                isSelected={currentLibrary === `plugin-${plugin.id}` || floatingPluginIds.includes(plugin.id)}
                onClick={() => {
                  handleOnClick(`plugin-${plugin.id}` as LibraryType);
                }}
                id={`plugin-${plugin.name.toLowerCase().replace(' ', '-')}`}
              />
            );
          })}
      </div>
      <IfFeatureEnabled feature={GB_CONSTANT.SHOW_PLUGINS}>
        <PanelButton
          activeIcon={<Plugin />}
          inactiveIcon={<Plugin />}
          tooltipProps={{
            content: 'Plugins',
            placement: 'right' as Placement,
          }}
          isSelected={currentLibrary === LibraryType.Plugin}
          onClick={() => handleOnClick(LibraryType.Plugin)}
        />
      </IfFeatureEnabled>
    </div>
  );
};
