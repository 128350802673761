/**
 * Copyright 2024 Design Barn Inc.
 */

import { GradientFillType } from '@lottiefiles/toolkit-js';

import type { GradientPointType } from '../components/ColorPicker/Area/GradientPoints/GradientPoint';

export function generateSolidStyle(red: number, green: number, blue: number, alpha: number): string {
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function generateGradientStyle(points: GradientPointType[], type: GradientFillType, degree: number): string {
  let style = '';
  const sortedPoints = points.slice();

  sortedPoints.sort((first, second) => first.left - second.left);

  if (type === GradientFillType.LINEAR) {
    style = `linear-gradient(${degree}deg,`;
  } else {
    style = 'radial-gradient(';
  }

  sortedPoints.forEach((point, index) => {
    style += `rgba(${point.red}, ${point.green}, ${point.blue}, ${point.alpha}) ${point.left * 100}%`;

    if (index !== sortedPoints.length - 1) {
      style += ',';
    }
  });

  style += ')';

  return style;
}
