/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useContext, useCallback, useEffect } from 'react';

import { SaveContext } from './SaveContext';

// eslint-disable-next-line no-restricted-imports
import { TextInput } from '~/features/plugin-dev/TextInput';
import { useCreatorStore } from '~/store';

export const SaveEditName: React.FC = () => {
  const existingTitle = useCreatorStore.getState().project.info.name;
  const { fileName, setFileName } = useContext(SaveContext);

  const handleTextChange = useCallback(
    (inputText: string) => {
      setFileName(inputText);
    },
    [setFileName],
  );

  useEffect(() => {
    setFileName(`Copy of ${existingTitle}`);
  }, [existingTitle, setFileName]);

  return (
    <div className="flex items-baseline	">
      <div className="m-2 ml-[0px] w-[20%] flex-initial">
        <p className="font-bold">File name</p>
      </div>
      <div className="m-2 w-[80%] flex-initial">
        <TextInput
          placeholder="Enter development localhost link"
          inputClass="h-6 rounded-sm border-2"
          onChange={(value) => handleTextChange(value)}
          value={fileName}
          showRemove={false}
          autoSelect={true}
        />
      </div>
    </div>
  );
};
