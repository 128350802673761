/**
 * Copyright 2022 Design Barn Inc.
 */

import { isEqual } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { PROPERTY_PANEL_KEYFRAME_WIDTH } from '../../constants';

import { getMixedValue, toggleKeyframe } from './helpers';

import { Rotation as RotationIcon } from '~/assets/icons';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { NumberResultName } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { PeriodicInput } from '~/components/Elements/Input/PeriodicInput';
import { Tooltip } from '~/components/Elements/Tooltip';
import { UITransformType, updateGroupFromUI } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { AnimatedType } from '~/lib/toolkit';
import { HotKey } from '~/providers/HotkeyListener';
import { useCreatorStore } from '~/store';

interface TransformRotationFieldProps {
  className?: string;
}

const handleKeyframeClick = (): void => {
  toggleKeyframe(AnimatedType.ROTATION);
};

export const TransformRotationField: React.FC<TransformRotationFieldProps> = () => {
  const [rotation, currentKeyframe, selectedNodesInfo, currentTransforms, rotationAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentTransform.rotation,
      state.toolkit.currentTransform.rotationCurrentKeyframe,
      state.ui.selectedNodesInfo,
      state.toolkit.currentTransforms,
      state.toolkit.currentTransform.rotationIsAnimated,
    ],
    shallow,
  );

  const [keyframeActive, setKeyframeActive] = useState(false);
  const [rotationIsAnimated, setRotationIsAnimated] = useState(false);
  const previousTransforms = useRef(currentTransforms);
  const multiSelect = selectedNodesInfo.length > 1;

  const handleOnChange = useCallback((result: NumberResult) => {
    if (result.name === NumberResultName.Rotation) {
      updateGroupFromUI(UITransformType.Rotation, result);
    } else if (result.name === NumberResultName.NumberOfRotations) {
      updateGroupFromUI(UITransformType.RotationCount, result);
    }

    emitter.emit(EmitterEvent.ANIMATED_ROTATION_UPDATED);
  }, []);

  useEffect(() => {
    if (!isEqual(previousTransforms.current, currentTransforms) || multiSelect) {
      previousTransforms.current = currentTransforms;
      const isActive = currentTransforms.every((transform) => transform.rotationCurrentKeyframe);

      setKeyframeActive(isActive);

      const isAnimated = currentTransforms.every((transform) => transform.rotationIsAnimated);

      setRotationIsAnimated(isAnimated);
    }

    if (!multiSelect) {
      setKeyframeActive(currentKeyframe !== '');
      setRotationIsAnimated(rotationAnimated);
    }
  }, [currentKeyframe, multiSelect, selectedNodesInfo, currentTransforms, rotationAnimated]);

  const mixedRotation = useMemo(() => {
    return getMixedValue(multiSelect, currentTransforms, AnimatedType.ROTATION);
  }, [currentTransforms, multiSelect]);

  return (
    <div className="mr-6 mt-2 flex">
      <div className={`relative flex items-center`} style={{ width: `calc(50% - ${PROPERTY_PANEL_KEYFRAME_WIDTH}px)` }}>
        <Tooltip content="Rotation">
          <div className="number-input-label flex w-[182px] rounded border border-transparent bg-gray-700 focus-within:border-teal-500">
            <PeriodicInput
              value={rotation}
              name={NumberResultName.Rotation}
              label={<RotationIcon className="h-4 w-4" />}
              append="°"
              period={0}
              allowNegative
              onChange={handleOnChange}
              styleClass={{
                label: 'p-1 pr-[10px] h-[22px] w-full text-xs font-normal rounded-l',
              }}
              message={mixedRotation ?? null}
            />
          </div>
        </Tooltip>
        <div className="absolute right-[-9px] top-[2px]">
          <KeyframeButton
            onClick={handleKeyframeClick}
            hasKeyframe={keyframeActive}
            withTooltip
            keyframeShortcut={HotKey.TOGGLE_ROTATION_KEYFRAME.toUpperCase()}
            isChannelAnimated={rotationIsAnimated}
          />
        </div>
      </div>
    </div>
  );
};
