/**
 * Copyright 2024 Design Barn Inc.
 */

import type { PrecompositionAsset, Scene } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { useTimelineUtils } from '../hooks';

import { TimelineWorkAreaSlider } from './TimelineWorkAreaSlider';

import { saveWorkAreaEnd, saveWorkAreaStart } from '~/features/timeline';
import { scenePropertiesMap, setScenePropertiesMap } from '~/lib/canvas';
import { getPlaybackParameters } from '~/lib/eventHandler/playback';
import { getActiveScene, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface Props {}

export const TimelineWorkArea: React.FC<Props> = () => {
  const [
    startFrame,
    endFrame,
    setStartFrame,
    setEndFrame,
    width,
    selectedPrecompositionId,
    _currentFrameRate,
    _precompFrameRate,
    _precompDuration,
    sceneOp,
    precompOp,
  ] = useCreatorStore(
    (state) => [
      state.timeline.workAreaFrameStart,
      state.timeline.workAreaFrameEnd,
      state.timeline.setWorkAreaFrameStart,
      state.timeline.setWorkAreaFrameEnd,
      state.timeline.keyframeScrubberWidth,
      state.toolkit.selectedPrecompositionId,
      state.toolkit.json?.timeline.properties.fr as number,
      state.toolkit.selectedPrecompositionJson?.timeline.properties.fr,
      state.toolkit.selectedPrecompositionJson?.timeline.duration as number,
      state.toolkit.json?.timeline.properties.op as number,
      state.toolkit.selectedPrecompositionJson?.timeline.properties.op as number,
    ],
    shallow,
  );

  const currentFps = _precompDuration ? (_precompFrameRate as number) : _currentFrameRate;

  const { totalFrames } = useTimelineUtils(width);

  useEffect(() => {
    const activeScene: Scene | PrecompositionAsset | null = getActiveScene(toolkit);

    if (!activeScene) return;

    const workAreaFrameStart = useCreatorStore.getState().timeline.workAreaFrameStart;
    const workAreaFrameEnd = useCreatorStore.getState().timeline.workAreaFrameEnd;

    if (workAreaFrameEnd === null || workAreaFrameStart === null) {
      return;
    }

    const applyWorkAreaRules = (op: number): void => {
      if (workAreaFrameEnd > op) {
        setEndFrame(op);
      }
      if (workAreaFrameEnd < op) {
        const sceneProperties = scenePropertiesMap.get(activeScene.nodeId);

        if (sceneProperties && sceneProperties.isWorkAreaFrameEndAtTheEndOfScene) {
          const { duration, fps } = getPlaybackParameters();
          const sceneTotalFrames = duration * fps;

          setEndFrame(sceneTotalFrames);
        }
      }

      if (workAreaFrameStart > op) {
        setStartFrame(op - 1);
      }
    };

    if (precompOp) {
      applyWorkAreaRules(precompOp);
    }

    if (sceneOp && !precompOp) {
      applyWorkAreaRules(sceneOp);
    }
  }, [precompOp, sceneOp, setEndFrame, setStartFrame]);

  useEffect(() => {
    if (startFrame !== null && endFrame !== null) {
      const activeScene: Scene | PrecompositionAsset | null = getActiveScene(toolkit);

      if (!activeScene) return;

      const { op } = getPlaybackParameters();

      setScenePropertiesMap(activeScene.nodeId, {
        isWorkAreaFrameEndAtTheEndOfScene: endFrame === op,
        workAreaFrameStart: startFrame,
        workAreaFrameEnd: endFrame,
      });
    }
  }, [startFrame, endFrame]);

  useEffect(() => {
    const activeScene: Scene | PrecompositionAsset | null = getActiveScene(toolkit);

    if (!activeScene) return;

    const sceneProperties = scenePropertiesMap.get(activeScene.nodeId);

    if (sceneProperties && sceneProperties.workAreaFrameStart !== null) {
      setStartFrame(sceneProperties.workAreaFrameStart as number);
    } else {
      setStartFrame(0);
    }

    if (sceneProperties && sceneProperties.workAreaFrameEnd !== null) {
      setEndFrame(sceneProperties.workAreaFrameEnd as number);
    } else {
      const { duration, fps } = getPlaybackParameters();
      const sceneTotalFrames = duration * fps;

      setEndFrame(sceneTotalFrames);
    }
  }, [selectedPrecompositionId, setEndFrame, setStartFrame]);

  const onStartFrameChange = useCallback(
    (frame: number) => {
      if (startFrame !== frame) {
        setStartFrame(frame);
      }
    },
    [startFrame, setStartFrame],
  );

  const onEndFrameChange = useCallback(
    (frame: number) => {
      if (endFrame !== frame) {
        setEndFrame(frame);
      }
    },
    [endFrame, setEndFrame],
  );

  if (startFrame === null || endFrame === null) {
    return null;
  }

  return (
    <>
      <TimelineWorkAreaSlider
        width={width}
        currentFps={currentFps}
        frame={startFrame}
        isLeftSlider={true}
        onFrameChange={onStartFrameChange}
        onScrubbingEnd={saveWorkAreaStart}
        totalFrames={totalFrames}
        minFrame={0}
        maxFrame={endFrame - 1}
      />
      <TimelineWorkAreaSlider
        width={width}
        currentFps={currentFps}
        frame={endFrame}
        isLeftSlider={false}
        onFrameChange={onEndFrameChange}
        onScrubbingEnd={saveWorkAreaEnd}
        totalFrames={totalFrames}
        minFrame={startFrame + 1}
        maxFrame={totalFrames}
      />
    </>
  );
};
