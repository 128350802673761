/**
 * Copyright 2022 Design Barn Inc.
 */

import Viewport from './viewport/viewport';

declare global {
  interface Window {
    EyeDropper: unknown;
    // For Usersnap
    onUsersnapCXLoad: unknown;
  }
}

export const initViewport = (...args: ConstructorParameters<typeof Viewport>): Viewport => {
  const viewport = new Viewport(...args);

  return viewport;
};
