/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { getCurrentKeyFrame, getIsAtKeyFrame } from './helpers';

import { PolystarPoints } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { MAX_POLYSTAR_POINTS, MIN_POLYSTAR_POINTS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface PolystarPointsFieldProps {
  points: number;
  pointsIsAnimated: boolean;
}

export const PolystarPointsField: React.FC<PolystarPointsFieldProps> = ({ points, pointsIsAnimated }) => {
  const [animated, currentShapeNode, setPoints, setPolystarVariableIsAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentShapeNode?.state?.animatedProperties?.nt,
      state.toolkit.currentShapeNode,
      state.toolkit.setPolystarPoints,
      state.toolkit.setPolystarVariableIsAnimated,
    ],
    shallow,
  );

  const isAtKeyFrame = getIsAtKeyFrame(animated);
  const pointsCurrentKeyFrame = getCurrentKeyFrame(animated, currentShapeNode);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setPoints(Math.round(result.value));
      emitter.emit(EmitterEvent.POLYSTAR_POINTS_UPDATED);
    },
    [setPoints],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (pointsCurrentKeyFrame === '') {
      setPolystarVariableIsAnimated('points', true);
      setPoints(Math.round(points));
    } else {
      removeKeyFrame(pointsCurrentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_POINTS_UPDATED);
    stateHistory.endAction();
  }, [setPoints, setPolystarVariableIsAnimated, pointsCurrentKeyFrame, points]);

  return (
    <div className="w-full">
      <NumberInput
        name="points"
        label={<PolystarPoints className="h-4 w-4" />}
        value={points}
        onChange={handleOnChange}
        showKeyframe
        min={MIN_POLYSTAR_POINTS}
        max={MAX_POLYSTAR_POINTS}
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={isAtKeyFrame}
        isChannelAnimated={pointsIsAnimated}
      />
    </div>
  );
};
