/**
 * Copyright 2024 Design Barn Inc.
 */

import type { Placement, Side, OffsetOptions } from '@floating-ui/react-dom-interactions';
import {
  offset,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  useDelayGroupContext,
  useDelayGroup,
  arrow,
  shift,
  limitShift,
} from '@floating-ui/react-dom-interactions';
import type { Transition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import type { FC } from 'react';
import React, { useState, useMemo, useEffect, useRef, cloneElement } from 'react';

import { ShortcutKey } from '../ShortcutKey';

const GAP = 4;
const ARROW_SIZE = 12;

export const ARROW_OFFSET = ARROW_SIZE / 2 + GAP;

interface TooltipProps {
  children: JSX.Element;
  content: React.ReactNode;
  /**
   * If this tooltip is a nested tooltip, add evt.stopPropagation() to onMouseMoveCapture and onMouseOut.
   * Not added by default because it blocks the parent from receiving the event, interfering with drag inputs.
   */
  disabled?: boolean;
  isNested?: boolean;
  offsetOptions?: OffsetOptions;
  placement?: Placement;
}

/**
 * Tooltip needs a reference element to attach to, so the children can't be a Component or a Fragment.
 *
 * @example
 * <Tooltip content="Hello!">
 *  <button>Click me!</button>
 * </Tooltip>
 *
 * @example
 * <Tooltip content="Hello!">
 *  <div>
 *    <Component />
 *  </div>
 * </Tooltip>
 */
export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  disabled = false,
  isNested = false,
  offsetOptions,
  placement = 'bottom',
}) => {
  const id = useRef(nanoid()).current;

  const { delay, setCurrentId } = useDelayGroupContext();
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const { context, floating, middlewareData, reference, strategy, x, y } = useFloating({
    placement,
    open,
    onOpenChange: (_open) => {
      setOpen(_open);

      if (_open) {
        setCurrentId(id);
      }
    },
    middleware: [
      offset(typeof offsetOptions === 'undefined' ? ARROW_OFFSET : offsetOptions),
      arrow({
        element: arrowRef,
        padding: ARROW_SIZE,
      }),
      shift({
        limiter: limitShift(),
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    useHover(context, { delay, restMs: 100, enabled: !disabled }),
    useFocus(context, { enabled: !disabled }),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
    useDelayGroup(context, { id }),
  ]);

  const framerTransition = useMemo((): Transition => {
    let transition: Transition = { type: 'spring', damping: 20, stiffness: 300 };

    if (typeof delay === 'object') {
      // When in "grouped phase", make the transition faster
      if (delay.open === 1) {
        transition = { duration: 0.1 };
      } else {
        transition = { ...transition, delay: (delay.open || 0) / 1000 };
      }
    }

    return transition;
  }, [delay]);

  const { arrow: { x: arrowX, y: arrowY } = {} } = middlewareData;

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0] as Side];

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({
          ref: reference,
          ...children.props,
          onMouseMoveCapture: (evt) => {
            if (isNested) evt.stopPropagation();
          },
          onMouseOut: (evt) => {
            if (isNested) evt.stopPropagation();
          },
        }),
      )}
      <AnimatePresence>
        {open ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.85 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            transition={framerTransition}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
            className="z-tooltip whitespace-nowrap rounded bg-action-secondary px-2 py-1 text-xs leading-normal text-black"
          >
            <div
              ref={arrowRef}
              className="absolute -z-10 rotate-45 bg-action-secondary"
              style={{
                width: ARROW_SIZE,
                height: ARROW_SIZE,
                left: arrowX ? `${arrowX}px` : '',
                top: arrowY ? `${arrowY}px` : '',
                [staticSide]: '-5px',
              }}
            />
            {content}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};

interface TooltipWithShortcutProps extends TooltipProps {
  shortcut: string;
}

/**
 * @see Tooltip for detailed usage
 */
export const TooltipWithShortcut: FC<TooltipWithShortcutProps> = ({ shortcut, ...props }) => (
  <Tooltip
    {...props}
    content={
      <>
        {props.content}
        {shortcut.length
          ? shortcut.split(' ').map((key, index) => (
              <ShortcutKey key={index} className={index === 0 ? 'ml-2' : 'ml-1'}>
                {key}
              </ShortcutKey>
            ))
          : null}
      </>
    }
  />
);
