/**
 * Copyright 2024 Design Barn Inc.
 */

import type { AnimatedProperty, GradientFillShape, VectorJSON } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';

import type { AnimatedInputProp } from './animatedUtil';

import { AnimatedPositionX, AnimatedPositionY } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { TimelineDualNumberInput } from '~/components/Elements/Input/TimelineDualNumberInput';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setAnimatedGradientEnd } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { formatNumber } from '~/utils';

export const AnimatedGradientEnd: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  const position = animatedProp.value as VectorJSON;

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeByIdOnly(animatedProp.id) as AnimatedProperty;

      if (node.parent) {
        if (result.name === 'posX') {
          setAnimatedGradientEnd(node.parent as GradientFillShape, [result.value, position.y]);
        } else if (result.name === 'posY') {
          setAnimatedGradientEnd(node.parent as GradientFillShape, [position.x, result.value]);
        }
        emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_END_UPDATED);
      }
    },
    [getNodeByIdOnly, animatedProp.id, position.x, position.y],
  );

  return (
    <div className="flex items-center">
      <TimelineDualNumberInput
        onChange={handleOnChange}
        left={formatNumber(position.x)}
        right={formatNumber(position.y)}
        leftOption={{
          name: 'posX',
          label: <AnimatedPositionX className="h-3 w-3" />,
        }}
        rightOption={{
          name: 'posY',
          label: <AnimatedPositionY className="h-3 w-3" />,
        }}
      />
    </div>
  );
};
