/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { shallow } from 'zustand/shallow';

import { Close, ToastErrorIcon, ToastSuccessIcon } from '~/assets/icons';
import { useCreatorStore } from '~/store';

export const AlertContainer: React.FC = () => {
  const [alertOn, setAlertOn] = useState(false);

  const [alert, setAlert] = useCreatorStore((state) => [state.ui.alert, state.ui.setAlert], shallow);

  const text = alert.text;
  const handle = alert.handle || null;
  const handleText = alert.handleText || null;
  const alertColor = alert.alertColor || '#00DDB3';
  const timeout = alert.timeout || 5000;
  const isClosable = alert.isClosable || false;
  const advancedRunOnceEveryPageRefresh = alert.advanced?.runOnceEveryPageRefresh || false;
  const advancedAlertId = alert.advanced?.alertId || 'random';
  const alertPopupCount = useRef<Map<string, number>>(new Map());

  const closeAlert = useCallback((): void => {
    setAlertOn(false);
    setAlert({ text: null });
  }, [setAlert]);

  const handleClick = async (evt: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    evt.preventDefault();
    if (handle) {
      closeAlert();
      await handle();
    }
  };

  const openAlert = useCallback((): void => {
    setAlertOn(true);
    if (!isClosable) {
      setTimeout(() => {
        closeAlert();
      }, timeout);
    }
  }, [isClosable, timeout, closeAlert]);

  const onCloseClickHandler = useCallback(() => {
    closeAlert();
  }, [closeAlert]);

  useEffect(() => {
    if (advancedRunOnceEveryPageRefresh) {
      const currentCount = alertPopupCount.current.get(advancedAlertId) || 0;

      alertPopupCount.current.set(advancedAlertId, currentCount + 1);
    }

    if (text) {
      const newCount = alertPopupCount.current.get(advancedAlertId) || 0;

      const onlyFirstRun = advancedRunOnceEveryPageRefresh && newCount === 1;

      if (!advancedRunOnceEveryPageRefresh || onlyFirstRun) {
        openAlert();
      }
    }

    if (text === null && alertOn) {
      closeAlert();
    }
  }, [text, openAlert, advancedRunOnceEveryPageRefresh, advancedAlertId, closeAlert, alertOn]);

  let alertIcon = null;

  if (alert.icon === 'error') {
    alertIcon = (
      <span>
        <ToastErrorIcon className={`fill-white ${alert.iconClass ?? ''}`} />
      </span>
    );
  } else if (alert.icon === 'success') {
    alertIcon = (
      <span>
        <ToastSuccessIcon className={`fill-white ${alert.iconClass ?? ''}`} />
      </span>
    );
  }

  return (
    <>
      {alertOn &&
        createPortal(
          <div className="fixed top-10 left-1/2 z-alert flex -translate-x-1/2 flex-col items-center justify-center pt-3">
            <div
              className="flex items-center gap-3 rounded-md py-2 pr-2 pl-3 text-sm text-white transition-all duration-500"
              style={{
                backgroundColor: alertColor,
              }}
            >
              {alertIcon}
              <p>{text}</p>
              {handleText && (
                <button
                  className="h-[32px] shrink-0 rounded-lg border border-white px-3 font-bold text-white"
                  onClick={handleClick}
                >
                  {handleText}
                </button>
              )}
              {isClosable && (
                <button>
                  <Close
                    className="h-6 w-6 cursor-pointer fill-current text-white hover:opacity-50"
                    onClick={onCloseClickHandler}
                  />
                </button>
              )}
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};
