/**
 * Copyright 2022 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState, useCallback } from 'react';

import { ErrorDisplay } from './ErrorDisplay';
import { OpenFooter } from './OpenFooter';
import type { SupportFeatureContextType } from './SupportFeatureContext';
import { SupportFeatureContext } from './SupportFeatureContext';

import { Divider } from '~/components/Elements/Divider';
import { Modal } from '~/components/Elements/Modal';
import { SUPPORTED_FEATURES_URL } from '~/data/urls';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  options: Record<string, unknown>;
}

export const SupportFeatures: React.FC<Props> = ({ isOpen, onClose, options }) => {
  const [error, setError] = useState(null);

  // Clear error state when modal is opened
  useEffect(() => {
    if (isOpen) {
      setError(null);
    }
  }, [isOpen]);

  const { openAnywayCallback, openLabel, revertCallback, revertLabel } = options;
  const ctx = {
    isOpen,
    onClose,
    error,
    setError,
    options,
    openAnywayCallback,
    revertLabel,
    revertCallback,
    openLabel,
  };

  const onCloseSupportFeature = useCallback(
    (onSupportFeature?: boolean) => {
      if (onSupportFeature) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <SupportFeatureContext.Provider value={ctx as SupportFeatureContextType}>
      <Modal isOpen={isOpen} onClose={onCloseSupportFeature}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Dialog.Panel className="relative w-[320px] overflow-hidden rounded-md bg-gray-800 align-middle shadow-sm transition-all">
                <Dialog.Title as="h3" className="relative h-full px-4 py-3 text-left text-sm font-bold text-white">
                  <div>This animation has unsupported features</div>
                  <div className="mt-1 text-[10px] text-gray-400">
                    Animations with features not supported by Lottie Creator might not render correctly. View the full
                    list of features that Lottie Creator supports{' '}
                    <a className="underline" href={SUPPORTED_FEATURES_URL} target="_blank" rel="noreferrer">
                      here
                    </a>
                    .
                  </div>
                </Dialog.Title>
                <Divider />
                <ErrorDisplay />
                <Divider />
                <OpenFooter />
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Modal>
    </SupportFeatureContext.Provider>
  );
};
