/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */

import type { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import type { ShapeLayer } from '@lottiefiles/lottie-js';
import type { GroupShape } from '@lottiefiles/toolkit-js';
import { isNaN } from 'lodash-es';

import type { FlattenedItem, TreeItem, TreeItems } from './types';

import { canvasMap } from '~/lib/canvas';
import { useCreatorStore } from '~/store';

export const iOS = typeof window !== 'undefined' ? /iPad|iPhone|iPod/u.test(navigator.platform) : false;

const getDragDepth = (offset: number, indentationWidth: number): number => {
  return Math.round(offset / indentationWidth);
};

const getMaxDepth = <T>({ previousItem }: { previousItem: FlattenedItem<T> | null }): number => {
  if (previousItem) {
    return previousItem.canHaveChildren === false ? previousItem.depth : previousItem.depth + 1;
  }

  return 0;
};

const getMinDepth = <T>({ nextItem }: { nextItem: FlattenedItem<T> | null }): number => {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
};

const flatten = <T extends Record<string, unknown>>(
  items: TreeItems<T>,
  parentId: UniqueIdentifier | null = null,
  depth = 0,
  parent: FlattenedItem<T> | null = null,
): Array<FlattenedItem<T>> => {
  return items.reduce<Array<FlattenedItem<T>>>((acc, item, index) => {
    const flattenedItem: FlattenedItem<T> = {
      ...item,
      parentId,
      depth,
      index,
      isLast: items.length === index + 1,
      parent,
    };

    let { shapes } = item;

    if (!shapes) shapes = [];

    return [...acc, flattenedItem, ...flatten(shapes as Array<FlattenedItem<T>>, item.id, depth + 1, flattenedItem)];
  }, []);
};

export const flattenTree = <T extends Record<string, unknown>>(items: TreeItems<T>): Array<FlattenedItem<T>> => {
  return flatten(items);
};

export const findItem = <T>(items: Array<TreeItem<T>>, itemId: UniqueIdentifier): FlattenedItem<T> => {
  return items.find(({ id }) => id === itemId) as FlattenedItem<T>;
};

export const buildTree = <T extends Record<string, unknown>>(flattenedItems: Array<FlattenedItem<T>>): TreeItems<T> => {
  const root: TreeItem<T> = { id: 'root', shapes: [] };
  const nodes: Record<string, TreeItem<T>> = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, shapes: [] }));

  // Record<string, TreeItem<T>>
  for (const item of items) {
    const { id } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);

    nodes[id] = item;
    parent.shapes?.push(item);
  }

  return root.shapes ?? [];
};

export const findItemDeep = <T extends Record<string, unknown>>(
  items: TreeItems<T>,
  itemId: UniqueIdentifier,
): TreeItem<T> | null => {
  for (const item of items) {
    const { id, shapes } = item;

    if (id === itemId) {
      return item;
    }

    if (shapes?.length) {
      const child = findItemDeep(shapes, itemId);

      if (child) {
        return child;
      }
    }
  }

  return null;
};

export const removeItem = <T extends Record<string, unknown>>(items: TreeItems<T>, id: string): TreeItems<T> => {
  const newItems = [];

  for (const item of items) {
    if (item.id === id) {
      continue;
    }

    if (item.shapes?.length) {
      item.shapes = removeItem(item.shapes, id);
    }

    newItems.push(item);
  }

  return newItems;
};

export const setProperty = <TData extends Record<string, unknown>, T extends keyof TreeItem<TData>>(
  items: TreeItems<TData>,
  id: string,
  property: T,
  setter: (value: TreeItem<TData>[T]) => TreeItem<TData>[T],
): TreeItems<TData> => {
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property]);
      continue;
    }

    if (item.shapes?.length) {
      item.shapes = setProperty(item.shapes, id, property, setter);
    }
  }

  return [...items];
};

const countChildren = <T>(items: Array<TreeItem<T>>, count = 0): number => {
  return items.reduce((acc, item) => {
    const { shapes } = item;

    if (shapes?.length) {
      return countChildren(shapes, acc + 1);
    }

    return acc + 1;
  }, count);
};

export const getChildCount = <T extends Record<string, unknown>>(items: TreeItems<T>, id: UniqueIdentifier): number => {
  if (!id) {
    return 0;
  }

  const item = findItemDeep(items, id);

  return item ? countChildren(item.shapes ?? []) : 0;
};

export const removeChildrenOf = <T>(
  items: Array<FlattenedItem<T>>,
  ids: UniqueIdentifier[],
): Array<FlattenedItem<T>> => {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.shapes?.length) {
        excludeParentIds.push(item.id);
      }

      return false;
    }

    return true;
  });
};

export const getProjection = <T>(
  items: Array<FlattenedItem<T>>,
  activeId: UniqueIdentifier | null,
  overId: UniqueIdentifier | null,
  dragOffset: number,
  indentationWidth: number,
): {
  depth: number;
  isLast: boolean;
  maxDepth: number;
  minDepth: number;
  parent: FlattenedItem<T> | null;
  parentId: string | null;
} | null => {
  let _revertLastChanges = (): void => {};

  _revertLastChanges();

  if (!activeId || !overId) return null;

  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1] as FlattenedItem<T> | null;
  const nextItem = newItems[overItemIndex + 1] as FlattenedItem<T> | null;
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem ? activeItem.depth + dragDepth : 0;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  let parent: FlattenedItem<T> | null = previousItem;
  let previousItemOnDepth: FlattenedItem<T> | null = null;
  let currentDepth = previousItem ? previousItem.depth + 1 : 0;
  const isLast = (nextItem?.depth ?? -1) < depth;

  while (depth !== currentDepth) {
    currentDepth -= 1;
    previousItemOnDepth = parent;
    parent = parent?.parent ?? null;
  }

  if (previousItemOnDepth && previousItemOnDepth.isLast) {
    _revertLastChanges = () => {
      previousItemOnDepth!.isLast = true;
    };
    previousItemOnDepth.isLast = false;
  }

  const getParentId = (): string | null => {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId as string;
    }

    if (depth > previousItem.depth) {
      return previousItem.id as string;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return (newParent ?? null) as string | null;
  };

  return {
    depth,
    maxDepth,
    minDepth,
    parentId: getParentId() as string | null,
    parent,
    isLast,
  };
};

export const getNumbersInRange = (start: number, stop: number): number[] =>
  Array.from({ length: (stop - start) / 1 + 1 }, (_value, index) => start + index);

export const sortByDrawOrders = (layerIds: string[]): string[] => {
  // (Oct 2023) For now assume that layers are of equal hierarchies
  // of either ShapeLayer or GroupShape
  // to revisit after layer simplification
  if (!isNaN(canvasMap.get(layerIds[0] as string)?.drawOrder)) {
    layerIds.sort((layerA, layerB) => {
      const aDrawOrder = canvasMap.get(layerA)?.drawOrder as number;
      const bDrawOrder = canvasMap.get(layerB)?.drawOrder as number;

      if (aDrawOrder < bDrawOrder) {
        return -1;
      }
      if (aDrawOrder > bDrawOrder) {
        return 1;
      }

      return 0;
    });

    return layerIds;
  } else {
    const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

    const parent = getNodeByIdOnly(layerIds[0] as string)?.parent as unknown as ShapeLayer;

    layerIds.sort((layerA, layerB) => {
      const aDrawOrder = parent.shapes.findIndex((node) => (node as unknown as GroupShape).nodeId === layerA);
      const bDrawOrder = parent.shapes.findIndex((node) => (node as unknown as GroupShape).nodeId === layerB);

      if (aDrawOrder < bDrawOrder) {
        return -1;
      }
      if (aDrawOrder > bDrawOrder) {
        return 1;
      }

      return 0;
    });

    return layerIds;
  }
};
