/**
 * Copyright 2022 Design Barn Inc.
 */

import { newAsyncContext, Scope } from 'quickjs-emscripten';

import type { Metadata, PluginOptions } from './Plugin';
import { PluginUIType, Plugin } from './Plugin';
import { openFloatingPlugin } from './utils';

import { PLUGIN_ARTIFACTS_ENDPOINT } from '~/config';
import { SIDEBAR_PLUGINS } from '~/data/plugins';
import { useCreatorStore } from '~/store';

const setLoadedPlugins = useCreatorStore.getState().plugins.setLoadedPlugins;
const setDevPlugin = useCreatorStore.getState().plugins.setDevPlugin;

export interface Manifest {
  height?: number;
  id: Uuid;
  name: string;
  plugin: string;
  ui: string;
  width?: number;
}

export interface PluginAssets {
  manifest: Manifest;
  metadata: Metadata;
  runtimeSource: string;
  uiSource: string;
}

export interface DevPlugin {
  name: string;
  plugin: Plugin;
  url: URL;
}

type Uuid = string;

export class PluginManager {
  private _devPlugin: DevPlugin | null = null;

  private readonly _loadedPlugins: Map<Uuid, Plugin> = new Map();

  public getDevPlugin(): DevPlugin | null {
    return this._devPlugin;
  }

  public getPlugin(id: Uuid): Plugin | undefined {
    return this._loadedPlugins.get(id);
  }

  public isLoaded(id: Uuid): boolean {
    return this._loadedPlugins.has(id);
  }

  // Fetches a plugin and bootstraps it
  public async load(
    id: Uuid,
    options: PluginOptions = { uiType: PluginUIType.FloatingUI, hasToolbarShortcut: false },
  ): Promise<Plugin> {
    // Don't double load plugins
    if (this._loadedPlugins.has(id)) {
      return this._loadedPlugins.get(id) as Plugin;
    }

    setLoadedPlugins([
      ...[...this._loadedPlugins.values()].map((loadedPlugin) => ({
        name: loadedPlugin.name(),
        id: loadedPlugin.id(),
        options: loadedPlugin.pluginOptions,
      })),
      {
        name: options.eagerName ?? 'Placeholder',
        id,
        options,
        loading: true,
      },
    ]);

    let plugin;

    try {
      const { manifest, metadata, runtimeSource, uiSource } = await this._fetchAssets(id);
      // const quickJs = await getQuickJS();
      const context = await newAsyncContext();

      const scope = new Scope();
      const vm = scope.manage(context);

      plugin = new Plugin(manifest, runtimeSource, uiSource, scope, vm, false, options, metadata);

      plugin.bootstrap();
      this._loadedPlugins.set(manifest.id, plugin);

      const params = new URLSearchParams(window.location.search);

      if (
        options.uiType === PluginUIType.FloatingUI &&
        manifest.name.toLowerCase().split(' ').join('-') === params.get('plugin')
      ) {
        if (
          useCreatorStore.getState().user.isAuthorized ||
          ![SIDEBAR_PLUGINS.promptVector.uuid, SIDEBAR_PLUGINS.motionCopilot.uuid].includes(manifest.id)
        ) {
          openFloatingPlugin(manifest.id);
        } else {
          useCreatorStore.getState().ui.setAlert({
            text: `Ooops, ${manifest.name} is available only for logged in users`,
            alertColor: '#D92600',
            icon: 'error',
          });
        }
      }
    } catch (err) {
      setLoadedPlugins([
        ...[...this._loadedPlugins.values()].map((loadedPlugin) => ({
          name: loadedPlugin.name(),
          id: loadedPlugin.id(),
          options: loadedPlugin.pluginOptions,
        })),
        {
          name: options.eagerName ?? 'Placeholder',
          id,
          options,
          loading: false,
          failed: true,
        },
      ]);

      return Promise.reject(err);
    }

    // Update the store
    setLoadedPlugins(
      [...this._loadedPlugins.values()].map((loadedPlugin) => ({
        name: loadedPlugin.name(),
        id: loadedPlugin.id(),
        options: loadedPlugin.pluginOptions,
        manifest: loadedPlugin.manifest,
      })),
    );

    return plugin;
  }

  public async loadDevPlugin(url: URL): Promise<DevPlugin> {
    this.unloadDevPlugin();

    const { manifest, runtimeSource, uiSource } = await this._fetchDevAssets(url);

    const vm = await newAsyncContext();

    const devManifest = {
      ...manifest,
      id: `dev-${manifest.id}`,
    };

    const scope = new Scope();
    const plugin = new Plugin(
      devManifest,
      runtimeSource,
      uiSource,
      scope,
      vm,
      true,
      { hasToolbarShortcut: false, uiType: PluginUIType.FloatingUI },
      { host: new URL('./bootstrapper.html', url).href },
    );

    // The floating window should be mounted (setFloatingPluginId) before the plugin is bootstrapped
    setTimeout(() => {
      plugin.bootstrap();
    }, 0);

    const name = devManifest.name;

    this._devPlugin = { name, plugin, url };
    // Update the store
    setDevPlugin({ name, manifest: devManifest });

    return this._devPlugin;
  }

  public async reloadDevPlugin(): Promise<DevPlugin | null> {
    if (this._devPlugin) {
      const { url } = this._devPlugin;

      this._devPlugin.plugin.cleanUp();
      this._devPlugin = null;
      await this.loadDevPlugin(url);
    }

    return null;
  }

  public unload(id: Uuid): boolean {
    const plugin = this._loadedPlugins.get(id);

    if (plugin) {
      plugin.cleanUp();
      const returnValue = this._loadedPlugins.delete(id);

      setLoadedPlugins(
        [...this._loadedPlugins.values()].map((loadedPlugin) => ({
          name: loadedPlugin.name(),
          id: loadedPlugin.id(),
          options: loadedPlugin.pluginOptions,
        })),
      );

      return returnValue;
    }

    return false;
  }

  public unloadDevPlugin(): boolean {
    if (!this._devPlugin) {
      return false;
    }

    this._devPlugin.plugin.cleanUp();
    this._devPlugin = null;
    // Update the store
    setDevPlugin(null);

    return true;
  }

  private async _fetchAssets(id: Uuid): Promise<PluginAssets> {
    const manifest = await ((
      await fetch(new URL(`./${id}/manifest.json`, PLUGIN_ARTIFACTS_ENDPOINT))
    ).json() as Promise<Manifest>);

    const [runtime, ui, metadata] = await Promise.all([
      fetch(new URL(`./${id}/${manifest.plugin}`, PLUGIN_ARTIFACTS_ENDPOINT)),
      fetch(new URL(`./${id}/${manifest.ui}`, PLUGIN_ARTIFACTS_ENDPOINT)),
      fetch(new URL(`./${id}/metadata.json`, PLUGIN_ARTIFACTS_ENDPOINT)),
    ]);

    const [runtimeSource, uiSource, metadataJSON] = await Promise.all([runtime.text(), ui.text(), metadata.json()]);

    return { manifest, runtimeSource, uiSource, metadata: metadataJSON };
  }

  // Given a url fetches assets to be loaded as a development plugin
  private async _fetchDevAssets(url: URL): Promise<Omit<PluginAssets, 'metadata'>> {
    const manifest = await ((await fetch(new URL('./manifest.json', url))).json() as Promise<Manifest>);

    const [runtime, ui] = await Promise.all([
      fetch(new URL(`./${manifest.plugin}`, url)),
      fetch(new URL(`./${manifest.ui}`, url)),
    ]);

    const [runtimeSource, uiSource] = await Promise.all([runtime.text(), ui.text()]);

    return { manifest, runtimeSource, uiSource };
  }
}

const pluginManager = new PluginManager();

export function getPluginManager(): PluginManager {
  return pluginManager;
}
