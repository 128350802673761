/**
 * Copyright 2023 Design Barn Inc.
 */

import type { AVLayer, GroupShape, Layer, Vector } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

const methodNames = {
  setRotation: 'setRotation',
  setScale: 'setScale',
  setSkew: 'setSkew',
  setSkewAxis: 'setSkewAxis',
  setPosition: 'setPosition',
  setOpacity: 'setOpacity',
  setAnchor: 'setAnchor',
  absolutePosition: 'absolutePosition',
  pivot: 'pivot',
  pivotAtKeyFrame: 'pivotAtKeyFrame',
  getPositionRelativeTo: 'getPositionRelativeTo',
  rotation: 'rotation',
  scale: 'scale',
  skew: 'skew',
  skewAxis: 'skewAxis',
  position: 'position',
  opacity: 'opacity',
  anchorPoint: 'anchorPoint',
  setAbsolutePosition: 'setAbsolutePosition',
};

export function getTransformableMethods(plugin: Plugin, layer: GroupShape | Layer): ObjectMethods {
  const additionalMethods = {
    UNSAFE_setReferencePoint: (node: AVLayer | GroupShape, vector: Vector) => {
      if (!plugin.canAccessUnsafeMethods()) {
        throw new Error('Plugin does not have access to unsafe methods');
      }

      node.setPivot(vector);
      const setAnchorPointsActive = useCreatorStore.getState().ui.setAnchorPointsActive;

      setAnchorPointsActive(node.nodeId, null, true);
      emitter.emit(EmitterEvent.PLUGIN_CANVAS_UPDATE);
    },
  };

  return getObjectMethods(plugin, methodNames, layer, {}, additionalMethods);
}
