/**
 * Copyright 2022 Design Barn Inc.layers.tsx
 */

import { ShapeType, LayerType } from '@lottiefiles/toolkit-js';
import type { ShapeLayerJSON, ShapeJSON, PrecompositionLayerJSON, AVLayer } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { MENU_HEIGHT, MENU_WIDTH } from '../../../../menu/constant';
import type { LayerUI } from '../helper';

import { AnimatedButton } from './Animated';
import { CustomDragOverlay } from './DraggableWrapper/customDragOverlay';
import { useTreeLines } from './hooks';
import { computePaddingLeft } from './layer-helper';
import { LayerActions } from './LayerActions';
import { LayerCollapseButton } from './LayerCollapseButton';
import { LayerThumbnail } from './LayerThumbnail';
import { TreeLine } from './TreeLine';

import { ShapeLayerMenuTimeline, NormalLayerMenu, NestedSceneLayerMenu } from '~/features/menu';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { DragDirection } from '~/store/timelineSlice';
import type { Optional } from '~/types';

export type OptionalShapeLayer = Optional<ShapeLayerJSON, 'shapes'>;

interface LayerRowContainerProp {
  children?: React.ReactNode;
  expanded: boolean;
  hasAnimated: boolean;
  hasChildren: boolean;
  hasParent: boolean;
  highlight: boolean;
  id: string;
  isSelected: boolean;
  level: number;
  refId: string;
}

export const LayerRowContainer: React.FC<LayerRowContainerProp> = ({ children, id, refId, ...props }) => {
  const [setHoverID, canvasHoveredID, dragDirection, hoverOverId, dragDisabled = false] = useCreatorStore(
    (state) => [
      state.timeline.setHoverID,
      state.ui.canvasHoveredNodeId,
      state.timeline.layerConfiguration.dragDirection,
      state.timeline.layerConfiguration.hoverOverId,
      state.timeline.layerConfiguration.dragDisabled,
    ],
    shallow,
  );

  const { expanded, hasAnimated, hasChildren, hasParent, highlight, isSelected, level } = props;

  // Compute padding left to align layers according to their level
  const paddingLeft = useMemo(
    () => `${computePaddingLeft(hasParent, hasChildren, level)}px`,
    [hasParent, hasChildren, level],
  );

  return (
    <>
      {dragDirection === DragDirection.TOP && hoverOverId === id && (
        <CustomDragOverlay direction={dragDirection} disabled={dragDisabled} />
      )}

      <div
        id={id}
        data-layerid={id}
        data-refid={refId}
        className={clsx(
          'relative mt-[1px] box-border flex h-6 w-full flex-row items-center justify-between text-ellipsis whitespace-nowrap border-[0.5px] border-transparent',
          {
            'bg-gray-900': highlight && isSelected,
            'bg-[#1F2429]': highlight && !isSelected,
            rounded: (!expanded && hasChildren) || !hasAnimated,
            'rounded-t': (hasAnimated && !hasChildren) || (isSelected && expanded),
            'border-white': id === canvasHoveredID,
            'hover:border-white': !hoverOverId,
            'drag-inside': !dragDisabled && dragDirection === DragDirection.INSIDE && hoverOverId === id,
            'drag-disabled': dragDisabled && dragDirection === DragDirection.INSIDE && hoverOverId === id,
          },
        )}
        style={{ paddingLeft }}
        onMouseEnter={() => setHoverID(id)}
        onMouseLeave={() => setHoverID(null)}
        data-testid="layer-row-container"
      >
        {children}
      </div>

      {dragDirection === DragDirection.BOTTOM && hoverOverId === id && (
        <CustomDragOverlay direction={dragDirection} disabled={dragDisabled} />
      )}
    </>
  );
};

interface LayerRowProps {
  isMask?: boolean | undefined;
  layer: OptionalShapeLayer | ShapeJSON;
  layerUI: LayerUI;
  onCollapse?: unknown;
}

export const LayerRow: React.FC<LayerRowProps> = ({ isMask, layer, layerUI }) => {
  const name = layer.properties.nm as string;
  const layerType = layer.type as string;

  const { animated, children, last, level, parent } = layerUI;

  const [
    showLayerID,
    showDrawOrder,
    isSelected,
    expandedLayerIds,
    setExpandedLayerIds,
    highlightedLayerIds,
    getNodeByIdOnly,
    isRenamingCurrentLayer,
    setRenamingLayer,
    showPrecompSource,
  ] = useCreatorStore(
    (state) => [
      state.timeline.showLayerID,
      state.timeline.showDrawOrder,
      state.ui.selectedNodesInfo.some((node) => node.nodeId === layer.id),
      state.timeline.expandedLayerIds,
      state.timeline.setExpandedLayerIds,
      state.timeline.highlightedLayerIds,
      state.toolkit.getNodeByIdOnly,
      state.timeline.renamingLayer === layer.id,
      state.timeline.setRenamingLayer,
      state.timeline.showPrecompSource,
    ],
    shallow,
  );

  const displayName =
    layerType === LayerType.PRECOMPOSITION && showPrecompSource
      ? ((layer as PrecompositionLayerJSON).composition.properties.nm as string)
      : name;

  let displayNameFormatted = displayName;

  if (
    layerType === LayerType.PRECOMPOSITION &&
    !showPrecompSource &&
    ((layer as PrecompositionLayerJSON).composition.properties.nm as string) === name
  ) {
    displayNameFormatted = `[${displayName}]`;
  }

  const layerUpdateId =
    layerType === LayerType.PRECOMPOSITION && showPrecompSource
      ? (layer as PrecompositionLayerJSON).composition.id
      : layer.id;

  const highlight = highlightedLayerIds.includes(layer.id);
  const expanded = expandedLayerIds.includes(layer.id);

  const hasAnimated = Boolean(animated.length > 0);

  let hasChildren = Boolean(children.length);

  if (layerType === LayerType.PRECOMPOSITION && hasAnimated) {
    hasChildren = true;
  }

  const hasParent = Boolean(parent.length);
  const rootWithoutChildren = !hasParent && !hasChildren;

  const { treeLines } = useTreeLines(parent, hasChildren, last, level, hasAnimated);

  const handleOnClickLayerCollapse = useCallback(
    (expand: boolean) => {
      setExpandedLayerIds(expand, [layer.id]);
    },
    [setExpandedLayerIds, layer],
  );

  const handleRowDoubleClick = useCallback(() => {
    const avLayer = getNodeByIdOnly(layer.id) as AVLayer;

    if (avLayer.state.type === 'PRECOMPOSITION') {
      emitter.emit(EmitterEvent.TIMELINE_PRECOMP_EDIT_SCENE, {
        id: (layer as PrecompositionLayerJSON).id,
      });
    }
  }, [layer, getNodeByIdOnly]);

  const handleStopRename = useCallback(
    (newName: string) => {
      // Don't allow layers to be renamed to have empty names
      if (newName.trim().length > 0) {
        const avLayer = getNodeByIdOnly(layerUpdateId) as AVLayer;

        stateHistory.beginAction();
        avLayer.setName(newName);
        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event: EmitterEvent.TIMELINE_RENAME_LAYER_END });
        stateHistory.endAction();
      }
      setRenamingLayer(null);
    },
    [layerUpdateId, setRenamingLayer, getNodeByIdOnly],
  );

  useEffect(() => {
    const expandAll = (): void => {
      handleOnClickLayerCollapse(!expanded);
    };

    if (isSelected) {
      emitter.on(EmitterEvent.TIMELINE_SHOW_ALL_KEYFRAMES, expandAll);
    }

    return () => {
      emitter.off(EmitterEvent.TIMELINE_SHOW_ALL_KEYFRAMES, expandAll);
    };
  }, [isSelected, handleOnClickLayerCollapse, expanded]);

  const props = {
    hasParent,
    hasAnimated,
    highlight,
    isSelected,
    expanded,
    hasChildren,
    level,
  };

  const renameRef = React.useRef<HTMLInputElement | null>() as React.MutableRefObject<HTMLInputElement | null>;

  // NOTE: data-layerid is used by the context menu handlers so be sure to add
  // them to elements that should show context menus
  const titleElement = isRenamingCurrentLayer ? (
    <input
      className="w-full border-none bg-transparent selection:bg-teal-800 selection:text-white focus-visible:outline-none"
      defaultValue={displayName}
      ref={(ref) => {
        renameRef.current = ref;
        ref?.focus();
        ref?.select();
      }}
      onBlur={(ev) => handleStopRename(ev.currentTarget.value)}
      onKeyDown={(ev) => {
        if (ev.key === 'Enter') {
          ev.stopPropagation();
          handleStopRename(ev.currentTarget.value);
        }
      }}
    />
  ) : (
    <span data-layerid={layer.id}>
      {isMask ? `Mask - ${displayNameFormatted}` : displayNameFormatted}
      <span data-layerid={layer.id} className={`absolute ml-2 opacity-30 ${showLayerID ? 'visible' : 'invisible'}`}>
        {layer.id} {showDrawOrder && `--- ${layer.properties.do}`}
      </span>
    </span>
  );

  return (
    <>
      <div data-layerid={layer.id}></div>
      <LayerRowContainer id={layer.id} refId={layer.referenceId as string} {...props}>
        <div data-layerid={layer.id} className="flex grow items-center">
          {hasChildren && <LayerCollapseButton expanded={expanded} onClick={handleOnClickLayerCollapse} />}
          {rootWithoutChildren && <div className="w-4" />}
          <TreeLine treeLines={treeLines} />
          <LayerThumbnail layer={layer as ShapeJSON} isMask={isMask} />
          <div data-layerid={layer.id} onDoubleClick={handleRowDoubleClick} className="ml-1 h-[15px] grow">
            {titleElement}
          </div>
        </div>
        <div className="flex items-center gap-1">
          <LayerActions layer={layer} layerUI={layerUI} />
          <div className="flex w-5 items-center">
            <AnimatedButton animated={hasAnimated} layer={layer} />
          </div>
        </div>
      </LayerRowContainer>
    </>
  );
};

export const LayerMenuContainer: React.FC = () => {
  const [timelineContext, layerMenuOpened, selectedNodesInfo, setTimelineContext] = useCreatorStore(
    (state) => [
      state.timeline.timelineContext,
      state.timeline.timelineContext.layerMenuOpened || false,
      state.ui.selectedNodesInfo,
      state.timeline.setTimelineContext,
    ],
    shallow,
  );
  const mousePos = timelineContext.mousePos;

  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
  const selectedNodeId = selectedNodesInfo[0]?.nodeId;
  const selectedRefId = timelineContext.referenceId;
  const selectedNode = selectedNodeId ? getNodeByIdOnly(selectedNodeId) : null;
  const selectedType = selectedNode?.type;

  const isNormalLayer = [ShapeType.GROUP, ShapeType.RECTANGLE, ShapeType.ELLIPSE, ShapeType.STAR, ShapeType.PATH];

  const onCloseMenu = useCallback(() => {
    if (layerMenuOpened) {
      setTimelineContext({
        layerMenuOpened: false,
        mousePos: { x: 0, y: 0 },
      });
    }
  }, [layerMenuOpened, setTimelineContext]);

  const timelineHeight = useCreatorStore.getState().timeline.height;
  const timelineWidth = useCreatorStore.getState().timeline.fullWidth;

  let xOffset = null;
  let yOffset = null;

  if (selectedType === LayerType.SHAPE) {
    if ((mousePos.x as number) + MENU_WIDTH.ShapeLayerMenuTimeline > timelineWidth) {
      xOffset = timelineWidth - MENU_WIDTH.ShapeLayerMenuTimeline;
    }
    if ((mousePos.y as number) + MENU_HEIGHT.ShapeLayerMenuTimeline > timelineHeight) {
      yOffset = timelineHeight - MENU_HEIGHT.ShapeLayerMenuTimeline;
    }
  } else if (isNormalLayer.includes(selectedType)) {
    if ((mousePos.x as number) + MENU_WIDTH.NormalLayerMenu > timelineWidth) {
      xOffset = timelineWidth - MENU_WIDTH.NormalLayerMenu;
    }
    if ((mousePos.y as number) + MENU_HEIGHT.NormalLayerMenu > timelineHeight) {
      yOffset = timelineHeight - MENU_HEIGHT.NormalLayerMenu;
    }
  } else if (selectedType === 'PRECOMPOSITION') {
    if ((mousePos.x as number) + MENU_WIDTH.NestedSceneLayerMenu > timelineWidth) {
      xOffset = timelineWidth - MENU_WIDTH.NestedSceneLayerMenu;
    }
    if ((mousePos.y as number) + MENU_HEIGHT.NestedSceneLayerMenu > timelineHeight) {
      yOffset = timelineHeight - MENU_HEIGHT.NestedSceneLayerMenu;
    }
  }

  if (!xOffset) xOffset = mousePos.x;
  if (!yOffset) yOffset = mousePos.y;

  return (
    <div className="absolute">
      {selectedType === LayerType.SHAPE && layerMenuOpened && (
        <ShapeLayerMenuTimeline isOpen={layerMenuOpened} onClose={onCloseMenu} coord={{ x: xOffset, y: yOffset }} />
      )}
      {isNormalLayer.includes(selectedType) && layerMenuOpened && (
        <NormalLayerMenu isOpen={layerMenuOpened} onClose={onCloseMenu} coord={{ x: xOffset, y: yOffset }} />
      )}
      {selectedType === 'PRECOMPOSITION' && layerMenuOpened && (
        <NestedSceneLayerMenu
          isOpen={layerMenuOpened}
          onClose={onCloseMenu}
          coord={{ x: xOffset, y: yOffset }}
          eventArg={{ id: selectedNodeId, layerRefId: selectedRefId as string }}
        />
      )}
    </div>
  );
};
