/**
 * Copyright 2024 Design Barn Inc.
 */

import { useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { useCreatorStore } from '~/store';
import { SavingState } from '~/store/projectSlice';

const BeforeUnloadListener: React.FC = () => {
  const previousSaveState = useRef<string | null>(null);
  const lastSavedChangeId = useRef<string | null>(null);
  const [currentSaveState, currentChangeId, madeFirstChange, isAuthorized] = useCreatorStore(
    (state) => [
      state.project.info.savingState,
      state.project.info.localChangeId,
      state.project.madeFirstChange,
      state.user.isAuthorized,
    ],
    shallow,
  );

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent): void => {
      if (
        isAuthorized &&
        madeFirstChange &&
        (currentSaveState === SavingState.SAVING || currentChangeId !== lastSavedChangeId.current)
      ) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    if (previousSaveState.current !== currentSaveState && currentSaveState === SavingState.SAVING) {
      lastSavedChangeId.current = currentChangeId;
    }

    previousSaveState.current = currentSaveState;

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isAuthorized, currentSaveState, currentChangeId, madeFirstChange]);

  return null;
};

export { BeforeUnloadListener };
