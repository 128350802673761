/**
 * Copyright 2024 Design Barn Inc.
 */

import type { AVLayer, ShapeJSON } from '@lottiefiles/toolkit-js';
import { LayerType, TrackMatteType } from '@lottiefiles/toolkit-js';
import React, { useCallback, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import type { OptionalShapeLayer } from './Layers';

import { Matte, InvertMatte } from '~/assets/icons';
import { Select } from '~/components/Elements/Select';
import { Tooltip } from '~/components/Elements/Tooltip';
import type { LayerUI } from '~/features/timeline';
import { useClickOutside } from '~/hooks';
import { useMatte } from '~/hooks/useMatte';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { getNodeById } from '~/plugins/shim/utils';
import { useCreatorStore } from '~/store';

interface LayerMatteActionProps {
  layer: OptionalShapeLayer | ShapeJSON;
  layerUI: LayerUI;
}

export const LayerMatteAction: React.FC<LayerMatteActionProps> = ({ layer, layerUI }) => {
  const [isHovered] = useCreatorStore((state) => [state.timeline.hoverId === layer.id], shallow);
  const ref = useRef<HTMLDivElement>(null);

  const layerNode = getNodeById(layer.id) as AVLayer | null;

  const { matteMode } = layerUI;
  const isMatte = layer.properties.td as boolean;

  const { matteOptions, onMatteChange, selectedMatte } = useMatte(layer.id);

  useClickOutside(ref, (event) => {
    if (selectedMatte?.label !== '(No matte)') return;

    const isPropertyPanelClicked = document.getElementById('right-panel')?.contains(event.target as Node);
    const isLayerNodeClicked = document.getElementById(layerNode?.nodeId as string)?.contains(event.target as Node);

    if (isPropertyPanelClicked || isLayerNodeClicked) return;

    layerNode?.setTrackMatteParent(null);
    layerNode?.setTrackMatteType(TrackMatteType.NO_TRACK_MATTE);
    emitter.emit(EmitterEvent.CANVAS_REDRAW);
  });

  const setMatted = useCallback(() => {
    if (!layerNode) return;
    let newMatteType = TrackMatteType.NO_TRACK_MATTE;

    if (matteMode === 0 || matteMode === 2) newMatteType = TrackMatteType.ALPHA;
    if (matteMode === 1) newMatteType = TrackMatteType.ALPHA_INVERTED;
    emitter.emit(EmitterEvent.TIMELINE_LAYER_MATTE_UPDATE, { id: layer.id, matteType: newMatteType });
    emitter.emit(EmitterEvent.CANVAS_REDRAW);
  }, [layer.id, layerNode, matteMode]);

  const isMatteIconVisible = isHovered || matteMode !== 0;

  const isVisibleForLayer = layer.type === LayerType.SHAPE || layer.type === LayerType.PRECOMPOSITION;

  if (!isVisibleForLayer || !isMatteIconVisible) {
    return null;
  }

  let matteIconTooltip = 'Apply Matte';

  if (matteMode !== 0) matteIconTooltip = 'Invert Matte';
  if (isMatte) matteIconTooltip = 'The matte is disabled because it has been used.';

  return (
    <div className="flex items-center gap-1" ref={ref}>
      {matteMode !== 0 && !isMatte && selectedMatte && (
        <div className="ml-0.5 w-[86px] py-1">
          <Select
            styleClass={{ button: 'border-0 pr-5 truncate -mt-[1px] h-[22px]', options: 'w-[160px]' }}
            options={matteOptions}
            selected={selectedMatte}
            onChange={onMatteChange}
          />
        </div>
      )}
      <div className="h-4 w-4">
        <Tooltip content={matteIconTooltip}>
          <button onClick={setMatted} disabled={isMatte}>
            {matteMode === 0 && <Matte className={`text-gray-500 ${isMatte ? '' : 'hover:text-white'}`} />}
            {matteMode === 1 && <Matte className={`text-white`} />}
            {matteMode === 2 && <InvertMatte className={`text-white`} />}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
