/**
 * Copyright 2023 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { DagNodeType, ShapeType } from '@lottiefiles/toolkit-js';
import type { Shape, AVLayer } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { ShapeLayerMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapperLayers } from './ContextMenuWrapperLayers';

import { GB_CONSTANT } from '~/config/growthbook';
import { useCreatorStore } from '~/store';

interface ShapeLayerMenuProps {
  coord: Coordinate;
  isOpen: boolean;
  onClose: () => void;
}

export const ShapeLayerMenuTimeline: React.FC<ShapeLayerMenuProps> = ({ coord, isOpen, onClose }) => {
  const showCreateSceneMenuItem = useFeatureIsOn(GB_CONSTANT.SHOW_CREATE_BREAK_SCENE);

  const [selectedNodesInfo, copiedMask, getNodeByIdOnly] = useCreatorStore(
    (state) => [state.ui.selectedNodesInfo, state.ui.copiedMask, state.toolkit.getNodeByIdOnly],
    shallow,
  );

  const maskAvailable = useCallback((): boolean => {
    const nodeId = selectedNodesInfo[0]?.nodeId;

    if (!nodeId) return false;

    const node = getNodeByIdOnly(nodeId);

    if (!node) return false;
    if (node.nodeType === DagNodeType.SHAPE) return true;
    if (
      'shapes' in node &&
      (node.shapes as Shape[]).filter((sh) =>
        [ShapeType.PATH, ShapeType.RECTANGLE, ShapeType.ELLIPSE, ShapeType.STAR].includes(sh.type),
      ).length === 1
    )
      return true;

    return false;
  }, [getNodeByIdOnly, selectedNodesInfo]);

  const ableToMask = useCallback((): boolean => {
    const nodeId = selectedNodesInfo[0]?.nodeId;

    if (!nodeId) return true;

    const node = getNodeByIdOnly(nodeId) as AVLayer | null;

    if (!node) return true;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (node.masks?.length > 0) return false;
    if (node.state.properties.td) return false;

    return true;
  }, [getNodeByIdOnly, selectedNodesInfo]);

  const renameMenu = ShapeLayerMenuList.find((item) => item.type === 'RenameItem');
  const animatedPropertiesMenu = ShapeLayerMenuList.find((item) => item.type === 'AnimatedProperties');
  const createMaskMenu = ShapeLayerMenuList.find((item) => item.type === 'Create Mask');
  const copyMaskMenu = ShapeLayerMenuList.find((item) => item.type === 'Copy as Mask');
  const pasteMaskMenu = ShapeLayerMenuList.find((item) => item.type === 'Paste Mask');

  if (renameMenu) renameMenu.disabled = selectedNodesInfo.length > 1;
  if (animatedPropertiesMenu) animatedPropertiesMenu.disabled = selectedNodesInfo.length > 1;
  if (createMaskMenu) createMaskMenu.disabled = selectedNodesInfo.length > 1 || !ableToMask();
  if (copyMaskMenu) copyMaskMenu.disabled = !maskAvailable();
  if (pasteMaskMenu) pasteMaskMenu.disabled = !copiedMask || !ableToMask();

  if (!showCreateSceneMenuItem) {
    const createMenu = ShapeLayerMenuList.find((item) => item.type === 'CreateScene');

    if (createMenu) {
      createMenu.isHidden = true;
    }
  }

  return (
    <ContextMenuWrapperLayers
      coord={coord}
      isOpen={isOpen}
      onClose={onClose}
      dropdownItems={ShapeLayerMenuList}
    ></ContextMenuWrapperLayers>
  );
};
