/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { LoadingSpinnerIcon, ShareLinkIcon } from '~/assets/icons';
import { CREATOR_HOME } from '~/config';
import { useProjectAPI } from '~/providers/api/project';
import { useCreatorStore } from '~/store';

interface RemixEnabledProps {
  onDisabled: () => void;
  sharedCode: string;
}

export const RemixEnabled: React.FC<RemixEnabledProps> = ({ onDisabled, sharedCode }) => {
  const [showToast, setShowToast] = useState(false);
  const [setAlert, token] = useCreatorStore((state) => [state.ui.setAlert, state.user.token], shallow);
  const [showLoading, setShowLoading] = useState(false);

  const { unShareFile } = useProjectAPI(token);

  const onUnshareHandler = useCallback(async () => {
    try {
      setShowLoading(true);

      await unShareFile();
      onDisabled();
    } catch {
      setAlert({
        text: 'Something went wrong while disabling the remix link. Please try again later.',
        alertColor: '#D92600',
      });
    } finally {
      setShowLoading(false);
    }
  }, [onDisabled, setAlert, unShareFile]);

  const copyLink = useCallback(async () => {
    const url = `${CREATOR_HOME}?remixId=${sharedCode}`;

    await navigator.clipboard.writeText(url);

    setShowToast(true);

    window.setTimeout(() => {
      setShowToast(false);
    }, 2000);
  }, [sharedCode]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between gap-6">
        <div className="flex flex-col gap-2">
          <div className="text-[14px] text-white">Enable Remix link</div>
          <div className="text-[12px] text-[#BFC8D1]">
            Anyone with the link can copy your animation to their workspace and customize it to their liking.
          </div>
        </div>
        <div className="flex items-center">
          <div className="relative">
            <button
              onClick={onUnshareHandler}
              disabled={showLoading}
              className={`flex h-5 w-9 shrink-0 justify-end rounded-[9999px] bg-[#019D91] p-[2px] ${showLoading ? 'opacity-30' : ''}`}
            >
              <div className="h-4 w-4 rounded-[9999px] bg-white" />
            </button>
            {showLoading && <LoadingSpinnerIcon className="absolute top-0.5 left-2.5 h-4 w-4 animate-spin" />}
          </div>
        </div>
      </div>
      <div className="flex h-[100px] flex-col">
        <div className="flex justify-center">
          <button
            className="flex w-full items-center justify-center gap-2 rounded-md bg-[#019D91] py-1 px-3 text-[14px] text-white"
            onClick={copyLink}
          >
            <ShareLinkIcon className="h-4 w-4" />
            <span>Copy Remix link</span>
          </button>
        </div>
        <div className="mt-[25px] flex justify-center">
          {showToast && (
            <div className="rounded bg-[#20272C] py-1 px-3 text-[14px] text-white shadow-card">Link copied</div>
          )}
        </div>
      </div>
    </div>
  );
};
