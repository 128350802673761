/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AngleJSON, AVLayer } from '@lottiefiles/toolkit-js';
import { Angle } from '@lottiefiles/toolkit-js';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { Rotation as RotationIcon } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { PeriodicInput } from '~/components/Elements/Input/PeriodicInput';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { setAnimatedRotation } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { formatNumber } from '~/utils';

export const AnimatedRotation: React.FC<AnimatedInputProp> = ({ animatedProp, parentId, parentType }) => {
  // const { deg } = animatedProp.value as AngleJSON;

  // temp fix
  const animatedValue = animatedProp.value as AngleJSON | null;
  const { deg } = animatedValue ? animatedValue : { deg: 0 };
  const [getNodeByIdOnly, selectedNodeId, selectedNodeRotation, multiselect] = useCreatorStore(
    (state) => [
      state.toolkit.getNodeByIdOnly,
      state.ui.selectedNodesInfo[0]?.nodeId as string,
      state.toolkit.currentTransform.rotation,
      state.ui.selectedNodesInfo.length > 1,
    ],
    shallow,
  );

  const rotation = useMemo(() => {
    // NOTE(miljau): starshape rotation isnt changable through the canvas yet(?)
    if (parentType !== 'sr' && selectedNodeId === parentId && !multiselect) {
      return selectedNodeRotation;
    }

    return deg;
  }, [parentType, selectedNodeId, parentId, multiselect, deg, selectedNodeRotation]);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeByIdOnly(animatedProp.id) as AVLayer;

      if (node.parent) {
        if (result.name === 'rotation') {
          const newRotation = formatNumber(Math.trunc(rotation / 360) * 360 + result.value);

          if (node.parent.nodeType === 'Shape') {
            (node.parent as AVLayer).setRotation(new Angle(newRotation));
          } else {
            setAnimatedRotation(node.parent as AVLayer, [newRotation]);
          }
        } else if (result.name === 'numberOfRotations') {
          setAnimatedRotation(node.parent as AVLayer, [result.value * 360 + (rotation % 360)]);
        }

        emitter.emit(EmitterEvent.ANIMATED_ROTATION_UPDATED);
      }
    },
    [getNodeByIdOnly, animatedProp, rotation],
  );

  return (
    <div className="flex items-center">
      <PeriodicInput
        value={formatNumber(rotation)}
        name="rotation"
        label={<RotationIcon className="h-2 w-2" />}
        append="°"
        period={0}
        allowNegative
        onChange={handleOnChange}
        styleClass={styleClass}
      />
    </div>
  );
};
