/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import RemixAnimation from '~/assets/animations/remix.json';
import { LoadingSpinnerIcon } from '~/assets/icons';
import { useProjectAPI } from '~/providers/api/project';
import { useCreatorStore } from '~/store';

interface RemixDisabledProps {
  onEnabled: (shareCode: string) => void;
}

export const RemixDisabled: React.FC<RemixDisabledProps> = ({ onEnabled }) => {
  const [setAlert, token] = useCreatorStore((state) => [state.ui.setAlert, state.user.token], shallow);
  const [showLoading, setShowLoading] = useState(false);

  const { shareFile } = useProjectAPI(token);

  const copyLink = useCallback(async () => {
    try {
      setShowLoading(true);
      const data = await shareFile();

      if (data?.['shareCode']) {
        onEnabled(data['shareCode']);
      }
    } catch {
      setAlert({
        text: 'Something went wrong while enabling the remix link. Please try again later.',
        alertColor: '#D92600',
      });
    } finally {
      setShowLoading(false);
    }
  }, [onEnabled, setAlert, shareFile]);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-1">
        <div className="text-center text-[18px] font-bold text-white">Share Remix link</div>
        <div className="text-center text-[12px] text-[#BFC8D1]">
          Anyone with the link can copy your animation to their workspace and customize it to their liking.
        </div>
      </div>
      <div className="flex justify-center">
        <div className="h-[249px] w-[332px] overflow-hidden rounded-lg">
          <dotlottie-player renderer="svg" autoplay loop src={JSON.stringify(RemixAnimation)}></dotlottie-player>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className={`flex h-8 w-[332px] items-center justify-center rounded-md py-1 px-3 text-[14px] text-white ${showLoading ? 'bg-[#00C1A24D]' : 'bg-[#019D91]'} `}
          disabled={showLoading}
          onClick={copyLink}
        >
          {!showLoading && 'Enable Remix link'}
          {showLoading && <LoadingSpinnerIcon className="h-4 w-4 animate-spin" />}
        </button>
      </div>
    </div>
  );
};
