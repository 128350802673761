/**
 * Copyright 2024 Design Barn Inc.
 */

import { GradientFillType } from '@lottiefiles/toolkit-js';
import { clamp } from 'lodash-es';
import React, { useEffect, useState, useRef, useCallback } from 'react';

import type { GradientPointType } from './GradientPoint';
import { GradientPoint } from './GradientPoint';

import type { MouseAction } from '@/ColorPicker/components/ColorPicker';
import { generateGradientStyle, rgbToHex } from '@/ColorPicker/helpers';

interface Props {
  activePointIndex: number;
  addPoint: (value: number) => void;
  changeActivePointIndex: (index: number) => void;
  points: GradientPointType[];
  updateGradientLeft: (left: number, index: number, name: MouseAction) => void;
}

export const GradientPoints: React.FC<Props> = ({
  activePointIndex,
  addPoint,
  changeActivePointIndex,
  points,
  updateGradientLeft,
}) => {
  const [pointsStyle, setpointsStyle] = useState({});
  const [width, setWidth] = useState(0);

  const pointsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pointsContainerRef.current) {
      setWidth(pointsContainerRef.current.clientWidth);
    }
  }, []);
  useEffect(() => {
    const style = generateGradientStyle(points, GradientFillType.LINEAR, 90);

    setpointsStyle({ background: style });
  }, [points]);

  const pointsContainerClick = useCallback(
    (event: MouseEvent) => {
      if (!pointsContainerRef.current) return;

      const gradientPoints = document.getElementsByClassName('picker-pointer');

      const gradientPointSelected = Array.from(gradientPoints).some((gradientPoint) =>
        gradientPoint.contains(event.target as Node),
      );

      if (gradientPointSelected) {
        return;
      }

      const containerRect = pointsContainerRef.current.getBoundingClientRect();
      const offsetX = event.pageX - containerRect.left;
      const left = offsetX / containerRect.width;

      addPoint(clamp(left, 0, 1));
    },
    [addPoint, pointsContainerRef],
  );

  const hexColor = useCallback((point: GradientPointType) => {
    return rgbToHex(point.red, point.green, point.blue);
  }, []);

  return (
    <div className="transparent-pattern-underlay">
      <div className="gradient" style={pointsStyle} onPointerDown={pointsContainerClick}>
        <div className="gradient-slider-container" ref={pointsContainerRef}>
          {points.map((point, index: number) => (
            <GradientPoint
              key={index}
              activePointIndex={activePointIndex}
              hexColor={hexColor(point)}
              index={index}
              point={point}
              points={points}
              width={width}
              changeActivePointIndex={changeActivePointIndex}
              updateGradientLeft={updateGradientLeft}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
