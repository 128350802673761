/**
 * Copyright 2022 Design Barn Inc.
 */

import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import type React from 'react';
import { useEffect, useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { setupExistingProject } from './shared';

import { WORKFLOW_DASHBOARD } from '~/config';
// eslint-disable-next-line no-restricted-imports
import { uploadDotLottie, uploadJSON } from '~/features/upload/components/helper';
// eslint-disable-next-line no-restricted-imports
import { executeUpload, exportDotCreatorToJSON } from '~/features/upload/components/middleware';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { resetLayerUI } from '~/lib/layer';
import { stateHistory, toolkit } from '~/lib/toolkit';
import type { ExistingFileType } from '~/providers/api/project';
import { useProjectAPI } from '~/providers/api/project';
import { useCreatorStore } from '~/store';
import { verifySanitizeURL } from '~/utils';

interface CurrentData {
  createdByUserId: string;
  fileId: string;
  fileKey: string;
  fileVersionId: string;
  folderId: string;
  folderName: string | null;
  projectId: string;
  projectName: string | null;
  workspaceId: string;
}

export const FetchExistingFile: React.FC = () => {
  const [currentFileId, currentRemixId, isExportedFile, token, setUrlType] = useCreatorStore(
    (state) => [
      state.project.fetchExistingFile.fileId,
      state.project.fetchExistingFile.remixId,
      state.project.fetchExistingFile.isExportedFile,
      state.user.token,
      state.project.setUrlType,
    ],
    shallow,
  );
  const { getFileById, getFileBySharedCode } = useProjectAPI(token);

  const setProjectData = (current: CurrentData | null, fileName: string, backgroundColor: string): void => {
    const setInfo = useCreatorStore.getState().project.setInfo;

    if (current) {
      const {
        createdByUserId,
        fileId,
        fileKey,
        fileVersionId,
        folderId,
        folderName,
        projectId,
        projectName,
        workspaceId,
      } = current;

      // Update existing directory for update
      setupExistingProject({
        workspaceId,
        projectId,
        folderId,
        projectName,
        folderName,
      });

      // Update existing api key for update
      setInfo({
        name: fileName,
        fileSubscribeKey: fileKey,
        fileVersionId,
        createdByUserId,
        fileId,
      });
    } else {
      setInfo({
        name: fileName,
      });
      useCreatorStore.getState().project.setMadeFirstChange(true);
    }

    if (backgroundColor) {
      useCreatorStore.getState().canvas.setBackground({ color: backgroundColor, opacity: 100 });
    }
  };

  const importToCanvasFromCreator = useCallback(
    (current: CurrentData | null, fileName: string, backgroundColor: string, json: unknown) => {
      stateHistory.offTheRecord(() => {
        const toolkitJSON = json?.state?.toolkit;

        if (toolkitJSON) {
          // Hydrate all toolkit states (all scenes)
          toolkit.fromJSON(toolkitJSON);

          emitter.emit(EmitterEvent.TOOLKIT_STATE_HYDRATED);

          setProjectData(current, fileName, backgroundColor);

          useCreatorStore.getState().ui.resetSelection();

          // Reset layer map
          resetLayerUI();
          useCreatorStore.getState().timeline.clearTabState();
          emitter.emit(EmitterEvent.TOOLKIT_JSON_IMPORTED);
        }
      });
    },
    [],
  );

  const parsingExportedFile = useCallback(async (exportedId: string) => {
    const setLoader = useCreatorStore.getState().ui.setLoader;
    const setFetchExistingFile = useCreatorStore.getState().project.setFetchExistingFile;

    try {
      const urlExtension = exportedId.split('.').pop();
      const response = await axios.get(verifySanitizeURL(exportedId));

      if (response.status === 200) {
        if (urlExtension === 'lottie') {
          await uploadDotLottie(exportedId);
        } else {
          const creatorFileJSON = response.data;

          if (creatorFileJSON) {
            await uploadJSON(creatorFileJSON);
          }
        }
      } else if (response.status === 403) {
        setFetchExistingFile({ fileNotFound: true });
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        setFetchExistingFile({ fileNotFound: true });
      } else {
        setFetchExistingFile({ failedToLoad: true });
      }
    } finally {
      setFetchExistingFile({ status: false });
    }

    setLoader({
      isLoading: false,
    });
  }, []);

  const parsingExistingFile = useCallback(
    async (fileId: string, remixId?: string) => {
      const setLoader = useCreatorStore.getState().ui.setLoader;
      const setFetchExistingFile = useCreatorStore.getState().project.setFetchExistingFile;

      try {
        let result: ExistingFileType | null = null;

        if (fileId) {
          result = await getFileById(fileId);
        }
        if (remixId) {
          result = await getFileBySharedCode(remixId);
        }

        if (result) {
          const creatorFileUrl = result.fileObject.url;

          // From Workflow File API
          // dotLottie/lottie/json: 'application/zip'
          // dotCreator: 'application/json'
          const isDotCreator = Boolean(result.fileObject.attributes.contentType === 'application/json');

          if (creatorFileUrl) {
            const creatorFile = await fetch(creatorFileUrl);

            let creatorFileJSON = null;
            let tempData = null;

            let isDotLottie = false;

            if (isDotCreator) {
              // dotCreator files opened in Workflow
              creatorFileJSON = await creatorFile.json();

              tempData = await exportDotCreatorToJSON(cloneDeep(creatorFileJSON?.state?.toolkit));
            } else if (creatorFile.body) {
              // lottie/json files opened in Workflow
              const dotLottieBuffer = await creatorFile.arrayBuffer();

              tempData = cloneDeep(dotLottieBuffer);
              isDotLottie = true;
            }

            if (tempData) {
              if (creatorFileJSON) {
                const current: CurrentData | null = fileId
                  ? {
                      workspaceId: result.project.workspaceId,
                      fileKey: result.fileObject.key,
                      fileVersionId: result.currentVersionId,
                      projectId: result.projectId,
                      projectName: result.project.title,
                      folderId: result.folderId,
                      folderName: result.folder ? result.folder.name : null,
                      createdByUserId: result.createdByUserId,
                      fileId: currentFileId as string,
                    }
                  : null;

                // tempData: Lottie JSON
                // creatorFileJSON: JSON (state.toolkit)
                await executeUpload({
                  revertLabel: 'Back to workspace',
                  data: tempData,
                  execute: () => {
                    importToCanvasFromCreator(current, result.name, result.backgroundColor, creatorFileJSON as unknown);
                    if (creatorFileJSON?.themes) {
                      useCreatorStore.getState().project.setThemes(creatorFileJSON.themes);
                    }
                  },
                  revert: () => {
                    window.location.href = `${WORKFLOW_DASHBOARD}`;
                  },
                });
              } else if (isDotLottie) {
                // tempData: Uint8Array
                await executeUpload({
                  revertLabel: 'Back to workspace',
                  type: 'dotLottie',
                  data: tempData,
                  execute: async () => {
                    await uploadDotLottie(tempData);
                    setUrlType(null);
                    setProjectData(null, result.name, result.backgroundColor);
                  },
                  revert: () => {
                    window.location.href = `${WORKFLOW_DASHBOARD}`;
                  },
                });
              }
            }
          }
        }

        if (!result) {
          setFetchExistingFile({ fileNotFound: true });
          setUrlType(null);
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          setFetchExistingFile({ fileNotFound: true });
          setUrlType(null);
        } else {
          setFetchExistingFile({ failedToLoad: true });
        }
      } finally {
        setFetchExistingFile({ status: false });
      }

      setLoader({
        isLoading: false,
      });
    },
    [setUrlType, currentFileId, getFileById, getFileBySharedCode, importToCanvasFromCreator],
  );

  const loadFile = useCallback((): void => {
    if (currentFileId) {
      if (isExportedFile) {
        parsingExportedFile(currentFileId);
      } else {
        parsingExistingFile(currentFileId);
      }
    }
    if (currentRemixId) {
      parsingExistingFile('', currentRemixId);
    }
  }, [currentFileId, isExportedFile, parsingExistingFile, parsingExportedFile, currentRemixId]);

  useEffect(() => {
    loadFile();
  }, [currentFileId, parsingExistingFile, isExportedFile, parsingExportedFile, loadFile]);

  return null;
};
