/**
 * Copyright 2021 Design Barn Inc.
 */

import clsx from 'clsx';
import type { KeyboardEventHandler } from 'react';
import React, { useState, useCallback, useRef, useMemo } from 'react';

import KeyframeButton from '../Button/KeyframeButton';

import { BindDragInputTo } from './BindDragInputTo';
import type { NumberResult } from './types';
import { useNumberInput } from './useNumberInput';
import { useTimeInput } from './useTimeInput';

import './input.css';
import { NumberInputPrecision } from '~/data/constant';

export const defaultStyle = {
  label:
    'p-1 h-[24px] w-full text-xs font-normal bg-gray-700 rounded border border-transparent focus-within:border-teal-500',
  input: 'text-xs pl-1 font-normal text-left bg-gray-700 focus:outline-none number-input',
  span: 'flex justify-center items-center text-gray-500 min-w-[16px]',
};

export interface NumberInputStyle {
  input?: string;
  keyframe?: string;
  label?: string;
  span?: string;
}

export interface TimerSetting {
  fps: number;
}

export interface NumberInputProps {
  append?: string;
  customInputParserFn?: (value: string, isManualInput: boolean) => string;
  hasKeyframe?: boolean;
  isChannelAnimated?: boolean;
  isDisabled?: boolean;
  keyframeShortcut?: string;
  label?: string | React.ReactNode | null;
  max?: number | null;
  message?: string | null | undefined;
  min?: number | null;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (result: NumberResult) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyframeClick?: () => void;
  precision?: number;
  showKeyframe?: boolean;
  step?: number;
  styleClass?: NumberInputStyle;
  testID?: string;
  timerSetting?: TimerSetting;
  value: number;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  append = '',
  customInputParserFn,
  hasKeyframe = false,
  isChannelAnimated = false,
  isDisabled = false,
  label = null,
  max = null,
  message,
  min = null,
  name,
  onBlur,
  onClick,
  onChange = null,
  onKeyDown,
  onKeyframeClick,
  precision = NumberInputPrecision,
  showKeyframe = false,
  step = 1,
  styleClass,
  testID = 'number-input',
  timerSetting = null,
  value,
  keyframeShortcut = '',
}) => {
  const [showMessage, setShowMessage] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const style = useMemo(() => ({ ...defaultStyle, ...styleClass }), [styleClass]);
  const [input, { handleInputChange, handleInputStop, handleOnBlur }] = (timerSetting ? useTimeInput : useNumberInput)({
    name,
    value,
    min,
    max,
    precision,
    inputRef,
    onChange,
    step,
    append,
    timerSetting,
    customInputParserFn,
  });

  const handleKeyframeClick = useCallback((): void => {
    if (onKeyframeClick) {
      onKeyframeClick();
    }
  }, [onKeyframeClick]);

  const handleDrag = useCallback(
    (obj: Record<string, Record<string, string>>): void => {
      if (isDisabled) {
        return;
      }

      const newEvent = new Event('ChangeEvent');

      handleInputChange({ ...newEvent, ...obj, type: 'onDrag' } as unknown as React.ChangeEvent<HTMLInputElement>);
      if (!timerSetting) {
        handleOnBlur();
      }
    },
    [isDisabled, handleInputChange, handleOnBlur, timerSetting],
  );

  const handleDragStop = useCallback(
    (obj: Record<string, Record<string, string>>): void => {
      if (isDisabled) {
        return;
      }

      const newEvent = new Event('DragStop');

      handleInputStop({ ...newEvent, ...obj } as unknown as React.ChangeEvent<HTMLInputElement>);
      handleOnBlur();
    },
    [isDisabled, handleInputStop, handleOnBlur],
  );

  const handleOnBlurInput = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }

      setShowMessage(true);
    },
    [onBlur],
  );

  const handleOnClick = useCallback(() => {
    if (isDisabled) {
      return;
    }

    inputRef.current?.select();
  }, [isDisabled]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) {
        return;
      }

      setShowMessage(false);
      handleInputChange(event);
    },
    [isDisabled, handleInputChange],
  );

  return (
    <>
      <div className="relative flex" onClick={onClick}>
        <label
          className={clsx('group flex items-center', style.label, isDisabled ? '' : 'number-input-label')}
          onBlur={handleOnBlur}
          data-testid="number-input-container"
          onClick={() => {
            // When click number input select all the text
            inputRef.current?.select();
          }}
        >
          {label && (
            <BindDragInputTo
              inputRef={inputRef}
              isDisabled={isDisabled}
              value={input}
              setValue={handleDrag}
              onStop={handleDragStop}
            >
              <span className={style.span} data-testid="number-input-label">
                {label}
              </span>
            </BindDragInputTo>
          )}

          <input
            disabled={isDisabled}
            autoComplete="off"
            ref={inputRef}
            name={name}
            className={clsx('w-full', style.input, isDisabled ? 'text-[#A1ADB7]' : '')}
            value={showMessage ? message ?? input : input}
            min={min ?? 0}
            max={max ?? 100}
            onBlur={handleOnBlurInput}
            onChange={handleOnChange}
            onKeyDown={onKeyDown}
            data-testid={testID}
            onClick={handleOnClick}
          />
        </label>
        {showKeyframe && (
          <div className="absolute right-[-9px] top-[2px]">
            <KeyframeButton
              hasKeyframe={hasKeyframe}
              isChannelAnimated={isChannelAnimated}
              onClick={handleKeyframeClick}
              withTooltip
              keyframeShortcut={keyframeShortcut}
            />
          </div>
        )}
      </div>
    </>
  );
};
