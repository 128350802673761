/**
 * Copyright 2023 Design Barn Inc.
 */

import React from 'react';

import { RulersMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapper } from './ContextMenuWrapper';

interface GuidesMenuProps {
  coord: Coordinate;
  onClose: () => void;
}

export const RulersMenu: React.FC<GuidesMenuProps> = ({ coord, onClose }) => {
  return <ContextMenuWrapper coord={coord} onClose={onClose} dropdownItems={RulersMenuList} />;
};
