/**
 * Copyright 2024 Design Barn Inc.
 */

import { Vector2, Vector3, MeshBasicMaterial, Mesh, PlaneGeometry } from 'three';

import { Elements } from '..';

import { materialOptions, RENDER_ORDERS } from '.';
import { WHITE_COLOR, BLUE_COLOR, CObject3D } from '~/features/canvas';

export const GRADIENT_HANDLE_OFFSET = 14;

const reusableMaterials = {
  squareHandleInner: new MeshBasicMaterial({ color: WHITE_COLOR, ...materialOptions }),
  squareHandleOuter: new MeshBasicMaterial({ color: BLUE_COLOR, ...materialOptions }),
};

const createSquareHandleAt = (position: Vector2, type: Elements): CObject3D => {
  const outerMesh = new Mesh(new PlaneGeometry(12, 12), reusableMaterials.squareHandleOuter);
  const innerMesh = new Mesh(new PlaneGeometry(8, 8), reusableMaterials.squareHandleInner);

  outerMesh.renderOrder = RENDER_ORDERS.START_AND_END_POINTS;
  innerMesh.renderOrder = RENDER_ORDERS.START_AND_END_POINTS;

  const square = new CObject3D().add(outerMesh, innerMesh);

  square.userData['type'] = type;
  square.position.set(position.x, position.y, 0);

  return square;
};

export const rotateGradientLineHandles = (handles: CObject3D[], lineDirection: Vector2): void => {
  handles.forEach((handle) => {
    handle.rotation.set(0, 0, 0);
    handle.rotateOnAxis(new Vector3(0, 0, 1), lineDirection.angle());
  });
};

export const getOffsettedLineStartAndEnd = (
  startPoint: Vector2,
  endPoint: Vector2,
  overrideOffset?: number,
): { end: Vector2; start: Vector2 } => {
  const offset = overrideOffset
    ? new Vector2(overrideOffset, overrideOffset)
    : new Vector2(GRADIENT_HANDLE_OFFSET, GRADIENT_HANDLE_OFFSET);

  const direction = endPoint.clone().sub(startPoint).normalize();
  const adjustedStartPoint = startPoint.clone().sub(offset.clone().multiply(direction));
  const adjustedEndPoint = endPoint.clone().add(offset.clone().multiply(direction));

  return { start: adjustedStartPoint, end: adjustedEndPoint };
};

export const createGradientLineHandles = (startPoint: Vector2, endPoint: Vector2): CObject3D[] => {
  const handles: CObject3D[] = [];

  const { end, start } = getOffsettedLineStartAndEnd(startPoint, endPoint);
  const startHandle = createSquareHandleAt(start, Elements.START_POINT);
  const endHandle = createSquareHandleAt(end, Elements.END_POINT);

  rotateGradientLineHandles([startHandle, endHandle], endPoint.clone().sub(startPoint).normalize());

  handles.push(startHandle, endHandle);

  return handles;
};
