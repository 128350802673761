/**
 * Copyright 2024 Design Barn Inc.
 */

import type { AngleJSON, AnimatedAngleProperty } from '@lottiefiles/toolkit-js';
import { Angle } from '@lottiefiles/toolkit-js';
import React, { useCallback } from 'react';

import type { AnimatedInputProp } from './animatedUtil';
import { styleClass } from './animatedUtil';

import { ScaleX } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';

export const AnimatedTrimOffset: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const deg = (animatedProp.value as AngleJSON | null)?.deg ?? 0;
  const getNodeByIdOnly = useCreatorStore((state) => state.toolkit.getNodeByIdOnly);

  const handleOnChange = useCallback(
    (result: NumberResult): void => {
      const node = getNodeByIdOnly(animatedProp.id) as AnimatedAngleProperty;

      if (result.name === 'trim-offset') {
        node.setValue(new Angle(result.value));
      }
      emitter.emit(EmitterEvent.ANIMATED_SHAPE_TRIM_OFFSET_UPDATED);
    },
    [getNodeByIdOnly, animatedProp],
  );

  return (
    <div className="flex items-center">
      <NumberInput
        name="trim-offset"
        onChange={handleOnChange}
        value={deg}
        precision={4}
        styleClass={styleClass}
        append="°"
        min={0}
        max={360}
        label={<ScaleX className="h-3 w-3" />}
      />
    </div>
  );
};
