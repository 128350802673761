/**
 * Copyright 2022 Design Barn Inc.
 */

export const FILE_UPLOAD_CREATE_REQUEST_MUTATION = `
 mutation FileUploadCreateRequest($input: CreateUploadRequestInput) {
   fileUploadRequestCreate(input: $input) {
     fields
     key
     url
   }
 }
`;

export const FILE_UPDATE_SUBSCRIPTION = `
 subscription FileUpdate($key: String!) {
   fileUpdate(key: $key) {
     versionId
   }
 }
`;

export const FILE_CREATE_MUTATION = `
   mutation FileCreate($input: FileCreateInput!) {
       fileCreate(input: $input) {
           id
           currentVersionId
       }
   }
`;

export const FILE_VERSION_CREATE_MUTATION = `
  mutation FileVersionCreate($input: FileVersionCreateInput!) {
    fileVersionCreate(input: $input) {
      id
      fileVersionId
    }
  }
`;

export const FILE_VERSION_QUERY = `
  query file($id: ID!) {
    file(id: $id) {
      currentVersionId
    }
  }
`;

export const FILE_BY_ID_QUERY = `
  query file($id: ID!) {
    file(id: $id) {
      createdByUserId
      currentVersionId
      folderId
      id
      name
      backgroundColor
      projectId
      project {
        workspaceId
        title
      }
      folder {
        projectId
        name
      }
      type
      fileObject {
        key
        url
        versionId
        attributes {
            contentType
        }
      }
    }
  }
`;

export const FILE_BY_SHARED_CODE_QUERY = `
  query sharedFile($shareCode: String!) {
    publicShareByCode(shareCode: $shareCode) {
      resource {
        ... on File {
          createdByUserId
          currentVersionId
          folderId
          id
          name
          backgroundColor
          projectId
          project {
            workspaceId
            title
          }
          folder {
            projectId
            name
          }
          fileObject {
            key
            url
            versionId
            attributes {
                contentType
            }
          }
        }
      }
    }
  }
`;

export const GET_SHARE_CODE_QUERY = `
  query publicShare($resourceId: ID!) {
    publicShare(resourceType: FILE, resourceId: $resourceId) {
      accessLevels
      shareCode
    }
  }
`;

export const SHARE_FILE_MUTATION = `
    mutation addPublicSharedResource($resourceId: ID!, $resourceType: PublicShareType!, $access: [String!]!, $allowGuestView: Boolean) {
      publicShareCreate(
        resourceId: $resourceId
        resourceType: $resourceType
        access: $access
        input: {allowGuestView: $allowGuestView}
      ) {
        id
        accessLevels
        allowGuestView
        shareCode
        expireAt
      }
    }
`;

export const RECENT_FILES_QUERY = `
    query getRecentlyModifiedFiles {
      filesRecentlyModified(fileType: CreatorFile, count: 10) {
        id
        backgroundColor
        name
        updatedAt
        fileObject {
          thumbnails {
            png {
              medium {
                url
              }
            }
          }
        }
      }
    }
`;
