/**
 * Copyright 2024 Design Barn Inc.
 */

import type { ChangeEventHandler } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useClickOutside } from '~/hooks';

export const TitleInput: React.FC<{
  containerClassName?: string;
  initialValue: string;
  onClose: (title: string) => void;
}> = ({ containerClassName = 'after:max-w-[min(25vw,540px)]', initialValue, onClose }) => {
  const [value, setValue] = useState(() => initialValue || '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (evt) => {
      if (evt.key === 'Enter') {
        onClose(value);
      }
    },
    [onClose, value],
  );

  useClickOutside(inputRef, () => onClose(value), null);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((evt) => {
    setValue(evt.target.value);
  }, []);

  return (
    <div
      className={`inline-grid after:invisible after:col-start-2 after:row-start-1 after:whitespace-nowrap after:px-1.5 after:text-xs after:content-[attr(data-value)] ${containerClassName}`}
      data-value={value}
    >
      <input
        className="col-start-2 row-start-1 min-w-0 rounded border border-[#F5F8FA] bg-transparent px-1 text-xs text-white outline-none"
        value={value}
        ref={inputRef}
        minLength={1}
        maxLength={100}
        onChange={onChange}
        onKeyDown={onKeyDown}
        size={1}
      />
    </div>
  );
};
