/**
 * Copyright 2024 Design Barn Inc.
 */

/* eslint-disable padding-line-between-statements */
/* eslint-disable no-case-declarations */
import { ShapeType, PropertyType, LayerType, GradientFillType, DagNodeType } from '@lottiefiles/toolkit-js';
import type {
  PrecompositionLayerJSON,
  TrimShape,
  ColorStopJSON,
  GradientFillShape,
  GradientStrokeShape,
  Layer,
  PrecompositionLayer,
  Track,
  Shape,
  AnimatedGradientProperty,
  AnimatedMultiDPropertyJSON,
  VectorJSON,
  ShapeLayer,
  MaskModeType,
  PathShape,
  GroupShape,
} from '@lottiefiles/toolkit-js';
import { Color, Vector2, Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';

import { AnimatedProperty, rotateAboutPoint } from '../3d/shapes/transform';
import type { DrawableBezierShape } from '../3d/threeFactory';
import { isPath, onMaskUpdate, getMaskModeIndex, updateBezierPlaneSize, updateBezierShape } from '../3d/threeFactory';
import { getAccumulatedOpacity } from '../3d/utils/three';
import type { BezierMesh } from '../types';
import { CMesh } from '../types';

// import { isCompoundBezier } from './helpers';

import {
  canvasMap,
  modifierMap,
  precompLayerMap,
  renderRangeMap,
  setPrecompLayerMap,
  setRenderRangeMap,
} from '~/lib/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { collectPathNodes } from '~/lib/threejs/PathControls';
import { rotationAxis } from '~/lib/threejs/TransformControls';
import { getColorStopDataTexture, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

const extractAnimatedVectors = (
  state: AnimatedMultiDPropertyJSON,
  value: VectorJSON | null,
): { x: number; y: number } => {
  if (!value) return { x: 0, y: 0 };
  // split vector
  if (state.isSplit) {
    const { x, y } = state.components;

    return {
      x: x?.value?.value ?? value.x,
      y: y?.value?.value ?? value.y,
    };
  }

  return {
    x: value.x,
    y: value.y,
  };
};

interface ChangeEvent {
  data: {
    tracks: Track[] | null;
    value: number;
  };
  target: {
    animatedProperties: Array<{
      parent: GradientFillShape | GradientStrokeShape;
      state: AnimatedMultiDPropertyJSON;
      toJSON: () => AnimatedGradientProperty;
      type: ShapeType | PropertyType | AnimatedProperty;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any;
    }>;
    parent: Layer | PrecompositionLayer;
    type: PropertyType;
    value: number;
  };
  type: string;
}

export const attachToolkitListener = (toolkitId: string): void => {
  const toolkitNode = getNodeByIdOnly(toolkitId) as Shape | null;

  if (!toolkitNode) {
    return;
  }

  const onChange = (event: ChangeEvent): void => {
    const target = event.target;
    const parent = target.parent;

    const changeEventType = target.type;

    if (
      changeEventType === PropertyType.END_FRAME ||
      changeEventType === PropertyType.TIMELINE_OFFSET ||
      changeEventType === PropertyType.START_FRAME
    ) {
      const parentNodeState = parent.state;
      const value = target.value as number;

      const ip = changeEventType === PropertyType.START_FRAME ? value : (parentNodeState.properties.ip as number);
      const op = changeEventType === PropertyType.END_FRAME ? value : (parentNodeState.properties.op as number);
      const tmo =
        changeEventType === PropertyType.TIMELINE_OFFSET ? value : (parentNodeState.properties.tmo as number) || 0;

      const eventParentType = parent.type;

      if (eventParentType === LayerType.SHAPE) {
        const rangeObj = renderRangeMap.get(parentNodeState.id);

        if (rangeObj) {
          const parentPrecompId = rangeObj[3];

          setRenderRangeMap(parentNodeState.id, [ip, op, tmo, parentPrecompId]);

          if (parentPrecompId) {
            setRenderRangeMap(`${parentPrecompId}_${parentNodeState.id}`, [ip, op, tmo, parentPrecompId]);
          }
        }
      } else if (eventParentType === LayerType.PRECOMPOSITION) {
        const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

        const precompFrameRate = (parentNodeState as PrecompositionLayerJSON).composition.timeline.properties
          .fr as number;
        const sceneFrameRate = toolkit.scenes[sceneIndex]?.timeline.frameRate as number;

        setPrecompLayerMap(parent.nodeId, {
          precompId: parent.nodeId,
          referenceId: (parentNodeState as PrecompositionLayerJSON).referenceId ?? '',
          inPoint: ip,
          outPoint: op,
          timelineOffset: tmo,
          precompFrameRate,
          sceneFrameRate,
        });
        const rangeObj = renderRangeMap.get(parentNodeState.id);

        if (rangeObj) {
          const parentPrecompId = rangeObj[3];

          setRenderRangeMap(parentNodeState.id, [ip, op, tmo, parentPrecompId]);

          if (parentPrecompId) {
            setRenderRangeMap(`${parentPrecompId}_${parentNodeState.id}`, [ip, op, tmo, parentPrecompId]);
          }
        }
      }
    }

    if (event.type !== 'render') return;
    const animatedProperties = target.animatedProperties;

    if (animatedProperties.length === 0) {
      return;
    }

    const newFrame = event.data.value;
    const precompIDs = [];

    for (const [precompToolkitId, precompLayer] of precompLayerMap) {
      if (precompLayer.currentFrame === newFrame) {
        precompIDs.push(`${precompToolkitId}_${toolkitId}`);
      }
    }

    const toolkitCanvasIDs = precompIDs.length > 0 ? [...precompIDs, toolkitId] : [toolkitId];

    toolkitCanvasIDs.forEach((toolkitCanvasID) => {
      const object = canvasMap.get(toolkitCanvasID) as BezierMesh | undefined;

      if (!object) {
        return;
      }
      let transformChanged: boolean = false;

      // TODO: update compound bezier animations independantly, without redraw
      // if (isCompoundBezier(object)) {
      // emitter.emit(EmitterEvent.CANVAS_REDRAW, { skipUpdate: true });

      // return;
      // }

      animatedProperties.forEach((animatedProperty) => {
        const eventType = animatedProperty.type;
        const value = animatedProperty.value;
        const state = animatedProperty.state as AnimatedMultiDPropertyJSON;

        switch (eventType) {
          case ShapeType.PATH:
            if (toolkitNode.nodeType === DagNodeType.MASK) {
              const maskMode = getMaskModeIndex(toolkitNode.state.properties.mo as MaskModeType);
              (toolkitNode.parent as ShapeLayer).shapes.forEach((sh) => {
                if (isPath(sh)) {
                  onMaskUpdate(toolkitNode as PathShape, toolkitNode.parent as GroupShape, maskMode);
                } else if (sh.type === ShapeType.GROUP) {
                  onMaskUpdate(toolkitNode as PathShape, sh as GroupShape, maskMode);
                }
              });
            } else {
              updateBezierShape(value, object);
            }
            break;

          case ShapeType.ROUNDED_CORNERS:
          case PropertyType.OUTER_ROUNDNESS:
          case PropertyType.INNER_ROUNDNESS:
          case PropertyType.OUTER_RADIUS:
          case PropertyType.INNER_RADIUS:
          case PropertyType.NUMBER_OF_POINTS:
          case PropertyType.SIZE:
          case PropertyType.ROUNDNESS:
            const shapeBezier = (toolkitNode as DrawableBezierShape).toBezier();

            updateBezierShape(shapeBezier, object);
            break;

          case AnimatedProperty.Rotation:
            object.toolkitRotation = value.value;

            if (object.name === 'bezier') {
              updateBezierShape((toolkitNode as DrawableBezierShape).toBezier(), object as unknown as BezierMesh);
            }
            transformChanged = true;
            break;

          case AnimatedProperty.Position:
            if (object.layerType === LayerType.SOLID) {
              // solid shape's position s changed
              const bezier = (toolkitNode as DrawableBezierShape).toBezier();

              updateBezierShape(bezier, object);
            } else {
              // layer or group shape
              const { x: posX, y: posY } = extractAnimatedVectors(state, value);
              object.toolkitPosition.x = posX;
              object.toolkitPosition.y = posY;
              transformChanged = true;
              if (object.name === 'bezier') {
                updateBezierShape((toolkitNode as DrawableBezierShape).toBezier(), object as unknown as BezierMesh);
              }
            }
            break;

          case AnimatedProperty.Scale:
            const { x: scaleX, y: scaleY } = extractAnimatedVectors(state, value);
            object.scale.x = scaleX / 100;
            object.scale.y = scaleY / 100;
            if (object.name === 'bezier') {
              updateBezierShape((toolkitNode as DrawableBezierShape).toBezier(), object as unknown as BezierMesh);
            }
            transformChanged = true;
            break;

          case AnimatedProperty.Anchor:
            const { x: anchorX, y: anchorY } = extractAnimatedVectors(state, value);
            object.toolkitAnchorPosition.x = anchorX;
            object.toolkitAnchorPosition.y = anchorY;
            transformChanged = true;
            break;

          case AnimatedProperty.Opacity:
            if (toolkitNode.nodeType === DagNodeType.MASK) {
              const pathNodes = collectPathNodes(toolkitNode.parent as ShapeLayer);

              pathNodes.forEach((pathNode) => {
                const pathMesh = canvasMap.get(pathNode.nodeId) as BezierMesh | null;

                if (!pathMesh) return;
                pathMesh.material.uniforms['uMaskOpacity'] = { value: value.value / 100 };
              });
              object.material.uniforms['uMaskOpacity'] = { value: value.value / 100 };
            } else if (toolkitNode.type === ShapeType.FILL) {
              object.opacity = value.value / 100;
              object.material.uniforms['uOpacity'] = { value: object.opacity * getAccumulatedOpacity(object, object) };
            } else {
              object.opacity = value.value / 100;
              object.traverse((child) => {
                if (child instanceof CMesh && child.parent) {
                  const uniforms = (child as BezierMesh).material.uniforms;

                  if (child.name === 'bezierStroke')
                    uniforms['uOpacity'] = {
                      value: child.opacity * getAccumulatedOpacity(child.parent as CMesh, object),
                    };
                  else if (child.material.userData['isFillOrGradientColor']) {
                    uniforms['uOpacity'] = { value: child.opacity * getAccumulatedOpacity(child, object) };
                  }
                  child.material.needsUpdate = true;
                }
              });
            }
            break;

          case AnimatedProperty.Color:
            object.material.uniforms['uColor'] = {
              value: new Color(`#${value.hex6}`),
            };
            break;

          case PropertyType.GRADIENT:
            const gradientMesh = object as BezierMesh;
            const { colors } = animatedProperty.toJSON().value;

            const { colorStopsTexture, offsetsTexture } = getColorStopDataTexture(colors as unknown as ColorStopJSON[]);

            gradientMesh.material.uniforms['uColorStopsCount'] = { value: colors.length };
            gradientMesh.material.uniforms['uColorStops'] = { value: colorStopsTexture };
            gradientMesh.material.uniforms['uColorStopOffsets'] = { value: offsetsTexture };

            break;

          case PropertyType.GRADIENT_START:
          case PropertyType.GRADIENT_END:
          case PropertyType.HIGHLIGHT_ANGLE:
          case PropertyType.HIGHLIGHT_LENGTH:
            const gradientNode = animatedProperty.parent as GradientFillShape | GradientStrokeShape;

            (object as BezierMesh).material.uniforms['uStart'] = {
              value: new Vector2(gradientNode.startPoint.value.x, gradientNode.startPoint.value.y),
            };

            (object as BezierMesh).material.uniforms['uEnd'] = {
              value: new Vector2(gradientNode.endPoint.value.x, gradientNode.endPoint.value.y),
            };

            if (gradientNode.gradientType === GradientFillType.RADIAL) {
              (object as BezierMesh).material.uniforms['uHighlightAngle'] = {
                value: gradientNode.highlightAngle.value.value,
              };
              (object as BezierMesh).material.uniforms['uHighlightLength'] = {
                value: gradientNode.highlightLength.value.value,
              };
            }
            break;

          case PropertyType.TRIM_END:
          case PropertyType.TRIM_START:
          case PropertyType.TRIM_OFFSET:
            const trimNode = toolkitNode as TrimShape | undefined;
            const trimObjects = modifierMap.get(toolkitId);

            if (!trimNode || !trimObjects) break;
            const beziers = trimObjects.map((trimObject) => {
              const bezierNode = getNodeByIdOnly(trimObject.toolkitId) as DrawableBezierShape;
              const bezier = bezierNode.toBezier();

              return bezier;
            });
            const newBeziers = trimNode.apply([beziers]);

            newBeziers[0]?.reverse().forEach((trimmedBezier, index) => {
              const trimObject = trimObjects[index];

              if (!trimObject) return;

              updateBezierShape(trimmedBezier, trimObject as BezierMesh);
            });
            break;

          case PropertyType.STROKE_WIDTH:
            object.material.uniforms['uStrokeWidth'] = { value: value.value };
            updateBezierPlaneSize(object.geometry, object.material.uniforms, value.value);
            break;

          case PropertyType.STROKE_COLOR:
            object.material.uniforms['uColor'] = {
              value: new Color(`rgb(${value.red.toFixed(0)},${value.green.toFixed(0)},${value.blue.toFixed(0)})`),
            };
            break;

          default:
            emitter.emit(EmitterEvent.CANVAS_REDRAW);
            break;
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (transformChanged) {
        object.position.x = object.toolkitPosition.x - object.toolkitAnchorPosition.x * object.scale.x;
        object.position.y = object.toolkitPosition.y - object.toolkitAnchorPosition.y * object.scale.y;

        object.rotation.set(0, 0, 0);
        rotateAboutPoint(
          object,
          new Vector3(object.toolkitPosition.x, object.toolkitPosition.y, 0),
          rotationAxis,
          -degToRad(object.toolkitRotation),
          false,
        );
        object.updateMatrixWorld();
      }
    });

    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  };

  toolkitNode.events.removeEventListener('*', onChange, toolkitNode);
  const scopedListeners = toolkitNode.events.scopedListeners.get('*');

  const onChangeListeners = scopedListeners?.get(toolkitNode)?.filter((listener) => listener.name === 'onChange');

  if (onChangeListeners && onChangeListeners.length > 0) return;
  toolkitNode.events.addEventListener('*', onChange, toolkitNode);
};
