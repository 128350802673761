/**
 * Copyright 2024 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import type { PrecompositionLayer } from '@lottiefiles/toolkit-js';
import React, { Fragment, useCallback } from 'react';

import { Divider } from '~/components/Elements/Divider';
import { Modal } from '~/components/Elements/Modal';
import { adjustTimelineOnImport } from '~/lib/function/import';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  options: Record<string, unknown>;
}

export const FrameOnImportModal: React.FC<Props> = ({ isOpen, onClose, options }) => {
  const { precomLayer } = options;
  const keepAssetFrame = useCallback(() => {
    onClose();
    adjustTimelineOnImport(precomLayer as PrecompositionLayer, false);
  }, [onClose, precomLayer]);

  const keepProjectFPS = useCallback(() => {
    onClose();
    adjustTimelineOnImport(precomLayer as PrecompositionLayer, true);
  }, [onClose, precomLayer]);

  return (
    <Modal isOpen={isOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center  text-center">
            <Dialog.Panel className="w-[360px] overflow-hidden rounded-md bg-gray-800 align-middle shadow-sm transition-all">
              <Dialog.Title className="relative h-[45px] px-4 py-3 text-left text-sm font-bold text-white ">
                New asset has a different frame rate
              </Dialog.Title>
              <Divider />
              <div className="p-4">
                <div className="mb-4 text-left text-xs text-gray-400">
                  The new animation asset has a different frame rate from the project.
                </div>
                <div className="text-left text-xs text-gray-400">
                  Would you like to use the new asset&apos;s frame rate for the project or keep the project&apos;s frame
                  rate?
                </div>
              </div>
              <Divider />
              <div className="flex justify-end gap-2 py-3 pr-5">
                <button
                  onClick={keepAssetFrame}
                  className="h-6 rounded-md bg-[#333c45] px-2 text-xs font-bold leading-normal text-white outline-none"
                >
                  Use asset frame rate
                </button>
                <button
                  onClick={keepProjectFPS}
                  className="h-6 cursor-pointer rounded-md bg-[#00C1A2] px-2 text-xs font-bold leading-normal text-white"
                >
                  Keep project frame rate
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Modal>
  );
};
