/**
 * Copyright 2023 Design Barn Inc.
 */

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */

/** Custom Dnd **/
// Wrap dnd core events, to prevent auto dragging when interacts with inputs, modals, etc.
// Usage: Add data-no-dnd="true" to the parent node and all the descendant nodes will not handle the dnd events.

import {
  MouseSensor as LibMouseSensor,
  KeyboardSensor as LibKeyboardSensor,
  PointerSensor,
  TouchSensor,
} from '@dnd-kit/core';
import type { MouseEvent, KeyboardEvent, PointerEvent, TouchEvent } from 'react';

import { getNumbersInRange } from './utilities';

import { getPathToRoot, getRootLayer } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import type { SelectedNodeInfo } from '~/store/uiSlice';
import { GlobalCursorType } from '~/store/uiSlice';

const shouldHandleEvent = (element: HTMLElement | null): boolean => {
  let cur = element;

  while (cur) {
    const { noDnd } = cur.dataset;

    if (noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
};

export class CustomMouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
    {
      eventName: 'onMouseUp' as const,
      handler: () => {
        const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;

        setTimelineContext({
          cursorClassName: 'cursor-pointer',
        });

        return true;
      },
    },
  ];
}

export class CustomKeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown' as const,
      handler: ({ nativeEvent: event }: KeyboardEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export const findNearestWithDataLayerId = (element: HTMLElement, selector: string): string | null => {
  const nearestWithDataLayerId = element.querySelector(`[${selector}]`);

  if (nearestWithDataLayerId) {
    return nearestWithDataLayerId.getAttribute(selector);
  } else if (element.parentElement) {
    return findNearestWithDataLayerId(element.parentElement) as string | null;
  } else {
    return null;
  }
};

const handleMultiselectModifier = (selectedNodes: SelectedNodeInfo[], id: string): void => {
  const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

  if (selectedNodes.length === 0) {
    addToSelectedNodes([id], true);

    return;
  }

  // only allow multi selection at the root layer level
  // TODO (Jan 2024): reivist after new layer hierarchy is implemented
  const startAndEndLayerIds = [...selectedNodes.map((node) => node.nodeId), id];
  const rootLayerIds = startAndEndLayerIds.map((nodeId) => getRootLayer(nodeId)['id']) as string[];

  const layerElements = Array.from(document.querySelectorAll('[data-timeline-item-layer]'));
  const layerElementIds = layerElements.map((layer) => layer.getAttribute('data-timeline-item-layer') || '');

  const drawOrders: number[] = [];

  layerElementIds.forEach((layerId) => {
    if (rootLayerIds.includes(layerId)) {
      drawOrders.push(layerElementIds.indexOf(layerId));
    }
  });

  const selectedDrawOrders = getNumbersInRange(Math.min(...drawOrders), Math.max(...drawOrders));

  const newSelectedIds: string[] = [];

  layerElementIds.forEach((layerId) => {
    if (selectedDrawOrders.includes(layerElementIds.indexOf(layerId))) {
      newSelectedIds.push(layerId);
    }
  });

  addToSelectedNodes(newSelectedIds, true);
};

const parentAlreadySelected = (selectedNodesInfo: SelectedNodeInfo[], nodeId: string): boolean => {
  const pathToRoot = getPathToRoot(nodeId);

  if (pathToRoot.length <= 1) return false;

  return selectedNodesInfo.some((node) => pathToRoot.slice(1).includes(node.nodeId));
};

const handleSelectionModifiers = (id: string, fromPointerUp: boolean = false): void => {
  const selectedNodes = useCreatorStore.getState().ui.selectedNodesInfo;
  const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;
  const removeSelectedNodes = useCreatorStore.getState().ui.removeSelectedNodes;
  const singleSelectModifier = useCreatorStore.getState().timeline.singleSelectionModifier;
  const multiSelectModifier = useCreatorStore.getState().timeline.multiSelectionModifier;
  const alreadySelected = useCreatorStore.getState().ui.selectedNodesInfo.find((node) => node.nodeId === id);

  if (multiSelectModifier) {
    handleMultiselectModifier(selectedNodes, id);

    return;
  }

  if (singleSelectModifier && alreadySelected) {
    removeSelectedNodes([id]);
  } else if (singleSelectModifier) {
    if (parentAlreadySelected(selectedNodes, id)) return;

    // only allow multi selection at the root layer level
    // TODO (Jan 2024): reivist after new layer hierarchy is implemented
    const newSelectedIds = [...selectedNodes.map((node) => node.nodeId), id];

    addToSelectedNodes(newSelectedIds, true);
  } else if (selectedNodes.length > 1) {
    if (alreadySelected && !fromPointerUp) return;
    if (!parentAlreadySelected(selectedNodes, id) || (alreadySelected && fromPointerUp)) {
      addToSelectedNodes([id], true);
    }
  } else {
    addToSelectedNodes([id], true);
  }
};

const getMousePosition = (event: PointerEvent): { x: number; y: number } => {
  const { height, top } = (event.target as HTMLElement).getBoundingClientRect();
  const timelineContainer = document.getElementById('TimelineContainer');
  const timelineSize = timelineContainer?.getBoundingClientRect();

  if (!timelineSize) return { x: 0, y: 0 };

  return {
    x: event.clientX,
    y: Number(top - timelineSize.top) + Number(height),
  };
};

export const handleLayerRightClick = (event: PointerEvent | MouseEvent, nearestWithDataLayerId: string): void => {
  const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;
  const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;
  const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;

  if (selectedNodesInfo.every((nodeInfo) => nodeInfo.nodeId !== nearestWithDataLayerId)) {
    addToSelectedNodes([nearestWithDataLayerId], true);
  }

  const mousePos = getMousePosition(event as PointerEvent);
  const refId =
    (event.target as HTMLElement).dataset['refid'] ||
    (findNearestWithDataLayerId(event.target as HTMLElement, 'data-layerid') as string);

  // Without setTimeout, the context menu doesn't open if the layer has not been selected first
  setTimeout(() => {
    setTimelineContext({
      referenceId: refId,
      layerMenuOpened: true,
      mousePos,
    });
  }, 0);
};
export class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        const nearestWithDataLayerId =
          event.target.dataset?.layerid || findNearestWithDataLayerId(event.target, 'data-layerid');

        if (!nearestWithDataLayerId) return shouldHandleEvent(event.target as HTMLElement);

        if (event.button === 0) {
          handleSelectionModifiers(nearestWithDataLayerId);
        }

        if (event.pointerType === 'mouse' && event.button === 2 && event.target) {
          handleLayerRightClick(event, nearestWithDataLayerId);
        } else if (event.target) {
          const setTimelineContext = useCreatorStore.getState().timeline.setTimelineContext;
          const mousePos = getMousePosition(event);

          setTimelineContext({
            cursorClassName: GlobalCursorType.GRABBING,
            mousePos,
          });
        }

        // Temporary event listener for pointerup
        const handlePointerUp = (): void => {
          const singleSelectModifier = useCreatorStore.getState().timeline.singleSelectionModifier;
          const layerConfig = useCreatorStore.getState().timeline.layerConfiguration;

          if (!layerConfig.hoverOverId && !singleSelectModifier && event.button === 1) {
            // when user is dragging layer, then release
            handleSelectionModifiers(nearestWithDataLayerId, true);
          }

          document.removeEventListener('pointerup', handlePointerUp);
        };

        document.addEventListener('pointerup', (evt) => handlePointerUp(evt));

        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export class CustomTouchSensor extends TouchSensor {
  static activators = [
    {
      eventName: 'onTouchStart' as const,
      handler: ({ nativeEvent: event }: TouchEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}
