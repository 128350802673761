/**
 * Copyright 2024 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import { DeleteIcon, OptionsIcon } from '~/assets/icons';
import { FloatingWindow, Window, WindowHeader } from '~/components/Elements/Window';
import { useClickOutside } from '~/hooks';
import { emitter, EmitterEvent } from '~/lib/emitter';

interface OptionsButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

export const OptionsButton: React.FC<OptionsButtonProps> = ({ disabled = false, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'ml-3 flex h-6 w-6 items-center justify-center rounded p-1 text-[#A1ADB7] disabled:cursor-auto disabled:text-[#4C5863]',
        { 'hover:bg-gray-700 hover:text-white': !disabled },
      )}
      tabIndex={-1}
      disabled={disabled}
    >
      <OptionsIcon />
    </button>
  );
};

const modalTitle = {
  [ShapeType.FILL]: 'Fill options',
  [ShapeType.STROKE]: 'Stroke options',
};

interface AppearancePropertyOptionsProps {
  disabled: boolean;
  ids: string[];
  type: ShapeType.FILL | ShapeType.STROKE;
}

export const AppearancePropertyOptions: React.FC<AppearancePropertyOptionsProps> = ({ disabled, ids, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const draggableNodeRef = useRef<HTMLDivElement>(null);

  const toggleOptions = useCallback((): void => {
    setIsOpen((prev) => !prev);
  }, []);

  useClickOutside(draggableNodeRef, toggleOptions);

  const onDelete = useCallback((): void => {
    emitter.emit(EmitterEvent.APPEARANCE_DELETED, { nodeIds: ids });
  }, [ids]);

  return (
    <div className="relative" ref={containerRef}>
      <OptionsButton onClick={toggleOptions} disabled={disabled} />
      {isOpen ? (
        <FloatingWindow>
          {({ windowProps }) => (
            <Draggable nodeRef={draggableNodeRef} handle=".drag-handle">
              <Window
                nodeRef={draggableNodeRef}
                {...windowProps}
                style={{ ...windowProps.style, top: (containerRef.current?.offsetTop || 0) + 40, right: 255 }}
              >
                {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                <WindowHeader title={modalTitle[type]} onClose={toggleOptions} className="drag-handle" />
                <div className="w-[258px] py-3 px-4">
                  <button
                    className="flex h-6 w-full items-center justify-center gap-2 rounded text-[#FF8A7D] hover:bg-[#283137]"
                    onClick={onDelete}
                  >
                    <span>
                      <DeleteIcon className="h-4 w-4" />
                    </span>
                    <span className="text-xs font-bold">Delete</span>
                  </button>
                </div>
              </Window>
            </Draggable>
          )}
        </FloatingWindow>
      ) : null}
    </div>
  );
};
