/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

import type { GroupShape, VectorJSON, AVLayer } from '@lottiefiles/toolkit-js';
import { PropertyType, Vector } from '@lottiefiles/toolkit-js';
import type { Intersection, Object3DEventMap, OrthographicCamera } from 'three';
import { Raycaster, Vector2, Quaternion, Vector3 } from 'three';
import { radToDeg, degToRad } from 'three/src/math/MathUtils';

import { getCursorStyle } from '../../../features/canvas/styleMapper';
import { updatePivot, updateSelectedNode, updateToolkit } from '../../../features/canvas/toolkit';
import { getCurrentTransform, stateHistory, toolkit } from '../../toolkit';
import { Box3 } from '../Box3';
import { Box3Helper } from '../Box3Helper';
import { DragControls } from '../DragControls';

import { AlignmentHelper } from './AlignmentHelper';
import { AlignmentPivotHelper } from './AlignmentPivotHelper';
import type { Pointer } from './constant';
import { rotationStep, boundingBox, eye, rotationAxis, SpaceType, TransformType } from './constant';
import { getCursorImageUrl, getCursorAngle, getCursorOffset } from './cursor-helpers';
import { SnapHelper } from './SnapHelper';
import { SnapManager } from './SnapManager';
import { TransformControlsGizmo } from './TransformControlGizmo';
import { TransformControlsPlane } from './TransformControlsPlane';
import type { AlignDirection, DistributionDirection } from './types';

import { ToolType } from '~/data/constant';
import type { Viewport } from '~/features/canvas';
import {
  getClosestPointToLine,
  CMesh,
  CObject3D,
  isChild,
  getPivotPoint,
  rotateAboutPoint,
  scaleAboutPoint,
  intersectObjectsWithRay,
  intersectObjectWithRay,
  UserDataMap,
  getPointer,
  BLUE_COLOR,
  onMatteNodeUpdate,
} from '~/features/canvas';
import { canvasMap } from '~/lib/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';
import type { TransformInfo } from '~/store/uiSlice';
import { AlignPivotDirection } from '~/store/uiSlice';

enum Direction {
  ClockWise = 'clockwise',
  CounterClockWise = 'counter-clockwise',
}

const getRotationDirection = (previousAngle: number, currentAngle: number): Direction | null => {
  // the angle are represented as follows,

  //      180,-180
  //         |
  //         |
  //    ------------
  //         |
  //         |
  //        0,-0

  // So, if the previous angle is ~ -0 and current angle is ~0, then the rotation is clockwise
  // and if the previous angle is ~0 and current angle is ~ -0, then the rotation is counter-clockwise

  if (
    Object.is(Math.trunc(previousAngle), -0) &&
    Object.is(Math.trunc(currentAngle), 0) &&
    previousAngle < currentAngle
  ) {
    return Direction.ClockWise;
  }

  // Note: the initial angle on an object is set to -0, so there's an extra check for counter-clockwise

  if (
    (Object.is(Math.trunc(previousAngle), 0) || Object.is(previousAngle, -0)) &&
    Object.is(Math.trunc(currentAngle), -0) &&
    previousAngle > currentAngle
  ) {
    return Direction.CounterClockWise;
  }

  return null;
};

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;
const setSingleSelectModifier = useCreatorStore.getState().timeline.setSingleSelectionModifier;
const setMultiSelectModifier = useCreatorStore.getState().timeline.setMultiSelectionModifier;
const setAnchorPointByCursor = useCreatorStore.getState().ui.setAnchorPointByCursor;

const _raycaster = new Raycaster();

const _tempVector = new Vector3();
const _tempVector2 = new Vector3();
const selectionBox = boundingBox();

const hoverSelectionBox = boundingBox();

hoverSelectionBox.visible = false;

// This shows only bounding rectangle without control gizmos. needed for the nodes which don't have animated properties
const nonTransformBox = new Box3();
const nonTransformSelectionBox = new Box3Helper(nonTransformBox, BLUE_COLOR);

nonTransformSelectionBox.visible = false;

export class TransformControls extends CObject3D {
  public _onPointerDown: (event: PointerEvent) => void;

  public _onPointerHover: (event: PointerEvent) => void;

  public _onPointerMove: (event: PointerEvent) => void;

  public _onPointerUp: (event: PointerEvent) => void;

  public _setScaleRatioLockedIcon: (isLocked: boolean) => void;

  public anchorToolActive = false;

  public axis: string | null = null;

  public camera: OrthographicCamera;

  public dragging = false;

  public dragged = 0;

  public hasDragged = false;

  public groupTransform: TransformInfo = {};

  public objects: CObject3D[] = [];

  public mode: TransformType = TransformType.Translation;

  public pivotPoint: CObject3D | null = null;

  public pivotDragging = false;

  public pivotHovered = false;

  public previousRotationAngle = 0;

  private _rotationDirection: Direction | null = null;

  private _rotationsCount: number | null = null;

  public rotationAngle = 0;

  public size = 1;

  public space = SpaceType.Local;

  public tag = '';

  private readonly _enabled = true;

  private readonly _cursorElement: HTMLElement;

  private readonly _cursorImageElement: HTMLImageElement;

  private readonly _domElement: HTMLElement = document as unknown as HTMLElement;

  private readonly _endNorm: Vector3 = new Vector3();

  private readonly _gizmo: TransformControlsGizmo;

  public lastSelectedObjectIDs: string[] = [];

  private readonly _plane: TransformControlsPlane;

  private readonly _viewport: Viewport;

  private readonly _startNorm: Vector3 = new Vector3();

  public scaleRatioLocked = false;

  public nonTransformNodeSelected = false;

  public hotkeys = { altDown: false, shiftDown: false, cmdDown: false };

  public alignmentHelper = new AlignmentHelper();

  public alignmentPivotHelper = new AlignmentPivotHelper();

  public shiftToSnapHelper = new SnapHelper();

  public snapManager: SnapManager | null = null;

  public constructor(viewport: Viewport) {
    super();

    this._viewport = viewport;

    this.visible = false;

    this._domElement = viewport.overlayCanvasElement;
    // disable touch scroll
    this._domElement.style.touchAction = 'none';
    this._gizmo = new TransformControlsGizmo();
    this.add(this._gizmo);

    this._plane = new TransformControlsPlane();
    this.add(this._plane);
    this.add(this.shiftToSnapHelper.root);

    this.snapManager = new SnapManager(viewport);

    viewport.scene.add(selectionBox);
    viewport.scene.add(hoverSelectionBox);
    viewport.scene.add(nonTransformSelectionBox);

    this.initPivotPoint(viewport.camera, viewport.overlayCanvasElement);

    // Defined getter, setter and store for a property
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const defineProperty = (propName: string, defaultValue: any, scope = this) => {
      let propValue = defaultValue;

      Object.defineProperty(scope, propName, {
        get: () => {
          return propValue ? propValue : defaultValue;
        },

        set: (value) => {
          if (propValue !== value) {
            propValue = value;

            this.dispatchEvent({ type: `${propName}-changed` as keyof Object3DEventMap, value });
            this.dispatchEvent({ type: 'change' as keyof Object3DEventMap });
          }
        },
      });
    };

    // Define properties with getters/setter
    // Setting the defined property will automatically trigger change event

    defineProperty('camera', viewport.camera);
    defineProperty('object', null);
    defineProperty('axis', null);
    defineProperty('mode', 'translate');
    defineProperty('_translationSnap', null);
    defineProperty('_rotationSnap', null);
    defineProperty('_scaleSnap', null);
    defineProperty('space', 'world');
    defineProperty('size', 1);
    defineProperty('dragging', false);

    this.camera = viewport.camera;

    this._onPointerDown = (event: PointerEvent) => {
      if (!this.enabled || this._viewport.movePlayHeadTool.isMovingPlayHead) return;

      if (useCreatorStore.getState().ui.currentTool === ToolType.Shape) {
        return;
      }

      // At certain render case, right click menu should always disable drag shape on pointer move
      if (event.button !== 2) {
        document.body.addEventListener('pointermove', this._onPointerMove);
      }
      if (event.altKey) {
        this.hotkeys.altDown = true;
      }

      this.pointerHover(event);
      if (this.objects.length > 0 && this.axis && this.axis !== 'XY')
        this.pointerDown(getPointer(event, this._domElement));
    };

    this._onPointerHover = (event: PointerEvent) => {
      if (!this.enabled || this._viewport.overlayCanvas.isDraggingGuide) return;
      if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
        this.pointerHover(event);
      }
    };

    this._onPointerMove = (event: PointerEvent) => {
      if (
        !this.enabled ||
        this._viewport.movePlayHeadTool.isMovingPlayHead ||
        this._viewport.overlayCanvas.isDraggingGuide
      )
        return;
      this.pointerMove(event);
    };

    this._onPointerUp = (event: PointerEvent) => {
      if (
        !this.enabled ||
        this._viewport.movePlayHeadTool.isMovingPlayHead ||
        this._viewport.overlayCanvas.isDraggingGuide
      )
        return;

      this._domElement.releasePointerCapture(event.pointerId);

      this._domElement.removeEventListener('pointermove', this._onPointerMove);

      this.pointerUp(getPointer(event, this._domElement));
      this.pointerHover(event);
    };

    this._setScaleRatioLockedIcon = (isLocked: boolean) => {
      const setScaleRatioLocked = useCreatorStore.getState().ui.setScaleRatioLocked;

      setScaleRatioLocked(isLocked);
    };

    document.body.addEventListener('pointerdown', this._onPointerDown);
    document.body.addEventListener('pointermove', this._onPointerHover);
    document.body.addEventListener('pointerup', this._onPointerUp);
    this._domElement.addEventListener('pointerleave', (event) =>
      this.updateCursor({ x: event.clientX, y: event.clientY }, null),
    );
    document.body.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.altKey) {
        this.hotkeys.altDown = true;
      }

      if (event.key === 'Shift') {
        this.hotkeys.shiftDown = true;
        this.shiftToSnapHelper.setShiftDown(true);
        this.scaleRatioLocked = !this.scaleRatioLocked;
        this._setScaleRatioLockedIcon(this.scaleRatioLocked);
        setMultiSelectModifier(true);
      }

      if (event.key === 'Meta' || event.ctrlKey) {
        this.hotkeys.cmdDown = true;
        setSingleSelectModifier(true);
      }
    });
    document.body.addEventListener('keyup', (event: KeyboardEvent) => {
      if (!event.altKey) {
        this.hotkeys.altDown = false;
      }

      if (event.key === 'Shift') {
        this.shiftToSnapHelper.setShiftDown(false);
        this.shiftToSnapHelper.translationSnapEnd();
        setMultiSelectModifier(false);

        if (this.hotkeys.shiftDown) {
          this.scaleRatioLocked = !this.scaleRatioLocked;
          this._setScaleRatioLockedIcon(this.scaleRatioLocked);
        }

        this.hotkeys.shiftDown = false;
      }

      if (!(event.key === 'Meta') || !event.ctrlKey) {
        this.hotkeys.cmdDown = false;
        setSingleSelectModifier(false);
      }
    });

    window.addEventListener('blur', () => {
      Object.keys(this.hotkeys).forEach((key) => {
        this.hotkeys[key as keyof typeof this.hotkeys] = false;
      });

      this.shiftToSnapHelper.setShiftDown(false);
      setMultiSelectModifier(false);
      setSingleSelectModifier(false);
    });

    this._cursorElement = document.createElement('div');
    this._cursorElement.id = 'cursor';
    this._cursorElement.style.zIndex = '1';

    this._cursorImageElement = document.createElement('img');
    this._cursorElement.append(this._cursorImageElement);

    document.body.append(this._cursorElement);
  }

  public autoCenter(object: CObject3D): void {
    if (!this.pivotPoint) return;

    this.pivotPoint.position.copy(new Box3().setFromObject(object, true).center);
    object.anchorPosition.copy(this.pivotPoint.position);
    updatePivot(object, this.pivotPoint.position, AlignPivotDirection.Center);
  }

  public duplicateOnDrag(): void {
    if (this.mode === TransformType.Rotation || this.mode === TransformType.Scale) return;

    emitter.emit(EmitterEvent.CANVAS_DUPLICATE);

    this.hotkeys.altDown = false;
  }

  public onDragPivotPoint(fromSignal?: boolean, fromPivotSelector?: AlignPivotDirection): void {
    // triggered when anchor point is moved
    if (this.objects.length === 0 || !this.pivotPoint) return;

    stateHistory.beginAction();

    if (!fromSignal && !fromPivotSelector && this.lastSelectedObjectIDs.length > 0) {
      // when anchor pivot point is moved manually by cursor
      const selectedObjectId = this.lastSelectedObjectIDs[0];

      if (selectedObjectId && this.lastSelectedObjectIDs.length === 1) {
        const setAnchorPointsActive = useCreatorStore.getState().ui.setAnchorPointsActive;

        // nullify anchor points
        setAnchorPointsActive(selectedObjectId, null, true);
      } else if (this.lastSelectedObjectIDs.length > 1) {
        setAnchorPointByCursor(true);
      }
    }

    if (this.objects.length === 1) {
      this.objects.forEach((object) => {
        if (!this.pivotPoint) return;

        const node = getNodeByIdOnly(object.toolkitId);
        const centered = node?.data.get(UserDataMap.PivotCentered) as boolean;

        if (centered) {
          const pivot = (node as GroupShape).pivot;

          object.anchorPosition.x = pivot.x;
          object.anchorPosition.y = pivot.y;
        } else {
          this.pivotPoint.position.copy(new Box3().setFromObject(object, true).center);
          object.anchorPosition.copy(this.pivotPoint.position);
        }

        if (!fromSignal) {
          updatePivot(object, (this.pivotPoint as CObject3D).position, fromPivotSelector);
        }
      });
    } else if (this.objects.length > 1) {
      this.groupTransform.pivot = new Vector3().copy(this.pivotPoint.position);
      this.groupTransform.pivotOffset = new Vector3().copy(this.pivotPoint.position).sub(selectionBox.box.center);

      toolkit.setData(UserDataMap.MultiPivotOffset, this.groupTransform.pivotOffset);
      toolkit.removeData(UserDataMap.MultiPivot);
    }

    stateHistory.endAction();

    this.updateGizmo();
    this.pivotDragging = false;
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  public async initPivotPoint(camera: OrthographicCamera, dom: HTMLElement): Promise<void> {
    this.pivotPoint = await getPivotPoint(useCreatorStore.getState().ui.currentTool !== ToolType.Anchor);
    this.add(this.pivotPoint);

    const dragControls = new DragControls([this.pivotPoint], camera as OrthographicCamera, dom);

    dragControls.transformGroup = true;

    const dragStartPosition = new Vector3();

    dragControls.addEventListener('dragstart', () => {
      if (!this.pivotPoint || !this.pivotPoint.visible || !this.anchorToolActive) {
        this.pivotDragging = false;

        return;
      }

      dragStartPosition.copy(this.pivotPoint.position);
      this.pivotDragging = true;
    });

    dragControls.addEventListener('drag', (event: any) => {
      if (!this.anchorToolActive) {
        dragControls.dispatchEvent({ type: 'dragend' });
        dragControls.enabled = false;
      }

      if (this.hotkeys.shiftDown) {
        const object = event.object as CObject3D;
        const offset = object.position.clone().sub(dragStartPosition);
        const snappedOffset = this.shiftToSnapHelper.translationSnap(offset, dragStartPosition, object.position);

        object.position.copy(snappedOffset).add(dragStartPosition);
        this.shiftToSnapHelper.updateLine(1, object.position);
      }

      emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
    });

    dragControls.addEventListener('dragend', () => {
      if (this.pivotDragging) {
        this.onDragPivotPoint();
      }

      dragControls.enabled = true;
      this.pivotDragging = false;
    });
  }

  public updateCursor(pointer: Pointer, intersect: CObject3D | null): void {
    if (
      (!intersect && !this.pivotDragging && useCreatorStore.getState().ui.currentTool !== ToolType.Shape) ||
      (this.anchorToolActive && !this.pivotDragging && !this.pivotHovered) ||
      !this.enabled ||
      this.objects.length === 0
    ) {
      this._cursorElement.style.display = 'none';

      if (!this._viewport.overlayCanvas.selectedGuide) {
        this._domElement.style.cursor = getCursorStyle('pointer');
      }

      return;
    }

    const mode = this.pivotDragging ? TransformType.Pivot : this.mode;

    this._cursorImageElement.src = getCursorImageUrl(this.pivotDragging, mode);

    const angle = getCursorAngle(mode, this.tag, intersect as CObject3D);

    const { deltaX, deltaY } = getCursorOffset(mode);

    this._cursorElement.style.transform = `translate(${pointer.x + deltaX}px, ${
      pointer.y + deltaY
    }px) rotate(${angle}deg)`;
    this._cursorElement.style.display = 'block';
    this._domElement.style.cursor = 'none';
  }

  public getGizmoIntersect(event: PointerEvent): false | Intersection | undefined {
    const pointer = getPointer(event, this._domElement);

    _raycaster.setFromCamera(pointer, this.camera);

    const objects = [this._gizmo._picker.translate, this._gizmo._picker.rotate, this._gizmo._picker.scale];

    if (this.pivotPoint?.visible && this.anchorToolActive) {
      objects.push(this.pivotPoint);
    }

    return intersectObjectsWithRay(objects, _raycaster, true);
  }

  public onHoverChange(hoverId: string): void {
    if (!hoverId || this.anchorToolActive) {
      this.hideBoundingBox();

      return;
    }

    const isHoveredIdSelected = this.objects.some((object) => object.toolkitId === hoverId);

    if (isHoveredIdSelected) return;

    const hoveredObject = canvasMap.get(hoverId);

    if (hoveredObject) {
      this.showBoundingBox(hoveredObject);
      this.updateGizmo([hoveredObject as CObject3D], true);
    } else {
      this.hideBoundingBox();
    }
  }

  public pointerHover(event: PointerEvent): void {
    if (this.objects.length === 0 || !this.pivotPoint) return;

    const intersect = this.getGizmoIntersect(event);

    this.pivotHovered = Boolean(intersect && isChild(this.pivotPoint, intersect.object as CObject3D));

    this.updateCursor(
      { x: event.clientX, y: event.clientY },
      this.dragging ? (this.objects[0] as CObject3D) : ((intersect as Intersection | null)?.object as CObject3D | null),
    );
    if (this.dragging) return;

    if (intersect) {
      this.axis = intersect.object.name;
      this.mode = intersect.object.userData['mode'];
      this.tag = intersect.object.userData['tag'];
    } else {
      this.axis = null;
    }
  }

  public pointerDown(pointer: Pointer): void {
    const hasCMesh = this.objects.find((item) => item instanceof CMesh);

    if (hasCMesh || this.objects.length === 0 || !this.axis) return;

    if (pointer.button !== 2) {
      this.dragging = true;
    }

    this.objects.forEach((obj) => {
      obj.startPosition.copy(obj.position);
      obj.startQuaternion.copy(obj.quaternion);
      obj.startScale.copy(obj.scale);
    });
    const selectedObject = this.objects[0] as CObject3D;

    this.previousRotationAngle = selectedObject.rotation.z;

    _raycaster.setFromCamera(pointer, this.camera);

    const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true);

    if (planeIntersect) {
      const closestPoint = new Vector3().copy(planeIntersect.point);

      if (this.mode === TransformType.Scale) {
        const bbx = this.getBoundingBox();

        if (this.axis.includes('X')) {
          const p1 = getClosestPointToLine(planeIntersect.point, bbx.maxXminY, bbx.max);
          const p2 = getClosestPointToLine(planeIntersect.point, bbx.minXmaxY, bbx.min);

          closestPoint.x = p1.distanceTo(planeIntersect.point) < p2.distanceTo(planeIntersect.point) ? p1.x : p2.x;
        }
        if (this.axis.includes('Y')) {
          const p1 = getClosestPointToLine(planeIntersect.point, bbx.min, bbx.maxXminY);
          const p2 = getClosestPointToLine(planeIntersect.point, bbx.minXmaxY, bbx.max);

          closestPoint.y = p1.distanceTo(planeIntersect.point) < p2.distanceTo(planeIntersect.point) ? p1.y : p2.y;
        }
      }

      this.objects.forEach((obj) => {
        obj.updateMatrixWorld();
        obj.parent?.updateMatrixWorld();

        obj.worldPositionStart.setFromMatrixPosition(obj.matrixWorld);
        obj.worldPositionStart.copy(obj.anchorPosition);
        obj.pointStart.copy(planeIntersect.point).sub(obj.worldPositionStart);

        if (this.mode === TransformType.Scale && this.pivotPoint) {
          const closestPointOnWorld = new Vector3().copy(closestPoint.clone()).add(obj.worldPosition.clone());
          const diff = closestPointOnWorld.clone().sub(this.pivotPoint.position.clone());

          // if the mouse is dragging on the same edge that the pivot point is on,
          // allow a slight offset based on the original mouse position
          // to avoid a pointStart value of 0,0 that results in an Infinity scale value
          if (Math.abs(diff.x) < 0.01 || Math.abs(diff.y) < 0.01) {
            obj.pointStart = obj.pointStart.clone();
          } else {
            obj.pointStart.copy(closestPoint).sub(obj.worldPositionStart);
          }
        }

        obj.pointStart.z = obj.worldPosition.z;
        // TODO
        // this.snapHelper.translationSnapStart(obj.worldPosition);
      });
    }

    const toolkitNode = toolkit.getNodeById(selectedObject.toolkitId);
    const transform = getCurrentTransform(toolkitNode, false);

    if (!this._rotationDirection) {
      this._rotationDirection = transform.rotation >= 0 ? Direction.ClockWise : Direction.CounterClockWise;
    }

    this._rotationsCount = null;

    hoverSelectionBox.visible = false;

    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  private _handleRotationDirection(previousAngle: number, currentAngle: number): void {
    let direction = getRotationDirection(previousAngle, currentAngle);

    let lastRotationCount = this._rotationsCount;

    // if the user is rotating the object for the first time, derive the rotation count from the current rotation
    // else, increment/decrement the rotation count based on the direction of rotation
    if (this._rotationsCount === null) {
      const selectedObject = this.objects[0] as CObject3D;
      const toolkitNode = toolkit.getNodeById(selectedObject.toolkitId);
      const transform = getCurrentTransform(toolkitNode, false);

      // `transform.rotation` is in degrees. `currentAngle` and `previousAngle` are in radians.
      const diffInRad = currentAngle - previousAngle;
      const newAngle = radToDeg(degToRad(transform.rotation) + diffInRad);

      this._rotationsCount = Math.trunc(newAngle / 360);

      lastRotationCount = this._rotationsCount;
    } else {
      // _rotationsCount ranges between -MaxInteger to -0 to 0 to +MaxInteger
      switch (direction) {
        case Direction.ClockWise:
          if (this._rotationsCount === -1) {
            this._rotationsCount = -0;
          } else if (Object.is(this._rotationsCount, -0)) {
            this._rotationsCount = 0;
          } else {
            this._rotationsCount += 1;
          }
          break;

        case Direction.CounterClockWise:
          if (Object.is(this._rotationsCount, 0)) {
            this._rotationsCount = -0;
          } else {
            this._rotationsCount -= 1;
          }
          break;

        default:
          break;
      }
    }

    direction = direction ?? this._rotationDirection;

    // change the direction only when the rotation count is 0, ie if rotation angle is between -360 to 360
    if (this._rotationDirection !== direction && (lastRotationCount === 0 || Object.is(lastRotationCount, -0))) {
      this._rotationDirection = direction;
    }
  }

  public pointerMove(event: PointerEvent): void {
    const pointer = getPointer(event, this._domElement);

    if (
      !this.axis ||
      !this.dragging ||
      this.objects.length === 0 ||
      pointer.button !== -1 ||
      !this.pivotPoint ||
      this.anchorToolActive
    )
      return;

    const selectedObject = this.objects[0] as CObject3D;
    // keep the same position z value during the transformation
    const originalZ = selectedObject.position.z;

    selectedObject.userData['mode'] = this.mode;
    selectedObject.userData['tag'] = this.tag;

    _raycaster.setFromCamera(pointer, this.camera);

    const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true);

    if (!planeIntersect) return;

    if (selectedObject.userData['isDuplicate']) {
      // if the user was in the middle of a transformation and duplicated an object,
      // update the duplicate's position in relation to the mouse
      // so the transformation can continue on the duplicate
      this.setDuplicatedObjectPosition(this.objects, planeIntersect);
    }

    this.objects.forEach((obj) => {
      obj.pointEnd.copy(planeIntersect.point).sub(obj.worldPositionStart);
      obj.pointEnd.z = obj.worldPosition.z;
    });
    this.dragged += 1;

    if (this.mode === TransformType.Translation) {
      this.visible = false;

      // Apply translate
      this.objects.forEach((obj) => {
        if (!this.axis) return;
        obj.offset.copy(obj.pointEnd).sub(obj.pointStart);
        obj.offset.applyQuaternion(obj.worldQuaternionInv);

        if (!this.axis.includes('X')) obj.offset.x = 0;
        if (!this.axis.includes('Y')) obj.offset.y = 0;
        if (!this.axis.includes('Z')) obj.offset.z = 0;

        obj.offset.multiplyScalar(Math.sign(obj.scale.y)).applyQuaternion(obj.startQuaternion).divide(obj.parentScale);

        let offset = obj.offset;

        if (this.hotkeys.shiftDown && this.pivotPoint) {
          offset = this.shiftToSnapHelper.translationSnap(obj.offset, obj.worldPositionStart, this.pivotPoint.position);
        }

        obj.position.copy(offset).add(obj.startPosition);
      });

      if (this.snapManager?.enabled) {
        const { distanceToLine, snapLineX, snapLineY } = this.snapManager.getSnapResult(
          TransformType.Translation,
          planeIntersect.point,
        );

        if (snapLineX || snapLineY) {
          this.objects.forEach((obj) => {
            obj.position.add(distanceToLine);
          });
        }
      }
    } else if (this.mode === TransformType.Scale) {
      const snapOffset = new Vector3();

      if (this.snapManager?.enabled) {
        const pointEnd = selectedObject.pointEnd.clone().add(this.pivotPoint.position);

        snapOffset.copy(this.snapManager.getSnapResult(TransformType.Scale, pointEnd).snapOffsetFromMouse);
      }

      _tempVector.copy(selectedObject.pointStart);
      _tempVector2.copy(selectedObject.pointEnd.clone().add(snapOffset));

      _tempVector.applyQuaternion(selectedObject.worldQuaternionInv);
      _tempVector2.applyQuaternion(selectedObject.worldQuaternionInv);

      _tempVector2.divide(_tempVector);

      if (this.axis.search('X') === -1) {
        _tempVector2.x = 1;
      }

      if (this.axis.search('Y') === -1) {
        _tempVector2.y = 1;
      }

      if (this.axis.search('Z') === -1) {
        _tempVector2.z = 1;
      }

      // Apply scale
      this.scaleRatioLocked = useCreatorStore.getState().ui.scaleRatioLocked;

      if (this.scaleRatioLocked) {
        if (this.axis.includes('XY')) {
          const max = Math.max(_tempVector2.x, _tempVector2.y);

          _tempVector2.setX(max);
          _tempVector2.setY(max);
        } else if (this.axis === 'X') {
          _tempVector2.setY(_tempVector2.x);
        } else if (this.axis === 'Y') {
          _tempVector2.setX(_tempVector2.y);
        }
      }

      this.objects.forEach((obj) => {
        const node = getNodeByIdOnly(obj.toolkitId) as AVLayer;
        const relativePosition = node.getPositionRelativeTo(
          new Vector(this.pivotPoint?.position.x, this.pivotPoint?.position.y),
        );

        const scaleValue = new Vector3().copy(obj.startScale).multiply(_tempVector2);

        if (this.pivotPoint) {
          const offset = new Vector3()
            .copy(this.pivotPoint.position)
            .sub(new Vector3(relativePosition.x, relativePosition.y));

          scaleAboutPoint(obj, this.pivotPoint.position, scaleValue, true, offset);
        }
      });
    } else {
      selectedObject.offset.copy(selectedObject.pointEnd).sub(selectedObject.pointStart);

      this.rotationAngle = selectedObject.pointEnd.angleTo(selectedObject.pointStart);

      this._startNorm.copy(selectedObject.pointStart).normalize();
      this._endNorm.copy(selectedObject.pointEnd.normalize());

      this.rotationAngle *= this._endNorm.cross(this._startNorm).dot(eye) < 0 ? -1 : 1;

      if (this.hotkeys.shiftDown) {
        if (Math.abs(this.rotationAngle) < rotationStep) {
          const currentRotationAngle = selectedObject.rotation.z + -1 * this.rotationAngle;

          this._handleRotationDirection(this.previousRotationAngle, currentRotationAngle);

          this.previousRotationAngle = currentRotationAngle;

          return;
        } else {
          this.rotationAngle = Math.sign(this.rotationAngle) * rotationStep;
        }
      }

      this.objects.forEach((obj) => {
        if (this.pivotPoint)
          rotateAboutPoint(
            obj,
            this.pivotPoint.position,
            rotationAxis,
            this.rotationAngle,
            true,
            obj.startPosition,
            obj.startQuaternion,
          );
      });

      if (!this.hotkeys.shiftDown) {
        this._handleRotationDirection(this.previousRotationAngle, selectedObject.rotation.z);
      }

      stateHistory.offTheRecord(() => {
        toolkit.setData(UserDataMap.MultiPivot, this.groupTransform.pivot);
        toolkit.removeData(UserDataMap.MultiPivotOffset);
      });

      this.previousRotationAngle = selectedObject.rotation.z;
    }

    this.objects.forEach((obj) => {
      obj.position.z = originalZ;
      obj.updateMatrixWorld();
      onMatteNodeUpdate(getNodeByIdOnly(obj.toolkitId));
    });

    this.updateGizmo();

    if (this.mode === TransformType.Translation) {
      this.objects.forEach((obj) => {
        updateSelectedNode(obj, this.mode);
      });

      this.updatePivotPosition();
    } else if (this.mode === TransformType.Rotation) {
      this.objects.forEach((obj) => {
        updateSelectedNode(
          obj,
          this.mode,
          this._rotationDirection === Direction.CounterClockWise,
          this._rotationsCount as number,
        );
      });

      if (this.hotkeys.shiftDown) {
        this.pointerDown(getPointer(event, this._domElement));
      }
    } else if (this.mode === TransformType.Scale) {
      this.objects.forEach((obj) => {
        updateSelectedNode(obj, TransformType.Scale);
      });

      stateHistory.offTheRecord(() => {
        toolkit.setData(UserDataMap.MultiPivot, this.groupTransform.pivot);
        toolkit.removeData(UserDataMap.MultiPivotOffset);
      });
    }
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  // rotate the selected object by 90 degree to the right
  public rotateRight(): void {
    if (this.objects.length === 0 || !this.pivotPoint) return;

    this.objects.forEach((object) => {
      if (!this.pivotPoint) return;
      rotateAboutPoint(object, this.pivotPoint.position, rotationAxis, -Math.PI / 2, true);
      hoverSelectionBox.box.setFromObject(object, true);
      hoverSelectionBox.update();
    });
    updateToolkit(this.objects, TransformType.Rotation);
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  // rotate the selected object by 90 degree to the left
  public rotateLeft(): void {
    if (this.objects.length === 0 || !this.pivotPoint) return;
    this.objects.forEach((object) => {
      if (!this.pivotPoint) return;
      rotateAboutPoint(object, this.pivotPoint.position, rotationAxis, Math.PI / 2, true);
      hoverSelectionBox.box.setFromObject(object, true);
      hoverSelectionBox.update();
    });
    updateToolkit(this.objects, TransformType.Rotation);
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  public pointerUp(pointer: Pointer): void {
    if (pointer.button !== 0) return;
    this.dragging = false;
    this.dragged = 0;
    this.axis = null;
    this.updateGizmo();
    this.shiftToSnapHelper.translationSnapEnd();
  }

  // executed after each dragging of transformcontrol gizmo
  public onDrag(): void {
    if (this.objects.length === 0 || this.dragged < 3) return;
    updateToolkit(
      this.objects,
      this.mode,
      this.groupTransform.pivotOffset,
      this._rotationDirection === Direction.CounterClockWise,
      this._rotationsCount as number,
    );
    this.hasDragged = true;
  }

  public moveSelectedShape(offset: Vector3): void {
    if (this.objects.length === 0) return;
    this.objects.forEach((object) => {
      object.position.add(offset);
      updateToolkit([object], TransformType.Translation);
      hoverSelectionBox.visible = false;
    });
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  public dispose(): void {
    this._domElement.removeEventListener('pointerdown', this._onPointerDown);
    this._domElement.removeEventListener('pointermove', this._onPointerHover);
    this._domElement.removeEventListener('pointermove', this._onPointerMove);
    this._domElement.removeEventListener('pointerup', this._onPointerUp);

    this.traverse((child) => {
      if (child instanceof CMesh) {
        child.geometry.dispose();
        child.material.dispose();
      }
    });
  }

  public showTransformControls(visible: boolean): void {
    const selectedNodeIds = useCreatorStore.getState().ui.selectedNodesInfo.map((node) => node.nodeId);

    if (selectedNodeIds.length === 0) {
      return;
    }

    const objects = selectedNodeIds.map((id) => canvasMap.get(id)).filter(Boolean) as CObject3D[];
    const nonTransformable = objects.some((object) => object instanceof CMesh);

    if (nonTransformable) {
      if (visible) {
        nonTransformBox.setFromObjects(objects, true);
        nonTransformSelectionBox.update();
      }

      nonTransformSelectionBox.visible = visible;
      hoverSelectionBox.visible = false;

      emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
    } else {
      if (visible) this.updateGizmo();
      selectionBox.visible = visible;
      this.visible = visible;
    }
  }

  public handleShapeDrawing(): void {
    if (this.objects.length === 0) {
      this.detach();

      return;
    }

    const node = getNodeByIdOnly((this.objects[0] as CObject3D).toolkitId);
    const pivot = (node as GroupShape).pivot;

    (this.pivotPoint as CObject3D).position.x = pivot.x;
    (this.pivotPoint as CObject3D).position.y = pivot.y;

    selectionBox.box.setFromObject(this.objects[0] as CObject3D, true);

    selectionBox.update();
    this.updateGizmo();
  }

  // Set current object
  public override attach(object: CObject3D | CMesh): this {
    if (!this.enabled) return this;

    const hideTransformControls = useCreatorStore.getState().canvas.hideTransformControls;

    // transform control is not available for mesh as of now.
    if (object instanceof CMesh || object.userData['isLocked']) {
      this.showBoundingBox(object, true);
      this.lastSelectedObjectIDs = [object.toolkitId];

      return this;
    }

    const isDisplay = object.displayOnly;

    if (!object.parent || !this.pivotPoint) return this;

    selectionBox.box.setFromObject(object, true);

    if (selectionBox.box.emptyObject) {
      hoverSelectionBox.visible = false;
      this.showTransformControls(false);
      emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });

      return this;
    }

    selectionBox.update();

    this.objects = [object];
    this.alignmentPivotHelper.object = object;
    this.lastSelectedObjectIDs = [object.toolkitId];

    if (!isDisplay) {
      const node = getNodeByIdOnly(object.toolkitId);
      const pivot = (node as GroupShape).pivot;

      const centered = node?.data.get(UserDataMap.PivotCentered) as boolean;

      if (centered) {
        this.pivotPoint.position.x = pivot.x;
        this.pivotPoint.position.y = pivot.y;
        this.pivotPoint.position.z = -20;
        object.anchorPosition.copy(this.pivotPoint.position);
      } else {
        stateHistory.offTheRecord(() => node?.setData(UserDataMap.PivotCentered, true));

        if ((node as AVLayer).animatedProperties.length === 0) {
          this.autoCenter(object);
        }
      }

      // Don't restore the state if shift is down
      if (!this.hotkeys.shiftDown) {
        this._setScaleRatioLockedIcon(Boolean(node?.data.get(UserDataMap.ScaleRatioLock) ?? false));
      }

      if (this.anchorToolActive) {
        useCreatorStore.getState().ui.setPivotVisibility(useCreatorStore.getState().ui.pivotVisible);
      } else {
        this.pivotPoint.visible = (node?.data.get(UserDataMap.PivotVisible) ?? true) as boolean;
        useCreatorStore.getState().ui.setPivotVisibility(this.pivotPoint.visible);
      }
    }

    object.initialPosition.copy(object.position);
    object.userData['mode'] = TransformType.Translation;

    if (hideTransformControls) {
      this.showTransformControls(false);

      return this;
    }

    this.visible = true;

    nonTransformSelectionBox.visible = false;
    this.nonTransformNodeSelected = false;
    this.updateGizmo();

    if (isDisplay) {
      this.enabled = false;
    }

    return this;
  }

  public getBoundingBox(): Box3 {
    // DO NOT use selectionbox, inconsistent properties update on precomp layers
    // Re-compute objects from Box3
    let updatedBoundingBox = null;

    const isMultiObjects = this.objects.length > 1;

    if (isMultiObjects) {
      updatedBoundingBox = new Box3().setFromObjects(this.objects, true);
    } else {
      updatedBoundingBox = new Box3().setFromObject(this.objects[0] as CObject3D, true);
    }

    return updatedBoundingBox;
  }

  public getSavedPivot(): Vector3 {
    // For group transformations, the pivot point is saved in toolkit
    // to link it to the toolkit state history for undo/redo

    // After each group transformation, attachMultiple is called,
    // so we need to restore the pivot point
    let pivot: Vector3 | null = null;

    const savedPivotOffset = toolkit.getData(UserDataMap.MultiPivotOffset) as Vector3 | null;
    const savedPivot = toolkit.getData(UserDataMap.MultiPivot) as Vector3 | null;

    if (savedPivotOffset) {
      this.groupTransform.pivotOffset = toolkit.getData(UserDataMap.MultiPivotOffset) as Vector3;
      pivot = selectionBox.box.center.add(this.groupTransform.pivotOffset);
    }

    // For rotation and scaling, the absolute pivot point was saved
    // as the offset doesn't account for rotation, and scaling should
    // not change the pivot point
    else if (savedPivot) {
      this.groupTransform.pivot = savedPivot;
      this.groupTransform.pivotOffset = new Vector3().copy(this.groupTransform.pivot).sub(selectionBox.box.center);
      pivot = this.groupTransform.pivot;
    } else {
      pivot = selectionBox.box.center;
      this.groupTransform.pivotOffset = new Vector3(0, 0, 0);
      stateHistory.offTheRecord(() => toolkit.setData(UserDataMap.MultiPivot, pivot));
    }

    return pivot;
  }

  public attachMultiple(objectIDs: string[], fromSignal = false, newPivot = false): this {
    if (!this.pivotPoint) return this;

    const pivotVisible = useCreatorStore.getState().ui.pivotVisible;

    this.visible = true;
    this.objects = objectIDs.map((toolkitID) => canvasMap.get(toolkitID)).filter(Boolean) as CObject3D[];
    this.updateGizmo();

    this.alignmentPivotHelper.objects = this.objects;

    if (fromSignal) return this;
    selectionBox.box.setFromObjects(this.objects, true, true);

    const pivot = this.getSavedPivot();

    this.pivotPoint.position.copy(pivot);
    this.pivotPoint.position.z = -30;
    this.pivotPoint.visible = pivotVisible;

    let maxOpacity = 0;
    let maxScaleX = 1;
    let maxScaleY = 1;
    let maxRotation = 0;

    stateHistory.offTheRecord(() => {
      this.objects.forEach((object) => {
        const node = getNodeByIdOnly(object.toolkitId);

        if (!node) return;

        const animatedProps = (node as AVLayer).state.animatedProperties;

        if (PropertyType.ANCHOR in animatedProps) {
          const newAnchor = animatedProps.a.value as VectorJSON;

          object.toolkitAnchorPosition.x = newAnchor.x;
          object.toolkitAnchorPosition.y = newAnchor.y;
        }

        object.initialPosition.copy(object.position);
        object.anchorPosition.copy(pivot as Vector3);

        maxOpacity = Math.max(maxOpacity, object.opacity);

        if (PropertyType.SCALE in animatedProps) {
          const scale = animatedProps.s.value as VectorJSON;

          maxScaleX = Math.max(maxScaleX, scale.x);
          maxScaleY = Math.max(maxScaleY, scale.y);
        }

        maxRotation = Math.max(maxRotation, object.rotation.z);
      });
    });

    this.lastSelectedObjectIDs = objectIDs;
    selectionBox.update();
    selectionBox.visible = true;
    this.updateGizmo();

    this.groupTransform.rotation = selectionBox.box.zRotation;
    this.groupTransform.position = new Vector3().copy(pivot);

    if (useCreatorStore.getState().canvas.hideTransformControls) this.showTransformControls(false);

    if (newPivot) {
      emitter.emit(EmitterEvent.CANVAS_TRANSFORMCONTROL_UPDATED, { commit: true });
    }

    return this;
  }

  public updatePivotPosition(): void {
    if (this.objects.length === 0 || !this.pivotPoint) return;

    if (this.objects.length === 1) {
      const object = this.objects[0] as CObject3D;
      const parentScale = new Vector3();
      const parentQuaternion = new Quaternion();

      object.parent?.getWorldScale(parentScale);
      object.parent?.getWorldQuaternion(parentQuaternion);
      const offset = new Vector3()
        .copy(object.position)
        .sub(object.initialPosition)
        .multiply(parentScale)
        .applyQuaternion(parentQuaternion);

      this.pivotPoint.position.copy(object.anchorPosition).add(offset);
    } else if (this.objects.length > 1) {
      this.groupTransform.pivot = new Vector3()
        .copy(this.groupTransform.pivotOffset as Vector3)
        .add(selectionBox.box.center);
      this.pivotPoint.position.copy(this.groupTransform.pivot);
    }
  }

  public showBoundingBox(object: CObject3D | CMesh, selectNonTransformable?: boolean): void {
    if (this.dragging) return;

    hoverSelectionBox.box.setFromObject(object, true);
    hoverSelectionBox.update();
    hoverSelectionBox.visible = true;

    if (selectNonTransformable) {
      nonTransformBox.setFromObject(object, true);
      nonTransformSelectionBox.update();
      nonTransformSelectionBox.visible = true;
      this.nonTransformNodeSelected = true;
    }
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  public hideBoundingBox(): void {
    if (!hoverSelectionBox.visible) return;

    hoverSelectionBox.visible = false;
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  public align(direction: AlignDirection | DistributionDirection): void {
    this.alignmentHelper.align(direction);
  }

  public alignPivot(direction: AlignPivotDirection): void {
    const multiSelect = this.objects.length > 1;

    if (multiSelect) {
      const pivotObjectPosition = this.alignmentPivotHelper.getAlignPivotPosition(
        direction as AlignPivotDirection,
        true,
      );

      if (!pivotObjectPosition) return;

      this.groupTransform.pivot = new Vector3()
        .copy(this.groupTransform.pivotOffset as Vector3)
        .add(selectionBox.box.center);
      this.pivotPoint?.position.copy(pivotObjectPosition);
      this.onDragPivotPoint(false, direction);
    } else {
      const pivotObjectPosition = this.alignmentPivotHelper.getAlignPivotPosition(direction as AlignPivotDirection);

      if (!pivotObjectPosition) return;

      const object = this.objects[0] as CObject3D;
      const parentScale = new Vector3();
      const parentQuaternion = new Quaternion();

      object.parent?.getWorldScale(parentScale);
      object.parent?.getWorldQuaternion(parentQuaternion);
      const offset = new Vector3()
        .copy(object.position)
        .sub(object.initialPosition)
        .multiply(parentScale)
        .applyQuaternion(parentQuaternion);

      this.pivotPoint?.position.copy(pivotObjectPosition).add(offset);

      this.onDragPivotPoint(false, direction);
      this.objects.forEach((obj) => {
        updateSelectedNode(obj, this.mode);
      });
      this.updatePivotPosition();
    }
  }

  public updateGizmo(objects = this.objects, translateOnly = false): void {
    const filteredObjects = objects.filter(Boolean);

    if (filteredObjects.length === 0) return;
    const multiSelect = filteredObjects.length > 1;

    if (this.objects.length) selectionBox.visible = true;
    selectionBox.box.setFromObjects(filteredObjects as CObject3D[], true);
    this.groupTransform.rotation = selectionBox.box.zRotation;
    if (this.dragging) selectionBox.update();
    if (this.pivotPoint) this.pivotPoint.position.z = -30;

    const rotatePicker = this._gizmo._picker.rotate;
    const scalePicker = this._gizmo._picker.scale;
    const scaleGizmo = this._gizmo._gizmo.scale;
    const translatePicker = this._gizmo._picker.translate;
    const bbx = selectionBox.box;

    translatePicker.children.forEach((tPicker: CMesh) => {
      tPicker.scale.set(bbx.max.distanceTo(bbx.minXmaxY), bbx.max.distanceTo(bbx.maxXminY), 1);
      tPicker.rotation.z = bbx.zRotation;
      tPicker.position.copy(bbx.max).add(bbx.min).divideScalar(2);
      tPicker.position.z = -15;
    });

    if (translateOnly && !multiSelect) {
      /*
      In normal situation, the translate picker is always selected first by the raycaster by being the closest to the camera.
      In the case that the translate picker moves alongside the mouse hovered shapes, the rotate and scale picker of the selected shape should be selected first.
      Lower z position means closer to the camera.
      */
      if (objects[0] !== this.objects[0]) {
        translatePicker.children[0].position.z = 0;
      }

      return;
    }

    rotatePicker.children.forEach((rPicker: CMesh) => {
      const {
        position,
        userData: { tag },
      } = rPicker;

      if (tag === 'pickerRotateTR') position.copy(bbx.maxXminY);
      else if (tag === 'pickerRotateTL') position.copy(bbx.min);
      else if (tag === 'pickerRotateBL') position.copy(bbx.minXmaxY);
      else if (tag === 'pickerRotateBR') position.copy(bbx.max);
      position.z = -5;
      rPicker.rotation.z = bbx.zRotation;
    });
    const scaleMeshes = [...scalePicker.children, ...scaleGizmo.children];

    scaleMeshes.forEach((sm: CMesh) => {
      const {
        position,
        scale,
        userData: { tag },
      } = sm;

      const roundPointDelta = new Vector2(8, 8);

      roundPointDelta.rotateAround(new Vector2(), bbx.zRotation);
      const scaleY = bbx.max.distanceTo(bbx.maxXminY);
      const scaleX = bbx.min.distanceTo(bbx.maxXminY);

      if (tag === 'ScaleX1') {
        scale.setY(scaleY * 0.8);
        position.copy(bbx.max).add(bbx.maxXminY).divideScalar(2);
      } else if (tag === 'ScaleX2') {
        scale.setY(scaleY * 0.8);
        position.copy(bbx.min).add(bbx.minXmaxY).divideScalar(2);
      } else if (tag === 'ScaleY1') {
        scale.setX(scaleX * 0.8);
        position.copy(bbx.min).add(bbx.maxXminY).divideScalar(2);
      } else if (tag === 'ScaleY2') {
        scale.setX(scaleX * 0.8);
        position.copy(bbx.max).add(bbx.minXmaxY).divideScalar(2);
      } else if (tag === 'ScaleTR') position.copy(bbx.maxXminY);
      else if (tag === 'ScaleTL') position.copy(bbx.min);
      else if (tag === 'ScaleBL') position.copy(bbx.minXmaxY);
      else if (tag === 'ScaleBR') position.copy(bbx.max);

      sm.rotation.z = bbx.zRotation;
      position.z = scaleX < 30 || scaleY < 30 ? -10 : -20;
    });
    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });
  }

  // Detatch from object
  public detach(): this {
    if (this.objects.length > 1) {
      this.objects.forEach((obj) => {
        const node = getNodeByIdOnly(obj.toolkitId);

        if (!node) return;

        this.groupTransform.pivotOffset = new Vector3(0, 0, 0);
        this.groupTransform.pivot = null;
      });

      stateHistory.offTheRecord(() => {
        toolkit.removeData(UserDataMap.MultiPivotOffset);
        toolkit.removeData(UserDataMap.MultiPivot);
      });
    }

    this.objects = [];
    this.alignmentPivotHelper.object = null;
    this.alignmentPivotHelper.objects = [];
    setAnchorPointByCursor(false);

    if (this.pivotPoint) this.pivotPoint.visible = false;

    this._cursorElement.style.display = 'none';

    this._domElement.style.cursor = getCursorStyle('pointer', 'auto');
    selectionBox.visible = false;
    nonTransformSelectionBox.visible = false;
    this.nonTransformNodeSelected = false;
    this.visible = false;

    emitter.emit(EmitterEvent.CANVAS_RENDER_UPDATE, { skipUpdate: true });

    return this;
  }

  // reselect the last object selected before redrawing
  public reselectLastObject(timelineUpdated?: boolean): void {
    // when the timeline is updated, no need to show the previous selection
    if (timelineUpdated) {
      this.visible = false;

      return;
    }

    if (this.lastSelectedObjectIDs.length > 1) {
      this.attachMultiple(this.lastSelectedObjectIDs);

      return;
    }

    const lastSelected = canvasMap.get(this.lastSelectedObjectIDs[0] ?? '');

    if (lastSelected) this.attach(lastSelected);
  }

  public reset(): void {
    if (!this.enabled || this.objects.length === 0 || !this.dragging) return;
    this.objects.forEach((obj) => {
      obj.position.copy(obj.startPosition);
      obj.quaternion.copy(obj.startQuaternion);
      obj.scale.copy(obj.startScale);

      obj.pointStart.copy(obj.pointEnd);
    });
  }

  public getRaycaster(): Raycaster {
    return _raycaster;
  }

  public getMode(): TransformType {
    return this.mode;
  }

  public setDuplicatedObjectPosition(objects: CObject3D[], planeIntersect: Intersection): void {
    objects.forEach((obj) => {
      obj.startPosition.copy(obj.position);
      obj.startQuaternion.copy(obj.quaternion);
      obj.startScale.copy(obj.scale);

      obj.updateMatrixWorld();
      obj.parent?.updateMatrixWorld();
      obj.worldPositionStart.copy(obj.anchorPosition);

      obj.pointStart.copy(planeIntersect.point).sub(obj.worldPositionStart);
      obj.pointStart.z = obj.worldPosition.z;

      obj.userData['isDuplicate'] = null;
    });
  }

  public setMode(mode: TransformType): void {
    this.mode = mode;
  }

  public setSize(size: number): void {
    this.size = size;
  }

  public setSpace(space: SpaceType): void {
    this.space = space;
  }

  public update(): void {
    // eslint-disable-next-line no-console
    console.warn(
      'THREE.TransformControls: update function has no more functionality and therefore has been deprecated.',
    );
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(value) {
    this._enabled = value;
  }

  // updateMatrixWorld  updates key transformation variables
  public override updateMatrixWorld(): void {
    if (this.dragging) {
      this.objects.forEach((obj) => {
        obj.updateMatrixWorld();
        if (obj.parent === null) {
          // eslint-disable-next-line no-console
          console.error('TransformControls: The attached 3D object must be a part of the scene graph.');
        } else {
          obj.parentQuaternion.setFromRotationMatrix(obj.parent.matrixWorld);
          obj.parentScale.setFromMatrixScale(obj.parent.matrixWorld);
        }

        const worldScale = new Vector3();

        obj.matrixWorld.decompose(obj.worldPosition, obj.worldQuaternion, worldScale);

        obj.parentQuaternionInv.copy(obj.parentQuaternion).invert();
        obj.worldQuaternionInv.copy(obj.worldQuaternion).invert();
      });
    }
    this.camera.updateMatrixWorld();

    this._gizmo.update(this.camera as OrthographicCamera);
    super.updateMatrixWorld();
  }
}
