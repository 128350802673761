/**
 * Copyright 2024 Design Barn Inc.
 */

import type { PrecompositionAsset, SizeJSON } from '@lottiefiles/toolkit-js';
import { Size } from '@lottiefiles/toolkit-js';
import { Box3 } from 'three';

import type { CObject3D } from '../types';

import { emitter, EmitterEvent } from '~/lib/emitter';
import { setPrecompReferenceLayersSize } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

const resizePrecompCanvas = (precompSize: Size, canvasSize: SizeJSON | null): void => {
  if (
    !canvasSize ||
    Math.floor(precompSize.width) !== Math.floor(canvasSize.w) ||
    Math.floor(precompSize.height) !== Math.floor(canvasSize.h)
  ) {
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.PRECOMP_SCENE_UPDATE_JSON,
    });
    emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, {
      event: EmitterEvent.CANVAS_ADJUST,
      data: { size: precompSize.toJSON() },
    });
  }
};

// Recalculate precomp canvas size based on the layers/shapes in view
const reCalculatePrecompCanvasSize = (
  precompAsset: PrecompositionAsset,
  parsedObjects: CObject3D[],
  canvasSize: SizeJSON | null,
): void => {
  const hoverBox = new Box3();
  const maxSize = new Size(100, 100);
  const minSize = new Size(0, 0);

  if (typeof precompAsset.getData('canvasWidth') !== 'undefined') {
    maxSize.setWidth(Math.max(precompAsset.getData('canvasWidth') as number, 0));
    maxSize.setHeight(Math.max(precompAsset.getData('canvasHeight') as number, 0));

    minSize.setWidth(Math.max(precompAsset.getData('canvasMinWidth') as number, 0));
    minSize.setHeight(Math.max(precompAsset.getData('canvasMinHeight') as number, 0));
  }

  parsedObjects.forEach((eachObj) => {
    hoverBox.setFromObject(eachObj, true);

    maxSize.width = Number.isFinite(hoverBox.max.x) ? Math.max(maxSize.width, hoverBox.max.x) : maxSize.width;
    maxSize.height = Number.isFinite(hoverBox.max.y) ? Math.max(maxSize.height, hoverBox.max.y) : maxSize.height;

    minSize.width =
      Number.isFinite(hoverBox.min.x) && (hoverBox.min.x < minSize.width || minSize.width === 0)
        ? hoverBox.min.x
        : minSize.width;

    minSize.height =
      Number.isFinite(hoverBox.min.y) && (hoverBox.min.y < minSize.height || minSize.height === 0)
        ? hoverBox.min.y
        : minSize.height;
  });

  precompAsset.setData('canvasCustom', true);

  precompAsset.setData('canvasMinWidth', minSize.width);
  precompAsset.setData('canvasMinHeight', minSize.height);

  precompAsset.setData('canvasWidth', maxSize.width);
  precompAsset.setData('canvasHeight', maxSize.height);

  const precompSize = new Size(
    Math.max(maxSize.width + minSize.width, 0),
    Math.max(maxSize.height + minSize.height, 0),
  );

  setPrecompReferenceLayersSize(precompAsset, precompSize.width, precompSize.height);

  resizePrecompCanvas(precompSize, canvasSize);
};

// Re-AdjustCanvas size if canvas width and height is available in toolkit asset data
export const reAdjustPrecompCanvas = (parsedObjects: CObject3D[], canvasSize: SizeJSON | null): void => {
  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

  if (!selectedPrecompositionId) return;

  const precompAsset = getNodeByIdOnly(selectedPrecompositionId) as PrecompositionAsset | null;

  if (!precompAsset) return;

  if (precompAsset.getData('canvasWidth')) {
    const canvasWidth =
      (precompAsset.getData('canvasWidth') as number) + (precompAsset.getData('canvasMinWidth') as number);
    const canvasHeight =
      (precompAsset.getData('canvasHeight') as number) + (precompAsset.getData('canvasMinHeight') as number);
    const precompSize = new Size(Math.max(canvasWidth, 0), Math.max(canvasHeight, 0));

    resizePrecompCanvas(precompSize, canvasSize);
  } else {
    reCalculatePrecompCanvasSize(precompAsset, parsedObjects, canvasSize);
  }
};
