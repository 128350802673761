/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import type { IUniform } from 'three';
import { shallow } from 'zustand/shallow';

import { getCurrentKeyFrame, getIsAtKeyFrame } from './helpers';

import { Roundness } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { Scalar2D } from '~/lib/toolkit';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface RectangleRoundnessFieldProps {
  roundness: number;
  roundnessIsAnimated: boolean;
  size: Scalar2D;
}

export const RectangleRoundnessField: React.FC<RectangleRoundnessFieldProps> = ({
  roundness,
  roundnessIsAnimated = false,
  size,
}) => {
  const [animated, currentShapeNode, setRoundness, setRectangleRoundnessIsAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentShapeNode?.state?.animatedProperties?.rd,
      state.toolkit.currentShapeNode,
      state.toolkit.setRectangleRoundness,
      state.toolkit.setRectangleRoundnessIsAnimated,
    ],
    shallow,
  );

  const isAtKeyFrame = getIsAtKeyFrame(animated);
  const roundnessCurrentKeyFrame = getCurrentKeyFrame(animated, currentShapeNode);

  const handleOnChange = useCallback(
    (result: IUniform<number>) => {
      stateHistory.beginAction();
      setRoundness(result.value);
      emitter.emit(EmitterEvent.RECT_ROUNDNESS_UPDATED, result);
    },
    [setRoundness],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();

    if (roundnessCurrentKeyFrame === '') {
      setRectangleRoundnessIsAnimated(true);
      setRoundness(roundness);
    } else {
      removeKeyFrame(roundnessCurrentKeyFrame);
    }
    stateHistory.endAction();
    emitter.emit(EmitterEvent.RECT_ROUNDNESS_UPDATED);
  }, [setRoundness, setRectangleRoundnessIsAnimated, roundnessCurrentKeyFrame, roundness]);

  return (
    <NumberInput
      name="roundness"
      label={<Roundness className="h-4 w-4" />}
      min={0}
      max={Math.min(...size) / 2}
      value={roundness}
      onChange={handleOnChange}
      showKeyframe
      onKeyframeClick={handleKeyframeClick}
      hasKeyframe={isAtKeyFrame}
      isChannelAnimated={roundnessIsAnimated}
    />
  );
};
