/**
 * Copyright 2024 Design Barn Inc.
 */

import type { Marker } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect, useState } from 'react';

import { Clock, DeleteIcon, WarningIcon } from '~/assets/icons';
import { Divider } from '~/components/Elements/Divider';
import { defaultStyle, NumberInput, type NumberResult } from '~/components/Elements/Input';
import { Tooltip } from '~/components/Elements/Tooltip';
import { formatPlaybackTime, parseFrames } from '~/features/timeline';

interface Props {
  currentFps: number;
  duration: number;
  isActive: boolean;
  isDisabled: boolean;
  marker: Marker;
  markers: Marker[];
  onDelete: () => void;
  onHover: (isHover: boolean) => void;
  onMatchWorkArea: () => void;
  onSelect: () => void;
  onUpdate: (startFrame: number, endFrame: number, comment: string, isAdjustTimeline?: boolean) => void;
  totalFrames: number;
  workAreaFrameEnd: number | null;
  workAreaFrameStart: number | null;
}

export const TimelineSegmentItem: React.FC<Props> = ({
  currentFps,
  duration,
  isActive,
  isDisabled,
  marker,
  markers,
  onDelete,
  onHover,
  onMatchWorkArea,
  onSelect,
  onUpdate,
  totalFrames,
  workAreaFrameEnd,
  workAreaFrameStart,
}) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [comment, setComment] = useState(marker.comment);

  useEffect(() => {
    setIsDuplicate(
      markers.some((_marker: Marker) => _marker.comment === marker.comment && _marker.nodeId !== marker.nodeId),
    );
    setComment(marker.comment);
  }, [markers, marker]);

  const handleOnChangeFrom = useCallback(
    (item: NumberResult) => {
      onUpdate(item.value, marker.endFrame, marker.comment, true);
    },
    [marker, onUpdate],
  );

  const handleOnChangeTo = useCallback(
    (item: NumberResult) => {
      onUpdate(marker.startFrame, item.value, marker.comment, true);
    },
    [marker, onUpdate],
  );

  const handleStopRename = useCallback(() => {
    onUpdate(marker.startFrame, marker.endFrame, comment, true);
  }, [marker, comment, onUpdate]);

  const handleRename = useCallback(
    (name: string) => {
      setComment(name);
    },
    [setComment],
  );

  const handleDelete = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (isDisabled) {
        return;
      }
      onDelete();
    },
    [isDisabled, onDelete],
  );

  const handleOnMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    onHover(true);
  }, [isDisabled, onHover]);

  const handleOnMouseLeave = useCallback(() => {
    if (isDisabled) {
      return;
    }
    onHover(false);
  }, [isDisabled, onHover]);

  const handleOnSelect = useCallback(() => {
    if (isDisabled) {
      return;
    }
    onSelect();
  }, [isDisabled, onSelect]);

  const handleOnClickInput = useCallback(
    (event: MouseEvent) => {
      if (isDisabled) {
        return;
      }

      if (isActive) {
        event.stopPropagation();
      }
    },
    [isDisabled, isActive],
  );

  const handleOnMatchWorkArea = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (isDisabled) {
        return;
      }
      onMatchWorkArea();
    },
    [isDisabled, onMatchWorkArea],
  );

  const getFormattedTime = (frame: number): string => {
    const formatDuration = formatPlaybackTime(frame / currentFps, currentFps);

    return `${formatDuration.seconds}s ${formatDuration.frames}f`;
  };

  const timeFrom = getFormattedTime(marker.startFrame);
  const timeTo = getFormattedTime(marker.endFrame);
  const timeDiff = getFormattedTime(marker.endFrame - marker.startFrame);

  const isMatchWorkAreaActive = workAreaFrameStart !== marker.startFrame || workAreaFrameEnd !== marker.endFrame;

  const bodyClassName = isDisabled
    ? 'rounded-lg bg-[#283137]'
    : `cursor-pointer rounded-lg ${isActive ? 'bg-[#333C45]' : 'bg-[#283137]'} hover:bg-[#333C45]`;

  const customParser = useCallback(
    (inputValue: string, isManualInput: boolean): string => {
      if (!isManualInput) {
        return inputValue;
      }

      const frame = parseFrames(inputValue, currentFps, duration);

      if (frame === null) {
        return inputValue;
      }

      return Math.round(frame).toString();
    },
    [currentFps, duration],
  );

  return (
    <div
      id={marker.nodeId}
      className={bodyClassName}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnSelect}
    >
      <div className="flex items-center justify-between px-2 py-1">
        <div className={`flex items-center gap-1 text-[12px] ${isActive ? 'text-[#FFFFFF]' : 'text-[#A1ADB7]'}`}>
          <div className="flex shrink-0 items-center gap-0.5">
            <Clock />
            <div className="shrink-0">{timeDiff}</div>
          </div>
          <div className="mx-1 h-4 w-[1px] bg-[#3D4853]"></div>
          <input
            id={`segment-name-${marker.nodeId}`}
            disabled={isDisabled}
            maxLength={30}
            minLength={4}
            className="w-full border-none bg-transparent selection:bg-teal-800 selection:text-white focus-visible:outline-none"
            value={comment}
            onChange={(ev) => handleRename(ev.currentTarget.value)}
            onClick={handleOnClickInput}
            onBlur={handleStopRename}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.stopPropagation();
                handleStopRename();
              }
            }}
          />
        </div>
        <Tooltip content="Delete">
          <div>
            <DeleteIcon
              className={`h-4 w-4 ${isDisabled ? '' : 'cursor-pointer hover:rounded hover:bg-gray-700'}`}
              onClick={handleDelete}
            />
          </div>
        </Tooltip>
      </div>
      <Divider />
      <div className="flex flex-col gap-2 p-2">
        {isDuplicate && (
          <div className="flex items-center gap-1">
            <WarningIcon className="h-4 w-4 shrink-0" />
            <div className="text-[10px] text-[#FFB887]">Segment name already in use.</div>
          </div>
        )}
        <div className={`flex items-center gap-1 ${isActive ? 'text-[#FFFFFF]' : 'text-[#A1ADB7]'}`}>
          <div className="w-full">
            <NumberInput
              isDisabled={isDisabled}
              styleClass={{
                span: `${defaultStyle.span} w-8`,
              }}
              name="segment-from"
              label="From"
              message={timeFrom}
              value={marker.startFrame}
              onChange={handleOnChangeFrom}
              onClick={handleOnClickInput}
              min={0}
              max={marker.endFrame - 1}
              customInputParserFn={customParser}
            />
          </div>
          <div className="relative w-full">
            <NumberInput
              isDisabled={isDisabled}
              name="segment-to"
              label="To"
              message={timeTo}
              value={marker.endFrame}
              onChange={handleOnChangeTo}
              onClick={handleOnClickInput}
              min={(marker.startFrame as number) + 1}
              max={totalFrames}
              customInputParserFn={customParser}
            />
            {marker.endFrame > totalFrames && (
              <div className="absolute right-0 top-0 p-1">
                <WarningIcon className="h-4 w-4 shrink-0" />
              </div>
            )}
          </div>
        </div>
        {isActive && (
          <div className="flex justify-center">
            {isMatchWorkAreaActive && !isDisabled && (
              <div className="cursor-pointer text-[12px] text-[#019D91]" onClick={handleOnMatchWorkArea}>
                Match work area
              </div>
            )}
            {(!isMatchWorkAreaActive || isDisabled) && (
              <div className="text-[12px] text-[#A1ADB7]">Match work area</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
