/**
 * Copyright 2022 Design Barn Inc.
 */

export interface NumberResult {
  arrowKeyPressed?: boolean;
  change?: number;
  dragged?: boolean;
  name: NumberResultName | string;
  trueValue: number;
  value: number;
}

export enum NumberResultName {
  NumberOfRotations = 'numberOfRotations',
  Opacity = 'opacity',
  Rotation = 'rotation',
  X = 'x',
  Y = 'y',
}
