/**
 * Copyright 2022 Design Barn Inc.
 */

import { MathUtils } from 'three';

export const cos = (rad: number): number => Math.cos(rad);
export const sin = (rad: number): number => Math.sin(rad);
export const tan = (rad: number): number => Math.tan(rad);
export const degToRad = (deg: number): number => MathUtils.degToRad(deg);
