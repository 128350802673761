/**
 * Copyright 2023 Design Barn Inc.
 */

import { emitter, EmitterEvent } from './emitter';

import { restoreWorkAreaFromHistory, updateMarkers } from '~/features/timeline';
import { toolkit, stateHistory, getToolkitState } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

const addingToolkitListener = (
  callback: (selectedState: boolean, previousSelectedState: boolean) => void,
): (() => void) => {
  return useCreatorStore.subscribe((state) => state.ui.isAddingToolkitListener === false, callback, {
    fireImmediately: true,
  });
};

const waitForAddingToolkitListener = async (): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const unsub = addingToolkitListener((isAdded: boolean) => {
      if (isAdded) {
        unsub();
        resolve(true);
      }
    });
  });
};

export const customEventTypes = {
  addedPathPoint: 'addedPathPoint',
};

export const undoHistory = async (event: string): Promise<void> => {
  const setJSON = useCreatorStore.getState().toolkit.setJSON;

  if (stateHistory.canUndo && stateHistory.index > 0) {
    // await for adding toolkitListener
    useCreatorStore.getState().ui.setIsAddingToolkitListener(true);
    await waitForAddingToolkitListener();

    // run toolkit undo
    const historyItem = stateHistory.history[stateHistory.index];
    const undoStateType = historyItem?.type;

    if (historyItem) {
      restoreWorkAreaFromHistory(historyItem, true);
    }

    stateHistory.undo();

    if (undoStateType && undoStateType !== customEventTypes.addedPathPoint) {
      // check latest json
      const json = structuredClone(getToolkitState(toolkit));

      if (json) {
        updateMarkers();
        setJSON(json);
        emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event });
      }
    }
  }
};

export const redoHistory = async (event: string): Promise<void> => {
  if (stateHistory.canRedo) {
    // await for adding toolkitListener
    useCreatorStore.getState().ui.setIsAddingToolkitListener(true);
    await waitForAddingToolkitListener();

    stateHistory.redo();

    const historyItem = stateHistory.history[stateHistory.index];
    const undoStateType = historyItem?.type;

    if (historyItem) {
      restoreWorkAreaFromHistory(historyItem, false);
    }

    if (undoStateType && undoStateType !== customEventTypes.addedPathPoint) {
      updateMarkers();
      emitter.emit(EmitterEvent.TOOLKIT_STATE_UPDATED, { event });
    }
  }
};
