/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AVLayer, RectangleShape, ShapeLayer, VectorJSON } from '@lottiefiles/toolkit-js';
import { ShapeType } from '@lottiefiles/toolkit-js';
import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import type { AnimatedInputProp } from './animatedUtil';

import { AnimatedPositionX, AnimatedPositionY } from '~/assets/icons';
import type { NumberResult } from '~/components/Elements/Input';
import { TimelineDualNumberInput } from '~/components/Elements/Input/TimelineDualNumberInput';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { Scalar2D } from '~/lib/toolkit';
import { setNodePosition } from '~/lib/toolkit';
import { roundArr } from '~/lib/toolkit/helper';
import { useCreatorStore } from '~/store';
import { formatNumber } from '~/utils';

export const AnimatedPosition: React.FC<AnimatedInputProp> = ({ animatedProp, parentId }) => {
  const [getNodeByIdOnly, selectedNodeId, currentPosition, multiselect] = useCreatorStore(
    (state) => [
      state.toolkit.getNodeByIdOnly,
      state.ui.selectedNodesInfo[0]?.nodeId as string,
      state.toolkit.currentTransform.position,
      state.ui.selectedNodesInfo.length > 1,
    ],
    shallow,
  );

  const { posX, posY } = useMemo(() => {
    const parentNode = getNodeByIdOnly(parentId as string) as ShapeLayer | RectangleShape;

    const isBasicShape =
      parentNode.type === ShapeType.RECTANGLE ||
      parentNode.type === ShapeType.ELLIPSE ||
      parentNode.type === ShapeType.STAR;

    if (isBasicShape && animatedProp.value) {
      return {
        posX: (animatedProp.value as VectorJSON).x,
        posY: (animatedProp.value as VectorJSON).y,
      };
    }
    if (!multiselect && selectedNodeId === parentId) {
      return {
        posX: currentPosition[0],
        posY: currentPosition[1],
      };
    }

    return {
      posX: parentNode.position.value.x,
      posY: parentNode.position.value.y,
    };
  }, [getNodeByIdOnly, parentId, animatedProp.value, multiselect, selectedNodeId, currentPosition]);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      const node = getNodeByIdOnly(animatedProp.id) as AVLayer;

      if (node.parent) {
        if (result.name === 'posX') {
          setNodePosition(node.parent as AVLayer, roundArr([result.value, posY]) as Scalar2D);
        } else if (result.name === 'posY') {
          setNodePosition(node.parent as AVLayer, roundArr([posX, result.value]) as Scalar2D);
        }
        emitter.emit(EmitterEvent.SHAPE_POSITION_UPDATED);
      }
    },
    [getNodeByIdOnly, animatedProp.id, posY, posX],
  );

  return (
    <div className="flex items-center">
      <TimelineDualNumberInput
        onChange={handleOnChange}
        left={formatNumber(posX)}
        right={formatNumber(posY)}
        leftOption={{
          name: 'posX',
          label: <AnimatedPositionX className="h-3 w-3" />,
        }}
        rightOption={{
          name: 'posY',
          label: <AnimatedPositionY className="h-3 w-3" />,
        }}
      />
    </div>
  );
};
