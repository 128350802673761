/**
 * Copyright 2022 Design Barn Inc.
 */

import { toolkit } from './toolkit';

import { useCreatorStore } from '~/store';

export const removeKeyFrame = (id: string): void => {
  const keyframe = toolkit.getKeyframeById(id);

  const parentTrack = keyframe?.parentTrack;

  // if the keyframe is at 0 and the track the property is not animated, we
  // refuse to remove the keyframe
  if (!keyframe || !parentTrack || (keyframe.frame === 0 && !parentTrack.isAnimated)) {
    return;
  }

  if (!useCreatorStore.getState().ui.isUIMinimized) {
    const setTimelineVisible = useCreatorStore.getState().timeline.setVisible;

    setTimelineVisible(true);
  }

  parentTrack.removeKeyFrame(keyframe.frame);
  // If it's the last keyframe in the track, set the property to be not
  // animated
  if (parentTrack.keyFrames.length === 0) {
    parentTrack.property.setIsAnimated(false);
  }
};

export const removeKeyFrames = (id: string): void => {
  const keyframe = toolkit.getKeyframeById(id);

  if (keyframe?.parentTrack?.isAnimated) {
    keyframe.parentTrack.clear();
  }
};

export const setKeyFrame = (id: string, frame: number): void => {
  const keyframe = toolkit.getKeyframeById(id);

  keyframe?.setFrameNo(frame);
};
