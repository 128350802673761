/**
 * Copyright 2023 Design Barn Inc.
 */

import { useFeature } from '@growthbook/growthbook-react';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { shallow } from 'zustand/shallow';

import { GettingStartedSidePanel } from './GettingStartedSidePanel';
import { PluginHolderPanel } from './PluginHolderPanel';

import { GB_CONSTANT } from '~/config/growthbook';
import { LibraryType } from '~/data/constant';
import { SearchAnimation } from '~/features/search-animation';
import { useCreatorStore } from '~/store';

interface SidePanelCollapseButtonProps {
  onClick?: (e: React.MouseEvent<SVGElement>) => void;
}

const SidePanelCollapseButton: React.FC<SidePanelCollapseButtonProps> = ({ onClick }) => {
  return (
    <svg
      className="absolute right-[-10px] top-[calc(50%-32px)] z-10 h-[46px] w-4 cursor-pointer"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9472 7.56002C10.9653 7.56669 10.9835 7.57335 11.0018 7.58C11.0281 7.58958 11.0469 7.59789 11.0583 7.60504C13.9596 8.80594 16.0008 11.6646 16.0008 15L16.0008 31C16.0008 34.3437 13.9495 37.2083 11.0366 38.4039C11.028 38.4088 11.0157 38.4142 10.9997 38.42L10.9714 38.4303C10.8705 38.4707 10.7687 38.509 10.6659 38.5454C6.00398 40.3523 5.54019 42.9755 5.13459 45.2697L5.13458 45.2697C5.09078 45.5175 5.04766 45.7614 5 46L5.00053 38.4996H5.00082L5.00082 7.50043C5.00072 7.50043 5.00063 7.50043 5.00053 7.50043L5 0C5.04753 0.237977 5.09056 0.481206 5.13428 0.728256C5.54097 3.02673 6.00618 5.65594 10.6914 7.4637C10.7773 7.49439 10.8626 7.5265 10.9472 7.56002Z"
        fill="#2B343B"
      />
      <path
        d="M8.66406 26.3327L5.33073 22.9993L8.66406 19.666"
        stroke="#606F7B"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const GettingStartedSidePanelCollapseButton: React.FC<SidePanelCollapseButtonProps> = ({ onClick }) => {
  return (
    <svg
      className="absolute right-[-10px] top-[calc(50%-32px)] z-10 h-[46px] w-4 cursor-pointer"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9472 7.56002C10.9653 7.56669 10.9835 7.57335 11.0018 7.58C11.0281 7.58958 11.0469 7.59789 11.0583 7.60504C13.9596 8.80594 16.0008 11.6646 16.0008 15L16.0008 31C16.0008 34.3437 13.9495 37.2083 11.0366 38.4039C11.028 38.4088 11.0157 38.4142 10.9997 38.42L10.9714 38.4303C10.8705 38.4707 10.7687 38.509 10.6659 38.5454C6.00398 40.3523 5.54019 42.9755 5.13459 45.2697L5.13458 45.2697C5.09078 45.5175 5.04766 45.7614 5 46L5.00053 38.4996H5.00082L5.00082 7.50043C5.00072 7.50043 5.00063 7.50043 5.00053 7.50043L5 0C5.04753 0.237977 5.09056 0.481206 5.13428 0.728256C5.54097 3.02673 6.00618 5.65594 10.6914 7.4637C10.7773 7.49439 10.8626 7.5265 10.9472 7.56002Z"
        fill="#22292f"
      />
      <path
        d="M8.66406 26.3327L5.33073 22.9993L8.66406 19.666"
        stroke="#606F7B"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SidebarPanel: React.FC = () => {
  const [currentLibrary, setCurrentLibrary, isUIMinimized] = useCreatorStore(
    (state) => [state.ui.currentLibrary, state.ui.setCurrentLibrary, state.ui.isUIMinimized],
    shallow,
  );
  const handleOnClick = useCallback(() => {
    setCurrentLibrary(null);
  }, [setCurrentLibrary]);

  const { ref } = useResizeDetector({ onResize: () => window.dispatchEvent(new Event('resize')) });

  const showGetStarted = useFeature(GB_CONSTANT.SHOW_GET_STARTED).on;

  const isW60 =
    (currentLibrary !== null && currentLibrary !== LibraryType.GettingStarted) ||
    (currentLibrary === LibraryType.GettingStarted && showGetStarted);

  return (
    <div
      className={clsx('relative z-[2] dark:bg-gray-800', {
        'transition-all': !isUIMinimized,
        'w-60': isW60 && !isUIMinimized,
        'w-0': currentLibrary === null || isUIMinimized,
        invisible: isUIMinimized,
      })}
      id="sidepanel"
      ref={ref}
    >
      {currentLibrary === LibraryType.GettingStarted && showGetStarted && (
        <>
          <GettingStartedSidePanel /> <GettingStartedSidePanelCollapseButton onClick={handleOnClick} />
        </>
      )}
      {currentLibrary === LibraryType.Lottie && <SearchAnimation />}
      {currentLibrary?.startsWith('plugin') && <PluginHolderPanel currentLibrary={currentLibrary} />}
      {currentLibrary && currentLibrary !== LibraryType.GettingStarted && (
        <SidePanelCollapseButton onClick={handleOnClick} />
      )}
    </div>
  );
};
