/**
 * Copyright 2021 Design Barn Inc.
 */

import { intersection } from 'lodash-es';
import type React from 'react';
import { useEffect } from 'react';

// Inspired from https://usehooks.com/useOnClickOutside/
export const useClickOutside = (
  ref: React.RefObject<HTMLElement> | null,
  handler: (evt: MouseEvent | TouchEvent) => void,
  exceptionClass: string[] | null = null,
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      // Do nothing if clicking ref's element or descendent elements
      const hasException = exceptionClass !== null && exceptionClass.length > 0;

      if (hasException) {
        const classList = event.toElement?.classList;

        if (intersection(Array.from(classList), exceptionClass).length > 0) {
          return;
        }
      }
      if (ref && (!ref.current || ref.current.contains(event.target as Node))) return;

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, exceptionClass]);
};
