/**
 * Copyright 2024 Design Barn Inc.
 */

import type { AVLayer, Scene } from '@lottiefiles/toolkit-js';
import { TrackMatteType } from '@lottiefiles/toolkit-js';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';

import type { Option } from '~/components/Elements/Select';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { stateHistory } from '~/lib/toolkit';
import { getNodeById } from '~/plugins/shim/utils';
import { useCreatorStore } from '~/store';

export interface MatteController {
  matteOptions: Option[];
  onAddTrackMatte: () => void;
  onMatteChange: (matteOption: Option) => void;
  selectedMatte: Option | null;
  setSelectedMatte: Dispatch<SetStateAction<Option | null>>;
}

export const useMatte = (layerId: string): MatteController => {
  const [layers] = useCreatorStore((state) => [state.toolkit.json?.allLayers], shallow);

  const layerNode = getNodeById(layerId) as AVLayer | null;

  let allLayers = useMemo(() => layers || [], [layers]);
  const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

  if (selectedPrecompositionId) {
    const precomNode = getNodeById(selectedPrecompositionId);

    allLayers = (precomNode as Scene).state.allLayers;
  }

  const allLayersMapped = useMemo(
    () =>
      allLayers
        .filter(
          (node) =>
            // Layer with a mask can't be used as a matte. Also matted layer can't be used as a matte
            node.id !== layerId && (node.properties.tt === 0 || !node.trackMatteParent) && node.masks.length === 0,
        )
        .map((node) => ({
          label: node.properties.nm as string,
          value: node.id,
        })),
    [allLayers, layerId],
  );

  const matteOptions: Option[] = [
    {
      label: '(No matte)',
      value: 'noMatte',
    },
    {
      label: '',
      isDivider: true,
      value: '',
    },
    ...allLayersMapped,
  ];

  const [selectedMatte, setSelectedMatte] = useState<Option | null>(matteOptions[0] ?? null);

  useEffect(() => {
    if (layerNode?.trackMatteParent) {
      setSelectedMatte({ label: layerNode.trackMatteParent.name, value: layerNode.trackMatteParent.nodeId });
    } else {
      setSelectedMatte({ label: '(No matte)', value: 'noMatte' });
    }
  }, [layerNode?.trackMatteParent, layerNode?.trackMatteParent?.name, layerNode?.trackMatteParent?.nodeId]);

  const resetTrackMatteParent = useCallback(() => {
    if (layerNode?.trackMatteParent) {
      const isTrackMatteParentActive = allLayers.some(
        (item) =>
          item.id !== layerNode.nodeId &&
          item.trackMatteParent &&
          item.trackMatteParent === layerNode.trackMatteParent?.nodeId,
      );

      if (!isTrackMatteParentActive) {
        layerNode.trackMatteParent.setIsTrackMatte(false);
        layerNode.trackMatteParent.setData('isMatteHidden', false);
      }
    }
  }, [allLayers, layerNode]);

  const onMatteChange = useCallback(
    (option: Option) => {
      if (!layerNode) return;
      stateHistory.beginAction();
      if (typeof option.value === 'string' && option.value !== 'noMatte') {
        resetTrackMatteParent();
        const matteLayer = getNodeById(option.value) as AVLayer | null;

        if (!matteLayer) return;
        matteLayer.setIsTrackMatte(true);
        matteLayer.setData('isMatteHidden', true);

        layerNode.setTrackMatteParent(matteLayer);
        layerNode.setTrackMatteType(TrackMatteType.ALPHA);
      } else {
        resetTrackMatteParent();
        layerNode.setTrackMatteParent(null);
        layerNode.setTrackMatteType(TrackMatteType.NO_TRACK_MATTE);
      }
      setSelectedMatte(option);
      stateHistory.endAction();
      emitter.emit(EmitterEvent.CANVAS_REDRAW);
    },
    [layerNode, resetTrackMatteParent],
  );

  const onAddTrackMatte = (): void => {
    const isMatte = layerNode?.state.properties.td as boolean;

    if (isMatte) return;
    emitter.emit(EmitterEvent.TIMELINE_LAYER_MATTE_UPDATE, { id: layerId, matteType: TrackMatteType.ALPHA });
    emitter.emit(EmitterEvent.CANVAS_REDRAW);
  };

  return {
    selectedMatte,
    matteOptions,
    setSelectedMatte,
    onMatteChange,
    onAddTrackMatte,
  };
};
