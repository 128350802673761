/**
 * Copyright 2021 Design Barn Inc.
 */

import React, { useEffect, useCallback } from 'react';

import App from '../App';

import { useURLQuery } from '.';
import { setupExistingProject } from '~/providers/components/save/shared';
import { useCreatorStore } from '~/store';
import { UrlType } from '~/store/projectSlice';

const setUrlType = useCreatorStore.getState().project.setUrlType;

export const CreateNewFile: React.FC = () => {
  const query = useURLQuery();

  const workspaceId = query.get('workspace') ?? '';
  const projectId = query.get('project') ?? '';
  const folderId = query.get('folder') ?? '';

  const setupProjectSaving = useCallback(async () => {
    setupExistingProject({
      workspaceId,
      projectId,
      folderId,
    });
    setUrlType(UrlType.Create);
  }, [folderId, projectId, workspaceId]);

  useEffect(() => {
    setupProjectSaving();
  }, [setupProjectSaving]);

  return <App />;
};
