/**
 * Copyright 2022 Design Barn Inc.
 */

import type { AnimatedPropertyJSON, AVLayer, AnimatedProperty, KeyFrameJSON } from '@lottiefiles/toolkit-js';
import { ShapeType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { AnimatedInput } from './AnimatedInput';
import { AnimatedType } from './animatedUtil';

import { LayerAnimated, LayerAnimatedShapeSpecific, LayerAnimatedStroke, LayerAnimatedTrim } from '~/assets/icons';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import { AnimatedPropertyMenu } from '~/features/menu';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { layerMap } from '~/lib/layer';
import { removeKeyFrame, mapAnimatedProperty } from '~/lib/toolkit';
import { CreatorAnimatedTypes } from '~/lib/toolkit/shape-helper';
import { useCreatorStore } from '~/store';

interface LayerAnimatedThumbnailProps {
  id: string;
  layerType: string;
}

const LayerAnimatedThumbnail: React.FC<LayerAnimatedThumbnailProps> = ({
  id,
  layerType,
}: LayerAnimatedThumbnailProps) => {
  // The last id in the parent array is the direct parent
  const parentLayerId = layerMap.get(id)?.parent.at(-1);
  const parentLayer = parentLayerId && layerMap.get(parentLayerId);
  const parentLayerAppearanceType = parentLayer && (parentLayer.appearanceType as ShapeType | undefined);
  const isShapeType =
    parentLayerAppearanceType &&
    [ShapeType.RECTANGLE, ShapeType.STAR, ShapeType.ELLIPSE].includes(parentLayerAppearanceType as ShapeType);

  if (isShapeType) {
    return <LayerAnimatedShapeSpecific className="h-4 w-4" />;
  }

  if (layerType === ShapeType.STROKE) return <LayerAnimatedStroke className="h-4 w-4" />;
  if (layerType === ShapeType.TRIM) return <LayerAnimatedTrim className="h-4 w-4" />;
  else return <LayerAnimated className="h-4 w-4 fill-current text-gray-500" />;
};

interface AnimatedRowProps {
  animatedProps: AnimatedPropertyJSON[];
  ids: string;
  layerType: string;
  parentId?: string;
  types: string[];
}

export const AnimatedRow: React.FC<AnimatedRowProps> = ({
  animatedProps,
  ids,
  layerType,
  parentId,
  types,
}: AnimatedRowProps) => {
  const hasMoreField = animatedProps.length > 1;
  const animatedProp = animatedProps[0] as AnimatedPropertyJSON;
  const animatedProp2 = hasMoreField ? animatedProps[1] : null;

  const id = ids.split('_')[0] as string;
  const id2 = hasMoreField ? ids.split('_')[1] : null;

  const type = types[0] as string;
  const type2 = hasMoreField ? types[1] : null;

  const [currentFrame, timelineContext, selectedNodesInfo, addAnimatedValue, setStaticValue, setSelectedKeyframeIds] =
    useCreatorStore(
      (state) => [
        state.toolkit.currentFrame,
        state.timeline.timelineContext,
        state.ui.selectedNodesInfo,
        state.toolkit.addAnimatedValue,
        state.toolkit.setStaticValue,
        state.timeline.setSelectedKeyframeIds,
      ],
      shallow,
    );

  const handleKeyFrameClick = useCallback(() => {
    const node = useCreatorStore.getState().toolkit.getNodeByIdOnly(animatedProp.id) as AnimatedProperty;

    const parentNode = node.parent as AVLayer;
    const mappedAnimatedProperty = mapAnimatedProperty[type];

    if (animatedProp.isAtKeyFrame) {
      const currentKeyFrame = animatedProp.keyFrames.find((keyFrame) => {
        return keyFrame.frame === currentFrame || keyFrame.frame === Math.round(currentFrame);
      }) as KeyFrameJSON | null;

      if (currentKeyFrame && mappedAnimatedProperty) {
        const currentValue = mappedAnimatedProperty.getAnimatedValue(parentNode);

        removeKeyFrame(currentKeyFrame.frameId as string);

        if (animatedProp.keyFrames.length === 1) {
          if (parentNode.type === 'GROUP' || parentNode.isAVLayer) {
            setStaticValue(mappedAnimatedProperty.animatedProperty, currentValue, parentNode.nodeId);
          } else {
            node.setIsAnimated(false);
            mappedAnimatedProperty.setShapeProperty(parentNode, currentValue);
          }
        }
      }
    } else if (mappedAnimatedProperty) {
      if (parentNode.type === 'GROUP' || parentNode.isAVLayer) {
        addAnimatedValue(
          mappedAnimatedProperty.animatedProperty,
          mappedAnimatedProperty.getAnimatedValue(parentNode),
          parentNode.nodeId,
        );
      } else {
        mappedAnimatedProperty.setShapeProperty(parentNode, mappedAnimatedProperty.getAnimatedValue(parentNode));
      }
    }
    emitter.emit(EmitterEvent.ANIMATED_KEYFRAME_UPDATED);
  }, [animatedProp, type, addAnimatedValue, setStaticValue, currentFrame]);

  const onAnimatedClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.type === 'contextmenu' && event.button === 2 && parentId) {
        const addToSelectedNodes = useCreatorStore.getState().ui.addToSelectedNodes;

        addToSelectedNodes([parentId], true);
      }
    },
    [parentId],
  );

  const mousePos = timelineContext.mousePos;
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  const selectedNodeId = selectedNodesInfo[0]?.nodeId;
  const selectedNode = selectedNodeId ? getNodeByIdOnly(selectedNodeId) : null;
  const selectedType = selectedNode?.type;
  const keyFrames = animatedProp.keyFrames.map((prop) => prop.frame);
  const isAtKeyframe = keyFrames.includes(currentFrame);

  const onClick = useCallback(() => {
    setSelectedKeyframeIds(animatedProp.keyFrames.map((keyFrame) => keyFrame.frameId as string));
  }, [animatedProp.keyFrames, setSelectedKeyframeIds]);

  return (
    <>
      <div
        className={clsx('flex h-full items-center justify-between', {})}
        onContextMenu={(evt) => onAnimatedClick(evt)}
        onClick={onClick}
      >
        <div className="flex items-center">
          <LayerAnimatedThumbnail layerType={layerType} id={ids} />
          <span className="ml-[6.66px] text-gray-400">{AnimatedType[type]}</span>
          <span data-layerid={id} className="invisible ml-1">
            {id}
          </span>
        </div>
        <div className="absolute right-0 mr-[3px] flex items-center">
          <AnimatedInput animatedProp={animatedProp} type={type} id={id} parentId={parentId} parentType={layerType} />

          {animatedProp2 && (
            <AnimatedInput
              animatedProp={animatedProp2}
              type={type2 as string}
              id={id2 as string}
              parentId={parentId}
              parentType={layerType}
            />
          )}

          <KeyframeButton
            hasKeyframe={isAtKeyframe}
            onClick={handleKeyFrameClick}
            isTransparent
            isChannelAnimated={Boolean(animatedProp.isAnimated)}
          />
        </div>
      </div>
      {CreatorAnimatedTypes.includes(selectedType) && (
        <div style={{ position: 'absolute' }}>
          <AnimatedPropertyMenu
            isOpen={selectedNodeId === id}
            onClose={() => null}
            coord={mousePos}
            eventArg={{
              id: selectedNodeId,
              type: selectedType,
            }}
          />
        </div>
      )}
    </>
  );
};
