/**
 * Copyright 2022 Design Barn Inc.
 */

import type { StateCreator } from 'zustand';

import type { StoreSlice } from '.';
import { DEFAULT_FILE_NAME } from '~/data/constant';

export const SavingState = {
  // Initial state. Nothing can be assumed in this state
  INIT: 'INIT',

  // Self-descriptive
  SAVING: 'SAVING',
  SAVED: 'SAVED',
  FAILED: 'FAILED',

  // when the user has made a change since last save, but the new change hasn't been saved yet
  PENDING_SAVE: 'PENDING_SAVE',
};

export const NetworkStatus = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
};

export interface IWorkspaceFolder {
  fileCount?: number;
  id: string;
  name: string;
  projectId: string;
  tempId?: string;
  thumbnails: string | null;
  type: string;
}

export interface IWorkspaceProject {
  folderCount: number;
  id: string;
  isPrivate?: boolean;
  stats?: { [key: string]: number };
  tempId?: string;
  title?: string;
  type: string;
  workspaceId: string;
}

export interface IWorkspace {
  icon: string;
  icon64?: string;
  id: string;
  name: string;
  projects: IWorkspaceProject[];
}

export interface ProjectInfo {
  createdByUserId?: string;
  draftProjectId?: string | null;
  fileId?: string | null;
  fileSubscribeKey?: string | null;
  fileVersionId?: string | null;
  id?: string;
  isDraft?: boolean;
  localChangeId: string | null;
  name?: string;
  oldName?: string;
  saveDraftCount?: number;
  saveFromPopup?: boolean;
  savingState: string;
  subscriptionVersionId?: string | null;
}

export enum DirectoryType {
  Folder = 'folder',
  Project = 'project',
  Workspace = 'workspace',
}

export enum UrlType {
  Create = 'create',
  Existing = 'existing',
}

export interface SelectedDirectory {
  id: string;
  parentId?: string;
  title?: string | undefined;
  type?: DirectoryType;
}

export interface FetchExistingFile {
  failedToLoad?: boolean;
  fileId: string | null;
  fileNotFound?: boolean;
  isExportedFile: boolean;
  remixId?: string | null;
  status: boolean;
}

export interface ProjectSlice {
  project: {
    addTheme: (themeId: string, data: Record<string, unknown>) => void;
    autoSaveStart: boolean;
    backOnline: boolean;
    fetchExistingFile: FetchExistingFile;
    info: ProjectInfo;
    madeFirstChange: boolean;
    network: string | null;
    removeTheme: (themeId: string) => void;
    selectedDirectory: SelectedDirectory | null;
    selectedWorkspace: IWorkspace | null;
    setAutoSaveStart(status: boolean): void;
    setBackOnline: (status: boolean) => void;
    setFetchExistingFile(data: Partial<FetchExistingFile>): void;
    setInfo: (info: Partial<ProjectInfo>) => void;
    setMadeFirstChange: (status: boolean) => void;
    setNetwork: (status: string) => void;
    setSelectedDirectory: (data: SelectedDirectory) => void;
    setSelectedWorkspace: (workspace: IWorkspace) => void;
    setThemes: (themes: Record<string, Record<string, unknown>>) => void;
    setUrlType: (type: UrlType | null) => void;
    setWorkflowWorkspaces: (workspaces: IWorkspace[]) => void;
    themes: Record<string, unknown>;
    urlType: UrlType | null;

    workflowWorkspaces: IWorkspace[];
  };
}

export const createProjectSlice: StateCreator<
  StoreSlice,
  [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
  [],
  ProjectSlice
> = (set) => ({
  project: {
    network: null,
    selectedDirectory: null,
    backOnline: false,
    madeFirstChange: false,
    autoSaveStart: false,
    workflowWorkspaces: [],
    selectedWorkspace: null,
    urlType: null,
    fetchExistingFile: { status: false, fileId: null, isExportedFile: false },
    info: {
      name: DEFAULT_FILE_NAME,
      isDraft: false,
      savingState: SavingState.INIT,
      fileSubscribeKey: null,
      fileVersionId: null,
      subscriptionVersionId: null,
      fileId: null,
      draftProjectId: null,
      localChangeId: null,
      saveDraftCount: 0,
      saveFromPopup: false,
    },
    themes: {},
    addTheme: (themeId: string, data: Record<string, unknown>) => {
      set((draft) => {
        draft.project.themes[themeId] = { ...data, id: themeId };
      });
    },
    setThemes: (themes: Record<string, Record<string, unknown>>) => {
      set((draft) => {
        draft.project.themes = themes;
      });
    },
    removeTheme: (themeId: string) => {
      set((draft) => {
        delete draft.project.themes[themeId];
      });
    },
    setInfo: (info: Partial<ProjectInfo>) => {
      set((draft) => {
        const nameChanged = draft.project.info.name !== info.name;

        draft.project.info = {
          ...draft.project.info,
          ...info,
          ...(nameChanged && info.name
            ? {
                name: info.name,
              }
            : {}),
        };
      });
    },
    setSelectedDirectory: (data: SelectedDirectory) => {
      set((draft) => {
        draft.project.selectedDirectory = { ...data };
      });
    },
    setNetwork: (status: string) => {
      set((draft) => {
        draft.project.network = status;
      });
    },
    setBackOnline: (status: boolean) => {
      set((draft) => {
        draft.project.backOnline = status;
      });
    },
    setMadeFirstChange: (status: boolean) => {
      set((draft) => {
        draft.project.madeFirstChange = status;
      });
    },
    setWorkflowWorkspaces: (workspaces: IWorkspace[]) => {
      set((draft) => {
        draft.project.workflowWorkspaces = workspaces;
      });
    },
    setSelectedWorkspace: (workspace: IWorkspace) => {
      set((draft) => {
        draft.project.selectedWorkspace = workspace;
      });
    },
    setAutoSaveStart: (status: boolean) => {
      set((draft) => {
        draft.project.autoSaveStart = status;
      });
    },
    setFetchExistingFile: (data: Partial<FetchExistingFile>) => {
      set((draft) => {
        draft.project.fetchExistingFile = {
          ...draft.project.fetchExistingFile,
          ...data,
        };
      });
    },
    setUrlType: (urlType: UrlType | null) => {
      set((draft) => {
        draft.project.urlType = urlType;
      });
    },
  },
});
