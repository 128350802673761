/**
 * Copyright 2023 Design Barn Inc.
 */

import clsx from 'clsx';
import React, { useCallback } from 'react';

import { TooltipWithShortcut } from '../Tooltip';

import { KeyframeIcon } from '~/assets/icons';
import { ClickSource, EventType } from '~/data/eventStore';
import { fireEvent, type EventStorePayload } from '~/utils';

interface Props {
  hasKeyframe?: boolean;
  isChannelAnimated: boolean;
  isTransparent?: boolean;
  keyframeShortcut?: string;
  onClick?: () => void;
  withTooltip?: boolean;
}

const KeyframeButton: React.FC<Props> = ({
  hasKeyframe = false,
  isChannelAnimated = false,
  isTransparent = false,
  keyframeShortcut = '',
  onClick,
  withTooltip = false,
}) => {
  const handleOnClick = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();

      const eventParams: EventStorePayload = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.AddedKeyframe,
        parameters: {
          // Conveniently, the keyframe button is transparent from timeline but not on property panel.
          // Just reusing this bool for now, but should probably rename it later
          source: isTransparent ? ClickSource.Timeline : ClickSource.PropertyPanel,
        },
      };

      fireEvent(eventParams);

      if (onClick) {
        onClick();
      }
    },
    [isTransparent, onClick],
  );

  return (
    <TooltipWithShortcut
      content={hasKeyframe ? 'Remove keyframe' : 'Add keyframe'}
      disabled={!withTooltip}
      shortcut={keyframeShortcut}
      placement="bottom-end"
      isNested
    >
      <button className="relative" onClick={handleOnClick} data-testid="keyframe-btn" tabIndex={-1}>
        <KeyframeIcon
          className={clsx('h-5 w-5', {
            'stroke-teal-400': isChannelAnimated,
            'stroke-gray-400': !isChannelAnimated && !hasKeyframe,
            'hover:stroke-white': !isChannelAnimated && !hasKeyframe,
            'fill-teal-400 hover:fill-teal-200 hover:stroke-teal-200': hasKeyframe,
            'fill-gray-800': !hasKeyframe && !isTransparent,
          })}
        />
      </button>
    </TooltipWithShortcut>
  );
};

export default KeyframeButton;
