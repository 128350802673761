/**
 * Copyright 2022 Design Barn Inc.
 */

import { Toolkit, StateHistory } from '@lottiefiles/toolkit-js';
import { DotlottiePlugin } from '@lottiefiles/toolkit-plugin-dotlottie';
import { LottiePlugin, Optimization } from '@lottiefiles/toolkit-plugin-lottie';
import { SvgPlugin } from '@lottiefiles/toolkit-plugin-svg';

const toolkit = new Toolkit({
  enableNodeIds: true,
  useFlatHierarchy: true,
  splitRenderEvent: true,
  hydratePrecompositions: true,
  immutableState: true,
  enableState: true,
});

const stateHistory = new StateHistory(toolkit.events, 100);

const lottiePlugin = new LottiePlugin({
  importConfig: {
    enableNodeIds: true,
    standardizeTimeline: true,
    defaultNames: true,
  },
  optimization: {
    effects: Optimization.REMOVE_DEFAULT,
  },
});

const dotLottiePlugin = new DotlottiePlugin({
  importConfig: {
    enableNodeIds: true,
    standardizeTimeline: true,
    defaultNames: true,
  },
  optimization: {
    effects: Optimization.REMOVE_DEFAULT,
  },
});

const svgPlugin = new SvgPlugin();

toolkit.addPlugin(lottiePlugin);
toolkit.addPlugin(svgPlugin);
toolkit.addPlugin(dotLottiePlugin);

export { toolkit, stateHistory, dotLottiePlugin, lottiePlugin, svgPlugin };
