/**
 * Copyright 2024 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import { Close } from '~/assets/icons';
import { Modal } from '~/components/Elements/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const LatestReleaseModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center  text-center">
            <Dialog.Panel className="h-[632px] w-[600px] overflow-hidden rounded-md bg-white align-middle shadow-sm transition-all">
              <Dialog.Title
                as="h3"
                className=" relative mt-7  px-6 text-left text-xl font-bold text-gray-800"
              >
                {`What's new`}
                <button className="absolute right-4">
                  <Close
                    className="h-6 w-6 cursor-pointer fill-current text-gray-500 hover:opacity-50"
                    onClick={onClose}
                  />
                </button>
              </Dialog.Title>
              <iframe className="h-[510px] w-full " src="https://releases.lottiefiles.com/creator-latest" />
              <button className="float-right m-4 flex cursor-pointer items-center rounded-lg bg-teal-400 p-2 text-sm font-bold leading-[17px] text-white hover:bg-teal-300">
                <a href="https://releases.lottiefiles.com/creator-releases" target="_blank" rel="noreferrer">
                  View changelog
                </a>
              </button>
            </Dialog.Panel>
          </div>
        </div>
      </Transition.Child>
    </Modal>
  );
};
