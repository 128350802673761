/**
 * Copyright 2024 Design Barn Inc.
 */

import type { GroupShape } from '@lottiefiles/lottie-js';
import { DagNodeType, MaskModeType, Percentage } from '@lottiefiles/toolkit-js';
import type {
  AVLayer,
  CubicBezierJSON,
  CubicBezierShape,
  Keyframe,
  Mask,
  PercentageJSON,
  PathShape,
} from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { Minus, Opacity, Plus } from '~/assets/icons';
import KeyframeButton from '~/components/Elements/Button/KeyframeButton';
import type { NumberResult } from '~/components/Elements/Input';
import { NumberInput } from '~/components/Elements/Input';
import type { Option } from '~/components/Elements/Select';
import { Select } from '~/components/Elements/Select';
import { hasMasks } from '~/features/canvas';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface Props {}

export const MaskProperty: React.FC<Props> = () => {
  const [selectedNodesInfo, getNodeByIdOnly, currentFrame] = useCreatorStore(
    (state) => [state.ui.selectedNodesInfo, state.toolkit.getNodeByIdOnly, state.toolkit.currentFrame],
    shallow,
  );
  const [currentShapeNode, setCurrentShapeNode] = useState<null | Mask>(null);
  const [nodes, setNodes] = useState<Array<AVLayer | GroupShape>>([]);
  const [currentKeyFrame, setCurrentKeyFrame] = useState('');
  const [currentOpacityKeyFrame, setCurrentOpacityKeyFrame] = useState('');
  const [isMask, setIsMask] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option>({
    label: 'Add',
    value: 'a',
  });

  const updateCurrentKeyFrame = useCallback(() => {
    setCurrentKeyFrame('');
    setCurrentOpacityKeyFrame('');
    if (currentShapeNode) {
      const currentShape = currentShapeNode as Mask;

      currentShape.shape.keyFrames.forEach((eachKeyFrame: Keyframe<CubicBezierShape, CubicBezierJSON>) => {
        if (eachKeyFrame.frame === currentFrame && !eachKeyFrame.isStatic) {
          setCurrentKeyFrame(eachKeyFrame.frameId as string);
        }
      });

      currentShape.opacity.keyFrames.forEach((eachKeyFrame: Keyframe<Percentage, PercentageJSON>) => {
        if (eachKeyFrame.frame === currentFrame && !eachKeyFrame.isStatic) {
          setCurrentOpacityKeyFrame(eachKeyFrame.frameId as string);
        }
      });
    }
  }, [currentFrame, currentShapeNode]);

  useEffect(() => {
    updateCurrentKeyFrame();
  }, [updateCurrentKeyFrame]);

  const updateMaskNodes = useCallback(() => {
    const updatedNodes = selectedNodesInfo.map((node) => {
      const tempNode = getNodeByIdOnly(node.nodeId) as AVLayer | GroupShape | PathShape;

      if ((node.nodeType === DagNodeType.MASK || node.nodeType === DagNodeType.SHAPE) && tempNode.parent) {
        return tempNode.parent;
      }

      return tempNode;
    });

    setNodes(updatedNodes);
    if (updatedNodes.length > 0) {
      const firstNode = updatedNodes[0] as AVLayer;

      setIsMask(hasMasks(firstNode));
      if (hasMasks(firstNode)) {
        const maskNode = firstNode.masks[0] as Mask;

        setSelectedOption({ label: maskNode.mode === MaskModeType.Add ? 'Add' : 'Subtract', value: maskNode.mode });
        setCurrentShapeNode(maskNode);
      }
    }
  }, [selectedNodesInfo, getNodeByIdOnly]);

  useEffect(() => {
    updateMaskNodes();
  }, [updateMaskNodes]);

  const options: Option[] = [
    { label: 'Add', value: 'a' },
    { label: 'Subtract', value: 's' },
  ];

  const onAddMask = (): void => {
    if (nodes.length > 0) {
      emitter.emit(EmitterEvent.CANVAS_MASK_CREATE);
      updateMaskNodes();
    }
  };

  const onRemoveMask = (): void => {
    if (nodes.length > 0) {
      emitter.emit(EmitterEvent.CANVAS_MASK_REMOVE);
      updateMaskNodes();
    }
  };
  const handlePathKeyframeClick = useCallback(() => {
    if (currentKeyFrame) {
      if ((currentShapeNode as Mask).shape.keyFrames.length === 1) {
        (currentShapeNode as Mask).shape.setIsAnimated(false);
      }
      removeKeyFrame(currentKeyFrame);
    } else {
      if (!(currentShapeNode as Mask).shape.isAnimated) {
        (currentShapeNode as Mask).shape.setIsAnimated(true);
      }

      (currentShapeNode as Mask).shape.setValue((currentShapeNode as Mask).shape.value);
    }
    emitter.emit(EmitterEvent.ANIMATED_SHAPE_PATH_UPDATED);
    updateCurrentKeyFrame();
  }, [currentShapeNode, updateCurrentKeyFrame, currentKeyFrame]);

  const handleOpacityKeyframeClick = useCallback((): void => {
    if (!currentShapeNode) return;
    if (!currentShapeNode.opacity.isAnimated) {
      currentShapeNode.opacity.setIsAnimated(true);
    }
    if (currentOpacityKeyFrame) {
      if ((currentShapeNode as Mask).opacity.keyFrames.length === 1) {
        (currentShapeNode as Mask).opacity.setIsAnimated(false);
      }
      removeKeyFrame(currentOpacityKeyFrame);
    } else {
      currentShapeNode.opacity.setValue(new Percentage(currentShapeNode.opacity.value.value));
    }
    emitter.emit(EmitterEvent.CANVAS_REDRAW);
    updateCurrentKeyFrame();
  }, [currentShapeNode, updateCurrentKeyFrame, currentOpacityKeyFrame]);

  const updateMaskOpacity = useCallback(
    (result: NumberResult) => {
      if (!currentShapeNode) return;

      if (currentShapeNode.opacity.isAnimated) {
        currentShapeNode.opacity.setValue(new Percentage(result.value));
      } else {
        currentShapeNode.opacity.setStaticValue(new Percentage(result.value));
      }

      emitter.emit(EmitterEvent.CANVAS_REDRAW);
    },
    [currentShapeNode],
  );

  const handleOnChangeMaskMode = useCallback(
    (option: Option) => {
      const firstNode = nodes[0] as AVLayer | null;

      if (firstNode && hasMasks(firstNode)) {
        firstNode.masks.forEach((mask) => {
          mask.setMode(option.value as MaskModeType);
        });
        setSelectedOption(option);
        emitter.emit(EmitterEvent.CANVAS_REDRAW);
      }
    },
    [nodes],
  );

  if (!isMask) {
    return (
      <div className="flex justify-between px-4 pb-3 pr-3 pt-4">
        <div className="text-xs font-bold text-gray-300">Mask</div>
        <div className="flex">
          <div className="pointer-events-auto mx-2 cursor-pointer" onClick={onAddMask}>
            <Plus className="h-4 w-4 stroke-gray-300 hover:rounded hover:bg-gray-700 hover:stroke-white" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 pb-3 pr-3 pt-4">
      <div className="flex justify-between">
        <div className="text-xs font-bold text-gray-300">Mask Path</div>
        <div className="flex">
          <div className="pointer-events-auto mx-2 cursor-pointer" onClick={onRemoveMask}>
            <Minus className="h-4 w-4 stroke-gray-300 hover:rounded hover:bg-gray-700 hover:stroke-white" />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="py-1">
          <div className="mb-1 text-xs text-gray-400">Mask Mode</div>
          <div className="flex">
            <div className="w-[130px]">
              <Select
                styleClass={{ button: 'border-0 h-[22px]' }}
                options={options}
                selected={selectedOption}
                onChange={handleOnChangeMaskMode}
              />
            </div>
          </div>
        </div>
        <div className="py-1">
          <div className="flex">
            <div className="mt-[1px] w-[100px]">
              <div className="mb-1 text-xs text-gray-400">Mask path</div>
              <div className="relative flex items-center">
                <label
                  className="group flex h-[24px] w-full items-center rounded border border-transparent bg-gray-700 p-1 px-2 text-xs font-normal"
                  data-testid="path-edit-container"
                >
                  Path
                </label>
                <div className="absolute right-[-9px] top-[2px]">
                  <KeyframeButton
                    hasKeyframe={Boolean(currentKeyFrame)}
                    onClick={handlePathKeyframeClick}
                    isChannelAnimated={currentShapeNode ? currentShapeNode.shape.isAnimated : false}
                  />
                </div>
              </div>
            </div>
            <div className="ml-[10px] mt-[1px] w-[86px]">
              <div className="mb-1 text-xs text-gray-400">Mask opacity</div>
              <NumberInput
                name="layer-opacity"
                label={<Opacity className="h-4 w-4" />}
                value={currentShapeNode ? currentShapeNode.opacity.value.value : 100}
                onChange={updateMaskOpacity}
                onKeyframeClick={handleOpacityKeyframeClick}
                hasKeyframe={Boolean(currentOpacityKeyFrame)}
                showKeyframe
                append="%"
                min={0}
                max={100}
                message={null}
                isChannelAnimated={currentShapeNode ? currentShapeNode.opacity.isAnimated : false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
