/**
 * Copyright 2022 Design Barn Inc.
 */

import React from 'react';
import { shallow } from 'zustand/shallow';

import { AlignPropertyFullWidth } from '../components/AlignProperty';
import { AppearanceProperty, SingleAppearanceProperty } from '../components/AppearanceProperty';
import { EasingProperty } from '../components/EasingProperty';
import { LayerProperty } from '../components/LayerProperty';
import { MaskProperty } from '../components/MaskProperty';
import { MatteProperty } from '../components/MatteProperty';
import { PathEditVertexTypeProperty } from '../components/PathEditVertexTypeProperty';
import { ShapeProperty } from '../components/ShapeProperty';
import { TransformProperty } from '../components/TransformProperty';
import { TrimPathProperty } from '../components/TrimPathProperty';
import { PropertyRow } from '../components/ui/PropertyRow';

import { PropertyPanel } from './PropertyPanel';

import { Divider } from '~/components/Elements/Divider';
import { hasMasks } from '~/features/canvas';
import { AppearancePropertyPanel } from '~/lib/toolkit/appearance';
import { useCreatorStore } from '~/store';
import { PropertyPanelType, ShapePathMap } from '~/store/constant';

export const ShapePanel: React.FC = () => {
  const [property, selectedPathPointIndexes] = useCreatorStore(
    (state) => [state.ui.currentPropertyPanel, state.ui.selectedPathPointIndexes],
    shallow,
  );

  const displayShapePath =
    property === PropertyPanelType.RectanglePath ||
    property === PropertyPanelType.EllipsePath ||
    property === PropertyPanelType.PolygonPath ||
    property === PropertyPanelType.StarPath ||
    property === PropertyPanelType.Path ||
    property === PropertyPanelType.EditPath;
  const isPath = property === PropertyPanelType.Path || property === PropertyPanelType.EditPath;

  return (
    <PropertyPanel>
      {(() => {
        if (property === PropertyPanelType.Mask) {
          return (
            <>
              <MaskProperty />
            </>
          );
        }
        if (property === PropertyPanelType.Precomposition) {
          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <EasingProperty enabled={true} />
              <TransformProperty property={property} />
              <Divider />
              <LayerProperty type={PropertyPanelType.Precomposition} />
              <Divider />
            </>
          );
        }

        if (property === PropertyPanelType.ShapeLayer) {
          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <Divider />
              <EasingProperty enabled={true} />
              <Divider />
              <TransformProperty property={property} />
              <Divider />
              <LayerProperty type={PropertyPanelType.ShapeLayer} />
              <Divider />
              <AppearanceProperty />
              <Divider />
              <MatteProperty />
              <Divider />
              <MaskProperty />
            </>
          );
        }

        if (property === PropertyPanelType.MultiObjects) {
          const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;
          const precompSelected = selectedNodesInfo.some(
            (nodeInfo) => nodeInfo.propertyPanel === PropertyPanelType.Precomposition,
          );

          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <Divider />
              <EasingProperty enabled={true} />
              <Divider />
              <TransformProperty property={property} />
              <Divider />
              <LayerProperty type={PropertyPanelType.ShapeLayer} />
              <Divider />
              {!precompSelected && <AppearanceProperty />}
            </>
          );
        }

        if (property === PropertyPanelType.Group) {
          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <Divider />
              <EasingProperty enabled={true} />
              <Divider />
              <TransformProperty property={property} />
              <Divider />
              <LayerProperty type={PropertyPanelType.Group} />
              <Divider />
              <AppearanceProperty />
            </>
          );
        }

        if (property === PropertyPanelType.Trim) {
          return (
            <>
              <Divider />
              <TrimPathProperty />
            </>
          );
        }

        if (AppearancePropertyPanel.includes(property)) {
          return <SingleAppearanceProperty />;
        }

        if (displayShapePath) {
          const selectedNodeIds = useCreatorStore.getState().ui.selectedNodesInfo.map((nodeInfo) => nodeInfo.nodeId);
          const parentLayer = useCreatorStore.getState().toolkit.getNodeByIdOnly(selectedNodeIds[0] as string)?.parent;

          const showMaskProperty = selectedNodeIds.length === 1 && hasMasks(parentLayer);

          return (
            <>
              <AlignPropertyFullWidth enabled={true} />
              <Divider />
              <EasingProperty enabled={true} />
              <Divider />
              <ShapeProperty property={ShapePathMap[property]} type={property} />
              {property === PropertyPanelType.EditPath && selectedPathPointIndexes.length > 0 && (
                <>
                  <Divider />
                  <PropertyRow title="Vertex type">
                    <PathEditVertexTypeProperty />
                  </PropertyRow>
                </>
              )}
              <Divider />
              <AppearanceProperty />
              {isPath && (
                <>
                  <Divider />
                  <TrimPathProperty />
                </>
              )}
              {showMaskProperty && (
                <>
                  <Divider />
                  <MaskProperty />
                </>
              )}
            </>
          );
        }

        return (
          <>
            <AlignPropertyFullWidth enabled={false} />
            <TransformProperty property={property} />
            <Divider />
            <ShapeProperty property={property} type={property} />
            <Divider />
            <AppearanceProperty />
          </>
        );
      })()}
    </PropertyPanel>
  );
};
