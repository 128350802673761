/**
 * Copyright 2021 Design Barn Inc.
 */

import React, { useEffect } from 'react';
// TODO: Remove this import and wrap it in a react component for readability, and then import where needed
import '@dotlottie/player-component';
import { shallow } from 'zustand/shallow';

import { LibraryType } from './data/constant';
import { useURLQuery } from './path';
import { AppProvider } from './providers/app';
import { UrlType } from './store/projectSlice';

import { Creator } from '~/components/Layout';
import { useCreatorStore } from '~/store';
import { LocalStorageKey } from '~/store/constant';

const setFetchExistingFile = useCreatorStore.getState().project.setFetchExistingFile;
const setLoader = useCreatorStore.getState().ui.setLoader;
const setUrlType = useCreatorStore.getState().project.setUrlType;
const setCurrentLibrary = useCreatorStore.getState().ui.setCurrentLibrary;

const App: React.FC = () => {
  const query = useURLQuery();

  // Naming this as "demoId" for url vanity purposes
  // We can reuse this for loading any exported lottie/json in the future, which is why everywhere else it is referred to as exportedId
  const exportedId = query.get('demoId');
  const fileId = query.get('fileId');
  const remixId = query.get('remixId');

  const [isAuthorized, isUserFetched] = useCreatorStore(
    (state) => [state.user.isAuthorized, state.user.isUserFetched],
    shallow,
  );

  useEffect(() => {
    if (isAuthorized && fileId) {
      setLoader({
        isLoading: true,
        title: 'Loading Creator...',
      });
      setUrlType(UrlType.Existing);
      setFetchExistingFile({ status: true, fileId, isExportedFile: false });
    }

    if (isAuthorized && exportedId) {
      setLoader({
        isLoading: true,
        title: 'Loading Creator...',
      });
      setFetchExistingFile({ status: true, fileId: exportedId, isExportedFile: true });
    }

    if (isUserFetched && remixId) {
      setLoader({
        isLoading: true,
        title: 'Loading Creator...',
      });
      setFetchExistingFile({ status: true, fileId: null, remixId, isExportedFile: false });
    }

    if (!exportedId) {
      const autoOpenOnSideBar = window.localStorage.getItem(LocalStorageKey.AutoOpenOnSideBar);
      const sideBarOpen = autoOpenOnSideBar === null || autoOpenOnSideBar === 'true';

      setCurrentLibrary(sideBarOpen ? LibraryType.GettingStarted : null);
    }
  }, [isAuthorized, fileId, exportedId, remixId, isUserFetched]);

  return (
    <AppProvider>
      <Creator />
    </AppProvider>
  );
};

export default App;
