/**
 * Copyright 2022 Design Barn Inc.
 */

import type { SceneJSON, Toolkit, PrecompositionAsset, PrecompositionLayer } from '@lottiefiles/toolkit-js';
import { Scene, Size } from '@lottiefiles/toolkit-js';
import { clamp } from 'three/src/math/MathUtils';

import { updatePrecompLayerCurrentFrame } from '../canvas';

import { stateHistory } from './toolkit';
import type { Scalar2D } from './types';

import packageJson from '~/../package.json';
import { useCreatorStore } from '~/store';

export interface SceneOption {
  endFrame: number;
  frameRate: number;
  height: number;
  name?: string;
  startFrame: number;
  width: number;
}

export const createScene = (toolkit: Toolkit, option: SceneOption): Scene => {
  stateHistory.beginAction();
  const scene = toolkit.createScene({ name: option.name ?? 'Scene 1', enableNodeIds: true });

  scene.setWidth(option.width);
  scene.setHeight(option.height);
  scene.timeline.setStartAndEndFrame(option.startFrame, option.endFrame);
  scene.timeline.setFrameRate(option.frameRate);
  scene.meta.setGenerator(`@lottiefiles/creator ${packageJson.version}`);
  stateHistory.endAction();

  return scene;
};

export const getToolkitState = (toolkit: Toolkit, sceneIndex = 0): SceneJSON | undefined =>
  toolkit.state.scenes[sceneIndex];

export const setSceneHeight = (scene: Scene, height: number): void => {
  scene.setHeight(height);
};

export const setToolkitCurrentFrame = (toolkit: Toolkit, frame: number): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
  const scene = toolkit.scenes[sceneIndex];

  if (scene) {
    const endFrame = scene.timeline.endFrame as number;

    const clampedFrame = clamp(frame, 0, endFrame - 1);

    if (scene.timeline.currentFrame !== clampedFrame) {
      scene.timeline.setCurrentFrame(clampedFrame);
    }

    updatePrecompLayerCurrentFrame(frame);
  }
};

export const setSceneWidth = (scene: Scene, width: number): void => {
  scene.setWidth(width);
};

export const setPrecompReferenceLayersSize = (
  precompAsset: PrecompositionAsset,
  width: number,
  height: number,
): void => {
  precompAsset.referencedLayers.forEach((layer: PrecompositionLayer) => {
    layer.setWidth(width);
    layer.setHeight(height);
  });
};

export const setPrecompHeight = (precompAsset: PrecompositionAsset, height: number): void => {
  const canvasMinHeight = (precompAsset.getData('canvasMinHeight') as number | null) ?? 0;

  const adjustedHeight = (height - canvasMinHeight) as number;

  precompAsset.setData('canvasHeight', adjustedHeight);
  setPrecompReferenceLayersSize(precompAsset, precompAsset.getData('canvasWidth') as number, adjustedHeight);
};

export const setPrecompWidth = (precompAsset: PrecompositionAsset, width: number): void => {
  const canvasMinWidth = (precompAsset.getData('canvasMinWidth') as number | null) ?? 0;

  const adjustedWidth = (width - canvasMinWidth) as number;

  precompAsset.setData('canvasWidth', adjustedWidth);
  setPrecompReferenceLayersSize(precompAsset, adjustedWidth, precompAsset.getData('canvasHeight') as number);
};

export const setPrecompSize = (precompAsset: PrecompositionAsset, size: Scalar2D): void => {
  const canvasMinWidth = (precompAsset.getData('canvasMinWidth') as number | null) ?? 0;
  const canvasMinHeight = (precompAsset.getData('canvasMinHeight') as number | null) ?? 0;

  const width = (size[0] - canvasMinWidth) as number;
  const height = (size[1] - canvasMinHeight) as number;

  precompAsset.setData('canvasWidth', width);
  precompAsset.setData('canvasHeight', height);
  setPrecompReferenceLayersSize(precompAsset, width, height);
};

export const setSceneSize = (scene: Scene, size: Scalar2D): void => {
  scene.setSize(new Size(size[0], size[1]));
};

export const removeAllScenes = (toolkit: Toolkit): void => {
  while (toolkit.scenes.length > 0 && toolkit.scenes[0]) {
    toolkit.removeScene(toolkit.scenes[0]);
  }
};

export const getActiveScene = (toolkit: Toolkit): Scene | PrecompositionAsset | null => {
  let scene: Scene | PrecompositionAsset | null = null;

  const state = useCreatorStore.getState();
  const selectedPrecompositionId = state.toolkit.selectedPrecompositionId;

  if (selectedPrecompositionId) {
    scene = state.toolkit.getNodeByIdOnly(selectedPrecompositionId) as PrecompositionAsset;
  } else {
    scene = toolkit.scenes[state.toolkit.sceneIndex] as Scene;
  }

  return scene;
};

export const getSceneSize = (scene: Scene | PrecompositionAsset): Size | null => {
  if (scene instanceof Scene) {
    return (scene as Scene).size;
  }

  if (!scene.getData('canvasWidth') || !scene.getData('canvasHeight')) {
    return null;
  }

  return new Size(
    (scene.getData('canvasWidth') as number) + (scene.getData('canvasMinWidth') as number),
    (scene.getData('canvasHeight') as number) + (scene.getData('canvasMinHeight') as number),
  );
};
