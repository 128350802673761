/**
 * Copyright 2022 Design Barn Inc.
 */

import type { Layer } from '@lottiefiles/toolkit-js';

import type { Plugin } from '../../Plugin';
import type { ObjectMethods } from '../vmInterface/wrapper';
import { getObjectMethods } from '../vmInterface/wrapper';

const methodNames = {
  allTracks: 'allTracks',
  name: 'name',
  setName: 'setName',
  allLayers: 'allLayers',
  classNames: 'classNames',
  drawOrder: 'drawOrder',
  startFrame: 'startFrame',
  endFrame: 'endFrame',
  addClassName: 'addClassName',
  endTime: 'endTime',
  parentLayer: 'parentLayer',
  startTime: 'startTime',
  timelineOffset: 'timelineOffset',
  type: 'type',
  refreshDrawOrder: 'refreshDrawOrder',
  removeClassName: 'removeClassName',
  setDrawOrder: 'setDrawOrder',
  setDrawOrderWithChildren: 'setDrawOrderWithChildren',
  setEndFrame: 'setEndFrame',
  setEndTime: 'setEndTime',
  setIsGuide: 'setIsGuide',
  setIsHidden: 'setIsHidden',
  setIsHiddenWithChildren: 'setIsHiddenWithChildren',
  setParentLayer: 'setParentLayer',
  isHidden: 'isHidden',
  isGuide: 'isGuide',
  setTimelineOffset: 'setTimelineOffset',
  setStartFrame: 'setStartFrame',
  setStartTime: 'setStartTime',
  setStartAndEndFrame: 'setStartAndEndFrame',
};

export function getLayerMethods(plugin: Plugin, layer: Layer): ObjectMethods {
  return getObjectMethods(plugin, methodNames, layer);
}
