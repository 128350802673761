/**
 * Copyright 2022 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import type { SaveContextType } from './SaveContext';
import { SaveContext } from './SaveContext';
import { SaveEditName } from './SaveEditName';
import { SaveFooter } from './SaveFooter';
import { SavePanel } from './SavePanel';

import { Close } from '~/assets/icons';
import { Divider } from '~/components/Elements/Divider';
import { Modal } from '~/components/Elements/Modal';
import { DEFAULT_FILE_NAME } from '~/data/constant';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  options?: Record<string, string | boolean> | undefined;
}

export const ProjectSaveModal: React.FC<Props> = ({ isOpen, onClose, options }) => {
  const [uiMap, setUIMap] = useImmer({});
  const [rowMap, setRowMap] = useImmer({});
  const [selected, setSelected] = useState({});
  const [fileName, setFileName] = useState(DEFAULT_FILE_NAME);

  const [error, setError] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<null | JSX.Element>(null);

  // Clear error state when modal is opened
  useEffect(() => {
    if (isOpen) {
      setError(false);
      setModalContent(
        <>
          <SavePanel />
          <Divider />
          <SaveFooter />
        </>,
      );
    }
  }, [isOpen]);

  const ctx = {
    isOpen,
    onClose,
    error,
    setError,
    options,
    uiMap,
    setUIMap,
    selected,
    setSelected,
    setRowMap,
    rowMap,
    fileName,
    setFileName,
  };

  return (
    <>
      <SaveContext.Provider value={ctx as SaveContextType}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-6 text-center">
                <Dialog.Panel className="relative w-[340px] overflow-hidden rounded-md bg-gray-800 align-middle shadow-sm transition-all">
                  <Dialog.Title as="h3" className="relative h-full px-4 py-3 text-left text-xs font-bold text-white">
                    <div>Make a copy</div>
                    <SaveEditName />
                  </Dialog.Title>
                  <Divider />
                  {modalContent}

                  <div className="absolute right-4 top-4">
                    <button>
                      <Close
                        className="h-4 w-4 cursor-pointer fill-current text-gray-400 hover:opacity-50"
                        onClick={onClose}
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </Modal>
      </SaveContext.Provider>
    </>
  );
};
