/**
 * Copyright 2023 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/lottie-js';
import type { AnimatedProperty } from '@lottiefiles/toolkit-js';
import { isArray } from 'lodash-es';

import { emitter, EmitterEvent } from '~/lib/emitter';
import type { CurrentFillShape, CurrentStrokeShape, CurrentTransformProperty, Scalar2D } from '~/lib/toolkit';
import { stateHistory, AnimatedType, removeKeyFrame } from '~/lib/toolkit';
import { getNodeById } from '~/plugins/shim/utils';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';
import type { SelectedNodeInfo } from '~/store/uiSlice';

const setStaticValue = useCreatorStore.getState().toolkit.setStaticValue;
const addAnimatedValue = useCreatorStore.getState().toolkit.addAnimatedValue;

const getKeyframeType = (animatedType: AnimatedType): string => {
  switch (animatedType) {
    case AnimatedType.POSITION:
      return 'positionCurrentKeyframe';

    case AnimatedType.OPACITY:
      return 'opacityCurrentKeyframe';

    case AnimatedType.ROTATION:
      return 'rotationCurrentKeyframe';

    case AnimatedType.SCALE:
      return 'scaleCurrentKeyframe';

    case AnimatedType.GRADIENT_OPACITY:
      return 'opacityCurrentKeyframe';

    default:
      return '';
  }
};

const getValueType = (animatedType: AnimatedType): string => {
  if (animatedType === AnimatedType.POSITION) {
    return 'position';
  } else if (animatedType === AnimatedType.OPACITY) {
    return 'opacity';
  } else if (animatedType === AnimatedType.ROTATION) {
    return 'rotation';
  } else if (animatedType === AnimatedType.SCALE) {
    return 'scale';
  } else if (animatedType === AnimatedType.GRADIENT_OPACITY) {
    return 'opacity';
  } else {
    return '';
  }
};

export const togglePropPanelKeyframe = (
  isMultiselect: boolean,
  currentTransforms: CurrentTransformProperty[],
  currentKeyframe: string,
  value: Scalar2D | number,
  animatedType: AnimatedType,
): void => {
  if (isMultiselect) {
    const keyframeType = getKeyframeType(animatedType) as keyof CurrentTransformProperty;
    const valueType = getValueType(animatedType) as keyof CurrentTransformProperty;

    const allKeyframesActive = currentTransforms.every((transform) => transform[keyframeType]);
    const allKeyframesInactive = currentTransforms.every((transform) => !transform[keyframeType]);

    if (allKeyframesActive) {
      // remove all keyframes from all layers
      currentTransforms.forEach((transform) => {
        removeKeyFrame(transform[keyframeType] as string);
        setStaticValue(
          animatedType,
          // the value is Scalar2D | number, but setStaticValue expects an array
          isArray(transform[valueType]) ? transform[valueType] : [transform[valueType]],
          transform.nodeId,
        );
      });
    } else if (allKeyframesInactive) {
      // add keyframes to all layers
      currentTransforms.forEach((transform) => {
        addAnimatedValue(
          animatedType,
          isArray(transform[valueType]) ? transform[valueType] : [transform[valueType]],
          transform.nodeId,
        );
      });
    } else {
      // add keyframes to layers that don't have them
      currentTransforms.forEach((transform) => {
        if (!transform[keyframeType]) {
          addAnimatedValue(
            animatedType,
            isArray(transform[valueType]) ? transform[valueType] : [transform[valueType]],
            transform.nodeId,
          );
        }
      });
    }

    return;
  }

  if (currentKeyframe) {
    removeKeyFrame(currentKeyframe);
    setStaticValue(animatedType, isArray(value) ? value : [value]);
  } else {
    addAnimatedValue(animatedType, isArray(value) ? value : [value]);
  }
};

const animatedTypeToEmitterEventMap = {
  [AnimatedType.POSITION]: EmitterEvent.ANIMATED_POSITION_UPDATED,
  [AnimatedType.ROTATION]: EmitterEvent.ANIMATED_ROTATION_UPDATED,
  [AnimatedType.SCALE]: EmitterEvent.ANIMATED_SCALE_UPDATED,
  [AnimatedType.OPACITY]: EmitterEvent.ANIMATED_OPACITY_UPDATED,
};

export const handleOpacityType = (
  selectedNodesInfo: SelectedNodeInfo[],
  currentTransforms: CurrentTransformProperty[],
  currentTransform: CurrentTransformProperty,
  propType:
    | PropertyPanelType.Fill
    | PropertyPanelType.Group
    | PropertyPanelType.ShapeLayer
    | PropertyPanelType.Stroke
    | PropertyPanelType.Precomposition,
): [number, string, boolean] => {
  const getShape = useCreatorStore.getState().toolkit.getShape;

  if (propType === PropertyPanelType.Stroke) {
    const strokeShape = getShape(ShapeType.STROKE) as CurrentStrokeShape;

    return [strokeShape.o, strokeShape.opacityCurrentKeyframe, strokeShape.opacityIsAnimated];
  }

  if (propType === PropertyPanelType.Fill) {
    const fillShape = getShape(ShapeType.FILL) as CurrentFillShape;

    return [fillShape.opacity, fillShape.opacityCurrentKeyframe, fillShape.opacityIsAnimated];
  }

  if (propType === PropertyPanelType.ShapeLayer || propType === PropertyPanelType.Precomposition) {
    const multiselect = selectedNodesInfo.length > 1;

    if (multiselect) {
      return [
        currentTransforms[0]?.opacity as number,
        currentTransforms[0]?.opacityCurrentKeyframe as string,
        Boolean(currentTransforms[0]?.opacityIsAnimated),
      ];
    }

    return [currentTransform.opacity, currentTransform.opacityCurrentKeyframe, currentTransform.opacityIsAnimated];
  }

  return [currentTransform.opacity, currentTransform.opacityCurrentKeyframe, currentTransform.opacityIsAnimated];
};

export const toggleKeyframe = (animatedType: AnimatedType): void => {
  const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;
  const multiSelect = selectedNodesInfo.length > 1;
  const currentTransforms = useCreatorStore.getState().toolkit.currentTransforms;
  const currentTransform = useCreatorStore.getState().toolkit.currentTransform;

  stateHistory.beginAction();

  if (animatedType === AnimatedType.POSITION) {
    const currentKeyframe = currentTransform.positionCurrentKeyframe;
    const x = currentTransform.position[0];
    const y = currentTransform.position[1];

    togglePropPanelKeyframe(multiSelect, currentTransforms, currentKeyframe, [x, y], AnimatedType.POSITION);
  } else if (animatedType === AnimatedType.ROTATION) {
    const currentKeyframe = currentTransform.rotationCurrentKeyframe;

    const rotation = currentTransform.rotation;

    togglePropPanelKeyframe(multiSelect, currentTransforms, currentKeyframe, rotation, AnimatedType.ROTATION);
  } else if (animatedType === AnimatedType.SCALE) {
    const currentKeyframe = currentTransform.scaleCurrentKeyframe;
    const scaleX = currentTransform.scale[0];
    const scaleY = currentTransform.scale[1];

    togglePropPanelKeyframe(multiSelect, currentTransforms, currentKeyframe, [scaleX, scaleY], AnimatedType.SCALE);
  } else if (animatedType === AnimatedType.OPACITY) {
    let type = useCreatorStore.getState().ui.currentPropertyPanel as
      | PropertyPanelType.Group
      | PropertyPanelType.Fill
      | PropertyPanelType.Stroke
      | PropertyPanelType.ShapeLayer
      | PropertyPanelType.Precomposition
      | PropertyPanelType.MultiObjects;

    type = type === PropertyPanelType.MultiObjects ? PropertyPanelType.ShapeLayer : type;

    const [opacity, opacityCurrentKeyframe] = handleOpacityType(
      selectedNodesInfo,
      currentTransforms,
      currentTransform,
      type,
    );

    togglePropPanelKeyframe(multiSelect, currentTransforms, opacityCurrentKeyframe, [opacity], AnimatedType.OPACITY);
  }

  emitter.emit(animatedTypeToEmitterEventMap[animatedType as keyof typeof animatedTypeToEmitterEventMap]);

  stateHistory.endAction();
};

export const getMixedValue = (
  multiselect: boolean,
  currentTransforms: CurrentTransformProperty[],
  animatedType: AnimatedType,
): string | null => {
  if (!multiselect) {
    return null;
  }

  if (animatedType === AnimatedType.ROTATION) {
    const firstRotation = (currentTransforms[0]?.rotation as number) % 360;
    const equalRotation = currentTransforms.every(
      (transform) => (transform.rotation % 360).toFixed(2) === firstRotation.toFixed(2),
    );

    return equalRotation ? null : 'Mixed';
  } else if (animatedType === AnimatedType.OPACITY) {
    const equalOpacity = currentTransforms.every(
      (transform) => transform.opacity.toFixed(2) === currentTransforms[0]?.opacity.toFixed(2),
    );

    return equalOpacity ? null : 'Mixed';
  }

  return null;
};

export const getMixedRotationCount = (
  multiselect: boolean,
  currentTransforms: CurrentTransformProperty[],
): string | null => {
  if (!multiselect) {
    return null;
  }

  const firstRotationCount = Math.trunc((currentTransforms[0]?.rotation as number) / 360);
  const equalCount = currentTransforms.every((transform) => {
    return Math.trunc(transform.rotation / 360) === firstRotationCount;
  });

  return equalCount ? firstRotationCount.toString() : 'Mixed';
};

export const getDualMixedValue = (
  multiselect: boolean,
  currentTransforms: CurrentTransformProperty[],
  animatedType: AnimatedType,
): { left: string | null; right: string | null } => {
  if (!multiselect) {
    return {
      left: null,
      right: null,
    };
  }

  if (animatedType === AnimatedType.POSITION) {
    const equalX = currentTransforms.every(
      (transform) => transform.position[0].toFixed(2) === currentTransforms[0]?.position[0].toFixed(2),
    );
    const equalY = currentTransforms.every(
      (transform) => transform.position[1].toFixed(2) === currentTransforms[0]?.position[1].toFixed(2),
    );

    return {
      left: equalX ? null : 'Mixed',
      right: equalY ? null : 'Mixed',
    };
  } else if (animatedType === AnimatedType.SCALE) {
    const equalX = currentTransforms.every(
      (transform) => transform.scale[0].toFixed(2) === currentTransforms[0]?.scale[0].toFixed(2),
    );
    const equalY = currentTransforms.every(
      (transform) => transform.scale[1].toFixed(2) === currentTransforms[0]?.scale[1].toFixed(2),
    );

    return {
      left: equalX ? null : 'Mixed',
      right: equalY ? null : 'Mixed',
    };
  }

  return {
    left: null,
    right: null,
  };
};

export const getCurrentKeyFrame = (animated: AnimatedProperty | null, shapeNode: unknown): string => {
  const currentFrame = shapeNode?.scene?.timeline?.currentFrame;

  const currentKeyFrame = animated?.keyFrames.find((item) => item.frame === currentFrame);

  return currentKeyFrame?.frameId || '';
};

export const getIsAtKeyFrame = (animated: AnimatedProperty | null): boolean => {
  const node = animated ? getNodeById(animated.id) : null;

  return node ? node.state?.isAtKeyFrame : false;
};
