/**
 * Copyright 2022 Design Barn Inc.
 */

import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import React, { useEffect } from 'react';
import Draggable from 'react-draggable';
import { shallow } from 'zustand/shallow';

import { PluginUIHeader } from './PluginUIHeader';

import LoadingAnimation from '~/assets/animations/loading-lf.json';
import { PluginFailed } from '~/assets/icons';
import { WindowHeaderSlot, Window, FloatingWindow } from '~/components/Elements/Window';
import { getPluginManager } from '~/plugins';
import { useCreatorStore } from '~/store';

interface Props {
  currentPluginId: string;
  isDevPlugin: boolean;
}

const FloatingPluginUI: React.FC<Props> = React.memo(({ currentPluginId, isDevPlugin }) => {
  const nodeRef = React.useRef(null);

  const [loadedPlugins, isUIMinimized] = useCreatorStore(
    (state) => [state.plugins.loadedPlugins, state.ui.isUIMinimized],
    shallow,
  );

  const currentPluginInfo = loadedPlugins.find((plugin) => plugin.id === currentPluginId);
  const isLoading = currentPluginInfo?.loading;
  const isFailed = currentPluginInfo?.failed;

  const devPlugin = useCreatorStore((state) => state.plugins.devPlugin);

  useEffect(() => {
    if (currentPluginId && !isLoading) {
      const plugin = getPluginManager().getPlugin(currentPluginId);

      setTimeout(() => {
        plugin?.dispatchMessage({ action: 'showSidebar' });
      }, 0);
    }
  }, [isLoading, currentPluginId]);

  if (!currentPluginId) {
    return null;
  }

  let name = '';
  let id = '';
  let width = 340;
  let height = 680;

  const plugin = getPluginManager().getPlugin(currentPluginId);

  if (!plugin && !devPlugin) {
    return null;
  }

  if (isDevPlugin && devPlugin) {
    name = devPlugin.manifest.name;
    id = devPlugin.manifest.id;
    width = devPlugin.manifest.width ?? width;
    height = devPlugin.manifest.height ?? height;
  } else if (plugin) {
    name = plugin.manifest.name;
    id = plugin.id();
    width = plugin.manifest.width ?? width;
    height = plugin.manifest.height ?? height;
  }

  return (
    <FloatingWindow>
      {({ windowId, windowProps }) => (
        <Draggable handle=".drag-handle" nodeRef={nodeRef}>
          <Window
            {...windowProps}
            // 40px is the height of the header
            // 2px is the border width
            style={{ ...windowProps.style, width: width + 2, height: height + 42 }}
            nodeRef={nodeRef}
            className={isUIMinimized ? 'hidden' : ''}
          >
            <WindowHeaderSlot>
              {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
              <PluginUIHeader id={currentPluginId} isDevPlugin={isDevPlugin} title={name} className="drag-handle" />
            </WindowHeaderSlot>
            {isLoading && !isFailed && (
              <div className="flex h-[calc(100%-56px)] grow flex-col justify-center">
                <LottiePlayer renderer="svg" autoplay loop className="h-20 w-20" src={LoadingAnimation} />
              </div>
            )}
            {isFailed && !isLoading && (
              <div className="flex grow flex-col justify-start">
                <div className="mx-auto w-[124px] pt-10">
                  <PluginFailed className="w-[115px]" />
                </div>
                <div className="mx-auto w-[185px] ">
                  <div className="mt-4 text-center text-[10px] font-bold text-gray-300">
                    Couldn{"'"}t load {name}
                  </div>
                  <div className="mt-2 text-center text-[10px] text-gray-300">
                    Please try again, or contact our{' '}
                    <a
                      target="_blank"
                      href="https://help.lottiefiles.com/hc/en-us/requests/new"
                      className="text-teal-500"
                      rel="noreferrer"
                    >
                      customer support team.
                    </a>
                  </div>
                </div>
              </div>
            )}
            {/* https://github.com/react-grid-layout/react-draggable/issues/582 */}
            <div className="draggable-iframe-cover absolute inset-0 hidden" />
            <div id={`floating-plugin-content-${id}`} data-window-id={windowId}></div>
          </Window>
        </Draggable>
      )}
    </FloatingWindow>
  );
});

export const FloatingPlugins: React.FC = () => {
  const [floatingPluginIds, devPlugin] = useCreatorStore(
    (state) => [state.ui.floatingPluginIds, state.plugins.devPlugin],
    shallow,
  );
  const devPluginId = devPlugin?.manifest.id;

  return (
    <>
      {[...floatingPluginIds, ...(devPluginId ? [devPluginId] : [])].map((id) => (
        <FloatingPluginUI key={id} currentPluginId={id} isDevPlugin={id === devPluginId} />
      ))}
    </>
  );
};
