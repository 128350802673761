/**
 * Copyright 2023 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import React from 'react';
import { shallow } from 'zustand/shallow';

import { NestedSceneLayerMenuList } from '../constant';

import type { Coordinate } from './ContextMenuWrapper';
import { ContextMenuWrapper } from './ContextMenuWrapper';

import { GB_CONSTANT } from '~/config/growthbook';
import { useCreatorStore } from '~/store';

interface NestedSceneCanvasMenuProps {
  coord: Coordinate;
  onClose: () => void;
}

export const NestedSceneCanvasMenu: React.FC<NestedSceneCanvasMenuProps> = ({ coord, onClose }) => {
  const [selectedNodesInfo] = useCreatorStore((state) => [state.ui.selectedNodesInfo], shallow);

  const showBreakSceneMenuItem = useFeatureIsOn(GB_CONSTANT.SHOW_CREATE_BREAK_SCENE);
  const selectedNodeId = selectedNodesInfo[0]?.nodeId as string;

  const renameMenu = NestedSceneLayerMenuList.find((item) => item.type === 'RenameItem');
  const animatedPropertiesMenu = NestedSceneLayerMenuList.find((item) => item.type === 'AnimatedProperties');
  const editSceneMenu = NestedSceneLayerMenuList.find((item) => item.type === 'EditScene');

  if (renameMenu) renameMenu.disabled = selectedNodesInfo.length > 1;
  if (animatedPropertiesMenu) animatedPropertiesMenu.disabled = selectedNodesInfo.length > 1;
  if (editSceneMenu) editSceneMenu.disabled = selectedNodesInfo.length > 1;

  if (!showBreakSceneMenuItem) {
    const breakMenu = NestedSceneLayerMenuList.find((item) => item.type === 'BreakScene');

    if (breakMenu) {
      breakMenu.isHidden = true;
    }
  }

  return (
    <ContextMenuWrapper
      coord={coord}
      onClose={onClose}
      dropdownItems={NestedSceneLayerMenuList}
      eventArg={{ id: selectedNodeId }}
    ></ContextMenuWrapper>
  );
};
