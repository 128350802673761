/**
 * Copyright 2024 Design Barn Inc.
 */

import { cubicBezierIntTest, getValueFromTexture } from '../bezier/bezier';

export const compoundFillFragment = `

// referenced from https://www.shadertoy.com/view/7ltBzj

varying vec2 vCoord;
uniform sampler2D uBezierPoints;
uniform sampler2D uBezierSegmentCounts;
uniform vec3 uColor;
uniform int uBezierCount;
uniform int uTotalSegments;
uniform float uOpacity;

// https://lottiefiles.github.io/lottie-docs/constants/#fill-rule
// 1: OR, 2: AND
uniform int uFillRule;

uniform sampler2D uMaskBezierPoints;
uniform int uMaskBezierCount;
uniform sampler2D uMatteBezierPoints;
uniform int uMatteBezierCount;
uniform float uMaskOpacity;
uniform int uMatteMode;
/*
  0: normal
  1: alpha
  2: inverted alpha
  3: luma
  4: inverted luma
 */
uniform int uMaskMode;
uniform mat4 uMatteTransform;

${getValueFromTexture}
${cubicBezierIntTest}

void main() {
  int s0 = 0;
  int bezierTotalCount = 0;
  int upIntersects = 0;
  int downIntersects = 0;
  int intersects = 0;
  for (int i = 0; i < uBezierCount; i++) {
    int bezierSegmentCount = int(getValueFromTexture(uBezierSegmentCounts, vec2(uBezierCount, 1), vec2(i, 0)).r);
    if (bezierSegmentCount == 0) break;
    for (int j = 0; j < bezierSegmentCount; j++) {
      vec3 P0 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 0)).rgb;
      vec3 P1 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 1)).rgb;
      vec3 P2 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 2)).rgb;
      vec3 P3 = getValueFromTexture(uBezierPoints, vec2(uTotalSegments, 4), vec2(bezierTotalCount + j, 3)).rgb;
      if (uFillRule == 2) {
        intersects += cubic_bezier_int_test_even_odd(P0, P1, P2, P3, vCoord);
      } else if (uFillRule == 1) {
        vec2 res = cubic_bezier_int_test_none_zero(P0, P1, P2, P3, vCoord);
        upIntersects += int(res.x);
        downIntersects += int(res.y);
      }
    }
    bezierTotalCount += bezierSegmentCount;
  }

  int mask0 = 0;
  int matte0 = 0;

  for (int i = 0; i < uMaskBezierCount; i++) {
    vec3 maskP0 = getValueFromTexture(uMaskBezierPoints, vec2(uMaskBezierCount, 4), vec2(i, 0)).rgb;
    vec3 maskP1 = getValueFromTexture(uMaskBezierPoints, vec2(uMaskBezierCount, 4), vec2(i, 1)).rgb;
    vec3 maskP2 = getValueFromTexture(uMaskBezierPoints, vec2(uMaskBezierCount, 4), vec2(i, 2)).rgb;
    vec3 maskP3 = getValueFromTexture(uMaskBezierPoints, vec2(uMaskBezierCount, 4), vec2(i, 3)).rgb;
    mask0 += cubic_bezier_int_test_even_odd(maskP0, maskP1, maskP2, maskP3, vCoord);
  }

  for (int i = 0; i < uMatteBezierCount; i++) {
    vec3 matteP0 = (uMatteTransform * getValueFromTexture(uMatteBezierPoints, vec2(uMatteBezierCount, 4), vec2(i, 0))).rgb;
    vec3 matteP1 = (uMatteTransform * getValueFromTexture(uMatteBezierPoints, vec2(uMatteBezierCount, 4), vec2(i, 1))).rgb;
    vec3 matteP2 = (uMatteTransform * getValueFromTexture(uMatteBezierPoints, vec2(uMatteBezierCount, 4), vec2(i, 2))).rgb;
    vec3 matteP3 = (uMatteTransform * getValueFromTexture(uMatteBezierPoints, vec2(uMatteBezierCount, 4), vec2(i, 3))).rgb;
    matte0 += cubic_bezier_int_test_even_odd(matteP0, matteP1, matteP2, matteP3, vCoord);
  }

  float finalOpacity = uOpacity * uMaskOpacity;

  if (uFillRule == 2 && intersects % 2 == 1 || uFillRule == 1 && downIntersects != upIntersects) {
    if (uMaskBezierCount == 0 && uMatteBezierCount == 0) {
      gl_FragColor = vec4(uColor, finalOpacity);
    } else {
      bool maskApplied = mask0 % 2 == 1 && (uMaskMode == 1 || uMaskMode == 4);
      if (mask0 % 2 == 0 && uMaskMode == 2) maskApplied = true;
      bool matteApplied = matte0 % 2 == 1 && uMatteMode == 1;
      if (matte0 % 2 == 0 && (uMatteMode == 2 || uMatteMode == 4)) matteApplied = true;
      if (matte0 % 2 == 1 && uMatteMode == 3) {
        float luminance = dot(uColor.rgb, vec3(0.2126, 0.7152, 0.0722)); // Calculate luminance
        float minLuminance = 0.0; // Minimum luminance
        float maxLuminance = 1.0; // Maximum luminance
        float finalOpacity = clamp((luminance - minLuminance) / (maxLuminance - minLuminance), 0.0, 1.0); // Map luminance to opacity in range [0, 1]
        matteApplied = true;
      }
      if (uMaskBezierCount == 0) {
        if (matteApplied) gl_FragColor = vec4(uColor, finalOpacity);
        else gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
      } else if (uMatteBezierCount == 0) {
        if (maskApplied) gl_FragColor = vec4(uColor, finalOpacity);
        else gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
      } else {
        if (maskApplied && matteApplied) gl_FragColor = vec4(uColor, finalOpacity);
        else gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
      }
    }
  } else gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
}

`;
