/**
 * Copyright 2024 Design Barn Inc.
 */

import { LayerType } from '@lottiefiles/toolkit-js';
import type React from 'react';
import { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import type { CreatorLayerItem } from '~/features/timeline';
import { CreatorLayerType } from '~/features/timeline';
import { useEventListener } from '~/hooks';
import { layerMap } from '~/lib/layer';
import { useCreatorStore } from '~/store';

interface TimeLineNavigationProps {
  items: CreatorLayerItem[];
}

export const TimeLineNavigation: React.FC<TimeLineNavigationProps> = ({ items }) => {
  const [selectedNodesInfo, addToSelectedNodes, isTimelineFocused, expandedLayerIds, setExpandedLayerIds] =
    useCreatorStore(
      (state) => [
        state.ui.selectedNodesInfo,
        state.ui.addToSelectedNodes,
        state.ui.isTimelineFocused,
        state.timeline.expandedLayerIds,
        state.timeline.setExpandedLayerIds,
      ],
      shallow,
    );

  const selectableItems = useMemo(
    () => items.filter((item) => item.creatorType !== CreatorLayerType.ANIMATED_PROPERTY),
    [items],
  );

  const moveUp = useCallback(
    (index: number): void => {
      if (index > 0) {
        const prevNode = selectableItems[index - 1];

        if (prevNode) {
          addToSelectedNodes([prevNode.id], true);
        }
      }
    },
    [addToSelectedNodes, selectableItems],
  );

  const moveDown = useCallback(
    (index: number): void => {
      if (index < selectableItems.length - 1) {
        const nextNode = selectableItems[index + 1];

        if (nextNode) {
          addToSelectedNodes([nextNode.id], true);
        }
      }
    },
    [addToSelectedNodes, selectableItems],
  );

  const moveLeft = useCallback(
    (nodeId: string): void => {
      if (expandedLayerIds.includes(nodeId)) {
        setExpandedLayerIds(false, [nodeId]);
      }
    },
    [expandedLayerIds, setExpandedLayerIds],
  );

  const moveRight = useCallback(
    (index: number, nodeId: string): void => {
      const item = selectableItems[index];

      if (!item) {
        return;
      }

      const layerUI = layerMap.get(nodeId);

      if (!layerUI) {
        return;
      }

      const { animated, children } = layerUI;

      const hasAnimated = Boolean(animated.length > 0);

      let hasChildren = Boolean(children.length);

      if (item.layer.type === LayerType.PRECOMPOSITION && hasAnimated) {
        hasChildren = true;
      }

      if (hasChildren && !expandedLayerIds.includes(nodeId)) {
        setExpandedLayerIds(true, [nodeId]);
      }
    },
    [expandedLayerIds, selectableItems, setExpandedLayerIds],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey) {
        return;
      }

      const isCmdOrCtrlPressed = event.metaKey || event.ctrlKey;
      const isMoveUp = event.key === 'ArrowUp';
      const isMoveDown = event.key === 'ArrowDown';
      const isMoveLeft = event.key === 'ArrowLeft';
      const isMoveRight = event.key === 'ArrowRight';

      const isNavigationKey = isMoveUp || isMoveDown || isMoveLeft || isMoveRight;

      if ((isTimelineFocused || isCmdOrCtrlPressed) && isNavigationKey) {
        if (selectedNodesInfo.length && selectedNodesInfo[0]) {
          const nodeId = selectedNodesInfo[0].nodeId;
          const index = selectableItems.findIndex((item) => item.id === nodeId);

          if (index === -1) {
            return;
          }

          if (isMoveRight) {
            moveRight(index, nodeId);
          } else if (isMoveLeft) {
            moveLeft(nodeId);
          } else if (isMoveDown) {
            moveDown(index);
          } else if (isMoveUp) {
            moveUp(index);
          }

          return;
        }

        if (selectableItems.length > 0 && selectableItems[0]) {
          addToSelectedNodes([selectableItems[0].id], true);
        }
      }
    },
    [isTimelineFocused, selectedNodesInfo, selectableItems, moveRight, moveLeft, moveDown, moveUp, addToSelectedNodes],
  );

  useEventListener('keydown', handleKeyDown);

  return null;
};
