/**
 * Copyright 2024 Design Barn Inc.
 */

import type { RGBA } from '../components/ColorPicker';

import setRGBA from './setRgba';

// reference: https://en.wikipedia.org/wiki/HSL_and_HSV#HSV_to_RGB
export default function hsvToRgb(hue: number, saturation: number, value: number): RGBA {
  const val = value / 100;
  const sat = saturation / 100;
  let chroma = sat * val;
  const hueSegment = hue / 60;
  let secondLargestComponent = chroma * (1 - Math.abs((hueSegment % 2) - 1));
  let minValue = val - chroma;
  const precision = 255;

  chroma = ((chroma + minValue) * precision) | 0;
  secondLargestComponent = ((secondLargestComponent + minValue) * precision) | 0;
  minValue = (minValue * precision) | 0;

  if (hueSegment >= 1 && hueSegment < 2) {
    return setRGBA(secondLargestComponent, chroma, minValue);
  }
  if (hueSegment >= 2 && hueSegment < 3) {
    return setRGBA(minValue, chroma, secondLargestComponent);
  }
  if (hueSegment >= 3 && hueSegment < 4) {
    return setRGBA(minValue, secondLargestComponent, chroma);
  }
  if (hueSegment >= 4 && hueSegment < 5) {
    return setRGBA(secondLargestComponent, minValue, chroma);
  }
  if (hueSegment >= 5 && hueSegment <= 6) {
    return setRGBA(chroma, minValue, secondLargestComponent);
  }

  return setRGBA(chroma, secondLargestComponent, minValue);
}
