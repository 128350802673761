/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { getCurrentKeyFrame, getIsAtKeyFrame } from './helpers';

import { PolygonRoundness } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { MIN_POLYSTAR_ROUNDNESS, MAX_POLYSTAR_ROUNDNESS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface PolystarOuterRoundnessFieldProps {
  outerRoundness: number;
  outerRoundnessIsAnimated: boolean;
}

export const PolystarOuterRoundnessField: React.FC<PolystarOuterRoundnessFieldProps> = ({
  outerRoundness,
  outerRoundnessIsAnimated,
}) => {
  const [animated, currentShapeNode, setOuterRoundness, setPolystarVariableIsAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentShapeNode?.state?.animatedProperties?.os,
      state.toolkit.currentShapeNode,
      state.toolkit.setPolystarOuterRoundness,
      state.toolkit.setPolystarVariableIsAnimated,
    ],
    shallow,
  );

  const isAtKeyFrame = getIsAtKeyFrame(animated);
  const outerRoundnessCurrentKeyFrame = getCurrentKeyFrame(animated, currentShapeNode);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setOuterRoundness(result.value);
      emitter.emit(EmitterEvent.POLYSTAR_OUTER_ROUNDNESS_UPDATED);
    },
    [setOuterRoundness],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (outerRoundnessCurrentKeyFrame === '') {
      setPolystarVariableIsAnimated('outerRoundness', true);
      setOuterRoundness(outerRoundness);
    } else {
      removeKeyFrame(outerRoundnessCurrentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_OUTER_ROUNDNESS_UPDATED);
    stateHistory.endAction();
  }, [setOuterRoundness, setPolystarVariableIsAnimated, outerRoundnessCurrentKeyFrame, outerRoundness]);

  return (
    <div className="w-full">
      <NumberInput
        name="outer-roundness"
        label={<PolygonRoundness className="h-4 w-4" />}
        value={outerRoundness}
        onChange={handleOnChange}
        showKeyframe
        min={MIN_POLYSTAR_ROUNDNESS}
        max={MAX_POLYSTAR_ROUNDNESS}
        append="%"
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={isAtKeyFrame}
        isChannelAnimated={outerRoundnessIsAnimated}
      />
    </div>
  );
};
