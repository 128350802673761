/**
 * Copyright 2022 Design Barn Inc.
 */

import { useCreatorStore } from '~/store';
import { DirectoryType } from '~/store/projectSlice';

interface SetupExistingProjectProp {
  folderId: string;
  folderName: string | null;
  projectId: string;
  projectName: string | null;
  workspaceId: string;
}

export const setupExistingProject: React.FC<SetupExistingProjectProp> = ({
  folderId,
  folderName,
  projectId,
  projectName,
  workspaceId,
}: SetupExistingProjectProp) => {
  const setSelectedDirectory = useCreatorStore.getState().project.setSelectedDirectory;

  if (workspaceId) {
    // Set selectedworkspaceId first before getting draft
    // Selected Workspace will be loaded elsewhere on load, no need to load it here
    // setSelectedWorkspace(workspace);

    if (workspaceId && projectId && folderId) {
      // Save to Folder
      const folderTitle = folderName ? { title: folderName } : {};

      setSelectedDirectory({ id: folderId, type: DirectoryType.Folder, parentId: projectId, ...folderTitle });
    } else if (workspaceId && projectId) {
      // Save to Project
      const projectTitle = projectName ? { title: projectName } : {};

      setSelectedDirectory({ id: projectId, type: DirectoryType.Project, ...projectTitle });
    } else if (workspaceId) {
      // Save to Draft
      // For our use case, selected workspace directory only, won't allow users to save.
      // So we make it empty string id
      setSelectedDirectory({ id: '', type: DirectoryType.Workspace });
    }
  }

  return null;
};

export const startAutoSave = (): void => {
  const autoSaveStart = useCreatorStore.getState().project.autoSaveStart;

  // Only start auto saving when is not running
  if (autoSaveStart === false) {
    const setAutoSaveStart = useCreatorStore.getState().project.setAutoSaveStart;

    setAutoSaveStart(true);
  }
};
