/**
 * Copyright 2024 Design Barn Inc.
 */

import React, { useEffect, useCallback } from 'react';

import { emitter, EmitterEvent } from '~/lib/emitter';

interface PreventPinchZoomProps {
  children: React.ReactNode;
}

const PreventPinchZoom: React.FC<PreventPinchZoomProps> = ({ children }) => {
  const handleWheel = useCallback((evt: MouseEvent) => {
    if (evt.ctrlKey) {
      // Disable pinch zoom with multiple touched on mac
      evt.preventDefault();
    }
  }, []);

  const handleKeyDown = useCallback((event: MouseEvent) => {
    // Check if CMD (or Meta) key and '-' key are pressed
    if ((event.metaKey || event.ctrlKey) && event.key === '-') {
      // Prevent the default behavior (zooming out)
      event.preventDefault();
      emitter.emit(EmitterEvent.CANVAS_ZOOM_OUT, { skipUpdate: true });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('wheel', handleWheel, {
      passive: false,
    });
    window.addEventListener('keydown', handleKeyDown);

    // Clean up function to remove event listener
    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleWheel, handleKeyDown]);

  return <>{children}</>;
};

export default PreventPinchZoom;
