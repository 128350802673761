/**
 * Copyright 2021 Design Barn Inc.
 */

/* eslint-disable padding-line-between-statements */
import React, { useState, useCallback } from 'react';

import { EllipseProperty } from './EllipseProperty';
import { LayerProperty } from './LayerProperty';
import { PathEditProperty } from './PathEditProperty';
import { PathProperty } from './PathProperty';
import { PolygonProperty } from './PolygonProperty';
import { RectangleProperty } from './RectangleProperty';
import { StarProperty } from './StarProperty';
import { PropertyRow } from './ui/PropertyRow';

import {
  LayerThumbnailEllipse,
  LayerThumbnailPolygon,
  LayerThumbnailRectangle,
  LayerThumbnailStar,
  LineWidth as LineWidthIcon,
  ThumbnailPath,
} from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { Thumbnail } from '~/features/timeline';
import { PropertyPanelType } from '~/store/constant';

interface ShapeProps {
  type: PropertyPanelType;
}

const LineWidth: React.FC = () => {
  const [lineWidth, setLineWidth] = useState<NumberResult>({
    name: 'lineWidth',
    value: 0,
    trueValue: 0,
  });

  const handleOnChange = useCallback((result: NumberResult) => {
    setLineWidth(result);
  }, []);

  return (
    <NumberInput
      name="angle"
      label={<LineWidthIcon className="h-4 w-4" />}
      min={0}
      value={lineWidth.value}
      onChange={handleOnChange}
      showKeyframe
    />
  );
};

const Line: React.FC = () => {
  return (
    <div className="mr-[121px] flex">
      <LineWidth />
    </div>
  );
};

const Shape: React.FC<ShapeProps> = ({ type }) => {
  return (
    <div>
      {type === PropertyPanelType.ShapeLayer && <LayerProperty type={PropertyPanelType.ShapeLayer} />}
      {type === PropertyPanelType.Rectangle && <RectangleProperty />}
      {type === PropertyPanelType.Ellipse && <EllipseProperty />}
      {type === PropertyPanelType.Polygon && <PolygonProperty />}
      {type === PropertyPanelType.Star && <StarProperty />}
      {type === PropertyPanelType.Path && <PathProperty />}
      {type === PropertyPanelType.EditPath && <PathEditProperty />}
      {type === PropertyPanelType.Line && <Line />}
    </div>
  );
};

interface ShapePropertyProp {
  property: PropertyPanelType;
  type: string;
}

export const ShapeProperty: React.FC<ShapePropertyProp> = ({ property, type }) => {
  let icon = null;

  switch (type) {
    case PropertyPanelType.Rectangle:
    case PropertyPanelType.RectanglePath:
      icon = <Thumbnail icon={<LayerThumbnailRectangle />} className="mr-1 bg-gray-700" type={''} />;
      break;
    case PropertyPanelType.Ellipse:
    case PropertyPanelType.EllipsePath:
      icon = <Thumbnail icon={<LayerThumbnailEllipse />} className="mr-1 bg-gray-700" type={''} />;
      break;
    case PropertyPanelType.Polygon:
    case PropertyPanelType.PolygonPath:
      icon = <Thumbnail icon={<LayerThumbnailPolygon />} className="mr-1 bg-gray-700" type={''} />;
      break;
    case PropertyPanelType.Star:
    case PropertyPanelType.StarPath:
      icon = <Thumbnail icon={<LayerThumbnailStar />} className="mr-1 bg-gray-700" type={''} />;
      break;
    case PropertyPanelType.Path:
    case PropertyPanelType.EditPath:
      icon = <Thumbnail icon={<ThumbnailPath />} className="mr-1 bg-gray-700 " type={''} />;
      break;
    default:
      break;
  }

  return (
    <PropertyRow title={property} icon={icon}>
      <Shape type={property} />
    </PropertyRow>
  );
};
