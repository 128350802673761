/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { getCurrentKeyFrame, getIsAtKeyFrame } from './helpers';

import { DualNumberInput } from '~/components/Elements/Input';
import type { NumberResult, NumberInputOption } from '~/components/Elements/Input';
import { MAX_X, MAX_Y, MIN_X, MIN_Y } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { Scalar2D } from '~/lib/toolkit';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { HotKey } from '~/providers/HotkeyListener';
import { useCreatorStore } from '~/store';

const positionOption: { x: NumberInputOption; y: NumberInputOption } = {
  x: {
    name: 'x',
    label: 'X',
    min: MIN_X,
    max: MAX_X,
  },
  y: {
    name: 'y',
    label: 'Y',
    min: MIN_Y,
    max: MAX_Y,
  },
};

interface ShapePositionFieldProps {
  position: Scalar2D;
  positionIsAnimated: boolean;
}
export const ShapePositionField: React.FC<ShapePositionFieldProps> = ({ position, positionIsAnimated }) => {
  const [animated, currentShapeNode, setShapePosition, setShapePositionIsAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentShapeNode?.state?.animatedProperties?.p,
      state.toolkit.currentShapeNode,
      state.toolkit.setShapePosition,
      state.toolkit.setShapePositionIsAnimated,
    ],
    shallow,
  );

  const isAtKeyFrame = getIsAtKeyFrame(animated);
  const positionCurrentKeyFrame = getCurrentKeyFrame(animated, currentShapeNode);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      stateHistory.beginAction();
      const [posX, posY] = position;

      if (result.name === 'x') {
        setShapePosition(result.value, posY);
      } else if (result.name === 'y') {
        setShapePosition(posX, result.value);
      }
      emitter.emit(EmitterEvent.SHAPE_POSITION_UPDATED);
    },
    [position, setShapePosition],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    const [posX, posY] = position;

    if (positionCurrentKeyFrame === '') {
      setShapePositionIsAnimated(true);
      setShapePosition(posX, posY);
    } else {
      removeKeyFrame(positionCurrentKeyFrame);
    }

    emitter.emit(EmitterEvent.SHAPE_POSITION_UPDATED);
    stateHistory.endAction();
  }, [setShapePosition, setShapePositionIsAnimated, positionCurrentKeyFrame, position]);

  return (
    <div className="mr-6 flex">
      <DualNumberInput
        showKeyframe
        onChange={handleOnChange}
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={isAtKeyFrame}
        left={position[0]}
        right={position[1]}
        leftMax={positionOption.x.max || MAX_X}
        leftMin={positionOption.x.min || MIN_X}
        rightMax={positionOption.y.max || MAX_Y}
        rightMin={positionOption.y.min || MIN_Y}
        leftOption={positionOption.x}
        rightOption={positionOption.y}
        isChannelAnimated={positionIsAnimated}
        keyframeShortcut={HotKey.TOGGLE_POSITON_KEYFRAME.toUpperCase()}
      />
    </div>
  );
};
