/**
 * Copyright 2022 Design Barn Inc.
 */

/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import type { AnimatedPropertiesJSON, ColorJSON, ShapeJSON } from '@lottiefiles/toolkit-js';
import { ShapeType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import { colord } from 'colord';
import React from 'react';

import {
  LayerThumbnailShape,
  LayerThumbnailGroup,
  LayerThumbnailPath,
  LayerThumbnailEllipse,
  LayerThumbnailNull,
  LayerThumbnailRectangle,
  LayerThumbnailText,
  LayerThumbnailFill,
  LayerThumbnailGradient,
  LayerThumbnailStroke,
  LayerThumbnailPolygon,
  LayerThumbnailStar,
  LayerThumbnailPrecomp,
  LayerThumbnailImage,
  LayerThumbnailSolid,
  LayerThumbnailMask,
} from '~/assets/icons/timeline';
import type { CurrentGFillShape } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface ThumbnailProps {
  animatedProperties?: AnimatedPropertiesJSON;
  className?: string;
  gradientFillShapeId?: string;
  icon: React.ReactElement;
  type: string;
}

export const Thumbnail: React.FC<ThumbnailProps> = ({
  animatedProperties,
  className,
  gradientFillShapeId,
  icon,
  type,
}) => {
  let color = '';

  if (animatedProperties) {
    if (animatedProperties.cl) {
      const { a: alpha, b, g, r } = animatedProperties.cl.value as ColorJSON;

      color = colord({ r, g, b, a: alpha }).toRgbString();
    } else if (animatedProperties.sc) {
      const { a: alpha, b, g, r } = animatedProperties.sc.value as ColorJSON;

      color = colord({ r, g, b, a: alpha }).toRgbString();
    } else if (animatedProperties.g && gradientFillShapeId) {
      const getShape = useCreatorStore.getState().toolkit.getShape;
      const shape = getShape(ShapeType.GRADIENT_FILL, gradientFillShapeId) as CurrentGFillShape;

      color = shape.cssColor;
    }
  }

  return (
    <div
      className={clsx(
        `flex h-4 w-4 items-center justify-center rounded-layer-thumbnail bg-gray-800 ${className || ''}`,
        {},
      )}
    >
      {React.cloneElement(icon, {
        className: clsx('h-4 w-4', {
          'stroke-current': type === 'st',
          'fill-current': type === 'fl' || type === 'SOLID',
          'text-gray-50': !animatedProperties,
        }),
        style: {
          color,
        },
      })}
    </div>
  );
};

const PreCompThumbnail: React.FC = () => {
  return (
    <div className={clsx('flex h-4 w-4 items-center justify-center rounded-layer-thumbnail bg-gray-800', {})}>
      <LayerThumbnailPrecomp />
    </div>
  );
};

interface LayerThumbnailProps {
  isMask?: boolean | undefined;
  layer: ShapeJSON;
}

export const LayerThumbnail: React.FC<LayerThumbnailProps> = React.memo(({ isMask, layer }) => {
  const { animatedProperties, properties, type } = layer;
  let layerType = type as string;

  // preprocess type as sr contain polygon and star property
  if (type === 'sr') {
    if (properties.sy === 1) {
      layerType = 'star';
    } else if (properties.sy === 2) {
      layerType = 'polygon';
    }
  }

  // Build thumbnail map for conditional rendering
  const thumbnailMap: Record<string, React.ReactNode> = {
    SHAPE: <Thumbnail icon={<LayerThumbnailShape />} type={layerType} />,
    PRECOMPOSITION: <PreCompThumbnail />,
    IMAGE: <Thumbnail icon={<LayerThumbnailImage />} type={layerType} />,
    SOLID: <Thumbnail icon={<LayerThumbnailSolid />} type={layerType} />,
    gr: <Thumbnail icon={<LayerThumbnailGroup />} type={layerType} />,
    GROUP: <Thumbnail icon={<LayerThumbnailGroup />} type={layerType} />,
    sh: <Thumbnail icon={<LayerThumbnailPath />} type={layerType} />,
    rc: <Thumbnail icon={<LayerThumbnailRectangle />} type={layerType} />,
    el: <Thumbnail icon={<LayerThumbnailEllipse />} type={layerType} />,
    star: <Thumbnail icon={<LayerThumbnailStar />} type={layerType} />,
    polygon: <Thumbnail icon={<LayerThumbnailPolygon />} type={layerType} />,
    TEXT: <Thumbnail icon={<LayerThumbnailText />} type={layerType} />,
    NULL: <Thumbnail icon={<LayerThumbnailNull />} type={layerType} />,
    fl: <Thumbnail icon={<LayerThumbnailFill />} type={layerType} animatedProperties={animatedProperties} />,
    st: <Thumbnail icon={<LayerThumbnailStroke />} type={type} animatedProperties={animatedProperties} />,
    mask: <Thumbnail icon={<LayerThumbnailMask />} type={layerType} />,
    gf: (
      <Thumbnail
        icon={<LayerThumbnailGradient />}
        type={layerType}
        animatedProperties={animatedProperties}
        gradientFillShapeId={layer.id}
      />
    ),
  };

  const ThumbnailView = isMask ? thumbnailMap['mask'] : thumbnailMap[layerType] ?? null;

  return <>{ThumbnailView}</>;
});
