/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ShapeJSON } from '@lottiefiles/toolkit-js';
import { PropertyType, ShapeType, StarType } from '@lottiefiles/toolkit-js';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import type { OptionalShapeLayer } from '../Layers';

import { LayerAnimated, LayerAddAnimated } from '~/assets/icons';
import { Tooltip } from '~/components/Elements/Tooltip';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant } from '~/store/constant/modal';

interface Props {
  animated: boolean;
  layer: OptionalShapeLayer | ShapeJSON;
}

export const AnimatedButton: React.FC<Props> = ({ animated, layer }) => {
  // TODO: handle multi select
  const selectedNodeId = useCreatorStore((state) => state.ui.selectedNodesInfo[0]?.nodeId as string);
  const buttonElement = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const onClick = (): void => {
      if (selectedNodeId !== layer.id) return;
      if (buttonElement.current) buttonElement.current.click();
    };

    if (selectedNodeId === layer.id) emitter.on(EmitterEvent.TIMELINE_SHOW_ANIMATED_PROPERTIES, onClick);

    return () => {
      emitter.off(EmitterEvent.TIMELINE_SHOW_ANIMATED_PROPERTIES, onClick);
    };
  }, [layer.id, selectedNodeId]);

  return (
    <Tooltip content="Animation toggle" placement="bottom-end">
      <div>
        <button
          className="group relative h-4 w-4 hover:bg-gray-800"
          ref={buttonElement}
          onClick={(evt) => {
            evt.stopPropagation();
            const { addToSelectedNodes, setGlobalModal } = useCreatorStore.getState().ui;

            addToSelectedNodes([layer.id], true);
            if (!buttonElement.current) return;
            const { x, y } = buttonElement.current.getClientRects()[0] as { x: number; y: number };

            let animationKeys = Object.keys(layer.animatedProperties) as string[];

            // FIXME(miljau): While toolkit allows you to modify these properties,
            // creator does not expect these properties to change for a polygon.

            // Removes inner radius and outer roundness from animated property panel for polygon shapes.
            if (layer.type === ShapeType.STAR && (layer.properties.sy as StarType) === StarType.Polygon) {
              animationKeys = animationKeys.filter(
                (key) => ![PropertyType.INNER_RADIUS, PropertyType.INNER_ROUNDNESS].includes(key as PropertyType),
              );
            }

            setGlobalModal(GlobalModalConstant.AnimationAdder, {
              defaultPosition: { x, y } as { x: number; y: number },
              animationKeys,
            });
          }}
        >
          <LayerAnimated
            className={clsx('', {
              'fill-gray-500 group-hover:fill-white': !animated,
              'fill-teal-200 group-hover:fill-teal-200': animated,
            })}
          />
          <LayerAddAnimated className="absolute left-[2px] top-[6px] hidden h-[5px] w-[5px] group-hover:block" />
        </button>
      </div>
    </Tooltip>
  );
};
