/**
 * Copyright 2022 Design Barn Inc.
 */

import clsx from 'clsx';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Interactive: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={clsx('ml-3 flex h-6 w-6 items-center justify-center rounded hover:bg-gray-700', className)}>
      {children}
    </div>
  );
};
