/**
 * Copyright 2022 Design Barn Inc.
 */

import { consoleLog } from './console';
import {
  getCurrentScene,
  getKeyFrameById,
  getSelectedKeyFrame,
  beginAction,
  endAction,
  batchAction,
  getSelectedKeyFrames,
  addToSelectedNodes,
  getUserToken,
  getUserId,
  getSelectedPrecomposition,
  getThemes,
  addTheme,
  removeTheme,
  getCanvasScreenshot,
  getBoundingBoxSize,
} from './creator';
import { customSceneData } from './custom-data';
import { addEventListener } from './event-listeners/event-listener';
import { insertLottieJson, insertSvg } from './import';
import { showUi, exitPlugin } from './plugin';
import { remove } from './remove';
import { showToast } from './show-toast';
import { getNodeById, getSelectedNode, getSelectedNodes } from './translate-node';

export const shimFunctions = {
  getThemes,
  addTheme,
  removeTheme,
  consoleLog,
  customSceneData,
  insertLottieJson,
  insertSvg,
  getNodeById,
  getSelectedNode,
  getSelectedNodes,
  getUserToken,
  getUserId,
  showUi,
  exitPlugin,
  remove,
  addEventListener,
  getCurrentScene,
  getKeyFrameById,
  getSelectedKeyFrame,
  getSelectedKeyFrames,
  beginAction,
  endAction,
  batchAction,
  addToSelectedNodes,
  getSelectedPrecomposition,
  showToast,
  getCanvasScreenshot,
  getBoundingBoxSize,
};
