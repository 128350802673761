/**
 * Copyright 2022 Design Barn Inc.
 */

// import { nanoid } from 'nanoid';
import React, { useCallback, useContext } from 'react';
import { shallow } from 'zustand/shallow';

import { SaveContext } from './SaveContext';

// import { Plus as IconPlus } from '~/assets/icons';
import { DEFAULT_FILE_NAME } from '~/data/constant';
import { useCreatorStore } from '~/store';
// import type { IWorkspaceFolder, IWorkspaceProject } from '~/store/projectSlice';
import { DirectoryType, SavingState } from '~/store/projectSlice';

export const SaveFooter: React.FC = () => {
  const [setSelectedDirectory, setInfo, setLoader] = useCreatorStore(
    (state) => [state.project.setSelectedDirectory, state.project.setInfo, state.ui.setLoader],
    shallow,
  );

  const { error, fileName, options, selected } = useContext(SaveContext);
  const { id, projectId, title, type } = selected;

  const handleOnClick = useCallback(async () => {
    const selectedDirectoryId = id as string | null;

    // When workspace is selected, save button will be ignored
    if (selectedDirectoryId && type !== DirectoryType.Workspace) {
      let newFile = false;

      if (options) {
        const { createNew } = options;

        newFile = Boolean(createNew);
      }

      if (type === DirectoryType.Project) {
        setSelectedDirectory({ id: selectedDirectoryId, type, title: title as string });
      } else if (type === DirectoryType.Folder) {
        setSelectedDirectory({ id: selectedDirectoryId, type, parentId: projectId as string, title: title as string });
      }

      setInfo({
        name: fileName ? fileName : DEFAULT_FILE_NAME,
        isDraft: false,
        savingState: SavingState.SAVING,
        saveFromPopup: true,
        ...(newFile
          ? {
              saveDraftCount: 0,
              fileId: null,
              fileSubscribeKey: null,
              fileVersionId: null,
            }
          : {}),
      });

      setLoader({
        isLoading: true,
      });
    }
  }, [id, type, options, setInfo, fileName, setLoader, setSelectedDirectory, title, projectId]);

  // TODO: Adding Directory/Folders is a paid feature, we have to introduce a check and paywall for this
  // For now, since creator will have zero paywalls, we're also not allowing adding directory/folders (27th July 2023)
  // Note: Check this with Karin & Dion
  // const addDirectory = useCallback((): void => {
  //   // evt.preventDefault();
  //   if (Object.keys(selected).length > 0) {
  //     const tempId = nanoid();

  //     let newRow = {};
  //     let childKey = 'projects';

  //     if (type === DirectoryType.Workspace) {
  //       // adding project into workspace
  //       childKey = 'projects';

  //       newRow = {
  //         id: tempId,
  //         tempId,
  //         isPrivate: false,
  //         title: '',
  //         type: DirectoryType.Project,
  //         workspaceId: id,
  //         folders: [],
  //       } as IWorkspaceProject;
  //     } else if (type === DirectoryType.Project) {
  //       // adding folders into project
  //       childKey = 'folders';

  //       newRow = {
  //         id: tempId,
  //         tempId,
  //         name: '',
  //         thumbnails: null,
  //         type: DirectoryType.Folder,
  //         projectId: id,
  //         fileCount: 0,
  //       } as IWorkspaceFolder;
  //     }

  //     if (Object.keys(newRow).length > 0 && childKey) {
  //       // newRow.id = tempId;
  //       // newRow.tempId = tempId;

  //       const parentId = id as string;

  //       setUIMap((draft: any) => {
  //         draft[tempId as string] = {
  //           expanded: false,
  //           name: '',
  //           type,
  //         };
  //         draft[parentId].expanded = true;
  //       });

  //       setRowMap((draft: any) => {
  //         draft[parentId][childKey] = [...draft[parentId][childKey], newRow];
  //         draft[tempId] = newRow;
  //       });
  //     }
  //   }
  // }, [selected, setRowMap, setUIMap, id, type]);

  return (
    <div className="h-[58px] px-3 py-4">
      {/* Refer to above note */}
      {/* <div
        onClick={addDirectory}
        className="float-left flex h-[24px] cursor-pointer rounded stroke-gray-500 p-1 hover:bg-gray-700 hover:stroke-white hover:text-white"
      >
        <IconPlus className="mr-1.5 inline h-[16px] w-[16px] cursor-pointer fill-current stroke-current text-gray-400 hover:text-white" />
        <span className="inline text-caption text-gray-400 hover:text-white ">New project/folder</span>
      </div> */}
      {error ? null : (
        <div className="float-right inline-block">
          <button
            data-testid="file-save-button"
            className="ml-1 h-6 w-12 rounded bg-teal-300 text-xs font-bold leading-[17px] text-white hover:bg-teal-400"
            onClick={handleOnClick}
            disabled={!id}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};
