/**
 * Copyright 2021 Design Barn Inc.
 */

import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import React, { useCallback, useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { Divider } from '../Elements/Divider';
import { FixedTooltip } from '../Elements/Tooltip/FixedTooltip';

import { TooltipWithShortcut } from '~/components/Elements/Tooltip';
import { EventType, PluginType } from '~/data/eventStore';
// eslint-disable-next-line no-restricted-imports
import { cmdOrCtrl } from '~/features/menu/constant';
import { CompositionPanel, PresetPanel, ShapePanel } from '~/features/property-panel';
import { useCreatorStore } from '~/store';
import { PropertyPanelType } from '~/store/constant';
import { fireEvent } from '~/utils';

export const RightSideBarSetting = {
  Property: 'Property',
  AnimationPresets: 'Animation Presets',
};

export const SettingTabs = [
  {
    label: RightSideBarSetting.Property,
    id: 'right-panel-properties',
  },
  { label: RightSideBarSetting.AnimationPresets, id: 'right-panel-presets' },
] as const;

const PropertyView: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const hasShownOnetimeTooltip = useCreatorStore.getState().ui.hasShownOnetimeTooltip;
  const [onetimeTooltipOpen, setOnetimeTooltipOpen] = useState(true);
  const [property, rightSideBarPanel] = useCreatorStore(
    (state) => [state.ui.currentPropertyPanel, state.ui.rightSideBarPanel],
    shallow,
  );

  const onTabChange = useCallback((index: number) => {
    setSelectedTabIndex(index);
    if (SettingTabs[index]?.label === RightSideBarSetting.AnimationPresets) {
      fireEvent({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        event_type: EventType.OpenedPlugin,
        parameters: {
          type: PluginType.AnimationPresets,
        },
      });
    }
  }, []);

  useEffect(
    () => setSelectedTabIndex(rightSideBarPanel === RightSideBarSetting.AnimationPresets ? 1 : 0),
    [rightSideBarPanel],
  );

  return (
    <Tab.Group onChange={onTabChange} selectedIndex={selectedTabIndex}>
      <Tab.List className="bg-gray-800 px-1">
        {SettingTabs.map((tab, tabIndex) => (
          <Tab key={tab.label} className="bg-gray-800 px-3 py-2 text-caption focus:outline-none" id={tab.id}>
            {({ selected }) => (
              <>
                <TooltipWithShortcut content={tab.label} shortcut={`${cmdOrCtrl} ${tabIndex + 1}`}>
                  <span
                    className={clsx({
                      'font-bold text-white': selected,
                      'font-normal text-gray-400 hover:text-white': !selected,
                    })}
                  >
                    {tab.label}
                  </span>
                </TooltipWithShortcut>
              </>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Divider />
      <Tab.Panels className="h-[calc(100%-51px)] overflow-y-auto">
        <FixedTooltip
          offset={{ mainAxis: 15, crossAxis: 30 }}
          label="Double click an object to view its properties"
          subLabel="You can also select the object in the timeline."
          placement="left"
          open={hasShownOnetimeTooltip && onetimeTooltipOpen}
          setOpen={() => setOnetimeTooltipOpen(false)}
        >
          <div></div>
        </FixedTooltip>
        <Tab.Panel>
          <div>{property === PropertyPanelType.Composition ? <CompositionPanel /> : <ShapePanel />}</div>
        </Tab.Panel>
        <Tab.Panel className={'h-full'} unmount={false}>
          <PresetPanel />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

interface PropertyProps {
  isHidden: boolean;
}

export const Property: React.FC<PropertyProps> = ({ isHidden }) => {
  return (
    <div id="right-panel" className={`relative z-[2] h-full bg-gray-800 text-gray-50 ${isHidden ? 'hidden' : ''}`}>
      <div className="flex h-full w-[248px] flex-col">
        <PropertyView />
      </div>
    </div>
  );
};
