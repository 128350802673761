/**
 * Copyright 2023 Design Barn Inc.
 */

export const GB_CONSTANT = {
  SHOW_ADD_NEW_SCENE: 'show_add_new_scene',
  SHOW_CREATE_BREAK_SCENE: 'show_create_break_scene',
  SHOW_GET_STARTED: 'show_get_started',
  SHOW_PEN_TOOL: 'show_pen_tool',
  SHOW_TIMELINE_ZOOMBAR: 'show_timeline_zoombar',
  SHOW_PLUGINS: 'show_plugins',
  SHOW_ICONSCOUT_PLUGIN: 'show_iconscout_plugin',
  SHOW_MOTION_PRESETS_PLUGIN: 'show_motion_presets_plugin',
  SHOW_SHARE_BUTTON: 'show_share_button',
  SHOW_ERROR_MESSAGE: 'show_error_message',
  SHOW_DUPLICATOR_PLUGIN: 'show_duplicator_plugin',
  SHOW_PROMPT_VECTOR_PLUGIN: 'show_prompt_vector_plugin',
  // eslint-disable-next-line no-secrets/no-secrets
  SHOW_KINETIC_TYPOGRAPHY_PLUGIN: 'show_kinetic_typography_plugin',
  SHOW_MOTION_COPILOT_PLUGIN: 'show_motion_copilot_plugin',
  SHOW_RULERS_GUIDES_GRID: 'show_rulers_guides_grid',
  LATEST_CHANGELOG_VERSION: 'latest_changelog_version',
};
