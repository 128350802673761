/**
 * Copyright 2024 Design Barn Inc.
 */

import { ShapeType } from '@lottiefiles/lottie-js';
import type {
  AnimatedColorProperty,
  AnimatedGradientProperty,
  FillShape,
  GradientFillShape,
  GradientStrokeShape,
  PrecompositionLayer,
  StrokeShape,
} from '@lottiefiles/toolkit-js';
import { PropertyType } from '@lottiefiles/toolkit-js';
import React, { useCallback, useEffect, useState } from 'react';

import type { ColorOptional } from '..';
import { MouseAction } from '..';
import type { GradientPointType } from '../Area/GradientPoints/GradientPoint';

import { rgbToHex, hexToRgb } from '@/ColorPicker/helpers';
import { ChevronDown, ChevronRight } from '~/assets/icons';
import { layerMap } from '~/lib/layer';
import { useCreatorStore } from '~/store';

interface IProps {
  gradientPoints?: GradientPointType[];
  setColor: (color: ColorOptional, action: MouseAction) => void;
  solidColor?: ColorOptional;
}

const DefaultColorPanel: React.FC<IProps> = ({ gradientPoints, setColor, solidColor }) => {
  const [showDocumentColors, setShowDocumentColors] = useState(true);
  const [colors, setColors] = useState<string[]>([]);

  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  const getSolidColor = useCallback((property: AnimatedColorProperty): string | null => {
    const { b, g, r } = property.value.rgba;

    return rgbToHex(r, g, b);
  }, []);

  const getGradientColors = useCallback((property: AnimatedGradientProperty): string[] => {
    return property.value.colors.map((color) => rgbToHex(color.red, color.green, color.blue));
  }, []);

  const getColorsFromLayerUI = useCallback((): string[] => {
    const values: string[] = [];

    layerMap.forEach((value, key) => {
      const toolkitNode = getNodeByIdOnly(key);

      if (!toolkitNode) return;

      const appearanceType = value.appearanceType;

      if (appearanceType === ShapeType.FILL || appearanceType === ShapeType.STROKE) {
        const color = getSolidColor((toolkitNode as FillShape | StrokeShape).color);

        if (color) values.push(color);
      } else if (appearanceType === ShapeType.GRADIENT_FILL || appearanceType === ShapeType.GRADIENT_STROKE) {
        const gradientColors = getGradientColors((toolkitNode as GradientFillShape | GradientStrokeShape).gradient);

        values.push(...gradientColors);
      } else if (appearanceType === 'PRECOMPOSITION') {
        const precompNode = getNodeByIdOnly(key) as PrecompositionLayer | undefined;
        const layers = precompNode?.precomposition?.layers;

        if (layers) {
          layers.forEach((layer) => {
            Object.values((layer as PrecompositionLayer).colors).forEach((precompColors) => {
              precompColors.forEach((color: AnimatedGradientProperty | AnimatedColorProperty) => {
                if (color.type === PropertyType.GRADIENT) {
                  const gradientColors = getGradientColors(color as AnimatedGradientProperty);

                  values.push(...gradientColors);
                }

                if (color.type === PropertyType.FILL_COLOR) {
                  const fillColor = getSolidColor(color as AnimatedColorProperty);

                  if (fillColor) values.push(fillColor);
                }
              });
            });
          });
        }
      }
    });

    return [...new Set(values)];
  }, [getGradientColors, getNodeByIdOnly, getSolidColor]);

  useEffect(() => {
    setColors(getColorsFromLayerUI());
  }, [getColorsFromLayerUI, gradientPoints, solidColor]);

  const onChooseColor = (item: string): void => {
    const { blue, green, red } = hexToRgb(item);

    setColor({ red, green, blue }, MouseAction.onChange);
  };

  if (!Array.isArray(colors) || !colors.length) return null;

  return (
    <>
      <div className="my-2 h-[1px] w-full bg-gray-700" />

      <button
        className="flex items-center text-xs font-bold text-white"
        onClick={() => setShowDocumentColors(!showDocumentColors)}
      >
        {showDocumentColors && <ChevronDown className="h-4 w-5 stroke-current dark:text-gray-400" />}
        {!showDocumentColors && <ChevronRight className="h-4 w-5 stroke-current dark:text-gray-400" />}
        Document colors
      </button>

      {showDocumentColors && (
        <div className="default-color-panel h-10">
          {colors.map((item: string, index: number) => {
            return (
              <div
                onClick={() => onChooseColor(item)}
                key={item + index.toString()}
                className={`default-color-panel_item`}
                style={{
                  background: `#${item}`,
                  border: '1px solid rgba(0, 0, 0, 0.2)',
                }}
              >
                <div className="item_qub"></div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DefaultColorPanel;
