/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback, useState, useEffect } from 'react';

import { UI_OTHER_SHIFT_INCREMENT } from '~/data/constant';
import { useCreatorStore } from '~/store';
import { GlobalCursorType } from '~/store/uiSlice';

interface BindDragInputToProps {
  children: React.ReactNode;
  inputRef: React.RefObject<HTMLInputElement>;
  isDisabled?: boolean;
  onStop: (obj: Record<string, Record<string, string>>) => void;
  setValue: (obj: Record<string, Record<string, string>>) => void;
  value: number | string;
}

export const BindDragInputTo: React.FC<BindDragInputToProps> = ({
  children,
  inputRef,
  isDisabled,
  onStop,
  setValue,
  value,
}: BindDragInputToProps) => {
  const setGlobalCursor = useCreatorStore.getState().ui.setGlobalCursor;
  // We are creating a snapshot of the values when the drag starts
  // because the [value] will itself change & we need the original
  // [value] to calculate during a drag.
  const [snapshot, setSnapshot] = useState(value);

  // This captures the starting position of the drag and is used to
  // calculate the diff in positions of the cursor.
  const [startVal, setStartVal] = useState(0);

  const [isDragging, setDragging] = useState(false);

  const [isMouseDown, setIsMouseDown] = useState(false);

  // Start the drag to change operation when the mouse button is down.
  const onStart = useCallback(
    (event: React.MouseEvent): void => {
      if (isDisabled) {
        return;
      }

      event.preventDefault();
      inputRef.current?.select();
      setStartVal(event.screenX);
      setSnapshot(value);
      setIsMouseDown(true);
      setDragging(true);
    },
    [isDisabled, value, inputRef],
  );

  // Only change the value if the drag was actually started.
  const onUpdate = useCallback(
    (event: MouseEvent): void => {
      event.preventDefault();
      if (!isMouseDown || !isDragging) return;

      if (startVal) {
        let offset = event.screenX - startVal;

        if (event.shiftKey) {
          // Get absolute value of offset
          const absOffset = Math.abs(offset);
          const newOffset =
            Math.ceil(absOffset / UI_OTHER_SHIFT_INCREMENT) * UI_OTHER_SHIFT_INCREMENT * Math.sign(offset);

          offset = newOffset;
        }

        setValue({ target: { value: (parseFloat(snapshot as string) + offset).toString() } });
      }
    },
    [isDragging, isMouseDown, setValue, snapshot, startVal],
  );

  // Stop the drag operation now.
  const onEnd = useCallback(
    (event: MouseEvent): void => {
      setStartVal(0);
      if (!isMouseDown || !isDragging) return;

      if (startVal) {
        let offset = event.screenX - startVal;

        if (event.shiftKey) {
          const absOffset = Math.abs(offset);
          const newOffset =
            Math.ceil(absOffset / UI_OTHER_SHIFT_INCREMENT) * UI_OTHER_SHIFT_INCREMENT * Math.sign(offset);

          offset = newOffset;
        }

        onStop({ target: { value: (parseFloat(snapshot as string) + offset).toString() } });
      }
      setIsMouseDown(false);
      setDragging(false);
    },
    [isDragging, isMouseDown, onStop, snapshot, startVal],
  );

  // We use document events to update and end the drag operation
  // because the mouse may not be present over the label during
  // the operation..
  useEffect(() => {
    if (isMouseDown && isDragging) {
      document.addEventListener('mousemove', onUpdate);
      document.addEventListener('mouseup', onEnd);
      setGlobalCursor(GlobalCursorType.RESIZE);
    } else {
      document.removeEventListener('mousemove', onUpdate);
      document.removeEventListener('mouseup', onEnd);
      setGlobalCursor(GlobalCursorType.DEFAULT);
    }

    return () => {
      document.removeEventListener('mousemove', onUpdate);
      document.removeEventListener('mouseup', onEnd);
    };
  }, [onUpdate, onEnd, isMouseDown, isDragging, setGlobalCursor]);

  return (
    <span onMouseDown={onStart} className={isDisabled ? '' : GlobalCursorType.RESIZE}>
      {children}
    </span>
  );
};
