/**
 * Copyright 2021 Design Barn Inc.
 */

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import React, { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { Help } from './Help';
import { Profile } from './Profile';
import { ShapeMenu } from './ShapeMenu';
import { ToolbarItem } from './ToolbarItem';
import { ZoomControl } from './ZoomControl';

import {
  ProjectStatusSaving,
  ProjectStatusUnsaved,
  ProjectStatusSaved,
  PointerActive,
  Pointer,
  HandActive,
  Hand,
  ProjectStatusPendingSave,
  Anchor,
  AnchorActive,
  CompTimelineTab,
} from '~/assets/icons';
import { TitleInput } from '~/components/Elements/Input';
import { ShortcutKey } from '~/components/Elements/ShortcutKey';
import { ARROW_OFFSET, Tooltip } from '~/components/Elements/Tooltip';
import { WORKFLOW_DASHBOARD } from '~/config';
import { GB_CONSTANT } from '~/config/growthbook';
import { SceneNameType, ToolType } from '~/data/constant';
import { EventType, UTMMedium, UTMSource } from '~/data/eventStore';
import { CHANGELOG_URL } from '~/data/urls';
import { CreatorMenu, PenMenu, altOrOpt, cmdOrCtrl } from '~/features/menu';
import { saveToCreator, saveToWorkSpace } from '~/lib/function/menu';
import { useUsersnapApi } from '~/providers/UsersnapProvider';
import { useCreatorStore } from '~/store';
import { GlobalModalConstant } from '~/store/constant';
import { DirectoryType, NetworkStatus, SavingState } from '~/store/projectSlice';
import type { EventStorePayload } from '~/utils';
import { utmURLBuilder, fireEvent, truncateString } from '~/utils';

const Report: React.FC = () => {
  const usersnapApi = useUsersnapApi();

  const handleClick = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (usersnapApi as any).open();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (usersnapApi as any).hideButton();
  }, [usersnapApi]);

  return (
    <button className="h-6 w-[86px] text-xs text-white" onClick={handleClick}>
      Report a bug
    </button>
  );
};

const ExportToWorkspace: React.FC = () => {
  const [selectedPrecompositionName, sceneName] = useCreatorStore((state) => [
    state.toolkit.selectedPrecompositionJson?.properties.nm as string,
    state.toolkit.json?.properties.nm as string,
  ]);

  const title = (selectedPrecompositionName || sceneName || SceneNameType.MainScene) as string;

  return (
    <>
      <Tooltip
        offsetOptions={{ mainAxis: ARROW_OFFSET, crossAxis: -30 }}
        content={
          <div className="block w-[250px] whitespace-normal">
            <span className="align-middle">Download</span> <CompTimelineTab className="inline h-4 w-4 text-gray-400" />{' '}
            <span className="align-middle font-bold">{`${title.substring(0, 25).trim()}${title.length > 25 ? '...' : ''}`}</span>{' '}
            <span className="align-middle">
              as dotLottie, Lottie JSON, MP4, WebM, and GIF by exporting it to your workspace.
            </span>
          </div>
        }
        placement="bottom"
      >
        <button className="h-6 w-[53px] rounded bg-[#00C1A2] text-xs text-white" onClick={saveToWorkSpace}>
          Export
        </button>
      </Tooltip>
    </>
  );
};

interface ShareProps {
  isEnabled: boolean;
}

const Share: React.FC<ShareProps> = ({ isEnabled }) => {
  const setGlobalModal = useCreatorStore.getState().ui.setGlobalModal;

  const onShare = useCallback(() => {
    setGlobalModal(GlobalModalConstant.Share);
  }, [setGlobalModal]);

  return (
    <button
      disabled={!isEnabled}
      className={`h-6 rounded ${isEnabled ? 'bg-[#3D4853] text-white' : 'bg-[#333C45] text-gray-400'} px-2 text-xs`}
      onClick={onShare}
    >
      Share
    </button>
  );
};

const AlphaBadge: React.FC = () => {
  const handleWhatsNew = useCallback(async () => {
    window.open(CHANGELOG_URL, '_blank');
  }, []);

  return (
    <button
      onClick={handleWhatsNew}
      className="flex h-[19px] items-center rounded-[10px] bg-gray-700 px-3 text-[12px] text-white"
    >
      Beta {APP_VERSION}
    </button>
  );
};

const RightMost: React.FC = () => {
  return (
    <div className="flex h-8 flex-row items-center justify-center">
      <Help />
      {/* Commented out for now, as we don't have an implementation for this, but we will bring it back eventually. */}
      {/* <Notification notifications={[]} /> */}
      <Profile />
    </div>
  );
};

const HeaderLeft: React.FC = () => {
  return (
    <div id="toolbar" className="mr-auto flex h-[24px] flex-1 items-center gap-2">
      <CreatorMenu />
      <ToolbarItem
        type={ToolType.Move}
        activeIcon={<PointerActive />}
        inactiveIcon={<Pointer />}
        tooltipProps={{
          content: 'Move',
          placement: 'bottom',
          shortcut: 'V',
        }}
      />
      <ToolbarItem
        type={ToolType.Anchor}
        activeIcon={<AnchorActive />}
        inactiveIcon={<Anchor />}
        tooltipProps={{
          content: 'Move anchor',
          placement: 'bottom',
          shortcut: 'Y',
        }}
      />
      <div>
        <ShapeMenu />
      </div>
      <div>
        <PenMenu />
      </div>
      <div className="h-4 w-[1px] rounded bg-gray-600" />
      <ToolbarItem
        type={ToolType.Hand}
        activeIcon={<HandActive />}
        inactiveIcon={<Hand />}
        tooltipProps={{
          content: 'Hand tool',
          placement: 'bottom',
          shortcut: 'H',
        }}
      />
      <ZoomControl />
    </div>
  );
};

const HeaderRight: React.FC = () => {
  const [isAuthorized, fileId] = useCreatorStore(
    (state) => [state.user.isAuthorized, state.project.info.fileId],
    shallow,
  );
  const isShowShareButtonFeatureOn = useFeatureIsOn(GB_CONSTANT.SHOW_SHARE_BUTTON);

  return (
    <div className="ml-auto flex h-[24px] flex-1 items-center justify-end">
      <div className="flex items-center gap-2">
        <AlphaBadge />
        {isAuthorized && <Report />}
        {isAuthorized && isShowShareButtonFeatureOn && <Share isEnabled={fileId !== null} />}
        {isAuthorized && <ExportToWorkspace />}
        <RightMost />
      </div>
    </div>
  );
};

const ProjectNameInput: React.FC<{
  closeEdit: () => void;
}> = ({ closeEdit }) => {
  const [oldProjectName, setInfo, setMadeFirstChange] = useCreatorStore(
    (state) => [state.project.info.name, state.project.setInfo, state.project.setMadeFirstChange],
    shallow,
  );

  const onClose = useCallback(
    (newProjectName: string) => {
      if (newProjectName.length > 0 && oldProjectName !== newProjectName) {
        const name = newProjectName.trim();

        setInfo({ name });
        setMadeFirstChange(true);
        saveToCreator({ saveWithoutPopup: true });
      }

      closeEdit();
    },
    [oldProjectName, setInfo, closeEdit, setMadeFirstChange],
  );

  return <TitleInput initialValue={oldProjectName || ''} onClose={onClose} />;
};

const ProjectName: React.FC = () => {
  const setInfo = useCreatorStore.getState().project.setInfo;
  const isAuthorized = useCreatorStore.getState().user.isAuthorized;

  const projectName = useCreatorStore((state) => state.project.info.name);
  const savingState = useCreatorStore.getState().project.info.savingState;

  const [isEditing, setIsEditing] = useState(false);

  const closeEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const openEdit = useCallback(() => {
    if (isAuthorized) {
      setIsEditing(true);
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (projectName && savingState !== SavingState.SAVING) {
      setInfo({
        oldName: projectName,
      });
    }
  }, [setInfo, projectName, savingState]);

  if (isEditing) {
    return <ProjectNameInput closeEdit={closeEdit} />;
  }

  return (
    <span
      data-testid="project-name"
      className="rounded border border-transparent px-1 text-xs text-gray-200 hover:border-gray-700"
      onClick={openEdit}
      rel="noreferrer"
    >
      {truncateString(projectName as string, 28)}
    </span>
  );
};

const onFileLocationClick = (): void => {
  const eventParams: EventStorePayload = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_type: EventType.ClickedFileLocation,
    parameters: {},
  };

  fireEvent(eventParams);
};

const HeaderCenter: React.FC = () => {
  const [savingState, selectedWorkspaceName, selectedDirectory, networkStatus] = useCreatorStore(
    (state) => [
      state.project.info.savingState,
      state.project.selectedWorkspace?.name,
      state.project.selectedDirectory,
      state.project.network,
    ],
    shallow,
  );

  const iconStyle = 'h-4 w-4 fill-current text-gray-400';

  let savingStateStatusIcon = null;

  // SavingState.INIT is ignored. In INIT state, no status icon is showed
  // Offline or Failed Save -> Shows Unsaved
  if (networkStatus === NetworkStatus.OFFLINE || savingState === SavingState.FAILED) {
    savingStateStatusIcon = <ProjectStatusUnsaved className={iconStyle} />;
  } else if (savingState === SavingState.PENDING_SAVE) {
    savingStateStatusIcon = (
      <Tooltip
        content={
          <>
            Creator autosaves every minute
            <ShortcutKey className="ml-2">{altOrOpt}</ShortcutKey>
            <ShortcutKey className="ml-1">{cmdOrCtrl}</ShortcutKey>
            <ShortcutKey className="mx-1">S</ShortcutKey>
            to save manually.
          </>
        }
      >
        <div>
          <ProjectStatusPendingSave className={iconStyle} />
        </div>
      </Tooltip>
    );
  } else if (savingState === SavingState.SAVING) {
    savingStateStatusIcon = (
      <Tooltip content="Saving...">
        <div>
          <ProjectStatusSaving className={iconStyle} />
        </div>
      </Tooltip>
    );
  } else if (savingState === SavingState.SAVED) {
    savingStateStatusIcon = (
      <Tooltip content="Changes Saved">
        <div>
          <ProjectStatusSaved className={`${iconStyle} text-teal-300`} />
        </div>
      </Tooltip>
    );
  }

  const workflowDirectory = selectedDirectory?.type === DirectoryType.Folder ? 'folder' : 'project';

  return (
    <div className="flex h-[24px] flex-1 items-center justify-center">
      <div className="h-4 w-4">{savingStateStatusIcon}</div>
      {selectedDirectory?.title ? (
        <>
          <Tooltip content={selectedWorkspaceName}>
            <a
              onClick={onFileLocationClick}
              href={utmURLBuilder(
                `${WORKFLOW_DASHBOARD}/${workflowDirectory}/${selectedDirectory.id}`,
                UTMSource.HeaderFileLocation,
                UTMMedium.Creator,
              )}
              target="_blank"
              className="ml-2 text-xs text-gray-500"
              rel="noreferrer"
            >
              {selectedDirectory.title}
            </a>
          </Tooltip>
          <span className="mx-1 text-xs text-gray-500">/</span>
        </>
      ) : null}
      <ProjectName />
    </div>
  );
};

interface HeaderProps {
  isHidden: boolean;
}

export const Header: React.FC<HeaderProps> = ({ isHidden }) => {
  return (
    <div
      className={`z-[11] box-border h-[40px] border-b border-gray-900 bg-gray-800 ${isHidden ? 'hidden' : ''}`}
      id="header"
    >
      <header className="h-[40px]">
        <nav className="flex px-2 py-[8px]">
          <HeaderLeft />
          <HeaderCenter />
          <HeaderRight />
        </nav>
      </header>
    </div>
  );
};
