/**
 * Copyright 2022 Design Barn Inc.
 */

import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { getCurrentKeyFrame, getIsAtKeyFrame } from './helpers';

import { PolystarInnerRoundness } from '~/assets/icons';
import { NumberInput } from '~/components/Elements/Input';
import type { NumberResult } from '~/components/Elements/Input';
import { MAX_POLYSTAR_ROUNDNESS, MIN_POLYSTAR_ROUNDNESS } from '~/data/range';
import { emitter, EmitterEvent } from '~/lib/emitter';
import { removeKeyFrame, stateHistory } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

interface PolystarInnerRoundnessFieldProps {
  innerRoundness: number;
  innerRoundnessIsAnimated: boolean;
}

export const PolystarInnerRoundnessField: React.FC<PolystarInnerRoundnessFieldProps> = ({
  innerRoundness,
  innerRoundnessIsAnimated,
}) => {
  const [animated, currentShapeNode, setInnerRoundness, setPolystarVariableIsAnimated] = useCreatorStore(
    (state) => [
      state.toolkit.currentShapeNode?.state?.animatedProperties?.is,
      state.toolkit.currentShapeNode,
      state.toolkit.setPolystarInnerRoundness,
      state.toolkit.setPolystarVariableIsAnimated,
    ],
    shallow,
  );

  const isAtKeyFrame = getIsAtKeyFrame(animated);
  const innerRoundnessCurrentKeyFrame = getCurrentKeyFrame(animated, currentShapeNode);

  const handleOnChange = useCallback(
    (result: NumberResult) => {
      setInnerRoundness(result.value);
      emitter.emit(EmitterEvent.POLYSTAR_INNER_ROUNDNESS_UPDATED);
    },
    [setInnerRoundness],
  );

  const handleKeyframeClick = useCallback(() => {
    stateHistory.beginAction();
    if (innerRoundnessCurrentKeyFrame === '') {
      setPolystarVariableIsAnimated('innerRoundness', true);
      setInnerRoundness(innerRoundness);
    } else {
      removeKeyFrame(innerRoundnessCurrentKeyFrame);
    }
    emitter.emit(EmitterEvent.POLYSTAR_INNER_ROUNDNESS_UPDATED);
    stateHistory.endAction();
  }, [setInnerRoundness, setPolystarVariableIsAnimated, innerRoundnessCurrentKeyFrame, innerRoundness]);

  return (
    <div className="w-full">
      <NumberInput
        name="inner-roundness"
        label={<PolystarInnerRoundness className="h-4 w-4" />}
        value={innerRoundness}
        onChange={handleOnChange}
        showKeyframe
        min={MIN_POLYSTAR_ROUNDNESS}
        max={MAX_POLYSTAR_ROUNDNESS}
        append="%"
        onKeyframeClick={handleKeyframeClick}
        hasKeyframe={isAtKeyFrame}
        isChannelAnimated={innerRoundnessIsAnimated}
      />
    </div>
  );
};
