/**
 * Copyright 2024 Design Barn Inc.
 */

import type { LayerUI } from '~/features/timeline';
import { useCreatorStore } from '~/store';

export type LayerUIMap = Map<string, LayerUI>;

export const layerMap: LayerUIMap = new Map<string, LayerUI>();

export const setLayerUI = (newMap: LayerUIMap): void => {
  layerMap.clear();
  for (const [key, value] of newMap.entries()) {
    layerMap.set(key, value);
  }
};

export const resetLayerUI = (): void => {
  layerMap.clear();
};

export const setLayerNameFromId = (name: string, id: string): void => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  const newNode = getNodeByIdOnly(id);

  if (newNode) {
    newNode.setName(name);
  }
};
