/**
 * Copyright 2023 Design Barn Inc.
 */

import type { PrecompositionAsset, Keyframe, Scene } from '@lottiefiles/toolkit-js';
import type { QuickJSHandle } from 'quickjs-emscripten';
import { Vector3 } from 'three';

import type { Plugin } from '../Plugin';

import { createPrecompAssetObj } from './precomp/precomposition-asset';
import { createSceneObject } from './scene';
import { createKeyframeObj } from './time/keyframe';
import { getNodeById } from './utils';
import { marshal } from './vmInterface/marshal';
import { newQuickjsObject } from './vmInterface/marshal/object';
import { getTranslatedObject } from './vmInterface/unmarshal/object';

import { SceneType } from '~/data/constant';
import type { CObject3D } from '~/features/canvas';
import { canvasMap } from '~/lib/canvas';
import { getCanvasScreenshot as _getCanvasScreenshot } from '~/lib/dotCreator';
import { Box3 } from '~/lib/threejs/Box3';
import { stateHistory, toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export function getCurrentScene(plugin: Plugin): void {
  const getCurrentSceneHandle = plugin.scope.manage(
    plugin.vm.newFunction('getCurrentScene', (): QuickJSHandle => {
      const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;
      const currentScene = toolkit.scenes[sceneIndex] as Scene;

      const strippedScene = createSceneObject(plugin, currentScene);
      const vmStrippedScene = newQuickjsObject(plugin, strippedScene, true);

      return vmStrippedScene;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getCurrentScene', {
    value: getCurrentSceneHandle,
    configurable: false,
  });
}

export function getKeyFrameById(plugin: Plugin): void {
  const getKeyFrameByIdHandle = plugin.scope.manage(
    plugin.vm.newFunction('getKeyFrameById', (vmFrameId: QuickJSHandle): QuickJSHandle => {
      const frameId = plugin.vm.getString(vmFrameId);
      const keyframe = toolkit.getKeyframeById(frameId) as Keyframe;
      const vmKeyframe = newQuickjsObject(plugin, createKeyframeObj(plugin, keyframe), true);

      return vmKeyframe;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getKeyFrameById', {
    value: getKeyFrameByIdHandle,
    configurable: false,
  });
}

export function getSelectedKeyFrame(plugin: Plugin): void {
  const getSelectedKeyFrameHandle = plugin.scope.manage(
    plugin.vm.newFunction('getSelectedKeyFrame', (): QuickJSHandle => {
      const sceneKeyframes = useCreatorStore.getState().timeline.selectedKeyframeIds;
      let vmKeyframe;

      if (sceneKeyframes[0]) {
        const frameId = useCreatorStore.getState().timeline.selectedKeyframeIds[0];
        const keyframe = toolkit.getKeyframeById(frameId as string) as Keyframe;

        vmKeyframe = newQuickjsObject(plugin, createKeyframeObj(plugin, keyframe), true);
      }

      return vmKeyframe as QuickJSHandle;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getSelectedKeyFrame', {
    value: getSelectedKeyFrameHandle,
    configurable: false,
  });
}

export function getSelectedKeyFrames(plugin: Plugin): void {
  const getSelectedKeyFramesHandle = plugin.scope.manage(
    plugin.vm.newFunction('getSelectedKeyFrames', (): QuickJSHandle => {
      const sceneKeyframeIds = useCreatorStore.getState().timeline.selectedKeyframeIds;

      if (sceneKeyframeIds.length === 0) {
        return plugin.vm.newArray();
      }

      const nodeArray: object[] = [];

      sceneKeyframeIds.forEach((frameId) => {
        const keyframe = toolkit.getKeyframeById(frameId as string) as Keyframe;
        const keyframeObject = createKeyframeObj(plugin, keyframe);

        nodeArray.push(keyframeObject);
      });

      const vmNodeArray = marshal(plugin, nodeArray);

      return vmNodeArray;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getSelectedKeyFrames', {
    value: getSelectedKeyFramesHandle,
    configurable: false,
  });
}

export function getUserToken(plugin: Plugin): void {
  const getUserTokenHandle = plugin.scope.manage(
    plugin.vm.newFunction('getUserToken', (): QuickJSHandle => {
      if (!plugin.canAccessUserToken()) {
        throw new Error('Plugin does not have access to user token');
      }

      const userToken = useCreatorStore.getState().user.token;

      if (userToken === '' || !userToken) {
        throw new Error('No user token found');
      }

      return plugin.vm.newString(userToken);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getUserToken', {
    value: getUserTokenHandle,
    configurable: false,
  });
}

export function getUserId(plugin: Plugin): void {
  const getUserIdHandle = plugin.scope.manage(
    plugin.vm.newFunction('getUserId', (): QuickJSHandle => {
      const userId = useCreatorStore.getState().user.info.id;

      if (userId === '' || !userId) {
        throw new Error('No user id found');
      }

      return plugin.vm.newString(userId);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getUserId', {
    value: getUserIdHandle,
    configurable: false,
  });
}

export function beginAction(plugin: Plugin): void {
  const beginActionHandle = plugin.scope.manage(
    plugin.vm.newFunction('beginAction', (): void => {
      stateHistory.beginAction();
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'beginAction', {
    value: beginActionHandle,
    configurable: false,
  });
}

export function endAction(plugin: Plugin): void {
  const endActionHandle = plugin.scope.manage(
    plugin.vm.newFunction('endAction', (): void => {
      stateHistory.endAction();
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'endAction', {
    value: endActionHandle,
    configurable: false,
  });
}

// TODO: replace `beginAction` and `endAction` with `batchAction`
// TOFIX: doesn't work if 'creator.batchAction' is wrapped in `creator.onMessage = function (message: any) {...}`
// as 'message' won't be accessible
export function batchAction(plugin: Plugin): void {
  const batchActionHandle = plugin.scope.manage(
    plugin.vm.newFunction('batchAction', (vmCallback: QuickJSHandle): void => {
      const callback = plugin.vm.getString(vmCallback);

      stateHistory.beginAction();
      plugin.vm.evalCode(`(${callback}())()`);
      stateHistory.endAction();
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'batchAction', {
    value: batchActionHandle,
    configurable: false,
  });
}

export function addToSelectedNodes(plugin: Plugin): void {
  const addToSelectedNodesHandle = plugin.scope.manage(
    plugin.vm.newFunction('addToSelectedNodes', (vmOptions: QuickJSHandle): void => {
      const { nodeIds, replaceCurrentSelection } = getTranslatedObject(plugin, vmOptions) as {
        nodeIds: string[];
        replaceCurrentSelection: boolean;
      };

      useCreatorStore.getState().ui.addToSelectedNodes(nodeIds, replaceCurrentSelection);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'addToSelectedNodes', {
    value: addToSelectedNodesHandle,
    configurable: false,
  });
}

export function getSelectedPrecomposition(plugin: Plugin): void {
  const getSelectedPrecompositionHandle = plugin.scope.manage(
    plugin.vm.newFunction('getSelectedPrecomposition', (): QuickJSHandle => {
      const selectedPrecompositionId = useCreatorStore.getState().toolkit.selectedPrecompositionId;

      if (!selectedPrecompositionId) {
        return plugin.vm.null;
      }

      const precompositionAsset = getNodeById(selectedPrecompositionId);

      const strippedPrecomp = createPrecompAssetObj(plugin, precompositionAsset as PrecompositionAsset);
      const vmStrippedPrecomp = newQuickjsObject(plugin, strippedPrecomp, true);

      return vmStrippedPrecomp;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getSelectedPrecomposition', {
    value: getSelectedPrecompositionHandle,
    configurable: false,
  });
}

export function getThemes(plugin: Plugin): void {
  const getThemesHandle = plugin.scope.manage(
    plugin.vm.newFunction('getThemes', (): QuickJSHandle => {
      const themes = useCreatorStore.getState().project.themes;

      const vmThemesObject = marshal(plugin, themes);

      return vmThemesObject;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getThemes', {
    value: getThemesHandle,
    configurable: false,
  });
}

export function addTheme(plugin: Plugin): void {
  const addThemeHandle = plugin.scope.manage(
    plugin.vm.newFunction('addTheme', (vmThemeId: QuickJSHandle, vmData: QuickJSHandle): void => {
      const id = plugin.vm.getString(vmThemeId);
      const data = getTranslatedObject(plugin, vmData) as Record<string, unknown>;

      if (!id || typeof data !== 'object') {
        throw new Error('Invalid arguments');
      }

      useCreatorStore.getState().project.addTheme(id, data);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'addTheme', {
    value: addThemeHandle,
    configurable: false,
  });
}

export function removeTheme(plugin: Plugin): void {
  const removeThemeHandle = plugin.scope.manage(
    plugin.vm.newFunction('removeTheme', (vmId: QuickJSHandle): void => {
      const id = plugin.vm.getString(vmId);

      if (!id) {
        throw new Error('Invalid argument');
      }

      useCreatorStore.getState().project.removeTheme(id);
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'removeTheme', {
    value: removeThemeHandle,
    configurable: false,
  });
}

export async function getCanvasScreenshot(plugin: Plugin): Promise<void> {
  const getCanvasScreenshotHandle = plugin.scope.manage(
    plugin.vm.newAsyncifiedFunction('getCanvasScreenshot', async (): Promise<QuickJSHandle> => {
      const setOpenHiddenPlayer = useCreatorStore.getState().ui.setOpenHiddenPlayer;

      setOpenHiddenPlayer(true);

      const dataURL = await _getCanvasScreenshot(SceneType.ACTIVE_SCENE);

      setOpenHiddenPlayer(false);

      const vmThemesObject = marshal(plugin, { dataURL });

      return vmThemesObject;
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getCanvasScreenshot', {
    value: getCanvasScreenshotHandle,
    configurable: false,
  });
}

export function getBoundingBoxSize(plugin: Plugin): void {
  const getBoundingBoxSizeHandle = plugin.scope.manage(
    plugin.vm.newFunction('getBoundingBoxSize', (): QuickJSHandle => {
      const selectedNodesInfo = useCreatorStore.getState().ui.selectedNodesInfo;

      const objects = selectedNodesInfo.map((node) => canvasMap.get(node.nodeId as string)).filter(Boolean);

      if (objects.length === 0) {
        return marshal(plugin, { width: 0, height: 0 });
      }

      const boundingBox = new Box3().setFromObjects(objects as CObject3D[], true);
      const size = boundingBox.getSize(new Vector3());

      return marshal(plugin, { width: size.x, height: size.y });
    }),
  );

  plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, 'getBoundingBoxSize', {
    value: getBoundingBoxSizeHandle,
    configurable: false,
  });
}
