/**
 * Copyright 2021 Design Barn Inc.
 */

import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { StartSaveFileUpload } from '../../ProjectListener';

import { SaveToExistingProject } from '.';
import { useCreatorStore } from '~/store';
import { SavingState } from '~/store/projectSlice';

// TODO: to be remove after implement state machine
const INIT_MIN_RENDER = 3;
let initRender = 0;

export const ExistingFile: React.FC = () => {
  const [savingState, madeFirstChange, setMadeFirstChange, token] = useCreatorStore(
    (state) => [
      state.project.info.savingState,
      state.project.madeFirstChange,
      state.project.setMadeFirstChange,
      state.user.token,
    ],
    shallow,
  );

  const ableToSave = Boolean(token);

  initRender += 1;

  useEffect(() => {
    if (madeFirstChange && initRender === INIT_MIN_RENDER - 1) {
      setMadeFirstChange(false);
    }
  }, [madeFirstChange, setMadeFirstChange]);

  if (savingState === SavingState.SAVING && ableToSave) {
    // Perform saving
    return (
      <>
        <StartSaveFileUpload />
      </>
    );
  } else if (savingState !== SavingState.SAVED && madeFirstChange && initRender > INIT_MIN_RENDER) {
    return <SaveToExistingProject />;
  }

  return null;
};
