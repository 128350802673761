/**
 * Copyright 2022 Design Barn Inc.
 */

export enum GlobalModalConstant {
  AnimationAdder = 'AnimationAdder',
  EditGuides = 'EditGuides',
  FrameOnImport = 'FrameOnImport',
  LatestReleaseModal = 'LatestReleaseModal',
  Onboarding = 'Onboarding',
  ProjectSave = 'ProjectSave',
  Share = 'Share',
  SupportFeatures = 'SupportFeatures',
}
