/**
 * Copyright 2024 Design Barn Inc.
 */

import { throttle } from 'lodash-es';
import { DoubleSide } from 'three';

import { emitter, EmitterEvent } from '~/lib/emitter';

export const RENDER_ORDERS = {
  COLOR_STOP: 100,
  COLOR_STOP_TRANSPARENT_PATTERN: 99,
  GRADIENT_LINE: 98,
  HIGHLIGHT_POINT: 101,
  HOVER_CIRCLE: 99,
  START_AND_END_POINTS: 100,
  SELECTION_BOX: 97,
};

export const materialOptions = {
  depthTest: false,
  fog: false,
  transparent: true,
  side: DoubleSide,
};

export const emitGradientFillUpdatedThrottled = throttle(() => {
  emitter.emit(EmitterEvent.SHAPE_GRADIENT_UPDATED_FROM_GRADIENT_CONTROLS);
}, 30);
