/**
 * Copyright 2023 Design Barn Inc.
 */

import type { Scene, CubicBezierShape } from '@lottiefiles/toolkit-js';
import { clamp } from 'lodash-es';

import { layerMap } from './layer';
import { getAssetByReferenceId, toolkit } from './toolkit';

import type { BezierMesh, CMesh, CObject3D } from '~/features/canvas';
import { useCreatorStore } from '~/store';

export const canvasMap: Map<string, CObject3D | CMesh | BezierMesh> = new Map<string, CObject3D>();

export const setCanvasMap = (toolkitId: string, object: CObject3D | CMesh): void => {
  canvasMap.set(toolkitId, object);
};

export const removeCanvasMapItem = (toolkitId: string): void => {
  canvasMap.delete(toolkitId);
};

export interface PrecompLayerRange {
  currentFrame?: number;
  inPoint: number;
  outPoint: number;
  precompFrameRate: number;
  precompId: string;
  referenceId: string;
  sceneFrameRate: number;
  timelineOffset: number;
}
export const precompLayerMap: Map<string, PrecompLayerRange> = new Map<string, PrecompLayerRange>();

// inPoint, outPoint, timelineOffset, parentPrecompId
type RenderRange = [number, number, number, string | null | undefined];

export const renderRangeMap: Map<string, RenderRange> = new Map<string, RenderRange>();

export const setRenderRangeMap = (toolkitId: string, range: RenderRange): void => {
  renderRangeMap.set(toolkitId, range);
};

export const removeRenderRangeMapItem = (toolkitId: string): void => {
  const renderRange = renderRangeMap.get(toolkitId);

  if (renderRange) {
    const [, , , parentPrecompId] = renderRange;

    if (parentPrecompId) {
      renderRangeMap.delete(`${parentPrecompId}_${toolkitId}`);
    }
    renderRangeMap.delete(toolkitId);
  }
};

export const updateRenderRanges = (currentFrame: number): void => {
  const getNodeByIdOnly = useCreatorStore.getState().toolkit.getNodeByIdOnly;

  renderRangeMap.forEach((renderRange, toolkitId) => {
    const object = canvasMap.get(toolkitId);

    if (!object) return;
    const [inPoint, outPoint, , parentPrecompId] = renderRange;
    let adjustedIp = inPoint;
    let adjustedOp = outPoint;

    if (parentPrecompId) {
      const precompLayer = precompLayerMap.get(parentPrecompId);

      if (precompLayer) {
        adjustedIp = Math.max(adjustedIp + precompLayer.timelineOffset, precompLayer.inPoint);
        adjustedOp = Math.min(adjustedOp + precompLayer.timelineOffset, precompLayer.outPoint);
      }
    }

    const layerUI = layerMap.get(toolkitId);
    const layer = getNodeByIdOnly(toolkitId);

    let isHidden = layerUI?.isHidden || Boolean(layer?.getData('isMatteHidden')) || false;

    if (object.userData['isHiddenAsAnotherOneIsFocused']) {
      isHidden = true;
    }

    object.visible = !isHidden && currentFrame >= adjustedIp && currentFrame < adjustedOp;
  });
};

export const setPrecompLayerMap = (toolkitId: string, range: PrecompLayerRange): void => {
  precompLayerMap.set(toolkitId, range);
};

export const removePrecompLayerMapItem = (toolkitId: string): void => {
  precompLayerMap.delete(toolkitId);
};

export const clearPrecompLayerMap = (): void => {
  precompLayerMap.clear();
};

export const updatePrecompLayerCurrentFrame = (currentFrame: number): void => {
  const sceneIndex = useCreatorStore.getState().toolkit.sceneIndex;

  const actions = [];

  for (const [toolkitId, precompLayer] of precompLayerMap) {
    const object = canvasMap.get(toolkitId);

    if (!object) return;

    const precompObj = getAssetByReferenceId(toolkit.scenes[sceneIndex] as Scene, precompLayer.referenceId as string);

    const precompLayerOffset = (precompLayer.timelineOffset as number) || 0;
    const endFrame = precompObj?.timeline.endFrame as number;
    const clampedFrame = clamp(currentFrame - precompLayerOffset, 0, endFrame - 1);

    if (precompObj && precompLayer.currentFrame !== clampedFrame) {
      precompLayer.currentFrame = clampedFrame;
      precompLayerMap.set(toolkitId, precompLayer);
      actions.push(() => {
        precompObj.timeline.setCurrentFrame(clampedFrame);
      });
    }
  }

  actions.forEach((action) => action());
};

export const initPrecompLayerMap = (): void => {
  precompLayerMap.clear();
};

export const modifierMap: Map<string, CMesh[]> = new Map<string, []>();

export const setModifierMap = (modifierId: string, object: CMesh): void => {
  const item = modifierMap.get(modifierId);

  if (item && item.length > 0) {
    modifierMap.set(modifierId, [...item, object]);
  } else modifierMap.set(modifierId, [object]);
};

export const removeModifierMapItem = (modifierId: string): void => {
  modifierMap.delete(modifierId);
};

export const initModifierMap = (): void => {
  modifierMap.clear();
  Object.keys(modifierMap).forEach((toolkitId) => {
    removeModifierMapItem(toolkitId);
  });
};

export interface CameraPosition {
  x: number;
  y: number;
}

interface SceneProperties {
  cameraPosition: CameraPosition | null;
  canvasZoom: number | null;
  isWorkAreaFrameEndAtTheEndOfScene: boolean;
  workAreaFrameEnd: number | null;
  workAreaFrameStart: number | null;
}

export const scenePropertiesMap: Map<string, SceneProperties> = new Map<string, SceneProperties>();

export const setScenePropertiesMap = (sceneId: string, props: Partial<SceneProperties>): void => {
  const currentProps = scenePropertiesMap.get(sceneId);

  scenePropertiesMap.set(sceneId, {
    ...(currentProps || {
      cameraPosition: null,
      canvasZoom: null,
      isWorkAreaFrameEndAtTheEndOfScene: false,
      workAreaFrameEnd: null,
      workAreaFrameStart: null,
    }),
    ...props,
  });
};

export const refreshScenePropertiesMap = (): void => {
  scenePropertiesMap.forEach((_props, sceneId) => {
    const scene = useCreatorStore.getState().toolkit.getNodeByIdOnly(sceneId);

    if (!scene) {
      scenePropertiesMap.delete(sceneId);
    }
  });
};
export interface Matte {
  matteBezier: CubicBezierShape | null;
  matteMesh: CMesh | null;
}
export interface MatteData {
  mattedItems: MattedItem[];
  mattes: Matte[];
}
export interface MattedItem {
  beziers: CubicBezierShape[];
  matteMode: number | null;
  mesh: BezierMesh;
}

export const matteMap: Map<
  string,
  {
    mattedItems: MattedItem[];
    mattes: Matte[];
  }
> = new Map<string, MatteData>();

export const setMaskMap = (matteId: string, { mattes, mattedItems = [] }: MatteData): void => {
  matteMap.set(matteId, {
    mattedItems,
    mattes,
  });
};

export const removeMaskMap = (modifierId: string): void => {
  matteMap.delete(modifierId);
};

export const initMaskMap = (): void => {
  matteMap.clear();
};
