/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ColorStopJSON } from '@lottiefiles/toolkit-js';
import { GradientFillType, ShapeType } from '@lottiefiles/toolkit-js';
import GParser from 'gradient-parser';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { ColorInput } from '../../../../../../components/Elements/ColorInput/ColorInput';

import type { AnimatedInputProp } from './animatedUtil';

import type { NumberResult } from '~/components/Elements/Input';
import { emitter, EmitterEvent } from '~/lib/emitter';
import type { CurrentGFillShape } from '~/lib/toolkit';
import { AnimatedType } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';

export const AnimatedGradientColor: React.FC<AnimatedInputProp> = ({ animatedProp }) => {
  const [setAnimatedValue, getShape, getNodeByIdOnly] = useCreatorStore(
    (state) => [state.toolkit.setAnimatedValue, state.toolkit.getShape, state.toolkit.getNodeByIdOnly],
    shallow,
  );

  const gradientFillShape = getNodeByIdOnly(animatedProp.id)?.parent;
  const shape = getShape(ShapeType.GRADIENT_FILL, gradientFillShape?.nodeId) as CurrentGFillShape;
  const currentColor = shape.cssColor;
  const currentOpacity = shape.opacity;

  const handleOnChangeColor = useCallback((color: string): void => {
    const gradientObj = GParser.parse(color);
    const updateParam: ColorStopJSON[] = [];

    if (gradientObj[0] && gradientObj[0].colorStops.length > 0) {
      gradientObj[0].colorStops.forEach((cStop) => {
        const [red, green, blue, alpha] = cStop.value;

        updateParam.push({
          r: Number(red),
          g: Number(green),
          b: Number(blue),
          a: Number(alpha),
          offset: Number(cStop.length?.value),
        });
      });
    }

    useCreatorStore.getState().toolkit.setGradientColor(updateParam);

    emitter.emit(EmitterEvent.ANIMATED_SHAPE_GRADIENT_COLOR_UPDATED);
  }, []);

  const handleOnChangeOpacity = useCallback(
    (result: NumberResult) => {
      if (currentOpacity !== result.value) {
        setAnimatedValue(AnimatedType.FILL_OPACITY, [result.value]);
      }
    },
    [currentOpacity, setAnimatedValue],
  );

  return (
    <div className="flex items-center">
      <ColorInput
        gradientType={ShapeType.GRADIENT_FILL}
        shapeType={ShapeType.GRADIENT_FILL}
        overrideStyle={{ left: '350px' }}
        colorStyleClass={'!w-3 !h-3'}
        styleClass="rounded-r-none w-[70px] !h-4 justify-end"
        inputStyleClass="text-[10px] w-[45px] !h-4 bg-transparent"
        color={currentColor}
        opacity={currentOpacity}
        onChangeColor={handleOnChangeColor}
        onChangeOpacity={(result) => handleOnChangeOpacity({ name: 'opacity', value: result, trueValue: result })}
        gradientShape={[shape]}
        message={shape.type === GradientFillType.LINEAR ? 'Linear' : 'Radial'}
        selectedIds={shape.id ? [shape.id] : []}
        disabled
        enableColorModeChange={true}
      />
    </div>
  );
};
