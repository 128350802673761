/**
 * Copyright 2021 Design Barn Inc.
 */

import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

const DefaultBackdrop: React.FC = () => {
  return <div className="fixed inset-0 bg-gray-900 opacity-70" />;
};

// Note: Make sure the children component has at least one focusable element to suppress there are no focusable element warning
// Reference: https://headlessui.dev/react/dialog#managing-initial-focus
// Issue: https://github.com/tailwindlabs/headlessui/issues/265
interface ModalProps {
  backdrop?: React.FC | React.ReactNode;
  children?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  showBackdrop?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  backdrop: backdropProp,
  children,
  isOpen,
  onClose,
  showBackdrop = true,
}) => {
  const backdrop = React.useMemo(() => (backdropProp ? backdropProp : <DefaultBackdrop />), [backdropProp]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <Dialog static as="div" className="relative z-modal" onClose={onClose ? onClose : () => {}}>
        <>
          {showBackdrop && backdrop}
          {children}
        </>
      </Dialog>
    </Transition>
  );
};
