/**
 * Copyright 2024 Design Barn Inc.
 */

import type { QuickJSHandle } from 'quickjs-emscripten';

import type { Plugin } from '../Plugin';

import { useCreatorStore } from '~/store';

export function showToast(plugin: Plugin): void {
  (
    [
      {
        name: 'showSuccessToast',
        options: { icon: 'success', alertColor: '#00951D', timeout: 3000, text: 'Plugin action successful' },
      },
      {
        name: 'showErrorToast',
        options: {
          text: 'Plugin action unsuccessful. Please try again.',
          icon: 'error',
          alertColor: '#D92600',
          timeout: 3000,
        },
      },
    ] as Array<{
      name: string;
      options: { alertColor: string; icon: 'error' | 'success'; text: string; timeout: number };
    }>
  ).forEach((methodInfo) => {
    const showToastFn = plugin.scope.manage(
      plugin.vm.newFunction(methodInfo.name, (): void => {
        useCreatorStore.getState().ui.setAlert({
          ...methodInfo.options,
        });
      }),
    );

    plugin.vm.defineProp(plugin.creatorHandle as QuickJSHandle, methodInfo.name, {
      value: showToastFn,
      configurable: false,
    });
  });
}
