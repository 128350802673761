/**
 * Copyright 2022 Design Barn Inc.
 */

import type { ProjectJSON } from '@lottiefiles/toolkit-js';
import DomToImage from 'dom-to-image';

import type { DotLottiePlayer } from '../../components/Elements/TestAnimationDialog/TestAnimationPlayer';
import { getActiveSceneLottieJSON, getLottieJSON } from '../function/menu';

import { getScaledSize } from '~/components/Elements/TestAnimationDialog/TestAnimationPlayer';
import { DEFAULT_FILE_NAME, SceneType } from '~/data/constant';
import { toolkit } from '~/lib/toolkit';
import { useCreatorStore } from '~/store';
import { getElementBySelectorAsync } from '~/utils';

const setOpenHiddenPlayer = useCreatorStore.getState().ui.setOpenHiddenPlayer;

export interface Metadata {
  thumbnail: string;
  title: string;
  version: string;
}

export interface ClientState {
  currentSelectedNode: string;
}

interface State {
  client: ClientState;
  toolkit: ProjectJSON;
}

interface DotCreator {
  metadata: Metadata;
  state: State;
  themes: Record<string, unknown>;
}

export const generateCreatorJSON = ({
  clientState,
  metadata,
  themes,
  toolkitJSON,
}: {
  clientState: ClientState;
  metadata: Metadata;
  themes: DotCreator['themes'];
  toolkitJSON: ProjectJSON;
}): DotCreator => {
  const dotCreatorJSON: DotCreator = {
    metadata: {
      ...metadata,
    },
    state: {
      client: {
        ...clientState,
      },
      toolkit: toolkitJSON,
    },
    themes,
  };

  return dotCreatorJSON;
};

export const getCanvasScreenshot = async (type: SceneType = SceneType.MAIN_SCENE): Promise<string> => {
  // Wait for #lottie dom to be shown
  const hiddenPlayerDOM = await getElementBySelectorAsync('#thumbnail-player');

  // let the browser render the player before getting the snapshot
  await new Promise((resolve) => setTimeout(resolve, 200));

  const shadowRoot = (hiddenPlayerDOM as Element).shadowRoot;
  const shadowContent = (shadowRoot as ShadowRoot).querySelector('#animation-container');
  const json = (type === SceneType.MAIN_SCENE ? await getLottieJSON() : await getActiveSceneLottieJSON()) as Record<
    string,
    never
  >;

  if (hiddenPlayerDOM) {
    (hiddenPlayerDOM as DotLottiePlayer).load(json);

    // eslint-disable-next-line id-length
    const { h, w } = json as Record<string, number>;

    const size = getScaledSize({ width: w as number, height: h as number });

    // convert dom to png
    const thumbnailImageDataURL = await DomToImage.toPng(shadowContent as HTMLElement, {
      quality: 1,
      height: size.h,
      width: size.w,
    });

    return Promise.resolve(thumbnailImageDataURL);
  } else {
    // Error image to browser screenshot
    const canvas = document.getElementById('artboard-canvas') as HTMLCanvasElement;

    return Promise.resolve(canvas.toDataURL());
  }
};

export const getDotCreator = async (): Promise<DotCreator | null> => {
  const json = toolkit.state;

  let dotCreator = null;

  setOpenHiddenPlayer(true);

  const thumbnail = await getCanvasScreenshot();

  const nm = json.scenes[0]?.properties.nm || DEFAULT_FILE_NAME;
  const metadata: Metadata = {
    title: nm as string,
    version: '1.0.0',
    thumbnail,
  };

  const themes = useCreatorStore.getState().project.themes;

  // TODO: handle multi select
  dotCreator = generateCreatorJSON({
    toolkitJSON: json,
    clientState: {
      currentSelectedNode: useCreatorStore.getState().ui.selectedNodesInfo[0]?.nodeId as string,
    },
    metadata,
    themes,
  });

  setOpenHiddenPlayer(false);

  return Promise.resolve(dotCreator);
};
